import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import Avatar from '../common/Avatar';

const StyledAvatar = styled(Avatar)(props => ({
  marginRight: props.border ? 4 : 10,
  marginLeft: 0
}));

const AvatarWithName = ({ name, size, defaultIcon, avatarProps, typographyProps }) => (
  <Box display="flex" justify="flex-start" alignItems="center">
    <StyledAvatar alt={name} defaultIcon={defaultIcon} size={size} {...avatarProps} />
    <Typography {...typographyProps}>{name}</Typography>
  </Box>
);

AvatarWithName.propTypes = {
  name: PropTypes.string.isRequired,
  avatarProps: PropTypes.object,
  typographyProps: PropTypes.object
};

AvatarWithName.defaultProps = {
  size: 20,
  defaultIcon: 'person',
  typographyProps: {
    variant: 'subtitle1'
  }
};

export default AvatarWithName;

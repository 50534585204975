import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import formStyles from '../../styles/common/formStyles';

const FilterSelect = props => {
  const { value, label, onChange, name, options, emptyValueLabel } = props;
  const classes = formStyles();

  const adjustedOptions = Array.isArray(options) ? options : JSON.parse(options);
  const filterId = `${name}-filter-select`;

  const onChangeHandler = event => {
    onChange(name, event.target.value);
  };

  return (
    <FormControl variant="outlined" className={classes.dropdownWrapper}>
      <InputLabel htmlFor={filterId}>{label}</InputLabel>
      <Select
        value={adjustedOptions.length ? value : ''}
        onChange={onChangeHandler}
        input={
          <OutlinedInput
            name={name}
            id={filterId}
            classes={{
              root: classes.dropdownRoot
            }}
          />
        }
        name={name}
      >
        <MenuItem value="">{emptyValueLabel || 'Show All'}</MenuItem>
        {adjustedOptions.map(option => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

FilterSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  emptyValueLabel: PropTypes.string
};

export default FilterSelect;

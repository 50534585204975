import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';

import ReleasesListFilters from './ReleasesListFilters';
import AutoTable from '../../../shared/components/table/AutoTable';
import { CELL_TYPES } from '../../../shared/constants';
import { getActiveReleaseDeadlineWarningColor } from '../../../shared/utils/helpers';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import { resolveProductLink } from '../../../shared/utils/linkResolvers';

const ReleaseList = props => {
  const {
    relay: { refetch },
    releases: { releasesByOrg },
    history,
    match,
    refetchCounter,
    userContext: {
      orgStaff: {
        organization: { configuration }
      }
    }
  } = props;

  const navigation = JSON.parse(configuration).navigation;

  if (!releasesByOrg) return <div>Something went wrong!</div>;

  const {
    edges,
    totalCount,
    edgeCount,
    pageInfo: { hasNextPage, endCursor }
  } = releasesByOrg;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    history.push(`${match.url}/${id}`);
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      name,
      code,
      product: { code: productCode, title: productTitle, id: productId },
      deliverablesTotalAmount,
      totalBudget,
      deadline,
      hasIncompleteDeliverables
    } = edge.node;

    return {
      node: {
        id,
        name,
        code,
        product__code: { cellText: productCode, cellLink: resolveProductLink(productId) },
        product__title: { cellText: productTitle, cellLink: resolveProductLink(productId) },
        deliverablesTotalAmount,
        totalBudget,
        deadline: {
          date: deadline,
          color: getActiveReleaseDeadlineWarningColor(
            configuration,
            deadline,
            hasIncompleteDeliverables
          )
        }
      }
    };
  });

  return (
    <AutoTable
      rowTemplate={[
        {
          name: 'name',
          label: `${navigation.releaseTitle.singular} Name`,
          type: CELL_TYPES.link,
          sortable: true
        },
        {
          name: 'code',
          label: `${navigation.releaseTitle.singular} Code`,
          type: CELL_TYPES.link,
          sortable: true
        },
        {
          name: 'product__title',
          label: `${navigation.productTitle.singular} Name`,
          sortable: true,
          type: CELL_TYPES.relationLink
        },
        {
          name: 'product__code',
          label: `${navigation.productTitle.singular} Code`,
          sortable: true,
          type: CELL_TYPES.relationLink
        },
        {
          name: 'deadline',
          label: 'Key Date',
          type: CELL_TYPES.dateWithWarning,
          sortable: true
        },
        {
          name: 'totalBudget',
          label: 'Total Budget',
          type: CELL_TYPES.amount
        },
        { name: 'deliverablesTotalAmount', label: 'Running Total', type: CELL_TYPES.amount }
      ]}
      edges={flattenedEdges}
      onChangeHandler={onChangeHandler}
      refetchCounter={refetchCounter}
      filterProps={{
        filterComponent: <ReleasesListFilters />,
        withApply: true
      }}
      rowProps={{
        handleLinkClick: onLinkClickHandler
      }}
      paginationProps={{ totalCount, edgeCount, hasNextPage, endCursor }}
      withBackground
    />
  );
};

export default withUserContext(
  createRefetchContainer(
    ReleaseList,
    {
      releases: graphql`
        fragment ReleaseList_releases on Query {
          releasesByOrg(
            after: $after
            first: $first
            releaseName: $releaseName
            releaseCode: $releaseCode
            productName: $productName
            productCode: $productCode
            deadlineFrom: $deadlineFrom
            deadlineTo: $deadlineTo
            orderBy: $orderBy
            includeInactive: $includeInactive
          ) {
            totalCount
            edgeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                code
                deadline
                product {
                  code
                  title
                  id
                }
                totalBudget
                deliverablesTotalAmount
                hasIncompleteDeliverables
              }
            }
          }
        }
      `
    },

    graphql`
      query ReleaseListRefetchQuery(
        $after: String
        $first: Int
        $releaseName: String
        $releaseCode: String
        $productName: String
        $productCode: String
        $deadlineFrom: Date
        $deadlineTo: Date
        $orderBy: String
        $includeInactive: Boolean
      ) {
        ...ReleaseList_releases
      }
    `
  )
);

/**
 * @flow
 * @relayHash 945114ed20f08620f4d6ee27ac6acc65
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ToggleAssignFollowersForJobsInput = {|
  userIds: $ReadOnlyArray<?string>,
  jobIds: $ReadOnlyArray<?string>,
  follow: boolean,
  withDeliverables?: ?boolean,
  clientMutationId?: ?string,
|};
export type ToggleAssignFollowersForJobsMutationVariables = {|
  input: ToggleAssignFollowersForJobsInput
|};
export type ToggleAssignFollowersForJobsMutationResponse = {|
  +toggleAssignFollowersForJobs: ?{|
    +success: ?boolean,
    +clientMutationId: ?string,
  |}
|};
export type ToggleAssignFollowersForJobsMutation = {|
  variables: ToggleAssignFollowersForJobsMutationVariables,
  response: ToggleAssignFollowersForJobsMutationResponse,
|};
*/

/*
mutation ToggleAssignFollowersForJobsMutation(
  $input: ToggleAssignFollowersForJobsInput!
) {
  toggleAssignFollowersForJobs(input: $input) {
    success
    clientMutationId
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'ToggleAssignFollowersForJobsInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'toggleAssignFollowersForJobs',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'ToggleAssignFollowersForJobsPayload',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'success',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'clientMutationId',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ToggleAssignFollowersForJobsMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'ToggleAssignFollowersForJobsMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'ToggleAssignFollowersForJobsMutation',
      id: null,
      text:
        'mutation ToggleAssignFollowersForJobsMutation(\n  $input: ToggleAssignFollowersForJobsInput!\n) {\n  toggleAssignFollowersForJobs(input: $input) {\n    success\n    clientMutationId\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '1a3ebae703a2cd4de119a058167a1d05';
module.exports = node;

import React from 'react';

import AutoTableRow from './AutoTableRow';

const AutoTableRows = ({ rowTemplate, edges, checked, selectedId, onCheckRow, ...otherProps }) => {
  return edges.map((edge, index) => {
    const { node } = edge;
    return (
      <AutoTableRow
        key={node.id}
        rowIndex={index}
        selected={selectedId && selectedId === node.id ? true : false}
        rowTemplate={rowTemplate}
        isChecked={checked ? checked.includes(node.id) : false}
        handleCheckRow={onCheckRow}
        labelledBy={
          (typeof node.name === 'string' && node.name) ||
          (typeof node.title === 'string' && node.title) ||
          node.subject ||
          node.id
        }
        {...node}
        {...otherProps}
      />
    );
  });
};

export default AutoTableRows;

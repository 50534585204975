import React from 'react';
import { styled } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';

import colors from '../../styles/common/colors';

const SidebarMenuArrow = styled(({ isOrganization, ...other }) => <IconButton {...other} />)({
  height: '34px',
  width: '34px',
  padding: '0px',
  border: 'none',
  borderRadius: '50%',
  backgroundColor: props => (props.isOrganization ? colors.grey : colors.violet),
  zIndex: '3',

  '&:hover': {
    backgroundColor: props => (props.isOrganization ? colors.grey : colors.violet)
  },

  '& svg': {
    height: '16px',
    width: '16px',
    color: props => (props.isOrganization ? colors.lightBlueGrey : colors.lightViolet)
  }
});

export default SidebarMenuArrow;

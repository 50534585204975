import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Spinner from './Spinner';

const useStyles = makeStyles(theme => {
  const {
    primary,
    secondary,
    common: { white, black },
    colors
  } = theme.palette;

  return {
    button: {
      height: '34px',
      lineHeight: '16px',
      padding: props => props.customPadding || '0 19px',
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'bold',

      '&:disabled': {
        opacity: 0.3
      }
    },

    big: {
      height: '60px',
      fontSize: '16px'
    },

    edit: {
      width: '80px'
    },

    margin: {
      margin: '0 8px'
    },

    primary: {
      color: white,
      backgroundColor: primary.main,
      '&:hover': {
        backgroundColor: primary.dark
      },
      '&:disabled': {
        color: white
      }
    },

    secondary: {
      color: colors.darkBlueGrey,
      backgroundColor: secondary.main,
      '&:hover': {
        backgroundColor: secondary.dark
      },
      '& svg': {
        '& path': {
          fill: colors.darkBlueGrey
        }
      },
      '&:disabled': {
        opacity: props => (props.disabledWithoutStyling ? 1 : 0.7),
        color: props =>
          props.disabledWithoutStyling ? colors.darkBlueGrey : theme.palette.action.disabled
      }
    },

    alternative: {
      color: primary.main,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: primary.main,
      '&:hover': {
        backgroundColor: fade(primary.main, 0.1)
      },
      '&:disabled': {
        color: primary.main
      }
    },

    transparent: {
      backgroundColor: 'transparent',
      color: colors.darkGrey,
      '& svg': {
        '& path': {
          fill: colors.darkBlueGrey
        }
      }
    },

    highlighted: {
      color: primary.main,
      backgroundColor: fade(primary.main, 0.2),
      '&:hover': {
        backgroundColor: fade(primary.main, 0.4)
      },
      '&:disabled': {
        opacity: props => (props.disabledWithoutStyling ? 1 : ''),
        color: primary.main
      }
    },

    black: {
      color: white,
      background: fade(black, 0.7),

      '&:hover': {
        background: fade(black, 0.5)
      },

      '& svg': {
        height: theme.spacing(2),
        width: theme.spacing(2),
        marginRight: theme.spacing(1),
        '& path': {
          fill: white
        }
      },
      '&:disabled': {
        color: white
      }
    },

    cancelation: {
      color: theme.palette.common.white,
      background: theme.palette.colors.red,
      '&:hover': {
        background: fade(theme.palette.colors.red, 0.5)
      }
    },

    acceptation: {
      color: theme.palette.common.white,
      background: theme.palette.colors.green,
      '&:hover': {
        background: fade(theme.palette.colors.green, 0.5)
      }
    },

    buttonIcon: {
      marginRight: '5px'
    },
    fullWidth: {
      width: '100%'
    }
  };
});

const GlobalButton = forwardRef((props, ref) => {
  const {
    handleClick,
    children,
    variant,
    icon,
    className,
    noMargin,
    big,
    edit,
    loading,
    iconProps,
    disabled,
    disabledWithoutStyling,
    fullWidth,
    customPadding,
    ...otherProps
  } = props;

  const classes = useStyles({ disabledWithoutStyling, customPadding });
  const Icon = icon;

  const matchButtonType = variant => {
    return typeof variant !== 'undefined' ? classes[variant] : classes.primary;
  };

  const isDisabled = disabled || disabledWithoutStyling;

  return (
    <Button
      ref={ref}
      onClick={handleClick}
      className={clsx(
        classes.button,
        matchButtonType(variant),
        { [classes.margin]: !noMargin },
        { [classes.big]: big },
        { [classes.edit]: edit },
        { [classes.fullWidth]: fullWidth },
        className
      )}
      formNoValidate
      disabled={isDisabled}
      {...otherProps}
    >
      {icon && <Icon className={classes.buttonIcon} {...iconProps} />}
      {loading ? <Spinner /> : children}
    </Button>
  );
});

GlobalButton.propTypes = {
  handleClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  variant: PropTypes.string,
  icon: PropTypes.object,
  className: PropTypes.string,
  // If 'true' will disable button, but keep non-disabled styles.
  disabledWithoutStyling: PropTypes.bool
};

GlobalButton.defaultProps = {
  disabledWithoutStyling: false
};

export default GlobalButton;

/**
 * @flow
 * @relayHash b99f9c47a96b53d84ddf39a270149ac7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchableProductDropdownQueryVariables = {|
  title?: ?string,
  orderBy?: ?string,
  first?: ?number,
  isActive?: ?boolean,
|};
export type SearchableProductDropdownQueryResponse = {|
  +allProducts: ?{|
    +edgeCount: ?number,
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
      |}
    |}>,
  |}
|};
export type SearchableProductDropdownQuery = {|
  variables: SearchableProductDropdownQueryVariables,
  response: SearchableProductDropdownQueryResponse,
|};
*/


/*
query SearchableProductDropdownQuery(
  $title: String
  $orderBy: String
  $first: Int
  $isActive: Boolean
) {
  allProducts(title: $title, orderBy: $orderBy, first: $first, isActive: $isActive) {
    edgeCount
    totalCount
    edges {
      node {
        id
        title
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isActive",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "allProducts",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "isActive",
        "variableName": "isActive"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "ProductNodeConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "edgeCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SearchableProductDropdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchableProductDropdownQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SearchableProductDropdownQuery",
    "id": null,
    "text": "query SearchableProductDropdownQuery(\n  $title: String\n  $orderBy: String\n  $first: Int\n  $isActive: Boolean\n) {\n  allProducts(title: $title, orderBy: $orderBy, first: $first, isActive: $isActive) {\n    edgeCount\n    totalCount\n    edges {\n      node {\n        id\n        title\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '47e39f3f9e4d29efa76d74f18d04afbf';
module.exports = node;

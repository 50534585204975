import React from 'react';
import PropTypes from 'prop-types';
import ClearFieldButton from '../ClearFieldButton';

const ClearIndicator = ({ clearValue }) => <ClearFieldButton onClick={clearValue} />;

ClearIndicator.propTypes = {
  clearValue: PropTypes.func.isRequired
};

export default ClearIndicator;

/**
 * @flow
 * @relayHash adab1a183066cd1aac988665055516e4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MarkAllNotificationsAsReadInput = {|
  clientMutationId?: ?string
|};
export type MarkAllNotificationsAsReadMutationVariables = {|
  input: MarkAllNotificationsAsReadInput
|};
export type MarkAllNotificationsAsReadMutationResponse = {|
  +markAllNotificationsAsRead: ?{|
    +isUpdated: ?boolean
  |}
|};
export type MarkAllNotificationsAsReadMutation = {|
  variables: MarkAllNotificationsAsReadMutationVariables,
  response: MarkAllNotificationsAsReadMutationResponse,
|};
*/


/*
mutation MarkAllNotificationsAsReadMutation(
  $input: MarkAllNotificationsAsReadInput!
) {
  markAllNotificationsAsRead(input: $input) {
    isUpdated
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkAllNotificationsAsReadInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "markAllNotificationsAsRead",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkAllNotificationsAsReadPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isUpdated",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MarkAllNotificationsAsReadMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MarkAllNotificationsAsReadMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MarkAllNotificationsAsReadMutation",
    "id": null,
    "text": "mutation MarkAllNotificationsAsReadMutation(\n  $input: MarkAllNotificationsAsReadInput!\n) {\n  markAllNotificationsAsRead(input: $input) {\n    isUpdated\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e1fa43adba7fbff474c79ad4e030e29b';
module.exports = node;

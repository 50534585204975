/**
 * @flow
 * @relayHash 8c1de2028d15921f88d9b34651534b97
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DeliverableFilesList_deliverable$ref = any;
export type DeliverableFilesTabQueryVariables = {|
  id: string
|};
export type DeliverableFilesTabQueryResponse = {|
  +deliverable: ?{|
    +$fragmentRefs: DeliverableFilesList_deliverable$ref
  |}
|};
export type DeliverableFilesTabQuery = {|
  variables: DeliverableFilesTabQueryVariables,
  response: DeliverableFilesTabQueryResponse,
|};
*/


/*
query DeliverableFilesTabQuery(
  $id: ID!
) {
  deliverable(id: $id) {
    ...DeliverableFilesList_deliverable
    id
  }
}

fragment DeliverableFilesList_deliverable on DeliverableNode {
  id
  state
  finalFileMaxSize
  finalFiles(orderBy: "-created") {
    edges {
      node {
        id
        created
        fileName
        fileSize
        description
        fileUrl
        storageClass
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeliverableFilesTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeliverableFilesList_deliverable",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeliverableFilesTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "finalFileMaxSize",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "finalFiles",
            "storageKey": "finalFiles(orderBy:\"-created\")",
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-created"
              }
            ],
            "concreteType": "DeliverableFinalFileNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableFinalFileNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableFinalFileNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "created",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileSize",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "storageClass",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DeliverableFilesTabQuery",
    "id": null,
    "text": "query DeliverableFilesTabQuery(\n  $id: ID!\n) {\n  deliverable(id: $id) {\n    ...DeliverableFilesList_deliverable\n    id\n  }\n}\n\nfragment DeliverableFilesList_deliverable on DeliverableNode {\n  id\n  state\n  finalFileMaxSize\n  finalFiles(orderBy: \"-created\") {\n    edges {\n      node {\n        id\n        created\n        fileName\n        fileSize\n        description\n        fileUrl\n        storageClass\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aab2acadc1b08cd0ce8c70531983c5a6';
module.exports = node;

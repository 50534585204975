import React from 'react';
import clsx from 'clsx';
import graphql from 'babel-plugin-relay/macro';
import * as Yup from 'yup';
import { createFragmentContainer } from 'react-relay';
import { Typography, Grid, Divider } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';

import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import FormikEditableImage from '../../../../../shared/components/form/formikFields/FormikEditableImage';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import UpdateProductMutation from '../../mutations/UpdateProductMutation';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import { yupSchemas } from '../../../../../shared/validations';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import EditableField from '../../../../../shared/components/form/fields/EditableField';

const ProductDetailsContent = props => {
  const {
    isEditing,
    setEditing,
    product: { id, code, representativeImageUrl, internalId, title, description },
    toggleEditHandler,
    userContext: {
      orgStaff: { allowedActions, organization }
    }
  } = props;

  const classes = useDetailsStyles();

  const initialValues = {
    productCode: code,
    productTitle: title,
    productDescription: description,
    internalId: internalId,
    reprImage: {
      src: representativeImageUrl,
      file: null
    }
  };

  const navigation = JSON.parse(organization.configuration).navigation;

  const submitHandler = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    UpdateProductMutation(
      id,
      values.productTitle,
      values.productCode,
      values.internalId,
      values.productDescription,
      values.reprImage.file, // file to upload
      values.reprImageNull,
      (response, errors) => {
        setSubmitting(false);
        if (errors && errors[0].fields) {
          setErrors(errors[0].fields);
        }
        if (response && response.updatedProduct) {
          setEditing(false);
        }
      }
    );
  };

  const validationSchema = yupSchemas.shape({
    reprImage: yupSchemas.shape({
      file: yupSchemas.thumbnailFileSize
    }),
    productTitle: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(100, 'Please enter no more than 100 characters.')
      .required('Required'),
    internalId: Yup.string().max(100, 'Please enter no more than 100 characters.'),
    productCode: Yup.string().max(30, 'Please enter no more than 30 characters.'),
    productDescription: Yup.string().max(5000, 'Please enter no more than 5000 characters.')
  });

  const productCodeLabel = `${navigation.productTitle.singular} Code`;
  const productTitleLabel = `${navigation.productTitle.singular} Name`;
  const internalIdLabel = `${navigation.productTitle.singular} Internal ID`;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values, resetForm }) => (
        <Form>
          <Grid>
            <Grid container spacing={3}>
              <Grid md={6} item>
                <DetailsSection noBorder title="Details">
                  <Grid container spacing={3}>
                    {[
                      {
                        name: 'productCode',
                        label: productCodeLabel,
                        fieldProps: {
                          placeholder: productCodeLabel
                        }
                      },
                      {
                        name: 'productTitle',
                        label: productTitleLabel,
                        fieldProps: {
                          placeholder: productTitleLabel
                        }
                      },
                      {
                        name: 'internalId',
                        label: internalIdLabel,
                        fieldProps: {
                          placeholder: internalIdLabel
                        }
                      }
                    ].map(field => (
                      <EditableField
                        key={field.name}
                        editing={isEditing}
                        formikValues={values}
                        gridItemProps={{
                          xs: 12
                        }}
                        deta
                        {...field}
                      />
                    ))}
                  </Grid>
                </DetailsSection>
                <DetailsSection noBorder title="Description">
                  <EditableField
                    name="productDescription"
                    label="Description"
                    editing={isEditing}
                    formikValues={values}
                    renderDetail={value => <Typography>{value}</Typography>}
                    gridItemProps={{ xs: 12 }}
                    fieldProps={{
                      className: classes.marginTop,
                      placeholder: `${navigation.productTitle.singular} Description`,
                      multiline: true,
                      fullWidth: true,
                      rows: '10'
                    }}
                  />
                </DetailsSection>
              </Grid>
              <Grid md={6} item>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <div className={clsx(classes.containerJustifyFlexEnd, classes.alignFlexStart)}>
                      {computePermissionRole(
                        ALLOWED_ACTIONS.PRODUCT_EDIT_DETAILS,
                        allowedActions
                      ) && (
                        <EditToggle
                          isEditing={isEditing}
                          isSubmitting={isSubmitting}
                          editProps={{
                            id: 'editProductDetails',
                            onClick: toggleEditHandler
                          }}
                          saveProps={{
                            id: 'saveProductDetails'
                          }}
                          cancelProps={{
                            id: 'cancelProductDetails',
                            onClick: () => {
                              resetForm();
                              toggleEditHandler();
                            }
                          }}
                        />
                      )}
                    </div>
                  </Grid>
                  <Divider orientation="vertical" flexItem variant="middle" />
                  <Grid item>
                    <DetailsSection noBorder title="Image">
                      {isEditing && (
                        <Typography variant="body2" gutterBottom>
                          Please upload image in 810 x 500 px size
                        </Typography>
                      )}
                      <div>
                        <Field
                          name="reprImage"
                          nameNull="reprImageNull"
                          editing={isEditing}
                          component={FormikEditableImage}
                          variant="thumbnail"
                          imageProps={{
                            alt: 'Title Image',
                            width: '453px',
                            height: '280px',
                            className: classes.productImage
                          }}
                          uploadButtonId="uploadOrganizationProfile"
                          deleteButtonId="deleteOrganizationProfile"
                        />
                      </div>
                    </DetailsSection>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(
  createFragmentContainer(ProductDetailsContent, {
    product: graphql`
      fragment ProductDetailsContent_product on ProductNode {
        id
        code
        internalId
        representativeImageUrl
        title
        description
      }
    `
  })
);

/**
 * @flow
 * @relayHash f6c24739a5ec19c7e2e8eca60e3d6bf4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationsList_solicitations$ref = any;
export type SolicitationsListRefetchQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
  subject?: ?string,
  staffCoordinator?: ?string,
  organization?: ?string,
  dateReceivedFrom?: ?any,
  dateReceivedTo?: ?any,
  replyByFrom?: ?any,
  replyByTo?: ?any,
  state?: ?string,
|};
export type SolicitationsListRefetchQueryResponse = {|
  +$fragmentRefs: SolicitationsList_solicitations$ref
|};
export type SolicitationsListRefetchQuery = {|
  variables: SolicitationsListRefetchQueryVariables,
  response: SolicitationsListRefetchQueryResponse,
|};
*/


/*
query SolicitationsListRefetchQuery(
  $first: Int
  $orderBy: String
  $after: String
  $subject: String
  $staffCoordinator: ID
  $organization: String
  $dateReceivedFrom: Date
  $dateReceivedTo: Date
  $replyByFrom: Date
  $replyByTo: Date
  $state: String
) {
  ...SolicitationsList_solicitations_hkyVx
}

fragment SolicitationsList_solicitations_hkyVx on Query {
  allSolicitations(first: $first, orderBy: $orderBy, after: $after, subject: $subject, staffCoordinator: $staffCoordinator, organization: $organization, dateReceivedFrom: $dateReceivedFrom, dateReceivedTo: $dateReceivedTo, replyByFrom: $replyByFrom, replyByTo: $replyByTo, state: $state) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        solicitationId
        subject
        replyByDate
        dateSent
        staffCoordinator {
          fullName
          representativeImageUrl
          organization {
            name
            representativeImageUrl
            id
          }
          id
        }
        state
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "subject",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "staffCoordinator",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "organization",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dateReceivedFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dateReceivedTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "replyByFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "replyByTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "state",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "dateReceivedFrom",
    "variableName": "dateReceivedFrom"
  },
  {
    "kind": "Variable",
    "name": "dateReceivedTo",
    "variableName": "dateReceivedTo"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "organization",
    "variableName": "organization"
  },
  {
    "kind": "Variable",
    "name": "replyByFrom",
    "variableName": "replyByFrom"
  },
  {
    "kind": "Variable",
    "name": "replyByTo",
    "variableName": "replyByTo"
  },
  {
    "kind": "Variable",
    "name": "staffCoordinator",
    "variableName": "staffCoordinator"
  },
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "state"
  },
  {
    "kind": "Variable",
    "name": "subject",
    "variableName": "subject"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationsListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "SolicitationsList_solicitations",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationsListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allSolicitations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SolicitationNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SolicitationNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "solicitationId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "subject",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "replyByDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dateSent",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "staffCoordinator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "organization",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrgNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "state",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationsListRefetchQuery",
    "id": null,
    "text": "query SolicitationsListRefetchQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n  $subject: String\n  $staffCoordinator: ID\n  $organization: String\n  $dateReceivedFrom: Date\n  $dateReceivedTo: Date\n  $replyByFrom: Date\n  $replyByTo: Date\n  $state: String\n) {\n  ...SolicitationsList_solicitations_hkyVx\n}\n\nfragment SolicitationsList_solicitations_hkyVx on Query {\n  allSolicitations(first: $first, orderBy: $orderBy, after: $after, subject: $subject, staffCoordinator: $staffCoordinator, organization: $organization, dateReceivedFrom: $dateReceivedFrom, dateReceivedTo: $dateReceivedTo, replyByFrom: $replyByFrom, replyByTo: $replyByTo, state: $state) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        solicitationId\n        subject\n        replyByDate\n        dateSent\n        staffCoordinator {\n          fullName\n          representativeImageUrl\n          organization {\n            name\n            representativeImageUrl\n            id\n          }\n          id\n        }\n        state\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a47a9894f9299bd877fec0f7c305cc36';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PaymentRequestStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentRequestsList_job$ref: FragmentReference;
declare export opaque type PaymentRequestsList_job$fragmentType: PaymentRequestsList_job$ref;
export type PaymentRequestsList_job = {|
  +id: string,
  +totalValue: number,
  +currencyCode: ?string,
  +paymentRequests: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +created: any,
        +requestId: string,
        +paymentAmount: number,
        +status: PaymentRequestStatus,
      |}
    |}>
  |},
  +$refType: PaymentRequestsList_job$ref,
|};
export type PaymentRequestsList_job$data = PaymentRequestsList_job;
export type PaymentRequestsList_job$key = {
  +$data?: PaymentRequestsList_job$data,
  +$fragmentRefs: PaymentRequestsList_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PaymentRequestsList_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currencyCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "paymentRequests",
      "storageKey": "paymentRequests(orderBy:\"-created\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-created"
        }
      ],
      "concreteType": "PaymentRequestNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PaymentRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "PaymentRequestNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "created",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "requestId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "paymentAmount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f368059e9274a4746a9eeb06805d0e13';
module.exports = node;

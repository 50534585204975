/**
 * @flow
 * @relayHash 16d5910488b9f418924b26a466ace292
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RegionalUICurrencyRatesQueryVariables = {||};
export type RegionalUICurrencyRatesQueryResponse = {|
  +currencyRates: ?$ReadOnlyArray<?{|
    +currencyCode: ?string,
    +rateDate: ?any,
    +rate: ?any,
  |}>
|};
export type RegionalUICurrencyRatesQuery = {|
  variables: RegionalUICurrencyRatesQueryVariables,
  response: RegionalUICurrencyRatesQueryResponse,
|};
*/

/*
query RegionalUICurrencyRatesQuery {
  currencyRates {
    currencyCode
    rateDate
    rate
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
    {
      kind: 'LinkedField',
      alias: null,
      name: 'currencyRates',
      storageKey: null,
      args: null,
      concreteType: 'ExchangeRateNode',
      plural: true,
      selections: [
        {
          kind: 'ScalarField',
          alias: null,
          name: 'currencyCode',
          args: null,
          storageKey: null
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'rateDate',
          args: null,
          storageKey: null
        },
        {
          kind: 'ScalarField',
          alias: null,
          name: 'rate',
          args: null,
          storageKey: null
        }
      ]
    }
  ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'RegionalUICurrencyRatesQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: [],
      selections: (v0 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'RegionalUICurrencyRatesQuery',
      argumentDefinitions: [],
      selections: (v0 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'RegionalUICurrencyRatesQuery',
      id: null,
      text:
        'query RegionalUICurrencyRatesQuery {\n  currencyRates {\n    currencyCode\n    rateDate\n    rate\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '59bf8ba14c86181fa0bfc8c09cdc2839';
module.exports = node;

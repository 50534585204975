import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation CancelJobMutation($input: CancelJobInput!) {
    cancelJob(input: $input) {
      isCanceled
      job {
        status
        deliverables {
          edges {
            node {
              state
            }
          }
        }
      }
    }
  }
`;

const CancelJobMutation = (id, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.cancelJob);
    },
    onError: err => console.error(err)
  });
};

export default CancelJobMutation;

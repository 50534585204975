import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../shared/styles/common/colors';

const useStyles = makeStyles(theme => ({
  subtitle: {
    display: 'flex',
    color: props => props.colorProp || colors.darkBlueGrey,
    fontSize: '12px',
    marginLeft: props => props.marginLeft || null
  },
  icon: {
    marginRight: '10px',
    '& svg': {
      '& path': {
        fill: props => props.colorProp || null
      }
    }
  }
}));

const BottomSubtitle = props => {
  const { children, icon, colorProp, marginLeft } = props;
  const classes = useStyles({ colorProp, marginLeft });
  return (
    <span className={classes.subtitle}>
      {icon ? <span className={classes.icon}>{icon}</span> : null}
      {children}
    </span>
  );
};

export default BottomSubtitle;

import React from 'react';
import { getURLPrefix } from '../services/utilities';
import { ORGANIZATION_APP } from '../services/constants';

const withAppType = WrappedComponent => {
  const ComponentWithAppType = props => {
    return <WrappedComponent {...props} isOrganization={getURLPrefix() === ORGANIZATION_APP} />;
  };

  return ComponentWithAppType;
};

export default withAppType;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation RevertClosedSolicitationMutation($input: RevertClosedSolicitationInput!) {
    revertClosedSolicitation(input: $input) {
      isUpdated
      solicitation {
        id
        state
      }
    }
  }
`;

const RevertClosedSolicitationMutation = (id, callback) => {
  const revertClosedSolicitationVariables = {
    input: { id }
  };

  const config = {
    mutation,
    variables: revertClosedSolicitationVariables,
    onCompleted: response => {
      callback(response.revertClosedSolicitation);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default RevertClosedSolicitationMutation;

RevertClosedSolicitationMutation.propTypes = {
  id: PropTypes.string.isRequired
};

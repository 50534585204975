import { makeStyles } from '@material-ui/core/styles';

const useQuickViewStyles = makeStyles(() => ({
  quickViewHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '22px',

    '& .MuiAvatar-root': {
      marginRight: '15px'
    },

    '& h1': {
      marginRight: '24px'
    }
  },
  tablePadding: {
    paddingBottom: '30px'
  }
}));

export default useQuickViewStyles;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import formStyles from '../../../../../styles/common/formStyles';
import { TextField } from '@material-ui/core';

const InputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = props => {
  const {
    children,
    innerProps,
    innerRef,
    isDisabled,
    selectProps: { classes, TextFieldProps, visibleToFreelancer }
  } = props;

  const extraClasses = formStyles({ visibleToFreelancer });

  return (
    <TextField
      variant="outlined"
      classes={{
        root: clsx(classes.innerTextField, extraClasses.textFieldRoot, {
          [classes.disabled]: isDisabled
        })
      }}
      InputProps={{
        notched: false,
        inputComponent: InputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
};

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]).isRequired,
  selectProps: PropTypes.object.isRequired
};

export default Control;

import ProductListPage from './components/products/ProductListPage';
import ProductDetailsPage from './components/products/ProductDetailsPage';
import ReleaseListPage from './components/releases/ReleaseListPage';
import ReleaseDetailsPage from './components/releases/ReleaseDetailsPage';
import DeliverableListPage from './components/deliverables/DeliverableListPage';
import DeliverableDetailsPage from './components/deliverables/DeliverableDetailsPage';
import JobsListPage from './components/jobs/JobsListPage';
import JobDetailsPage from './components/jobs/JobDetailsPage';
import SolicitationsPage from './components/solicitations/SolicitationsPage';
import SolicitationDetailsPage from './components/solicitations/SolicitationDetailsPage';
import ContractorsPage from './components/contractors/ContractorsPage';
import ContractorDetailsPage from './components/contractors/ContractorDetailsPage';

import { ReactComponent as ProductsIcon } from '../shared/images/products.svg';
import { ReactComponent as ReleasesIcon } from '../shared/images/releases.svg';
import { ReactComponent as DeliverablesIcon } from '../shared/images/deliverables.svg';
import { ReactComponent as SolicitationsIcon } from '../shared/images/solicitations.svg';
import { ReactComponent as JobsIcon } from '../shared/images/jobs.svg';
import { ReactComponent as ContractorsIcon } from '../shared/images/contractors.svg';

import CategoryDetailsPage from './components/admin/customization/CategoryDetailsPage';
import CustomizationPage from './components/admin/customization/CustomizationPage';
import StaffDetailsPage from './components/admin/staffManagement/StaffTab/StaffDetailsPage';
import RolesDetailsPage from './components/admin/staffManagement/RolesTab/RolesDetailsPage';
import StaffManagementPage from './components/admin/staffManagement/StaffManagementPage';
import BillingPage from './components/admin/billing/BillingPage';
import ContractTemplateDetailsPage from './components/admin/contractTemplates/ContractTemplateDetailsPage';
import ContractTemplatesPage from './components/admin/contractTemplates/ContractTemplatesPage';
import OrganizationProfilePage from './components/admin/OrganizationProfilePage';
import StaffUserPage from './components/profile/StaffUserPage';
import StorageManagement from './components/admin/StorageManagement';

import { ReactComponent as CustomizationIcon } from '../shared/images/customization.svg';
import { ReactComponent as StaffManagementIcon } from '../shared/images/staff.svg';
import { ReactComponent as BillingIcon } from '../shared/images/billing.svg';
import { ReactComponent as ContractsIcon } from '../shared/images/contracts.svg';
import { ReactComponent as StorageIcon } from '../shared/images/storage.svg';
import { ReactComponent as OrganizationProfileIcon } from '../shared/images/organization_profile.svg';

import { ALLOWED_ACTIONS } from '../shared/constants';

import { IC_TARGETS } from '../shared/services/intercomService';

export const ORGANIZATION_ROUTES = [
  {
    component: ProductDetailsPage,
    path: '/products/:id'
  },
  {
    component: ProductListPage,
    path: '/products',
    linkProps: { label: 'Titles', icon: ProductsIcon, intercomDataTarget: IC_TARGETS.sbProducts }
  },
  {
    component: ReleaseDetailsPage,
    path: '/releases/:id'
  },
  {
    component: ReleaseListPage,
    path: '/releases',
    linkProps: { label: 'Releases', icon: ReleasesIcon, intercomDataTarget: IC_TARGETS.sbReleases }
  },
  {
    component: DeliverableDetailsPage,
    path: '/deliverables/:id'
  },
  {
    component: DeliverableListPage,
    path: '/deliverables',
    linkProps: {
      label: 'Deliverables',
      icon: DeliverablesIcon,
      intercomDataTarget: IC_TARGETS.sbDeliverables
    }
  },
  {
    component: SolicitationDetailsPage,
    path: '/solicitations/:id'
  },
  {
    component: SolicitationsPage,
    path: '/solicitations',
    linkProps: {
      label: 'JobOpps',
      icon: SolicitationsIcon,
      intercomDataTarget: IC_TARGETS.sbSolicitations
    }
  },
  {
    component: JobDetailsPage,
    path: '/jobs/:id'
  },
  {
    component: JobsListPage,
    path: '/jobs',
    linkProps: { label: 'Jobs', icon: JobsIcon, intercomDataTarget: IC_TARGETS.sbJobs }
  },
  {
    component: ContractorDetailsPage,
    path: '/contractors/contractors/:id'
  },
  {
    component: ContractorsPage,
    path: '/contractors',
    linkProps: {
      path: '/contractors/contractors',
      label: 'Contractors',
      icon: ContractorsIcon,
      intercomDataTarget: IC_TARGETS.sbContractors
    }
  },
  {
    component: StaffUserPage,
    path: '/profile'
  }
];

export const ORGANIZATION_LINKS = ORGANIZATION_ROUTES.filter(route =>
  route.hasOwnProperty('linkProps')
);

export const ADMIN_ROUTES = [
  {
    component: CategoryDetailsPage,
    path: '/admin/customization/deliverables/:id',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_CUSTOMIZATION
  },
  {
    component: CustomizationPage,
    path: '/admin/customization',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_CUSTOMIZATION,
    linkProps: {
      label: 'Customization',
      icon: CustomizationIcon,
      intercomDataTarget: IC_TARGETS.Customization
    }
  },
  {
    component: StaffDetailsPage,
    path: '/admin/staff-management/users/:id',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STAFF_ROLE_AND_PERMISSIONS
  },
  {
    component: RolesDetailsPage,
    path: '/admin/staff-management/roles/:id',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STAFF_ROLE_AND_PERMISSIONS
  },
  {
    component: StaffManagementPage,
    path: '/admin/staff-management',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STAFF_ROLE_AND_PERMISSIONS,
    linkProps: {
      path: '/admin/staff-management/users',
      label: 'User Management',
      icon: StaffManagementIcon,
      intercomDataTarget: IC_TARGETS.sbStaffManagement
    }
  },
  {
    component: BillingPage,
    path: '/admin/billing',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_BILLING_INFO,
    linkProps: { label: 'Billing', icon: BillingIcon, intercomDataTarget: IC_TARGETS.sbBilling }
  },
  {
    component: ContractTemplateDetailsPage,
    path: '/admin/contracts/:id',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_CONTRACTS_TEMPLATE
  },
  {
    component: ContractTemplatesPage,
    path: '/admin/contracts',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_CONTRACTS_TEMPLATE,
    linkProps: {
      label: 'Contracts',
      icon: ContractsIcon,
      intercomDataTarget: IC_TARGETS.sbContracts
    }
  },
  {
    component: StorageManagement,
    path: '/admin/storage-management',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STORAGE,
    linkProps: {
      label: 'Storage',
      icon: StorageIcon,
      intercomDataTarget: IC_TARGETS.sbStorageManagement
    }
  },
  {
    component: OrganizationProfilePage,
    path: '/admin/organization-profile',
    action: ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_ORGANIZATION_PROFILE,
    linkProps: {
      label: 'Organization',
      icon: OrganizationProfileIcon,
      intercomDataTarget: IC_TARGETS.sbOrganizationProfile
    }
  }
];

export const ADMIN_LINKS = ADMIN_ROUTES.filter(route => route.hasOwnProperty('linkProps'));

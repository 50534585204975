import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';

const CircleColorButton = styled(({ active, ...props }) => <ButtonBase {...props} />)(
  ({ theme, size, color, active }) => ({
    position: 'relative',
    width: size,
    height: size,
    borderRadius: '50%',
    backgroundColor: color,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: color === 'white' || color === '#ffffff' ? 'black' : 'transparent',
    '&:disabled': {
      opacity: 0.3
    },
    '&::after': active && {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -3,
      left: -3,
      borderRadius: '50%',
      backgroundColor: theme.palette.colors.filterButtonGrey,
      width: size + 4,
      height: size + 4,
      zIndex: -1
    }
  })
);


const ColorButton = ({ color, onClick, size, active, ...otherProps }) => {
  const onClickHandler = event => onClick(event, color);
  return (
    <CircleColorButton
      onClick={onClickHandler}
      color={color}
      size={size}
      active={active}
      {...otherProps}
    />
  );
};

ColorButton.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  size: PropTypes.number
};

ColorButton.defaultProps = {
  size: 20,
  active: false
};

export default ColorButton;

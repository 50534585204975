/**
 * @flow
 * @relayHash 63bf0ead77a9d65560979b5b4c086f5d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProductDetails_product$ref = any;
export type ProductDetailsPageQueryVariables = {|
  id: string
|};
export type ProductDetailsPageQueryResponse = {|
  +product: ?{|
    +$fragmentRefs: ProductDetails_product$ref
  |}
|};
export type ProductDetailsPageQuery = {|
  variables: ProductDetailsPageQueryVariables,
  response: ProductDetailsPageQueryResponse,
|};
*/


/*
query ProductDetailsPageQuery(
  $id: ID!
) {
  product(id: $id) {
    ...ProductDetails_product
    id
  }
}

fragment ProductDetails_product on ProductNode {
  title
  isActive
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProductDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "product",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ProductDetails_product",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "product",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isActive",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProductDetailsPageQuery",
    "id": null,
    "text": "query ProductDetailsPageQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    ...ProductDetails_product\n    id\n  }\n}\n\nfragment ProductDetails_product on ProductNode {\n  title\n  isActive\n  id\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b70725fdcc125eaf0a929ca89d782aa';
module.exports = node;

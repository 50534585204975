import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import get from 'lodash/get';

import FilterCheckbox from '../../../shared/components/filters/FilterCheckbox';
import FilterSelect from '../../../shared/components/filters/FilterSelect';
import DateField from '../../../shared/components/form/fields/DateField';
import SearchableStaffDropdown from '../shared/dropdowns/SearchableStaffDropdown';
import FilterDebounceInput from '../../../shared/components/filters/FilterDebounceInput';
import SearchableTagsDropdown from '../form/SearchableTagsDropdown';
import SearchableReleaseDropdown from '../shared/dropdowns/SearchableReleaseDropdown';
import SearchableProductDropdown from '../releases/SearchableProductDropdown';
import { useFilterStyles } from '../../../shared/styles/common/filterStyles';
import { CategorySelectWithTypes } from '../shared/dropdowns/CategorySelectWithTypes';
import { DELIVERABLE_STATES } from '../../../shared/constants';
import SearchableStaffAndContractorDropdown from '../shared/dropdowns/SearchableStaffAndContractorDropdown';

const DeliverablesListFilters = props => {
  const { filterValues, onFilterChange, stateOptions } = props;
  const { includeCanceled, includeClosed, includeInactive, state } = filterValues;
  const classes = useFilterStyles();

  const [mappedStateOptions, setMappedStateOptions] = useState([]);

  const mapStateOptions = options =>
    options.map(option => ({
      value: option.name,
      name: option.description
    }));

  useEffect(() => {
    if (
      (state === DELIVERABLE_STATES.inactive && !includeInactive) ||
      (state === DELIVERABLE_STATES.closed && !includeClosed) ||
      (state === DELIVERABLE_STATES.canceled && !includeCanceled)
    ) {
      onFilterChange('state', '');
    }

    const newStateOptions = stateOptions.filter(option => {
      if (option.name === DELIVERABLE_STATES.inactive) {
        return includeInactive;
      }
      if (option.name === DELIVERABLE_STATES.closed) {
        return includeClosed;
      }
      if (option.name === DELIVERABLE_STATES.canceled) {
        return includeCanceled;
      }
      return true;
    });
    setMappedStateOptions(mapStateOptions(newStateOptions));
  }, [includeCanceled, includeClosed, includeInactive, stateOptions, state, onFilterChange]);

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          name="title"
          value={filterValues['title']}
          label="Deliverable Name"
          onChange={onFilterChange}
          fullWidth
          placeholder="Enter Deliverable Name..."
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableProductDropdown
          name="productId"
          onChange={value => onFilterChange('productId', value)}
          value={filterValues['productId']}
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableReleaseDropdown
          name="releaseId"
          onChange={value => onFilterChange('releaseId', value)}
          value={filterValues['releaseId']}
          releaseOnly
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          id="filterByDeliverableState"
          name="state"
          onChange={onFilterChange}
          value={filterValues['state']}
          label="Deliverable State"
          options={mappedStateOptions}
        />
      </Grid>
      <Grid item xs={2}>
        <CategorySelectWithTypes
          name="category"
          label="Category"
          value={filterValues['category']}
          onChange={(field, selectedOption) => {
            onFilterChange('categoryType', null);
            onFilterChange(
              field,
              !selectedOption || selectedOption.value === '' ? null : selectedOption
            );
          }}
          formatOptions={options => [
            {
              value: '',
              label: 'Show All'
            },
            ...options.map(category => ({
              value: category.node.id,
              label: category.node.name,
              types: category.node.categoryTypes
            }))
          ]}
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          name="categoryType"
          label="Type"
          options={
            get(filterValues, 'category.types')
              ? filterValues.category.types.edges.map(edge => ({
                  value: edge.node.id,
                  name: edge.node.name
                }))
              : []
          }
          value={filterValues.categoryType || ''}
          emptyValueLabel={
            !get(filterValues, 'category.types') ? 'Select a Category first...' : undefined
          }
          onChange={onFilterChange}
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableStaffDropdown
          showCurrentUser
          label="Coordinator"
          id="filterByStaffInDeliverables"
          name="staffCoordinator"
          value={filterValues['staffCoordinator']}
          onChange={value => onFilterChange('staffCoordinator', value)}
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableStaffAndContractorDropdown
          id="filterByStaffOrContractor"
          name="assignedToStaffOrContractor"
          value={filterValues['assignedToStaffOrContractor']}
          onChange={value => onFilterChange('assignedToStaffOrContractor', value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={3}>
          <Grid item xs={6} classes={{ item: classes.dueDateFrom }}>
            <DateField
              id="due_date_from"
              name="dueDateFrom"
              label="Due Date"
              value={filterValues['dueDateFrom']}
              placeholder="From..."
              onChange={onFilterChange}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              id="due-date-to"
              name="dueDateTo"
              label="Due Date"
              value={filterValues['dueDateTo']}
              placeholder="To..."
              onChange={onFilterChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={2}>
          <SearchableTagsDropdown
            name="tags"
            label="Tags"
            onChange={onFilterChange}
            value={filterValues['tags'] || { options: [] }}
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <FilterCheckbox
          id="filerIncludeInactive"
          name="includeInactive"
          onChange={onFilterChange}
          value={filterValues['includeInactive']}
          label="Include Inactive Deliverables"
        />
      </Grid>
      <Grid item xs={2}>
        <FilterCheckbox
          id="filterincludeClosed"
          name="includeClosed"
          onChange={onFilterChange}
          value={filterValues['includeClosed']}
          label="Include Closed Deliverables"
        />
      </Grid>
      <Grid item xs={2}>
        <FilterCheckbox
          id="filterIncludeCanceled"
          name="includeCanceled"
          onChange={onFilterChange}
          value={filterValues['includeCanceled']}
          label="Include Canceled Deliverables"
        />
      </Grid>
      <Grid item xs={2}>
        <FilterCheckbox
          id="onlyFollowed"
          name="onlyFollowed"
          onChange={onFilterChange}
          value={filterValues['onlyFollowed']}
          label="Following"
        />
      </Grid>
    </Grid>
  );
};

export default DeliverablesListFilters;

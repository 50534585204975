import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import get from 'lodash/get';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import SearchableDropdown from '../../../../shared/components/form/SearchableDropdown';

const staffQuery = graphql`
  query SearchableStaffDropdownQuery(
    $staffName: String
    $first: Int
    $orderBy: String
    $includeInactive: Boolean
  ) {
    staffByOrg(
      staffName: $staffName
      orderBy: $orderBy
      first: $first
      includeInactive: $includeInactive
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

const SearchableStaffDropdown = props => {
  const {
    onChange,
    noLabel,
    label,
    userContext: {
      firstName,
      lastName,
      orgStaff: { id: orgStaffId }
    },
    showCurrentUser,
    value,
    ...otherProps
  } = props;

  // NOTE:
  // hackish way to handle dropdown with chips and without
  // ideally all SearchableDropdown components should be refactored to accept single value type
  const valueLength = get(value, 'length') || get(value, 'options.length', 0);

  const getFetchVariables = inputValue => {
    return {
      staffName: inputValue,
      first: 10 + (otherProps.isMulti ? valueLength : 0),
      orderBy: 'lastName',
      includeInactive: false
    };
  };

  const formatOptions = staffList => {
    if (!staffList || !staffList.staffByOrg) {
      return [];
    }

    if (showCurrentUser) {
      const options = [
        { value: orgStaffId, label: `${firstName} ${lastName} (Me)` },
        ...(props.id === 'filterByStaffInDeliverables'
          ? [{ value: 'unassigned', label: 'Unassigned' }]
          : []),
        ...staffList.staffByOrg.edges
          .filter(staff => staff.node.id !== orgStaffId)
          .map(staff => {
            return {
              value: staff.node.id,
              label: staff.node.fullName
            };
          })
      ];

      return options;
    }

    return staffList.staffByOrg.edges.map(staff => {
      return {
        value: staff.node.id,
        label: staff.node.fullName
      };
    });
  };

  return (
    <SearchableDropdown
      resultKey="staffByOrg"
      keyToReload={`items-count-${valueLength}`}
      query={staffQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      label={noLabel ? '' : label || 'Assigned Staff'}
      placeholder="Select Staff..."
      value={value}
      {...otherProps}
    />
  );
};

export default withUserContext(SearchableStaffDropdown);

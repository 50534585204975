import React from 'react';
import Grid from '@material-ui/core/Grid';

import FilterDebounceInput from '../../../../../shared/components/filters/FilterDebounceInput';
import FilterSelect from '../../../../../shared/components/filters/FilterSelect';
import FilterCheckbox from '../../../../../shared/components/filters/FilterCheckbox';
import withRoleTypes from '../roleTypes/withRoleTypes';

const RolesFilterSelect = withRoleTypes(({ roleTypes, ...selectProps }) => (
  <FilterSelect
    {...selectProps}
    options={roleTypes.map(({ id, name }) => ({ value: id, name }))}
    emptyValueLabel={roleTypes.length === 0 ? 'Loading...' : undefined}
  />
));

const StaffListFilters = props => {
  const { filterValues, onFilterChange } = props;
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        <FilterDebounceInput
          id="filterByStaffNameStaffList"
          name="staffName"
          value={filterValues['staffName']}
          label="Staff name"
          onChange={onFilterChange}
          fullWidth
          className="filterInputMarginZero"
        />
      </Grid>
      <Grid item xs={4}>
        <RolesFilterSelect
          id="filterByRoleStaffList"
          name="role"
          onChange={onFilterChange}
          value={filterValues['role']}
          label="Role"
        />
      </Grid>
      <Grid item xs={4}>
        <FilterCheckbox
          name="includeInactive"
          id="filterIncludeInactiveUsers"
          value={filterValues['includeInactive']}
          label="Include Inactive Users"
          onChange={onFilterChange}
        />
      </Grid>
    </Grid>
  );
};

export default StaffListFilters;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../../../shared/relay/QueryRenderer';
import InvoiceQuickViewContent from './InvoiceQuickViewContent';

const InvoiceQuickView = ({ invoiceId, ...props }) => (
  <QueryRenderer
    query={graphql`
      query InvoiceQuickViewQuery($id: ID!) {
        invoice(id: $id) {
          ...InvoiceQuickViewContent_invoice
        }
      }
    `}
    variables={{ id: invoiceId }}
    render={relayProps => (
      <InvoiceQuickViewContent invoice={relayProps.invoice} {...relayProps} {...props} />
    )}
  />
);

export default InvoiceQuickView;

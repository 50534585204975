/**
 * @flow
 * @relayHash 5b5a038364356adc6e17926ab7b27a8f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeactivateReleaseInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeactivateReleaseMutationVariables = {|
  input: DeactivateReleaseInput
|};
export type DeactivateReleaseMutationResponse = {|
  +deactivateRelease: ?{|
    +updatedRelease: ?{|
      +id: string,
      +isActive: boolean,
    |}
  |}
|};
export type DeactivateReleaseMutation = {|
  variables: DeactivateReleaseMutationVariables,
  response: DeactivateReleaseMutationResponse,
|};
*/


/*
mutation DeactivateReleaseMutation(
  $input: DeactivateReleaseInput!
) {
  deactivateRelease(input: $input) {
    updatedRelease {
      id
      isActive
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeactivateReleaseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deactivateRelease",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeactivateReleasePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedRelease",
        "storageKey": null,
        "args": null,
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isActive",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeactivateReleaseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeactivateReleaseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeactivateReleaseMutation",
    "id": null,
    "text": "mutation DeactivateReleaseMutation(\n  $input: DeactivateReleaseInput!\n) {\n  deactivateRelease(input: $input) {\n    updatedRelease {\n      id\n      isActive\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c266e2be5eabceedf454c2c7c0ea5899';
module.exports = node;

import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Typography, Box, Divider } from '@material-ui/core';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import { successToast, errorToast } from '../../../../shared/toasts';
import { JOB_INVOICE_STATES } from '../../../../shared/constants';
import Detail from '../../../../shared/components/UI/Detail';
import Date from '../../../../shared/components/common/Date';
import AvatarWithName from '../../../../shared/components/UI/AvatarWithName';
import StatusIndicator from '../../../../shared/components/UI/StatusIndicator';
import { formatAmount } from '../../../../shared/utils/formatters';
import { ActionsContainer } from '../../../../shared/components/common/QuickViewDrawer';
import colors from '../../../../shared/styles/common/colors';
import FullscreenModal from '../../../../shared/components/fullscreenModal/FullscreenModal';
import ContractPreview from '../../../../shared/components/job/ContractPreview';
import RemindAboutInvoiceMutation from '../mutations/RemindAboutInvoiceMutation';
import ToggleInvoicePaymentReceivedMutation from '../mutations/ToggleInvoicePaymentReceivedMutation';

const InvoiceQuickView = ({ invoice, statusTypes }) => {
  const [isSendingPaymentReminder, setIsSendingPaymentReminder] = useState(false);
  const [isTogglingPaymentRecieved, setIsTogglingPaymentRecieved] = useState(false);
  const [isInvoiceVisible, setIsInvoiceVisible] = useState(false);

  const canSendPaymentReminder = [
    JOB_INVOICE_STATES.new,
    JOB_INVOICE_STATES.paymentRequested
  ].includes(invoice.status);
  const canMarkPaymentRecieved = invoice.status !== JOB_INVOICE_STATES.declined;

  const handleSendPaymentReminder = () => {
    setIsSendingPaymentReminder(true);
    RemindAboutInvoiceMutation({ id: invoice.id })
      .then(() => successToast('Request was sent!'))
      .catch(() => errorToast('Request failed to send.'))
      .finally(() => setIsSendingPaymentReminder(false));
  };

  const handleTogglePaymentRecieved = () => {
    setIsTogglingPaymentRecieved(true);
    ToggleInvoicePaymentReceivedMutation({
      id: invoice.id,
      received: invoice.status === JOB_INVOICE_STATES.paymentReceived ? false : true
    }).finally(() => setIsTogglingPaymentRecieved(false));
  };

  return (
    <>
      <FullscreenModal
        open={isInvoiceVisible}
        handleBack={() => setIsInvoiceVisible(false)}
        actions={[
          {
            label: 'Download',
            handler: () => window.open(invoice.invoiceFileUrl)
          }
        ]}
      >
        <ContractPreview
          contractPreviewPdf={invoice.invoiceFileUrl}
          loading="Loading invoice..."
          error="Failed to load the invoice"
        />
      </FullscreenModal>

      <Typography variant="h3" style={{ marginBottom: '4px' }}>
        {invoice.identifier}
      </Typography>
      <StatusIndicator
        statusCode={invoice.status}
        variant="jobInvoice"
        statusTypes={statusTypes.enumValues}
        noPadding
      />

      <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
        <Detail
          name="Organization"
          value={invoice.job.staffCoordinator.organization.name}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Detail
          name="Date Submitted"
          value={<Date date={invoice.created} />}
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail
          name="Job Name"
          value={invoice.job.name}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Detail
          name="Date Approved"
          value={
            invoice.status !== JOB_INVOICE_STATES.declined ? (
              <Date date={invoice.verificationDate} />
            ) : null
          }
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail
          name="Contact"
          renderValue={() => (
            <AvatarWithName
              name={invoice.job.staffCoordinator.fullName}
              size={34}
              avatarProps={{
                src: invoice.job.staffCoordinator.representativeImageUrl
              }}
            />
          )}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Detail
          name="Invoice Amount"
          value={formatAmount(invoice.amount, invoice.job.currencyCode)}
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>

      {invoice.status === JOB_INVOICE_STATES.declined && (
        <>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Declined by"
              renderValue={() => (
                <AvatarWithName
                  name={invoice.verificationBy.fullName}
                  size={34}
                  avatarProps={{
                    src: invoice.verificationBy.representativeImageUrl
                  }}
                />
              )}
              flexDirection="column"
              alignItems="flex-start"
              nameColor={colors.red}
            />
            <Detail
              name="Date Declined"
              value={<Date date={invoice.verificationDate} />}
              flexDirection="column"
              alignItems="flex-end"
              nameColor={colors.red}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Reason"
              value={invoice.verificationMessage}
              flexDirection="column"
              alignItems="flex-start"
              valueContainerProps={{ variant: 'body1' }}
              nameColor={colors.red}
            />
          </Box>
        </>
      )}

      {invoice.markedPaymentSentBy && (
        <>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Marked Payment Sent by"
              renderValue={() => (
                <AvatarWithName
                  name={invoice.markedPaymentSentBy.fullName}
                  size={34}
                  avatarProps={{
                    src: invoice.markedPaymentSentBy.representativeImageUrl
                  }}
                />
              )}
              flexDirection="column"
              alignItems="flex-start"
            />
            <Detail
              name="Date Payment Sent"
              value={<Date date={invoice.markedPaymentSentDate} />}
              flexDirection="column"
              alignItems="flex-end"
            />
          </Box>
          {invoice.markedPaymentSentMessage && (
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Detail
                name="Message"
                value={invoice.markedPaymentSentMessage}
                flexDirection="column"
                alignItems="flex-start"
                valueContainerProps={{ variant: 'body1' }}
              />
            </Box>
          )}
        </>
      )}

      {invoice.message && (
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Detail
            name="Invoice Message"
            value={invoice.message}
            flexDirection="column"
            alignItems="flex-start"
            valueContainerProps={{ variant: 'body1' }}
          />
        </Box>
      )}

      <Box mt={3}>
        <Divider />
      </Box>

      <ActionsContainer>
        <GlobalButton variant="secondary" handleClick={() => setIsInvoiceVisible(true)}>
          View Invoice
        </GlobalButton>
        {canSendPaymentReminder && (
          <GlobalButton
            variant="secondary"
            handleClick={handleSendPaymentReminder}
            loading={isSendingPaymentReminder}
          >
            Send Payment Reminder
          </GlobalButton>
        )}
        {canMarkPaymentRecieved && (
          <GlobalButton
            handleClick={handleTogglePaymentRecieved}
            loading={isTogglingPaymentRecieved}
          >
            {invoice.status === JOB_INVOICE_STATES.paymentReceived
              ? 'Revert Invoice to Unpaid Status'
              : 'Mark Payment Recieved'}
          </GlobalButton>
        )}
      </ActionsContainer>
    </>
  );
};

export default createFragmentContainer(InvoiceQuickView, {
  invoice: graphql`
    fragment InvoiceQuickView_invoice on InvoiceNode {
      id
      identifier
      status
      invoiceFileUrl
      created
      amount
      message
      verificationDate
      verificationMessage
      verificationBy {
        fullName
        representativeImageUrl
      }
      markedPaymentSentDate
      markedPaymentSentMessage
      markedPaymentSentBy {
        fullName
        representativeImageUrl
      }
      job {
        name
        currencyCode
        staffCoordinator {
          fullName
          representativeImageUrl
          organization {
            name
          }
        }
      }
    }
  `,
  statusTypes: graphql`
    fragment InvoiceQuickView_statusTypes on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

/**
 * @flow
 * @relayHash 8968c3848e134b441d6f500f57a23d09
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VideoCanvasInterfaceQueryVariables = {|
  reviewAssetId: string
|};
export type VideoCanvasInterfaceQueryResponse = {|
  +reviewAsset: ?{|
    +id: string,
    +reviewAssetAnnotation: ?{|
      +annotationData: ?any
    |},
  |}
|};
export type VideoCanvasInterfaceQuery = {|
  variables: VideoCanvasInterfaceQueryVariables,
  response: VideoCanvasInterfaceQueryResponse,
|};
*/

/*
query VideoCanvasInterfaceQuery(
  $reviewAssetId: ID!
) {
  reviewAsset(id: $reviewAssetId) {
    id
    reviewAssetAnnotation {
      annotationData
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'reviewAssetId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'reviewAssetId'
      }
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'annotationData',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'VideoCanvasInterfaceQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'reviewAsset',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'ReviewAssetNode',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'reviewAssetAnnotation',
              storageKey: null,
              args: null,
              concreteType: 'DeliverableReviewAnnotationNode',
              plural: false,
              selections: [(v3 /*: any*/)]
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'VideoCanvasInterfaceQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'reviewAsset',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'ReviewAssetNode',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'reviewAssetAnnotation',
              storageKey: null,
              args: null,
              concreteType: 'DeliverableReviewAnnotationNode',
              plural: false,
              selections: [(v3 /*: any*/), (v2 /*: any*/)]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'VideoCanvasInterfaceQuery',
      id: null,
      text:
        'query VideoCanvasInterfaceQuery(\n  $reviewAssetId: ID!\n) {\n  reviewAsset(id: $reviewAssetId) {\n    id\n    reviewAssetAnnotation {\n      annotationData\n      id\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '55eca2500ee978305ae027fb70056c37';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractsList_statusTypes$ref: FragmentReference;
declare export opaque type ContractsList_statusTypes$fragmentType: ContractsList_statusTypes$ref;
export type ContractsList_statusTypes = {|
  +enumValues: ?$ReadOnlyArray<{|
    +name: string,
    +description: ?string,
  |}>,
  +$refType: ContractsList_statusTypes$ref,
|};
export type ContractsList_statusTypes$data = ContractsList_statusTypes;
export type ContractsList_statusTypes$key = {
  +$data?: ContractsList_statusTypes$data,
  +$fragmentRefs: ContractsList_statusTypes$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ContractsList_statusTypes",
  "type": "__Type",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "enumValues",
      "storageKey": null,
      "args": null,
      "concreteType": "__EnumValue",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b89a123564045df739f096c950253fda';
module.exports = node;

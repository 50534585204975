/**
 * @flow
 * @relayHash 27e811c2684423fb546afa303bdd89cc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateContractTemplateInput = {|
  id: string,
  name?: ?string,
  categories?: ?$ReadOnlyArray<?string>,
  ignoreVariablesErrors?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type UpdateContractTemplateMutationVariables = {|
  input: UpdateContractTemplateInput
|};
export type UpdateContractTemplateMutationResponse = {|
  +updateContractTemplate: ?{|
    +contractTemplate: ?{|
      +id: string,
      +name: string,
      +docxFileName: string,
      +docxFileUrl: ?string,
      +samplePdfFileUrl: ?string,
      +categories: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
          |}
        |}>
      |},
    |},
    +errors: ?any,
  |}
|};
export type UpdateContractTemplateMutation = {|
  variables: UpdateContractTemplateMutationVariables,
  response: UpdateContractTemplateMutationResponse,
|};
*/

/*
mutation UpdateContractTemplateMutation(
  $input: UpdateContractTemplateInput!
) {
  updateContractTemplate(input: $input) {
    contractTemplate {
      id
      name
      docxFileName
      docxFileUrl
      samplePdfFileUrl
      categories(orderBy: "name") {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    errors
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'UpdateContractTemplateInput!',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null
    },
    v3 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'updateContractTemplate',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'UpdateContractTemplatePayload',
        plural: false,
        selections: [
          {
            kind: 'LinkedField',
            alias: null,
            name: 'contractTemplate',
            storageKey: null,
            args: null,
            concreteType: 'ContractTemplateNode',
            plural: false,
            selections: [
              (v1 /*: any*/),
              (v2 /*: any*/),
              {
                kind: 'ScalarField',
                alias: null,
                name: 'docxFileName',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'docxFileUrl',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'samplePdfFileUrl',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: null,
                name: 'categories',
                storageKey: 'categories(orderBy:"name")',
                args: [
                  {
                    kind: 'Literal',
                    name: 'orderBy',
                    value: 'name'
                  }
                ],
                concreteType: 'CategoryNodeConnection',
                plural: false,
                selections: [
                  {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'edges',
                    storageKey: null,
                    args: null,
                    concreteType: 'CategoryNodeEdge',
                    plural: true,
                    selections: [
                      {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'node',
                        storageKey: null,
                        args: null,
                        concreteType: 'CategoryNode',
                        plural: false,
                        selections: [(v1 /*: any*/), (v2 /*: any*/)]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'errors',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'UpdateContractTemplateMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'UpdateContractTemplateMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'UpdateContractTemplateMutation',
      id: null,
      text:
        'mutation UpdateContractTemplateMutation(\n  $input: UpdateContractTemplateInput!\n) {\n  updateContractTemplate(input: $input) {\n    contractTemplate {\n      id\n      name\n      docxFileName\n      docxFileUrl\n      samplePdfFileUrl\n      categories(orderBy: "name") {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n    errors\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'cd0cc26f2307ab971f15398450de3ec5';
module.exports = node;

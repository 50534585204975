/**
 * @flow
 * @relayHash 013f3fdb686a414f7662de836c33a74a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeactivateStaffUserInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeactivateStaffUserMutationVariables = {|
  input: DeactivateStaffUserInput
|};
export type DeactivateStaffUserMutationResponse = {|
  +deactivateStaffUser: ?{|
    +updatedStaffUser: ?{|
      +id: string,
      +user: {|
        +isActive: ?boolean
      |},
    |}
  |}
|};
export type DeactivateStaffUserMutation = {|
  variables: DeactivateStaffUserMutationVariables,
  response: DeactivateStaffUserMutationResponse,
|};
*/


/*
mutation DeactivateStaffUserMutation(
  $input: DeactivateStaffUserInput!
) {
  deactivateStaffUser(input: $input) {
    updatedStaffUser {
      id
      user {
        isActive
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeactivateStaffUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isActive",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeactivateStaffUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deactivateStaffUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateStaffUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeactivateStaffUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deactivateStaffUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateStaffUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeactivateStaffUserMutation",
    "id": null,
    "text": "mutation DeactivateStaffUserMutation(\n  $input: DeactivateStaffUserInput!\n) {\n  deactivateStaffUser(input: $input) {\n    updatedStaffUser {\n      id\n      user {\n        isActive\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'df35d83b569ddafe8edd2b93952be4c9';
module.exports = node;

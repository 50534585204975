import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';
import BaseTextField from '../../form/fields/TextField';

const StyledTextField = styled(BaseTextField)`
  .MuiInputBase-root {
    padding: 0;
  }

  .MuiOutlinedInput-root:focus-within fieldset {
    border-color: ${props => props.theme.palette.primary.main};
  }

  [role='textbox'] {
    width: 100%;
    padding: 18.5px 14px;
  }
`;

const EditableArea = styled.div`
  width: 100%;

  &.ql-container {
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    height: 100%;
    margin: 0px;
    position: relative;
  }

  .ql-editor {
    box-sizing: border-box;
    counter-reset: list-0;
    line-height: 1.28;
    height: 100%;
    min-height: 200px;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;

    > * {
      cursor: text;
    }
  }

  .ql-clipboard {
    left: -100000px;
    height: 1px;
    overflow-y: hidden;
    position: absolute;
    top: 50%;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  quill-link {
    text-decoration: underline;
    color: ${props => props.theme.palette.primary.main};
  }

  quill-reference-file {
    display: inline-block;
    margin-top: 10px;
    margin-right: 12px;
    margin-bottom: 12px;

    + quill-reference-file {
      margin-left: 12px;
    }

    span span {
      margin: 4px;
    }
  }
`;

const TextField = memo(
  forwardRef((_, ref) => (
    <StyledTextField
      multiline
      fullWidth
      InputProps={{
        inputComponent: () => <EditableArea ref={ref} />
      }}
    />
  ))
);

export default TextField;

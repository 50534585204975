import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import { ReactComponent as Logo } from '../../images/AWEbase-Default-Light.svg';
import loginStyles from '../../styles/account/login';
import withAppType from '../../hoc/withAppType';
import GlobalButton from '../UI/GlobalButton';
import authService from '../../services/authService';

const ForgotPassword = props => {
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { classes } = props;

  const _confirm = (values, actions) => {
    authService.forgotPassword(values.email, response => {
      actions.setSubmitting(false);
      if (!response || !response.message) {
        setError('Something went wrong. Try again.');
      } else {
        setMessage(response.message);
      }
    });
  };

  const handleValidate = values => {
    setError('');
    const errors = {};
    const emailRequirements = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRequirements.test(values.email) === false) {
      errors.email = 'Please enter a valid email address.';
    }

    return errors;
  };

  const initialValues = {
    email: ''
  };

  return (
    <div className={classes.mainLogin}>
      <div className={classes.logo}>
        <Logo />
      </div>

      <div className={classes.loginContent}>
        <Typography variant="h1">Forgot Password?</Typography>
        {message ? (
          <Typography variant="body2" className={classes.goodAlert}>
            {message}
          </Typography>
        ) : (
          <Formik initialValues={initialValues} validate={handleValidate} onSubmit={_confirm}>
            {({ isSubmitting }) => (
              <Form className={classes.loginForm}>
                <Typography variant="body2">
                  Please enter your email and we'll send you further instructions.
                </Typography>
                <Field
                  required
                  fullWidth
                  name="email"
                  component={FormikTextField}
                  label="Email address"
                  placeholder="Email address"
                  type="email"
                  className={classes.loginFormInputField}
                />
                <GlobalButton
                  id="submitForgotPassword"
                  type="submit"
                  fullWidth={true}
                  disabled={isSubmitting}
                  className={classes.submitButton}
                >
                  Submit
                </GlobalButton>
                <p className={classes.divider}>OR</p>
              </Form>
            )}
          </Formik>
        )}

        <Link className={classes.back} to="/login">
          Go back to login
        </Link>

        <Typography
          variant="body2"
          className={error ? classes.badAlert : classes.badAlertPlaceholder}
        >
          {error}
        </Typography>
      </div>
    </div>
  );
};

export default withAppType(withStyles(loginStyles)(ForgotPassword));

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeleteTagsMutation($input: DeleteTagsInput!) {
    deleteTags(input: $input) {
      isDeleted
    }
  }
`;

const DeleteTagsMutation = (ids, callback) => {
  const config = {
    mutation,
    variables: { input: { ids } },
    onCompleted: (response, errors) => {
      callback(response.deleteTags, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default DeleteTagsMutation;

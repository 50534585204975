/**
 * @flow
 * @relayHash 23d612a6fee372789fc680d185c64bdd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FileSizeLimits_organization$ref = any;
export type FileSizeLimitsRendererQueryVariables = {||};
export type FileSizeLimitsRendererQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: FileSizeLimits_organization$ref
  |}
|};
export type FileSizeLimitsRendererQuery = {|
  variables: FileSizeLimitsRendererQueryVariables,
  response: FileSizeLimitsRendererQueryResponse,
|};
*/


/*
query FileSizeLimitsRendererQuery {
  organization {
    ...FileSizeLimits_organization
    id
  }
}

fragment FileSizeLimits_organization on OrganizationNode {
  id
  configuration
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FileSizeLimitsRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "FileSizeLimits_organization",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FileSizeLimitsRendererQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "configuration",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FileSizeLimitsRendererQuery",
    "id": null,
    "text": "query FileSizeLimitsRendererQuery {\n  organization {\n    ...FileSizeLimits_organization\n    id\n  }\n}\n\nfragment FileSizeLimits_organization on OrganizationNode {\n  id\n  configuration\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = '1da3d99885c5ba9f3af531053c499279';
module.exports = node;

import React, { useState } from 'react';
import { Slider as MuiSlider } from '@material-ui/core';
import PropTypes from 'prop-types';

import SquareButton from '../UI/SquareButton';
import { useCommonAnnotationStyles } from '../../styles/deliverable/commonAnnotationStyles';

const Slider = props => {
  const { min, max, step, value, onChange, onClick, icon } = props;
  const classes = useCommonAnnotationStyles();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const onChangeHandler = (_event, value) => onChange(value);

  return (
    <div
      className={classes.sliderControlContainer}
      onMouseEnter={toggleOpen}
      onMouseLeave={toggleOpen}
    >
      {open && (
        <div className={classes.sliderContainer}>
          <MuiSlider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChangeHandler}
            orientation="vertical"
          />
        </div>
      )}
      <SquareButton icon={icon} variant="transparent" onClick={onClick} active={open} />
    </div>
  );
};

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired
};

Slider.defaultProps = {
  min: 0,
  max: 1,
  step: 0.01,
  value: 1,
  icon: 'volume'
};

export default Slider;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import DeliverableReview from './DeliverableReview';

const DeliverableReviewTab = props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableReviewTabQuery($id: ID!) {
          deliverable(id: $id) {
            ...DeliverableReview_deliverable
          }
        }
      `}
      variables={{ id }}
      render={relayProps => <DeliverableReview deliverable={relayProps.deliverable} {...props} />}
    />
  );
};

export default DeliverableReviewTab;

import React, { useRef, Fragment } from 'react';
import { useFrame } from 'react-three-fiber';

const Placeholder = props => {
  const { theme } = props;
  const meshRef = useRef();
  useFrame(() => (meshRef.current.rotation.x = meshRef.current.rotation.y += 0.02));
  return (
    <Fragment>
      <ambientLight intensity={1} />
      <mesh {...props} ref={meshRef}>
        <boxBufferGeometry attach="geometry" args={[0.25, 0.25, 0.25]} />
        <meshToonMaterial attach="material" color={theme.palette.primary.main} />
      </mesh>
    </Fragment>
  );
};

export default Placeholder;

import React, { Component, Fragment } from 'react';
import WaveSurfer from 'wavesurfer.js';

import ProgressToolbar from './ProgressToolbar';

import { withTheme } from '@material-ui/styles';
import {
  AnnotationAssetContainer,
  AnnotationToolbarContainer
} from '../deliverable/review_interfaces/AnnotationInterfaceContainers';
import { getTimeFromAnnotationRef } from '../../utils/helpers';

class AudioWavePlayer extends Component {
  state = {
    waveReady: false,
    volume: 1,
    isMuted: false,
    isPlaying: false
  };

  componentDidMount() {
    this.initializeWave();
    this.loadAudio();
    if (this.props.withToolbar) {
      this.initializeToolBarEvents();
    }
  }

  componentDidUpdate(prevProps) {
    const { src, highlightedAnnotationRef, timeProps } = prevProps;
    this.srcChangeHandler(src);
    this.highlightChangeHandler(highlightedAnnotationRef, timeProps);
  }

  componentWillUnmount() {
    this.wave.destroy();
  }

  initializeWave = () => {
    const { id, waveSurferProps, theme } = this.props;
    this.wave = WaveSurfer.create({
      container: `#${id}`,
      responsive: true,
      progressColor: theme.palette.primary.main,
      waveColor: theme.palette.colors.formFieldBorderColor,
      ...waveSurferProps
    });
  };

  loadAudio = () => {
    this.wave.load(this.props.src);
  };

  initializeToolBarEvents = () => {
    const {
      wave,
      onMuteHandler,
      onVolumeHandler,
      onPlayPauseHandler,
      onTimeProcessHandler,
      props: { timeProps }
    } = this;

    wave.on('ready', () => {
      this.setState({ waveReady: true });
      timeProps.setDuration(wave.getDuration());
      wave.on('audioprocess', onTimeProcessHandler);
      wave.on('seek', onTimeProcessHandler);
      wave.on('mute', onMuteHandler);
      wave.on('volume', onVolumeHandler);
      wave.on('play', onPlayPauseHandler);
      wave.on('pause', onPlayPauseHandler);
    });
  };

  srcChangeHandler = previousSrc => {
    const { src } = this.props;
    if (src && previousSrc !== src) {
      this.wave.empty();
      this.loadAudio();
    }
  };

  highlightChangeHandler = (previousAnnotationRef, previousTimeProps) => {
    const { timeProps, highlightedAnnotationRef } = this.props;
    // nothing to do if timeprops did not load yet
    // when coming from Eye icon from reviw tab comments
    if (!timeProps || !timeProps.duration) {
      return;
    }
    if (
      highlightedAnnotationRef &&
      (highlightedAnnotationRef !== previousAnnotationRef ||
        !previousTimeProps ||
        !previousTimeProps.duration)
    ) {
      const time = getTimeFromAnnotationRef(highlightedAnnotationRef);
      this.wave.seekTo(time / timeProps.duration);
      if (!previousTimeProps || !previousTimeProps.duration) {
        this.onTimeProcessHandler();
      }
    }
  };

  onMuteHandler = isMuted => {
    this.setState({ isMuted });
  };

  onVolumeHandler = volume => {
    this.setState({ volume });
  };

  onPlayPauseHandler = () => {
    this.setState({ isPlaying: this.wave.isPlaying() });
  };

  onTimeProcessHandler = () => {
    const { timeProps } = this.props;
    let updatedCurrentTime = Number(this.wave.getCurrentTime().toFixed(0));
    if (updatedCurrentTime !== timeProps.currentTime) {
      timeProps.setCurrentTime(updatedCurrentTime);
    }
  };

  progressBarClickHandler = trackPercent => {
    this.props.handleHighlightAnnotation();
    this.wave.seekTo(trackPercent);
  };

  togglePlayHandler = () => {
    this.wave.playPause();
  };

  volumeChangeHandler = newVolume => {
    this.wave.setVolume(newVolume);
  };

  toggleMuteHandler = () => {
    this.wave.setMute(!this.state.isMuted);
  };

  render() {
    const {
      withToolbar,
      id,
      timeProps,
      addAnnotationHandler,
      addCommentHandler,
      annotationData,
      handleHighlightAnnotation,
      highlightedAnnotationRef
    } = this.props;

    const { volume, isMuted, isPlaying, waveReady } = this.state;

    return withToolbar ? (
      <Fragment>
        <AnnotationAssetContainer>
          <div id={id} />
        </AnnotationAssetContainer>
        <AnnotationToolbarContainer>
          {waveReady && (
            <ProgressToolbar
              sliderProps={{
                value: isMuted ? 0 : volume,
                onChange: this.volumeChangeHandler,
                onClick: this.toggleMuteHandler
              }}
              isPlaying={isPlaying}
              duration={timeProps.duration}
              currentTime={timeProps.currentTime}
              annotationData={annotationData}
              highlightedAnnotationRef={highlightedAnnotationRef}
              onProgressBarClick={this.progressBarClickHandler}
              onAddAnnotationClick={addAnnotationHandler}
              onAddCommentClick={addCommentHandler}
              canAddMarkup={addCommentHandler !== undefined}
              onTogglePlayClick={this.togglePlayHandler}
              onAnnotationClick={handleHighlightAnnotation}
            />
          )}
        </AnnotationToolbarContainer>
      </Fragment>
    ) : (
      <div id={id} />
    );
  }
}

export default withTheme(AudioWavePlayer);

/**
 * @flow
 * @relayHash 111bd0cf97800f2c32d3b0105e6efb10
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobInvoiceStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
export type PaymentRequestStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
export type InvoiceApprovalInput = {|
  invoiceId: string,
  approved: boolean,
  message?: ?string,
  paymentAmount?: ?any,
  attachContract?: ?boolean,
  clientMutationId?: ?string,
|};
export type InvoiceApprovalMutationVariables = {|
  input: InvoiceApprovalInput
|};
export type InvoiceApprovalMutationResponse = {|
  +invoiceApproval: ?{|
    +clientMutationId: ?string,
    +invoice: ?{|
      +id: string,
      +status: JobInvoiceStatus,
      +verificationDate: ?any,
      +verificationMessage: ?string,
      +verificationBy: ?{|
        +fullName: ?string,
        +representativeImageUrl: ?string,
      |},
      +job: {|
        +id: string,
        +balance: ?any,
        +totalAmountOfPaymentRequests: ?any,
        +paymentRequests: ?{|
          +totalCount: ?number,
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +created: any,
              +requestId: string,
              +paymentAmount: number,
              +status: PaymentRequestStatus,
            |}
          |}>,
        |},
      |},
    |},
  |}
|};
export type InvoiceApprovalMutation = {|
  variables: InvoiceApprovalMutationVariables,
  response: InvoiceApprovalMutationResponse,
|};
*/


/*
mutation InvoiceApprovalMutation(
  $input: InvoiceApprovalInput!
) {
  invoiceApproval(input: $input) {
    clientMutationId
    invoice {
      id
      status
      verificationDate
      verificationMessage
      verificationBy {
        fullName
        representativeImageUrl
        id
      }
      job {
        id
        balance
        totalAmountOfPaymentRequests
        paymentRequests(orderBy: "-created") {
          totalCount
          edges {
            node {
              id
              created
              requestId
              paymentAmount
              status
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "InvoiceApprovalInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "verificationDate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "verificationMessage",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "job",
  "storageKey": null,
  "args": null,
  "concreteType": "JobNode",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "balance",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalAmountOfPaymentRequests",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "paymentRequests",
      "storageKey": "paymentRequests(orderBy:\"-created\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-created"
        }
      ],
      "concreteType": "PaymentRequestNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PaymentRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "PaymentRequestNode",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "created",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "requestId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "paymentAmount",
                  "args": null,
                  "storageKey": null
                },
                (v4/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "InvoiceApprovalMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invoiceApproval",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceApprovalPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "invoice",
            "storageKey": null,
            "args": null,
            "concreteType": "InvoiceNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "verificationBy",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "InvoiceApprovalMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invoiceApproval",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceApprovalPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "invoice",
            "storageKey": null,
            "args": null,
            "concreteType": "InvoiceNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "verificationBy",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "InvoiceApprovalMutation",
    "id": null,
    "text": "mutation InvoiceApprovalMutation(\n  $input: InvoiceApprovalInput!\n) {\n  invoiceApproval(input: $input) {\n    clientMutationId\n    invoice {\n      id\n      status\n      verificationDate\n      verificationMessage\n      verificationBy {\n        fullName\n        representativeImageUrl\n        id\n      }\n      job {\n        id\n        balance\n        totalAmountOfPaymentRequests\n        paymentRequests(orderBy: \"-created\") {\n          totalCount\n          edges {\n            node {\n              id\n              created\n              requestId\n              paymentAmount\n              status\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67e8f021379b40491c26d6e6c5f02c30';
module.exports = node;

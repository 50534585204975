import React from 'react';
import useAssetStyles from '../../styles/assets/useAssetStyles';
import AssetCard from './AssetCard';

const AssetGrid = props => {
  const { edges, deleteItem, editItem, canEditItem, canDeleteItem, ...otherProps } = props;
  const classes = useAssetStyles();

  return (
    <div className={classes.assetGrid}>
      {edges.map((edge, index) => {
        const { id, fileUrl, fileName, description, reviewInterface } = edge.node;
        const handleEdit = canEditItem ? (canEditItem(edge.node) ? editItem : undefined) : editItem;
        const handleDelete = canDeleteItem
          ? canDeleteItem(edge.node)
            ? deleteItem
            : undefined
          : deleteItem;

        return (
          <AssetCard
            key={id}
            id={id}
            index={index + 1}
            src={fileUrl}
            name={fileName}
            description={description}
            reviewInterface={reviewInterface}
            {...otherProps}
            editItem={handleEdit}
            deleteItem={handleDelete}
          />
        );
      })}
    </div>
  );
};

export default AssetGrid;

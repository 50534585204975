import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import FormikDateField from '../../../shared/components/form/FormikDateField';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import CreateReleaseMutation from '../../mutations/CreateReleaseMutation';
import FormikSearchableDropdown from '../../../shared/components/form/FormikSearchableDropdown';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import { Grid } from '@material-ui/core';

import { formatDateForQueries } from '../../../shared/utils/formatters';
import { successToast } from '../../../shared/toasts';

const CreateReleaseForm = props => {
  const { handleClose, toRefetchData, isContextual, contextAction, userContext } = props;
  const navigation = JSON.parse(userContext.orgStaff.organization.configuration).navigation;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(100, 'Please enter no more than 100 characters.')
      .required('Required'),
    description: Yup.string().max(5000, 'Please enter no more than 5000 characters.'),
    code: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.')
  });

  const initialValues = {
    name: '',
    code: '',
    product:
      isContextual && contextAction
        ? { value: contextAction.data.productId, label: contextAction.data.productTitle }
        : null,
    deadline: null,
    description: ''
  };

  const validate = values => {
    let errors = {};
    if (!values.product || !values.product.value) {
      errors.product = 'Required';
    }
    return errors;
  };

  const onSubmit = (values, { setSubmitting }) => {
    const { name, code, product, deadline, description } = values;
    CreateReleaseMutation(
      {
        name,
        code,
        product: product.value,
        ...(deadline && { deadline: formatDateForQueries(deadline) }),
        description,
        copyOfRelease: contextAction ? contextAction.data.copyOfRelease : null
      },
      createRelease => {
        setSubmitting(false);
        if (createRelease && createRelease.newRelease) {
          handleClose();
          setTimeout(() => {
            successToast(`New ${navigation.releaseTitle.singular} has been created.`, {
              text: `Go to ${navigation.releaseTitle.singular}`,
              link: `/releases/${createRelease.newRelease.id}`
            });
          }, 250);
          toRefetchData();
        }
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Field
                required
                name="name"
                component={FormikTextField}
                label={`${navigation.releaseTitle.singular} Name`}
                placeholder={`Enter ${navigation.releaseTitle.singular} name...`}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="code"
                component={FormikTextField}
                label={`${navigation.releaseTitle.singular} Code`}
                placeholder={`Enter ${navigation.releaseTitle.singular} code...`}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                required
                name="product"
                disabled={isContextual && !contextAction.data.copyOfRelease}
                component={FormikSearchableDropdown}
                {...(isContextual ? { setInitialValue: true } : null)}
                placeholder={`Select ${navigation.productTitle.singular}...`}
                label={`${navigation.productTitle.singular} Name`}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field name="deadline" label="Key Date" component={FormikDateField} />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                multiline
                rows="10"
                name="description"
                component={FormikTextField}
                label="Description"
                placeholder={`Enter ${navigation.releaseTitle.singular} description...`}
              />
            </Grid>
            <Grid container item xs={12} justify="flex-end">
              <GlobalButton variant="transparent" handleClick={handleClose} id="cancelRelease">
                Cancel
              </GlobalButton>
              <GlobalButton id="submitRelease" type="submit" disabled={isSubmitting}>
                Create {navigation.releaseTitle.singular}
              </GlobalButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(CreateReleaseForm);

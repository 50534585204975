import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Box, Typography, Link } from '@material-ui/core';
import isArray from 'lodash/isArray';

import FormikDropzone from '../../../shared/components/form/formikFields/FormikDropzone';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import useDialogStyles from '../../../shared/styles/common/useDialogStyles';
import { yupSchemas } from '../../../shared/validations';
import ImportDeliverablesMutation from '../../mutations/ImportDeliverablesMutation';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';

const ImportDeliverablesForm = props => {
  const { handleClose, toRefetchData } = props;
  const classes = useDialogStyles();

  const deliverableImportInitialValues = {
    file: null
  };

  const importDeliverablesValidationSchema = yupSchemas.shape({
    file: Yup.mixed().required('A file is required or you are using an incorrect format.')
  });

  const onSubmit = ({ file }, { setSubmitting, setErrors }) => {
    ImportDeliverablesMutation(file, (response, errors) => {
      setSubmitting(false);
      if (response && response.result) {
        handleClose();
        toRefetchData();
      } else if (response && response.errors) {
        setErrors({ file: response.errors });
        setSubmitting(false);
      } else {
        console.log(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={deliverableImportInitialValues}
      validationSchema={importDeliverablesValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <Box mt={2} mb={2}>
            <Field
              fullWidth
              required
              name="file"
              label=".CSV File"
              type="file"
              component={FormikDropzone}
              accept={[
                'text/csv',
                'application/vnd.ms-excel',
                'text/x-csv',
                'application/csv',
                'application/x-csv',
                'text/comma-separated-values',
                'text/x-comma-separated-values'
              ]}
              error={
                isArray(errors.file) && errors.file.length > 0 ? (
                  <Box>
                    There are validation issues with the chosen .csv. Here are a list of errors that
                    need to be corrected before the Deliverables can be imported:
                    <ul>
                      {errors.file.map((msg, i) => (
                        <li key={i}>{msg}</li>
                      ))}
                    </ul>
                  </Box>
                ) : (
                  errors.file
                )
              }
            />
          </Box>
          <Box mb={3}>
            <Typography>
              {'You can find the template and documentation on Deliverable imports '}
              <Link
                href="https://intercom.help/awebase/en/articles/5831890-import-and-export-deliverables"
                variant="caption"
                target="_blank"
                rel="noopener"
              >
                {'here'}
              </Link>
              {'.'}
            </Typography>
          </Box>

          <div className={classes.dialogActions}>
            <GlobalButton
              id="cancelImportDeliverables"
              variant="transparent"
              handleClick={handleClose}
              disabled={isSubmitting}
            >
              Cancel
            </GlobalButton>
            <GlobalButton
              loading={isSubmitting}
              id="submitImportDeliverables"
              disabled={isSubmitting}
              type="submit"
            >
              Import
            </GlobalButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(ImportDeliverablesForm);

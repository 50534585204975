/**
 * @flow
 * @relayHash 9302e6a282002f1a8e551fafdea77764
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CurrencyCodes = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type UpdateDeliverableInput = {|
  title?: ?string,
  release?: ?string,
  category?: ?string,
  categoryType?: ?string,
  assignedStaff?: ?string,
  dueDate?: ?any,
  description?: ?string,
  amount?: ?any,
  currencyCode?: ?CurrencyCodes,
  managerNote?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  markReady?: ?boolean,
  markDraft?: ?boolean,
  internalId?: ?string,
  id: string,
  clientMutationId?: ?string,
|};
export type UpdateDeliverableMutationVariables = {|
  input: UpdateDeliverableInput
|};
export type UpdateDeliverableMutationResponse = {|
  +updateDeliverable: ?{|
    +updatedDeliverable: ?{|
      +id: string,
      +title: string,
      +description: ?string,
      +internalId: ?string,
      +state: DeliverableState,
      +release: ?{|
        +id: string,
        +name: string,
      |},
      +category: {|
        +id: string,
        +name: string,
      |},
      +categoryType: ?{|
        +name: string
      |},
      +amount: ?any,
      +amountInHomeCurrency: ?any,
      +currencyCode: ?DeliverableCurrencyCode,
      +assignedStaff: ?{|
        +id: string,
        +fullName: ?string,
        +representativeImageUrl: ?string,
        +user: {|
          +id: string
        |},
      |},
      +tags: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
          |}
        |}>
      |},
      +dueDate: ?any,
      +managerNote: ?string,
    |}
  |}
|};
export type UpdateDeliverableMutation = {|
  variables: UpdateDeliverableMutationVariables,
  response: UpdateDeliverableMutationResponse,
|};
*/


/*
mutation UpdateDeliverableMutation(
  $input: UpdateDeliverableInput!
) {
  updateDeliverable(input: $input) {
    updatedDeliverable {
      id
      title
      description
      internalId
      state
      release {
        id
        name
      }
      category {
        id
        name
      }
      categoryType {
        name
        id
      }
      amount
      amountInHomeCurrency
      currencyCode
      assignedStaff {
        id
        fullName
        representativeImageUrl
        user {
          id
        }
      }
      tags {
        edges {
          node {
            id
            name
          }
        }
      }
      dueDate
      managerNote
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "internalId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "release",
  "storageKey": null,
  "args": null,
  "concreteType": "ReleaseNode",
  "plural": false,
  "selections": (v8/*: any*/)
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryNode",
  "plural": false,
  "selections": (v8/*: any*/)
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amountInHomeCurrency",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyCode",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "assignedStaff",
  "storageKey": null,
  "args": null,
  "concreteType": "StaffNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ]
    }
  ]
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": null,
  "args": null,
  "concreteType": "TagNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TagNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "TagNode",
          "plural": false,
          "selections": (v8/*: any*/)
        }
      ]
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dueDate",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "managerNote",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "categoryType",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryTypeNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ]
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "categoryType",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryTypeNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateDeliverableMutation",
    "id": null,
    "text": "mutation UpdateDeliverableMutation(\n  $input: UpdateDeliverableInput!\n) {\n  updateDeliverable(input: $input) {\n    updatedDeliverable {\n      id\n      title\n      description\n      internalId\n      state\n      release {\n        id\n        name\n      }\n      category {\n        id\n        name\n      }\n      categoryType {\n        name\n        id\n      }\n      amount\n      amountInHomeCurrency\n      currencyCode\n      assignedStaff {\n        id\n        fullName\n        representativeImageUrl\n        user {\n          id\n        }\n      }\n      tags {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      dueDate\n      managerNote\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ece8aa479b3b22fa3f046ac0ae954c83';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobDetailsToggleFollowButton_job$ref: FragmentReference;
declare export opaque type JobDetailsToggleFollowButton_job$fragmentType: JobDetailsToggleFollowButton_job$ref;
export type JobDetailsToggleFollowButton_job = {|
  +id: string,
  +isCurrentUserFollowing: ?boolean,
  +followers: ?{|
    +edges?: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
      |}
    |}>,
    +totalCount: ?number,
  |},
  +$refType: JobDetailsToggleFollowButton_job$ref,
|};
export type JobDetailsToggleFollowButton_job$data = JobDetailsToggleFollowButton_job;
export type JobDetailsToggleFollowButton_job$key = {
  +$data?: JobDetailsToggleFollowButton_job$data,
  +$fragmentRefs: JobDetailsToggleFollowButton_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "JobDetailsToggleFollowButton_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "withFollowers",
      "type": "Boolean",
      "defaultValue": null
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isCurrentUserFollowing",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "followers",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "Condition",
          "passingValue": true,
          "condition": "withFollowers",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "StaffNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '24da650dc035ddec390774fc9f21076c';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeleteRoleMutation($input: DeleteOrganizationRoleInput!) {
    deleteOrganizationRole(input: $input) {
      isDeleted
    }
  }
`;

const DeleteRoleMutation = (id, callback) => {
  const config = {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.deleteOrganizationRole);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default DeleteRoleMutation;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

/**
 * @param {Object} variables - Variables passed to the mutation
 * @param {string} variables.fileName - Name of the asset
 * @param {string} variables.description - Portfolio asset description
 * @param {Object} file - Portfolio image file
 */

export default (variables, file, onCompleted, onError) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreatePortfolioAssetMutation($input: CreatePortfolioAssetInput!) {
        createPortfolioAsset(input: $input) {
          newAsset {
            id
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    uploadables: {
      upload_image: file
    },
    onCompleted,
    onError
  });
};

import { formatBytes } from '../../../../../shared/utils/formatters';

export const SUBSCRIPTION_TIERS_INFO = [
  {
    label: 'Number of Users',
    name: 'numberOfUsers',
    tooltip: 'The maximum total users allowed for a given tier.'
  },
  {
    label: 'Active Storage',
    name: 'activeStorage',
    bytes: true,
    tooltip: 'Files will count towards you active storage limit until archived or deleted.'
  },
  {
    label: 'Archive Storage',
    name: 'archiveStorage',
    bytes: true,
    tooltip:
      'Files archived from active storage will count towards your archive storage until restored or deleted.'
  },
  {
    label: 'Active Deliverables',
    name: 'activeDeliverables',
    tooltip:
      'A Deliverable is considered active as long as it is not closed, canceled, or marked inactive.'
  },
  {
    label: 'Active Contractors',
    name: 'activeContractors',
    tooltip:
      'The number of active contractors added by your organization, regardless of invite status.'
  },
  {
    label: 'Contract Templates',
    name: 'activeContractTemplates',
    tooltip: 'The number of active contract templates added by your organization.'
  },
  {
    label: 'Module Access',
    name: 'moduleAccess',
    tooltip:
      'Restricts the ability to add feature, storage, and activity expanding modules from the Billing screen.'
  }
];

export const formatCellContent = (row, value) => {
  if (row.bytes && value !== 'Unlimited') {
    return formatBytes(value, 0);
  }
  if (value === true) return 'Yes';
  if (value === false) return 'No';
  return value;
};

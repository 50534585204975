import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

/**
 *
 * @param {Object} variables - Variables passed to the mutation
 * @param {string} variables.id - ID of the comment
 * @param {string} variables.content - Updated content of the comment
 * @param {string} variables.extraData - Extra data in form of stringified json
 * @param {string} variables.clientMutationId
 * @param {*} callback
 */

const UpdateCommentMutation = (variables, onCompleted, onError) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateCommentMutation($input: UpdateCommentInput!) {
        updateComment(input: $input) {
          updatedComment {
            id
            created
            content
            extraData
            objectId
            contentEditedAt
            user {
              id
              fullName
              representativeImageUrl
            }
            referenceFiles {
              id
              title
              description
              fileUrl
              reviewInterface
            }
            reactions {
              id
              definitionId
              name
              color
              number
              users {
                id
                fullName
                representativeImageUrl
              }
            }
          }
          clientMutationId
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted,
    onError
  });
};

export default UpdateCommentMutation;

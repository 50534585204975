import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import getAccountCreationData from '../../mutations/getAccountCreationData';
import CreateAccountMutation from './mutations/CreateAccountMutation';
import authService from '../../../shared/services/authService';
import useQueryParams from '../../../shared/hooks/useQueryParams';
import ConfirmPassword from '../../../shared/components/account/ConfirmPassword';

const CreateFreelancerAccount = () => {
  const [error, setError] = useState('');
  const [redirectToApp, setRedirectToApp] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [validLink, setValidLink] = useState(undefined);
  const queryParams = useQueryParams();

  useEffect(() => {
    if (queryParams.get('contractor-token') && queryParams.get('uidb64')) {
      getAccountCreationData(
        queryParams.get('contractor-token'),
        queryParams.get('uidb64'),
        response => {
          if (response.accountCreationTokenStatus) {
            setValidLink(true);
          } else {
            setValidLink(false);
          }
        }
      );
    } else {
      // if there is no contractor token in the url, redirect to Login page
      setRedirectToLogin(true);
    }
  }, []);

  if (redirectToApp) return <Redirect to="/organizations" />;
  if (redirectToLogin)
    return (
      <Redirect
        to={{
          pathname: '/login'
        }}
      />
    );

  const submitHandler = (values, actions) => {
    CreateAccountMutation(
      values.password,
      queryParams.get('contractor-token'),
      queryParams.get('uidb64'),
      response => {
        if (response && response.createAccount && response.createAccount.newUser) {
          // it logs in user automatically if account creation was successful
          authService.signIn(
            response.createAccount.newUser.email,
            values.password,
            (success, error) => {
              actions.setSubmitting(false);
              if (success) {
                setRedirectToApp(true);
              } else {
                setError(error);
              }
            }
          );
        } else {
          actions.setSubmitting(false);

          setError('Something went wrong.');
          console.error('Account creation failed.');
        }
      }
    );
  };

  return <ConfirmPassword submitHandler={submitHandler} validLink={validLink} error={error} />;
};

export default CreateFreelancerAccount;

/**
 * @flow
 * @relayHash 7d917a74d38985bbb8ad364947f933e4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobInvoicesTab_job$ref = any;
type JobInvoicesTab_statusTypes$ref = any;
export type JobInvoicesTabRendererQueryVariables = {|
  id: string,
  first?: ?number,
  after?: ?string,
|};
export type JobInvoicesTabRendererQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: JobInvoicesTab_job$ref
  |},
  +__type: ?{|
    +$fragmentRefs: JobInvoicesTab_statusTypes$ref
  |},
|};
export type JobInvoicesTabRendererQuery = {|
  variables: JobInvoicesTabRendererQueryVariables,
  response: JobInvoicesTabRendererQueryResponse,
|};
*/


/*
query JobInvoicesTabRendererQuery(
  $id: ID!
  $first: Int
  $after: String
) {
  job(id: $id) {
    ...JobInvoicesTab_job_2HEEH6
    id
  }
  __type(name: "JobInvoiceStatus") {
    ...JobInvoicesTab_statusTypes
  }
}

fragment JobInvoicesTab_job_2HEEH6 on JobNode {
  id
  totalValue
  currencyCode
  totalInvoicesAmount
  totalAmountOfPaymentReceived
  invoices(orderBy: "-created", first: $first, after: $after) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        identifier
        created
        status
        amount
      }
    }
  }
}

fragment JobInvoicesTab_statusTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "JobInvoiceStatus"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JobInvoicesTabRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "JobInvoicesTab_job",
            "args": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"JobInvoiceStatus\")",
        "args": (v4/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "JobInvoicesTab_statusTypes",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "JobInvoicesTabRendererQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalValue",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currencyCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalInvoicesAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalAmountOfPaymentReceived",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "invoices",
            "storageKey": null,
            "args": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-created"
              }
            ],
            "concreteType": "InvoiceNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "InvoiceNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "identifier",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "created",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"JobInvoiceStatus\")",
        "args": (v4/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "JobInvoicesTabRendererQuery",
    "id": null,
    "text": "query JobInvoicesTabRendererQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n) {\n  job(id: $id) {\n    ...JobInvoicesTab_job_2HEEH6\n    id\n  }\n  __type(name: \"JobInvoiceStatus\") {\n    ...JobInvoicesTab_statusTypes\n  }\n}\n\nfragment JobInvoicesTab_job_2HEEH6 on JobNode {\n  id\n  totalValue\n  currencyCode\n  totalInvoicesAmount\n  totalAmountOfPaymentReceived\n  invoices(orderBy: \"-created\", first: $first, after: $after) {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        identifier\n        created\n        status\n        amount\n      }\n    }\n  }\n}\n\nfragment JobInvoicesTab_statusTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c07c513691c0e70f56b422e482477c05';
module.exports = node;

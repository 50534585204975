import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Grid, Typography } from '@material-ui/core';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import { formatBytes, formatBytesToGb } from '../../../../../shared/utils/formatters';

const OverviewContent = props => {
  const { organization } = props;

  const classes = useDetailsStyles();

  if (!organization) {
    return <div>Something went wrong!</div>;
  }
  const {
    latestActiveMetricsData,
    latestArchiveMetricsData,
    archiveStorageLimit,
    activeStorageLimit
  } = organization;

  const renderRow = (name, value, className = '', variant = 'body2') => (
    <Grid container className={className} key={name}>
      <Grid item xs={6} style={{ paddingLeft: '6px' }}>
        <Typography variant={variant}>{name}</Typography>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right', paddingRight: '6px' }}>
        <Typography variant={variant}> {value}</Typography>
      </Grid>
    </Grid>
  );

  const mapMetricsLayout = metricsData => {
    return metricsData.map(metricGroup => {
      return (
        <div style={{ marginBottom: '15px' }} key={metricGroup.groupName}>
          <Typography variant="overline" style={{ paddingLeft: '6px' }}>
            {metricGroup.groupName}
          </Typography>

          {metricGroup.metricValues.map(metric => {
            return renderRow(metric.name, formatBytes(metric.value), classes.storageOverviewRow);
          })}
        </div>
      );
    });
  };

  const renderTotalRow = metric =>
    renderRow('Total', formatBytesToGb(metric.total), classes.storageTotalRow, 'h3');

  const renderLimitRow = metricValue =>
    renderRow('Included', formatBytesToGb(metricValue), classes.storageTotalRow, 'subtitle1');

  return (
    <Grid container className={classes.detailsContentNoTabs}>
      <Grid item xs={4} className={`${classes.borderRight} ${classes.storageOverviewColumn}`}>
        <DetailsSection title="Active Storage Breakdown" noBorder>
          {mapMetricsLayout(latestActiveMetricsData.data)}
          {renderTotalRow(latestActiveMetricsData)}
          {renderLimitRow(activeStorageLimit)}
        </DetailsSection>
      </Grid>
      <Grid item xs={4} className={classes.storageOverviewColumn}>
        <DetailsSection title="Archive Storage Breakdown">
          {mapMetricsLayout(latestArchiveMetricsData.data)}
          {renderTotalRow(latestArchiveMetricsData)}
          {renderLimitRow(archiveStorageLimit)}
        </DetailsSection>
        <Typography variant="overline">
          All values are updated automatically at 10:00 AM UTC.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default createFragmentContainer(OverviewContent, {
  organization: graphql`
    fragment OverviewContent_organization on OrganizationNode {
      id
      activeStorageLimit
      archiveStorageLimit
      latestActiveMetricsData {
        total
        data {
          groupName
          metricValues {
            name
            value
          }
        }
      }
      latestArchiveMetricsData {
        total
        data {
          groupName
          metricValues {
            name
            value
          }
        }
      }
    }
  `
});

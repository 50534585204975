/**
 * @flow
 * @relayHash 1d0772889f63ecdb8651cd8806ec0483
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SubscriptionModuleDialogQueryVariables = {|
  module: string,
  increase: boolean,
|};
export type SubscriptionModuleDialogQueryResponse = {|
  +updateModuleCheck: ?{|
    +invoicePreview: ?{|
      +total: ?number,
      +timestamp: ?string,
      +invoiceLines: ?$ReadOnlyArray<?{|
        +itemName: ?string,
        +quantity: ?number,
        +cost: ?number,
      |}>,
    |},
    +cancelModuleRemoval: ?boolean,
    +removeModule: ?boolean,
    +error: ?string,
    +subscriptionChanges: ?{|
      +tier: ?string,
      +interval: ?string,
      +modules: ?number,
      +activeUsers: ?number,
      +totalUsers: ?number,
    |},
  |}
|};
export type SubscriptionModuleDialogQuery = {|
  variables: SubscriptionModuleDialogQueryVariables,
  response: SubscriptionModuleDialogQueryResponse,
|};
*/


/*
query SubscriptionModuleDialogQuery(
  $module: String!
  $increase: Boolean!
) {
  updateModuleCheck(module: $module, increase: $increase) {
    invoicePreview {
      total
      timestamp
      invoiceLines {
        itemName
        quantity
        cost
      }
    }
    cancelModuleRemoval
    removeModule
    error
    subscriptionChanges {
      tier
      interval
      modules
      activeUsers
      totalUsers
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "module",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "increase",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateModuleCheck",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "increase",
        "variableName": "increase"
      },
      {
        "kind": "Variable",
        "name": "module",
        "variableName": "module"
      }
    ],
    "concreteType": "UpdateSubscriptionCheck",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invoicePreview",
        "storageKey": null,
        "args": null,
        "concreteType": "InvoicePreview",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "total",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timestamp",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "invoiceLines",
            "storageKey": null,
            "args": null,
            "concreteType": "InvoiceLine",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "itemName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "quantity",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cost",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cancelModuleRemoval",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removeModule",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "error",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subscriptionChanges",
        "storageKey": null,
        "args": null,
        "concreteType": "SubscriptionInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tier",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "interval",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "modules",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeUsers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalUsers",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SubscriptionModuleDialogQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SubscriptionModuleDialogQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SubscriptionModuleDialogQuery",
    "id": null,
    "text": "query SubscriptionModuleDialogQuery(\n  $module: String!\n  $increase: Boolean!\n) {\n  updateModuleCheck(module: $module, increase: $increase) {\n    invoicePreview {\n      total\n      timestamp\n      invoiceLines {\n        itemName\n        quantity\n        cost\n      }\n    }\n    cancelModuleRemoval\n    removeModule\n    error\n    subscriptionChanges {\n      tier\n      interval\n      modules\n      activeUsers\n      totalUsers\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd120cd2cc01797b4fe9fd933f4c3a0b5';
module.exports = node;

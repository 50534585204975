import React from 'react';
import clsx from 'clsx';
import useAssetStyles from '../../styles/assets/useAssetStyles';

const AspectRatioImageContainer = ({ cover, children, ...rest }) => {
  const classes = useAssetStyles({ cover });
  return (
    <div
      {...rest}
      className={clsx('aspect-ratio-image-container', classes.aspectRatioImageContainer)}
    >
      {children}
    </div>
  );
};

export default AspectRatioImageContainer;

import React, { Fragment, useRef, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useFrame, useLoader, useThree } from 'react-three-fiber';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import * as THREE from 'three';

import ModelAsset from './ModelAsset';
import Controls from './Controls';
import AnnotationSprite from './AnnotationSprite';

import useUpdateEffect from '../../../../hooks/useUpdateEffect';

import hdr2 from '../../../../images/env2.hdr';
import hdr3 from '../../../../images/env3.hdr';
import hdr4 from '../../../../images/env4.hdr';
import hdr5 from '../../../../images/env5.hdr';

const CanvasContent = props => {
  const {
    handleHighlightAnnotation,
    highlightedAnnotationRef,
    annotationData,
    setAddedAnnotationData,
    theme,
    settings,
    togglePointer,
    isEditing,
    src
  } = props;

  const [alpha, setAlpha] = useState(0);
  const [cameraMoved, setCameraMoved] = useState(false);
  const controlsRef = useRef();
  let { gl, scene } = useThree();

  const dataTextures = useLoader(RGBELoader, [hdr2, hdr3, hdr4, hdr5]);
  const pmremGenerator = new THREE.PMREMGenerator(gl);
  pmremGenerator.compileEquirectangularShader();
  const environments = dataTextures.map(
    dataTexture => pmremGenerator.fromEquirectangular(dataTexture).texture
  );

  useEffect(() => {
    const environmentValue = settings ? settings.environment : 0;
    scene.environment = environments[environmentValue];
  }, []);

  useUpdateEffect(() => {
    setCameraMoved(false);
    setAlpha(0);
  }, [highlightedAnnotationRef]);

  useUpdateEffect(() => {
    const environmentValue = settings ? settings.environment : 0;
    scene.environment = environments[environmentValue];
    scene.traverse(function (child) {
      if (child.isMesh) {
        child.material.flatShading = settings.flatShading;
        child.material.needsUpdate = true;
        // TOFIX RoughnessMipmapper seems to be broken with WebGL 2.0
        // roughnessMipmapper.generateMipmaps( child.material );
      }
    });
    scene.environment = environments[settings.environment];
  }, [settings]);

  useFrame(state => {
    if (highlightedAnnotationRef && !cameraMoved) {
      const highlightedAnnotation = annotationData.find(
        annotation => annotation.annotationRef === highlightedAnnotationRef
      );
      const oldCamPos = new THREE.Vector3().copy(highlightedAnnotation.cameraPosition);
      if (!isEqual(roundVector(oldCamPos), roundVector(state.camera.position)) && alpha < 1) {
        state.camera.position.lerp(oldCamPos, alpha);
        setAlpha(alpha + 1 / (1.5 * 60));
      } else {
        setCameraMoved(true);
        setAlpha(0);
      }
    }
  });

  const roundVector = (vector3, digits = 2) => {
    let newValues = vector3.toArray().map(value => Number(value.toFixed(digits)));
    return new THREE.Vector3(...newValues);
  };

  const onAnnotationClick = event => {
    handleHighlightAnnotation(event.object.userData.annotationRef);
  };

  const onClick = event => {
    event.stopPropagation();
    setAddedAnnotationData({
      position: event.point,
      cameraPosition: controlsRef.current.object.position
    });
  };

  const centerCameraOn = object => {
    let controls = controlsRef.current;
    let camera = controlsRef.current.object;
    const box = new THREE.Box3();
    const fitOffset = 1.2;

    box.expandByObject(object);

    const size = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());

    const maxSize = Math.max(size.x, size.y, size.z);
    const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / camera.aspect;
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = controls.target
      .clone()
      .sub(camera.position)
      .normalize()
      .multiplyScalar(distance);

    controls.maxDistance = distance * 10;
    controls.target.copy(center);

    camera.near = distance / 100;
    camera.far = distance * 100;
    camera.updateProjectionMatrix();

    camera.position.copy(controls.target).sub(direction);

    controls.update();
  };

  return (
    <Fragment>
      <Controls ref={controlsRef} />
      <group name="lights">
        <ambientLight intensity={0.5} />
        <directionalLight
          color={0xc0c0c0}
          position={[-50, 50, 50]}
          intensity={settings.lightIntensity}
        />
        <directionalLight
          color={0xc0c0c0}
          position={[50, 50, -50]}
          intensity={settings.lightIntensity}
        />
      </group>
      <group name="asset">
        <ModelAsset
          src={src}
          centerCameraOn={centerCameraOn}
          theme={theme}
          flatShading={settings.flatShading}
          handlers={
            isEditing
              ? {
                  onPointerEnter: togglePointer,
                  onPointerLeave: togglePointer,
                  onClick
                }
              : {}
          }
        />
      </group>
      <group name="annotations">
        {annotationData
          ? annotationData.map(annotation => (
              <AnnotationSprite
                scale={[0.05, 0.05, 0.05]}
                key={annotation.annotationRef}
                position={Object.values(annotation.position)}
                userData={{
                  annotationRef: annotation.annotationRef
                }}
                theme={theme}
                handlers={
                  !isEditing
                    ? {
                        onClick: onAnnotationClick,
                        onPointerEnter: togglePointer,
                        onPointerLeave: togglePointer
                      }
                    : {}
                }
                active={annotation.annotationRef === highlightedAnnotationRef}
              />
            ))
          : null}
      </group>
    </Fragment>
  );
};

export default CanvasContent;

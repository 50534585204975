/**
 * @flow
 * @relayHash da94953620a902753db02ebd0715d589
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateOrganizationBillingAddressInput = {|
  billingAddress1: string,
  billingAddress2?: ?string,
  billingCity: string,
  billingState?: ?string,
  billingCountry: string,
  billingZipcode: string,
  phone: string,
  clientMutationId?: ?string,
|};
export type UpdateOrganizationBillingAddressMutationVariables = {|
  input: UpdateOrganizationBillingAddressInput
|};
export type UpdateOrganizationBillingAddressMutationResponse = {|
  +updateOrganizationBillingAddress: ?{|
    +organization: ?{|
      +billingAddress1: string,
      +billingAddress2: ?string,
      +billingCity: string,
      +billingCountry: ?{|
        +code: ?string,
        +name: ?string,
      |},
      +billingState: string,
      +billingZipcode: string,
      +phone: string,
      +hasBillingInfo: ?boolean,
    |}
  |}
|};
export type UpdateOrganizationBillingAddressMutation = {|
  variables: UpdateOrganizationBillingAddressMutationVariables,
  response: UpdateOrganizationBillingAddressMutationResponse,
|};
*/


/*
mutation UpdateOrganizationBillingAddressMutation(
  $input: UpdateOrganizationBillingAddressInput!
) {
  updateOrganizationBillingAddress(input: $input) {
    organization {
      billingAddress1
      billingAddress2
      billingCity
      billingCountry {
        code
        name
      }
      billingState
      billingZipcode
      phone
      hasBillingInfo
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrganizationBillingAddressInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "billingAddress1",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "billingAddress2",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "billingCity",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "billingCountry",
  "storageKey": null,
  "args": null,
  "concreteType": "Country",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "billingState",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "billingZipcode",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasBillingInfo",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateOrganizationBillingAddressMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrganizationBillingAddress",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationBillingAddressPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateOrganizationBillingAddressMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrganizationBillingAddress",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationBillingAddressPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateOrganizationBillingAddressMutation",
    "id": null,
    "text": "mutation UpdateOrganizationBillingAddressMutation(\n  $input: UpdateOrganizationBillingAddressInput!\n) {\n  updateOrganizationBillingAddress(input: $input) {\n    organization {\n      billingAddress1\n      billingAddress2\n      billingCity\n      billingCountry {\n        code\n        name\n      }\n      billingState\n      billingZipcode\n      phone\n      hasBillingInfo\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50c3be14034caf317440005308a7ef1d';
module.exports = node;

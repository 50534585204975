import React from 'react';
import PropTypes from 'prop-types';

import GlobalButton from './GlobalButton';
import { Tooltip } from '@material-ui/core';

import { GLOBAL_BUTTON_TYPES } from '../../constants';

const ButtonWithTooltip = props => {
  const {
    onClick,
    variant = 'secondary',
    buttonText,
    tooltipText,
    ...otherGlobalButtonProps
  } = props;

  return (
    <Tooltip title={tooltipText}>
      {/* wrapping in span so disabled buttons also show tooltip on hover. Otherwise, disabled elements do not trigger hover */}
      <span
        style={{
          zIndex: 1,
          width: props.fullWidth ? '100%' : 'auto',
          marginRight: props.fullWidth ? '16px' : '0'
        }}
      >
        <GlobalButton
          variant={variant}
          handleClick={onClick}
          children={buttonText}
          {...otherGlobalButtonProps}
        />
      </span>
    </Tooltip>
  );
};

ButtonWithTooltip.propTypes = {
  onClick: function (props, propName) {
    // required only if button is not disabled
    if (
      !props['disabled'] &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error('Please provide an onClick function to ButtonWithTooltip component!');
    }
  },
  variant: PropTypes.oneOf(GLOBAL_BUTTON_TYPES),
  buttonText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  fullWidth: PropTypes.bool
};

export default ButtonWithTooltip;

import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';

function TableCellClickable({ children, handleClick, truncate, classes, ...otherProps }) {
  return (
    <StyledTableCell clickable truncate={truncate} onClick={handleClick} {...otherProps} >
      {children}
    </StyledTableCell>
  );
}

TableCellClickable.propTypes = {
  children: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
  truncate: PropTypes.bool
};

export default TableCellClickable;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import FileSizeLimits from '../FileSizeLimits';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';

const FileSizeLimitsRenderer = ({ tableName, ...otherProps }) => {
  return (
    <QueryRenderer
      query={graphql`
        query FileSizeLimitsRendererQuery {
          organization {
            ...FileSizeLimits_organization
          }
        }
      `}
      variables={{}}
      render={relayProps => (
        <FileSizeLimits organization={relayProps.organization} {...otherProps} />
      )}
    />
  );
};

export default FileSizeLimitsRenderer;

import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { ReactComponent as CommentIcon } from '../../../images/message.svg';
import { ReactComponent as EditIcon } from '../../../images/edit.svg';
import GlobalButton from '../../UI/GlobalButton';

const Container = styled(Box)`
  display: inline-flex;
  align-items: center;

  button {
    margin-left: 0;

    &:not(:last-child) {
      margin-right: ${props => props.theme.spacing(3)}px;
    }

    svg path {
      fill: #fff;
    }
  }
`;

const AddActionsGroup = props => {
  const { disabled, onAddAnnotation, onAddComment } = props;
  return (
    <Container>
      {onAddAnnotation && (
        <GlobalButton
          icon={EditIcon}
          iconProps={{ style: { height: '12px' } }}
          variant="primary"
          disabled={disabled}
          onClick={onAddAnnotation}
        >
          Add Annotation
        </GlobalButton>
      )}
      {onAddComment && (
        <GlobalButton
          icon={CommentIcon}
          iconProps={{ style: { height: '12px' } }}
          variant="primary"
          disabled={disabled}
          onClick={onAddComment}
        >
          Add Comment
        </GlobalButton>
      )}
    </Container>
  );
};

export default AddActionsGroup;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation ReassignSolicitationCoordinatorMutation($input: ReassignSolicitationCoordinatorInput!) {
    reassignSolicitationCoordinator(input: $input) {
      isUpdated
      solicitation {
        id
        staffCoordinator {
          id
          fullName
          representativeImageUrl
          user {
            id
          }
        }
      }
    }
  }
`;

const ReassignSolicitationCoordinatorMutation = (id, staffCoordinator, callback) => {
  const reassignSolicitationCoordinatorVariables = {
    input: { id, staffCoordinator }
  };

  const config = {
    mutation,
    variables: reassignSolicitationCoordinatorVariables,
    onCompleted: response => {
      callback(response.reassignSolicitationCoordinator);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default ReassignSolicitationCoordinatorMutation;

ReassignSolicitationCoordinatorMutation.propTypes = {
  id: PropTypes.string.isRequired,
  staffCoordinator: PropTypes.string.isRequired
};

import { useRef, useEffect } from 'react';

/**
 *
 * @param {function} cb Function to execute on dependancies update
 * @param {[]} deps Array of dependancies whose update calls the callback function
 */

const useUpdateEffect = (cb, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      cb();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default useUpdateEffect;

import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';
import withUserContext from '../../../contexts/userContext/withUserContext';
import Tooltip from '../../table/CustomTableTooltip';
import Avatar from '../../common/Avatar';

export const CounterContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #e8e7ed;
  border-radius: 100%;
  overflow: hidden;

  .MuiAvatar-root {
    border-radius: 0;
  }

  > &:first-child {
    opacity: 1;
    transition: all 0.2s;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -4px 0 0 -4px;
    width: 8px;
    height: 8px;
    opacity: 0;

    path {
      fill: #fff;
    }
  }
`;

export const LabelContainer = styled.div`
  margin-left: -14px;
  padding-left: 18px;
  height: 28px;
  display: flex;
  align-items: center;
  border: 2px solid #e8e7ed;
  border-left: none;
  background: #fff;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  padding-right: 12px;
`;

const Container = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  height: 28px;

  ${props =>
    props.canDelete &&
    css`
      cursor: pointer;

      &:hover {
        ${CounterContainer} {
          background: ${props.theme.palette.colors.darkBlueGrey};

          > *:first-child {
            opacity: 0;
          }

          > svg {
            opacity: 1;
          }
        }
      }
    `};
`;

const Label = styled(({ colorCode, ...props }) => <Typography {...props} />).attrs({
  variant: 'overline'
})`
  color: ${props => props.colorCode};
`;

const ReactionCard = ({ reaction, userContext, onDelete, ...containerProps }) => {
  const canDelete = reaction.users.find(user => user.id === userContext.id) ? true : false;
  const showAvatar = reaction.number === 1;

  const handleDelete = e => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <Container
      {...containerProps}
      {...(canDelete && {
        canDelete: true,
        onClick: handleDelete
      })}
    >
      <Tooltip
        title={
          <>
            {reaction.users.map((user, i) => (
              <Fragment key={i}>
                {i !== 0 ? <br /> : null}
                {user.fullName}
              </Fragment>
            ))}
          </>
        }
      >
        <CounterContainer withAvatar={showAvatar}>
          {showAvatar ? (
            <Avatar size={28} src={reaction.users[0].representativeImageUrl} />
          ) : (
            <Typography variant="body2" color="inherit">
              {reaction.number}
            </Typography>
          )}
          <CloseIcon />
        </CounterContainer>
      </Tooltip>
      <LabelContainer>
        <Label colorCode={`#${reaction.color}`}>{reaction.name}</Label>
      </LabelContainer>
    </Container>
  );
};

export default styled(withUserContext(ReactionCard))``;

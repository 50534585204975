import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation RenameTagMutation($input: RenameTagInput!) {
    renameTag(input: $input) {
      tag {
        id
        name
      }
      nameExists
    }
  }
`;

const RenameTagMutation = (id, name, mergeIfExists, callback) => {
  const config = {
    mutation,
    variables: { input: { id, name, mergeIfExists } },
    onCompleted: (response, errors) => {
      callback(response.renameTag, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default RenameTagMutation;

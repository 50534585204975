/**
 * @flow
 * @relayHash f0c7884c077d95de31e8e4a885b7c626
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateShowcaseAssetInput = {|
  contractorId: string,
  fileName: string,
  description?: ?string,
  stagedPath?: ?string,
  clientMutationId?: ?string,
|};
export type CreateShowcaseAssetMutationVariables = {|
  input: CreateShowcaseAssetInput
|};
export type CreateShowcaseAssetMutationResponse = {|
  +createShowcaseAsset: ?{|
    +newAsset: ?{|
      +id: string,
      +fileName: ?string,
      +fileUrl: ?string,
      +description: ?string,
    |}
  |}
|};
export type CreateShowcaseAssetMutation = {|
  variables: CreateShowcaseAssetMutationVariables,
  response: CreateShowcaseAssetMutationResponse,
|};
*/


/*
mutation CreateShowcaseAssetMutation(
  $input: CreateShowcaseAssetInput!
) {
  createShowcaseAsset(input: $input) {
    newAsset {
      id
      fileName
      fileUrl
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateShowcaseAssetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createShowcaseAsset",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateShowcaseAssetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "newAsset",
        "storageKey": null,
        "args": null,
        "concreteType": "AssetNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateShowcaseAssetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateShowcaseAssetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateShowcaseAssetMutation",
    "id": null,
    "text": "mutation CreateShowcaseAssetMutation(\n  $input: CreateShowcaseAssetInput!\n) {\n  createShowcaseAsset(input: $input) {\n    newAsset {\n      id\n      fileName\n      fileUrl\n      description\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c05a1f72f269e6701d1543eeae1499c9';
module.exports = node;

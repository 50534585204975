import React, { useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import Select from '../../../shared/components/form/fields/Select';

import { useEffectWithStatus } from '../../../shared/hooks/useEffectWithStatus';
import { getEnvironment, formatCurrencyOption } from '../../../shared/utils/helpers';

export const CurrencySelect = props => {
  const [options, setOptions] = useState();

  useEffectWithStatus(status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query CurrencySelectQuery {
          currencyRates {
            rateDate
            baseCurrency
            currencyCode
            rate
          }
        }
      `
    ).then(response => {
      status.mounted && setOptions(response.currencyRates.map(formatCurrencyOption));
    });
  }, []);

  return <Select options={options} {...props} />;
};

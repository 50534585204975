import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation MarkContractTemplateActiveMutation($input: MarkContractTemplateActiveInput!) {
    markContractTemplateActive(input: $input) {
      contractTemplate {
        id
        state
        activationDate
      }
    }
  }
`;

const MarkContractTemplateActiveMutation = (id, callback) => {
  const config = {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.markContractTemplateActive);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default MarkContractTemplateActiveMutation;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import DeliverableList from './DeliverableList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';

const DeliverableListPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableListPageQuery(
          $first: Int
          $after: String
          $orderBy: String
          $title: String
          $state: String
          $assignedStaff: ID
          $jobDeadlineFrom: Date
          $jobDeadlineTo: Date
          $organization: String
          $includeClosed: Boolean
          $includeCompleted: Boolean
          $includeCanceled: Boolean
        ) {
          ...DeliverableList_deliverables
          __type(name: "DeliverableState") {
            ...DeliverableList_stateTypes
          }
        }
      `}
      variables={variables}
      render={relayProps => (
        <DeliverableList deliverables={relayProps} stateTypes={relayProps.__type} {...props} />
      )}
    />
  );
};

export default withTableHistoryProvider(DeliverableListPage, 'freDeliverables', {
  orderBy: 'title',
  initialFilters: {
    state: '',
    title: '',
    includeClosed: false,
    includeCompleted: false,
    includeCanceled: false,
    assignedStaff: null,
    jobDeadlineFrom: null,
    jobDeadlineTo: null,
    organization: null
  }
});

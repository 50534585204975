/**
 * @flow
 * @relayHash 979fdae069770c938696d94d2c360387
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractorDetails_contractor$ref = any;
export type ContractorDetailsPageQueryVariables = {|
  id: string
|};
export type ContractorDetailsPageQueryResponse = {|
  +contractor: ?{|
    +$fragmentRefs: ContractorDetails_contractor$ref
  |}
|};
export type ContractorDetailsPageQuery = {|
  variables: ContractorDetailsPageQueryVariables,
  response: ContractorDetailsPageQueryResponse,
|};
*/


/*
query ContractorDetailsPageQuery(
  $id: ID!
) {
  contractor(id: $id) {
    ...ContractorDetails_contractor
    id
  }
}

fragment ContractorDetails_contractor on ContractorNode {
  id
  doNotHire
  isActive
  firstName
  lastName
  fullName
  nickname
  title
  email
  phone
  timezone
  internalId
  companyName
  portfolioUrl
  address1
  address2
  city
  state
  zipcode
  country {
    name
    code
  }
  invitationEmailSent
  tags {
    edges {
      node {
        id
        name
      }
    }
  }
  freelancer {
    id
  }
  conflictingContractor {
    id
  }
  coverImageUrl
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractorDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractorDetails_contractor",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractorDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "doNotHire",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isActive",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fullName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nickname",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timezone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "internalId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "portfolioUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "city",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "zipcode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "country",
            "storageKey": null,
            "args": null,
            "concreteType": "Country",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "code",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "invitationEmailSent",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tags",
            "storageKey": null,
            "args": null,
            "concreteType": "TagNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "freelancer",
            "storageKey": null,
            "args": null,
            "concreteType": "FreelancerNode",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "conflictingContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "coverImageUrl",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractorDetailsPageQuery",
    "id": null,
    "text": "query ContractorDetailsPageQuery(\n  $id: ID!\n) {\n  contractor(id: $id) {\n    ...ContractorDetails_contractor\n    id\n  }\n}\n\nfragment ContractorDetails_contractor on ContractorNode {\n  id\n  doNotHire\n  isActive\n  firstName\n  lastName\n  fullName\n  nickname\n  title\n  email\n  phone\n  timezone\n  internalId\n  companyName\n  portfolioUrl\n  address1\n  address2\n  city\n  state\n  zipcode\n  country {\n    name\n    code\n  }\n  invitationEmailSent\n  tags {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  freelancer {\n    id\n  }\n  conflictingContractor {\n    id\n  }\n  coverImageUrl\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1567f0f83989b8cfd86e15ebf358a35f';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DetailsNotes_deliverable$ref: FragmentReference;
declare export opaque type DetailsNotes_deliverable$fragmentType: DetailsNotes_deliverable$ref;
export type DetailsNotes_deliverable = {|
  +managerNote: ?string,
  +$refType: DetailsNotes_deliverable$ref,
|};
export type DetailsNotes_deliverable$data = DetailsNotes_deliverable;
export type DetailsNotes_deliverable$key = {
  +$data?: DetailsNotes_deliverable$data,
  +$fragmentRefs: DetailsNotes_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DetailsNotes_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "managerNote",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6c9f2f77ebbdb58ddc2693126fa49954';
module.exports = node;

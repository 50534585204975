/**
 * @flow
 * @relayHash 02caf26fc642d8ba321c41aac9c6d32d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentRequestStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
export type DeclinePaymentRequestInput = {|
  id: string,
  reason: string,
  clientMutationId?: ?string,
|};
export type DeclinePaymentRequestMutationVariables = {|
  input: DeclinePaymentRequestInput
|};
export type DeclinePaymentRequestMutationResponse = {|
  +declinePaymentRequest: ?{|
    +emailSent: ?boolean,
    +paymentRequest: ?{|
      +id: string,
      +status: PaymentRequestStatus,
      +paymentAmount: number,
      +declinedBy: ?{|
        +id: string,
        +fullName: ?string,
        +representativeImageUrl: ?string,
      |},
      +declinedDate: ?any,
      +declinedReason: ?string,
      +job: {|
        +balance: ?any,
        +totalAmountOfPaymentRequests: ?any,
      |},
    |},
  |}
|};
export type DeclinePaymentRequestMutation = {|
  variables: DeclinePaymentRequestMutationVariables,
  response: DeclinePaymentRequestMutationResponse,
|};
*/


/*
mutation DeclinePaymentRequestMutation(
  $input: DeclinePaymentRequestInput!
) {
  declinePaymentRequest(input: $input) {
    emailSent
    paymentRequest {
      id
      status
      paymentAmount
      declinedBy {
        id
        fullName
        representativeImageUrl
      }
      declinedDate
      declinedReason
      job {
        balance
        totalAmountOfPaymentRequests
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeclinePaymentRequestInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailSent",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paymentAmount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "declinedBy",
  "storageKey": null,
  "args": null,
  "concreteType": "StaffNode",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "declinedDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "declinedReason",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "balance",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalAmountOfPaymentRequests",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeclinePaymentRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "declinePaymentRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeclinePaymentRequestPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "paymentRequest",
            "storageKey": null,
            "args": null,
            "concreteType": "PaymentRequestNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "job",
                "storageKey": null,
                "args": null,
                "concreteType": "JobNode",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeclinePaymentRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "declinePaymentRequest",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeclinePaymentRequestPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "paymentRequest",
            "storageKey": null,
            "args": null,
            "concreteType": "PaymentRequestNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "job",
                "storageKey": null,
                "args": null,
                "concreteType": "JobNode",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeclinePaymentRequestMutation",
    "id": null,
    "text": "mutation DeclinePaymentRequestMutation(\n  $input: DeclinePaymentRequestInput!\n) {\n  declinePaymentRequest(input: $input) {\n    emailSent\n    paymentRequest {\n      id\n      status\n      paymentAmount\n      declinedBy {\n        id\n        fullName\n        representativeImageUrl\n      }\n      declinedDate\n      declinedReason\n      job {\n        balance\n        totalAmountOfPaymentRequests\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cee21df04512d9e67ab285c593f3accb';
module.exports = node;

import React from 'react';
import { useParams } from 'react-router';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import ContractorDetails from './ContractorDetails';

const ContractorDetailsPage = props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query ContractorDetailsPageQuery($id: ID!) {
          contractor(id: $id) {
            ...ContractorDetails_contractor
          }
        }
      `}
      variables={{ id }}
      render={relayProps => <ContractorDetails contractor={relayProps.contractor} {...props} />}
    />
  );
};

export default ContractorDetailsPage;

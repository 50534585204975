import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import { CELL_TYPES } from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { getActiveReleaseDeadlineWarningColor } from '../../../../../shared/utils/helpers';

const ProductReleasesList = props => {
  const {
    relay: { refetch },
    product: { id, releases },
    history,
    userContext: {
      orgStaff: {
        organization: { configuration }
      }
    }
  } = props;

  const navigation = JSON.parse(configuration).navigation;

  const PRODUCTS_RELEASES_ROW_TEMPLATE = [
    {
      name: 'name',
      label: `${navigation.releaseTitle.singular} Name`,
      type: CELL_TYPES.link,
      sortable: true
    },
    { name: 'deadline', label: 'Key Date', type: CELL_TYPES.dateWithWarning },
    {
      name: 'numberOfDeliverablesWithoutCoordinator',
      label: 'Unassigned Deliverables',
      align: 'right'
    }
  ];

  if (!releases) return <div>Something went wrong!</div>;

  const {
    edges,
    edgeCount,
    totalCount,
    pageInfo: { hasNextPage, endCursor }
  } = releases;

  const onChangeHandler = variables => {
    refetch({ id, ...variables });
  };

  const onLinkClickHandler = id => {
    history.push(`/releases/${id}`);
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      name,
      deadline,
      numberOfDeliverablesWithoutCoordinator,
      hasIncompleteDeliverables
    } = edge.node;

    return {
      node: {
        id,
        name,
        numberOfDeliverablesWithoutCoordinator,
        deadline: {
          date: deadline,
          color: getActiveReleaseDeadlineWarningColor(
            configuration,
            deadline,
            hasIncompleteDeliverables
          )
        }
      }
    };
  });

  return (
    <AutoTable
      rowTemplate={PRODUCTS_RELEASES_ROW_TEMPLATE}
      edges={flattenedEdges}
      refetchCounter={props.refetchCounter}
      onChangeHandler={onChangeHandler}
      paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
      rowProps={{
        handleLinkClick: onLinkClickHandler
      }}
    />
  );
};

export default withUserContext(
  withRouter(
    createRefetchContainer(
      ProductReleasesList,
      {
        product: graphql`
          fragment ProductReleasesList_product on ProductNode {
            id
            releases(first: $first, orderBy: $orderBy, after: $after) {
              pageInfo {
                hasNextPage
                endCursor
              }
              totalCount
              edgeCount
              edges {
                node {
                  id
                  name
                  numberOfDeliverablesWithoutCoordinator
                  deadline
                  hasIncompleteDeliverables
                }
              }
            }
          }
        `
      },
      graphql`
        query ProductReleasesListRefetchQuery(
          $id: ID!
          $first: Int
          $orderBy: String
          $after: String
        ) {
          product(id: $id) {
            ...ProductReleasesList_product
          }
        }
      `
    )
  )
);

import React from 'react';
import StyledTableCell from './StyledTableCell';
import { useTableStyles } from '../../styles/common/tableStyles';
import SquareButton from '../UI/SquareButton';

const TableCellActions = props => {
  const {
    onClickDuplicate,
    onClickTrash,
    hideIconDuplicate,
    hideIconTrash,
    duplicateTooltip,
    trashTooltip
  } = props;
  const classes = useTableStyles();

  const duplicateButtonVariables = {
    icon: 'duplicate',
    variant: 'transparent',
    tooltipTitle: duplicateTooltip ? duplicateTooltip : 'Duplicate'
  };

  const trashButtonVariables = {
    icon: 'trash',
    variant: 'transparent',
    tooltipTitle: trashTooltip ? trashTooltip : 'Delete'
  };

  return (
    <StyledTableCell className={classes.actionsCell} noWrap>
      {!hideIconDuplicate && (
        <SquareButton {...duplicateButtonVariables} onClick={onClickDuplicate} />
      )}
      {!hideIconTrash && <SquareButton {...trashButtonVariables} onClick={onClickTrash} />}
    </StyledTableCell>
  );
};

export default TableCellActions;

import React, { useEffect, Fragment } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Typography, Box } from '@material-ui/core';

import SolicitationQuickViewContractorList from './SolicitationQuickViewContractorList';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import useQuickViewStyles from '../../../../shared/styles/common/useQuickViewStyles';

const SolicitationQuickView = props => {
  const {
    solicitationId,
    solicitation: { solicitation },
    relay: { refetch }
  } = props;
  const classes = useQuickViewStyles();

  useEffect(() => {
    refetch({ id: solicitationId });
  }, [solicitationId]);

  if (solicitation) {
    const { subject, message } = solicitation;

    return (
      <Fragment>
        <Box className={classes.quickViewHeader}>
          <Typography variant="h1">{subject}</Typography>
        </Box>
        <DetailsSection title="JobOpp Message">
          <Typography variant="body1">{message}</Typography>
        </DetailsSection>
        <DetailsSection title="Contractor List" noBorder>
          <SolicitationQuickViewContractorList solicitation={solicitation} />
        </DetailsSection>
      </Fragment>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default createRefetchContainer(
  SolicitationQuickView,
  {
    solicitation: graphql`
      fragment SolicitationQuickView_solicitation on Query
        @argumentDefinitions(id: { type: "ID!", defaultValue: "" }) {
        solicitation(id: $id) {
          subject
          message
          ...SolicitationQuickViewContractorList_solicitation
        }
      }
    `
  },
  graphql`
    query SolicitationQuickViewRefetchQuery($id: ID!) {
      ...SolicitationQuickView_solicitation @arguments(id: $id)
    }
  `
);

import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HelpIcon from '@material-ui/icons/Help';

import TopBarOrganizationNotification from './TopBarOrganizationNotification';
import appStyles from '../../../shared/styles/common/base';
import GlobalCreate from '../../../shared/components/common/GlobalCreate';
import GlobalCreateAdmin from '../../../shared/components/common/GlobalCreateAdmin';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import Avatar from '../../../shared/components/common/Avatar';
import authService from '../../../shared/services/authService';
import { ALLOWED_ACTIONS } from '../../../shared/constants';
import { showIntercom, IC_TARGETS } from '../../../shared/services/intercomService';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import { NotificationsButton } from '../../../shared/components/common/TopBarNotification';

export const adminOptions = {
  contractTemplate: {
    name: 'contractTemplate',
    buttonName: 'Add Contract Template',
    modalTitle: 'Add New Contract Template',
    pageName: '/contracts'
  },
  staffRolesManagement: {
    name: 'staffRolesManagement',
    buttonName: 'Create Role',
    modalTitle: 'Create Role',
    pageName: '/staff-management/roles'
  },
  staffManagement: {
    name: 'staffManagement',
    buttonName: 'Add New User',
    modalTitle: 'Add New User',
    pageName: '/staff-management'
  }
};

const TopBar = props => {
  const classes = appStyles();
  const {
    userContext,
    history: {
      location: { pathname }
    },
    contextActions
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(anchorEl);
  const navigation = JSON.parse(userContext.orgStaff.organization.configuration).navigation;

  const options = {
    products: {
      name: 'products',
      pageName: '/products',
      buttonName: `Create ${navigation.productTitle.singular}`,
      modalTitle: `Create New ${navigation.productTitle.singular}`,
      detailsName: 'productDetails',
      allowedActionName: ALLOWED_ACTIONS.PRODUCT_CREATE
    },
    releases: {
      name: 'releases',
      pageName: '/releases',
      buttonName: `Create ${navigation.releaseTitle.singular}`,
      modalTitle: `Create New ${navigation.releaseTitle.singular}`,
      detailsName: 'releases',
      allowedActionName: ALLOWED_ACTIONS.RELEASE_CREATE
    },
    deliverables: {
      name: 'deliverables',
      pageName: '/deliverables',
      buttonName: 'Create Deliverable',
      modalTitle: 'Create New Deliverable',
      detailsName: 'deliverables',
      allowedActionName: ALLOWED_ACTIONS.DELIVERABLE_CREATE
    },
    importDeliverables: {
      name: 'importDeliverables',
      buttonName: 'Import Deliverables',
      modalTitle: 'Import Deliverables',
      allowedActionName: ALLOWED_ACTIONS.DELIVERABLE_CREATE
    },
    solicitations: {
      name: 'solicitations',
      pageName: '/solicitations',
      buttonName: 'Create JobOpp',
      modalTitle: 'Create New JobOpp',
      detailsName: 'solicitations',
      allowedActionName: ALLOWED_ACTIONS.SOLICITATION_CREATE
    },
    batchDeliverables: {
      name: 'batchDeliverables',
      buttonName: 'Create Batch Deliverables',
      modalTitle: 'Create New Batch Deliverables',
      allowedActionName: ALLOWED_ACTIONS.DELIVERABLE_CREATE
    },
    jobs: {
      name: 'jobs',
      pageName: '/jobs',
      buttonName: 'Create Job',
      modalTitle: 'Create New Job',
      detailsName: 'jobs',
      allowedActionName: [
        ALLOWED_ACTIONS.JOB_CREATE_WITHOUT_CONTRACT,
        ALLOWED_ACTIONS.JOB_SIGN_AND_SEND_CONTRACT
      ]
    },
    contractors: {
      name: 'contractors',
      pageName: '/contractors',
      buttonName: 'Add Contractor',
      modalTitle: 'Add New Contractor',
      detailsName: 'contractors'
    },
    // context actions
    jobWithDeliverables: {
      name: 'jobWithDeliverables',
      buttonName: 'Create Job with Deliverables',
      modalTitle: 'Create New Job With Deliverables',
      pageName: '/deliverables',
      isContextual: true,
      allowedActionName: [
        ALLOWED_ACTIONS.JOB_CREATE_WITHOUT_CONTRACT,
        ALLOWED_ACTIONS.JOB_SIGN_AND_SEND_CONTRACT
      ]
    },
    jobWithDeliverablesSolicitations: {
      name: 'jobWithDeliverablesSolicitations',
      buttonName: 'Create Job with JobOpp Deliverables',
      modalTitle: 'Create New Job With JobOpp Deliverables',
      pageName: '/solicitations',
      isContextual: true,
      allowedActionName: [
        ALLOWED_ACTIONS.JOB_CREATE_WITHOUT_CONTRACT,
        ALLOWED_ACTIONS.JOB_SIGN_AND_SEND_CONTRACT
      ]
    },
    productDetails: {
      name: 'productDetails',
      pageName: '/products/',
      buttonName: `Create ${navigation.releaseTitle.singular} For ${navigation.productTitle.singular}`,
      modalTitle: `Create New ${navigation.releaseTitle.singular} For ${navigation.productTitle.singular}`,
      isContextual: true,
      allowedActionName: ALLOWED_ACTIONS.RELEASE_CREATE
    },
    batchReleaseDetails: {
      name: 'batchReleaseDetails',
      pageName: '/releases/',
      buttonName: `Create Batch Deliverables For ${navigation.releaseTitle.singular}`,
      modalTitle: `Create New Batch Deliverables For ${navigation.releaseTitle.singular}`,
      isContextual: true,
      allowedActionName: ALLOWED_ACTIONS.DELIVERABLE_CREATE
    },
    releaseDetailsDeliverable: {
      name: 'releaseDetailsDeliverable',
      pageName: '/releases/',
      buttonName: `Create Deliverable For ${navigation.releaseTitle.singular}`,
      modalTitle: `Create New Deliverable For ${navigation.releaseTitle.singular}`,
      isContextual: true,
      allowedActionName: ALLOWED_ACTIONS.DELIVERABLE_CREATE
    },
    duplicateRelease: {
      name: 'duplicateRelease',
      pageName: '/releases/',
      buttonName: `Create ${navigation.releaseTitle.singular} Using This Budget`,
      modalTitle: `Create ${navigation.releaseTitle.singular} Using an Existing Budget`,
      isContextual: true,
      allowedActionName: ALLOWED_ACTIONS.RELEASE_CREATE
    },
    solicitationWithDeliverables: {
      name: 'solicitationWithDeliverables',
      buttonName: 'Create JobOpp with Deliverables',
      modalTitle: 'Create New JobOpp With Deliverables',
      pageName: '/deliverables',
      isContextual: true,
      allowedActionName: ALLOWED_ACTIONS.SOLICITATION_CREATE
    },
    solicitationWithContractors: {
      name: 'solicitationWithContractors',
      pageName: '/contractors',
      buttonName: 'Create JobOpp With Contractors',
      modalTitle: 'Create New JobOpp With Contractors',
      isContextual: true,
      allowedActionName: ALLOWED_ACTIONS.SOLICITATION_CREATE
    },
    contractorGroupWithContractors: {
      name: 'contractorGroupWithContractors',
      pageName: '/contractors',
      buttonName: 'Create Contractor Group With Contractors',
      modalTitle: 'Create New Group With Contractors',
      isContextual: true,
      allowedActionName: ALLOWED_ACTIONS.CONTRACTOR_GROUP_CREATE
    },
    profile: {
      name: 'profile',
      pageName: '/profile',
      buttonName: 'Create Deliverable',
      modalTitle: 'Create New Deliverable',
      isContextual: true, //a little hacky but this allows to set default buttonName for page without duplicating option in the dropdown
      allowedActionName: ALLOWED_ACTIONS.DELIVERABLE_CREATE
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography className={classes.dividerText} align="center" display="block">
        User Profile
      </Typography>
      <Divider component="div" />
      <MenuItem className={classes.topBarMenuItemOrg} onClick={handleOpenUserProfile}>
        Your Profile
      </MenuItem>
      <Divider component="div" />
      <MenuItem className={classes.topBarMenuItemOrg} onClick={handleLogout}>
        Log out
      </MenuItem>
    </Menu>
  );

  const getGlobalCreateSelectedForPage = () => {
    for (const key in options) {
      if (pathname.includes(options[key].pageName)) {
        const option = options[key];
        if (pathname.includes('/details')) {
          return options[option.detailsName];
        } else {
          return option;
        }
      }
    }
  };

  const getAdminOptionSelectedForPage = () => {
    for (const key in adminOptions) {
      if (adminOptions[key].pageName && pathname.includes(adminOptions[key].pageName)) {
        return adminOptions[key];
      }
    }
    return null;
  };

  const getGlobalCreate = () => {
    //check if pathname contains /admin and return
    //global create for admin or organization
    if (pathname.includes('/admin/')) {
      return (
        <GlobalCreateAdmin
          props={props}
          getAdminOptionSelectedForPage={getAdminOptionSelectedForPage}
          options={adminOptions}
          pathname={pathname}
        />
      );
    } else {
      return (
        <GlobalCreate
          props={props}
          options={options}
          contextActions={contextActions}
          getGlobalCreateSelectedForPage={getGlobalCreateSelectedForPage}
          pathname={pathname}
        />
      );
    }
  };

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleOpenUserProfile() {
    const { history } = props;
    history.push(`/profile`);
    setAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleContactSupport() {
    setAnchorEl(null);
    showIntercom();
  }

  function handleLogout() {
    authService.signout();
  }

  return (
    <Fragment>
      <AppBar position="relative" className={clsx(classes.appBar)} color="inherit">
        <Toolbar className={classes.appBarToolbar}>
          <div>{getGlobalCreate()}</div>
          <div className={classes.appBarRight}>
            <IconButton
              aria-label={`Contact Support`}
              color="inherit"
              onClick={handleContactSupport}
              data-intercom-target={IC_TARGETS.helpButton}
            >
              <HelpIcon />
            </IconButton>
            <QueryRenderer
              query={graphql`
                query TopBarOrganizationNotificationsQuery {
                  ...TopBarOrganizationNotification_notifications
                }
              `}
              render={relayProps => <TopBarOrganizationNotification notifications={relayProps} />}
              renderLoading={() => <NotificationsButton disabled />}
            />
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              className={classes.currentUserIcon}
              data-intercom-target={IC_TARGETS.profileButton}
            >
              <Avatar alt={`Your avatar`} src={userContext.representativeImageUrl} />
              <Typography variant="body2">{`${userContext.firstName} ${userContext.lastName}`}</Typography>
              <KeyboardArrowDownIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Fragment>
  );
};

export default withRouter(withUserContext(TopBar));

/**
 * @flow
 * @relayHash 2b96207893ac37fae9e51e771059a935
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CreateInvoiceForm_invoiceTypes$ref = any;
type CreateInvoiceForm_job$ref = any;
type CreateInvoiceForm_paymentSettings$ref = any;
export type CreateInvoiceFormRendererQueryVariables = {|
  jobId: string
|};
export type CreateInvoiceFormRendererQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: CreateInvoiceForm_job$ref
  |},
  +paymentSettings: ?{|
    +$fragmentRefs: CreateInvoiceForm_paymentSettings$ref
  |},
  +invoiceTypes: ?{|
    +$fragmentRefs: CreateInvoiceForm_invoiceTypes$ref
  |},
|};
export type CreateInvoiceFormRendererQuery = {|
  variables: CreateInvoiceFormRendererQueryVariables,
  response: CreateInvoiceFormRendererQueryResponse,
|};
*/


/*
query CreateInvoiceFormRendererQuery(
  $jobId: ID!
) {
  job(id: $jobId) {
    ...CreateInvoiceForm_job
    id
  }
  paymentSettings {
    ...CreateInvoiceForm_paymentSettings
    id
  }
  invoiceTypes: __type(name: "JobInvoiceInvoiceType") {
    ...CreateInvoiceForm_invoiceTypes
  }
}

fragment CreateInvoiceForm_job on JobNode {
  id
  jobId
  name
  balance
  pendingInvoiceTotals
  currencyCode
  conversionRateInPreferredCurrency
  staffCoordinator {
    fullName
    representativeImageUrl
    organization {
      name
      id
    }
    id
  }
  invoices {
    totalCount
  }
}

fragment CreateInvoiceForm_paymentSettings on FreelancerPaymentConfigurationNode {
  preferredCurrency
  paymentTerm
  paymentInfo
  includeVat
  vatPercentage
  taxPercentage
  taxIdentifier
}

fragment CreateInvoiceForm_invoiceTypes on __Type {
  enumValues {
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "jobId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "jobId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "JobInvoiceInvoiceType"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateInvoiceFormRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CreateInvoiceForm_job",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "paymentSettings",
        "storageKey": null,
        "args": null,
        "concreteType": "FreelancerPaymentConfigurationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CreateInvoiceForm_paymentSettings",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "invoiceTypes",
        "name": "__type",
        "storageKey": "__type(name:\"JobInvoiceInvoiceType\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CreateInvoiceForm_invoiceTypes",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateInvoiceFormRendererQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "jobId",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "balance",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pendingInvoiceTotals",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currencyCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "conversionRateInPreferredCurrency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffCoordinator",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "representativeImageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ]
              },
              (v3/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "invoices",
            "storageKey": null,
            "args": null,
            "concreteType": "InvoiceNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "paymentSettings",
        "storageKey": null,
        "args": null,
        "concreteType": "FreelancerPaymentConfigurationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "preferredCurrency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "paymentTerm",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "paymentInfo",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "includeVat",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "vatPercentage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "taxPercentage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "taxIdentifier",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "invoiceTypes",
        "name": "__type",
        "storageKey": "__type(name:\"JobInvoiceInvoiceType\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CreateInvoiceFormRendererQuery",
    "id": null,
    "text": "query CreateInvoiceFormRendererQuery(\n  $jobId: ID!\n) {\n  job(id: $jobId) {\n    ...CreateInvoiceForm_job\n    id\n  }\n  paymentSettings {\n    ...CreateInvoiceForm_paymentSettings\n    id\n  }\n  invoiceTypes: __type(name: \"JobInvoiceInvoiceType\") {\n    ...CreateInvoiceForm_invoiceTypes\n  }\n}\n\nfragment CreateInvoiceForm_job on JobNode {\n  id\n  jobId\n  name\n  balance\n  pendingInvoiceTotals\n  currencyCode\n  conversionRateInPreferredCurrency\n  staffCoordinator {\n    fullName\n    representativeImageUrl\n    organization {\n      name\n      id\n    }\n    id\n  }\n  invoices {\n    totalCount\n  }\n}\n\nfragment CreateInvoiceForm_paymentSettings on FreelancerPaymentConfigurationNode {\n  preferredCurrency\n  paymentTerm\n  paymentInfo\n  includeVat\n  vatPercentage\n  taxPercentage\n  taxIdentifier\n}\n\nfragment CreateInvoiceForm_invoiceTypes on __Type {\n  enumValues {\n    name\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83c30603e0c0af41a332ade005b57fd8';
module.exports = node;

import React from 'react';
import { Formik, Form, Field } from 'formik';
import AutoTabListWithoutRouting from '../../../../shared/components/UI/AutoTabListWithoutRouting';

import FormikDateField from '../../../../shared/components/form/FormikDateField';
import FormikTextField from '../../../../shared/components/form/FormikTextField';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import { formatDateForQueries } from '../../../../shared/utils/formatters';
import appStyles from '../../../styles/modalForms';
import { successToast } from '../../../../shared/toasts';
import CreateSolicitationMutation from '../mutations/CreateSolicitationMutation';
import DeliverableTab from './DeliverableTab';
import ContractorTab from './ContractorTab';
import { yupSchemas } from '../../../../shared/validations';

const CreateSolicitationForm = props => {
  const { handleClose, toRefetchData, contextAction, isContextual } = props;
  const classes = appStyles();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const variables = formatVariables(values);
    CreateSolicitationMutation(variables, response => {
      setSubmitting(false);
      if (response && response.newSolicitation) {
        handleClose();
        setTimeout(() => {
          successToast('New JobOpp has been created.', {
            text: 'Go to JobOpp',
            link: `/solicitations/${response.newSolicitation.id}`
          });
        }, 250);
        toRefetchData();
      }
    });
  };

  const formatVariables = values => {
    return {
      subject: values.subject,
      ...(values.replyByDate && { replyByDate: formatDateForQueries(values.replyByDate) }),
      ...(values.message && { message: values.message }),
      ...(values.deliverables && {
        deliverables: mapEntitiesToIds(values.deliverables)
      }),
      ...(values.contractors && { contractors: mapEntitiesToIds(values.contractors) })
    };
  };

  const mapEntitiesToIds = entities => {
    return entities.map(entity => entity.id);
  };

  const validationSchema = yupSchemas.shape({
    subject: yupSchemas.string(true),
    message: yupSchemas.string(false, { max: 5000 })
  });

  const initialValues = {
    subject: '',
    message: '',
    replyByDate: null,
    deliverables:
      isContextual && contextAction.name === 'solicitationWithDeliverables'
        ? contextAction.data.checked
        : [],
    contractors:
      isContextual && contextAction.name === 'solicitationWithContractors'
        ? contextAction.data.checked
        : []
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <div className={classes.formRow}>
            <div className={classes.halfWidthField}>
              <Field
                required
                name="subject"
                component={FormikTextField}
                label="JobOpp Subject"
                fullWidth
              />
            </div>
            <div className={classes.halfWidthField}>
              <Field
                name="replyByDate"
                component={FormikDateField}
                label="Reply By Date"
                fullWidth
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <Field
              fullWidth
              multiline
              rows="7"
              name="message"
              component={FormikTextField}
              label="Message"
            />
          </div>

          <div className={classes.buttons}>
            <GlobalButton variant="transparent" handleClick={handleClose}>
              Cancel
            </GlobalButton>
            <GlobalButton type="submit" id="submitSolicitation" disabled={isSubmitting}>
              Save draft
            </GlobalButton>
          </div>
          <AutoTabListWithoutRouting initialTabIndex={initialValues.deliverables.length ? 1 : 0}>
            <Field component={ContractorTab} label="Contractors" className={classes.formTabBox} />
            <Field component={DeliverableTab} label="Deliverables" className={classes.formTabBox} />
          </AutoTabListWithoutRouting>
        </Form>
      )}
    </Formik>
  );
};

export default CreateSolicitationForm;

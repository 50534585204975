/**
 * @flow
 * @relayHash 1da1bce77eb7a3aee17df5e978e8f3de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type validateContractPdfQueryVariables = {|
  contractor: string,
  name: string,
  description: string,
  category: string,
  contractTemplate: string,
  jobDeadline?: ?any,
  contractSignDeadline?: ?any,
  totalValue?: ?any,
  deliverables?: ?$ReadOnlyArray<?string>,
  contractDate?: ?any,
|};
export type validateContractPdfQueryResponse = {|
  +validateContractPdf: ?{|
    +errors: ?any
  |}
|};
export type validateContractPdfQuery = {|
  variables: validateContractPdfQueryVariables,
  response: validateContractPdfQueryResponse,
|};
*/


/*
query validateContractPdfQuery(
  $contractor: ID!
  $name: String!
  $description: String!
  $category: ID!
  $contractTemplate: ID!
  $jobDeadline: Date
  $contractSignDeadline: Date
  $totalValue: Decimal
  $deliverables: [ID]
  $contractDate: Date
) {
  validateContractPdf(contractor: $contractor, name: $name, description: $description, category: $category, contractTemplate: $contractTemplate, jobDeadline: $jobDeadline, contractSignDeadline: $contractSignDeadline, totalValue: $totalValue, deliverables: $deliverables, contractDate: $contractDate) {
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contractor",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "description",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractTemplate",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadline",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractSignDeadline",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "totalValue",
    "type": "Decimal",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "deliverables",
    "type": "[ID]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractDate",
    "type": "Date",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "validateContractPdf",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "category",
        "variableName": "category"
      },
      {
        "kind": "Variable",
        "name": "contractDate",
        "variableName": "contractDate"
      },
      {
        "kind": "Variable",
        "name": "contractSignDeadline",
        "variableName": "contractSignDeadline"
      },
      {
        "kind": "Variable",
        "name": "contractTemplate",
        "variableName": "contractTemplate"
      },
      {
        "kind": "Variable",
        "name": "contractor",
        "variableName": "contractor"
      },
      {
        "kind": "Variable",
        "name": "deliverables",
        "variableName": "deliverables"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "jobDeadline",
        "variableName": "jobDeadline"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "totalValue",
        "variableName": "totalValue"
      }
    ],
    "concreteType": "ValidateContractPDF",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "errors",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "validateContractPdfQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "validateContractPdfQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "validateContractPdfQuery",
    "id": null,
    "text": "query validateContractPdfQuery(\n  $contractor: ID!\n  $name: String!\n  $description: String!\n  $category: ID!\n  $contractTemplate: ID!\n  $jobDeadline: Date\n  $contractSignDeadline: Date\n  $totalValue: Decimal\n  $deliverables: [ID]\n  $contractDate: Date\n) {\n  validateContractPdf(contractor: $contractor, name: $name, description: $description, category: $category, contractTemplate: $contractTemplate, jobDeadline: $jobDeadline, contractSignDeadline: $contractSignDeadline, totalValue: $totalValue, deliverables: $deliverables, contractDate: $contractDate) {\n    errors\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5442ed14dc1fa7e0e978afe4d0bc9bff';
module.exports = node;

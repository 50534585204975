/**
 * @flow
 * @relayHash 51934ac8ee958cf027d4b836d7f1cc7c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractorsList_contractors$ref = any;
export type ContractorsListRefetchQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
  includeInactive?: ?boolean,
  includeDoNotHire?: ?boolean,
  contractorName?: ?string,
  inviteStatus?: ?string,
  country?: ?string,
  release?: ?string,
  product?: ?string,
  tags?: ?string,
  minCompletedDeliverables?: ?number,
  group?: ?string,
|};
export type ContractorsListRefetchQueryResponse = {|
  +$fragmentRefs: ContractorsList_contractors$ref
|};
export type ContractorsListRefetchQuery = {|
  variables: ContractorsListRefetchQueryVariables,
  response: ContractorsListRefetchQueryResponse,
|};
*/


/*
query ContractorsListRefetchQuery(
  $first: Int
  $orderBy: String
  $after: String
  $includeInactive: Boolean
  $includeDoNotHire: Boolean
  $contractorName: String
  $inviteStatus: String
  $country: String
  $release: String
  $product: String
  $tags: String
  $minCompletedDeliverables: Float
  $group: String
) {
  ...ContractorsList_contractors
}

fragment ContractorsList_contractors on Query {
  contractorsByOrg(first: $first, orderBy: $orderBy, after: $after, contractorName: $contractorName, includeDoNotHire: $includeDoNotHire, includeInactive: $includeInactive, inviteStatus: $inviteStatus, country: $country, release: $release, product: $product, tags: $tags, minCompletedDeliverables: $minCompletedDeliverables, group: $group) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        doNotHire
        representativeImageUrl
        activeDeliverablesCount
        pastDueDeliverablesCount
        completedDeliverablesCount
        fullName
        lastName
        isActive
        inviteStatus
        conflictingContractor {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeDoNotHire",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractorName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "inviteStatus",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "country",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "release",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "product",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tags",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "minCompletedDeliverables",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "group",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractorsListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ContractorsList_contractors",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractorsListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractorsByOrg",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "contractorName",
            "variableName": "contractorName"
          },
          {
            "kind": "Variable",
            "name": "country",
            "variableName": "country"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "group",
            "variableName": "group"
          },
          {
            "kind": "Variable",
            "name": "includeDoNotHire",
            "variableName": "includeDoNotHire"
          },
          {
            "kind": "Variable",
            "name": "includeInactive",
            "variableName": "includeInactive"
          },
          {
            "kind": "Variable",
            "name": "inviteStatus",
            "variableName": "inviteStatus"
          },
          {
            "kind": "Variable",
            "name": "minCompletedDeliverables",
            "variableName": "minCompletedDeliverables"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          },
          {
            "kind": "Variable",
            "name": "product",
            "variableName": "product"
          },
          {
            "kind": "Variable",
            "name": "release",
            "variableName": "release"
          },
          {
            "kind": "Variable",
            "name": "tags",
            "variableName": "tags"
          }
        ],
        "concreteType": "ContractorNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPreviousPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "doNotHire",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "representativeImageUrl",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "activeDeliverablesCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pastDueDeliverablesCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "completedDeliverablesCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fullName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isActive",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "inviteStatus",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "conflictingContractor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractorsListRefetchQuery",
    "id": null,
    "text": "query ContractorsListRefetchQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n  $includeInactive: Boolean\n  $includeDoNotHire: Boolean\n  $contractorName: String\n  $inviteStatus: String\n  $country: String\n  $release: String\n  $product: String\n  $tags: String\n  $minCompletedDeliverables: Float\n  $group: String\n) {\n  ...ContractorsList_contractors\n}\n\nfragment ContractorsList_contractors on Query {\n  contractorsByOrg(first: $first, orderBy: $orderBy, after: $after, contractorName: $contractorName, includeDoNotHire: $includeDoNotHire, includeInactive: $includeInactive, inviteStatus: $inviteStatus, country: $country, release: $release, product: $product, tags: $tags, minCompletedDeliverables: $minCompletedDeliverables, group: $group) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        doNotHire\n        representativeImageUrl\n        activeDeliverablesCount\n        pastDueDeliverablesCount\n        completedDeliverablesCount\n        fullName\n        lastName\n        isActive\n        inviteStatus\n        conflictingContractor {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5987877685fb66e5348a889132a653ea';
module.exports = node;

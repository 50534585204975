import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../../shared/components/common/ConfirmationDialogV2';

const getCurrentUserText = ({ jobIds, follow }) => {
  const actionStr = follow ? 'follow' : 'unfollow';

  return jobIds && jobIds.length > 1
    ? `You’ve opted to ${actionStr} multiple Jobs. Would you like to ${actionStr} all Deliverables associated with the selected Jobs as well?`
    : `You’ve opted to ${actionStr} a Job. Would you like to ${actionStr} all Deliverables associated with this Job as well?`;
};

const getOtherUserText = ({ follow }) => {
  const jobActionStr = follow ? 'added' : 'removed';
  const deliverableActionStr = follow ? 'add' : 'remove';
  const nodeDirectionStr = follow ? 'to' : 'from';

  return `You’ve ${jobActionStr} someone as a follower ${nodeDirectionStr} a Job. Would you like to ${deliverableActionStr} them as a follower ${nodeDirectionStr} all Deliverables associated with this Job as well?`;
};

const ConfirmJobFollow = props => {
  const { follow, isForCurrentUser, jobIds, onSubmit, ...dialogProps } = props;

  return (
    <ConfirmationDialog
      {...dialogProps}
      title={`${follow ? 'Follow' : 'Unfollow'} Job Deliverables`}
      content={isForCurrentUser ? getCurrentUserText(props) : getOtherUserText(props)}
      copies={{
        cancel: 'No',
        confirm: 'Yes'
      }}
      onReject={() => onSubmit(false)}
      onConfirm={() => onSubmit(true)}
    />
  );
};

ConfirmJobFollow.propTypes = {
  follow: PropTypes.bool,
  jobs: PropTypes.array,
  isForCurrentUser: PropTypes.bool
};

ConfirmJobFollow.defaultProps = {
  isForCurrentUser: true
};

export default ConfirmJobFollow;

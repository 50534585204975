import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import UpdateNotificationSettingsMutation from '../../mutations/profile/UpdateNotificationSettingsMutation';
import StyledSwitch from '../UI/StyledSwitch';
import DetailsSection from '../common/DetailsSection';
import Select from '../form/fields/Select';

const FlexBox = styled(Box)({
  display: 'flex'
});

const FlexBoxColumn = styled(FlexBox)({
  flexDirection: 'column'
});

const FlexBoxColumn75 = styled(FlexBoxColumn)({
  width: '75%',
  marginRight: '10px'
});

const FlexBoxColumn50 = styled(FlexBoxColumn)({
  width: '50%'
});

const NotificationSettings = props => {
  const {
    notificationConfig,
    receiveSummaryEmails,
    notificationSummaryInterval,
    intervalValues,
    settingsOptions: SETTINGS,
    firstColumnSections,
    secondColumnSections
  } = props;

  const emailNotifications = JSON.parse(notificationConfig);

  const initialValues = Object.assign(
    { receiveSummaryEmails, notificationSummaryInterval },
    ...Object.entries(SETTINGS).map(([k, v]) => ({ [k]: emailNotifications[v.category][v.name] }))
  );

  const submitHandler = variables => {
    UpdateNotificationSettingsMutation(variables, () => {});
  };

  const handleToggle = (name, value) => {
    const configDict = Object.assign(
      {},
      ...Object.entries(SETTINGS)
        .filter(([k, v]) => v.category === SETTINGS[name].category)
        .map(([k, v]) => ({ [v.name]: k === name ? value : initialValues[k] }))
    );
    submitHandler({ config: JSON.stringify({ [SETTINGS[name].category]: configDict }) });
  };

  const handleSummaryToggle = value => {
    submitHandler({ receiveSummaryEmails: value });
  };

  const handleIntervalChange = (name, value) => {
    submitHandler({ interval: value.value });
  };

  const renderSectionLabel = section => (
    <FlexBox mb="12px" mt="12px">
      <Typography style={{ width: '75%' }} variant="overline">
        {section.label}
      </Typography>
      {section.emailLabel && (
        <Typography variant="overline" style={{ paddingLeft: '22px' }}>
          Email
        </Typography>
      )}
    </FlexBox>
  );

  const renderSectionItem = sectionItem => (
    <FlexBox key={sectionItem} pb="12px">
      <FlexBoxColumn75>
        <Typography variant="body2">{SETTINGS[sectionItem].title}</Typography>
        <Typography variant="body1">{SETTINGS[sectionItem].description}</Typography>
      </FlexBoxColumn75>
      <FlexBox>
        <StyledSwitch
          checked={initialValues[sectionItem]}
          name={sectionItem}
          onChange={e => handleToggle(sectionItem, e.target.checked)}
        />
      </FlexBox>
    </FlexBox>
  );

  const renderSectionColumn = sectionColumn => (
    <FlexBoxColumn50>
      {sectionColumn.map(section => {
        return (
          <FlexBoxColumn key={section.label}>
            {renderSectionLabel(section)}
            {section.order.map(sectionItem => renderSectionItem(sectionItem))}
          </FlexBoxColumn>
        );
      })}
    </FlexBoxColumn50>
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={9}>
        <DetailsSection noBorder title="Email Notification Settings">
          <FlexBox>
            {renderSectionColumn(firstColumnSections)}
            {renderSectionColumn(secondColumnSections)}
          </FlexBox>
        </DetailsSection>
      </Grid>

      <Grid item md={9}>
        <DetailsSection noBorder title="Summary Settings">
          <FlexBoxColumn50>
            <FlexBox>
              <FlexBoxColumn75 mb="12px">
                <Typography variant="body2">Summary Email</Typography>
                <Typography variant="body1">
                  Receive an email alerting you to unread notifications from within the specified
                  interval.
                </Typography>
              </FlexBoxColumn75>
              <FlexBox>
                <StyledSwitch
                  checked={initialValues.receiveSummaryEmails}
                  name="receiveSummaryEmails"
                  onChange={e => handleSummaryToggle(e.target.checked)}
                />
              </FlexBox>
            </FlexBox>
            <FlexBoxColumn50>
              <Select
                id="intervalDropdown"
                options={intervalValues.enumValues.map(enumValue => ({
                  value: enumValue.name,
                  label: enumValue.description
                }))}
                label="Summary Interval"
                name="interval"
                noClear
                value={{
                  value: notificationSummaryInterval,
                  label: intervalValues.enumValues.find(
                    enumValue => enumValue.name === notificationSummaryInterval
                  ).description
                }}
                onChange={handleIntervalChange}
              />
            </FlexBoxColumn50>
          </FlexBoxColumn50>
        </DetailsSection>
      </Grid>
    </Grid>
  );
};

export default NotificationSettings;

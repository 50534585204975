import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const UpdateFreelancerEmailRequestMutation = (newEmail, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateFreelancerEmailRequestMutation($input: UpdateFreelancerEmailRequestInput!) {
        updateFreelancerEmailRequest(input: $input) {
          emailSent
          emailAlreadyExists
        }
      }
    `,
    variables: {
      input: { newEmail }
    },
    onCompleted: (response, errors) => {
      callback(response.updateFreelancerEmailRequest, errors);
    },
    onError: err => console.error(err)
  });
};

export default UpdateFreelancerEmailRequestMutation;

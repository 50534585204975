/**
 * @flow
 * @relayHash e43789669d613ce900ab6f635a62e74c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InvoiceQuickViewContent_invoice$ref = any;
export type InvoiceQuickViewQueryVariables = {|
  id: string
|};
export type InvoiceQuickViewQueryResponse = {|
  +invoice: ?{|
    +$fragmentRefs: InvoiceQuickViewContent_invoice$ref
  |}
|};
export type InvoiceQuickViewQuery = {|
  variables: InvoiceQuickViewQueryVariables,
  response: InvoiceQuickViewQueryResponse,
|};
*/


/*
query InvoiceQuickViewQuery(
  $id: ID!
) {
  invoice(id: $id) {
    ...InvoiceQuickViewContent_invoice
    id
  }
}

fragment InvoiceQuickViewContent_invoice on InvoiceNode {
  id
  identifier
  status
  created
  amount
  message
  invoiceFileUrl
  verificationDate
  verificationMessage
  verificationBy {
    fullName
    representativeImageUrl
    id
  }
  markedPaymentSentDate
  markedPaymentSentMessage
  markedPaymentSentBy {
    fullName
    representativeImageUrl
    id
  }
  job {
    id
    name
    currencyCode
    staffCoordinator {
      fullName
      representativeImageUrl
      id
    }
    contractor {
      fullName
      representativeImageUrl
      id
    }
    ...CreatePaymentRequestDialog_job
  }
}

fragment CreatePaymentRequestDialog_job on JobNode {
  id
  jobId
  name
  balance
  totalValue
  currencyCode
  conversionRateInPreferredCurrency
  contractor {
    id
    fullName
    representativeImageUrl
    currency
    organization {
      financeEmail
      email
      id
    }
  }
  contract {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "InvoiceQuickViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invoice",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InvoiceQuickViewContent_invoice",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "InvoiceQuickViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invoice",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "InvoiceNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "identifier",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "invoiceFileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verificationDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verificationMessage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "verificationBy",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "markedPaymentSentDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "markedPaymentSentMessage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "markedPaymentSentBy",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currencyCode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "staffCoordinator",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": (v5/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contractor",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "currency",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "organization",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrganizationNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "financeEmail",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "jobId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "balance",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalValue",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "conversionRateInPreferredCurrency",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contract",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "InvoiceQuickViewQuery",
    "id": null,
    "text": "query InvoiceQuickViewQuery(\n  $id: ID!\n) {\n  invoice(id: $id) {\n    ...InvoiceQuickViewContent_invoice\n    id\n  }\n}\n\nfragment InvoiceQuickViewContent_invoice on InvoiceNode {\n  id\n  identifier\n  status\n  created\n  amount\n  message\n  invoiceFileUrl\n  verificationDate\n  verificationMessage\n  verificationBy {\n    fullName\n    representativeImageUrl\n    id\n  }\n  markedPaymentSentDate\n  markedPaymentSentMessage\n  markedPaymentSentBy {\n    fullName\n    representativeImageUrl\n    id\n  }\n  job {\n    id\n    name\n    currencyCode\n    staffCoordinator {\n      fullName\n      representativeImageUrl\n      id\n    }\n    contractor {\n      fullName\n      representativeImageUrl\n      id\n    }\n    ...CreatePaymentRequestDialog_job\n  }\n}\n\nfragment CreatePaymentRequestDialog_job on JobNode {\n  id\n  jobId\n  name\n  balance\n  totalValue\n  currencyCode\n  conversionRateInPreferredCurrency\n  contractor {\n    id\n    fullName\n    representativeImageUrl\n    currency\n    organization {\n      financeEmail\n      email\n      id\n    }\n  }\n  contract {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f94332b0776010d334880afaf6c3588';
module.exports = node;

/**
 * @flow
 * @relayHash e9c25f2c91e39b0750015cdfda790a1c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveReleaseBudgetInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type RemoveReleaseBudgetMutationVariables = {|
  input: RemoveReleaseBudgetInput
|};
export type RemoveReleaseBudgetMutationResponse = {|
  +removeReleaseBudget: ?{|
    +updatedRelease: ?{|
      +id: string,
      +totalBudget: ?any,
      +budgetInfo: ?$ReadOnlyArray<?{|
        +category: ?{|
          +id: string,
          +name: string,
        |},
        +budgetAmount: ?any,
      |}>,
    |}
  |}
|};
export type RemoveReleaseBudgetMutation = {|
  variables: RemoveReleaseBudgetMutationVariables,
  response: RemoveReleaseBudgetMutationResponse,
|};
*/


/*
mutation RemoveReleaseBudgetMutation(
  $input: RemoveReleaseBudgetInput!
) {
  removeReleaseBudget(input: $input) {
    updatedRelease {
      id
      totalBudget
      budgetInfo {
        category {
          id
          name
        }
        budgetAmount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveReleaseBudgetInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeReleaseBudget",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveReleaseBudgetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedRelease",
        "storageKey": null,
        "args": null,
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalBudget",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetInfo",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "budgetAmount",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RemoveReleaseBudgetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RemoveReleaseBudgetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RemoveReleaseBudgetMutation",
    "id": null,
    "text": "mutation RemoveReleaseBudgetMutation(\n  $input: RemoveReleaseBudgetInput!\n) {\n  removeReleaseBudget(input: $input) {\n    updatedRelease {\n      id\n      totalBudget\n      budgetInfo {\n        category {\n          id\n          name\n        }\n        budgetAmount\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c3af4eb98b2aa660b28a1d6518f8faa1';
module.exports = node;

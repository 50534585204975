import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import Placeholder from './Placeholder';
import { useEffectWithStatus } from '../../../../hooks/useEffectWithStatus';
import { errorToast } from '../../../../toasts';
/**
 * This components loads and renders FBX 3D files.
 *
 */
const FbxAsset = props => {
  const { src, centerCameraOn, theme, flatShading, handlers } = props;
  const [obj, setScene] = useState(null);

  const getFileName = path => {
    const arrayPath = path.split('/');
    // get the fileanem and query string
    const lastPath = arrayPath[arrayPath.length - 1];
    // get the file name by removing the query string part
    return lastPath.split('?')[0];
  };

  useEffectWithStatus(status => {
    const manager = new THREE.LoadingManager();
    let loader = new FBXLoader(manager);
    manager.setURLModifier(url => {
      // check if the file name is the file name in the source.
      // Split the url path
      const srcFileName = getFileName(src);
      const filename = getFileName(url);
      if (srcFileName !== filename) {
        errorToast(
          'Fbx file is using an external file: ' +
            filename +
            ', please use embedded fbx or a zip file.'
        );
      }
      return url;
    });
    loader.load(src, obj => {
      obj.traverse(function (child) {
        if (child.isMesh) {
          child.material.flatShading = flatShading;
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      status.mounted && setScene(obj);
    });
  }, []);

  useUpdateEffect(() => {
    centerCameraOn(obj);
  }, [obj]);

  return obj ? <primitive object={obj} {...handlers} /> : <Placeholder theme={theme} />;
};

FbxAsset.propTypes = {
  src: PropTypes.string.isRequired,
  centerCameraOn: PropTypes.func.isRequired,
  flatShading: PropTypes.bool,
  handlers: PropTypes.shape({
    onPointerMove: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onClick: PropTypes.func
  })
};

export default FbxAsset;

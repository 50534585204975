/**
 * @flow
 * @relayHash d079f4bd8f7387a4198fc30d3b2794ef
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateContractorNoteInput = {|
  content: string,
  id: string,
  rating?: ?number,
  repliedTo?: ?string,
  clientMutationId?: ?string,
|};
export type CreateContractorNoteMutationVariables = {|
  input: CreateContractorNoteInput
|};
export type CreateContractorNoteMutationResponse = {|
  +createContractorNote: ?{|
    +contractor: ?{|
      +contractorNotes: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +created: any,
            +staff: {|
              +user: {|
                +id: string,
                +fullName: ?string,
              |},
              +representativeImageUrl: ?string,
            |},
            +content: ?string,
            +repliedTo: ?{|
              +content: ?string,
              +created: ?any,
              +user: ?{|
                +fullName: ?string
              |},
            |},
          |}
        |}>
      |}
    |}
  |}
|};
export type CreateContractorNoteMutation = {|
  variables: CreateContractorNoteMutationVariables,
  response: CreateContractorNoteMutationResponse,
|};
*/


/*
mutation CreateContractorNoteMutation(
  $input: CreateContractorNoteInput!
) {
  createContractorNote(input: $input) {
    contractor {
      contractorNotes {
        edges {
          node {
            id
            created
            staff {
              user {
                id
                fullName
              }
              representativeImageUrl
              id
            }
            content
            repliedTo {
              content
              created
              user {
                fullName
                id
              }
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateContractorNoteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/)
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateContractorNoteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createContractorNote",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateContractorNotePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contractorNotes",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNoteNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNoteNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNoteNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "staff",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffNode",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          },
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "repliedTo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRepliedToNode",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v3/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateContractorNoteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createContractorNote",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateContractorNotePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contractorNotes",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNoteNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNoteNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNoteNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "staff",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffNode",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ]
                          },
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "repliedTo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRepliedToNode",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v3/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v2/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateContractorNoteMutation",
    "id": null,
    "text": "mutation CreateContractorNoteMutation(\n  $input: CreateContractorNoteInput!\n) {\n  createContractorNote(input: $input) {\n    contractor {\n      contractorNotes {\n        edges {\n          node {\n            id\n            created\n            staff {\n              user {\n                id\n                fullName\n              }\n              representativeImageUrl\n              id\n            }\n            content\n            repliedTo {\n              content\n              created\n              user {\n                fullName\n                id\n              }\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fbecd6b7fa9b2e8688c533863277ae0e';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrganizationSubscriptionTier = "BUSINESS" | "ENTERPRISE" | "ENTREPRENEUR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BillingPayment_organization$ref: FragmentReference;
declare export opaque type BillingPayment_organization$fragmentType: BillingPayment_organization$ref;
export type BillingPayment_organization = {|
  +billingAddress1: string,
  +billingAddress2: ?string,
  +billingCity: string,
  +billingCountry: ?{|
    +name: ?string,
    +code: ?string,
  |},
  +billingZipcode: string,
  +billingState: string,
  +phone: string,
  +activeSubscription: ?{|
    +tier: OrganizationSubscriptionTier,
    +autoRenew: boolean,
  |},
  +$refType: BillingPayment_organization$ref,
|};
export type BillingPayment_organization$data = BillingPayment_organization;
export type BillingPayment_organization$key = {
  +$data?: BillingPayment_organization$data,
  +$fragmentRefs: BillingPayment_organization$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "BillingPayment_organization",
  "type": "OrganizationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddress1",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddress2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingCity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "billingCountry",
      "storageKey": null,
      "args": null,
      "concreteType": "Country",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "code",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingZipcode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingState",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "activeSubscription",
      "storageKey": null,
      "args": null,
      "concreteType": "OrganizationSubscriptionNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tier",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "autoRenew",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'fa5700cefa3a172b62c1c2bc3b54c4cb';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CommentCommentType = "JOB_INVOICE" | "TEXT" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobCommentThread_job$ref: FragmentReference;
declare export opaque type JobCommentThread_job$fragmentType: JobCommentThread_job$ref;
export type JobCommentThread_job = {|
  +id: string,
  +staffCoordinator: {|
    +id: string
  |},
  +deliverableCoordinators: ?$ReadOnlyArray<?{|
    +id: string
  |}>,
  +comments: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +content: string,
        +created: any,
        +contentEditedAt: ?any,
        +extraData: ?any,
        +commentType: CommentCommentType,
        +repliedTo: ?{|
          +content: ?string,
          +created: ?any,
          +user: ?{|
            +fullName: ?string
          |},
        |},
        +user: ?{|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |},
      |}
    |}>
  |},
  +$refType: JobCommentThread_job$ref,
|};
export type JobCommentThread_job$data = JobCommentThread_job;
export type JobCommentThread_job$key = {
  +$data?: JobCommentThread_job$data,
  +$fragmentRefs: JobCommentThread_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "JobCommentThread_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "id",
      "type": "ID!",
      "defaultValue": ""
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffCoordinator",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "deliverableCoordinators",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": true,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "comments",
      "storageKey": null,
      "args": null,
      "concreteType": "CommentNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CommentNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CommentNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "contentEditedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "extraData",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "commentType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "repliedTo",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CommentRepliedToNode",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "user",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "representativeImageUrl",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6cbd8bd11b314271a39479f82902bab2';
module.exports = node;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { isEqual, isEmpty, uniq, trimStart, get } from 'lodash';

import { StyledTable } from './StyledTable';
import AutoTableHeader from './AutoTableHeader';
import AutoTableRows from './AutoTableRows';
import Pagination from '../lists/Pagination';
import { StyledTableBody } from './StyledTableBody';
import ActionButton from '../../components/filters/Action';
import FiltersContainer from './FiltersContainer';
import { formatFilterValuesForQuery } from '../../utils/helpers';
import EmptyListTextContainer from './EmptyListTextContainer';
import FilterButton from '../filters/FilterButton';
import FilterControls from '../filters/FilterControls';
import WhiteBox from '../common/WhiteBox';
import TableColGroup from './TableColGroup';
import { withTableHistoryContext } from '../../contexts/tableHistoryContext';

class AutoTable extends Component {
  state = {
    orderBy: this.props.tableHistoryContext.orderBy || this.props.initialOrderBy,
    cursorStack: this.props.tableHistoryContext.cursorStack,
    rowsPerPage: this.props.tableHistoryContext.rowsPerPage,
    checked: [],
    lastCheckedId: null,
    selectedId: this.props.selectedId,
    disabledActions: [],
    filters:
      this.props.tableHistoryContext.filters || get(this.props.filterProps, 'initialFilters') || {},
    activeFilters: null,
    filtersVisible: false,
    saveFiltersAsDefault: false
  };

  componentDidUpdate(prevProps, prevState) {
    this.cursorStackUpdateHandler(prevState.cursorStack);
    this.refetchCounterUpdateHandler(prevProps.refetchCounter);
    this.resetCursorAndRefetchCounterUpdateHandler(prevProps.resetCursorAndRefetchCounter);
    this.checkedUpdateHandler(prevState.checked);
    this.activeFiltersCheckHandler();
    this.actionsDisabledCheckHandler();
  }

  componentDidMount() {
    this.activeFiltersCheckHandler();
    this.actionsDisabledCheckHandler();
  }

  cursorStackUpdateHandler = prevCursorStack => {
    if (prevCursorStack !== this.state.cursorStack) {
      this.refetchWithVariables();
    }
  };

  refetchCounterUpdateHandler = prevRefetchCounter => {
    if (prevRefetchCounter !== this.props.refetchCounter) {
      this.refetchWithVariables();
    }
  };

  resetCursorAndRefetchCounterUpdateHandler = prevResetCursorAndRefetchCounter => {
    if (prevResetCursorAndRefetchCounter !== this.props.resetCursorAndRefetchCounter) {
      this.setState({ cursorStack: [] });
      this.props.tableHistoryContext.setValue({
        cursorStack: []
      });
    }
  };

  checkedUpdateHandler = prevChecked => {
    if (!isEqual(prevChecked, this.state.checked)) {
      this.props.actionButtonProps && this.checkForDisabledActionsHandler();
      this.props.checkForContextActions && this.checkForContextActionsHandler();
    }
  };

  checkedUpdateAfterEditHandler = () => {
    this.props.actionButtonProps && this.checkForDisabledActionsHandler();
    this.props.checkForContextActions && this.checkForContextActionsHandler();
  };

  activeFiltersCheckHandler = () => {
    if (this.props.filterProps && !this.state.activeFilters) {
      this.setActiveFilters();
    }
  };

  actionsDisabledCheckHandler = () => {
    if (
      this.props.actionButtonProps &&
      isEmpty(this.state.checked) &&
      isEmpty(this.state.disabledActions)
    ) {
      this.disableAllActionsHandler();
    }
  };

  getCheckedItems = () => {
    return this.props.edges.filter(edge => this.state.checked.includes(edge.node.id));
  };

  checkForDisabledActionsHandler = () => {
    this.setState({
      disabledActions: this.props.actionButtonProps.getDisabledActions(this.getCheckedItems())
    });
  };

  checkForContextActionsHandler = () => {
    this.props.checkForContextActions(this.getCheckedItems(), this.checkedUpdateAfterEditHandler);
  };

  disableAllActionsHandler = () => {
    this.setState({
      disabledActions: this.props.actionButtonProps.options.map(option => option.name)
    });
  };

  paginationHandler = ({ cursorStack, first }) => {
    this.setState({
      cursorStack,
      rowsPerPage: first
    });
    this.props.tableHistoryContext.setValue({
      cursorStack,
      rowsPerPage: first
    });
  };

  sortHandler = orderBy => {
    this.setState({
      orderBy,
      cursorStack: []
    });
    this.props.tableHistoryContext.setValue({
      orderBy,
      cursorStack: []
    });
  };

  filterHandler = (name, value) => {
    const {
      filterProps: { withApply, withoutContainer }
    } = this.props;

    this.setState(
      state => {
        const updatedFilters = { ...state.filters };
        updatedFilters[name] = value;

        return {
          filters: updatedFilters
        };
      },
      () => {
        if (withoutContainer || !withApply) {
          this.applyFiltersHandler();
        }
      }
    );
  };

  applyFiltersHandler = () => {
    this.props.tableHistoryContext.setValue({
      filters: this.state.filters,
      ...(this.state.saveFiltersAsDefault && {
        defaultSavedFilters: this.state.filters
      }),
      cursorStack: []
    });

    this.setState({ checked: [], cursorStack: [], saveFiltersAsDefault: false }, () => {
      this.setActiveFilters();
      this.toggleFilters();
    });
  };

  clearFiltersHandler = () => {
    const initialFilters =
      this.props.tableHistoryContext.initialFilters ||
      get(this.props.filterProps, 'initialFilters') ||
      {};

    this.setState(
      {
        filters: initialFilters,
        checked: [],
        cursorStack: []
      },
      () => {
        this.props.tableHistoryContext.setValue({
          filters: null,
          defaultSavedFilters: null,
          cursorStack: []
        });
        this.setActiveFilters();
      }
    );
  };

  actionClickHandler = actionName => {
    this.props.actionButtonProps.handleMenuItemClick(
      actionName,
      this.state.checked,
      this.checkedUpdateAfterEditHandler
    );
  };

  checkAllItemsHandler = () => {
    const { edges } = this.props;
    this.setState(state => {
      if (state.checked.length < edges.length) {
        return { checked: edges.map(edge => edge.node.id) };
      } else {
        return { checked: [] };
      }
    });
  };

  checkItemHandler = (checkedId, shiftClick) => {
    const { lastCheckedId, checked } = this.state;
    const { edges } = this.props;

    if (shiftClick && lastCheckedId) {
      const lastCheckedIndex = edges.findIndex(({ node }) => node.id === lastCheckedId);
      const newCheckedIndex = edges.findIndex(({ node }) => node.id === checkedId);
      const checkedIds = edges.reduce((prev, { node }, index) => {
        if (
          index >= Math.min(lastCheckedIndex, newCheckedIndex) &&
          index <= Math.max(lastCheckedIndex, newCheckedIndex)
        ) {
          prev = prev.concat(node.id);
        }
        return prev;
      }, []);
      this.setState({
        checked: uniq([...checked, ...checkedIds])
      });
    } else {
      const isAlreadyChecked = checked.includes(checkedId);
      this.setState({
        lastCheckedId: isAlreadyChecked ? null : checkedId,
        checked: isAlreadyChecked
          ? checked.filter(id => id !== checkedId)
          : checked.concat(checkedId)
      });
    }
  };

  refetchWithVariables = () => {
    if (this.props.actionButtonProps) {
      // for lists with checkboxes: when switching pages, sorting or filters, unchecking all checked items
      // because we can't know what page they will end up in and might not be visible to the user
      this.setState({ checked: [] });
    }

    const { orderBy, cursorStack, rowsPerPage } = this.state;
    this.props.onChangeHandler({
      orderBy,
      first: rowsPerPage,
      after: cursorStack ? cursorStack[cursorStack.length - 1] : '',
      ...formatFilterValuesForQuery(this.state.filters)
    });
  };

  getActiveFilters = () => {
    const {
      state: { filters },
      props: { tableHistoryContext, filterProps }
    } = this;
    const initialFilters =
      tableHistoryContext.initialFilters || get(filterProps, 'initialFilters') || {};

    return Object.keys(filters).reduce((prev, key) => {
      const value = filters[key];
      if (value instanceof Date) {
        !isNaN(value) && prev.push(key);
      } else if (!isEqual(value, initialFilters[key])) {
        prev.push(key);
      }
      return prev;
    }, []);
  };

  setActiveFilters = () => {
    this.setState({
      activeFilters: this.getActiveFilters()
    });
  };

  saveAsDefaultHandler = () => {
    this.setState({ saveFiltersAsDefault: !this.state.saveFiltersAsDefault });
  };

  toggleFilters = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
  };

  getSortedEdges = () => {
    const {
      props: { onChangeHandler, rowTemplate, edges },
      state: { orderBy }
    } = this;

    const sortedEdges = [...edges];
    const sortedColumn = rowTemplate.find(column => column.name === trimStart(orderBy, '-'));
    const columnSortFunction = sortedColumn && sortedColumn.sortFunction;
    const isDescending = orderBy[0] === '-';

    return !onChangeHandler && columnSortFunction
      ? columnSortFunction(sortedEdges, isDescending)
      : sortedEdges;
  };

  render() {
    const {
      orderBy,
      cursorStack,
      rowsPerPage,
      checked,
      disabledActions,
      activeFilters
    } = this.state;
    const {
      title,
      rowTemplate,
      emptyListText,
      labelledByHeader,
      rowProps,
      paginationProps,
      actionButtonProps,
      filterProps,
      withBackground
    } = this.props;

    const sortedEdges = this.getSortedEdges();

    return (
      <WhiteBox hidden={!withBackground}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div>
            {title && (
              <Typography variant="h3" style={{ marginRight: 'auto', marginBottom: '0.5rem' }}>
                {title}
              </Typography>
            )}
            {actionButtonProps && (
              <ActionButton
                options={actionButtonProps.options}
                disabledItems={disabledActions}
                handleMenuItemClick={this.actionClickHandler}
                withMarginBottom={false}
              />
            )}
            {filterProps && !filterProps.withoutContainer && (
              <FilterButton
                id="filterButton"
                activeFilters={activeFilters || []}
                onClick={this.toggleFilters}
                onClear={this.clearFiltersHandler}
              />
            )}
          </div>
          {filterProps && !filterProps.withoutContainer && filterProps.withApply && (
            <FilterControls
              showFilters={this.state.filtersVisible}
              width={filterProps.width}
              withApply={filterProps.withApply}
              onToggleFilters={this.toggleFilters}
              onApplyFilters={this.applyFiltersHandler}
              withSaveAsDefault={filterProps.withSaveAsDefault}
              saveAsDefaultValue={this.state.saveFiltersAsDefault}
              handleSaveAsDefault={this.saveAsDefaultHandler}
            />
          )}
        </Box>

        {filterProps &&
          (filterProps.withoutContainer ? (
            React.cloneElement(filterProps.filterComponent, {
              onFilterChange: this.filterHandler,
              filterValues: this.state.filters
            })
          ) : (
            <FiltersContainer
              showFilters={this.state.filtersVisible}
              activeFilters={activeFilters || []}
              width={filterProps.width}
            >
              {React.cloneElement(filterProps.filterComponent, {
                onFilterChange: this.filterHandler,
                filterValues: this.state.filters
              })}
            </FiltersContainer>
          ))}
        <StyledTable>
          <TableColGroup rowTemplate={rowTemplate} />
          <AutoTableHeader
            sortableProps={{
              orderBy: orderBy,
              handleSort: this.sortHandler
            }}
            rowTemplate={rowTemplate}
            checkboxProps={{
              rowsCount: sortedEdges.length,
              checkedCount: checked.length,
              handleCheckAll: this.checkAllItemsHandler,
              labelledBy: labelledByHeader
            }}
          />
          <StyledTableBody>
            {sortedEdges.length ? (
              <AutoTableRows
                edges={sortedEdges}
                rowTemplate={rowTemplate}
                onCheckRow={this.checkItemHandler}
                checked={checked}
                selectedId={this.props.selectedId}
                {...rowProps}
              />
            ) : (
              <EmptyListTextContainer>
                {activeFilters && activeFilters.length
                  ? `${emptyListText} for selected filters`
                  : emptyListText}
              </EmptyListTextContainer>
            )}
          </StyledTableBody>
        </StyledTable>
        {paginationProps && (
          <Pagination
            {...paginationProps}
            onChange={this.paginationHandler}
            rowsPerPage={rowsPerPage}
            cursorStack={cursorStack}
          />
        )}
      </WhiteBox>
    );
  }
}

AutoTable.propTypes = {
  // Displays an H3 with the string, placed inline with the action and filter buttons.
  title: PropTypes.string,
  // Defines the columns and the type of cells.
  rowTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      // used internally for sorting and matching the data from props,
      // must be the same as the name of the property in the node object,
      name: PropTypes.string.isRequired,
      // label for the table header
      label: PropTypes.string.isRequired,
      // type of the cell, needs to be defined in AutoTableRows
      type: PropTypes.string,
      // Makes the column sortable
      sortable: PropTypes.bool,
      // If there is no refetching going on and the edges are stored in state, then sorting has
      // to be handled internally. To do so provide a sortFunction that accepts edges and
      // isDescending as arguments and should return a sorted array of edges
      sortFunction: PropTypes.func,
      // set to true if the cell should truncate.
      truncate: PropTypes.bool
    })
  ),
  // Array of nodes. In order to map the data correctly using the rowTemplate
  // the 'nodes' object must be flat. That means that the edges must be mapped in some cases.
  edges: PropTypes.arrayOf(PropTypes.shape({ node: PropTypes.object })).isRequired,
  // Initial sorting value - same as 'name' in 'rowTemplate'
  initialOrderBy: PropTypes.string,
  // labelledBy area for header checkbox (required if table supports checkboxes)
  labelledByHeader: PropTypes.string,
  // To be used to force table to refetch but preserve all variables (mostly by something happening in sibling or parent component)
  refetchCounter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // To be used to force table to reset cursor stack to empty (we need this when the changes might affect end Cursor, most likely when filtering),
  resetCursorAndRefetchCounter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // Mostly handlers passed to rows, dependant on type of the cells.
  rowProps: PropTypes.shape({
    // Passed to TableCellClickable link to details handler
    handleLinkClick: PropTypes.func,
    // Passed to TableCellMessage - handler for opening message interface
    handleMessageClick: PropTypes.func,
    // Passed to TableCelTrash- handler for deleting an item
    handleRemoveClick: PropTypes.func,
    //Passed to TableCellContract - handler for viewing contract
    handleContractClick: PropTypes.func,
    // Passed to TableCellAvatarWithText - Should avatar cells contain only image with tooltip and no text
    avatarNoText: PropTypes.bool,
    // Passed To TableCellActions - handler for duplicating item
    handleActionsDuplicateClick: PropTypes.func,
    // Passed to TableCellActions - handler for deleting item
    handleActionsTrashClick: PropTypes.func,
    // Passed to TableCellEdit - handler for editing item
    handleEditClick: PropTypes.func,
    // Passed to TableCellUpDown - handler for moving up
    handleUpClick: PropTypes.func,
    // Passed to TableCellUpDown - move down handler
    handleDownClick: PropTypes.func,
    emptyListText: PropTypes.string
    // Text displayed below the table header when there are no records.
  }),
  filterProps: PropTypes.shape({
    filterComponent: PropTypes.node,
    initialFilters: PropTypes.object,
    width: PropTypes.number,
    withoutContainer: PropTypes.bool,
    withApply: PropTypes.bool,
    withSaveAsDefault: PropTypes.bool
  }),
  paginationProps: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    endCursor: PropTypes.string,
    totalCount: PropTypes.number,
    edgeCount: PropTypes.number
  }),
  // Props for action button (Action Button will be redered only if this is passed)
  actionButtonProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ).isRequired,
    handleMenuItemClick: PropTypes.func,
    getDisabledActions: PropTypes.func
  }),
  // Renders table with a white background wrapper;
  withBackground: PropTypes.bool.isRequired
};

AutoTable.defaultProps = {
  initialOrderBy: 'name',
  withBackground: false,
  emptyListText: 'No Items to display'
};

export default withTableHistoryContext(AutoTable);

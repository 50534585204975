import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { yupSchemas } from '../../../../shared/validations';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import Detail from '../../../../shared/components/UI/Detail';
import StyledSwitch from '../../../../shared/components/UI/StyledSwitch';
import FormikDropdownField from '../../../../shared/components/form/FormikDropdownField';
import EditToggle from '../../../../shared/components/UI/EditToggle';
import UpdatePaymentSettingsMutation from './UpdatePaymentSettingsMutation';
import { GridEditableField, VatStatusLabel } from './styled';

const PaymentDetailsContent = props => {
  const {
    details: { paymentSettings },
    currencyCodes,
    isEditing,
    toggleEditHandler
  } = props;

  const handleSubmit = ({ vatPercentage, taxPercentage, ...values }, actions) => {
    const input = {
      ...values,
      vatPercentage: vatPercentage === '' ? 0 : vatPercentage,
      taxPercentage: taxPercentage === '' ? 0 : taxPercentage
    };
    UpdatePaymentSettingsMutation(input).then(() => {
      actions.setSubmitting(false);
      toggleEditHandler();
    });
  };

  const initialValues = {
    paymentTerm: paymentSettings.paymentTerm || '',
    preferredCurrency: paymentSettings.preferredCurrency || '',
    paymentInfo: paymentSettings.paymentInfo || '',
    includeVat: paymentSettings.includeVat || false,
    vatPercentage: paymentSettings.vatPercentage || '',
    taxPercentage: paymentSettings.taxPercentage || '',
    taxIdentifier: paymentSettings.taxIdentifier || ''
  };

  const validationSchema = Yup.object().shape({
    paymentTerm: yupSchemas.string(true),
    preferredCurrency: yupSchemas.string(true, { max: 3 }),
    paymentInfo: yupSchemas.string(),
    includeVat: Yup.boolean(),
    vatPercentage: Yup.number().max(100),
    taxPercentage: Yup.number().max(100),
    taxIdentifier: yupSchemas.string()
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, resetForm, handleChange }) => (
        <Form>
          <DetailsSection
            title="Payment Details"
            renderRight={() => (
              <EditToggle
                isEditing={isEditing}
                isSubmitting={isSubmitting}
                editProps={{
                  onClick: toggleEditHandler,
                  buttonCopy: 'Edit Payment Information',
                  edit: false
                }}
                saveProps={{
                  loading: isSubmitting
                }}
                cancelProps={{
                  onClick: () => {
                    resetForm();
                    toggleEditHandler();
                  }
                }}
              />
            )}
          >
            <Grid container spacing={3}>
              <GridEditableField
                editing={isEditing}
                formikValues={values}
                name="paymentTerm"
                label="Default Payment Terms"
              />
              <GridEditableField
                editing={isEditing}
                formikValues={values}
                component={FormikDropdownField}
                name="preferredCurrency"
                label="Preferred Currency"
                fieldProps={{
                  itemType: 'enumValue',
                  items: currencyCodes.enumValues
                }}
              />
            </Grid>
            <Grid container spacing={3}>
              <GridEditableField
                editing={isEditing}
                formikValues={values}
                name="paymentInfo"
                label="Additional Payment Instructions"
                gridItemProps={{
                  xs: 6
                }}
                fieldProps={{
                  multiline: true,
                  rows: '10'
                }}
              />
            </Grid>
          </DetailsSection>
          <DetailsSection noBorder title="Tax Details">
            <Detail
              mb={2}
              name="Include Tax Information on Invoices"
              value={
                isEditing ? (
                  <StyledSwitch
                    checked={values.includeVat}
                    name="includeVat"
                    onChange={handleChange}
                  />
                ) : values.includeVat === undefined ? (
                  '-'
                ) : (
                  <VatStatusLabel
                    isEnabled={values.includeVat}
                    children={values.includeVat ? 'Enabled' : 'Disabled'}
                  />
                )
              }
            />
            <Grid container spacing={3}>
              <GridEditableField
                editing={isEditing}
                formikValues={values}
                name="vatPercentage"
                label="VAT Percentage"
                detailProps={{
                  value: values.vatPercentage ? `${values.vatPercentage}%` : '-'
                }}
                fieldProps={{
                  type: 'number',
                  decoratorEnd: '%',
                  step: '0.01'
                }}
              />
              <GridEditableField
                editing={isEditing}
                formikValues={values}
                name="taxIdentifier"
                label="Tax Identifier"
              />
            </Grid>
            <Grid container spacing={3}>
              <GridEditableField
                editing={isEditing}
                formikValues={values}
                name="taxPercentage"
                label="Tax Withholding Percentage"
                detailProps={{
                  value: values.taxPercentage ? `${values.taxPercentage}%` : '-'
                }}
                fieldProps={{
                  type: 'number',
                  decoratorEnd: '%',
                  step: '0.01'
                }}
              />
            </Grid>
          </DetailsSection>
        </Form>
      )}
    </Formik>
  );
};

export default createFragmentContainer(PaymentDetailsContent, {
  details: graphql`
    fragment PaymentDetailsContent_details on Query {
      paymentSettings {
        paymentTerm
        preferredCurrency
        paymentInfo
        includeVat
        vatPercentage
        taxPercentage
        taxIdentifier
      }
    }
  `,
  currencyCodes: graphql`
    fragment PaymentDetailsContent_currencyCodes on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const updateProductMutation = graphql`
  mutation UpdateProductMutation($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      updatedProduct {
        id
        code
        title
        internalId
        description
        representativeImageUrl
      }
    }
  }
`;

export default (id, title, code, internalId, description, file, reprImageNull, callback) => {
  const updateProductVariables = {
    input: {
      id,
      title,
      code,
      internalId,
      description,
      reprImageNull
    }
  };

  const config = {
    mutation: updateProductMutation,
    uploadables: {
      representative_image: file
    },
    variables: updateProductVariables,
    onCompleted: (response, errors) => {
      callback(response.updateProduct, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const organizationQuery = graphql`
  query SearchableOrganizationDropdownQuery(
    $name: String
    $first: Int
    $orderBy: String
  ) {
    organizations(
      name: $name
      orderBy: $orderBy
      first: $first
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const SearchableOrganizationDropdown = props => {
  const { onChange, noLabel, ...otherProps } = props;

  const getFetchVariables = inputValue => {
    return {
      name: inputValue,
      first: 10,
      orderBy: 'name',
    };
  };

  const formatOptions = orgList => {
    if (!orgList || !orgList.organizations) {
      return [];
    }

    return orgList.organizations.edges.map(org => {
      return {
        value: org.node.id,
        label: org.node.name
      };
    });
  };

  return (
    <SearchableDropdown
      resultKey="organizations"
      query={organizationQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      label={noLabel ? '' : 'Organization'}
      {...otherProps}
    />
  );
};

export default SearchableOrganizationDropdown;

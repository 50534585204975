import React, { Fragment } from 'react';

import { formatAmount } from '../../utils/formatters';
import { getCurrencyCodeFromUserContext } from '../../utils/helpers';
import withUserContext from '../../contexts/userContext/withUserContext';

const Amount = ({ amount, currencyCode, userContext }) => (
  <Fragment>
    {formatAmount(
      amount,
      currencyCode ? currencyCode : getCurrencyCodeFromUserContext(userContext)
    )}
  </Fragment>
);

export default withUserContext(Amount);

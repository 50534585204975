/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeliverableFilesList_deliverableStateTypes$ref: FragmentReference;
declare export opaque type DeliverableFilesList_deliverableStateTypes$fragmentType: DeliverableFilesList_deliverableStateTypes$ref;
export type DeliverableFilesList_deliverableStateTypes = {|
  +enumValues: ?$ReadOnlyArray<{|
    +name: string,
    +description: ?string,
  |}>,
  +$refType: DeliverableFilesList_deliverableStateTypes$ref,
|};
export type DeliverableFilesList_deliverableStateTypes$data = DeliverableFilesList_deliverableStateTypes;
export type DeliverableFilesList_deliverableStateTypes$key = {
  +$data?: DeliverableFilesList_deliverableStateTypes$data,
  +$fragmentRefs: DeliverableFilesList_deliverableStateTypes$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DeliverableFilesList_deliverableStateTypes",
  "type": "__Type",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "enumValues",
      "storageKey": null,
      "args": null,
      "concreteType": "__EnumValue",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b8955fdbc063e04db3bc8b5671bd4886';
module.exports = node;

/**
 * @flow
 * @relayHash 110abe94053930f589429cf7dd91bb69
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
export type SolicitationDeliverableAllocationsQueryVariables = {|
  id: string,
  contractorId: string,
  orderBy?: ?string,
|};
export type SolicitationDeliverableAllocationsQueryResponse = {|
  +solicitation: ?{|
    +solicitationId: ?string,
    +state: SolicitationState,
    +staffCoordinator: {|
      +user: {|
        +id: string
      |}
    |},
    +allocatedDeliverables: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: string,
          +amount: ?any,
          +currencyCode: ?DeliverableCurrencyCode,
          +amountInHomeCurrency: ?any,
          +state: DeliverableState,
          +dueDate: ?any,
          +category: {|
            +id: string,
            +name: string,
          |},
          +assignedStaff: ?{|
            +id: string,
            +fullName: ?string,
            +representativeImageUrl: ?string,
            +user: {|
              +id: string
            |},
          |},
          +release: ?{|
            +product: {|
              +title: string
            |}
          |},
        |}
      |}>
    |},
  |},
  +contractor: ?{|
    +id: string,
    +fullName: ?string,
    +representativeImageUrl: ?string,
    +isActive: ?boolean,
    +conflictingContractor: ?{|
      +id: string
    |},
  |},
|};
export type SolicitationDeliverableAllocationsQuery = {|
  variables: SolicitationDeliverableAllocationsQueryVariables,
  response: SolicitationDeliverableAllocationsQueryResponse,
|};
*/


/*
query SolicitationDeliverableAllocationsQuery(
  $id: ID!
  $contractorId: ID!
  $orderBy: String
) {
  solicitation(id: $id, contractorId: $contractorId) {
    solicitationId
    state
    staffCoordinator {
      user {
        id
      }
      id
    }
    allocatedDeliverables(orderBy: $orderBy) {
      edges {
        node {
          id
          title
          amount
          currencyCode
          amountInHomeCurrency
          state
          dueDate
          category {
            id
            name
          }
          assignedStaff {
            id
            fullName
            representativeImageUrl
            user {
              id
            }
          }
          release {
            product {
              title
              id
            }
            id
          }
        }
      }
    }
    id
  }
  contractor(id: $contractorId) {
    id
    fullName
    representativeImageUrl
    isActive
    conflictingContractor {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractorId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractorId",
    "variableName": "contractorId"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "solicitationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": (v5/*: any*/)
},
v7 = [
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyCode",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amountInHomeCurrency",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dueDate",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryNode",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "assignedStaff",
  "storageKey": null,
  "args": null,
  "concreteType": "StaffNode",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/),
    (v6/*: any*/)
  ]
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contractor",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "contractorId"
    }
  ],
  "concreteType": "ContractorNode",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "conflictingContractor",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractorNode",
      "plural": false,
      "selections": (v5/*: any*/)
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationDeliverableAllocationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffCoordinator",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allocatedDeliverables",
            "storageKey": null,
            "args": (v7/*: any*/),
            "concreteType": "DeliverableNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v3/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v16/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      (v17/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationDeliverableAllocationsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffCoordinator",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v4/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allocatedDeliverables",
            "storageKey": null,
            "args": (v7/*: any*/),
            "concreteType": "DeliverableNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v3/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v16/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v4/*: any*/)
                            ]
                          },
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      },
      (v17/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationDeliverableAllocationsQuery",
    "id": null,
    "text": "query SolicitationDeliverableAllocationsQuery(\n  $id: ID!\n  $contractorId: ID!\n  $orderBy: String\n) {\n  solicitation(id: $id, contractorId: $contractorId) {\n    solicitationId\n    state\n    staffCoordinator {\n      user {\n        id\n      }\n      id\n    }\n    allocatedDeliverables(orderBy: $orderBy) {\n      edges {\n        node {\n          id\n          title\n          amount\n          currencyCode\n          amountInHomeCurrency\n          state\n          dueDate\n          category {\n            id\n            name\n          }\n          assignedStaff {\n            id\n            fullName\n            representativeImageUrl\n            user {\n              id\n            }\n          }\n          release {\n            product {\n              title\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n  contractor(id: $contractorId) {\n    id\n    fullName\n    representativeImageUrl\n    isActive\n    conflictingContractor {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '222a5fe62453b4dac90cf6d9278337ba';
module.exports = node;

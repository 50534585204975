import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const exposeReferenceToSolicitationMutation = (input, onCompleted) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation ExposeReferenceToSolicitationMutation($input: ExposeReferenceToSolicitationInput!) {
        exposeReferenceToSolicitation(input: $input) {
          updatedReference {
            id
            exposeToSolicitation
          }
        }
      }
    `,
    variables: {
      input
    },
    onCompleted
  });
};

export default exposeReferenceToSolicitationMutation;

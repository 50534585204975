import React, { Fragment } from 'react';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import colors from '../../styles/common/colors';
import Avatar from '../common/Avatar';
import { Box } from '@material-ui/core';
import SquareButton from './SquareButton';
import { ReactComponent as ArrowIcon } from '../../images/breadcrumbs_arrow.svg';
import { RichTextFormattedValue } from '../richText';

const TopSection = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

const ColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const StyledSquareButton = styled(SquareButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.notificationHover,
    '& svg path': {
      fill: colors.orange
    }
  },
  marginRight: '-7px'
}));

const BreadCrumbBox = styled(Box)({
  display: 'flex',
  alignSelf: 'flex-start',
  paddingTop: '10px',
  '& span': {
    paddingRight: '5px',
    paddingLeft: '5px',
    fontSize: '12px'
  },
  '& span:first-of-type': {
    paddingLeft: 0
  },
  '& svg': {
    height: '10px',
    alignSelf: 'center'
  }
});

const renderBreadCrumb = deliverable => {
  if (!deliverable) return null;
  return (
    <BreadCrumbBox>
      <Typography variant="body2" component="span">
        {deliverable.release.product.title}
      </Typography>
      <ArrowIcon />
      <Typography variant="body2" component="span">
        {deliverable.release.name}
      </Typography>
      <ArrowIcon />
      <Typography variant="body2" component="span">
        {deliverable.title}
      </Typography>
    </BreadCrumbBox>
  );
};

const NotificationMenuItem = props => {
  const {
    timesince,
    actor,

    extraData: { action, instanceType, instanceTitle, content },
    deliverable
  } = props.notification;

  return (
    <Fragment>
      <TopSection>
        <Box display="flex">
          <Avatar
            src={actor.representativeImageUrl}
            alt={actor.name}
            size={36}
            defaultIcon={actor.actorType === 'organization' ? 'organization' : 'person'}
          />
          <ColumnBox>
            <Typography variant="body2">{actor.name}</Typography>
            <Typography variant="body1" noWrap style={{ maxWidth: '300px' }}>
              {action}{' '}
              <Typography variant="body2" component="span">
                {!['Deliverable', 'Job'].includes(instanceType) && instanceType} {instanceTitle}
              </Typography>
            </Typography>
          </ColumnBox>
        </Box>

        <ColumnBox>
          <StyledSquareButton
            icon="close"
            variant="transparent"
            onClick={e => {
              e.stopPropagation();
              props.handleClearClick(props.notification.id);
            }}
            size={20}
          />
          <Typography variant="overline">
            {/* apparently timesince value contains a different whitespace, so without replacing it with common whitespace this checkfails */}
            {timesince && timesince.replace(/\s+/g, ' ') === '0 minutes'
              ? 'Just now'
              : `${timesince.split(', ')[0]} ago`}
          </Typography>
        </ColumnBox>
      </TopSection>
      {content && (
        <Box alignSelf="flex-start" pt="8px">
          <Typography variant="body1" className="truncateContent">
            <RichTextFormattedValue value={content} plain />
          </Typography>
        </Box>
      )}
      {deliverable && renderBreadCrumb(deliverable)}
    </Fragment>
  );
};

export default NotificationMenuItem;

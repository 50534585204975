import React, { Fragment, useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import {
  CELL_TYPES,
  DELIVERABLE_STATES,
  ALLOWED_ACTIONS,
  SOLICITATION_STATES
} from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import useDialog from '../../../../../shared/hooks/useDialog';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import {
  computePermissionRole,
  getActiveDeliverableDueDateWarningColor,
  getEnvironment
} from '../../../../../shared/utils/helpers';
import QuickViewAvatarHeader from '../../../../../shared/components/UI/QuickViewAvatarHeader';
import AllocateDeliverableMutation from '../../mutations/AllocateDeliverableMutation';
import RemoveAllocatedDeliverableMutation from '../../mutations/RemoveAllocatedDeliverableMutation';
import { errorToast } from '../../../../../shared/toasts';
import useGlobalCreateModalStyles from '../../../../../shared/styles/common/useGlobalCreateModalStyles';
import useSideBar from '../../../../../shared/hooks/useSideBar';
import Dialog from '../../../../../shared/components/common/Dialog';
import SearchableDeliverableDropdown from '../../../jobs/SearchableDeliverableDropdown';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import CreateJobForm from '../../../jobs/CreateJobForm';

const solicitationDeliverableAllocationsQuery = graphql`
  query SolicitationDeliverableAllocationsQuery($id: ID!, $contractorId: ID!, $orderBy: String) {
    solicitation(id: $id, contractorId: $contractorId) {
      solicitationId
      state
      staffCoordinator {
        user {
          id
        }
      }
      allocatedDeliverables(orderBy: $orderBy) {
        edges {
          node {
            id
            title
            amount
            currencyCode
            amountInHomeCurrency
            state
            dueDate
            category {
              id
              name
            }
            assignedStaff {
              id
              fullName
              representativeImageUrl
              user {
                id
              }
            }
            release {
              product {
                title
              }
            }
          }
        }
      }
    }
    contractor(id: $contractorId) {
      id
      fullName
      representativeImageUrl
      isActive
      conflictingContractor {
        id
      }
    }
  }
`;

const SolicitationDeliverableAllocations = props => {
  const {
    userContext: {
      id: currentUserId,
      orgStaff: {
        organization: { configuration },
        allowedActions
      }
    },
    solicitationId,
    contractorId,
    setRefetchCounter: setParentRefetchCounter,
    solicitationDjangoId,
    setSelectedIdForAllocations
  } = props;

  const [solicitation, setSolicitation] = useState(null);
  const [contractor, setContractor] = useState(null);
  const [orderBy, setOrderBy] = useState('dueDate');
  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const [isLoading, setIsLoading] = useState(true);

  const isOpen = useSideBar()[0];
  const classes = useGlobalCreateModalStyles({ isOpen });

  useEffectWithStatus(
    status => {
      getData(status);
    },
    [orderBy]
  );

  useEffectWithStatus(
    status => {
      // only when we switch between contractors we should see Loading indicator
      // not needed when order changes or add/remove happens
      setIsLoading(true);
      getData(status);
    },
    [contractorId]
  );

  const getData = (status = { mounted: true }) => {
    fetchQuery(getEnvironment(), solicitationDeliverableAllocationsQuery, {
      orderBy,
      id: solicitationId,
      contractorId
    }).then(response => {
      if (status.mounted) {
        setIsLoading(false);
        setSolicitation(response.solicitation);
        setContractor(response.contractor);
      }
    });
  };

  const onChangeHandler = variables => {
    setOrderBy(variables.orderBy);
  };

  const onLinkClickHandler = id => {
    const { history } = props;
    history.push(`/deliverables/${id}`);
  };

  const removeClickHandler = deliverableId => {
    RemoveAllocatedDeliverableMutation(solicitationId, deliverableId, contractorId, response => {
      if (response && response.isUpdated) {
        setParentRefetchCounter(counter => counter + 1);
        getData();
      }
    });
  };

  const selectDeliverable = selected => {
    if (selected) {
      AllocateDeliverableMutation(solicitationId, selected.value, contractorId, response => {
        if (response && response.isUpdated) {
          setParentRefetchCounter(counter => counter + 1);
          getData();
        }
      });
    }
  };

  const areAllReadyOrInDraft = () => {
    return solicitation.allocatedDeliverables.edges.every(
      ({ node }, _i) =>
        node.state === DELIVERABLE_STATES.ready || node.state === DELIVERABLE_STATES.draft
    );
  };

  const isAllowedToCreateJob = () => {
    return computePermissionRole(
      [ALLOWED_ACTIONS.JOB_CREATE_WITHOUT_CONTRACT, ALLOWED_ACTIONS.JOB_SIGN_AND_SEND_CONTRACT],
      allowedActions
    );
  };

  const createJob = () => {
    if (!areAllReadyOrInDraft()) {
      errorToast(
        'Some of allocated deliverables are already in progress. Please remove them to continue.'
      );
      return;
    }

    if (!contractor.isActive || contractor.conflictingContractor) {
      errorToast('This contractor is unavailable for a job.');
      return;
    }
    toggleDialogOpen();
  };

  const canChangeAllocations = () => {
    return (
      solicitation.staffCoordinator.user.id === currentUserId &&
      solicitation.state !== SOLICITATION_STATES.closed
    );
  };

  const flattenedEdges =
    solicitation &&
    solicitation.allocatedDeliverables.edges.map(edge => {
      const {
        id,
        title,
        release: {
          product: { title: product }
        },
        state,
        amount,
        currencyCode,
        amountInHomeCurrency,
        dueDate
      } = edge.node;
      return {
        node: {
          id,
          title,
          product,
          amount,
          currencyCode,
          // used for CreateJob
          amountInHomeCurrency,
          dueDate: {
            date: dueDate,
            color: getActiveDeliverableDueDateWarningColor(configuration, dueDate, state)
          }
        }
      };
    });

  if (solicitation && contractor && solicitation.allocatedDeliverables && !isLoading) {
    return (
      <Fragment>
        <QuickViewAvatarHeader
          fullName={contractor.fullName}
          representativeImageUrl={contractor.representativeImageUrl}
          marginBottom={42}
        />
        {canChangeAllocations() && (
          <Grid direction="row" container spacing={1} style={{ paddingBottom: '30px' }}>
            <Grid item xs={9}>
              <SearchableDeliverableDropdown
                name="allocationDeliverables"
                fetchVariables={{
                  onlyInSolicitation: solicitationDjangoId,
                  excludeAllocated: true,
                  state: '2',
                  assignedToMeOnly: true
                }}
                onChange={selectDeliverable}
                keyToReload={JSON.stringify(solicitation.allocatedDeliverables.edges.length)}
                placeholder="Select Deliverables..."
                hideSelection={true}
              />
            </Grid>
            {isAllowedToCreateJob() && (
              <Grid item>
                <GlobalButton
                  handleClick={createJob}
                  style={{ width: '100%', height: '40px' }}
                  disabled={!solicitation.allocatedDeliverables.edges.length}
                >
                  Create Job
                </GlobalButton>
              </Grid>
            )}
          </Grid>
        )}

        <AutoTable
          rowTemplate={[
            { name: 'title', label: 'Name', sortable: true, type: CELL_TYPES.link },
            { name: 'product', label: 'Product' },
            {
              name: 'dueDate',
              label: 'Due Date',
              type: CELL_TYPES.dateWithWarning,
              sortable: true,
              width: 100
            },
            { name: 'amount', label: 'Amount', type: CELL_TYPES.amount },
            {
              name: 'trash',
              label: '',
              type: CELL_TYPES.trash,
              onClick: removeClickHandler,
              hidden: !canChangeAllocations(),
              width: 30,
              tooltip: 'Remove'
            }
          ]}
          edges={flattenedEdges}
          onChangeHandler={onChangeHandler}
          rowProps={{
            handleLinkClick: onLinkClickHandler
          }}
          initialOrderBy="dueDate"
        />
        {solicitation.allocatedDeliverables.edges.length > 0 && (
          <Dialog
            className={clsx(classes.globalCreateDialog, classes.job)}
            title="Create Job with Allocated Deliverables"
            isDialogOpen={isDialogOpen}
            closeDialog={toggleDialogOpen}
            disableBackdropClick
            noButton
            disableEscapeKeyDown
          >
            <CreateJobForm
              selectedDeliverables={solicitation.allocatedDeliverables.edges.map(edge => edge.node)}
              selectedContractor={{ value: contractor.id, label: contractor.fullName }}
              toRefetchData={() => {
                props.toRefetchData();
                setParentRefetchCounter(counter => counter + 1);
                setSelectedIdForAllocations(null);
              }}
              handleClose={toggleDialogOpen}
            />
          </Dialog>
        )}
      </Fragment>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default withRouter(withUserContext(SolicitationDeliverableAllocations));

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import flattenDeep from 'lodash/flattenDeep';
import { Typography, Box } from '@material-ui/core';
import ConfirmationDialog from '../../../shared/components/common/ConfirmationDialogV2';

// NOTE:
// errors[key] could be one of following
// - ['var1', 'var2']
// - {'var1': '', 'var2': ''}
// - {job: [{'var1': '', 'var2': ''}]}
const getErrorsArray = (errors, key) => {
  const errorsGroup = get(errors, key);
  if (!errorsGroup) return null;

  const result = Array.isArray(errorsGroup)
    ? errorsGroup
    : flattenDeep(
        Object.entries(errorsGroup).map(([key, val]) =>
          typeof val === 'string' ? key : val.map(obj => Object.keys(obj))
        )
      );

  return result.length > 0 ? result : null;
};

const renderVars = (vars, withDeliverableWarning = false) => (
  <ul>
    {vars.map(key => (
      <li key={key}>
        {key}
        {withDeliverableWarning && key.includes('.') ? '*' : ''}
      </li>
    ))}
  </ul>
);

const ContractTemplateVariableWarningDialog = ({ errors, ...dialogProps }) => {
  const notSupportedVars = getErrorsArray(errors, 'not_supported');
  const emptyVars = getErrorsArray(errors, 'empty');
  const signatureVars = getErrorsArray(errors, 'no_signature');
  const shouldShowDeliverableNote = emptyVars
    ? emptyVars.find(key => key.includes('.')) !== undefined
    : false;

  return (
    <ConfirmationDialog
      {...dialogProps}
      title="Template Variable Warning"
      content={
        <>
          {notSupportedVars && (
            <Box mb={3}>
              There are variables referenced in your Contract Template that are unsupported by
              AWEbase. Here is a list of variables which will lead to errors if you choose to
              continue:
              {renderVars(notSupportedVars)}
            </Box>
          )}

          {emptyVars && (
            <Box mb={3}>
              There are variables referenced in your Contract Template that are missing data. Here
              is a list of variables which will be left empty if you choose to continue:
              {renderVars(emptyVars, true)}
            </Box>
          )}

          {signatureVars && (
            <Box mb={3}>
              Your contract template does not include the variables for{' '}
              {signatureVars
                .map(key => (key === 'organization_signature_1' ? 'organization' : 'contractor'))
                .join(' and ')}{' '}
              signatures. If this is intentional, please proceed. Otherwise, revise the template to
              include{' '}
              {signatureVars.map((key, i) => (
                <Fragment key={key}>
                  {i > 0 ? ' and ' : ''}
                  <b>{key}</b>
                </Fragment>
              ))}
            </Box>
          )}

          {shouldShowDeliverableNote && (
            <Typography variant="overline">
              *This variable is associated with a Deliverable. While some Deliverables in this Job
              may include the required data, at least one does not.
            </Typography>
          )}
        </>
      }
    />
  );
};

ContractTemplateVariableWarningDialog.propTypes = {
  errors: PropTypes.object
};

export default ContractTemplateVariableWarningDialog;

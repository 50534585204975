import React, { useState, useEffect, Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { withRouter } from 'react-router';

import { ReactComponent as ProductsIcon } from '../../../../shared/images/products.svg';
import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import DetailsTab from './ProductDetailsTab';
import ReleasesTab from './ProductReleasesTab';
import ProductToolsTab from './ProductToolsTab';

const ProductDetails = props => {
  const {
    product: { isActive, id, title },
    handleContextActionOptions,
    userContext: {
      orgStaff: { organization }
    }
  } = props;

  const [isEditing, setEditing] = useState(false);

  const classes = useDetailsStyles();
  const navigation = JSON.parse(organization.configuration).navigation;

  useEffect(() => {
    handleContextActionOptions({
      name: 'productDetails',
      data: {
        disabledProps: { disabled: !isActive },
        productId: id,
        productTitle: title
      }
    });
    return () => {
      handleContextActionOptions();
    };
  }, [props.history.location.pathname, isActive, id, title]);

  const toggleEditHandler = () => {
    setEditing(!isEditing);
  };

  return (
    <Fragment>
      <div className={classes.topSectionHeightSmall}>
        <CategoryTitleHeader
          category={`${navigation.productTitle.plural}`}
          title={title}
          icon={ProductsIcon}
          link="/products"
        />
      </div>
      <AutoTabList>
        <DetailsTab
          label="Details"
          isEditing={isEditing}
          setEditing={setEditing}
          toggleEditHandler={toggleEditHandler}
        />
        <ReleasesTab
          label={navigation.releaseTitle.plural}
          disabled={isEditing}
          refetchCounter={props.refetchCounter}
        />
        <ProductToolsTab label="Tools" disabled={isEditing} refetchCounter={props.refetchCounter} />
      </AutoTabList>
    </Fragment>
  );
};

export default withUserContext(
  withRouter(
    createFragmentContainer(ProductDetails, {
      product: graphql`
        fragment ProductDetails_product on ProductNode {
          title
          isActive
          id
        }
      `
    })
  )
);

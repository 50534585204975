/**
 * @flow
 * @relayHash ab5311cd098943d41e18b2164537030c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateReviewAssetSettingsInput = {|
  assetId: string,
  settings?: ?any,
  clientMutationId?: ?string,
|};
export type UpdateReviewAssetSettingsMutationVariables = {|
  input: UpdateReviewAssetSettingsInput
|};
export type UpdateReviewAssetSettingsMutationResponse = {|
  +updateReviewAssetSettings: ?{|
    +reviewAsset: ?{|
      +settings: ?any
    |}
  |}
|};
export type UpdateReviewAssetSettingsMutation = {|
  variables: UpdateReviewAssetSettingsMutationVariables,
  response: UpdateReviewAssetSettingsMutationResponse,
|};
*/


/*
mutation UpdateReviewAssetSettingsMutation(
  $input: UpdateReviewAssetSettingsInput!
) {
  updateReviewAssetSettings(input: $input) {
    reviewAsset {
      settings
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateReviewAssetSettingsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "settings",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateReviewAssetSettingsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateReviewAssetSettings",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateReviewAssetSettingsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "ReviewAssetNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateReviewAssetSettingsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateReviewAssetSettings",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateReviewAssetSettingsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "ReviewAssetNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateReviewAssetSettingsMutation",
    "id": null,
    "text": "mutation UpdateReviewAssetSettingsMutation(\n  $input: UpdateReviewAssetSettingsInput!\n) {\n  updateReviewAssetSettings(input: $input) {\n    reviewAsset {\n      settings\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'deda1aad8df5e4a9dc7a5f776b6db684';
module.exports = node;

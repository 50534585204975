import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Grid, Box, FormControlLabel, Typography } from '@material-ui/core';

import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import SquareButton from '../../../shared/components/UI/SquareButton';
import FormikTextField from '../../../shared/components/form/FormikTextField';
import { FormikContractTemplateSelect } from '../form/ContractTemplateSelect';
import { FormikAmountWithCurrencyField } from '../form/AmountWithCurrencyField';
import FormikSearchableDropdown from '../../../shared/components/form/FormikSearchableDropdown';
import FormikDateField from '../../../shared/components/form/FormikDateField';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import StyledCheckbox from '../../../shared/components/UI/StyledCheckbox';
import CreateJobFormDeliverablesSection from './CreateJobFormDeliverablesSection';
import {
  calculateTotalAmount,
  computePermissionRole,
  dataURLtoFile
} from '../../../shared/utils/helpers';
import batchUnlockDeliverablesMutation from '../deliverables/mutations/BatchUnlockDeliverablesMutation';
import { DELIVERABLE_STATES, ALLOWED_ACTIONS } from '../../../shared/constants';
import ValidateDeliverablesForJobMutation from './mutations/ValidateDeliverablesForJobMutation';
import SplitButton from '../../../shared/components/UI/SplitButton';
import { formatDateForQueries } from '../../../shared/utils/formatters';
import FullscreenModal from '../../../shared/components/fullscreenModal/FullscreenModal';
import ContractPreview from '../../../shared/components/job/ContractPreview';
import SignaturePadModal from '../../../shared/components/signaturePad/SignaturePadModal';
import CreateJobMutation from './mutations/CreateJobMutation';
import { errorToast, successToast } from '../../../shared/toasts';
import ContractTemplateVariableWarningDialog from '../shared/CotractTemplateVariableWarningDialog';
import SearchableStaffDropdown from '../shared/dropdowns/SearchableStaffDropdown';
import validateContractPdfQuery from './queries/validateContractPdfQuery';
import getContractPreviewPdfQuery from './queries/getContractPreviewPdfQuery';

const PLACEHOLDERS = {
  noDeliverables: 'No Deliverables added...',
  notValidated: 'Validate Deliverables...',
  invalid: 'Resolve conflict...',
  valid: 'Select...'
};

const FORM_STATES = {
  notValidated: undefined,
  invalid: false,
  valid: true
};

const CreateJobForm = props => {
  const {
    selectedDeliverables = [],
    selectedContractor,
    userContext,
    handleClose,
    toRefetchData,
    refreshCheckedItems
  } = props;
  const {
    orgStaff: { allowedActions }
  } = userContext;
  //   undefined, false, true will represent non-validated, invalid and valid states of chosen deliverables
  const initialDeliverablesValidity = {
    category: undefined,
    assignedStaff: undefined,
    amount: undefined,
    state: undefined,
    budget: undefined
  };
  const [deliverablesValidity, setDeliverablesValidity] = useState(initialDeliverablesValidity);
  //   set to full deliverable object when edit button on appropriate row clicked
  const [editingDeliverable, setEditingDeliverable] = useState(null);
  const [contractPreviewPdf, setContractPreviewPdf] = useState(null);
  const [contractPreviewWarning, setContractPreviewWarning] = useState(null);
  const [openSignaturePad, setOpenSignaturePad] = useState(false);

  const validateDeliverablesButtonRef = useRef();

  useEffect(() => {
    // will simulate validate deliverables button click when creating a job with context action from selected deliverables
    if (selectedDeliverables.length) {
      validateDeliverablesButtonRef.current && validateDeliverablesButtonRef.current.click();
    }
  }, [selectedDeliverables.length]);

  const HELPERS = {
    mapDeliverablesToIds(deliverables) {
      return deliverables.map(deliverable => deliverable.id);
    },
    filterLockedDeliverables(deliverables) {
      return deliverables.filter(deliverable => deliverable.state === DELIVERABLE_STATES.locked);
    },
    findDeliverableById(deliverables, id) {
      return deliverables.find(deliverable => deliverable.id === id);
    },
    findDeliverableIndexById(deliverables, id) {
      return deliverables.findIndex(deliverable => deliverable.id === id);
    }
  };

  const getFormState = () => {
    if (Object.values(deliverablesValidity).some(validityValue => validityValue === false)) {
      return FORM_STATES.invalid;
    } else if (Object.values(deliverablesValidity).every(validityValue => validityValue === true)) {
      return FORM_STATES.valid;
    } else {
      return FORM_STATES.notValidated;
    }
  };

  const initialValues = {
    name: '',
    description: '',
    category: '',
    deadline: null,
    amount: calculateTotalAmount([], userContext),
    template: null,
    // signBy value is ignored and excluded from mutation if proceed without contract is selected
    signBy: null,
    contractor: selectedContractor ? selectedContractor : null,
    deliverables: selectedDeliverables,
    contractDate: new Date(),
    organizationSignature: '',
    withContract: undefined,
    // need for some error state rendering where we need id and not only name from input field
    fullCategory: null,
    followers: {
      options: [
        {
          value: userContext.orgStaff.id,
          label: `${userContext.firstName} ${userContext.lastName}`
        }
      ]
    },
    addFollowersToDeliverables: false
  };

  const formatVariables = values => {
    return {
      name: values.name,
      description: values.description,
      contractor: values.contractor.value,
      category: values.fullCategory.id,
      ...(values.withContract && values.template && { contractTemplate: values.template.value }),
      ...(values.deadline && { jobDeadline: formatDateForQueries(values.deadline) }),
      ...(values.signBy &&
        values.withContract && { contractSignDeadline: formatDateForQueries(values.signBy) }),
      ...(values.contractDate && { contractDate: formatDateForQueries(values.contractDate) }),
      ...((values.amount.amount || values.amount.amount === 0) && {
        totalValue: values.amount.amount
      }),
      deliverables: HELPERS.mapDeliverablesToIds(values.deliverables)
    };
  };

  const canSignAndSend = computePermissionRole(
    ALLOWED_ACTIONS.JOB_SIGN_AND_SEND_CONTRACT,
    allowedActions
  );
  const canCreateJobWithoutContract = computePermissionRole(
    ALLOWED_ACTIONS.JOB_CREATE_WITHOUT_CONTRACT,
    allowedActions
  );

  const getPlaceholder = (values, field) => {
    if (!values.deliverables || !values.deliverables.length) {
      return PLACEHOLDERS.noDeliverables;
    }
    const isFormValid = getFormState();

    if (isFormValid === FORM_STATES.notValidated) {
      return PLACEHOLDERS.notValidated;
    }
    if (!isFormValid && !deliverablesValidity[field]) {
      return PLACEHOLDERS.invalid;
    }
    return PLACEHOLDERS.valid;
  };

  const closeFormHandler = () => {
    handleClose();
    refreshCheckedItems && refreshCheckedItems();
  };

  const handleCancelClick = deliverables => {
    batchUnlockDeliverablesMutation(
      HELPERS.mapDeliverablesToIds(HELPERS.filterLockedDeliverables(deliverables)),
      closeFormHandler
    );
  };

  const unlockDeliverablesHandler = (latestDeliverables, deliverablesToUnlock, setFieldValue) => {
    const lockedDeliverables = HELPERS.filterLockedDeliverables(deliverablesToUnlock);
    if (lockedDeliverables.length) {
      batchUnlockDeliverablesMutation(
        HELPERS.mapDeliverablesToIds(lockedDeliverables),
        response => {
          if (response && response.deliverables) {
            const { deliverables: responseDeliverables } = response;
            const updatedDeliverables = latestDeliverables.map(deliverable => {
              const validDeliverable = HELPERS.findDeliverableById(
                responseDeliverables,
                deliverable.id
              );
              // updating state for deliverables that got unlocked so we have the most recent data
              return validDeliverable
                ? {
                    ...deliverable,
                    state: validDeliverable.state
                  }
                : deliverable;
            });
            setFieldValue('deliverables', updatedDeliverables, false);
          }
        }
      );
    }
  };

  const validateDeliverablesForJob = (values, setValues, setSubmitting) => {
    ValidateDeliverablesForJobMutation(
      HELPERS.mapDeliverablesToIds(values.deliverables),
      response => {
        setSubmitting(false);
        if (response && response.deliverables) {
          const {
            isValidCategory,
            isValidCurrency,
            isValidStaff,
            isValidState,
            isValidBudget,
            deliverables: responseDeliverables
          } = response;

          setDeliverablesValidity({
            category: isValidCategory,
            amount: isValidCurrency,
            assignedStaff: isValidStaff,
            state: isValidState,
            budget: isValidBudget
          });

          const updatedValues = {};
          // we grab a list of deliverables and update just in case any of them got updated by other user
          // between time they were added to this form and Validate button click so user has the most recent data
          const updatedDeliverables = values.deliverables.map(oldDeliverable => {
            return HELPERS.findDeliverableById(responseDeliverables, oldDeliverable.id);
          });
          updatedValues['deliverables'] = updatedDeliverables;
          updatedValues['template'] = null;
          updatedValues['category'] = isValidCategory ? updatedDeliverables[0].category.name : '';
          updatedValues['fullCategory'] = isValidCategory ? updatedDeliverables[0].category : null;
          updatedValues['amount'] = calculateTotalAmount(
            isValidCurrency ? updatedDeliverables : [],
            userContext
          );
          setValues({ ...values, ...updatedValues }, false);
        }
      }
    );
  };

  const submitHandler = (values, { setValues, setSubmitting }) => {
    setSubmitting(true);
    const isFormValid = getFormState();
    if (!isFormValid) {
      // Validate deliverables button clicked
      validateDeliverablesForJob(values, setValues, setSubmitting);
    } else {
      // will either start generating contract or will create a job after signing, depending on values
      const { withContract, organizationSignature } = values;
      if (withContract && !organizationSignature) {
        validateContractPdfQuery(formatVariables(values))
          .then(data => {
            if (data.errors) {
              setContractPreviewWarning(data.errors);
              setSubmitting(false);
            } else {
              renderContractPreview(values, setSubmitting);
            }
          })
          .catch(() => {
            setSubmitting(false);
          });
      } else {
        createJob(values, setSubmitting);
      }
    }
  };

  const renderContractPreview = (values, setSubmitting) => {
    const variablesPdf = formatVariables(values);
    return getContractPreviewPdfQuery(variablesPdf)
      .then(data => {
        if (data.pdfFile) {
          setContractPreviewPdf('data:application/pdf;base64,' + data.pdfFile);
        } else {
          console.error('Pdf wasnt returned', data);
          handleCancelClick(values.deliverables);
        }
      })
      .catch(() => {
        handleCancelClick(values.deliverables);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const createJob = ({ followers, addFollowersToDeliverables, ...values }, setSubmitting) => {
    const variables = formatVariables(values);
    variables['withContract'] = values.withContract;

    if (followers.options.length > 0) {
      variables.followerIds = followers.options.map(option => option.value);
      variables.addFollowersToDeliverables = addFollowersToDeliverables;
    }

    if (values.withContract && !values.organizationSignature) {
      errorToast('Missing signature');
      return;
    }

    CreateJobMutation(
      variables,
      {
        ...(values.organizationSignature && {
          organization_signature: dataURLtoFile(
            values.organizationSignature,
            `organizationSignature.png`
          )
        })
      },
      response => {
        setSubmitting(false);
        if (response && response.newJob) {
          setContractPreviewPdf(null);

          // additional information to the user, while still showing success message since job was actually created

          if (!response.emailSent) {
            errorToast('Job was created, but sending email to freelancer failed.');
          }
          handleClose();
          setTimeout(() => {
            successToast('New job has been created.', {
              text: 'Go to job',
              link: `/jobs/${response.newJob.id}`
            });
          }, 250);
          if (response.closedSolicitations.length > 0) {
            setTimeout(() => {
              successToast(
                `${response.closedSolicitations.length} JobOpp${
                  response.closedSolicitations.length > 1 ? 's have' : ' has'
                } been fully contracted and ${
                  response.closedSolicitations.length > 1 ? 'were' : ' was'
                } closed automatically.`
              );
            }, 250);
          }
          toRefetchData();
        }
      }
    );
  };

  const validateForm = values => {
    const errors = {};
    if (!getFormState()) {
      // if deliverables are not valid, we only check for deliverable errors, other fields don't matter yet
      const { category, amount, budget } = deliverablesValidity;
      if (category === false) {
        errors['category'] = 'Category Conflict';
        errors['template'] = 'Category Conflict';
      }
      if (amount === false) {
        errors['amount'] = 'Currency Conflict';
      }
      if (budget === false) {
        errors['amount'] = 'Budget Issue';
      }
    } else if (values.withContract !== undefined) {
      // this will be a validation for when user clicks preview and confirm: either for contract generation or for job creation
      if (!values.name) {
        errors.name = 'Required';
      }
      if (values.withContract && !values.template) {
        errors.template = 'Required';
      }
      if (values.withContract && !values.signBy) {
        errors.signBy = 'Required';
      }
      if (!values.contractor || !values.contractor.value) {
        errors.contractor = 'Required';
      }
    }
    return errors;
  };

  const renderSubmitButton = (submitForm, values, isSubmitting, setFieldValue) => {
    const isFormValid = getFormState();
    if (!isFormValid) {
      return (
        <GlobalButton
          id="validateDeliverablesForJob"
          ref={validateDeliverablesButtonRef}
          fullWidth
          noMargin
          handleClick={submitForm}
          disabled={
            !values.deliverables ||
            !values.deliverables.length ||
            isSubmitting ||
            editingDeliverable !== null
          }
          loading={isSubmitting}
          variant={isFormValid === FORM_STATES.notValidated ? 'primary' : 'cancelation'}
          style={{ maxWidth: '200px' }}
        >
          Validate Deliverables
        </GlobalButton>
      );
    } else {
      if (canCreateJobWithoutContract && canSignAndSend) {
        return (
          <SplitButton
            id="preview"
            label="Continue"
            loading={isSubmitting}
            options={['Preview and Confirm Contract', 'Proceed without Contract']}
            variant="acceptation"
            handleMenuItemClick={option => {
              setFieldValue('withContract', option === 0, false);
              setTimeout(submitForm, 0);
            }}
            disabledButton={isSubmitting}
          />
        );
      } else {
        return (
          <GlobalButton
            variant="acceptation"
            loading={isSubmitting}
            fullWidth
            handleClick={() => {
              setFieldValue('withContract', canSignAndSend, false);
              setTimeout(submitForm, 0);
            }}
            id={canSignAndSend ? 'previewAndConfirm' : 'createWithoutContract'}
            disabled={isSubmitting}
          >
            {canSignAndSend ? 'Preview and Confirm Contract' : 'Proceed without Contract'}
          </GlobalButton>
        );
      }
    }
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validateForm}>
        {({ values, setFieldValue, submitForm, setErrors, isSubmitting }) => (
          <Form>
            <ContractTemplateVariableWarningDialog
              isDialogOpen={contractPreviewWarning !== null}
              onConfirm={({ actions }) => renderContractPreview(values, actions.setSubmitting)}
              closeDialog={() => {
                setContractPreviewWarning(null);
              }}
              errors={contractPreviewWarning}
            />

            <Grid container spacing={3}>
              <Box position="absolute" top={24} right={24}>
                <SquareButton
                  onClick={() => handleCancelClick(values.deliverables)}
                  variant="secondary"
                  icon="close"
                  type="button"
                />
              </Box>
              <Grid item xs={4}>
                <Field
                  required
                  name="name"
                  component={FormikTextField}
                  label="Job Name"
                  placeholder="Enter a Job name..."
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  required
                  name="contractor"
                  component={FormikSearchableDropdown}
                  placeholder="Select a Contractor..."
                  //   will be true if coming from CreateJob in solicitation allocations view
                  disabled={selectedContractor ? true : false}
                />
              </Grid>
              <Grid item xs={4}>
                <Field name="contractDate" component={FormikDateField} label="Contract Date" />
              </Grid>
              <Grid item xs={4}>
                <Field
                  required
                  name="category"
                  component={FormikTextField}
                  label="Category"
                  disabled
                  fullWidth
                  placeholder={getPlaceholder(values, 'category')}
                  value={getFormState() === FORM_STATES.notValidated ? '' : values.category}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="template"
                  label="Contract Template"
                  component={FormikContractTemplateSelect}
                  category={values.fullCategory ? values.fullCategory.id : null}
                  placeholder={getPlaceholder(values, 'category')}
                  disabled={!deliverablesValidity['category']}
                  value={getFormState() === FORM_STATES.notValidated ? null : values.template}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="amount"
                  component={FormikAmountWithCurrencyField}
                  label="Total Amount"
                  disabled
                  noSelect
                  placeholder={getPlaceholder(values, 'amount')}
                  value={
                    getFormState() === FORM_STATES.notValidated
                      ? calculateTotalAmount([], userContext)
                      : values.amount
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Field name="signBy" component={FormikDateField} label="Sign By" />
              </Grid>
              <Grid item xs={4}>
                <Field name="deadline" component={FormikDateField} label="Deadline" />
              </Grid>
              <Grid item xs={12}>
                <SearchableStaffDropdown
                  name="followers"
                  label="Followers"
                  value={values.followers}
                  onChange={setFieldValue}
                  withChipsBelow
                  isMulti
                />
                {values.followers.options.length > 0 && (
                  <Box mt={0.5}>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={values.addFollowersToDeliverables}
                          onChange={e =>
                            setFieldValue('addFollowersToDeliverables', e.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography variant="overline">
                          Add followers to associated Deliverables
                        </Typography>
                      }
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="description"
                  component={FormikTextField}
                  label="Job Description"
                  placeholder="Enter a Job description..."
                  fullWidth
                  multiline
                  rows="5"
                />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: '75px' }}>
                <FieldArray
                  name="deliverables"
                  render={arrayHelpers => (
                    <CreateJobFormDeliverablesSection
                      deliverables={values.deliverables}
                      setFieldValue={setFieldValue}
                      arrayHelpers={arrayHelpers}
                      resetDeliverablesValidity={latestDeliverables => {
                        if (getFormState() !== FORM_STATES.notValidated) {
                          unlockDeliverablesHandler(
                            latestDeliverables,
                            values.deliverables,
                            setFieldValue
                          );
                          setDeliverablesValidity(initialDeliverablesValidity);
                          if (values.withContract !== undefined) {
                            // if user tried to proceed with contract, but then started editing deliverables again
                            // we don't want to apply job creation related validations
                            setFieldValue('withContract', undefined);
                          }
                          setErrors({});
                        }
                      }}
                      isFormValid={getFormState()}
                      deliverablesValidity={deliverablesValidity}
                      editingDeliverable={editingDeliverable}
                      setEditingDeliverable={setEditingDeliverable}
                      HELPERS={HELPERS}
                      isSubmitting={isSubmitting}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <SignaturePadModal
              open={openSignaturePad}
              handleClose={() => setOpenSignaturePad(false)}
              handleInsertSignature={signature => {
                setFieldValue('organizationSignature', signature, false);
                setTimeout(submitForm, 0);
              }}
            />
            {contractPreviewPdf && (
              <FullscreenModal
                open={true}
                handleBack={() => setContractPreviewPdf(null)}
                actions={[
                  {
                    label: 'Sign and Send',
                    handler: () => setOpenSignaturePad(true),
                    isSubmitting
                  }
                ]}
              >
                <ContractPreview contractPreviewPdf={contractPreviewPdf} />
              </FullscreenModal>
            )}
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
            >
              <GlobalButton handleClick={props.handleClose} variant="transparent">
                Cancel
              </GlobalButton>
              {renderSubmitButton(submitForm, values, isSubmitting, setFieldValue)}
            </Grid>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default withUserContext(CreateJobForm);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

/**
 * Update showcase asset
 * @param {Object} variables - Bag of variables passed to the mutation
 * @param {string} variables.assetId - ID of the updated showcase asset
 * @param {string} variables.fileName - Updated file name of the showcase asset
 * @param {string} variables.description - Updated description of the showcase asset
 * @param {onCompleted} onCompleted - Callback ran on completed
 * @param {onError} onError - Callback ran on error
 * @callback onCompleted
 * @callback onError
 */

const UpdateShowcaseAssetMutation = (variables, onCompleted, onError) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateShowcaseAssetMutation($input: UpdateShowcaseAssetInput!) {
        updateShowcaseAsset(input: $input) {
          asset {
            id
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted,
    onError
  });
};

UpdateShowcaseAssetMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      assetId: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ),
  onCompleted: PropTypes.func,
  onError: PropTypes.func
};

export default UpdateShowcaseAssetMutation;

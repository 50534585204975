import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';

import colors from '../../styles/common/colors';

const useStyle = makeStyles({
  badge: {
    color: colors.darkBlueGrey,
    fontSize: '12px',
    backgroundColor: colors.filterButtonGrey,
    padding: '0 5px',
    borderRadius: '2px',
    marginLeft: '8px'
  }
});

const TableCellListWithBadge = props => {
  const { items, ...otherProps } = props;
  const classes = useStyle();

  return (
    <StyledTableCell {...otherProps}>
      {items.slice(0, 3).map((item, index, arr) => {
        return `${item}${index === arr.length - 1 ? '' : ', '}`;
      })}
      {items.length > 3 && <span className={classes.badge}>+{items.length - 3}</span>}
    </StyledTableCell>
  );
};

TableCellListWithBadge.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TableCellListWithBadge;

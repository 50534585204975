import SigninUserMutation from '../mutations/auth/SigninUserMutation';
import SocialAuthMutation from '../mutations/auth/SocialAuthMutation';
import ForgotPasswordMutation from '../mutations/auth/ForgotPasswordMutation';
import ResetPasswordMutation from '../mutations/auth/ResetPasswordMutation';
import RefreshTokenMutation from '../mutations/auth/RefreshTokenMutation';
import getResetPasswordTokenStatus from '../mutations/auth/getResetPasswordTokenStatus';
import { logoutIntercom } from './intercomService';
import Cookies from 'js-cookie';

import {
  AWE_AUTH_TOKEN_FREELANCER,
  AWE_AUTH_REFRESH_TOKEN_FREELANCER,
  AWE_AUTH_TOKEN_ORGANIZATION,
  AWE_AUTH_REFRESH_TOKEN_ORGANIZATION,
  ORGANIZATION_APP,
  FREELANCER_APP,
  AWE_USER_EMAIL_FREELANCER,
  AWE_USER_EMAIL_ORGANIZATION,
  APP_TYPES
} from '../services/constants';

import { getURLPrefix } from './utilities';
import { isLoginError } from '../utils/helpers';
import SignInEmailMutation from '../../organization/components/account/mutations/SignInEmailMutation';
import ObtainJSONWebTokenMutation from '../../organization/components/account/mutations/ObtainJSONWebTokenMutation';
import LogoutMutation from '../mutations/auth/LogoutMutation';

export default {
  urlPrefix: '',
  tokenAttr: '',
  refreshTokenAttr: '',
  emailAttr: '',
  userType: '',
  isAuthenticated: false,
  jwt: { AWE_AUTH_TOKEN_ORGANIZATION: '', AWE_AUTH_TOKEN_FREELANCER: '' },
  refreshTokenInState: {
    AWE_AUTH_REFRESH_TOKEN_ORGANIZATION: '',
    AWE_AUTH_REFRESH_TOKEN_FREELANCER: ''
  },

  setAuthVariables() {
    this.urlPrefix = getURLPrefix();

    switch (this.urlPrefix) {
      case ORGANIZATION_APP:
        this.tokenAttr = AWE_AUTH_TOKEN_ORGANIZATION;
        this.refreshTokenAttr = AWE_AUTH_REFRESH_TOKEN_ORGANIZATION;
        this.userType = APP_TYPES.organization;
        this.emailAttr = AWE_USER_EMAIL_ORGANIZATION;
        break;
      case FREELANCER_APP:
        this.tokenAttr = AWE_AUTH_TOKEN_FREELANCER;
        this.refreshTokenAttr = AWE_AUTH_REFRESH_TOKEN_FREELANCER;
        this.userType = APP_TYPES.freelancer;
        this.emailAttr = AWE_USER_EMAIL_FREELANCER;
        break;
    }
  },

  checkIfAuthenticated() {
    this.isAuthenticated = this._getJwt() || this._getRefreshToken() ? true : false;
  },

  refreshToken(cb) {
    if (this._getRefreshToken()) {
      RefreshTokenMutation(this._getRefreshToken(), response => {
        if (response.refreshToken && response.refreshToken.payload && response.refreshToken.token) {
          this._setJwt(response.refreshToken.token);
          cb(true, response.refreshToken.payload);
        } else {
          this._removeTokens();
          cb(false);
        }
      });
    } else {
      if (this._getJwt() || this.isAuthenticated) {
        this._removeTokens();
      }
      cb(false);
    }
  },

  signInEmail(email, cb, rememberMe = false) {
    this.isAuthenticated = false;
    if (rememberMe === true) {
      this._setRememberMeEmail(email);
    } else {
      this._removeRememberMeEmail();
    }
    SignInEmailMutation(email, (response, _errors) => {
      if (response && response.signInEmail) {
        const { passwordRequired, signInIdpLink } = response.signInEmail;
        cb(passwordRequired, signInIdpLink);
      } else {
        cb();
      }
    });
  },

  signIn(email, password, cb, rememberMe = false) {
    if (rememberMe === true) {
      this._setRememberMeEmail(email);
    } else {
      this._removeRememberMeEmail();
    }

    SigninUserMutation(email, password, (response, _errors) => {
      if (_errors && _errors.length && isLoginError(_errors)) {
        this.isAuthenticated = false;
        cb(false, _errors[0].message);
      } else if (
          response &&
          response.signIn &&
          response.signIn.token
      ) {
          if (response.signIn.userType === this.userType) {
            this._setJwt(response.signIn.token);
            this._setRefreshToken(response.signIn.refreshToken);
            cb(true);
          } else {
            this.isAuthenticated = false;
            if (this.userType === "organization") {
              cb(false, 'It appears you are attempting to log into an Organization with a Contractor account, please log in as a Contractor.');
            } else {
              cb(false, 'It appears you are attempting to log into a Freelancer with an Organization account, please log in as an Organization member.');
            }
          }
      } else {
        this.isAuthenticated = false;
        cb(false, 'Something went wrong.');
      }
    });
  },

  signInGoogle(accessToken, cb) {
    SocialAuthMutation('google-oauth2', accessToken, (response, _errors) => {
      if (_errors && _errors.length && isLoginError(_errors)) {
        this.isAuthenticated = false;
        cb(false, _errors[0].message);
      } else if (
          response &&
          response.signIn &&
          response.signIn.token
      ) {
          if (response.signIn.userType === this.userType) {
            this._setJwt(response.socialAuth.token);
            this._setRefreshToken(response.socialAuth.refreshToken);
            cb(true);
          } else {
            this.isAuthenticated = false;
            if (this.userType === "organization") {
              cb(false, 'It appears you are attempting to log into an Organization with a Contractor account, please log in as a Contractor.');
            } else {
              cb(false, 'It appears you are attempting to log into a Freelancer with an Organization account, please log in as a Organization member.');
            }
          }
      } else {
        this.isAuthenticated = false;
        cb(false, 'Something went wrong.');
      }
    });
  },

  signout() {
    // remove tokens from for user signout
    LogoutMutation(() => {
      this._removeTokens();
      logoutIntercom();
      this.redirectLogin();
    });
  },

  forgotPassword(email, cb) {
    ForgotPasswordMutation(email, response => {
      cb(response);
    });
  },

  checkResetPasswordTokenStatus(resetToken, uidb64, cb) {
    getResetPasswordTokenStatus(resetToken, uidb64, response => {
      cb(response);
    });
  },

  resetPassword(password1, password2, uidb64, token, cb) {
    ResetPasswordMutation(password1, password2, uidb64, token, (response, errors) => {
      cb(response, errors);
    });
  },

  obtainTokens(cb) {
    ObtainJSONWebTokenMutation(response => {
      if (
        response &&
        response.obtainJsonWebToken &&
        response.obtainJsonWebToken.userType &&
        response.obtainJsonWebToken.token
      ) {
        this._setJwt(response.obtainJsonWebToken.token);
        this._setRefreshToken(response.obtainJsonWebToken.refreshToken);
        cb(true);
      } else {
        this.isAuthenticated = false;
        cb(false, 'Something went wrong.');
      }
    });
  },

  redirectHome() {
    window.location.href = this.urlPrefix;
  },

  redirectLogin() {
    window.location.href = this.urlPrefix + '/login';
  },

  redirectNotFound() {
    window.location.href = `${this.urlPrefix}/not-found`;
  },

  redirectForbidden() {
    window.location.href = `${this.urlPrefix}/forbidden`;
  },

  _getJwt() {
    return this.jwt[this.tokenAttr];
  },

  _getRefreshToken() {
    return Cookies.get(this.refreshTokenAttr) || this.refreshTokenInState[this.refreshTokenAttr];
  },

  _setJwt(token) {
    this.jwt[this.tokenAttr] = token;
    this.isAuthenticated = true;
  },

  _setRefreshToken(token) {
    // we will store refresh token in state as well in cookie: cookie will be useful when user comes back to the site
    // after closing the tab
    // but we can use state when user stays in the same tab (mostly good for users that disable cookies)
    Cookies.set(this.refreshTokenAttr, token, { path: '/', expires: 14, sameSite: 'strict' });
    this.refreshTokenInState[this.refreshTokenAttr] = token;
  },

  _removeRefreshToken() {
    Cookies.remove(this.refreshTokenAttr, { path: '/' });
    this.refreshTokenInState[this.refreshTokenAttr] = '';
  },

  _removeJwt() {
    this.jwt[this.tokenAttr] = '';
  },

  _removeTokens() {
    this._removeJwt();
    this._removeRefreshToken();
    this.isAuthenticated = false;
  },

  _setRememberMeEmail(email) {
    localStorage.setItem(this.emailAttr, email);
  },

  _getRememberMeEmail() {
    return localStorage.getItem(this.emailAttr);
  },

  _removeRememberMeEmail() {
    localStorage.removeItem(this.emailAttr);
  }
};

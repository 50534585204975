import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import BrightnessMedium from '@material-ui/icons/BrightnessMedium';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import GetAppIcon from '@material-ui/icons/GetApp';

import GlobalButton from './GlobalButton';
import { useButtonStyles } from '../../styles/common/useButtonStyles';

import { ReactComponent as TrashIcon } from '../../../shared/images/trash-icon.svg';
import { ReactComponent as AnnotationIcon } from '../../../shared/images/annotation-icon.svg';
import { ReactComponent as CircleIcon } from '../../../shared/images/tools/circle-icon.svg';
import { ReactComponent as PencilIcon } from '../../../shared/images/tools/pencil-icon.svg';
import { ReactComponent as DotIcon } from '../../../shared/images/tools/dot-icon.svg';
import { ReactComponent as CursorIcon } from '../../../shared/images/tools/cursor-icon.svg';
import { ReactComponent as LineIcon } from '../../../shared/images/tools/line-icon.svg';
import { ReactComponent as EditIcon } from '../../../shared/images/edit.svg';
import { ReactComponent as FullscreenIcon } from '../../../shared/images/fullscreen.svg';
import { ReactComponent as VolumeIcon } from '../../../shared/images/tools/volume-icon.svg';
import { ReactComponent as CommentIcon } from '../../../shared/images/message.svg';
import { ReactComponent as PlayIcon } from '../../../shared/images/tools/play-icon.svg';
import { ReactComponent as PauseIcon } from '../../../shared/images/tools/pause-icon.svg';
import { ReactComponent as InvoiceIcon } from '../../../shared/images/file-invoice.svg';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import { ReactComponent as AddPostRoundedIcon } from '../../../shared/images/post_add-24px.svg';
import { ReactComponent as DuplicateIcon } from '../../../shared/images/duplicate.svg';
import { ReactComponent as JobsIcon } from '../../../shared/images/jobs.svg';
import { ReactComponent as ContractsIcon } from '../../../shared/images/contracts.svg';

import { GLOBAL_BUTTON_TYPES } from '../../constants';
import CustomTableTooltip from '../table/CustomTableTooltip';

const ICONS = [
  { name: 'trash', icon: TrashIcon },
  { name: 'annotation', icon: AnnotationIcon },
  { name: 'circleTool', icon: CircleIcon },
  { name: 'dotTool', icon: DotIcon },
  { name: 'lineTool', icon: LineIcon },
  { name: 'penTool', icon: PencilIcon },
  { name: 'arrowTool', icon: CallReceivedIcon },
  { name: 'defaultCursor', icon: CursorIcon },
  { name: 'edit', icon: EditIcon },
  { name: 'close', icon: ClearIcon },
  { name: 'fullscreen', icon: FullscreenIcon },
  { name: 'volume', icon: VolumeIcon },
  { name: 'comment', icon: CommentIcon },
  { name: 'play', icon: PlayIcon },
  { name: 'pause', icon: PauseIcon },
  { name: 'arrowDropdown', icon: ExpandMoreIcon },
  { name: 'brightness', icon: BrightnessMedium },
  { name: 'download', icon: GetAppIcon },
  { name: 'invoice', icon: InvoiceIcon },
  { name: 'save', icon: SaveRoundedIcon },
  { name: 'post', icon: AddPostRoundedIcon },
  { name: 'duplicate', icon: DuplicateIcon },
  { name: 'job', icon: JobsIcon },
  { name: 'contract', icon: ContractsIcon }
];

const SquareButton = ({
  onClick,
  tooltipTitle,
  variant = 'black',
  size,
  icon,
  active,
  className,
  innerRef,
  ...otherProps
}) => {
  const classes = useButtonStyles({ size, active });

  const renderButtonComponent = () => (
    <GlobalButton
      ref={innerRef}
      variant={variant}
      className={clsx('square-button', classes.squareButton, className)}
      handleClick={onClick}
      icon={typeof icon === 'string' ? ICONS.find(el => el.name === icon).icon : icon}
      {...otherProps}
    />
  );

  return tooltipTitle ? (
    <CustomTableTooltip title={tooltipTitle}>
      <span>{renderButtonComponent()}</span>
    </CustomTableTooltip>
  ) : (
    renderButtonComponent()
  );
};

SquareButton.propTypes = {
  onClick: PropTypes.func,
  tooltipTitle: PropTypes.string,
  variant: PropTypes.oneOf(GLOBAL_BUTTON_TYPES),
  icon: PropTypes.oneOfType([PropTypes.oneOf(ICONS.map(icon => icon.name)), PropTypes.elementType])
    .isRequired,
  size: PropTypes.number,
  active: PropTypes.bool
};

SquareButton.defaultProps = {
  tooltipTitle: ''
};

export default forwardRef((props, ref) => <SquareButton {...props} innerRef={ref} />);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

const BatchDeleteDeliverableFinalFileMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation BatchDeleteDeliverableFinalFileMutation(
          $input: DeleteBatchDeliverableFinalFilesInput!
        ) {
          deleteBatchDeliverableFinalFiles(input: $input) {
            isDeleted
          }
        }
      `,
      variables: {
        input
      },
      onCompleted: resolve,
      onError: reject
    });
  });

export default BatchDeleteDeliverableFinalFileMutation;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

const CreateDeliverableFinalFileMutation = (
  variables,
  file,
  onCompleted = () => {},
  onError = () => {}
) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateDeliverableFinalFileMutation($input: CreateDeliverableFinalFileInput!) {
        createDeliverableFinalFile(input: $input) {
          deliverable {
            id
            finalFiles(orderBy: "-created") {
              edges {
                node {
                  id
                  created
                  fileName
                  fileSize
                  description
                  fileUrl
                  storageClass
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    uploadables: {
      final_file: file
    },
    onCompleted,
    onError
  });
};

CreateDeliverableFinalFileMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      deliverableId: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      stagedPath: PropTypes.string,
      fileName: PropTypes.string
    })
  ),
  file: PropTypes.any
};

export default CreateDeliverableFinalFileMutation;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const BatchMarkDeliverableCompleteMutation = ids =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation BatchMarkDeliverableCompleteMutation($input: MarkBatchDeliverableCompleteInput!) {
          markBatchDeliverableComplete(input: $input) {
            updatedDeliverables {
              id
              state
              job {
                id
                status
                hasCompletedDeliverables
              }
            }
          }
        }
      `,
      variables: {
        input: {
          ids
        }
      },
      onCompleted: res => resolve(res.markBatchDeliverableComplete),
      onError: reject
    });
  });

export default BatchMarkDeliverableCompleteMutation;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import { computePermissionRole, getAssignedUserId } from '../../../../../shared/utils/helpers';
import FinalFilesList from '../../../../../shared/components/deliverable/FinalFilesList';

const DeliverableFilesList = props => {
  const {
    deliverable,
    userContext: {
      id: currentUserId,
      orgStaff: { allowedActions }
    }
  } = props;

  const { assignedStaff, assignedInternally } = deliverable;

  const isUserAssignedInternally = currentUserId === getAssignedUserId(assignedInternally);

  const canUserUploadFile =
    isUserAssignedInternally ||
    (currentUserId === getAssignedUserId(assignedStaff) &&
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_UPLOAD_FINAL_FILE_ASSIGNED_TO_AUTHENTICATED_USER,
        allowedActions
      )) ||
    computePermissionRole(
      ALLOWED_ACTIONS.DELIVERABLE_UPLOAD_FINAL_FILE_ASSIGNED_TO_ANOTHER_USER,
      allowedActions
    );

  const canDownloadFinalFiles =
    isUserAssignedInternally ||
    computePermissionRole(ALLOWED_ACTIONS.DELIVERABLE_DOWNLOAD_FINAL_FILE, allowedActions);

  const canDeleteFinalFile =
    isUserAssignedInternally ||
    computePermissionRole(ALLOWED_ACTIONS.DELIVERABLE_DELETE_FINAL_FILE, allowedActions);

  return (
    <FinalFilesList
      deliverable={deliverable}
      canUserUpload={canUserUploadFile}
      canUserDelete={canDeleteFinalFile}
      canUserDownload={canDownloadFinalFiles}
    />
  );
};

export default createFragmentContainer(withUserContext(DeliverableFilesList), {
  deliverable: graphql`
    fragment DeliverableFilesList_deliverable on DeliverableNode {
      id
      finalFileMaxSize
      assignedStaff {
        user {
          id
        }
      }
      assignedInternally {
        user {
          id
        }
      }
      finalFiles(orderBy: "-created") {
        edges {
          node {
            id
            created
            fileName
            fileSize
            description
            fileUrl
            storageClass
          }
        }
      }
    }
  `
});

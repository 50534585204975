import React, { useState, Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import AutoTable from '../../../../../shared/components/table/AutoTable';
import { tableStyles } from '../../../../../shared/styles/common/tableStyles';
import { CELL_TYPES } from '../../../../../shared/constants';
import DeliverablesListFilters from '../../../deliverables/DeliverablesListFilters';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { getActiveDeliverableDueDateWarningColor } from '../../../../../shared/utils/helpers';
import { resolveProductLink, resolveReleaseLink } from '../../../../../shared/utils/linkResolvers';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import DeliverablesQuickView from '../../../deliverables/DeliverablesQuickView';

const ContractorDeliverablesList = props => {
  const {
    relay: { refetch },
    contractor: { assignedDeliverables },
    stateTypes,
    history,
    filterProps,
    userContext: {
      orgStaff: {
        organization: { configuration }
      }
    }
  } = props;

  const [selectedId, setSelectedId] = useState(null);

  const navigation = JSON.parse(configuration).navigation;

  if (!assignedDeliverables) return <div>Something went wrong!</div>;

  const {
    edges,
    totalCount,
    edgeCount,
    pageInfo: { hasNextPage, endCursor }
  } = assignedDeliverables;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    history.push(`/deliverables/${id}`);
  };

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      title,
      state,
      category,
      categoryType,
      reviewStep,
      amount,
      currencyCode,
      dueDate,
      assignedStaff,
      release: {
        id: releaseId,
        name: releaseName,
        product: { id: productId, title: productName }
      }
    } = edge.node;

    const categoryName = category ? category.name : '-';
    const type = categoryType ? categoryType.name : '-';

    const staff = assignedStaff
      ? {
          id: assignedStaff.user.id,
          name: assignedStaff.fullName,
          imageUrl: assignedStaff.representativeImageUrl
        }
      : null;

    return {
      node: {
        id,
        title,
        type,
        reviewStep,
        amount,
        currencyCode,
        categoryName,
        state: { code: state },
        releaseName: { cellText: releaseName, cellLink: resolveReleaseLink(releaseId) },
        productName: { cellText: productName, cellLink: resolveProductLink(productId) },
        dueDate: {
          date: dueDate,
          color: getActiveDeliverableDueDateWarningColor(configuration, dueDate, state)
        },
        staff
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        title="Deliverables History"
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
          { name: 'title', label: 'Name', type: CELL_TYPES.link, sortable: true },
          {
            name: 'productName',
            label: navigation.productTitle.singular,
            type: CELL_TYPES.relationLink,
            sortable: true
          },
          {
            name: 'releaseName',
            label: navigation.releaseTitle.singular,
            type: CELL_TYPES.relationLink,
            sortable: true
          },
          { name: 'categoryName', label: 'Category' },
          { name: 'dueDate', label: 'Due Date', type: CELL_TYPES.dateWithWarning, sortable: true },
          {
            name: 'state',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: stateTypes.enumValues
          },
          {
            name: 'staff',
            label: 'Coordinator',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center'
          },
          { name: 'amount', label: 'Amount', type: CELL_TYPES.amount }
        ]}
        onChangeHandler={onChangeHandler}
        edges={flattenedEdges}
        refetchCounter={props.refetchCounter}
        paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
        rowProps={{
          handleLinkClick: onLinkClickHandler
        }}
        filterProps={{
          filterComponent: <DeliverablesListFilters stateOptions={stateTypes.enumValues} />,
          withApply: true,
          ...filterProps
        }}
      />
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <DeliverablesQuickView deliverableId={selectedId} configuration={configuration} />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withStyles(tableStyles)(
  withRouter(
    withUserContext(
      createRefetchContainer(
        ContractorDeliverablesList,
        {
          contractor: graphql`
            fragment ContractorDeliverablesList_contractor on ContractorNode {
              id
              assignedDeliverables(
                first: $first
                after: $after
                orderBy: $orderBy
                dueDateFrom: $dueDateFrom
                dueDateTo: $dueDateTo
                category: $category
                state: $state
                title: $title
                releaseId: $releaseId
                productId: $productId
                assignedToStaffOrContractor: $assignedToStaffOrContractor
                staffCoordinator: $staffCoordinator
                tags: $tags
                includeClosed: $includeClosed
                includeCanceled: $includeCanceled
                includeInactive: $includeInactive
                categoryType: $categoryType
              ) {
                edgeCount
                totalCount
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                }
                edges {
                  node {
                    id
                    title
                    category {
                      name
                    }
                    categoryType {
                      name
                    }
                    state
                    amount
                    currencyCode
                    dueDate
                    release {
                      id
                      name
                      product {
                        id
                        title
                      }
                    }
                    assignedStaff {
                      user {
                        id
                      }
                      fullName
                      representativeImageUrl
                    }
                  }
                }
              }
            }
          `,
          stateTypes: graphql`
            fragment ContractorDeliverablesList_stateTypes on __Type {
              enumValues {
                name
                description
              }
            }
          `
        },
        graphql`
          query ContractorDeliverablesListRefetchQuery(
            $id: ID!
            $first: Int
            $after: String
            $orderBy: String
            $dueDateFrom: Date
            $dueDateTo: Date
            $category: ID
            $state: String
            $title: String
            $tags: String
            $includeClosed: Boolean
            $includeCanceled: Boolean
            $includeInactive: Boolean
            $releaseId: String
            $productId: String
            $categoryType: ID
            $assignedToStaffOrContractor: String
            $staffCoordinator: String
          ) {
            contractor(id: $id) {
              ...ContractorDeliverablesList_contractor
            }
          }
        `
      )
    )
  )
);

import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Typography } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

const btnResetCss = css`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleButton = styled.button.attrs(props => ({
  children: props.isFollowing ? <NotificationsActiveIcon /> : <NotificationsNoneIcon />
}))`
  ${btnResetCss};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 7px;

  svg {
    font-size: 28px;
  }
`;

export const FollowersListContainer = styled.div`
  width: 270px;
  padding: 4px 4px 8px;
`;

export const FollowersListTitle = styled(Typography).attrs({
  variant: 'h4'
})`
  padding: 8px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.colors.progressBarLightGrey};
`;

export const FollowersListContentContainer = styled.div`
  padding: 6px 12px;
`;

export const FollowersList = styled.ul`
  margin: 7px 0 0 0;
  padding: 0;
  max-height: 260px;
  overflow: auto;
  user-select: none;
`;

export const FollowersListLoading = styled.div.attrs({
  children: <CircularProgress size={24} />
})`
  padding: 20px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
`;

const FollowersListItemComponent = ({ children, onRemove, ...rest }) => (
  <Typography variant="body1" component="li" {...rest}>
    <span>{children}</span>
    {onRemove && <CloseIcon onClick={onRemove} />}
  </Typography>
);

export const FollowersListItem = styled(FollowersListItemComponent)`
  list-style: none;
  display: flex;
  align-items: center;

  && {
    margin-top: 1px;
    padding: 4px 12px;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }

  svg {
    font-size: 16px;
    margin-left: auto;
    cursor: pointer;
    opacity: 0;
  }

  ${props =>
    props.onRemove &&
    css`
      border-radius: 4px;

      &:hover {
        background: ${props => transparentize(0.8, props.theme.palette.primary.main)};
        color: ${props => props.theme.palette.primary.main};
        font-weight: bold;

        svg {
          opacity: 1;
        }
      }
    `};
`;

const ToggleFollowersListButtonContent = ({ totalFollowers }) => (
  <>
    <Typography color="inherit" variant="body2" children={totalFollowers} component="span" />
    <ArrowDropDownIcon />
  </>
);

export const ToggleFollowersListButton = styled.button.attrs(props => ({
  children: <ToggleFollowersListButtonContent {...props} />
}))`
  ${btnResetCss};
  font-size: 22px;
  color: ${({ theme }) => theme.palette.primary.main};

  > *:first-child {
    line-height: 1px;
  }

  svg {
    width: 20px;
    height: 20px;
    font-size: 22px;
  }
`;

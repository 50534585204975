import React from 'react';

import { NavLink } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import useSideBarStyles from '../../styles/common/sideBarStyles';

const SideBarLink = props => {
  const { to, label, iconComponent, intercomDataTarget, ...otherProps } = props;
  const classes = useSideBarStyles();

  const checkIfActive = (_match, location) => {
    const splitPath = location.pathname.split('/');
    const keyPathPart = splitPath[splitPath[1] === 'admin' ? 2 : 1];
    return to.includes(keyPathPart);
  };

  let Icon = iconComponent;

  return (
    <NavLink
      to={to}
      className={classes.sideBarLink}
      activeClassName={classes.activeSideBarLink}
      isActive={checkIfActive}
      data-intercom-target={intercomDataTarget}
      {...otherProps}
    >
      <ListItem button>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    </NavLink>
  );
};

export default SideBarLink;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TextField as MuiTextField, InputAdornment } from '@material-ui/core';
import formStyles from '../../../styles/common/formStyles';

const TextField = props => {
  const {
    name,
    value,
    onChange,
    label,
    error,
    helperText,
    decoratorStart,
    decoratorEnd,
    alignRight,
    big,
    InputProps,
    step = '0.01',
    visibleToFreelancer,
    ...otherProps
  } = props;
  const classes = formStyles({ visibleToFreelancer });

  const textFieldProps = {
    name,
    value,
    label,
    onChange,
    id: otherProps.id ? otherProps.id : `textField-${name}`,
    autoComplete: 'off',
    variant: 'outlined',
    classes: {
      root: big ? clsx(classes.textFieldRoot, classes.bigTextFieldRoot) : classes.textFieldRoot
    },
    InputLabelProps: {
      shrink: true
    },
    inputProps: {
      min: 0,
      style: { textAlign: alignRight ? 'right' : 'left' },
      step
    },
    InputProps: {
      notched: false,
      startAdornment: decoratorStart && (
        <InputAdornment position="start">{decoratorStart}</InputAdornment>
      ),
      endAdornment: decoratorEnd && <InputAdornment position="end">{decoratorEnd}</InputAdornment>,
      classes: {
        multiline: classes.outLinedInputMultiline
      },
      ...InputProps
    },
    ...otherProps,
    ...(typeof error === 'undefined'
      ? {}
      : {
          error,
          helperText
        })
  };

  return <MuiTextField {...textFieldProps} />;
};

TextField.propTypes = {
  InputProps: PropTypes.object
};

TextField.defaultProps = {
  InputProps: {}
};

export default TextField;

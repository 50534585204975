import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import Typography from '@material-ui/core/Typography';
import { Field } from 'formik';

import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';

const DetailsNotes = props => {
  const { isEditing } = props;
  const { managerNote } = props.deliverable;

  return (
    <DetailsSection title="Notes">
      {isEditing ? (
        <Field
          name="managerNote"
          label="Notes"
          placeholder="Notes"
          component={FormikTextField}
          multiline
          fullWidth
        />
      ) : managerNote && managerNote.length ? (
        <Typography variant="body1">{managerNote}</Typography>
      ) : (
        <Typography variant="body1">No notes added</Typography>
      )}
    </DetailsSection>
  );
};

export default createFragmentContainer(DetailsNotes, {
  deliverable: graphql`
    fragment DetailsNotes_deliverable on DeliverableNode {
      managerNote
    }
  `
});

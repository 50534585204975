/**
 * @flow
 * @relayHash dc59fb2d632d42b1c6b0b4f35ea5c8d2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type withRoleTypesQueryVariables = {|
  isDefault?: ?boolean
|};
export type withRoleTypesQueryResponse = {|
  +organizationRoles: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |}
|};
export type withRoleTypesQuery = {|
  variables: withRoleTypesQueryVariables,
  response: withRoleTypesQueryResponse,
|};
*/

/*
query withRoleTypesQuery(
  $isDefault: Boolean
) {
  organizationRoles(isDefault: $isDefault) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'isDefault',
        type: 'Boolean',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'organizationRoles',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'isDefault',
            variableName: 'isDefault'
          }
        ],
        concreteType: 'OrganizationRoleNodeConnection',
        plural: false,
        selections: [
          {
            kind: 'LinkedField',
            alias: null,
            name: 'edges',
            storageKey: null,
            args: null,
            concreteType: 'OrganizationRoleNodeEdge',
            plural: true,
            selections: [
              {
                kind: 'LinkedField',
                alias: null,
                name: 'node',
                storageKey: null,
                args: null,
                concreteType: 'OrganizationRoleNode',
                plural: false,
                selections: [
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'id',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'name',
                    args: null,
                    storageKey: null
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'withRoleTypesQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'withRoleTypesQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'withRoleTypesQuery',
      id: null,
      text:
        'query withRoleTypesQuery(\n  $isDefault: Boolean\n) {\n  organizationRoles(isDefault: $isDefault) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'a782ccdc923fb0159ab6a8340c7313d4';
module.exports = node;

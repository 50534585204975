import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import FinalFilesList from '../../../../../shared/components/deliverable/FinalFilesList';
import { DELIVERABLE_STATES } from '../../../../../shared/constants';

const DeliverableFilesList = ({ deliverable }) => {
  const canUserUpload = deliverable.state === DELIVERABLE_STATES.inProgress;
  const canUserDelete = deliverable.state === DELIVERABLE_STATES.inProgress;
  return (
    <FinalFilesList
      deliverable={deliverable}
      canUserUpload={canUserUpload}
      canUserDelete={canUserDelete}
    />
  );
};

export default createFragmentContainer(DeliverableFilesList, {
  deliverable: graphql`
    fragment DeliverableFilesList_deliverable on DeliverableNode {
      id
      state
      finalFileMaxSize
      finalFiles(orderBy: "-created") {
        edges {
          node {
            id
            created
            fileName
            fileSize
            description
            fileUrl
            storageClass
          }
        }
      }
    }
  `
});

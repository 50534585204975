/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SolicitationQuickViewContractorList_solicitation$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SolicitationQuickView_solicitation$ref: FragmentReference;
declare export opaque type SolicitationQuickView_solicitation$fragmentType: SolicitationQuickView_solicitation$ref;
export type SolicitationQuickView_solicitation = {|
  +solicitation: ?{|
    +subject: string,
    +message: ?string,
    +$fragmentRefs: SolicitationQuickViewContractorList_solicitation$ref,
  |},
  +$refType: SolicitationQuickView_solicitation$ref,
|};
export type SolicitationQuickView_solicitation$data = SolicitationQuickView_solicitation;
export type SolicitationQuickView_solicitation$key = {
  +$data?: SolicitationQuickView_solicitation$data,
  +$fragmentRefs: SolicitationQuickView_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SolicitationQuickView_solicitation",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "id",
      "type": "ID!",
      "defaultValue": ""
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "solicitation",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": "SolicitationNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subject",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "message",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "SolicitationQuickViewContractorList_solicitation",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'fc7e2bce321554955918860869918d33';
module.exports = node;

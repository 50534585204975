import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const ConnectWithOrganizationMutation = (id, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation ConnectWithOrganizationMutation($input: ConnectWithOrganizationInput!) {
        connectWithOrganization(input: $input) {
          success
          organization {
            status
          }
        }
      }
    `,
    variables: {
      input: {
        id
      }
    },
    onCompleted: response => {
      callback(response.connectWithOrganization);
    }
  });
};

ConnectWithOrganizationMutation.propTypes = {
  id: PropTypes.string.isRequired,
  callback: PropTypes.func
};

export default ConnectWithOrganizationMutation;

import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import Login from '../shared/components/account/Login';
import ForgotPassword from '../shared/components/account/ForgotPassword';
import ResetPassword from '../shared/components/account/ResetPassword';
import TopBar from './components/shared/TopBarOrganization';
import SideBar from '../shared/components/common/SideBar';
import appStyles from '../shared/styles/common/base';
import getUserData from './mutations/getUserData';
import authService from '../shared/services/authService';
import UserProvider from '../shared/contexts/userContext/UserProvider';
import InvalidLink from '../shared/components/account/InvalidLink';
import Forbidden from '../shared/components/account/Forbidden';
import useToastStyles from '../shared/styles/common/useToastStyles';
import CompleteStaffAccount from './components/account/CompleteStaffAccount';
import SignUp from './components/account/SignUp';
import VerifyEmail from './components/account/VerifyEmail';
import SignUpEmailVerified from './components/account/SignUpEmailVerified';
import StripeComplete from './components/account/StripeComplete';

import { computePermissionRole } from '../shared/utils/helpers';
import { bootIntercomStaff, updateIntercom } from '../shared/services/intercomService';

import { ADMIN_ROUTES, ORGANIZATION_ROUTES } from './routes';
import useQueryParams from '../shared/hooks/useQueryParams';
import StyledContent from '../shared/components/common/StyledContent';
import SamlRedirect from './components/account/SamlRedirect';

const LoginContainer = () => {
  const classes = appStyles();
  const { isAuthenticated } = authService;
  return (
    <main className={clsx(classes.loginContainer, classes.loginContainerOrganization)}>
      <Route
        exact
        path="/login"
        render={props => (isAuthenticated ? <Redirect to="/" /> : <Login {...props} />)}
      />
      <Route
        exact
        path="/login/forgot-password"
        render={props => (isAuthenticated ? <Redirect to="/" /> : <ForgotPassword {...props} />)}
      />
      <Route exact path="/login/reset-password" render={props => <ResetPassword {...props} />} />
      <Route exact path="/login/complete-account" component={CompleteStaffAccount} />
      <Route
        exact
        path="/login/saml-redirect"
        render={props => (isAuthenticated ? <Redirect to="/" /> : <SamlRedirect {...props} />)}
      />
    </main>
  );
};

const DefaultContainer = props => {
  const [refetchCounter, setRefetchCounter] = useState(0);
  // only used in deliverables page and solicitation details page: need to show extra options in Global Create dropdown when certain conditions are met
  const [contextActions, setContextActions] = useState([]);
  const queryParams = useQueryParams();

  const classes = appStyles();

  useEffect(() => {
    // informs Intercom that the route has changed.
    updateIntercom();
    // closes the quick view drawer when redirecting to another route
  }, [props.location]);

  if (queryParams.get('contractorId')) {
    //used for solicitation contractors list to open chat with selected contractor
    // if refactored to update url when opening different chat and not just state, then we can remove this
    props.history.push(props.location.pathname, {
      contractorId: queryParams.get('contractorId'),
      search: ''
    });
  }

  const toRefetchData = () => {
    // to be used in list components when new instance is created with Global Create action
    setRefetchCounter(prev => prev + 1);
  };

  const handleContextActionOptions = contextAction => {
    if (!contextAction) {
      setContextActions([]);
    } else if (Array.isArray(contextAction)) {
      setContextActions([...contextAction]);
    } else {
      setContextActions([contextAction]);
    }
  };

  return (
    <UserProvider userInfo={props.userInfo} setUserInfo={props.setUserInfo}>
      <div className={classes.root}>
        <SideBar pathname={props.location.pathname} />
        <div className={classes.appContainer}>
          <TopBar toRefetchData={toRefetchData} contextActions={contextActions} />
          <main className={classes.mainContainer}>
            <StyledContent>
              <Switch>
                {ORGANIZATION_ROUTES.map(({ component: Component, path }) => (
                  <Route
                    key={path}
                    path={path}
                    render={routeProps => (
                      <Component
                        {...routeProps}
                        refetchCounter={refetchCounter}
                        handleContextActionOptions={handleContextActionOptions}
                        toRefetchData={toRefetchData}
                      />
                    )}
                  />
                ))}
                {ADMIN_ROUTES.map(route => (
                  <AdminRoute
                    key={route.path}
                    component={route.component}
                    path={route.path}
                    action={route.action}
                    userInfo={props.userInfo}
                    refetchCounter={refetchCounter}
                  />
                ))}
              </Switch>
            </StyledContent>
          </main>
        </div>
      </div>
    </UserProvider>
  );
};

const AdminRoute = ({ component: Component, userInfo, action, refetchCounter, ...rest }) => {
  const shouldHaveAdminAccess =
    userInfo && computePermissionRole(action, userInfo.orgStaff.allowedActions);

  return (
    <Route
      {...rest}
      render={props => {
        return shouldHaveAdminAccess ? (
          <Component {...props} refetchCounter={refetchCounter} />
        ) : (
          <Redirect to="/forbidden" />
        );
      }}
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(authService.isAuthenticated);
  const [tokenExpTimestamp, setTokenExpTimestamp] = useState('');
  const [userInfo, setUserInfo] = useState({});

  const getUserDataCallback = userInfo => {
    setUserInfo(userInfo);
    bootIntercomStaff(userInfo);
  };

  const handleRefreshToken = getUserInfo => (isAuthenticated, payload) => {
    setAuthenticated(isAuthenticated);
    setTokenExpTimestamp(payload && payload.exp);
    if (isAuthenticated && getUserInfo) {
      getUserData(getUserDataCallback);
    }
  };

  useEffect(() => {
    if (authenticated) {
      if (tokenExpTimestamp && userInfo) {
        // if we have expiration timestamp, start a timer to refresh token 60 sec before it expires,
        // no need to get user data, we would already have it
        const timer = setTimeout(() => {
          authService.refreshToken(handleRefreshToken(false));
        }, (tokenExpTimestamp - Math.round(new Date().getTime() / 1000) - 60) * 1000);
        return () => clearTimeout(timer);
      } else {
        // immediately refresh token and get user data - this will happen on page load
        authService.refreshToken(handleRefreshToken(true));
      }
    }
  }, [tokenExpTimestamp, authenticated, userInfo]);

  return (
    <Route
      {...rest}
      render={props => {
        return authenticated ? (
          !isEmpty(userInfo) ? (
            <Component {...props} userInfo={userInfo} setUserInfo={setUserInfo} />
          ) : (
            // TODO replace with a loading indicator
            'Verifying token & fetching user data'
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

const OrganizationApp = () => {
  const classes = useToastStyles();
  const { isAuthenticated } = authService;

  return (
    <Fragment>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/deliverables" />} />
        <Route path="/login" component={LoginContainer} />
        <Route
          path="/signup"
          render={props => (isAuthenticated ? <Redirect to="/" /> : <SignUp {...props} />)}
        />
        <Route
          path="/verify-email"
          render={props => (isAuthenticated ? <Redirect to="/" /> : <VerifyEmail {...props} />)}
        />
        <Route path="/stripe/complete" render={props => <StripeComplete {...props} />} />
        <Route
          path="/complete-account"
          render={props =>
            isAuthenticated ? <Redirect to="/" /> : <SignUpEmailVerified {...props} />
          }
        />
        <Route path="/not-found" component={InvalidLink} />
        <Route path="/forbidden" component={Forbidden} />
        <PrivateRoute component={DefaultContainer} />
      </Switch>
      <ToastContainer
        className={classes.toastContainer2}
        toastClassName={classes.toast2}
        bodyClassName={classes.toastBody2}
        position="top-left"
        autoClose={20000}
        hideProgressBar={true}
        closeButton={false}
      />
    </Fragment>
  );
};

export default OrganizationApp;

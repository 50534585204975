import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import StaffDetails from './StaffDetails';
import QueryRenderer from '../../../../../../shared/relay/QueryRenderer';

const StaffDetailsPage = props => (
  <QueryRenderer
    query={graphql`
      query StaffDetailsPageQuery($id: ID!) {
        staff(id: $id) {
          ...StaffDetails_staff
        }
      }
    `}
    variables={{
      id: props.match.params.id
    }}
    render={relayProps => <StaffDetails staff={relayProps.staff} {...props} />}
  />
);

export default StaffDetailsPage;

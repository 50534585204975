/**
 * @flow
 * @relayHash 3080477bc59ef4c4c26a96cba6cd2556
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BillingActivity_organization$ref = any;
export type BillingActivityRefetchQueryVariables = {|
  first?: ?number,
  after?: ?string,
|};
export type BillingActivityRefetchQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: BillingActivity_organization$ref
  |}
|};
export type BillingActivityRefetchQuery = {|
  variables: BillingActivityRefetchQueryVariables,
  response: BillingActivityRefetchQueryResponse,
|};
*/


/*
query BillingActivityRefetchQuery(
  $first: Int
  $after: String
) {
  organization {
    ...BillingActivity_organization_2HEEH6
    id
  }
}

fragment BillingActivity_organization_2HEEH6 on OrganizationNode {
  id
  subscriptionHistory(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        timestamp
        actor {
          orgStaff {
            fullName
            representativeImageUrl
            id
          }
          freelancer {
            fullName
            representativeImageUrl
            id
          }
          id
        }
        action
        changes
        additionalData
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BillingActivityRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BillingActivity_organization",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BillingActivityRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscriptionHistory",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "LogEntryNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LogEntryNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LogEntryNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "timestamp",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "actor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "orgStaff",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffNode",
                            "plural": false,
                            "selections": (v3/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "freelancer",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FreelancerNode",
                            "plural": false,
                            "selections": (v3/*: any*/)
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "action",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "changes",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "additionalData",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BillingActivityRefetchQuery",
    "id": null,
    "text": "query BillingActivityRefetchQuery(\n  $first: Int\n  $after: String\n) {\n  organization {\n    ...BillingActivity_organization_2HEEH6\n    id\n  }\n}\n\nfragment BillingActivity_organization_2HEEH6 on OrganizationNode {\n  id\n  subscriptionHistory(first: $first, after: $after) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        timestamp\n        actor {\n          orgStaff {\n            fullName\n            representativeImageUrl\n            id\n          }\n          freelancer {\n            fullName\n            representativeImageUrl\n            id\n          }\n          id\n        }\n        action\n        changes\n        additionalData\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a75ac2fb826e45be62b24183baac8cfa';
module.exports = node;

import React, { Fragment, useState } from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { findIndex, capitalize } from 'lodash';
import {
  Grid,
  Typography,
  Box
} from '@material-ui/core';
import { ReactComponent as Logo } from '../../../../shared/images/awebase-logo-white-small.svg';

import { useBillingStyles } from '../../../../shared/styles/admin/useBillingStyles';
import { formatAmount } from '../../../../shared/utils/formatters';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import Detail from '../../../../shared/components/UI/Detail';
import HelpIcon from '@material-ui/icons/Help';
import CustomTableTooltip from '../../../../shared/components/table/CustomTableTooltip';
import {
  StorageBox,
  ScheduledSubscriptionInterval,
  StyledModuleBox,
  ModuleIndicator,
  StyledModuleFootnote,
  StyledInfoRow
} from './styled';
import UpdateSubscriptionDialog, { INTERVALS } from './UpdateSubscriptionDialog';
import Date from '../../../../shared/components/common/Date';
import SubscriptionModuleDialog from './SubscriptionModuleDialog';
import AvailablePlans from './AvailablePlans';

const PLAN_INFORMATION = [
  { name: 'tierName', label: 'Tier' },
  { name: 'interval', label: 'Subscription Cycle' },
  {
    name: 'nextBillingDate',
    label: 'Next Billing Date',
    tooltip: 'The next date you will be charged your subscription fees.'
  },
  {
    name: 'nextBillingAmount',
    label: 'Next Billing Amount',
    tooltip: 'The subscription fees you will be charged on your next billing date.'
  },
  { name: 'numberOfModules', label: 'Modules' }
];

const USAGE = [
  {
    name: 'numberOfActiveUsers',
    label: 'Active Users',
    tooltip: 'The number of active users within your organization.'
  },
  {
    name: 'numberOfTotalUsers',
    label: 'Total Users',
    tooltip: 'The number of users your organization has paid for through the next billing cycle.'
  },
  {
    name: 'numberOfDeliverables',
    label: 'Active Deliverables',
    tooltip:
      'A Deliverable is considered active as long as it is not closed, canceled, or marked inactive.'
  },
  {
    name: 'numberOfContractors',
    label: 'Active Contractors',
    tooltip:
      'The number of active contractors added by your organization, regardless of invite status.'
  },
  {
    name: 'numberOfContractTemplates',
    label: 'Contract Templates',
    tooltip: 'The number of active contract templates added by your organization.'
  }
];

const CURRENT_PLAN_LAYOUT = [
  { label: 'Subscription Information', fields: PLAN_INFORMATION },
  { label: 'Usage', fields: USAGE }
];

const BillingPlan = props => {
  const classes = useBillingStyles();

  const [displayMonthly, setDisplayMonthly] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);

  if (
    !props.billing ||
    !props.billing.organization ||
    !props.billing.organization.subscription ||
    !props.billing.subscriptionTiers
  )
    return <div>Something went wrong...</div>;
  const {
    billing: {
      organization: {
        subscription,
        activeStorageLimit,
        archiveStorageLimit,
        latestActiveMetricsData: { total: inUseActive },
        latestArchiveMetricsData: { total: inUseArchive },
        scheduledSubscription,
        activeSubscription
      },
      subscriptionTiers
    }
  } = props;

  const { tierName: subscriptionTierName, nextBillingDate } = subscription;

  const isChosenMonthly = subscription.interval === INTERVALS.monthly;
  const isScheduledMonthly =
    scheduledSubscription && scheduledSubscription.interval.toLowerCase() === INTERVALS.monthly;
  const isChosenFree = subscriptionTierName === 'Free';
  const currentPlanIndex = findIndex(subscriptionTiers, tier => tier.name === subscriptionTierName);

  const getTierActionLabel = (tier, i) => {
    let buttonLabel = '';
    if (i === currentPlanIndex && !isChosenFree) {
      if (isScheduledMonthly && !isChosenMonthly && !displayMonthly) {
        buttonLabel = 'Switch Back to Annual Billing';
      } else if (!displayMonthly && isChosenMonthly) {
        buttonLabel = 'Switch to Annual Billing';
      } else if (displayMonthly && !isChosenMonthly && !isScheduledMonthly) {
        buttonLabel = 'Switch to Monthly Billing';
      }
    } else if (i < currentPlanIndex) {
      buttonLabel = `Downgrade to ${tier.name}`;
    } else if (i > currentPlanIndex) {
      buttonLabel = `Upgrade to ${tier.name}`;
    }

    return buttonLabel;
  };

  const onModuleCardClick = module => {
    setSelectedModule({ current: module, scheduled: getModuleInScheduledSubscription(module) });
  };

  const getModuleInScheduledSubscription = module => {
    if (!scheduledSubscription || !scheduledSubscription.availableModules) {
      return null;
    }
    return scheduledSubscription.availableModules.find(
      scheduledModule => scheduledModule.moduleId === module.moduleId
    );
  };

  const renderModulesSection = () => {
    const renderFutureSubscriptionNote = (moduleName, quantity) => {
      return `${quantity ? quantity : ''} ${moduleName} ${
        quantity ? 'will be active' : 'will be no longer active'
      } ${nextBillingDate ? 'on ' : 'if you choose to renew your subscription'}`;
    };

    return activeSubscription.availableModules.map(module => {
      const moduleInScheduled = getModuleInScheduledSubscription(module);
      let futureSubscriptionNote = '';
      if (moduleInScheduled && moduleInScheduled.quantity !== module.quantity) {
        futureSubscriptionNote = renderFutureSubscriptionNote(
          module.name,
          moduleInScheduled.quantity
        );
      }

      return (
        <Grid item xs={3} key={module.name}>
          <StyledModuleBox quantity={module.quantity} onClick={() => onModuleCardClick(module)}>
            <ModuleIndicator
              quantity={module.quantity}
              incremental={module.incremental}
              scheduledChange={futureSubscriptionNote ? true : false}
            />
            <div className="pencil-logo">
              <Logo />
            </div>
            <Typography variant="body2">{module.name}</Typography>
            <Typography>{module.blurbText}</Typography>
            <Typography variant="body2">Click to learn more...</Typography>
          </StyledModuleBox>
          {futureSubscriptionNote && (
            <StyledModuleFootnote>
              {futureSubscriptionNote}
              {nextBillingDate && <Date date={nextBillingDate} />}
            </StyledModuleFootnote>
          )}
        </Grid>
      );
    });
  };

  const renderValueWithFutureSubscriptionNote = fieldName => {
    let futureSubscriptionNote = '';
    switch (fieldName) {
      case 'interval':
        if (isScheduledMonthly && !isChosenMonthly) {
          futureSubscriptionNote = 'Monthly subscription goes into effect ';
        }
        break;
      case 'numberOfModules':
        if (
          scheduledSubscription &&
          scheduledSubscription.activeModulesCount !== subscription[fieldName]
        ) {
          futureSubscriptionNote = `${scheduledSubscription.activeModulesCount} modules will be active `;
        }
        break;
      default:
        break;
    }
    if (futureSubscriptionNote) {
      futureSubscriptionNote += nextBillingDate
        ? 'on '
        : 'if you choose to renew your subscription';
    }
    if (futureSubscriptionNote) {
      return () => (
        <ScheduledSubscriptionInterval>
          <Typography variant="body1">{capitalize(subscription[fieldName])}</Typography>
          <Typography variant="body2">
            {futureSubscriptionNote}
            {nextBillingDate && <Date date={nextBillingDate} />}
          </Typography>
        </ScheduledSubscriptionInterval>
      );
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <Grid container item md={12} lg={10} justify="space-between">
        {CURRENT_PLAN_LAYOUT.map(section => (
          <Grid item xs={12} key={section.label}>
            <Typography variant="h3">{section.label}</Typography>
            <StyledInfoRow>
              {section.fields.map(field => (
                <Box display="flex" width="135px" key={field.name}>
                  <Detail
                    name={field.label}
                    nameIcon={
                      field.tooltip ? (
                        <CustomTableTooltip title={field.tooltip}>
                          <HelpIcon className={classes.helpIcon} />
                        </CustomTableTooltip>
                      ) : null
                    }
                    renderValue={renderValueWithFutureSubscriptionNote(field.name)}
                    value={
                      subscription[field.name] || subscription[field.name] === 0 ? (
                        field.name === 'nextBillingAmount' ? (
                          formatAmount(subscription[field.name])
                        ) : field.name === 'nextBillingDate' ? (
                          <Date date={subscription[field.name]} />
                        ) : (
                          capitalize(subscription[field.name])
                        )
                      ) : (
                        '-'
                      )
                    }
                    flexDirection="column"
                    alignItems="left"
                    valueContainerProps={{ variant: 'body1' }}
                  />
                </Box>
              ))}
            </StyledInfoRow>
          </Grid>
        ))}
        <DetailsSection>
          <Grid container justify="space-between" spacing={8}>
            <Grid item xs={6}>
              <StorageBox label="Active Storage" inUse={inUseActive} total={activeStorageLimit} />
            </Grid>
            <Grid item xs={6}>
              <StorageBox
                label="Archive Storage"
                inUse={inUseArchive}
                total={archiveStorageLimit}
              />
            </Grid>
          </Grid>
          <Typography variant="overline">
            Storage values are updated automatically at 10:00 AM UTC.
          </Typography>
        </DetailsSection>

        <DetailsSection title="Available Tiers" noBorder={isChosenFree}>
          <AvailablePlans
            displayMonthly={displayMonthly}
            onBillingCycleChange={setDisplayMonthly}
            onTierSelect={tier => setSelectedTier(tier.name)}
            activeTierName={subscriptionTierName}
            subscriptionTiers={subscriptionTiers}
            getTierActionLabel={getTierActionLabel}
          />
        </DetailsSection>
        {!isChosenFree &&
          activeSubscription.availableModules &&
          activeSubscription.availableModules.length && (
            <DetailsSection title="Available Modules" noBorder>
              <Grid container item spacing={6}>
                {renderModulesSection()}
              </Grid>
            </DetailsSection>
          )}
      </Grid>
      {selectedTier && (
        <UpdateSubscriptionDialog
          toggleDialogOpen={() => setSelectedTier(null)}
          tierName={selectedTier}
          displayMonthly={displayMonthly}
          currentSubscription={{
            tier: subscriptionTierName,
            interval: capitalize(
              scheduledSubscription ? scheduledSubscription.interval : subscription.interval
            ),
            nextBillingDate,
            modules: scheduledSubscription
              ? scheduledSubscription.activeModulesCount
              : subscription.numberOfModules,
            activeUsers: subscription.numberOfActiveUsers,
            totalUsers: subscription.numberOfTotalUsers
          }}
        />
      )}

      {selectedModule && (
        <SubscriptionModuleDialog
          toggleDialogOpen={() => setSelectedModule(null)}
          currentModule={selectedModule.current}
          scheduledModule={selectedModule.scheduled}
          interval={capitalize(subscription.interval)}
          tier={subscriptionTierName}
          nextBillingDate={nextBillingDate}
        />
      )}
    </Fragment>
  );
};

export default createFragmentContainer(BillingPlan, {
  billing: graphql`
    fragment BillingPlan_billing on Query {
      subscriptionTiers {
        name
        numberOfUsers
        activeStorage
        archiveStorage
        activeDeliverables
        activeContractors
        activeContractTemplates
        moduleAccess
        monthlyPrice
        annualPrice
      }
      organization {
        subscription {
          tierName
          interval
          nextBillingDate
          nextBillingAmount
          numberOfModules
          numberOfActiveUsers
          numberOfTotalUsers
          numberOfDeliverables
          numberOfContractors
          numberOfContractTemplates
        }
        scheduledSubscription {
          interval
          activeModulesCount
          availableModules {
            moduleId
            quantity
          }
        }
        activeSubscription {
          tier
          interval
          availableModules {
            moduleId
            name
            incremental
            quantity
            cost {
              tier
              price
            }
            blurbText
            description
            imageUrl
            headline
            externalUrl
          }
        }
        latestActiveMetricsData {
          total
        }
        latestArchiveMetricsData {
          total
        }
        activeStorageLimit
        archiveStorageLimit
      }
    }
  `
});

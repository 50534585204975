/**
 * @flow
 * @relayHash 38b450ca0e6f94b538c1c287a1509022
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotificationSettingsContent_intervalValues$ref = any;
type NotificationSettingsContent_notificationSettings$ref = any;
export type NotificationSettingsTabQueryVariables = {||};
export type NotificationSettingsTabQueryResponse = {|
  +__type: ?{|
    +$fragmentRefs: NotificationSettingsContent_intervalValues$ref
  |},
  +$fragmentRefs: NotificationSettingsContent_notificationSettings$ref,
|};
export type NotificationSettingsTabQuery = {|
  variables: NotificationSettingsTabQueryVariables,
  response: NotificationSettingsTabQueryResponse,
|};
*/


/*
query NotificationSettingsTabQuery {
  ...NotificationSettingsContent_notificationSettings
  __type(name: "StaffAppConfigurationNotificationSummaryInterval") {
    ...NotificationSettingsContent_intervalValues
  }
}

fragment NotificationSettingsContent_notificationSettings on Query {
  currentUser {
    orgStaff {
      notificationConfig
      receiveSummaryEmails
      notificationSummaryInterval
      id
    }
    id
  }
}

fragment NotificationSettingsContent_intervalValues on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "StaffAppConfigurationNotificationSummaryInterval"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NotificationSettingsTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"StaffAppConfigurationNotificationSummaryInterval\")",
        "args": (v0/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NotificationSettingsContent_intervalValues",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "NotificationSettingsContent_notificationSettings",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotificationSettingsTabQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "UserNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "orgStaff",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "notificationConfig",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "receiveSummaryEmails",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "notificationSummaryInterval",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/)
            ]
          },
          (v1/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"StaffAppConfigurationNotificationSummaryInterval\")",
        "args": (v0/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NotificationSettingsTabQuery",
    "id": null,
    "text": "query NotificationSettingsTabQuery {\n  ...NotificationSettingsContent_notificationSettings\n  __type(name: \"StaffAppConfigurationNotificationSummaryInterval\") {\n    ...NotificationSettingsContent_intervalValues\n  }\n}\n\nfragment NotificationSettingsContent_notificationSettings on Query {\n  currentUser {\n    orgStaff {\n      notificationConfig\n      receiveSummaryEmails\n      notificationSummaryInterval\n      id\n    }\n    id\n  }\n}\n\nfragment NotificationSettingsContent_intervalValues on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c47c3dae5dd9147fb0605e36acb8d24';
module.exports = node;

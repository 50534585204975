import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Field } from 'formik';
import { withRouter } from 'react-router-dom';
import { Link, Grid } from '@material-ui/core';

import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import contractorDetailsStyles from '../../../../../shared/styles/contractors/contractorDetailsStyles';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import AvatarWithName from '../../../../../shared/components/UI/AvatarWithName';
import FormikDropdownField from '../../../../../shared/components/form/FormikDropdownField';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import FormikPhoneField from '../../../../../shared/components/form/FormikPhoneField';

const ContractorInfo = props => {
  const {
    history,
    match,
    isEditing,
    contractor: {
      fullName,
      nickname,
      currency: preferredCurrency,
      companyName,
      email,
      phone,
      portfolioUrl,
      timezone,
      timezoneList,
      title,
      conflictingContractor,
      freelancer,
      internalId
    }
  } = props;

  const classes = contractorDetailsStyles();

  const handleConflictingContractorClick = () => {
    history.push(`${match.path.split(':id')[0]}${conflictingContractor.id}`);
  };

  return isEditing ? (
    <DetailsSection title="Contractor Info">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="firstName"
            label="First Name"
            fullWidth
            placeholder="Enter first name..."
            component={FormikTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="lastName"
            label="Last Name"
            fullWidth
            placeholder="Enter last name..."
            component={FormikTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="nickname"
            label="Nickname"
            fullWidth
            placeholder="Enter nickname..."
            component={FormikTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="title"
            label="Title"
            fullWidth
            placeholder="Enter title..."
            component={FormikTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="email"
            label="Email"
            fullWidth
            placeholder="Enter email address..."
            component={FormikTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="portfolioUrl"
            label="Portfolio"
            fullWidth
            placeholder="Enter portfolio url..."
            component={FormikTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="phone"
            label="Phone"
            fullWidth
            placeholder="Enter phone number..."
            component={FormikPhoneField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="timezone"
            label="Timezone"
            itemType="timezones"
            items={timezoneList.length ? timezoneList : null}
            placeholder="Select timezone..."
            component={FormikDropdownField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={freelancer !== null}
            name="companyName"
            label="Company name"
            fullWidth
            placeholder="Enter company name..."
            component={FormikTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={false}
            name="internalId"
            label="Internal ID"
            fullWidth
            placeholder="Enter contractor Internal ID..."
            component={FormikTextField}
          />
        </Grid>
      </Grid>
    </DetailsSection>
  ) : (
    <DetailsSection title="Contractor Info">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <DetailsRow title="Name" content={fullName} />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow title="Nickname" content={nickname} />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow title="Title" content={title} />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow title="Email" content={email} />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow
            title="Portfolio"
            content={
              portfolioUrl ? (
                <Link
                  href={portfolioUrl.includes('://') ? portfolioUrl : 'http://' + portfolioUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="caption"
                >
                  {portfolioUrl}
                </Link>
              ) : (
                '-'
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow title="Phone" content={phone} />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow title="Timezone" content={timezone} />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow title="Company Name" content={companyName} />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow title="Preferred Currency" content={preferredCurrency} />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow title="Internal ID" content={internalId} />
        </Grid>
      </Grid>
      {conflictingContractor && (
        <div className={classes.warningDetailsRow}>
          <DetailsRow
            title="Conflicting contractor"
            content={
              <AvatarWithName
                name={conflictingContractor.fullName}
                avatarProps={{
                  src: conflictingContractor.representativeImageUrl
                }}
              />
            }
            onClick={handleConflictingContractorClick}
            titleWidth={150}
          />
        </div>
      )}
    </DetailsSection>
  );
};

export default withRouter(
  createFragmentContainer(ContractorInfo, {
    contractor: graphql`
      fragment ContractorInfo_contractor on ContractorNode {
        id
        currency
        firstName
        lastName
        fullName
        nickname
        title
        email
        portfolioUrl
        phone
        timezone
        timezoneList
        companyName
        internalId
        conflictingContractor {
          id
          fullName
          representativeImageUrl
        }
        freelancer {
          id
        }
      }
    `
  })
);

import React, { Fragment, useState } from 'react';
import { Typography } from '@material-ui/core';
import { last } from 'lodash';

import AssetGrid from './AssetGrid';
import useAssetStyles from '../../styles/assets/useAssetStyles';
import useDialog from '../../hooks/useDialog';
import Dialog from '../common/Dialog';
import Pagination from '../lists/Pagination';
import GlobalButton from '../UI/GlobalButton';
import { PAGINATION_TYPES } from '../../constants';
import AssetForm from './AssetForm';
import { withTableHistoryContext } from '../../contexts/tableHistoryContext';

const AssetList = props => {
  const {
    edges,
    createItemHandler,
    deleteItemHandler,
    editItemHandler,
    canEditItem,
    canDeleteItem,
    toggleAction,
    showOnFullScreen = true,
    paginationProps,
    refetch,
    shouldShowUploadButton,
    emptyListText,
    maxFileSize,
    tableHistoryContext
  } = props;

  const classes = useAssetStyles();
  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const [editedItem, setEditedItem] = useState(null);
  const [cursorStack, setCursorStack] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    tableHistoryContext.rowsPerPage || PAGINATION_TYPES.short.defaultValue
  );

  const refetchHandler = variables => {
    refetch(prevVariables => ({ ...prevVariables, ...variables }));
  };

  const onChangeHandler = variables => {
    const { first, cursorStack } = variables;
    setCursorStack(cursorStack);
    const lastCursor = last(cursorStack);
    const after = lastCursor ? lastCursor : '';
    if (first) {
      setRowsPerPage(first);
      tableHistoryContext.setValue({ rowsPerPage: first });
    }

    refetchHandler({ after, ...(first ? { first } : null) });
  };

  const deleteHandler = id => {
    deleteItemHandler(id, () => setCursorStack([]));
  };

  const closeDialogHandler = () => {
    editedItem && setEditedItem(null);
    toggleDialogOpen();
  };

  const editHandler = id => {
    const editingItem = edges.find(edge => edge.node.id === id);
    setEditedItem(editingItem);
    toggleDialogOpen();
  };

  const submitCreateHandler = (values, formikBag) => {
    createItemHandler(values, formikBag, () => {
      formikBag.setSubmitting(false);
      toggleDialogOpen();
      onChangeHandler({ cursorStack: [] });
    });
  };

  const submitEditHandler = (values, { setSubmitting }) => {
    editItemHandler(values, () => {
      setSubmitting(false);
      closeDialogHandler();
    });
  };

  return (
    <Fragment>
      {createItemHandler && shouldShowUploadButton && (
        <div className={classes.headerToolbar}>
          <GlobalButton id="upload-asset" handleClick={toggleDialogOpen} variant="secondary">
            Upload File
          </GlobalButton>
        </div>
      )}
      {edges.length ? (
        <Fragment>
          <AssetGrid
            edges={edges}
            deleteItem={deleteItemHandler ? deleteHandler : null}
            editItem={editItemHandler ? editHandler : null}
            canDeleteItem={canDeleteItem}
            canEditItem={canEditItem}
            toggleAction={toggleAction}
            showOnFullScreen={showOnFullScreen}
          />
          <Pagination
            paginationType={PAGINATION_TYPES.short.name}
            onChange={onChangeHandler}
            rowsPerPage={rowsPerPage}
            cursorStack={cursorStack}
            {...paginationProps}
          />
        </Fragment>
      ) : (
        <Typography align="center" variant="body2">
          {emptyListText}
        </Typography>
      )}

      <Dialog
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialogHandler}
        title={editedItem ? 'Edit Asset' : 'Upload Asset'}
        minWidth={600}
      >
        <AssetForm
          onSubmit={editedItem ? submitEditHandler : submitCreateHandler}
          onCancel={closeDialogHandler}
          editedAsset={editedItem}
          maxFileSize={maxFileSize}
        />
      </Dialog>
    </Fragment>
  );
};

AssetList.defaultProps = {
  emptyListText: 'No files added'
};

export default withTableHistoryContext(AssetList);

import React, { Fragment, useState, useEffect } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';
import { Typography, Grid, Box } from '@material-ui/core';

import AutoTable from '../../../../../shared/components/table/AutoTable';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import ContractorDropdowns from '../../ContractorDropdowns';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import SolicitationDeliverableAllocations from './SolicitationDeliverableAllocations';
import SolicitationChatView from './SolicitationChatView';

import useInterval from '../../../../../shared/hooks/useInterval';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import RemoveSolicitationContractorMutation from '../../mutations/RemoveSolicitationContractorMutation';
import AddSolicitationContractorsMutation from '../../mutations/AddSolicitationContractorsMutation';
import getFetchNotifications from '../../../../../shared/mutations/notifications/fetchNotifications';
import { CELL_TYPES, SOLICITATION_STATES } from '../../../../../shared/constants';
import SolicitationContractorListFilters from './SolicitationContractorListFilters';

const SolicitationContractorsList = props => {
  const {
    relay: { refetch },
    solicitation: {
      id,
      solicitationId,
      state,
      numberOfDeliverables,
      numberOfAllocatedDeliverables,
      numberOfContractorsThatContacted,
      numberOfContractedDeliverables,
      contractors: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    },
    initialContractor,
    canEditSolicitation,
    canAddComment,
    toRefetchData,
    refetchCounterForContractors,
    setRefetchCounterForContractors,
    filterProps
  } = props;

  const [selectedIdForChat, setSelectedIdForChat] = useState(initialContractor);
  const [selectedIdForAllocations, setSelectedIdForAllocations] = useState(null);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    if (props.location.state && props.location.state.contractorId) {
      setSelectedIdForChat(props.location.state.contractorId);
    }
  }, [props.location.state]);

  useEffectWithStatus(
    status => {
      if (state !== SOLICITATION_STATES.draft) {
        refetchNotifications(status);
      } else {
        setNotifications(null);
      }
    },
    [state]
  );

  useInterval(
    () => {
      if (state === SOLICITATION_STATES.active) {
        refetchNotifications();
        setRefetchCounterForContractors(counter => counter + 1);
      }
    },
    60000,
    state
  );

  const refetchNotifications = getFetchNotifications('solicitation', setNotifications, {
    targetId: id
  });

  const messageClickHandler = id => {
    setSelectedIdForAllocations(null);
    id === selectedIdForChat ? setSelectedIdForChat(null) : setSelectedIdForChat(id);
  };

  const briefcaseClickHandler = id => {
    setSelectedIdForChat(null);
    id === selectedIdForAllocations
      ? setSelectedIdForAllocations(null)
      : setSelectedIdForAllocations(id);
  };

  const onChangeHandler = variables => {
    refetch(variables, null, () => {}, { force: true });
  };

  const linkClickHandler = id => {
    const { history } = props;
    history.push(`/contractors/contractors/${id}/details/`);
  };

  const removeClickHandler = contractorId => {
    RemoveSolicitationContractorMutation(id, contractorId, response => {
      if (response && response.isUpdated) {
        setRefetchCounterForContractors(prev => prev + 1);
      }
    });
  };

  const addToListHandler = newContractors => {
    const newContractorsIds = newContractors.map(contractor => contractor.id);
    if (newContractorsIds.length > 0) {
      AddSolicitationContractorsMutation(id, newContractorsIds, addSolicitationContractors => {
        if (addSolicitationContractors) {
          setRefetchCounterForContractors(prev => prev + 1);
        }
      });
    }
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      fullName,
      representativeImageUrl,
      freelancer,
      numberOfAllocatedDeliverablesInSolicitation,
      numberOfCommentsInSolicitation,
      numberOfContractedDeliverablesInSolicitation
    } = edge.node;
    let messageType = 'none';
    if (notifications && freelancer) {
      if (
        notifications.edges.find(element => element.node.actorObjectId === freelancer.user.userId)
      ) {
        messageType = 'new';
      } else {
        messageType = 'default';
      }
    } else if (state !== SOLICITATION_STATES.draft) {
      messageType = 'default';
    }
    return {
      node: {
        id,
        fullName: { name: fullName, imageUrl: representativeImageUrl, id },
        numberOfContractedDeliverablesInSolicitation,
        message: { type: messageType, value: String(numberOfCommentsInSolicitation) },
        briefcase: { value: String(numberOfAllocatedDeliverablesInSolicitation) }
      }
    };
  });

  return (
    <Fragment>
      <Typography variant="h3">{`${numberOfContractorsThatContacted} ${
        numberOfContractorsThatContacted === 1 ? 'Contractor' : 'Contractors'
      } responded to this JobOpp`}</Typography>
      <Grid container direction="row" style={{ marginTop: '20px', marginBottom: '25px' }}>
        <Grid item xs={4}>
          <DetailsRow
            title="Allocated Deliverables"
            content={numberOfAllocatedDeliverables}
            titleWidth={160}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailsRow
            title="Contracted Deliverables"
            content={numberOfContractedDeliverables}
            titleWidth={160}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailsRow title="Total Deliverables" content={numberOfDeliverables} />
        </Grid>
      </Grid>
      {canEditSolicitation() && (
        <Box width="300px" mb={2}>
          <ContractorDropdowns
            onChange={addToListHandler}
            contractorsInList={flattenedEdges.map(({ node }) => node)}
          />
        </Box>
      )}
      <AutoTable
        rowTemplate={[
          { name: 'briefcase', label: '', type: CELL_TYPES.briefcase, width: 48 },
          { name: 'message', label: '', type: CELL_TYPES.message, width: 48 },
          {
            name: 'fullName',
            label: 'Name',
            type: CELL_TYPES.avatar,
            sortable: true,
            onClick: linkClickHandler
          },
          {
            name: 'numberOfContractedDeliverablesInSolicitation',
            label: 'Contracted',
            align: 'right'
          },
          //Disabling Ratings from UI until feature fully fleshed out
          // { name: 'rating', label: 'Rating', type: CELL_TYPES.rating, width: '20%' },
          {
            name: 'trash',
            label: '',
            type: CELL_TYPES.trash,
            onClick: removeClickHandler,
            hidden: !canEditSolicitation(),
            tooltip: 'Remove'
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        refetchCounter={refetchCounterForContractors}
        rowProps={{
          handleMessageClick: messageClickHandler,
          handleBriefcaseClick: briefcaseClickHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
        filterProps={{
          filterComponent: <SolicitationContractorListFilters />,
          withApply: true,
          ...filterProps
        }}
      />
      <QuickViewDrawer selectedId={selectedIdForChat} setSelectedId={setSelectedIdForChat}>
        <SolicitationChatView
          contractorId={selectedIdForChat}
          solicitationId={id}
          allowAddNew={canAddComment()}
          refetchNotifications={refetchNotifications}
        />
      </QuickViewDrawer>
      <QuickViewDrawer
        selectedId={selectedIdForAllocations}
        setSelectedId={setSelectedIdForAllocations}
      >
        <SolicitationDeliverableAllocations
          contractorId={selectedIdForAllocations}
          solicitationId={id}
          solicitationDjangoId={solicitationId}
          setRefetchCounter={setRefetchCounterForContractors}
          toRefetchData={toRefetchData}
          setSelectedIdForAllocations={setSelectedIdForAllocations}
        />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withRouter(
  createRefetchContainer(
    SolicitationContractorsList,
    {
      solicitation: graphql`
        fragment SolicitationContractorsList_solicitation on SolicitationNode {
          id
          state
          solicitationId
          numberOfDeliverables
          numberOfAllocatedDeliverables
          numberOfContractorsThatContacted
          numberOfContractedDeliverables
          contractors(
            first: $first
            after: $after
            orderBy: $orderBy
            contractorName: $contractorName
            includeDoNotHire: $includeDoNotHire
            includeInactive: $includeInactive
            inviteStatus: $inviteStatus
          ) {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                fullName
                representativeImageUrl
                numberOfAllocatedDeliverablesInSolicitation
                numberOfCommentsInSolicitation
                numberOfContractedDeliverablesInSolicitation
                freelancer {
                  user {
                    userId
                  }
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query SolicitationContractorsListRefetchQuery(
        $first: Int
        $after: String
        $id: ID!
        $orderBy: String
        $contractorName: String
        $includeInactive: Boolean
        $includeDoNotHire: Boolean
        $inviteStatus: String
      ) {
        solicitation: solicitation(id: $id) {
          ...SolicitationContractorsList_solicitation
        }
      }
    `
  )
);

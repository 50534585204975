/**
 * @flow
 * @relayHash 00471b1a73a900909961915fa0ce6e54
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PaymentRequestsList_job$ref = any;
type PaymentRequestsList_statusTypes$ref = any;
export type PaymentRequestsListQueryVariables = {|
  jobId: string
|};
export type PaymentRequestsListQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: PaymentRequestsList_job$ref
  |},
  +paymentRequestStatusTypes: ?{|
    +$fragmentRefs: PaymentRequestsList_statusTypes$ref
  |},
|};
export type PaymentRequestsListQuery = {|
  variables: PaymentRequestsListQueryVariables,
  response: PaymentRequestsListQueryResponse,
|};
*/

/*
query PaymentRequestsListQuery(
  $jobId: ID!
) {
  job(id: $jobId) {
    ...PaymentRequestsList_job
    id
  }
  paymentRequestStatusTypes: __type(name: "PaymentRequestStatus") {
    ...PaymentRequestsList_statusTypes
  }
}

fragment PaymentRequestsList_job on JobNode {
  id
  totalValue
  currencyCode
  paymentRequests(orderBy: "-created") {
    edges {
      node {
        id
        created
        requestId
        paymentAmount
        status
      }
    }
  }
}

fragment PaymentRequestsList_statusTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'jobId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'jobId'
      }
    ],
    v2 = [
      {
        kind: 'Literal',
        name: 'name',
        value: 'PaymentRequestStatus'
      }
    ],
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'PaymentRequestsListQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'PaymentRequestsList_job',
              args: null
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: 'paymentRequestStatusTypes',
          name: '__type',
          storageKey: '__type(name:"PaymentRequestStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'PaymentRequestsList_statusTypes',
              args: null
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'PaymentRequestsListQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            (v3 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'totalValue',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'currencyCode',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'paymentRequests',
              storageKey: 'paymentRequests(orderBy:"-created")',
              args: [
                {
                  kind: 'Literal',
                  name: 'orderBy',
                  value: '-created'
                }
              ],
              concreteType: 'PaymentRequestNodeConnection',
              plural: false,
              selections: [
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'edges',
                  storageKey: null,
                  args: null,
                  concreteType: 'PaymentRequestNodeEdge',
                  plural: true,
                  selections: [
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'node',
                      storageKey: null,
                      args: null,
                      concreteType: 'PaymentRequestNode',
                      plural: false,
                      selections: [
                        (v3 /*: any*/),
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'created',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'requestId',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'paymentAmount',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'status',
                          args: null,
                          storageKey: null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: 'paymentRequestStatusTypes',
          name: '__type',
          storageKey: '__type(name:"PaymentRequestStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'enumValues',
              storageKey: null,
              args: null,
              concreteType: '__EnumValue',
              plural: true,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'name',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'description',
                  args: null,
                  storageKey: null
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'PaymentRequestsListQuery',
      id: null,
      text:
        'query PaymentRequestsListQuery(\n  $jobId: ID!\n) {\n  job(id: $jobId) {\n    ...PaymentRequestsList_job\n    id\n  }\n  paymentRequestStatusTypes: __type(name: "PaymentRequestStatus") {\n    ...PaymentRequestsList_statusTypes\n  }\n}\n\nfragment PaymentRequestsList_job on JobNode {\n  id\n  totalValue\n  currencyCode\n  paymentRequests(orderBy: "-created") {\n    edges {\n      node {\n        id\n        created\n        requestId\n        paymentAmount\n        status\n      }\n    }\n  }\n}\n\nfragment PaymentRequestsList_statusTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '733faf75fc00630248be4e18202fcae8';
module.exports = node;

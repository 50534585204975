import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

export default (provider, accessToken, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation SocialAuthMutation($input: SocialAuthInput!) {
        socialAuth(input: $input) {
          token
          refreshToken
          userType
          clientMutationId
        }
      }
    `,
    variables: {
      input: {
        provider,
        accessToken,
        clientMutationId: ''
      }
    },
    onCompleted: response => {
      callback(response);
    },
    onError: err => console.error(err)
  });
};

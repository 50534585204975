import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import { withRouter } from 'react-router';
import { CELL_TYPES } from '../../../shared/constants';
import AutoTable from '../../../shared/components/table/AutoTable';
import QuickViewDrawer from '../../../shared/components/common/QuickViewDrawer';
import { JOB_INVOICE_STATES } from '../../../shared/constants';
import InvoicesPageFilters from './InvoicesPageFilters';
import InvoiceQuickView from './InvoiceQuickView';

const InvoicesList = props => {
  const {
    relay: { refetch },
    invoices: {
      allInvoices: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    },
    statusTypes: { enumValues: statusEnumValues },
    history
  } = props;
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const handleQuickViewToggle = id => setSelectedInvoiceId(val => (val === id ? null : id));

  const flattenedEdges = edges.map(({ node }) => {
    const {
      id,
      identifier,
      verificationDate,
      job: {
        id: jobId,
        name: jobName,
        totalValue: jobTotalAmount,
        staffCoordinator: { fullName: staffName, representativeImageUrl: imageUrl },
        currencyCode
      },
      status,
      created,
      amount
    } = node;

    return {
      node: {
        id,
        identifier,
        jobId,
        jobName,
        jobTotalAmount,
        currencyCode,
        status: { code: status },
        created,
        approvedAt: status !== JOB_INVOICE_STATES.declined ? verificationDate : null,
        amount,
        staff: { name: staffName, imageUrl }
      }
    };
  });

  return (
    <>
      <QuickViewDrawer selectedId={selectedInvoiceId} setSelectedId={setSelectedInvoiceId}>
        <InvoiceQuickView invoiceId={selectedInvoiceId} />
      </QuickViewDrawer>
      <AutoTable
        rowTemplate={[
          { name: 'info', label: '', type: CELL_TYPES.info, onClick: handleQuickViewToggle },
          {
            name: 'identifier',
            label: 'Invoice ID',
            type: CELL_TYPES.link
          },
          {
            name: 'jobName',
            label: 'Job Name',
            type: CELL_TYPES.link,
            handleClick: edge => {
              history.push(`/jobs/${edge.jobId}`);
            }
          },
          {
            name: 'created',
            label: 'Date Submitted',
            type: CELL_TYPES.date
          },
          {
            name: 'approvedAt',
            label: 'Date Approved',
            type: CELL_TYPES.date
          },
          {
            name: 'status',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: statusEnumValues,
            variant: 'jobInvoice'
          },
          {
            name: 'staff',
            label: 'Contact',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center',
            width: 64
          },
          {
            name: 'amount',
            label: 'Invoice Amount',
            type: CELL_TYPES.amount,
            width: '10%'
          },
          {
            name: 'jobTotalAmount',
            label: 'Job Total Amount',
            type: CELL_TYPES.amount,
            width: '12%'
          }
        ]}
        rowProps={{
          handleLinkClick: handleQuickViewToggle
        }}
        edges={flattenedEdges}
        onChangeHandler={refetch}
        filterProps={{
          filterComponent: <InvoicesPageFilters statusOptions={statusEnumValues} />,
          withApply: true
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
        withBackground
      />
    </>
  );
};

export default withRouter(
  createRefetchContainer(
    InvoicesList,
    {
      invoices: graphql`
        fragment InvoicesList_invoices on Query
        @argumentDefinitions(
          after: { type: "String" }
          first: { type: "Int" }
          invoiceId: { type: "String" }
          jobName: { type: "String" }
          status: { type: "String", defaultValue: "" }
          contact: { type: "String" }
        ) {
          allInvoices(
            orderBy: "-created"
            after: $after
            first: $first
            invoiceId: $invoiceId
            jobName: $jobName
            status: $status
            contact: $contact
          ) {
            totalCount
            edgeCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                identifier
                created
                verificationDate
                job {
                  id
                  name
                  staffCoordinator {
                    fullName
                    representativeImageUrl
                  }
                  currencyCode
                  totalValue
                }
                status
                amount
              }
            }
          }
        }
      `,
      statusTypes: graphql`
        fragment InvoicesList_statusTypes on __Type {
          enumValues {
            name
            description
          }
        }
      `
    },
    graphql`
      query InvoicesListQuery(
        $first: Int
        $after: String
        $invoiceId: String
        $jobName: String
        $status: String
        $contact: String
      ) {
        ...InvoicesList_invoices
          @arguments(
            after: $after
            first: $first
            invoiceId: $invoiceId
            jobName: $jobName
            status: $status
            contact: $contact
          )
      }
    `
  )
);

import React from 'react';

import { useCommonAnnotationStyles } from '../../styles/deliverable/commonAnnotationStyles';

const ProgressBarAnnotation = props => {
  const { value, left, isActive, onAnnotationClick } = props;
  const classes = useCommonAnnotationStyles({ left, isActive });

  const onClickHandler = () => {
    onAnnotationClick(value);
  };

  return <div onClick={onClickHandler} className={classes.progressBarAnnotation} />;
};

export default ProgressBarAnnotation;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withUserContext from '../../contexts/userContext/withUserContext';
import { getActiveDeliverableDueDateWarningColor } from '../../utils/helpers';
import { useDetailsStyles } from '../../styles/common/useDetailsStyles';
import { ReactComponent as CalendarIcon } from '../../images/calendar.svg';
import Date from '../common/Date';
import StatusIndicator from './StatusIndicator';
import BottomSubtitle from './BottomSubtitle';

const HeaderSubtitle = props => {
  const { date, state, stateTypes, userContext, variant } = props;

  const classes = useDetailsStyles();

  const colorProp =
    variant === 'deliverable' && userContext.orgStaff
      ? getActiveDeliverableDueDateWarningColor(
          userContext.orgStaff.organization.configuration,
          date,
          state
        )
      : null;

  let dateLabel = null;
  switch (variant) {
    case 'deliverable':
      dateLabel = 'Due Date:';
      break;
    default:
      dateLabel = 'Job Completion Deadline:';
  }

  return (
    <div className={clsx(classes.container, classes.stateIndicatorContainer)}>
      <BottomSubtitle colorProp={colorProp} icon={<CalendarIcon />}>
        {dateLabel} <Date date={date} />
      </BottomSubtitle>
      <BottomSubtitle marginLeft={'12px'}>
        <StatusIndicator
          table
          statusTypes={stateTypes.enumValues}
          statusCode={state}
          variant={variant}
        />
      </BottomSubtitle>
    </div>
  );
};

HeaderSubtitle.propTypes = {
  /**
  The date displayed in the header
  */
  date: PropTypes.string,
  state: PropTypes.string.isRequired,
  stateTypes: PropTypes.shape({
    enumValues: PropTypes.array
  }).isRequired,
  variant: PropTypes.string.isRequired
};

export default withUserContext(HeaderSubtitle);

import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  commentsSection: {
    position: 'relative',
    height: '100%',
    maxHeight: props => (props.noMaxHeight ? '100vh' : '580px'),
    overflow: 'auto'
  },
  noComments: {
    textAlign: 'center',
    paddingTop: '16px'
  }
}));

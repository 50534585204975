/**
 * @flow
 * @relayHash c9edf15b19ff65670d910da3356be9bf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateJobCommentInput = {|
  jobId: string,
  content?: ?string,
  rawContent?: ?string,
  repliedTo?: ?string,
  clientMutationId?: ?string,
|};
export type CreateJobCommentMutationVariables = {|
  input: CreateJobCommentInput
|};
export type CreateJobCommentMutationResponse = {|
  +createJobComment: ?{|
    +job: ?{|
      +comments: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +content: string,
            +created: any,
            +contentEditedAt: ?any,
            +repliedTo: ?{|
              +user: ?{|
                +fullName: ?string
              |},
              +content: ?string,
              +created: ?any,
            |},
            +user: ?{|
              +id: string,
              +fullName: ?string,
              +representativeImageUrl: ?string,
            |},
          |}
        |}>
      |}
    |}
  |}
|};
export type CreateJobCommentMutation = {|
  variables: CreateJobCommentMutationVariables,
  response: CreateJobCommentMutationResponse,
|};
*/


/*
mutation CreateJobCommentMutation(
  $input: CreateJobCommentInput!
) {
  createJobComment(input: $input) {
    job {
      comments {
        edges {
          node {
            id
            content
            created
            contentEditedAt
            repliedTo {
              user {
                fullName
                id
              }
              content
              created
            }
            user {
              id
              fullName
              representativeImageUrl
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateJobCommentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contentEditedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateJobCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateJobCommentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "comments",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "repliedTo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRepliedToNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ]
                              },
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ]
                          },
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateJobCommentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJobComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateJobCommentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "comments",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "repliedTo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRepliedToNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v2/*: any*/)
                                ]
                              },
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ]
                          },
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateJobCommentMutation",
    "id": null,
    "text": "mutation CreateJobCommentMutation(\n  $input: CreateJobCommentInput!\n) {\n  createJobComment(input: $input) {\n    job {\n      comments {\n        edges {\n          node {\n            id\n            content\n            created\n            contentEditedAt\n            repliedTo {\n              user {\n                fullName\n                id\n              }\n              content\n              created\n            }\n            user {\n              id\n              fullName\n              representativeImageUrl\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a564b5b3731443fa1e5d8512854a88a9';
module.exports = node;

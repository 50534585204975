import { makeStyles } from '@material-ui/core/styles';

export const reviewAssetContentStyles = theme => ({
  card: {
    background: props => (props.active ? '#eeeeee' : 'transparent'),
    width: '100%',
    position: 'relative'
  },
  assetDateText: {
    display: 'block',
    alignSelf: 'flex-start',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.colors.mediumBlueGrey
  },

  cardActionArea: {
    padding: theme.spacing(1)
  }
});

export const useReviewAssetContentStyles = makeStyles(reviewAssetContentStyles);

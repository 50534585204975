import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import ContractTemplateDetails from './ContractTemplateDetails';

const ContractTemplateDetailsPage = props => {
  return (
    <QueryRenderer
      query={graphql`
        query ContractTemplateDetailsPageQuery($id: ID!) {
          contractTemplate(id: $id) {
            ...ContractTemplateDetails_template
          }
          __type(name: "ContractTemplateState") {
            ...ContractTemplateDetails_stateTypes
          }
        }
      `}
      variables={{
        id: props.match.params.id
      }}
      render={relayProps => (
        <ContractTemplateDetails
          stateTypes={relayProps.__type}
          template={relayProps.contractTemplate}
          {...props}
        />
      )}
    />
  );
};

export default ContractTemplateDetailsPage;

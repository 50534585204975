import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import DeliverableFiles from './DeliverableFiles';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import { initialOrderBy, initialVariables } from '../shared/initialVariables';

const StorageDeliverableFilesTab = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query StorageDeliverableFilesTabQuery(
          $first: Int
          $after: String
          $orderBy: String
          $storageClass: String
          $deliverableState: String
          $releaseId: String
          $deliverableTitle: String
          $fileName: String
        ) {
          organization {
            ...DeliverableFilesHeader_storageInfo
          }
          ...DeliverableFilesList_files
          storageClasses: __type(name: "StorageClassEnum") {
            ...DeliverableFilesList_storageClasses
          }
          deliverableStateTypes: __type(name: "DeliverableState") {
            ...DeliverableFilesList_deliverableStateTypes
          }
        }
      `}
      variables={variables}
      render={relayProps => <DeliverableFiles relayProps={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(StorageDeliverableFilesTab, 'orgStorageDelFiles', {
  orderBy: initialOrderBy,
  initialFilters: initialVariables
});

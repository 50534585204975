import { Table } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export const StyledTable = styled(Table)({
  borderCollapse: 'collapse',

  '& td, th': {
    borderBottom: 'none'
  }
});

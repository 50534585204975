import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Chip from '../../../../UI/Chip';

const MultiValue = props => {
  const {
    data: { id, label },
    removeProps: { onClick }
  } = props;

  return (
    <Grid item>
      <Chip id={id} text={label} onDelete={onClick} size="small" />
    </Grid>
  );
};

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired
  }).isRequired,
  selectProps: PropTypes.object.isRequired
};

export default MultiValue;

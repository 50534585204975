import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import JobsList from './JobsList';

const JobsListContainer = props => {
  const {
    jobs: {
      allJobs: {
        totalCount,
        edgeCount,
        pageInfo: { hasNextPage, endCursor },
        edges
      }
    },
    ...otherProps
  } = props;

  return (
    <JobsList
      edges={edges}
      paginationProps={{ totalCount, edgeCount, hasNextPage, endCursor }}
      {...otherProps}
    />
  );
};

export default createRefetchContainer(
  JobsListContainer,
  {
    jobs: graphql`
      fragment JobsList_jobs on Query {
        allJobs(
          first: $first
          after: $after
          orderBy: $orderBy
          jobDeadlineFrom: $jobDeadlineFrom
          jobDeadlineTo: $jobDeadlineTo
          onlyFollowed: $onlyFollowed
          jobId: $jobId
          releaseId: $releaseId
          productId: $productId
          category: $category
          status: $status
          paymentStatus: $paymentStatus
          includeInactive: $includeInactive
          contractorId: $contractorId
          staffId: $staffId
          jobName: $name
        ) {
          totalCount
          edgeCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              created
              jobId
              jobDeadline
              status
              name
              releases {
                id
                name
                product {
                  id
                  title
                }
              }
              isCurrentUserFollowing
              deliverableCategory {
                name
              }
              contractor {
                id
                fullName
                representativeImageUrl
              }
              staffCoordinator {
                id
                fullName
                representativeImageUrl
              }
              totalValue
              currencyCode
              paymentStatus
              hasInvoicesToReview
            }
          }
        }
      }
    `,
    statusTypes: graphql`
      fragment JobsList_statusTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `,
    paymentStatusTypes: graphql`
      fragment JobsList_paymentStatusTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  },

  graphql`
    query JobsListContainerRefetchQuery(
      $first: Int
      $after: String
      $orderBy: String
      $jobDeadlineFrom: Date
      $jobDeadlineTo: Date
      $jobId: String
      $releaseId: String
      $productId: String
      $category: ID
      $status: String
      $paymentStatus: String
      $includeInactive: Boolean
      $contractorId: String
      $staffId: String
      $name: String
      $onlyFollowed: Boolean
    ) {
      ...JobsList_jobs
    }
  `
);

/**
 * @flow
 * @relayHash 0b0d178dcb3c586759562fc5bcfa84db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFreelancerProfileInput = {|
  firstName?: ?string,
  lastName?: ?string,
  title?: ?string,
  phone?: ?string,
  skype?: ?string,
  nickname?: ?string,
  companyName?: ?string,
  timezone?: ?string,
  address1?: ?string,
  address2?: ?string,
  city?: ?string,
  state?: ?string,
  country?: ?string,
  zipcode?: ?string,
  portfolioUrl?: ?string,
  additionalInfo?: ?string,
  reprImageNull?: ?boolean,
  coverImageNull?: ?boolean,
  clientMutationId?: ?string,
|};
export type UpdateFreelancerProfileMutationVariables = {|
  input: UpdateFreelancerProfileInput
|};
export type UpdateFreelancerProfileMutationResponse = {|
  +updateFreelancerProfile: ?{|
    +updatedFreelancerProfile: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
      +fullName: ?string,
      +email: string,
      +representativeImageUrl: ?string,
      +freelancer: ?{|
        +id: string,
        +coverImageUrl: ?string,
        +companyName: ?string,
        +nickname: string,
        +title: ?string,
        +phone: ?string,
        +timezone: string,
        +address1: ?string,
        +address2: ?string,
        +country: ?{|
          +name: ?string,
          +code: ?string,
        |},
        +state: ?string,
        +city: ?string,
        +zipcode: ?string,
        +additionalInfo: ?string,
        +portfolioUrl: ?string,
      |},
    |}
  |}
|};
export type UpdateFreelancerProfileMutation = {|
  variables: UpdateFreelancerProfileMutationVariables,
  response: UpdateFreelancerProfileMutationResponse,
|};
*/


/*
mutation UpdateFreelancerProfileMutation(
  $input: UpdateFreelancerProfileInput!
) {
  updateFreelancerProfile(input: $input) {
    updatedFreelancerProfile {
      id
      firstName
      lastName
      fullName
      email
      representativeImageUrl
      freelancer {
        id
        coverImageUrl
        companyName
        nickname
        title
        phone
        timezone
        address1
        address2
        country {
          name
          code
        }
        state
        city
        zipcode
        additionalInfo
        portfolioUrl
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFreelancerProfileInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateFreelancerProfile",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateFreelancerProfilePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedFreelancerProfile",
        "storageKey": null,
        "args": null,
        "concreteType": "UserNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fullName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "representativeImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "freelancer",
            "storageKey": null,
            "args": null,
            "concreteType": "FreelancerNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "coverImageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "companyName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "nickname",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "phone",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "timezone",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "address1",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "address2",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "country",
                "storageKey": null,
                "args": null,
                "concreteType": "Country",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "code",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "state",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "city",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zipcode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "additionalInfo",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "portfolioUrl",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateFreelancerProfileMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateFreelancerProfileMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateFreelancerProfileMutation",
    "id": null,
    "text": "mutation UpdateFreelancerProfileMutation(\n  $input: UpdateFreelancerProfileInput!\n) {\n  updateFreelancerProfile(input: $input) {\n    updatedFreelancerProfile {\n      id\n      firstName\n      lastName\n      fullName\n      email\n      representativeImageUrl\n      freelancer {\n        id\n        coverImageUrl\n        companyName\n        nickname\n        title\n        phone\n        timezone\n        address1\n        address2\n        country {\n          name\n          code\n        }\n        state\n        city\n        zipcode\n        additionalInfo\n        portfolioUrl\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '668352f27bb1e88acabea0761148653a';
module.exports = node;

import React from 'react';
import { Grid } from '@material-ui/core';
import FilterDebounceInput from '../../../shared/components/filters/FilterDebounceInput';
import { useFilterStyles } from '../../../shared/styles/common/filterStyles';
import FilterSelect from '../../../shared/components/filters/FilterSelect';
import SearchableContactDropdown from '../shared/SearchableContactDropdown';

const InvoicesPageFilters = ({ filterValues, onFilterChange, statusOptions }) => {
  const classes = useFilterStyles();

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }} alignItems="center">
      {[
        {
          name: 'invoiceId',
          label: 'Invoice ID',
          component: FilterDebounceInput,
          props: { fullWidth: true }
        },
        {
          name: 'jobName',
          label: 'Job Name',
          component: FilterDebounceInput,
          props: { fullWidth: true }
        },
        {
          name: 'status',
          label: 'Status',
          component: FilterSelect,
          props: {
            options: statusOptions.map(({ name, description }) => ({
              value: name,
              name: description
            }))
          }
        },
        {
          name: 'contact',
          label: 'Contact',
          component: SearchableContactDropdown,
          onChange: value => onFilterChange('contact', value)
        }
      ].map(({ name, label, component: Component, props, onChange }) => (
        <Grid key={name} item xs={2}>
          <Component
            name={name}
            label={label}
            value={filterValues[name]}
            onChange={onChange || onFilterChange}
            {...props}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InvoicesPageFilters;

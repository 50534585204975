import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import { PAGINATION_TYPES } from '../../../../../shared/constants';
import DeliverableReferencesList from './DeliverableReferencesList';

const DeliverableReferenceTab = props => {
  const variables = useTableHistoryQueryVariables();
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableReferenceTabQuery(
          $id: ID!
          $first: Int
          $after: String
          $orderBy: String
        ) {
          deliverable(id: $id) {
            ...DeliverableReferencesList_deliverable @arguments(first: $first)
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <DeliverableReferencesList deliverable={relayProps.deliverable} {...props} />
      )}
    />
  );
};

export default withTableHistoryProvider(DeliverableReferenceTab, 'freDelReference', {
  rowsPerPage: PAGINATION_TYPES.short.defaultValue
});

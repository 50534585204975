/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReleaseDeliverablesListHeader_release$ref: FragmentReference;
declare export opaque type ReleaseDeliverablesListHeader_release$fragmentType: ReleaseDeliverablesListHeader_release$ref;
export type ReleaseDeliverablesListHeader_release = {|
  +id: string,
  +numberOfUnassignedDeliverables: ?number,
  +numberOfDeliverablesWithoutCoordinator: ?number,
  +deliverablesTotalAmount: ?any,
  +deliverablesTotalContractedAmount: ?any,
  +$refType: ReleaseDeliverablesListHeader_release$ref,
|};
export type ReleaseDeliverablesListHeader_release$data = ReleaseDeliverablesListHeader_release;
export type ReleaseDeliverablesListHeader_release$key = {
  +$data?: ReleaseDeliverablesListHeader_release$data,
  +$fragmentRefs: ReleaseDeliverablesListHeader_release$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ReleaseDeliverablesListHeader_release",
  "type": "ReleaseNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfUnassignedDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfDeliverablesWithoutCoordinator",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deliverablesTotalAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deliverablesTotalContractedAmount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '946be556e167a910d441a4037d574c03';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import InvoiceQuickView from './InvoiceQuickView';

const InvoiceQuickViewRenderer = ({ invoiceId }) => (
  <QueryRenderer
    query={graphql`
      query InvoiceQuickViewRendererQuery($id: ID!) {
        invoice(id: $id) {
          ...InvoiceQuickView_invoice
        }
        __type(name: "JobInvoiceStatus") {
          ...InvoiceQuickView_statusTypes
        }
      }
    `}
    variables={{ id: invoiceId }}
    render={relayProps => <InvoiceQuickView statusTypes={relayProps.__type} {...relayProps} />}
  />
);

export default InvoiceQuickViewRenderer;

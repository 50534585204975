import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { TOOLS_DESCRIPTIONS } from '../../../../../shared/constants';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import DeleteProductMutation from '../../mutations/DeleteProductMutation';
import DeactivateProductMutation from '../../mutations/DeactivateProductMutation';
import ActivateProductMutation from '../../mutations/ActivateProductMutation';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import ToolActions from '../../../shared/ToolActions';

const ProductToolsActions = props => {
  const {
    product: { id, hasReleases, isActive },
    history,
    userContext: {
      orgStaff: { allowedActions, organization }
    },
    setRefetchCounterForTools
  } = props;

  const [isSubmitting, setSubmitting] = useState(false);
  const navigation = JSON.parse(organization.configuration).navigation;

  const handleDelete = () => {
    DeleteProductMutation(id, response => {
      if (response && response.isDeleted) {
        history.push('/products');
      }
    });
  };

  const handleDeactivate = () => {
    setSubmitting(true);
    DeactivateProductMutation(id, response => {
      setSubmitting(false);
      if (response && response.updatedProduct) {
        setRefetchCounterForTools(prev => prev + 1);
      }
    });
  };

  const handleActivate = () => {
    setSubmitting(true);
    ActivateProductMutation(id, response => {
      setSubmitting(false);
      if (response && response.updatedProduct) {
        setRefetchCounterForTools(prev => prev + 1);
      }
    });
  };

  const canDeleteProduct =
    !hasReleases && computePermissionRole(ALLOWED_ACTIONS.PRODUCT_DELETE, allowedActions);

  const hasDeactivatePermission = computePermissionRole(
    ALLOWED_ACTIONS.PRODUCT_DEACTIVATE,
    allowedActions
  );

  const canDeactivateProduct = isActive && hasReleases && hasDeactivatePermission;

  const canActivateProduct = !isActive && hasDeactivatePermission;

  const actionList = [
    {
      condition: canDeleteProduct,
      actionRender: () => (
        <GlobalButton
          fullWidth
          noMargin
          id="deleteProduct"
          handleClick={handleDelete}
          disabled={isSubmitting}
        >
          Delete {navigation.productTitle.singular}
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.deleteProductFunc(
        navigation.productTitle.singular,
        navigation.releaseTitle.plural
      )
    },
    {
      condition: canDeactivateProduct,
      actionRender: () => (
        <GlobalButton
          fullWidth
          noMargin
          id="deactivateProduct"
          handleClick={handleDeactivate}
          disabled={isSubmitting}
        >
          Mark {navigation.productTitle.singular} Inactive
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.markProductInactive
    },
    {
      condition: canActivateProduct,
      actionRender: () => (
        <GlobalButton
          fullWidth
          noMargin
          id="activateProduct"
          handleClick={handleActivate}
          disabled={isSubmitting}
        >
          Mark {navigation.productTitle.singular} Active
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.activateProduct
    }
  ];

  return <ToolActions actions={actionList} isSubmitting={isSubmitting} />;
};

export default withRouter(
  withUserContext(
    createFragmentContainer(ProductToolsActions, {
      product: graphql`
        fragment ProductToolsActions_product on ProductNode {
          id
          hasReleases
          isActive
        }
      `
    })
  )
);

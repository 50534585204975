import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const getBottom = type => {
  switch (type) {
    case 'message':
      return '77%';
    case 'smallMessage':
      return '70%';
    default:
      return '80%';
  }
};
const getRight = (type, withBorder) => {
  switch (type) {
    case 'message':
      return '10%';
    case 'smallMessage':
      return '7%';
    default:
      return withBorder ? '15%' : '30%';
  }
};
const getBoxShadow = type => {
  switch (type) {
    case 'message':
    case 'smallMessage':
      return '1px';
    default:
      return '2px';
  }
};
const getWidth = type => {
  switch (type) {
    case 'message':
      return '7px';
    case 'smallMessage':
      return '5px';
    default:
      return '9px';
  }
};

const NewMessageBadge = withStyles(theme => ({
  badge: {
    backgroundColor: theme.palette.colors.red,
    color: theme.palette.colors.red,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    },
    '&.MuiBadge-dot': {
      minWidth: '5px'
    },

    bottom: props => getBottom(props.type),
    right: props => getRight(props.type, props.border),
    boxShadow: props => `0 0 0 ${getBoxShadow(props.type)} ${theme.palette.colors.lightBlueGrey}`,
    width: props => getWidth(props.type),
    height: props => getWidth(props.type)
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.7)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(1.8)',
      opacity: 0
    }
  }
}))(Badge);

export default NewMessageBadge;

const DELIVERABLES_URL = '/deliverables';
const TITLES_URL = '/products';
const RELEASES_URL = '/releases';
const JOB_URL = '/jobs';
const SOLICITATION_URL = '/solicitations';
const CONTRACTOR_URL = '/contractors/contractors';
const STAFF_URL = '/admin/staff-management/users';
const ORGANIZATION_URL = '/organizations/organizations';

const resolveReleaseLink = (id, tab) => {
  return RELEASES_URL + '/' + id;
};

const resolveProductLink = (id, tab) => {
  return TITLES_URL + '/' + id;
};

const resolveSolicitationLink = (id, tab) => {
  let link = `${SOLICITATION_URL}/${id}`;
  if (tab) {
    link += '/' + tab;
  }
  return link;
};

const resolveJobLink = (id, tab = 'details') => {
  return `${JOB_URL}/${id}/${tab}`;
};

const resolveJobInvoiceLink = (jobId, invoiceId) => {
  return (
    resolveJobLink(jobId, 'payment-requests-invoices') +
    `/invoices` +
    (invoiceId ? `/${invoiceId}` : '')
  );
};

const resolveDeliverableLink = (id, tab, tabId) => {
  let link = DELIVERABLES_URL + '/' + id;
  if (tab) {
    link += '/' + tab;
    if (tabId) {
      link += '/' + tabId;
    }
  }

  return link;
};

const resolveDeliverableReviewMarkupLink = (deliverableId, assetId) => {
  return `${DELIVERABLES_URL}/${deliverableId}/review/${assetId}/markup`;
};

const resolveContractorLink = id => {
  return CONTRACTOR_URL + '/' + id;
};

const resolveStaffLink = id => {
  return STAFF_URL + '/' + id;
};

const resolveOrganizationLink = id => {
  return ORGANIZATION_URL + '/' + id;
};

export {
  resolveReleaseLink,
  resolveProductLink,
  resolveSolicitationLink,
  resolveJobLink,
  resolveJobInvoiceLink,
  resolveDeliverableLink,
  resolveContractorLink,
  resolveStaffLink,
  resolveOrganizationLink,
  resolveDeliverableReviewMarkupLink
};

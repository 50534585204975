import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 40px 20px 0;
`;

const AddCommentButtonComponent = props => (
  <button type="button" {...props}>
    <ChatBubbleIcon />
    <Typography variant="body2" component="span">
      Add Comment
    </Typography>
  </button>
);

const AddCommentButton = styled(AddCommentButtonComponent)`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;

  svg {
    width: 16px;
    margin-right: 12px;

    path {
      fill: ${props => props.theme.palette.primary.main};
    }
  }

  .MuiTypography-root {
    font-size: 12px;
    color: ${props => props.theme.palette.primary.main};
  }
`;

const CommentListHeader = ({ onAddComment }) => (
  <Container>
    <Typography variant="h3">Comments</Typography>
    <AddCommentButton onClick={onAddComment} />
  </Container>
);

export default CommentListHeader;

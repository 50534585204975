import React from 'react';

import { Grid } from '@material-ui/core';

import DateField from '../../../shared/components/form/fields/DateField';
import FilterDebounceInput from '../../../shared/components/filters/FilterDebounceInput';
import FilterCheckbox from '../../../shared/components/filters/FilterCheckbox';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import { useFilterStyles } from '../../../shared/styles/common/filterStyles';

const ReleasesListFilters = props => {
  const {
    filterValues,
    onFilterChange,
    userContext: {
      orgStaff: { organization }
    }
  } = props;
  const classes = useFilterStyles();
  const navigation = JSON.parse(organization.configuration).navigation;

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          id="filterByNameReleaseList"
          value={filterValues['releaseName']}
          onChange={onFilterChange}
          name="releaseName"
          label={`${navigation.releaseTitle.singular} Name`}
          fullWidth
          placeholder={`Enter ${navigation.releaseTitle.singular} Name...`}
        />
      </Grid>
      <Grid item xs={2}>
        <FilterDebounceInput
          id="filterByReleaseCodeReleaseList"
          value={filterValues['releaseCode']}
          onChange={onFilterChange}
          name="releaseCode"
          label={`${navigation.releaseTitle.singular} Code`}
          fullWidth
          placeholder={`Enter ${navigation.releaseTitle.singular} Code...`}
        />
      </Grid>
      <Grid item xs={2}>
        <FilterDebounceInput
          value={filterValues['productCode']}
          id="filterByProductCodeReleaseList"
          onChange={onFilterChange}
          validateNegativeNumbers
          name="productCode"
          label={`${navigation.productTitle.singular} Code`}
          fullWidth
          placeholder={`Enter ${navigation.productTitle.singular} Code...`}
        />
      </Grid>
      <Grid item xs={2}>
        <FilterDebounceInput
          value={filterValues['productName']}
          id="filterByProductNameReleaseList"
          onChange={onFilterChange}
          name="productName"
          label={`${navigation.productTitle.singular} Name`}
          fullWidth
          placeholder={`Enter ${navigation.productTitle.singular} Name...`}
        />
      </Grid>
      <Grid item xs={2}>
        <FilterCheckbox
          name="includeInactive"
          id="filterIncludeInactiveReleaseList"
          value={filterValues['includeInactive']}
          label={`Include Inactive ${navigation.releaseTitle.plural}`}
          onChange={onFilterChange}
        />
      </Grid>
      <Grid item xs={2} classes={{ item: classes.dueDateFrom }}>
        <DateField
          name="deadlineFrom"
          id="filterByDeadlineFromReleaseList"
          value={filterValues['deadlineFrom']}
          onChange={onFilterChange}
          label="Key Date"
          placeholder="From..."
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name="deadlineTo"
          id="filterByDeadlineToReleaseList"
          value={filterValues['deadlineTo']}
          onChange={onFilterChange}
          label="Key Date"
          placeholder="To..."
        />
      </Grid>
    </Grid>
  );
};

export default withUserContext(ReleasesListFilters);

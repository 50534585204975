import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import DateField from '../../../shared/components/form/fields/DateField';
import FilterSelect from '../../../shared/components/filters/FilterSelect';
import FilterDebounceInput from '../../../shared/components/filters/FilterDebounceInput';
import SearchableStaffDropdown from '../shared/dropdowns/SearchableStaffDropdown';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import { useFilterStyles } from '../../../shared/styles/common/filterStyles';
import FilterCheckbox from '../../../shared/components/filters/FilterCheckbox';
import { SOLICITATION_STATES } from '../../../shared/constants';

const SolicitationsListFilters = props => {
  const { filterValues, stateOptions, onFilterChange } = props;
  const { includeClosed, state } = filterValues;

  const classes = useFilterStyles();

  const [mappedStateOptions, setMappedStateOptions] = useState([]);

  const mapStateOptions = options =>
    options.map(option => ({
      value: option.name,
      name: option.description
    }));

  useEffect(() => {
    if (state === SOLICITATION_STATES.closed && !includeClosed) {
      onFilterChange('state', '');
    }

    const newStateOptions = stateOptions.filter(option => {
      if (option.name === SOLICITATION_STATES.closed) {
        return includeClosed;
      }
      return true;
    });
    setMappedStateOptions(mapStateOptions(newStateOptions));
  }, [includeClosed, stateOptions, state, onFilterChange]);

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          id="filterBySubject"
          value={filterValues['subject']}
          onChange={onFilterChange}
          name="subject"
          label="Subject"
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          id="filterBySolicitationState"
          name="state"
          onChange={onFilterChange}
          value={filterValues['state']}
          label="State"
          options={mappedStateOptions}
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableStaffDropdown
          showCurrentUser
          id="filterByStaff"
          name="staffCoordinator"
          value={filterValues['staffCoordinator']}
          onChange={value => onFilterChange('staffCoordinator', value)}
          label="Coordinator"
        />
      </Grid>
      <Grid item xs={2} classes={{ item: classes.dueDateFrom }}>
        <DateField
          name="replyByFrom"
          id="filterByReplyByFrom"
          value={filterValues['replyByFrom']}
          onChange={onFilterChange}
          label="Reply By Date"
          placeholder="From..."
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name="replyByTo"
          id="filterByReplyByTo"
          value={filterValues['replyByTo']}
          onChange={onFilterChange}
          label="Reply By Date"
          placeholder="To..."
        />
      </Grid>
      <Grid item xs={2}>
        <FilterCheckbox
          id="filterincludeClosed"
          name="includeClosed"
          onChange={onFilterChange}
          value={filterValues['includeClosed']}
          label="Include Closed JobOpps"
        />
      </Grid>
    </Grid>
  );
};

export default withUserContext(SolicitationsListFilters);

import React from 'react';
import Dialog from '../../../../shared/components/common/Dialog';
import CreateInvoiceFormRenderer from './CreateInvoiceFormRenderer';

const CreateInvoiceDialog = ({ isDialogOpen, onClose, jobId, onCreated }) => (
  <Dialog isDialogOpen={isDialogOpen} closeDialog={onClose} title="Send Invoice" minWidth={500}>
    <CreateInvoiceFormRenderer
      jobId={jobId}
      onCancel={onClose}
      onCreated={() => {
        onClose();
        if (onCreated) onCreated();
      }}
    />
  </Dialog>
);

export default CreateInvoiceDialog;

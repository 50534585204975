import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import JobComments from '../../../shared/components/job/JobComments';

const JobCommentThread = props => {
  const {
    job,
    relay: { refetch }
  } = props;

  if (job) {
    const { id, comments } = job;
    return <JobComments jobId={id} comments={comments} refetch={refetch} />;
  } else {
    return <div>Loading</div>;
  }
};

export default createRefetchContainer(
  JobCommentThread,
  {
    job: graphql`
      fragment JobCommentThread_job on JobNode
      @argumentDefinitions(id: { type: "ID!", defaultValue: "" }) {
        id
        comments {
          edges {
            node {
              id
              content
              created
              contentEditedAt
              extraData
              commentType
              repliedTo {
                content
                created
                user {
                  fullName
                }
              }
              user {
                id
                fullName
                representativeImageUrl
              }
            }
          }
        }
      }
    `
  },
  graphql`
    query JobCommentThreadRefetchQuery($id: ID!) {
      job: job(id: $id) {
        ...JobCommentThread_job @arguments(id: $id)
      }
    }
  `
);

import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import DateField from '../../../../shared/components/form/fields/DateField';
import SearchableReleaseDropdown from '../../shared/dropdowns/SearchableReleaseDropdown';
import SearchableProductDropdown from '../../releases/SearchableProductDropdown';
import SearchableContractorDropdown from '../SearchableContractorDropdown';
import SearchableStaffDropdown from '../../shared/dropdowns/SearchableStaffDropdown';
import { CategorySelect } from '../../shared/dropdowns/CategorySelect';
import FilterDebounceInput from '../../../../shared/components/filters/FilterDebounceInput';
import FilterCheckbox from '../../../../shared/components/filters/FilterCheckbox';
import FilterSelect from '../../../../shared/components/filters/FilterSelect';
import { useFilterStyles } from '../../../../shared/styles/common/filterStyles';
import { JOB_STATUSES } from '../../../../shared/constants';

const JobsListFilters = ({ filterValues, onFilterChange, statusOptions, paymentStatusOptions }) => {
  const [mappedStatusOptions, setMappedStatusOptions] = useState([]);
  const { includeInactive } = filterValues;
  const classes = useFilterStyles();

  const mappedPaymentStatusOptions = paymentStatusOptions.map(({ name, description }) => ({
    value: name,
    name: description
  }));

  const mapStatusOptions = options =>
    options.map(option => ({
      value: option.name,
      name: option.description
    }));

  useEffect(() => {
    if (
      !includeInactive &&
      [
        JOB_STATUSES.canceled,
        JOB_STATUSES.revoked,
        JOB_STATUSES.closed,
        JOB_STATUSES.declined
      ].includes(filterValues['status'])
    ) {
      onFilterChange('status', '');
    }

    const newStatusOptions = statusOptions.filter(option => {
      if (
        [
          JOB_STATUSES.canceled,
          JOB_STATUSES.closed,
          JOB_STATUSES.revoked,
          JOB_STATUSES.declined
        ].includes(option.name)
      ) {
        return includeInactive;
      }
      return true;
    });
    setMappedStatusOptions(mapStatusOptions(newStatusOptions));
  }, [includeInactive]);

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }} alignItems="center">
      {[
        {
          name: 'name',
          label: 'Job Name',
          component: FilterDebounceInput,
          onChange: onFilterChange,
          props: { fullWidth: true, placeholder: 'Enter Job Name...' }
        },
        {
          name: 'releaseId',
          component: SearchableReleaseDropdown,
          onChange: value => onFilterChange('releaseId', value),
          props: { releaseOnly: true }
        },
        {
          name: 'productId',
          component: SearchableProductDropdown,
          onChange: value => onFilterChange('productId', value)
        },
        {
          name: 'category',
          label: 'Category',
          component: CategorySelect,
          onChange: onFilterChange,
          props: {
            formatOptions: options => [
              {
                value: '',
                label: 'Show All'
              },
              ...options.map(category => ({
                value: category.node.id,
                label: category.node.name
              }))
            ]
          }
        },
        {
          name: 'status',
          label: 'Job Status',
          component: FilterSelect,
          onChange: onFilterChange,
          props: { options: mappedStatusOptions }
        },
        {
          name: 'jobDeadlineFrom',
          label: 'Deadline',
          component: DateField,
          onChange: onFilterChange,
          props: { placeholder: 'From...' }
        },
        {
          name: 'jobDeadlineTo',
          label: 'Deadline',
          component: DateField,
          onChange: onFilterChange,
          props: { placeholder: 'To...' }
        },
        {
          name: 'staffId',
          label: 'Coordinator',
          component: SearchableStaffDropdown,
          onChange: value => onFilterChange('staffId', value),
          props: { showCurrentUser: true }
        },
        {
          name: 'contractorId',
          label: 'Contractor',
          component: SearchableContractorDropdown,
          onChange: value => onFilterChange('contractorId', value)
        },
        {
          name: 'paymentStatus',
          label: 'Payment Status',
          component: FilterSelect,
          onChange: onFilterChange,
          props: { options: mappedPaymentStatusOptions }
        },
        {
          name: 'includeInactive',
          label: 'Include Inactive Jobs',
          component: FilterCheckbox,
          onChange: onFilterChange
        },
        {
          name: 'onlyFollowed',
          label: 'Following',
          component: FilterCheckbox,
          onChange: onFilterChange
        }
      ].map(({ name, label, component: Component, props, onChange }) => (
        <Grid
          key={name}
          item
          xs={2}
          classes={{ item: name === 'jobDeadlineFrom' ? classes.dueDateFrom : '' }}
        >
          <Component
            name={name}
            value={filterValues[name]}
            onChange={onChange}
            {...(label && { label })}
            {...props}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default JobsListFilters;

import React, { forwardRef } from 'react';

import { styled } from '@material-ui/styles';
import { Box } from '@material-ui/core';

const toolbarHeight = 50;

export const AnnotationModalContentContainer = styled(Box)(() => ({
  display: 'flex',
  height: '100vh'
}));

export const AnnotationInterfaceContainer = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  flexFlow: 'column',
  borderLeft: `1px solid ${theme.palette.colors.lightBlueGrey}`,
  position: 'relative'
}));

export const AnnotationAssetContainer = styled(
  forwardRef(({ transparent, ...otherProps }, ref) => <Box ref={ref} {...otherProps} />)
)(({ transparent, theme }) => ({
  flex: '1 1 auto',
  position: 'relative',
  backgroundColor: transparent ? 'transparent' : theme.palette.colors.lightBlueGrey,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'auto',

  '& .video-js': {
    backgroundColor: 'transparent'
  },

  '& #audio-wave-markup': {
    width: '100%',
    margin: 'auto 0',
    padding: theme.spacing(6)
  },

  '& img': {
    maxHeight: '100%',
    maxWidth: '100%'
  }
}));

export const AnnotationToolbarContainer = styled(Box)(({ theme }) => ({
  flex: `0 1 ${toolbarHeight}px`,
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  minHeight: '54px',
  display: 'flex',
  backgroundColor: 'white'
}));

export const AnnotationActionsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 10,
  top: theme.spacing(3),
  right: theme.spacing(3)
}));

/**
 * @flow
 * @relayHash 049ea377bbda9656693c99828055d352
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PaymentRequestQuickViewContent_paymentRequest$ref = any;
type PaymentRequestQuickViewContent_statusTypes$ref = any;
export type PaymentRequestQuickViewQueryVariables = {|
  id: string
|};
export type PaymentRequestQuickViewQueryResponse = {|
  +paymentRequest: ?{|
    +$fragmentRefs: PaymentRequestQuickViewContent_paymentRequest$ref
  |},
  +__type: ?{|
    +$fragmentRefs: PaymentRequestQuickViewContent_statusTypes$ref
  |},
|};
export type PaymentRequestQuickViewQuery = {|
  variables: PaymentRequestQuickViewQueryVariables,
  response: PaymentRequestQuickViewQueryResponse,
|};
*/

/*
query PaymentRequestQuickViewQuery(
  $id: ID!
) {
  paymentRequest(id: $id) {
    ...PaymentRequestQuickViewContent_paymentRequest
    id
  }
  __type(name: "PaymentRequestStatus") {
    ...PaymentRequestQuickViewContent_statusTypes
  }
}

fragment PaymentRequestQuickViewContent_paymentRequest on PaymentRequestNode {
  id
  requestId
  status
  created
  paymentAmount
  paymentRequestedBy {
    id
    fullName
    representativeImageUrl
  }
  paymentRequestFileUrl
  message
  declinedBy {
    id
    fullName
    representativeImageUrl
  }
  declinedDate
  declinedReason
  invoice {
    invoiceFileUrl
    id
  }
  job {
    currencyCode
    contractor {
      id
      fullName
      representativeImageUrl
    }
    id
  }
}

fragment PaymentRequestQuickViewContent_statusTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id'
      }
    ],
    v2 = [
      {
        kind: 'Literal',
        name: 'name',
        value: 'PaymentRequestStatus'
      }
    ],
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v4 = [
      (v3 /*: any*/),
      {
        kind: 'ScalarField',
        alias: null,
        name: 'fullName',
        args: null,
        storageKey: null
      },
      {
        kind: 'ScalarField',
        alias: null,
        name: 'representativeImageUrl',
        args: null,
        storageKey: null
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'PaymentRequestQuickViewQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'paymentRequest',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'PaymentRequestNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'PaymentRequestQuickViewContent_paymentRequest',
              args: null
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: null,
          name: '__type',
          storageKey: '__type(name:"PaymentRequestStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'PaymentRequestQuickViewContent_statusTypes',
              args: null
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'PaymentRequestQuickViewQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'paymentRequest',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'PaymentRequestNode',
          plural: false,
          selections: [
            (v3 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'requestId',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'status',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'created',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'paymentAmount',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'paymentRequestedBy',
              storageKey: null,
              args: null,
              concreteType: 'StaffNode',
              plural: false,
              selections: (v4 /*: any*/)
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'paymentRequestFileUrl',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'message',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'declinedBy',
              storageKey: null,
              args: null,
              concreteType: 'StaffNode',
              plural: false,
              selections: (v4 /*: any*/)
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'declinedDate',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'declinedReason',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'invoice',
              storageKey: null,
              args: null,
              concreteType: 'InvoiceNode',
              plural: false,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'invoiceFileUrl',
                  args: null,
                  storageKey: null
                },
                (v3 /*: any*/)
              ]
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'job',
              storageKey: null,
              args: null,
              concreteType: 'JobNode',
              plural: false,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'currencyCode',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'contractor',
                  storageKey: null,
                  args: null,
                  concreteType: 'ContractorNode',
                  plural: false,
                  selections: (v4 /*: any*/)
                },
                (v3 /*: any*/)
              ]
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: null,
          name: '__type',
          storageKey: '__type(name:"PaymentRequestStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'enumValues',
              storageKey: null,
              args: null,
              concreteType: '__EnumValue',
              plural: true,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'name',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'description',
                  args: null,
                  storageKey: null
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'PaymentRequestQuickViewQuery',
      id: null,
      text:
        'query PaymentRequestQuickViewQuery(\n  $id: ID!\n) {\n  paymentRequest(id: $id) {\n    ...PaymentRequestQuickViewContent_paymentRequest\n    id\n  }\n  __type(name: "PaymentRequestStatus") {\n    ...PaymentRequestQuickViewContent_statusTypes\n  }\n}\n\nfragment PaymentRequestQuickViewContent_paymentRequest on PaymentRequestNode {\n  id\n  requestId\n  status\n  created\n  paymentAmount\n  paymentRequestedBy {\n    id\n    fullName\n    representativeImageUrl\n  }\n  paymentRequestFileUrl\n  message\n  declinedBy {\n    id\n    fullName\n    representativeImageUrl\n  }\n  declinedDate\n  declinedReason\n  invoice {\n    invoiceFileUrl\n    id\n  }\n  job {\n    currencyCode\n    contractor {\n      id\n      fullName\n      representativeImageUrl\n    }\n    id\n  }\n}\n\nfragment PaymentRequestQuickViewContent_statusTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '994d14f350f3ecb2b6293f9c8a59b277';
module.exports = node;

import React, { useState, Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import { ReactComponent as DeliverablesIcon } from '../../../../shared/images/deliverables.svg';
import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import BatchMarkDeliverableReadyMutation from '../mutations/BatchMarkDeliverableReadyMutation';
import BatchRevertToDraftDeliverableMutation from '../mutations/BatchRevertToDraftDeliverableMutation';
import BatchMarkDeliverableCompleteMutation from '../mutations/BatchMarkDeliverableCompleteMutation';
import ReopenDeliverableMutation from '../mutations/ReopenDeliverableMutation';
import { computePermissionRole, getAssignedUserId } from '../../../../shared/utils/helpers';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import { DELIVERABLE_STATES, ALLOWED_ACTIONS } from '../../../../shared/constants';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import ProductReleaseBreadcrumb from '../../../../shared/components/UI/ProductReleaseBreadcrumb';
import HeaderSubtitle from '../../../../shared/components/UI/HeaderSubtitle';
import DeliverableReviewTab from './DeliverableReviewTab';
import DeliverableReferenceTab from './DeliverableReferenceTab';
import DeliverableFilesTab from './DeliverableFilesTab';
import DeliverableToolsTab from './DeliverableToolsTab';
import DeliverableDetailsTab from './DeliverableDetailsTab';
import useDialog from '../../../../shared/hooks/useDialog';
import AssignDeliverableInternallyForm from '../AssignDeliverableInternallyForm';
import CloseDeliverableMutation from '../mutations/CloseDeliverableMutation';
import ConfirmationDialog from '../../../../shared/components/common/ConfirmationDialog';
import DeliverableDetailsToggleFollowButton, {
  DeliverableDetailsToggleFollowButtonDataContextProvider
} from './DeliverableDetailsToggleFollowButton';

const DeliverableDetails = props => {
  const classes = useDetailsStyles();
  const {
    deliverable: { id, title, state, release, assignedStaff, assignedInternally },
    deliverableStateTypes,
    relayProps,
    userContext: {
      id: currentUserId,
      orgStaff: { allowedActions }
    }
  } = props;

  const [isEditing, setEditing] = useState(false);
  const [refetchCounterForTools, setRefetchCounterForTools] = useState(0);
  const [isDialogOpen, setDialogOpen] = useDialog();

  const checkMutationResponse = response => {
    if (response && response.updatedDeliverable) {
      setRefetchCounterForTools(prev => prev + 1);
    }
  };

  const handleMarkReady = () => BatchMarkDeliverableReadyMutation([id]).then(checkMutationResponse);

  const handleRevertToDraft = () =>
    BatchRevertToDraftDeliverableMutation([id]).then(checkMutationResponse);

  const handleMarkCompleted = () =>
    BatchMarkDeliverableCompleteMutation([id]).then(checkMutationResponse);

  const handleReopen = () => ReopenDeliverableMutation(id, checkMutationResponse);

  const handleAssignInternally = () => {
    setDialogOpen(true);
  };

  const handleMarkClosed = () => CloseDeliverableMutation(id, checkMutationResponse);

  const checkMarkReadyAllowed = () => {
    if (state === DELIVERABLE_STATES.draft) {
      if (
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_READY_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_READY_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      ) {
        return (
          <GlobalButton id="markReadyButton" handleClick={handleMarkReady}>
            Mark Assignable
          </GlobalButton>
        );
      }
    }
    return null;
  };

  const checkRevertToDraftAllowed = () => {
    if (state === DELIVERABLE_STATES.ready) {
      if (
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_READY_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_READY_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      ) {
        return (
          <GlobalButton id="markReadyButton" handleClick={handleRevertToDraft}>
            Revert to Draft
          </GlobalButton>
        );
      }
    }
    return null;
  };

  const checkMarkCompletedAllowed = () => {
    if (state === DELIVERABLE_STATES.inProgress) {
      if (
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      ) {
        return (
          <GlobalButton id="markCompleteButton" handleClick={handleMarkCompleted}>
            Mark Completed
          </GlobalButton>
        );
      }
    }
    return null;
  };

  const checkReopenAllowed = () => {
    if (state === DELIVERABLE_STATES.completed) {
      if (
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      ) {
        return (
          <GlobalButton id="reopenButton" handleClick={handleReopen}>
            Reopen
          </GlobalButton>
        );
      }
    }
    return null;
  };

  const checkAssignInternallyAllowed = () => {
    if (
      state === DELIVERABLE_STATES.ready ||
      (state === DELIVERABLE_STATES.inProgress && assignedInternally)
    ) {
      if (
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_ASSIGN_INTERNALLY_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_ASSIGN_INTERNALLY_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      )
        return (
          <GlobalButton id="assignInternallyButton" handleClick={handleAssignInternally}>
            {assignedInternally ? 'Reassign Internally' : 'Assign Internally'}
          </GlobalButton>
        );
    }

    return null;
  };

  const checkMarkClosedAllowed = () => {
    if (state === DELIVERABLE_STATES.completed && assignedInternally) {
      if (
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_INTERNAL_MARK_CLOSED_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_INTERNAL_MARK_CLOSED_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      )
        return (
          <ConfirmationDialog
            content="This action is irreversible, and should be used to close internal Deliverables which do no belong to a Job once they are finished. Are you sure you want to close Deliverable?"
            copies={{ confirmCopy: 'Close Deliverable', cancelCopy: 'Cancel' }}
          >
            {confirm => (
              <GlobalButton id="markClosedButton" handleClick={confirm(handleMarkClosed)}>
                Mark Closed
              </GlobalButton>
            )}
          </ConfirmationDialog>
        );
    }
  };

  return (
    <DeliverableDetailsToggleFollowButtonDataContextProvider>
      <div
        className={clsx(
          classes.alignFlexStart,
          classes.marginBottom,
          classes.containerSpaceBetween
        )}
      >
        <CategoryTitleHeader
          title={title}
          renderTop={() => (
            <ProductReleaseBreadcrumb
              config={{
                product: release.product.title,
                release: release.name,
                productLink: `/products/${release.product.id}`,
                releaseLink: `/releases/${release.id}`
              }}
            />
          )}
          renderBottom={() => (
            <HeaderSubtitle
              date={props.deliverable.dueDate}
              state={state}
              stateTypes={deliverableStateTypes}
              variant="deliverable"
            />
          )}
          icon={DeliverablesIcon}
          link="/deliverables"
        />
        <div className={classes.container}>
          <Box mr={2} height={34} display="flex" alignItems="center">
            <DeliverableDetailsToggleFollowButton deliverable={relayProps.deliverable} />
          </Box>
          {!isEditing && (
            <>
              {checkAssignInternallyAllowed()}
              {checkMarkReadyAllowed()}
              {checkMarkCompletedAllowed()}
              {checkRevertToDraftAllowed()}
              {checkReopenAllowed()}
              {checkMarkClosedAllowed()}
            </>
          )}
        </div>
      </div>
      <AutoTabList
        label="deliverable details tabs"
        initialTabIndex={state === DELIVERABLE_STATES.inProgress ? 1 : 0}
      >
        <DeliverableDetailsTab isEditing={isEditing} setEditing={setEditing} label="Details" />
        <DeliverableReviewTab disabled={isEditing} label="Review" />
        <DeliverableReferenceTab disabled={isEditing} label="Reference" />
        <DeliverableFilesTab disabled={isEditing} label="Files" />
        <DeliverableToolsTab
          disabled={isEditing}
          setRefetchCounterForTools={setRefetchCounterForTools}
          refetchCounterForTools={refetchCounterForTools}
          label="Tools"
          refetchCounter={props.refetchCounter}
        />
      </AutoTabList>
      <AssignDeliverableInternallyForm
        isDialogOpen={isDialogOpen}
        deliverableIds={[id]}
        handleClose={() => setDialogOpen(false)}
        handleCloseWithRefetch={() => {
          setDialogOpen(false);
          setRefetchCounterForTools(prev => prev + 1);
        }}
        modalTitle={`${assignedInternally ? 'Reassign' : 'Assign'} Internal Deliverable`}
        initialValue={
          assignedInternally && { value: assignedInternally.id, label: assignedInternally.fullName }
        }
      />
    </DeliverableDetailsToggleFollowButtonDataContextProvider>
  );
};

export default withUserContext(
  createFragmentContainer(DeliverableDetails, {
    deliverable: graphql`
      fragment DeliverableDetails_deliverable on DeliverableNode {
        id
        title
        state
        internalId
        assignedStaff {
          id
          fullName
          user {
            id
          }
        }
        assignedInternally {
          id
          fullName
        }
        release {
          id
          name
          code
          product {
            id
            title
            code
          }
        }
        dueDate
      }
    `,
    deliverableStateTypes: graphql`
      fragment DeliverableDetails_deliverableStateTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  })
);

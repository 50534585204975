/**
 * @flow
 * @relayHash 534acbaf29c00308851d93eb29f5a36d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteReviewAssetCommentInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeleteReviewAssetCommentMutationVariables = {|
  input: DeleteReviewAssetCommentInput
|};
export type DeleteReviewAssetCommentMutationResponse = {|
  +deleteReviewAssetComment: ?{|
    +isDeleted: ?boolean,
    +reviewAsset: ?{|
      +reviewAssetAnnotation: ?{|
        +id: string,
        +annotationData: ?any,
      |},
      +comments: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +created: any,
            +content: string,
            +extraData: ?any,
            +objectId: number,
            +contentEditedAt: ?any,
            +user: ?{|
              +id: string,
              +fullName: ?string,
              +representativeImageUrl: ?string,
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type DeleteReviewAssetCommentMutation = {|
  variables: DeleteReviewAssetCommentMutationVariables,
  response: DeleteReviewAssetCommentMutationResponse,
|};
*/


/*
mutation DeleteReviewAssetCommentMutation(
  $input: DeleteReviewAssetCommentInput!
) {
  deleteReviewAssetComment(input: $input) {
    isDeleted
    reviewAsset {
      reviewAssetAnnotation {
        id
        annotationData
      }
      comments {
        edges {
          node {
            id
            created
            content
            extraData
            objectId
            contentEditedAt
            user {
              id
              fullName
              representativeImageUrl
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteReviewAssetCommentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDeleted",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reviewAssetAnnotation",
  "storageKey": null,
  "args": null,
  "concreteType": "DeliverableReviewAnnotationNode",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "annotationData",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "comments",
  "storageKey": null,
  "args": null,
  "concreteType": "CommentNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CommentNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "CommentNode",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "created",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "content",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "extraData",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "objectId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "contentEditedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "user",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fullName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "representativeImageUrl",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteReviewAssetCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteReviewAssetComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteReviewAssetCommentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "ReviewAssetNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteReviewAssetCommentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteReviewAssetComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteReviewAssetCommentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "ReviewAssetNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteReviewAssetCommentMutation",
    "id": null,
    "text": "mutation DeleteReviewAssetCommentMutation(\n  $input: DeleteReviewAssetCommentInput!\n) {\n  deleteReviewAssetComment(input: $input) {\n    isDeleted\n    reviewAsset {\n      reviewAssetAnnotation {\n        id\n        annotationData\n      }\n      comments {\n        edges {\n          node {\n            id\n            created\n            content\n            extraData\n            objectId\n            contentEditedAt\n            user {\n              id\n              fullName\n              representativeImageUrl\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fdf71691051f5f12453d1362e0258992';
module.exports = node;

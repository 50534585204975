import React from 'react';
import GlobalButton from './GlobalButton';

const SaveButton = ({ id, onClick, disabled, loading }) => {
  return (
    <GlobalButton id={id} type="submit" handleClick={onClick} disabled={disabled} loading={loading}>
      Save
    </GlobalButton>
  );
};

export default SaveButton;

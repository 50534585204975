import React, { Fragment } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import { CELL_TYPES } from '../../../../shared/constants';
import AutoTable from '../../../../shared/components/table/AutoTable';
import ContractTemplatesListFilters from './ContractTemplatesListFilters';

const ContractTemplatesList = props => {
  const {
    relay: { refetch },
    contractTemplates: {
      contractTemplatesByOrg: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    },
    stateTypes,
    refetchCounter
  } = props;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    const { history, match } = props;
    history.push(`${match.url}/${id}`);
  };

  const flattenedEdges = edges.map(edge => {
    const { id, name, createdBy, created, state: stateCode, categories } = edge.node;

    const createdByField = createdBy
      ? { name: createdBy.fullName, imageUrl: createdBy.representativeImageUrl }
      : { name: '', imageUrl: null };

    return {
      node: {
        id,
        createdBy: createdByField,
        name,
        created,
        status: {
          code: stateCode
        },
        categories: categories.edges.map(category => category.node.name)
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          {
            name: 'name',
            label: 'Template name',
            type: CELL_TYPES.link,
            sortable: true
          },
          { name: 'categories', label: 'Categories', type: CELL_TYPES.listWithBadge },
          {
            name: 'created',
            label: 'Created',
            type: CELL_TYPES.date,
            sortable: true
          },
          {
            name: 'status',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: stateTypes.enumValues,
            variant: 'contractTemplate'
          },
          { name: 'createdBy', label: 'Created by', type: CELL_TYPES.avatar }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        refetchCounter={refetchCounter}
        rowProps={{
          handleLinkClick: onLinkClickHandler
        }}
        filterProps={{
          filterComponent: <ContractTemplatesListFilters />,
          withoutContainer: true
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
        withBackground
      />
    </Fragment>
  );
};

export default withRouter(
  createRefetchContainer(
    ContractTemplatesList,
    {
      contractTemplates: graphql`
        fragment ContractTemplatesList_contractTemplates on Query {
          contractTemplatesByOrg(
            first: $first
            orderBy: $orderBy
            after: $after
            excludeInactive: $excludeInactive
          ) {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                name
                created
                createdBy {
                  fullName
                  representativeImageUrl
                }
                state
                categories(orderBy: "name") {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      stateTypes: graphql`
        fragment ContractTemplatesList_stateTypes on __Type {
          enumValues {
            name
            description
          }
        }
      `
    },
    graphql`
      query ContractTemplatesListRefetchQuery(
        $first: Int
        $orderBy: String
        $after: String
        $excludeInactive: Boolean
      ) {
        ...ContractTemplatesList_contractTemplates
      }
    `
  )
);

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  commentHeaderContainer: {
    display: 'inline-flex',
    flexFlow: 'column',
    width: '100%'
  },
  textAlign: {
    textAlign: props => props.direction
  },
  avatarAlign: {
    marginLeft: props => (props.direction === 'start' ? '0px' : '12px'),
    marginRight: props => (props.direction === 'start' ? '12px' : '6px')
  },
  commentHeader: {
    width: 'inherit',
    display: 'inline-flex',
    alignItems: 'center'
  },
  commentHeaderAvatar: {
    minWidth: '34px',
    '& .MuiAvatar-root': {
      width: '34px',
      height: '34px'
    }
  },
  commentHeaderActionContainer: {
    marginLeft: '4px',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    height: '24px',
    '& button': {
      marginLeft: '4px'
    }
  },
  justifyLeft: {
    alignSelf: 'flex-start',
    width: 'inherit'
  },
  commentCard: {
    flexDirection: 'column',
    backgroundColor: props => (props.highlighted ? theme.palette.colors.lightBlueGrey : 'inherit')
  },
  commentBody: {
    textAlign: 'left',
    width: 'inherit',
    color: theme.palette.colors.darkBlueGrey,
    fontSize: '14px',
    lineHeight: '24px'
  },
  commentEditedSpan: {
    fontSize: '10px',
    color: theme.palette.colors.mediumBlueGrey
  }
}));

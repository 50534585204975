import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Box, Grid, Divider } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { yupSchemas } from '../../../../shared/validations';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import Detail from '../../../../shared/components/UI/Detail';
import AvatarWithName from '../../../../shared/components/UI/AvatarWithName';
import { FormikCheckbox } from '../../../../shared/components/form/FormikCheckbox';
import FormikTextField from '../../../../shared/components/form/FormikTextField';
import FormikDropzone from '../../../../shared/components/form/formikFields/FormikDropzone';
import FormikAmountFieldWithCurrencyRate from '../../../../shared/components/form/formikFields/FormikAmountFieldWithCurrencyRate';
import FormikRadioGroupField from '../../../../shared/components/form/FormikRadioGroupField';
import FullscreenModal from '../../../../shared/components/fullscreenModal/FullscreenModal';
import ContractPreview from '../../../../shared/components/job/ContractPreview';
import { ERROR_LABELS } from '../../../../shared/constants';
import { errorToast, successToast } from '../../../../shared/toasts';
import { formatAmount } from '../../../../shared/utils/formatters';
import PreviewInvoiceMutation from './mutations/PreviewInvoiceMutation';
import CreateInvoiceMutation from './mutations/CreateInvoiceMutation';

const CreateInvoiceForm = props => {
  const { job, paymentSettings, invoiceTypes, onCreated, onCancel } = props;
  const [invoicePreviewBase64, setInvoicePreviewBase64] = useState(null);

  const handleSubmit = (values, { setSubmitting }) => {
    const {
      invoice_file,
      includeVat,
      includeTaxWithholding,
      vatPercentage,
      witholdingPercentage,
      ...fieldValues
    } = values;

    const input = {
      ...fieldValues,
      vatPercentage: includeVat && vatPercentage ? vatPercentage : undefined,
      witholdingPercentage:
        includeTaxWithholding && witholdingPercentage ? witholdingPercentage : undefined
    };

    if (invoicePreviewBase64) {
      CreateInvoiceMutation(input, invoice_file).then(() => {
        setSubmitting(false);
        setInvoicePreviewBase64(null);
        if (onCreated) onCreated();
        successToast('Invoice was created!');
      });
    } else {
      PreviewInvoiceMutation(input, invoice_file).then(res => {
        setSubmitting(false);
        if (res && res.previewInvoice && res.previewInvoice.invoicePreview) {
          setInvoicePreviewBase64(
            `data:application/pdf;base64,` + res.previewInvoice.invoicePreview
          );
        } else {
          errorToast('Failed to generate invoice, please try again!');
        }
      });
    }
  };

  const initialValues = {
    jobId: job.id,
    invoice_file: null,
    invoiceType: 'AWEBASE',
    amount: '',
    identifier: `${job.staffCoordinator.organization.name}-${job.jobId}-${
      job.invoices.totalCount + 1
    }`,
    paymentTerm: paymentSettings.paymentTerm || '',
    vatPercentage: paymentSettings.vatPercentage || '',
    witholdingPercentage: paymentSettings.taxPercentage || '',
    includeVat: paymentSettings.includeVat || false,
    includeTaxWithholding: paymentSettings.includeVat || false
  };

  const maxAmount = job.balance - job.pendingInvoiceTotals;

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required('Required')
      .max(
        maxAmount,
        `Amount cannot exceed ${formatAmount(
          maxAmount
        )}, which is the job balance minus any additional pending invoices`
      ),
    invoiceType: yupSchemas.string(true),
    paymentTerm: Yup.mixed().when('invoiceType', (invoiceType, schema) =>
      invoiceType === 'AWEBASE' ? yupSchemas.string(true) : schema
    ),
    vatPercentage: Yup.number().max(100),
    witholdingPercentage: Yup.number().max(100),
    identifier: Yup.mixed().when('invoiceType', (invoiceType, schema) =>
      invoiceType === 'CUSTOM' ? yupSchemas.string(true) : schema
    ),
    invoice_file: Yup.mixed().when('invoiceType', (invoiceType, schema) =>
      invoiceType === 'CUSTOM'
        ? Yup.mixed()
            .required(ERROR_LABELS.fileRequired)
            .test('fileSize', 'File cannot be empty.', value => value && value.size > 0)
        : schema
    ),
    message: yupSchemas.string(false, { max: 5000 })
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <>
          <FullscreenModal
            open={invoicePreviewBase64 !== null}
            handleBack={() => setInvoicePreviewBase64(null)}
            actions={[
              {
                label: 'Submit',
                handler: submitForm,
                isSubmitting
              }
            ]}
          >
            {invoicePreviewBase64 && <ContractPreview contractPreviewPdf={invoicePreviewBase64} />}
          </FullscreenModal>

          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Organization"
              value={job.staffCoordinator.organization.name}
              flexDirection="column"
              alignItems="flex-start"
            />
            <Detail
              name="Job ID"
              value={job.staffCoordinator.organization.name + '-' + job.jobId}
              flexDirection="column"
              alignItems="flex-end"
            />
          </Box>
          <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
            <Detail
              name="Job Name"
              value={job.name}
              flexDirection="column"
              alignItems="flex-start"
            />
          </Box>
          <Box display="flex" justifyContent="space-between" mt={2} mb={3}>
            <Detail
              name="Contact"
              renderValue={() => (
                <AvatarWithName
                  name={job.staffCoordinator.fullName}
                  size={34}
                  avatarProps={{
                    src: job.staffCoordinator.representativeImageUrl
                  }}
                />
              )}
              flexDirection="column"
              alignItems="flex-start"
            />
            <Detail
              name="Job Balance"
              value={formatAmount(job.balance, job.currencyCode)}
              flexDirection="column"
              alignItems="flex-end"
              valueContainerProps={{
                variant: 'h2'
              }}
            />
          </Box>

          <Box mt={3} mb={2}>
            <Divider />
          </Box>

          <Form>
            <Box mb={values.invoiceType === 'AWEBASE' ? 1 : 2}>
              <Field
                component={FormikRadioGroupField}
                name="invoiceType"
                label=""
                options={invoiceTypes.enumValues.map(({ name }) => ({
                  value: name,
                  name: name === 'AWEBASE' ? 'Generate Invoice' : 'Attach Invoice'
                }))}
                onChangeHandler={(e, _form, field) => {
                  setFieldValue(field.name, e.target.value);
                }}
              />
            </Box>

            {values.invoiceType === 'AWEBASE' ? (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      component={FormikTextField}
                      name="paymentTerm"
                      label={
                        <>
                          <br />
                          Payment Terms
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="amount"
                      label={
                        <>
                          Job Payment <br />
                          (Exclusive of VAT and Tax Withholding)
                        </>
                      }
                      component={FormikAmountFieldWithCurrencyRate}
                      currencyCode={job.currencyCode}
                      targetCurrencyRate={job.conversionRateInPreferredCurrency}
                      targetCurrencyCode={paymentSettings.preferredCurrency}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} display="flex">
                    <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                      <Field name="includeVat" label="Include VAT" component={FormikCheckbox} />
                      <Field
                        style={{ width: '35%' }}
                        component={FormikTextField}
                        type="number"
                        name="vatPercentage"
                        label="VAT"
                        decoratorEnd="%"
                        step="0.01"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                      <Field
                        name="includeTaxWithholding"
                        label="Tax Withholding"
                        component={FormikCheckbox}
                      />
                      <Field
                        style={{ width: '35%' }}
                        component={FormikTextField}
                        type="number"
                        name="witholdingPercentage"
                        label="Withholding"
                        decoratorEnd="%"
                        step="0.01"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={FormikTextField}
                      name="message"
                      label="Invoice Message"
                      multiline
                      rows={6}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="invoice_file"
                    label="Upload a .pdf file"
                    component={FormikDropzone}
                    accept="application/pdf"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={FormikTextField}
                    name="identifier"
                    label={
                      <>
                        <br />
                        Invoice Number/ID*
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="amount"
                    label="Job Payment (Exclusive of VAT and Tax Withholding)"
                    component={FormikAmountFieldWithCurrencyRate}
                    currencyCode={job.currencyCode}
                    targetCurrencyRate={job.conversionRateInPreferredCurrency}
                    targetCurrencyCode={paymentSettings.preferredCurrency}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={FormikTextField}
                    name="message"
                    label="Invoice Message"
                    multiline
                    rows={6}
                  />
                </Grid>
              </Grid>
            )}

            <Box display="flex" justifyContent="flex-end" mt={4}>
              <GlobalButton handleClick={onCancel} variant="transparent" disabled={isSubmitting}>
                Cancel
              </GlobalButton>
              <GlobalButton
                loading={isSubmitting}
                disabled={isSubmitting}
                type="submit"
                variant="primary"
                style={{ marginRight: 0 }}
              >
                Review and Submit
              </GlobalButton>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default createFragmentContainer(CreateInvoiceForm, {
  job: graphql`
    fragment CreateInvoiceForm_job on JobNode {
      id
      jobId
      name
      balance
      pendingInvoiceTotals
      currencyCode
      conversionRateInPreferredCurrency
      staffCoordinator {
        fullName
        representativeImageUrl
        organization {
          name
        }
      }
      invoices {
        totalCount
      }
    }
  `,
  paymentSettings: graphql`
    fragment CreateInvoiceForm_paymentSettings on FreelancerPaymentConfigurationNode {
      preferredCurrency
      paymentTerm
      paymentInfo
      includeVat
      vatPercentage
      taxPercentage
      taxIdentifier
    }
  `,
  invoiceTypes: graphql`
    fragment CreateInvoiceForm_invoiceTypes on __Type {
      enumValues {
        name
      }
    }
  `
});

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../table/CustomTableTooltip';
import DropdownTooltip from '../DropdownTooltip';
import {
  Container,
  ToggleButton,
  ToggleFollowersListButton,
  FollowersListContainer,
  FollowersListTitle,
  FollowersListContentContainer,
  FollowersList,
  FollowersListLoading,
  FollowersListItem
} from './styled';

const FollowButton = ({
  isFollowing,
  followers,
  totalFollowers,
  onFollowToggle,
  onShowFollowersToggle,
  onFollowerRemove,
  renderAddFollowerForm,
  shouldHideOnOusideClick
}) => {
  const containerRef = useRef(null);
  const [isFollowersListVisible, setIsFollowersListVisible] = useState(false);

  const handleFollowersListToggle = () => {
    const newState = !isFollowersListVisible;
    if (onShowFollowersToggle) onShowFollowersToggle(newState);
    setIsFollowersListVisible(newState);
  };

  const canHideOnOutsideClick = e => {
    const parentCanHide = shouldHideOnOusideClick ? shouldHideOnOusideClick(e) : true;
    return (
      parentCanHide && e.target !== containerRef.current && !containerRef.current.contains(e.target)
    );
  };

  return (
    <Container ref={containerRef}>
      <Tooltip title={`Click here to ${isFollowing ? 'unfollow' : 'follow'}`}>
        <ToggleButton isFollowing={isFollowing} onClick={() => onFollowToggle(!isFollowing)} />
      </Tooltip>

      <DropdownTooltip
        open={isFollowersListVisible}
        onClose={() => setIsFollowersListVisible(false)}
        shouldCloseOnOutsideClick={canHideOnOutsideClick}
        title={
          <FollowersListContainer>
            <FollowersListTitle>Followers</FollowersListTitle>
            {followers ? (
              <FollowersListContentContainer>
                {renderAddFollowerForm && renderAddFollowerForm()}
                <FollowersList>
                  {followers.map((follower, i) => (
                    <FollowersListItem
                      key={i}
                      onRemove={onFollowerRemove ? () => onFollowerRemove(follower) : undefined}
                      children={follower.name}
                    />
                  ))}
                </FollowersList>
              </FollowersListContentContainer>
            ) : (
              <FollowersListLoading />
            )}
          </FollowersListContainer>
        }
      >
        <ToggleFollowersListButton
          totalFollowers={totalFollowers}
          onClick={handleFollowersListToggle}
        />
      </DropdownTooltip>
    </Container>
  );
};

FollowButton.propTypes = {
  isFollowing: PropTypes.bool.isRequired,
  followers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  totalFollowers: PropTypes.number.isRequired,
  onFollowToggle: PropTypes.func.isRequired,
  onShowFollowersToggle: PropTypes.func.isRequired,
  onFollowerRemove: PropTypes.func,
  renderAddFollowerForm: PropTypes.func,
  shouldHideOnOusideClick: PropTypes.func
};

export default FollowButton;

import React, { useEffect, Fragment } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Typography, Box } from '@material-ui/core';

import DetailsSection from '../../../shared/components/common/DetailsSection';
import useQuickViewStyles from '../../../shared/styles/common/useQuickViewStyles';
import SolicitationCommentThread from './SolicitationCommentThread';

const SolicitationQuickView = props => {
  const {
    solicitationId,
    solicitation: { solicitation },
    relay: { refetch },
    refetchNotifications
  } = props;
  const classes = useQuickViewStyles();

  useEffect(() => {
    refetch({ id: solicitationId });
  }, [solicitationId]);

  if (solicitation) {
    const { subject, message } = solicitation;
    return (
      <Fragment>
        <Box className={classes.quickViewHeader}>
          <Typography variant="h1">{subject}</Typography>
        </Box>
        <DetailsSection title="JobOpp Inquiry Message">
          <Typography variant="body1">{message}</Typography>
        </DetailsSection>
        <SolicitationCommentThread
          solicitationId={solicitationId}
          solicitation={solicitation}
          refetchNotifications={refetchNotifications}
        />
      </Fragment>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default createRefetchContainer(
  SolicitationQuickView,
  {
    solicitation: graphql`
      fragment SolicitationQuickView_solicitation on Query
        @argumentDefinitions(id: { type: "ID!", defaultValue: "" }) {
        solicitation(id: $id) {
          subject
          message
          ...SolicitationCommentThread_solicitation @arguments(id: $id)
        }
      }
    `
  },
  graphql`
    query SolicitationQuickViewRefetchQuery($id: ID!) {
      ...SolicitationQuickView_solicitation @arguments(id: $id)
    }
  `
);

import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Quill from 'quill/core';
import { Toolbar, TextField } from './components';
import QuillContext from './QuillContext';
import './blots';

import { useTheme } from '@material-ui/styles';

// NOTE:
// Value is not 100% controllable, it acts as initialValue + reset on empty
const Editor = ({ value, onChange, onReferenceFileSelect }) => {
  const textFieldRef = useRef();
  const quillRef = useRef();
  const isMounted = useRef(true);
  // eslint-disable-next-line  no-unused-vars
  const [_renderKey, setRenderKey] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const quill = quillRef.current;
    if (!value && quill && quill.getText().trim().length > 0) {
      quill.setText('');
    }
  }, [value]);

  useEffect(() => {
    // NOTE:
    // Hackish way to pass theme to blot render
    window.__awebaseReactQuillDataBridge = { theme };
    const quill = new Quill(textFieldRef.current);

    if (value) {
      if (typeof value === 'string') {
        quill.setText(value);
      } else {
        quill.setContents(value);
      }
    }

    quill.on('text-change', () => {
      const content = quill.getContents();
      onChange(content);
    });
    quill.on('editor-change', () => {
      handleRenderKeyUpdate();
    });

    quillRef.current = quill;

    return () => (isMounted.current = false);
  }, []);

  // NOTE:
  // Call this manually on .format(type, value) as format change on
  // empty selection won't trigger change events.
  //
  // Increse performance by debouncing toolbar re-renders when user types
  const handleRenderKeyUpdate = useCallback(
    debounce(() => {
      if (isMounted.current) setRenderKey(i => i + 1);
    }, 100),
    []
  );

  const contextValue = useMemo(
    () => ({
      quillRef,
      updateRenderKey: handleRenderKeyUpdate,
      onReferenceFileSelect
    }),
    []
  );

  return (
    <QuillContext.Provider value={contextValue}>
      <Toolbar />
      <TextField ref={textFieldRef} />
    </QuillContext.Provider>
  );
};

Editor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onReferenceFileSelect: PropTypes.func.isRequired
};

export default Editor;

/**
 * @flow
 * @relayHash 356dcaa0cc2647bc62d4b0099781ba8c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobDeliverablesTab_job$ref = any;
export type JobDeliverablesTabRefetchQueryVariables = {|
  id: string,
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
|};
export type JobDeliverablesTabRefetchQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: JobDeliverablesTab_job$ref
  |}
|};
export type JobDeliverablesTabRefetchQuery = {|
  variables: JobDeliverablesTabRefetchQueryVariables,
  response: JobDeliverablesTabRefetchQueryResponse,
|};
*/


/*
query JobDeliverablesTabRefetchQuery(
  $id: ID!
  $first: Int
  $after: String
  $orderBy: String
) {
  job(id: $id) {
    ...JobDeliverablesTab_job_2IJuIk
    id
  }
}

fragment JobDeliverablesTab_job_2IJuIk on JobNode {
  id
  deliverables(first: $first, after: $after, orderBy: $orderBy) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        title
        state
        categoryType {
          name
          id
        }
        reviewStep
        assignedStaff {
          representativeImageUrl
          fullName
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JobDeliverablesTabRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "JobDeliverablesTab_job",
            "args": (v2/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "JobDeliverablesTabRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "DeliverableNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "categoryType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryTypeNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reviewStep",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignedStaff",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "representativeImageUrl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "JobDeliverablesTabRefetchQuery",
    "id": null,
    "text": "query JobDeliverablesTabRefetchQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n  $orderBy: String\n) {\n  job(id: $id) {\n    ...JobDeliverablesTab_job_2IJuIk\n    id\n  }\n}\n\nfragment JobDeliverablesTab_job_2IJuIk on JobNode {\n  id\n  deliverables(first: $first, after: $after, orderBy: $orderBy) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        title\n        state\n        categoryType {\n          name\n          id\n        }\n        reviewStep\n        assignedStaff {\n          representativeImageUrl\n          fullName\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab070001f27044d0a379d4529a6092a5';
module.exports = node;

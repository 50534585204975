/**
 * @flow
 * @relayHash 2fe50dcff152d96852e6c025bd0b2be6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CountrySelectQueryVariables = {||};
export type CountrySelectQueryResponse = {|
  +general: ?{|
    +countryList: ?$ReadOnlyArray<?{|
      +code: ?string,
      +name: ?string,
    |}>
  |}
|};
export type CountrySelectQuery = {|
  variables: CountrySelectQueryVariables,
  response: CountrySelectQueryResponse,
|};
*/


/*
query CountrySelectQuery {
  general {
    countryList {
      code
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "general",
    "storageKey": null,
    "args": null,
    "concreteType": "General",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "countryList",
        "storageKey": null,
        "args": null,
        "concreteType": "CountryNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CountrySelectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CountrySelectQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "CountrySelectQuery",
    "id": null,
    "text": "query CountrySelectQuery {\n  general {\n    countryList {\n      code\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ccd36f5e2033b15602b56bacd3d8f3c';
module.exports = node;

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import merge from 'lodash/merge';
import { Formik } from 'formik';
import GlobalButton from '../../../UI/GlobalButton';
import RichTextField from '../../../form/fields/RichTextField';
import SelectReferenceFileDialog from './SelectReferenceFileDialog';

const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  > *:last-child {
    margin-right: 0;
  }
`;

const CommentForm = ({
  deliverableId,
  comment,
  actionLabels: propActionLabels,
  onSubmit,
  onCancel
}) => {
  const actionLabels = merge(
    {
      cancel: 'Cancel',
      submit: 'Add Comment'
    },
    propActionLabels
  );
  const [isSelectDialogVisible, setIsSelectDialogVisible] = useState(false);
  const insertFileRef = useRef(null);

  // NOTE:
  // Formik isn't using <Form> because we don't want enter press to trigger submit
  // when form isn't focused
  const handleSubmit = (values, actions) => {
    if (comment && comment.content === values.content) {
      return onCancel();
    }

    const resetForm = () => {
      actions.resetForm({
        content: '',
        rawContent: '',
        referenceIds: null
      });
    };

    onSubmit(values, {
      ...actions,
      resetForm
    });
  };

  return (
    <>
      <SelectReferenceFileDialog
        isDialogOpen={isSelectDialogVisible}
        closeDialog={() => setIsSelectDialogVisible(false)}
        deliverableId={deliverableId}
        onSelect={({ id, fileUrl: src, title, reviewInterface }) => {
          setIsSelectDialogVisible(false);
          insertFileRef.current({
            id,
            src,
            title,
            reviewInterface
          });
        }}
      />
      <Formik
        initialValues={{
          content: comment ? comment.content : '',
          referenceIds: null,
          rawContent: ''
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isSubmitting, handleSubmit }) => (
          <>
            <RichTextField
              value={values.content}
              onChange={({ content, rawContent, referenceIds }) => {
                setFieldValue('content', content);
                setFieldValue('rawContent', rawContent);
                setFieldValue('referenceIds', referenceIds);
              }}
              onReferenceFileSelect={({ confirmSelect }) => {
                insertFileRef.current = confirmSelect;
                setIsSelectDialogVisible(true);
              }}
            />
            <Actions>
              {onCancel && (
                <GlobalButton
                  type="button"
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={onCancel}
                >
                  {actionLabels.cancel}
                </GlobalButton>
              )}
              <GlobalButton
                type="button"
                onClick={handleSubmit}
                disabled={!onSubmit || isSubmitting || !values.content}
              >
                {actionLabels.submit}
              </GlobalButton>
            </Actions>
          </>
        )}
      </Formik>
    </>
  );
};

export default CommentForm;

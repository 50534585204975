import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const StyledLabel = styled(Typography)(({ theme }) => ({
  padding: '6px 16px',
  fontSize: 12,
  color: theme.palette.colors.blueGrey
}));

const Menu = props => {
  const { isLoading, resultsCounts, itemsInList } = props;

  const showLabel = resultsCounts && !isLoading;

  const renderCountsLabel = () => {
    const { edgeCount, totalCount } = resultsCounts;
    const numberOfItemsInList = itemsInList.length;
    const numberOfVisibleOptions = edgeCount - numberOfItemsInList;
    const numberOfTotalOptions = totalCount - numberOfItemsInList;
    return (
      edgeCount !== totalCount && (
        <StyledLabel variant="body2">
          {`Showing ${numberOfVisibleOptions} out of ${numberOfTotalOptions} results`}
        </StyledLabel>
      )
    );
  };

  return (
    <Paper
      square
      id={`${props.selectProps.inputId}-dropdown`}
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {showLabel && renderCountsLabel()}
      {props.children}
    </Paper>
  );
};

Menu.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
  /* 
    For those dropdowns that add items to list stored in state.
    Reduces the numbers showed in the counts label by the number of 
    items added to the list. Temporal solution to avoid hacky triggering 
    of loading new options.
  */
  itemsInList: PropTypes.array
};

Menu.defaultProps = {
  itemsInList: []
};

export default Menu;

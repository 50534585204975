import React from 'react';

import StyledTableCell from './StyledTableCell';
import SquareButton from '../UI/SquareButton';
import { useTableStyles } from '../../styles/common/tableStyles';

const TableCellContract = props => {
  const { handleClick, contract } = props;
  const classes = useTableStyles();

  const squareButtonVariables = {
    icon: 'contract',
    variant: 'transparent',
    tooltipTitle: 'Review Contract'
  };

  return (
    <StyledTableCell noWrap>
      <div className={classes.verticalCenter}>
        { contract && <SquareButton {...squareButtonVariables} onClick={handleClick} /> }
      </div>
    </StyledTableCell>
  );
};

export default TableCellContract;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import ReviewAssets from './ReviewAssets';
import { initialOrderBy, initialVariables } from '../shared/initialVariables';

const StorageReviewAssetsTab = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query StorageReviewAssetsTabQuery(
          $first: Int
          $after: String
          $orderBy: String
          $storageClass: String
          $deliverableState: String
          $releaseId: String
          $deliverableTitle: String
          $fileName: String
        ) {
          organization {
            ...ReviewAssetsHeader_storageInfo
          }
          ...ReviewAssetsList_files
          storageClasses: __type(name: "StorageClassEnum") {
            ...ReviewAssetsList_storageClasses
          }
          deliverableStateTypes: __type(name: "DeliverableState") {
            ...ReviewAssetsList_deliverableStateTypes
          }
        }
      `}
      variables={variables}
      render={relayProps => <ReviewAssets relayProps={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(StorageReviewAssetsTab, 'orgStorageReviewAssets', {
  orderBy: initialOrderBy,
  initialFilters: initialVariables
});

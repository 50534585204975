import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeleteSolicitationsMutation($input: DeleteSolicitationBatchInput!) {
    deleteSolicitationBatch(input: $input) {
      isDeleted
    }
  }
`;

const DeleteSolicitationsMutation = (ids, callback) => {
  const deleteSolicitationVariables = {
    input: { ids }
  };

  const config = {
    mutation,
    variables: deleteSolicitationVariables,
    onCompleted: response => {
      callback(response.deleteSolicitationBatch);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default DeleteSolicitationsMutation;

DeleteSolicitationsMutation.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired
};

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const reopenDeliverableMutation = graphql`
  mutation ReopenDeliverableMutation($input: ReopenCompleteDeliverableInput!) {
    reopenCompleteDeliverable(input: $input) {
      updatedDeliverable {
        id
        state
        job {
          id
          status
          hasCompletedDeliverables
        }
      }
    }
  }
`;

export default (id, callback) => {
  const reopenDeliverableVariables = {
    input: { id }
  };

  const config = {
    mutation: reopenDeliverableMutation,
    variables: reopenDeliverableVariables,
    onCompleted: response => {
      callback(response.reopenDeliverable);
    }
  };

  commitMutation(getEnvironment(), config);
};

import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { ATTRS } from './CanvasTools/contants';
import { fabric } from 'fabric';
import { getObjectsByRef } from './CanvasTools/SessionManager';
import { toolCursor } from './CanvasTools';
import {
  getCanvasDataAsJson,
  reviveObjects,
  annotationResizeHandler,
  updateStrokeWhileScaling,
  setEditMode
} from './CanvasData';

const initializeCanvas = (elId, dimensions) => {
  var canvas = new fabric.Canvas(elId);
  canvas.perPixelTargetFind = true;
  canvas.selection = false;
  canvas.selectionLineWidth = 2;
  canvas.setDimensions(dimensions);
  canvas.setBackgroundColor('rgb(0, 73, 0)', canvas.renderAll.bind(canvas));
  return canvas;
};

const splitObjectsByAuthor = (canvas, currentUserId) => {
  return canvas.getObjects().reduce(
    ([byCurrentUser, byOthers], object) => {
      return object[ATTRS.authorId] === currentUserId
        ? [[...byCurrentUser, object], byOthers]
        : [byCurrentUser, [...byOthers, object]];
    },
    [[], []]
  );
};

const NON_SELECTED_OPACITY = 0.4;
/**
AWEbaseCanvas is the AWEbase component that handles the visual annotations for the Image and Video Review Interface.
The component accepts a tool that allows to perform operations on the canvas

 */
const AWEbaseCanvas = forwardRef((props, ref) => {
  // TODO Add user id support and edit mode
  const {
    userId,
    dimensions,
    annotationData,
    tool,
    backgroundColor,
    color,
    sessionId,
    annotationRef,
    frameRef,
    hideMarkers,
    editing,
    onChangeAnnotation,
    onCloseEditMode,
    onActiveAnnotationColor
  } = props;

  useImperativeHandle(ref, () => ({
    getCanvas() {
      return canvasRef.current;
    },
    cancel(sessionId) {
      const listToRemove = getObjectsByRef(canvasRef.current, sessionId);
      canvasRef.current.remove(...listToRemove);
    },
    cleanupSession() {
      disableEditableObjects();
    },
    startEdit() {
      enterEditMode();
    },
    saveEdit() {
      closeEditMode();
    },
    containsAnnotations(sessionId) {
      const objects = getObjectsByRef(canvasRef.current, sessionId);
      return objects && objects.length > 0 ? true : false;
    },
    cancelEdit() {}
  }));

  const canvasRef = useRef(null);

  useEffect(() => {
    canvasRef.current = initializeCanvas('image-annotation', dimensions);
    canvasRef.current.loadFromJSON(
      { objects: annotationData },
      () => {
        canvasRef.current.isDrawingMode = false;
        setCanvasTool();
        /**
         * Loading data from JSON overwrites all current canvas data,
         * even the background object so it needs to be re-set.
         */

        if (frameRef) {
          displayFrameOnly(frameRef);
        }

        if (hideMarkers) {
          hideAll();
        }

        canvasRef.current.renderAll();

        /**
         * In VideoAnnotation highlightedAnnotationRef is set before ImageAnnoation
         * is mounted, so when the annotation data is loaded need to highlight the
         * object manually
         */

        // if (this.props.highlightedAnnotationRef) {
        //   this.highlightAnnotation(this.props.highlightedAnnotationRef);
        // }
      },
      /**
       * This function is called on every object received from json. Is used to re-set
       * objects positions based on relative values calculated on save.
       */
      (json, object) => {
        reviveObjects(json, object, dimensions);
        canvasRef.current.requestRenderAll();
      }
    );
  }, [dimensions]);

  const displayFrameOnly = frameRef => {
    canvasRef.current.getObjects().forEach(obj => {
      if (obj['frameRef'] !== frameRef) {
        obj.set({ visible: false });
      } else {
        obj.set({ visible: true });
      }
    });
  };

  const hideAll = () => {
    if (hideMarkers) {
      displayFrameOnly(null);
    } else {
      displayFrameOnly(frameRef);
    }
    canvasRef.current.renderAll();
  };

  const changeBackgroundColor = () => {
    canvasRef.current.setBackgroundColor(backgroundColor);
    canvasRef.current.renderAll();
  };

  const redrawAnnotations = () => {
    // we do not want to redraw the canvas with data coming from the server
    // when the user is making changes
    if (sessionId) return;
    if (editing) return;
    canvasRef.current.remove(...canvasRef.current.getObjects());
    canvasRef.current.loadFromJSON(
      { objects: annotationData },
      () => {
        canvasRef.current.setBackgroundColor(backgroundColor);
        selectAnnotations(annotationRef);
        setCanvasTool();
        canvasRef.current.renderAll();
      },
      (json, object) => {
        reviveObjects(json, object, dimensions);
      }
    );
  };

  const resetSelection = () => {
    selectAnnotations(null);
    canvasRef.current.renderAll();
  };

  const selectAnnotations = annotationRef => {
    // we are in an annotation creation session and we deselect all the annotations
    // that are not matching the sessionId
    if (sessionId) {
      // remove all the annotations that do not match the session id.W
      canvasRef.current.getObjects().forEach(obj => {
        obj.set({ opacity: NON_SELECTED_OPACITY });
      });
      const newObjects = getObjectsByRef(canvasRef.current, sessionId);
      newObjects.forEach(obj => {
        obj.set({ opacity: 1 });
      });
    } else {
      const objects = getObjectsByRef(canvasRef.current, annotationRef);
      if (annotationRef === null) {
        objects.forEach(obj => {
          obj.set({ opacity: 1 });
        });
      }

      const opacity = annotationRef === null ? 1 : NON_SELECTED_OPACITY;
      canvasRef.current.getObjects().forEach(obj => {
        obj.set({ opacity: opacity });
      });
      objects.forEach(obj => {
        obj.set({ opacity: 1 });
      });
    }
  };

  const onSelectAnnotationHandler = ev => {
    // 1. inform the comment interface of the annotation that has been selected
    // 2. select the annotation that has been selected
    if (ev.target) {
      selectAnnotations(ev.target[ATTRS.ref]);
      onChangeAnnotation(ev.target[ATTRS.ref]);
    } else {
      resetSelection();
      onChangeAnnotation(null);
    }
    // if the session id is not null we need to only allow select annotations
    // that are part of the current creation session.
  };

  const setCanvasTool = () => {
    if (sessionId && tool) {
      tool.func(
        canvasRef.current,
        {
          userId: userId,
          annotationColor: color,
          annotationRef: sessionId,
          frameRef: frameRef,
          sessionId,
          dimensions,
          onActiveAnnotationColor
        },
        { onSelectAnnotationHandler }
      );
    } else {
      toolCursor(
        canvasRef.current,
        {
          userId: userId,
          annotationColor: color,
          annotationRef: sessionId,
          frameRef: frameRef,
          sessionId,
          dimensions,
          onActiveAnnotationColor
        },
        { onSelectAnnotationHandler }
      );
    }
  };

  const enterEditMode = () => {
    // we only enter edit mode if we are not creating an annotation
    // check the sessionId if the user is creating an annotation
    if (sessionId) return;
    // deselect all the comments we should inform the parent component
    // that we cleared the selection.
    resetSelection();
    canvasRef.current.isDrawingMode = false;
    canvasRef.current.off();
    const [usersObjects, othersObjects] = splitObjectsByAuthor(canvasRef.current, userId);
    usersObjects.forEach(object => {
      setEditMode(object);
    });
    othersObjects.forEach(object => object.set({ opacity: 0.3, hoverCursor: 'default' }));
    canvasRef.current.renderAll();

    canvasRef.current.on('object:scaling', updateStrokeWhileScaling);
    canvasRef.current.on('object:scaled', annotationResizeHandler);
  };

  const disableEditableObjects = () => {
    canvasRef.current.getObjects().forEach(object =>
      object.set({
        selectable: false
      })
    );
  };

  const closeEditMode = () => {
    canvasRef.current.getObjects().forEach(object =>
      object.set({
        selectable: false,
        opacity: 1
      })
    );
    setCanvasTool();
    canvasRef.current.renderAll();

    const jsonCanvasData = getCanvasDataAsJson(canvasRef.current, dimensions);

    const onSuccessCallback = () => {
      canvasRef.current.off('object:scaled', annotationResizeHandler);
      canvasRef.current.off('object:scaling', updateStrokeWhileScaling);
    };
    onCloseEditMode(jsonCanvasData, onSuccessCallback);
  };

  /**
   * This effect is invoked whenever the annotationData passed to the canvas changes.
   */
  useEffect(redrawAnnotations, [annotationData]);
  useEffect(changeBackgroundColor, [backgroundColor]);
  useEffect(setCanvasTool, [tool, color]);
  useEffect(hideAll, [hideMarkers]);

  useEffect(() => {
    setCanvasTool();
    resetSelection();
    displayFrameOnly(frameRef);
    canvasRef.current.renderAll();
  }, [frameRef]);

  useEffect(() => {
    selectAnnotations(annotationRef);
    canvasRef.current.renderAll();
  }, [annotationRef]);

  const styles = {
    position: 'absolute',
    marginRight: 'auto',
    marginLeft: 'auto'
  };

  return (
    <div style={styles}>
      <canvas id="image-annotation" />
    </div>
  );
});

AWEbaseCanvas.propTypes = {
  /** Dimensions of the canvas */
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }).isRequired,
  /** annotation fabric object to be loaded into the canvas */
  annotationData: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Tool function that is used for annotations */
  tool: PropTypes.shape({
    name: PropTypes.string,
    func: PropTypes.func,
    cursor: PropTypes.string,
    icon: PropTypes.string
  }),
  /** background color for the canvas */
  backgroundColor: PropTypes.string,
  /** Annotation color */
  color: PropTypes.string,
  /** Annotation Reference used when creating new objects
   *  all the new objects will be tagged with this annotation reference
   *  the cursorTool uses the annotation reference to select objects.
   */
  annotationRef: PropTypes.string,
  /** frameRef used when creating new objects the canvas will displayed only
   *  the object tags with the annotation reference.
   */
  frameRef: PropTypes.string,
  editing: PropTypes.bool,
  onCloseEditMode: PropTypes.func
};
export default AWEbaseCanvas;

import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import Control from './fieldElements/reactSelectComponents/Control';
import Option from './fieldElements/reactSelectComponents/Option';
import MultiValue from './fieldElements/reactSelectComponents/MultiValue';
import ValueContainer from './fieldElements/reactSelectComponents/ValueContainer';
import Menu from './fieldElements/reactSelectComponents/Menu';
import ClearIndicator from './fieldElements/reactSelectComponents/ClearIndicator';
import { useSearchableSelectStyles, getSelectStyles } from './SearchableFormSelect';
import { useTheme } from '@material-ui/core/styles';

const Select = props => {
  const {
    onChange,
    label,
    name,
    helperText,
    notFullWidth,
    disabled,
    noClear,
    maxMenuHeight,
    required,
    ...otherProps
  } = props;

  const classes = useSearchableSelectStyles({ notFullWidth });
  const theme = useTheme();

  const onChangeHandler = value => {
    onChange(name, value);
  };

  return (
    <ReactSelect
      onChange={onChangeHandler}
      inputId={name}
      classes={classes}
      styles={getSelectStyles(theme, props)}
      isClearable={true}
      isDisabled={disabled}
      maxMenuHeight={maxMenuHeight}
      TextFieldProps={{
        label,
        InputLabelProps: {
          shrink: true,
          htmlFor: name
        },
        error: typeof helperText === 'string',
        helperText,
        fullWidth: false,
        required
      }}
      components={{
        Control,
        Menu,
        Option,
        MultiValue,
        ValueContainer,
        ClearIndicator: noClear ? () => null : ClearIndicator
      }}
      menuPortalTarget={document.body}
      menuPortal={base => ({ ...base, zIndex: 9999 })}
      {...otherProps}
    />
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  noClear: PropTypes.bool.isRequired
};

Select.defaultProps = {
  disabled: false,
  noClear: false,
  maxMenuHeight: 300
};

export default Select;

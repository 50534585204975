import { makeStyles } from '@material-ui/styles';
import { drawerClosedWidth, drawerWidth, appBarHeight } from './base';

export default makeStyles(theme => ({
  globalCreateDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1200px'
    },
    '& .MuiDialog-paper': {
      maxHeight: '85%',
      minHeight: '30%',
      marginTop: `${appBarHeight}px`,
      marginRight: 'auto',
      marginBottom: 'auto',
      marginLeft: props =>
        `${(props.isOpen ? drawerWidth : drawerClosedWidth) + theme.spacing(3)}px`
    }
  },
  defaultWidth: {
    '& .MuiDialog-paper': {
      width: '60%',
      maxWidth: '685px',
      minWidth: '430px'
    }
  },
  contractor: {
    '& .MuiDialog-paper': {
      width: '685px',
      height: '390px'
    }
  },

  release: {
    '& .MuiDialog-paper': {
      width: '685px',
      height: '605px'
    }
  },
  product: {
    '& .MuiDialog-paper': {
      width: '685px',
      height: '585px'
    }
  },
  deliverable: {
    '& .MuiDialog-paper': {
      width: '685px',
      height: '870px'
    }
  },
  job: {
    '& .MuiDialog-paper': {
      width: '1200px'
    }
  },
  solicitation: {
    '& .MuiDialog-paper': {
      width: '774px',
      height: '710px'
    }
  }
}));

import React, { Fragment, useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { CELL_TYPES } from '../../../../shared/constants';
import useDialog from '../../../../shared/hooks/useDialog';
import withDialogProps from '../../../../shared/hoc/withDialogProps';
import AutoTable from '../../../../shared/components/table/AutoTable';
import Dialog from '../../../../shared/components/common/Dialog';
import HandleCategoryForm from './HandleCategoryForm';
import { errorToast } from '../../../../shared/toasts';

const ACTION_BUTTON_OPTIONS = {
  delete: { name: 'delete', label: 'Delete', modalTitle: 'Deleting Categories' }
};

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: '30px'
  }
});

const CategoriesList = props => {
  const classes = useStyles();
  const {
    relay: { refetch },
    categories: { categoryByOrg }
  } = props;

  const initialActionsData = { action: '', selectedCategories: [] };
  const [resetCursorAndRefetchCounter, setResetCursorAndRefetchCounter] = useState(0);
  const [actionsData, setActionsData] = useState(initialActionsData);
  const [isDialogOpen, setDialogOpen] = useDialog();

  if (!categoryByOrg) return <div>Something went wrong!</div>;

  const {
    pageInfo: { hasNextPage, endCursor },
    edges,
    totalCount,
    edgeCount
  } = categoryByOrg;

  const toggleDialogOpen = () => {
    setDialogOpen(!isDialogOpen);
  };

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    const { history, match } = props;
    history.push(`${match.url}/${id}`);
  };

  const onDuplicateClickHandler = id => {
    const currentCategories = getCurrentSelectedCategories([id]);
    if (currentCategories.length) {
      setActionsData({ action: 'duplicate', selectedCategories: currentCategories });
      toggleDialogOpen();
    }
  };

  const onDeleteClickHandler = id => {
    const currentCategories = getCurrentSelectedCategories([id]);
    if (currentCategories.length && currentCategories[0].node.numberOfDeliverables === 0) {
      setActionsData({ action: 'delete', selectedCategories: currentCategories });
      toggleDialogOpen();
    } else if (currentCategories.length) {
      errorToast(
        `You cannot delete this category because it is assigned to ${currentCategories[0].node.numberOfDeliverables} deliverable(s).`
      );
    }
  };

  const getCurrentSelectedCategories = ids => {
    return edges.filter(edge => ids.includes(edge.node.id));
  };

  const closeEditingHandler = () => {
    setActionsData(initialActionsData);
    toggleDialogOpen();
  };

  const actionClickHandler = (action, checked) => {
    setActionsData({ action, selectedCategories: getCurrentSelectedCategories(checked) });
    toggleDialogOpen();
  };

  const handleCloseWithRefetch = () => {
    closeEditingHandler();
    setResetCursorAndRefetchCounter(resetCursorAndRefetchCounter + 1);
  };

  const getDialogTitle = () => {
    // handling dialog title for action button Delete, or table cell Delete or Duplicate click
    if (actionsData.action) {
      if (actionsData.action === 'duplicate') {
        return 'Create New Category';
      }
      if (actionsData.action === 'delete') {
        if (actionsData.selectedCategories.length > 1) {
          return 'Delete Categories';
        }
        return 'Delete Category';
      }
    }
    return '';
  };

  const disabledActionsCheckHandler = checked => {
    const disabledItems = [];
    const areAllWithoutDeliverables = checked.every(edge => !edge.node.numberOfDeliverables);

    if (!areAllWithoutDeliverables) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.delete.name);
    }

    return disabledItems;
  };

  const flattenedEdges = edges.map(edge => {
    const { id, name, numberOfDeliverables } = edge.node;

    return {
      node: {
        id,
        name,
        numberOfDeliverables,
        actions: {
          allowDuplicate: true,
          allowDelete: true,
          duplicateTooltip: 'Duplicate category to create a custom one',
          trashTooltip: 'Delete category'
        }
      }
    };
  });

  return (
    <Fragment>
      <Typography variant="h3" className={classes.marginBottom}>
        Categories
      </Typography>
      <AutoTable
        initialOrderBy="name"
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
          { name: 'name', label: 'Category Name', type: CELL_TYPES.link, sortable: true },
          { name: 'numberOfDeliverables', label: 'Deliverables', align: 'right' },
          { name: 'actions', label: '', type: CELL_TYPES.actions }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        resetCursorAndRefetchCounter={resetCursorAndRefetchCounter}
        labelledByHeader="select all categories"
        rowProps={{
          handleLinkClick: onLinkClickHandler,
          handleActionDuplicateClick: onDuplicateClickHandler,
          handleActionTrashClick: onDeleteClickHandler
        }}
        actionButtonProps={{
          options: Object.values(ACTION_BUTTON_OPTIONS),
          handleMenuItemClick: actionClickHandler,
          getDisabledActions: disabledActionsCheckHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
      <Dialog title={getDialogTitle()} isDialogOpen={isDialogOpen} closeDialog={toggleDialogOpen}>
        {actionsData.selectedCategories.length && (
          <HandleCategoryForm
            handleClose={toggleDialogOpen}
            handleCloseWithRefetch={handleCloseWithRefetch}
            action={actionsData.action}
            selectedCategories={actionsData.selectedCategories}
          />
        )}
      </Dialog>
    </Fragment>
  );
};

export default withRouter(
  withDialogProps(
    createRefetchContainer(
      CategoriesList,
      {
        categories: graphql`
          fragment CategoriesList_categories on Query
          @argumentDefinitions(orderBy: { type: "String", defaultValue: "name" }) {
            categoryByOrg(first: $first, orderBy: $orderBy, after: $after) {
              pageInfo {
                hasNextPage
                endCursor
              }
              totalCount
              edgeCount
              edges {
                node {
                  id
                  name
                  numberOfDeliverables
                }
              }
            }
          }
        `
      },
      graphql`
        query CategoriesListRefetchQuery($first: Int, $orderBy: String, $after: String) {
          ...CategoriesList_categories @arguments(orderBy: $orderBy)
        }
      `
    )
  )
);

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { resolveJobLink } from '../../../utils/linkResolvers';
import useInterval from '../../../hooks/useInterval';
import getFetchNotifications from '../../../mutations/notifications/fetchNotifications';
import MessageIcon from '../../UI/Message';
import NewMessageBadge from '../../UI/NewMessageBadge';

const JobCommentsBadge = ({ deliverable }) => {
  const [notifications, setNotifications] = useState(null);
  const refetchNotification = getFetchNotifications('job', setNotifications, {
    targetId: deliverable.job.id
  });

  useInterval(refetchNotification, 60000);

  const icon = <MessageIcon size={16} cursor={false} />;

  return (
    <Link to={resolveJobLink(deliverable.job.id)}>
      {notifications && notifications.edges.length > 0 ? (
        <NewMessageBadge
          variant="dot"
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          type="smallMessage"
          children={icon}
        />
      ) : (
        icon
      )}
    </Link>
  );
};

export default JobCommentsBadge;

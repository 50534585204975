/**
 * @flow
 * @relayHash 5abcd905ea9b5c974ff910d3802dc7cc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationDetails_solicitation$ref = any;
type SolicitationDetails_stateTypes$ref = any;
export type SolicitationDetailsPageQueryVariables = {|
  id: string
|};
export type SolicitationDetailsPageQueryResponse = {|
  +solicitation: ?{|
    +$fragmentRefs: SolicitationDetails_solicitation$ref
  |},
  +__type: ?{|
    +$fragmentRefs: SolicitationDetails_stateTypes$ref
  |},
|};
export type SolicitationDetailsPageQuery = {|
  variables: SolicitationDetailsPageQueryVariables,
  response: SolicitationDetailsPageQueryResponse,
|};
*/


/*
query SolicitationDetailsPageQuery(
  $id: ID!
) {
  solicitation(id: $id) {
    ...SolicitationDetails_solicitation
    id
  }
  __type(name: "SolicitationState") {
    ...SolicitationDetails_stateTypes
  }
}

fragment SolicitationDetails_solicitation on SolicitationNode {
  id
  subject
  state
  numberOfDeliverables
  numberOfContractors
  replyByDate
  message
  numberOfAllocatedDeliverables
  numberOfAllocatedContractedDeliverables
  createdBy {
    user {
      id
    }
    id
  }
  staffCoordinator {
    id
    fullName
    user {
      id
    }
  }
}

fragment SolicitationDetails_stateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "SolicitationState"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SolicitationDetails_solicitation",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"SolicitationState\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SolicitationDetails_stateTypes",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subject",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfContractors",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "replyByDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfAllocatedDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfAllocatedContractedDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "createdBy",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffCoordinator",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              (v4/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"SolicitationState\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationDetailsPageQuery",
    "id": null,
    "text": "query SolicitationDetailsPageQuery(\n  $id: ID!\n) {\n  solicitation(id: $id) {\n    ...SolicitationDetails_solicitation\n    id\n  }\n  __type(name: \"SolicitationState\") {\n    ...SolicitationDetails_stateTypes\n  }\n}\n\nfragment SolicitationDetails_solicitation on SolicitationNode {\n  id\n  subject\n  state\n  numberOfDeliverables\n  numberOfContractors\n  replyByDate\n  message\n  numberOfAllocatedDeliverables\n  numberOfAllocatedContractedDeliverables\n  createdBy {\n    user {\n      id\n    }\n    id\n  }\n  staffCoordinator {\n    id\n    fullName\n    user {\n      id\n    }\n  }\n}\n\nfragment SolicitationDetails_stateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2c174ee73adba31542772de335c2393c';
module.exports = node;

import React, { Fragment, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { withRouter } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';
import { Typography, Grid } from '@material-ui/core';
import get from 'lodash/get';

import ProductReleaseBreadcrumb from '../../../../shared/components/UI/ProductReleaseBreadcrumb';
import AvatarWithName from '../../../../shared/components/UI/AvatarWithName';
import ReviewAssetCard from '../../../../shared/components/review/ReviewAssetCard';
import { formatAmount } from '../../../../shared/utils/formatters';
import { getEnvironment } from '../../../../shared/utils/helpers';
import {
  resolveProductLink,
  resolveReleaseLink,
  resolveDeliverableReviewMarkupLink,
  resolveJobLink
} from '../../../../shared/utils/linkResolvers';
import TagsGrid from '../../../../shared/components/UI/TagsGrid';
import { useEffectWithStatus } from '../../../../shared/hooks/useEffectWithStatus';
import HeaderSubtitle from '../../../../shared/components/UI/HeaderSubtitle';
import { HeaderContainer, DetailsGroup, Detail } from './styled';

const deliverableQuickViewQuery = graphql`
  query DeliverablesQuickViewQuery($id: ID!) {
    deliverable(id: $id) {
      release {
        id
        name
        product {
          id
          title
        }
      }
      title
      description
      dueDate
      category {
        name
      }
      categoryType {
        name
      }
      amount
      currencyCode
      tags {
        edges {
          node {
            id
            name
          }
        }
      }
      assignedStaff {
        fullName
        representativeImageUrl
      }
      assignedContractor {
        id
        fullName
        representativeImageUrl
      }
      assignedInternally {
        id
        fullName
        representativeImageUrl
      }
      latestReviewAsset {
        id
        fileName
        fileThumbnailUrl
        reviewInterface
        revisionNumber
        storageClass
      }
      state
      job {
        id
        name
      }
    }
    deliverableStateTypes: __type(name: "DeliverableState") {
      enumValues {
        name
        description
      }
    }
  }
`;

const DeliverablesQuickView = props => {
  const { deliverableId, history } = props;
  const [deliverable, setDeliverable] = useState(null);

  useEffectWithStatus(
    status => {
      fetchQuery(getEnvironment(), deliverableQuickViewQuery, { id: deliverableId }).then(
        response => {
          status.mounted &&
            setDeliverable({
              ...response.deliverable,
              stateTypes: response.deliverableStateTypes
            });
        }
      );
    },
    [deliverableId]
  );

  const jobId = get(deliverable, 'job.id');
  const assignedTo =
    get(deliverable, 'assignedContractor') || get(deliverable, 'assignedInternally') || null;

  return deliverable ? (
    <Fragment>
      <HeaderContainer>
        <ProductReleaseBreadcrumb
          config={{
            product: deliverable.release.product.title,
            release: deliverable.release.name,
            productLink: resolveProductLink(deliverable.release.product.id),
            releaseLink: resolveReleaseLink(deliverable.release.id)
          }}
        />
        <Typography variant="h1">{deliverable.title}</Typography>
        <HeaderSubtitle
          date={deliverable.dueDate}
          state={deliverable.state}
          stateTypes={deliverable.stateTypes}
          variant="deliverable"
        />
      </HeaderContainer>

      <DetailsGroup>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Detail
              name="Most Recent Review Asset"
              value={
                deliverable.latestReviewAsset ? (
                  <ReviewAssetCard
                    {...deliverable.latestReviewAsset}
                    assetId={deliverable.latestReviewAsset.id}
                    cardType="preview"
                    handleItemClicked={() => {
                      history.push(
                        resolveDeliverableReviewMarkupLink(
                          deliverableId,
                          deliverable.latestReviewAsset.revisionNumber
                        )
                      );
                    }}
                  />
                ) : (
                  'No active review'
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Detail
              name="Coordinator"
              value={
                deliverable.assignedStaff ? (
                  <AvatarWithName
                    name={deliverable.assignedStaff.fullName}
                    avatarProps={{
                      src: deliverable.assignedStaff.representativeImageUrl
                    }}
                  />
                ) : undefined
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Detail
              name="Assignee"
              value={
                assignedTo ? (
                  <AvatarWithName
                    name={assignedTo.fullName}
                    avatarProps={{
                      src: assignedTo.representativeImageUrl,
                      border: deliverable.assignedContractor ? 'freelancer' : 'organization'
                    }}
                  />
                ) : undefined
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Detail name="Category" value={get(deliverable, 'category.name')} />
          </Grid>
          <Grid item xs={6}>
            <Detail name="Type" value={get(deliverable, 'categoryType.name')} />
          </Grid>
          <Grid item xs={6}>
            <Detail
              name="Deliverable Amount"
              value={formatAmount(deliverable.amount, deliverable.currencyCode)}
            />
          </Grid>
          <Grid item xs={6}>
            <Detail
              name="Job Name"
              value={get(deliverable, 'job.name')}
              link={jobId ? resolveJobLink(jobId) : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Detail
              name="Description"
              nameContainerProps={{ variant: 'h3' }}
              value={deliverable.description || 'No description added'}
            />
          </Grid>
        </Grid>
      </DetailsGroup>

      <DetailsGroup>
        <Detail
          name="Tags"
          renderValue={() => <TagsGrid tags={deliverable.tags.edges} withMessage />}
        />
      </DetailsGroup>
    </Fragment>
  ) : (
    <div>Loading</div>
  );
};

export default withRouter(DeliverablesQuickView);

import React from 'react';
import AnimateHeight from 'react-animate-height';

import { useFilterStyles } from '../../styles/common/filterStyles';

const FiltersContainer = props => {
  const { showFilters, width, children } = props;

  const filtersHeight = showFilters ? 'auto' : 0;
  const classes = useFilterStyles({ width });

  return (
    <AnimateHeight duration={300} height={filtersHeight}>
      <div className={classes.filterSection} style={{ margin: '1.5rem 0' }}>
        <div className={classes.filtersWidth}>{children}</div>
      </div>
    </AnimateHeight>
  );
};

export default FiltersContainer;

import React, { Fragment, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';

import AutoTable from '../../../../../shared/components/table/AutoTable';
import StyledTableCell from '../../../../../shared/components/table/StyledTableCell';
import TableCellClickable from '../../../../../shared/components/table/TableCellClickable';
import ReleaseDeliverablesListFilters from './ReleaseDeliverablesListFilters';
import useReleaseDeliverablesStyles from '../../../../../shared/styles/release/useReleaseDeliverablesStyles';
import { tableStyles } from '../../../../../shared/styles/common/tableStyles';
import { CELL_TYPES } from '../../../../../shared/constants';
import { getActiveDeliverableDueDateWarningColor } from '../../../../../shared/utils/helpers';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { resolveJobLink } from '../../../../../shared/utils/linkResolvers';
import ReleaseDeliverablesListHeader from './ReleaseDeliverablesListHeader';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import DeliverablesQuickView from '../../../deliverables/DeliverablesQuickView';

const ReleaseDeliverablesList = props => {
  const {
    relay: { refetch },
    release: { deliverables },
    history,
    stateTypes,
    filterProps,
    userContext: {
      orgStaff: {
        organization: { configuration }
      }
    }
  } = props;

  const [selectedId, setSelectedId] = useState(null);

  const classes = useReleaseDeliverablesStyles();

  if (!deliverables) return <div>Something went wrong!</div>;

  const {
    edges,
    totalCount,
    edgeCount,
    pageInfo: { hasNextPage, endCursor }
  } = deliverables;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    history.push(`/deliverables/${id}`);
  };

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      title,
      categoryType: categoryTypeObj,
      state,
      reviewStep,
      amount,
      currencyCode,
      dueDate,
      category: categoryObj,
      assignedStaff,
      assignedContractor,
      assignedInternally,
      jobId,
      job
    } = edge.node;
    const coordinator = assignedStaff
      ? {
          id: assignedStaff.user.id,
          name: assignedStaff.fullName,
          imageUrl: assignedStaff.representativeImageUrl
        }
      : null;

    const categoryType = categoryTypeObj ? categoryTypeObj.name : '-';

    const category = categoryObj ? categoryObj.name : '-';

    const assignedTo = assignedContractor || assignedInternally || null;

    const assignedToObj = assignedTo
      ? {
          id: assignedTo.id,
          name: assignedTo.fullName,
          imageUrl: assignedTo.representativeImageUrl,
          border: assignedContractor ? 'freelancer' : 'organization'
        }
      : null;

    return {
      node: {
        id,
        title,
        categoryType,
        state: { code: state, reviewStep },
        reviewStep,
        amount,
        currencyCode,
        dueDate: {
          date: dueDate,
          color: getActiveDeliverableDueDateWarningColor(configuration, dueDate, state)
        },
        coordinator,
        category,
        assignedTo: assignedToObj,
        jobId,
        job
      }
    };
  });

  return (
    <Fragment>
      <div className={classes.relative}>
        <ReleaseDeliverablesListHeader release={props.relayProps.release} />
        <AutoTable
          rowTemplate={[
            { name: '', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
            {
              name: 'title',
              label: 'Name',
              type: CELL_TYPES.link,
              sortable: true
            },
            { name: 'category', label: 'Category', sortable: true },
            {
              name: '',
              label: 'Job Name',
              renderCell: ({ index, rowIndex }) => {
                const { node } = flattenedEdges[rowIndex];

                return node.job ? (
                  <TableCellClickable
                    key={index}
                    handleClick={() => history.push(resolveJobLink(node.job.id))}
                  >
                    {node.job.name}
                  </TableCellClickable>
                ) : (
                  <StyledTableCell key={index} minWidth={true}>
                    -
                  </StyledTableCell>
                );
              }
            },
            {
              name: 'dueDate',
              label: 'Due Date',
              type: CELL_TYPES.dateWithWarning,
              sortable: true,
              width: 100
            },
            {
              name: 'state',
              label: 'State',
              type: CELL_TYPES.status,
              statusTypes: stateTypes.enumValues
            },
            {
              name: 'coordinator',
              label: 'Coordinator',
              type: CELL_TYPES.avatar,
              onlyTooltip: true,
              align: 'center'
            },
            {
              name: 'assignedTo',
              label: 'Assignee',
              type: CELL_TYPES.avatar,
              onlyTooltip: true,
              width: 83,
              align: 'center'
            },
            { name: 'amount', label: 'Amount', type: CELL_TYPES.amount }
          ]}
          onChangeHandler={onChangeHandler}
          edges={flattenedEdges}
          refetchCounter={props.refetchCounter}
          paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
          rowProps={{
            handleLinkClick: onLinkClickHandler
          }}
          filterProps={{
            filterComponent: (
              <ReleaseDeliverablesListFilters stateOptions={stateTypes.enumValues} />
            ),
            withApply: true,
            ...filterProps
          }}
        />
        <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
          <DeliverablesQuickView deliverableId={selectedId} configuration={configuration} />
        </QuickViewDrawer>
      </div>
    </Fragment>
  );
};
export default withStyles(tableStyles)(
  withUserContext(
    withRouter(
      createRefetchContainer(
        ReleaseDeliverablesList,
        {
          release: graphql`
            fragment ReleaseDeliverablesList_release on ReleaseNode {
              id
              deliverables(
                first: $first
                orderBy: $orderBy
                after: $after
                dueDateFrom: $dueDateFrom
                dueDateTo: $dueDateTo
                title: $title
                state: $state
                includeInactive: $includeInactive
                category: $category
                tags: $tags
                includeClosed: $includeClosed
                includeCanceled: $includeCanceled
                staffCoordinator: $staffCoordinator
                assignedToStaffOrContractor: $assignedToStaffOrContractor
              ) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
                totalCount
                edgeCount
                edges {
                  cursor
                  node {
                    id
                    jobId
                    job {
                      id
                      name
                    }
                    title
                    categoryType {
                      name
                    }
                    category {
                      name
                    }
                    state
                    reviewStep
                    amount
                    currencyCode
                    dueDate
                    assignedContractor {
                      id
                      representativeImageUrl
                      fullName
                    }
                    assignedInternally {
                      id
                      representativeImageUrl
                      fullName
                    }
                    assignedStaff {
                      user {
                        id
                      }
                      fullName
                      representativeImageUrl
                    }
                  }
                }
              }
            }
          `,
          stateTypes: graphql`
            fragment ReleaseDeliverablesList_stateTypes on __Type {
              enumValues {
                name
                description
              }
            }
          `
        },
        graphql`
          query ReleaseDeliverablesListRefetchQuery(
            $id: ID!
            $first: Int
            $after: String
            $orderBy: String
            $dueDateFrom: Date
            $dueDateTo: Date
            $title: String
            $includeInactive: Boolean
            $state: String
            $category: ID
            $tags: String
            $staffCoordinator: String
            $assignedToStaffOrContractor: String
            $includeClosed: Boolean
            $includeCanceled: Boolean
          ) {
            release(id: $id) {
              ...ReleaseDeliverablesList_release
            }
          }
        `
      )
    )
  )
);

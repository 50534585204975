import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation ActivateStaffUserMutation($input: ActivateStaffUserInput!) {
    activateStaffUser(input: $input) {
      updatedStaffUser {
        id
        user {
          isActive
        }
      }
    }
  }
`;

const ActivateStaffUserMutation = (id, prorationTimestamp, callback) => {
  const config = {
    mutation,
    variables: { input: { id, prorationTimestamp } },
    onCompleted: response => {
      callback(response.activateStaffUser);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default ActivateStaffUserMutation;

import styled from 'styled-components';
import BaseDetail from '../../../../shared/components/UI/Detail';

// NOTE:
// -32px is a hackish way to align header with cancel button
// this should be fixed when every QuickView will have same design
export const HeaderContainer = styled.div`
  margin-top: -32px;
  padding: 0 64px 10px 16px;

  > h1 {
    font-size: 24px;
    margin: 9px 0 12px 0;
  }
`;

export const DetailsGroup = styled.div`
  border-top: 1px solid ${props => props.theme.palette.colors.lightestGrey};
  padding: ${props => props.theme.spacing(3)}px ${props => props.theme.spacing(2)}px;
`;

export const Detail = styled(BaseDetail).attrs(() => ({
  flexDirection: 'column',
  alignItems: 'left',
  valueContainerProps: {
    variant: 'body1',
    component: 'div'
  }
}))``;

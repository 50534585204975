import React, { useMemo, useContext } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Grid, Divider, Box, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import {
  computePermissionRole,
  getCurrencyCodeFromUserContext
} from '../../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS, DELIVERABLE_STATES } from '../../../../../shared/constants';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import { getAssignedUserId } from '../../../../../shared/utils/helpers';
import UpdateDeliverableMutation from '../../mutations/UpdateDeliverableMutation';
import { formatDateForQueries } from '../../../../../shared/utils/formatters';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import TagsAutocompleteSection from '../../../../../shared/components/common/TagsAutocompleteSection';
import EditButton from '../../../../../shared/components/UI/EditButton';
import { yupSchemas } from '../../../../../shared/validations';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import Dialog, { DialogActions } from '../../../../../shared/components/common/Dialog';
import useDialog from '../../../../../shared/hooks/useDialog';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import { FormikCheckbox } from '../../../../../shared/components/form/FormikCheckbox';
import UpdateStaffProfileMutation from '../../../profile/mutations/UpdateStaffProfileMutation';
import { DeliverableDetailsToggleFollowButtonDataContext } from '../DeliverableDetailsToggleFollowButton';
import DetailsDescription from './DetailsDescription';
import DetailsNotes from './DetailsNotes';
import DetailsSolicitations from './DetailsSolicitations';
import DetailsMainSection from './DetailsMainSection';
import DetailsInfo from './DetailsInfo';

const DetailsContent = props => {
  const classes = useDetailsStyles();
  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const { deliverable, relayProps, isEditing, setEditing, userContext } = props;
  const {
    id: currentUserId,
    setUserInfo,
    orgStaff: { allowedActions, showFreelancerFieldsPopup }
  } = userContext;

  const { refetchFollowersData } = useContext(DeliverableDetailsToggleFollowButtonDataContext);
  const usersOrganizationCurrencyCode = getCurrencyCodeFromUserContext(userContext);

  const initialCurrencyCode = useMemo(() => {
    return deliverable.currencyCode !== usersOrganizationCurrencyCode
      ? deliverable.currencyCode
      : usersOrganizationCurrencyCode;
  }, [deliverable.currencyCode, usersOrganizationCurrencyCode]);

  const initialValues = {
    title: deliverable.title || '',
    description: deliverable.description || '',
    managerNote: deliverable.managerNote || '',
    internalId: deliverable.internalId || '',
    dueDate: deliverable.dueDate || null,
    tags: {
      options: deliverable.tags
        ? deliverable.tags.edges.map(edge => ({ value: edge.node.id, label: edge.node.name }))
        : []
    },
    assignedStaff: deliverable.assignedStaff
      ? { value: deliverable.assignedStaff.id, label: deliverable.assignedStaff.fullName }
      : null,
    assignedContractor:
      (deliverable.assignedContractor && deliverable.assignedContractor.fullName) ||
      (deliverable.assignedInternally && deliverable.assignedInternally.fullName) ||
      '',
    category: deliverable.category
      ? {
          value: deliverable.category.id,
          label: deliverable.category.name,
          types: deliverable.category.categoryTypes
        }
      : null,
    categoryType: deliverable.categoryType ? deliverable.categoryType.id : '',
    amount: {
      amount: deliverable.amount ? deliverable.amount : '',
      currencyCode: initialCurrencyCode
    },
    state: deliverable.state,
    dontShowAgain: false,
    release: { value: deliverable.release.id, label: deliverable.release.name }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().when('state', {
      is: val => val === DELIVERABLE_STATES.draft || val === DELIVERABLE_STATES.ready,
      then: yupSchemas.string
    }),
    internalId: Yup.string().max(100, 'Please enter no more than 100 characters.'),
    release: Yup.string().required('Required').nullable(),
    description: yupSchemas.string(false, { max: 5000 }),
    amount: Yup.object().shape({
      amount: Yup.number()
        .transform(cv => (isNaN(cv) ? undefined : cv))
        .min(0, 'Amount must be zero or more.')
    }),
    category: yupSchemas.string(true)
  });

  const submitHandler = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const {
      amount: { amount, currencyCode },
      title,
      release,
      category,
      categoryType,
      assignedStaff,
      dueDate,
      internalId,
      description,
      managerNote,
      tags
    } = values;

    const variables = {
      id: props.deliverable.id,
      title,
      internalId,
      release: release.value,
      category: category.value,
      categoryType,
      ...(assignedStaff && { assignedStaff: assignedStaff.value }),
      ...(dueDate && { dueDate: formatDateForQueries(dueDate) }),
      amount: amount || amount === 0 ? amount : null,
      currencyCode,
      description,
      managerNote,
      tags: tags.options.map(option => option.label)
    };

    UpdateDeliverableMutation(variables, (response, errors) => {
      setSubmitting(false);

      if (errors && errors[0].fields) {
        setErrors(errors[0].fields);
      }
      if (response && response.updatedDeliverable) {
        setEditing(false);
        refetchFollowersData();
      }
    });
  };

  const checkEditAllowed = () => {
    let button = <EditButton id="editButton" onClick={toggleEditHandler} />;

    if (
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER,
        allowedActions
      ) &&
      currentUserId === getAssignedUserId(deliverable.assignedStaff)
    ) {
      return button;
    } else if (
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER,
        allowedActions
      )
    ) {
      return button;
    }
  };
  const toggleEditHandler = () => {
    if (!isEditing && showFreelancerFieldsPopup) {
      toggleDialogOpen();
    }
    setEditing(!isEditing);
  };

  const submitDontShowAgain = value => {
    // no need to submit if box is not checked
    if (value) {
      UpdateStaffProfileMutation({ showFreelancerFieldsPopup: !value }, null, response => {
        if (response && response.updatedStaffProfile) {
          setUserInfo({
            ...props.userContext,
            orgStaff: {
              ...props.userContext.orgStaff,
              showFreelancerFieldsPopup:
                response.updatedStaffProfile.orgStaff.showFreelancerFieldsPopup
            }
          });
        }
      });
    }
    toggleDialogOpen();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid md={8} item>
              <DetailsInfo
                deliverable={relayProps.deliverable}
                deliverableStateTypes={relayProps.deliverableStateTypes}
                setFieldValue={setFieldValue}
                isEditing={isEditing}
              />
              <DetailsDescription
                deliverable={relayProps.deliverable}
                setFieldValue={setFieldValue}
                isEditing={isEditing}
              />
              <TagsAutocompleteSection
                values={values}
                setFieldValue={setFieldValue}
                isEditing={isEditing}
              />
              <DetailsNotes
                deliverable={relayProps.deliverable}
                setFieldValue={setFieldValue}
                isEditing={isEditing}
              />
              <DetailsSolicitations
                deliverable={relayProps.deliverable}
                solicitationStateTypes={relayProps.solicitationState}
              />
            </Grid>
            <Divider orientation="vertical" flexItem variant="middle" />
            <Grid xs="auto" item>
              <div className={classes.containerJustifyFlexEnd}>
                {deliverable.state !== DELIVERABLE_STATES.inactive && checkEditAllowed() && (
                  <EditToggle
                    isEditing={isEditing}
                    isSubmitting={isSubmitting}
                    editProps={{
                      id: 'editDeliverableDetails',
                      onClick: toggleEditHandler,
                      disabled: false
                    }}
                    saveProps={{
                      id: 'saveDeliverableDetails'
                    }}
                    cancelProps={{
                      id: 'cancelDeliverableDetails',
                      onClick: toggleEditHandler
                    }}
                  />
                )}
              </div>
              <DetailsMainSection
                values={values}
                deliverable={relayProps.deliverable}
                setFieldValue={setFieldValue}
                isEditing={isEditing}
              />
            </Grid>
          </Grid>
          <Dialog isDialogOpen={isDialogOpen} closeDialog={toggleDialogOpen} noButton>
            <Box>
              <Typography>Fields highlighted in purple will be shared with Contractors.</Typography>
              <Field
                component={FormikCheckbox}
                name="dontShowAgain"
                label="Don't show this again"
              />
              <DialogActions>
                <GlobalButton
                  id="dontShowAgainSubmit"
                  handleClick={() => submitDontShowAgain(values.dontShowAgain)}
                >
                  Ok
                </GlobalButton>
              </DialogActions>
            </Box>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(
  createFragmentContainer(DetailsContent, {
    deliverable: graphql`
      fragment DetailsContent_deliverable on DeliverableNode {
        id
        title
        description
        state
        internalId
        release {
          id
          name
        }
        category {
          id
          name
          categoryTypes {
            edges {
              node {
                id
                name
              }
            }
          }
        }
        categoryType {
          id
          name
        }
        amount
        currencyCode
        assignedStaff {
          id
          fullName
          user {
            id
          }
        }
        assignedContractor {
          fullName
        }
        assignedInternally {
          fullName
        }
        tags {
          edges {
            node {
              id
              name
            }
          }
        }
        dueDate
        managerNote
      }
    `
  })
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation UpdateRoleMutation($input: UpdateOrganizationRoleInput!) {
    updateOrganizationRole(input: $input) {
      updatedOrganizationRole {
        id
        name
        extraPermissions
      }
    }
  }
`;

const UpdateRoleMutation = (input, callback) => {
  const config = {
    mutation,
    variables: { input },
    onCompleted: (response, errors) => {
      callback(response.updateOrganizationRole, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default UpdateRoleMutation;

UpdateRoleMutation.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  extraPermissions: PropTypes.arrayOf(PropTypes.string)
};

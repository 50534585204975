/**
 * @flow
 * @relayHash 3f58f3b9f0979dc073f8a36ea04ee0f3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CategoriesList_categories$ref = any;
type TagsList_tags$ref = any;
export type CustomizationPageQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
  name?: ?string,
|};
export type CustomizationPageQueryResponse = {|
  +$fragmentRefs: CategoriesList_categories$ref & TagsList_tags$ref
|};
export type CustomizationPageQuery = {|
  variables: CustomizationPageQueryVariables,
  response: CustomizationPageQueryResponse,
|};
*/


/*
query CustomizationPageQuery(
  $first: Int
  $after: String
) {
  ...CategoriesList_categories
  ...TagsList_tags
}

fragment CategoriesList_categories on Query {
  categoryByOrg(first: $first, orderBy: "name", after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        name
        numberOfDeliverables
      }
    }
  }
}

fragment TagsList_tags on Query {
  tagByOrg(first: $first, orderBy: "name", after: $after, name: "") {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        name
        relatedInstancesNumber
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "name"
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "edgeCount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CustomizationPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "CategoriesList_categories",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "TagsList_tags",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CustomizationPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "categoryByOrg",
        "storageKey": null,
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "concreteType": "CategoryNodeConnection",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "numberOfDeliverables",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tagByOrg",
        "storageKey": null,
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "name",
            "value": ""
          },
          (v3/*: any*/)
        ],
        "concreteType": "TagNodeConnection",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "TagNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "TagNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "relatedInstancesNumber",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CustomizationPageQuery",
    "id": null,
    "text": "query CustomizationPageQuery(\n  $first: Int\n  $after: String\n) {\n  ...CategoriesList_categories\n  ...TagsList_tags\n}\n\nfragment CategoriesList_categories on Query {\n  categoryByOrg(first: $first, orderBy: \"name\", after: $after) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        name\n        numberOfDeliverables\n      }\n    }\n  }\n}\n\nfragment TagsList_tags on Query {\n  tagByOrg(first: $first, orderBy: \"name\", after: $after, name: \"\") {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        name\n        relatedInstancesNumber\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6e90a7c4a54f20698564c7cdc7bdabb';
module.exports = node;

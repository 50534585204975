import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import { withRouter } from 'react-router';

import AutoTable from '../../../../shared/components/table/AutoTable';
import { CELL_TYPES } from '../../../../shared/constants';

const JobDeliverablesTab = props => {
  const {
    job: {
      deliverables: {
        pageInfo: { hasNextPage, endCursor },
        totalCount,
        edgeCount,
        edges
      }
    },
    relay: { refetch },
    stateTypes: { enumValues: statusEnumValues },
    history
  } = props;

  const onLinkClickHandler = id => {
    history.push(`/deliverables/${id}`);
  };

  return (
    <AutoTable
      rowTemplate={[
        { name: 'title', label: 'Name', sortable: true, type: CELL_TYPES.link, width: '40%' },
        { name: 'categoryType', label: 'Type' },
        {
          name: 'state',
          label: 'State',
          type: CELL_TYPES.status,
          statusTypes: statusEnumValues
        },
        {
          name: 'reviewStep',
          label: 'Review Step'
        },
        { name: 'assignedStaff', label: 'Staff', type: CELL_TYPES.avatar }
      ]}
      edges={edges.map(({ node }) => {
        const assignedStaff = node.assignedStaff
          ? {
              name: node.assignedStaff.fullName,
              imageUrl: node.assignedStaff.representativeImageUrl
            }
          : null;
        return {
          node: {
            id: node.id,
            title: node.title,
            categoryType: node.categoryType ? node.categoryType.name : '-',
            state: { code: node.state },
            reviewStep: node.reviewStep,
            assignedStaff
          }
        };
      })}
      rowProps={{
        handleLinkClick: onLinkClickHandler
      }}
      onChangeHandler={refetch}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
    />
  );
};

export default withRouter(
  createRefetchContainer(
    JobDeliverablesTab,
    {
      job: graphql`
        fragment JobDeliverablesTab_job on JobNode
        @argumentDefinitions(
          first: { type: "Int" }
          orderBy: { type: "String", defaultValue: "title" }
          after: { type: "String" }
        ) {
          id
          deliverables(first: $first, after: $after, orderBy: $orderBy) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                title
                state
                categoryType {
                  name
                }
                reviewStep
                assignedStaff {
                  representativeImageUrl
                  fullName
                }
              }
            }
          }
        }
      `,
      stateTypes: graphql`
        fragment JobDeliverablesTab_stateTypes on __Type {
          enumValues {
            name
            description
          }
        }
      `
    },
    graphql`
      query JobDeliverablesTabRefetchQuery(
        $id: ID!
        $first: Int
        $after: String
        $orderBy: String
      ) {
        job(id: $id) {
          ...JobDeliverablesTab_job @arguments(first: $first, after: $after, orderBy: $orderBy)
        }
      }
    `
  )
);

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';

import AccountInformationContent from './AccountInformationContent';

const AccountInformationTab = props => {
  return (
    <QueryRenderer
      query={graphql`
        query AccountInformationTabQuery {
          ...AccountInformationContent_accountInformation
          general {
            ...AccountInformationContent_timezones
          }
        }
      `}
      variables={{}}
      render={relayProps => {
        return (
          <AccountInformationContent
            accountInformation={relayProps}
            timezones={relayProps.general}
            {...props}
          />
        );
      }}
    />
  );
};

export default AccountInformationTab;

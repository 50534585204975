import React, { Fragment, useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import AutoTable from '../../../shared/components/table/AutoTable';
import QuickViewDrawer from '../../../shared/components/common/QuickViewDrawer';
import SolicitationQuickView from './SolicitationQuickView';
import SolicitationsListFilters from './SolicitationsListFilters';

import { CELL_TYPES } from '../../../shared/constants';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import useInterval from '../../../shared/hooks/useInterval';
import getFetchNotifications from '../../../shared/mutations/notifications/fetchNotifications';
import { useEffectWithStatus } from '../../../shared/hooks/useEffectWithStatus';

const SolicitationsList = props => {
  const {
    relay: { refetch },
    solicitations: { allSolicitations },
    relayProps,
    stateTypes
  } = props;

  const [selectedId, setSelectedId] = useState(null);
  const [notifications, setNotifications] = useState(null);

  useInterval(() => refetchNotifications(), 60000);

  useEffectWithStatus(status => {
    refetchNotifications(status);
  }, []);

  if (!allSolicitations) return <div>Something went wrong!</div>;

  const {
    pageInfo: { hasNextPage, endCursor },
    edges,
    totalCount,
    edgeCount
  } = allSolicitations;

  const refetchNotifications = getFetchNotifications('solicitation', setNotifications);

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    const { history, match } = props;
    history.push(`${match.url}/${id}`);
  };

  const onMessageClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      solicitationId,
      staffCoordinator: {
        fullName,
        representativeImageUrl,
        organization: { name: organization, representativeImageUrl: organizationImage }
      },
      subject,
      replyByDate,
      dateSent,
      state: stateCode
    } = edge.node;
    let messageType = 'default';
    if (notifications) {
      if (notifications.edges.find(element => element.node.targetObjectId === solicitationId)) {
        messageType = 'new';
      }
    }
    return {
      node: {
        id,
        staffCoordinator: { name: fullName, imageUrl: representativeImageUrl },
        subject,
        dateSent,
        replyByDate,
        organization: { name: organization, imageUrl: organizationImage },
        status: { code: stateCode },
        message: { type: messageType, size: 18 }
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: 'message', label: '', type: CELL_TYPES.message },
          {
            name: 'subject',
            label: 'JobOpp Inquiry',
            type: CELL_TYPES.link,
            sortable: true,
            width: '40%'
          },
          { name: 'dateSent', label: 'Date Received', type: CELL_TYPES.date, sortable: true },
          {
            name: 'replyByDate',
            label: 'Reply By Date',
            sortable: true,
            type: CELL_TYPES.date
          },
          {
            name: 'status',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: stateTypes.enumValues,
            variant: 'solicitation'
          },
          {
            name: 'staffCoordinator',
            label: 'Contact',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center'
          },
          {
            name: 'organization',
            label: 'Organization',
            type: CELL_TYPES.avatar,
            icon: 'organization'
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        filterProps={{
          filterComponent: <SolicitationsListFilters stateOptions={stateTypes.enumValues} />,
          withApply: true
        }}
        rowProps={{
          handleLinkClick: onLinkClickHandler,
          handleMessageClick: onMessageClickHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
        withBackground
      />
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <SolicitationQuickView
          solicitationId={selectedId}
          solicitation={relayProps}
          refetchNotifications={refetchNotifications}
        />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withRouter(
  withUserContext(
    createRefetchContainer(
      SolicitationsList,
      {
        solicitations: graphql`
          fragment SolicitationsList_solicitations on Query
          @argumentDefinitions(
            first: { type: "Int" }
            orderBy: { type: "String", defaultValue: "-dateSent" }
            after: { type: "String" }
            subject: { type: "String" }
            staffCoordinator: { type: "ID" }
            organization: { type: "String" }
            dateReceivedFrom: { type: "Date" }
            dateReceivedTo: { type: "Date" }
            replyByFrom: { type: "Date" }
            replyByTo: { type: "Date" }
            state: { type: "String" }
          ) {
            allSolicitations(
              first: $first
              orderBy: $orderBy
              after: $after
              subject: $subject
              staffCoordinator: $staffCoordinator
              organization: $organization
              dateReceivedFrom: $dateReceivedFrom
              dateReceivedTo: $dateReceivedTo
              replyByFrom: $replyByFrom
              replyByTo: $replyByTo
              state: $state
            ) {
              pageInfo {
                hasNextPage
                endCursor
              }
              totalCount
              edgeCount
              edges {
                node {
                  id
                  solicitationId
                  subject
                  replyByDate
                  dateSent
                  staffCoordinator {
                    fullName
                    representativeImageUrl
                    organization {
                      name
                      representativeImageUrl
                    }
                  }
                  state
                }
              }
            }
          }
        `,
        stateTypes: graphql`
          fragment SolicitationsList_stateTypes on __Type {
            enumValues {
              name
              description
            }
          }
        `
      },
      graphql`
        query SolicitationsListRefetchQuery(
          $first: Int
          $orderBy: String
          $after: String
          $subject: String
          $staffCoordinator: ID
          $organization: String
          $dateReceivedFrom: Date
          $dateReceivedTo: Date
          $replyByFrom: Date
          $replyByTo: Date
          $state: String
        ) {
          ...SolicitationsList_solicitations
            @arguments(
              first: $first
              orderBy: $orderBy
              after: $after
              subject: $subject
              staffCoordinator: $staffCoordinator
              organization: $organization
              dateReceivedFrom: $dateReceivedFrom
              dateReceivedTo: $dateReceivedTo
              replyByFrom: $replyByFrom
              replyByTo: $replyByTo
              state: $state
            )
        }
      `
    )
  )
);

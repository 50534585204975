import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import colors from '../../styles/common/colors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  chip: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    backgroundColor: '#E8E8ED',
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      color: colors.darkBlueGrey,
      fontSize: '12px',
      fontWeight: 'bold'
    }
  }
}));

PropTypes.AssetReviewCommentChip = {
  revisionNumber: PropTypes.string.isRequired
};

export default function AssetReviewCommentChip({ revisionNumber, onClick }) {
  const classes = useStyles();
  return <Chip onClick={onClick} label={revisionNumber} className={classes.chip} />;
}

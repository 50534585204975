import colors from '../common/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default {
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: 'span',
        subtitle2: 'span'
      }
    }
  },
  palette: {
    colors,
    secondary: {
      main: fade(colors.blueGrey, 0.3),
      dark: fade(colors.blueGrey, 0.7)
    }
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
    h1: {
      fontWeight: 'bold',
      fontSize: '32px',
      color: colors.darkBlueGrey
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '24px',
      color: colors.darkBlueGrey
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: colors.darkBlueGrey
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: colors.darkBlueGrey
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '14px',
      whiteSpace: 'pre-wrap'
    },
    body2: {
      fontWeight: 'bold',
      color: colors.darkBlueGrey,
      fontSize: '14px'
    },
    overline: {
      fontWeight: 'bold',
      textTransform: 'none',
      color: colors.mediumBlueGrey,
      fontSize: '12px',
      lineHeight: '1.5'
    },
    caption: {
      fontWeight: 'bold',
      color: colors.orange,
      fontSize: '14px'
    },
    subtitle1: {
      fontWeight: 'bold',
      color: colors.mediumBlueGrey,
      fontSize: '14px',
      lineHeight: '19px'
    },
    subtitle2: {
      fontWeight: 'bold',
      color: colors.darkBlueGrey,
      fontSize: '14px',
      lineHeight: '19px'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Nunito']
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        height: '75px'
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '12px',
        transform: 'none !important',
        marginBottom: '6px',
        lineHeight: '16px',
        position: 'relative'
      },
      formControl: {
        position: 'relative'
      }
    },
    MuiGrid: {
      'grid-xs-auto': {
        flexGrow: 1
      }
    },
    MuiTableCell: {
      root: {
        padding: '16px 8px'
      }
    },
    MuiFormHelperText: { root: { fontSize: '12px' } },
    MuiTab: {
      root: {
        fontWeight: 'bold',
        padding: '9px 10px',
        height: '20px',
        textTransform: 'none'
      }
    }
  }
};

/**
 * @flow
 * @relayHash 744f11e167302374a88af5a287217031
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
export type MarkClosedSolicitationInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type MarkClosedSolicitationMutationVariables = {|
  input: MarkClosedSolicitationInput
|};
export type MarkClosedSolicitationMutationResponse = {|
  +markClosedSolicitation: ?{|
    +isUpdated: ?boolean,
    +solicitation: ?{|
      +id: string,
      +state: SolicitationState,
    |},
  |}
|};
export type MarkClosedSolicitationMutation = {|
  variables: MarkClosedSolicitationMutationVariables,
  response: MarkClosedSolicitationMutationResponse,
|};
*/


/*
mutation MarkClosedSolicitationMutation(
  $input: MarkClosedSolicitationInput!
) {
  markClosedSolicitation(input: $input) {
    isUpdated
    solicitation {
      id
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkClosedSolicitationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "markClosedSolicitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkClosedSolicitationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isUpdated",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": null,
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MarkClosedSolicitationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MarkClosedSolicitationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MarkClosedSolicitationMutation",
    "id": null,
    "text": "mutation MarkClosedSolicitationMutation(\n  $input: MarkClosedSolicitationInput!\n) {\n  markClosedSolicitation(input: $input) {\n    isUpdated\n    solicitation {\n      id\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '28eec1512c261c1f3fd0cccbf0c04870';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type GreyBar_solicitation$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type InfoSection_solicitation$ref: FragmentReference;
declare export opaque type InfoSection_solicitation$fragmentType: InfoSection_solicitation$ref;
export type InfoSection_solicitation = {|
  +message: ?string,
  +dateSent: ?any,
  +replyByDate: ?any,
  +$fragmentRefs: GreyBar_solicitation$ref,
  +$refType: InfoSection_solicitation$ref,
|};
export type InfoSection_solicitation$data = InfoSection_solicitation;
export type InfoSection_solicitation$key = {
  +$data?: InfoSection_solicitation$data,
  +$fragmentRefs: InfoSection_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "InfoSection_solicitation",
  "type": "SolicitationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dateSent",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "replyByDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "GreyBar_solicitation",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '186e3e09c806b15b2a960d46ef541d9f';
module.exports = node;

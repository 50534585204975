/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AnnotationView_reviewAsset$ref: FragmentReference;
declare export opaque type AnnotationView_reviewAsset$fragmentType: AnnotationView_reviewAsset$ref;
export type AnnotationView_reviewAsset = {|
  +id: string,
  +fileReviewUrl: ?string,
  +settings: ?any,
  +reviewAssetAnnotation: ?{|
    +annotationData: ?any
  |},
  +reactions: ?$ReadOnlyArray<?{|
    +id: ?string,
    +definitionId: ?string,
    +name: ?string,
    +color: ?string,
    +number: ?number,
    +users: ?$ReadOnlyArray<?{|
      +id: string,
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |}>,
  |}>,
  +comments: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +content: string,
        +contentEditedAt: ?any,
        +created: any,
        +objectId: number,
        +extraData: ?any,
        +referenceFiles: ?$ReadOnlyArray<?{|
          +id: string,
          +title: ?string,
          +description: ?string,
          +fileUrl: ?string,
          +reviewInterface: ?string,
        |}>,
        +repliedTo: ?{|
          +content: ?string,
          +created: ?any,
          +user: ?{|
            +fullName: ?string
          |},
          +referenceFiles: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
            +description: ?string,
            +fileUrl: ?string,
            +reviewInterface: ?string,
          |}>,
        |},
        +user: ?{|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |},
        +reactions: ?$ReadOnlyArray<?{|
          +id: ?string,
          +definitionId: ?string,
          +name: ?string,
          +color: ?string,
          +number: ?number,
          +users: ?$ReadOnlyArray<?{|
            +id: string,
            +fullName: ?string,
            +representativeImageUrl: ?string,
          |}>,
        |}>,
      |}
    |}>
  |},
  +$refType: AnnotationView_reviewAsset$ref,
|};
export type AnnotationView_reviewAsset$data = AnnotationView_reviewAsset;
export type AnnotationView_reviewAsset$key = {
  +$data?: AnnotationView_reviewAsset$data,
  +$fragmentRefs: AnnotationView_reviewAsset$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
],
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reactions",
  "storageKey": null,
  "args": null,
  "concreteType": "ReactionNode",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "definitionId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "users",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": true,
      "selections": (v2/*: any*/)
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referenceFiles",
  "storageKey": null,
  "args": null,
  "concreteType": "ReferenceNode",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reviewInterface",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "AnnotationView_reviewAsset",
  "type": "ReviewAssetNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileReviewUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "settings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "reviewAssetAnnotation",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableReviewAnnotationNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "annotationData",
          "args": null,
          "storageKey": null
        }
      ]
    },
    (v3/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "comments",
      "storageKey": null,
      "args": null,
      "concreteType": "CommentNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CommentNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CommentNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "contentEditedAt",
                  "args": null,
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "objectId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "extraData",
                  "args": null,
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "repliedTo",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CommentRepliedToNode",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "user",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ]
                    },
                    (v6/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": (v2/*: any*/)
                },
                (v3/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd70fac5ccde17710bdb4b4d5a516483e';
module.exports = node;

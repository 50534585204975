import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import DropdownTooltip from '../../../UI/DropdownTooltip';
import QuillContext from '../../QuillContext';
import { Button } from './styled';

const EMOJI_LIST = [
  '👍',
  '👎',
  '👌',
  '👏',
  '✅',
  '🙂',
  '😆',
  '😎',
  '🤔',
  '💯',
  '🥂',
  '🎉',
  '🔥',
  '✨',
  '💡'
];

const EmojiListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 130px;
`;

const EmojiButton = styled.button.attrs({
  type: 'button'
})`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  text-align: center;
`;

const EmojiSelectButton = ({ disabled }) => {
  const { quillRef } = useContext(QuillContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <DropdownTooltip
      open={isDropdownOpen}
      onClose={() => setIsDropdownOpen(false)}
      title={
        <EmojiListContainer>
          {EMOJI_LIST.map((str, i) => (
            <EmojiButton
              key={i}
              onMouseDown={e => {
                e.preventDefault();
                const range = quillRef.current.getSelection();
                quillRef.current.insertText(range.index, str);
                setIsDropdownOpen(false);
              }}
            >
              {str}
            </EmojiButton>
          ))}
        </EmojiListContainer>
      }
    >
      <Button
        disabled={disabled}
        onMouseDown={e => {
          e.preventDefault();
          setIsDropdownOpen(val => !val);
        }}
        icon={InsertEmoticonIcon}
      />
    </DropdownTooltip>
  );
};

export default EmojiSelectButton;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';

import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import DeliverableDetails from './DeliverableDetails';

const DeliverableDetailsPage = props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableDetailsPageQuery($id: ID!) {
          deliverable(id: $id) {
            ...DeliverableDetails_deliverable
            ...DeliverableDetailsToggleFollowButton_deliverable
          }
          deliverableStateTypes: __type(name: "DeliverableState") {
            ...DeliverableDetails_deliverableStateTypes
          }
        }
      `}
      variables={{
        id
      }}
      render={relayProps => (
        <DeliverableDetails
          deliverable={relayProps.deliverable}
          deliverableStateTypes={relayProps.deliverableStateTypes}
          relayProps={relayProps}
          {...props}
        />
      )}
    />
  );
};

export default DeliverableDetailsPage;

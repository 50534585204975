/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeliverableToolsActions_deliverable$ref: FragmentReference;
declare export opaque type DeliverableToolsActions_deliverable$fragmentType: DeliverableToolsActions_deliverable$ref;
export type DeliverableToolsActions_deliverable = {|
  +id: string,
  +state: DeliverableState,
  +assignedInternally: ?{|
    +id: string
  |},
  +assignedStaff: ?{|
    +user: {|
      +id: string
    |}
  |},
  +job: ?{|
    +id: string
  |},
  +$refType: DeliverableToolsActions_deliverable$ref,
|};
export type DeliverableToolsActions_deliverable$data = DeliverableToolsActions_deliverable;
export type DeliverableToolsActions_deliverable$key = {
  +$data?: DeliverableToolsActions_deliverable$data,
  +$fragmentRefs: DeliverableToolsActions_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "DeliverableToolsActions_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignedInternally",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignedStaff",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "UserNode",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "job",
      "storageKey": null,
      "args": null,
      "concreteType": "JobNode",
      "plural": false,
      "selections": (v1/*: any*/)
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fd9781d859e770497c358ac68277187e';
module.exports = node;

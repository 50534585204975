import React from 'react';
import { Field, Form, Formik } from 'formik';
import { DialogContentText, Typography } from '@material-ui/core';
import FormikTextField from '../../../../../../../shared/components/form/FormikTextField';
import Dialog, { DialogActions } from '../../../../../../../shared/components/common/Dialog';
import { yupSchemas } from '../../../../../../../shared/validations';
import GlobalButton from '../../../../../../../shared/components/UI/GlobalButton';
import InvoiceApprovalMutation from '../../../../mutations/InvoiceApprovalMutation';

const DeclineInvoiceDialog = ({ invoiceId, isDialogOpen, closeDialog, onDeclined }) => {
  const handleSubmit = ({ reason }, { setSubmitting }) => {
    setSubmitting(true);
    InvoiceApprovalMutation({
      approved: false,
      invoiceId,
      message: reason
    }).finally(() => {
      setSubmitting(false);
      closeDialog();
      onDeclined();
    });
  };

  const initialValues = {
    reason: ''
  };

  const validationSchema = yupSchemas.shape({
    reason: yupSchemas.string(true, { max: 5000 })
  });

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title="Decline Invoice"
      minWidth={400}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContentText>
              <Typography variant="body1" component="span">
                Please provide a reason for declining this invoice
              </Typography>
            </DialogContentText>
            <Field
              component={FormikTextField}
              rows="2"
              name="reason"
              fullWidth
              multiline
              required
            />
            <DialogActions>
              <GlobalButton handleClick={closeDialog} variant="transparent" disabled={isSubmitting}>
                Cancel
              </GlobalButton>
              <GlobalButton
                type="submit"
                variant="cancelation"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Decline
              </GlobalButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DeclineInvoiceDialog;

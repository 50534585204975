import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import clsx from 'clsx';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import { IC_TARGETS } from '../../../../../shared/services/intercomService';
import SolicitationCommentThread from '../../SolicitationCommentThread';
import InfoSection from './InfoSection';

const SolicitationDetailsTab = props => {
  const { relayProps } = props;
  const classes = useDetailsStyles();

  return (
    <div className={classes.container}>
      <div className={clsx(classes.solicitationLeft, classes.borderRight)}>
        <InfoSection solicitation={relayProps.solicitation} classes={classes} />
      </div>
      <div
        className={clsx(classes.solicitationRight)}
        data-intercom-target={IC_TARGETS.solicitationsMessage}
      >
        <div className={classes.detailsContentNoTitle}>
          <SolicitationCommentThread
            solicitation={relayProps.solicitation}
            solicitationId={relayProps.solicitation.id}
          />
        </div>
      </div>
    </div>
  );
};

export default props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query SolicitationDetailsTabQuery($id: ID!) {
          solicitation(id: $id) {
            id
            ...SolicitationCommentThread_solicitation
            ...InfoSection_solicitation
          }
        }
      `}
      variables={{ id }}
      render={relayProps => <SolicitationDetailsTab {...props} relayProps={relayProps} />}
    />
  );
};

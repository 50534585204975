/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Notes_contractor$ref: FragmentReference;
declare export opaque type Notes_contractor$fragmentType: Notes_contractor$ref;
export type Notes_contractor = {|
  +id: string,
  +contractorNotes: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +created: any,
        +staff: {|
          +user: {|
            +id: string,
            +fullName: ?string,
          |},
          +representativeImageUrl: ?string,
        |},
        +content: ?string,
        +contentEditedAt: ?any,
        +repliedTo: ?{|
          +content: ?string,
          +created: ?any,
          +user: ?{|
            +fullName: ?string
          |},
        |},
      |}
    |}>
  |},
  +$refType: Notes_contractor$ref,
|};
export type Notes_contractor$data = Notes_contractor;
export type Notes_contractor$key = {
  +$data?: Notes_contractor$data,
  +$fragmentRefs: Notes_contractor$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Notes_contractor",
  "type": "ContractorNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contractorNotes",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractorNoteNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractorNoteNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractorNoteNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "staff",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "user",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/)
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "representativeImageUrl",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "contentEditedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "repliedTo",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CommentRepliedToNode",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "user",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6db8318c5f4db54a80c9c704a5e12fef';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeclinePaymentRequestMutation($input: DeclinePaymentRequestInput!) {
    declinePaymentRequest(input: $input) {
      emailSent
      paymentRequest {
        id
        status
        paymentAmount
        declinedBy {
          id
          fullName
          representativeImageUrl
        }
        declinedDate
        declinedReason
        job {
          balance
          totalAmountOfPaymentRequests
        }
      }
    }
  }
`;

export default (id, reason, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { id, reason } },
    onCompleted: response => {
      callback(response.declinePaymentRequest);
    },
    onError: err => console.error(err)
  });
};

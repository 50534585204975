/**
 * @flow
 * @relayHash a8451bfe81af97e3d1daee6c5797c685
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CurrencyCodes = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type UpdateBatchDeliverableInput = {|
  ids: $ReadOnlyArray<?string>,
  assignedStaff?: ?string,
  amount?: ?any,
  currencyCode?: ?CurrencyCodes,
  dueDate?: ?any,
  clientMutationId?: ?string,
|};
export type UpdateBatchDeliverableMutationVariables = {|
  input: UpdateBatchDeliverableInput
|};
export type UpdateBatchDeliverableMutationResponse = {|
  +updateBatchDeliverable: ?{|
    +isUpdated: ?boolean,
    +updatedDeliverables: ?$ReadOnlyArray<?{|
      +id: string,
      +assignedStaff: ?{|
        +fullName: ?string,
        +representativeImageUrl: ?string,
        +user: {|
          +id: string
        |},
      |},
      +amount: ?any,
      +amountInHomeCurrency: ?any,
      +dueDate: ?any,
      +currencyCode: ?DeliverableCurrencyCode,
    |}>,
  |}
|};
export type UpdateBatchDeliverableMutation = {|
  variables: UpdateBatchDeliverableMutationVariables,
  response: UpdateBatchDeliverableMutationResponse,
|};
*/


/*
mutation UpdateBatchDeliverableMutation(
  $input: UpdateBatchDeliverableInput!
) {
  updateBatchDeliverable(input: $input) {
    isUpdated
    updatedDeliverables {
      id
      assignedStaff {
        fullName
        representativeImageUrl
        user {
          id
        }
        id
      }
      amount
      amountInHomeCurrency
      dueDate
      currencyCode
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBatchDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isUpdated",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amountInHomeCurrency",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dueDate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyCode",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateBatchDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBatchDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBatchDeliverablePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "assignedStaff",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateBatchDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBatchDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBatchDeliverablePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "assignedStaff",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v3/*: any*/)
                ]
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateBatchDeliverableMutation",
    "id": null,
    "text": "mutation UpdateBatchDeliverableMutation(\n  $input: UpdateBatchDeliverableInput!\n) {\n  updateBatchDeliverable(input: $input) {\n    isUpdated\n    updatedDeliverables {\n      id\n      assignedStaff {\n        fullName\n        representativeImageUrl\n        user {\n          id\n        }\n        id\n      }\n      amount\n      amountInHomeCurrency\n      dueDate\n      currencyCode\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f026eb4b291b537cd6586d446f7f5b0e';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentDetailsContent_currencyCodes$ref: FragmentReference;
declare export opaque type PaymentDetailsContent_currencyCodes$fragmentType: PaymentDetailsContent_currencyCodes$ref;
export type PaymentDetailsContent_currencyCodes = {|
  +enumValues: ?$ReadOnlyArray<{|
    +name: string,
    +description: ?string,
  |}>,
  +$refType: PaymentDetailsContent_currencyCodes$ref,
|};
export type PaymentDetailsContent_currencyCodes$data = PaymentDetailsContent_currencyCodes;
export type PaymentDetailsContent_currencyCodes$key = {
  +$data?: PaymentDetailsContent_currencyCodes$data,
  +$fragmentRefs: PaymentDetailsContent_currencyCodes$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PaymentDetailsContent_currencyCodes",
  "type": "__Type",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "enumValues",
      "storageKey": null,
      "args": null,
      "concreteType": "__EnumValue",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd429889c0dfe4dbb1e7c534004c0c44d';
module.exports = node;

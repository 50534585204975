import React from 'react';
import { Grid } from '@material-ui/core';

import { Field } from 'formik';
import DetailsSection from './DetailsSection';
import TagsGrid from '../UI/TagsGrid';
import SearchableTagsDropdown from '../../../organization/components/form/SearchableTagsDropdown';

const TagsAutocompleteSection = props => {
  const {
    values: { tags },
    setFieldValue,
    isEditing
  } = props;

  return isEditing ? (
    <DetailsSection title="Tags">
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Field
            name="tags"
            id="tagInput"
            label=""
            placeholder="Add new tag"
            component={SearchableTagsDropdown}
            creatable
            value={tags}
            onChange={(field, options) => setFieldValue(field, options)}
          />
        </Grid>
      </Grid>
    </DetailsSection>
  ) : (
    <DetailsSection title="Tags">
      <TagsGrid
        tags={tags.options.map(tag => ({ node: { id: tag.value, name: tag.label } }))}
        withMessage
      />
    </DetailsSection>
  );
};

export default TagsAutocompleteSection;

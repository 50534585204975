import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import Reactions from './Reactions';

const ReactionsQueryRenderer = () => {
  return (
    <QueryRenderer
      query={graphql`
        query ReactionsQueryRendererQuery {
          reactionByOrg {
            edges {
              node {
                id
                name
                color
              }
            }
          }
        }
      `}
      render={relayProps => <Reactions reactions={relayProps.reactionByOrg.edges} />}
    />
  );
};

export default ReactionsQueryRenderer;

/**
 * @flow
 * @relayHash dbbe7076e257c1952477c37c3822f6df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NotificationTypes = "assigned_internally" | "comment_reaction" | "contract_signed" | "contractor_invite_accepted" | "contractor_invite_received" | "deliverable_cancel" | "deliverable_close" | "deliverable_file_upload" | "deliverable_reset" | "follow_object" | "invoice_approved" | "invoice_created" | "invoice_declined" | "job_canceled" | "job_comment" | "job_declined" | "job_received" | "job_revoked" | "payment_requested" | "reference_upload" | "review_asset_comment" | "review_asset_reaction" | "review_asset_upload" | "solicitation_comment" | "solicitation_received" | "staff_assigned" | "staff_invite_accepted" | "storage_limit_exceeded" | "%future added value";
export type MarkNotificationsAsReadInput = {|
  notificationId?: ?string,
  targetId?: ?string,
  senderId?: ?string,
  actionObjectId?: ?string,
  notificationType?: ?NotificationTypes,
  clientMutationId?: ?string,
|};
export type MarkNotificationsAsReadMutationVariables = {|
  input: MarkNotificationsAsReadInput
|};
export type MarkNotificationsAsReadMutationResponse = {|
  +markNotificationsAsRead: ?{|
    +isUpdated: ?boolean
  |}
|};
export type MarkNotificationsAsReadMutation = {|
  variables: MarkNotificationsAsReadMutationVariables,
  response: MarkNotificationsAsReadMutationResponse,
|};
*/


/*
mutation MarkNotificationsAsReadMutation(
  $input: MarkNotificationsAsReadInput!
) {
  markNotificationsAsRead(input: $input) {
    isUpdated
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkNotificationsAsReadInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "markNotificationsAsRead",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkNotificationsAsReadPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isUpdated",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MarkNotificationsAsReadMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MarkNotificationsAsReadMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MarkNotificationsAsReadMutation",
    "id": null,
    "text": "mutation MarkNotificationsAsReadMutation(\n  $input: MarkNotificationsAsReadInput!\n) {\n  markNotificationsAsRead(input: $input) {\n    isUpdated\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '734a0a911f2607c435f51cc426926596';
module.exports = node;

import { TableBody } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  '& tr': {
    position: 'relative',
    height: '44px',

    '& td:not(.MuiTableCell-paddingCheckbox), th:not(.MuiTableCell-paddingCheckbox)': {
      padding: 8,
      '&:first-child': {
        paddingLeft: '9px'
      },
      '&:last-child': {
        paddingRight: '9px'
      }
    }
  },

  '& tr:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.colors.filterButtonGrey}`
  }
}));

/**
 * @flow
 * @relayHash d66b19daf06c98634f3ba7c2504b293d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
export type SendSolicitationInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type SendSolicitationMutationVariables = {|
  input: SendSolicitationInput
|};
export type SendSolicitationMutationResponse = {|
  +sendSolicitation: ?{|
    +isSent: ?boolean,
    +solicitation: ?{|
      +id: string,
      +state: SolicitationState,
      +dateSent: ?any,
    |},
  |}
|};
export type SendSolicitationMutation = {|
  variables: SendSolicitationMutationVariables,
  response: SendSolicitationMutationResponse,
|};
*/


/*
mutation SendSolicitationMutation(
  $input: SendSolicitationInput!
) {
  sendSolicitation(input: $input) {
    isSent
    solicitation {
      id
      state
      dateSent
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendSolicitationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sendSolicitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendSolicitationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isSent",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": null,
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dateSent",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SendSolicitationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SendSolicitationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SendSolicitationMutation",
    "id": null,
    "text": "mutation SendSolicitationMutation(\n  $input: SendSolicitationInput!\n) {\n  sendSolicitation(input: $input) {\n    isSent\n    solicitation {\n      id\n      state\n      dateSent\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2286481ea7b193b5789b98a5099770fc';
module.exports = node;

import React from 'react';
import get from 'lodash/get';
import TextField from './fields/TextField';

export const isInvalid = ({ form, field }) =>
  (get(form.errors, field.name) || get(form.errors, field.name) === '') &&
  get(form.touched, field.name);

const FormikTextField = props => {
  const { field, form, ...otherProps } = props;

  return (
    <TextField
      name={field.name}
      value={field.value}
      error={isInvalid(props)}
      helperText={get(form.errors, field.name)}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
      {...otherProps}
    />
  );
};

export default FormikTextField;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeactivateStaffUserMutation($input: DeactivateStaffUserInput!) {
    deactivateStaffUser(input: $input) {
      updatedStaffUser {
        id
        user {
          isActive
        }
      }
    }
  }
`;

const DeactivateStaffUserMutation = (id, callback) => {
  const config = {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.deactivateStaffUser);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default DeactivateStaffUserMutation;

/**
 * @flow
 * @relayHash d1fa6bcfefcd25a9f2523f5c3851fa49
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProductList_products$ref = any;
export type ProductListRefetchQueryVariables = {|
  first?: ?number,
  after?: ?string,
  includeInactive?: ?boolean,
  orderBy?: ?string,
  title?: ?string,
  code?: ?string,
|};
export type ProductListRefetchQueryResponse = {|
  +$fragmentRefs: ProductList_products$ref
|};
export type ProductListRefetchQuery = {|
  variables: ProductListRefetchQueryVariables,
  response: ProductListRefetchQueryResponse,
|};
*/


/*
query ProductListRefetchQuery(
  $first: Int
  $after: String
  $includeInactive: Boolean
  $orderBy: String
  $title: String
  $code: String
) {
  ...ProductList_products
}

fragment ProductList_products on Query {
  allProducts(first: $first, after: $after, includeInactive: $includeInactive, orderBy: $orderBy, title: $title, code: $code) {
    edges {
      node {
        title
        description
        representativeImageUrl
        code
        id
      }
    }
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "code",
    "type": "String",
    "defaultValue": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProductListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ProductList_products",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProducts",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "code",
            "variableName": "code"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "includeInactive",
            "variableName": "includeInactive"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          },
          {
            "kind": "Variable",
            "name": "title",
            "variableName": "title"
          }
        ],
        "concreteType": "ProductNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "representativeImageUrl",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "code",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProductListRefetchQuery",
    "id": null,
    "text": "query ProductListRefetchQuery(\n  $first: Int\n  $after: String\n  $includeInactive: Boolean\n  $orderBy: String\n  $title: String\n  $code: String\n) {\n  ...ProductList_products\n}\n\nfragment ProductList_products on Query {\n  allProducts(first: $first, after: $after, includeInactive: $includeInactive, orderBy: $orderBy, title: $title, code: $code) {\n    edges {\n      node {\n        title\n        description\n        representativeImageUrl\n        code\n        id\n      }\n    }\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a960b01bd704bfb08f51233624de65e1';
module.exports = node;

import styled from 'styled-components';
import BaseToolbar from '@material-ui/core/Toolbar';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Toolbar = styled(BaseToolbar)`
  display: flex;
  justify-content: flex-end;
`;

export const ContentContainer = styled.div`
  overflow: auto;
  flex-grow: 1;
`;

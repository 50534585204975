/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type JobInvoiceStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type InvoiceQuickView_invoice$ref: FragmentReference;
declare export opaque type InvoiceQuickView_invoice$fragmentType: InvoiceQuickView_invoice$ref;
export type InvoiceQuickView_invoice = {|
  +id: string,
  +identifier: string,
  +status: JobInvoiceStatus,
  +invoiceFileUrl: ?string,
  +created: any,
  +amount: number,
  +message: ?string,
  +verificationDate: ?any,
  +verificationMessage: ?string,
  +verificationBy: ?{|
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +markedPaymentSentDate: ?any,
  +markedPaymentSentMessage: ?string,
  +markedPaymentSentBy: ?{|
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +job: {|
    +name: string,
    +currencyCode: ?string,
    +staffCoordinator: {|
      +fullName: ?string,
      +representativeImageUrl: ?string,
      +organization: {|
        +name: string
      |},
    |},
  |},
  +$refType: InvoiceQuickView_invoice$ref,
|};
export type InvoiceQuickView_invoice$data = InvoiceQuickView_invoice;
export type InvoiceQuickView_invoice$key = {
  +$data?: InvoiceQuickView_invoice$data,
  +$fragmentRefs: InvoiceQuickView_invoice$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "InvoiceQuickView_invoice",
  "type": "InvoiceNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "identifier",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "invoiceFileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "created",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "verificationDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "verificationMessage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "verificationBy",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "markedPaymentSentDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "markedPaymentSentMessage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "markedPaymentSentBy",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "job",
      "storageKey": null,
      "args": null,
      "concreteType": "JobNode",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currencyCode",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "staffCoordinator",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "organization",
              "storageKey": null,
              "args": null,
              "concreteType": "OrgNode",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c131c0298eacb5a0cd2f3c096a583680';
module.exports = node;

import React, { createContext, useEffect, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { PAGINATION_TYPES } from '../constants';
import { formatFilterValuesForQuery } from '../utils/helpers';

let STORAGE_KEY = null;
const DEFAULT_CTX_VALUE = {
  cursorStack: [],
  rowsPerPage: PAGINATION_TYPES.default.defaultValue
};

const TableHistoryContext = createContext();

const getStoredValue = () => JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
const setStoredValue = val => localStorage.setItem(STORAGE_KEY, JSON.stringify(val));

export const TableHistoryContextManager = ({ baseName, children }) => {
  const location = useLocation();
  STORAGE_KEY = `table-history-context-${baseName}`;

  useEffect(() => {
    const storedFilters = getStoredValue();
    Object.entries(storedFilters).forEach(([_key, value]) => {
      const shouldReset = !location.pathname.startsWith(value.pathname);
      if (shouldReset) {
        delete value.filters;
        delete value.cursorStack;
      }
    });
    setStoredValue(storedFilters);
  }, [location.pathname]);

  return children;
};

export const TableHistoryContextProvider = ({ tableId, initialValue, children }) => {
  const location = useLocation();
  const storedValue = getStoredValue()[tableId];

  const [value, _setValue] = useState({
    ...DEFAULT_CTX_VALUE,
    ...initialValue,
    ...storedValue,
    pathname: location.pathname
  });

  const setValue = data => {
    _setValue(val => {
      const newValue = mergeWith({}, val, data, (objValue, srcValue) => {
        if (isArray(objValue)) {
          return srcValue;
        } else if (isObject(objValue) && isObject(srcValue)) {
          return merge({}, objValue, srcValue);
        } else {
          return srcValue;
        }
      });

      const storageValue = getStoredValue();
      const newStorageValue = {
        ...storageValue,
        [tableId]: newValue
      };
      setStoredValue(newStorageValue);

      return newValue;
    });
  };

  // NOTE:
  // There are 3 types of filters
  // value.filters - stored untill TableHistoryContextManager will reset
  // value.defaultSavedFilters - stored untill manual reset
  // value.initialFilters - initial context value
  const filters = value.filters || value.defaultSavedFilters || value.initialFilters || {};

  return (
    <TableHistoryContext.Provider
      value={{
        ...value,
        filters,
        setValue
      }}
      children={children}
    />
  );
};

export const withTableHistoryProvider = (Component, tableId, initialValue) => props => {
  return (
    <TableHistoryContextProvider tableId={tableId} initialValue={initialValue}>
      <Component {...props} />
    </TableHistoryContextProvider>
  );
};

export const withTableHistoryContext = Component => props => {
  const ctx = useContext(TableHistoryContext) || {
    ...DEFAULT_CTX_VALUE,
    setValue: () => null
  };

  return <Component {...props} tableHistoryContext={ctx} />;
};

export const useTableHistoryQueryVariables = () => {
  const { filters, orderBy, rowsPerPage, cursorStack } = useContext(TableHistoryContext);

  // NOTE:
  // Caches original values that were in local storage before first render
  // This is needed because we don't want to trigger any re-renders after filter update unless path changes
  return useMemo(
    () => ({
      ...formatFilterValuesForQuery(filters),
      orderBy: orderBy,
      first: rowsPerPage,
      after: cursorStack ? cursorStack[cursorStack.length - 1] : ''
    }),
    []
  );
};

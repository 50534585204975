import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { styled } from '@material-ui/styles';
import {
  Box,
  Typography,
  InputLabel,
  FormControl,
  FormHelperText,
  RootRef,
  SvgIcon
} from '@material-ui/core';
import GlobalButton from '../../UI/GlobalButton';

import { ReactComponent as FileIcon } from '../../../../shared/images/file.svg';
import { ReactComponent as FileUploadIcon } from '../../../../shared/images/file-upload.svg';
import ClearFieldButton from './fieldElements/ClearFieldButton';

const StyledZone = styled(({ isDragActive, isDragReject, error, ...otherProps }) => (
  <Box {...otherProps} />
))(({ theme, isDragActive, isDragReject, error }) => ({
  position: 'relative',
  width: '100%',
  height: '132px',
  borderWidth: isDragActive ? 2 : 1,
  borderStyle: isDragActive ? 'dashed' : 'solid',
  borderColor:
    isDragReject || error ? theme.palette.colors.red : theme.palette.colors.formFieldBorderColor,
  borderRadius: '2px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexFlow: 'column'
}));

const StyledIcon = styled(props => <SvgIcon {...props} viewBox="0 0 22 31" />)(({ theme }) => ({
  color: theme.palette.colors.darkBlueGrey,
  width: 'auto',
  height: theme.spacing(6)
}));

const Dropzone = props => {
  const { accept, file, error, label, onChange } = props;

  const onDropHandler = acceptedFiles => {
    onChange(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, open, isDragActive, isDragReject } = useDropzone({
    onDrop: onDropHandler,
    multiple: false,
    accept,
    noClick: true
  });

  const { ref, ...rootProps } = getRootProps({ isDragActive, isDragReject, error });

  const clearFileHandler = () => {
    onChange(null);
  };

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <InputLabel>{label}</InputLabel>
      <RootRef rootRef={ref}>
        <StyledZone {...rootProps}>
          {isDragActive ? (
            isDragReject ? (
              <Typography variant="overline" gutterBottom>
                Wrong file format
              </Typography>
            ) : (
              <StyledIcon component={FileUploadIcon} />
            )
          ) : file ? (
            <Fragment>
              <Box position="absolute" top={8} right={0}>
                <ClearFieldButton onClick={clearFileHandler} />
              </Box>
              <Box mb={1}>
                <StyledIcon component={FileIcon} />
              </Box>
              <Typography variant="subtitle2">{file.name}</Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography variant="overline" gutterBottom>
                Drag a file here
              </Typography>
              <Typography variant="overline" gutterBottom>
                - or -
              </Typography>
              <GlobalButton handleClick={open} variant="secondary">
                Select a file from your computer
              </GlobalButton>
            </Fragment>
          )}
          <input {...getInputProps()} />
        </StyledZone>
      </RootRef>
      <FormHelperText component="div" error={Boolean(error)}>
        {error && error}
      </FormHelperText>
    </FormControl>
  );
};

Dropzone.propTypes = {
  accept: PropTypes.string.isRequired,
  error: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

Dropzone.defaultProps = {
  label: 'Upload',
  accept: 'application/pdf'
};

export default Dropzone;

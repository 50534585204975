import React from 'react';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import graphql from 'babel-plugin-relay/macro';
import FreelancerPortfolio from './FreelancerPortfolio';
import { PAGINATION_TYPES } from '../../../shared/constants';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';

const FreelancerPortfolioPage = () => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      variables={variables}
      query={graphql`
        query FreelancerPortfolioPageQuery($first: Int, $after: String) {
          ...FreelancerPortfolio_portfolio @arguments(first: $first, after: $after)
        }
      `}
      render={relayProps => <FreelancerPortfolio portfolio={relayProps} />}
    />
  );
};

export default withTableHistoryProvider(FreelancerPortfolioPage, 'frePortfolio', {
  rowsPerPage: PAGINATION_TYPES.short.defaultValue
});

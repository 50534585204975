import React from 'react';
import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import StorageDeliverableFilesTab from './StorageDeliverableFilesTab';
import OverviewTab from './OverviewTab';
import StorageReviewAssetsTab from './StorageReviewAssetsTab';

const StorageManagement = props => (
  <AutoTabList>
    <OverviewTab label="Overview" {...props} />
    <StorageDeliverableFilesTab label="Deliverable Files" {...props} />
    <StorageReviewAssetsTab label="Review Assets" {...props} />
  </AutoTabList>
);

export default StorageManagement;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import ContractsList from './ContractsList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';

const JobsPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query JobsPageQuery(
          $after: String
          $first: Int
          $orderBy: String
          $status: String
          $organization: String
          $orgSequenceId: String
          $staffId: String
          $contractDateFrom: Date
          $contractDateTo: Date
          $jobDeadlineFrom: Date
          $jobDeadlineTo: Date
          $name: String
        ) {
          ...ContractsList_jobs
            @arguments(
              first: $first
              orderBy: $orderBy
              after: $after
              status: $status
              organization: $organization
              orgSequenceId: $orgSequenceId
              staffId: $staffId
              contractDateFrom: $contractDateFrom
              contractDateTo: $contractDateTo
              jobDeadlineFrom: $jobDeadlineFrom
              jobDeadlineTo: $jobDeadlineTo
              name: $name
            )
          __type(name: "JobStatus") {
            ...ContractsList_statusTypes
          }
        }
      `}
      variables={variables}
      render={relayProps => (
        <ContractsList
          label="Contracts"
          jobs={relayProps}
          statusTypes={relayProps.__type}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(JobsPage, 'freJobs', {
  orderBy: '-contractDate',
  initialFilters: {
    name: '',
    organization: null,
    orgSequenceId: '',
    staffId: null,
    contractDateFrom: null,
    contractDateTo: null,
    jobDeadlineFrom: null,
    jobDeadlineTo: null,
    status: ''
  }
});

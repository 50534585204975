import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';
import MessageIcon from '../UI/Message';
import { useTableStyles } from '../../styles/common/tableStyles';
import NewMessageBadge from '../UI/NewMessageBadge';

const TableCellMessage = props => {
  const {
    handleClick,
    iconProps: { value, type, size, cursor }
  } = props;
  const classes = useTableStyles();
  return (
    <StyledTableCell noWrap>
      <div className={classes.verticalCenter}>
        {type === 'new' && (
          <NewMessageBadge
            variant="dot"
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            type="message"
          >
            <MessageIcon onClick={handleClick} text={value} size={size} cursor={cursor} />
          </NewMessageBadge>
        )}
        {type === 'default' && (
          <MessageIcon onClick={handleClick} text={value} size={size} cursor={cursor} />
        )}
      </div>
    </StyledTableCell>
  );
};

export default TableCellMessage;

TableCellMessage.propTypes = {
  handleClick: PropTypes.func,
  iconProps: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.oneOf(['none', 'default', 'new']).isRequired,
    size: PropTypes.number,
    cursor: PropTypes.bool
  })
};

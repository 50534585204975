import React, { useState } from 'react';

import AutoTable from '../../../../shared/components/table/AutoTable';
import ContractorDropdowns from '../ContractorDropdowns';

import { CELL_TYPES } from '../../../../shared/constants';
import useUpdateEffect from '../../../../shared/hooks/useUpdateEffect';

const ContractorTab = props => {
  const {
    className,
    form: {
      values: { contractors },
      setFieldValue
    }
  } = props;

  const [orderBy, setOrderBy] = useState('fullName');

  useUpdateEffect(() => {
    setFieldValue('contractors', contractors.sort(compareContractorsName));
  }, [orderBy]);

  const compareContractorsName = (a, b) =>
    orderBy === 'fullName'
      ? a.lastName.localeCompare(b.lastName)
      : b.lastName.localeCompare(a.lastName);

  const setContractorsFieldHandler = addedContractors => {
    const updatedContractors = [...contractors, ...addedContractors].sort(compareContractorsName);
    setFieldValue('contractors', updatedContractors);
  };

  const removeFromListHandler = id => {
    setFieldValue(
      'contractors',
      contractors.filter(value => value.id !== id)
    );
  };

  const onTableChangeHandler = ({ orderBy }) => {
    setOrderBy(orderBy);
  };

  const flattenedEdges = contractors.map(contractor => {
    const contractorCopy = Object.assign({ ...contractor });
    if (typeof contractorCopy.fullName === 'string') {
      contractorCopy.fullName = {
        name: contractorCopy.fullName,
        imageUrl: contractorCopy.representativeImageUrl
      };
    }
    return {
      node: {
        id: contractor.id,
        fullName: contractorCopy.fullName
      }
    };
  });

  return (
    <div className={className}>
      <ContractorDropdowns onChange={setContractorsFieldHandler} contractorsInList={contractors} />
      <AutoTable
        rowTemplate={[
          { name: 'fullName', label: 'Name', type: CELL_TYPES.avatar, sortable: true },
          //Disabling Ratings from UI until feature fully fleshed out
          // { name: 'rating', label: 'Rating', type: CELL_TYPES.rating },
          {
            name: 'trash',
            label: '',
            type: CELL_TYPES.trash,
            onClick: removeFromListHandler,
            tooltip: 'Remove'
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onTableChangeHandler}
        initialOrderBy="fullName"
      />
    </div>
  );
};

export default ContractorTab;

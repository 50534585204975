import React, { Fragment, useState } from 'react';
import { Grid } from '@material-ui/core';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import { CELL_TYPES } from '../../../../../shared/constants';

import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getCountryName, getEnvironment } from '../../../../../shared/utils/helpers';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import QuickViewAvatarHeader from '../../../../../shared/components/UI/QuickViewAvatarHeader';

const organizationQuickViewQuery = graphql`
  query OrganizationQuickViewQuery($id: ID!) {
    organization(id: $id) {
      representativeImageUrl
      name
      email
      phone
      country {
        name
      }
      timezoneFormat

      jobs {
        edges {
          node {
            id
            jobId
            contract {
              dateSignedByOrganization
            }
          }
        }
      }

      orgStaffs {
        edges {
          node {
            id
            fullName
            user {
              id
              representativeImageUrl
              email
            }
            title
          }
        }
      }
    }
  }
`;

const OrganizationQuickView = ({ organizationId }) => {
  const [organization, setOrganization] = useState(null);

  useEffectWithStatus(
    status => {
      fetchQuery(getEnvironment(), organizationQuickViewQuery, { id: organizationId }).then(
        response => {
          status.mounted && setOrganization(response.organization);
        }
      );
    },
    [organizationId]
  );

  const mappedStaffEdges = organization
    ? organization.orgStaffs.edges.map(edge => {
        const {
          fullName,
          user: { email, representativeImageUrl },
          title,
          id
        } = edge.node;

        return {
          node: {
            id,
            name: { name: fullName, imageUrl: representativeImageUrl },
            title,
            email,
            representativeImageUrl
          }
        };
      })
    : [];

  const mappedContractsEdges = organization
    ? organization.jobs.edges.map(({ node: { id, jobId, contract } }) => ({
        node: {
          id,
          jobId,
          date: contract ? contract.dateSignedByOrganization : ''
        }
      }))
    : [];

  return organization ? (
    <Fragment>
      <QuickViewAvatarHeader
        fullName={organization.name}
        representativeImageUrl={organization.representativeImageUrl}
      />
      <DetailsSection title="Contact">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DetailsRow title="Email" content={organization.email} />
            <DetailsRow title="Phone" content={organization.phone} />
          </Grid>
          <Grid item xs={6}>
            <DetailsRow title="Location" content={getCountryName(organization.country)} />
            <DetailsRow title="Timezone" content={organization.timezoneFormat} />
          </Grid>
        </Grid>
      </DetailsSection>
      <DetailsSection title="Organization Staff">
        <AutoTable
          edges={mappedStaffEdges}
          rowTemplate={[
            { name: 'name', type: CELL_TYPES.avatar, label: 'Name' },
            { name: 'title', label: 'Title' },
            { name: 'email', label: 'Email' }
          ]}
        />
      </DetailsSection>
      <DetailsSection title="Contracts" noBorder>
        <AutoTable
          edges={mappedContractsEdges}
          rowTemplate={[
            { name: 'jobId', label: 'Job ID' },
            { name: 'date', label: 'Date' }
          ]}
        />
      </DetailsSection>
    </Fragment>
  ) : (
    // TODO add loading indicator
    <div>Loading</div>
  );
};

export default OrganizationQuickView;

import React from 'react';

import { Typography } from '@material-ui/core';
import StyledCheckbox from '../../../../shared/components/UI/StyledCheckbox';

const ContractTemplatesListFilters = props => {
  const { filterValues, onFilterChange } = props;

  return (
    <Typography variant="body1">
      <StyledCheckbox
        checked={!filterValues['excludeInactive']}
        onChange={(_event, value) => onFilterChange('excludeInactive', !value)}
      />
      Show inactive Contract Templates
    </Typography>
  );
};

export default ContractTemplatesListFilters;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractorInfo_contractor$ref: FragmentReference;
declare export opaque type ContractorInfo_contractor$fragmentType: ContractorInfo_contractor$ref;
export type ContractorInfo_contractor = {|
  +id: string,
  +currency: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +fullName: ?string,
  +nickname: ?string,
  +title: ?string,
  +email: ?string,
  +portfolioUrl: ?string,
  +phone: ?string,
  +timezone: ?string,
  +timezoneList: ?$ReadOnlyArray<?string>,
  +companyName: ?string,
  +internalId: ?string,
  +conflictingContractor: ?{|
    +id: string,
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +freelancer: ?{|
    +id: string
  |},
  +$refType: ContractorInfo_contractor$ref,
|};
export type ContractorInfo_contractor$data = ContractorInfo_contractor;
export type ContractorInfo_contractor$key = {
  +$data?: ContractorInfo_contractor$data,
  +$fragmentRefs: ContractorInfo_contractor$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ContractorInfo_contractor",
  "type": "ContractorNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currency",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nickname",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "portfolioUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "timezone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "timezoneList",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "internalId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "conflictingContractor",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractorNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "representativeImageUrl",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "freelancer",
      "storageKey": null,
      "args": null,
      "concreteType": "FreelancerNode",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bed0364275365448a30bf0482fbf09c2';
module.exports = node;

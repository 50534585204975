/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DetailsSolicitations_deliverable$ref: FragmentReference;
declare export opaque type DetailsSolicitations_deliverable$fragmentType: DetailsSolicitations_deliverable$ref;
export type DetailsSolicitations_deliverable = {|
  +solicitations: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +staffCoordinator: {|
          +representativeImageUrl: ?string,
          +fullName: ?string,
        |},
        +dateSent: ?any,
        +state: SolicitationState,
        +subject: string,
      |}
    |}>
  |},
  +$refType: DetailsSolicitations_deliverable$ref,
|};
export type DetailsSolicitations_deliverable$data = DetailsSolicitations_deliverable;
export type DetailsSolicitations_deliverable$key = {
  +$data?: DetailsSolicitations_deliverable$data,
  +$fragmentRefs: DetailsSolicitations_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DetailsSolicitations_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "solicitations",
      "storageKey": null,
      "args": null,
      "concreteType": "SolicitationNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SolicitationNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SolicitationNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "staffCoordinator",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "representativeImageUrl",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dateSent",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "state",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "subject",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '31637556c0d9aafbf5b3014fc1b16bd3';
module.exports = node;

/**
 * @flow
 * @relayHash da8b56c2b582d90a5db17c7e63b7581d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InfoSection_solicitation$ref = any;
type SolicitationCommentThread_solicitation$ref = any;
export type SolicitationDetailsTabQueryVariables = {|
  id: string
|};
export type SolicitationDetailsTabQueryResponse = {|
  +solicitation: ?{|
    +id: string,
    +$fragmentRefs: SolicitationCommentThread_solicitation$ref & InfoSection_solicitation$ref,
  |}
|};
export type SolicitationDetailsTabQuery = {|
  variables: SolicitationDetailsTabQueryVariables,
  response: SolicitationDetailsTabQueryResponse,
|};
*/


/*
query SolicitationDetailsTabQuery(
  $id: ID!
) {
  solicitation(id: $id) {
    id
    ...SolicitationCommentThread_solicitation
    ...InfoSection_solicitation
  }
}

fragment SolicitationCommentThread_solicitation on SolicitationNode {
  solicitationId
  state
  comments {
    edges {
      node {
        id
        content
        created
        contentEditedAt
        repliedTo {
          content
          created
          user {
            fullName
            id
          }
        }
        contractor {
          fullName
          representativeImageUrl
          freelancer {
            user {
              id
            }
            id
          }
          id
        }
        createdByStaff {
          fullName
          representativeImageUrl
          user {
            id
          }
          id
        }
      }
    }
  }
}

fragment InfoSection_solicitation on SolicitationNode {
  message
  dateSent
  replyByDate
  ...GreyBar_solicitation
}

fragment GreyBar_solicitation on SolicitationNode {
  staffCoordinator {
    fullName
    representativeImageUrl
    organization {
      representativeImageUrl
      name
      country {
        name
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationDetailsTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "SolicitationCommentThread_solicitation",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "InfoSection_solicitation",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationDetailsTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "solicitationId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comments",
            "storageKey": null,
            "args": null,
            "concreteType": "SolicitationContractorLogNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SolicitationContractorLogNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SolicitationContractorLogNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "contentEditedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "repliedTo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentRepliedToNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contractor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "freelancer",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FreelancerNode",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v2/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdByStaff",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dateSent",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "replyByDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffCoordinator",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgNode",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "country",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Country",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationDetailsTabQuery",
    "id": null,
    "text": "query SolicitationDetailsTabQuery(\n  $id: ID!\n) {\n  solicitation(id: $id) {\n    id\n    ...SolicitationCommentThread_solicitation\n    ...InfoSection_solicitation\n  }\n}\n\nfragment SolicitationCommentThread_solicitation on SolicitationNode {\n  solicitationId\n  state\n  comments {\n    edges {\n      node {\n        id\n        content\n        created\n        contentEditedAt\n        repliedTo {\n          content\n          created\n          user {\n            fullName\n            id\n          }\n        }\n        contractor {\n          fullName\n          representativeImageUrl\n          freelancer {\n            user {\n              id\n            }\n            id\n          }\n          id\n        }\n        createdByStaff {\n          fullName\n          representativeImageUrl\n          user {\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment InfoSection_solicitation on SolicitationNode {\n  message\n  dateSent\n  replyByDate\n  ...GreyBar_solicitation\n}\n\nfragment GreyBar_solicitation on SolicitationNode {\n  staffCoordinator {\n    fullName\n    representativeImageUrl\n    organization {\n      representativeImageUrl\n      name\n      country {\n        name\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2583c07dd2ad8bf630d75f22304cea8';
module.exports = node;

/**
 * @flow
 * @relayHash eac055064db6da82f69aa156f83c26e5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type AssignBatchDeliverableInternallyInput = {|
  ids: $ReadOnlyArray<?string>,
  staffId: string,
  clientMutationId?: ?string,
|};
export type AssignBatchDeliverableInternallyMutationVariables = {|
  input: AssignBatchDeliverableInternallyInput
|};
export type AssignBatchDeliverableInternallyMutationResponse = {|
  +assignBatchDeliverableInternally: ?{|
    +isUpdated: ?boolean,
    +updatedDeliverables: ?$ReadOnlyArray<?{|
      +state: DeliverableState,
      +assignedInternally: ?{|
        +id: string,
        +user: {|
          +id: string
        |},
        +fullName: ?string,
        +representativeImageUrl: ?string,
      |},
    |}>,
  |}
|};
export type AssignBatchDeliverableInternallyMutation = {|
  variables: AssignBatchDeliverableInternallyMutationVariables,
  response: AssignBatchDeliverableInternallyMutationResponse,
|};
*/


/*
mutation AssignBatchDeliverableInternallyMutation(
  $input: AssignBatchDeliverableInternallyInput!
) {
  assignBatchDeliverableInternally(input: $input) {
    isUpdated
    updatedDeliverables {
      state
      assignedInternally {
        id
        user {
          id
        }
        fullName
        representativeImageUrl
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AssignBatchDeliverableInternallyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isUpdated",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "assignedInternally",
  "storageKey": null,
  "args": null,
  "concreteType": "StaffNode",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AssignBatchDeliverableInternallyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "assignBatchDeliverableInternally",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignBatchDeliverableInternallyPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AssignBatchDeliverableInternallyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "assignBatchDeliverableInternally",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignBatchDeliverableInternallyPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AssignBatchDeliverableInternallyMutation",
    "id": null,
    "text": "mutation AssignBatchDeliverableInternallyMutation(\n  $input: AssignBatchDeliverableInternallyInput!\n) {\n  assignBatchDeliverableInternally(input: $input) {\n    isUpdated\n    updatedDeliverables {\n      state\n      assignedInternally {\n        id\n        user {\n          id\n        }\n        fullName\n        representativeImageUrl\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a607b07ee75285c51d1122b4a1e34ed5';
module.exports = node;

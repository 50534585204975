import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { DialogContentText } from '@material-ui/core';
import * as Yup from 'yup';
import Dialog, { DialogActions } from './Dialog';
import GlobalButton from '../UI/GlobalButton';
import FormikSearchableDropdown from '../form/FormikSearchableDropdown';

const ManageContractorGroupsDialog = ({ ids, onConfirm, activate, ...dialogProps }) => {
  const initialValues = {
    contractorGroup: null
  };

  const groupValidationSchema = Yup.object().shape({
    contractorGroup: Yup.string().required('Required').nullable()
  });

  return (
    <Dialog
      title={`${activate ? 'Add' : 'Remove'} Contractor ${activate ? 'to' : 'from'} Group`}
      minWidth={350}
      {...dialogProps}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={groupValidationSchema}
        onSubmit={onConfirm}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <DialogContentText variant="body1">
              {`${activate ? 'Add' : 'Remove'} Contractor ${
                activate ? 'to the' : 'from the'
              } selected group.  If the Contractor ${
                activate ? 'is already in' : 'is already not in'
              } the group, the record will not be modified.`}
            </DialogContentText>
            <Field
              required
              name="contractorGroup"
              label="Contractor Group"
              placeholder="Select group..."
              component={FormikSearchableDropdown}
            />

            <DialogActions>
              <GlobalButton
                variant="transparent"
                handleClick={dialogProps.closeDialog}
                disabled={isSubmitting}
              >
                Cancel
              </GlobalButton>
              <GlobalButton handleClick={submitForm} loading={isSubmitting} disabled={isSubmitting}>
                {activate ? 'Add' : 'Remove'}
              </GlobalButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

ManageContractorGroupsDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    })
  )
};

export default ManageContractorGroupsDialog;

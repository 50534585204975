import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const CreateBatchDeliverableMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateBatchDeliverableMutation($input: CreateBatchDeliverableInput!) {
        createBatchDeliverable(input: $input) {
          isCreated
          deliverables {
            totalCount
            edges {
              node {
                id
                release {
                  hasDeliverables
                  categoryInfo {
                    category {
                      id
                      name
                    }
                    contractedAmount
                    pendingAmount
                  }
                  deliverablesTotalAmount
                  deliverablesTotalOriginalAmount
                  deliverablesTotalPendingAmount
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.createBatchDeliverable, errors);
    },
    onError: err => console.error(err)
  });
};

CreateBatchDeliverableMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      release: PropTypes.string.isRequired,
      categoryType: PropTypes.string.isRequired,
      amount: PropTypes.number,
      quantity: PropTypes.number.isRequired,
      dueDate: PropTypes.string
    })
  )
};

export default CreateBatchDeliverableMutation;

/**
 * @flow
 * @relayHash c0844af3313fff492ec8786cbb771432
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAccountInput = {|
  contractorToken: string,
  uidb64: string,
  password: string,
  clientMutationId?: ?string,
|};
export type CreateAccountMutationVariables = {|
  createAccountInput: CreateAccountInput
|};
export type CreateAccountMutationResponse = {|
  +createAccount: ?{|
    +newUser: ?{|
      +email: string
    |}
  |}
|};
export type CreateAccountMutation = {|
  variables: CreateAccountMutationVariables,
  response: CreateAccountMutationResponse,
|};
*/


/*
mutation CreateAccountMutation(
  $createAccountInput: CreateAccountInput!
) {
  createAccount(input: $createAccountInput) {
    newUser {
      email
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "createAccountInput",
    "type": "CreateAccountInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "createAccountInput"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createAccount",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAccountPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "newUser",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateAccountMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createAccount",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAccountPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "newUser",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateAccountMutation",
    "id": null,
    "text": "mutation CreateAccountMutation(\n  $createAccountInput: CreateAccountInput!\n) {\n  createAccount(input: $createAccountInput) {\n    newUser {\n      email\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '92eac6903c449457975fb494dd30494c';
module.exports = node;

import React, { Fragment, useState, useEffect, useMemo } from 'react';
import graphql from 'babel-plugin-relay/macro';
import * as Yup from 'yup';
import { createFragmentContainer } from 'react-relay';
import { Grid, Box, Divider, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import { cloneDeep, isEqual, unionWith, has } from 'lodash';
import { Field, Form, Formik } from 'formik';

import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import { CELL_TYPES } from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import AmountTrend from '../../../../../shared/components/UI/AmountTrend';
import Detail from '../../../../../shared/components/UI/Detail';
import Date from '../../../../../shared/components/common/Date';

import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import TableCellAmount from '../../../../../shared/components/table/TableCellAmount';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import StyledTableCell from '../../../../../shared/components/table/StyledTableCell';
import FormikAmountField from '../../../../../shared/components/form/formikFields/FormikAmountField';
import UpdateReleaseBudgetMutation from '../../mutations/UpdateReleaseBudgetMutation';
import { formatAmount, round2 } from '../../../../../shared/utils/formatters';
import BudgetAddCategoryForm from './BudgetAddCategoryForm';
import { TableCellWithBadge, ColorBadge } from './styled';
import { getCurrencyCodeFromUserContext } from '../../../../../shared/utils/helpers';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import BudgetQuickView from './BudgetQuickView';
import DoughnutChart from '../../../../../shared/components/charts/DoughnutChart';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import { computePermissionRole } from '../../../../../shared/utils/helpers';

const CHART_COLORS = [
  '#574368',
  '#8488D3',
  '#FF8E65',
  '#F8C868',
  '#D1B3FF',
  '#69CFEF',
  '#8DDB34',
  '#CFD3C1'
];

const Budget = props => {
  const {
    release,
    theme: {
      palette: { colors }
    },
    userContext,
    setEditing,
    isEditing: editing
  } = props;

  const {
    id,
    deliverablesTotalAmount,
    exchangeRateDate,
    budgetInfo,
    categoryInfo,
    deliverablesTotalOriginalAmount
  } = release;

  const [budgetState, setBudgetState] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    setBudgetState(getFormattedBudgetData());
  }, [release]);

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const getFormattedBudgetData = () => {
    return unionWith(budgetInfo, categoryInfo, (arr, otherArr) =>
      isEqual(arr.category, otherArr.category)
    ).map(value => {
      const { category, ...otherValues } = value;

      let element = { ...otherValues };

      if (category) {
        element = {
          ...element,
          id: category.id,
          name: category.name
        };
      } else {
        element = {
          ...element,
          id: 'otherCategory',
          name: 'Other Categories'
        };
      }

      if (has(element, 'budgetAmount')) {
        element = {
          ...element,
          budgetAmount: round2(element.budgetAmount)
        };
      } else {
        element = {
          ...element,
          budgetAmount: null
        };
      }

      if (!has(element, 'contractedAmount')) {
        const foundCategory = categoryInfo.find(categoryValue =>
          isEqual(categoryValue.category, category)
        );

        element = foundCategory
          ? {
              ...element,
              contractedAmount: round2(foundCategory.contractedAmount),
              pendingAmount: round2(foundCategory.pendingAmount),
              originalPendingAmount: round2(foundCategory.originalPendingAmount),
              originalContractedAmount: round2(foundCategory.originalContractedAmount),
              hasFxDeliverables: foundCategory.hasFxDeliverables
            }
          : {
              ...element,
              contractedAmount: 0,
              pendingAmount: 0
            };
      } else {
        element.contractedAmount = round2(element.contractedAmount);
        element.pendingAmount = round2(element.pendingAmount);
        element.originalPendingAmount = round2(element.originalPendingAmount);
        element.originalContractedAmount = round2(element.originalContractedAmount);
      }

      return element;
    });
  };

  const submitHandler = ({ budget }) => {
    const budgetInfo = budget.map(({ categoryId, budgetAmount }) => ({
      categoryId,
      budgetAmount
    }));

    UpdateReleaseBudgetMutation(id, budgetInfo, ({ updateReleaseBudget }) => {
      if (updateReleaseBudget && updateReleaseBudget.updatedRelease) {
        setEditing(false);
      }
    });
  };

  const addCategoryHandler = ({ category, amount }, bag) => {
    const stateCopy = cloneDeep(budgetState);
    const addedCategory = stateCopy.find(value => value.id === category.value);
    const formattedAmount = round2(amount);

    if (addedCategory) {
      const redactedFromCategory = stateCopy.find(value => value.id === 'otherCategory');
      redactedFromCategory.contractedAmount = round2(
        redactedFromCategory.contractedAmount - addedCategory.contractedAmount
      );
      redactedFromCategory.pendingAmount = round2(
        redactedFromCategory.pendingAmount - addedCategory.pendingAmount
      );
      redactedFromCategory.budgetAmount = round2(redactedFromCategory.budgetAmount - amount);
      addedCategory.budgetAmount = formattedAmount;
    } else {
      stateCopy.unshift({
        id: category.value,
        name: category.label,
        contractedAmount: 0,
        pendingAmount: 0,
        budgetAmount: formattedAmount
      });
    }

    bag.resetForm();
    setBudgetState(stateCopy);
  };

  const removeCategoryClickHandler = id => {
    const stateCopy = cloneDeep(budgetState);
    const removedCategory = stateCopy.find(el => el.id === id);
    const otherCategory = stateCopy.find(el => el.id === 'otherCategory');

    otherCategory.contractedAmount = round2(
      otherCategory.contractedAmount + removedCategory.contractedAmount
    );
    otherCategory.pendingAmount = round2(
      otherCategory.pendingAmount + removedCategory.pendingAmount
    );
    otherCategory.budgetAmount += round2(
      removedCategory.contractedAmount + removedCategory.pendingAmount
    );
    removedCategory.budgetAmount = null;

    setBudgetState(stateCopy);
  };

  const onCancelClickHandler = () => {
    setEditing(false);
    setBudgetState(getFormattedBudgetData());
  };

  const edges = useMemo(() => {
    const stateCopy = cloneDeep(budgetState);
    const otherCategoryIndex = stateCopy.findIndex(el => el.id === 'otherCategory');
    const sortedStateCopy = stateCopy.concat(stateCopy.splice(otherCategoryIndex, 1));

    const mappedEdges = sortedStateCopy
      .filter(value => value.budgetAmount !== null)
      .map((value, index, array) => {
        const color = array.length > CHART_COLORS.length ? null : CHART_COLORS[index];

        return {
          node: {
            ...value,
            contractedAmount: {
              amount: value.contractedAmount,
              ...(value.hasFxDeliverables && {
                trend: round2(value.originalContractedAmount - value.contractedAmount)
              })
            },
            pendingAmount: {
              amount: value.pendingAmount,
              ...(value.hasFxDeliverables && {
                trend: round2(value.originalPendingAmount - value.pendingAmount)
              })
            },
            name: { name: value.name, color },
            hideTrash: value.id === 'otherCategory',
            availableAmount: round2(
              value.budgetAmount - round2(value.contractedAmount + value.pendingAmount)
            )
          }
        };
      });

    return mappedEdges;
  }, [budgetState]);

  const initialValues = useMemo(
    () => ({
      budget: edges.map(({ node }) => ({
        categoryId: node.id === 'otherCategory' ? null : node.id,
        budgetAmount: node.budgetAmount,
        minBudget: round2(node.contractedAmount.amount + node.pendingAmount.amount)
      }))
    }),
    [edges]
  );

  const budgetArrayValidationSchema = Yup.object().shape({
    budget: Yup.array().of(
      Yup.object().shape({
        budgetAmount: Yup.number()
          .transform(cv => (isNaN(cv) ? undefined : cv))
          .required('Required')
          .when('minBudget', (minBudget, schema) =>
            schema.min(
              minBudget,
              `Budget must be greater than or equal to ${formatAmount(
                minBudget,
                getCurrencyCodeFromUserContext(userContext)
              )}`
            )
          )
      })
    )
  });

  const renderBudgetCell = ({ index, rowIndex, value }) => {
    return editing ? (
      <StyledTableCell key={index} align="right">
        <Field name={`budget.${rowIndex}.budgetAmount`} component={FormikAmountField} fullWidth />
      </StyledTableCell>
    ) : (
      <TableCellAmount key={index} amount={value} />
    );
  };

  const renderNameCell = ({ index, value }) => (
    <TableCellWithBadge key={index} color={value.color} name={value.name} />
  );

  const totalValues = useMemo(() => {
    const totals = edges.reduce(
      (prev, value) => {
        prev.contracted += value.node.contractedAmount.amount;
        prev.pending += value.node.pendingAmount.amount;
        prev.budget += value.node.budgetAmount;
        return prev;
      },
      {
        contracted: 0,
        pending: 0,
        budget: 0
      }
    );
    totals.available = round2(totals.budget - round2(totals.contracted + totals.pending));
    return totals;
  }, [edges]);

  const byCategoryData = edges.reduce(
    (prev, value) => {
      prev.data.push(value.node.budgetAmount);
      prev.labels.push(value.node.name.name);
      prev.backgroundColor.push(value.node.name.color);
      return prev;
    },
    {
      data: [],
      backgroundColor: [],
      labels: []
    }
  );

  const { labels, ...otherCategoryData } = byCategoryData;

  const totalAvailableValue = totalValues.available < 0 ? 0 : totalValues.available;

  const netChange = round2(
    round2(deliverablesTotalOriginalAmount) - round2(deliverablesTotalAmount)
  );

  const dougnutChartOptions = {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: ({ index }, { datasets, labels }) => ({
          label: labels[index],
          value: formatAmount(datasets[0].data[index], getCurrencyCodeFromUserContext(userContext))
        })
      }
    }
  };

  return (
    <Fragment>
      <Formik
        onSubmit={submitHandler}
        initialValues={initialValues}
        validationSchema={budgetArrayValidationSchema}
        enableReinitialize
      >
        <Form>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DetailsSection
                  title="Budget By Status"
                  noBorder
                  contentBoxProps={{ display: 'flex' }}
                >
                  <Box ml={4}>
                    <DoughnutChart
                      height={200}
                      width={200}
                      data={{
                        datasets: [
                          {
                            data: [
                              totalValues.contracted,
                              totalValues.pending,
                              totalAvailableValue
                            ],
                            backgroundColor: [colors.red, colors.orange, colors.green],
                            borderWidth: 0
                          }
                        ],
                        labels: ['Contracted Amount', 'Pending Amount', 'Available Amount']
                      }}
                      options={dougnutChartOptions}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    ml={6}
                    width="350px"
                  >
                    <Detail
                      name="Total Contracted Amount"
                      value={formatAmount(
                        totalValues.contracted,
                        getCurrencyCodeFromUserContext(userContext)
                      )}
                      badgeColor={colors.red}
                      justifyContent="space-between"
                    />
                    <Detail
                      name="Total Pending Amount"
                      value={formatAmount(
                        totalValues.pending,
                        getCurrencyCodeFromUserContext(userContext)
                      )}
                      badgeColor={colors.orange}
                      justifyContent="space-between"
                    />
                    <Detail
                      name="Total Available Amount"
                      value={formatAmount(
                        totalValues.available,
                        getCurrencyCodeFromUserContext(userContext)
                      )}
                      badgeColor={colors.green}
                      justifyContent="space-between"
                    />
                    <Detail
                      name="Total Budgeted Amount"
                      value={formatAmount(
                        totalValues.budget,
                        getCurrencyCodeFromUserContext(userContext)
                      )}
                      badgeColor="transparent"
                      justifyContent="space-between"
                    />
                  </Box>
                </DetailsSection>
              </Grid>
              <Grid item xs={6}>
                <DetailsSection
                  title="Budget By Category"
                  noBorder
                  contentBoxProps={{ display: 'flex' }}
                  renderRight={() => (
                    <EditToggle
                      isEditing={editing}
                      editProps={{
                        id: 'enterReleaseBudgetEdit',
                        onClick: () => {
                          setEditing(true);
                        },
                        disabled: !computePermissionRole(
                          ALLOWED_ACTIONS.RELEASE_EDIT_BUDGET,
                          userContext.orgStaff.allowedActions
                        )
                      }}
                      saveProps={{
                        id: 'saveReleaseBudgetEdit'
                      }}
                      cancelProps={{
                        id: 'cancelReleaseBudgetEdit',
                        onClick: onCancelClickHandler
                      }}
                    />
                  )}
                >
                  <Box ml={4}>
                    <DoughnutChart
                      height={200}
                      width={200}
                      data={{
                        datasets: [{ ...otherCategoryData, borderWidth: 0 }],
                        labels: labels,
                        borderWidth: 0
                      }}
                      options={dougnutChartOptions}
                    />
                  </Box>
                  <Box width="100%" ml={9} mt={4}>
                    <Grid container spacing={2}>
                      {edges.map(({ node }) => (
                        <Grid item xs={6} key={node.id}>
                          <Box display="flex" alignItems="center">
                            <ColorBadge color={node.name.color} />
                            <Typography variant="subtitle2">{node.name.name}</Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </DetailsSection>
              </Grid>
            </Grid>
          </Box>
          <Divider orientation="horizontal" />
          <Box mt={2}>
            <DetailsSection title="Budget" noBorder>
              <Box display="flex" mt={1} mb={1}>
                <Box mr={2}>
                  <Detail name="Exchange Rate Date" value={<Date date={exchangeRateDate} />} />
                </Box>
                <Detail
                  name="Net Exchange Rate Effect"
                  renderValue={() => <AmountTrend value={netChange} />}
                />
              </Box>
              <AutoTable
                rowTemplate={[
                  {
                    name: '',
                    label: '',
                    type: CELL_TYPES.info,
                    onClick: onQuickViewClickHandler,
                    hidden: editing
                  },
                  { name: 'name', label: 'Category', renderCell: renderNameCell },
                  {
                    name: 'contractedAmount',
                    label: 'Contracted Amount',
                    type: CELL_TYPES.amount,
                    badgeColor: colors.red,
                    hideTrend: editing,
                    width: '15%'
                  },
                  {
                    name: 'pendingAmount',
                    label: 'Pending Amount',
                    type: CELL_TYPES.amount,
                    badgeColor: colors.orange,
                    hideTrend: editing,
                    width: '15%'
                  },
                  {
                    name: 'availableAmount',
                    label: 'Available Amount',
                    type: CELL_TYPES.amount,
                    badgeColor: colors.green,
                    width: '15%'
                  },
                  {
                    name: 'budgetAmount',
                    label: 'Budgeted Amount',
                    // even though here renderCell is responsible for the cell rendering,
                    // the type is still necessary to properly align column header
                    type: CELL_TYPES.amount,
                    renderCell: renderBudgetCell,
                    width: '15%'
                  },
                  {
                    name: 'trash',
                    label: '',
                    type: CELL_TYPES.trash,
                    onClick: removeCategoryClickHandler,
                    hidden: !editing,
                    tooltip: 'Delete'
                  }
                ]}
                edges={edges}
              />
            </DetailsSection>
          </Box>
        </Form>
      </Formik>
      {editing && <BudgetAddCategoryForm onSubmit={addCategoryHandler} budgetState={budgetState} />}
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId} width={800}>
        <BudgetQuickView
          releaseId={id}
          selectedId={selectedId}
          exchangeRateDate={exchangeRateDate}
        />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withUserContext(
  createFragmentContainer(withTheme(Budget), {
    release: graphql`
      fragment Budget_release on ReleaseNode {
        id
        budgetInfo {
          category {
            id
            name
          }
          budgetAmount
        }
        categoryInfo {
          category {
            id
            name
          }
          pendingAmount
          contractedAmount
          originalPendingAmount
          originalContractedAmount
          hasFxDeliverables
        }
        exchangeRateDate
        deliverablesTotalAmount
        deliverablesTotalOriginalAmount
      }
    `
  })
);

import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import formStyles from '../../styles/common/formStyles';

const FormikRadioGroupField = props => {
  const { field, options, onChangeHandler, form, label, noMarginRight, className } = props;
  const classes = formStyles({ noMarginRight });

  const mapOptions = () =>
    options.map(option => (
      <FormControlLabel
        key={option.name}
        checked={option.value === field.value}
        value={option.value}
        control={<Radio color="primary" />}
        label={option.name}
      />
    ));
  return (
    <FormControl component="fieldset" className={`${classes.radioButtonGroupWrapper} ${className}`}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        className={classes.radioButtonGroup}
        aria-label={field.name}
        name={field.name}
        value={field.value}
        onChange={e => onChangeHandler(e, form, field)}
        row
      >
        {mapOptions()}
      </RadioGroup>
    </FormControl>
  );
};
export default FormikRadioGroupField;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation CreateRoleMutation($input: CreateOrganizationRoleInput!) {
    createOrganizationRole(input: $input) {
      newOrganizationRole {
        id
      }
    }
  }
`;

const CreateRoleMutation = (id, name, callback) => {
  const config = {
    mutation,
    variables: { input: { id, name } },
    onCompleted: (response, errors) => {
      callback(response.createOrganizationRole, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default CreateRoleMutation;

import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import styled from 'styled-components';
import { createFragmentContainer } from 'react-relay';
import GlobalButton from '../../../../../UI/GlobalButton';
import { DialogActions } from '../../../../../common/Dialog';
import ReferenceFilePreview from '../../../../../richText/components/ReferenceFilePreview';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 10px;

  > * {
    cursor: pointer;
    margin-right: 0;
  }
`;

const SelectExistingTab = props => {
  const {
    deliverable: { references },
    onSelect,
    onCancel
  } = props;
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <Container>
        {references.edges.map(({ node }) => {
          return (
            <ReferenceFilePreview
              width={250}
              key={node.id}
              src={node.fileUrl}
              title={node.title}
              reviewInterface={node.reviewInterface}
              active={selectedItem && selectedItem.id === node.id}
              onClick={() => {
                setSelectedItem(node);
              }}
            />
          );
        })}
      </Container>
      <DialogActions>
        <GlobalButton id="cancelCreateAssetForm" variant="transparent" handleClick={onCancel}>
          Cancel
        </GlobalButton>
        <GlobalButton
          id="saveAsset"
          handleClick={() => onSelect(selectedItem)}
          disabled={selectedItem === null}
        >
          Select
        </GlobalButton>
      </DialogActions>
    </>
  );
};

export default createFragmentContainer(SelectExistingTab, {
  deliverable: graphql`
    fragment SelectExistingTab_deliverable on DeliverableNode {
      id
      references(orderBy: $orderBy) {
        edges {
          node {
            id
            title
            fileUrl
            reviewInterface
          }
        }
      }
    }
  `
});

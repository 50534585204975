import Typography from '@material-ui/core/Typography';
import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { CELL_TYPES } from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import {
  formatAuditLogDescription,
  formatAuditLogAction
} from '../../../../../shared/utils/helpers';

import useUpdateEffect from '../../../../../shared/hooks/useUpdateEffect';

const ProductActionLogList = props => {
  const {
    relay: { refetch },
    product: { id, history: historyLog }
  } = props;

  const [refetchCounter, setRefetchCounter] = useState(0);

  useUpdateEffect(() => {
    // we want tools tab to refetch the logs if new release is created (because it could be created for current product)
    setRefetchCounter(prev => prev + 1);
  }, [props.refetchCounter, props.refetchCounterForTools]);

  if (!historyLog) return <div>Something went wrong!</div>;

  const {
    edges,
    edgeCount,
    totalCount,
    pageInfo: { hasNextPage, endCursor }
  } = historyLog;

  const onChangeHandler = variables => {
    refetch({ id, ...variables });
  };

  const formattedEdges = edges.map(edge => {
    const {
      id,
      timestamp: date,
      actor: {
        orgStaff: { fullName, representativeImageUrl }
      },
      action,
      changes,
      additionalData
    } = edge.node;
    return {
      node: {
        id,
        date,
        who: { name: fullName, imageUrl: representativeImageUrl },
        action: formatAuditLogAction(action, additionalData),
        changes: formatAuditLogDescription(action, changes, additionalData)
      }
    };
  });

  return (
    <Fragment>
      <Typography variant="h3">Audit Log</Typography>
      <AutoTable
        rowTemplate={[
          { name: 'action', label: 'Action' },
          { name: 'changes', label: 'Changes', width: '50%' },
          { name: 'date', label: 'Date', type: CELL_TYPES.date },
          { name: 'who', label: 'User', type: CELL_TYPES.avatar }
          
        ]}
        edges={formattedEdges}
        refetchCounter={refetchCounter}
        onChangeHandler={onChangeHandler}
        paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
      />
    </Fragment>
  );
};

export default withRouter(
  createRefetchContainer(
    ProductActionLogList,
    {
      product: graphql`
        fragment ProductActionLogList_product on ProductNode {
          id
          history(first: $first, after: $after) {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                timestamp
                actor {
                  orgStaff {
                    fullName
                    representativeImageUrl
                  }
                }
                action
                changes
                additionalData
              }
            }
          }
        }
      `
    },
    graphql`
      query ProductActionLogListRefetchQuery($id: ID!, $first: Int, $after: String) {
        product(id: $id) {
          ...ProductActionLogList_product
        }
      }
    `
  )
);

import React from 'react';
import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import StaffTab from './StaffTab';
import RolesTab from './RolesTab';
import SamlTab from './SamlTab';

const StaffManagementPage = props => {
  return (
    <AutoTabList>
      <StaffTab label="Users" {...props} />
      <RolesTab label="Roles" {...props} />
      <SamlTab label="SAML" {...props} />
    </AutoTabList>
  );
};

export default StaffManagementPage;

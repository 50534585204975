import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Formik, Form, Field } from 'formik';

import { Grid, InputAdornment } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import FormikTextField from '../../../../shared/components/form/FormikTextField';
import SaveButton from '../../../../shared/components/UI/SaveButton';
import EditButton from '../../../../shared/components/UI/EditButton';
import CancelButton from '../../../../shared/components/UI/CancelButton';
import UpdateOrganizationConfigurationMutation from './mutations/UpdateOrganizationConfigurationMutation';

const FileSizeLimits = props => {
  const { organization } = props;
  const [isEditing, setEditing] = useState(false);

  const classes = useDetailsStyles({ isEditing });

  if (!organization) {
    return <div>Something went wrong!</div>;
  }
  const reviewInterfaces = JSON.parse(organization.configuration).review_interface_file_size;
  const finalFiles = JSON.parse(organization.configuration).final_file_size;
  const miscFiles = JSON.parse(organization.configuration).misc_file_size;

  const toggleEditHandler = resetForm => {
    // when user clicks Cancel, we should reset form to intial values, regardless of any changes user has made
    if (typeof resetForm === 'function') {
      resetForm();
    }
    setEditing(!isEditing);
  };

  const submitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    const updatedReviewInterfaces = Object.entries(values).reduce(
      (allInterfaces, [fieldName, fieldValue]) => {
        if (fieldName !== 'final_files' && fieldName !== 'misc_files') {
          const interfaceType = fieldName.split('-')[1];
          allInterfaces[interfaceType] = Number(fieldValue);
        }
        return allInterfaces;
      },
      {}
    );
    UpdateOrganizationConfigurationMutation(
      {
        fileSizeLimits: JSON.stringify({
          review_interfaces: updatedReviewInterfaces,
          final_files: values.final_files,
          misc_files: values.misc_files
        })
      },
      response => {
        setSubmitting(false);
        if (response && response.isUpdated) {
          toggleEditHandler();
        }
      }
    );
  };

  const mapInterfacesLayout = () => {
    return Object.keys(reviewInterfaces)
      .sort()
      .map(key => {
        return (
          <div className={classes.fileSizeContainer} key={key}>
            <Grid container item xs={5} spacing={4}>
              <Grid item xs={6}>
                {isEditing ? (
                  <Field
                    component={FormikTextField}
                    name={`asset-${key}`}
                    placeholder={reviewInterfaces[key].label}
                    label={reviewInterfaces[key].label}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">MB</InputAdornment>
                    }}
                  />
                ) : (
                  <Fragment>
                    <Typography variant="body1">{reviewInterfaces[key].label}</Typography>
                    <Typography variant="body2" className={classes.marginTop}>
                      {reviewInterfaces[key].asset} MB
                    </Typography>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </div>
        );
      });
  };

  const mapInitialValues = () => {
    const reviewInterfaceValues = Object.entries(reviewInterfaces).reduce(
      (initialValues, [key, value]) => {
        initialValues['asset-' + key] = value.asset;
        return initialValues;
      },
      {}
    );
    return { ...reviewInterfaceValues, final_files: finalFiles, misc_files: miscFiles };
  };

  const mapValidationSchema = () => {
    const validationObject = Object.keys(mapInitialValues()).reduce((schema, fieldName) => {
      schema[fieldName] = Yup.number().min(1, 'Must be more than zero').required('Required');
      return schema;
    }, {});
    return Yup.object().shape(validationObject);
  };

  return (
    <Formik
      initialValues={mapInitialValues()}
      enableReinitialize
      onSubmit={submitHandler}
      validationSchema={mapValidationSchema()}
    >
      {({ submitForm, resetForm, isSubmitting }) => (
        <Form>
          {isEditing && (
            <div className={classes.buttonRowRight}>
              <CancelButton
                id="cancelFileSize"
                onClick={() => toggleEditHandler(resetForm)}
                disabled={isSubmitting}
              />
              <SaveButton id="saveFileSize" disabled={isSubmitting} />
            </div>
          )}
          {!isEditing && (
            <div className={classes.buttonRowRight}>
              <EditButton id="editFileSize" onClick={toggleEditHandler} />
            </div>
          )}

          <div className={classes.detailsContentNoTabs}>
            <Fragment>
              <Typography variant="h3" className={classes.marginBottom}>
                Review Asset Maximum
              </Typography>
              {mapInterfacesLayout()}
              <div className={classes.borderMarginTop}></div>
              <Typography variant="h3" className={classes.marginBottom}>
                Final File Maximum
              </Typography>
              <div className={classes.fileSizeContainer}>
                <Grid container item xs={5} spacing={4}>
                  <Grid item xs={6}>
                    {isEditing ? (
                      <Field
                        component={FormikTextField}
                        name="final_files"
                        placeholder="Final File Maximum"
                        label=""
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="end">MB</InputAdornment>
                        }}
                      />
                    ) : (
                      <Typography variant="body2">{finalFiles} MB</Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
              <div className={classes.borderMarginTop}></div>
              <Typography variant="h3" className={classes.marginBottom}>
                Misc. File Size Maximum
              </Typography>
              <div className={classes.fileSizeContainer}>
                <Grid container item xs={5} spacing={4}>
                  <Grid item xs={6}>
                    {isEditing ? (
                      <Field
                        component={FormikTextField}
                        name="misc_files"
                        placeholder="Misc. File Maximum"
                        label=""
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="end">MB</InputAdornment>
                        }}
                      />
                    ) : (
                      <Typography variant="body2">{miscFiles} MB</Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default createFragmentContainer(FileSizeLimits, {
  organization: graphql`
    fragment FileSizeLimits_organization on OrganizationNode {
      id
      configuration
    }
  `
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractsList_jobs$ref: FragmentReference;
declare export opaque type ContractsList_jobs$fragmentType: ContractsList_jobs$ref;
export type ContractsList_jobs = {|
  +allJobs: ?{|
    +totalCount: ?number,
    +edgeCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
      +startCursor: ?string,
      +endCursor: ?string,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +jobId: ?number,
        +name: string,
        +staffCoordinator: {|
          +fullName: ?string,
          +representativeImageUrl: ?string,
          +organization: {|
            +name: string,
            +representativeImageUrl: ?string,
            +currencyCode: string,
          |},
        |},
        +jobDeadline: ?any,
        +totalValue: number,
        +contractDate: any,
        +status: JobStatus,
        +currencyCode: ?string,
        +deliverables: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
        +contract: ?{|
          +id: string,
          +contractFileUrl: ?string,
          +job: {|
            +status: JobStatus
          |},
        |},
      |}
    |}>,
  |},
  +$refType: ContractsList_jobs$ref,
|};
export type ContractsList_jobs$data = ContractsList_jobs;
export type ContractsList_jobs$key = {
  +$data?: ContractsList_jobs$data,
  +$fragmentRefs: ContractsList_jobs$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyCode",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ContractsList_jobs",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "after",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orderBy",
      "type": "String",
      "defaultValue": "-contractDate"
    },
    {
      "kind": "LocalArgument",
      "name": "status",
      "type": "String",
      "defaultValue": ""
    },
    {
      "kind": "LocalArgument",
      "name": "organization",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orgSequenceId",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "staffId",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "contractDateFrom",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "contractDateTo",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "jobDeadlineFrom",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "jobDeadlineTo",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "name",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "allJobs",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "contractDateFrom",
          "variableName": "contractDateFrom"
        },
        {
          "kind": "Variable",
          "name": "contractDateTo",
          "variableName": "contractDateTo"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "jobDeadlineFrom",
          "variableName": "jobDeadlineFrom"
        },
        {
          "kind": "Variable",
          "name": "jobDeadlineTo",
          "variableName": "jobDeadlineTo"
        },
        {
          "kind": "Variable",
          "name": "jobName",
          "variableName": "name"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "orgSequenceId",
          "variableName": "orgSequenceId"
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Variable",
          "name": "staffId",
          "variableName": "staffId"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "status"
        }
      ],
      "concreteType": "JobNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasPreviousPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "JobNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jobId",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "staffCoordinator",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "organization",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "OrgNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jobDeadline",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "totalValue",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "contractDate",
                  "args": null,
                  "storageKey": null
                },
                (v4/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "deliverables",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "DeliverableNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "DeliverableNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "DeliverableNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contract",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "contractFileUrl",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "job",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "JobNode",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dbcd47126f7508eee83fcca5288a0d7';
module.exports = node;

import React from 'react';

import { ReactComponent as EditIcon } from '../../../shared/images/edit.svg';
import StyledTableCell from './StyledTableCell';

import { useTableStyles } from '../../styles/common/tableStyles';

const TableCellEdit = props => {
  const { id, handleClick } = props;
  const classes = useTableStyles();
  const onClickHandler = () => {
    handleClick(id);
  };
  return (
    <StyledTableCell noWrap>
      <div className={classes.verticalCenter}>
        <EditIcon onClick={onClickHandler} className={classes.infoIcon} />
      </div>
    </StyledTableCell>
  );
};

export default TableCellEdit;

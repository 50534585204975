import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import SolicitationDetails from './SolicitationDetails';

const SolicitationDetailsPage = props => (
  <QueryRenderer
    query={graphql`
      query SolicitationDetailsPageQuery($solicitationId: ID!) {
        solicitation(id: $solicitationId) {
          ...SolicitationDetails_solicitation
        }
      }
    `}
    variables={{
      solicitationId: props.match.params.id
    }}
    render={relayProps => <SolicitationDetails solicitation={relayProps.solicitation} {...props} />}
  />
);

export default SolicitationDetailsPage;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReleaseList_releases$ref: FragmentReference;
declare export opaque type ReleaseList_releases$fragmentType: ReleaseList_releases$ref;
export type ReleaseList_releases = {|
  +releasesByOrg: ?{|
    +totalCount: ?number,
    +edgeCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +code: string,
        +deadline: ?any,
        +product: {|
          +code: ?string,
          +title: string,
          +id: string,
        |},
        +totalBudget: ?any,
        +deliverablesTotalAmount: ?any,
        +hasIncompleteDeliverables: ?boolean,
      |}
    |}>,
  |},
  +$refType: ReleaseList_releases$ref,
|};
export type ReleaseList_releases$data = ReleaseList_releases;
export type ReleaseList_releases$key = {
  +$data?: ReleaseList_releases$data,
  +$fragmentRefs: ReleaseList_releases$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ReleaseList_releases",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "releaseName",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "releaseCode",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "productName",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "productCode",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "deadlineFrom",
      "type": "Date"
    },
    {
      "kind": "RootArgument",
      "name": "deadlineTo",
      "type": "Date"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "includeInactive",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "releasesByOrg",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "deadlineFrom",
          "variableName": "deadlineFrom"
        },
        {
          "kind": "Variable",
          "name": "deadlineTo",
          "variableName": "deadlineTo"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "includeInactive",
          "variableName": "includeInactive"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "productCode",
          "variableName": "productCode"
        },
        {
          "kind": "Variable",
          "name": "productName",
          "variableName": "productName"
        },
        {
          "kind": "Variable",
          "name": "releaseCode",
          "variableName": "releaseCode"
        },
        {
          "kind": "Variable",
          "name": "releaseName",
          "variableName": "releaseName"
        }
      ],
      "concreteType": "ReleaseNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ReleaseNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ReleaseNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "deadline",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "product",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ProductNode",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "title",
                      "args": null,
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "totalBudget",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "deliverablesTotalAmount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hasIncompleteDeliverables",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c140923f8bbfac45bad350cdf7a04c3b';
module.exports = node;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../shared/styles/common/colors';

const useStyles = makeStyles(theme => ({
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    color: colors.darkBlueGrey,
    fontSize: '14px'
  }
}));
const TopSubtitle = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.subtitle}>{children}</span>;
};

export default TopSubtitle;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';
import InvoicesList from './InvoicesList';

const InvoicesPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query InvoicesPageQuery(
          $after: String
          $first: Int
          $invoiceId: String
          $jobName: String
          $status: String
          $contact: String
        ) {
          ...InvoicesList_invoices
            @arguments(
              after: $after
              first: $first
              invoiceId: $invoiceId
              jobName: $jobName
              status: $status
              contact: $contact
            )
          __type(name: "JobInvoiceStatus") {
            ...InvoicesList_statusTypes
          }
        }
      `}
      variables={variables}
      render={relayProps => (
        <InvoicesList invoices={relayProps} statusTypes={relayProps.__type} {...props} />
      )}
    />
  );
};

export default withTableHistoryProvider(InvoicesPage, 'freInvoices', {
  initialFilters: {
    invoiceId: '',
    jobName: '',
    status: '',
    contact: null
  }
});

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

export default (email, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation ForgotPasswordMutation($input: ForgotPasswordInput!) {
        forgotPassword(input: $input) {
          message
          clientMutationId
        }
      }
    `,
    variables: {
      input: {
        email,
        clientMutationId: ''
      }
    },
    onCompleted: response => {
      callback(response.forgotPassword);
    },
    onError: err => console.error(err)
  });
};

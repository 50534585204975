import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import StyledButtonGroup from './buttonGroup/StyledButtonGroup';
import GlobalButton from './GlobalButton';
import GroupButtonSquare from './buttonGroup/GroupButtonSquare';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block'
  },
  button: {
    margin: '2px',
    textTransform: 'none',
    border: 'none',
    color: '#FFF',
    '& span': {
      fontSize: '12px',
      fontWeight: 'bold'
    },
    '&.Mui-disabled': {
      border: 'none'
    }
  },
  popper: {
    zIndex: '2'
  }
}));

const SplitButton = ({
  options,
  label,
  variant,
  handleMenuItemClick,
  disabledItem,
  disableAll,
  disabledButton,
  id,
  loading
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  function handleToggle() {
    if (!disabledButton) {
      setOpen(prevOpen => !prevOpen);
    }
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  function handleItemClick(event, index) {
    handleMenuItemClick(index);
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <StyledButtonGroup ref={anchorRef} aria-label="split button">
        <GlobalButton
          id={`${id}-main`}
          variant={variant}
          handleClick={handleToggle}
          disabled={disabledButton}
          loading={loading}
          noMargin
          style={{ minWidth: '140px' }}
        >
          {label}
        </GlobalButton>
        <GroupButtonSquare
          id={`${id}-open-menu`}
          onClick={handleToggle}
          variant={variant}
          icon="arrowDropdown"
          size={34}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          disabled={disabledButton}
        />
      </StyledButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center bottom'
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      id={`${id}-menu-item-${index + 1}`}
                      key={option}
                      disabled={disableAll || index === disabledItem}
                      selected={false}
                      onClick={event => handleItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default SplitButton;

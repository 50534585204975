import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';

const DetailsMainContent = props => {
  const { id, category, categoryType, job } = props.deliverable;

  return (
    <DetailsSection noBorder title="Details">
      <div key={id}>
        <DetailsRow titleWidth={150} noMarginTop title="Category" content={category.name} />
        <DetailsRow
          titleWidth={150}
          title="Type"
          content={categoryType && categoryType.name ? categoryType.name : '-'}
        />
        <DetailsRowDate
          titleWidth={150}
          title="Start date"
          date={job && job.contract && job.contract.dateSignedByContractor}
        />
        <DetailsRowDate titleWidth={150} title="Job Completion Deadline" date={job.jobDeadline} />
      </div>
    </DetailsSection>
  );
};

export default createFragmentContainer(DetailsMainContent, {
  deliverable: graphql`
    fragment DetailsMainContent_deliverable on DeliverableNode {
      id
      category {
        name
      }
      categoryType {
        name
      }
      job {
        jobDeadline
        contract {
          dateSignedByContractor
        }
      }
    }
  `
});

/**
 * @flow
 * @relayHash 528270b063d627ba38f20083ade61df3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ToggleCommentReactionInput = {|
  reactionId: string,
  commentId: string,
  clientMutationId?: ?string,
|};
export type ToggleCommentReactionMutationVariables = {|
  input: ToggleCommentReactionInput
|};
export type ToggleCommentReactionMutationResponse = {|
  +toggleCommentReaction: ?{|
    +comment: ?{|
      +id: string,
      +reactions: ?$ReadOnlyArray<?{|
        +id: ?string,
        +definitionId: ?string,
        +name: ?string,
        +color: ?string,
        +number: ?number,
        +users: ?$ReadOnlyArray<?{|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |}>,
      |}>,
    |}
  |}
|};
export type ToggleCommentReactionMutation = {|
  variables: ToggleCommentReactionMutationVariables,
  response: ToggleCommentReactionMutationResponse,
|};
*/


/*
mutation ToggleCommentReactionMutation(
  $input: ToggleCommentReactionInput!
) {
  toggleCommentReaction(input: $input) {
    comment {
      id
      reactions {
        id
        definitionId
        name
        color
        number
        users {
          id
          fullName
          representativeImageUrl
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ToggleCommentReactionInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "toggleCommentReaction",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleCommentReactionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "comment",
        "storageKey": null,
        "args": null,
        "concreteType": "CommentNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reactions",
            "storageKey": null,
            "args": null,
            "concreteType": "ReactionNode",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "definitionId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "color",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "users",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fullName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "representativeImageUrl",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ToggleCommentReactionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ToggleCommentReactionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ToggleCommentReactionMutation",
    "id": null,
    "text": "mutation ToggleCommentReactionMutation(\n  $input: ToggleCommentReactionInput!\n) {\n  toggleCommentReaction(input: $input) {\n    comment {\n      id\n      reactions {\n        id\n        definitionId\n        name\n        color\n        number\n        users {\n          id\n          fullName\n          representativeImageUrl\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'edf8bf0767d7190d0069f4776397b2e2';
module.exports = node;

/**
 * @flow
 * @relayHash eebbf8d567d7e757501784ae24cc9a47
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type RevertToDraftBatchDeliverableInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type BatchRevertToDraftDeliverableMutationVariables = {|
  input: RevertToDraftBatchDeliverableInput
|};
export type BatchRevertToDraftDeliverableMutationResponse = {|
  +revertToDraftBatchDeliverable: ?{|
    +updatedDeliverables: ?$ReadOnlyArray<?{|
      +id: string,
      +state: DeliverableState,
    |}>
  |}
|};
export type BatchRevertToDraftDeliverableMutation = {|
  variables: BatchRevertToDraftDeliverableMutationVariables,
  response: BatchRevertToDraftDeliverableMutationResponse,
|};
*/


/*
mutation BatchRevertToDraftDeliverableMutation(
  $input: RevertToDraftBatchDeliverableInput!
) {
  revertToDraftBatchDeliverable(input: $input) {
    updatedDeliverables {
      id
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RevertToDraftBatchDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "revertToDraftBatchDeliverable",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RevertToDraftBatchDeliverablePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedDeliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BatchRevertToDraftDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BatchRevertToDraftDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BatchRevertToDraftDeliverableMutation",
    "id": null,
    "text": "mutation BatchRevertToDraftDeliverableMutation(\n  $input: RevertToDraftBatchDeliverableInput!\n) {\n  revertToDraftBatchDeliverable(input: $input) {\n    updatedDeliverables {\n      id\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '464aa8f6aecc396c954e8765c1269a31';
module.exports = node;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { DialogContentText as BaseDialogContentText } from '@material-ui/core';
import Dialog, { DialogActions } from './Dialog';
import GlobalButton from '../UI/GlobalButton';
import { isPromise } from '../../utils/helpers';

const DialogContentText = styled(BaseDialogContentText)`
  ul {
    margin-top: 5px;
    padding-left: 30px;
    font-weight: bold;
  }
`;

// NOTE:
// Styleguide version which must be used for every confirmation dialog
const ConfirmationDialog = ({
  onConfirm,
  onReject,
  copies: extendCopies,
  content,
  formikProps,
  ...dialogProps
}) => {
  const copies = {
    cancel: 'Cancel',
    confirm: 'Continue',
    ...extendCopies
  };
  const [isRejectSubmitting, setIsRejectSubmitting] = useState(false);

  const handleRejectClick = () => {
    if (onReject) {
      setIsRejectSubmitting(true);
      const result = onReject({ setSubmitting: setIsRejectSubmitting });
      if (isPromise(result)) {
        result.finally(() => setIsRejectSubmitting(false));
      } else {
        setIsRejectSubmitting(false);
      }
    } else {
      dialogProps.closeDialog();
    }
  };

  return (
    <Dialog minWidth={350} noButton={dialogProps.title === undefined} {...dialogProps}>
      <Formik
        {...formikProps}
        onSubmit={(values, actions) => {
          // TODO:
          // This won't be needed when formik will be updated to 2.x as it handles promises automatically
          const result = onConfirm({ values, actions });
          if (isPromise(result)) {
            result.finally(() => actions.setSubmitting(false));
          } else {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <DialogContentText variant="body1" component="div">
              {content}
            </DialogContentText>
            <DialogActions>
              <GlobalButton
                variant="transparent"
                handleClick={handleRejectClick}
                disabled={isSubmitting || isRejectSubmitting}
              >
                {copies.cancel}
              </GlobalButton>
              <GlobalButton
                handleClick={submitForm}
                loading={isSubmitting}
                disabled={isSubmitting || isRejectSubmitting}
              >
                {copies.confirm}
              </GlobalButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  content: PropTypes.node,
  onReject: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  formikProps: PropTypes.object,
  copies: PropTypes.object
};

const ConfirmationDialogWithState = ({ children, onConfirm, ...confirmationDialogProps }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <ConfirmationDialog
        {...confirmationDialogProps}
        closeDialog={() => setIsVisible(false)}
        isDialogOpen={isVisible}
        onConfirm={async () => {
          await onConfirm();
          setIsVisible(false);
        }}
      />
      {children(() => setIsVisible(true))}
    </>
  );
};

ConfirmationDialogWithState.propTypes = {
  children: PropTypes.func.isRequired
};

export default props =>
  React.createElement(
    typeof props.children === 'function' ? ConfirmationDialogWithState : ConfirmationDialog,
    props
  );

import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Box } from '@material-ui/core';
import withUserContext from '../../../../../../shared/contexts/userContext/withUserContext';
import { JOB_STATUSES, ALLOWED_ACTIONS } from '../../../../../../shared/constants';
import { computePermissionRole } from '../../../../../../shared/utils/helpers';
import GlobalButton from '../../../../../../shared/components/UI/GlobalButton';
import Detail from '../../../../../../shared/components/UI/Detail';
import { formatAmount } from '../../../../../../shared/utils/formatters';
import useDialog from '../../../../../../shared/hooks/useDialog';
import { successToast, errorToast } from '../../../../../../shared/toasts';
import CreatePaymentRequestMutation from '../../../mutations/CreatePaymentRequestMutation';
import CreatePaymentRequestDialog from './CreatePaymentRequestDialog';

const Header = props => {
  const {
    job,
    userContext: {
      orgStaff: { allowedActions }
    }
  } = props;
  const { status, totalValue, currencyCode, totalAmountOfPaymentRequests, balance } = job;
  const [isCreateRequestDialogOpen, toggleRequestDialog] = useDialog();

  const canCreatePaymentRequest =
    computePermissionRole(ALLOWED_ACTIONS.JOB_MANAGE_PAYMENT_REQUESTS, allowedActions) &&
    [JOB_STATUSES.delivered, JOB_STATUSES.in_progress, JOB_STATUSES.closed].includes(status);

  const handlePaymentRequestSubmit = (values, actions) => {
    const { paymentAmount, message, attachContract, file } = values;
    const variables = { jobId: job.id, paymentAmount, message, attachContract };

    CreatePaymentRequestMutation(variables, file, response => {
      actions.setSubmitting(false);
      if (response && response.createPaymentRequest) {
        toggleRequestDialog();
        if (response.createPaymentRequest.emailSent) {
          successToast('Payment request was created!');
        } else {
          errorToast('Payment request was created but email to accounting failed to send.');
        }
      }
    });
  };

  return (
    <>
      <CreatePaymentRequestDialog
        job={job}
        onSubmit={handlePaymentRequestSubmit}
        isDialogOpen={isCreateRequestDialogOpen}
        closeDialog={toggleRequestDialog}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" maxWidth={1000} mb={3}>
        {canCreatePaymentRequest && (
          <GlobalButton handleClick={toggleRequestDialog} variant="secondary">
            Create Payment Request
          </GlobalButton>
        )}
        <Detail name="Contract Amount" value={formatAmount(totalValue, currencyCode)} />
        <Detail
          name="Requested Amount"
          value={formatAmount(totalAmountOfPaymentRequests, currencyCode)}
        />
        <Detail name="Balance" value={formatAmount(balance, currencyCode)} />
      </Box>
    </>
  );
};

export default createFragmentContainer(withUserContext(Header), {
  job: graphql`
    fragment Header_job on JobNode {
      id
      status
      balance
      totalValue
      currencyCode
      totalAmountOfPaymentRequests
      ...CreatePaymentRequestDialog_job
    }
  `
});

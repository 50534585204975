import React from 'react';
import PropTypes from 'prop-types';
import BaseConfirmationDialog from '../../../../../shared/components/common/ConfirmationDialogV2';

const ConfirmationDialog = ({ files, action, ...rest }) => {
  const content =
    files.length === 1 ? (
      <>
        You are about to {action} <b>{files[0].node.fileName}</b>
      </>
    ) : (
      <>
        You are about to {action} {files.length} files:
        <ul>
          {files.map(({ node }, i) => (
            <li key={i}>{node.fileName}</li>
          ))}
        </ul>
      </>
    );

  return <BaseConfirmationDialog {...rest} content={content} />;
};

ConfirmationDialog.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        fileName: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  action: PropTypes.string
};

export default ConfirmationDialog;

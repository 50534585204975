import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import AssetList from '../../../../../shared/components/assets/AssetList';

const SolicitationReferencesList = props => {
  const {
    relay: { refetch },
    solicitation: {
      references: { totalCount, edgeCount, pageInfo, edges }
    }
  } = props;

  return (
    <AssetList
      edges={edges.map(({ node }) => ({ node: { ...node, fileName: node.title } }))}
      refetch={refetch}
      paginationProps={{
        ...pageInfo,
        totalCount,
        edgeCount
      }}
    />
  );
};

export default createRefetchContainer(
  SolicitationReferencesList,
  {
    solicitation: graphql`
      fragment SolicitationReferencesList_solicitation on SolicitationNode {
        references(first: $first, after: $after, orderBy: "-created") {
          totalCount
          edgeCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              title
              fileUrl
              description
              reviewInterface
            }
          }
        }
      }
    `
  },
  graphql`
    query SolicitationReferencesListRefetchQuery($first: Int, $after: String, $id: ID!) {
      solicitation: solicitation(id: $id) {
        ...SolicitationReferencesList_solicitation
      }
    }
  `
);

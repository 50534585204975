import React from 'react';
import { Grid } from '@material-ui/core';

import FilterDebounceInput from '../../../../../shared/components/filters/FilterDebounceInput';
import FilterSelect from '../../../../../shared/components/filters/FilterSelect';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { useFilterStyles } from '../../../../../shared/styles/common/filterStyles';

const OrganizationsListFilters = props => {
  const { filterValues, onFilterChange } = props;
  const classes = useFilterStyles();

  const statusOptions = [
    { value: 'pending', name: 'Pending invitation' },
    { value: 'connected', name: 'Connected' }
  ];

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          name="name"
          label="Organization"
          value={filterValues['name']}
          onChange={onFilterChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          id="filterByStatus"
          name="status"
          onChange={onFilterChange}
          value={filterValues['status']}
          label="Status"
          options={statusOptions}
        />
      </Grid>
    </Grid>
  );
};

export default withUserContext(OrganizationsListFilters);

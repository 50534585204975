import React, { useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const withRoleTypes = (Component, options = {}) => componentProps => {
  const [roles, setRoles] = useState([]);

  useEffectWithStatus(status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query withRoleTypesQuery($isDefault: Boolean) {
          organizationRoles(isDefault: $isDefault) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      {
        isDefault: options.onlyDefaultRoles
      }
    ).then(response => {
      if (status.mounted && response) {
        const mappedRoles = response.organizationRoles.edges.map(({ node }) => node);
        setRoles(mappedRoles);
      }
    });
  }, []);

  return <Component roleTypes={roles} {...componentProps} />;
};

export default withRoleTypes;
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const InvoiceApprovalMutation = (input, invoice_file) =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation InvoiceApprovalMutation($input: InvoiceApprovalInput!) {
          invoiceApproval(input: $input) {
            clientMutationId
            invoice {
              id
              status
              verificationDate
              verificationMessage
              verificationBy {
                fullName
                representativeImageUrl
              }
              job {
                id
                balance
                totalAmountOfPaymentRequests
                paymentRequests(orderBy: "-created") {
                  totalCount
                  edges {
                    node {
                      id
                      created
                      requestId
                      paymentAmount
                      status
                    }
                  }
                }
              }
            }
          }
        }
      `,
      uploadables: { invoice_file },
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default InvoiceApprovalMutation;

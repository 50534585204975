/**
 * @flow
 * @relayHash 9f7d26cdd3e38d98222030ca639b0430
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ToggleAutoRenewalSettingsInput = {|
  shouldAutoRenew?: ?boolean,
  clientMutationId?: ?string,
|};
export type ToggleAutoRenewalMutationVariables = {|
  input: ToggleAutoRenewalSettingsInput
|};
export type ToggleAutoRenewalMutationResponse = {|
  +toggleAutoRenewalSettings: ?{|
    +activeSubscription: ?{|
      +autoRenew: boolean
    |}
  |}
|};
export type ToggleAutoRenewalMutation = {|
  variables: ToggleAutoRenewalMutationVariables,
  response: ToggleAutoRenewalMutationResponse,
|};
*/


/*
mutation ToggleAutoRenewalMutation(
  $input: ToggleAutoRenewalSettingsInput!
) {
  toggleAutoRenewalSettings(input: $input) {
    activeSubscription {
      autoRenew
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ToggleAutoRenewalSettingsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "autoRenew",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ToggleAutoRenewalMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "toggleAutoRenewalSettings",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleAutoRenewalSettingsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "activeSubscription",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationSubscriptionNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ToggleAutoRenewalMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "toggleAutoRenewalSettings",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleAutoRenewalSettingsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "activeSubscription",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationSubscriptionNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ToggleAutoRenewalMutation",
    "id": null,
    "text": "mutation ToggleAutoRenewalMutation(\n  $input: ToggleAutoRenewalSettingsInput!\n) {\n  toggleAutoRenewalSettings(input: $input) {\n    activeSubscription {\n      autoRenew\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd58a2456858aafacd024e49fae2791a';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation ActivateProductMutation($input: ActivateProductInput!) {
    activateProduct(input: $input) {
      updatedProduct {
        id
        isActive
      }
    }
  }
`;

const ActivateProductMutation = (id, callback) => {
  const config = {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.activateProduct);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default ActivateProductMutation;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import CreateInvoiceForm from './CreateInvoiceForm';

const CreateInvoiceFormRenderer = ({ jobId, ...props }) => (
  <QueryRenderer
    query={graphql`
      query CreateInvoiceFormRendererQuery($jobId: ID!) {
        job(id: $jobId) {
          ...CreateInvoiceForm_job
        }
        paymentSettings {
          ...CreateInvoiceForm_paymentSettings
        }
        invoiceTypes: __type(name: "JobInvoiceInvoiceType") {
          ...CreateInvoiceForm_invoiceTypes
        }
      }
    `}
    variables={{
      jobId
    }}
    render={relayProps => (
      <CreateInvoiceForm
        job={relayProps.job}
        paymentSettings={relayProps.paymentSettings}
        invoiceTypes={relayProps.invoiceTypes}
        {...props}
      />
    )}
  />
);

export default CreateInvoiceFormRenderer;

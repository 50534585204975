/**
 * @flow
 * @relayHash 35e48f05d039dfb0e264a26b816a5212
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteBatchDeliverableFinalFilesInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type BatchDeleteDeliverableFinalFileMutationVariables = {|
  input: DeleteBatchDeliverableFinalFilesInput
|};
export type BatchDeleteDeliverableFinalFileMutationResponse = {|
  +deleteBatchDeliverableFinalFiles: ?{|
    +isDeleted: ?boolean
  |}
|};
export type BatchDeleteDeliverableFinalFileMutation = {|
  variables: BatchDeleteDeliverableFinalFileMutationVariables,
  response: BatchDeleteDeliverableFinalFileMutationResponse,
|};
*/

/*
mutation BatchDeleteDeliverableFinalFileMutation(
  $input: DeleteBatchDeliverableFinalFilesInput!
) {
  deleteBatchDeliverableFinalFiles(input: $input) {
    isDeleted
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'DeleteBatchDeliverableFinalFilesInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'deleteBatchDeliverableFinalFiles',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'DeleteBatchDeliverableFinalFilesPayload',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'isDeleted',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'BatchDeleteDeliverableFinalFileMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'BatchDeleteDeliverableFinalFileMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'BatchDeleteDeliverableFinalFileMutation',
      id: null,
      text:
        'mutation BatchDeleteDeliverableFinalFileMutation(\n  $input: DeleteBatchDeliverableFinalFilesInput!\n) {\n  deleteBatchDeliverableFinalFiles(input: $input) {\n    isDeleted\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '25164dacca112203c07fb77605b9f35c';
module.exports = node;

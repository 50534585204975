import React, { Fragment } from 'react';
import { Typography, DialogActions, DialogContent } from '@material-ui/core';
import { Field } from 'formik';

import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import useDialogStyles from '../../../../shared/styles/common/useDialogStyles';
import FormikRadioGroupField from '../../../../shared/components/form/FormikRadioGroupField';

const InvitationResendDialog = ({ handleClose, setFieldValue, handleSubmit, isSubmitting }) => {
  const classes = useDialogStyles();

  return (
    <Fragment>
      <DialogContent>
        <Typography variant="body1">
          The email address you are changing has previously been sent an invitation to connect to
          your Organization via AWEbase, but never responded. By updating the Contractor's email
          address, the pending invitation will be deactivated.
        </Typography>
        <Typography variant="body1" className={classes.marginTop}>
          To update email address on file, please select one of the following options:
        </Typography>
        <Field
          component={FormikRadioGroupField}
          name="resendInvitation"
          options={[
            {
              name:
                'Update the email address, then resend the Contractor an invitation to connect on AWEbase.',
              value: 'resend'
            },
            {
              name: 'Update the email address, but do not send the Contractor a new invitation.',
              value: 'notResend'
            }
          ]}
          onChangeHandler={e => {
            setFieldValue('resendInvitation', e.target.value);
          }}
          label=""
          className={`${classes.radioButtonOptions} ${classes.marginTop}`}
          noMarginRight
        />
        <DialogActions className={classes.marginTop}>
          <GlobalButton variant="transparent" handleClick={handleClose} disabled={isSubmitting}>
            Cancel
          </GlobalButton>
          <GlobalButton handleClick={handleSubmit} disabled={isSubmitting}>
            Save
          </GlobalButton>
        </DialogActions>
      </DialogContent>
    </Fragment>
  );
};

export default InvitationResendDialog;

/**
 * @flow
 * @relayHash c950468a9b1ccaff4bd04601200dd8c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InvoiceQuickView_invoice$ref = any;
type InvoiceQuickView_statusTypes$ref = any;
export type InvoiceQuickViewRendererQueryVariables = {|
  id: string
|};
export type InvoiceQuickViewRendererQueryResponse = {|
  +invoice: ?{|
    +$fragmentRefs: InvoiceQuickView_invoice$ref
  |},
  +__type: ?{|
    +$fragmentRefs: InvoiceQuickView_statusTypes$ref
  |},
|};
export type InvoiceQuickViewRendererQuery = {|
  variables: InvoiceQuickViewRendererQueryVariables,
  response: InvoiceQuickViewRendererQueryResponse,
|};
*/

/*
query InvoiceQuickViewRendererQuery(
  $id: ID!
) {
  invoice(id: $id) {
    ...InvoiceQuickView_invoice
    id
  }
  __type(name: "JobInvoiceStatus") {
    ...InvoiceQuickView_statusTypes
  }
}

fragment InvoiceQuickView_invoice on InvoiceNode {
  id
  identifier
  status
  invoiceFileUrl
  created
  amount
  message
  verificationDate
  verificationMessage
  verificationBy {
    fullName
    representativeImageUrl
    id
  }
  markedPaymentSentDate
  markedPaymentSentMessage
  markedPaymentSentBy {
    fullName
    representativeImageUrl
    id
  }
  job {
    name
    currencyCode
    staffCoordinator {
      fullName
      representativeImageUrl
      organization {
        name
        id
      }
      id
    }
    id
  }
}

fragment InvoiceQuickView_statusTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id'
      }
    ],
    v2 = [
      {
        kind: 'Literal',
        name: 'name',
        value: 'JobInvoiceStatus'
      }
    ],
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v4 = {
      kind: 'ScalarField',
      alias: null,
      name: 'fullName',
      args: null,
      storageKey: null
    },
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'representativeImageUrl',
      args: null,
      storageKey: null
    },
    v6 = [(v4 /*: any*/), (v5 /*: any*/), (v3 /*: any*/)],
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'InvoiceQuickViewRendererQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'invoice',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'InvoiceNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'InvoiceQuickView_invoice',
              args: null
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: null,
          name: '__type',
          storageKey: '__type(name:"JobInvoiceStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'InvoiceQuickView_statusTypes',
              args: null
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'InvoiceQuickViewRendererQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'invoice',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'InvoiceNode',
          plural: false,
          selections: [
            (v3 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'identifier',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'status',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'invoiceFileUrl',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'created',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'amount',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'message',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'verificationDate',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'verificationMessage',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'verificationBy',
              storageKey: null,
              args: null,
              concreteType: 'StaffNode',
              plural: false,
              selections: (v6 /*: any*/)
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'markedPaymentSentDate',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'markedPaymentSentMessage',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'markedPaymentSentBy',
              storageKey: null,
              args: null,
              concreteType: 'StaffNode',
              plural: false,
              selections: (v6 /*: any*/)
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'job',
              storageKey: null,
              args: null,
              concreteType: 'JobNode',
              plural: false,
              selections: [
                (v7 /*: any*/),
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'currencyCode',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'staffCoordinator',
                  storageKey: null,
                  args: null,
                  concreteType: 'StaffNode',
                  plural: false,
                  selections: [
                    (v4 /*: any*/),
                    (v5 /*: any*/),
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'organization',
                      storageKey: null,
                      args: null,
                      concreteType: 'OrgNode',
                      plural: false,
                      selections: [(v7 /*: any*/), (v3 /*: any*/)]
                    },
                    (v3 /*: any*/)
                  ]
                },
                (v3 /*: any*/)
              ]
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: null,
          name: '__type',
          storageKey: '__type(name:"JobInvoiceStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'enumValues',
              storageKey: null,
              args: null,
              concreteType: '__EnumValue',
              plural: true,
              selections: [
                (v7 /*: any*/),
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'description',
                  args: null,
                  storageKey: null
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'InvoiceQuickViewRendererQuery',
      id: null,
      text:
        'query InvoiceQuickViewRendererQuery(\n  $id: ID!\n) {\n  invoice(id: $id) {\n    ...InvoiceQuickView_invoice\n    id\n  }\n  __type(name: "JobInvoiceStatus") {\n    ...InvoiceQuickView_statusTypes\n  }\n}\n\nfragment InvoiceQuickView_invoice on InvoiceNode {\n  id\n  identifier\n  status\n  invoiceFileUrl\n  created\n  amount\n  message\n  verificationDate\n  verificationMessage\n  verificationBy {\n    fullName\n    representativeImageUrl\n    id\n  }\n  markedPaymentSentDate\n  markedPaymentSentMessage\n  markedPaymentSentBy {\n    fullName\n    representativeImageUrl\n    id\n  }\n  job {\n    name\n    currencyCode\n    staffCoordinator {\n      fullName\n      representativeImageUrl\n      organization {\n        name\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment InvoiceQuickView_statusTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'ff7d7cff73c5920b7781bbabb7717825';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Tabs_job$ref: FragmentReference;
declare export opaque type Tabs_job$fragmentType: Tabs_job$ref;
export type Tabs_job = {|
  +id: string,
  +paymentRequests: ?{|
    +totalCount: ?number
  |},
  +invoices: ?{|
    +totalCount: ?number
  |},
  +$refType: Tabs_job$ref,
|};
export type Tabs_job$data = Tabs_job;
export type Tabs_job$key = {
  +$data?: Tabs_job$data,
  +$fragmentRefs: Tabs_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "-created"
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "Tabs_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "paymentRequests",
      "storageKey": "paymentRequests(orderBy:\"-created\")",
      "args": (v0/*: any*/),
      "concreteType": "PaymentRequestNodeConnection",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "invoices",
      "storageKey": "invoices(orderBy:\"-created\")",
      "args": (v0/*: any*/),
      "concreteType": "InvoiceNodeConnection",
      "plural": false,
      "selections": (v1/*: any*/)
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a758890f5c212cd298dfee64704e6b9';
module.exports = node;

import { Box, styled, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import GlobalButton from '../UI/GlobalButton';
import SquareButton from '../UI/SquareButton';
import { AnnotationActionsContainer } from './review_interfaces/AnnotationInterfaceContainers';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
  '& div:last-child': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${theme.palette.colors.darkBlueGrey}`,
    borderRadius: '5px',
    padding: '50px',
    '& button': {
      width: '150px',
      marginTop: '15px'
    }
  }
}));

const FileProcessing = ({ handleRefetch, onCloseClick }) => {
  const [disableButton, setDisableButton] = useState(false);

  const handleClick = () => {
    setDisableButton(true);
    // disabling button for 5sec so users don't spam us with no need
    setTimeout(() => setDisableButton(false), 5000);
    handleRefetch();
  };
  return (
    <StyledBox>
      <AnnotationActionsContainer>
        <SquareButton onClick={onCloseClick} icon="close" />
      </AnnotationActionsContainer>
      <Box>
        <Typography variant="subtitle2">
          The file is being converted, it may take a few minutes.
        </Typography>
        <GlobalButton handleClick={handleClick} disabled={disableButton} variant="alternative">
          Retry
        </GlobalButton>
      </Box>
    </StyledBox>
  );
};

export default FileProcessing;

import React from 'react';

import SearchableProductDropdown from '../../../organization/components/releases/SearchableProductDropdown';
import SearchableReleaseDropdown from '../../../organization/components/shared/dropdowns/SearchableReleaseDropdown';
import SearchableStaffDropdown from '../../../organization/components/shared/dropdowns/SearchableStaffDropdown';
import SearchableContractorDropdown from '../../../organization/components/jobs/SearchableContractorDropdown';
import SearchableDeliverableDropdown from '../../../organization/components/jobs/SearchableDeliverableDropdown';
import SearchableTagsDropdown from '../../../organization/components/form/SearchableTagsDropdown';
import SearchableNewOwnerDropdown from '../../../organization/components/admin/staffManagement/SearchableNewOwnerDropdown';
import SearchableDeliverableCategoryDropdown from '../../../organization/components/jobs/SearchableDeliverableCategoryDropdown';
import SearchableContractorGroupDropdown from '../../../organization/components/contractors/SearchableContractorGroupDropdown';

const FormikSearchableDropdown = props => {
  const { field, form, dropdownName, ...otherProps } = props;

  // Regarding const touched: on submit, if values have not been touched and initial values for field.name are an empty object,
  // (which in this case, they are for release and assigned staff and products), form.touched[field.name]
  // will set their values to an empty object rather than true like it should, and console errors will happen
  // because then isInvalid will evaluate to an empty object instead of a boolean. Therefore, if it's an object,
  // just make it truthy in order to onSubmit validation to work without console errors.

  const searchableDropdowns = {
    product: SearchableProductDropdown,
    release: SearchableReleaseDropdown,
    assignedStaff: SearchableStaffDropdown,
    deliverableCategories: SearchableDeliverableCategoryDropdown,
    contractor: SearchableContractorDropdown,
    deliverables: SearchableDeliverableDropdown,
    tags: SearchableTagsDropdown,
    newOwner: SearchableNewOwnerDropdown,
    contractorGroup: SearchableContractorGroupDropdown
  };

  const SearchableDropdown = dropdownName
    ? searchableDropdowns[dropdownName]
    : searchableDropdowns[field.name];

  const touched = typeof form.touched[field.name] === 'object' ? true : form.touched[field.name];
  const isInvalid = form.errors[field.name] && touched;

  const onChangeHandler = value => {
    form.setFieldValue(field.name, value);
  };

  return (
    <SearchableDropdown
      onBlur={form.handleBlur}
      onChange={onChangeHandler}
      error={isInvalid}
      helperText={isInvalid && form.errors[field.name]}
      name={field.name}
      value={field.value}
      {...otherProps}
    />
  );
};

export default FormikSearchableDropdown;

/**
 * @flow
 * @relayHash 16de267985867c8d814c6111be079dec
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RestoreBatchReviewAssetsInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type BatchRestoreReviewAssetsMutationVariables = {|
  input: RestoreBatchReviewAssetsInput
|};
export type BatchRestoreReviewAssetsMutationResponse = {|
  +restoreBatchReviewAssets: ?{|
    +isRestoring: ?boolean
  |}
|};
export type BatchRestoreReviewAssetsMutation = {|
  variables: BatchRestoreReviewAssetsMutationVariables,
  response: BatchRestoreReviewAssetsMutationResponse,
|};
*/


/*
mutation BatchRestoreReviewAssetsMutation(
  $input: RestoreBatchReviewAssetsInput!
) {
  restoreBatchReviewAssets(input: $input) {
    isRestoring
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RestoreBatchReviewAssetsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "restoreBatchReviewAssets",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RestoreBatchReviewAssetsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isRestoring",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BatchRestoreReviewAssetsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BatchRestoreReviewAssetsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BatchRestoreReviewAssetsMutation",
    "id": null,
    "text": "mutation BatchRestoreReviewAssetsMutation(\n  $input: RestoreBatchReviewAssetsInput!\n) {\n  restoreBatchReviewAssets(input: $input) {\n    isRestoring\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '31c8bc0de290e36a07ba160569e90a1f';
module.exports = node;

/**
 * @flow
 * @relayHash 5a674b132573273372c723007d96a941
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TransferAccountOwnershipInput = {|
  staff: string,
  clientMutationId?: ?string,
|};
export type TransferAccountOwnershipMutationVariables = {|
  input: TransferAccountOwnershipInput
|};
export type TransferAccountOwnershipMutationResponse = {|
  +transferAccountOwnership: ?{|
    +currentStaffUser: ?{|
      +id: string,
      +isOwner: boolean,
      +roleLabel: ?string,
      +role: ?{|
        +id: string,
        +name: string,
      |},
      +allowedActions: ?$ReadOnlyArray<?string>,
      +organization: {|
        +configuration: ?any,
        +currencyCode: string,
      |},
    |},
    +updatedStaffUser: ?{|
      +isOwner: boolean,
      +roleLabel: ?string,
    |},
  |}
|};
export type TransferAccountOwnershipMutation = {|
  variables: TransferAccountOwnershipMutationVariables,
  response: TransferAccountOwnershipMutationResponse,
|};
*/


/*
mutation TransferAccountOwnershipMutation(
  $input: TransferAccountOwnershipInput!
) {
  transferAccountOwnership(input: $input) {
    currentStaffUser {
      id
      isOwner
      roleLabel
      role {
        id
        name
      }
      allowedActions
      organization {
        configuration
        currencyCode
        id
      }
    }
    updatedStaffUser {
      isOwner
      roleLabel
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TransferAccountOwnershipInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isOwner",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "roleLabel",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "role",
  "storageKey": null,
  "args": null,
  "concreteType": "OrganizationRoleNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "allowedActions",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "configuration",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyCode",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TransferAccountOwnershipMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "transferAccountOwnership",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TransferAccountOwnershipPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentStaffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TransferAccountOwnershipMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "transferAccountOwnership",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TransferAccountOwnershipPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentStaffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "TransferAccountOwnershipMutation",
    "id": null,
    "text": "mutation TransferAccountOwnershipMutation(\n  $input: TransferAccountOwnershipInput!\n) {\n  transferAccountOwnership(input: $input) {\n    currentStaffUser {\n      id\n      isOwner\n      roleLabel\n      role {\n        id\n        name\n      }\n      allowedActions\n      organization {\n        configuration\n        currencyCode\n        id\n      }\n    }\n    updatedStaffUser {\n      isOwner\n      roleLabel\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '618d0364e098cc506d291525a7767e89';
module.exports = node;

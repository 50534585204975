import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { DELIVERABLE_STATES } from '../../../../constants';
import useDialog from '../../../../hooks/useDialog';
import CreateReviewAssetMutation from '../../../../mutations/review/CreateReviewAssetMutation';
import { performFileUpload } from '../../../../services/utilities';
import Dialog from '../../../common/Dialog';
import GlobalButton from '../../../UI/GlobalButton';
import ReviewStepProgress from '../../../review/ReviewStepProgress';
import DeliverableReviewAssetFileUploadForm from '../../DeliverableReviewAssetFileUploadForm';
import DeliverableDetailsHeader from '../../review/DeliverableDetailsHeader';

const Container = styled.div`
  padding: 0 0 24px 0;
  border-bottom: 1px solid ${props => props.theme.palette.colors.lightestGrey};
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  > *:first-child {
    width: 447px;
  }
`;

const ReviewStepContainer = styled.div`
  margin-right: auto;
`;

const Header = ({
  deliverable,
  canUpload,
  canChangeReviewStep,
  onReviewStepChange,
  history,
  match
}) => {
  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const [isLoading, setIsLoading] = useState(false);

  const handleAddAssetOk = response => {
    setIsLoading(false);
    if (response && response.createReviewAsset) {
      toggleDialogOpen();
      const newRevisionNumber =
        response.createReviewAsset.deliverableReview.deliverableReviewAssets.edges[0].node
          .revisionNumber;
      history.push(`${match.url}/${newRevisionNumber}`);
    }
  };

  const handleAddAsset = values => {
    setIsLoading(true);
    let modelName = 'reviewasset';
    let fieldName = 'file';

    performFileUpload(
      modelName,
      fieldName,
      null,
      values.file,
      // noPresignedUploadHandler
      file => {
        CreateReviewAssetMutation(
          {
            deliverable: deliverable.id,
            reviewInterface: values.reviewInterface
          },
          file,
          handleAddAssetOk
        );
      },
      // confirmPresignedUploadSuccessfulCallback
      (stagedPath, fileName) => {
        CreateReviewAssetMutation(
          {
            deliverable: deliverable.id,
            stagedPath,
            fileName,
            reviewInterface: values.reviewInterface
          },
          null,
          handleAddAssetOk
        );
      }
    );
  };

  return (
    <Container>
      <DeliverableDetailsHeader deliverable={deliverable} />

      <ReviewStepContainer>
        <ReviewStepProgress
          stepList={deliverable.reviewActive.reviewStepList}
          currentStep={deliverable.reviewActive.step}
          handleStepClicked={onReviewStepChange}
          canChangeReviewStepInReviewTab={canChangeReviewStep}
        />
      </ReviewStepContainer>

      {canUpload && (
        <GlobalButton
          id="uploadFileDeliverableReview"
          handleClick={toggleDialogOpen}
          disabled={deliverable.state !== DELIVERABLE_STATES.inProgress}
          variant="secondary"
        >
          Upload File
        </GlobalButton>
      )}

      <Dialog
        title="Upload New Asset"
        isDialogOpen={isDialogOpen}
        closeDialog={toggleDialogOpen}
        minWidth={480}
        maxWidth={480}
      >
        <DeliverableReviewAssetFileUploadForm
          isLoading={isLoading}
          closeDialog={toggleDialogOpen}
          onSubmit={handleAddAsset}
          reviewAssetFileValidationConfig={deliverable.reviewAssetFileValidationConfig}
        />
      </Dialog>
    </Container>
  );
};

export default withRouter(Header);

/**
 * @flow
 * @relayHash dc831adf4fccb0b35b1bf44c4cb9addd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDeliverableReviewStepInput = {|
  deliverableReview: string,
  step: string,
  clientMutationId?: ?string,
|};
export type UpdateDeliverableReviewStepMutationVariables = {|
  input: UpdateDeliverableReviewStepInput
|};
export type UpdateDeliverableReviewStepMutationResponse = {|
  +updateDeliverableReviewStep: ?{|
    +updatedDeliverableReview: ?{|
      +deliverable: {|
        +reviewStep: ?string,
        +reviewActive: ?{|
          +id: string,
          +step: {|
            +id: string,
            +name: string,
          |},
        |},
      |}
    |}
  |}
|};
export type UpdateDeliverableReviewStepMutation = {|
  variables: UpdateDeliverableReviewStepMutationVariables,
  response: UpdateDeliverableReviewStepMutationResponse,
|};
*/


/*
mutation UpdateDeliverableReviewStepMutation(
  $input: UpdateDeliverableReviewStepInput!
) {
  updateDeliverableReviewStep(input: $input) {
    updatedDeliverableReview {
      deliverable {
        reviewStep
        reviewActive {
          id
          step {
            id
            name
          }
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
  var v0 = [
        {
          "kind": "LocalArgument",
          "name": "input",
          "type": "UpdateDeliverableReviewStepInput!",
          "defaultValue": null
        }
      ],
      v1 = [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      v2 = {
        "kind": "ScalarField",
        "alias": null,
        "name": "reviewStep",
        "args": null,
        "storageKey": null
      },
      v3 = {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      v4 = {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewActive",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableReviewNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "step",
            "storageKey": null,
            "args": null,
            "concreteType": "StepNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      };
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateDeliverableReviewStepMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDeliverableReviewStep",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeliverableReviewStepPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverableReview",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableReviewNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverable",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateDeliverableReviewStepMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDeliverableReviewStep",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeliverableReviewStepPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverableReview",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableReviewNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverable",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/)
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateDeliverableReviewStepMutation",
    "id": null,
    "text": "mutation UpdateDeliverableReviewStepMutation(\n  $input: UpdateDeliverableReviewStepInput!\n) {\n  updateDeliverableReviewStep(input: $input) {\n    updatedDeliverableReview {\n      deliverable {\n        reviewStep\n        reviewActive {\n          id\n          step {\n            id\n            name\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6cb43c91f9a49195ed28de0da5f07f65';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import get from 'lodash/get';
import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const query = graphql`
  query SearchableTagsDropdownQuery($name: String, $first: Int) {
    tagByOrg(name: $name, first: $first) {
      edgeCount
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const SearchableTagsDropdown = props => {
  const { onChange, value: passedValue, name, label, field, creatable } = props;
  const value = passedValue || field.value;

  const formatOptions = options => {
    if (!options || !options.tagByOrg) {
      return [];
    }
    if (value.options) {
      // filter out already selected options in forms only (for now at least)
      return options.tagByOrg.edges.reduce((result, tag) => {
        if (
          !value.options.find(tagSelected => tagSelected.value === tag.node.id) &&
          result.length < 10
        ) {
          result.push({
            value: tag.node.id,
            label: tag.node.name
          });
        }
        return result;
      }, []);
    } else {
      return options.tagByOrg.edges
        .map(tag => {
          return { value: tag.node.id, label: tag.node.name };
        })
        .slice(0, 10);
    }
  };

  const getFetchVariables = inputValue => {
    return {
      name: inputValue,
      first: 10 + value.options.length
    };
  };

  return (
    <SearchableDropdown
      resultKey="tagByOrg"
      keyToReload={`items-count-${get(value, 'options.length', 0)}`}
      name={name || field.name}
      label={label}
      selectChangeHandler={onChange}
      query={query}
      formatOptions={formatOptions}
      getFetchVariables={getFetchVariables}
      value={value}
      withChipsBelow
      isMulti
      creatable={creatable}
      itemsInList={value.options ? value.options.map(({ value }) => ({ id: value })) : undefined}
      placeholder="Select Tags..."
    />
  );
};

export default SearchableTagsDropdown;

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../shared/styles/common/colors';

const fieldSeparatorMargin = 30;

const appStyles = makeStyles(theme => ({
  header: {
    color: 'black',
    margin: 0,
    fontSize: '24px',
    fontWeight: 'bold'
  },

  formField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& p': {
      fontWeight: 'bold'
    }
  },

  halfWidthField: {
    width: '48% !important'
  },

  required: {
    color: 'red',
    fontWeight: 'bold'
    // fontSize: '16px',
  },

  outlinedInput: {
    height: '42px',
    fontSize: '12px',
    // '&:not(hover):not($disabled):not($focused):not($error) $notchedOutline': {
    //   borderColor: 'gray' //default
    // },
    // '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
    //   borderColor: 'blue' //hovered
    // },
    '&$focused $notchedOutline': {
      borderColor: 'gray' //focused
    }
  },
  notchedOutline: {},
  focused: {},

  notesOutlinedInput: {
    height: '100%',
    width: '100%',
    fontSize: '12px',

    '&$focused $notchedOutline': {
      borderColor: 'gray' //focused
    }
  },

  dropdownOption: {
    minWidth: '160px',
    width: '100%'
  },

  formDropdownWrapper: {
    width: '48%'
  },

  alone: {
    width: `calc(50% - (${fieldSeparatorMargin / 2}px))`
  },

  dateWrapper: {
    width: '100%'
  },

  iconSize: {
    padding: '8px'
  },

  modalLabel: {
    color: 'gray',
    marginBottom: '5px',
    fontSize: '12px'
  },

  error: {
    color: 'red'
  },
  contractorForm: {
    height: '285px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minHeight: '85px',
    margin: '10px 0',
    '& .MuiFormLabel-root': {
      // color: colors.orange
    }
  },

  fullHeight: {
    height: '100%'
  },

  formRowNoMin: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '10px 0'
  },

  formRowShortBottom: {
    margin: '10px 0 0 0'
  },

  formRowShortTop: {
    margin: '0 0 10px 0'
  },

  formRowShort: {
    margin: 0
  },

  formSpecificContainer: {
    flexWrap: 'wrap',
    '& > *': {
      marginTop: '25px'
    }
    // '& div:not(.MuiTextField-root) + div:not(.MuiTextField-root)': {
    //   backgroundColor: 'red',
    //   margin: 0,
    // },
    // '& div.MuiTextField-root + div.MuiTextField-root': {
    //   backgroundColor: 'green',
    //   marginTop: 0,
    // },  // TODO: Fix margins/spacing between rows of fields
  },

  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '0 0 16px 0'
  },

  inputMargin: {
    marginLeft: `${fieldSeparatorMargin}px !important`
  },

  addToListButton: {
    padding: '0 12px'
  },

  addToListDropdown: {
    paddingLeft: '16px',
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      backgroundColor: 'white',
      padding: 'unset',
      fontSize: '12px',
      height: 'unset'
    },
    '& .MuiGrid-root': {
      padding: '4px',
      '& input': {
        padding: '3px 0!important'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.lightestGrey
      }
    }
  },
  deliverablesListTable: {
    paddingTop: '16px',
    '& td:last-of-type svg': {
      cursor: 'pointer'
    }
  },

  deliverablesContainer: {
    overflow: 'visible !important',
    '& > div': {
      fontSize: '12px'
    },
    '& .MuiChip-root': {
      height: '28px'
    }
  },
  helperTextJobForm: {
    position: 'relative',
    left: '285px',
    bottom: '35px',
    fontSize: '12px',
    color: '#808080'
  },
  formTabBox: {
    marginRight: '-30px',
    '& table': {
      paddingLeft: '5px'
    },
    '& .MuiGrid-container': {
      alignItems: 'center'
    },
    '& .MuiGrid-item:last-of-type': {
      marginBottom: '10px'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '24px',
    right: '24px'
  },
  marginTop: {
    marginTop: '32px'
  }
}));

export default appStyles;

import { fabric } from 'fabric';
import { ATTRS } from './contants';

const getObjectsByRef = (canvas, annotationRef) => {
  return canvas.getObjects().filter(object => object[ATTRS.ref] === annotationRef);
};

const startSession = (canvas, annotationRef) => {
  // remove any previous
  // create a group object to use for the session.
  var sessionObj = new fabric.Group();
  sessionObj[ATTRS.ref] = annotationRef;
  canvas.add(sessionObj);
  return sessionObj;
};

const getSessionObj = (canvas, annotationRef) => {
  var sessionObj = canvas.getObjects().find(object => object[ATTRS.ref] === annotationRef);
  if (!sessionObj) {
    return startSession(canvas, annotationRef);
  }
  return sessionObj;
};

export { getSessionObj, getObjectsByRef };

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';

import ProductListFilters from './ProductListFilters';
import AutoTable from '../../../shared/components/table/AutoTable';
import { CELL_TYPES } from '../../../shared/constants';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';

const ProductList = props => {
  const {
    products: { allProducts },
    relay: { refetch },
    history,
    match,
    refetchCounter,
    userContext: {
      orgStaff: { organization }
    }
  } = props;

  const navigation = JSON.parse(organization.configuration).navigation;

  // TODO need to create a component for errors
  if (!allProducts) return <div>Something went wrong!</div>;

  const {
    edges,
    totalCount,
    edgeCount,
    pageInfo: { hasNextPage, endCursor }
  } = allProducts;

  const flattenedEdges = edges.map(edge => {
    const { id, title, description, code, representativeImageUrl: imageUrl } = edge.node;
    return {
      node: {
        id,
        title,
        description,
        code,
        image: { imageUrl, id: id }
      }
    };
  });

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    history.push(`${match.url}/${id}`);
  };

  return (
    <AutoTable
      rowTemplate={[
        {
          name: 'image',
          label: 'Image',
          type: CELL_TYPES.thumbnail
        },
        {
          name: 'title',
          label: `${navigation.productTitle.singular} Name`,
          type: CELL_TYPES.link,
          sortable: true
        },
        {
          name: 'description',
          label: 'Description',
          truncate: true,
          width: '50%'
        },
        {
          name: 'code',
          label: `${navigation.productTitle.singular} Code`,
          sortable: true
        }
      ]}
      edges={flattenedEdges}
      onChangeHandler={onChangeHandler}
      refetchCounter={refetchCounter}
      rowProps={{
        handleLinkClick: onLinkClickHandler,
        handleThumbnailClick: onLinkClickHandler
      }}
      filterProps={{
        filterComponent: <ProductListFilters />,
        width: 650,
        withApply: true
      }}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
      withBackground
    />
  );
};

export default withUserContext(
  createRefetchContainer(
    ProductList,
    {
      products: graphql`
        fragment ProductList_products on Query {
          allProducts(
            first: $first
            after: $after
            includeInactive: $includeInactive
            orderBy: $orderBy
            title: $title
            code: $code
          ) {
            edges {
              node {
                title
                description
                representativeImageUrl
                code
                id
              }
            }
            totalCount
            edgeCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `
    },

    graphql`
      query ProductListRefetchQuery(
        $first: Int
        $after: String
        $includeInactive: Boolean
        $orderBy: String
        $title: String
        $code: String
      ) {
        ...ProductList_products
      }
    `
  )
);

import React, { useState, Fragment } from 'react';
import AudioWavePlayer from '../review/AudioWavePlayer';
import { errorToast } from '../../toasts';
import SquareButton from '../UI/SquareButton';
import UpdateDeliverableReviewAnnotationMutation from '../../mutations/review/UpdateDeliverableReviewAnnotationMutation';
import { AnnotationActionsContainer } from './review_interfaces/AnnotationInterfaceContainers';

const AudioAnnotation = props => {
  const {
    src,
    assetId,
    annotationData,
    handleHighlightAnnotation,
    highlightedAnnotationRef,
    onCloseClick,
    canAddMarkup,
    setSidebarAction
  } = props;

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleAddAnnotation = () =>
    setSidebarAction(val => ({
      ...val,
      type: 'ADD_NEW',
      submitHandler: ({ content, rawContent, referenceIds }, actions) => {
        if (annotationData.find(annotation => annotation.time === currentTime)) {
          errorToast('Cannot add two annotations at the same point in time.');
          actions.setSubmitting(false);
          return;
        }

        const newAnnotation = {
          time: currentTime,
          annotationRef: `annotation-${annotationData.length}-${currentTime}`
        };
        UpdateDeliverableReviewAnnotationMutation(
          {
            reviewAsset: assetId,
            annotationData: JSON.stringify(annotationData.concat(newAnnotation)),
            annotationRef: newAnnotation.annotationRef,
            content,
            rawContent,
            referenceIds
          },
          response => {
            setSidebarAction(null);
          }
        );
      }
    }));

  const handleAddComment = () =>
    setSidebarAction(val => ({
      ...val,
      type: 'ADD_NEW',
      submitHandler: null
    }));

  return (
    <Fragment>
      <AnnotationActionsContainer>
        <SquareButton onClick={onCloseClick} icon="close" />
      </AnnotationActionsContainer>
      <AudioWavePlayer
        id="audio-wave-markup"
        src={src}
        withTime
        withToolbar
        annotationData={annotationData}
        addAnnotationHandler={canAddMarkup ? handleAddAnnotation : undefined}
        addCommentHandler={canAddMarkup ? handleAddComment : undefined}
        canAddMarkup={canAddMarkup}
        currentTime={currentTime}
        duration={duration}
        handleHighlightAnnotation={handleHighlightAnnotation}
        highlightedAnnotationRef={highlightedAnnotationRef}
        timeProps={{
          currentTime,
          duration,
          setCurrentTime,
          setDuration
        }}
        waveSurferProps={{
          height: 256,
          cursorWidth: 0,
          barWidth: 2,
          barHeight: 1,
          interact: true
        }}
      />
    </Fragment>
  );
};

export default AudioAnnotation;

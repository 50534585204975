import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import StyledFilesList from '../shared/StyledFilesList';
import BatchArchiveReviewAssetsMutation from '../../../deliverables/mutations/BatchArchiveReviewAssetsMutation';
import BatchDeleteReviewAssetMutation from '../../../deliverables/mutations/BatchDeleteReviewAssetMutation';
import BatchRestoreReviewAssetsMutation from '../../../deliverables/mutations/BatchRestoreReviewAssetsMutation';
import DownloadBatchReviewAssetsMutation from '../../../deliverables/mutations/DownloadBatchReviewAssetsMutation';
import getFetchReviewAssetDownloadUrl from '../../../../../shared/mutations/review/fetchReviewAssetDownloadUrl';

const ACTIONS_ON_CONFIRM = {
  archive: {
    onConfirm: BatchArchiveReviewAssetsMutation
  },
  delete: {
    onConfirm: BatchDeleteReviewAssetMutation
  },
  restore: {
    onConfirm: BatchRestoreReviewAssetsMutation
  },
  download: {
    onConfirm: DownloadBatchReviewAssetsMutation
  }
};

const ReviewAssetsList = props => {
  const {
    files: { reviewAssetsByOrg },
    storageClasses,
    deliverableStateTypes,
    relay
  } = props;

  const canDelete = (assets = []) => {
    let result = true;
    for (const asset of assets) {
      result = !asset.node.hasComments;
      if (!result) break;
    }
    return result;
  };

  const handleDownloadClick = assetId => callback => {
    getFetchReviewAssetDownloadUrl(assetId, callback)();
  };

  return (
    <StyledFilesList
      files={reviewAssetsByOrg}
      storageClasses={storageClasses}
      deliverableStateTypes={deliverableStateTypes}
      relay={relay}
      canDelete={canDelete}
      canDownload={true}
      onActionConfirm={ACTIONS_ON_CONFIRM}
      handleDownloadClick={handleDownloadClick}
    />
  );
};

export default createRefetchContainer(
  ReviewAssetsList,
  {
    files: graphql`
      fragment ReviewAssetsList_files on Query {
        reviewAssetsByOrg(
          first: $first
          after: $after
          orderBy: $orderBy
          storageClass: $storageClass
          deliverableState: $deliverableState
          releaseId: $releaseId
          deliverableTitle: $deliverableTitle
          fileName: $fileName
        ) {
          edges {
            node {
              id
              fileName
              fileSize
              storageClass
              hasComments
              deliverable {
                title
                state
                release {
                  name
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
        }
      }
    `,
    storageClasses: graphql`
      fragment ReviewAssetsList_storageClasses on __Type {
        enumValues {
          name
          description
        }
      }
    `,
    deliverableStateTypes: graphql`
      fragment ReviewAssetsList_deliverableStateTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  },
  graphql`
    query ReviewAssetsListRefetchQuery(
      $first: Int
      $after: String
      $orderBy: String
      $storageClass: String
      $deliverableState: String
      $releaseId: String
      $deliverableTitle: String
      $fileName: String
    ) {
      ...ReviewAssetsList_files
    }
  `
);

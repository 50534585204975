/**
 * @flow
 * @relayHash f86e61b42904c88033fda7d557d0c32f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
export type SolicitationChatViewQueryVariables = {|
  id: string,
  contractorId: string,
|};
export type SolicitationChatViewQueryResponse = {|
  +solicitation: ?{|
    +state: SolicitationState,
    +comments: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +content: ?string,
          +contentEditedAt: ?any,
          +repliedTo: ?{|
            +content: ?string,
            +created: ?any,
            +user: ?{|
              +fullName: ?string
            |},
          |},
          +contractor: {|
            +fullName: ?string,
            +representativeImageUrl: ?string,
            +freelancer: ?{|
              +user: {|
                +id: string
              |}
            |},
          |},
          +createdByStaff: ?{|
            +fullName: ?string,
            +representativeImageUrl: ?string,
            +user: {|
              +id: string
            |},
          |},
          +created: any,
        |}
      |}>
    |},
  |},
  +contractor: ?{|
    +id: string,
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
|};
export type SolicitationChatViewQuery = {|
  variables: SolicitationChatViewQueryVariables,
  response: SolicitationChatViewQueryResponse,
|};
*/


/*
query SolicitationChatViewQuery(
  $id: ID!
  $contractorId: ID!
) {
  solicitation(id: $id, contractorId: $contractorId) {
    state
    comments {
      edges {
        node {
          id
          content
          contentEditedAt
          repliedTo {
            content
            created
            user {
              fullName
              id
            }
          }
          contractor {
            fullName
            representativeImageUrl
            freelancer {
              user {
                id
              }
              id
            }
            id
          }
          createdByStaff {
            fullName
            representativeImageUrl
            user {
              id
            }
            id
          }
          created
        }
      }
    }
    id
  }
  contractor(id: $contractorId) {
    id
    fullName
    representativeImageUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractorId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractorId",
    "variableName": "contractorId"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contentEditedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contractor",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "contractorId"
    }
  ],
  "concreteType": "ContractorNode",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationChatViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comments",
            "storageKey": null,
            "args": null,
            "concreteType": "SolicitationContractorLogNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SolicitationContractorLogNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SolicitationContractorLogNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "repliedTo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentRepliedToNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contractor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "freelancer",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FreelancerNode",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdByStaff",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ]
                      },
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      (v10/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationChatViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comments",
            "storageKey": null,
            "args": null,
            "concreteType": "SolicitationContractorLogNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SolicitationContractorLogNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SolicitationContractorLogNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "repliedTo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentRepliedToNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v3/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contractor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "freelancer",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FreelancerNode",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v3/*: any*/)
                            ]
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdByStaff",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v3/*: any*/)
                        ]
                      },
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationChatViewQuery",
    "id": null,
    "text": "query SolicitationChatViewQuery(\n  $id: ID!\n  $contractorId: ID!\n) {\n  solicitation(id: $id, contractorId: $contractorId) {\n    state\n    comments {\n      edges {\n        node {\n          id\n          content\n          contentEditedAt\n          repliedTo {\n            content\n            created\n            user {\n              fullName\n              id\n            }\n          }\n          contractor {\n            fullName\n            representativeImageUrl\n            freelancer {\n              user {\n                id\n              }\n              id\n            }\n            id\n          }\n          createdByStaff {\n            fullName\n            representativeImageUrl\n            user {\n              id\n            }\n            id\n          }\n          created\n        }\n      }\n    }\n    id\n  }\n  contractor(id: $contractorId) {\n    id\n    fullName\n    representativeImageUrl\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eae6f3bcff019804344e79b3a3db8ed6';
module.exports = node;

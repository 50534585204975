import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createPaginationContainer } from 'react-relay';
import TopBarNotification from '../../../shared/components/common/TopBarNotification';
import { ORGANIZATION_APP } from '../../../shared/services/constants';

const TopBarOrganizationNotification = props => {
  const { notifications, relay } = props;

  return <TopBarNotification type={ORGANIZATION_APP} notifications={notifications} {...relay} />;
};
export default createPaginationContainer(
  TopBarOrganizationNotification,
  {
    notifications: graphql`
      fragment TopBarOrganizationNotification_notifications on Query
      @argumentDefinitions(
        unread: { type: Boolean, defaultValue: true }
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
      ) {
        notifications(unread: $unread, first: $first, after: $after)
          @connection(key: "TopBarOrganizationNotification_notifications") {
          totalCount
          edges {
            node {
              id
              unread
              timesince
              description
              actor {
                name
                representativeImageUrl
                actorType
              }
              deliverable {
                title
                release {
                  name
                  product {
                    title
                  }
                }
              }
              extraData {
                targetRelayId
                actionRelayId
                content
                notificationType
                instanceType
                instanceTitle
                action
              }
              reviewAssetComment {
                id
              }
              reviewAsset {
                id
                revisionNumber
                deliverable {
                  id
                }
              }
            }
          }
        }
      }
    `
  },
  {
    direction: 'forward',
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        first: count,
        after: cursor,
        unread: fragmentVariables.unread
      };
    },
    query: graphql`
      query TopBarOrganizationNotificationRefetchQuery(
        $unread: Boolean
        $first: Int!
        $after: String
      ) {
        ...TopBarOrganizationNotification_notifications
          @arguments(unread: $unread, first: $first, after: $after)
      }
    `
  }
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const PreviewInvoiceMutation = (input, file) =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation PreviewInvoiceMutation($input: PreviewInvoiceInput!) {
          previewInvoice(input: $input) {
            clientMutationId
            invoicePreview
          }
        }
      `,
      variables: { input },
      uploadables: {
        invoice_file: file
      },
      onCompleted: resolve,
      onError: reject
    });
  });

export default PreviewInvoiceMutation;

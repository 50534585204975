import React, { Component } from 'react';
import TableCellCheckbox from './TableCellCheckbox';

class TableHeaderCheckbox extends Component {
  render() {
    const { labelledBy, handleCheckAll, checkedCount, rowsCount } = this.props;

    return (
      <TableCellCheckbox
        labelledBy={labelledBy}
        onCheck={handleCheckAll}
        isChecked={checkedCount !== 0 && checkedCount === rowsCount}
        indeterminate={checkedCount !== 0 && checkedCount < rowsCount}
      />
    );
  }
}

export default TableHeaderCheckbox;

import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';
import { useTableStyles } from '../../styles/common/tableStyles';
import defaultImage from '../../../shared/images/AWEbase-logo-unlinked-contractor.svg';

const TableCellThumbnailWithText = props => {
  const {
    avatarSrc,
    cellText,
    handleClick,
    onlyTooltip,
    withBadge,
    defaultIcon,
    ...otherProps
  } = props;
  const classes = useTableStyles();
  return (
    <StyledTableCell {...otherProps} onClick={handleClick}>
      <div>
        {avatarSrc ? (
          <img src={avatarSrc} alt={cellText} className={classes.thumbnailImage} />
        ) : (
          <img src={defaultImage} alt={cellText} className={classes.thumbnailImage} />
        )}
      </div>
    </StyledTableCell>
  );
};

TableCellThumbnailWithText.propTypes = {
  avatarSrc: PropTypes.string,
  cellText: PropTypes.string,
  handleClick: PropTypes.func
};

export default TableCellThumbnailWithText;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import ReleaseDeliverablesList from './ReleaseDeliverablesList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';

const ReleaseDeliverablesTab = props => {
  const { id } = useParams();
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ReleaseDeliverablesTabQuery(
          $id: ID!
          $first: Int
          $after: String
          $orderBy: String
          $dueDateFrom: Date
          $dueDateTo: Date
          $title: String
          $includeInactive: Boolean
          $assignedToStaffOrContractor: String
          $staffCoordinator: String
          $state: String
          $category: ID
          $tags: String
          $includeClosed: Boolean
          $includeCanceled: Boolean
        ) {
          release(id: $id) {
            ...ReleaseDeliverablesListHeader_release
            ...ReleaseDeliverablesList_release
          }
          __type(name: "DeliverableState") {
            ...ReleaseDeliverablesList_stateTypes
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <ReleaseDeliverablesList
          release={relayProps.release}
          stateTypes={relayProps.__type}
          relayProps={relayProps}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(ReleaseDeliverablesTab, 'orgReleaseDeliverables', {
  orderBy: 'dueDate',
  initialFilters: {
    title: '',
    tags: '',
    category: null,
    state: '',
    staffCoordinator: null,
    assignedToStaffOrContractor: null,
    dueDateFrom: null,
    dueDateTo: null,
    includeInactive: false,
    includeClosed: false,
    includeCanceled: false
  }
});

/**
 * @flow
 * @relayHash 079a2f816a655d803e2fccec9f5039be
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationDetails_solicitation$ref = any;
export type SolicitationDetailsPageQueryVariables = {|
  solicitationId: string
|};
export type SolicitationDetailsPageQueryResponse = {|
  +solicitation: ?{|
    +$fragmentRefs: SolicitationDetails_solicitation$ref
  |}
|};
export type SolicitationDetailsPageQuery = {|
  variables: SolicitationDetailsPageQueryVariables,
  response: SolicitationDetailsPageQueryResponse,
|};
*/


/*
query SolicitationDetailsPageQuery(
  $solicitationId: ID!
) {
  solicitation(id: $solicitationId) {
    ...SolicitationDetails_solicitation
    id
  }
}

fragment SolicitationDetails_solicitation on SolicitationNode {
  subject
  replyByDate
  staffCoordinator {
    fullName
    organization {
      name
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "solicitationId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "solicitationId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SolicitationDetails_solicitation",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subject",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "replyByDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffCoordinator",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              },
              (v2/*: any*/)
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationDetailsPageQuery",
    "id": null,
    "text": "query SolicitationDetailsPageQuery(\n  $solicitationId: ID!\n) {\n  solicitation(id: $solicitationId) {\n    ...SolicitationDetails_solicitation\n    id\n  }\n}\n\nfragment SolicitationDetails_solicitation on SolicitationNode {\n  subject\n  replyByDate\n  staffCoordinator {\n    fullName\n    organization {\n      name\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f9ed9b7418ca0c9cabf819027d1fd23';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const ToggleCommentReactionMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation ToggleCommentReactionMutation($input: ToggleCommentReactionInput!) {
          toggleCommentReaction(input: $input) {
            comment {
              id
              reactions {
                id
                definitionId
                name
                color
                number
                users {
                  id
                  fullName
                  representativeImageUrl
                }
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default ToggleCommentReactionMutation;

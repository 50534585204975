import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';

import ReplyIcon from '@material-ui/icons/Reply';

import AssetReviewCommentChip from '../UI/AssetReviewCommentChip';

import SquareButton from '../UI/SquareButton';
import Dialog from '../common/Dialog';
import Avatar from '../common/Avatar';

import useDialog from '../../hooks/useDialog';
import useCommentCardStyles from '../../styles/comment/useCommentCardStyles';
import UpdateCommentMutation from '../../mutations/comments/UpdateCommentMutation';
import AnnotationCommentForm from '../deliverable/AnnotationCommentForm';
import TimeBadge from './TimeBadge';
import DateBadge from './DateBadge';
import CommentRepliedTo from './CommentRepliedTo';
import { COMMENT_TYPES } from '../../constants';
import { RichTextFormattedValue } from '../richText';

const CommentCard = props => {
  const {
    commentId,
    direction,
    fullName,
    date,
    revisionNumber,
    avatarSrc,
    commentText,
    wasCommentEdited,
    handleReply,
    handleDelete,
    handleUpdate,
    handleSelectAsset,
    highlighted,
    annotationRef,
    handleHighlightAnnotation,
    isCurrentUser,
    refetch,
    noEditButton,
    noDeleteButton,
    extraData: extraDataJSON,
    commentType
  } = props;

  const classes = useCommentCardStyles({ direction, highlighted });
  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const extraData = extraDataJSON ? JSON.parse(extraDataJSON) : null;

  const onRevisionNumberClick = () => {
    handleSelectAsset(revisionNumber);
  };

  const onDeleteButtonClick = () => {
    handleDelete(commentId);
  };

  const onHighlighAnnotationClick = () => {
    handleHighlightAnnotation(annotationRef);
  };

  const onEditButtonClick = () => {
    toggleDialogOpen();
  };

  const onEditSave = (content, cb) => {
    if (handleUpdate) {
      // custom edit mutation needs to be used
      handleUpdate(commentId, content, () => {
        cb();
        toggleDialogOpen();
      });
    } else {
      UpdateCommentMutation({ id: commentId, content }, () => {
        cb();
        toggleDialogOpen();
        if (refetch) {
          refetch();
        }
      });
    }
  };

  return (
    <Fragment>
      <ListItem className={classes.commentCard}>
        <div className={classes.commentHeaderContainer}>
          <div className={classes.commentHeaderActionContainer}>
            {handleSelectAsset && (revisionNumber || revisionNumber === 0) && (
              <AssetReviewCommentChip
                onClick={onRevisionNumberClick}
                revisionNumber={revisionNumber}
              />
            )}

            {handleReply && (
              <SquareButton onClick={handleReply} variant="secondary" icon={ReplyIcon} size={24} />
            )}

            {annotationRef && handleHighlightAnnotation && (
              <SquareButton
                onClick={onHighlighAnnotationClick}
                variant="secondary"
                icon="annotation"
                size={24}
              />
            )}

            {isCurrentUser && !noEditButton && (
              <SquareButton onClick={onEditButtonClick} variant="secondary" size={24} icon="edit" />
            )}

            {handleDelete && !noDeleteButton && (
              <SquareButton
                onClick={onDeleteButtonClick}
                variant="transparent"
                size={24}
                icon="trash"
              />
            )}
          </div>
          <div className={clsx(classes.commentHeader, classes.textAlign)}>
            {direction === 'start' && (
              <ListItemAvatar className={clsx(classes.commentHeaderAvatar, classes.avatarAlign)}>
                <Avatar alt={fullName} src={avatarSrc} />
              </ListItemAvatar>
            )}

            <ListItemText
              disableTypography={true}
              primary={
                <Fragment>
                  <TimeBadge annotationRef={annotationRef} />
                  <DateBadge date={date} />
                </Fragment>
              }
              secondary={<Typography variant="body2">{fullName}</Typography>}
            />

            {direction === 'end' && (
              <ListItemAvatar className={clsx(classes.commentHeaderAvatar, classes.avatarAlign)}>
                <Avatar alt={fullName} src={avatarSrc} />
              </ListItemAvatar>
            )}
          </div>
        </div>

        <div className={classes.justifyLeft}>
          <Typography component="div" className={classes.commentBody}>
            <span>
              <RichTextFormattedValue value={commentText} plain />
            </span>{' '}
            {commentType === COMMENT_TYPES.jobInvoice && extraData && (
              <>
                <br />
                <Link href={extraData.link} target="_blank" variant="caption">
                  {extraData.identifier || 'View Invoice'}
                </Link>
              </>
            )}
            {wasCommentEdited && <span className={classes.commentEditedSpan}>(edited)</span>}
            {props.repliedTo && (
              <CommentRepliedTo
                {...props.repliedTo}
                content={<RichTextFormattedValue value={props.repliedTo.content} plain />}
              />
            )}
          </Typography>
        </div>
      </ListItem>
      <Dialog
        isDialogOpen={isDialogOpen}
        title="Edit comment"
        closeDialog={toggleDialogOpen}
        noButton
      >
        <AnnotationCommentForm
          initialValue={commentText}
          closeDialog={toggleDialogOpen}
          handleOnSubmit={onEditSave}
        />
      </Dialog>
    </Fragment>
  );
};

CommentCard.propTypes = {
  /** Determines text-alignment direction: "start" | "end" */
  direction: PropTypes.string.isRequired,
  /** Text to render full name of commenter: "Ben Smith" */
  fullName: PropTypes.string.isRequired,
  /** URL to image to render comment user's avatar: "https://randomuser.me/api/portraits/men/86.jpg" */
  avatarSrc: PropTypes.string,
  /** Text to render date of comment: JS Date object */
  date: PropTypes.string.isRequired,
  /** Text to render the asset review revision number: 10 */
  assetIndex: PropTypes.number,
  /** Text to render comment text: "Lorem ipsum" */
  commentText: PropTypes.string.isRequired,
  handleReply: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSelectAsset: PropTypes.func,
  // pass custom update comment function, if not passed, default func will be used
  handleUpdate: PropTypes.func,
  // even if currentUser but we don't want to allow edit
  noEditButton: PropTypes.bool,
  // was original comment edited - will add (edited) span at the end of the text
  wasCommentEdited: PropTypes.bool
};

CommentCard.defaultProps = {
  direction: 'start',
  firstName: '',
  lastName: '',
  avatarSrc: '',
  date: null,
  revisionNumber: 0,
  commentText: '',
  noEditButton: false,
  wasCommentEdited: false
};

export default CommentCard;

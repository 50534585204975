/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SolicitationCommentThread_solicitation$ref: FragmentReference;
declare export opaque type SolicitationCommentThread_solicitation$fragmentType: SolicitationCommentThread_solicitation$ref;
export type SolicitationCommentThread_solicitation = {|
  +solicitationId: ?string,
  +state: SolicitationState,
  +comments: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +content: ?string,
        +created: any,
        +contentEditedAt: ?any,
        +repliedTo: ?{|
          +content: ?string,
          +created: ?any,
          +user: ?{|
            +fullName: ?string
          |},
        |},
        +contractor: {|
          +fullName: ?string,
          +representativeImageUrl: ?string,
          +freelancer: ?{|
            +user: {|
              +id: string
            |}
          |},
        |},
        +createdByStaff: ?{|
          +fullName: ?string,
          +representativeImageUrl: ?string,
          +user: {|
            +id: string
          |},
        |},
      |}
    |}>
  |},
  +$refType: SolicitationCommentThread_solicitation$ref,
|};
export type SolicitationCommentThread_solicitation$data = SolicitationCommentThread_solicitation;
export type SolicitationCommentThread_solicitation$key = {
  +$data?: SolicitationCommentThread_solicitation$data,
  +$fragmentRefs: SolicitationCommentThread_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ]
};
return {
  "kind": "Fragment",
  "name": "SolicitationCommentThread_solicitation",
  "type": "SolicitationNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "id",
      "type": "ID!",
      "defaultValue": ""
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "solicitationId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "comments",
      "storageKey": null,
      "args": null,
      "concreteType": "SolicitationContractorLogNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SolicitationContractorLogNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SolicitationContractorLogNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "contentEditedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "repliedTo",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CommentRepliedToNode",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "user",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contractor",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractorNode",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "freelancer",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "FreelancerNode",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "createdByStaff",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '47e32f64c0e0d6c500efbb52c58764ea';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import useInterval from '../../hooks/useInterval';
import CreateJobCommentMutation from '../../mutations/comments/CreateJobCommentMutation';
import DeleteJobCommentMutation from '../../mutations/comments/DeleteJobCommentMutation';
import DetailsSection from '../common/DetailsSection';
import CommentThread from '../comment/CommentThread';
import { mapJobComments } from '../../utils/helpers';

const JobComments = props => {
  const { comments, jobId, refetch, canAddComment } = props;

  useInterval(() => {
    refetch({ id: jobId }, null, null, { force: true });
  }, 60000);

  const handleSubmitComment = ({ comment, repliedTo }, cb) => {
    CreateJobCommentMutation(
      {
        jobId: jobId,
        content: comment,
        ...(repliedTo && { repliedTo: repliedTo.commentId })
      },
      response => {
        if (response && response.job) {
          cb();
        }
      }
    );
  };

  const handleDeleteComment = commentId => {
    DeleteJobCommentMutation(commentId, () => {});
  };

  return (
    <DetailsSection title="Comments" noBorder>
      <CommentThread
        comments={mapJobComments(comments.edges)}
        formTitle=""
        handleAddComment={handleSubmitComment}
        handleReplyToComment={canAddComment ? handleSubmitComment : null}
        disabled={!canAddComment}
        handleDeleteComment={handleDeleteComment}
      />
    </DetailsSection>
  );
};

export default JobComments;

JobComments.propTypes = {
  comments: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string.isRequired,
          created: PropTypes.string.isRequired,
          content: PropTypes.string.isRequired,
          contentEditedAt: PropTypes.string,
          user: PropTypes.shape({
            id: PropTypes.string.isRequired,
            fullName: PropTypes.string.isRequired,
            representativeImageUrl: PropTypes.string
          }).isRequired
        }).isRequired
      })
    ).isRequired
  }),
  jobId: PropTypes.string.isRequired,
  canAddComment: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired
};

JobComments.defaultProps = {
  canAddComment: true
};

import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import AssetList from '../../../../../shared/components/assets/AssetList';

const PortfolioList = props => {
  const {
    contractor: {
      contractorPortfolio: {
        pageInfo: { hasNextPage, endCursor },
        totalCount,
        edgeCount,
        edges
      }
    },
    relay: { refetch }
  } = props;

  return (
    <AssetList
      edges={edges}
      emptyListText="The contractor has not uploaded any files to their portfolio."
      refetch={refetch}
      shouldShowUploadButton={false}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
    />
  );
};

export default createRefetchContainer(
  PortfolioList,
  {
    contractor: graphql`
      fragment PortfolioList_contractor on ContractorNode {
        contractorPortfolio(first: $first, after: $after) {
          totalCount
          edgeCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              fileName
              fileUrl
              description
              reviewInterface
            }
          }
        }
      }
    `
  },
  graphql`
    query PortfolioListRefetchQuery($id: ID!, $first: Int, $after: String) {
      contractor(id: $id) {
        ...PortfolioList_contractor
      }
    }
  `
);

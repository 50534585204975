import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeclineJobByContractorMutation($input: DeclineJobByContractorInput!) {
    declineJobByContractor(input: $input) {
      job {
        id
        status
        deliverables {
          edges {
            node {
              state
            }
          }
        }
      }
    }
  }
`;

export default (id, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.declineJobByContractor);
    },
    onError: err => console.error(err)
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';

import ThumbnailImage from '../../UI/ThumbnailImage';
import UploadImageButton from '../UploadImageButton';
import SquareButton from '../../UI/SquareButton';
import { useDetailsStyles } from '../../../styles/common/useDetailsStyles';

const ThumbnailEditActionsContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    '& > :first-child': {
      marginLeft: 0,
      marginRight: theme.spacing(1)
    }
  }),
  { withTheme: true }
);

const EditableThumbnail = props => {
  const { thumbnailProps, editing, uploadButtonProps, deleteButtonProps } = props;
  const classes = useDetailsStyles();

  const onChangeHandler = (file, dataUrl) => {
    uploadButtonProps.handleChange(file, dataUrl);
  };

  return (
    <div className={classes.containerColumn}>
      <ThumbnailImage {...thumbnailProps} />
      {editing && (
        <ThumbnailEditActionsContainer>
          <div>
            <UploadImageButton id={uploadButtonProps.id} handleChange={onChangeHandler}>
              Upload Image
            </UploadImageButton>
            <SquareButton icon="trash" {...deleteButtonProps} />
          </div>
        </ThumbnailEditActionsContainer>
      )}
    </div>
  );
};

EditableThumbnail.propTypes = {
  editing: PropTypes.bool.isRequired,
  thumbnailProps: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired
  }).isRequired,
  uploadButtonProps: PropTypes.shape({
    id: PropTypes.string,
    handleChange: PropTypes.func
  }), //shouldn't be required because thumbnail is not editable everywhere
  deleteButtonProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func
  }) //shouldn't be required because thumbnail is not editable everywhere
};

export default EditableThumbnail;

import React, { useState, Fragment, useRef } from 'react';
import GlobalButton from '../UI/GlobalButton';

const UploadFileButton = props => {
  const { handleChange, disabled, ...otherProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  const onClickHandler = () => {
    inputRef.current.click();
  };

  const onChangeHandler = event => {
    setIsLoading(true);
    handleChange(event.currentTarget.files[0], () => {
      setIsLoading(false);
      inputRef.current.value = '';
    });
  };

  return (
    <Fragment>
      <GlobalButton
        noMargin
        loading={isLoading}
        disabled={disabled || isLoading}
        variant="secondary"
        handleClick={onClickHandler}
        {...otherProps}
      >
        Upload File
      </GlobalButton>
      <input ref={inputRef} type="file" style={{ display: 'none' }} onChange={onChangeHandler} />
    </Fragment>
  );
};

export default UploadFileButton;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DetailsDescription_deliverable$ref: FragmentReference;
declare export opaque type DetailsDescription_deliverable$fragmentType: DetailsDescription_deliverable$ref;
export type DetailsDescription_deliverable = {|
  +description: ?string,
  +state: DeliverableState,
  +$refType: DetailsDescription_deliverable$ref,
|};
export type DetailsDescription_deliverable$data = DetailsDescription_deliverable;
export type DetailsDescription_deliverable$key = {
  +$data?: DetailsDescription_deliverable$data,
  +$fragmentRefs: DetailsDescription_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DetailsDescription_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ba63d005784c271a899821115ad36696';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReleaseToolsActions_release$ref: FragmentReference;
declare export opaque type ReleaseToolsActions_release$fragmentType: ReleaseToolsActions_release$ref;
export type ReleaseToolsActions_release = {|
  +id: string,
  +isActive: boolean,
  +hasDeliverables: ?boolean,
  +budgetInfo: ?$ReadOnlyArray<?{|
    +budgetAmount: ?any
  |}>,
  +$refType: ReleaseToolsActions_release$ref,
|};
export type ReleaseToolsActions_release$data = ReleaseToolsActions_release;
export type ReleaseToolsActions_release$key = {
  +$data?: ReleaseToolsActions_release$data,
  +$fragmentRefs: ReleaseToolsActions_release$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ReleaseToolsActions_release",
  "type": "ReleaseNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetInfo",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "budgetAmount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '73f54fef35b61db74f41b9af83095e3c';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DetailsMainContent_deliverable$ref: FragmentReference;
declare export opaque type DetailsMainContent_deliverable$fragmentType: DetailsMainContent_deliverable$ref;
export type DetailsMainContent_deliverable = {|
  +id: string,
  +category: {|
    +name: string
  |},
  +categoryType: ?{|
    +name: string
  |},
  +job: ?{|
    +jobDeadline: ?any,
    +contract: ?{|
      +dateSignedByContractor: ?any
    |},
  |},
  +$refType: DetailsMainContent_deliverable$ref,
|};
export type DetailsMainContent_deliverable$data = DetailsMainContent_deliverable;
export type DetailsMainContent_deliverable$key = {
  +$data?: DetailsMainContent_deliverable$data,
  +$fragmentRefs: DetailsMainContent_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "DetailsMainContent_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "category",
      "storageKey": null,
      "args": null,
      "concreteType": "CategoryNode",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "categoryType",
      "storageKey": null,
      "args": null,
      "concreteType": "CategoryTypeNode",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "job",
      "storageKey": null,
      "args": null,
      "concreteType": "JobNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "jobDeadline",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contract",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "dateSignedByContractor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c6d2ec918b16d2541a8abc493a78a03';
module.exports = node;

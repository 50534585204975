import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeleteProductMutation($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      isDeleted
    }
  }
`;

const DeleteProductMutation = (id, callback) => {
  const config = {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.deleteProduct);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default DeleteProductMutation;

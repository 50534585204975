/**
 * @flow
 * @relayHash ed5073d0fa1508bfec252d024effb0c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRoleBatchStaffUsersInput = {|
  ids: $ReadOnlyArray<?string>,
  role: string,
  clientMutationId?: ?string,
|};
export type UpdateRoleBatchStaffUsersMutationVariables = {|
  input: UpdateRoleBatchStaffUsersInput
|};
export type UpdateRoleBatchStaffUsersMutationResponse = {|
  +updateRoleBatchStaffUsers: ?{|
    +updatedStaffUsers: ?$ReadOnlyArray<?{|
      +id: string,
      +roleLabel: ?string,
      +role: ?{|
        +id: string,
        +numberOfStaff: ?number,
      |},
    |}>,
    +oldRoles: ?$ReadOnlyArray<?{|
      +id: string,
      +numberOfStaff: ?number,
    |}>,
  |}
|};
export type UpdateRoleBatchStaffUsersMutation = {|
  variables: UpdateRoleBatchStaffUsersMutationVariables,
  response: UpdateRoleBatchStaffUsersMutationResponse,
|};
*/


/*
mutation UpdateRoleBatchStaffUsersMutation(
  $input: UpdateRoleBatchStaffUsersInput!
) {
  updateRoleBatchStaffUsers(input: $input) {
    updatedStaffUsers {
      id
      roleLabel
      role {
        id
        numberOfStaff
      }
    }
    oldRoles {
      id
      numberOfStaff
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRoleBatchStaffUsersInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "numberOfStaff",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateRoleBatchStaffUsers",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateRoleBatchStaffUsersPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedStaffUsers",
        "storageKey": null,
        "args": null,
        "concreteType": "StaffNode",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "roleLabel",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "role",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationRoleNode",
            "plural": false,
            "selections": (v2/*: any*/)
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "oldRoles",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationRoleNode",
        "plural": true,
        "selections": (v2/*: any*/)
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateRoleBatchStaffUsersMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateRoleBatchStaffUsersMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateRoleBatchStaffUsersMutation",
    "id": null,
    "text": "mutation UpdateRoleBatchStaffUsersMutation(\n  $input: UpdateRoleBatchStaffUsersInput!\n) {\n  updateRoleBatchStaffUsers(input: $input) {\n    updatedStaffUsers {\n      id\n      roleLabel\n      role {\n        id\n        numberOfStaff\n      }\n    }\n    oldRoles {\n      id\n      numberOfStaff\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e7ecb8a3b1cc241f2ec31560b07f8ec';
module.exports = node;

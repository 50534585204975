import React, { useState, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { Box } from '@material-ui/core';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import DetailsSection from '../../../shared/components/common/DetailsSection';
import AutoTable from '../../../shared/components/table/AutoTable';
import { FormikCheckbox } from '../../../shared/components/form/FormikCheckbox';

import CreateContractorGroupMutation from './mutations/CreateContractorGroupMutation';
import UpdateContractorGroupMutation from './mutations/UpdateContractorGroupMutation';
import appStyles from '../../styles/modalForms';

import { CELL_TYPES } from '../../../shared/constants';
import { errorToast, successToast } from '../../../shared/toasts';
import SearchableContractorDropdown from './GroupSearchableContractorDropdown';

const CreateGroupForm = props => {
  // this component is used when creating new group with context action, when creating new group from group tab component,
  // when duplicating group from group list,
  // and when editing group upon click on group name in group list
  const {
    // used when opened from Group List
    actionType,
    group,
    setRefetchCounter,
    // used when opened from Global context action
    contextAction,
    isContextual,
    toRefetchData,
    // required in both cases
    handleClose
  } = props;

  const getInitialState = () => {
    if (isContextual) {
      return contextAction.data.checked.map(item => ({
        ...item,
        fullName: item.name.name
      }));
    } else if (group && Object.keys(group).length !== 0) {
      return group.contractors.edges.map(item => item.node);
    } else {
      return [];
    }
  };

  const [contractors, setContractors] = useState(getInitialState());
  const classes = appStyles();

  const matchGroupName = () => {
    if (group && Object.keys(group).length !== 0) {
      return group.name;
    } else return '';
  };

  const afterCreate = () => {
    setContractors([]);
    // this will happen when creating group directly from list page
    if (typeof setRefetchCounter === 'function') {
      setRefetchCounter(counter => counter + 1);
    }
    // this will happen when using context aciton
    if (typeof toRefetchData === 'function') {
      toRefetchData();
    }
    successToast('New group was created.');
    handleClose();
  };

  const groupFormInitialValues = {
    name: matchGroupName(),
    groupId: (group && group.id) || '',
    isPublic: (group && group.isPublic) || false,
    contractors
  };

  const adjustedEdges = contractors.map(contractor => {
    return {
      node: {
        ...contractor
      }
    };
  });

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    if (contractors.length < 1) {
      errorToast('You must select at least one contractor.');
      setSubmitting(false);
      return;
    }
    setSubmitting(true);
    const { groupId, name, isPublic } = values;
    const mappedContractors = contractors.map(contractor => contractor.id);

    if (actionType === 'EDIT') {
      UpdateContractorGroupMutation(
        {
          groupId,
          name,
          contractors: mappedContractors,
          isPublic
        },
        (response, errors) => {
          setSubmitting(false);
          if (errors && errors[0].fields) {
            setErrors(errors[0].fields);
          }
          if (response) {
            handleClose();
          }
        }
      );
    } else {
      CreateContractorGroupMutation(
        {
          name,
          contractors: mappedContractors,
          isPublic
        },
        (response, errors) => {
          setSubmitting(false);
          if (errors && errors[0].fields) {
            setErrors(errors[0].fields);
          }
          if (response) {
            afterCreate();
          }
        }
      );
    }
  };

  const GroupFormValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters.')
      .max(30, 'Please enter no more than 30 characters.')
      .matches(/^(?!\s*$).+/, 'Please enter at least one letter or number.')
      .required('Required')
  });

  const handleAddContractor = option => {
    setContractors([...contractors, option]);
  };

  const onRemoveClickHandler = selectedId => {
    setContractors(contractors.filter(contractor => contractor.id !== selectedId));
  };

  return (
    <Fragment>
      <Formik
        initialValues={groupFormInitialValues}
        validationSchema={GroupFormValidationSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field fullWidth name="name" label="Group Name" component={FormikTextField} />
            <Field component={FormikCheckbox} name="isPublic" label="Is Public" />
            <div className={classes.marginTop}>
              <DetailsSection noBorder title="Contractors in group">
                <SearchableContractorDropdown
                  contractorsInList={contractors}
                  handleAddContractor={handleAddContractor}
                />
                <Box maxHeight={450} overflow="scroll">
                  <AutoTable
                    edges={adjustedEdges || []}
                    rowTemplate={[
                      { name: 'fullName', type: CELL_TYPES.linkWithAvatar, label: 'Name' },
                      //Disabling Ratings from UI until feature fully fleshed out
                      // { name: 'rating', type: CELL_TYPES.rating, label: 'Rating' },
                      {
                        name: 'trash',
                        type: CELL_TYPES.trash,
                        label: '',
                        onClick: onRemoveClickHandler,
                        tooltip: 'Remove'
                      }
                    ]}
                  />
                </Box>
              </DetailsSection>
            </div>
            <div className={classes.buttons}>
              <GlobalButton variant="transparent" handleClick={handleClose} disabled={isSubmitting}>
                Cancel
              </GlobalButton>
              <GlobalButton type="submit" disabled={isSubmitting}>
                {actionType === 'EDIT' ? 'Save' : 'Add Group'}
              </GlobalButton>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default CreateGroupForm;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import ProductReleasesList from './ProductReleasesList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';

const ProductReleasesTab = props => {
  const { id } = useParams();
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ProductReleasesTabQuery($id: ID!, $first: Int, $orderBy: String, $after: String) {
          product(id: $id) {
            ...ProductReleasesList_product
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => <ProductReleasesList product={relayProps.product} {...props} />}
    />
  );
};

export default withTableHistoryProvider(ProductReleasesTab, 'orgProductReleases', {
  orderBy: 'name'
});

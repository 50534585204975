/**
 * @flow
 * @relayHash 20356413fc66a01ed7e5761ff3e140d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractTemplateState = "A_1" | "A_2" | "A_3" | "%future added value";
export type MarkContractTemplateInactiveInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type MarkContractTemplateInactiveMutationVariables = {|
  input: MarkContractTemplateInactiveInput
|};
export type MarkContractTemplateInactiveMutationResponse = {|
  +markContractTemplateInactive: ?{|
    +contractTemplate: ?{|
      +id: string,
      +state: ContractTemplateState,
    |}
  |}
|};
export type MarkContractTemplateInactiveMutation = {|
  variables: MarkContractTemplateInactiveMutationVariables,
  response: MarkContractTemplateInactiveMutationResponse,
|};
*/


/*
mutation MarkContractTemplateInactiveMutation(
  $input: MarkContractTemplateInactiveInput!
) {
  markContractTemplateInactive(input: $input) {
    contractTemplate {
      id
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkContractTemplateInactiveInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "markContractTemplateInactive",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkContractTemplateInactivePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractTemplate",
        "storageKey": null,
        "args": null,
        "concreteType": "ContractTemplateNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MarkContractTemplateInactiveMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MarkContractTemplateInactiveMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MarkContractTemplateInactiveMutation",
    "id": null,
    "text": "mutation MarkContractTemplateInactiveMutation(\n  $input: MarkContractTemplateInactiveInput!\n) {\n  markContractTemplateInactive(input: $input) {\n    contractTemplate {\n      id\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1047f32df74b5a90d41e9721ed24a6c7';
module.exports = node;

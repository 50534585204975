import React, { Fragment } from 'react';

import ContractorDeliverablesTopSection from './ContractorDeliverablesTopSection';
import ContractorDeliverablesList from './ContractorDeliverablesList';

const ContractorDeliverablesContent = props => {
  const { relayProps, refetchCounter } = props;

  return (
    <Fragment>
      <ContractorDeliverablesTopSection
        relayProps={relayProps}
        contractor={relayProps.contractor}
      />
      <ContractorDeliverablesList
        relayProps={relayProps}
        contractor={relayProps.contractor}
        stateTypes={relayProps.deliverableState}
        refetchCounter={refetchCounter}
      />
    </Fragment>
  );
};

export default ContractorDeliverablesContent;

import React, { Fragment, useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Grid } from '@material-ui/core';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import QuickViewAvatarHeader from '../../../../../shared/components/UI/QuickViewAvatarHeader';
import TagsGrid from '../../../../../shared/components/UI/TagsGrid';
import DetailsRowAmount from '../../../../../shared/components/UI/DetailsRowAmount';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import { getCountryName, getEnvironment } from '../../../../../shared/utils/helpers';

const contractorQuickViewQuery = graphql`
  query ContractorsQuickViewQuery($id: ID!) {
    contractor(id: $id) {
      fullName
      country {
        name
      }
      tags {
        edges {
          node {
            id
            name
          }
        }
      }
      representativeImageUrl
      email
      phone
      skype
      timezone
      activeDeliverablesCount
      pastDueDeliverablesCount
      activeJobsCount
      completedJobsCount
      jobsLifetimeValue
      jobsThisYearValue
      assignedDeliverables {
        edges {
          node {
            state
          }
        }
      }
      assignedJobs {
        edges {
          node {
            status
          }
        }
      }
    }
  }
`;

const ContractorsQuickView = props => {
  const { contractorId } = props;
  const [contractor, setContractor] = useState(null);

  useEffectWithStatus(
    status => {
      setContractor(null);
      fetchQuery(getEnvironment(), contractorQuickViewQuery, { id: contractorId }).then(
        response => {
          status.mounted && setContractor(response.contractor);
        }
      );
    },
    [contractorId]
  );

  if (contractor) {
    const {
      fullName,
      country,
      representativeImageUrl,
      timezone,
      email,
      phone,
      skype,
      activeDeliverablesCount,
      pastDueDeliverablesCount,
      activeJobsCount,
      completedJobsCount,
      jobsLifetimeValue,
      jobsThisYearValue,
      tags
    } = contractor;

    return (
      <Fragment>
        <QuickViewAvatarHeader
          fullName={fullName}
          representativeImageUrl={representativeImageUrl}
          // rating={rating}
        />
        <DetailsSection title="Tags">
          <TagsGrid tags={tags.edges} withMessage />
        </DetailsSection>
        <DetailsSection title="Contact Details">
          <DetailsRow titleWidth={50} title="Email" content={email} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DetailsRow titleWidth={50} title="Phone" content={phone} />
              <DetailsRow titleWidth={50} title="Skype" content={skype} />
            </Grid>
            <Grid item xs={6}>
              <DetailsRow titleWidth={50} title="Location" content={getCountryName(country)} />
              <DetailsRow titleWidth={50} title="Timezone" content={timezone} />
            </Grid>
          </Grid>
        </DetailsSection>
        <DetailsSection title="Work Summary">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DetailsRow title="Active Deliverables" content={activeDeliverablesCount} />
              <DetailsRow title="Active Jobs" content={activeJobsCount} />
            </Grid>
            <Grid item xs={6}>
              <DetailsRow title="Past Due Deliverables" content={pastDueDeliverablesCount} />
              <DetailsRow title="Completed Jobs" content={completedJobsCount} />
            </Grid>
          </Grid>
        </DetailsSection>
        <DetailsSection title="Payout Summary">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DetailsRowAmount title="Lifetime Payout" amount={jobsLifetimeValue} />
            </Grid>
            <Grid item xs={6}>
              <DetailsRowAmount title="Year-to-Date Payout" amount={jobsThisYearValue} />
            </Grid>
          </Grid>
        </DetailsSection>
      </Fragment>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default ContractorsQuickView;

export const GLOBAL_BUTTON_TYPES = [
  'primary',
  'secondary',
  'alternative',
  'transparent',
  'highlighted',
  'black',
  'acceptation'
];

export const CELL_TYPES = {
  link: 'link',
  relationLink: 'relationLink',
  linkWithIcon: 'linkWithIcon',
  info: 'info',
  amount: 'amount',
  organization: 'organization',
  listWithBadge: 'listWithBadge',
  listWithMultiple: 'listWithMultiple',
  rating: 'rating',
  addUser: 'addUser',
  avatar: 'avatar',
  avatarWithLink: 'avatarWithLink',
  thumbnail: 'thumbnail',
  status: 'status',
  date: 'date',
  message: 'message',
  checkbox: 'checkbox',
  trash: 'trash',
  visibility: 'visibility',
  duplicate: 'duplicate',
  contract: 'contract',
  flags: 'flags',
  actions: 'actions',
  edit: 'edit',
  upDown: 'upDown',
  dateWithWarning: 'dateWithWarning',
  fileDownload: 'fileDownload',
  briefcase: 'briefcase',
  warning: 'warning'
};

export const CELL_WIDTHS = {
  [CELL_TYPES.amount]: '90px',
  [CELL_TYPES.checkbox]: '42px',
  [CELL_TYPES.info]: '36px',
  [CELL_TYPES.trash]: '32px'
};

export const TOOLS_DESCRIPTIONS = {
  // Contractors
  doNotHire:
    'If you flag this Contractor with "Do Not Hire," it will be blocked from general searches and cannot be added to any Jobs. The Contractor will not receive any notification of this status.',
  markContractorInactive:
    'Mark this Contractor Inactive to hide it from general searches and prevent it being added to new Jobs, without resorting to the "Do Not Hire" Administrative action. The Contractor will not receive any notification of being marked Inactive.',
  activateContractor:
    'Return this Inactive Contractor to the pool of Contractors available to your Organization. The Contractor will not receive any notification that they have been returned to Active status.',

  // Products
  deleteProductFunc: (productLabel, releaseLabel) =>
    `This ${productLabel} can only be deleted when it has no associated ${releaseLabel}. Once deleted, it will no longer be available to your Organization or appear in your AWEbase account.`,
  markProductInactive:
    'Mark this record Inactive to hide it from general searches and prevent it from being managed or developed any further by your Organization staff.',
  activateProduct:
    'Return this Inactive record to the Active status for your Organization. Once Active it can be managed by your Organization staff, as usual.',

  // Releases
  deleteReleaseFunc: releaseLabel =>
    `This ${releaseLabel} can only be deleted when it has no associated Deliverables. Once deleted, it will no longer be available to your Organization or appear in your AWEbase account.`,
  markReleaseDetailsInactive:
    'Mark this record Inactive to hide it from general searches and prevent it from being managed or developed any further by your Organization staff.',
  activateReleaseDetails:
    'Return this Inactive record to the Active status for your Organization. Once Active it can be managed by your Organization staff, as usual.',
  deleteReleaseBudget:
    'Eliminate budget considerations for this record. All budget data will be lost.',

  //Jobs
  revokeContract:
    'This Job has a contract that has not been signed by the Contractor. If Revoked, the Contract will no longer be available to the Contractor. All the Deliverables will return to the Assignable state, in preparation for inclusion in a new Job.',
  cancelJob:
    'This Job is in progress and may have an active Contract on file. By Canceling the Job, all the Deliverables in this Job will be canceled. A new, duplicate set of the Deliverables will be added to your Organization as Assignable, in preparation for a new Job.',

  //Deliverables

  markReadyDeliverable:
    'If this Deliverable is fully defined with all the information required, mark it Assignable so it can be used in JobOpps and added to a Job.',
  revertToDraftDeliverable:
    'If you return this Deliverable to the Draft state, it will no longer be available for JobOpps or Jobs.',
  markCompletedDeliverable:
    'If there is no further work to be done for this Deliverable, mark it Complete. All of the Deliverables for a Job need to be marked as Complete before the Order to Pay can be initiated.',
  reopenDeliverable: 'Reopen this Deliverable to return it to the In Progress state.',
  unlockDeliverable:
    'This Deliverable is Locked. It should only remain Locked while a staff member is preparing a new Job with that Deliverable. Click the button to manually unlock this Deliverable and allow staff to manage the Deliverable, as usual.',
  markDeliverableInactive:
    'Mark this Deliverable Inactive to remove it from the master list of Deliverables and keep it hidden from general searches.',
  activateDeliverable:
    'Return this Inactive Deliverable to the Active status for your Organization. Once Active it can be managed by Organization staff, as usual.',
  deleteDeliverable:
    "Deliverable can only be deleted when it's Inactive or Draft. Once deleted, it will no longer be available to your Organization or appear in your AWEbase account.",
  resetDeliverable:
    'An irreversible action, used to reset an internal Deliverable to contract it externally.  This Deliverable will be canceled, and a duplicate will be created in the Assignable state.',
  cancelDeliverable:
    'An irreversible action, used to cancel internal Deliverables which do not belong to a Job while in progress.'
};

export const SOLICITATION_STATES = {
  draft: 'A_1',
  active: 'A_2',
  closed: 'A_3'
};

export const JOB_STATUSES = {
  preparing: 'A_1',
  awaiting_signature: 'A_2',
  in_progress: 'A_3',
  delivered: 'A_4',
  closed: 'A_5',
  canceled: 'A_6',
  revoked: 'A_7',
  declined: 'A_8'
};

export const CONTRACT_TEMPLATE_STATES = {
  draft: 'A_1',
  active: 'A_2',
  inactive: 'A_3'
};

export const DELIVERABLE_STATES = {
  draft: 'A_1',
  ready: 'A_2',
  locked: 'A_3',
  pendingSignature: 'A_4',
  inProgress: 'A_5',
  completed: 'A_6',
  closed: 'A_7',
  canceled: 'A_8',
  inactive: 'A_9'
};

export const REVIEW_INTERFACES = {
  image: 'image',
  audio: 'audio',
  generic: 'generic',
  video: 'video',
  a3dModel: '3d_model',
  document: 'document'
};

export const FILE_STORAGE_CLASSES = {
  STANDARD: 'STANDARD',
  RESTORING: 'RESTORING',
  GLACIER: 'GLACIER'
};

export const VIDEO_MIME_TYPE_MAPPER = {
  mp4: 'video/mp4',
  avi: 'video/x-msvideo',
  wmv: 'video/x-ms-wmv'
};

export const getSizeLabel = size => `File size must be less than ${size} MB`;

export const ERROR_LABELS = {
  fileNameRequired: 'File name is required',
  fileRequired: 'A file is required',
  fileTooLarge: {
    contractorBackground: getSizeLabel(10),
    avatar: getSizeLabel(5),
    default: getSizeLabel(10),
    thumbnail: getSizeLabel(2)
  },
  fileTooLargeWithByteSize: size => {
    return getSizeLabel(size / 1000000);
  }
};

export const getSizeInBytes = mb => mb * 1000 * 1000;

export const ACCEPTED_FILE_SIZES = {
  contractorBackground: getSizeInBytes(10),
  avatar: getSizeInBytes(5),
  thumbnail: getSizeInBytes(2),
  portfolio: getSizeInBytes(10),
  default: getSizeInBytes(10)
};

export const PAGINATION_TYPES = {
  default: { name: 'default', values: [10, 20], defaultValue: 20 },
  short: { name: 'short', values: [4, 8, 16], defaultValue: 4 }
};

export const phoneRegExp = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
export const AUDIT_LOG_ACTIONS = {
  create: 'A_0',
  update: 'A_1',
  delete: 'A_2'
};

export const ORGANIZATION_STATUS_FOR_FREELANCER = {
  pendingInvitation: 'Pending invitation',
  connected: 'Connected'
};

export const BILLING_ACTIVITY_STATES = {
  draft: 'A_1',
  open: 'A_2',
  paid: 'A_3',
  uncollectible: 'A_4',
  void: 'A_5'
};

export const PAYMENT_REQUESTS_STATES = {
  received: 'A_1',
  requested: 'A_2',
  declined: 'A_3',
  paymentSent: 'A_4',
  paid: 'A_5'
};

export const JOB_PAYMENT_STATUS = {
  noRequests: 'A_1',
  partiallyRequested: 'A_2',
  partiallySent: 'A_3',
  fullyRequested: 'A_4',
  fullySent: 'A_5'
};

export const JOB_INVOICE_STATES = {
  new: 'A_1',
  paymentRequested: 'A_2',
  declined: 'A_3',
  paymentSent: 'A_4',
  paymentReceived: 'A_5'
};

export const COMMENT_TYPES = {
  text: 'TEXT',
  jobInvoice: 'JOB_INVOICE'
};

export const ALLOWED_ACTIONS = {
  ADD_FOLLOWERS: 'ADD_FOLLOWERS',
  ORGANIZATION_ADMIN_MANAGE_ORGANIZATION_PROFILE: 'ORGANIZATION_ADMIN_MANAGE_ORGANIZATION_PROFILE',
  JOB_UPLOAD_ASSOCIATED_FILES_OF_CONTRACT: 'JOB_UPLOAD_ASSOCIATED_FILES_OF_CONTRACT',
  PRODUCT_DELETE: 'PRODUCT_DELETE',
  SOLICITATION_REPLY_TO_SOLICITATION_MESSAGE_ASSIGNED_TO_ANOTHER_USER:
    'SOLICITATION_REPLY_TO_SOLICITATION_MESSAGE_ASSIGNED_TO_ANOTHER_USER',
  PRODUCT_EDIT_DETAILS: 'PRODUCT_EDIT_DETAILS',
  DELIVERABLE_UPLOAD_REVIEW_ASSET_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_UPLOAD_REVIEW_ASSET_ASSIGNED_TO_AUTHENTICATED_USER',
  DELIVERABLE_MARK_READY_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_MARK_READY_ASSIGNED_TO_AUTHENTICATED_USER',
  DELIVERABLE_DOWNLOAD_FINAL_FILE: 'DELIVERABLE_DOWNLOAD_FINAL_FILE',
  JOB_TERMINATE_SIGNED_CONTRACT: 'JOB_TERMINATE_SIGNED_CONTRACT',
  JOB_RESEND_CONTRACT: 'JOB_RESEND_CONTRACT',
  JOB_CLOSE_JOB: 'JOB_CLOSE_JOB',
  JOB_MANAGE_PAYMENT_REQUESTS: 'JOB_MANAGE_PAYMENT_REQUESTS',
  ORGANIZATION_ADMIN_MANAGE_STAFF_ROLE_AND_PERMISSIONS:
    'ORGANIZATION_ADMIN_MANAGE_STAFF_ROLE_AND_PERMISSIONS',
  DELIVERABLE_CREATE: 'DELIVERABLE_CREATE',
  RELEASE_DEACTIVATE: 'RELEASE_DEACTIVATE',
  SOLICITATION_MARK_CLOSED_ASSIGNED_TO_ANOTHER_USER:
    'SOLICITATION_MARK_CLOSED_ASSIGNED_TO_ANOTHER_USER',
  JOB_ASSIGN_RATING_TO_CLOSED: 'JOB_ASSIGN_RATING_TO_CLOSED',
  DELIVERABLE_DELETE: 'DELIVERABLE_DELETE',
  DELIVERABLE_DEACTIVATE: 'DELIVERABLE_DEACTIVATE',
  CONTRACTOR_UPLOAD_SHOWCASE_FILE: 'CONTRACTOR_UPLOAD_SHOWCASE_FILE',
  DELIVERABLE_CHANGE_CURRENT_REVIEW_STEP_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_CHANGE_CURRENT_REVIEW_STEP_ASSIGNED_TO_ANOTHER_USER',
  PRODUCT_CREATE: 'PRODUCT_CREATE',
  CONTRACTOR_MARK_PROFILE_INACTIVE: 'CONTRACTOR_MARK_PROFILE_INACTIVE',
  RELEASE_EDIT_DETAILS: 'RELEASE_EDIT_DETAILS',
  SOLICITATION_REASSIGN_TO_ANOTHER_STAFF_MEMBER: 'SOLICITATION_REASSIGN_TO_ANOTHER_STAFF_MEMBER',
  DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER',
  ORGANIZATION_ADMIN_MANAGE_CUSTOMIZATION: 'ORGANIZATION_ADMIN_MANAGE_CUSTOMIZATION',
  JOB_REVOKE_CONTRACT_IF_UNSIGNED_BY_FREELANCER: 'JOB_REVOKE_CONTRACT_IF_UNSIGNED_BY_FREELANCER',
  DELIVERABLE_ADD_REVIEW_COMMENT_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_ADD_REVIEW_COMMENT_ASSIGNED_TO_ANOTHER_USER',
  DELIVERABLE_DELETE_FINAL_FILE: 'DELIVERABLE_DELETE_FINAL_FILE',
  DELIVERABLE_ADD_REVIEW_COMMENT_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_ADD_REVIEW_COMMENT_ASSIGNED_TO_AUTHENTICATED_USER',
  DELIVERABLE_UNLOCK_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_UNLOCK_ASSIGNED_TO_AUTHENTICATED_USER',
  ORGANIZATION_ADMIN_MANAGE_CONTRACTS_TEMPLATE: 'ORGANIZATION_ADMIN_MANAGE_CONTRACTS_TEMPLATE',
  RELEASE_CREATE: 'RELEASE_CREATE',
  SOLICITATION_SEND_ASSIGNED_TO_ANOTHER_USER: 'SOLICITATION_SEND_ASSIGNED_TO_ANOTHER_USER',
  DELIVERABLE_UPLOAD_REFERENCE_FILE: 'DELIVERABLE_UPLOAD_REFERENCE_FILE',
  DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_AUTHENTICATED_USER',
  SOLICITATION_CREATE: 'SOLICITATION_CREATE',
  PRODUCT_DEACTIVATE: 'PRODUCT_DEACTIVATE',
  CONTRACTOR_EDIT_DETAILS: 'CONTRACTOR_EDIT_DETAILS',
  SOLICITATION_MARK_CLOSED_ASSIGNED_TO_AUTHENTICATED_USER:
    'SOLICITATION_MARK_CLOSED_ASSIGNED_TO_AUTHENTICATED_USER',
  SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER:
    'SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER',
  CONTRACTOR_ADD_NOTE: 'CONTRACTOR_ADD_NOTE',
  SOLICITATION_SEND_ASSIGNED_TO_AUTHENTICATED_USER:
    'SOLICITATION_SEND_ASSIGNED_TO_AUTHENTICATED_USER',
  DELIVERABLE_UPLOAD_REVIEW_ASSET_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_UPLOAD_REVIEW_ASSET_ASSIGNED_TO_ANOTHER_USER',
  ORGANIZATION_ADMIN_MANAGE_BILLING_INFO: 'ORGANIZATION_ADMIN_MANAGE_BILLING_INFO',
  SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER:
    'SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER',
  DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_ANOTHER_USER',
  DELIVERABLE_UPLOAD_FINAL_FILE_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_UPLOAD_FINAL_FILE_ASSIGNED_TO_ANOTHER_USER',
  RELEASE_EDIT_BUDGET: 'RELEASE_EDIT_BUDGET',
  RELEASE_VIEW_BUDGET: 'RELEASE_VIEW_BUDGET',
  DELIVERABLE_CHANGE_CURRENT_REVIEW_STEP_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_CHANGE_CURRENT_REVIEW_STEP_ASSIGNED_TO_AUTHENTICATED_USER',
  DELIVERABLE_UPLOAD_FINAL_FILE_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_UPLOAD_FINAL_FILE_ASSIGNED_TO_AUTHENTICATED_USER',
  SOLICITATION_REPLY_TO_SOLICITATION_MESSAGE_ASSIGNED_TO_AUTHENTICATED_USER:
    'SOLICITATION_REPLY_TO_SOLICITATION_MESSAGE_ASSIGNED_TO_AUTHENTICATED_USER',
  JOB_CREATE_WITHOUT_CONTRACT: 'JOB_CREATE_WITHOUT_CONTRACT',
  DELIVERABLE_UNLOCK_ASSIGNED_TO_ANOTHER_USER: 'DELIVERABLE_UNLOCK_ASSIGNED_TO_ANOTHER_USER',
  JOB_SIGN_AND_SEND_CONTRACT: 'JOB_SIGN_AND_SEND_CONTRACT',
  ORGANIZATION_ADMIN_MANAGE_STORAGE: 'ORGANIZATION_ADMIN_MANAGE_STORAGE',
  SOLICITATION_REVERT_SOLICITATION_TO_ACTIVE_ASSIGNED_TO_ANOTHER_USER:
    'SOLICITATION_REVERT_SOLICITATION_TO_ACTIVE_ASSIGNED_TO_ANOTHER_USER',
  DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER',
  SOLICITATION_REVERT_SOLICITATION_TO_ACTIVE_ASSIGNED_TO_AUTHENTICATED_USER:
    'SOLICITATION_REVERT_SOLICITATION_TO_ACTIVE_ASSIGNED_TO_AUTHENTICATED_USER',
  DELIVERABLE_MARK_READY_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_MARK_READY_ASSIGNED_TO_ANOTHER_USER',
  CONTRACTOR_MARK_DO_NOT_HIRE: 'CONTRACTOR_MARK_DO_NOT_HIRE',
  RELEASE_DELETE: 'RELEASE_DELETE',
  SOLICITATION_DELETE_ASSIGNED_TO_AUTHENTICATED_USER:
    'SOLICITATION_DELETE_ASSIGNED_TO_AUTHENTICATED_USER',
  CONTRACTOR_GROUP_CREATE: 'CONTRACTOR_GROUP_CREATE',
  JOB_ADD_COMMENT: 'JOB_ADD_COMMENT',
  DELIVERABLE_EXPOSE_REFERENCE_TO_SOLICITATION: 'DELIVERABLE_EXPOSE_REFERENCE_TO_SOLICITATION',
  JOB_EDIT_DETAILS: 'JOB_EDIT_DETAILS',
  DELIVERABLE_ASSIGN_INTERNALLY_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_ASSIGN_INTERNALLY_ASSIGNED_TO_ANOTHER_USER',
  DELIVERABLE_ASSIGN_INTERNALLY_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_ASSIGN_INTERNALLY_ASSIGNED_TO_AUTHENTICATED_USER',
  DELIVERABLE_INTERNAL_RESET_OR_CANCEL: 'DELIVERABLE_INTERNAL_RESET_OR_CANCEL',
  DELIVERABLE_INTERNAL_MARK_CLOSED_ASSIGNED_TO_AUTHENTICATED_USER:
    'DELIVERABLE_INTERNAL_MARK_CLOSED_ASSIGNED_TO_AUTHENTICATED_USER',
  DELIVERABLE_INTERNAL_MARK_CLOSED_ASSIGNED_TO_ANOTHER_USER:
    'DELIVERABLE_INTERNAL_MARK_CLOSED_ASSIGNED_TO_ANOTHER_USER'
};

const IMAGE_ASSET_MESSAGE = 'AWEbase uses the Image Review Interface for this file type';
const IMAGE_ASSET_DOC_LINK =
  'https://intercom.help/awebase/en/articles/4201278-accepted-file-types';
const VIDEO_ASSET_MESSAGE = 'AWEbase uses the Video Review Interface for this file type';
const VIDEO_ASSET_DOC_LINK =
  'https://intercom.help/awebase/en/articles/4201278-accepted-file-types';
const DOCUMENT_ASSET_MESSAGE = 'AWEbase uses the Document Review Interface for this file type';
const DOCUMENT_ASSET_DOC_LINK =
  'https://intercom.help/awebase/en/articles/4201278-accepted-file-types';
const AUDIO_ASSET_MESSAGE = 'AWEbase uses the Audio Review Interface for this file type';
const AUDIO_ASSET_DOC_LINK =
  'https://intercom.help/awebase/en/articles/4201278-accepted-file-types';
const MODEL3D_ASSET_MESSAGE = 'AWEbase uses the 3D Review Interface for this file type';
const MODEL3D_ASSET_DOC_LINK =
  'https://intercom.help/awebase/en/articles/4201278-accepted-file-types';

export const ASSET_UPLOAD_USER_MESSAGES = {
  fbx: {
    msg:
      'AWEbase Review Interface supports FBX embedded file. Please make sure that all the Texture for the model have been exported by usign FBX embedded mode.',
    link: MODEL3D_ASSET_DOC_LINK
  },
  obj: {
    msg:
      'AWEbase Review Interface supports OBJ file, if your OBJ file requires a material file and texture files please read the documentation.',
    link: MODEL3D_ASSET_DOC_LINK
  },
  glb: {
    msg: MODEL3D_ASSET_MESSAGE,
    link: MODEL3D_ASSET_DOC_LINK
  },
  stl: {
    msg: MODEL3D_ASSET_MESSAGE,
    link: MODEL3D_ASSET_DOC_LINK
  },
  zip: {
    msg:
      'AWEbase Review Interface supports generic zip files, or zip files containing OBJ and FBX 3D models with textures and material files.',
    link: MODEL3D_ASSET_DOC_LINK
  },
  png: {
    msg: IMAGE_ASSET_MESSAGE,
    link: IMAGE_ASSET_DOC_LINK
  },
  gif: {
    msg: IMAGE_ASSET_MESSAGE,
    link: IMAGE_ASSET_DOC_LINK
  },
  jpeg: {
    msg: IMAGE_ASSET_MESSAGE,
    link: IMAGE_ASSET_DOC_LINK
  },
  jpg: {
    msg: IMAGE_ASSET_MESSAGE,
    link: IMAGE_ASSET_DOC_LINK
  },
  aiff: {
    msg: AUDIO_ASSET_MESSAGE,
    link: AUDIO_ASSET_DOC_LINK
  },
  aif: {
    msg: AUDIO_ASSET_MESSAGE,
    link: AUDIO_ASSET_DOC_LINK
  },
  mp3: {
    msg: AUDIO_ASSET_MESSAGE,
    link: AUDIO_ASSET_DOC_LINK
  },
  wav: {
    msg: AUDIO_ASSET_MESSAGE,
    link: AUDIO_ASSET_DOC_LINK
  },
  mp4: {
    msg: VIDEO_ASSET_MESSAGE,
    link: VIDEO_ASSET_DOC_LINK
  },
  avi: {
    msg: VIDEO_ASSET_MESSAGE,
    link: VIDEO_ASSET_DOC_LINK
  },
  wmv: {
    msg: VIDEO_ASSET_MESSAGE,
    link: VIDEO_ASSET_DOC_LINK
  },
  mov: {
    msg: VIDEO_ASSET_MESSAGE,
    link: VIDEO_ASSET_DOC_LINK
  },
  m4v: {
    msg: VIDEO_ASSET_MESSAGE,
    link: VIDEO_ASSET_DOC_LINK
  },
  pdf: {
    msg: DOCUMENT_ASSET_MESSAGE,
    link: DOCUMENT_ASSET_DOC_LINK
  },
  docx: {
    msg:
      DOCUMENT_ASSET_MESSAGE +
      ". It might take a few minutes to prepare the file after it's uploaded.",
    link: DOCUMENT_ASSET_DOC_LINK
  }
};

export const NOTIFICATION_TYPES = {
  // '''both organization and freelancer'''
  // deliverables
  reviewAssetUpload: 'review_asset_upload',
  reviewAssetComment: 'review_asset_comment',
  reviewAssetCommentReaction: 'comment_reaction',
  reviewAssetReaction: 'review_asset_reaction',
  deliverableFileUpload: 'deliverable_file_upload',
  // solicitation
  solicitationComment: 'solicitation_comment',
  // job
  jobComment: 'job_comment',

  // '''only organization'''
  // deliverables
  staffAssigned: 'staff_assigned',
  assignedInternally: 'assigned_internally',
  deliverableClose: 'deliverable_close',
  deliverableReset: 'deliverable_reset',
  deliverableCancel: 'deliverable_cancel',
  // job
  contractSigned: 'contract_signed',
  jobDeclined: 'job_declined',
  // contractor
  contractorInviteAccepted: 'contractor_invite_accepted',
  // organization
  staffInviteAccepted: 'staff_invite_accepted',
  storageLimitExceeded: 'storage_limit_exceeded',
  followObject: 'follow_object',

  // '''only freelancer'''
  // # deliverable
  referenceUpload: 'reference_upload',
  // solicitation
  solicitationReceived: 'solicitation_received',
  // job
  jobReceived: 'job_received',
  jobRevoked: 'job_revoked',
  jobCanceled: 'job_canceled',
  paymentRequested: 'payment_requested',
  invoiceApproved: 'invoice_approved',
  invoiceDeclined: 'invoice_declined',
  invoiceCreated: 'invoice_created',
  // organization
  contractorInviteReceived: 'contractor_invite_received'
};

/**
 * @flow
 * @relayHash 072bddc7fedb28b814918b7ef5485d4b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CategoryDetails_category$ref = any;
export type CategoryDetailsPageQueryVariables = {|
  id: string
|};
export type CategoryDetailsPageQueryResponse = {|
  +category: ?{|
    +$fragmentRefs: CategoryDetails_category$ref
  |}
|};
export type CategoryDetailsPageQuery = {|
  variables: CategoryDetailsPageQueryVariables,
  response: CategoryDetailsPageQueryResponse,
|};
*/


/*
query CategoryDetailsPageQuery(
  $id: ID!
) {
  category(id: $id) {
    ...CategoryDetails_category
    id
  }
}

fragment CategoryDetails_category on CategoryNode {
  id
  name
  numberOfDeliverables
  categoryTypes {
    edges {
      node {
        id
        name
        numberOfDeliverables
      }
    }
  }
  steps {
    edges {
      node {
        id
        name
        order
        numberOfDeliverables
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numberOfDeliverables",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CategoryDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CategoryNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CategoryDetails_category",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CategoryDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CategoryNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categoryTypes",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryTypeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryTypeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryTypeNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "steps",
            "storageKey": null,
            "args": null,
            "concreteType": "StepNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "StepNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StepNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CategoryDetailsPageQuery",
    "id": null,
    "text": "query CategoryDetailsPageQuery(\n  $id: ID!\n) {\n  category(id: $id) {\n    ...CategoryDetails_category\n    id\n  }\n}\n\nfragment CategoryDetails_category on CategoryNode {\n  id\n  name\n  numberOfDeliverables\n  categoryTypes {\n    edges {\n      node {\n        id\n        name\n        numberOfDeliverables\n      }\n    }\n  }\n  steps {\n    edges {\n      node {\n        id\n        name\n        order\n        numberOfDeliverables\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03950bcf50f74f9a0ae5740e86fc3852';
module.exports = node;

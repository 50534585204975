import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatSecondsToHms } from '../../utils/formatters';
import { getTimeFromAnnotationRef } from '../../utils/helpers';

const useTimeBadgeStyles = makeStyles(theme => {
  return {
    timeBadge: {
      fontSize: 10,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.colors.darkBlueGrey,
      borderRadius: 2,
      padding: '0 3px',
      margin: '0 6px'
    }
  };
});

const TimeBadge = props => {
  const { annotationRef } = props;
  const classes = useTimeBadgeStyles();
  const time = getTimeFromAnnotationRef(annotationRef);
  return time ? (
    <Typography variant="body1" className={classes.timeBadge} component="span">
      {formatSecondsToHms(time)}
    </Typography>
  ) : null;
};

TimeBadge.propTypes = {
  annotationRef: PropTypes.string
};

export default TimeBadge;

import React, { useState } from 'react';

import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import BillingPlanRenderer from './renderers/BillingPlanRenderer';
import BillingInvoicesRenderer from './renderers/BillingInvoicesRenderer';
import BillingPaymentRenderer from './renderers/BillingPaymentRenderer';
import BillingActivitybRenderer from './renderers/BillingActivityRenderer';

const BillingPage = props => {
  const [isEditing, setEditing] = useState(false);
  const toggleEditHandler = () => setEditing(!isEditing);
  return (
    <AutoTabList>
      <BillingPlanRenderer {...props} label="Subscription" disabled={isEditing} />
      <BillingPaymentRenderer
        {...props}
        label="Payment"
        disabled={isEditing}
        toggleEditHandler={toggleEditHandler}
        isEditing={isEditing}
      />
      <BillingInvoicesRenderer {...props} label="Invoices" disabled={isEditing} />
      <BillingActivitybRenderer {...props} label="Activity" disabled={isEditing} />
    </AutoTabList>
  );
};

export default BillingPage;

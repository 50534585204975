import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

export default (refreshToken, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation RefreshTokenMutation($input: RefreshInput!) {
        refreshToken(input: $input) {
          token
          payload
          clientMutationId
        }
      }
    `,
    variables: {
      input: {
        refreshToken,
        clientMutationId: ''
      }
    },
    onCompleted: response => {
      callback(response);
    },
    onError: err => console.error(err)
  });
};

import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { FILE_STORAGE_CLASSES } from '../../../../constants';
import DeleteReviewAssetMutation from '../../../../mutations/review/DeleteReviewAssetMutation';
import getFetchReviewAssetDownloadUrl from '../../../../mutations/review/fetchReviewAssetDownloadUrl';
import Date from '../../../common/Date';
import ReviewAssetCardContent from '../../../review/ReviewAssetCardContent';
import SquareButton from '../../../UI/SquareButton';
import AssetReviewCommentChip from '../../../UI/AssetReviewCommentChip';
import BaseDownloadFileSquareButton from '../../../UI/DownloadFileSquareButton';
import ConfirmDialog from '../../../common/ConfirmationDialog';
import AddReviewAssetReactionButton from '../../../../../organization/components/deliverables/reactions/AddReviewAssetReactionButton';
import ToggleReviewAssetReactionMutation from '../../../../../organization/components/deliverables/reactions/ToggleReviewAssetReactionMutation';
import ReactionCard, {
  CounterContainer as ReactionCounterContainer,
  LabelContainer as ReactionLabelContainer
} from '../../review/ReactionCard';

const DateBadge = styled(Typography).attrs({ variant: 'body2' })`
  flex-shrink: 0;
  margin-right: auto;
`;

const DownloadFileSquareButton = styled(BaseDownloadFileSquareButton)`
  svg {
    width: 16px;
    height: auto;
  }
`;

const Container = styled.div`
  padding: 12px;
  background: #e8e7ed;
  margin-bottom: 18px;

  ${ReactionCard} {
    ${ReactionLabelContainer} {
      border-color: ${props => props.theme.palette.colors.darkBlueGrey};
    }

    ${ReactionCounterContainer} {
      background: ${props => props.theme.palette.colors.darkBlueGrey};
      color: #fff;
    }
  }

  ${props =>
    props.active &&
    css`
      box-shadow: inset 0px -4px 0px 0px ${props.theme.palette.primary.main};
      background: ${fade(props.theme.palette.primary.main, 0.2)};

      ${DateBadge} {
        color: ${props.theme.palette.primary.main};
      }

      ${ReactionCard} {
        ${ReactionLabelContainer} {
          border-color: ${props.theme.palette.primary.main};
        }

        ${ReactionCounterContainer} {
          background: ${props.theme.palette.primary.main};
        }
      }
    `};
`;

const ViewMarkupOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: opacity 0.2s;
  user-select: none;
`;

const PreviewContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
  border-radius: 5px;
  overflow: hidden;

  button {
    background: #e8e7ed;

    &:hover {
      background: rgb(206, 206, 206);
    }
  }

  ${props =>
    props.canViewMarkup &&
    css`
      cursor: pointer;

      &:hover {
        ${ViewMarkupOverlay} {
          opacity: 1;
        }

        .MuiButtonBase-root,
        button {
          &,
          &:hover {
            background: ${props => props.theme.palette.colors.darkBlueGrey};

            &,
            .MuiChip-label {
              color: #fff;
            }

            svg path {
              fill: #fff;
            }
          }
        }
      }
    `};
`;

const LeftCornerActions = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  max-width: 65%;
`;

const RightCornerActions = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;

  > * {
    margin-left: 8px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  min-height: 28px;
  flex-wrap: wrap;
  justify-content: flex-end;

  ${props =>
    props.withReactions &&
    css`
      margin-bottom: -6px;
    `};

  ${ReactionCard} {
    margin-bottom: 6px;
    margin-left: 4px;
  }
`;

const FileNameContainer = styled.div`
  background: ${props => props.theme.palette.colors.darkBlueGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;

  span:last-child {
    margin: 0 8px;
    color: #fafafa;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const AssetCard = props => {
  const {
    asset: {
      id,
      created,
      revisionNumber,
      fileName,
      fileThumbnailUrl,
      reviewInterface,
      storageClass,
      reactions
    },
    onRevisionNumberClick,
    onViewMarkup,
    canDelete,
    canAddReaction,
    ...containerProps
  } = props;
  const isZeroAsset = revisionNumber === 0;
  const isStandardStorage = storageClass === FILE_STORAGE_CLASSES.STANDARD;

  const handleDownloadClick = callback => {
    getFetchReviewAssetDownloadUrl(id, callback)();
  };

  return (
    <Container {...containerProps}>
      <PreviewContainer canViewMarkup={!!onViewMarkup}>
        <ReviewAssetCardContent
          fileUrl={fileThumbnailUrl}
          reviewInterface={reviewInterface}
          isStandardStorage={isStandardStorage}
        />
        {onViewMarkup && (
          <ViewMarkupOverlay data-over onClick={onViewMarkup}>
            <Typography variant="body2">Click to view markup...</Typography>
          </ViewMarkupOverlay>
        )}
        <LeftCornerActions>
          <FileNameContainer>
            <AssetReviewCommentChip
              revisionNumber={revisionNumber}
              onClick={onRevisionNumberClick}
            />
            {revisionNumber !== 0 && fileName && <span>{fileName}</span>}
          </FileNameContainer>
        </LeftCornerActions>
        <RightCornerActions>
          {canAddReaction && <AddReviewAssetReactionButton reviewAsset={props.asset} />}
          {!isZeroAsset && isStandardStorage && (
            <DownloadFileSquareButton
              id="downloadReviewAsset"
              handleDownloadClick={handleDownloadClick}
              fileName={fileName}
              variant="secondary"
              size={24}
            />
          )}
          {canDelete && !isZeroAsset && (
            <ConfirmDialog
              content="Are you sure you want to remove this asset?"
              copies={{
                confirmCopy: 'Confirm'
              }}
            >
              {confirm => (
                <SquareButton
                  icon="trash"
                  onClick={confirm(() => {
                    DeleteReviewAssetMutation(id);
                  })}
                  variant="secondary"
                  size={24}
                />
              )}
            </ConfirmDialog>
          )}
        </RightCornerActions>
      </PreviewContainer>
      <FooterContainer withReactions={reactions && reactions.length > 0}>
        <DateBadge>
          <Date date={created} />
        </DateBadge>
        {reactions &&
          reactions.map(reaction => (
            <ReactionCard
              key={reaction.id}
              reaction={reaction}
              onDelete={() => {
                ToggleReviewAssetReactionMutation({
                  reactionId: reaction.definitionId,
                  reviewAssetId: id
                });
              }}
            />
          ))}
      </FooterContainer>
    </Container>
  );
};

export default AssetCard;

import React from 'react';
import { Box, Typography, Grid, styled } from '@material-ui/core';
import { Link } from 'react-router-dom';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import { round2 } from '../../../../shared/utils/formatters';
import Detail from '../../../../shared/components/UI/Detail';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import { ReactComponent as ExchangeIcon } from '../../../../shared/images/exchange.svg';
import Date from '../../../../shared/components/common/Date';
import AutoTable from '../../../../shared/components/table/AutoTable';
import { CELL_TYPES } from '../../../../shared/constants';
import StyledTableCell from '../../../../shared/components/table/StyledTableCell';
import TableCellAmount from '../../../../shared/components/table/TableCellAmount';
import { INTERVALS } from './UpdateSubscriptionDialog';

const InfoContentBox = styled(Box)(({ theme }) => ({
  '& a': {
    textDecoration: 'none',
    color: theme.palette.colors.orange,
    fontWeight: 'bold'
  },
  '& >div:last-of-type': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px'
  }
}));

export const MissingPaymentInfoModalContent = ({ handleClose }) => {
  return (
    <InfoContentBox>
      <Typography>
        You dont't have billing or payment information set. Please set it in Payment tab before
        upgrading.
      </Typography>
      <Link to="/admin/billing/payment">Go to Payment tab</Link>
      <Box>
        <GlobalButton id="cancelUpgradeTier" handleClick={handleClose}>
          Ok
        </GlobalButton>
      </Box>
    </InfoContentBox>
  );
};

export const UpgradeTierModalContent = ({ handleClose }) => {
  return (
    <InfoContentBox>
      <Typography>
        You have reached the maximum number of active users your current tier allows. You will need
        to upgrade your tier in order to add more users to your organization. You may upgrade your
        tier from the <Link to="/admin/billing/subscription">Billing</Link> screen
      </Typography>
      <Box>
        <GlobalButton id="cancelUpgradeTier" handleClick={handleClose}>
          Ok
        </GlobalButton>
      </Box>
    </InfoContentBox>
  );
};

export const NotAllowedModalContent = ({ handleClose }) => (
  <InfoContentBox>
    <Typography>
      You cannot choose this tier because you have too many active users. Please mark users inactive
      until you are within the user count threshold. Once your user count is lowered, you will be
      able to choose this tier.
    </Typography>
    <Box>
      <GlobalButton id="cancelDowngradeTier" handleClick={handleClose}>
        Ok
      </GlobalButton>
    </Box>
  </InfoContentBox>
);

export const ConfirmAutoRenewToggleModalContent = ({
  handleConfirm,
  handleClose,
  value,
  isSubmitting
}) => (
  <InfoContentBox>
    <Typography>
      You are about to turn {value ? 'off' : 'on'} auto renewal for your subscription. Do you want
      to continue?
    </Typography>
    <Box>
      <GlobalButton
        id="cancelAutoRenewToggle"
        handleClick={handleClose}
        variant="transparent"
        disabled={isSubmitting}
      >
        Cancel
      </GlobalButton>
      <GlobalButton
        id="confirmAutoRenewToggle"
        handleClick={handleConfirm}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        Confirm
      </GlobalButton>
    </Box>
  </InfoContentBox>
);

const StyledSubscriptionDetailBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    height: '12px',
    width: '12px',
    marginLeft: '7px',
    marginRight: '7px'
  },
  '& span': {
    fontWeight: 'normal'
  }
}));

const SubscriptionDetail = ({ name, prevValue, newValue, isDate, fromFree }) => {
  const renderValueWhenChanged = () => (
    <StyledSubscriptionDetailBox>
      <Typography variant="subtitle1" component="span">
        {isDate ? <Date date={prevValue} /> : prevValue}
      </Typography>
      <ExchangeIcon />
      <Typography variant="body1" component="span">
        {isDate ? <Date date={newValue} /> : newValue}
      </Typography>
    </StyledSubscriptionDetailBox>
  );

  return (
    <Grid item xs={4}>
      <Detail
        name={name}
        flexDirection="column"
        value={
          fromFree || prevValue === newValue ? isDate ? <Date date={newValue} /> : newValue : null
        }
        renderValue={!fromFree && prevValue !== newValue ? renderValueWhenChanged : null}
        alignItems="flex-start"
        valueContainerProps={{ variant: 'body1' }}
      />
    </Grid>
  );
};

const InvoicePreviewTableCell = styled(({ isFinalLine, ...other }) => (
  <StyledTableCell {...other} />
))(({ theme, isFinalLine }) => ({
  color: isFinalLine ? theme.palette.colors.mediumBlueGrey : theme.palette.colors.darkBlueGrey,
  fontWeight: isFinalLine ? 'bold' : 'normal',
  fontSize: isFinalLine ? '12px' : '14px'
}));

const InvoicePreviewAmountCell = styled(({ isFinalLine, isTotal, amount, ...other }) => (
  <TableCellAmount amount={amount} {...other} />
))(({ theme, isTotal, amount, isFinalLine }) => ({
  color: isTotal && amount < 0 ? theme.palette.colors.orange : theme.palette.colors.darkBlueGrey,
  fontWeight: isTotal ? 'bold' : 'normal',
  fontSize: isFinalLine ? '16px' : '14px'
}));

export const BillingModalContent = ({
  handleClose,
  handleSubmit,
  isSubmitting,
  invoicePreview,
  subscriptionChanges,
  currentSubscription,
  isDowngradingTier,
  cancelModuleRemoval,
  removeModule
}) => {
  const SUBSCRIPTION_CHANGES = [
    { label: 'Tier', name: 'tier' },
    { label: 'Subscription Cycle', name: 'interval' },
    { label: 'Next Billing Date', name: 'nextBillingDate' },
    { label: 'Modules', name: 'modules' },
    { label: 'Active Users', name: 'activeUsers' },
    { label: 'Total Users', name: 'totalUsers' }
  ];

  const downgradingTierInfo =
    'Your downgrade will go into effect immediately. Your maximum user count will be lowered according to the new tier. If your storage, number of deliverables or contractors exceed the limits of the new tier, you will be unable to add new records until they are once more within those limits.';
  const downgradingIntervalInfo = `Your monthly subscription will go into effect ${
    subscriptionChanges.nextBillingDate
      ? 'on your next billing date, '
      : 'if you choose to renew your subscription'
  }`;
  const cancelingModuleRemovalInfo = `${cancelModuleRemoval} will be added back to your next billing cycle.`;
  const removeModuleInfo = `${removeModule} will be removed from your subscription ${
    subscriptionChanges.nextBillingDate
      ? 'on your next billing date, '
      : 'if you choose to renew your subscription'
  }`;

  const isCurrentFree = currentSubscription.tier === 'Free';

  const renderCell = ({ index, rowIndex, value, columnName, id }) => {
    const isFinalLine = id === 'finalLine';
    if (columnName === 'cost' || (columnName === 'costPerItem' && id !== 'finalLine')) {
      return (
        <InvoicePreviewAmountCell
          isTotal={columnName === 'cost'}
          isFinalLine={isFinalLine}
          amount={value}
          currencyCode="USD"
          key={index}
        />
      );
    } else {
      return (
        <InvoicePreviewTableCell key={index} isFinalLine={isFinalLine}>
          {isFinalLine && columnName === 'itemName' && <sup>*</sup>}
          {value}
          {!isFinalLine &&
            columnName === 'itemName' &&
            invoicePreview &&
            invoicePreview.invoiceLines[rowIndex]['cost'] < 0 && <sup>*</sup>}
        </InvoicePreviewTableCell>
      );
    }
  };

  const prepInvoiceLines = lines => {
    let eachLine = lines.map(line => {
      const { itemName, quantity, cost } = line;
      return {
        node: {
          id: itemName,
          itemName,
          quantity,
          costPerItem: round2(Math.abs(cost / quantity)),
          cost
        }
      };
    });
    if (lines.some(line => line.cost < 0)) {
      eachLine.push({
        node: {
          id: 'finalLine',
          itemName: 'Prorated according to your subscription cycle.',
          quantity: 'Total',
          costPerItem: '',
          cost: invoicePreview.total
        }
      });
    }
    return eachLine;
  };

  return (
    <Box>
      <DetailsSection title={`Subscription ${!isCurrentFree ? 'Changes' : ''}`}>
        <Grid container spacing={3}>
          {SUBSCRIPTION_CHANGES.map(item => (
            <SubscriptionDetail
              key={item.name}
              isDate={item.name === 'nextBillingDate'}
              fromFree={isCurrentFree}
              name={item.label}
              prevValue={currentSubscription[item.name]}
              newValue={subscriptionChanges[item.name]}
            />
          ))}
        </Grid>
      </DetailsSection>
      {isDowngradingTier && (
        <Typography style={{ marginBottom: '10px' }}>{downgradingTierInfo}</Typography>
      )}
      {subscriptionChanges.interval.toLowerCase() === INTERVALS.monthly &&
        currentSubscription.interval.toLowerCase() !== INTERVALS.monthly &&
        !isCurrentFree && (
          <Typography style={{ marginBottom: '10px' }}>
            {downgradingIntervalInfo}
            {subscriptionChanges.nextBillingDate && (
              <Date date={subscriptionChanges.nextBillingDate} />
            )}
            .
          </Typography>
        )}
      {cancelModuleRemoval ? (
        <Typography style={{ marginBottom: '10px' }}>{cancelingModuleRemovalInfo}</Typography>
      ) : null}
      {removeModule ? (
        <Typography style={{ marginBottom: '10px' }}>
          {removeModuleInfo}
          {subscriptionChanges.nextBillingDate && (
            <Date date={subscriptionChanges.nextBillingDate} />
          )}
          .
        </Typography>
      ) : null}

      {invoicePreview && (
        <AutoTable
          title="Payment Summary"
          rowTemplate={[
            { name: 'itemName', label: 'Item', truncate: true, width: '50%', renderCell },
            { name: 'costPerItem', label: 'Each', type: CELL_TYPES.amount, renderCell },
            { name: 'quantity', label: 'Quantity', renderCell },
            { name: 'cost', label: 'Total', type: CELL_TYPES.amount, renderCell }
          ]}
          edges={prepInvoiceLines(invoicePreview.invoiceLines)}
        />
      )}

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <GlobalButton
          id="cancelBillingModal"
          variant="transparent"
          handleClick={handleClose}
          disabled={isSubmitting}
        >
          Cancel
        </GlobalButton>
        <GlobalButton
          id="confirmBillingModal"
          handleClick={handleSubmit}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Confirm
        </GlobalButton>
      </Box>
    </Box>
  );
};

/**
 * @flow
 * @relayHash 905e08f5f7a95572871051606699c19a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddSolicitationDeliverableInput = {|
  solicitationId: string,
  deliverableId: string,
  clientMutationId?: ?string,
|};
export type AddSolicitationDeliverableMutationVariables = {|
  input: AddSolicitationDeliverableInput
|};
export type AddSolicitationDeliverableMutationResponse = {|
  +addSolicitationDeliverable: ?{|
    +isUpdated: ?boolean,
    +solicitation: ?{|
      +id: string,
      +numberOfDeliverables: ?number,
      +totalDeliverableAmount: ?number,
    |},
  |}
|};
export type AddSolicitationDeliverableMutation = {|
  variables: AddSolicitationDeliverableMutationVariables,
  response: AddSolicitationDeliverableMutationResponse,
|};
*/


/*
mutation AddSolicitationDeliverableMutation(
  $input: AddSolicitationDeliverableInput!
) {
  addSolicitationDeliverable(input: $input) {
    isUpdated
    solicitation {
      id
      numberOfDeliverables
      totalDeliverableAmount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddSolicitationDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addSolicitationDeliverable",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddSolicitationDeliverablePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isUpdated",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": null,
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalDeliverableAmount",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddSolicitationDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddSolicitationDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddSolicitationDeliverableMutation",
    "id": null,
    "text": "mutation AddSolicitationDeliverableMutation(\n  $input: AddSolicitationDeliverableInput!\n) {\n  addSolicitationDeliverable(input: $input) {\n    isUpdated\n    solicitation {\n      id\n      numberOfDeliverables\n      totalDeliverableAmount\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd19a1c82a7ba533128028b3881135da2';
module.exports = node;

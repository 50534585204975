import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../../../shared/relay/QueryRenderer';
import PaymentRequestsList from './PaymentRequestsList';

const PaymentRequestsListQueryRenderer = ({ jobId }) => (
  <QueryRenderer
    query={graphql`
      query PaymentRequestsListQuery($jobId: ID!) {
        job(id: $jobId) {
          ...PaymentRequestsList_job
        }
        paymentRequestStatusTypes: __type(name: "PaymentRequestStatus") {
          ...PaymentRequestsList_statusTypes
        }
      }
    `}
    variables={{ jobId }}
    render={relayProps => (
      <PaymentRequestsList
        job={relayProps.job}
        statusTypes={relayProps.paymentRequestStatusTypes}
      />
    )}
  />
);

export default PaymentRequestsListQueryRenderer;

import React from 'react';
import clsx from 'clsx';

import StyledTableCell from './StyledTableCell';
import { ReactComponent as UpIcon } from '../../images/caret-up-solid.svg';
import { ReactComponent as DownIcon } from '../../../shared/images/caret-down-solid.svg';
import { useTableStyles } from '../../styles/common/tableStyles';

const TableCellUpDown = props => {
  const { onClickUp, onClickDown, hideIconUp, hideIconDown } = props;
  const classes = useTableStyles();

  return (
    <StyledTableCell className={classes.upDownCell} noWrap>
      <UpIcon
        onClick={onClickUp}
        className={clsx({
          [classes.disabledNavigationIcon]: hideIconUp,
          [classes.activeNavigationIcon]: !hideIconUp
        })}
      />
      <DownIcon
        onClick={onClickDown}
        className={clsx({
          [classes.disabledNavigationIcon]: hideIconDown,
          [classes.activeNavigationIcon]: !hideIconDown
        })}
      />
    </StyledTableCell>
  );
};

export default TableCellUpDown;

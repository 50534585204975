import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Box } from '@material-ui/core';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import CreateContractorMutation from '../../mutations/CreateContractorMutation';
import appStyles from '../../styles/modalForms';
import { successToast } from '../../../shared/toasts';

const CreateContractorForm = ({ toRefetchData, handleClose }) => {
  const classes = appStyles();

  const onSubmit = (values, { setSubmitting, setFieldError }) => {
    CreateContractorMutation(
      values.firstName,
      values.lastName,
      values.email,
      (response, errors) => {
        setSubmitting(false);
        if (errors && errors[0].fields && errors[0].fields.email) {
          setFieldError('email', errors[0].fields.email);
        }
        if (!errors && response.newContractor) {
          handleClose();
          setTimeout(() => {
            successToast('New contractor has been added.', {
              text: 'Go to contractor',
              link: `/contractors/contractors/${response.newContractor.id}`
            });
          }, 250);
          toRefetchData();
        }
      }
    );
  };

  const CreateContractorFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(50, 'Please enter no more than 50 characters.')
      .required('Required'),
    lastName: Yup.string().max(50, 'Please enter no more than 50 characters.').required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .max(200, 'Please enter no more than 200 characters.')
      .required('Required')
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: ''
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={CreateContractorFormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={classes.contractorForm}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Field
                  required
                  name="firstName"
                  component={FormikTextField}
                  label="First Name"
                  placeholder="Enter first name..."
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="lastName"
                  component={FormikTextField}
                  label="Last Name"
                  placeholder="Enter last name..."
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  name="email"
                  component={FormikTextField}
                  label="Email Address"
                  placeholder="Enter email address..."
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" width="100%" mt={3}>
              <GlobalButton id="cancelContractor" variant="transparent" handleClick={handleClose}>
                Cancel
              </GlobalButton>
              <GlobalButton id="submitContractor" type="submit" disabled={isSubmitting}>
                Add Contractor
              </GlobalButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default CreateContractorForm;

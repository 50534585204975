/**
 * @flow
 * @relayHash f63c8379e09e54d04e2a9a2645fc2db1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSolicitationInput = {|
  id: string,
  subject?: ?string,
  replyByDate?: ?any,
  message?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateSolicitationMutationVariables = {|
  input: UpdateSolicitationInput
|};
export type UpdateSolicitationMutationResponse = {|
  +updateSolicitation: ?{|
    +isUpdated: ?boolean,
    +solicitation: ?{|
      +id: string,
      +subject: string,
      +message: ?string,
      +replyByDate: ?any,
    |},
  |}
|};
export type UpdateSolicitationMutation = {|
  variables: UpdateSolicitationMutationVariables,
  response: UpdateSolicitationMutationResponse,
|};
*/

/*
mutation UpdateSolicitationMutation(
  $input: UpdateSolicitationInput!
) {
  updateSolicitation(input: $input) {
    isUpdated
    solicitation {
      id
      subject
      message
      replyByDate
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'UpdateSolicitationInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'updateSolicitation',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'UpdateSolicitationPayload',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'isUpdated',
            args: null,
            storageKey: null
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'solicitation',
            storageKey: null,
            args: null,
            concreteType: 'SolicitationNode',
            plural: false,
            selections: [
              {
                kind: 'ScalarField',
                alias: null,
                name: 'id',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'subject',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'message',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'replyByDate',
                args: null,
                storageKey: null
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'UpdateSolicitationMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'UpdateSolicitationMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'UpdateSolicitationMutation',
      id: null,
      text:
        'mutation UpdateSolicitationMutation(\n  $input: UpdateSolicitationInput!\n) {\n  updateSolicitation(input: $input) {\n    isUpdated\n    solicitation {\n      id\n      subject\n      message\n      replyByDate\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'fb5a3365ff90fe8a9b9468428e28fd4a';
module.exports = node;

import React, { Fragment, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, fetchQuery } from 'react-relay';
import { Typography, Grid, Box } from '@material-ui/core';
import { useStripe } from '@stripe/react-stripe-js';

import DetailsSection from '../../../../shared/components/common/DetailsSection';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import DetailsRow from '../../../../shared/components/UI/DetailsRow';
import { getEnvironment } from '../../../../shared/utils/helpers';
import { errorToast, successToast } from '../../../../shared/toasts';
import EditToggle from '../../../../shared/components/UI/EditToggle';
import { Formik, Form } from 'formik';
import EditableField from '../../../../shared/components/form/fields/EditableField';
import { FormikCountrySelect } from '../../../../shared/components/form/dropdowns/CountrySelect';
import FormikPhoneField from '../../../../shared/components/form/FormikPhoneField';
import UpdateOrganizationBillingAddressMutation from './mutations/UpdateOrganizationBillingAddressMutation';
import { yupSchemas } from '../../../../shared/validations';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import StyledSwitch from '../../../../shared/components/UI/StyledSwitch';
import Dialog from '../../../../shared/components/common/Dialog';
import useDialog from '../../../../shared/hooks/useDialog';
import { ConfirmAutoRenewToggleModalContent } from './SubscriptionModals';
import ToggleAutoRenewalMutation from './mutations/ToggleAutoRenewalMutation';

const CARD_INFO_FIELDS = [
  { label: 'Account holder name', name: 'name' },
  { label: 'Last four digits', name: 'last_four_digits' },
  { label: 'Expiration month', name: 'exp_month' },
  { label: 'Expiration year', name: 'exp_year' },
  { label: 'Brand', name: 'brand' }
];

const ADDRESS_INFO_FIELDS = [
  { name: 'billingAddress1', label: 'Address 1' },
  { name: 'billingAddress2', label: 'Address 2' },
  { name: 'billingCity', label: 'City' },
  { name: 'billingState', label: 'State/Province' },
  { name: 'billingZipcode', label: 'ZIP/Postal Code' },
  {
    name: 'billingCountry',
    label: 'Country',
    component: FormikCountrySelect,
    getContent: value => (value && value.label) || ''
  },
  {
    name: 'phone',
    label: 'Phone',
    component: FormikPhoneField
  }
];

const BillingPayment = props => {
  const {
    billing,
    toggleEditHandler,
    isEditing,
    organization,
    userContext: {
      orgStaff: { isOwner }
    }
  } = props;
  const stripe = useStripe();
  const [isLoading, setLoading] = useState(false);

  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const [isSubmittingAutoRenewChange, setSubmittingAutoRenewChange] = useState(false);

  if (!billing || !organization) return <div>Something went wrong</div>;

  const stripePaymentDetail = JSON.parse(billing.stripePaymentDetail);

  const {
    billingAddress1,
    billingAddress2,
    billingCity,
    billingCountry,
    billingState,
    billingZipcode,
    phone,
    activeSubscription
  } = organization;

  const shouldShowAutoRenewSection =
    isOwner && activeSubscription && activeSubscription.tier !== 'free';

  const handleUpdatePaymentMethod = () => {
    setLoading(true);
    fetchQuery(
      getEnvironment(),
      graphql`
        query BillingPaymentQuery {
          stripeSessionIdUpdatePayment
        }
      `,
      {}
    ).then(response => {
      setLoading(false);
      if (response && response.stripeSessionIdUpdatePayment) {
        return stripe
          .redirectToCheckout({ sessionId: response.stripeSessionIdUpdatePayment })
          .then(result => console.error(result.error.message));
      } else {
        errorToast('Could not start stripe session.');
        return;
      }
    });
  };

  const submitAutoRenewalChange = () => {
    const value = !(activeSubscription && activeSubscription.autoRenew);
    setSubmittingAutoRenewChange(true);
    ToggleAutoRenewalMutation(value, response => {
      setSubmittingAutoRenewChange(false);
      toggleDialogOpen();
      if (response && response.activeSubscription) {
        successToast('You auto renewal settings were updated.');
      }
    });
  };

  const initialValues = {
    billingAddress1,
    billingAddress2: billingAddress2 || '',
    billingCity,
    billingState,
    billingZipcode,
    billingCountry: billingCountry
      ? {
          value: billingCountry.code,
          label: billingCountry.name
        }
      : null,
    phone
  };

  const validationSchema = yupSchemas.shape({
    billingAddress1: yupSchemas.string(true),
    billingAddress2: yupSchemas.string().ensure(),
    billingCity: yupSchemas.string(true),
    billingState: yupSchemas.string(true),
    billingZipcode: yupSchemas.string(true, { max: 10 }),
    billingCountry: yupSchemas.country(true),
    phone: yupSchemas.phone(true)
  });

  const billingAddressSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    UpdateOrganizationBillingAddressMutation(
      { ...values, billingCountry: values.billingCountry.value },
      response => {
        setSubmitting(false);

        if (response) {
          toggleEditHandler();
        }
      }
    );
  };

  return (
    <Fragment>
      <DetailsSection
        title="Payment Details"
        renderRight={() => (
          <GlobalButton
            handleClick={handleUpdatePaymentMethod}
            disabled={isLoading || isEditing}
            loading={isLoading}
            noMargin
          >
            Update Payment Method
          </GlobalButton>
        )}
      >
        {stripePaymentDetail && stripePaymentDetail.error && (
          <Typography>{stripePaymentDetail.error}</Typography>
        )}
        {stripePaymentDetail && !stripePaymentDetail.error && (
          <Fragment>
            {CARD_INFO_FIELDS.map(field => (
              <DetailsRow
                key={field.name}
                title={field.label}
                content={stripePaymentDetail[field.name]}
                titleWidth={200}
              />
            ))}
          </Fragment>
        )}
      </DetailsSection>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={billingAddressSubmitHandler}
        validationSchema={validationSchema}
      >
        {({ values, resetForm, isSubmitting }) => (
          <Form>
            <DetailsSection
              noBorder={!shouldShowAutoRenewSection}
              title="Billing Address Info"
              renderRight={() => (
                <EditToggle
                  isEditing={isEditing}
                  isSubmitting={isSubmitting}
                  editProps={{
                    id: 'editBillingAddress',
                    onClick: toggleEditHandler
                  }}
                  saveProps={{
                    id: 'saveBillingAddress',
                    loading: isSubmitting
                  }}
                  cancelProps={{
                    id: 'cancelBillingAddress',
                    onClick: () => {
                      resetForm();
                      toggleEditHandler();
                    }
                  }}
                />
              )}
            >
              <Grid container spacing={2}>
                {ADDRESS_INFO_FIELDS.map(field => (
                  <EditableField
                    key={field.name}
                    editing={isEditing}
                    detailsRowProps={{ noMarginTop: true, titleWidth: 140 }}
                    formikValues={values}
                    gridItemProps={{ xs: 5 }}
                    fieldProps={{
                      ...(field.name === 'billingCountry' ? { notFullWidth: true } : {}),
                      ...(field.name !== 'billingAddress2' ? { required: true } : {})
                    }}
                    {...field}
                  />
                ))}
              </Grid>
            </DetailsSection>
          </Form>
        )}
      </Formik>
      {shouldShowAutoRenewSection && (
        <>
          <DetailsSection noBorder title="Subscription Auto-Renewal">
            <Box display="flex" alignItems="center" ml="auto">
              <Typography>Renew Subscription Automatically</Typography>
              <StyledSwitch
                checked={activeSubscription && activeSubscription.autoRenew}
                onChange={toggleDialogOpen}
              />
            </Box>
          </DetailsSection>
          <Dialog
            isDialogOpen={isDialogOpen}
            closeDialog={toggleDialogOpen}
            title={'Confirm Auto-Renewal Changes'}
            minWidth={400}
          >
            <ConfirmAutoRenewToggleModalContent
              handleClose={toggleDialogOpen}
              handleConfirm={submitAutoRenewalChange}
              value={activeSubscription && activeSubscription.autoRenew}
              isSubmitting={isSubmittingAutoRenewChange}
            />
          </Dialog>
        </>
      )}
    </Fragment>
  );
};

export default withUserContext(
  createFragmentContainer(BillingPayment, {
    billing: graphql`
      fragment BillingPayment_billing on Query {
        stripePaymentDetail
      }
    `,
    organization: graphql`
      fragment BillingPayment_organization on OrganizationNode {
        billingAddress1
        billingAddress2
        billingCity
        billingCountry {
          name
          code
        }
        billingZipcode
        billingState
        phone
        activeSubscription {
          tier
          autoRenew
        }
      }
    `
  })
);

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Grid } from '@material-ui/core';
import { createFragmentContainer } from 'react-relay';

import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';

const ContractorDeliverablesTopSection = props => {
  const {
    activeDeliverablesCount,
    pastDueDeliverablesCount,
    completedDeliverablesCount
  } = props.contractor;

  return (
    <DetailsSection title="Deliverables Summary">
      <Grid container spacing={3}>
        {[
          { title: 'Active', content: activeDeliverablesCount },
          { title: 'Past Due', content: pastDueDeliverablesCount },
          { title: 'Completed', content: completedDeliverablesCount }
        ].map(detailProps => (
          <Grid key={detailProps.title} item xs={3}>
            <DetailsRow {...detailProps} />
          </Grid>
        ))}
      </Grid>
    </DetailsSection>
  );
};

export default createFragmentContainer(ContractorDeliverablesTopSection, {
  contractor: graphql`
    fragment ContractorDeliverablesTopSection_contractor on ContractorNode {
      activeDeliverablesCount
      pastDueDeliverablesCount
      completedDeliverablesCount
    }
  `
});

/**
 * @flow
 * @relayHash d8667cce73af6af5a827aed78bf27683
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ReleaseDetailsMainContent_release$ref = any;
export type ReleaseDetailsTabQueryVariables = {|
  id: string
|};
export type ReleaseDetailsTabQueryResponse = {|
  +release: ?{|
    +$fragmentRefs: ReleaseDetailsMainContent_release$ref
  |}
|};
export type ReleaseDetailsTabQuery = {|
  variables: ReleaseDetailsTabQueryVariables,
  response: ReleaseDetailsTabQueryResponse,
|};
*/


/*
query ReleaseDetailsTabQuery(
  $id: ID!
) {
  release(id: $id) {
    ...ReleaseDetailsMainContent_release
    id
  }
}

fragment ReleaseDetailsMainContent_release on ReleaseNode {
  id
  description
  name
  code
  internalId
  deadline
  totalBudget
  product {
    id
    code
    title
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReleaseDetailsTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "release",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ReleaseDetailsMainContent_release",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReleaseDetailsTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "release",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "internalId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deadline",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalBudget",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "product",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ReleaseDetailsTabQuery",
    "id": null,
    "text": "query ReleaseDetailsTabQuery(\n  $id: ID!\n) {\n  release(id: $id) {\n    ...ReleaseDetailsMainContent_release\n    id\n  }\n}\n\nfragment ReleaseDetailsMainContent_release on ReleaseNode {\n  id\n  description\n  name\n  code\n  internalId\n  deadline\n  totalBudget\n  product {\n    id\n    code\n    title\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '543568de31f923483b8b4715cbd786dc';
module.exports = node;

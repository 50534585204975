import React, { useState, useEffect, useRef } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';
import videojs from 'video.js';
import UpdateDeliverableReviewAnnotationMutation from '../../mutations/review/UpdateDeliverableReviewAnnotationMutation';
import VideoCanvasProgressToolbar from '../review/VideoCanvasProgressToolbar';
import { getTimeFromAnnotationRef } from '../../utils/helpers';
import {
  AnnotationAssetContainer,
  AnnotationActionsContainer,
  AnnotationInterfaceContainer,
  AnnotationToolbarContainer
} from './review_interfaces/AnnotationInterfaceContainers';
import AWEbaseCanvas from './CanvasAnnotation/AWEbaseCanvas';
import SquareButton from '../UI/SquareButton';
import { VIDEO_MIME_TYPE_MAPPER } from '../../constants';
import { clamp } from 'lodash';
import { getCanvasDataAsJson, mergeAnnotationData } from './CanvasAnnotation/CanvasData';
import { TOOLS, ATTRS, getAnnotationAddTools } from './CanvasAnnotation/CanvasTools/contants';
import withUserContext from '../../contexts/userContext/withUserContext';
import { errorToast } from '../../toasts';

const getMimeType = src => {
  if (src.startsWith('http')) {
    const urlSplit = src.split('/');
    const filenameTokens = urlSplit[urlSplit.length - 1];
    const cleanedFilename = filenameTokens.split('?')[0];
    const cleanedTokens = cleanedFilename.split('.');
    const extension = cleanedTokens[cleanedTokens.length - 1];

    return VIDEO_MIME_TYPE_MAPPER[extension];
  } else {
    const cleanedTokens = src.split('.');
    const extension = cleanedTokens[cleanedTokens.length - 1];

    return VIDEO_MIME_TYPE_MAPPER[extension];
  }
};

const generateId = currentTime =>
  'image-highlight-' + new Date().getTime().toString() + '/video-' + currentTime;

const VideoCanvasAnnotation = ({
  src,
  highlightedAnnotationRef,
  userContext,
  annotationData,
  assetId,
  handleHighlightAnnotation,
  canAddMarkup,
  sidebarAction,
  setSidebarAction,
  onCloseClick
}) => {
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAnnotating, setIsAnnotating] = useState(false);
  const [selectedAnnotationData, setSelectedAnnotationData] = useState([]);
  const [volume, setVolume] = useState(100);
  const [currentTime, setCurrentTime] = useState(null);
  const [size, setSize] = useState(null);
  const [duration, setDuration] = useState(null);
  const [selectedTool, setSelectedTool] = useState(null);
  const [editing, setEditing] = useState(false);
  const [hideMarkers, setHideMarkers] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [color, setColor] = useState('red');

  const playerRef = useRef();
  const videoNodeRef = useRef();
  const containerRef = useRef();
  const childRef = useRef();
  /**
   * Annotation Data for video is an array of objects with
   * the following attribute:
   * time: time in seconds with a decimal place, this needs to change to millis
   * annotationRef: the unique identifier for this annotation group
   * annotationData: this is the same data structure used in the ImageCanvasInterface
   *
   */
  useEffect(() => {
    let mimeType = getMimeType(src);

    let sourceData = {
      src
    };

    if (mimeType !== undefined) {
      sourceData['type'] = mimeType;
    }

    playerRef.current = videojs(videoNodeRef.current, {
      preload: 'metadata',
      controls: false,
      fill: true,
      sources: [sourceData]
    });

    playerRef.current.ready(onPlayerReady);
    playerRef.current.on('loadedmetadata', onLoadedMetaData);
    playerRef.current.on('timeupdate', onTimeUpdate);
    // playerRef.current.on('play', onPlayPause);
    playerRef.current.on('ended', () => {
      setIsPlaying(false);
    });

    onHighlightChangeHandler();
  }, []);

  useEffect(() => {
    onHighlightChangeHandler();
  }, [highlightedAnnotationRef, selectedAnnotationData]);

  useEffect(() => {
    if (sessionId && (!sidebarAction || sidebarAction.type !== 'ADD_NEW')) {
      onCancelSession();
    }
  }, [sidebarAction]);

  useEffect(() => {
    // if highlighted annotation data is null we need to get the current time
    selectedAnnotationFromCurrentTime();
  }, [annotationData]);

  const onHighlightChangeHandler = () => {
    annotationData.forEach(element => {
      if (!element.annotationData) {
        element.annotationData = [];
      }
      element.annotationData.forEach(annotation => {
        if (annotation[ATTRS.ref] === highlightedAnnotationRef) {
          onAnnotationGroupClick(element[ATTRS.ref]);
        }
      });
    });
  };

  const onLoadedMetaData = event => {
    const { videoHeight, videoWidth, duration } = event.target.firstChild;
    const { clientWidth, clientHeight } = containerRef.current;

    const scale = {
      x: clientWidth / videoWidth,
      y: clientHeight / videoHeight
    };

    const scaleFactor = clamp(Math.min(scale.x, scale.y), 1);

    setDuration(toMillis(duration));
    setSize({
      width: videoWidth * scaleFactor,
      height: videoHeight * scaleFactor
    });
  };

  const onTimeUpdate = () => {
    const newTime = toMillis(playerRef.current.currentTime());
    if (newTime !== currentTime) {
      setCurrentTime(newTime);
    }
  };

  const onPlayerReady = () => {
    setVolume(playerRef.current.volume());
  };

  // beginning
  const progressBarClickHandler = trackPercent => {
    setSelectedAnnotationData([]);
    isHighlightSet() && handleHighlightAnnotation(null);
    playerRef.current.currentTime(fromMillis(duration * trackPercent));
  };

  const toMillis = time => {
    return (time * 1000).toFixed(3);
  };
  const fromMillis = time => {
    return (time / 1000).toFixed(6);
  };

  const togglePlayHandler = () => {
    setIsPlaying(!isPlaying);
    setSelectedAnnotationData([]);
    isHighlightSet() && handleHighlightAnnotation(null);

    playerRef.current.paused() ? playerRef.current.play() : playerRef.current.pause();
  };

  const isHighlightSet = () => {
    return highlightedAnnotationRef !== null;
  };

  /** SUBMIT AND COMMENT HANDLERS */
  const annotationQuery = graphql`
    query VideoCanvasInterfaceQuery($reviewAssetId: ID!) {
      reviewAsset(id: $reviewAssetId) {
        id
        reviewAssetAnnotation {
          annotationData
        }
      }
    }
  `;
  const fetchAnnotationData = (callback = () => {}) => {
    fetchQuery(getEnvironment(), annotationQuery, { reviewAssetId: assetId }, { force: true }).then(
      data => {
        callback(data.reviewAsset.reviewAssetAnnotation.annotationData);
      }
    );
  };

  const validateVideoData = data => {
    let errors = [];
    if (!Array.isArray(data)) {
      errors.push('Annotation data is not an array');
    }
    data.forEach((el, index) => {
      // check if element has time property and it's a float
      if (!('time' in el)) {
        errors.push('Annotation  at index: ' + index + ' does not have property time');
      } else if (isNaN(parseFloat(el.time))) {
        errors.push('Annotation  at index: ' + index + ' cannot be converted to float: ' + el.time);
      }
      // check if element has annotationRef property
      if (!('annotationRef' in el)) {
        errors.push('Annotation  at index: ' + index + ' does not have property annotationRef');
      } else if (!el.annotationRef.includes('annotation-group-at/')) {
        errors.push(
          'Annotation  at index: ' + index + ' annotationRef invalid value: ' + el.annotationRef
        );
      }
      // check if element has annotationData and it's an array
      if (!('annotationData' in el)) {
        errors.push('Annotation  at index: ' + index + ' does not have property annotationData');
      } else if (!Array.isArray(el.annotationData)) {
        errors.push(
          'Annotation  at index: ' + index + ' annotationData invalid value: ' + el.annotationData
        );
      }
    });
    return errors.length === 0 ? [true, errors] : [false, errors];
  };
  /**
   * This method takes the Video annotation for the asset coming from the
   * server, extract any annotation for the currentTime  and merges the
   * annotationData field of the video annotation, with the annotation data
   * from the canvas
   * @param {string} serverData: json string with the video annotation for the ReviewAsset
   * @param {Array} localAnnotations: annotation data from the local canvas
   */
  const getMergedAnnotation = (serverData, localAnnotations) => {
    const serverAnnotation = JSON.parse(serverData);
    const [valid, errors] = validateVideoData(serverAnnotation);
    if (!valid) {
      // return the current content of the canvas as the merged annotation
      // if the validation of the server data failes we overwrite what we received from
      // the server.
      errorToast(errors.join('\n'));
      return localAnnotations;
    } else {
      // First I need to find the associated index in the annotation data
      // find the index of the array associated with the timestamp
      const indexOfServerData = serverAnnotation.findIndex(
        data => data.time === fromMillis(currentTime)
      );

      let imageAnnotationData = null;
      if (indexOfServerData > -1) {
        imageAnnotationData = serverAnnotation[indexOfServerData];
      }

      return mergeAnnotationData(
        getCanvasDataAsJson(childRef.current.getCanvas(), size),
        imageAnnotationData && Array.isArray(imageAnnotationData.annotationData)
          ? imageAnnotationData.annotationData
          : [],
        userContext.id
      );
    }
  };

  const onEditSubmitHandler = callback => {
    fetchAnnotationData(serverData => {
      const newAnnotationData = getMergedAnnotation(
        serverData,
        getCanvasDataAsJson(childRef.current.getCanvas(), size)
      );

      const newAnnotation = {
        annotationRef: `annotation-group-at/${fromMillis(currentTime)}`,
        time: fromMillis(currentTime),
        annotationData: newAnnotationData
      };

      let updatedAnnotationData;

      const indexOfExistingDataForCurrentTime = annotationData.findIndex(
        data => data.time === fromMillis(currentTime)
      );

      if (indexOfExistingDataForCurrentTime >= 0) {
        updatedAnnotationData = [...annotationData];
        updatedAnnotationData[indexOfExistingDataForCurrentTime].annotationData =
          newAnnotation.annotationData;
      } else {
        updatedAnnotationData = [...annotationData, newAnnotation];
      }

      UpdateDeliverableReviewAnnotationMutation({
        reviewAsset: assetId,
        annotationData: JSON.stringify(updatedAnnotationData)
      });
    });
  };

  const addAnnotationGroupHandler = () => {
    playerRef.current.pause();
    setIsAnnotating(true);
    // change the video toolbar with the annotation toolbar
  };

  const onAnnotationGroupClick = annotationRef => {
    const annotationTime = getTimeFromAnnotationRef(annotationRef);

    playerRef.current.pause();
    setIsPlaying(false);
    playerRef.current.currentTime(annotationTime);

    const selectedAnnotation = annotationData.find(data => data.time === annotationTime);
    const selectedAnnotationData = selectedAnnotation ? selectedAnnotation.annotationData : [];

    setSelectedAnnotationData(selectedAnnotationData);
    setIsAnnotating(isAnnotating);
  };

  const selectedAnnotationFromCurrentTime = () => {
    const selectedAnnotation = annotationData.find(data => data.time === fromMillis(currentTime));
    const selectedAnnotationData = selectedAnnotation ? selectedAnnotation.annotationData : [];

    setSelectedAnnotationData(selectedAnnotationData);
    setIsAnnotating(isAnnotating);
  };

  const onSaveEditChanges = () => {
    onEditSubmitHandler();
  };

  // end

  // Edit Markers Handlers
  const onStartEditMarkers = () => {
    setEditing(true);
    childRef.current.startEdit();
  };

  const onSaveEditMarkers = value => {
    setEditing(false);
    childRef.current.saveEdit();
  };

  const onAnnotationVisibilityChanged = value => {
    setHideMarkers(!value);
  };

  /**
  Receives the notification from the Toolbar component that
  the user wants to start an annotation session.
  Annotation sessions are related 1-to-1 with comments.
    */
  const onStartSession = () => {
    playerRef.current.pause();
    setIsPlaying(false);
    // generate an id for the session
    // FRAMTODO this id needs to be unique
    const annotationRef = generateId(fromMillis(currentTime));
    // change the toolbar controls to dot tool
    setSelectedTool(TOOLS.dot);
    // pass the new session id to the toolbar component
    setSessionId(annotationRef);

    setSidebarAction(val => ({
      ...val,
      type: 'ADD_NEW',
      submitHandler: ({ content, rawContent, referenceIds }, actions) => {
        if (!childRef.current.containsAnnotations(annotationRef)) {
          errorToast('Please add some annotations before posting a comment.');
          actions.setSubmitting(false);
          return;
        }

        childRef.current.cleanupSession();
        setSelectedTool(TOOLS.defaultTool);

        fetchAnnotationData(serverData => {
          const newAnnotationData = getMergedAnnotation(
            serverData,
            getCanvasDataAsJson(childRef.current.getCanvas(), size)
          );

          // creating the annotation group using the timestamp
          const newAnnotation = {
            annotationRef: `annotation-group-at/${fromMillis(currentTime)}`,
            time: fromMillis(currentTime),
            annotationData: newAnnotationData
          };
          // return value after the mutation
          let updatedAnnotationData;
          // find the index of the array associated with the timestamp
          const indexOfExistingDataForCurrentTime = annotationData.findIndex(
            data => data.time === fromMillis(currentTime)
          );

          // if a group was present within the annotation data we replace it
          if (indexOfExistingDataForCurrentTime >= 0) {
            // create a new annotation array by using the annotationData
            updatedAnnotationData = [...annotationData];
            // replace the previous annotation data with the current annotations
            updatedAnnotationData[indexOfExistingDataForCurrentTime].annotationData =
              newAnnotation.annotationData;
            // a group was not present within the annotation data we add it
          } else {
            // add the group with the new timestamp to the annotation data.
            updatedAnnotationData = [...annotationData, newAnnotation];
          }

          // send the data to the server.
          UpdateDeliverableReviewAnnotationMutation(
            {
              reviewAsset: assetId,
              annotationData: JSON.stringify(updatedAnnotationData),
              content,
              rawContent,
              referenceIds,
              [ATTRS.ref]: annotationRef
            },
            response => {
              setSidebarAction(null);
              setSessionId(null);
            }
          );
        });
      }
    }));
  };

  const onChangeTool = name => {
    setSelectedTool(TOOLS[name]);
  };

  const onCancelSession = () => {
    childRef.current.cancel(sessionId);
    setSessionId(null);
    setSelectedTool(TOOLS.defaultTool);
    setSidebarAction(null);
  };

  const onActiveAnnotationColor = color => {
    setColor(color);
  };

  return (
    <AnnotationInterfaceContainer>
      {!isAnnotating && (
        <AnnotationActionsContainer>
          <SquareButton onClick={onCloseClick} variant="black" icon="close" />
        </AnnotationActionsContainer>
      )}
      <AnnotationAssetContainer ref={containerRef}>
        <div data-vjs-player style={size ? size : {}}>
          <video ref={node => (videoNodeRef.current = node)} className="video-js"></video>
        </div>
        {size && (
          <AWEbaseCanvas
            userId={userContext.id}
            dimensions={size}
            backgroundColor={'transparent'}
            color={color}
            annotationData={selectedAnnotationData}
            tool={selectedTool}
            editing={editing}
            hideMarkers={hideMarkers}
            onChangeAnnotation={handleHighlightAnnotation}
            // the user annotating session
            sessionId={sessionId}
            annotationRef={highlightedAnnotationRef}
            onCloseEditMode={onSaveEditChanges}
            ref={childRef}
            onActiveAnnotationColor={onActiveAnnotationColor}
          />
        )}
      </AnnotationAssetContainer>
      <AnnotationToolbarContainer>
        <VideoCanvasProgressToolbar
          sliderProps={{
            min: 0,
            max: 1,
            step: 0.01,
            value: isMuted ? 0 : volume,
            onChange: vol => {
              setVolume(vol);
              playerRef.current.volume(vol);
            },
            onClick: () => {
              setIsMuted(!isMuted);
              playerRef.muted(!isMuted);
            }
          }}
          sessionId={sessionId}
          tools={getAnnotationAddTools()}
          isPlaying={isPlaying}
          duration={duration}
          editing={editing}
          currentTime={currentTime}
          annotationData={annotationData}
          selectedAnnotationData={selectedAnnotationData}
          onProgressBarClick={progressBarClickHandler}
          onAddCommentClick={addAnnotationGroupHandler}
          onTogglePlayClick={togglePlayHandler}
          onAnnotationClick={onAnnotationGroupClick}
          canAddMarkup={canAddMarkup}
          annotationsVisible={!hideMarkers}
          toggleAnnotationVisibility={onAnnotationVisibilityChanged}
          onStartEdit={onStartEditMarkers}
          onSaveEdit={onSaveEditMarkers}
          onStartSession={onStartSession}
          onAddComment={() => {
            setSidebarAction(val => ({ ...val, type: 'ADD_NEW' }));
          }}
          setToolHandler={onChangeTool}
          onCloseClick={onCancelSession}
          selectedToolId={selectedTool ? selectedTool.name : null}
          colorProps={{
            color: color,
            disabled: editing && !sessionId,
            onChange: setColor
          }}
        />
        {/* { isAnnotating &&
            <CanvasAnnotationToolbar
              sessionId={sessionId}
              canAddMarkup={canAddMarkup}
              onStartSession={onStartSession}
              setToolHandler={onChangeTool}
              tools={Object.values(TOOLS)}
              selectedToolId={selectedTool ? selectedTool.name : null}
              toggleAnnotationVisibility={onAnnotationVisibilityChanged}
              annotationsVisible={!hideMarkers}
              onSaveClick={onSaveSession}
              onCloseClick={onCancelSession}
              editing={editing}
              onStartEdit={onStartEditMarkers}
              onSaveEdit={onSaveEditMarkers}
              colorProps={{
                color: color,
                disabled: editing && !sessionId,
                onChange: onChangeColor
              }}
            />} */}
      </AnnotationToolbarContainer>
    </AnnotationInterfaceContainer>
  );
};

export default withUserContext(VideoCanvasAnnotation);

import React from 'react';

import Header from './ReviewAssetsHeader';
import ReviewAssetsList from './ReviewAssetsList';

const ReviewAssets = ({ relayProps }) => {
  return (
    <>
      <Header storageInfo={relayProps.organization} />
      <ReviewAssetsList
        files={relayProps}
        storageClasses={relayProps.storageClasses}
        deliverableStateTypes={relayProps.deliverableStateTypes}
      />
    </>
  );
};

export default ReviewAssets;

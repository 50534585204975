import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import UpdateOrganizationConfigurationMutation from './mutations/UpdateOrganizationConfigurationMutation';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import StyledCheckbox from '../../../../shared/components/UI/StyledCheckbox';
import FilterDebounceInput from '../../../../shared/components/filters/FilterDebounceInput';
import SwatchColorPicker from '../../../../shared/components/colorPicker/SwatchColorPicker';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';

const DueDates = props => {
  const {
    userContext: {
      setUserInfo,
      orgStaff: { organization }
    }
  } = props;
  const [values, setValues] = useState({
    checkboxDeliverableBefore: false,
    colorDeliverableBefore: '',
    numberOfDaysDeliverable: '',
    checkboxDeliverablePast: false,
    colorDeliverablePast: '',
    checkboxReleaseBefore: false,
    colorReleaseBefore: '',
    numberOfDaysRelease: '',
    checkboxReleasePast: false,
    colorReleasePast: ''
  });
  const classes = useDetailsStyles();

  useEffect(() => {
    mapInitialValues();
  }, [organization.configuration]);

  if (!organization) {
    return <div>Something went wrong!</div>;
  }

  const dueDateWarnings = JSON.parse(organization.configuration).due_date_warnings;

  const submitHandler = values => {
    const updatedDueDateWarnings = {
      deliverable: {
        before_date: {
          is_active: values.checkboxDeliverableBefore,
          number_of_days: values.numberOfDaysDeliverable,
          color: values.colorDeliverableBefore
        },
        past_date: { is_active: values.checkboxDeliverablePast, color: values.colorDeliverablePast }
      },
      release: {
        before_date: {
          is_active: values.checkboxReleaseBefore,
          number_of_days: values.numberOfDaysRelease,
          color: values.colorReleaseBefore
        },
        past_date: { is_active: values.checkboxReleasePast, color: values.colorReleasePast }
      }
    };
    UpdateOrganizationConfigurationMutation(
      { dueDateWarnings: JSON.stringify(updatedDueDateWarnings) },
      response => {
        if (response && response.isUpdated && response.organization) {
          // setting response to the context so configuration is updated across the app without extra api call
          setUserInfo({
            ...props.userContext,
            orgStaff: { ...props.userContext.orgStaff, organization: response.organization }
          });
        }
      }
    );
  };

  const mapInitialValues = () => {
    const deliverableBeforeDate = dueDateWarnings['deliverable']['before_date'];
    const deliverablePastDate = dueDateWarnings['deliverable']['past_date'];
    const releaseBeforeDate = dueDateWarnings['release']['before_date'];
    const releasePastDate = dueDateWarnings['release']['past_date'];
    setValues({
      checkboxDeliverableBefore: deliverableBeforeDate['is_active'],
      colorDeliverableBefore: deliverableBeforeDate['color'],
      numberOfDaysDeliverable: deliverableBeforeDate['number_of_days'],
      checkboxDeliverablePast: deliverablePastDate['is_active'],
      colorDeliverablePast: deliverablePastDate['color'],
      checkboxReleaseBefore: releaseBeforeDate['is_active'],
      colorReleaseBefore: releaseBeforeDate['color'],
      numberOfDaysRelease: releaseBeforeDate['number_of_days'],
      checkboxReleasePast: releasePastDate['is_active'],
      colorReleasePast: releasePastDate['color']
    });
  };

  const handleColorChange = (name, color) => {
    submitHandler({ ...values, [name]: color });
  };

  const handleDayChange = (name, value) => {
    if (value > 0) {
      submitHandler({ ...values, [name]: Number(value) });
    }
  };

  const mapLayout = instanceType => {
    // instanceType - 'Deliverable' or 'Release'
    return (
      <Fragment>
        {['Before', 'Past'].map(suffix => {
          return (
            <div
              className={clsx(classes.dueDatesWarningRow, {
                [classes.dueDatesWarningRowGrey]: suffix === 'Past'
              })}
              key={suffix}
            >
              <StyledCheckbox
                id={`checkbox${instanceType}${suffix}`}
                name={`checkbox${instanceType}${suffix}`}
                onChange={e => {
                  submitHandler({
                    ...values,
                    [`checkbox${instanceType}${suffix}`]: e.target.checked
                  });
                }}
                checked={values[`checkbox${instanceType}${suffix}`]}
              />
              {values[`color${instanceType}${suffix}`] && (
                <SwatchColorPicker
                  initialColor={values[`color${instanceType}${suffix}`]}
                  onChange={handleColorChange}
                  name={`color${instanceType}${suffix}`}
                />
              )}
              <Typography variant="body2">{`when a ${instanceType} is `}</Typography>{' '}
              <Typography variant="body2" component="span">
                Incomplete
              </Typography>
              <Typography variant="body2">{` and ${
                suffix === 'Before'
                  ? 'within'
                  : `on or past its ${instanceType === 'Deliverable' ? 'due date' : 'key date'}.`
              } `}</Typography>
              {suffix === 'Before' && (
                <FilterDebounceInput
                  validateNegativeNumbersAndZero
                  errorMessage=" "
                  name={`numberOfDays${instanceType}`}
                  value={values[`numberOfDays${instanceType}`]}
                  label=""
                  onChange={handleDayChange}
                />
              )}
              {suffix === 'Before' && (
                <Typography variant="body2">
                  {' '}
                  {`days of its ${instanceType === 'Deliverable' ? 'due date' : 'key date'}.`}
                </Typography>
              )}
            </div>
          );
        })}
      </Fragment>
    );
  };

  return (
    <div className={classes.detailsContentNoTabs}>
      <DetailsSection title="Deliverable Due Date Warning">
        {mapLayout('Deliverable')}
      </DetailsSection>
      <DetailsSection noBorder title="Release Key Date Warning">
        {mapLayout('Release')}
      </DetailsSection>
    </div>
  );
};

export default withUserContext(DueDates);

import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ToastContainer } from 'react-toastify';

import Login from '../shared/components/account/Login';
import ForgotPassword from '../shared/components/account/ForgotPassword';
import ResetPassword from '../shared/components/account/ResetPassword';
import CreateFreelancerAccount from './components/account/CreateFreelancerAccount';
import TopBar from './components/shared/TopBarFreelancer';
import appStyles from '../shared/styles/common/base';
import SideBar from '../shared/components/common/SideBar';
import getUserData from './mutations/getUserData';
import UserProvider from '../shared/contexts/userContext/UserProvider';
import authService from '../shared/services/authService';
import useQueryParams from '../shared/hooks/useQueryParams';
import InvalidLink from '../shared/components/account/InvalidLink';
import Forbidden from '../shared/components/account/Forbidden';
import useToastStyles from '../shared/styles/common/useToastStyles';
import clsx from 'clsx';
import ConfirmEmailChange from './components/account/ConfirmEmailChange';
import { FREELANCER_ROUTES } from './routes';
import { bootIntercomFreelancer, updateIntercom } from '../shared/services/intercomService';
import StyledContent from '../shared/components/common/StyledContent';

const LoginContainer = () => {
  const classes = appStyles();
  const queryParamsToken = useQueryParams().get('contractor-token');
  if (queryParamsToken) {
    authService._removeTokens();
  }
  const { isAuthenticated } = authService;

  return (
    <main className={clsx(classes.loginContainer, classes.loginContainerFreelancer)}>
      <Route
        exact
        path="/login"
        render={props => (isAuthenticated ? <Redirect to="/" /> : <Login {...props} />)}
      />
      <Route
        exact
        path="/login/forgot-password"
        render={props => (isAuthenticated ? <Redirect to="/" /> : <ForgotPassword {...props} />)}
      />
      <Route exact path="/login/reset-password" render={props => <ResetPassword {...props} />} />
      <Route
        exact
        path="/login/create-account"
        render={props =>
          isAuthenticated ? <Redirect to="/" /> : <CreateFreelancerAccount {...props} />
        }
      />
      <Route exact path="/login/email-change" component={ConfirmEmailChange} />
    </main>
  );
};

const DefaultContainer = props => {
  const classes = appStyles();

  useEffect(() => {
    // Informs intercom that the route has changed
    updateIntercom();
    // closes the quick view drawer when redirecting to another route
  }, [props.location]);

  return (
    <UserProvider userInfo={props.userInfo} setUserInfo={props.setUserInfo}>
      <div className={classes.root}>
        <SideBar />
        <div className={classes.appContainer}>
          <TopBar />
          <main className={classes.mainContainer}>
            <StyledContent>
              <Switch>
                <Redirect from="/jobs/contracts/:id" to="/jobs/:id" />
                {FREELANCER_ROUTES.map(route => (
                  <Route key={route.path} path={route.path} component={route.component} />
                ))}
              </Switch>
            </StyledContent>
          </main>
        </div>
      </div>
    </UserProvider>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(authService.isAuthenticated);
  const [userInfo, setUserInfo] = useState({});
  const [tokenExpTimestamp, setTokenExpTimestamp] = useState('');

  const getUserDataCallback = userInfo => {
    setUserInfo(userInfo);
    bootIntercomFreelancer(userInfo);
  };

  /**
   *
   * @param {bool} getUserInfo
   *
   */
  const handleRefreshToken = getUserInfo => (isAuthenticated, payload) => {
    setAuthenticated(isAuthenticated);
    setTokenExpTimestamp(payload && payload.exp);
    if (isAuthenticated && getUserInfo) {
      getUserData(getUserDataCallback);
    }
  };

  useEffect(() => {
    if (authenticated) {
      if (tokenExpTimestamp && userInfo) {
        // if we have expiration timestamp, start a timer to refresh token 60 sec before it expires,
        // no need to get user data, we would already have it
        const timer = setTimeout(() => {
          authService.refreshToken(handleRefreshToken(false));
        }, (tokenExpTimestamp - Math.round(new Date().getTime() / 1000) - 60) * 1000);
        return () => clearTimeout(timer);
      } else {
        // immediately refresh token and get user data - this will happen on page load
        authService.refreshToken(handleRefreshToken(true));
      }
    }
  }, [tokenExpTimestamp, authenticated, userInfo]);

  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          !isEmpty(userInfo) ? (
            <Component {...props} userInfo={userInfo} setUserInfo={setUserInfo} />
          ) : (
            // TODO replace with a loding indicator
            'Verifying token & fetching user data'
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const FreelancerApp = () => {
  const classes = useToastStyles();
  return (
    <Fragment>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/deliverables" />} />
        <Route path="/login" component={LoginContainer} />
        <Route path="/not-found" component={InvalidLink} />
        <Route path="/forbidden" component={Forbidden} />
        <PrivateRoute component={DefaultContainer} />
      </Switch>
      <ToastContainer
        className={classes.toastContainer2}
        toastClassName={classes.toast2}
        bodyClassName={classes.toastBody2}
        position="top-left"
        autoClose={20000}
        hideProgressBar={true}
        closeButton={false}
      />
    </Fragment>
  );
};

export default FreelancerApp;

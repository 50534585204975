import React from 'react';
import PropTypes from 'prop-types';
import MuiPhoneField from 'material-ui-phone-number';
import formStyles from '../../../styles/common/formStyles';

const PhoneField = props => {
  const {
    name,
    value,
    onChange,
    label,
    disabled,
    error,
    helperText,
    alignRight,
    big,
    InputProps,
    ...otherProps
  } = props;
  const classes = formStyles();

  const handleChange = value => onChange(value === '+' ? '' : value);

  const phoneFieldProps = {
    name,
    value,
    label,
    onChange: handleChange,
    defaultCountry: 'us',
    disableAreaCodes: true,
    disableDropdown: disabled,
    variant: 'outlined',
    inputClass: classes.textFieldRoot,
    InputLabelProps: {
      shrink: true
    },
    inputProps: {
      style: { textAlign: alignRight ? 'right' : 'left' }
    },
    InputProps: {
      notched: false,
      disabled: disabled,
      ...InputProps
    },
    ...otherProps,
    ...(typeof error === 'undefined'
      ? {}
      : {
          error,
          helperText
        })
  };

  return <MuiPhoneField {...phoneFieldProps} />;
};

PhoneField.propTypes = {
  InputProps: PropTypes.object
};

PhoneField.defaultProps = {
  InputProps: {}
};

export default PhoneField;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';
import PropTypes from 'prop-types';

const ImportDeliverablesMutation = (file, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation ImportDeliverablesMutation($input: ImportDeliverablesInput!) {
        importDeliverables(input: $input) {
          result
          errors
        }
      }
    `,
    variables: {
      input: {
        file: file.name
      }
    },
    uploadables: {
      file: file
    },
    onCompleted: (response, errors) => {
      callback(response.importDeliverables, errors);
    },
    onError: err => console.error(err)
  });
};

ImportDeliverablesMutation.propTypes = {
  file: PropTypes.any.isRequired
};

export default ImportDeliverablesMutation;

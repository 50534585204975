import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const contactQuery = graphql`
  query SearchableContactDropdownQuery(
    $staffName: String
    $first: Int
    $orderBy: String
    $includeInactive: Boolean
  ) {
    allStaff(
      staffName: $staffName
      orderBy: $orderBy
      first: $first
      includeInactive: $includeInactive
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

const SearchableContactDropdown = props => {
  const { onChange, noLabel, label, ...otherProps } = props;

  const getFetchVariables = inputValue => {
    return {
      staffName: inputValue,
      first: 10,
      orderBy: 'lastName',
      includeInactive: false
    };
  };

  const formatOptions = staffList => {
    if (!staffList || !staffList.allStaff) {
      return [];
    }

    return staffList.allStaff.edges.map(staff => {
      return {
        value: staff.node.id,
        label: staff.node.fullName
      };
    });
  };

  return (
    <SearchableDropdown
      resultKey="allStaff"
      query={contactQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      label={noLabel ? '' : label || 'Contact'}
      {...otherProps}
    />
  );
};

export default SearchableContactDropdown;

/**
 * @flow
 * @relayHash d08b4fb2e185921445e80864d61f1c8a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResendPaymentRequestInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type ResendPaymentRequestMutationVariables = {|
  input: ResendPaymentRequestInput
|};
export type ResendPaymentRequestMutationResponse = {|
  +resendPaymentRequest: ?{|
    +emailSent: ?boolean
  |}
|};
export type ResendPaymentRequestMutation = {|
  variables: ResendPaymentRequestMutationVariables,
  response: ResendPaymentRequestMutationResponse,
|};
*/


/*
mutation ResendPaymentRequestMutation(
  $input: ResendPaymentRequestInput!
) {
  resendPaymentRequest(input: $input) {
    emailSent
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ResendPaymentRequestInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resendPaymentRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResendPaymentRequestPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailSent",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ResendPaymentRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ResendPaymentRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ResendPaymentRequestMutation",
    "id": null,
    "text": "mutation ResendPaymentRequestMutation(\n  $input: ResendPaymentRequestInput!\n) {\n  resendPaymentRequest(input: $input) {\n    emailSent\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9689e8fef286cc04ab37f0e83a116c75';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../shared/contexts/tableHistoryContext';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import JobInvoicesTab from '../tabs/JobInvoicesTab';

const JobInvoicesTabRenderer = props => {
  const variables = useTableHistoryQueryVariables();
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query JobInvoicesTabRendererQuery($id: ID!, $first: Int, $after: String) {
          job(id: $id) {
            ...JobInvoicesTab_job @arguments(first: $first, after: $after)
          }
          __type(name: "JobInvoiceStatus") {
            ...JobInvoicesTab_statusTypes
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <JobInvoicesTab
          job={relayProps.job}
          statusTypes={relayProps.__type}
          relayProps={relayProps}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(JobInvoicesTabRenderer, 'freJobInvoices');

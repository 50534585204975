/**
 * @flow
 * @relayHash 1578d82991fef7ba16801400ca0f9348
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DownloadBatchDeliverableFinalFilesInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type DownloadBatchDeliverableFinalFilesMutationVariables = {|
  input: DownloadBatchDeliverableFinalFilesInput
|};
export type DownloadBatchDeliverableFinalFilesMutationResponse = {|
  +downloadBatchDeliverableFinalFiles: ?{|
    +email: ?boolean
  |}
|};
export type DownloadBatchDeliverableFinalFilesMutation = {|
  variables: DownloadBatchDeliverableFinalFilesMutationVariables,
  response: DownloadBatchDeliverableFinalFilesMutationResponse,
|};
*/


/*
mutation DownloadBatchDeliverableFinalFilesMutation(
  $input: DownloadBatchDeliverableFinalFilesInput!
) {
  downloadBatchDeliverableFinalFiles(input: $input) {
    email
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DownloadBatchDeliverableFinalFilesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "downloadBatchDeliverableFinalFiles",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DownloadBatchDeliverableFinalFilesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DownloadBatchDeliverableFinalFilesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DownloadBatchDeliverableFinalFilesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DownloadBatchDeliverableFinalFilesMutation",
    "id": null,
    "text": "mutation DownloadBatchDeliverableFinalFilesMutation(\n  $input: DownloadBatchDeliverableFinalFilesInput!\n) {\n  downloadBatchDeliverableFinalFiles(input: $input) {\n    email\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '540354c6c73bae6691d74be4f125e725';
module.exports = node;

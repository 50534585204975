/**
 * @flow
 * @relayHash a3e9e496ba52a7e598cddd672aba68e7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type ResetDeliverableInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type ResetDeliverableMutationVariables = {|
  input: ResetDeliverableInput
|};
export type ResetDeliverableMutationResponse = {|
  +resetDeliverable: ?{|
    +updatedDeliverable: ?{|
      +state: DeliverableState
    |}
  |}
|};
export type ResetDeliverableMutation = {|
  variables: ResetDeliverableMutationVariables,
  response: ResetDeliverableMutationResponse,
|};
*/


/*
mutation ResetDeliverableMutation(
  $input: ResetDeliverableInput!
) {
  resetDeliverable(input: $input) {
    updatedDeliverable {
      state
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ResetDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ResetDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resetDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ResetDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ResetDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resetDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ResetDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ResetDeliverableMutation",
    "id": null,
    "text": "mutation ResetDeliverableMutation(\n  $input: ResetDeliverableInput!\n) {\n  resetDeliverable(input: $input) {\n    updatedDeliverable {\n      state\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '594527f0ff1e7f4655b8df5e4fe5b026';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractorDeliverablesList_contractor$ref: FragmentReference;
declare export opaque type ContractorDeliverablesList_contractor$fragmentType: ContractorDeliverablesList_contractor$ref;
export type ContractorDeliverablesList_contractor = {|
  +id: string,
  +assignedDeliverables: ?{|
    +edgeCount: ?number,
    +totalCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
      +startCursor: ?string,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
        +category: {|
          +name: string
        |},
        +categoryType: ?{|
          +name: string
        |},
        +state: DeliverableState,
        +amount: ?any,
        +currencyCode: ?DeliverableCurrencyCode,
        +dueDate: ?any,
        +release: ?{|
          +id: string,
          +name: string,
          +product: {|
            +id: string,
            +title: string,
          |},
        |},
        +assignedStaff: ?{|
          +user: {|
            +id: string
          |},
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |},
      |}
    |}>,
  |},
  +$refType: ContractorDeliverablesList_contractor$ref,
|};
export type ContractorDeliverablesList_contractor$data = ContractorDeliverablesList_contractor;
export type ContractorDeliverablesList_contractor$key = {
  +$data?: ContractorDeliverablesList_contractor$data,
  +$fragmentRefs: ContractorDeliverablesList_contractor$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "ContractorDeliverablesList_contractor",
  "type": "ContractorNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "dueDateFrom",
      "type": "Date"
    },
    {
      "kind": "RootArgument",
      "name": "dueDateTo",
      "type": "Date"
    },
    {
      "kind": "RootArgument",
      "name": "category",
      "type": "ID"
    },
    {
      "kind": "RootArgument",
      "name": "state",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "title",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "releaseId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "productId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "assignedToStaffOrContractor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "staffCoordinator",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "tags",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "includeClosed",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCanceled",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeInactive",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "categoryType",
      "type": "ID"
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignedDeliverables",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "assignedToStaffOrContractor",
          "variableName": "assignedToStaffOrContractor"
        },
        {
          "kind": "Variable",
          "name": "category",
          "variableName": "category"
        },
        {
          "kind": "Variable",
          "name": "categoryType",
          "variableName": "categoryType"
        },
        {
          "kind": "Variable",
          "name": "dueDateFrom",
          "variableName": "dueDateFrom"
        },
        {
          "kind": "Variable",
          "name": "dueDateTo",
          "variableName": "dueDateTo"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "includeCanceled",
          "variableName": "includeCanceled"
        },
        {
          "kind": "Variable",
          "name": "includeClosed",
          "variableName": "includeClosed"
        },
        {
          "kind": "Variable",
          "name": "includeInactive",
          "variableName": "includeInactive"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "productId",
          "variableName": "productId"
        },
        {
          "kind": "Variable",
          "name": "releaseId",
          "variableName": "releaseId"
        },
        {
          "kind": "Variable",
          "name": "staffCoordinator",
          "variableName": "staffCoordinator"
        },
        {
          "kind": "Variable",
          "name": "state",
          "variableName": "state"
        },
        {
          "kind": "Variable",
          "name": "tags",
          "variableName": "tags"
        },
        {
          "kind": "Variable",
          "name": "title",
          "variableName": "title"
        }
      ],
      "concreteType": "DeliverableNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasPreviousPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "DeliverableNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "category",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CategoryNode",
                  "plural": false,
                  "selections": (v3/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "categoryType",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CategoryTypeNode",
                  "plural": false,
                  "selections": (v3/*: any*/)
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "state",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currencyCode",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dueDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "release",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ReleaseNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "product",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ProductNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "assignedStaff",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "user",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "representativeImageUrl",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b712c9915292fc78af95bd9cdfe673a';
module.exports = node;

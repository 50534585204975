/**
 * @flow
 * @relayHash 6d91af844be37c611a7e3159c19387bd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignInEmailInput = {|
  email?: ?string,
  clientMutationId?: ?string,
|};
export type SignInEmailMutationVariables = {|
  input: SignInEmailInput
|};
export type SignInEmailMutationResponse = {|
  +signInEmail: ?{|
    +passwordRequired: ?boolean,
    +signInIdpLink: ?string,
  |}
|};
export type SignInEmailMutation = {|
  variables: SignInEmailMutationVariables,
  response: SignInEmailMutationResponse,
|};
*/


/*
mutation SignInEmailMutation(
  $input: SignInEmailInput!
) {
  signInEmail(input: $input) {
    passwordRequired
    signInIdpLink
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SignInEmailInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "signInEmail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignInEmailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "passwordRequired",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "signInIdpLink",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SignInEmailMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SignInEmailMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SignInEmailMutation",
    "id": null,
    "text": "mutation SignInEmailMutation(\n  $input: SignInEmailInput!\n) {\n  signInEmail(input: $input) {\n    passwordRequired\n    signInIdpLink\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc6f407f60eacc6aaacc054d3a438950';
module.exports = node;

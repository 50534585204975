import { ATTRS, TOOLS, OPTIONS } from './CanvasTools/contants';

export const mergeAnnotationData = (localData, serverData, userId) => {
  // we merge all the local annotation that are owned by the user with all
  // the server annotation that are not owned by the user.
  if (!Array.isArray(serverData)) {
    serverData = [];
  }
  const currentUserData = localData.filter(obj => {
    return obj[ATTRS.authorId] === userId;
  });
  const otherUsersData = serverData.filter(obj => {
    return obj[ATTRS.authorId] !== userId;
  });

  const mergedData = currentUserData.concat(otherUsersData);

  return mergedData;
};

export const getCanvasDataAsJson = (canvas, dimensions) => {
  const propertiesToIncludeInJson = Object.keys(ATTRS).map(key => ATTRS[key]);
  const json = canvas.toJSON(propertiesToIncludeInJson);

  // Fabric includes background image in the json, however this causes issues with background size later on,
  // as the background object is not accessible when loading from the json. Best solution is to delete
  // background object and re-set it on load.
  if (json.backgroundImage) {
    delete json.backgroundImage;
  }

  const { width, height } = dimensions;
  const objects = json.objects;

  // Parsing objects positions into a relative format.
  objects.forEach(obj => {
    updateRelativeAttributes(obj, width, height);
  });

  return objects;
};

export const updateRelativeAttributes = (obj, width, height) => {
  switch (obj[ATTRS.type]) {
    case TOOLS.arrow.name:
      obj[ATTRS.relative] = {
        ...obj[ATTRS.relative],
        left: obj.left / width,
        top: obj.top / height,
        width: obj.width / width,
        height: obj.height / height
      };

      if (!obj[ATTRS.relative].hasOwnProperty('original')) {
        obj[ATTRS.relative].original = {
          width,
          height
        };
      }
      break;
    case TOOLS.circle.name:
      obj[ATTRS.relative] = {
        left: obj.left / width,
        top: obj.top / height,
        radius: obj.radius / width
      };
      break;
    case TOOLS.dot.name:
      obj[ATTRS.relative] = {
        left: obj.left / width,
        top: obj.top / height
      };
      break;
    case TOOLS.line.name:
      obj[ATTRS.relative] = {
        x1: (obj.left + obj.x1) / width,
        x2: (obj.left + obj.x2) / width,
        y1: (obj.top + obj.y1) / height,
        y2: (obj.top + obj.y2) / height
      };
      break;
    case TOOLS.free.name:
      obj[ATTRS.relative] = {
        ...obj[ATTRS.relative],
        left: obj.left / width,
        top: obj.top / height
      };

      if (!obj[ATTRS.relative].hasOwnProperty('original')) {
        obj[ATTRS.relative].original = {
          width,
          height
        };
      }
      break;
    default:
      break;
  }
};

export const setArrowObjectScale = (object, scale = object.scaleX) => {
  object.getObjects().forEach(el => {
    switch (el[ATTRS.type]) {
      case TOOLS.arrow.elements.triangle:
        el.set({
          scaleX: 1 / scale,
          scaleY: 1 / scale
        });
        break;
      case TOOLS.arrow.elements.line:
        el.set({
          strokeWidth: OPTIONS.strokeWidth / scale
        });
        break;
      default:
        break;
    }
  });
};

export const updateStrokeWhileScaling = ({ target: object }) => {
  if (object[ATTRS.type] === TOOLS.arrow.name) {
    setArrowObjectScale(object);
  } else {
    object.set({
      strokeWidth: OPTIONS.strokeWidth / object.scaleX
    });
  }
};

export const annotationResizeHandler = ({ target: object }) => {
  if (object[ATTRS.type] === TOOLS.free.name || object[ATTRS.type] === TOOLS.arrow.name) {
    object.set({
      [ATTRS.relative]: {
        ...object[ATTRS.relative],
        scaled:
          object[ATTRS.relative].oldWindowScale !== 1
            ? object.scaleX / object[ATTRS.relative].oldWindowScale
            : object.scaleX
      }
    });
  }
};

export const hideControls = (object, controlsArray) => {
  controlsArray.forEach(control => object.setControlVisible(control, false));
};

export const setEditMode = object => {
  object.set({
    selectable: true,
    hoverCursor: 'pointer',
    borderColor: 'white',
    borderScaleFactor: 1,
    centeredScaling: true,
    centeredRotation: true,
    cornerSize: 10,
    cornerStrokeColor: 'black',
    transparentCorners: false,
    cornerColor: 'white',
    active: true
  });
};

export const reviveObjects = (_jsonObject, object, dimensions) => {
  const { width, height } = dimensions;
  const relative = object[ATTRS.relative];

  switch (object[ATTRS.type]) {
    case TOOLS.arrow.name:
      const arrowScale = width / relative.original.width;
      const finalArrowScale = relative.scaled ? arrowScale * relative.scaled : arrowScale;

      object.set({
        left: relative.left * width,
        top: relative.top * height,
        scaleX: finalArrowScale,
        scaleY: finalArrowScale,
        [ATTRS.relative]: {
          ...object[ATTRS.relative],
          oldWindowScale: arrowScale
        }
      });

      setArrowObjectScale(object, finalArrowScale);
      hideControls(object, ['mt', 'ml', 'mr', 'mb']);
      break;
    case TOOLS.circle.name:
      object.set({
        left: relative.left * width,
        top: relative.top * height,
        radius: relative.radius * width,
        strokeWidth: OPTIONS.strokeWidth / object.scaleX
      });
      hideControls(object, ['mt', 'ml', 'mr', 'mb', 'mtr']);
      break;
    case TOOLS.dot.name:
      object.set({
        left: relative.left * width,
        top: relative.top * height
      });
      hideControls(object, ['tl', 'tr', 'br', 'bl', 'mtr', 'mt', 'ml', 'mr', 'mb']);
      break;
    case TOOLS.line.name:
      object.set({
        x1: relative.x1 * width,
        x2: relative.x2 * width,
        y1: relative.y1 * height,
        y2: relative.y2 * height,
        strokeWidth: OPTIONS.strokeWidth / object.scaleX
      });
      hideControls(object, ['mt', 'ml', 'mr', 'mb']);
      break;
    case TOOLS.free.name:
      // since image proportions are always kept, it does not matter from what property scale is calculated
      const windowScale = width / relative.original.width;
      const finalScale = relative.scaled ? windowScale * relative.scaled : windowScale;

      object.set({
        left: relative.left * width,
        top: relative.top * height,
        scaleX: finalScale,
        scaleY: finalScale,
        strokeWidth: OPTIONS.strokeWidth / finalScale,
        [ATTRS.relative]: {
          ...object[ATTRS.relative],
          oldWindowScale: windowScale
        }
      });

      object.setCoords();
      hideControls(object, ['mt', 'ml', 'mr', 'mb']);
      break;
    default:
      break;
  }
};

export const setAnnotationColor = (object, color) => {
  if (!object || !color) return;
  const overwriteOriginalColor = true;
  switch (object[ATTRS.type]) {
    case TOOLS.dot.name:
      object.set({ fill: color });
      break;
    case TOOLS.arrow.name:
      object.getObjects().forEach(el => el.set({ fill: color, stroke: color }));
      break;
    default:
      object.set({ stroke: color });
      break;
  }

  if (overwriteOriginalColor) {
    object.set({ [ATTRS.originalColor]: color });
  }
};

export const getAnnotationColor = object => {
  switch (object[ATTRS.type]) {
    case TOOLS.dot.name:
      return object.fill;
    case TOOLS.arrow.name:
      return object.getObjects()[0].stroke
        ? object.getObjects()[0].stroke
        : object.getObjects()[0].fill;
    default:
      return object.stroke;
  }
};

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentDetailsContent_details$ref: FragmentReference;
declare export opaque type PaymentDetailsContent_details$fragmentType: PaymentDetailsContent_details$ref;
export type PaymentDetailsContent_details = {|
  +paymentSettings: ?{|
    +paymentTerm: string,
    +preferredCurrency: string,
    +paymentInfo: ?string,
    +includeVat: boolean,
    +vatPercentage: ?number,
    +taxPercentage: ?number,
    +taxIdentifier: string,
  |},
  +$refType: PaymentDetailsContent_details$ref,
|};
export type PaymentDetailsContent_details$data = PaymentDetailsContent_details;
export type PaymentDetailsContent_details$key = {
  +$data?: PaymentDetailsContent_details$data,
  +$fragmentRefs: PaymentDetailsContent_details$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PaymentDetailsContent_details",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "paymentSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "FreelancerPaymentConfigurationNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "paymentTerm",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "preferredCurrency",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "paymentInfo",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "includeVat",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "vatPercentage",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "taxPercentage",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "taxIdentifier",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f2248cb821942955262a0799fb67dd91';
module.exports = node;

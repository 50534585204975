/**
 * @flow
 * @relayHash d36ed4fbfec1c3ccfe3beb5aedf600bd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteContractorGroupInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeleteContractorGroupMutationVariables = {|
  input: DeleteContractorGroupInput
|};
export type DeleteContractorGroupMutationResponse = {|
  +deleteContractorGroup: ?{|
    +isDeleted: ?boolean
  |}
|};
export type DeleteContractorGroupMutation = {|
  variables: DeleteContractorGroupMutationVariables,
  response: DeleteContractorGroupMutationResponse,
|};
*/


/*
mutation DeleteContractorGroupMutation(
  $input: DeleteContractorGroupInput!
) {
  deleteContractorGroup(input: $input) {
    isDeleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteContractorGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteContractorGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteContractorGroupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isDeleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteContractorGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteContractorGroupMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteContractorGroupMutation",
    "id": null,
    "text": "mutation DeleteContractorGroupMutation(\n  $input: DeleteContractorGroupInput!\n) {\n  deleteContractorGroup(input: $input) {\n    isDeleted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0b2be953af1aa58d116b695e2964cffb';
module.exports = node;

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Typography } from '@material-ui/core';

import ReviewAssetCardContent from './ReviewAssetCardContent';
import DownloadFileSquareButton from '../UI/DownloadFileSquareButton';
import AssetReviewCommentChip from '../UI/AssetReviewCommentChip';
import Card from '../assets/Card';
import { useReviewAssetContentStyles } from '../../styles/deliverable/useReviewAssetContentStyles';
import { formatDateDisplay } from '../../utils/formatters';
import { FILE_STORAGE_CLASSES, REVIEW_INTERFACES } from '../../constants';
import withUserContext from '../../contexts/userContext/withUserContext';
import { getDateDisplayFormatFromUserContext } from '../../utils/helpers';
import ConfirmDialog from '../common/ConfirmationDialog';
import SquareButton from '../UI/SquareButton';
import GlobalButton from '../UI/GlobalButton';
import getFetchReviewAssetDownloadUrl from '../../mutations/review/fetchReviewAssetDownloadUrl';

const ReviewAssetCard = props => {
  const {
    assetId,
    revisionNumber,
    fileThumbnailUrl,
    fileName,
    dateCreated,
    showDate,
    cardType,
    handleItemClicked,
    handleItemDelete,
    reviewInterface,
    active,
    storageClass,
    userContext
  } = props;

  const classes = useReviewAssetContentStyles({ active });
  const isZeroAsset = revisionNumber === 0;
  const isStandardStorage = storageClass === FILE_STORAGE_CLASSES.STANDARD;

  const handleCardClick =
    cardType === 'preview' &&
    !isZeroAsset &&
    reviewInterface !== REVIEW_INTERFACES.generic &&
    isStandardStorage
      ? () => handleItemClicked(assetId)
      : undefined;

  const handleDownloadClick = callback => {
    getFetchReviewAssetDownloadUrl(assetId, callback)();
  };

  const showFileName =
    reviewInterface === REVIEW_INTERFACES.generic || reviewInterface === REVIEW_INTERFACES.a3dModel;

  return (
    <Card
      onCardClick={handleCardClick}
      classes={{
        card: classes.card,
        cardActionArea: classes.cardActionArea
      }}
      noHover
      renderTopLeft={() => <AssetReviewCommentChip revisionNumber={revisionNumber} />}
      renderTopRight={() =>
        cardType === 'preview' && (
          <Fragment>
            {handleCardClick && (
              <GlobalButton handleClick={handleCardClick} variant="black">
                View Markup
              </GlobalButton>
            )}
            {!isZeroAsset && isStandardStorage && (
              <DownloadFileSquareButton
                id="downloadReviewAsset"
                handleDownloadClick={handleDownloadClick}
                fileName={fileName}
              />
            )}
            {handleItemDelete && !isZeroAsset && (
              <ConfirmDialog
                content="Are you sure you want to remove this asset?"
                copies={{
                  confirmCopy: 'Confirm'
                }}
              >
                {confirm => (
                  <SquareButton icon="trash" onClick={confirm(() => handleItemDelete(assetId))} />
                )}
              </ConfirmDialog>
            )}
          </Fragment>
        )
      }
      renderContent={() => (
        <ReviewAssetCardContent
          fileUrl={fileThumbnailUrl}
          reviewInterface={reviewInterface}
          isStandardStorage={isStandardStorage}
        />
      )}
      renderActionBottom={() => (
        <Fragment>
          {showFileName && <Typography variant="body2">{fileName}</Typography>}
          {showDate && (
            <Typography component="span" className={classes.assetDateText}>
              {formatDateDisplay(dateCreated, getDateDisplayFormatFromUserContext(userContext))}
            </Typography>
          )}
        </Fragment>
      )}
    />
  );
};

ReviewAssetCard.propTypes = {
  /** Determines the url to image asset (either review url or thumbnail url)*/
  fileThumbnailUrl: PropTypes.string.isRequired,
  /** Text to render the asset review revision number: 10 */
  revisionNumber: PropTypes.number.isRequired,
  /** Text to render date of asset uploaded: JS Date object */
  dateCreated: PropTypes.string,
  /** Flag to show/hide date text */
  showDate: PropTypes.bool.isRequired,
  /** String Flag to set display size: "thread" | "preview" | "gallery" */
  cardType: PropTypes.oneOf(['thread', 'preview']).isRequired,
  /** Callback function when component is clicked. Argument: (event, idx) */
  handleItemClicked: PropTypes.func,
  /** Boolean for applying active asset styling */
  active: PropTypes.bool,
  /** Func for how to handle deleting file - if null, no Trash button will be shown */
  handleItemDelete: PropTypes.func,
  /**Only standard storage review assets can be viewed/downloaded */
  storageClass: PropTypes.oneOf(Object.keys(FILE_STORAGE_CLASSES))
};

ReviewAssetCard.defaultProps = {
  fileThumbnailUrl: '',
  revisionNumber: 0,
  showDate: false,
  cardType: 'thread',
  handleItemClicked: null,
  handleItemDelete: null,
  storageClass: FILE_STORAGE_CLASSES.STANDARD
};

export default withUserContext(ReviewAssetCard);

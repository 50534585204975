/**
 * @flow
 * @relayHash 08fec18df3fef40be2b07554b34d4d63
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type CreateJobInput = {|
  contractor: string,
  category: string,
  name?: ?string,
  description?: ?string,
  contractTemplate?: ?string,
  jobDeadline?: ?any,
  contractSignDeadline?: ?any,
  contractDate?: ?any,
  totalValue?: ?any,
  customFields?: ?any,
  deliverables?: ?$ReadOnlyArray<?string>,
  withContract: boolean,
  followerIds?: ?$ReadOnlyArray<?string>,
  addFollowersToDeliverables?: ?boolean,
  clientMutationId?: ?string,
|};
export type CreateJobMutationVariables = {|
  input: CreateJobInput
|};
export type CreateJobMutationResponse = {|
  +createJob: ?{|
    +newJob: ?{|
      +id: string,
      +deliverables: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +state: DeliverableState,
            +assignedContractor: ?{|
              +id: string,
              +fullName: ?string,
              +representativeImageUrl: ?string,
            |},
          |}
        |}>
      |},
    |},
    +emailSent: ?boolean,
    +closedSolicitations: ?$ReadOnlyArray<?{|
      +id: string,
      +subject: string,
    |}>,
  |}
|};
export type CreateJobMutation = {|
  variables: CreateJobMutationVariables,
  response: CreateJobMutationResponse,
|};
*/


/*
mutation CreateJobMutation(
  $input: CreateJobInput!
) {
  createJob(input: $input) {
    newJob {
      id
      deliverables {
        edges {
          node {
            state
            assignedContractor {
              id
              fullName
              representativeImageUrl
            }
            id
          }
        }
      }
    }
    emailSent
    closedSolicitations {
      id
      subject
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateJobInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "assignedContractor",
  "storageKey": null,
  "args": null,
  "concreteType": "ContractorNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailSent",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "closedSolicitations",
  "storageKey": null,
  "args": null,
  "concreteType": "SolicitationNode",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subject",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateJobMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateJobPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "newJob",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverables",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateJobMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateJobPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "newJob",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverables",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateJobMutation",
    "id": null,
    "text": "mutation CreateJobMutation(\n  $input: CreateJobInput!\n) {\n  createJob(input: $input) {\n    newJob {\n      id\n      deliverables {\n        edges {\n          node {\n            state\n            assignedContractor {\n              id\n              fullName\n              representativeImageUrl\n            }\n            id\n          }\n        }\n      }\n    }\n    emailSent\n    closedSolicitations {\n      id\n      subject\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1746567baf0efa8dd353efc960fdcbd2';
module.exports = node;

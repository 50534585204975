/**
 * @flow
 * @relayHash 33e6e539f4489790e8b807513365d33c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RolesDetails_allPermissions$ref = any;
type RolesDetails_role$ref = any;
export type RolesDetailsPageQueryVariables = {|
  id: string
|};
export type RolesDetailsPageQueryResponse = {|
  +organizationRole: ?{|
    +$fragmentRefs: RolesDetails_role$ref
  |},
  +__type: ?{|
    +$fragmentRefs: RolesDetails_allPermissions$ref
  |},
|};
export type RolesDetailsPageQuery = {|
  variables: RolesDetailsPageQueryVariables,
  response: RolesDetailsPageQueryResponse,
|};
*/


/*
query RolesDetailsPageQuery(
  $id: ID!
) {
  organizationRole(id: $id) {
    ...RolesDetails_role
    id
  }
  __type(name: "OrganizationPermission") {
    ...RolesDetails_allPermissions
  }
}

fragment RolesDetails_role on OrganizationRoleNode {
  id
  name
  isDefault
  defaultPermissions
  extraPermissions
  basedOnRole {
    name
    id
  }
}

fragment RolesDetails_allPermissions on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "OrganizationPermission"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RolesDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organizationRole",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationRoleNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RolesDetails_role",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"OrganizationPermission\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RolesDetails_allPermissions",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RolesDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organizationRole",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationRoleNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isDefault",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "defaultPermissions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "extraPermissions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "basedOnRole",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationRoleNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"OrganizationPermission\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RolesDetailsPageQuery",
    "id": null,
    "text": "query RolesDetailsPageQuery(\n  $id: ID!\n) {\n  organizationRole(id: $id) {\n    ...RolesDetails_role\n    id\n  }\n  __type(name: \"OrganizationPermission\") {\n    ...RolesDetails_allPermissions\n  }\n}\n\nfragment RolesDetails_role on OrganizationRoleNode {\n  id\n  name\n  isDefault\n  defaultPermissions\n  extraPermissions\n  basedOnRole {\n    name\n    id\n  }\n}\n\nfragment RolesDetails_allPermissions on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0b29128e29c8e784de67108d80be4f3';
module.exports = node;

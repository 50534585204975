import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import ProductDetailsContent from './ProductDetailsContent';

const ProductDetailsTab = props => {
  const { id } = useParams();
  return (
    <QueryRenderer
      query={graphql`
        query ProductDetailsTabQuery($id: ID!) {
          product(id: $id) {
            ...ProductDetailsContent_product
          }
        }
      `}
      variables={{ id }}
      render={relayProps => <ProductDetailsContent product={relayProps.product} {...props} />}
    />
  );
};

export default ProductDetailsTab;

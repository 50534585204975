import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import NotificationSettings from '../../../../shared/components/profile/NotificationSettings';

const SETTINGS = {
  inviteAccepted: {
    category: 'contractor',
    name: 'invite_accepted',
    title: 'Contractor Invite Accepted',
    description: 'Receive notifications when a contractor accepts an invite to AWEbase.'
  },
  staffAssigned: {
    category: 'deliverable',
    name: 'staff_assigned',
    title: 'Set/Removed as Coordinator',
    description:
      'Receive notifications when someone sets or removes you as Coordinator to or from Deliverables.'
  },
  reviewAssetUpload: {
    category: 'deliverable',
    name: 'review_asset_upload',
    title: 'Review Asset Upload',
    description:
      'Receive notifications when someone uploads a review asset to Deliverable you are coordinating or are assigned to.'
  },
  reviewAssetComment: {
    category: 'deliverable',
    name: 'review_asset_comment',
    title: 'Review Asset Comment',
    description:
      'Receive notifications when someone comments on a Deliverable you are coordinating or are assigned to.'
  },
  deliverableFileUpload: {
    category: 'deliverable',
    name: 'deliverable_file_upload',
    title: 'Deliverable File Upload',
    description:
      'Receive notifications when someone uploads a deliverable file to a Deliverable you are coordinating or are assigned to.'
  },
  assignedInternally: {
    category: 'deliverable',
    name: 'assigned_internally',
    title: 'Internal Deliverable Assigned/Unassigned',
    description:
      'Receive notifications when someone assigns or unassigns you internally to or from a Deliverable.'
  },
  deliverableCloseCancelReset: {
    category: 'deliverable',
    name: 'deliverable_close_cancel_reset',
    title: 'Deliverable Canceled/Closed/Reset',
    description:
      'Receive notifications when someone cancels, closes, or resets an internal Deliverable you are coordinating or are assigned to'
  },
  solicitationComment: {
    category: 'solicitation',
    name: 'solicitation_comment',
    title: 'JobOpp Comment',
    description: 'Receive notifications when someone comments on a JobOpp you are coordinating.'
  },
  jobComment: {
    category: 'job',
    name: 'job_comment',
    title: 'Job Comment',
    description: 'Receive notifications when someone comments on a Job you are coordinating.'
  }
};

const DELIVERABLES_SECTION = {
  label: 'Deliverable Notifications',
  emailLabel: true,
  order: [
    'staffAssigned',
    'reviewAssetUpload',
    'reviewAssetComment',
    'deliverableFileUpload',
    'assignedInternally',
    'deliverableCloseCancelReset'
  ]
};
const SOLICITATIONS_SECTION = {
  label: 'JobOpp Notifications',
  emailLabel: true,
  order: ['solicitationComment']
};
const JOB_SECTION = { label: 'Job Notifications', emailLabel: false, order: ['jobComment'] };
const CONTRACTOR_SECTION = {
  label: 'Contractor Notifications',
  emailLabel: false,
  order: ['inviteAccepted']
};

const NotificationSettingsContent = props => {
  const {
    notificationSettings: {
      currentUser: { orgStaff }
    },
    intervalValues
  } = props;

  return (
    <NotificationSettings
      {...orgStaff}
      intervalValues={intervalValues}
      settingsOptions={SETTINGS}
      firstColumnSections={[DELIVERABLES_SECTION]}
      secondColumnSections={[SOLICITATIONS_SECTION, JOB_SECTION, CONTRACTOR_SECTION]}
    />
  );
};

export default createFragmentContainer(NotificationSettingsContent, {
  notificationSettings: graphql`
    fragment NotificationSettingsContent_notificationSettings on Query {
      currentUser {
        orgStaff {
          notificationConfig
          receiveSummaryEmails
          notificationSummaryInterval
        }
      }
    }
  `,
  intervalValues: graphql`
    fragment NotificationSettingsContent_intervalValues on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

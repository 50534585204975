import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Typography } from '@material-ui/core';
import { Field } from 'formik';

import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import { DELIVERABLE_STATES } from '../../../../../shared/constants';

const DetailsDescription = props => {
  const {
    isEditing,
    deliverable: { description, state }
  } = props;

  return (
    <DetailsSection title="Description">
      {isEditing && (state === DELIVERABLE_STATES.draft || state === DELIVERABLE_STATES.ready) ? (
        <Field
          name="description"
          label="Description"
          placeholder="Deliverable description"
          component={FormikTextField}
          multiline
          fullWidth
          visibleToFreelancer
        />
      ) : (
        <Typography variant="body1">
          {description && description.length ? description : 'No description added.'}
        </Typography>
      )}
    </DetailsSection>
  );
};

export default createFragmentContainer(DetailsDescription, {
  deliverable: graphql`
    fragment DetailsDescription_deliverable on DeliverableNode {
      description
      state
    }
  `
});

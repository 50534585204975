import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation ResendPaymentRequestMutation($input: ResendPaymentRequestInput!) {
    resendPaymentRequest(input: $input) {
      emailSent
    }
  }
`;

export default (id, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.resendPaymentRequest);
    },
    onError: err => console.error(err)
  });
};

/**
 * @flow
 * @relayHash 867e59f0658f494b3b7408b57f5c4fc9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentRequestStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
export type CreatePaymentRequestInput = {|
  jobId: string,
  paymentAmount: any,
  message?: ?string,
  attachContract?: ?boolean,
  clientMutationId?: ?string,
|};
export type CreatePaymentRequestMutationVariables = {|
  input: CreatePaymentRequestInput
|};
export type CreatePaymentRequestMutationResponse = {|
  +createPaymentRequest: ?{|
    +emailSent: ?boolean,
    +job: ?{|
      +id: string,
      +balance: ?any,
      +totalAmountOfPaymentRequests: ?any,
      +paymentRequests: ?{|
        +totalCount: ?number,
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +created: any,
            +requestId: string,
            +paymentAmount: number,
            +status: PaymentRequestStatus,
          |}
        |}>,
      |},
    |},
  |}
|};
export type CreatePaymentRequestMutation = {|
  variables: CreatePaymentRequestMutationVariables,
  response: CreatePaymentRequestMutationResponse,
|};
*/


/*
mutation CreatePaymentRequestMutation(
  $input: CreatePaymentRequestInput!
) {
  createPaymentRequest(input: $input) {
    emailSent
    job {
      id
      balance
      totalAmountOfPaymentRequests
      paymentRequests(orderBy: "-created") {
        totalCount
        edges {
          node {
            id
            created
            requestId
            paymentAmount
            status
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePaymentRequestInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createPaymentRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePaymentRequestPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailSent",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": null,
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "balance",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalAmountOfPaymentRequests",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "paymentRequests",
            "storageKey": "paymentRequests(orderBy:\"-created\")",
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-created"
              }
            ],
            "concreteType": "PaymentRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "PaymentRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PaymentRequestNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "created",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "requestId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "paymentAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreatePaymentRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreatePaymentRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreatePaymentRequestMutation",
    "id": null,
    "text": "mutation CreatePaymentRequestMutation(\n  $input: CreatePaymentRequestInput!\n) {\n  createPaymentRequest(input: $input) {\n    emailSent\n    job {\n      id\n      balance\n      totalAmountOfPaymentRequests\n      paymentRequests(orderBy: \"-created\") {\n        totalCount\n        edges {\n          node {\n            id\n            created\n            requestId\n            paymentAmount\n            status\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ebb72ee1cfa95b9d298bf8f774bd1f1';
module.exports = node;

import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import DeletePortfolioAssetMutation from '../../mutations/DeletePortfolioAssetMutation';
import CreatePortfolioAssetMutation from '../../mutations/CreatePortfolioAssetMutation';
import UpdatePortfolioAssetMutation from '../../mutations/UpdatePortfolioAssetMutation';

import WhiteBox from '../../../shared/components/common/WhiteBox';
import AssetList from '../../../shared/components/assets/AssetList';
import { performFileUpload } from '../../../shared/services/utilities';
import { ACCEPTED_FILE_SIZES } from '../../../shared/constants';

const FreelancerPortfolio = props => {
  const {
    portfolio: {
      portfolio: {
        pageInfo: { hasNextPage, endCursor },
        totalCount,
        edgeCount,
        edges
      }
    },
    relay: { refetch }
  } = props;

  let submittedFormValues = undefined;
  let submittedFormCallback = undefined;

  const noPresignedUploadHandler = (file, setSubmitting) => {
    const { fileName: formFileName, description } = submittedFormValues;
    CreatePortfolioAssetMutation(
      { fileName: formFileName || file.name, description },
      file,
      submittedFormCallback,
      () => {
        setSubmitting(false);
      }
    );
  };

  const confirmPresignedUploadSuccessfulCallback = (stagedPath, filename, setSubmitting) => {
    const { fileName: formFileName, description } = submittedFormValues;
    CreatePortfolioAssetMutation(
      { fileName: formFileName || filename, description, stagedPath },
      null,
      submittedFormCallback,
      () => {
        setSubmitting(false);
      }
    );
  };

  const handleCreateItem = (values, { setSubmitting }, cb) => {
    const { file } = values;

    let modelName = 'asset';
    let fieldName = 'file';

    submittedFormValues = values;
    submittedFormCallback = cb;

    performFileUpload(
      modelName,
      fieldName,
      null,
      file,
      file => {
        noPresignedUploadHandler(file, setSubmitting);
      },
      (stagedPath, filename) => {
        confirmPresignedUploadSuccessfulCallback(stagedPath, filename, setSubmitting);
      }
    );
  };

  const handleDeleteItem = (id, cb) =>
    DeletePortfolioAssetMutation(id, () => {
      cb();
      refetch(prevVars => ({ ...prevVars, after: '' }));
    });

  const handleEditItem = (values, cb) => {
    const { id, fileName, description } = values;
    UpdatePortfolioAssetMutation({ id, fileName, description }, cb);
  };

  return (
    <WhiteBox>
      <AssetList
        edges={edges}
        refetch={refetch}
        createItemHandler={handleCreateItem}
        deleteItemHandler={handleDeleteItem}
        shouldShowUploadButton={true}
        editItemHandler={handleEditItem}
        maxFileSize={ACCEPTED_FILE_SIZES.portfolio}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
    </WhiteBox>
  );
};

export default createRefetchContainer(
  FreelancerPortfolio,
  {
    portfolio: graphql`
      fragment FreelancerPortfolio_portfolio on Query
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        portfolio(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
          edges {
            node {
              id
              fileName
              fileUrl
              description
              reviewInterface
            }
          }
        }
      }
    `
  },
  graphql`
    query FreelancerPortfolioRefetchQuery($first: Int, $after: String) {
      ...FreelancerPortfolio_portfolio @arguments(first: $first, after: $after)
    }
  `
);

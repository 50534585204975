import React, { Fragment } from 'react';
import { Grid, Box } from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Field } from 'formik';

import { SOLICITATION_STATES } from '../../../../../shared/constants';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import Detail from '../../../../../shared/components/UI/Detail';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import AvatarWithName from '../../../../../shared/components/UI/AvatarWithName';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import FormikDateField from '../../../../../shared/components/form/FormikDateField';
import FormikSearchableDropdown from '../../../../../shared/components/form/FormikSearchableDropdown';
import DetailsRowAmount from '../../../../../shared/components/UI/DetailsRowAmount';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';

const SolicitationDetailsMainContent = props => {
  const classes = useDetailsStyles();
  const {
    solicitation: {
      subject,
      message,
      createdBy,
      dateSent,
      replyByDate,
      numberOfDeliverables,
      totalDeliverableAmount,
      staffCoordinator,
      state
    },
    isEditing,
    setEditing,
    canReassignSolicitation,
    canEditSolicitation
  } = props;

  const toggleEditHandler = () => {
    setEditing(!isEditing);
  };

  return (
    <Fragment>
      <DetailsSection
        noBorder
        title=" "
        renderRight={() =>
          canEditSolicitation() && (
            <EditToggle
              isEditing={isEditing}
              editProps={{
                id: 'editSolicitation',
                onClick: toggleEditHandler
              }}
              saveProps={{
                id: 'saveSolicitation'
              }}
              cancelProps={{
                id: 'cancelSolicitation',
                onClick: toggleEditHandler
              }}
            />
          )
        }
      >
        <Box mb={2}>
          {isEditing ? (
            <Field
              required
              name="subject"
              label="JobOpp Subject"
              placeholder="Enter a subject..."
              component={FormikTextField}
              fullWidth
            />
          ) : (
            <DetailsRow title="Subject" content={subject} titleWidth={132} noMarginTop />
          )}
        </Box>

        {isEditing && state !== SOLICITATION_STATES.active ? (
          <Field
            name="message"
            label="JobOpp Message"
            placeholder="Enter JobOpp message..."
            component={FormikTextField}
            multiline
            fullWidth
          />
        ) : (
          <Detail name="Message" value={message} flexDirection="column" alignItems="flex-start" />
        )}
      </DetailsSection>

      <DetailsSection title="JobOpp Information">
        <Grid container>
          <Grid item xs={6}>
            <DetailsRow
              title="Created By"
              content={
                <AvatarWithName
                  name={createdBy.fullName}
                  avatarProps={{
                    src: createdBy.representativeImageUrl
                  }}
                />
              }
            />
            <DetailsRow
              title="Coordinator"
              content={
                isEditing && canReassignSolicitation() ? (
                  <Field
                    name="assignedStaff"
                    component={FormikSearchableDropdown}
                    placeholder="Staff coordinator"
                    noLabel
                    className={classes.formFieldNoMargin}
                  />
                ) : (
                  <AvatarWithName
                    name={staffCoordinator.fullName}
                    avatarProps={{
                      src: staffCoordinator.representativeImageUrl
                    }}
                  />
                )
              }
            />
            <DetailsRowDate title="Date Sent" date={dateSent} />
            {isEditing && state !== SOLICITATION_STATES.active ? (
              <DetailsRow
                title="Reply by Date"
                content={
                  <Field
                    name="replyByDate"
                    component={FormikDateField}
                    className={classes.formFieldNoMargin}
                  />
                }
              />
            ) : (
              <DetailsRowDate date={replyByDate} title="Reply by Date" />
            )}
          </Grid>
          <Grid item xs={5}>
            <DetailsRow title="Deliverables" content={numberOfDeliverables} />
            <DetailsRowAmount title="Total Amount" amount={totalDeliverableAmount} />
          </Grid>
        </Grid>
      </DetailsSection>
    </Fragment>
  );
};

export default createFragmentContainer(SolicitationDetailsMainContent, {
  solicitation: graphql`
    fragment SolicitationDetailsMainContent_solicitation on SolicitationNode {
      createdBy {
        fullName
        representativeImageUrl
      }
      staffCoordinator {
        fullName
        representativeImageUrl
      }
      subject
      dateSent
      replyByDate
      numberOfDeliverables
      totalDeliverableAmount
      message
      state
    }
  `
});

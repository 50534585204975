/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CreateInvoiceForm_paymentSettings$ref: FragmentReference;
declare export opaque type CreateInvoiceForm_paymentSettings$fragmentType: CreateInvoiceForm_paymentSettings$ref;
export type CreateInvoiceForm_paymentSettings = {|
  +preferredCurrency: string,
  +paymentTerm: string,
  +paymentInfo: ?string,
  +includeVat: boolean,
  +vatPercentage: ?number,
  +taxPercentage: ?number,
  +taxIdentifier: string,
  +$refType: CreateInvoiceForm_paymentSettings$ref,
|};
export type CreateInvoiceForm_paymentSettings$data = CreateInvoiceForm_paymentSettings;
export type CreateInvoiceForm_paymentSettings$key = {
  +$data?: CreateInvoiceForm_paymentSettings$data,
  +$fragmentRefs: CreateInvoiceForm_paymentSettings$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CreateInvoiceForm_paymentSettings",
  "type": "FreelancerPaymentConfigurationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "preferredCurrency",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentTerm",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentInfo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "includeVat",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "vatPercentage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "taxPercentage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "taxIdentifier",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd8397b631ba85e33aa1268728ce1eae3';
module.exports = node;

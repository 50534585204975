/**
 * @flow
 * @relayHash 1ca02f1714a88a8eb103254fefd33612
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type CloseDeliverableInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type CloseDeliverableMutationVariables = {|
  input: CloseDeliverableInput
|};
export type CloseDeliverableMutationResponse = {|
  +closeDeliverable: ?{|
    +updatedDeliverable: ?{|
      +state: DeliverableState
    |}
  |}
|};
export type CloseDeliverableMutation = {|
  variables: CloseDeliverableMutationVariables,
  response: CloseDeliverableMutationResponse,
|};
*/


/*
mutation CloseDeliverableMutation(
  $input: CloseDeliverableInput!
) {
  closeDeliverable(input: $input) {
    updatedDeliverable {
      state
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CloseDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CloseDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "closeDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CloseDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CloseDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "closeDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CloseDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CloseDeliverableMutation",
    "id": null,
    "text": "mutation CloseDeliverableMutation(\n  $input: CloseDeliverableInput!\n) {\n  closeDeliverable(input: $input) {\n    updatedDeliverable {\n      state\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9e09d134d5c06a85f797637d129472d2';
module.exports = node;

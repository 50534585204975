import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const createContractorNoteMutation = graphql`
  mutation CreateContractorNoteMutation($input: CreateContractorNoteInput!) {
    createContractorNote(input: $input) {
      contractor {
        contractorNotes {
          edges {
            node {
              id
              created
              staff {
                user {
                  id
                  fullName
                }
                representativeImageUrl
              }
              content
              repliedTo {
                content
                created
                user {
                  fullName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: createContractorNoteMutation,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.createContractorNote, errors);
    },
    onError: err => console.log(err)
  });
};

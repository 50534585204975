import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const deliverableQuery = graphql`
  query SearchableDeliverableDropdownQuery(
    $title: String
    $first: Int
    $state: String
    $excludeAllocated: Boolean
    $onlyInSolicitation: String
    $category: ID
    $excludeInSolicitation: String
    $currencyCode: String
    $onlyDraftOrReady: Boolean
    $assignedToMeOnly: Boolean
  ) {
    allDeliverables(
      title: $title
      orderBy: "title"
      state: $state
      first: $first
      excludeAllocated: $excludeAllocated
      onlyInSolicitation: $onlyInSolicitation
      assignedToMeOnly: $assignedToMeOnly
      category: $category
      excludeInSolicitation: $excludeInSolicitation
      currencyCode: $currencyCode
      onlyDraftOrReady: $onlyDraftOrReady
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          title
          release {
            product {
              title
            }
          }
          category {
            id
            name
          }
          state
          amount
          dueDate
          currencyCode
          amountInHomeCurrency
          assignedStaff {
            id
            fullName
            representativeImageUrl
          }
        }
      }
    }
  }
`;

const SearchableDeliverableDropdown = props => {
  const { onChange, fetchVariables = {}, ...otherProps } = props;

  const getFetchVariables = inputValue => ({
    title: inputValue,
    first: 10 + (otherProps.itemsInList ? otherProps.itemsInList.length : 0),
    ...fetchVariables
  });

  const formatOptions = deliverables =>
    deliverables || deliverables.allDeliverables
      ? deliverables.allDeliverables.edges.map(deliverable => ({
          value: deliverable.node.id,
          label: deliverable.node.title,
          ...deliverable.node
        }))
      : [];

  return (
    <SearchableDropdown
      resultKey="allDeliverables"
      query={deliverableQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      {...otherProps}
    />
  );
};

export default SearchableDeliverableDropdown;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SolicitationContractorsList_solicitation$ref: FragmentReference;
declare export opaque type SolicitationContractorsList_solicitation$fragmentType: SolicitationContractorsList_solicitation$ref;
export type SolicitationContractorsList_solicitation = {|
  +id: string,
  +state: SolicitationState,
  +solicitationId: ?string,
  +numberOfDeliverables: ?number,
  +numberOfAllocatedDeliverables: ?number,
  +numberOfContractorsThatContacted: ?number,
  +numberOfContractedDeliverables: ?number,
  +contractors: ?{|
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
    +totalCount: ?number,
    +edgeCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
        +representativeImageUrl: ?string,
        +numberOfAllocatedDeliverablesInSolicitation: ?number,
        +numberOfCommentsInSolicitation: ?number,
        +numberOfContractedDeliverablesInSolicitation: ?number,
        +freelancer: ?{|
          +user: {|
            +userId: ?string
          |}
        |},
      |}
    |}>,
  |},
  +$refType: SolicitationContractorsList_solicitation$ref,
|};
export type SolicitationContractorsList_solicitation$data = SolicitationContractorsList_solicitation;
export type SolicitationContractorsList_solicitation$key = {
  +$data?: SolicitationContractorsList_solicitation$data,
  +$fragmentRefs: SolicitationContractorsList_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SolicitationContractorsList_solicitation",
  "type": "SolicitationNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "contractorName",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "includeDoNotHire",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeInactive",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "inviteStatus",
      "type": "String"
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "solicitationId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfAllocatedDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfContractorsThatContacted",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfContractedDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contractors",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "contractorName",
          "variableName": "contractorName"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "includeDoNotHire",
          "variableName": "includeDoNotHire"
        },
        {
          "kind": "Variable",
          "name": "includeInactive",
          "variableName": "includeInactive"
        },
        {
          "kind": "Variable",
          "name": "inviteStatus",
          "variableName": "inviteStatus"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "ContractorNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractorNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractorNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fullName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "representativeImageUrl",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "numberOfAllocatedDeliverablesInSolicitation",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "numberOfCommentsInSolicitation",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "numberOfContractedDeliverablesInSolicitation",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "freelancer",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "FreelancerNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "user",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "userId",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '733a82ec351c5db9e4e993c380009355';
module.exports = node;

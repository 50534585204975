/**
 * @flow
 * @relayHash eb8d9cff0e0e8abb86e451594a06c4bf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationQuickView_solicitation$ref = any;
export type SolicitationQuickViewRefetchQueryVariables = {|
  id: string
|};
export type SolicitationQuickViewRefetchQueryResponse = {|
  +$fragmentRefs: SolicitationQuickView_solicitation$ref
|};
export type SolicitationQuickViewRefetchQuery = {|
  variables: SolicitationQuickViewRefetchQueryVariables,
  response: SolicitationQuickViewRefetchQueryResponse,
|};
*/


/*
query SolicitationQuickViewRefetchQuery(
  $id: ID!
) {
  ...SolicitationQuickView_solicitation_1Bmzm5
}

fragment SolicitationQuickView_solicitation_1Bmzm5 on Query {
  solicitation(id: $id) {
    subject
    message
    ...SolicitationQuickViewContractorList_solicitation
    id
  }
}

fragment SolicitationQuickViewContractorList_solicitation on SolicitationNode {
  id
  state
  contractors(orderBy: "newest_comment") {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        fullName
        representativeImageUrl
        numberOfCommentsInSolicitation
        freelancer {
          user {
            userId
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationQuickViewRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "SolicitationQuickView_solicitation",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationQuickViewRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subject",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractors",
            "storageKey": "contractors(orderBy:\"newest_comment\")",
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "newest_comment"
              }
            ],
            "concreteType": "ContractorNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "representativeImageUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numberOfCommentsInSolicitation",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "freelancer",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FreelancerNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "userId",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationQuickViewRefetchQuery",
    "id": null,
    "text": "query SolicitationQuickViewRefetchQuery(\n  $id: ID!\n) {\n  ...SolicitationQuickView_solicitation_1Bmzm5\n}\n\nfragment SolicitationQuickView_solicitation_1Bmzm5 on Query {\n  solicitation(id: $id) {\n    subject\n    message\n    ...SolicitationQuickViewContractorList_solicitation\n    id\n  }\n}\n\nfragment SolicitationQuickViewContractorList_solicitation on SolicitationNode {\n  id\n  state\n  contractors(orderBy: \"newest_comment\") {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        fullName\n        representativeImageUrl\n        numberOfCommentsInSolicitation\n        freelancer {\n          user {\n            userId\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '003a92200d2b857499a8f2249c349d67';
module.exports = node;

/**
 * @flow
 * @relayHash da761962de074dffe484a8c2a6e68ab4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractorActionLogList_contractor$ref = any;
type ContractorToolsActions_contractor$ref = any;
export type ContractorToolsTabQueryVariables = {|
  id: string,
  first?: ?number,
  after?: ?string,
|};
export type ContractorToolsTabQueryResponse = {|
  +contractor: ?{|
    +$fragmentRefs: ContractorToolsActions_contractor$ref & ContractorActionLogList_contractor$ref
  |}
|};
export type ContractorToolsTabQuery = {|
  variables: ContractorToolsTabQueryVariables,
  response: ContractorToolsTabQueryResponse,
|};
*/


/*
query ContractorToolsTabQuery(
  $id: ID!
  $first: Int
  $after: String
) {
  contractor(id: $id) {
    ...ContractorToolsActions_contractor
    ...ContractorActionLogList_contractor
    id
  }
}

fragment ContractorToolsActions_contractor on ContractorNode {
  id
  isActive
  doNotHire
}

fragment ContractorActionLogList_contractor on ContractorNode {
  id
  history(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        timestamp
        actor {
          orgStaff {
            fullName
            representativeImageUrl
            id
          }
          id
        }
        action
        changes
        additionalData
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractorToolsTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractorToolsActions_contractor",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "ContractorActionLogList_contractor",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractorToolsTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isActive",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "doNotHire",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "history",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              }
            ],
            "concreteType": "LogEntryNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LogEntryNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LogEntryNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "timestamp",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "actor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "orgStaff",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fullName",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "representativeImageUrl",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "action",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "changes",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "additionalData",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractorToolsTabQuery",
    "id": null,
    "text": "query ContractorToolsTabQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n) {\n  contractor(id: $id) {\n    ...ContractorToolsActions_contractor\n    ...ContractorActionLogList_contractor\n    id\n  }\n}\n\nfragment ContractorToolsActions_contractor on ContractorNode {\n  id\n  isActive\n  doNotHire\n}\n\nfragment ContractorActionLogList_contractor on ContractorNode {\n  id\n  history(first: $first, after: $after) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        timestamp\n        actor {\n          orgStaff {\n            fullName\n            representativeImageUrl\n            id\n          }\n          id\n        }\n        action\n        changes\n        additionalData\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '609067ada8a69c7256dd2cdc7fa4a70d';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import StyledButtonGroup from '../UI/buttonGroup/StyledButtonGroup';
import GlobalButton from '../UI/GlobalButton';
import GroupButtonSquare from '../UI/buttonGroup/GroupButtonSquare';
import ConfirmationDialog from '../common/ConfirmationDialog';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block'
  },
  button: {
    margin: '2px',
    backgroundColor: '#D5D9E2',
    textTransform: 'none',
    border: 'none',
    '& span': {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  popper: {
    zIndex: '2'
  }
}));

const ActionButton = ({ options, handleMenuItemClick, disabledItems, withMarginBottom }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  function handleItemClick(action) {
    handleMenuItemClick(action);
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <StyledButtonGroup
        ref={anchorRef}
        withMarginRight
        {...(withMarginBottom ? { withMarginBottom: true } : null)}
      >
        <GlobalButton id="actionButton" variant="secondary" noMargin handleClick={handleToggle}>
          Action
        </GlobalButton>
        <GroupButtonSquare
          id="actionButtonArrow"
          onClick={handleToggle}
          icon="arrowDropdown"
          variant="secondary"
          size={34}
          aria-haspopup="true"
          aria-owns={open ? 'menu-list-grow' : undefined}
        />
      </StyledButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center bottom'
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map(option => {
                    if (option.confirmText) {
                      return (
                        <ConfirmationDialog content={option.confirmText} key={option.name}>
                          {confirm => (
                            <MenuItem
                              id={`option-${option.name}`}
                              disabled={disabledItems ? disabledItems.includes(option.name) : false}
                              selected={false}
                              onClick={confirm(() => handleItemClick(option.name))}
                            >
                              {option.label}
                            </MenuItem>
                          )}
                        </ConfirmationDialog>
                      );
                    } else {
                      return (
                        <MenuItem
                          id={`option-${option.name}`}
                          key={option.name}
                          disabled={disabledItems ? disabledItems.includes(option.name) : false}
                          selected={false}
                          onClick={() => handleItemClick(option.name)}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    }
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

ActionButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      modalTitle: PropTypes.string,
      // if passed, we will display ConfirmatioDialog before performing handleMenuItemClick action
      confirmText: PropTypes.string
    })
  ).isRequired,
  handleMenuItemClick: PropTypes.func.isRequired,
  disabledItems: PropTypes.arrayOf(PropTypes.string),
  withMarginBottom: PropTypes.bool
};

export default ActionButton;

import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Formik, Form } from 'formik';

import DetailsSection from '../../../../shared/components/common/DetailsSection';

import StyledSwitch from '../../../../shared/components/UI/StyledSwitch';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import { useCustomizationStyles } from '../../../../shared/styles/admin/useCustomizationStyles';

import UpdateOrganizationConfigurationMutation from './mutations/UpdateOrganizationConfigurationMutation';

const PaymentRequests = props => {
  const {
    userContext: {
      setUserInfo,
      orgStaff: { organization }
    }
  } = props;

  const currentValue = JSON.parse(organization.configuration).enable_payment_request_csv;
  const enablePaymentRequestCsv = currentValue == null ? false : currentValue;
  const [enable, setEnable] = useState(enablePaymentRequestCsv);

  const classes = useCustomizationStyles();

  const initialValues = { enable: enablePaymentRequestCsv };

  const handleToggleEnable = (e, values) => {
    setEnable(!enable);
    console.log('New value is going to be set to: ' + !enable);
    UpdateOrganizationConfigurationMutation({ enablePaymentRequestCsv: !enable }, response => {
      console.log(response);
      if (response && response.isUpdated && response.organization) {
        setUserInfo({
          ...props.userContext,
          orgStaff: { ...props.userContext.orgStaff, organization: response.organization }
        });
      }
    });
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({ values, setFieldValue, setValues }) => (
        <Form>
          <div
            className={clsx(
              classes.container,
              classes.justifySpaceBetween,
              classes.alignItemsCenter,
              classes.topSectionHeight
            )}
          >
            <Typography variant="h3">Send CSV with Payment Requests</Typography>
          </div>
          <DetailsSection>
            <div>
              <div className={clsx(classes.container, classes.rowHeight, classes.marginBottom)}>
                <div className={clsx(classes.container, classes.alignItemsCenter)}>
                  <StyledSwitch checked={enable} onChange={e => handleToggleEnable(e, values)} />
                </div>

                <div className={classes.textWidth280}>
                  <span className={clsx(classes.bold, classes.textMarginRight)}>
                    Send weighted payment allocation CSV file with Payment Request Emails
                  </span>
                </div>
              </div>
            </div>
          </DetailsSection>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(PaymentRequests);

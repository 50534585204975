/**
 * @flow
 * @relayHash 3fe64be37fd5cacfca1b07a9a813c90d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignUpOrganizationUpdateEmailInput = {|
  email: string,
  orgToken: string,
  uidb64: string,
  clientMutationId?: ?string,
|};
export type SignUpOrganizationUpdateEmailMutationVariables = {|
  input: SignUpOrganizationUpdateEmailInput
|};
export type SignUpOrganizationUpdateEmailMutationResponse = {|
  +signUpOrganizationUpdateEmail: ?{|
    +userEmail: ?string,
    +emailSent: ?boolean,
    +orgToken: ?string,
    +uidb64: ?string,
    +clientMutationId: ?string,
  |}
|};
export type SignUpOrganizationUpdateEmailMutation = {|
  variables: SignUpOrganizationUpdateEmailMutationVariables,
  response: SignUpOrganizationUpdateEmailMutationResponse,
|};
*/


/*
mutation SignUpOrganizationUpdateEmailMutation(
  $input: SignUpOrganizationUpdateEmailInput!
) {
  signUpOrganizationUpdateEmail(input: $input) {
    userEmail
    emailSent
    orgToken
    uidb64
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SignUpOrganizationUpdateEmailInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "signUpOrganizationUpdateEmail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignUpOrganizationUpdateEmailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "userEmail",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailSent",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orgToken",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uidb64",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SignUpOrganizationUpdateEmailMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SignUpOrganizationUpdateEmailMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SignUpOrganizationUpdateEmailMutation",
    "id": null,
    "text": "mutation SignUpOrganizationUpdateEmailMutation(\n  $input: SignUpOrganizationUpdateEmailInput!\n) {\n  signUpOrganizationUpdateEmail(input: $input) {\n    userEmail\n    emailSent\n    orgToken\n    uidb64\n    clientMutationId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e505a8e3a2d75946e3165ddd3a9f0b3';
module.exports = node;

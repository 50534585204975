import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { TableCell } from '@material-ui/core';

import { useTableStyles } from '../../styles/common/tableStyles';

const StyledTableCell = props => {
  const {
    truncate,
    empty,
    avatar,
    noWrap,
    clickable,
    state,
    status,
    children,
    hidden,
    icon,
    className,
    colorProp,
    alignTop,
    verticalCenter,
    minWidth,
    ...otherProps
  } = props;

  const classes = useTableStyles({ colorProp });
  const classesCheck = {
    [classes.tableCellTruncate]: truncate,
    [classes.avatarCell]: avatar,
    [classes.emptyCell]: empty,
    [classes.noWrapCell]: noWrap,
    [classes.tableCellClickable]: clickable,
    [classes.stateTableCell]: state || status,
    [classes.hidden]: hidden,
    [classes.iconCell]: icon,
    [classes.warningCell]: colorProp,
    [classes.verticalTop]: alignTop,
    [classes.verticalCenter]: verticalCenter,
    [classes.minWidthCell]: minWidth
  };

  return (
    <TableCell
      className={clsx(className ? className : classes.tableCellGrey, classesCheck)}
      {...otherProps}
    >
      {children}
    </TableCell>
  );
};

StyledTableCell.propTypes = {
  truncate: PropTypes.bool,
  empty: PropTypes.bool,
  avatar: PropTypes.bool,
  noWrap: PropTypes.bool,
  clickable: PropTypes.bool,
  className: PropTypes.string,
  state: PropTypes.bool,
  status: PropTypes.bool,
  icon: PropTypes.bool,
  minWidth: PropTypes.bool
};

export default StyledTableCell;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const closeDeliverableMutation = graphql`
  mutation CloseDeliverableMutation($input: CloseDeliverableInput!) {
    closeDeliverable(input: $input) {
      updatedDeliverable {
        state
      }
    }
  }
`;

export default (id, callback) => {
  const config = {
    mutation: closeDeliverableMutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.closeDeliverable);
    }
  };

  commitMutation(getEnvironment(), config);
};

import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import differenceBy from 'lodash/differenceBy';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import FollowButton from '../../../../shared/components/UI/FollowButton';
import { computePermissionRole } from '../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS } from '../../../../shared/constants';
import ToggleAssignFollowersForDeliverablesMutation from '../mutations/ToggleAssignFollowersForDeliverablesMutation';
import SearchableStaffDropdown from '../../shared/dropdowns/SearchableStaffDropdown';

export const DeliverableDetailsToggleFollowButtonDataContext = createContext();

export const DeliverableDetailsToggleFollowButtonDataContextProvider = props => {
  const [dataKey, setDataKey] = useState(0);

  const refetchFollowersData = () => setDataKey(val => val + 1);

  return (
    <DeliverableDetailsToggleFollowButtonDataContext.Provider
      value={{
        dataKey,
        refetchFollowersData
      }}
      {...props}
    />
  );
};

const DeliverableDetailsToggleFollowButton = ({ deliverable, relay, userContext }) => {
  const [isFetchingFollowers, setIsFetchingFollowers] = useState(false);
  const [isFollowersListVisible, setIsFollowersListVisible] = useState(false);
  const selectFocusRef = useRef({ focused: false });
  const { dataKey } = useContext(DeliverableDetailsToggleFollowButtonDataContext);
  const canAddFollowers = computePermissionRole(
    ALLOWED_ACTIONS.ADD_FOLLOWERS,
    userContext.orgStaff.allowedActions
  );

  useEffect(() => {
    if (dataKey > 0) relay.refetch({ withFollowers: isFollowersListVisible }, null);
  }, [dataKey]);

  const handleListToggle = isVisible => {
    setIsFollowersListVisible(isVisible);
    if (isVisible) {
      setIsFetchingFollowers(true);
      relay.refetch({ withFollowers: true }, null, () => setIsFetchingFollowers(false));
    }
  };

  const handleUserFollowToggle = (user, follow) => {
    ToggleAssignFollowersForDeliverablesMutation({
      follow,
      userIds: [user.id],
      deliverableIds: [deliverable.id]
    }).then(() => {
      relay.refetch({ withFollowers: isFollowersListVisible });
    });
  };

  // NOTE:
  // This is needed because SearchableStaffDropdown uses portal which can't be tracked
  // by FollowButton handleOutsideClick
  const handleSelectFocus = () => {
    selectFocusRef.current.focused = true;
  };

  const handleSelectBlur = () => {
    selectFocusRef.current.focused = false;
  };

  const followers = deliverable.followers.edges
    ? deliverable.followers.edges.map(({ node }) => ({
        id: node.id,
        name: node.fullName
      }))
    : null;

  return (
    <FollowButton
      isFollowing={deliverable.isCurrentUserFollowing}
      followers={isFetchingFollowers ? null : followers}
      totalFollowers={deliverable.followers.totalCount}
      onFollowToggle={isFollowing =>
        handleUserFollowToggle(
          {
            id: userContext.orgStaff.id
          },
          isFollowing
        )
      }
      onShowFollowersToggle={handleListToggle}
      onFollowerRemove={
        canAddFollowers ? follower => handleUserFollowToggle(follower, false) : undefined
      }
      shouldHideOnOusideClick={() => !selectFocusRef.current.focused}
      renderAddFollowerForm={
        canAddFollowers
          ? () => (
              <SearchableStaffDropdown
                name="selectedStaff"
                label="Staff Name"
                placeholder="Add Follower"
                value={
                  followers ? followers.map(({ id, name }) => ({ value: id, label: name })) : null
                }
                autoFocus
                onFocus={handleSelectFocus}
                onBlur={handleSelectBlur}
                onChange={value => {
                  if (value.length > 0) {
                    const [newFollower] = differenceBy(
                      value.map(({ value, label }) => ({ id: value, name: label })),
                      followers,
                      follower => follower.id
                    );
                    handleUserFollowToggle(newFollower, true);
                  }
                }}
                isMulti
                controlShouldRenderValue={false}
                itemsInList={followers ? followers : undefined}
              />
            )
          : undefined
      }
    />
  );
};

export default createRefetchContainer(
  withUserContext(DeliverableDetailsToggleFollowButton),
  {
    deliverable: graphql`
      fragment DeliverableDetailsToggleFollowButton_deliverable on DeliverableNode
      @argumentDefinitions(withFollowers: { type: "Boolean" }) {
        id
        isCurrentUserFollowing
        followers {
          edges @include(if: $withFollowers) {
            node {
              id
              fullName
            }
          }
          totalCount
        }
      }
    `
  },
  graphql`
    query DeliverableDetailsToggleFollowButtonQuery($id: ID!, $withFollowers: Boolean!) {
      deliverable(id: $id) {
        ...DeliverableDetailsToggleFollowButton_deliverable
          @arguments(withFollowers: $withFollowers)
      }
    }
  `
);

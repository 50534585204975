import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';
import PropTypes from 'prop-types';

const UpdateStaffProfileMutation = (variables, file, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateStaffProfileMutation($input: UpdateStaffProfileInput!) {
        updateStaffProfile(input: $input) {
          updatedStaffProfile {
            firstName
            lastName
            fullName
            email
            representativeImageUrl
            orgStaff {
              id
              roleLabel
              phone
              title
              notificationConfig
              showFreelancerFieldsPopup
              organization {
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    uploadables: {
      representative_image: file
    },
    onCompleted: (response, errors) => {
      callback(response.updateStaffProfile, errors);
    },
    onError: err => console.error(err)
  });
};

UpdateStaffProfileMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      title: PropTypes.string,
      phone: PropTypes.string,
      reprImageNull: PropTypes.bool,
      showFreelancerFieldsPopup: PropTypes.bool
    })
  ),
  file: PropTypes.any.isRequired
};

export default UpdateStaffProfileMutation;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AutoTable from '../../table/AutoTable';
import GlobalButton from '../../UI/GlobalButton';
import Dialog from '../../common/Dialog';
import FinalFilesUploadForm from '../FinalFilesUploadForm';
import useDialog from '../../../hooks/useDialog';
import { CELL_TYPES, FILE_STORAGE_CLASSES } from '../../../constants';
import TableCellFileDownload from '../../../components/table/TableCellFileDownload';
import DeleteDeliverableFinalFileMutation from '../../../mutations/deliverables/DeleteDeliverableFinalFileMutation';
import { FileStatusCell } from './styled';
import { formatBytes } from '../../../utils/formatters';

const FinalFilesList = props => {
  const {
    canUserUpload,
    canUserDelete,
    canUserDownload,
    deliverable: {
      id,
      finalFiles: { edges },
      finalFileMaxSize
    }
  } = props;

  const [isDialogOpen, toggleDialogOpen] = useDialog();

  const onFileDeleteHandler = id => DeleteDeliverableFinalFileMutation(id);

  const flattenedEdges = edges.map(({ node }) => ({
    node: {
      id: node.id,
      created: node.created,
      fileName: node.fileName,
      fileSize: formatBytes(node.fileSize),
      description: node.description,
      fileDownload: {
        fileUrl: node.fileUrl,
        fileName: node.fileName
      },
      delete: node.fileName,
      storageClass: node.storageClass
    }
  }));

  return (
    <Fragment>
      {canUserUpload && (
        <GlobalButton
          id="uploadFileFinalFilesList"
          handleClick={toggleDialogOpen}
          variant="secondary"
          noMargin
        >
          Upload File
        </GlobalButton>
      )}
      <AutoTable
        rowTemplate={[
          { name: 'fileName', label: 'Filename' },
          { name: 'description', label: 'Comments' },
          { name: 'created', label: 'Date Uploaded', type: CELL_TYPES.date },
          { name: 'fileSize', label: 'File Size' },
          {
            name: 'fileDownload',
            label: '',
            width: 30,
            renderCell: ({ index, rowIndex }) => {
              const { node } = flattenedEdges[rowIndex];

              return node.storageClass === FILE_STORAGE_CLASSES.STANDARD ? (
                <TableCellFileDownload
                  key={index}
                  fileProps={node.fileDownload}
                  disabled={!canUserDownload}
                />
              ) : (
                <FileStatusCell key={index} node={node} />
              );
            }
          },
          {
            name: 'delete',
            label: '',
            type: CELL_TYPES.trash,
            onClick: onFileDeleteHandler,
            disabled: !canUserDelete,
            withConfirm: true,
            tooltip: 'Delete',
            width: 30
          }
        ]}
        edges={flattenedEdges}
      />
      <Dialog
        title="Upload File"
        isDialogOpen={isDialogOpen}
        closeDialog={toggleDialogOpen}
        minWidth={480}
      >
        <FinalFilesUploadForm
          deliverableId={id}
          closeDialog={toggleDialogOpen}
          finalFileMaxSize={finalFileMaxSize}
        />
      </Dialog>
    </Fragment>
  );
};

FinalFilesList.propTypes = {
  canUserUpload: PropTypes.bool.isRequired,
  canUserDelete: PropTypes.bool.isRequired,
  canUserDownload: PropTypes.bool.isRequired,
  deliverable: PropTypes.shape({
    id: PropTypes.string.isRequired,
    finalFileMaxSize: PropTypes.number.isRequired,
    finalFiles: PropTypes.shape({
      edges: PropTypes.array.isRequired
    })
  }).isRequired
};

FinalFilesList.defaultProps = {
  canUserUpload: true,
  canUserDelete: true,
  canUserDownload: true
};

export default FinalFilesList;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: props => (props.withMarginRight ? theme.spacing(3) : ''),
    marginBottom: props => (props.withMarginBottom ? '1.5rem' : ''),
    display: 'inline-block'
  },

  contained: {
    boxShadow: 'none'
  },

  groupedContained: {
    '&:hover': {
      boxShadow: 'none'
    }
  }
}));

const StyledButtonGroup = forwardRef((props, ref) => {
  const { children, withMarginRight, withMarginBottom, ...otherProps } = props;
  const classes = useStyles({ withMarginRight, withMarginBottom });

  return (
    <ButtonGroup variant="contained" classes={{ ...classes }} ref={ref} {...otherProps}>
      {children}
    </ButtonGroup>
  );
});

StyledButtonGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  withMarginRight: PropTypes.bool,
  withMarginBottom: PropTypes.bool
};

export default StyledButtonGroup;

/**
 * @flow
 * @relayHash 26b0ebeb7a7293c512f436693e8d332e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchableContactDropdownQueryVariables = {|
  staffName?: ?string,
  first?: ?number,
  orderBy?: ?string,
  includeInactive?: ?boolean,
|};
export type SearchableContactDropdownQueryResponse = {|
  +allStaff: ?{|
    +edgeCount: ?number,
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
      |}
    |}>,
  |}
|};
export type SearchableContactDropdownQuery = {|
  variables: SearchableContactDropdownQueryVariables,
  response: SearchableContactDropdownQueryResponse,
|};
*/


/*
query SearchableContactDropdownQuery(
  $staffName: String
  $first: Int
  $orderBy: String
  $includeInactive: Boolean
) {
  allStaff(staffName: $staffName, orderBy: $orderBy, first: $first, includeInactive: $includeInactive) {
    edgeCount
    totalCount
    edges {
      node {
        id
        fullName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "staffName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "allStaff",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "includeInactive",
        "variableName": "includeInactive"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "staffName",
        "variableName": "staffName"
      }
    ],
    "concreteType": "StaffNodeConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "edgeCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "StaffNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SearchableContactDropdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchableContactDropdownQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SearchableContactDropdownQuery",
    "id": null,
    "text": "query SearchableContactDropdownQuery(\n  $staffName: String\n  $first: Int\n  $orderBy: String\n  $includeInactive: Boolean\n) {\n  allStaff(staffName: $staffName, orderBy: $orderBy, first: $first, includeInactive: $includeInactive) {\n    edgeCount\n    totalCount\n    edges {\n      node {\n        id\n        fullName\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab9626021483b77d85eccefad57fad61';
module.exports = node;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2';
import { Color } from 'three';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import Placeholder from './Placeholder';
import { useEffectWithStatus } from '../../../../hooks/useEffectWithStatus';

/**
 * This components loads and renders OBJ 3D files.
 *
 */
const ObjAsset = props => {
  const { src, centerCameraOn, theme, flatShading, handlers } = props;
  const [obj, setScene] = useState(null);

  useEffectWithStatus(status => {
    let loader = new OBJLoader2();
    loader.load(src, obj => {
      obj.traverse(function (child) {
        if (child.isMesh) {
          child.material.color = new Color('#555555');
          child.material.flatShading = flatShading;
        }
      });
      status.mounted && setScene(obj);
    });
  }, []);

  useUpdateEffect(() => {
    centerCameraOn(obj);
  }, [obj]);

  return obj ? <primitive object={obj} {...handlers} /> : <Placeholder theme={theme} />;
};

ObjAsset.propTypes = {
  src: PropTypes.string.isRequired,
  centerCameraOn: PropTypes.func.isRequired,
  flatShading: PropTypes.bool,
  handlers: PropTypes.shape({
    onPointerMove: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onClick: PropTypes.func
  })
};

export default ObjAsset;

import React from 'react';
import Dropzone from '../fields/Dropzone';

const FormikDropzone = props => {
  const { field, form, ...otherProps } = props;

  const onChangeHandler = file => {
    form.setFieldValue(field.name, file);
    form.setFieldTouched(field.name)
  };

  const error = form.touched[field.name] && form.errors[field.name]

  return <Dropzone file={field.value} onChange={onChangeHandler} error={error} {...otherProps} />;
};

export default FormikDropzone;

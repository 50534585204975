import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation UpdateSolicitationMutation($input: UpdateSolicitationInput!) {
    updateSolicitation(input: $input) {
      isUpdated
      solicitation {
        id
        subject
        message
        replyByDate
      }
    }
  }
`;

const UpdateSolicitationMutation = (input, callback) => {
  const config = {
    mutation,
    variables: { input },
    onCompleted: response => {
      callback(response.updateSolicitation);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default UpdateSolicitationMutation;

UpdateSolicitationMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string,
      replyByDate: PropTypes.string
    })
  )
};

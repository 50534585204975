/**
 * @flow
 * @relayHash e50a85f486c72f05ad661ca4faed0207
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PaymentDetailsContent_currencyCodes$ref = any;
type PaymentDetailsContent_details$ref = any;
export type PaymentDetailsTabQueryVariables = {||};
export type PaymentDetailsTabQueryResponse = {|
  +__type: ?{|
    +$fragmentRefs: PaymentDetailsContent_currencyCodes$ref
  |},
  +$fragmentRefs: PaymentDetailsContent_details$ref,
|};
export type PaymentDetailsTabQuery = {|
  variables: PaymentDetailsTabQueryVariables,
  response: PaymentDetailsTabQueryResponse,
|};
*/

/*
query PaymentDetailsTabQuery {
  ...PaymentDetailsContent_details
  __type(name: "DeliverableCurrencyCode") {
    ...PaymentDetailsContent_currencyCodes
  }
}

fragment PaymentDetailsContent_details on Query {
  paymentSettings {
    paymentTerm
    preferredCurrency
    paymentInfo
    includeVat
    vatPercentage
    taxPercentage
    taxIdentifier
    id
  }
}

fragment PaymentDetailsContent_currencyCodes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
    {
      kind: 'Literal',
      name: 'name',
      value: 'DeliverableCurrencyCode'
    }
  ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'PaymentDetailsTabQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: '__type',
          storageKey: '__type(name:"DeliverableCurrencyCode")',
          args: (v0 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'PaymentDetailsContent_currencyCodes',
              args: null
            }
          ]
        },
        {
          kind: 'FragmentSpread',
          name: 'PaymentDetailsContent_details',
          args: null
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'PaymentDetailsTabQuery',
      argumentDefinitions: [],
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'paymentSettings',
          storageKey: null,
          args: null,
          concreteType: 'FreelancerPaymentConfigurationNode',
          plural: false,
          selections: [
            {
              kind: 'ScalarField',
              alias: null,
              name: 'paymentTerm',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'preferredCurrency',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'paymentInfo',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'includeVat',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'vatPercentage',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'taxPercentage',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'taxIdentifier',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'id',
              args: null,
              storageKey: null
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: null,
          name: '__type',
          storageKey: '__type(name:"DeliverableCurrencyCode")',
          args: (v0 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'enumValues',
              storageKey: null,
              args: null,
              concreteType: '__EnumValue',
              plural: true,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'name',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'description',
                  args: null,
                  storageKey: null
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'PaymentDetailsTabQuery',
      id: null,
      text:
        'query PaymentDetailsTabQuery {\n  ...PaymentDetailsContent_details\n  __type(name: "DeliverableCurrencyCode") {\n    ...PaymentDetailsContent_currencyCodes\n  }\n}\n\nfragment PaymentDetailsContent_details on Query {\n  paymentSettings {\n    paymentTerm\n    preferredCurrency\n    paymentInfo\n    includeVat\n    vatPercentage\n    taxPercentage\n    taxIdentifier\n    id\n  }\n}\n\nfragment PaymentDetailsContent_currencyCodes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '1f7f93d3f1773d1317b25ddc130765cf';
module.exports = node;

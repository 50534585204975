import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import ProductList from './ProductList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';
import QueryRenderer from '../../../shared/relay/QueryRenderer';

const ProductListPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ProductListPageQuery(
          $first: Int
          $after: String
          $includeInactive: Boolean
          $orderBy: String
          $title: String
          $code: String
        ) {
          ...ProductList_products
        }
      `}
      variables={variables}
      render={relayProps => <ProductList products={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(ProductListPage, 'orgProductsList', {
  orderBy: 'title',
  initialFilters: {
    includeInactive: false,
    title: '',
    code: ''
  }
});

/**
 * @flow
 * @relayHash a05c8ab74bea999da224187d0a5701de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobCommentThread_job$ref = any;
type JobDetailsTab_job$ref = any;
type JobDetailsTab_stateTypes$ref = any;
export type JobDetailsTabRendererQueryVariables = {|
  id: string
|};
export type JobDetailsTabRendererQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: JobDetailsTab_job$ref & JobCommentThread_job$ref
  |},
  +jobState: ?{|
    +$fragmentRefs: JobDetailsTab_stateTypes$ref
  |},
|};
export type JobDetailsTabRendererQuery = {|
  variables: JobDetailsTabRendererQueryVariables,
  response: JobDetailsTabRendererQueryResponse,
|};
*/

/*
query JobDetailsTabRendererQuery(
  $id: ID!
) {
  job(id: $id) {
    ...JobDetailsTab_job
    ...JobCommentThread_job
    id
  }
  jobState: __type(name: "JobStatus") {
    ...JobDetailsTab_stateTypes
  }
}

fragment JobDetailsTab_job on JobNode {
  id
  contractSignDeadline
  contractDate
  created
  status
  staffCoordinator {
    fullName
    representativeImageUrl
    organization {
      name
      representativeImageUrl
      id
    }
    id
  }
  contract {
    id
    contractFileUrl
    job {
      status
      id
    }
  }
  jobId
  totalValue
  currencyCode
  hasDeliverableReview
  deliverableCategory {
    name
    id
  }
}

fragment JobCommentThread_job on JobNode {
  id
  comments {
    edges {
      node {
        id
        content
        created
        contentEditedAt
        extraData
        commentType
        repliedTo {
          content
          created
          user {
            fullName
            id
          }
        }
        user {
          id
          fullName
          representativeImageUrl
        }
      }
    }
  }
}

fragment JobDetailsTab_stateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id'
      }
    ],
    v2 = [
      {
        kind: 'Literal',
        name: 'name',
        value: 'JobStatus'
      }
    ],
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v4 = {
      kind: 'ScalarField',
      alias: null,
      name: 'created',
      args: null,
      storageKey: null
    },
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'status',
      args: null,
      storageKey: null
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'fullName',
      args: null,
      storageKey: null
    },
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'representativeImageUrl',
      args: null,
      storageKey: null
    },
    v8 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null
    },
    v9 = {
      kind: 'ScalarField',
      alias: null,
      name: 'content',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'JobDetailsTabRendererQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'JobDetailsTab_job',
              args: null
            },
            {
              kind: 'FragmentSpread',
              name: 'JobCommentThread_job',
              args: null
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: 'jobState',
          name: '__type',
          storageKey: '__type(name:"JobStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'JobDetailsTab_stateTypes',
              args: null
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'JobDetailsTabRendererQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            (v3 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'contractSignDeadline',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'contractDate',
              args: null,
              storageKey: null
            },
            (v4 /*: any*/),
            (v5 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'staffCoordinator',
              storageKey: null,
              args: null,
              concreteType: 'StaffNode',
              plural: false,
              selections: [
                (v6 /*: any*/),
                (v7 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'organization',
                  storageKey: null,
                  args: null,
                  concreteType: 'OrgNode',
                  plural: false,
                  selections: [(v8 /*: any*/), (v7 /*: any*/), (v3 /*: any*/)]
                },
                (v3 /*: any*/)
              ]
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'contract',
              storageKey: null,
              args: null,
              concreteType: 'ContractNode',
              plural: false,
              selections: [
                (v3 /*: any*/),
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'contractFileUrl',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'job',
                  storageKey: null,
                  args: null,
                  concreteType: 'JobNode',
                  plural: false,
                  selections: [(v5 /*: any*/), (v3 /*: any*/)]
                }
              ]
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'jobId',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'totalValue',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'currencyCode',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'hasDeliverableReview',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'deliverableCategory',
              storageKey: null,
              args: null,
              concreteType: 'CategoryNode',
              plural: false,
              selections: [(v8 /*: any*/), (v3 /*: any*/)]
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'comments',
              storageKey: null,
              args: null,
              concreteType: 'CommentNodeConnection',
              plural: false,
              selections: [
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'edges',
                  storageKey: null,
                  args: null,
                  concreteType: 'CommentNodeEdge',
                  plural: true,
                  selections: [
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'node',
                      storageKey: null,
                      args: null,
                      concreteType: 'CommentNode',
                      plural: false,
                      selections: [
                        (v3 /*: any*/),
                        (v9 /*: any*/),
                        (v4 /*: any*/),
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'contentEditedAt',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'extraData',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'commentType',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'repliedTo',
                          storageKey: null,
                          args: null,
                          concreteType: 'CommentRepliedToNode',
                          plural: false,
                          selections: [
                            (v9 /*: any*/),
                            (v4 /*: any*/),
                            {
                              kind: 'LinkedField',
                              alias: null,
                              name: 'user',
                              storageKey: null,
                              args: null,
                              concreteType: 'UserNode',
                              plural: false,
                              selections: [(v6 /*: any*/), (v3 /*: any*/)]
                            }
                          ]
                        },
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'user',
                          storageKey: null,
                          args: null,
                          concreteType: 'UserNode',
                          plural: false,
                          selections: [(v3 /*: any*/), (v6 /*: any*/), (v7 /*: any*/)]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: 'jobState',
          name: '__type',
          storageKey: '__type(name:"JobStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'enumValues',
              storageKey: null,
              args: null,
              concreteType: '__EnumValue',
              plural: true,
              selections: [
                (v8 /*: any*/),
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'description',
                  args: null,
                  storageKey: null
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'JobDetailsTabRendererQuery',
      id: null,
      text:
        'query JobDetailsTabRendererQuery(\n  $id: ID!\n) {\n  job(id: $id) {\n    ...JobDetailsTab_job\n    ...JobCommentThread_job\n    id\n  }\n  jobState: __type(name: "JobStatus") {\n    ...JobDetailsTab_stateTypes\n  }\n}\n\nfragment JobDetailsTab_job on JobNode {\n  id\n  contractSignDeadline\n  contractDate\n  created\n  status\n  staffCoordinator {\n    fullName\n    representativeImageUrl\n    organization {\n      name\n      representativeImageUrl\n      id\n    }\n    id\n  }\n  contract {\n    id\n    contractFileUrl\n    job {\n      status\n      id\n    }\n  }\n  jobId\n  totalValue\n  currencyCode\n  hasDeliverableReview\n  deliverableCategory {\n    name\n    id\n  }\n}\n\nfragment JobCommentThread_job on JobNode {\n  id\n  comments {\n    edges {\n      node {\n        id\n        content\n        created\n        contentEditedAt\n        extraData\n        commentType\n        repliedTo {\n          content\n          created\n          user {\n            fullName\n            id\n          }\n        }\n        user {\n          id\n          fullName\n          representativeImageUrl\n        }\n      }\n    }\n  }\n}\n\nfragment JobDetailsTab_stateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '1d0c1c06a161d15dd6dad7e2188232b5';
module.exports = node;

import React, { Fragment, useState, useEffect } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withStyles } from '@material-ui/styles';

import AutoTable from '../../../../../shared/components/table/AutoTable';
import ContractorsListFilters from './ContractorsListFilters';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { tableStyles } from '../../../../../shared/styles/common/tableStyles';
import { CELL_TYPES } from '../../../../../shared/constants';
import { successToast } from '../../../../../shared/toasts';
import BulkSendInvitationsToContractorsMutation from '../../mutations/BulkSendInvitationsToContractorsMutation';
import BulkToggleTagsOnContractorsMutation from '../../mutations/BulkToggleTagsOnContractorsMutation';
import ManageContractorsInGroupMutation from '../../mutations/ManageContractorsInGroupMutation';
import ContractorsQuickView from './ContractorsQuickView';
import ContractorBulkInviteDialog from './ContractorBulkInviteDialog';
import ToggleTagsDialog from '../../../../../shared/components/common/ToggleTagsDialog';
import ManageContractorGroupsDialog from '../../../../../shared/components/common/ManageContractorGroupsDialog';
import useDialog from '../../../../../shared/hooks/useDialog';

const ACTION_BUTTON_OPTIONS = {
  invite: { name: 'invite', label: 'Invite' },
  addTags: { name: 'addTags', label: 'Add Tags' },
  removeTags: { name: 'removeTags', label: 'Remove Tags' },
  addGroup: { name: 'addGroup', label: 'Add to Group' },
  removeGroup: { name: 'removeGroup', label: 'Remove from Group' }
};

const ContractorsList = props => {
  const {
    refetchCounter,
    toRefetchData,
    relay: { refetch },
    contractors: {
      contractorsByOrg: {
        edges,
        totalCount,
        edgeCount,
        pageInfo: { hasNextPage, endCursor }
      }
    },
    history,
    match,
    handleContextActionOptions,
    filterProps
  } = props;

  const [selectedId, setSelectedId] = useState(null);
  const [selectedContractors, setSelectedContractors] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [addTags, setAddTags] = useState(null);
  const [addGroup, setAddGroup] = useState(null);
  const [isDialogOpen, setDialogOpen] = useDialog();

  useEffect(() => {
    return () => {
      handleContextActionOptions();
    };
  }, []);

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onClickAvatar = id => {
    history.push(`${match.url}/${id}`);
  };

  const checkForContextActionsHandler = checked => {
    if (checked.length) {
      const areAllActiveAndNotConflicting = checked.every(
        contractor => contractor.node.isActive && !contractor.node.flags.hasConflictingContractor
      );
      handleContextActionOptions([
        {
          name: 'solicitationWithContractors',
          data: {
            checked: checked.map(item => ({ ...item.node, fullName: item.node.name })),
            disabledProps: {
              disabled: !areAllActiveAndNotConflicting,
              variant: 'solicitationFromContractors'
            }
          }
        },
        {
          name: 'contractorGroupWithContractors',
          data: {
            checked: checked.map(item => item.node),
            disabledProps: {
              disabled: !areAllActiveAndNotConflicting
            }
          }
        }
      ]);
    } else {
      handleContextActionOptions();
    }
  };

  const getDisabledActions = checked => {
    for (const item of checked) {
      if (item.node.inviteStatus === 'Joined') {
        return [ACTION_BUTTON_OPTIONS.invite.name];
      }
    }
  };

  const handleActionClick = (action, checkedIds) => {
    if (action === ACTION_BUTTON_OPTIONS.addTags.name) {
      setAddTags(true);
    } else if (action === ACTION_BUTTON_OPTIONS.removeTags.name) {
      setAddTags(false);
    } else if (action === ACTION_BUTTON_OPTIONS.addGroup.name) {
      setAddGroup(true);
    } else if (action === ACTION_BUTTON_OPTIONS.removeGroup.name) {
      setAddGroup(false);
    }
    setSelectedContractors(flattenedEdges.filter(({ node }) => checkedIds.includes(node.id)));
    setSelectedAction(action);
    toggleDialogOpen();
  };

  const handleBulkInviteSubmit = ({ message }) => {
    BulkSendInvitationsToContractorsMutation(
      {
        ids: selectedContractors.map(({ node }) => node.id),
        message
      },
      () => {
        successToast(`Invitation email${selectedContractors.length > 1 ? 's were' : ' was'} sent!`);
        toggleDialogOpen();
        setSelectedContractors(null);
        toRefetchData();
      }
    );
  };

  const handleBulkTagToggleSubmit = values => {
    BulkToggleTagsOnContractorsMutation(
      {
        tags: values.tags.options.map(option => option.value),
        contractors: selectedContractors.map(({ node }) => node.id),
        activate: addTags ? true : false
      },
      () => {
        successToast(
          `Contractor ${values.tags.options.length > 1 ? 'tags' : 'tag'} succesfully ${
            addTags ? 'added' : 'removed'
          }.`
        );
        setSelectedContractors(null);
        toRefetchData();
        toggleDialogOpen();
      }
    );
  };

  const handleManageContractorsInGroupSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    ManageContractorsInGroupMutation(
      {
        group: values.contractorGroup.value,
        contractors: selectedContractors.map(({ node }) => node.id),
        activate: addGroup ? true : false
      },
      (response, errors) => {
        setSubmitting(false);
        if (errors) {
          // do nothing?
        } else {
          successToast(
            `The Contractor${selectedContractors.length > 1 ? 's were' : ' was'} succesfully ${
              addGroup ? 'added to' : 'removed from'
            } the group.`
          );
          setSelectedContractors(null);
          toRefetchData();
          toggleDialogOpen();
        }
      }
    );
  };

  const toggleDialogOpen = () => {
    setDialogOpen(!isDialogOpen);
  };

  const handleClose = () => {
    setSelectedContractors(null);
    setSelectedAction(null);
    toggleDialogOpen();
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      lastName,
      fullName: name,
      representativeImageUrl: imageUrl,
      activeDeliverablesCount,
      pastDueDeliverablesCount,
      completedDeliverablesCount,
      doNotHire,
      isActive,
      conflictingContractor,
      inviteStatus
    } = edge.node;

    return {
      node: {
        id,
        lastName,
        name: { name, imageUrl, id },
        flags: {
          doNotHire,
          deactivated: isActive ? false : true,
          hasConflictingContractor: conflictingContractor ? true : false
        },
        activeDeliverablesCount,
        pastDueDeliverablesCount,
        completedDeliverablesCount,
        isActive,
        inviteStatus
      }
    };
  });

  let dialogComponent = null;
  if (selectedAction === ACTION_BUTTON_OPTIONS.invite.name) {
    dialogComponent = (
      <ContractorBulkInviteDialog
        isDialogOpen={isDialogOpen}
        contractors={selectedContractors}
        onConfirm={handleBulkInviteSubmit}
        closeDialog={handleClose}
      />
    );
  } else if (
    selectedAction === ACTION_BUTTON_OPTIONS.addTags.name ||
    selectedAction === ACTION_BUTTON_OPTIONS.removeTags.name
  ) {
    dialogComponent = (
      <ToggleTagsDialog
        isDialogOpen={isDialogOpen}
        ids={selectedContractors}
        onConfirm={handleBulkTagToggleSubmit}
        closeDialog={handleClose}
        activate={addTags ? true : false}
        type={'Contractor'}
      />
    );
  } else if (
    selectedAction === ACTION_BUTTON_OPTIONS.addGroup.name ||
    selectedAction === ACTION_BUTTON_OPTIONS.removeGroup.name
  ) {
    dialogComponent = (
      <ManageContractorGroupsDialog
        isDialogOpen={isDialogOpen}
        ids={selectedContractors}
        onConfirm={handleManageContractorsInGroupSubmit}
        closeDialog={handleClose}
        activate={addGroup ? true : false}
      />
    );
  }

  return (
    <Fragment>
      {dialogComponent}
      <AutoTable
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
          { name: '', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
          {
            name: 'name',
            label: 'Name',
            type: CELL_TYPES.avatar,
            sortable: true,
            onClick: onClickAvatar,
            clickable: true
          },
          {
            name: 'flags',
            label: 'Flags',
            type: CELL_TYPES.flags
          },
          {
            name: 'inviteStatus',
            label: 'Invite Status'
          },
          {
            name: 'activeDeliverablesCount',
            label: 'Active Deliverables',
            align: 'right'
          },
          {
            name: 'completedDeliverablesCount',
            label: 'Completed Deliverables',
            align: 'right'
          }
          //* Disabling Ratings from UI until feature fully fleshed out
          // {
          //   name: 'rating',
          //   label: 'Rating',
          //   type: CELL_TYPES.rating,
          //   width: '10%'
          // }
        ]}
        onChangeHandler={onChangeHandler}
        edges={flattenedEdges}
        refetchCounter={refetchCounter}
        labelledByHeader="select all contractors"
        selectedId={selectedId}
        paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
        checkForContextActions={checkForContextActionsHandler}
        actionButtonProps={{
          options: Object.values(ACTION_BUTTON_OPTIONS),
          handleMenuItemClick: handleActionClick,
          getDisabledActions
        }}
        filterProps={{
          filterComponent: <ContractorsListFilters />,
          withApply: true,
          withSaveAsDefault: true,
          ...filterProps
        }}
      />
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <ContractorsQuickView contractorId={selectedId} />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withUserContext(
  withStyles(tableStyles)(
    createRefetchContainer(
      ContractorsList,
      {
        contractors: graphql`
          fragment ContractorsList_contractors on Query {
            contractorsByOrg(
              first: $first
              orderBy: $orderBy
              after: $after
              contractorName: $contractorName
              includeDoNotHire: $includeDoNotHire
              includeInactive: $includeInactive
              inviteStatus: $inviteStatus
              country: $country
              release: $release
              product: $product
              tags: $tags
              minCompletedDeliverables: $minCompletedDeliverables
              group: $group
            ) {
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              totalCount
              edgeCount
              edges {
                node {
                  id
                  doNotHire
                  representativeImageUrl
                  activeDeliverablesCount
                  pastDueDeliverablesCount
                  completedDeliverablesCount
                  fullName
                  lastName
                  isActive
                  inviteStatus
                  conflictingContractor {
                    id
                  }
                }
              }
            }
          }
        `
      },
      graphql`
        query ContractorsListRefetchQuery(
          $first: Int
          $orderBy: String
          $after: String
          $includeInactive: Boolean
          $includeDoNotHire: Boolean
          $contractorName: String
          $inviteStatus: String
          $country: String
          $release: String
          $product: String
          $tags: String
          $minCompletedDeliverables: Float
          $group: String
        ) {
          ...ContractorsList_contractors
        }
      `
    )
  )
);

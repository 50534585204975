import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import DeleteShowcaseAssetMutation from '../../mutations/DeleteShowcaseAssetMutation';
import CreateShowcaseAssetMutation from '../../mutations/CreateShowcaseAssetMutation';
import AssetList from '../../../../../shared/components/assets/AssetList';
import UpdateShowcaseAssetMutation from '../../mutations/UpdateShowcaseAssetMutation';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { performFileUpload } from '../../../../../shared/services/utilities';

const ShowcaseList = props => {
  const {
    contractor: {
      id: contractorId,
      contractorShowcase: {
        pageInfo: { hasNextPage, endCursor },
        totalCount,
        edgeCount,
        edges
      }
    },
    userContext: {
      orgStaff: { allowedActions }
    },
    relay: { refetch }
  } = props;

  let submittedFormValues = undefined;
  let submittedFormCallback = undefined;

  const noPresignedUploadHandler = (file, submittedFormCallback) => {
    const { fileName, description } = submittedFormValues;
    CreateShowcaseAssetMutation(
      { contractorId, fileName: fileName || file.name, description },
      file,
      submittedFormCallback
    );
  };

  const confirmPresignedUploadSuccessfulCallback = (
    stagedPath,
    filename,
    submittedFormCallback
  ) => {
    const { fileName: formFileName, description } = submittedFormValues;
    CreateShowcaseAssetMutation(
      { contractorId, fileName: formFileName || filename, description, stagedPath },
      null,
      submittedFormCallback
    );
  };

  const handleCreateItem = (values, { setSubmitting }, cb) => {
    setSubmitting(true);
    const { file } = values;

    let modelName = 'asset';
    let fieldName = 'file';

    submittedFormValues = values;
    submittedFormCallback = cb;

    performFileUpload(
      modelName,
      fieldName,
      null,
      file,
      file => {
        noPresignedUploadHandler(file, submittedFormCallback);
      },
      (stagedPath, filename) => {
        confirmPresignedUploadSuccessfulCallback(stagedPath, filename, submittedFormCallback);
      }
    );
  };

  const handleDeleteItem = (assetId, cb) => {
    DeleteShowcaseAssetMutation(assetId, response => {
      if (response) {
        cb();
        refetch(prevVars => ({ ...prevVars, shortAfter: '' }));
      }
    });
  };

  const handleEditItem = (values, cb) => {
    const { id: assetId, fileName, description } = values;
    UpdateShowcaseAssetMutation(
      {
        assetId,
        fileName,
        description
      },
      cb
    );
  };

  return (
    <AssetList
      edges={edges}
      refetch={refetch}
      createItemHandler={handleCreateItem}
      deleteItemHandler={handleDeleteItem}
      editItemHandler={handleEditItem}
      shouldShowUploadButton={computePermissionRole(
        ALLOWED_ACTIONS.CONTRACTOR_UPLOAD_SHOWCASE_FILE,
        allowedActions
      )}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
    />
  );
};

export default withUserContext(
  createRefetchContainer(
    ShowcaseList,
    {
      contractor: graphql`
        fragment ShowcaseList_contractor on ContractorNode {
          id
          contractorShowcase(first: $first, after: $after) {
            totalCount
            edgeCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                fileName
                fileUrl
                description
                reviewInterface
              }
            }
          }
        }
      `
    },
    graphql`
      query ShowcaseListRefetchQuery($id: ID!, $first: Int, $after: String) {
        contractor(id: $id) {
          ...ShowcaseList_contractor
        }
      }
    `
  )
);

import React, { useState, useMemo } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Grid, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { createFragmentContainer } from 'react-relay';

import UpdateOrganizationMutation from './organizationProfile/mutations/UpdateOrganizationMutation';
import EditableField from '../../../shared/components/form/fields/EditableField';
import { yupSchemas } from '../../../shared/validations';
import { FormikCountrySelect } from '../../../shared/components/form/dropdowns/CountrySelect';
import FormikPhoneField from '../../../shared/components/form/FormikPhoneField';
import EditToggle from '../../../shared/components/UI/EditToggle';
import FormikEditableImage from '../../../shared/components/form/formikFields/FormikEditableImage';
import WhiteBox from '../../../shared/components/common/WhiteBox';
import DetailsSection from '../../../shared/components/common/DetailsSection';

const ORGANIZATION_INFO_FIELDS = [
  // { name: 'name', label: 'Organization Name' }, //Have to add it manually, because one field below will not be editable now.
  { name: 'contractEmail', label: 'Contract Delivery Email' },
  { name: 'financeEmail', label: 'Accounts Payable Email' },
  { name: 'website', label: 'Website' }
];

const ADDRESS_INFO_FIELDS = [
  { name: 'address1', label: 'Address 1' },
  { name: 'address2', label: 'Address 2' },
  { name: 'city', label: 'City' },
  { name: 'state', label: 'State/Province' },
  { name: 'zipcode', label: 'ZIP/Postal Code' },
  {
    name: 'country',
    label: 'Country',
    component: FormikCountrySelect,
    getContent: value => value && value.label,
    fieldProps: { notFullWidth: true }
  },
  {
    name: 'phone',
    label: 'Phone',
    component: FormikPhoneField
  }
];

const OrganizationProfile = props => {
  const {
    organization: { name }
  } = props;

  const [editing, setEditing] = useState(false);

  const initialValues = useMemo(() => {
    const {
      name,
      address1,
      address2,
      city,
      state,
      zipcode,
      country,
      website,
      representativeImageUrl,
      financeEmail,
      contractEmail,
      email,
      phone
    } = props.organization;
    return {
      name,
      address1,
      address2: address2 || '',
      city,
      state,
      zipcode,
      country: country
        ? {
            value: country.code,
            label: country.name
          }
        : null,
      email,
      phone,
      website: website || '',
      financeEmail: financeEmail || '',
      contractEmail: contractEmail || '',
      reprImageNull: false,
      reprImage: {
        src: representativeImageUrl,
        file: null
      }
    };
  }, [props.organization]);

  const formikProps = {
    initialValues,
    validationSchema: yupSchemas.shape({
      name: yupSchemas.string(true, { max: 100 }),
      address1: yupSchemas.string(true),
      address2: yupSchemas.string().ensure(),
      city: yupSchemas.string(true),
      state: yupSchemas.string(true),
      zipcode: yupSchemas.string(true, { max: 10 }),
      country: yupSchemas.country(true),
      website: yupSchemas.url(false),
      financeEmail: yupSchemas.email(false),
      contractEmail: yupSchemas.email(false),
      email: yupSchemas.email(true),
      phone: yupSchemas.phone(true),
      reprImage: yupSchemas.shape({
        file: yupSchemas.avatarFileSize
      })
    }),
    onSubmit: (values, { resetForm }) => {
      const { country, reprImage, ...otherValues } = values;
      const file = reprImage.file;
      UpdateOrganizationMutation(
        {
          ...otherValues,
          country: country.value
        },
        (response, errors) => {
          if (!errors) {
            resetForm();
            setEditing(false);
          }
        },
        file
      );
    }
  };

  return (
    <Formik {...formikProps} enableReinitialize>
      {({ values, resetForm }) => (
        <Form>
          <WhiteBox>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: '3rem' }}
            >
              <Typography variant="h3">{name}</Typography>
              <EditToggle
                isEditing={editing}
                editProps={{
                  id: 'editOrganizationProfile',
                  onClick: () => setEditing(true)
                }}
                saveProps={{
                  id: 'saveOrganizationProfileEdit'
                }}
                cancelProps={{
                  id: 'cancelOrganizationProfileEdit',
                  onClick: () => {
                    resetForm();
                    setEditing(false);
                  }
                }}
              />
            </Grid>
            <Grid container spacing={6}>
              <Grid item>
                <Field
                  name="reprImage"
                  nameNull="reprImageNull"
                  editing={editing}
                  component={FormikEditableImage}
                  imageProps={{ alt: name }}
                  uploadButtonId="uploadOrganizationImage"
                  deleteButtonId="deleteOrganizationImage"
                />
              </Grid>
              <Grid item xs={12} sm container direction="column">
                <DetailsSection title="Organization Info">
                  <Grid container spacing={2} direction="column" justify="flex-start">
                    <EditableField
                      key="name"
                      name="name"
                      label="Organization Name"
                      editing={editing}
                      detailsRowProps={{ noMarginTop: true, titleWidth: 150 }}
                      formikValues={values}
                    />
                    {ORGANIZATION_INFO_FIELDS.map(field => (
                      <EditableField
                        key={field.name}
                        editing={editing}
                        detailsRowProps={{ noMarginTop: true, titleWidth: 150 }}
                        formikValues={values}
                        {...field}
                      />
                    ))}
                  </Grid>
                </DetailsSection>
                <DetailsSection title="Address Info" noBorder>
                  <Grid
                    container
                    spacing={2}
                    style={{ width: 800 }}
                    justify={editing ? 'flex-start' : 'space-between'}
                  >
                    {ADDRESS_INFO_FIELDS.map(field => (
                      <EditableField
                        key={field.name}
                        editing={editing}
                        formikValues={values}
                        {...field}
                      />
                    ))}
                  </Grid>
                </DetailsSection>
              </Grid>
            </Grid>
          </WhiteBox>
        </Form>
      )}
    </Formik>
  );
};

export default createFragmentContainer(OrganizationProfile, {
  organization: graphql`
    fragment OrganizationProfile_organization on OrganizationNode {
      id
      name
      address1
      address2
      city
      state
      zipcode
      country {
        name
        code
      }
      website
      representativeImageUrl
      financeEmail
      contractEmail
      email
      phone
    }
  `
});

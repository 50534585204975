import React, { useState } from 'react';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Formik, Form, Field } from 'formik';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import FilterDebounceInput from '../../../../shared/components/filters/FilterDebounceInput';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import StyledSwitch from '../../../../shared/components/UI/StyledSwitch';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import { useCustomizationStyles } from '../../../../shared/styles/admin/useCustomizationStyles';

import UpdateOrganizationConfigurationMutation from './mutations/UpdateOrganizationConfigurationMutation';

const Navigation = props => {
  const {
    userContext: {
      setUserInfo,
      orgStaff: { organization }
    }
  } = props;

  const navigation = JSON.parse(organization.configuration).navigation;
  const [customProductEnabled, setCustomProductEnabled] = useState(
    navigation.productTitle.is_active
  );

  const [customReleaseEnabled, setCustomReleaseEnabled] = useState(
    navigation.releaseTitle.is_active
  );

  const classes = useCustomizationStyles();

  const initialValues = { ...navigation };

  const handleToggleEditProduct = (e, values) => {
    setCustomProductEnabled(!customProductEnabled);
    let variables = {
      productTitle: {
        singular: e.target.checked === false ? 'Title' : values.productTitle.singular,
        plural: e.target.checked === false ? 'Titles' : values.productTitle.plural,
        icon: 'Titles',
        is_active: e.target.checked
      },
      releaseTitle: {
        singular: values.releaseTitle.singular,
        plural: values.releaseTitle.plural,
        icon: 'Releases',
        is_active: customReleaseEnabled
      }
    };
    UpdateOrganizationConfigurationMutation({ navigation: JSON.stringify(variables) }, response => {
      if (response && response.isUpdated && response.organization) {
        setUserInfo({
          ...props.userContext,
          orgStaff: { ...props.userContext.orgStaff, organization: response.organization }
        });
      }
    });
  };

  const handleToggleEditRelease = (e, values) => {
    setCustomReleaseEnabled(!customReleaseEnabled);
    let variables = {
      productTitle: {
        singular: values.productTitle.singular,
        plural: values.productTitle.plural,
        icon: 'Titles',
        is_active: customProductEnabled
      },
      releaseTitle: {
        singular: e.target.checked === false ? 'Release' : values.releaseTitle.singular,
        plural: e.target.checked === false ? 'Releases' : values.releaseTitle.plural,
        icon: 'Releases',
        is_active: e.target.checked
      }
    };
    UpdateOrganizationConfigurationMutation({ navigation: JSON.stringify(variables) }, response => {
      if (response && response.isUpdated && response.organization) {
        setUserInfo({
          ...props.userContext,
          orgStaff: { ...props.userContext.orgStaff, organization: response.organization }
        });
      }
    });
  };

  const updateOrganizationConfiguration = debounce(async (name, value, formValues) => {
    if (!value) return;
    let values = {
      productTitle: {
        singular: formValues.productTitle.singular,
        plural: formValues.productTitle.plural,
        icon: 'Titles',
        is_active: customProductEnabled
      },
      releaseTitle: {
        singular: formValues.releaseTitle.singular,
        plural: formValues.releaseTitle.plural,
        icon: 'Releases',
        is_active: customReleaseEnabled
      }
    };

    if (customProductEnabled && (!values.productTitle.singular || !values.productTitle.plural))
      return;
    if (customReleaseEnabled && (!values.releaseTitle.singular || !values.releaseTitle.plural))
      return;

    set(values, name, value);

    UpdateOrganizationConfigurationMutation({ navigation: JSON.stringify(values) }, response => {
      if (response && response.isUpdated && response.organization) {
        setUserInfo({
          ...props.userContext,
          orgStaff: { ...props.userContext.orgStaff, organization: response.organization }
        });
      }
    });
  }, 1000);

  const navigationValidationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.'),
    titles: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.'),
    release: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.'),
    releases: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.')
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={navigationValidationSchema}
      enableReinitialize
    >
      {({ values, setFieldValue, setValues }) => (
        <Form>
          <div
            className={clsx(
              classes.container,
              classes.justifySpaceBetween,
              classes.alignItemsCenter,
              classes.topSectionHeight
            )}
          >
            <Typography variant="h3">Navigation</Typography>
          </div>
          <DetailsSection>
            <div>
              <div className={clsx(classes.container, classes.rowHeight, classes.marginBottom)}>
                <div className={clsx(classes.container, classes.alignItemsCenter)}>
                  <StyledSwitch
                    checked={customProductEnabled}
                    disabled={!values.productTitle.singular || !values.productTitle.plural}
                    onChange={e => handleToggleEditProduct(e, values)}
                  />
                  <div className={classes.textWidth}>
                    <span className={clsx(classes.greyText, classes.textMarginRight)}>Change</span>
                    <span className={clsx(classes.bold, classes.textMarginRight)}>Title</span>
                    <span className={clsx(classes.greyText)}>to</span>
                  </div>
                </div>

                <Field name="productTitle.singular" placeholder="eg. Game ">
                  {({
                    field // { name, value, onChange, onBlur }
                  }) => (
                    <FilterDebounceInput
                      value={field.value}
                      disabled={!customProductEnabled}
                      onChange={(e, value) => {
                        setFieldValue('productTitle.singular', value);
                        updateOrganizationConfiguration('productTitle.singular', value, values);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div
                className={clsx(
                  classes.container,
                  classes.rowHeight,
                  classes.naviCustomizeMargin,
                  classes.marginBottom
                )}
              >
                <div className={classes.textWidth}>
                  <span className={clsx(classes.greyText, classes.textMarginRight)}>Change</span>
                  <span className={clsx(classes.bold, classes.textMarginRight)}>Titles</span>
                  <span className={clsx(classes.greyText)}>to</span>
                </div>
                <Field name="productTitle.plural" placeholder="eg. Game ">
                  {({
                    field // { name, value, onChange, onBlur }
                  }) => (
                    <FilterDebounceInput
                      value={field.value}
                      disabled={!customProductEnabled}
                      onChange={(e, value) => {
                        setFieldValue('productTitle.plural', value);
                        updateOrganizationConfiguration('productTitle.plural', value, values);
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>
          </DetailsSection>
          <div>
            <div className={clsx(classes.container, classes.rowHeight, classes.marginBottom)}>
              <div className={clsx(classes.container, classes.alignItemsCenter)}>
                <StyledSwitch
                  checked={customReleaseEnabled}
                  disabled={!values.releaseTitle.singular || !values.releaseTitle.plural}
                  onChange={e => handleToggleEditRelease(e, values)}
                />
                <div className={classes.textWidth}>
                  <span className={clsx(classes.greyText, classes.textMarginRight)}>Change</span>
                  <span className={clsx(classes.bold, classes.textMarginRight)}>Release</span>
                  <span className={clsx(classes.greyText)}>to</span>
                </div>
              </div>

              <Field name="releaseTitle.singular" placeholder="eg. Game ">
                {({
                  field // { name, value, onChange, onBlur }
                }) => (
                  <FilterDebounceInput
                    value={field.value}
                    disabled={!customReleaseEnabled}
                    onChange={(e, value) => {
                      setFieldValue('releaseTitle.singular', value);
                      updateOrganizationConfiguration('releaseTitle.singular', value, values);
                    }}
                  />
                )}
              </Field>
            </div>
            <div
              className={clsx(
                classes.container,
                classes.rowHeight,
                classes.naviCustomizeMargin,
                classes.marginBottom
              )}
            >
              <div className={classes.textWidth}>
                <span className={clsx(classes.greyText, classes.textMarginRight)}>Change</span>
                <span className={clsx(classes.bold, classes.textMarginRight)}>Releases</span>
                <span className={clsx(classes.greyText)}>to</span>
              </div>
              <Field name="releaseTitle.plural" placeholder="eg. Game ">
                {({
                  field // { name, value, onChange, onBlur }
                }) => (
                  <FilterDebounceInput
                    value={field.value}
                    disabled={!customReleaseEnabled}
                    onChange={(e, value) => {
                      setFieldValue('releaseTitle.plural', value);
                      updateOrganizationConfiguration('releaseTitle.plural', value, values);
                    }}
                  />
                )}
              </Field>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(Navigation);

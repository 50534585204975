/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReleaseDetailsMainContent_release$ref: FragmentReference;
declare export opaque type ReleaseDetailsMainContent_release$fragmentType: ReleaseDetailsMainContent_release$ref;
export type ReleaseDetailsMainContent_release = {|
  +id: string,
  +description: string,
  +name: string,
  +code: string,
  +internalId: ?string,
  +deadline: ?any,
  +totalBudget: ?any,
  +product: {|
    +id: string,
    +code: ?string,
    +title: string,
  |},
  +$refType: ReleaseDetailsMainContent_release$ref,
|};
export type ReleaseDetailsMainContent_release$data = ReleaseDetailsMainContent_release;
export type ReleaseDetailsMainContent_release$key = {
  +$data?: ReleaseDetailsMainContent_release$data,
  +$fragmentRefs: ReleaseDetailsMainContent_release$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ReleaseDetailsMainContent_release",
  "type": "ReleaseNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "internalId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deadline",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalBudget",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "product",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '387eda02bc0c4adf0333726b1da4e930';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import { ReactComponent as SolicitationIcon } from '../../../../shared/images/solicitations.svg';
import BottomSubtitle from '../../../../shared/components/UI/BottomSubtitle';
import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import SolicitationDetailsTab from './SolicitationDetailsTab';
import SolicitationReferenceTab from './SolicitationReferenceTab';

const SolicitationDetails = props => {
  const {
    solicitation: { subject, staffCoordinator, replyByDate }
  } = props;
  const classes = useDetailsStyles();

  const BottomTitleHeader = (
    <>
      <BottomSubtitle>
        <span className={classes.titleMarginRight}>From: {staffCoordinator.fullName}</span>
        Organization: {staffCoordinator.organization.name}
      </BottomSubtitle>
      <BottomSubtitle>Reply By Date: {replyByDate}</BottomSubtitle>
    </>
  );

  return (
    <>
      <div className={classes.marginBottom}>
        <CategoryTitleHeader
          title={subject}
          icon={SolicitationIcon}
          link="/solicitations"
          renderBottom={() => BottomTitleHeader}
        />
      </div>

      <AutoTabList>
        <SolicitationDetailsTab label="Details" />
        <SolicitationReferenceTab label="Reference" />
      </AutoTabList>
    </>
  );
};

export default createFragmentContainer(SolicitationDetails, {
  solicitation: graphql`
    fragment SolicitationDetails_solicitation on SolicitationNode {
      subject
      replyByDate
      staffCoordinator {
        fullName
        organization {
          name
        }
      }
    }
  `
});

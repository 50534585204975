import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';
import authService from '../../services/authService';
import ConfirmPassword from './ConfirmPassword';

const ResetPassword = props => {
  const [error, setError] = useState('');
  const [tokenValid, setTokenValid] = useState(undefined);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const queryParams = useQueryParams();

  useEffect(() => {
    // Force-logout before resetting password
    authService._removeTokens();
    if (queryParams.get('token') && queryParams.get('uidb64')) {
      authService.checkResetPasswordTokenStatus(
        queryParams.get('token'),
        queryParams.get('uidb64'),
        response => {
          if (response.resetPasswordTokenStatus) {
            setTokenValid(true);
          } else {
            setTokenValid(false);
          }
        }
      );
    } else {
      // if url does not have required query params, redirect to login page
      setRedirectToLogin(true);
    }
  }, []);

  if (redirectToLogin) {
    return (
      <Redirect
        to={{
          pathname: '/login'
        }}
      />
    );
  }

  const submitHandler = (values, actions) => {
    authService.resetPassword(
      values.password,
      values.repeatPassword,
      queryParams.get('uidb64'),
      queryParams.get('token'),
      response => {
        if (response && response.email && !response.errors) {
          authService.signIn(response.email, values.password, (success, error) => {
            actions.setSubmitting(false);

            if (success) {
              props.history.push('/');
            } else {
              setError(error);
            }
          });
        } else {
          actions.setSubmitting(false);

          setError('Something went wrong.');
          console.error('Reset password failed.');
        }
      }
    );
  };

  return (
    <ConfirmPassword
      submitHandler={submitHandler}
      validLink={tokenValid}
      error={error}
      variant="resetPassword"
    />
  );
};

export default ResetPassword;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@material-ui/core';

import { ReactComponent as BanIcon } from '../../images/ban-icon.svg';
import { ReactComponent as UserAlertIcon } from '../../images/user-alert-icon.svg';
import SpeakerNotesOffOutlinedIcon from '@material-ui/icons/SpeakerNotesOffOutlined';

const FlagIndicator = ({ deactivated, doNotHire, hasConflictingContractor }) => (
  <Fragment>
    {doNotHire && (
      <Tooltip title="Do Not Hire">
        <BanIcon />
      </Tooltip>
    )}
    {deactivated && (
      <Tooltip title="Inactive Contractor">
        <SpeakerNotesOffOutlinedIcon color="error" />
      </Tooltip>
    )}
    {hasConflictingContractor && (
      <Tooltip title="Conflicting Contractor">
        <UserAlertIcon />
      </Tooltip>
    )}
  </Fragment>
);

FlagIndicator.propTypes = {
  deactivated: PropTypes.bool,
  doNotHire: PropTypes.bool,
  hasConflictingContractor: PropTypes.bool
};

export default FlagIndicator;

import React, { Fragment } from 'react';

import { formatDateDisplay } from '../../utils/formatters';
import { getDateDisplayFormatFromUserContext } from '../../utils/helpers';
import withUserContext from '../../contexts/userContext/withUserContext';

const Date = ({ date, userContext }) => (
  <Fragment>{formatDateDisplay(date, getDateDisplayFormatFromUserContext(userContext))}</Fragment>
);

export default withUserContext(Date);

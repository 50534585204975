/**
 * @flow
 * @relayHash b5c167dce3ae7ba0d2ba89bbfeef2375
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrganizationSubscriptionInterval = "ANNUAL" | "MONTHLY" | "%future added value";
export type UpdateOrganizationSubscriptionInput = {|
  tierName: string,
  interval: string,
  prorationTimestamp?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateOrganizationSubscriptionMutationVariables = {|
  input: UpdateOrganizationSubscriptionInput
|};
export type UpdateOrganizationSubscriptionMutationResponse = {|
  +updateOrganizationSubscription: ?{|
    +isUpdated: ?boolean,
    +organization: ?{|
      +subscription: ?{|
        +tierName: ?string,
        +interval: ?string,
        +nextBillingDate: ?any,
        +numberOfModules: ?number,
        +nextBillingAmount: ?string,
        +numberOfActiveUsers: ?number,
        +numberOfTotalUsers: ?number,
        +numberOfDeliverables: ?number,
        +numberOfContractors: ?number,
        +numberOfContractTemplates: ?number,
      |},
      +activeStorageLimit: ?number,
      +archiveStorageLimit: ?number,
      +activeSubscription: ?{|
        +availableModules: ?$ReadOnlyArray<?{|
          +moduleId: ?string,
          +name: ?string,
          +incremental: ?boolean,
          +quantity: ?number,
          +cost: ?$ReadOnlyArray<?{|
            +tier: ?string,
            +price: ?number,
          |}>,
          +blurbText: ?string,
          +headline: ?string,
          +externalUrl: ?string,
          +description: ?string,
          +imageUrl: ?string,
        |}>
      |},
      +scheduledSubscription: ?{|
        +activeModulesCount: ?number,
        +interval: OrganizationSubscriptionInterval,
        +availableModules: ?$ReadOnlyArray<?{|
          +moduleId: ?string,
          +quantity: ?number,
        |}>,
      |},
    |},
  |}
|};
export type UpdateOrganizationSubscriptionMutation = {|
  variables: UpdateOrganizationSubscriptionMutationVariables,
  response: UpdateOrganizationSubscriptionMutationResponse,
|};
*/


/*
mutation UpdateOrganizationSubscriptionMutation(
  $input: UpdateOrganizationSubscriptionInput!
) {
  updateOrganizationSubscription(input: $input) {
    isUpdated
    organization {
      subscription {
        tierName
        interval
        nextBillingDate
        numberOfModules
        nextBillingAmount
        numberOfActiveUsers
        numberOfTotalUsers
        numberOfDeliverables
        numberOfContractors
        numberOfContractTemplates
      }
      activeStorageLimit
      archiveStorageLimit
      activeSubscription {
        availableModules {
          moduleId
          name
          incremental
          quantity
          cost {
            tier
            price
          }
          blurbText
          headline
          externalUrl
          description
          imageUrl
        }
        id
      }
      scheduledSubscription {
        activeModulesCount
        interval
        availableModules {
          moduleId
          quantity
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrganizationSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isUpdated",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "interval",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "subscription",
  "storageKey": null,
  "args": null,
  "concreteType": "OrganizationSubscription",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tierName",
      "args": null,
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nextBillingDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfModules",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nextBillingAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfActiveUsers",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfTotalUsers",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfContractors",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfContractTemplates",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activeStorageLimit",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "archiveStorageLimit",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "moduleId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "availableModules",
  "storageKey": null,
  "args": null,
  "concreteType": "SubscriptionModuleNode",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "incremental",
      "args": null,
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cost",
      "storageKey": null,
      "args": null,
      "concreteType": "SubscriptionModuleCostNode",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tier",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "price",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "blurbText",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "headline",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "externalUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "imageUrl",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activeModulesCount",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "availableModules",
  "storageKey": null,
  "args": null,
  "concreteType": "SubscriptionModuleNode",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/)
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateOrganizationSubscriptionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrganizationSubscription",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSubscriptionPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "activeSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationSubscriptionNode",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scheduledSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationSubscriptionNode",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v11/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateOrganizationSubscriptionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrganizationSubscription",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSubscriptionPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "activeSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationSubscriptionNode",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v12/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scheduledSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationSubscriptionNode",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              (v12/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateOrganizationSubscriptionMutation",
    "id": null,
    "text": "mutation UpdateOrganizationSubscriptionMutation(\n  $input: UpdateOrganizationSubscriptionInput!\n) {\n  updateOrganizationSubscription(input: $input) {\n    isUpdated\n    organization {\n      subscription {\n        tierName\n        interval\n        nextBillingDate\n        numberOfModules\n        nextBillingAmount\n        numberOfActiveUsers\n        numberOfTotalUsers\n        numberOfDeliverables\n        numberOfContractors\n        numberOfContractTemplates\n      }\n      activeStorageLimit\n      archiveStorageLimit\n      activeSubscription {\n        availableModules {\n          moduleId\n          name\n          incremental\n          quantity\n          cost {\n            tier\n            price\n          }\n          blurbText\n          headline\n          externalUrl\n          description\n          imageUrl\n        }\n        id\n      }\n      scheduledSubscription {\n        activeModulesCount\n        interval\n        availableModules {\n          moduleId\n          quantity\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '97f7d84a8f6a58957e043922a94f1543';
module.exports = node;

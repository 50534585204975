import styled from 'styled-components';
import CommentListContainer from '../../review/CommentListContainer';

export const ContentContainer = styled.div`
  display: flex;

  > * {
    max-height: 580px;

    &:not(:first-child) {
      overflow-y: auto;
    }

    &:first-child {
      width: 408px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      ${CommentListContainer} {
        overflow-y: auto;
        padding-right: 24px;
      }
    }
  }
`;

export const CommentsListOuterContainer = styled.div``;

export const CommentContainer = styled.div`
  width: 100%;
  margin: 0 24px 0 0;
  padding: 0 36px;
  border-left: 1px solid ${props => props.theme.palette.colors.lightestGrey};
  border-right: 1px solid ${props => props.theme.palette.colors.lightestGrey};
`;

export const ReviewAssetThreadContainer = styled.div`
  width: 384px;
  flex-shrink: 0;
`;

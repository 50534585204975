import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

export default (id, onCompleted, onError) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation DeletePortfolioAssetMutation($input: DeletePortfolioAssetInput!) {
        deletePortfolioAsset(input: $input) {
          clientMutationId
          isDeleted
        }
      }
    `,
    variables: { input: { id } },
    onCompleted,
    onError
  });
};

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractorToolsActions_contractor$ref: FragmentReference;
declare export opaque type ContractorToolsActions_contractor$fragmentType: ContractorToolsActions_contractor$ref;
export type ContractorToolsActions_contractor = {|
  +id: string,
  +isActive: ?boolean,
  +doNotHire: ?boolean,
  +$refType: ContractorToolsActions_contractor$ref,
|};
export type ContractorToolsActions_contractor$data = ContractorToolsActions_contractor;
export type ContractorToolsActions_contractor$key = {
  +$data?: ContractorToolsActions_contractor$data,
  +$fragmentRefs: ContractorToolsActions_contractor$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ContractorToolsActions_contractor",
  "type": "ContractorNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "doNotHire",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'abf385aeb1854a3f7f39df921473e379';
module.exports = node;

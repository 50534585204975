/**
 * @flow
 * @relayHash 16bedbbad894acfa01d1ab7e8ee2dd9d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type generateUploadUrlQueryVariables = {|
  filename: string,
  modelName: string,
  modelId?: ?string,
  fieldName: string,
  contentType?: ?string,
|};
export type generateUploadUrlQueryResponse = {|
  +fileUploadUrl: ?string
|};
export type generateUploadUrlQuery = {|
  variables: generateUploadUrlQueryVariables,
  response: generateUploadUrlQueryResponse,
|};
*/


/*
query generateUploadUrlQuery(
  $filename: String!
  $modelName: String!
  $modelId: ID
  $fieldName: String!
  $contentType: String
) {
  fileUploadUrl(filename: $filename, modelName: $modelName, modelId: $modelId, fieldName: $fieldName, contentType: $contentType)
}
*/

const node/*: ConcreteRequest*/ = (function () {
  var v0 = [
        {
          "kind": "LocalArgument",
          "name": "filename",
          "type": "String!",
          "defaultValue": null
        },
        {
          "kind": "LocalArgument",
          "name": "modelName",
          "type": "String!",
          "defaultValue": null
        },
        {
          "kind": "LocalArgument",
          "name": "modelId",
          "type": "ID",
          "defaultValue": null
        },
        {
          "kind": "LocalArgument",
          "name": "fieldName",
          "type": "String!",
          "defaultValue": null
        },
        {
          "kind": "LocalArgument",
          "name": "contentType",
          "type": "String",
          "defaultValue": null
        }
      ],
      v1 = [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fileUploadUrl",
          "args": [
            {
              "kind": "Variable",
              "name": "contentType",
              "variableName": "contentType"
            },
            {
              "kind": "Variable",
              "name": "fieldName",
              "variableName": "fieldName"
            },
            {
              "kind": "Variable",
              "name": "filename",
              "variableName": "filename"
            },
            {
              "kind": "Variable",
              "name": "modelId",
              "variableName": "modelId"
            },
            {
              "kind": "Variable",
              "name": "modelName",
              "variableName": "modelName"
            }
          ],
          "storageKey": null
        }
      ];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "generateUploadUrlQuery",
      "type": "Query",
      "metadata": null,
      "argumentDefinitions": (v0/*: any*/),
      "selections": (v1/*: any*/)
    },
    "operation": {
      "kind": "Operation",
      "name": "generateUploadUrlQuery",
      "argumentDefinitions": (v0/*: any*/),
      "selections": (v1/*: any*/)
    },
    "params": {
      "operationKind": "query",
      "name": "generateUploadUrlQuery",
      "id": null,
      "text": "query generateUploadUrlQuery(\n  $filename: String!\n  $modelName: String!\n  $modelId: ID\n  $fieldName: String!\n  $contentType: String\n) {\n  fileUploadUrl(filename: $filename, modelName: $modelName, modelId: $modelId, fieldName: $fieldName, contentType: $contentType)\n}\n",
      "metadata": {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '42db730e1b0a6e8d7f249cf11a00a94e';
module.exports = node;

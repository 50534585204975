import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation UpdateOrganizationSubscriptionMutation($input: UpdateOrganizationSubscriptionInput!) {
    updateOrganizationSubscription(input: $input) {
      isUpdated
      organization {
        subscription {
          tierName
          interval
          nextBillingDate
          numberOfModules
          nextBillingAmount
          numberOfActiveUsers
          numberOfTotalUsers
          numberOfDeliverables
          numberOfContractors
          numberOfContractTemplates
        }
        activeStorageLimit
        archiveStorageLimit
        activeSubscription {
          availableModules {
            moduleId
            name
            incremental
            quantity
            cost {
              tier
              price
            }
            blurbText
            headline
            externalUrl
            description
            imageUrl
          }
        }
        scheduledSubscription {
          activeModulesCount
          interval
          availableModules {
            moduleId
            quantity
          }
        }
      }
    }
  }
`;

const UpdateOrganizationSubscriptionMutation = (input, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input },
    onCompleted: response => {
      callback(response.updateOrganizationSubscription);
    },
    onError: err => console.error(err)
  });
};

export default UpdateOrganizationSubscriptionMutation;

UpdateOrganizationSubscriptionMutation.propTypes = {
  tierName: PropTypes.oneOf(['free', 'entrepreneur', 'business', 'enterprise']).isRequired,
  interval: PropTypes.oneOf(['monthly', 'annual']).isRequired
};

import React from 'react';

import FormikDropdownField from '../../../../shared/components/form/FormikDropdownField';

const CurrencyCodeDropdown = props => {
  const items = [
    'AUD',
    'BGN',
    'BRL',
    'CAD',
    'CHF',
    'CNY',
    'CZK',
    'DKK',
    'EUR',
    'GBP',
    'HKD',
    'HRK',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'ISK',
    'JPY',
    'KRW',
    'MXN',
    'MYR',
    'NOK',
    'NZD',
    'PLN',
    'PHP',
    'RON',
    'RUB',
    'SEK',
    'SGD',
    'THB',
    'TRY',
    'USD',
    'ZAR'
  ];

  return <FormikDropdownField {...props} items={items.map(item => ({ id: item, title: item }))} />;
};

export default CurrencyCodeDropdown;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';
import PropTypes from 'prop-types';

const mutation = graphql`
  mutation CreateJobMutation($input: CreateJobInput!) {
    createJob(input: $input) {
      newJob {
        id
        deliverables {
          edges {
            node {
              state
              assignedContractor {
                id
                fullName
                representativeImageUrl
              }
            }
          }
        }
      }
      emailSent
      closedSolicitations {
        id
        subject
      }
    }
  }
`;

const CreateJobMutation = (variables, uploadables, callback) => {
  const createJobVariables = {
    input: variables
  };

  commitMutation(getEnvironment(), {
    mutation,
    variables: createJobVariables,
    uploadables: uploadables,
    onCompleted: response => {
      callback(response.createJob);
    },
    onError: err => console.error(err)
  });
};

export default CreateJobMutation;

CreateJobMutation.propTypes = {
  variables: PropTypes.shape({
    contractor: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    contracTemplate: PropTypes.string,
    deadline: PropTypes.string,
    contractSignDeadline: PropTypes.string,
    totalAmount: PropTypes.number,
    deliverables: PropTypes.arrayOf(PropTypes.string).isRequired,
    withContract: PropTypes.bool,
    contractDate: PropTypes.string
  }),
  uploadables: PropTypes.shape({ organization_signature: PropTypes.file }),
  callback: PropTypes.func.isRequired
};

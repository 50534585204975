import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

const DeleteReferenceMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation DeleteReferenceMutation($input: DeleteReferenceInput!) {
          deleteReference(input: $input) {
            isDeleted
          }
        }
      `,
      variables: {
        input
      },
      onCompleted: (res, errors) => {
        resolve(res.deleteReference, errors);
      },
      onError: reject
    });
  });

export default DeleteReferenceMutation;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import DeliverableFilesList from './DeliverableFilesList';

const DeliverableFilesTab = props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableFilesTabQuery($id: ID!) {
          deliverable(id: $id) {
            ...DeliverableFilesList_deliverable
          }
        }
      `}
      variables={{ id }}
      render={relayProps => (
        <DeliverableFilesList deliverable={relayProps.deliverable} {...props} />
      )}
    />
  );
};

export default DeliverableFilesTab;

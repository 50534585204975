import React, { Fragment, useMemo } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import SearchableDeliverableDropdown from './SearchableDeliverableDropdown';
import { CELL_TYPES, DELIVERABLE_STATES, ALLOWED_ACTIONS } from '../../../shared/constants';
import AutoTable from '../../../shared/components/table/AutoTable';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import colors from '../../../shared/styles/common/colors';
import StyledTableCell from '../../../shared/components/table/StyledTableCell';
import { Field, Formik } from 'formik';
import CustomTableTooltip from '../../../shared/components/table/CustomTableTooltip';
import TableCellAvatarWithText from '../../../shared/components/table/TableCellAvatarWithText';
import FormikSearchableDropdown from '../../../shared/components/form/FormikSearchableDropdown';
import { FormikAmountWithCurrencyField } from '../form/AmountWithCurrencyField';
import TableCellAmount from '../../../shared/components/table/TableCellAmount';
import TableCellAssignable from '../../../shared/components/table/TableCellAssignable';
import { FormikCheckbox } from '../../../shared/components/form/FormikCheckbox';
import UpdateDeliverableMutation from '../deliverables/mutations/UpdateDeliverableMutation';
import {
  computePermissionRole,
  getCurrencyCodeFromUserContext
} from '../../../shared/utils/helpers';
import SquareButton from '../../../shared/components/UI/SquareButton';
import GlobalButton from '../../../shared/components/UI/GlobalButton';

const ERROR_COLOR_PROP = colors.red;
const TOOLTIPS_COPY = {
  category: 'All Deliverables must share a category.',
  amount: 'All deliverables must share a currency.',
  assignedStaff: 'All Deliverables must be assigned to the staff member creating the Job.',
  state: 'All deliverables must be assignable.',
  budget:
    'Because of currency rate changes, creating job with selected deliverables would exceed one or more release budgets.',
  noPermission: 'You do not have a right permission to edit this field.',
  noPermissions: 'You do not have right permissions to edit any of the fields.'
};

const CreateJobFormDeliverablesSection = props => {
  const {
    deliverables,
    arrayHelpers,
    setFieldValue,
    resetDeliverablesValidity,
    isFormValid,
    deliverablesValidity,
    editingDeliverable,
    setEditingDeliverable,
    HELPERS,
    userContext: {
      orgStaff: { id: currentStaffId, allowedActions }
    },
    isSubmitting
  } = props;

  const removeDeliverableClickHandler = removedDeliverableId => {
    resetDeliverablesValidity(deliverables.filter(deliv => deliv.id !== removedDeliverableId));
    arrayHelpers.remove(HELPERS.findDeliverableIndexById(deliverables, removedDeliverableId));
  };

  const checkIfBelongsToMajority = (allValues, checkValue) => {
    if (allValues.length === 0) return null;
    var modeMap = {};
    var maxEl = new Set(),
      maxCount = 1;
    maxEl = maxEl.add(allValues[0]);
    for (var i = 0; i < allValues.length; i++) {
      var el = allValues[i];
      if (modeMap[el] == null) modeMap[el] = 1;
      else modeMap[el]++;
      if (modeMap[el] === maxCount) {
        maxEl = maxEl.add(el);
      }
      if (modeMap[el] > maxCount) {
        maxEl.clear();
        maxEl = maxEl.add(el);
        maxCount = modeMap[el];
      }
    }
    if (maxEl.size !== 1) {
      return false;
    }
    if (maxEl.has(checkValue)) {
      return true;
    }

    return false;
  };

  const checkIfValid = (fieldName, fieldValue) => {
    const isValid = deliverablesValidity[fieldName] !== false;
    if (!isValid) {
      //   in some cases we want to mark invalid only non majority selections
      //  for example, user selected Artwork twice, and Video once, then we mark as an error only Video
      // if user selected Artwork once, Video once, then we mark both as errors
      switch (fieldName) {
        case 'category':
          return checkIfBelongsToMajority(
            deliverables.map(deliverable => deliverable.category.id),
            fieldValue.id
          );
        case 'assignedStaff':
          return fieldValue ? currentStaffId === fieldValue.id : false;
        case 'amount':
          return checkIfBelongsToMajority(
            deliverables.map(deliverable => deliverable.currencyCode),
            fieldValue.currencyCode
          );
        case 'state':
          return fieldValue.isAssignable;

        default:
          return isValid;
      }
    }
    return isValid;
  };

  const initialDeliverableValues = useMemo(
    () => ({
      deliverable: deliverables.map(
        ({ id, category, assignedStaff, amount, currencyCode, state }) => ({
          id,
          category: { value: category.id, label: category.name },
          assignedStaff: assignedStaff
            ? { value: assignedStaff.id, label: assignedStaff.fullName }
            : null,
          amount: { amount: amount || '', currencyCode },
          state: state === DELIVERABLE_STATES.ready || state === DELIVERABLE_STATES.locked
        })
      )
    }),
    [deliverables]
  );

  const validationSchema = Yup.object().shape({
    deliverable: Yup.array().of(
      Yup.object().shape({
        amount: Yup.object().shape({
          amount: Yup.number()
            .transform(cv => (isNaN(cv) ? undefined : cv))
            .min(0, 'Amount must be zero or more.')
        })
      })
    )
  });

  const isEditingDeliverable = index => {
    if (!editingDeliverable) {
      return false;
    }
    return HELPERS.findDeliverableIndexById(deliverables, editingDeliverable.id) === index;
  };

  const renderEditCell = ({ index, rowIndex, value }) => {
    if (isEditingDeliverable(rowIndex)) {
      return (
        <StyledTableCell key={index} noWrap align="center">
          <GlobalButton
            id={`saveRow-${rowIndex}`}
            variant="transparent"
            type="button"
            handleClick={value.submitForm}
            noMargin
          >
            Save
          </GlobalButton>
        </StyledTableCell>
      );
    } else {
      const fullDeliverable = HELPERS.findDeliverableById(deliverables, value.id);
      const canEditAtLeastOneField =
        canEditDeliverable(fullDeliverable) || canMarkReadyDeliverable(fullDeliverable);

      return (
        <StyledTableCell key={index} noWrap align="center">
          <SquareButton
            id={`editRow-${rowIndex}`}
            icon="edit"
            variant="transparent"
            onClick={() => {
              setEditingDeliverable(fullDeliverable);
              resetDeliverablesValidity(deliverables);
            }}
            disabled={editingDeliverable || isSubmitting || !canEditAtLeastOneField ? true : false}
            tooltipTitle={!canEditAtLeastOneField ? TOOLTIPS_COPY.noPermissions : ''}
          />
        </StyledTableCell>
      );
    }
  };

  const renderCellInputField = (columnName, rowIndex) => {
    const fieldName = `deliverable.${rowIndex}.${columnName}`;

    switch (columnName) {
      case 'category':
        return (
          <Field
            required
            noClear
            name={fieldName}
            component={FormikSearchableDropdown}
            dropdownName="deliverableCategories"
            fullWidth
            noLabel
            maxMenuHeight={rowIndex <= 1 ? 175 : 125}
          />
        );

      case 'assignedStaff':
        return (
          <Field
            name={fieldName}
            component={FormikSearchableDropdown}
            dropdownName="assignedStaff"
            fullWidth
            noLabel
            maxMenuHeight={rowIndex <= 1 ? 175 : 125}
          />
        );
      case 'amount':
        return (
          <Field
            name={fieldName}
            component={FormikAmountWithCurrencyField}
            fullWidth
            label=""
            placeholder="Amount"
            maxMenuHeight={rowIndex <= 1 ? 175 : 125}
          />
        );
      case 'state':
        return (
          <Field
            name={fieldName}
            component={FormikCheckbox}
            checkedcolorprop={colors.green}
            label=""
            labelPlacement="start"
          />
        );
      default:
        break;
    }
  };
  const renderCellTextField = (
    index,
    columnName,
    value,
    isFieldValid,
    isBudgetValid,
    showPermissionTooltip
  ) => {
    const colorProp = !isFieldValid && ERROR_COLOR_PROP;
    const tooltipTitleNoPermission = showPermissionTooltip ? TOOLTIPS_COPY.noPermission : '';
    const tooltipTitle = !isFieldValid ? TOOLTIPS_COPY[columnName] : '';
    switch (columnName) {
      case 'category':
        return (
          <StyledTableCell key={index} colorProp={colorProp} truncate>
            <CustomTableTooltip title={tooltipTitleNoPermission || tooltipTitle}>
              <span>{value.cellText}</span>
            </CustomTableTooltip>
          </StyledTableCell>
        );
      case 'assignedStaff':
        return value ? (
          <TableCellAvatarWithText
            key={index}
            id={value.id}
            cellText={value.name}
            avatarSrc={value.imageUrl}
            colorProp={colorProp}
            customTooltipText={tooltipTitle}
          />
        ) : (
          <StyledTableCell key={index} colorProp={colorProp}>
            <CustomTableTooltip title={tooltipTitleNoPermission || tooltipTitle}>
              <span>-</span>
            </CustomTableTooltip>
          </StyledTableCell>
        );
      case 'amount':
        return (
          <TableCellAmount
            key={index}
            currencyCode={value.currencyCode}
            amount={value.amount}
            colorProp={(!isFieldValid || !isBudgetValid) && ERROR_COLOR_PROP}
            customTooltipText={
              tooltipTitleNoPermission ||
              (!isFieldValid ? TOOLTIPS_COPY.amount : !isBudgetValid ? TOOLTIPS_COPY.budget : '')
            }
            subscriptAmount={value.subscriptAmount}
          />
        );
      case 'state':
        return (
          <TableCellAssignable
            key={index}
            isAssignable={value.isAssignable}
            customTooltipText={
              tooltipTitleNoPermission || (!isFieldValid ? TOOLTIPS_COPY.state : '')
            }
          />
        );
      default:
        break;
    }
  };

  const renderCell = ({ index, rowIndex, value, columnName }) => {
    const isInEditMode = isEditingDeliverable(rowIndex);
    const canEditCell =
      isInEditMode &&
      ((canEditDeliverable(editingDeliverable) && columnName !== 'state') ||
        (canMarkReadyDeliverable(editingDeliverable) && columnName === 'state'));
    if (canEditCell) {
      return (
        <StyledTableCell key={index} alignTop={columnName !== 'state'}>
          {renderCellInputField(columnName, rowIndex)}
        </StyledTableCell>
      );
    } else {
      const isFieldValid = checkIfValid(columnName, value);
      const isBudgetValid = checkIfValid('budget');

      return renderCellTextField(
        index,
        columnName,
        value,
        isFieldValid,
        isBudgetValid,
        isInEditMode && !canEditCell
      );
    }
  };

  const handleSaveSubmit = (submittingValues, { setFieldError: setDeliverableError }) => {
    const editingDeliverableNewValue = HELPERS.findDeliverableById(
      submittingValues.deliverable,
      editingDeliverable.id
    );

    const { id, category, assignedStaff, amount, state } = editingDeliverableNewValue;
    const formatVariablesIfCanEdit = canEditDeliverable(editingDeliverable)
      ? {
          category: category.value,
          assignedStaff: assignedStaff ? assignedStaff.value : null,
          amount: amount.amount || amount.amount === 0 ? amount.amount : null,
          currencyCode: amount.currencyCode
        }
      : {};
    const formatVariablesIfCanMarkReady = canMarkReadyDeliverable(editingDeliverable)
      ? {
          markReady: state,
          markDraft: !state
        }
      : {};
    if (isEmpty(formatVariablesIfCanMarkReady) && isEmpty(formatVariablesIfCanEdit)) {
      // both vars are empty, no need to submit anything, user has no permissions
      setEditingDeliverable(null);
      return;
    }
    UpdateDeliverableMutation(
      {
        id,
        ...formatVariablesIfCanEdit,
        ...formatVariablesIfCanMarkReady
      },
      (response, errors) => {
        if (response && response.updatedDeliverable) {
          const {
            updatedDeliverable: {
              id: updatedId,
              category: updatedCategory,
              assignedStaff: updatedStaff,
              amount: updatedAmount,
              currencyCode: updatedCurrencyCode,
              state: updatedState,
              amountInHomeCurrency: updatedAmountInHomeCurrency
            }
          } = response;
          const updatedDeliverables = deliverables.map(deliverable => {
            if (deliverable.id === updatedId) {
              return {
                ...deliverable,
                category: updatedCategory,
                assignedStaff: updatedStaff,
                amount: updatedAmount,
                currencyCode: updatedCurrencyCode,
                amountInHomeCurrency: updatedAmountInHomeCurrency,
                state: updatedState
              };
            } else {
              return deliverable;
            }
          });
          setFieldValue('deliverables', updatedDeliverables, false);
          setEditingDeliverable(null);
        } else {
          if (errors && errors[0].fields) {
            const editingDeliverableIndex = HELPERS.findDeliverableIndexById(
              submittingValues.deliverable,
              editingDeliverable.id
            );
            // this mostly will take care of backend budget related errors
            setDeliverableError(
              `deliverable.${editingDeliverableIndex}.amount`,
              errors[0].fields.amount
            );
            setDeliverableError(
              `deliverable.${editingDeliverableIndex}.category`,
              errors[0].fields.category
            );
          }
        }
      }
    );
  };

  const canEditDeliverable = deliverable =>
    (deliverable.assignedStaff &&
      deliverable.assignedStaff.id === currentStaffId &&
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER,
        allowedActions
      )) ||
    computePermissionRole(
      ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER,
      allowedActions
    );

  const canMarkReadyDeliverable = deliverable =>
    (deliverable.assignedStaff &&
      deliverable.assignedStaff.id === currentStaffId &&
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_MARK_READY_ASSIGNED_TO_AUTHENTICATED_USER,
        allowedActions
      )) ||
    computePermissionRole(
      ALLOWED_ACTIONS.DELIVERABLE_MARK_READY_ASSIGNED_TO_ANOTHER_USER,
      allowedActions
    );

  return (
    <Fragment>
      <SearchableDeliverableDropdown
        name="deliverables"
        label="Deliverables"
        onChange={deliverable => {
          if (deliverable) {
            resetDeliverablesValidity([...deliverables, deliverable]);
            arrayHelpers.push(deliverable);
          }
        }}
        disabled={isSubmitting}
        keyToReload={`${deliverables.length}`}
        placeholder="Add deliverables..."
        itemsInList={deliverables}
        fetchVariables={{
          onlyDraftOrReady: true
        }}
        value={null}
        force
      />
      <Formik
        initialValues={initialDeliverableValues}
        enableReinitialize
        onSubmit={handleSaveSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm }) => {
          return (
            <AutoTable
              rowTemplate={[
                {
                  name: 'lock',
                  label: '',
                  renderContent: () => (isFormValid ? <LockIcon /> : null),
                  width: '3%'
                },
                { name: 'name', label: 'Deliverable Name', type: '', width: '16%', truncate: true },
                {
                  name: 'category',
                  label: 'Category',
                  width: '16%',
                  renderCell
                },
                {
                  name: 'assignedStaff',
                  label: 'Coordinator',
                  width: '16%',
                  renderCell
                },
                { name: 'dueDate', label: 'Due Date', type: CELL_TYPES.date, width: '9%' },
                {
                  name: 'amount',
                  label: 'Amount',
                  width: '20%',
                  type: CELL_TYPES.amount,
                  renderCell
                },
                {
                  name: 'state',
                  label: 'Assignable',
                  width: '8%',
                  renderCell,
                  align: 'center'
                },
                {
                  name: 'edit',
                  label: '',
                  renderCell: renderEditCell,
                  width: '8%'
                },
                {
                  name: 'remove',
                  label: '',
                  type: CELL_TYPES.trash,
                  onClick: removeDeliverableClickHandler,
                  width: '4%',
                  disabled: editingDeliverable || isSubmitting ? true : false
                }
              ]}
              edges={
                deliverables
                  ? deliverables.map(
                      ({
                        id,
                        title,
                        category,
                        assignedStaff,
                        amount,
                        dueDate,
                        currencyCode,
                        state,
                        amountInHomeCurrency
                      }) => ({
                        node: {
                          id,
                          name: title,
                          category: {
                            id: category.id,
                            cellText: category.name
                          },
                          dueDate: dueDate && typeof dueDate === 'object' ? dueDate.date : dueDate,
                          amount: {
                            amount,
                            currencyCode,
                            subscriptAmount:
                              currencyCode !== getCurrencyCodeFromUserContext(props.userContext)
                                ? amountInHomeCurrency
                                : null
                          },
                          edit: {
                            id,
                            submitForm
                          },
                          state:
                            state === DELIVERABLE_STATES.ready ||
                            (state === DELIVERABLE_STATES.locked && isFormValid)
                              ? { isAssignable: true }
                              : {
                                  isAssignable: false
                                },
                          assignedStaff: assignedStaff
                            ? {
                                id: assignedStaff.id,
                                name: assignedStaff.fullName,
                                imageUrl: assignedStaff.representativeImageUrl
                              }
                            : null
                        }
                      })
                    )
                  : []
              }
              emptyListText="No Deliverables to Display."
            />
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default withUserContext(CreateJobFormDeliverablesSection);

import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  CELL_TYPES,
  TOOLS_DESCRIPTIONS,
  ALLOWED_ACTIONS,
  JOB_STATUSES
} from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  formatAuditLogDescription,
  formatAuditLogAction,
  computePermissionRole
} from '../../../../../shared/utils/helpers';
import ToolActions from '../../../shared/ToolActions';
import ConfirmationDialog from '../../../../../shared/components/common/ConfirmationDialog';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import { successToast, errorToast } from '../../../../../shared/toasts';
import ButtonWithTooltip from '../../../../../shared/components/UI/ButtonWithTooltip';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import CancelJobMutation from '../../mutations/CancelJobMutation';
import RevokeJobMutation from '../../mutations/RevokeJobMutation';

const JobToolsTab = props => {
  const {
    relay: { refetch },
    job: { id, status, hasCompletedDeliverables, history: historyLog },
    userContext: {
      orgStaff: { allowedActions }
    },
    setRefetchCounterForTools
  } = props;

  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setRefetchCounter(prev => prev + 1);
  }, [props.refetchCounter, props.refetchCounterForTools]);

  if (!historyLog) return <div>Something went wrong!</div>;

  const {
    edges,
    edgeCount,
    totalCount,
    pageInfo: { hasNextPage, endCursor }
  } = historyLog;

  const onChangeHandler = variables => {
    refetch({ id, ...variables });
  };

  const formattedEdges = edges.map(edge => {
    const {
      id,
      timestamp: date,
      action,
      changes,
      additionalData,
      actor: { orgStaff, freelancer }
    } = edge.node;
    const name = orgStaff ? orgStaff.fullName : freelancer ? freelancer.fullName : '';
    const imageUrl = orgStaff
      ? orgStaff.representativeImageUrl
      : freelancer
      ? freelancer.representativeImageUrl
      : '';
    return {
      node: {
        id,
        date,
        who: { name, imageUrl },
        action: formatAuditLogAction(action, additionalData),
        changes: formatAuditLogDescription(action, changes, additionalData)
      }
    };
  });

  const handleRevokeJob = () => {
    setSubmitting(true);
    RevokeJobMutation(id, response => {
      setSubmitting(false);
      if (response && response.isRevoked) {
        setRefetchCounterForTools(prev => prev + 1);
        successToast('Contract was revoked.');
      } else {
        errorToast('Something went wrong.');
      }
    });
  };
  const handleCancelJob = () => {
    setSubmitting(true);
    CancelJobMutation(id, response => {
      setSubmitting(false);
      if (response && response.isCanceled) {
        setRefetchCounterForTools(prev => prev + 1);
        successToast('Job was canceled.');
      } else {
        errorToast('Something went wrong.');
      }
    });
  };

  const canRevokeJob =
    status === JOB_STATUSES.awaiting_signature &&
    computePermissionRole(
      ALLOWED_ACTIONS.JOB_REVOKE_CONTRACT_IF_UNSIGNED_BY_FREELANCER,
      allowedActions
    );

  const canCancelJob =
    status === JOB_STATUSES.in_progress &&
    computePermissionRole(ALLOWED_ACTIONS.JOB_TERMINATE_SIGNED_CONTRACT, allowedActions);

  const renderCancelJobButton = () => {
    if (hasCompletedDeliverables) {
      return (
        <ButtonWithTooltip
          disabled
          fullWidth
          noMargin
          variant="primary"
          buttonText="Cancel Job"
          tooltipText="You cannot cancel this job because some of its deliverables are already completed."
        />
      );
    } else {
      return (
        <ConfirmationDialog
          content="This action is irreversible. The contract and all existing Deliverables on this job will be canceled. Copies of the Deliverables will be created for reassignment. Are you sure you want to proceed?"
          copies={{
            cancelCopy: 'Back',
            confirmCopy: 'Cancel Job'
          }}
        >
          {confirm => (
            <GlobalButton
              noMargin
              disabled={isSubmitting}
              fullWidth
              handleClick={confirm(handleCancelJob)}
            >
              Cancel Job
            </GlobalButton>
          )}
        </ConfirmationDialog>
      );
    }
  };

  const actionList = [
    {
      condition: canRevokeJob,
      actionRender: () => (
        <ConfirmationDialog content="This action is irreversible. All deliverables of this job will be returned to Assignable state.">
          {confirm => (
            <GlobalButton fullWidth handleClick={confirm(handleRevokeJob)} noMargin>
              Revoke Contract
            </GlobalButton>
          )}
        </ConfirmationDialog>
      ),
      description: TOOLS_DESCRIPTIONS.revokeContract
    },
    {
      condition: canCancelJob,
      actionRender: () => renderCancelJobButton(),
      description: TOOLS_DESCRIPTIONS.cancelJob
    }
  ];

  return (
    <Fragment>
      <ToolActions actions={actionList} />
      <Typography variant="h3">Audit Log</Typography>
      <AutoTable
        rowTemplate={[
          { name: 'action', label: 'Action' },
          { name: 'changes', label: 'Changes', width: '50%' },
          { name: 'date', label: 'Date', type: CELL_TYPES.date },
          { name: 'who', label: 'Who', type: CELL_TYPES.avatar }
        ]}
        edges={formattedEdges}
        refetchCounter={refetchCounter}
        onChangeHandler={onChangeHandler}
        paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
      />
    </Fragment>
  );
};

export default withUserContext(
  createRefetchContainer(
    JobToolsTab,
    {
      job: graphql`
        fragment JobToolsTab_job on JobNode
          @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
          id
          status
          hasCompletedDeliverables
          history(first: $first, after: $after) {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                timestamp
                actor {
                  orgStaff {
                    fullName
                    representativeImageUrl
                  }
                  freelancer {
                    fullName
                    representativeImageUrl
                  }
                }
                action
                changes
                additionalData
              }
            }
          }
        }
      `
    },
    graphql`
      query JobToolsTabRefetchQuery($id: ID!, $first: Int, $after: String) {
        job(id: $id) {
          ...JobToolsTab_job @arguments(first: $first, after: $after)
        }
      }
    `
  )
);

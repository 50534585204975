import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useBillingStyles } from '../../../../../shared/styles/admin/useBillingStyles';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import { StyledTable } from '../../../../../shared/components/table/StyledTable';
import CustomTableTooltip from '../../../../../shared/components/table/CustomTableTooltip';
import {
  ToggleButtonsGroup,
  ToggleButton,
  PlanSelectionCell,
  PlanHeaderCell,
  StyledPlanInfoRow,
  StyledPlanInfoCell
} from './styled';
import { SUBSCRIPTION_TIERS_INFO, formatCellContent } from './helpers';

const AvailablePlans = props => {
  const {
    displayMonthly,
    onBillingCycleChange,
    onTierSelect,
    activeTierName,
    subscriptionTiers,
    getTierActionLabel
  } = props;
  const classes = useBillingStyles();

  const isCurrentTier = name => name === activeTierName;

  return (
    <StyledTable size="small">
      <TableHead>
        <TableRow>
          <PlanSelectionCell>
            <ToggleButtonsGroup>
              <ToggleButton active={!displayMonthly} onClick={() => onBillingCycleChange(false)}>
                Annual
              </ToggleButton>
              <ToggleButton active={displayMonthly} onClick={() => onBillingCycleChange(true)}>
                Monthly
              </ToggleButton>
            </ToggleButtonsGroup>
            <Typography variant="h1">User Fee</Typography>
            <Typography>Per Month, Billed {displayMonthly ? 'Monthly' : 'Annually'}</Typography>
          </PlanSelectionCell>
          {subscriptionTiers.map(tier => (
            <PlanHeaderCell
              key={tier.name}
              name={tier.name}
              price={displayMonthly ? tier.monthlyPrice || '0' : tier.annualPrice || '0'}
              isCurrent={isCurrentTier(tier.name)}
              align="center"
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {SUBSCRIPTION_TIERS_INFO.map((row, i) => (
          <StyledPlanInfoRow key={row.name}>
            <TableCell component="th" scope="row" align="left">
              {row.label}
              <CustomTableTooltip title={row.tooltip}>
                <HelpIcon className={classes.helpIcon} />
              </CustomTableTooltip>
            </TableCell>
            {subscriptionTiers.map(tier => (
              <StyledPlanInfoCell key={tier.name} isCurrent={isCurrentTier(tier.name)} index={i}>
                {formatCellContent(row, tier[row.name])}
              </StyledPlanInfoCell>
            ))}
          </StyledPlanInfoRow>
        ))}
      </TableBody>
      {getTierActionLabel && onTierSelect && (
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            {subscriptionTiers.map((tier, i) => {
              const label = getTierActionLabel(tier, i);

              return (
                <StyledPlanInfoCell key={tier.name} isFooter>
                  {label && (
                    <GlobalButton handleClick={() => onTierSelect(tier)}>{label}</GlobalButton>
                  )}
                </StyledPlanInfoCell>
              );
            })}
          </TableRow>
        </TableFooter>
      )}
    </StyledTable>
  );
};

AvailablePlans.propTypes = {
  displayMonthly: PropTypes.bool.isRequired,
  onBillingCycleChange: PropTypes.func.isRequired,
  onTierSelect: PropTypes.func,
  activeTierName: PropTypes.string,
  subscriptionTiers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      monthlyPrice: PropTypes.string,
      annualPrice: PropTypes.string
    })
  ).isRequired,
  getTierActionLabel: PropTypes.func
};

export default AvailablePlans;

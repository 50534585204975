import React, { Fragment, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {
  formatAuditLogDescription,
  formatAuditLogAction
} from '../../../../../shared/utils/helpers';
import { CELL_TYPES } from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';

import useUpdateEffect from '../../../../../shared/hooks/useUpdateEffect';

const ReleaseActionLogList = props => {
  const {
    relay: { refetch },
    release: { id, history: historyLog }
  } = props;

  const [refetchCounter, setRefetchCounter] = useState(0);

  useUpdateEffect(() => {
    setRefetchCounter(prev => prev + 1);
  }, [props.refetchCounter, props.refetchCounterForTools]);

  if (!historyLog) return <div>Something went wrong!</div>;

  const {
    edges,
    edgeCount,
    totalCount,
    pageInfo: { hasNextPage, endCursor }
  } = historyLog;

  const onChangeHandler = variables => {
    refetch({ id, ...variables });
  };

  const formattedEdges = edges.map(edge => {
    const {
      id,
      timestamp: date,
      actor: {
        orgStaff: { fullName: name, representativeImageUrl: imageUrl }
      },
      action,
      changes,
      additionalData
    } = edge.node;
    return {
      node: {
        id,
        date,
        who: { name, imageUrl },
        action: formatAuditLogAction(action, additionalData),
        changes: formatAuditLogDescription(action, changes, additionalData)
      }
    };
  });

  return (
    <Fragment>
      <Typography variant="h3">Audit Log</Typography>
      <AutoTable
        rowTemplate={[
          { name: 'action', label: 'Action' },
          { name: 'changes', label: 'Changes', width: '50%' },
          { name: 'date', label: 'Date', type: CELL_TYPES.date },
          { name: 'who', label: 'User', type: CELL_TYPES.avatar },
        ]}
        edges={formattedEdges}
        refetchCounter={refetchCounter}
        onChangeHandler={onChangeHandler}
        paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
      />
    </Fragment>
  );
};

export default createRefetchContainer(
  ReleaseActionLogList,
  {
    release: graphql`
      fragment ReleaseActionLogList_release on ReleaseNode {
        id
        history(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
          edges {
            node {
              id
              timestamp
              actor {
                orgStaff {
                  fullName
                  representativeImageUrl
                }
              }
              action
              changes
              additionalData
            }
          }
        }
      }
    `
  },
  graphql`
    query ReleaseActionLogListRefetchQuery($id: ID!, $first: Int, $after: String) {
      release(id: $id) {
        ...ReleaseActionLogList_release
      }
    }
  `
);

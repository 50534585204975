/**
 * @flow
 * @relayHash 91740eeb02d311e5e70407589e4ff793
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ReviewAssetsList_files$ref = any;
export type ReviewAssetsListRefetchQueryVariables = {|
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
  storageClass?: ?string,
  deliverableState?: ?string,
  releaseId?: ?string,
  deliverableTitle?: ?string,
  fileName?: ?string,
|};
export type ReviewAssetsListRefetchQueryResponse = {|
  +$fragmentRefs: ReviewAssetsList_files$ref
|};
export type ReviewAssetsListRefetchQuery = {|
  variables: ReviewAssetsListRefetchQueryVariables,
  response: ReviewAssetsListRefetchQueryResponse,
|};
*/


/*
query ReviewAssetsListRefetchQuery(
  $first: Int
  $after: String
  $orderBy: String
  $storageClass: String
  $deliverableState: String
  $releaseId: String
  $deliverableTitle: String
  $fileName: String
) {
  ...ReviewAssetsList_files
}

fragment ReviewAssetsList_files on Query {
  reviewAssetsByOrg(first: $first, after: $after, orderBy: $orderBy, storageClass: $storageClass, deliverableState: $deliverableState, releaseId: $releaseId, deliverableTitle: $deliverableTitle, fileName: $fileName) {
    edges {
      node {
        id
        fileName
        fileSize
        storageClass
        hasComments
        deliverable {
          title
          state
          release {
            name
            id
          }
          id
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "storageClass",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "deliverableState",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "releaseId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "deliverableTitle",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "fileName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReviewAssetsListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ReviewAssetsList_files",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReviewAssetsListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewAssetsByOrg",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "deliverableState",
            "variableName": "deliverableState"
          },
          {
            "kind": "Variable",
            "name": "deliverableTitle",
            "variableName": "deliverableTitle"
          },
          {
            "kind": "Variable",
            "name": "fileName",
            "variableName": "fileName"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          },
          {
            "kind": "Variable",
            "name": "releaseId",
            "variableName": "releaseId"
          },
          {
            "kind": "Variable",
            "name": "storageClass",
            "variableName": "storageClass"
          }
        ],
        "concreteType": "ReviewAssetNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ReviewAssetNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ReviewAssetNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileSize",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "storageClass",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasComments",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "deliverable",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ]
                      },
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ReviewAssetsListRefetchQuery",
    "id": null,
    "text": "query ReviewAssetsListRefetchQuery(\n  $first: Int\n  $after: String\n  $orderBy: String\n  $storageClass: String\n  $deliverableState: String\n  $releaseId: String\n  $deliverableTitle: String\n  $fileName: String\n) {\n  ...ReviewAssetsList_files\n}\n\nfragment ReviewAssetsList_files on Query {\n  reviewAssetsByOrg(first: $first, after: $after, orderBy: $orderBy, storageClass: $storageClass, deliverableState: $deliverableState, releaseId: $releaseId, deliverableTitle: $deliverableTitle, fileName: $fileName) {\n    edges {\n      node {\n        id\n        fileName\n        fileSize\n        storageClass\n        hasComments\n        deliverable {\n          title\n          state\n          release {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a25e90a3873f5a902df995fe01ca4bc7';
module.exports = node;

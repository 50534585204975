import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import SolicitationDetails from './SolicitationDetails';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import { useParams } from 'react-router';

const SolicitationDetailsPage = props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query SolicitationDetailsPageQuery($id: ID!) {
          solicitation(id: $id) {
            ...SolicitationDetails_solicitation
          }
          __type(name: "SolicitationState") {
            ...SolicitationDetails_stateTypes
          }
        }
      `}
      variables={{
        id
      }}
      render={relayProps => (
        <SolicitationDetails
          stateTypes={relayProps.__type}
          solicitation={relayProps.solicitation}
          {...props}
        />
      )}
    />
  );
};

export default SolicitationDetailsPage;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const contractorQuery = graphql`
  query SearchableContractorDropdownQuery($contractorName: String) {
    contractorsByOrg(
      contractorName: $contractorName
      orderBy: "fullName"
      first: 10
      excludeWithConflicts: true
      includeInactive: false
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

const SearchableContractorDropdown = props => {
  const { onChange, ...otherProps } = props;

  const getFetchVariables = inputValue => {
    return {
      contractorName: inputValue
    };
  };

  const formatOptions = contractors => {
    if (!contractors || !contractors.contractorsByOrg) {
      return [];
    }

    return [
      ...(props.id === 'filterByContractor' ? [{ value: 'unassigned', label: 'Unassigned' }] : []), // only show Unassigned option on Deliverables filters
      ...contractors.contractorsByOrg.edges.map(contractor => {
        return { value: contractor.node.id, label: contractor.node.fullName };
      })
    ];
  };

  return (
    <SearchableDropdown
      resultKey="contractorsByOrg"
      query={contractorQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      placeholder="Select Contractor..."
      label="Contractor"
      value={props.value}
      {...otherProps}
    />
  );
};

export default SearchableContractorDropdown;

/**
 * @flow
 * @relayHash ee81de96bb7fffce06d67f6c5499b9ad
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StorageClassEnum = "DEEP_ARCHIVE" | "GLACIER" | "RESTORING" | "STANDARD" | "%future added value";
export type CreateReviewAssetInput = {|
  deliverable: string,
  stagedPath?: ?string,
  reviewInterface?: ?string,
  fileName?: ?string,
  settings?: ?any,
  clientMutationId?: ?string,
|};
export type CreateReviewAssetMutationVariables = {|
  input: CreateReviewAssetInput
|};
export type CreateReviewAssetMutationResponse = {|
  +createReviewAsset: ?{|
    +deliverableReview: ?{|
      +id: string,
      +deliverable: {|
        +id: string,
        +reviewStep: ?string,
        +reviewActive: ?{|
          +id: string,
          +step: {|
            +id: string,
            +name: string,
          |},
          +reviewStepList: ?$ReadOnlyArray<?{|
            +id: string,
            +name: string,
          |}>,
        |},
      |},
      +deliverableReviewAssets: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +fileThumbnailUrl: ?string,
            +fileName: string,
            +created: any,
            +revisionNumber: ?number,
            +reviewInterface: ?string,
            +storageClass: ?StorageClassEnum,
            +comments: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +created: any,
                  +content: string,
                  +extraData: ?any,
                  +objectId: number,
                  +contentEditedAt: ?any,
                  +user: ?{|
                    +id: string,
                    +fullName: ?string,
                    +representativeImageUrl: ?string,
                  |},
                |}
              |}>
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type CreateReviewAssetMutation = {|
  variables: CreateReviewAssetMutationVariables,
  response: CreateReviewAssetMutationResponse,
|};
*/


/*
mutation CreateReviewAssetMutation(
  $input: CreateReviewAssetInput!
) {
  createReviewAsset(input: $input) {
    deliverableReview {
      id
      deliverable {
        id
        reviewStep
        reviewActive {
          id
          step {
            id
            name
          }
          reviewStepList {
            id
            name
          }
        }
      }
      deliverableReviewAssets {
        edges {
          node {
            id
            fileThumbnailUrl
            fileName
            created
            revisionNumber
            reviewInterface
            storageClass
            comments {
              edges {
                node {
                  id
                  created
                  content
                  extraData
                  objectId
                  contentEditedAt
                  user {
                    id
                    fullName
                    representativeImageUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateReviewAssetInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createReviewAsset",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateReviewAssetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverableReview",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableReviewNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "reviewStep",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "reviewActive",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableReviewNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "step",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StepNode",
                    "plural": false,
                    "selections": (v2/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "reviewStepList",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StepNode",
                    "plural": true,
                    "selections": (v2/*: any*/)
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverableReviewAssets",
            "storageKey": null,
            "args": null,
            "concreteType": "ReviewAssetNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ReviewAssetNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReviewAssetNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileThumbnailUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileName",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "revisionNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reviewInterface",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "storageClass",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "comments",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CommentNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "content",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "extraData",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "objectId",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "contentEditedAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "user",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "fullName",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "representativeImageUrl",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateReviewAssetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateReviewAssetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateReviewAssetMutation",
    "id": null,
    "text": "mutation CreateReviewAssetMutation(\n  $input: CreateReviewAssetInput!\n) {\n  createReviewAsset(input: $input) {\n    deliverableReview {\n      id\n      deliverable {\n        id\n        reviewStep\n        reviewActive {\n          id\n          step {\n            id\n            name\n          }\n          reviewStepList {\n            id\n            name\n          }\n        }\n      }\n      deliverableReviewAssets {\n        edges {\n          node {\n            id\n            fileThumbnailUrl\n            fileName\n            created\n            revisionNumber\n            reviewInterface\n            storageClass\n            comments {\n              edges {\n                node {\n                  id\n                  created\n                  content\n                  extraData\n                  objectId\n                  contentEditedAt\n                  user {\n                    id\n                    fullName\n                    representativeImageUrl\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fd99c20b1358134012338cd70d32e129';
module.exports = node;

/**
 * @flow
 * @relayHash efc38808ecc955adc717f631831779f2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
export type ReopenCompleteDeliverableInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type ReopenDeliverableMutationVariables = {|
  input: ReopenCompleteDeliverableInput
|};
export type ReopenDeliverableMutationResponse = {|
  +reopenCompleteDeliverable: ?{|
    +updatedDeliverable: ?{|
      +id: string,
      +state: DeliverableState,
      +job: ?{|
        +id: string,
        +status: JobStatus,
        +hasCompletedDeliverables: ?boolean,
      |},
    |}
  |}
|};
export type ReopenDeliverableMutation = {|
  variables: ReopenDeliverableMutationVariables,
  response: ReopenDeliverableMutationResponse,
|};
*/


/*
mutation ReopenDeliverableMutation(
  $input: ReopenCompleteDeliverableInput!
) {
  reopenCompleteDeliverable(input: $input) {
    updatedDeliverable {
      id
      state
      job {
        id
        status
        hasCompletedDeliverables
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReopenCompleteDeliverableInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reopenCompleteDeliverable",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReopenCompleteDeliverablePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedDeliverable",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasCompletedDeliverables",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReopenDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReopenDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReopenDeliverableMutation",
    "id": null,
    "text": "mutation ReopenDeliverableMutation(\n  $input: ReopenCompleteDeliverableInput!\n) {\n  reopenCompleteDeliverable(input: $input) {\n    updatedDeliverable {\n      id\n      state\n      job {\n        id\n        status\n        hasCompletedDeliverables\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3cb0fbcc4a197ca7a976426280b7f049';
module.exports = node;

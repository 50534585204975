import React from 'react';
import { styled } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AreaHighlight } from '@awebase/react-pdf-highlighter';
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';
import CropFreeIcon from '@material-ui/icons/CropFree';
import GlobalButton from '../../UI/GlobalButton';
import SquareButton from '../../UI/SquareButton';

export const PdfContainer = styled(({ isInAreaSelectMode, ...rest }) => <Box {...rest} />)(
  ({ theme, isInAreaSelectMode }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.colors.grey,
    ...(isInAreaSelectMode && { cursor: 'crosshair' }),
    '& .MouseSelection': {
      background: theme.palette.colors.lightOrange
    },
    '& .textLayer ::selection': {
      background: theme.palette.colors.lightOrange
    },
    '& .Highlight .Highlight__part': {
      background: theme.palette.colors.lightOrange
    },
    '& .Highlight.Highlight--scrolledTo .Highlight__part': {
      background: theme.palette.colors.orange
    }
  })
);

// NOTE:
// - onUpdate added by react-pdf-highlger, inore it.
// - mimics the style of GlobalButton which we can't use because context
//   is not available in PdfHighlighter render methods
export const PdfSelectionTip = styled(({ label, onUpdate, ...rest }) => (
  <Box {...rest} children={label} />
))(({ theme }) => ({
  color: theme.palette.common.white,
  background: theme.palette.primary.main,
  borderRadius: '4px',
  fontSize: '12px',
  fontWeight: 'bold',
  height: '34px',
  lineHeight: '34px',
  padding: '0 19px',
  cursor: 'pointer',
  userSelect: 'none',
  '&.disabled': {
    cursor: 'auto'
  }
}));

const SpinnerComponent = props => (
  <Box {...props}>
    <CircularProgress size={36} />
  </Box>
);

export const Spinner = styled(SpinnerComponent)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px'
}));

export const StyledAreaHighlight = styled(AreaHighlight)(({ theme, isScrolledTo }) => ({
  background: isScrolledTo ? theme.palette.colors.orange : theme.palette.colors.lightOrange,
  cursor: 'pointer',
  border: '1px solid #333',
  opacity: 1,
  mixBlendMode: 'multiply'
}));

const ToolbarComponent = ({ onCancel, isAreaSelect, onAreaSelectToggle, disabled, ...rest }) => (
  <Box {...rest}>
    <SquareButton
      handleClick={onCancel}
      tooltipTitle="Discard Annotation"
      icon="close"
      variant="secondary"
    />
    <GlobalButton
      icon={FormatColorTextIcon}
      iconProps={{ style: { fontSize: '16px' } }}
      variant={!isAreaSelect ? 'primary' : 'transparent'}
      onClick={() => onAreaSelectToggle(false)}
      disabled={disabled}
    >
      Text Select
    </GlobalButton>
    <GlobalButton
      icon={CropFreeIcon}
      iconProps={{ style: { fontSize: '16px' } }}
      variant={isAreaSelect ? 'primary' : 'transparent'}
      onClick={() => onAreaSelectToggle(true)}
      disabled={disabled}
    >
      Area Select
    </GlobalButton>
  </Box>
);

export const ReviewToolbar = styled(ToolbarComponent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > span': {
    marginRight: theme.spacing(1)
  },
  '& > button': {
    height: '32px',
    marginRight: theme.spacing(0.5),
    marginLeft: 0
  }
}));

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const BatchRestoreDeliverableFinalFilesMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation BatchRestoreDeliverableFinalFilesMutation(
          $input: RestoreBatchDeliverableFinalFilesInput!
        ) {
          restoreBatchDeliverableFinalFiles(input: $input) {
            isRestoring
          }
        }
      `,
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default BatchRestoreDeliverableFinalFilesMutation;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

/**
 *
 * @param {Object} variables - Variables passed to the mutation
 * @param {string} variables.reviewAsset - ID of the review asset
 * @param {string} variables.annotationData - JSON string of canvas data
 * @param {string} variables.annotationRef - Id of newly created annotation
 * @param {string} variables.content - Content of the comment
 * @param {string} variables.clientMutationId - clientMutationId
 * @param {*} callback - Callback that receives 'response' and 'errors' as arguments
 */

const UpdateDeliverableReviewAnnotationMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateDeliverableReviewAnnotationMutation(
        $input: UpdateDeliverableReviewAnnotationInput!
      ) {
        updateDeliverableReviewAnnotation(input: $input) {
          updatedDeliverableReviewAnnotation {
            annotationData
            reviewAsset {
              comments {
                edges {
                  node {
                    id
                    content
                    contentEditedAt
                    created
                    objectId
                    extraData
                    repliedTo {
                      content
                      created
                      user {
                        fullName
                      }
                      referenceFiles {
                        id
                        title
                        description
                        fileUrl
                        reviewInterface
                      }
                    }
                    user {
                      id
                      fullName
                      representativeImageUrl
                    }
                    referenceFiles {
                      id
                      title
                      description
                      fileUrl
                      reviewInterface
                    }
                    reactions {
                      id
                      definitionId
                      name
                      color
                      number
                      users {
                        id
                        fullName
                        representativeImageUrl
                      }
                    }
                  }
                }
              }
            }
          }
          clientMutationId
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: callback
  });
};

UpdateDeliverableReviewAnnotationMutation.propTypes = {
  variables: PropTypes.shape({
    reviewAsset: PropTypes.string.isRequired,
    annotationData: PropTypes.string.isRequired,
    annotationRef: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    clientMutationId: PropTypes.string
  })
};

export default UpdateDeliverableReviewAnnotationMutation;

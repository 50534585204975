import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const MarkPaymentSentMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation MarkPaymentSentMutation($input: MarkPaymentSentInput!) {
          markPaymentSent(input: $input) {
            paymentRequest {
              id
              status
            }
          }
        }
      `,
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default MarkPaymentSentMutation;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar as MUIAvatar } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { ReactComponent as OrganizationsIcon } from '../../../shared/images/organization_avatar.svg';

import { makeStyles } from '@material-ui/styles';

const useAvatarStyles = makeStyles(theme => ({
  avatar: {
    width: props => `${props.size}px`,
    height: props => `${props.size}px`,
    border: props =>
      props.border
        ? `3px solid ${
            props.border === 'organization'
              ? theme.palette.colors.orange
              : theme.palette.colors.brightViolet
          }`
        : '',
    '& svg': {
      width: props => `${props.size * 0.9}px`,
      height: props => `${props.size * 0.9}px`
    }
  }
}));

export const Avatar = ({ src, alt, size, defaultIcon, border, className }) => {
  const classes = useAvatarStyles({ size: border ? size + 6 : size, border });
  return src ? (
    <MUIAvatar className={clsx(classes.avatar, 'avatar', className)} alt={alt} src={src} />
  ) : (
    <MUIAvatar className={clsx(classes.avatar, 'avatar', className)} alt={alt}>
      {defaultIcon === 'person' && <AccountCircle />}
      {defaultIcon === 'organization' && <OrganizationsIcon />}
    </MUIAvatar>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  /** size of the avatar circle in px, default value is 32px */
  size: PropTypes.number,
  defaultIcon: PropTypes.oneOf(['person', 'organization']),
  // will add a border around avatar icon: orange or purple
  border: PropTypes.oneOf(['organization', 'freelancer']),
  className: PropTypes.string
};

Avatar.defaultProps = {
  size: 32,
  defaultIcon: 'person',
  border: undefined
};

export default Avatar;

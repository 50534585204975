import React, { forwardRef } from 'react';
import { useFrame, useThree, extend } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

const Controls = forwardRef((_props, ref) => {
  const { camera, gl } = useThree();

  useFrame(() => {
    ref.current && ref.current.update();
  });

  return (
    <orbitControls
      ref={ref}
      args={[camera, gl.domElement]}
      enableRotate
      enableDamping
      enableKeys
      enablePan={true}
      screenSpacePanning={true}
      maxDistance={100}
      minDistance={0}
    />
  );
});

export default Controls;

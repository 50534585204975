/**
 * @flow
 * @relayHash f12d8cd7626e3f4323fc156c914cbc96
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateNotificationSettingsInput = {|
  receiveSummaryEmails?: ?boolean,
  interval?: ?string,
  config?: ?any,
  clientMutationId?: ?string,
|};
export type UpdateNotificationSettingsMutationVariables = {|
  input: UpdateNotificationSettingsInput
|};
export type UpdateNotificationSettingsMutationResponse = {|
  +updateNotificationSettings: ?{|
    +currentUser: ?{|
      +freelancer: ?{|
        +id: string,
        +notificationConfig: ?any,
        +receiveSummaryEmails: ?boolean,
        +notificationSummaryInterval: ?string,
      |},
      +orgStaff: ?{|
        +id: string,
        +notificationConfig: ?any,
        +receiveSummaryEmails: ?boolean,
        +notificationSummaryInterval: ?string,
      |},
    |}
  |}
|};
export type UpdateNotificationSettingsMutation = {|
  variables: UpdateNotificationSettingsMutationVariables,
  response: UpdateNotificationSettingsMutationResponse,
|};
*/


/*
mutation UpdateNotificationSettingsMutation(
  $input: UpdateNotificationSettingsInput!
) {
  updateNotificationSettings(input: $input) {
    currentUser {
      freelancer {
        id
        notificationConfig
        receiveSummaryEmails
        notificationSummaryInterval
      }
      orgStaff {
        id
        notificationConfig
        receiveSummaryEmails
        notificationSummaryInterval
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateNotificationSettingsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "notificationConfig",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "receiveSummaryEmails",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "notificationSummaryInterval",
    "args": null,
    "storageKey": null
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "freelancer",
  "storageKey": null,
  "args": null,
  "concreteType": "FreelancerNode",
  "plural": false,
  "selections": (v3/*: any*/)
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "orgStaff",
  "storageKey": null,
  "args": null,
  "concreteType": "StaffNode",
  "plural": false,
  "selections": (v3/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateNotificationSettingsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNotificationSettings",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotificationSettingsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentUser",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateNotificationSettingsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNotificationSettings",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotificationSettingsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentUser",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateNotificationSettingsMutation",
    "id": null,
    "text": "mutation UpdateNotificationSettingsMutation(\n  $input: UpdateNotificationSettingsInput!\n) {\n  updateNotificationSettings(input: $input) {\n    currentUser {\n      freelancer {\n        id\n        notificationConfig\n        receiveSummaryEmails\n        notificationSummaryInterval\n      }\n      orgStaff {\n        id\n        notificationConfig\n        receiveSummaryEmails\n        notificationSummaryInterval\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a3bcb5e4f8b9a9849d4ec12ee956f8c2';
module.exports = node;

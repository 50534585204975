import styled, { css } from 'styled-components';
import SquareButton from '../../../UI/SquareButton';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(SquareButton).attrs(props => ({
  variant: props.active ? 'primary' : 'secondary',
  size: 24
}))`
  && {
    margin-top: 7px;
    margin-bottom: 7px;
    transition: none;

    &:not(:first-child) {
      margin-left: 7px;
    }

    svg {
      width: 14px;
      height: 14px;

      ${props =>
        props.active &&
        css`
          path {
            fill: #fff;
          }
        `};
    }
  }
`;

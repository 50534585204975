import React, { useState, useContext } from 'react';
import { Field, Form } from 'formik';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { Range } from 'quill/core/selection';
import { yupSchemas } from '../../../../validations';
import FormikTextField from '../../../form/FormikTextField';
import ConfirmationDialog from '../../../common/ConfirmationDialogV2';
import QuillContext from '../../QuillContext';
import { LinkBlot } from '../../blots';
import { Button } from './styled';

const ToggleLinkButton = ({ disabled }) => {
  const { quillRef, updateRenderKey } = useContext(QuillContext);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const isLinkActive = disabled ? false : !!quillRef.current.getFormat().link;

  const handleToggleLink = e => {
    e.preventDefault();
    if (disabled) return false;

    const range = quillRef.current.getSelection();

    if (isLinkActive) {
      const [link, offset] = quillRef.current.scroll.descendant(LinkBlot, range.index);
      if (link) {
        const linkRange = new Range(range.index - offset, link.length());
        quillRef.current.formatText(linkRange.index, linkRange.length, 'link', false);
      } else {
        quillRef.current.format('link', false);
        updateRenderKey();
      }
    } else {
      setIsDialogVisible(true);
    }
  };

  return (
    <>
      <ConfirmationDialog
        isDialogOpen={isDialogVisible}
        closeDialog={() => {
          setIsDialogVisible(false);
          quillRef.current.focus();
        }}
        onConfirm={({ values }) => {
          const linkHref = values.url;

          quillRef.current.focus();
          const range = quillRef.current.getSelection();

          if (range.length === 0) {
            quillRef.current.insertText(range.index, linkHref, 'link', linkHref);
            quillRef.current.format('link', false);
            quillRef.current.insertText(range.index + linkHref.length, ' ');
          } else {
            quillRef.current.formatText(range.index, range.length, 'link', linkHref);
          }

          setIsDialogVisible(false);
        }}
        title="Add Link"
        formikProps={{
          initialValues: {
            url: ''
          },
          validationSchema: Yup.object().shape({
            url: yupSchemas.url(true)
          })
        }}
        minWidth={400}
        content={
          <Form>
            <Box mb={1}>Enter the URL you wish to add as a hyperlink:</Box>
            <Field
              fullWidth
              autoFocus
              component={FormikTextField}
              name="url"
              label="URL"
              placeholder="Enter URL..."
            />
          </Form>
        }
        copies={{
          confirm: 'Add Link'
        }}
      />

      <Button
        disabled={disabled}
        icon={isLinkActive ? LinkOffIcon : LinkIcon}
        active={isLinkActive}
        onMouseDown={handleToggleLink}
      />
    </>
  );
};

export default ToggleLinkButton;

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import formStyles from '../../../styles/common/formStyles';

const SelectField = props => {
  const { value, label, onChange, name, options, withNone } = props;
  const selectId = `${name}-select`;
  const classes = formStyles();
  return (
    <FormControl variant="outlined" className={classes.dropdownWrapper}>
      {label && <InputLabel htmlFor={selectId}>{label}</InputLabel>}
      <Select
        name={name}
        value={value}
        onChange={onChange}
        input={<OutlinedInput name={name} id={selectId} classes={{ root: classes.dropdownRoot }} />}
      >
        {withNone && <MenuItem value="">None</MenuItem>}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectField.propTypes = {
  label: PropTypes.node,
  withNone: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      name: PropTypes.node
    })
  ).isRequired
};

export default SelectField;

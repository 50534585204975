import React from 'react';

import StyledTableCell from './StyledTableCell';
import Date from '../common/Date';

const TableCellDate = ({ date, ...otherProps }) => (
  <StyledTableCell align="left" noWrap {...otherProps}>
    <Date date={date} />
  </StyledTableCell>
);

export default TableCellDate;

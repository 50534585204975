import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import NotificationSettings from '../../../../shared/components/profile/NotificationSettings';

const SETTINGS = {
  reviewAssetUpload: {
    category: 'deliverable',
    name: 'review_asset_upload',
    title: 'Review Asset Upload',
    description:
      'Receive notifications when an organization uploads a review asset to a Deliverable.'
  },
  reviewAssetComment: {
    category: 'deliverable',
    name: 'review_asset_comment',
    title: 'Review Asset Comment',
    description: 'Receive notifications when an organization comments on a Deliverable.'
  },
  deliverableFileUpload: {
    category: 'deliverable',
    name: 'deliverable_file_upload',
    title: 'Deliverable File Upload',
    description:
      'Receive notifications when an organization uploads a deliverable file to a Deliverable.'
  },
  referenceFileUpload: {
    category: 'deliverable',
    name: 'reference_upload',
    title: 'Reference File Upload',
    description:
      'Receive notifications when an organization uploads a reference file to a Deliverable.'
  },
  solicitationReceived: {
    category: 'solicitation',
    name: 'solicitation_received',
    title: 'JobOpp Inquiry Received',
    description: 'Receive notifications when an organization sends you a JobOpp Inquiry.'
  },
  solicitationComment: {
    category: 'solicitation',
    name: 'solicitation_comment',
    title: 'JobOpp Inquiry Comment',
    description: 'Receive notifications when an organization comments on a JobOpp Inquiry.'
  },
  jobComment: {
    category: 'job',
    name: 'job_comment',
    title: 'Job Comment',
    description: 'Receive notifications when an organization comments on a Job.'
  }
};

const DELIVERABLES_SECTION = {
  label: 'Deliverable Notifications',
  emailLabel: true,
  order: ['reviewAssetUpload', 'reviewAssetComment', 'deliverableFileUpload', 'referenceFileUpload']
};
const SOLICITATIONS_SECTION = {
  label: 'JobOpp Inquiry Notifications',
  emailLabel: true,
  order: ['solicitationReceived', 'solicitationComment']
};
const JOB_SECTION = { label: 'Job Notifications', emailLabel: false, order: ['jobComment'] };

const NotificationSettingsContent = props => {
  const {
    notificationSettings: {
      currentUser: { freelancer }
    },
    intervalValues
  } = props;

  return (
    <NotificationSettings
      {...freelancer}
      intervalValues={intervalValues}
      settingsOptions={SETTINGS}
      firstColumnSections={[DELIVERABLES_SECTION]}
      secondColumnSections={[SOLICITATIONS_SECTION, JOB_SECTION]}
    />
  );
};

export default createFragmentContainer(NotificationSettingsContent, {
  notificationSettings: graphql`
    fragment NotificationSettingsContent_notificationSettings on Query {
      currentUser {
        freelancer {
          notificationConfig
          receiveSummaryEmails
          notificationSummaryInterval
        }
      }
    }
  `,
  intervalValues: graphql`
    fragment NotificationSettingsContent_intervalValues on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SamlForm_organization$ref: FragmentReference;
declare export opaque type SamlForm_organization$fragmentType: SamlForm_organization$ref;
export type SamlForm_organization = {|
  +hasSamlModule: ?boolean,
  +samlConfiguration: ?{|
    +samlEnabled: boolean,
    +idpEntityId: ?string,
    +idpUrl: ?string,
    +idpX509cert: ?string,
    +idpAttrUserPermanentId: string,
    +metadataUrl: ?string,
  |},
  +$refType: SamlForm_organization$ref,
|};
export type SamlForm_organization$data = SamlForm_organization;
export type SamlForm_organization$key = {
  +$data?: SamlForm_organization$data,
  +$fragmentRefs: SamlForm_organization$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SamlForm_organization",
  "type": "OrganizationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasSamlModule",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "samlConfiguration",
      "storageKey": null,
      "args": null,
      "concreteType": "SamlConfigurationNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "samlEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "idpEntityId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "idpUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "idpX509cert",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "idpAttrUserPermanentId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "metadataUrl",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0f8641e59eba1568e10d44f2fdb5d00a';
module.exports = node;

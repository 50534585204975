import React, { Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import ContractorActionLogList from './ContractorActionLogList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import ContractorToolsActions from './ContractorToolsActions';

const ContractorToolsTab = props => {
  const variables = useTableHistoryQueryVariables();
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query ContractorToolsTabQuery($id: ID!, $first: Int, $after: String) {
          contractor(id: $id) {
            ...ContractorToolsActions_contractor
            ...ContractorActionLogList_contractor
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <Fragment>
          <ContractorToolsActions contractor={relayProps.contractor} {...props} />
          <ContractorActionLogList contractor={relayProps.contractor} {...props} />
        </Fragment>
      )}
    />
  );
};

export default withTableHistoryProvider(ContractorToolsTab, 'orgContractorTools', {
  rowsPerPage: 10
});

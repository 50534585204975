import React from 'react';
import { useLoader } from 'react-three-fiber';
import * as THREE from 'three';
import annotationMarker from '../../../../images/annotation.svg';

const AnnotationSprite = props => {
  const { active, theme, handlers, ...otherProps } = props;
  const texture = useLoader(THREE.TextureLoader, annotationMarker);

  return (
    <group {...handlers}>
      <sprite {...otherProps}>
        <spriteMaterial
          attach="material"
          map={texture}
          sizeAttenuation={false}
          color={active ? theme.palette.primary.main : theme.palette.colors.annotationStroke}
        />
      </sprite>
      <sprite {...otherProps}>
        <spriteMaterial
          attach="material"
          map={texture}
          sizeAttenuation={false}
          {...{
            depthTest: false,
            opacity: 0.3,
            transparent: true
          }}
          color={active ? theme.palette.primary.main : theme.palette.colors.annotationStroke}
        />
      </sprite>
    </group>
  );
};

export default AnnotationSprite;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation CreateSolicitationMutation($input: CreateSolicitationInput!) {
    createSolicitation(input: $input) {
      newSolicitation {
        id
      }
    }
  }
`;

const CreateSolicitationMutation = (input, callback) => {
  const config = {
    mutation,
    variables: { input },
    onCompleted: response => {
      callback(response.createSolicitation);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default CreateSolicitationMutation;

CreateSolicitationMutation.propTypes = {
  input: PropTypes.objectOf(
    PropTypes.shape({
      subject: PropTypes.string.isRequired,
      message: PropTypes.string,
      replyByDate: PropTypes.string,
      deliverables: PropTypes.arrayOf(PropTypes.string),
      contractors: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

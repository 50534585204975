/**
 * @flow
 * @relayHash c02d3a6c2079e410ac6739121372c5e0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReassignSolicitationCoordinatorInput = {|
  id: string,
  staffCoordinator: string,
  clientMutationId?: ?string,
|};
export type ReassignSolicitationCoordinatorMutationVariables = {|
  input: ReassignSolicitationCoordinatorInput
|};
export type ReassignSolicitationCoordinatorMutationResponse = {|
  +reassignSolicitationCoordinator: ?{|
    +isUpdated: ?boolean,
    +solicitation: ?{|
      +id: string,
      +staffCoordinator: {|
        +id: string,
        +fullName: ?string,
        +representativeImageUrl: ?string,
        +user: {|
          +id: string
        |},
      |},
    |},
  |}
|};
export type ReassignSolicitationCoordinatorMutation = {|
  variables: ReassignSolicitationCoordinatorMutationVariables,
  response: ReassignSolicitationCoordinatorMutationResponse,
|};
*/


/*
mutation ReassignSolicitationCoordinatorMutation(
  $input: ReassignSolicitationCoordinatorInput!
) {
  reassignSolicitationCoordinator(input: $input) {
    isUpdated
    solicitation {
      id
      staffCoordinator {
        id
        fullName
        representativeImageUrl
        user {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReassignSolicitationCoordinatorInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reassignSolicitationCoordinator",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReassignSolicitationCoordinatorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isUpdated",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": null,
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffCoordinator",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "representativeImageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReassignSolicitationCoordinatorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReassignSolicitationCoordinatorMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReassignSolicitationCoordinatorMutation",
    "id": null,
    "text": "mutation ReassignSolicitationCoordinatorMutation(\n  $input: ReassignSolicitationCoordinatorInput!\n) {\n  reassignSolicitationCoordinator(input: $input) {\n    isUpdated\n    solicitation {\n      id\n      staffCoordinator {\n        id\n        fullName\n        representativeImageUrl\n        user {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a9287a83685f81be2b11de0162b537c7';
module.exports = node;

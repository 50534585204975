import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import StyledHeader from '../shared/StyledHeader';

const ReviewAssetsHeader = props => {
  const { storage } = props.storageInfo;

  return (
    <StyledHeader
      activeStorage={storage.activeStorageReviewAssets}
      archiveStorage={storage.archivedStorageReviewAssets}
    />
  );
};

export default createFragmentContainer(ReviewAssetsHeader, {
  storageInfo: graphql`
    fragment ReviewAssetsHeader_storageInfo on OrganizationNode {
      storage {
        activeStorageReviewAssets
        archivedStorageReviewAssets
      }
    }
  `
});

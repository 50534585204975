/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeliverableDetails_deliverableStateTypes$ref: FragmentReference;
declare export opaque type DeliverableDetails_deliverableStateTypes$fragmentType: DeliverableDetails_deliverableStateTypes$ref;
export type DeliverableDetails_deliverableStateTypes = {|
  +enumValues: ?$ReadOnlyArray<{|
    +name: string,
    +description: ?string,
  |}>,
  +$refType: DeliverableDetails_deliverableStateTypes$ref,
|};
export type DeliverableDetails_deliverableStateTypes$data = DeliverableDetails_deliverableStateTypes;
export type DeliverableDetails_deliverableStateTypes$key = {
  +$data?: DeliverableDetails_deliverableStateTypes$data,
  +$fragmentRefs: DeliverableDetails_deliverableStateTypes$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DeliverableDetails_deliverableStateTypes",
  "type": "__Type",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "enumValues",
      "storageKey": null,
      "args": null,
      "concreteType": "__EnumValue",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '79fbb62df74ea7c73a430a924fea3e97';
module.exports = node;

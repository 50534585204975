import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { REVIEW_INTERFACES } from '../../constants';
import FullScreenImageModal from '../assets/FullScreenImageModal';
import ReferenceFilePreview from './components/ReferenceFilePreview';

const Group = styled.div`
  a {
    text-decoration: underline;
    color: ${props => props.theme.palette.primary.main};
  }
`;

const renderOpToStyled = (op, i, { onAssetClick }) => {
  const {
    insert: { type, value },
    attributes
  } = op;
  let result;

  if (type === 'text') {
    result = value.split('\n').map((str, i) => (
      <Fragment key={i}>
        {i !== 0 && <br />}
        {str}
      </Fragment>
    ));
    if (attributes.underline) result = <u>{result}</u>;
    if (attributes.italic) result = <i>{result}</i>;
    if (attributes.bold) result = <b>{result}</b>;
    if (attributes.link)
      result = (
        <a href={attributes.link} target="_blank" rel="noreferrer">
          {result}
        </a>
      );
  } else if (type === 'referenceFile') {
    result = (
      <ReferenceFilePreview
        src={value.src}
        reviewInterface={value.reviewInterface}
        onClick={() => onAssetClick(value)}
      />
    );
  } else {
    throw new Error(`Don't know how to render ${type}`);
  }

  return <Fragment key={i}>{result}</Fragment>;
};

export const RichTextFormattedOps = ({ ops }) => {
  const [activeAssetPreview, setActiveAssetPreview] = useState(null);
  const converter = new QuillDeltaToHtmlConverter(ops);
  const groups = converter.getGroupedOps();

  const opProps = {
    onAssetClick: val => {
      if (val.reviewInterface === REVIEW_INTERFACES.image) {
        setActiveAssetPreview({
          name: val.title,
          src: val.src,
          description: val.description
        });
      } else if (val.src) {
        window.open(val.src, '_blank');
      }
    }
  };

  const result = groups.map((group, i) => {
    let result = group.ops.map((op, i) => renderOpToStyled(op, i, opProps));

    if (group.op && group.op.attributes.header) {
      result = <Typography variant={`h${group.op.attributes.header}`}>{result}</Typography>;
    }

    return <Group key={i}>{result}</Group>;
  });

  return (
    <>
      {activeAssetPreview && (
        <FullScreenImageModal
          open
          onCloseClick={() => setActiveAssetPreview(null)}
          {...activeAssetPreview}
        />
      )}
      {result}
    </>
  );
};

export const RichTextPlainOps = ({ ops }) =>
  opsToRawString(ops)
    .split('\n')
    .map((val, i) =>
      val.trim().length > 0 ? (
        <Fragment key={i}>
          {val}
          <br />
        </Fragment>
      ) : null
    );

export const opsToRawString = ops =>
  ops.map(op => (typeof op.insert === 'string' ? op.insert : '')).join('');

import React from 'react';
import { Grid } from '@material-ui/core';

import FilterDebounceInput from '../../../../../shared/components/filters/FilterDebounceInput';
import FilterCheckbox from '../../../../../shared/components/filters/FilterCheckbox';
import FilterSelect from '../../../../../shared/components/filters/FilterSelect';
import { useFilterStyles } from '../../../../../shared/styles/common/filterStyles';
import { CountrySelect } from '../../../../../shared/components/form/dropdowns/CountrySelect';
import SearchableTagsDropdown from '../../../form/SearchableTagsDropdown';
import SearchableReleaseDropdown from '../../../shared/dropdowns/SearchableReleaseDropdown';
import SearchableProductDropdown from '../../../releases/SearchableProductDropdown';
import SearchableContractorGroupDropdown from '../../SearchableContractorGroupDropdown';

const ContractorsListFilters = props => {
  const { filterValues, onFilterChange } = props;
  const classes = useFilterStyles();

  const statusOptions = [
    { value: 'not_invited', name: 'Not invited' },
    { value: 'pending', name: 'Pending' },
    { value: 'joined', name: 'Joined' }
  ];

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          id="contractorNameFilter"
          value={filterValues['contractorName']}
          onChange={onFilterChange}
          name="contractorName"
          label="Contractor Name"
          fullWidth
          placeholder="Enter Contractor Name..."
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          id="filterByInviteStatus"
          name="inviteStatus"
          onChange={onFilterChange}
          value={filterValues['inviteStatus']}
          label="Invite Status"
          options={statusOptions}
        />
      </Grid>
      <Grid item xs={2}>
        <CountrySelect
          id="filterByCountry"
          name="country"
          onChange={onFilterChange}
          value={filterValues['country']}
          label="Country"
        />
      </Grid>

      <Grid item xs={2}>
        <SearchableReleaseDropdown
          name="release"
          onChange={value => onFilterChange('release', value)}
          value={filterValues['release']}
          releaseOnly
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableProductDropdown
          name="product"
          onChange={value => onFilterChange('product', value)}
          value={filterValues['product']}
        />
      </Grid>
      <Grid item xs={2}>
        <FilterDebounceInput
          id="filterByMinDeliverables"
          value={filterValues['minCompletedDeliverables'] || ''}
          onChange={onFilterChange}
          name="minCompletedDeliverables"
          label="Min Completed Deliverables"
          type="number"
          fullWidth
          placeholder="Enter Number..."
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableTagsDropdown
          name="tags"
          label="Tags"
          onChange={onFilterChange}
          value={filterValues['tags'] || { options: [] }}
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableContractorGroupDropdown
          name="group"
          label="Group"
          onChange={value => onFilterChange('group', value)}
          value={filterValues['group']}
        />
      </Grid>
      <Grid item xs={2} className={classes.alignSelfCenter}>
        <FilterCheckbox
          id="includeDoNotHireFilter"
          onChange={onFilterChange}
          name="includeDoNotHire"
          value={filterValues['includeDoNotHire']}
          label="Include 'Do not hire'"
        />
      </Grid>
      <Grid item xs={2} className={classes.alignSelfCenter}>
        <FilterCheckbox
          id="includeInactiveFilter"
          onChange={onFilterChange}
          name="includeInactive"
          value={filterValues['includeInactive']}
          label="Include Inactive"
        />
      </Grid>
    </Grid>
  );
};

export default ContractorsListFilters;

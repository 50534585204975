import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import PaymentDetailsContent from './PaymentDetailsContent';

const PaymentDetailsTab = props => (
  <QueryRenderer
    query={graphql`
      query PaymentDetailsTabQuery {
        ...PaymentDetailsContent_details
        __type(name: "DeliverableCurrencyCode") {
          ...PaymentDetailsContent_currencyCodes
        }
      }
    `}
    render={relayProps => (
      <PaymentDetailsContent
        currencyCodes={relayProps.__type}
        details={relayProps}
        {...relayProps}
        {...props}
      />
    )}
  />
);

export default PaymentDetailsTab;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import Typography from '@material-ui/core/Typography';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';

const DetailsDescription = props => {
  const { description } = props.deliverable;

  return (
    <DetailsSection title="Description">
      <Typography variant="body1">{description}</Typography>
    </DetailsSection>
  );
};

export default createFragmentContainer(DetailsDescription, {
  deliverable: graphql`
    fragment DetailsDescription_deliverable on DeliverableNode {
      description
    }
  `
});

/**
 * @flow
 * @relayHash 20a8928b914a0b3b11b2952b1dc40112
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateReactionDefinitionsInput = {|
  definitions?: ?$ReadOnlyArray<?ReactionDefinitionToUpdate>,
  clientMutationId?: ?string,
|};
export type ReactionDefinitionToUpdate = {|
  id: string,
  name: string,
  color: string,
  file?: ?string,
|};
export type UpdateReactionsMutationVariables = {|
  input: UpdateReactionDefinitionsInput
|};
export type UpdateReactionsMutationResponse = {|
  +updateReactions: ?{|
    +reactions: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
      +color: string,
    |}>
  |}
|};
export type UpdateReactionsMutation = {|
  variables: UpdateReactionsMutationVariables,
  response: UpdateReactionsMutationResponse,
|};
*/


/*
mutation UpdateReactionsMutation(
  $input: UpdateReactionDefinitionsInput!
) {
  updateReactions(input: $input) {
    reactions {
      id
      name
      color
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateReactionDefinitionsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateReactions",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateReactionDefinitionsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reactions",
        "storageKey": null,
        "args": null,
        "concreteType": "ReactionDefinitionNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "color",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateReactionsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateReactionsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateReactionsMutation",
    "id": null,
    "text": "mutation UpdateReactionsMutation(\n  $input: UpdateReactionDefinitionsInput!\n) {\n  updateReactions(input: $input) {\n    reactions {\n      id\n      name\n      color\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c477dcb65c54f875d91df552b1c008c';
module.exports = node;

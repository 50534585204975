import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import authService from '../../../shared/services/authService';
import loginStyles from '../../../shared/styles/account/login';
import { ReactComponent as Logo } from '../../../shared/images/AWEbase-Default-Light.svg';

const SamlRedirect = props => {
  const { history, classes } = props;

  useEffect(() => {
    authService.obtainTokens(() => {
      // if success, this will lead to home page, otherwise back to login with 'Authentication failed' error message
      history.push('/');
    });
  }, [history]);

  return (
    <div className={classes.mainLogin}>
      <div className={classes.logo}>
        <Logo />
      </div>
      <div className={classes.loginContent}>
        <Typography variant="body2">Loading...</Typography>
      </div>
    </div>
  );
};

export default withRouter(withStyles(loginStyles)(SamlRedirect));

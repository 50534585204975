import React from 'react';
import PropTypes from 'prop-types';
import { Info } from '@material-ui/icons';

import StyledTableCell from './StyledTableCell';

import { useTableStyles } from '../../styles/common/tableStyles';

const TableCellInfo = ({ id, onClick, hidden }) => {
  const classes = useTableStyles();

  const onClickHandler = () => {
    onClick(id);
  };

  return (
    <StyledTableCell>
      <div className={classes.verticalCenter}>
        {!hidden && <Info onClick={onClickHandler} className={classes.infoIcon} />}
      </div>
    </StyledTableCell>
  );
};

TableCellInfo.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool
};

export default TableCellInfo;

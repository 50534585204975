import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';



const TableCellRelationLink = props => {
  const {
    cellText,
    cellLink,
    colorProp,
    ...otherProps
  } = props;
  
  // Link style is not playing well with the warning color
  // setting the color in the link with a style prop.
  // REVIEW Is there a better way of doing this?
  const cellStyle = colorProp ? {color: colorProp} : {}

  return  (
    <StyledTableCell {...otherProps} >
        <Link style={cellStyle} to={cellLink}>{cellText}</Link>
    </StyledTableCell>
  );
};

TableCellRelationLink.propTypes = {
  cellLink: PropTypes.string,
  cellText: PropTypes.string
};

export default TableCellRelationLink;
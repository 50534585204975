import React, { useState, useEffect, Fragment, Suspense } from 'react';
import { Canvas } from 'react-three-fiber';
import * as THREE from 'three';
import { withTheme } from '@material-ui/styles';
import { Tooltip, Typography, Box } from '@material-ui/core';

import {
  AnnotationAssetContainer,
  AnnotationToolbarContainer,
  AnnotationActionsContainer
} from './AnnotationInterfaceContainers';
import { ReactComponent as EditIcon } from '../../../images/edit.svg';
import { ReactComponent as CommentIcon } from '../../../images/message.svg';
import SquareButton from '../../UI/SquareButton';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import UpdateDeliverableReviewAnnotationMutation from '../../../mutations/review/UpdateDeliverableReviewAnnotationMutation';
import AddActionButton from './AddActionButton';
import CanvasContent from './threeDee/CanvasContent';
import Placeholder from './threeDee/Placeholder';
import SceneSettings from './threeDee/SceneSettings';

const defaultSceneSettings = {
  lightIntensity: 0.5,
  environment: 0,
  flatShading: false
};

const generateId = () => '3d-annotation-' + new Date().getTime().toString();

const ThreeDeeAnnotation = props => {
  const {
    src,
    assetId,
    annotationData,
    sceneSettings,
    theme,
    onCloseClick,
    highlightedAnnotationRef,
    handleHighlightAnnotation,
    canAddMarkup,
    sidebarAction,
    setSidebarAction
  } = props;

  const [isPointer, setPointer] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [settings, setSettings] = useState(sceneSettings ? sceneSettings : defaultSceneSettings);
  const [addedAnnotationData, setAddedAnnotationData] = useState(null);

  useEffect(() => {
    if (addedAnnotationData && (!sidebarAction || sidebarAction.type !== 'ADD_NEW')) {
      setAddedAnnotationData(null);
      setEditing(false);
    }
  }, [sidebarAction]);

  useUpdateEffect(() => {
    setSidebarAction(val => ({
      ...val,
      type: 'ADD_NEW',
      submitHandler: ({ content, rawContent, referenceIds }) => {
        const objects = annotationData || [];
        const newAnnotationRef = generateId();
        const newAnnotation = {
          ...addedAnnotationData,
          annotationRef: newAnnotationRef
        };
        UpdateDeliverableReviewAnnotationMutation(
          {
            reviewAsset: assetId,
            annotationRef: newAnnotationRef,
            annotationData: JSON.stringify([...objects, newAnnotation]),
            content,
            rawContent,
            referenceIds
          },
          response => {
            setAddedAnnotationData(null);
            setEditing(false);
            setSidebarAction(null);
          }
        );
      }
    }));
  }, [addedAnnotationData]);

  const onPointerMissed = () => {
    handleHighlightAnnotation(null);
  };

  const togglePointer = () => {
    setPointer(!isPointer);
  };

  return (
    <Fragment>
      <AnnotationAssetContainer>
        <AnnotationActionsContainer>
          <SquareButton onClick={onCloseClick} variant="black" icon="close" />
        </AnnotationActionsContainer>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: `4px solid ${isEditing ? theme.palette.primary.main : 'transparent'}`
          }}
        />
        <Canvas
          id="three"
          gl={{
            antialias: true,
            outputEncoding: THREE.sRGBEncoding,
            physicallyCorrectLights: true
          }}
          style={{
            cursor: isPointer ? 'pointer' : 'default'
          }}
          onPointerMissed={onPointerMissed}
        >
          <Suspense fallback={<Placeholder theme={theme} />}>
            <CanvasContent
              environment={settings.environment}
              highlightedAnnotationRef={highlightedAnnotationRef}
              handleHighlightAnnotation={handleHighlightAnnotation}
              annotationData={annotationData}
              isEditing={isEditing}
              settings={settings}
              setAddedAnnotationData={setAddedAnnotationData}
              theme={theme}
              togglePointer={togglePointer}
              src={src}
            />
          </Suspense>
        </Canvas>
      </AnnotationAssetContainer>
      <AnnotationToolbarContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {isEditing ? (
            <SquareButton
              handleClick={() => {
                setEditing(false);
                setSidebarAction(null);
              }}
              tooltipTitle="Close annotation mode"
              icon="close"
              variant="secondary"
            />
          ) : (
            <Fragment>
              <Tooltip
                title="Click anywhere on the object to add an annotation. Your camera position while adding the annotation will be saved."
                position="top"
              >
                <span>
                  <AddActionButton
                    icon={EditIcon}
                    handleClick={() => {
                      setEditing(!isEditing);
                      setSidebarAction(null);
                    }}
                    disabled={!canAddMarkup}
                  >
                    Add Annotation
                  </AddActionButton>
                </span>
              </Tooltip>
              <AddActionButton
                icon={CommentIcon}
                disabled={!canAddMarkup}
                onClick={() => {
                  setSidebarAction(val => ({ ...val, type: 'ADD_NEW' }));
                }}
              >
                Add Comment
              </AddActionButton>
            </Fragment>
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}></div>
          <SceneSettings
            src={src}
            assetId={assetId}
            settings={settings}
            settingsChanged={settings => setSettings(settings)}
            disabled={!canAddMarkup}
          />
          <Box ml={2}>
            <Typography variant="body2">Hold Down [Shift] Key to Pan the Camera</Typography>
          </Box>
        </div>
      </AnnotationToolbarContainer>
    </Fragment>
  );
};

export default withTheme(ThreeDeeAnnotation);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useUpdateEffect from '../../hooks/useUpdateEffect';

import SwatchColorPickerButton from './SwatchColorPickerButton';
import ColorPicker from './ColorPicker';

const SwatchColorPicker = props => {
  const { initialColor, onChange, name } = props;

  const [color, setColor] = useState(initialColor);

  useUpdateEffect(() => {
    onChange(name, color);
  }, [color]);

  const onChangeHandler = color => {
    setColor(color);
  };

  return (
    <ColorPicker
      onChange={onChangeHandler}
      color={color}
      colors={[
        '#fc0000',
        '#e91e63',
        '#9c27b0',
        '#673ab7',
        '#3f51b5',
        '#2196f3',
        '#03a9f4',
        '#00bcd4',
        '#009688',
        '#4caf50',
        '#8bc34a',
        '#cddc39',
        '#ffeb3b',
        '#ffc107',
        '#fca216',
        '#ff5722',
        '#795548',
        '#607d8b'
      ]}
      renderButton={SwatchColorPickerButton}
      popoverProps={{
        maxWidth: 224,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'center'
        }
      }}
    />
  );
};

SwatchColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  initialColor: PropTypes.string.isRequired
};

export default SwatchColorPicker;

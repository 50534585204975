import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { styled } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import ContractorInfo from './ContractorInfo';
import AddressInfo from './AddressInfo';
import Notes from './Notes';
import TagsAutocompleteSection from '../../../../../shared/components/common/TagsAutocompleteSection';
import AssetCardImage from '../../../../../shared/components/assets/AssetCardImage';
import BigAvatar from '../../../../../shared/components/UI/BigAvatar';
import FlagIndicator from '../../../../../shared/components/UI/FlagIndicator';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import { borderRight } from '../../../../../shared/styles/borders';

const StyledBigAvatar = styled(BigAvatar)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(3),
  top: 0,
  transform: 'translateY(-50%)'
}));

const ContractorDetailsContent = props => {
  const {
    relayProps,
    isEditing,
    setFieldValue,
    values,
    toggleEdit,
    canUserEdit,
    isSubmitting,
    resetForm,
    contractor: {
      representativeImageUrl,
      additionalInfo,
      doNotHire,
      conflictingContractor,
      fullName,
      coverImageUrl,
      isActive
    }
  } = props;

  return (
    <Box display="flex">
      <Box flex="2" pr={2} style={borderRight}>
        <AssetCardImage cover aspectRatio={25} src={coverImageUrl} />
        <Box py={3} pl="200px" mb={5} position="relative">
          <StyledBigAvatar src={representativeImageUrl} alt={`${fullName} picture`} />
          <Box display="flex">
            <Box mr={3} display="flex" alignItems="center">
              <FlagIndicator
                doNotHire={doNotHire}
                deactivated={!isActive}
                hasConflictingContractor={conflictingContractor ? true : false}
              />
            </Box>
            {/* Disabling Ratings from UI until feature fully fleshed out */}
            {/* <Box display="flex" alignItems="center">
              <GrayTextContainer>Rating</GrayTextContainer>
              <RatingStars rating={rating} />
            </Box> */}
            {canUserEdit && (
              <EditToggle
                isEditing={isEditing}
                isSubmitting={isSubmitting}
                editProps={{
                  id: 'editContractorDetails',
                  onClick: toggleEdit,
                  disabled: false
                }}
                saveProps={{
                  id: 'saveContractorDetails'
                }}
                cancelProps={{
                  id: 'cancelContractorDetails',
                  onClick: () => {
                    toggleEdit();
                    resetForm();
                  }
                }}
              />
            )}
          </Box>
        </Box>
        <ContractorInfo
          setFieldValue={setFieldValue}
          isEditing={isEditing}
          contractor={relayProps.contractor}
        />
        <TagsAutocompleteSection
          values={values}
          setFieldValue={setFieldValue}
          isEditing={isEditing}
        />
        <AddressInfo isEditing={isEditing} contractor={relayProps.contractor} />
        <DetailsSection title="Additional Information" noBorder>
          <Typography>
            {additionalInfo
              ? additionalInfo
              : 'No Additional Information provided by the Freelancer.'}
          </Typography>
        </DetailsSection>
      </Box>
      <Box flex="1">
        <Notes contractor={relayProps.contractor} />
      </Box>
    </Box>
  );
};

export default createFragmentContainer(ContractorDetailsContent, {
  contractor: graphql`
    fragment ContractorDetailsContent_contractor on ContractorNode {
      representativeImageUrl
      fullName
      internalId
      doNotHire
      isActive
      coverImageUrl
      additionalInfo
      conflictingContractor {
        id
      }
    }
  `
});

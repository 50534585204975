import React from 'react';
import SolicitationsList from './SolicitationsList';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import graphql from 'babel-plugin-relay/macro';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';

const SolicitationsPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query SolicitationsPageQuery(
          $first: Int
          $orderBy: String
          $after: String
          $id: ID!
          $subject: String
          $staffCoordinator: ID
          $organization: String
          $dateReceivedFrom: Date
          $dateReceivedTo: Date
          $replyByFrom: Date
          $replyByTo: Date
        ) {
          ...SolicitationsList_solicitations
            @arguments(
              first: $first
              after: $after
              subject: $subject
              staffCoordinator: $staffCoordinator
              organization: $organization
              dateReceivedFrom: $dateReceivedFrom
              dateReceivedTo: $dateReceivedTo
              replyByFrom: $replyByFrom
              replyByTo: $replyByTo
            )
          __type(name: "SolicitationState") {
            ...SolicitationsList_stateTypes
          }
          ...SolicitationQuickView_solicitation
        }
      `}
      variables={variables}
      render={relayProps => (
        <SolicitationsList
          label="JobOpp Inquiry"
          solicitations={relayProps}
          stateTypes={relayProps.__type}
          relayProps={relayProps}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(SolicitationsPage, 'freSolicitations', {
  orderBy: 'dateSent',
  initialFilters: {
    subject: '',
    organization: null,
    staffCoordinator: null,
    dateReceivedFrom: null,
    dateReceivedTo: null,
    replyByFrom: null,
    replyByTo: null,
    state: ''
  }
});

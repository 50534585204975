import { useEffect } from 'react';

export const useEffectWithStatus = (effect, dependancies) => {
  const status = {};
  useEffect(() => {
    status.mounted = true;
    const cleanUp = effect(status);
    return () => {
      status.mounted = false;
      if (typeof cleanUp === 'function') {
        cleanUp();
      }
    };
  }, [...dependancies]);
};

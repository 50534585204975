/**
 * @flow
 * @relayHash 470e3d791e398999ac54e5c34f21fa77
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StripeCompleteQueryVariables = {|
  uidb64: string,
  status: string,
|};
export type StripeCompleteQueryResponse = {|
  +stripeCompleteUpdate: ?{|
    +status: ?boolean,
    +message: ?string,
  |}
|};
export type StripeCompleteQuery = {|
  variables: StripeCompleteQueryVariables,
  response: StripeCompleteQueryResponse,
|};
*/


/*
query StripeCompleteQuery(
  $uidb64: String!
  $status: String!
) {
  stripeCompleteUpdate(uidb64: $uidb64, status: $status) {
    status
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uidb64",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "stripeCompleteUpdate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "uidb64",
        "variableName": "uidb64"
      }
    ],
    "concreteType": "UpdateStripeNode",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StripeCompleteQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "StripeCompleteQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "StripeCompleteQuery",
    "id": null,
    "text": "query StripeCompleteQuery(\n  $uidb64: String!\n  $status: String!\n) {\n  stripeCompleteUpdate(uidb64: $uidb64, status: $status) {\n    status\n    message\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c43275bd42f1bc75ad01944db86ad72';
module.exports = node;

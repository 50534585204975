/**
 * @flow
 * @relayHash 54437a57bb01031c00ac16b01c56c7fa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContactsList_contacts$ref = any;
export type ContactsListRefetchQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
  staffName?: ?string,
  organization?: ?string,
|};
export type ContactsListRefetchQueryResponse = {|
  +$fragmentRefs: ContactsList_contacts$ref
|};
export type ContactsListRefetchQuery = {|
  variables: ContactsListRefetchQueryVariables,
  response: ContactsListRefetchQueryResponse,
|};
*/


/*
query ContactsListRefetchQuery(
  $first: Int
  $orderBy: String
  $after: String
  $staffName: String
  $organization: ID
) {
  ...ContactsList_contacts_3ikLvE
}

fragment ContactsList_contacts_3ikLvE on Query {
  allStaff(first: $first, orderBy: $orderBy, after: $after, staffName: $staffName, organization: $organization) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        fullName
        user {
          email
          representativeImageUrl
          id
        }
        title
        organization {
          name
          representativeImageUrl
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "staffName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "organization",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "organization",
    "variableName": "organization"
  },
  {
    "kind": "Variable",
    "name": "staffName",
    "variableName": "staffName"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContactsListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ContactsList_contacts",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactsListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allStaff",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StaffNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fullName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "organization",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrgNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContactsListRefetchQuery",
    "id": null,
    "text": "query ContactsListRefetchQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n  $staffName: String\n  $organization: ID\n) {\n  ...ContactsList_contacts_3ikLvE\n}\n\nfragment ContactsList_contacts_3ikLvE on Query {\n  allStaff(first: $first, orderBy: $orderBy, after: $after, staffName: $staffName, organization: $organization) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        fullName\n        user {\n          email\n          representativeImageUrl\n          id\n        }\n        title\n        organization {\n          name\n          representativeImageUrl\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78cfda808118c8eed95ac956d48494ee';
module.exports = node;

/**
 * @flow
 * @relayHash a84a70c12a04aa729aacb0c66dae4f8c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationReferencesList_solicitation$ref = any;
export type SolicitationReferencesListRefetchQueryVariables = {|
  first?: ?number,
  after?: ?string,
  id: string,
|};
export type SolicitationReferencesListRefetchQueryResponse = {|
  +solicitation: ?{|
    +$fragmentRefs: SolicitationReferencesList_solicitation$ref
  |}
|};
export type SolicitationReferencesListRefetchQuery = {|
  variables: SolicitationReferencesListRefetchQueryVariables,
  response: SolicitationReferencesListRefetchQueryResponse,
|};
*/


/*
query SolicitationReferencesListRefetchQuery(
  $first: Int
  $after: String
  $id: ID!
) {
  solicitation: solicitation(id: $id) {
    ...SolicitationReferencesList_solicitation
    id
  }
}

fragment SolicitationReferencesList_solicitation on SolicitationNode {
  references(first: $first, after: $after, orderBy: "-created") {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        title
        fileUrl
        description
        reviewInterface
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationReferencesListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "solicitation",
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SolicitationReferencesList_solicitation",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationReferencesListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "solicitation",
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "references",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-created"
              }
            ],
            "concreteType": "ReferenceNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ReferenceNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReferenceNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reviewInterface",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationReferencesListRefetchQuery",
    "id": null,
    "text": "query SolicitationReferencesListRefetchQuery(\n  $first: Int\n  $after: String\n  $id: ID!\n) {\n  solicitation: solicitation(id: $id) {\n    ...SolicitationReferencesList_solicitation\n    id\n  }\n}\n\nfragment SolicitationReferencesList_solicitation on SolicitationNode {\n  references(first: $first, after: $after, orderBy: \"-created\") {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        title\n        fileUrl\n        description\n        reviewInterface\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '58d43f5823dba4072c93d893856d544e';
module.exports = node;

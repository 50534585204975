import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

const CreateReferenceMutation = (input, file) =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation CreateReferenceMutation($input: CreateReferenceInput!) {
          createReference(input: $input) {
            newReference {
              id
              title
              fileUrl
              reviewInterface
            }
          }
        }
      `,
      variables: {
        input
      },
      uploadables: {
        file
      },
      onCompleted: resolve,
      onError: reject
    });
  });

export default CreateReferenceMutation;

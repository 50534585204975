import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation SignContractByContractorMutation($input: SignContractByContractorInput!) {
    signContractByContractor(input: $input) {
      job {
        id
        status
        contract {
          signedPdfByContractorUrl
          contractFileUrl
        }
        deliverables {
          edges {
            node {
              state
            }
          }
        }
      }
    }
  }
`;

export default (id, contractor_signature, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { id } },
    uploadables: { contractor_signature },
    onCompleted: response => {
      callback(response.signContractByContractor);
    },
    onError: err => console.error(err)
  });
};

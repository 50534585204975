import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

export default (email, password, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation SigninUserMutation($signinUserInput: SignInInput!) {
        signIn(input: $signinUserInput) {
          token
          refreshToken
          userType
          clientMutationId
        }
      }
    `,
    variables: {
      signinUserInput: {
        email,
        password,
        clientMutationId: ''
      }
    },
    onCompleted: callback,
    onError: err => console.error(err)
  });
};

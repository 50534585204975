import React from 'react';
import get from 'lodash/get';

const withFieldProps = WrappedComponent => {
  const ComponentWithFieldProps = props => {
    const { field, form, ...otherProps } = props;

    const isInvalid = get(form.errors, field.name) && get(form.touched, field.name);

    return (
      <WrappedComponent
        name={field.name}
        onChange={form.setFieldValue}
        onBlur={form.handleBlur}
        value={field.value}
        helperText={isInvalid && get(form.errors, field.name)}
        {...otherProps}
      />
    );
  };
  return ComponentWithFieldProps;
};

export default withFieldProps;

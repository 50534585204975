import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../../shared/utils/helpers';

// Fetching all user data so we can update the context
const mutation = graphql`
  mutation UpdateOrganizationConfigurationMutation($input: UpdateOrganizationConfigurationInput!) {
    updateOrganizationConfiguration(input: $input) {
      isUpdated
      organization {
        id
        configuration
        currencyCode
        name
        tierLabel
      }
    }
  }
`;

const UpdateOrganizationConfigurationMutation = (input, callback) => {
  const config = {
    mutation,
    variables: { input },
    onCompleted: (response, errors) => {
      callback(response.updateOrganizationConfiguration, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default UpdateOrganizationConfigurationMutation;

UpdateOrganizationConfigurationMutation.propTypes = {
  // TODO: add more fields later when writing other customization tabs
  fileSizeLimits: PropTypes.object,
  dueDateWarnings: PropTypes.object,
  currencyCode: PropTypes.string,
  dateDisplay: PropTypes.string,
  timeDisplay: PropTypes.oneOf(['12', '24'])
};

import React, { Fragment } from 'react';
import clsx from 'clsx';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { ReactComponent as JobsIcon } from '../../../shared/images/jobs.svg';
import CategoryTitleHeader from '../../../shared/components/UI/CategoryTitleHeader';
import { useDetailsStyles } from '../../../shared/styles/common/useDetailsStyles';
import TopSubtitle from '../../../shared/components/UI/TopSubtitle';
import { JOB_STATUSES } from '../../../shared/constants';
import AutoTabList from '../../../shared/components/UI/AutoTabList';
import JobDetailsTabRenderer from './tabRenderers/JobDetailsTabRenderer';
import JobDeliverablesTabRenderer from './tabRenderers/JobDeliverablesTabRenderer';
import JobInvoicesTabRenderer from './tabRenderers/JobInvoicesTabRenderer';
import HeaderSubtitle from '../../../shared/components/UI/HeaderSubtitle';

const JobDetails = props => {
  const {
    jobDeadline,
    contractor: { organization },
    status,
    name
  } = props.job;
  const { stateTypes } = props;
  const classes = useDetailsStyles();

  return (
    <Fragment>
      <div className={clsx(classes.marginBottom, classes.topSectionHeightSmall)}>
        <div className={classes.containerSpaceBetween}>
          <CategoryTitleHeader
            title={name}
            renderTop={() => <TopSubtitle>{organization.name}</TopSubtitle>}
            renderBottom={() => (
              <HeaderSubtitle
                date={jobDeadline}
                state={status}
                stateTypes={stateTypes}
                variant="job"
              />
            )}
            icon={JobsIcon}
            link="/jobs"
          />
        </div>
      </div>
      {status === JOB_STATUSES.revoked || status === JOB_STATUSES.declined ? (
        <div className={classes.detailsContentNoTabs}>
          <JobDetailsTabRenderer />
        </div>
      ) : (
        <div className={classes.marginTop}>
          <AutoTabList>
            <JobDetailsTabRenderer label="Details" />
            {status !== JOB_STATUSES.revoked && status !== JOB_STATUSES.declined && (
              <JobDeliverablesTabRenderer label="Deliverables" />
            )}
            <JobInvoicesTabRenderer label="Invoices" />
          </AutoTabList>
        </div>
      )}
    </Fragment>
  );
};

export default createFragmentContainer(JobDetails, {
  job: graphql`
    fragment JobDetails_job on JobNode {
      jobId
      name
      status
      jobDeadline
      contractor {
        organization {
          name
          representativeImageUrl
        }
      }
    }
  `,
  stateTypes: graphql`
    fragment JobDetails_stateTypes on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DetailsInfo_deliverable$ref: FragmentReference;
declare export opaque type DetailsInfo_deliverable$fragmentType: DetailsInfo_deliverable$ref;
export type DetailsInfo_deliverable = {|
  +state: DeliverableState,
  +title: string,
  +jobId: ?string,
  +contractSignDeadline: ?string,
  +release: ?{|
    +id: string,
    +name: string,
    +code: string,
    +product: {|
      +id: string,
      +title: string,
    |},
  |},
  +job: ?{|
    +id: string,
    +name: string,
  |},
  +$refType: DetailsInfo_deliverable$ref,
|};
export type DetailsInfo_deliverable$data = DetailsInfo_deliverable;
export type DetailsInfo_deliverable$key = {
  +$data?: DetailsInfo_deliverable$data,
  +$fragmentRefs: DetailsInfo_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DetailsInfo_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "contractSignDeadline",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "release",
      "storageKey": null,
      "args": null,
      "concreteType": "ReleaseNode",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "code",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "product",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "job",
      "storageKey": null,
      "args": null,
      "concreteType": "JobNode",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b14db1094621f60f0202a8e99d523f5';
module.exports = node;

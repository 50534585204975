import React, { Fragment, useEffect, useState } from 'react';
import { Field } from 'formik';
import { Typography, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import FormikTextField from '../../../../shared/components/form/FormikTextField';
import FormikDropdownField from '../../../../shared/components/form/FormikDropdownField';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '0',
    paddingBottom: theme.spacing(4),
    minWidth: '350px',
    maxWidth: '500px'
  }
}));

const HandleCategoryEditForm = props => {
  const {
    handleClose,
    editingData: { action, selected },
    values,
    setFieldError,
    setFieldValue,
    editingActions: { addType, addStep, editStep, editType, removeStep, removeType }
  } = props;
  const classes = useStyles();

  const [fullSelected, setFullSelected] = useState({});

  useEffect(() => {
    if ([editType.name, editStep.name, removeStep.name, removeType.name].includes(action.name)) {
      setFieldValue('typeOrStepName', selected);
    }
    setFullSelected(getFullSelected());
  }, []);

  const onSubmitHandler = () => {
    if ([addType.name, editType.name, removeType.name].includes(action.name)) {
      const validatedTypes = validate(values.categoryTypes);
      if (validatedTypes) {
        setFieldValue('categoryTypes', validatedTypes);
        emptyFieldAndClose();
      }
    } else if ([addStep.name, editStep.name, removeStep.name].includes(action.name)) {
      const validatedSteps = validate(values.steps);
      if (validatedSteps) {
        setFieldValue('steps', validatedSteps);
        emptyFieldAndClose();
      }
    }
  };
  const emptyFieldAndClose = () => {
    setFieldValue('typeOrStepName', '');
    setFieldValue('replacementName', '');
    handleClose();
  };

  const getFullSelected = () => {
    if (selected) {
      if ([addType.name, editType.name, removeType.name].includes(action.name)) {
        return values.categoryTypes.find(typeOrStep => typeOrStep.name === selected);
      } else {
        return values.steps.find(typeOrStep => typeOrStep.name === selected);
      }
    }
    return null;
  };

  const getAvailableReplacementItems = () => {
    if (selected) {
      if ([removeType.name].includes(action.name)) {
        return values.categoryTypes.filter(type => type.name !== selected);
      } else if ([removeStep.name].includes(action.name)) {
        return values.steps.filter(step => step.name !== selected);
      }
    }
    return [];
  };

  const validate = allTypesOrSteps => {
    const typeOrStepNameLower = values.typeOrStepName.trim().toLowerCase();
    if (!typeOrStepNameLower) {
      setFieldError('typeOrStepName', 'Cannot be empty.');
      return false;
    }
    if (typeOrStepNameLower.length < 3 || typeOrStepNameLower.length > 30) {
      setFieldError('typeOrStepName', 'Must contain 3-30 characters.');
      return false;
    }
    const allTypesOrStepsLower = allTypesOrSteps.map(typeOrStep => typeOrStep.name.toLowerCase());
    let editingIndex = -1;
    if (selected) {
      editingIndex = allTypesOrSteps.map(typeOrStep => typeOrStep.name).indexOf(selected);
    }

    if ([addType.name, addStep.name].includes(action.name)) {
      if (allTypesOrStepsLower.includes(typeOrStepNameLower)) {
        setFieldError('typeOrStepName', `${action.variant} name must be unique.`);
        return false;
      } else {
        return [
          ...allTypesOrSteps,
          { name: values.typeOrStepName, numberOfDeliverables: 0, replacementFor: [] }
        ];
      }
    } else if ([editType.name, editStep.name].includes(action.name)) {
      // exclude current item when checking for uniqueness
      if (
        [
          ...allTypesOrStepsLower.slice(0, editingIndex),
          ...allTypesOrStepsLower.slice(editingIndex + 1)
        ].includes(typeOrStepNameLower)
      ) {
        setFieldError('typeOrStepName', `${action.variant} name must be unique.`);
        return false;
      } else {
        return [
          ...allTypesOrSteps.slice(0, editingIndex),
          { ...fullSelected, name: values.typeOrStepName },
          ...allTypesOrSteps.slice(editingIndex + 1)
        ];
      }
    } else if ([removeStep.name, removeType.name].includes(action.name)) {
      const replacementStepOrType = values.replacementName;
      let replacementIndex, replacementFull, newListWithReplacementSet;
      if (!replacementStepOrType && fullSelected.numberOfDeliverables) {
        setFieldError('replacementName', 'You must select a replacement.');
        return false;
      }
      if (replacementStepOrType) {
        replacementIndex = allTypesOrSteps
          .map(typeOrStep => typeOrStep.name)
          .indexOf(replacementStepOrType);
        replacementFull = allTypesOrSteps.find(
          typeOrStep => typeOrStep.name === replacementStepOrType
        );
        newListWithReplacementSet = [
          ...allTypesOrSteps.slice(0, replacementIndex),
          {
            ...replacementFull,
            replacementFor: [
              // this type/step will be replacement for newly deleted one plus for what it was already replacing
              ...replacementFull.replacementFor,
              ...(fullSelected.relayId ? [fullSelected.relayId] : []),
              ...fullSelected.replacementFor
            ],
            numberOfDeliverables:
              replacementFull.numberOfDeliverables + fullSelected.numberOfDeliverables
          },
          ...allTypesOrSteps.slice(replacementIndex + 1)
        ];
      }

      return newListWithReplacementSet
        ? [
            ...newListWithReplacementSet.slice(0, editingIndex),
            ...newListWithReplacementSet.slice(editingIndex + 1)
          ]
        : [...allTypesOrSteps.slice(0, editingIndex), ...allTypesOrSteps.slice(editingIndex + 1)];
    }
    return false;
  };

  return (
    <Fragment>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {[removeStep.name, removeType.name].includes(action.name) && (
            <Fragment>
              <Typography variant="body1" component="span">
                {`You are about to remove ${selected} ${action.variant.toLowerCase()}.`}
              </Typography>
              {fullSelected.numberOfDeliverables ? (
                <Typography variant="body1" component="span">
                  {` Please select a replacement for deliverables that are using this ${action.variant.toLowerCase()}.`}
                </Typography>
              ) : null}
            </Fragment>
          )}
        </DialogContentText>
        {[addType.name, editType.name, addStep.name, editStep.name].includes(action.name) && (
          <Field
            component={FormikTextField}
            name="typeOrStepName"
            label={`${action.variant} Name`}
            required
            fullWidth
          />
        )}

        {[removeStep.name, removeType.name].includes(action.name) &&
        fullSelected.numberOfDeliverables ? (
          <Field
            component={FormikDropdownField}
            name="replacementName"
            items={getAvailableReplacementItems()}
            itemType="adminCategories"
            ignoreTouchedValue={true}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <GlobalButton variant="transparent" handleClick={emptyFieldAndClose}>
          Cancel
        </GlobalButton>
        <GlobalButton handleClick={onSubmitHandler}>Confirm</GlobalButton>
      </DialogActions>
    </Fragment>
  );
};

export default HandleCategoryEditForm;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

/**
 * @param {Object} variables Mutation variables
 * @param {string} variables.firstName
 * @param {string} variables.lastName
 * @param {string} variables.title
 * @param {string} variables.phone
 * @param {string} variables.skype
 * @param {string} variables.companyName
 * @param {string} variables.timezone
 * @param {string} variables.address1
 * @param {string} variables.address2
 * @param {string} variables.city
 * @param {string} variables.state
 * @param {string} variables.country
 * @param {string} variables.zipcode
 * @param {boolean} variables.reprImageNull If True removes the image
 * @param {boolean} variables.coverImageNull If True removes the image
 * @param {File} representative_image Avatar file
 * @param {File} cover_image Background file
 * @param {function} onCompleted onCompleted callback
 * @param {function} onError onError callback
 */

const UpdateFreelancerProfileMutation = (
  variables,
  representative_image,
  cover_image,
  onCompleted,
  onError
) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateFreelancerProfileMutation($input: UpdateFreelancerProfileInput!) {
        updateFreelancerProfile(input: $input) {
          updatedFreelancerProfile {
            id
            firstName
            lastName
            fullName
            email
            representativeImageUrl
            freelancer {
              id
              coverImageUrl
              companyName
              nickname
              title
              phone
              timezone
              address1
              address2
              country {
                name
                code
              }
              state
              city
              zipcode
              additionalInfo
              portfolioUrl
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    uploadables: {
      representative_image,
      cover_image
    },
    onCompleted: (response, errors) => {
      onCompleted(response.updateFreelancerProfile, errors);
    },
    onError
  });
};

export default UpdateFreelancerProfileMutation;

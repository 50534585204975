/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SolicitationDetails_solicitation$ref: FragmentReference;
declare export opaque type SolicitationDetails_solicitation$fragmentType: SolicitationDetails_solicitation$ref;
export type SolicitationDetails_solicitation = {|
  +subject: string,
  +replyByDate: ?any,
  +staffCoordinator: {|
    +fullName: ?string,
    +organization: {|
      +name: string
    |},
  |},
  +$refType: SolicitationDetails_solicitation$ref,
|};
export type SolicitationDetails_solicitation$data = SolicitationDetails_solicitation;
export type SolicitationDetails_solicitation$key = {
  +$data?: SolicitationDetails_solicitation$data,
  +$fragmentRefs: SolicitationDetails_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SolicitationDetails_solicitation",
  "type": "SolicitationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subject",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "replyByDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffCoordinator",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "OrgNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '19f1dc858716c1c34ada1945be550c8a';
module.exports = node;

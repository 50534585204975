import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  FormControlLabel,
  Typography,
  IconButton,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Dialog
} from '@material-ui/core';

import GlobalButton from '../../components/UI/GlobalButton';
import TextField from '../form/fields/TextField';
import StyledCheckbox from '../UI/StyledCheckbox';
import { isCanvasBlank } from '../../utils/helpers';
import { errorToast } from '../../toasts';

const DialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '32px 30px 16px'
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '14px',
    color: theme.palette.darkBlue
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    paddingRight: '30px',
    paddingLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      paddingBottom: '12px',
      paddingTop: '12px'
    },
    '& .sigCanvas': {
      border: '1px solid rgba(159,162,181,0.75)',
      borderRadius: '2px'
    },
    '& label': {
      marginTop: '20px',
      '& a': {
        color: theme.palette.primary.main
      }
    }
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    paddingLeft: '22px',
    padding: '16px 22px 30px 3px',
    '& button:first-of-type': {
      marginRight: 'auto',
      border: `1px solid ${theme.palette.colors.darkBlueGrey}`
    }
  }
}))(MuiDialogActions);

const SignaturePadModal = props => {
  const { handleClose, open } = props;
  let sigCanvas = {};
  const [typedSignature, setTypedSignature] = useState('');
  const [consent, setConsent] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const clear = () => {
    sigCanvas.clear();
    sigCanvas.on();
    setTypedSignature('');
    setConsent(false);
  };

  const handleTyping = e => {
    const inputValue = e.target.value;
    setTypedSignature(inputValue);
    sigCanvas.clear();
    if (!inputValue) {
      // if user deletes all input, we reenable canvas so they can draw
      sigCanvas.on();
      return;
    }
    // otherwise we disable canvas before drawing text
    sigCanvas.off();
    const canvas = sigCanvas.getCanvas();
    const ctx = canvas.getContext('2d');
    ctx.font = "44px 'Just Another Hand'";
    ctx.textAlign = 'center';
    ctx.fillText(inputValue, 312, 96);
  };

  const save = () => {
    if (isCanvasBlank(sigCanvas.getCanvas())) {
      errorToast('Signature field can not be blank.');
      return;
    }
    setLoading(true);
    //   pass image data URL to the parent component
    props.handleInsertSignature(sigCanvas.getTrimmedCanvas().toDataURL('image/png'));
    handleClose();
    setLoading(false);
  };

  const cancel = () => {
    setTypedSignature('');
    setConsent(false);
    handleClose();
  };

  return (
    <Dialog onClose={cancel} aria-labelledby="signature-pad-title" open={open} maxWidth="md">
      <DialogTitle id="signature-pad-title" onClose={cancel}>
        Your Signature
      </DialogTitle>
      <DialogContent>
        <SignatureCanvas
          penColor="black"
          ref={ref => {
            sigCanvas = ref;
          }}
          canvasProps={{ width: 624, height: 192, className: 'sigCanvas' }}
        />
        <Typography variant="body1">
          Either draw your signature above, or type it into the field below:
        </Typography>
        <TextField onChange={handleTyping} name="typedSignature" value={typedSignature} />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={consent}
              onChange={() => setConsent(!consent)}
              name="consentCheckbox"
            />
          }
          label={
            <Typography variant="overline">
              I consent to the use of electronic signatures pursuant to the AWEbase{' '}
              <a
                href="https://www.awebase.com/awebase-software-terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use{' '}
              </a>
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <GlobalButton variant="transparent" handleClick={clear}>
          Clear
        </GlobalButton>
        <GlobalButton variant="transparent" handleClick={cancel}>
          Cancel
        </GlobalButton>
        <GlobalButton loading={isLoading} handleClick={save} disabled={!consent}>
          Sign
        </GlobalButton>
      </DialogActions>
    </Dialog>
  );
};

SignaturePadModal.propTypes = {
  /** Determines if signature pad modal should be open */
  open: PropTypes.bool,
  /** Callback function when insert signature button is clicked */
  handleInsertSignature: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

SignaturePadModal.defaultProps = {
  open: false,
  handleInsertSignature: signature => {
    console.info('Sign CLICKED. Generated data URL: ', signature);
  },
  handleClose: () => {
    console.info('Close the modal');
  }
};

export default SignaturePadModal;

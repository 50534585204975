/**
 * @flow
 * @relayHash 5c3dcf7a084cad3b43c252cdb4990a17
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ReleaseList_releases$ref = any;
export type ReleaseListPageQueryVariables = {|
  after?: ?string,
  first?: ?number,
  releaseName?: ?string,
  releaseCode?: ?string,
  productName?: ?string,
  productCode?: ?string,
  deadlineFrom?: ?any,
  deadlineTo?: ?any,
  orderBy?: ?string,
  includeInactive?: ?boolean,
|};
export type ReleaseListPageQueryResponse = {|
  +$fragmentRefs: ReleaseList_releases$ref
|};
export type ReleaseListPageQuery = {|
  variables: ReleaseListPageQueryVariables,
  response: ReleaseListPageQueryResponse,
|};
*/


/*
query ReleaseListPageQuery(
  $after: String
  $first: Int
  $releaseName: String
  $releaseCode: String
  $productName: String
  $productCode: String
  $deadlineFrom: Date
  $deadlineTo: Date
  $orderBy: String
  $includeInactive: Boolean
) {
  ...ReleaseList_releases
}

fragment ReleaseList_releases on Query {
  releasesByOrg(after: $after, first: $first, releaseName: $releaseName, releaseCode: $releaseCode, productName: $productName, productCode: $productCode, deadlineFrom: $deadlineFrom, deadlineTo: $deadlineTo, orderBy: $orderBy, includeInactive: $includeInactive) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        code
        deadline
        product {
          code
          title
          id
        }
        totalBudget
        deliverablesTotalAmount
        hasIncompleteDeliverables
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "releaseName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "releaseCode",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "productName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "productCode",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "deadlineFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "deadlineTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReleaseListPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ReleaseList_releases",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReleaseListPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "releasesByOrg",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "deadlineFrom",
            "variableName": "deadlineFrom"
          },
          {
            "kind": "Variable",
            "name": "deadlineTo",
            "variableName": "deadlineTo"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "includeInactive",
            "variableName": "includeInactive"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          },
          {
            "kind": "Variable",
            "name": "productCode",
            "variableName": "productCode"
          },
          {
            "kind": "Variable",
            "name": "productName",
            "variableName": "productName"
          },
          {
            "kind": "Variable",
            "name": "releaseCode",
            "variableName": "releaseCode"
          },
          {
            "kind": "Variable",
            "name": "releaseName",
            "variableName": "releaseName"
          }
        ],
        "concreteType": "ReleaseNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ReleaseNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ReleaseNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "deadline",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalBudget",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "deliverablesTotalAmount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasIncompleteDeliverables",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ReleaseListPageQuery",
    "id": null,
    "text": "query ReleaseListPageQuery(\n  $after: String\n  $first: Int\n  $releaseName: String\n  $releaseCode: String\n  $productName: String\n  $productCode: String\n  $deadlineFrom: Date\n  $deadlineTo: Date\n  $orderBy: String\n  $includeInactive: Boolean\n) {\n  ...ReleaseList_releases\n}\n\nfragment ReleaseList_releases on Query {\n  releasesByOrg(after: $after, first: $first, releaseName: $releaseName, releaseCode: $releaseCode, productName: $productName, productCode: $productCode, deadlineFrom: $deadlineFrom, deadlineTo: $deadlineTo, orderBy: $orderBy, includeInactive: $includeInactive) {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        name\n        code\n        deadline\n        product {\n          code\n          title\n          id\n        }\n        totalBudget\n        deliverablesTotalAmount\n        hasIncompleteDeliverables\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'edcfdfffaeb5d52e9c85f2dafbd5d9fc';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import SearchableDropdown from '../../../../shared/components/form/SearchableDropdown';

const staffContractorQuery = graphql`
  query SearchableStaffAndContractorDropdownQuery($staffName: String, $contractorName: String) {
    staffByOrg(staffName: $staffName, orderBy: "lastName", first: 10, includeInactive: false) {
      edgeCount
      totalCount
      edges {
        node {
          id
          fullName
        }
      }
    }
    contractorsByOrg(
      contractorName: $contractorName
      orderBy: "fullName"
      first: 10
      excludeWithConflicts: true
      includeInactive: false
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

const SearchableStaffAndContractorDropdown = props => {
  const {
    onChange,
    noLabel,
    label,
    userContext: {
      firstName,
      lastName,
      orgStaff: { id: orgStaffId }
    },
    showCurrentUser,
    ...otherProps
  } = props;

  const getFetchVariables = inputValue => {
    return {
      staffName: inputValue,
      contractorName: inputValue
    };
  };

  const formatOptions = data => {
    let options = [];
    if (!data) {
      return options;
    }
    if (data.staffByOrg) {
      options = [
        ...options,
        ...data.staffByOrg.edges
          .filter(staff => staff.node.id !== orgStaffId)
          .map(staff => ({
            value: staff.node.id,
            label: staff.node.fullName
          }))
      ];
    }
    if (data.contractorsByOrg) {
      options = [
        ...options,
        ...data.contractorsByOrg.edges.map(contractor => ({
          value: contractor.node.id,
          label: contractor.node.fullName
        }))
      ];
    }
    options.sort((a, b) => a.label.split(' ')[1].localeCompare(b.label.split(' ')[1]));
    options = options.slice(0, 10);

    options = [
      { value: orgStaffId, label: `${firstName} ${lastName} (Me)` },
      { value: 'unassigned', label: 'Unassigned' },
      ...options
    ];

    return options;
  };

  return (
    <SearchableDropdown
      resultKey="staffByOrg+contractorsByOrg"
      query={staffContractorQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      label="Assignee"
      placeholder="Select Contractor or Staff..."
      {...otherProps}
    />
  );
};

export default withUserContext(SearchableStaffAndContractorDropdown);

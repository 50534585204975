/**
 * @flow
 * @relayHash 98e27b07a42836f1cc8069660028d571
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeactivateProductInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeactivateProductMutationVariables = {|
  input: DeactivateProductInput
|};
export type DeactivateProductMutationResponse = {|
  +deactivateProduct: ?{|
    +updatedProduct: ?{|
      +id: string,
      +isActive: boolean,
    |}
  |}
|};
export type DeactivateProductMutation = {|
  variables: DeactivateProductMutationVariables,
  response: DeactivateProductMutationResponse,
|};
*/


/*
mutation DeactivateProductMutation(
  $input: DeactivateProductInput!
) {
  deactivateProduct(input: $input) {
    updatedProduct {
      id
      isActive
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeactivateProductInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deactivateProduct",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeactivateProductPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedProduct",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isActive",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeactivateProductMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeactivateProductMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeactivateProductMutation",
    "id": null,
    "text": "mutation DeactivateProductMutation(\n  $input: DeactivateProductInput!\n) {\n  deactivateProduct(input: $input) {\n    updatedProduct {\n      id\n      isActive\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9e226b1ca760e87cb7d3dfc67949fb7';
module.exports = node;

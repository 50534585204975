import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams, withRouter } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import ContractorJobsTopSection from './ContractorJobsTopSection';
import ContractorJobsList from './ContractorJobsList';

const ContractorJobsTab = props => {
  const { id } = useParams();
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ContractorJobsTabQuery(
          $id: ID!
          $first: Int
          $after: String
          $orderBy: String
          $jobDeadlineFrom: Date
          $jobDeadlineTo: Date
          $jobId: String
          $staffId: String
          $status: String
          $paymentStatus: String
          $releaseId: String
          $productId: String
          $category: ID
          $includeInactive: Boolean
          $name: String
        ) {
          contractor(id: $id) {
            ...ContractorJobsTopSection_contractor
            ...ContractorJobsList_contractor
          }
          jobStatuses: __type(name: "JobStatus") {
            ...ContractorJobsList_statusTypes
          }
          jobPaymentStatuses: __type(name: "JobPaymentStatus") {
            ...ContractorJobsList_paymentStatusTypes
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <>
          <ContractorJobsTopSection relayProps={relayProps} contractor={relayProps.contractor} />
          <ContractorJobsList
            contractor={relayProps.contractor}
            statusTypes={relayProps.jobStatuses}
            paymentStatusTypes={relayProps.jobPaymentStatuses}
            {...props}
          />
        </>
      )}
    />
  );
};

export default withTableHistoryProvider(withRouter(ContractorJobsTab), 'orgContrJobs', {
  orderBy: 'name',
  initialFilters: {
    releaseId: null,
    productId: null,
    category: null,
    jobDeadlineFrom: null,
    jobDeadlineTo: null,
    status: '',
    paymentStatus: '',
    staffId: null,
    contractorId: null,
    includeInactive: false,
    name: '',
    onlyFollowed: false
  }
});

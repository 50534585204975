/**
 * @flow
 * @relayHash 1fa78a0917a3fb7081765f7ec481f858
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CurrencyCodes = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type CreateBatchDeliverableInput = {|
  title: string,
  release: string,
  category: string,
  categoryType?: ?string,
  assignedStaff?: ?string,
  amount?: ?any,
  currencyCode?: ?CurrencyCodes,
  description?: ?string,
  quantity?: ?number,
  dueDate?: ?any,
  tags?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type CreateBatchDeliverableMutationVariables = {|
  input: CreateBatchDeliverableInput
|};
export type CreateBatchDeliverableMutationResponse = {|
  +createBatchDeliverable: ?{|
    +isCreated: ?boolean,
    +deliverables: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +release: ?{|
            +hasDeliverables: ?boolean,
            +categoryInfo: ?$ReadOnlyArray<?{|
              +category: ?{|
                +id: string,
                +name: string,
              |},
              +contractedAmount: ?any,
              +pendingAmount: ?any,
            |}>,
            +deliverablesTotalAmount: ?any,
            +deliverablesTotalOriginalAmount: ?any,
            +deliverablesTotalPendingAmount: ?any,
          |},
        |}
      |}>,
    |},
  |}
|};
export type CreateBatchDeliverableMutation = {|
  variables: CreateBatchDeliverableMutationVariables,
  response: CreateBatchDeliverableMutationResponse,
|};
*/


/*
mutation CreateBatchDeliverableMutation(
  $input: CreateBatchDeliverableInput!
) {
  createBatchDeliverable(input: $input) {
    isCreated
    deliverables {
      totalCount
      edges {
        node {
          id
          release {
            hasDeliverables
            categoryInfo {
              category {
                id
                name
              }
              contractedAmount
              pendingAmount
            }
            deliverablesTotalAmount
            deliverablesTotalOriginalAmount
            deliverablesTotalPendingAmount
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateBatchDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isCreated",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasDeliverables",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "categoryInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "ReleaseCategoryInfo",
  "plural": true,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "category",
      "storageKey": null,
      "args": null,
      "concreteType": "CategoryNode",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "contractedAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pendingAmount",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalAmount",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalOriginalAmount",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalPendingAmount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateBatchDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createBatchDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateBatchDeliverablePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNodeConnection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateBatchDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createBatchDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateBatchDeliverablePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNodeConnection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateBatchDeliverableMutation",
    "id": null,
    "text": "mutation CreateBatchDeliverableMutation(\n  $input: CreateBatchDeliverableInput!\n) {\n  createBatchDeliverable(input: $input) {\n    isCreated\n    deliverables {\n      totalCount\n      edges {\n        node {\n          id\n          release {\n            hasDeliverables\n            categoryInfo {\n              category {\n                id\n                name\n              }\n              contractedAmount\n              pendingAmount\n            }\n            deliverablesTotalAmount\n            deliverablesTotalOriginalAmount\n            deliverablesTotalPendingAmount\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c9f61b0b811ee019aef3a973d288833';
module.exports = node;

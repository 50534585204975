import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

const DeleteDeliverableFinalFileMutation = (id, callback = () => {}) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation DeleteDeliverableFinalFileMutation($input: DeleteDeliverableFinalFileInput!) {
        deleteDeliverableFinalFile(input: $input) {
          isDeleted
          deliverable {
            id
            finalFiles(orderBy: "-created") {
              edges {
                node {
                  id
                  created
                  fileName
                  fileSize
                  description
                  fileUrl
                  storageClass
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: {
        id
      }
    },
    onCompleted: (response, errors) => {
      callback(response.updateDeliverable, errors);
    },
    onError: err => console.error(err)
  });
};

DeleteDeliverableFinalFileMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  )
};

export default DeleteDeliverableFinalFileMutation;

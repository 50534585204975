import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import ConnectWithOrganizationMutation from './mutations/ConnectWithOrganizationMutation';
import DetailsSection from '../../../shared/components/common/DetailsSection';
import DetailsRow from '../../../shared/components/UI/DetailsRow';
import { IC_TARGETS } from '../../../shared/services/intercomService';
import { getCountryName } from '../../../shared/utils/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    padding: '30px',
    '& p': {
      marginBottom: '18px'
    }
  }
}));

const OrganizationDetailsPending = ({ organization }) => {
  const {
    organization: { id, name, email, phone, address1, country, city, state, zipcode, website }
  } = organization;
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);

  const onClick = () => {
    setSubmitting(true);
    ConnectWithOrganizationMutation(id, response => {
      // only if there is error we update state, otherwise the component will switch to different one, after response is received
      // and we should not update state anymore on unmounted component
      if (!response) {
        setSubmitting(false);
      }
    });
  };

  return (
    <div className={classes.root}>
      <DetailsSection title={name}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DetailsRow title="Email" content={email} />
            <DetailsRow title="Phone" content={phone} />
            <DetailsRow title="Address" content={address1} />
            <DetailsRow title="City" content={city} />
          </Grid>
          <Grid item xs={6}>
            <DetailsRow title="State" content={state} />
            <DetailsRow title="Zipcode" content={zipcode} />
            <DetailsRow title="Country" content={getCountryName(country)} />
            <DetailsRow
              title="Website"
              content={
                <a target="_blank" href={website} rel="noopener noreferrer">
                  {website}
                </a>
              }
            />
          </Grid>
        </Grid>
      </DetailsSection>
      <Typography variant="body2">
        Accept pending invitation to connect with this organization.
      </Typography>
      <GlobalButton
        handleClick={onClick}
        noMargin
        data-intercom-target={IC_TARGETS.orgAcceptButton}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        Accept Invitation
      </GlobalButton>
    </div>
  );
};

export default createFragmentContainer(OrganizationDetailsPending, {
  organization: graphql`
    fragment OrganizationDetailsPending_organization on Query
      @argumentDefinitions(id: { type: "ID!" }) {
      organization(id: $id) {
        id
        name
        address1
        city
        state
        website
        zipcode
        country {
          name
        }
        phone
        email
      }
    }
  `
});

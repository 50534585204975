import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const BatchArchiveDeliverableFinalFilesMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation BatchArchiveDeliverableFinalFilesMutation(
          $input: ArchiveBatchDeliverableFinalFilesInput!
        ) {
          archiveBatchDeliverableFinalFiles(input: $input) {
            isArchived
            organization {
              storage {
                activeStorageFinalFiles
                archivedStorageFinalFiles
              }
            }
          }
        }
      `,
      variables: {
        input
      },
      onCompleted: resolve,
      onError: reject
    });
  });

export default BatchArchiveDeliverableFinalFilesMutation;

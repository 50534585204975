import React, { Fragment, useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import QuickViewAvatarHeader from '../../../../../shared/components/UI/QuickViewAvatarHeader';
import CommentThread from '../../../../../shared/components/comment/CommentThread';
import { mapSolicitationComments, getEnvironment } from '../../../../../shared/utils/helpers';
import AddSolicitationCommentMutation from '../../../../../shared/mutations/solicitations/AddSolicitationCommentMutation';
import UpdateSolicitationCommentMutation from '../../../../../shared/mutations/solicitations/UpdateSolicitationCommentMutation';
import RemoveSolicitationCommentMutation from '../../../../../shared/mutations/solicitations/RemoveSolicitationCommentMutation';
import { SOLICITATION_STATES } from '../../../../../shared/constants';
import useInterval from '../../../../../shared/hooks/useInterval';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';

const solicitationChatViewQuery = graphql`
  query SolicitationChatViewQuery($id: ID!, $contractorId: ID!) {
    solicitation(id: $id, contractorId: $contractorId) {
      state
      comments {
        edges {
          node {
            id
            content
            contentEditedAt
            repliedTo {
              content
              created
              user {
                fullName
              }
            }
            contractor {
              fullName
              representativeImageUrl
              freelancer {
                user {
                  id
                }
              }
            }
            createdByStaff {
              fullName
              representativeImageUrl
              user {
                id
              }
            }
            created
          }
        }
      }
    }
    contractor(id: $contractorId) {
      id
      fullName
      representativeImageUrl
    }
  }
`;
const SolicitationChatView = props => {
  const { solicitationId, contractorId, allowAddNew, refetchNotifications = () => {} } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [solicitation, setSolicitation] = useState(null);
  const [contractor, setContractor] = useState(null);

  useEffectWithStatus(
    status => {
      // only show Loading indicator when switching between contractors, not everytime getData is called on interval
      setIsLoading(true);
      getData(status);
    },
    [contractorId]
  );

  const getData = (status = { mounted: true }) => {
    fetchQuery(getEnvironment(), solicitationChatViewQuery, {
      id: solicitationId,
      contractorId
    }).then(response => {
      if (status.mounted) {
        setIsLoading(false);
        setSolicitation(response.solicitation);
        setContractor(response.contractor);
        refetchNotifications();
      }
    });
  };

  useInterval(
    () => {
      // polling for new messages every 1min
      // new messages will come in only in ACTIVE state, so setting interval only to it
      if (solicitation.state === SOLICITATION_STATES.active) {
        getData();
      }
    },
    60000,
    contractorId,
    solicitation && solicitation.state
  );

  const handleSubmitComment = ({ comment, repliedTo }, cb) => {
    AddSolicitationCommentMutation(
      {
        solicitationId,
        contractorId,
        content: comment,
        ...(repliedTo && { repliedTo: repliedTo.commentId })
      },
      response => {
        if (response && response.isUpdated) {
          cb();
          setSolicitation(response.solicitation);
        }
      }
    );
  };

  const handleUpdateComment = (commentId, content, cb) => {
    const variables = {
      commentId,
      content
    };
    UpdateSolicitationCommentMutation(variables, response => {
      if (response && response.isUpdated) {
        cb();
        setSolicitation(response.solicitation);
      }
    });
  };

  const handleDeleteComment = commentId => {
    RemoveSolicitationCommentMutation(commentId, response => {
      if (response && response.isUpdated) {
        setSolicitation(response.solicitation);
      }
    });
  };

  if (solicitation && solicitation.comments && contractor && !isLoading) {
    return (
      <Fragment>
        <QuickViewAvatarHeader
          fullName={contractor.fullName}
          representativeImageUrl={contractor.representativeImageUrl}
        />
        <DetailsSection>
          <CommentThread
            formTitle=""
            comments={mapSolicitationComments(solicitation.comments)}
            handleAddComment={allowAddNew ? handleSubmitComment : null}
            handleReplyToComment={allowAddNew ? handleSubmitComment : null}
            handleUpdateComment={handleUpdateComment}
            handleDeleteComment={
              solicitation.state === SOLICITATION_STATES.active ? handleDeleteComment : null
            }
            noEditButton={solicitation.state !== SOLICITATION_STATES.active}
          />
        </DetailsSection>
      </Fragment>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default SolicitationChatView;

import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Typography, Box } from '@material-ui/core';
import styled from 'styled-components';
import { withRouter, NavLink } from 'react-router-dom';

const TabItem = styled(NavLink)`
  text-decoration: none;
  outline: none;
  width: 100%;
  display: block;
  border: none;
  background: transparent;
  position: relative;
  padding: 0;
  border-top: 1px solid ${props => props.theme.palette.colors.progressBarLightGrey};
  border-bottom: 1px solid ${props => props.theme.palette.colors.progressBarLightGrey};

  &:not(:first-child) {
    margin-top: -1px;
  }

  > div {
    padding: ${props => props.theme.spacing(1.5)}px;
    display: flex;
    justify-content: space-between;
  }

  &.active {
    z-index: 1;
    background: ${props => props.theme.palette.primary.light};
    border-color: ${props => props.theme.palette.primary.main};

    > div {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`;

const Tabs = props => {
  const {
    job: { paymentRequests, invoices },
    match
  } = props;

  return (
    <Box mt={3}>
      {[
        {
          label: 'Payment Requests',
          itemsCount: paymentRequests.totalCount,
          to: match.url,
          exact: true
        },
        {
          label: 'Invoices',
          itemsCount: invoices.totalCount,
          to: `${match.url}/invoices`
        }
      ].map(({ label, itemsCount, ...navLinkProps }, i) => (
        <TabItem key={i} {...navLinkProps}>
          <Typography variant="body2" component="div">
            {label} <span>{itemsCount}</span>
          </Typography>
        </TabItem>
      ))}
    </Box>
  );
};

export default createFragmentContainer(withRouter(Tabs), {
  job: graphql`
    fragment Tabs_job on JobNode {
      id
      paymentRequests(orderBy: "-created") {
        totalCount
      }
      invoices(orderBy: "-created") {
        totalCount
      }
    }
  `
});

import { makeStyles } from '@material-ui/core/styles';
import colors from '../common/colors';
export const customizationStyles = () => ({
  bold: {
    fontWeight: 'bold'
  },
  greyText: {
    color: colors.mediumBlueGrey,
    fontWeight: 'bold'
  },
  textMarginRight: {
    marginRight: '5px'
  },
  naviCustomizeMargin: {
    marginLeft: '57px'
  },
  marginBottom: { marginBottom: '30px' },
  container: {
    display: 'flex'
  },
  containerColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  justifySpaceBetween: {
    justifyContent: 'space-between'
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  rowHeight: {
    height: '40px'
  },
  textWidth: {
    width: '200px'
  },
  textWidth280: {
    width: '280px'
  },
  topSectionHeight: {
    height: '30px'
  }
});

export const useCustomizationStyles = makeStyles(customizationStyles);

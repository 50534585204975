import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseSquareButton from './SquareButton';
import { triggerFileDownload } from '../../utils/helpers';

const SquareButton = styled(BaseSquareButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const DownloadFileSquareButton = props => {
  const {
    fileName,
    fileUrl,
    handleDownloadClick,
    icon,
    disabled,
    id,
    isVisible,
    tooltip,
    ...otherProps
  } = props;
  const [downloadUrl, setDownloadUrl] = useState(fileUrl);
  const downloadAnchorID = `${fileName}-download`;

  const onClickHandler = event => {
    event.stopPropagation();
    if (handleDownloadClick) {
      handleDownloadClick(url => {
        setDownloadUrl(url);
        triggerFileDownload(downloadAnchorID);
      });
    } else {
      triggerFileDownload(downloadAnchorID);
    }
  };

  return (
    <Fragment>
      {!disabled && (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          id={downloadAnchorID}
          href={downloadUrl}
          target="_blank"
          download={fileName}
          rel="noopener noreferrer"
          style={{ display: 'none' }}
        />
      )}
      <SquareButton
        id={id}
        onClick={onClickHandler}
        disabled={disabled}
        icon={icon}
        noMargin
        tooltipTitle={tooltip ? tooltip : 'Download'}
        {...otherProps}
      />
    </Fragment>
  );
};

DownloadFileSquareButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUrl: props => {
    if (!props.fileUrl && !props.handleDownloadClick && !props.disabled) {
      return new Error('One of fileUrl or handleDownloadClick was not specified.');
    }
    if (props.fileUrl) {
      PropTypes.checkPropTypes({ fileUrl: PropTypes.string }, { fileUrl: props.fileUrl });
    }
    return null;
  },
  // use when we only want to fetch download url upon click
  handleDownloadClick: props => {
    if (!props.fileUrl && !props.handleDownloadClick && !props.disabled) {
      return new Error('One of fileUrl or handleDownloadClick was not specified.');
    }
    if (props.handleDownloadClick) {
      PropTypes.checkPropTypes(
        { handleDownloadClick: PropTypes.func },
        { handleDownloadClick: props.handleDownloadClick }
      );
    }
    return null;
  }
};

DownloadFileSquareButton.defaultProps = {
  icon: 'download',
  isVisible: true
};

export default DownloadFileSquareButton;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SolicitationDetailsMainContent_solicitation$ref: FragmentReference;
declare export opaque type SolicitationDetailsMainContent_solicitation$fragmentType: SolicitationDetailsMainContent_solicitation$ref;
export type SolicitationDetailsMainContent_solicitation = {|
  +createdBy: ?{|
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +staffCoordinator: {|
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +subject: string,
  +dateSent: ?any,
  +replyByDate: ?any,
  +numberOfDeliverables: ?number,
  +totalDeliverableAmount: ?number,
  +message: ?string,
  +state: SolicitationState,
  +$refType: SolicitationDetailsMainContent_solicitation$ref,
|};
export type SolicitationDetailsMainContent_solicitation$data = SolicitationDetailsMainContent_solicitation;
export type SolicitationDetailsMainContent_solicitation$key = {
  +$data?: SolicitationDetailsMainContent_solicitation$data,
  +$fragmentRefs: SolicitationDetailsMainContent_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "SolicitationDetailsMainContent_solicitation",
  "type": "SolicitationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "createdBy",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffCoordinator",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subject",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dateSent",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "replyByDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalDeliverableAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '598e3cf1086611153edc10c60aca9e32';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../../relay/QueryRenderer';
import SelectExistingTab from './SelectExistingTab';

const SelectExistingTabRenderer = ({ deliverableId, ...rest }) => (
  <QueryRenderer
    query={graphql`
      query SelectExistingTabRendererQuery($id: ID!, $orderBy: String) {
        deliverable(id: $id) {
          ...SelectExistingTab_deliverable
        }
      }
    `}
    variables={{ id: deliverableId, orderBy: '-created' }}
    render={relayProps => <SelectExistingTab {...rest} deliverable={relayProps.deliverable} />}
  />
);

export default SelectExistingTabRenderer;

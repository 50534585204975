import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Formik, Form, Field } from 'formik';

import { Paper, Typography } from '@material-ui/core';

import { useDetailsStyles } from '../../../../../../shared/styles/common/useDetailsStyles';
import FormikTextField from '../../../../../../shared/components/form/FormikTextField';
import SaveButton from '../../../../../../shared/components/UI/SaveButton';
import DetailsSection from '../../../../../../shared/components/common/DetailsSection';
import DetailsRow from '../../../../../../shared/components/UI/DetailsRow';
import EditButton from '../../../../../../shared/components/UI/EditButton';
import CancelButton from '../../../../../../shared/components/UI/CancelButton';
import CategoryTitleHeader from '../../../../../../shared/components/UI/CategoryTitleHeader';
import StatusIndicator from '../../../../../../shared/components/UI/StatusIndicator';
import GlobalButton from '../../../../../../shared/components/UI/GlobalButton';
import withUserContext from '../../../../../../shared/contexts/userContext/withUserContext';
import { errorToast, successToast } from '../../../../../../shared/toasts';
import { ReactComponent as StaffManagementIcon } from '../../../../../../shared/images/staff.svg';
import UpdateStaffUserMutation from '../../mutations/UpdateStaffUserMutation';
import DeactivateStaffUserMutation from '../../mutations/DeactivateStaffUserMutation';
import ResendCompleteStaffAccountEmailMutation from '../../mutations/ResendCompleteStaffAccountEmailMutation';
import getUserData from '../../../../../mutations/getUserData';
import DetailsRowDate from '../../../../../../shared/components/UI/DetailsRowDate';
import { yupSchemas } from '../../../../../../shared/validations';
import Dialog from '../../../../../../shared/components/common/Dialog';
import useDialog from '../../../../../../shared/hooks/useDialog';
import TransferOwnershipForm from './TransferOwnershipForm';
import FormikEditableImage from '../../../../../../shared/components/form/formikFields/FormikEditableImage';
import FormikRolesSelect from '../../roleTypes/FormikRolesSelect';
import AddStaffUserForm from '../../AddStaffUserForm';
import useGlobalCreateModalStyles from '../../../../../../shared/styles/common/useGlobalCreateModalStyles';
import useSideBar from '../../../../../../shared/hooks/useSideBar';

const StaffDetails = props => {
  const { staff, userContext } = props;
  const [isEditing, setEditing] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [isTransferringOwnership, setTransferringOwnership] = useState(false);
  const [isDialogOpen, toggleDialogOpen] = useDialog();

  const classes = useDetailsStyles();
  const isOpen = useSideBar()[0];
  const createModalClasses = useGlobalCreateModalStyles({ isOpen });

  const toggleEditHandler = () => {
    setEditing(!isEditing);
  };

  const deactivateUserHandler = () => {
    setUpdating(true);
    DeactivateStaffUserMutation(staff.id, () => {
      setUpdating(false);
    });
  };

  const activateUserHandler = () => {
    toggleDialogOpen();
  };

  const resendCompleteAccountEmail = () => {
    ResendCompleteStaffAccountEmailMutation(staff.id, response => {
      if (response && response.emailSent) {
        successToast('Email was sent!');
      }
      if (response && !response.emailSent) {
        errorToast('Email failed to send.');
      }
    });
  };

  const transferAccountOwnership = () => {
    setTransferringOwnership(true);
  };

  const submitHandler = (values, { setFieldError }) => {
    const { title, firstName, lastName, email, role, reprImage, reprImageNull } = values;
    const file = reprImage.file;
    UpdateStaffUserMutation(
      {
        id: staff.id,
        title,
        firstName,
        lastName,
        email,
        role,
        reprImageNull
      },
      file,
      (response, errors) => {
        if (errors && errors[0].fields && errors[0].fields.email) {
          setFieldError('email', errors[0].fields.email);
        }
        if (response && response.updatedStaffUser) {
          setEditing(false);
        }
        if (response && response.emailFailed) {
          errorToast('User information was updated but sending notification email failed.');
        }
        if (userContext.id === staff.user.id) {
          // user edited his own profile
          getUserData(userContext.setUserInfo);
        }
      }
    );
  };

  const initialValues = staff
    ? {
        firstName: staff.user.firstName ? staff.user.firstName : '',
        lastName: staff.user.lastName ? staff.user.lastName : '',
        title: staff.title ? staff.title : '',
        role: staff.role ? staff.role.id : '',
        email: staff.user.email ? staff.user.email : '',
        reprImageNull: false,
        reprImage: {
          src: staff.representativeImageUrl,
          file: null
        }
      }
    : {};

  const validationSchema = yupSchemas.shape({
    firstName: yupSchemas.string(true),
    lastName: yupSchemas.string(true),
    title: yupSchemas.string(true),
    email: yupSchemas.email(true),
    reprImage: yupSchemas.shape({
      file: yupSchemas.avatarFileSize
    })
  });

  return (
    <Fragment>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitHandler}
      >
        {({ submitForm }) => (
          <Form>
            <div className={classes.topSectionHeight}>
              <div className={classes.containerSpaceBetween}>
                <div className={clsx(classes.containerColumn, classes.alignFlexStart)}>
                  <CategoryTitleHeader
                    title={staff.fullName}
                    icon={StaffManagementIcon}
                    link="/admin/staff-management/users"
                  />
                  <StatusIndicator
                    statusCode={staff.user.isActive}
                    statusTypes={[]}
                    variant="active"
                  />
                </div>
                {isEditing ? (
                  <div className={classes.containerAlignFlexStart}>
                    <CancelButton id="cancelStaff" onClick={() => toggleEditHandler()} />
                    <SaveButton id="saveStaff" />
                  </div>
                ) : (
                  <div className={classes.containerAlignFlexStart}>
                    <EditButton id="editStaff" onClick={toggleEditHandler} />
                    {!staff.user.lastLogin && staff.user.isActive && (
                      <GlobalButton handleClick={resendCompleteAccountEmail}>
                        Resend Account Verification Email
                      </GlobalButton>
                    )}
                    {staff.isOwner && userContext.orgStaff.isOwner && (
                      <GlobalButton handleClick={transferAccountOwnership}>
                        Transfer Ownership
                      </GlobalButton>
                    )}
                  </div>
                )}
              </div>
            </div>
            <Paper>
              <div className={classes.container}>
                <div className={classes.profileLeft}>
                  <Field
                    name="reprImage"
                    nameNull="reprImageNull"
                    editing={isEditing}
                    component={FormikEditableImage}
                    imageProps={{ alt: staff.fullName }}
                    uploadButtonId="uploadImageStaff"
                    deleteButtonId="deleteImageStaff"
                  />
                </div>
                <div className={classes.profileRight}>
                  {isEditing ? (
                    <Fragment>
                      <DetailsSection noBorder title="Account Info">
                        <div className={classes.container}>
                          <div className={classes.containerColumn}>
                            <div className={classes.formField}>
                              <Field
                                name="firstName"
                                label="First Name"
                                placeholder="First Name"
                                component={FormikTextField}
                                fullWidth
                              />
                            </div>
                            <div className={classes.formField}>
                              <Field
                                name="title"
                                label="Title"
                                placeholder="Title value"
                                component={FormikTextField}
                                fullWidth
                              />
                            </div>
                            {!staff.isOwner && (
                              // We cannot change role of the owner
                              <div className={classes.formField}>
                                <Field
                                  required
                                  name="role"
                                  component={FormikRolesSelect}
                                  label="Role"
                                />
                              </div>
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.containerColumn,
                              classes.deliverableContainerPadding
                            )}
                          >
                            <div className={classes.formField}>
                              <Field
                                name="lastName"
                                label="Last Name"
                                placeholder="Last Name"
                                component={FormikTextField}
                                fullWidth
                              />
                            </div>
                            <div className={classes.formField}>
                              <Field
                                name="email"
                                label="Email"
                                placeholder="Email address"
                                component={FormikTextField}
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                      </DetailsSection>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <DetailsSection noBorder title="Account Info">
                        <div className={classes.container}>
                          <div className={classes.containerColumn}>
                            <DetailsRow title="Name" content={staff.fullName} />
                            <DetailsRow title="Title" content={staff.title} />
                            <DetailsRow title="Role" content={staff.roleLabel} />
                          </div>
                          <div
                            className={clsx(
                              classes.containerColumn,
                              classes.deliverableContainerPadding
                            )}
                          >
                            <DetailsRow title="Email" content={staff.user.email} />
                            <DetailsRowDate
                              title="Last access"
                              date={staff.user.lastLogin}
                              emptyText="Invited"
                            />
                          </div>
                        </div>
                      </DetailsSection>
                    </Fragment>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.greyBorderBottom}></div>
                <div className={classes.profileLeft}>
                  <Typography variant="h3" className={classes.marginBottom}>
                    Maintenance
                  </Typography>
                  {staff.user.isActive && !staff.isOwner && (
                    <GlobalButton
                      handleClick={deactivateUserHandler}
                      variant="secondary"
                      noMargin
                      disabled={isEditing || isUpdating}
                    >
                      Mark User Inactive
                    </GlobalButton>
                  )}
                  {!staff.user.isActive && (
                    <GlobalButton
                      handleClick={activateUserHandler}
                      variant="secondary"
                      noMargin
                      disabled={isEditing || isUpdating}
                    >
                      Activate User Account
                    </GlobalButton>
                  )}
                </div>
              </div>
            </Paper>
          </Form>
        )}
      </Formik>
      <Dialog
        title="Transfer Ownership"
        isDialogOpen={isTransferringOwnership}
        closeDialog={() => setTransferringOwnership(false)}
      >
        <TransferOwnershipForm
          handleClose={() => setTransferringOwnership(false)}
          userContext={userContext}
        />
      </Dialog>
      <Dialog
        className={clsx(createModalClasses.globalCreateDialog, createModalClasses.defaultWidth)}
        title="Activate User"
        isDialogOpen={isDialogOpen}
        closeDialog={toggleDialogOpen}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <AddStaffUserForm
          handleClose={toggleDialogOpen}
          toRefetchData={() => {}}
          activateUserId={staff.id}
        />
      </Dialog>
    </Fragment>
  );
};

export default withUserContext(
  createFragmentContainer(StaffDetails, {
    staff: graphql`
      fragment StaffDetails_staff on StaffNode {
        id
        fullName
        title
        roleLabel
        isOwner
        role {
          id
          name
        }
        representativeImageUrl
        user {
          id
          email
          firstName
          lastName
          isActive
          lastLogin
        }
      }
    `
  })
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

export default (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateContractorMutation($input: UpdateContractorInput!) {
        updateContractor(input: $input) {
          updatedContractor {
            id
            firstName
            lastName
            fullName
            nickname
            title
            email
            phone
            timezone
            skype
            companyName
            portfolioUrl
            invitationEmailSent
            internalId
            address1
            address2
            city
            state
            zipcode
            country {
              name
              code
            }
            representativeImageUrl
            tags {
              edges {
                node {
                  id
                  name
                }
              }
            }
            freelancer {
              id
            }
            coverImageUrl
            conflictingContractor {
              id
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.updateContractor, errors);
    }
  });
};

import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import FormikTextField from '../form/FormikTextField';
import GlobalButton from '../UI/GlobalButton';
import { DialogActions } from '../common/Dialog';
import { ERROR_LABELS, ACCEPTED_FILE_SIZES } from '../../constants';
import withUserContext from '../../contexts/userContext/withUserContext';
import {
  getSizeLabel,
  getSizeInBytes,
  getMiscFileMaxSizeFromUserContext
} from '../../utils/helpers';
import FormikDropzone from '../form/formikFields/FormikDropzone';

const AssetForm = props => {
  const { onSubmit, onCancel, editedAsset, userContext, maxFileSize: passedMaxFileSize } = props;
  const maxFileSize =
    passedMaxFileSize ||
    getMiscFileMaxSizeFromUserContext(userContext) ||
    ACCEPTED_FILE_SIZES.default;
  const size = getSizeInBytes(maxFileSize);
  const label = getSizeLabel(maxFileSize);

  let formikProps = {
    onSubmit,
    initialValues: { fileName: '', description: '', file: null },
    validationSchema: Yup.object().shape({
      fileName: Yup.string()
        .min(3, 'Name should be at least 3 characters.')
        .max(255, 'Name should be less than 255 characters.'),
      file: Yup.mixed()
        .required(ERROR_LABELS.fileRequired)
        .test('fileSize', label, value => value && value.size <= size),
      description: Yup.string()
    })
  };

  if (editedAsset) {
    const { fileName, description, id } = editedAsset.node;
    formikProps = {
      onSubmit,
      initialValues: { fileName, description: description ? description : '', id },
      validationSchema: Yup.object().shape({
        fileName: Yup.string()
          .required(ERROR_LABELS.fileNameRequired)
          .min(3, 'Name should be at least 3 characters.')
          .max(255, 'Name should be less than 255 characters.'),
        description: Yup.string()
      })
    };
  }

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Grid container spacing={2}>
            {!editedAsset && (
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="file"
                  label="File"
                  type="file"
                  component={FormikDropzone}
                  accept=""
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Field fullWidth name="fileName" label="Asset name" component={FormikTextField} />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                name="description"
                label="Description"
                multiline
                rows="8"
                component={FormikTextField}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <GlobalButton
              id="cancelCreateAssetForm"
              variant="transparent"
              handleClick={onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </GlobalButton>
            <GlobalButton
              id="saveAsset"
              handleClick={submitForm}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {editedAsset ? 'Save changes' : 'Add file'}
            </GlobalButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(AssetForm);

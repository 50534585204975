import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CloseIcon from '@material-ui/icons/Close';
import AssetCardImage from '../../assets/AssetCardImage';

const AssetContainer = styled.div`
  position: relative;
`;

const RemoveButton = styled.button`
  border: none;
  padding: 0;
  background: #4a4554;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    font-size: 14px;

    path {
      stroke: #fff;
      stroke-width: 1px;
    }
  }
`;

const Title = styled(Typography).attrs({ variant: 'body2' })`
  && {
    margin-top: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Container = styled.span`
  display: inline-flex;
  flex-direction: column;
  margin: 4px 24px 12px 0;

  ${props =>
    props.active &&
    css`
      ${AssetContainer} {
        border-radius: 2px;
        box-shadow: 0 0 0 2px ${props => fade(props.theme.palette.primary.main, 0.4)};
      }

      ${Title} {
        color: ${props => props.theme.palette.primary.main};
      }
    `};

  ${props =>
    props.onClick &&
    props.src &&
    css`
      cursor: pointer;
    `};

  &,
  ${AssetContainer} {
    width: ${props => props.width}px;
  }
`;

const ReferenceFilePreview = ({
  onRemove,
  title,
  src,
  reviewInterface,
  width = 200,
  ...containerProps
}) => {
  const handleRemoveClick = e => {
    e.preventDefault();
    onRemove();
  };

  return (
    <Container {...containerProps} width={width}>
      <AssetContainer>
        <AssetCardImage
          src={src}
          imageContainerProps={{
            style: {
              background: '#E8E7ED'
            }
          }}
          reviewInterface={reviewInterface}
          placeholder={<Typography variant="body2">File no longer available</Typography>}
        />
        {onRemove && <RemoveButton onMouseDown={handleRemoveClick} children={<CloseIcon />} />}
      </AssetContainer>
      {title && <Title>{title}</Title>}
    </Container>
  );
};

export default ReferenceFilePreview;

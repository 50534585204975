/**
 * @flow
 * @relayHash 0fca489e2d46bbe1a082ffcf4c3b19f9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddStaffUserFormQueryVariables = {||};
export type AddStaffUserFormQueryResponse = {|
  +organization: ?{|
    +subscription: ?{|
      +nextBillingDate: ?any
    |}
  |},
  +addStaffUserCheck: ?{|
    +invoicePreview: ?{|
      +total: ?number,
      +timestamp: ?string,
      +invoiceLines: ?$ReadOnlyArray<?{|
        +itemName: ?string,
        +quantity: ?number,
        +cost: ?number,
      |}>,
    |},
    +subscriptionInfo: ?{|
      +tier: ?string,
      +interval: ?string,
      +modules: ?number,
      +activeUsers: ?number,
      +totalUsers: ?number,
    |},
    +needUpgrade: ?boolean,
    +isSeatAvailable: ?boolean,
    +error: ?string,
  |},
|};
export type AddStaffUserFormQuery = {|
  variables: AddStaffUserFormQueryVariables,
  response: AddStaffUserFormQueryResponse,
|};
*/


/*
query AddStaffUserFormQuery {
  organization {
    subscription {
      nextBillingDate
    }
    id
  }
  addStaffUserCheck {
    invoicePreview {
      total
      timestamp
      invoiceLines {
        itemName
        quantity
        cost
      }
    }
    subscriptionInfo {
      tier
      interval
      modules
      activeUsers
      totalUsers
    }
    needUpgrade
    isSeatAvailable
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "subscription",
  "storageKey": null,
  "args": null,
  "concreteType": "OrganizationSubscription",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nextBillingDate",
      "args": null,
      "storageKey": null
    }
  ]
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "addStaffUserCheck",
  "storageKey": null,
  "args": null,
  "concreteType": "AddStaffUserCheck",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "invoicePreview",
      "storageKey": null,
      "args": null,
      "concreteType": "InvoicePreview",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "timestamp",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "invoiceLines",
          "storageKey": null,
          "args": null,
          "concreteType": "InvoiceLine",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "itemName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "quantity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cost",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscriptionInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "SubscriptionInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tier",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "interval",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "modules",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "activeUsers",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalUsers",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "needUpgrade",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSeatAvailable",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "error",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddStaffUserFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ]
      },
      (v1/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddStaffUserFormQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      },
      (v1/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AddStaffUserFormQuery",
    "id": null,
    "text": "query AddStaffUserFormQuery {\n  organization {\n    subscription {\n      nextBillingDate\n    }\n    id\n  }\n  addStaffUserCheck {\n    invoicePreview {\n      total\n      timestamp\n      invoiceLines {\n        itemName\n        quantity\n        cost\n      }\n    }\n    subscriptionInfo {\n      tier\n      interval\n      modules\n      activeUsers\n      totalUsers\n    }\n    needUpgrade\n    isSeatAvailable\n    error\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a22633d6e2235ed31c5079a8e94c942';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Field } from 'formik';
import clsx from 'clsx';

import { FormikCountrySelect } from '../../../../../shared/components/form/dropdowns/CountrySelect';
import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import contractorDetailsStyles from '../../../../../shared/styles/contractors/contractorDetailsStyles';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import { getCountryName } from '../../../../../shared/utils/helpers';

const AddressInfo = props => {
  const classes = contractorDetailsStyles();
  var { address1, address2, city, country, state, zipcode } = props.contractor;

  const { isEditing } = props;
  const disabled = props.contractor.freelancer ? true : false;

  return isEditing && !disabled ? (
    <DetailsSection title="Address Info">
      <div className={classes.container}>
        <div className={clsx(classes.container, classes.marginRight)}>
          <div className={classes.containerColumn}>
            <div className={classes.formFieldNoMarginTop}>
              <Field
                name="address1"
                label="Address"
                fullWidth
                placeholder="Street Address"
                component={FormikTextField}
                autoComplete="new-password"
              />
            </div>
            <div className={classes.formField}>
              <Field
                name="address2"
                label="Address 2"
                fullWidth
                placeholder="Street Address"
                component={FormikTextField}
                autoComplete="new-password"
              />
            </div>
            <div className={classes.formField}>
              <Field
                name="city"
                label="City"
                fullWidth
                placeholder="City"
                component={FormikTextField}
                autoComplete="new-password"
              />
            </div>
            <div className={classes.formField}>
              <Field
                name="zipcode"
                label="Zip/Postal Code"
                fullWidth
                placeholder="Zip/Postal code"
                component={FormikTextField}
                autoComplete="new-password"
              />
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.containerColumn}>
            <div className={classes.formFieldNoMarginTop}>
              <Field
                name="state"
                label="State/Province"
                fullWidth
                placeholder="State/Province"
                component={FormikTextField}
                autoComplete="new-password"
              />
            </div>
            <div className={classes.formField}>
              <Field
                name="country"
                label="Country"
                fullWidth
                placeholder="Country"
                component={FormikCountrySelect}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
    </DetailsSection>
  ) : (
    <DetailsSection title="Address Info" noBorder>
      <div className={classes.container}>
        <div className={clsx(classes.container, classes.marginRight)}>
          <div className={classes.containerColumn}>
            <DetailsRow title="Address" content={address1} />
            <DetailsRow title="Address 2" content={address2} />
            <DetailsRow title="City" content={city} />
            <DetailsRow title="Zip/Postal code" content={zipcode} />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.containerColumn}>
            <DetailsRow title="State/Province" content={state} />
            <DetailsRow title="Country" content={getCountryName(country)} />
          </div>
        </div>
      </div>
    </DetailsSection>
  );
};

export default createFragmentContainer(AddressInfo, {
  contractor: graphql`
    fragment AddressInfo_contractor on ContractorNode {
      address1
      address2
      city
      state
      country {
        name
      }
      zipcode
      freelancer {
        id
      }
    }
  `
});

/**
 * @flow
 * @relayHash cbd1ed47072c879db5da0c53910dc642
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchableStaffAndContractorDropdownQueryVariables = {|
  staffName?: ?string,
  contractorName?: ?string,
|};
export type SearchableStaffAndContractorDropdownQueryResponse = {|
  +staffByOrg: ?{|
    +edgeCount: ?number,
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
      |}
    |}>,
  |},
  +contractorsByOrg: ?{|
    +edgeCount: ?number,
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
      |}
    |}>,
  |},
|};
export type SearchableStaffAndContractorDropdownQuery = {|
  variables: SearchableStaffAndContractorDropdownQueryVariables,
  response: SearchableStaffAndContractorDropdownQueryResponse,
|};
*/


/*
query SearchableStaffAndContractorDropdownQuery(
  $staffName: String
  $contractorName: String
) {
  staffByOrg(staffName: $staffName, orderBy: "lastName", first: 10, includeInactive: false) {
    edgeCount
    totalCount
    edges {
      node {
        id
        fullName
      }
    }
  }
  contractorsByOrg(contractorName: $contractorName, orderBy: "fullName", first: 10, excludeWithConflicts: true, includeInactive: false) {
    edgeCount
    totalCount
    edges {
      node {
        id
        fullName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "staffName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractorName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v2 = {
  "kind": "Literal",
  "name": "includeInactive",
  "value": false
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "edgeCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "staffByOrg",
    "storageKey": null,
    "args": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "lastName"
      },
      {
        "kind": "Variable",
        "name": "staffName",
        "variableName": "staffName"
      }
    ],
    "concreteType": "StaffNodeConnection",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "StaffNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": (v5/*: any*/)
          }
        ]
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "contractorsByOrg",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractorName",
        "variableName": "contractorName"
      },
      {
        "kind": "Literal",
        "name": "excludeWithConflicts",
        "value": true
      },
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "fullName"
      }
    ],
    "concreteType": "ContractorNodeConnection",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ContractorNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": (v5/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SearchableStaffAndContractorDropdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchableStaffAndContractorDropdownQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SearchableStaffAndContractorDropdownQuery",
    "id": null,
    "text": "query SearchableStaffAndContractorDropdownQuery(\n  $staffName: String\n  $contractorName: String\n) {\n  staffByOrg(staffName: $staffName, orderBy: \"lastName\", first: 10, includeInactive: false) {\n    edgeCount\n    totalCount\n    edges {\n      node {\n        id\n        fullName\n      }\n    }\n  }\n  contractorsByOrg(contractorName: $contractorName, orderBy: \"fullName\", first: 10, excludeWithConflicts: true, includeInactive: false) {\n    edgeCount\n    totalCount\n    edges {\n      node {\n        id\n        fullName\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bce8d336adb7d1955b7138986e858669';
module.exports = node;

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import { ReactComponent as Logo } from '../../../shared/images/AWEbase-Default-Light.svg';
import loginStyles from '../../../shared/styles/account/login';
import useQueryParams from '../../../shared/hooks/useQueryParams';
import UpdateFreelancerEmailMutation from './mutations/UpdateFreelancerEmailMutation';
import authService from '../../../shared/services/authService';

const ConfirmEmailChange = props => {
  const [error, setError] = useState('');
  const [validLink, setValidLink] = useState(undefined);

  const queryParams = useQueryParams();
  const { classes, history } = props;

  const redirectToLogin = () => {
    history.push('/login');
  };
  useEffect(() => {
    // Force-logout before resetting email
    authService._removeTokens();
    if (queryParams.get('email-change-token') && queryParams.get('uidb64')) {
      UpdateFreelancerEmailMutation(
        {
          emailChangeToken: queryParams.get('email-change-token'),
          uidb64: queryParams.get('uidb64')
        },
        response => {
          if (response && !response.tokenValid) {
            setValidLink(false);
          } else if (response && response.emailChanged) {
            setValidLink(true);
          }
          if (response && response.message) {
            setError(response.message);
          }
        }
      );
    } else {
      // if one or both of query params are missing, setting link error
      setValidLink(false);
    }
  }, []);

  return (
    <div className={classes.mainLogin}>
      <div className={classes.logo}>
        <Logo />
      </div>
      <div className={classes.loginContent}>
        {validLink === undefined ? (
          <Typography variant="body2">Loading...</Typography>
        ) : !validLink ? (
          <Typography variant="body2">This link is invalid.</Typography>
        ) : (
          <Typography variant="body2">Your email was changed successfully.</Typography>
        )}
        <div className={classes.marginTop}>
          <GlobalButton handleClick={redirectToLogin}>Login here</GlobalButton>
        </div>

        <Typography
          variant="body2"
          className={error ? classes.badAlert : classes.badAlertPlaceholder}
        >
          {error}
        </Typography>
      </div>
    </div>
  );
};

export default withRouter(withStyles(loginStyles)(ConfirmEmailChange));

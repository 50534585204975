import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

/**
 * @param {Object} variables - Variables passed to the mutation
 * @param {string} variables.id - ID of the updated asset
 * @param {string} variables.fileName - Name of the asset
 * @param {string} variables.description - Portfolio asset description
 */

export default (variables, onCompleted, onError) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdatePortfolioAssetMutation($input: UpdatePortfolioAssetInput!) {
        updatePortfolioAsset(input: $input) {
          clientMutationId
          updatedAsset {
            description
            fileName
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted,
    onError
  });
};

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

export default (id, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation DeleteContractorGroupMutation($input: DeleteContractorGroupInput!) {
        deleteContractorGroup(input: $input) {
          isDeleted
        }
      }
    `,
    variables: {
      input: {
        id
      }
    },
    onCompleted: (response, errors) => {
      callback(response.deleteContractorGroup, errors);
    }
  });
};

/**
 * @flow
 * @relayHash f13369cb3ac6175856ca750d0f84f1f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Budget_release$ref = any;
type CreateBudget_release$ref = any;
export type ReleaseBudgetTabQueryVariables = {|
  id: string
|};
export type ReleaseBudgetTabQueryResponse = {|
  +release: ?{|
    +budgetInfo: ?$ReadOnlyArray<?{|
      +budgetAmount: ?any
    |}>,
    +$fragmentRefs: Budget_release$ref & CreateBudget_release$ref,
  |}
|};
export type ReleaseBudgetTabQuery = {|
  variables: ReleaseBudgetTabQueryVariables,
  response: ReleaseBudgetTabQueryResponse,
|};
*/


/*
query ReleaseBudgetTabQuery(
  $id: ID!
) {
  release(id: $id) {
    budgetInfo {
      budgetAmount
    }
    ...Budget_release
    ...CreateBudget_release
    id
  }
}

fragment Budget_release on ReleaseNode {
  id
  budgetInfo {
    category {
      id
      name
    }
    budgetAmount
  }
  categoryInfo {
    category {
      id
      name
    }
    pendingAmount
    contractedAmount
    originalPendingAmount
    originalContractedAmount
    hasFxDeliverables
  }
  exchangeRateDate
  deliverablesTotalAmount
  deliverablesTotalOriginalAmount
}

fragment CreateBudget_release on ReleaseNode {
  id
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "budgetAmount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryNode",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReleaseBudgetTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "release",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetInfo",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "Budget_release",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "CreateBudget_release",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReleaseBudgetTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "release",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetInfo",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ]
          },
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categoryInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "ReleaseCategoryInfo",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "pendingAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contractedAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "originalPendingAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "originalContractedAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasFxDeliverables",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "exchangeRateDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deliverablesTotalAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deliverablesTotalOriginalAmount",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ReleaseBudgetTabQuery",
    "id": null,
    "text": "query ReleaseBudgetTabQuery(\n  $id: ID!\n) {\n  release(id: $id) {\n    budgetInfo {\n      budgetAmount\n    }\n    ...Budget_release\n    ...CreateBudget_release\n    id\n  }\n}\n\nfragment Budget_release on ReleaseNode {\n  id\n  budgetInfo {\n    category {\n      id\n      name\n    }\n    budgetAmount\n  }\n  categoryInfo {\n    category {\n      id\n      name\n    }\n    pendingAmount\n    contractedAmount\n    originalPendingAmount\n    originalContractedAmount\n    hasFxDeliverables\n  }\n  exchangeRateDate\n  deliverablesTotalAmount\n  deliverablesTotalOriginalAmount\n}\n\nfragment CreateBudget_release on ReleaseNode {\n  id\n  name\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b67fb49466c43f01aad88f90cd820f8';
module.exports = node;

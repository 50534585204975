import React, { useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';
import Select from '../../../../shared/components/form/fields/Select';
import withFieldProps from '../../../../shared/hoc/withFieldProps';
import { useEffectWithStatus } from '../../../../shared/hooks/useEffectWithStatus';
import TagsGrid from '../../../../shared/components/UI/TagsGrid';

const formatOptions = options =>
  options.map(category => ({
    value: category.node.id,
    label: category.node.name
  }));

// TODO:
// - empyOption logic should be moved to form/fields/Select
// - selected options should be replaced with value
export const CategorySelect = props => {
  const {
    formatOptions,
    selectedOptions,
    withEmptyOption: withEmptyOptionCheck,
    emptyOptionLabel,
    ...otherProps
  } = props;
  const [options, setOptions] = useState([]);
  const withEmptyOption =
    typeof withEmptyOptionCheck === 'function'
      ? withEmptyOptionCheck(options, selectedOptions)
      : withEmptyOptionCheck;

  useEffectWithStatus(status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query CategorySelectQuery {
          categoryByOrg(orderBy: "name") {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `
    ).then(response => {
      if (status.mounted) {
        setOptions(formatOptions(response.categoryByOrg.edges));
      }
    });
  }, []);

  let filteredOptions = options;
  if (selectedOptions) {
    const filterValues = selectedOptions.map(option => option.value);
    filteredOptions = options.filter(option => !filterValues.includes(option.value));
  }
  if (withEmptyOption) {
    filteredOptions = [
      { value: '', label: emptyOptionLabel, allOptions: options },
      ...filteredOptions
    ];
  }

  return <Select options={filteredOptions} {...otherProps} />;
};

CategorySelect.propTypes = {
  withEmptyOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  emptyOptionLabel: PropTypes.string,
  formatOptions: PropTypes.func,
  selectedOptions: PropTypes.array
};

CategorySelect.defaultProps = {
  emptyOptionLabel: '-',
  selectedOptions: [],
  formatOptions
};

export const CategorySelectWithChips = ({ onChange, value, ...rest }) => {
  const handleChange = (field, selectedOption) => {
    const options = selectedOption.allOptions
      ? selectedOption.allOptions
      : [...value, selectedOption];
    onChange(field, options);
  };

  const handleDelete = deleteValue =>
    onChange(
      rest.name,
      value.filter(option => option.value !== deleteValue && option.value !== '')
    );

  return (
    <>
      <CategorySelect
        {...rest}
        value=""
        selectedOptions={value}
        onChange={handleChange}
        withEmptyOption={(options, selectedOptions) => options.length - 1 > selectedOptions.length}
        emptyOptionLabel="Add all categories"
      />
      <TagsGrid
        tags={value
          .filter(option => option.value)
          .map(option => ({
            node: { id: option.value, name: option.label }
          }))}
        onDelete={handleDelete}
        style={{ paddingTop: '0.5rem' }}
      />
    </>
  );
};

CategorySelectWithChips.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string }))
};

export const FormikCategorySelect = withFieldProps(({ withChipsBelow, ...selectProps }) => {
  const Component = withChipsBelow ? CategorySelectWithChips : CategorySelect;
  return <Component {...selectProps} />;
});

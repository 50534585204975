import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import ReleaseDetails from './ReleaseDetails';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import { useParams } from 'react-router';

const ReleaseDetailsPage = props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query ReleaseDetailsPageQuery($id: ID!) {
          release(id: $id) {
            ...ReleaseDetails_release
          }
        }
      `}
      variables={{
        id
      }}
      render={relayProps => <ReleaseDetails release={relayProps.release} {...props} />}
    />
  );
};

export default ReleaseDetailsPage;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductDetailsContent_product$ref: FragmentReference;
declare export opaque type ProductDetailsContent_product$fragmentType: ProductDetailsContent_product$ref;
export type ProductDetailsContent_product = {|
  +id: string,
  +code: ?string,
  +internalId: ?string,
  +representativeImageUrl: ?string,
  +title: string,
  +description: ?string,
  +$refType: ProductDetailsContent_product$ref,
|};
export type ProductDetailsContent_product$data = ProductDetailsContent_product;
export type ProductDetailsContent_product$key = {
  +$data?: ProductDetailsContent_product$data,
  +$fragmentRefs: ProductDetailsContent_product$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProductDetailsContent_product",
  "type": "ProductNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "internalId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f895f9785047f7e0edfc6b43dd0082c5';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import FullScreenModal from '../fullscreenModal/FullscreenModal';
import SquareButton from '../UI/SquareButton';

const FullScreenContentContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  backgroundColor: theme.palette.colors.lightBlueGrey,
  padding: theme.spacing(3),
  alignItems: 'flex-start'
}));

const FullScreenImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  flex: '1 1 auto',
  marginBottom: theme.spacing(3),
  borderRadius: '4px',
  backgroundColor: theme.palette.colors.lightestGrey
}));

const FullScreenImage = styled('img')({
  position: 'absolute',
  objectFit: 'contain',
  width: '100%',
  height: '100%'
});

const FullScreenImageModal = ({ open, onCloseClick, name, src, description }) => (
  <FullScreenModal open={open} handleBack={onCloseClick} noTopBar>
    <FullScreenContentContainer>
      <Box position="absolute" top="0" right="0" p={3}>
        <SquareButton onClick={onCloseClick} icon="close" />
      </Box>
      <Typography variant="h1" gutterBottom>
        {name}
      </Typography>
      <FullScreenImageContainer>
        <FullScreenImage src={src} />
      </FullScreenImageContainer>
      <Typography>{description}</Typography>
    </FullScreenContentContainer>
  </FullScreenModal>
);

FullScreenImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  description: PropTypes.string
};

FullScreenImageModal.defaultProps = {
  description: ''
};

export default FullScreenImageModal;

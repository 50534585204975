/**
 * @flow
 * @relayHash 90906126b9094b4f9da51005ba5c6d3d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ManageContractorsInGroupInput = {|
  group: string,
  contractors: $ReadOnlyArray<?string>,
  activate: boolean,
  clientMutationId?: ?string,
|};
export type ManageContractorsInGroupMutationVariables = {|
  input: ManageContractorsInGroupInput
|};
export type ManageContractorsInGroupMutationResponse = {|
  +manageContractorsInGroup: ?{|
    +group: ?{|
      +id: string
    |}
  |}
|};
export type ManageContractorsInGroupMutation = {|
  variables: ManageContractorsInGroupMutationVariables,
  response: ManageContractorsInGroupMutationResponse,
|};
*/


/*
mutation ManageContractorsInGroupMutation(
  $input: ManageContractorsInGroupInput!
) {
  manageContractorsInGroup(input: $input) {
    group {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ManageContractorsInGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "manageContractorsInGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ManageContractorsInGroupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "group",
        "storageKey": null,
        "args": null,
        "concreteType": "ContractorGroupNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ManageContractorsInGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ManageContractorsInGroupMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ManageContractorsInGroupMutation",
    "id": null,
    "text": "mutation ManageContractorsInGroupMutation(\n  $input: ManageContractorsInGroupInput!\n) {\n  manageContractorsInGroup(input: $input) {\n    group {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3caa559490c6041dfa1178a590718ee2';
module.exports = node;

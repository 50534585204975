import graphql from 'babel-plugin-relay/macro';
import React, { Fragment } from 'react';
import { createFragmentContainer } from 'react-relay';
import UploadFileButton from '../../../../../shared/components/form/UploadFileButton';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import { ALLOWED_ACTIONS, CELL_TYPES } from '../../../../../shared/constants';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import TopSection from './JobContractTabTopSection';
import CreateJobAssociatedFileMutation from '../../mutations/CreateJobAssociatedFileMutation';
import DeleteJobAssociatedFileMutation from '../../mutations/DeleteJobAssociatedFileMutation';

const JobContractTab = props => {
  const {
    job: {
      id: jobId,
      associatedFiles: { edges }
    },
    userContext: {
      orgStaff: { allowedActions }
    }
  } = props;

  const onFileDeleteHandler = id => DeleteJobAssociatedFileMutation(id, jobId);
  const onFileUploadHandler = (file, cb) => {
    CreateJobAssociatedFileMutation(jobId, file, cb);
  };

  const canUserUploadFile = computePermissionRole(
    ALLOWED_ACTIONS.JOB_UPLOAD_ASSOCIATED_FILES_OF_CONTRACT,
    allowedActions
  );

  return (
    <Fragment>
      <TopSection {...props} />
      {canUserUploadFile && (
        <UploadFileButton id="contractUploadAssociatedFile" handleChange={onFileUploadHandler} />
      )}
      <AutoTable
        rowTemplate={[
          { name: 'fileName', label: 'Name' },
          { name: 'fileSize', label: 'Size' },
          { name: 'fileDownload', label: '', type: CELL_TYPES.fileDownload, tooltip: 'Download', width: 30 },
          {
            name: 'fileDelete',
            label: '',
            type: CELL_TYPES.trash,
            onClick: onFileDeleteHandler,
            withConfirm: true,
            width: 30,
            tooltip: 'Delete'
          }
        ]}
        edges={edges.map(({ node }) => ({
          node: {
            id: node.id,
            fileName: node.fileName,
            fileSize: node.fileSize,
            fileDownload: {
              fileUrl: node.fileUrl,
              fileName: node.fileName
            },
            fileDelete: node.fileName
          }
        }))}
      />
    </Fragment>
  );
};

export default createFragmentContainer(withUserContext(JobContractTab), {
  job: graphql`
    fragment JobContractTab_job on JobNode {
      contract {
        id
        contractId
        dateSignedByOrganization
        dateSignedByContractor
        signedPdfByContractorUrl
        signedPdfByOrganizationUrl
        finalizedPdfUrl
        template {
          name
        }
      }
      id
      jobId
      status
      associatedFiles(first: 50) @connection(key: "JobContractTab_associatedFiles") {
        edges {
          node {
            id
            fileName
            fileSize
            fileUrl
          }
        }
      }
    }
  `
});

import { commitMutation } from 'react-relay';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const ValidateDeliverablesForJobMutation = (deliverables, callback = () => {}) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation ValidateDeliverablesForJobMutation($input: ValidateDeliverablesForJobInput!) {
        validateDeliverablesForJob(input: $input) {
          isValidCategory
          isValidState
          isValidCurrency
          isValidStaff
          isValidBudget
          deliverables {
            id
            title
            category {
              id
              name
            }
            amount
            dueDate
            currencyCode
            amountInHomeCurrency
            assignedStaff {
              id
              fullName
              representativeImageUrl
            }
            state
          }
        }
      }
    `,
    variables: {
      input: {
        deliverables
      }
    },
    onCompleted: (response, errors) => {
      callback(response.validateDeliverablesForJob, errors);
    }
  });
};

ValidateDeliverablesForJobMutation.propTypes = {
  deliverables: PropTypes.arrayOf(PropTypes.string)
};

export default ValidateDeliverablesForJobMutation;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  inviteFormContainer: {
    padding: '0',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& textarea': {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'bold',
      color: theme.palette.colors.darkBlueGrey
    },
    '& span': {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '22px',
      color: theme.palette.colors.darkBlueGrey
    },
    '& button': {
      marginTop: '20px',
      marginRight: '0px',
      height: '34px',
      width: '115px',
      color: '#FFFFFF',
      alignSelf: 'flex-end',
      padding: '0px 16px',
      '& span': {
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '16px',
        color: '#FFFFFF'
      }
    }
  }
}));

import React from 'react';
import { Field, Form, Formik } from 'formik';

import { Box, Typography } from '@material-ui/core';
import FormikSearchableDropdown from '../../../shared/components/form/FormikSearchableDropdown';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import Dialog from '../../../shared/components/common/Dialog';
import AssignBatchDeliverableInternallyMutation from './mutations/AssignBatchDeliverableInternallyMutation';

const AssignDeliverableInternallyForm = props => {
  const {
    deliverableIds,
    isDialogOpen,
    handleClose,
    handleCloseWithRefetch,
    modalTitle,
    initialValue
  } = props;

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    AssignBatchDeliverableInternallyMutation(
      deliverableIds,
      values.assignedStaff.value,
      response => {
        setSubmitting(false);
        if (response && response.isUpdated) {
          handleCloseWithRefetch();
        }
      }
    );
  };

  const validate = values => {
    let errors = {};
    if (!values.assignedStaff || !values.assignedStaff.value) {
      errors.assignedStaff = 'Required';
    }
    return errors;
  };

  return (
    <Dialog title={modalTitle} isDialogOpen={isDialogOpen} closeDialog={handleClose} minWidth={400}>
      <Formik
        initialValues={{ assignedStaff: initialValue || null }}
        validate={validate}
        onSubmit={onSubmitHandler}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Box>
              <Typography style={{ marginBottom: '12px' }}>
                Please select a Staff member to assign the Deliverable to.
              </Typography>
              <Field
                name="assignedStaff"
                component={FormikSearchableDropdown}
                label="Staff"
                placeholder="Select Staff..."
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={3} mb={1}>
              <GlobalButton
                id="cancelAssignStaff"
                variant="transparent"
                handleClick={handleClose}
                disabled={isSubmitting}
              >
                Cancel
              </GlobalButton>
              <GlobalButton
                id="confirmAssignStaff"
                disabled={isSubmitting}
                loading={isSubmitting}
                handleClick={submitForm}
              >
                Assign Internally
              </GlobalButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AssignDeliverableInternallyForm;

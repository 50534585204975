import React from 'react';
import PropTypes from 'prop-types';
import { formatAmount } from '../../utils/formatters';
import DetailsRow from './DetailsRow';
import withUserContext from '../../contexts/userContext/withUserContext';
import { getCurrencyCodeFromUserContext } from '../../utils/helpers';

const DetailsRowAmount = props => {
  const {
    title,
    titleWidth,
    amount,
    currencyCode,
    noMarginTop,
    subscriptAmount,
    userContext
  } = props;

  return (
    <DetailsRow
      titleWidth={titleWidth}
      title={title || 'Amount'}
      // if this component is used in freelancer app, we need to pass currency code based on what org's instance we are displaying
      content={formatAmount(
        amount,
        currencyCode ? currencyCode : getCurrencyCodeFromUserContext(userContext)
      )}
      subscript={
        subscriptAmount && getCurrencyCodeFromUserContext(userContext) !== currencyCode
          ? formatAmount(subscriptAmount, getCurrencyCodeFromUserContext(userContext))
          : ''
      }
      noMarginTop={noMarginTop}
    />
  );
};

export default withUserContext(DetailsRowAmount);

PropTypes.DetailsRowAmount = {
  amount: PropTypes.number,
  titleWidth: PropTypes.number,
  title: PropTypes.string,
  currencyCode: PropTypes.string,
  noMarginTop: PropTypes.bool,
  subscriptAmount: PropTypes.number
};

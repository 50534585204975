import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import { computePermissionRole } from '../../../../../shared/utils/helpers';
import ToolActions from '../../../shared/ToolActions';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import DeleteReleaseMutation from '../../mutations/DeleteReleaseMutation';
import DeactivateReleaseMutation from '../../mutations/DeactivateReleaseMutation';
import { ALLOWED_ACTIONS, TOOLS_DESCRIPTIONS } from '../../../../../shared/constants';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import ActivateReleaseMutation from '../../mutations/ActivateReleaseMutation';
import RemoveReleaseBudgetMutation from '../../mutations/RemoveReleaseBudgetMutation';
import ConfirmationDialog from '../../../../../shared/components/common/ConfirmationDialog';
import useUpdateEffect from '../../../../../shared/hooks/useUpdateEffect';

const ReleaseToolsActions = props => {
  const {
    history,
    release: { id, isActive, hasDeliverables, budgetInfo },
    userContext: {
      orgStaff: {
        allowedActions,
        organization: { configuration }
      }
    },
    setRefetchCounterForTools
  } = props;

  const navigation = JSON.parse(configuration).navigation;

  useUpdateEffect(() => {
    setRefetchCounterForTools(prev => prev + 1);
  }, [props.refetchCounter]);

  const handleDelete = () => {
    DeleteReleaseMutation(id, response => {
      if (response && response.isDeleted) {
        history.push('/releases');
      }
    });
  };

  const handleDeactivate = () => {
    DeactivateReleaseMutation(id, response => {
      if (response && response.updatedRelease) {
        setRefetchCounterForTools(prev => prev + 1);
      }
    });
  };

  const handleActivate = () => {
    ActivateReleaseMutation(id, response => {
      if (response && response.updatedRelease) {
        setRefetchCounterForTools(prev => prev + 1);
      }
    });
  };

  const handleDeleteBudget = () => {
    RemoveReleaseBudgetMutation(id, response => {
      if (response && response.updatedRelease) {
        setRefetchCounterForTools(prev => prev + 1);
      }
    });
  };

  const canDeleteRelease =
    isActive &&
    !hasDeliverables &&
    computePermissionRole(ALLOWED_ACTIONS.RELEASE_DELETE, allowedActions);

  const canDeactivateRelease = computePermissionRole(
    ALLOWED_ACTIONS.RELEASE_DEACTIVATE,
    allowedActions
  );

  const canMarkInactive = isActive && hasDeliverables && canDeactivateRelease;

  const canMarkActive = !isActive && canDeactivateRelease;

  const canDeleteBudget =
    isActive &&
    (budgetInfo || false) &&
    computePermissionRole(ALLOWED_ACTIONS.RELEASE_EDIT_BUDGET, allowedActions);

  const actionList = [
    {
      condition: canDeleteRelease,
      actionRender: () => (
        <GlobalButton noMargin id="deleteRelease" handleClick={handleDelete} fullWidth>
          Delete {navigation.releaseTitle.singular}
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.deleteReleaseFunc(navigation.releaseTitle.singular)
    },
    {
      condition: canMarkInactive,
      actionRender: () => (
        <GlobalButton
          noMargin
          id="deactivateReleaseDetails"
          handleClick={handleDeactivate}
          fullWidth
        >
          Mark {navigation.releaseTitle.singular} Inactive
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.markReleaseDetailsInactive
    },
    {
      condition: canMarkActive,
      actionRender: () => (
        <GlobalButton noMargin id="activateReleaseDetails" handleClick={handleActivate} fullWidth>
          Mark {navigation.releaseTitle.singular} Active
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.activateReleaseDetails
    },
    {
      condition: canDeleteBudget,
      actionRender: () => (
        <ConfirmationDialog
          content="This action is irreversible. Are you sure you want to remove the budget?"
          copies={{ confirmCopy: 'Remove Budget', cancelCopy: 'Cancel' }}
        >
          {confirm => (
            <GlobalButton
              id="deleteReleaseBudget"
              handleClick={confirm(handleDeleteBudget)}
              fullWidth
              noMargin
            >
              Remove Budget
            </GlobalButton>
          )}
        </ConfirmationDialog>
      ),
      description: TOOLS_DESCRIPTIONS.deleteReleaseBudget
    }
  ];

  return <ToolActions actions={actionList} />;
};

export default withUserContext(
  withRouter(
    createFragmentContainer(ReleaseToolsActions, {
      release: graphql`
        fragment ReleaseToolsActions_release on ReleaseNode {
          id
          isActive
          hasDeliverables
          budgetInfo {
            budgetAmount
          }
        }
      `
    })
  )
);

import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import loginStyles from '../../styles/account/login';
import GlobalButton from '../UI/GlobalButton';
import { withRouter } from 'react-router';

const InvalidLinkFromEmail = props => {
  const { classes, history } = props;
  return (
    <Fragment>
      <Typography variant="body2">This link is invalid.</Typography>
      <div className={classes.marginTop}>
        <GlobalButton handleClick={() => history.push('/login')}>Login here</GlobalButton>
      </div>
    </Fragment>
  );
};

export default withRouter(withStyles(loginStyles)(InvalidLinkFromEmail));

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotificationSettingsContent_notificationSettings$ref: FragmentReference;
declare export opaque type NotificationSettingsContent_notificationSettings$fragmentType: NotificationSettingsContent_notificationSettings$ref;
export type NotificationSettingsContent_notificationSettings = {|
  +currentUser: ?{|
    +freelancer: ?{|
      +notificationConfig: ?any,
      +receiveSummaryEmails: ?boolean,
      +notificationSummaryInterval: ?string,
    |}
  |},
  +$refType: NotificationSettingsContent_notificationSettings$ref,
|};
export type NotificationSettingsContent_notificationSettings$data = NotificationSettingsContent_notificationSettings;
export type NotificationSettingsContent_notificationSettings$key = {
  +$data?: NotificationSettingsContent_notificationSettings$data,
  +$fragmentRefs: NotificationSettingsContent_notificationSettings$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "NotificationSettingsContent_notificationSettings",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentUser",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "freelancer",
          "storageKey": null,
          "args": null,
          "concreteType": "FreelancerNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "notificationConfig",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "receiveSummaryEmails",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "notificationSummaryInterval",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c528c88c5f4608cd78676d43372047d7';
module.exports = node;

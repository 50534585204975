import React, { Fragment } from 'react';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { styled } from '@material-ui/styles';
import { LinearProgress, FormControlLabel, Switch, Typography, Grid, Box } from '@material-ui/core';
import Timer from './Timer';
import ProgressBarAnnotation from './ProgressBarAnnotation';
import GlobalButton from '../UI/GlobalButton';
import SquareButton from '../UI/SquareButton';
import CircleColorPicker from '../colorPicker/CircleColorPicker';
import { useCommonAnnotationStyles } from '../../styles/deliverable/commonAnnotationStyles';
import Slider from '../common/Slider';
import AddActionsGroup from '../deliverable/review_interfaces/AddActionsGroup';

const ToolbarControlsContainer = styled('div')(
  ({ theme }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& > p': {
      marginRight: theme.spacing(2)
    },
    '& > button:not(:last-child)': {
      marginRight: theme.spacing(0.5)
    }
  }),
  { withTheme: true }
);

const VideoCanvasProgressToolbar = props => {
  const {
    sessionId,
    currentTime,
    duration,
    annotationData,
    selectedAnnotationData,
    onProgressBarClick,
    onAnnotationClick,
    onTogglePlayClick,
    highlightedAnnotationRef,
    isPlaying,
    sliderProps,
    canAddMarkup,
    editing,
    toggleAnnotationVisibility,
    // FRAMTODO pass the annotation visible flag
    annotationsVisible,
    onSaveEdit, // Callback to save users edit
    onStartEdit, // Callback to start editing
    onCloseClick,
    setToolHandler,
    colorProps,
    tools,
    selectedToolId,
    onStartSession,
    onAddComment
  } = props;

  const classes = useCommonAnnotationStyles();

  const fromMillis = time => {
    return time / 1000;
  };

  const onLinearProgressClick = event => {
    const currentTargetRect = event.currentTarget.getBoundingClientRect();
    const trackPercent = (event.pageX - currentTargetRect.left) / currentTargetRect.width;
    onProgressBarClick(trackPercent);
  };

  const onChangeHandler = event => {
    toggleAnnotationVisibility(event.target.checked);
  };

  return (
    <div className={classes.progressToolbarContainer}>
      {!sessionId && !editing ? (
        <Fragment>
          <div className={classes.toolbarButtonsContainer}>
            <AddActionsGroup
              disabled={editing || !canAddMarkup}
              onAddAnnotation={onStartSession}
              onAddComment={onAddComment}
            />
            <SquareButton
              active={isPlaying}
              icon={isPlaying ? 'pause' : 'play'}
              variant="transparent"
              onClick={onTogglePlayClick}
            />
          </div>

          <Timer time={fromMillis(currentTime)} primary />
          <div className={classes.progressBarContainer}>
            <LinearProgress
              onClick={onLinearProgressClick}
              className={classes.progressBar}
              variant="determinate"
              value={(currentTime / duration) * 100}
            />
            {annotationData.map(annotation => {
              return (
                <ProgressBarAnnotation
                  key={annotation.annotationRef}
                  value={annotation.annotationRef}
                  left={(annotation.time * 1000) / duration}
                  onAnnotationClick={onAnnotationClick}
                  isActive={highlightedAnnotationRef === annotation.annotationRef}
                />
              );
            })}
          </div>
          <Timer time={fromMillis(duration)} />
          <Slider {...sliderProps} />
        </Fragment>
      ) : null}
      {sessionId && (
        <Grid item xs={6} container spacing={1}>
          <Grid item>
            <ToolbarControlsContainer>
              <Box>
                <SquareButton
                  handleClick={onCloseClick}
                  tooltipTitle="Discard Annotation"
                  icon="close"
                  variant="secondary"
                />
              </Box>
            </ToolbarControlsContainer>
          </Grid>
          <Grid item>
            <ToolbarControlsContainer>
              <Typography variant="body2">Tools</Typography>
              {tools.map(tool => (
                <SquareButton
                  key={tool.name}
                  disabled={editing || !canAddMarkup}
                  variant="transparent"
                  icon={tool.icon}
                  tooltipTitle={tool.description}
                  active={tool.name === selectedToolId}
                  onClick={() => setToolHandler(tool.name)}
                />
              ))}
            </ToolbarControlsContainer>
          </Grid>
          <Grid item>
            <ToolbarControlsContainer>
              <Typography variant="body2">Color</Typography>
              <CircleColorPicker {...colorProps} />
            </ToolbarControlsContainer>
          </Grid>
        </Grid>
      )}
      {!sessionId &&
        (!sessionId && editing ? (
          <GlobalButton
            handleClick={onSaveEdit}
            disabled={
              !canAddMarkup || !selectedAnnotationData || selectedAnnotationData.length === 0
            }
            icon={OpenWithIcon}
            variant="black"
            iconProps={{ style: { fontSize: '16px' } }}
          >
            Save Changes
          </GlobalButton>
        ) : (
          <GlobalButton
            handleClick={onStartEdit}
            disabled={
              !canAddMarkup || !selectedAnnotationData || selectedAnnotationData.length === 0
            }
            icon={OpenWithIcon}
            variant="black"
            iconProps={{ style: { fontSize: '16px' } }}
          >
            Edit Markers
          </GlobalButton>
        ))}
      {!sessionId && !editing ? (
        <FormControlLabel
          control={
            <Switch
              disabled={editing}
              onChange={onChangeHandler}
              checked={annotationsVisible}
              value={annotationsVisible}
              color="primary"
            />
          }
          label={<Typography variant="body2">View markers</Typography>}
          labelPlacement="start"
        />
      ) : null}
    </div>
  );
};

export default VideoCanvasProgressToolbar;

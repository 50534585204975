import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import StaffList from './StaffList';

const StaffTab = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query StaffTabQuery(
          $first: Int
          $orderBy: String
          $after: String
          $staffName: String
          $role: ID
          $includeInactive: Boolean
        ) {
          ...StaffList_staff
        }
      `}
      variables={variables}
      render={relayProps => <StaffList staff={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(StaffTab, 'adminStaffList', {
  orderBy: 'lastName',
  initialFilters: {
    role: '',
    staffName: '',
    includeInactive: false
  }
});

/**
 * @flow
 * @relayHash db64c3f2a69605f2d882eafc84218e16
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateReleaseInput = {|
  name: string,
  code?: ?string,
  deadline?: ?any,
  internalId?: ?string,
  description?: ?string,
  id: string,
  product?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateReleaseMutationVariables = {|
  input: UpdateReleaseInput
|};
export type UpdateReleaseMutationResponse = {|
  +updateRelease: ?{|
    +updatedRelease: ?{|
      +id: string,
      +name: string,
      +internalId: ?string,
      +product: {|
        +id: string
      |},
      +code: string,
      +description: string,
      +deadline: ?any,
    |}
  |}
|};
export type UpdateReleaseMutation = {|
  variables: UpdateReleaseMutationVariables,
  response: UpdateReleaseMutationResponse,
|};
*/


/*
mutation UpdateReleaseMutation(
  $input: UpdateReleaseInput!
) {
  updateRelease(input: $input) {
    updatedRelease {
      id
      name
      internalId
      product {
        id
      }
      code
      description
      deadline
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateReleaseInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateRelease",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateReleasePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedRelease",
        "storageKey": null,
        "args": null,
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "internalId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "product",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductNode",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deadline",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateReleaseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateReleaseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateReleaseMutation",
    "id": null,
    "text": "mutation UpdateReleaseMutation(\n  $input: UpdateReleaseInput!\n) {\n  updateRelease(input: $input) {\n    updatedRelease {\n      id\n      name\n      internalId\n      product {\n        id\n      }\n      code\n      description\n      deadline\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3f4a923adf379f15e68a98f6222e3662';
module.exports = node;

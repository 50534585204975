import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const UpdateOrganizationBillingAddressMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateOrganizationBillingAddressMutation(
        $input: UpdateOrganizationBillingAddressInput!
      ) {
        updateOrganizationBillingAddress(input: $input) {
          organization {
            billingAddress1
            billingAddress2
            billingCity
            billingCountry {
              code
              name
            }
            billingState
            billingZipcode
            phone
            hasBillingInfo
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.updateOrganizationBillingAddress, errors);
    },
    onError: err => console.error(err)
  });
};

export default UpdateOrganizationBillingAddressMutation;

UpdateOrganizationBillingAddressMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      billingAddress1: PropTypes.string.isRequired,
      billingAddress2: PropTypes.string,
      billingCity: PropTypes.string.isRequired,
      billingState: PropTypes.string,
      billingCountry: PropTypes.string.isRequired,
      billingZipcode: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired
    })
  )
};

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../utils/helpers';

const mutation = graphql`
  mutation MarkNotificationsAsReadMutation($input: MarkNotificationsAsReadInput!) {
    markNotificationsAsRead(input: $input) {
      isUpdated
    }
  }
`;

const MarkNotificationsAsReadMutation = (input, callback = () => {}) => {
  const markNotificationsAsReadVariables = {
    input
  };

  const config = {
    mutation,
    variables: markNotificationsAsReadVariables,
    onCompleted: response => {
      callback(response.markNotificationsAsRead);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default MarkNotificationsAsReadMutation;

MarkNotificationsAsReadMutation.propTypes = {
  input: PropTypes.shape({
    //   marks one notification as read if notificationId is provided
    notificationId: PropTypes.string,
    //   marks all notifications for current user of this targeted object as read
    notificationType: PropTypes.string,
    targetId: PropTypes.string,
    // contractorId whose messages are being read
    senderId: PropTypes.string,
    // only for annotation view
    actionObjectId: PropTypes.string
  })
};

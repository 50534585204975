import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const UpdateReleaseMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateReleaseMutation($input: UpdateReleaseInput!) {
        updateRelease(input: $input) {
          updatedRelease {
            id
            name
            internalId
            product {
              id
            }
            code
            description
            deadline
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.updateRelease, errors);
    },
    onError: err => console.error(err)
  });
};

export default UpdateReleaseMutation;

UpdateReleaseMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      internalId: PropTypes.string,
      product: PropTypes.string.isRequired,
      code: PropTypes.string,
      deadline: PropTypes.string,
      description: PropTypes.string
    })
  )
};

import React, { Fragment } from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Grid } from '@material-ui/core';

import DetailsSection from '../../../shared/components/common/DetailsSection';
import DetailsRow from '../../../shared/components/UI/DetailsRow';
import { getCountryName } from '../../../shared/utils/helpers';
import BigAvatar from '../../../shared/components/UI/BigAvatar';

const OrganizationDetails = props => {
  const {
    organization: {
      organization: { name, phone, address1, country, city, state, zipcode, website, representativeImageUrl }
    }
  } = props;

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item>
          <BigAvatar src={representativeImageUrl} alt={`${name} picture`} />
        </Grid>
        <Grid item>
          <DetailsSection title="Organization Info">
            <Grid container spacing={10}>
              <Grid item>
                <DetailsRow title="Phone" content={phone} />
                <DetailsRow title="Address" content={address1} />
                <DetailsRow title="City" content={city} />
                <DetailsRow title="State" content={state} />
              </Grid>
              <Grid item>
                <DetailsRow title="Zipcode" content={zipcode} />
                <DetailsRow title="Country" content={getCountryName(country)} />
                <DetailsRow
                  title="Website"
                  content={
                    <a target="_blank" href={website} rel="noopener noreferrer">
                      {website}
                    </a>
                  }
                />
              </Grid>
            </Grid>
          </DetailsSection>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default createFragmentContainer(OrganizationDetails, {
  organization: graphql`
    fragment OrganizationDetails_organization on Query @argumentDefinitions(id: { type: "ID!" }) {
      organization(id: $id) {
        name
        address1
        city
        state
        zipcode
        country {
          name
        }
        website
        phone
        email
        representativeImageUrl
      }
    }
  `
});

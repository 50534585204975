import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import ColorButton from './ColorButton';

const StyledColorGridContainer = styled(props => <Grid container spacing={1} {...props} />)(
  ({ theme }) => ({
    padding: theme.spacing(1)
  })
);

const StyledColorGridItem = styled(props => <Grid item {...props} />)({
  // These attributes are required for the 'after' border styling of the active ColorButton
  position: 'relative',
  zIndex: 1
});

const ColorsGrid = ({ selectedColor, colors, onChange, size }) => {
  return (
    <StyledColorGridContainer>
      {colors.map(color => (
        <StyledColorGridItem key={color}>
          <ColorButton
            color={color}
            onClick={onChange}
            size={size}
            active={color === selectedColor}
          />
        </StyledColorGridItem>
      ))}
    </StyledColorGridContainer>
  );
};

ColorsGrid.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number
};

ColorsGrid.defaultProps = {
  size: 28
};

export default ColorsGrid;

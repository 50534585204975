import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Typography } from '@material-ui/core';

import DeliverableReviewContent from '../../../../../shared/components/deliverable/DeliverableReviewContent';
import UpdateDeliverableReviewStepMutation from '../../mutations/UpdateDeliverableReviewStepMutation';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { computePermissionRole, getAssignedUserId } from '../../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS, DELIVERABLE_STATES } from '../../../../../shared/constants';

const DeliverableReview = props => {
  const {
    deliverable: { reviewActive, state, assignedStaff, assignedInternally },
    userContext: {
      id: currentUserId,
      orgStaff: { allowedActions }
    },
    relay: { refetch }
  } = props;

  const refetchHandler = (force = false) => {
    refetch(oldVariables => oldVariables, null, null, { force });
  };

  const updateStepHandler = stepId => {
    UpdateDeliverableReviewStepMutation(reviewActive.id, stepId, () => {});
  };

  const canAddCommentInReviewTab = () => {
    if (state === DELIVERABLE_STATES.inProgress) {
      return (
        currentUserId === getAssignedUserId(assignedInternally) ||
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_ADD_REVIEW_COMMENT_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_ADD_REVIEW_COMMENT_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      );
    } else {
      return false;
    }
  };

  const canUploadAssetInReviewTab = () => {
    if (state === DELIVERABLE_STATES.inProgress) {
      return (
        currentUserId === getAssignedUserId(assignedInternally) ||
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_UPLOAD_REVIEW_ASSET_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_UPLOAD_REVIEW_ASSET_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      );
    } else {
      return false;
    }
  };

  const canChangeReviewStepInReviewTab = () => {
    if (state === DELIVERABLE_STATES.inProgress) {
      return (
        (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_CHANGE_CURRENT_REVIEW_STEP_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_CHANGE_CURRENT_REVIEW_STEP_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      );
    } else {
      return false;
    }
  };

  return reviewActive ? (
    <>
      <DeliverableReviewContent
        deliverable={props.deliverable}
        refetch={refetchHandler}
        onReviewStepChange={updateStepHandler}
        canChangeReviewStep={canChangeReviewStepInReviewTab()}
        canAddComment={canAddCommentInReviewTab()}
        canUpload={canUploadAssetInReviewTab()}
        canAddReaction
      />
    </>
  ) : (
    <Typography align="center" variant="body2">
      No active review
    </Typography>
  );
};

export default withUserContext(
  createRefetchContainer(
    DeliverableReview,
    {
      deliverable: graphql`
        fragment DeliverableReview_deliverable on DeliverableNode {
          id
          job {
            id
            name
            contractor {
              fullName
              representativeImageUrl
            }
          }
          state
          description
          assignedStaff {
            id
            fullName
            representativeImageUrl
            user {
              id
              firstName
              lastName
            }
          }
          assignedInternally {
            user {
              id
              fullName
              representativeImageUrl
            }
          }
          reviewAssetFileValidationConfig {
            reviewInterface
            fileTypes
            fileSize
          }
          reviewActive {
            id
            step {
              id
              name
            }
            reviewStepList {
              id
              name
            }
            deliverableReviewAssets {
              edges {
                node {
                  id
                  fileThumbnailUrl
                  fileName
                  created
                  revisionNumber
                  reviewInterface
                  storageClass
                  reactions {
                    id
                    definitionId
                    name
                    color
                    number
                    users {
                      id
                      fullName
                      representativeImageUrl
                    }
                  }
                  comments {
                    edges {
                      node {
                        id
                        created
                        content
                        extraData
                        contentEditedAt
                        repliedTo {
                          content
                          created
                          user {
                            fullName
                          }
                          referenceFiles {
                            id
                            title
                            description
                            fileUrl
                            reviewInterface
                          }
                        }
                        user {
                          id
                          fullName
                          representativeImageUrl
                        }
                        referenceFiles {
                          id
                          title
                          description
                          fileUrl
                          reviewInterface
                        }
                        reactions {
                          id
                          definitionId
                          name
                          color
                          number
                          users {
                            id
                            fullName
                            representativeImageUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query DeliverableReviewRefetchQuery($id: ID!) {
        deliverable(id: $id) {
          ...DeliverableReview_deliverable
        }
      }
    `
  )
);

import { makeStyles } from '@material-ui/styles';

export const buttonStyles = theme => ({
  squareButton: {
    margin: '0',
    marginLeft: 'auto',
    width: props => `${props.size ? props.size : 32}px`,
    height: props => `${props.size ? props.size : 32}px`,
    padding: '0',
    minWidth: 'inherit',
    '& svg': {
      margin: '0',
      width: props => `${props.size ? props.size / 2 : 16}px`,
      height: props => `${props.size ? props.size / 2 : 16}px`,

      '& path': {
        fill: props => {
          if (typeof props.active !== 'undefined') {
            return props.active ? theme.palette.primary.main : '';
          }
        }
      }
    }
  },
  link: {
    cursor: 'pointer'
  },
  squareGroupButton: {
    width: 34,
    height: 34,
    // NOTE: This is workaround for custom css beeing loaded before material ui core
    '&&': {
      minWidth: 'inherit'
    },
    '& svg': {
      width: 8,
      height: 8
    }
  }
});

export const useButtonStyles = makeStyles(buttonStyles);

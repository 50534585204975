import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';

import BigAvatar from '../../UI/BigAvatar';
import UploadImageButton from '../UploadImageButton';
import SquareButton from '../../UI/SquareButton';

const AvatarEditActionsContainer = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    '& > :first-child': {
      marginLeft: 0,
      marginRight: theme.spacing(1)
    }
  }),
  { withTheme: true }
);

const EditableBigAvatar = props => {
  const { avatarProps, editing, uploadButtonProps, deleteButtonProps } = props;

  const onChangeHandler = (file, dataUrl) => {
    uploadButtonProps.handleChange(file, dataUrl);
  };

  return (
    <Fragment>
      <BigAvatar {...avatarProps} />
      {editing && (
        <AvatarEditActionsContainer>
          <UploadImageButton id={uploadButtonProps.id} handleChange={onChangeHandler} />
          <SquareButton icon="trash" {...deleteButtonProps} />
        </AvatarEditActionsContainer>
      )}
    </Fragment>
  );
};

EditableBigAvatar.propTypes = {
  editing: PropTypes.bool.isRequired,
  avatarProps: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string.isRequired
  }).isRequired,
  uploadButtonProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    handleChange: PropTypes.func
  }).isRequired,
  deleteButtonProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired
};

export default EditableBigAvatar;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractorDetailsContent_contractor$ref: FragmentReference;
declare export opaque type ContractorDetailsContent_contractor$fragmentType: ContractorDetailsContent_contractor$ref;
export type ContractorDetailsContent_contractor = {|
  +representativeImageUrl: ?string,
  +fullName: ?string,
  +internalId: ?string,
  +doNotHire: ?boolean,
  +isActive: ?boolean,
  +coverImageUrl: ?string,
  +additionalInfo: ?string,
  +conflictingContractor: ?{|
    +id: string
  |},
  +$refType: ContractorDetailsContent_contractor$ref,
|};
export type ContractorDetailsContent_contractor$data = ContractorDetailsContent_contractor;
export type ContractorDetailsContent_contractor$key = {
  +$data?: ContractorDetailsContent_contractor$data,
  +$fragmentRefs: ContractorDetailsContent_contractor$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ContractorDetailsContent_contractor",
  "type": "ContractorNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "internalId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "doNotHire",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "coverImageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "additionalInfo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "conflictingContractor",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractorNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0e31ee05c78bda94ae1e4ec7e5fa432e';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';

const ThumbnailImage = ({ alt, src, className, width, height }) => {
  return src ? (
    <img alt={alt} src={src} width={width} height={height} className={className} />
  ) : null;
};

PropTypes.ThumbnailImage = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default ThumbnailImage;

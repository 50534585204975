import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import GlobalCreateModal from './GlobalCreateModal';
import appStyles from '../../styles/common/globalCreate';
import useDialog from '../../hooks/useDialog';

import { computePermissionRole } from '../../../shared/utils/helpers';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import GlobalCreateTooltip from './GlobalCreateTooltip';

const GlobalCreate = ({
  props,
  options,
  getGlobalCreateSelectedForPage,
  contextActions,
  pathname
}) => {
  const { allowedActions } = props.userContext.orgStaff;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState(null);

  const [isDialogOpen, toggleDialogOpen] = useDialog();

  // listen to page change and re-set selected modal (and re-render button text) accordingly
  useEffect(() => {
    setSelectedModal(getGlobalCreateSelectedForPage());
  }, [pathname]);

  function handleMenuItemClick(option) {
    setOpen(false);
    setSelectedModal(option);
    toggleDialogOpen();
  }

  function handleClick() {
    toggleDialogOpen();
    setSelectedModal(getGlobalCreateSelectedForPage());
    // Once modal closes, reset modal back to current page's default modal
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  const checkIfUserHasPermission = option => {
    if (option && option.allowedActionName) {
      return computePermissionRole(option.allowedActionName, allowedActions);
    }
    return true;
  };

  const checkForDisabledProps = option => {
    if (option && option.name) {
      const actionToCheck = contextActions.find(action => action.name === option.name);

      if (actionToCheck && actionToCheck.data.disabledProps) {
        return actionToCheck.data.disabledProps;
      }
    }
    return null;
  };

  const checkIfOptionDisabled = option => {
    const hasPermission = checkIfUserHasPermission(option);
    if (!hasPermission) return true;
    const disabledProps = checkForDisabledProps(option);
    return disabledProps && disabledProps.disabled;
  };

  const showContextAction = optionName => {
    let actionNames = contextActions.map(action => action.name);
    return actionNames.includes(optionName);
  };

  const willShowAnyContextActions =
    Object.values(options).filter(
      option =>
        option.isContextual && pathname.includes(option.pageName) && showContextAction(option.name)
    ).length > 0;

  const classes = appStyles({ willShowAnyContextActions });

  function renderOptions() {
    let regularOptions = Object.values(options)
      .filter(option => !option.isContextual)
      .map(option => {
        if (checkIfUserHasPermission(option)) {
          return (
            <MenuItem key={option.buttonName} onClick={() => handleMenuItemClick(option)}>
              {option.buttonName}
            </MenuItem>
          );
        } else {
          return (
            <GlobalCreateTooltip
              key={option.buttonName}
              variant="permission"
              willShowAnyContextActions={willShowAnyContextActions}
            >
              <span>
                <MenuItem key={option.buttonName} disabled>
                  {option.buttonName}
                </MenuItem>
              </span>
            </GlobalCreateTooltip>
          );
        }
      });

    let contextOptions = Object.values(options)
      .filter(
        option =>
          option.isContextual &&
          pathname.includes(option.pageName) &&
          showContextAction(option.name)
      )
      .map(option => {
        const hasPermission = checkIfUserHasPermission(option);
        if (hasPermission) {
          const optionDisabledProps = checkForDisabledProps(option);
          if (optionDisabledProps && optionDisabledProps.disabled) {
            return (
              <GlobalCreateTooltip
                key={option.buttonName}
                {...optionDisabledProps}
                willShowAnyContextActions
              >
                <span>
                  <MenuItem disabled>{option.buttonName}</MenuItem>
                </span>
              </GlobalCreateTooltip>
            );
          } else {
            return (
              <MenuItem key={option.buttonName} onClick={() => handleMenuItemClick(option)}>
                {option.buttonName}
              </MenuItem>
            );
          }
        } else {
          return (
            <GlobalCreateTooltip
              key={option.buttonName}
              variant="permission"
              willShowAnyContextActions
            >
              <span>
                <MenuItem key={option.buttonName} disabled>
                  {option.buttonName}
                </MenuItem>
              </span>
            </GlobalCreateTooltip>
          );
        }
      });
    const divider = text => (
      <div key={text}>
        <li key={`${text}-li`} className={classes.divider}>
          <Typography display="block" style={{ lineHeight: 2.75 }}>
            {text}
          </Typography>
        </li>
        <Divider component="li" key={`${text}-divider`} />
      </div>
    );
    if (!contextOptions.length) {
      return [...regularOptions];
    } else {
      return [
        divider('Context Actions'),
        ...contextOptions,
        divider('Global Actions'),
        ...regularOptions
      ];
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} align="center">
        <ButtonGroup
          className={classes.buttonGroup}
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          {selectedModal && (
            <Button
              disabled={checkIfOptionDisabled(selectedModal)}
              className={classes.primaryButton}
              onClick={handleClick}
            >
              {selectedModal.buttonName}
            </Button>
          )}
          <Button
            className={classes.primaryButton}
            size="small"
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          className={classes.menuPopper}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>{renderOptions()}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>

      <GlobalCreateModal
        isDialogOpen={isDialogOpen}
        handleClose={handleClick}
        selectedModal={selectedModal}
        contextActions={contextActions}
        toRefetchData={props.toRefetchData}
        options={options}
      />
    </Grid>
  );
};
export default withUserContext(GlobalCreate);

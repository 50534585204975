import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

export default callback => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation LogoutMutation($input: LogoutInput!) {
        logout(input: $input) {
          success
        }
      }
    `,
    variables: {
      input: {}
    },
    onCompleted: response => {
      callback(response);
    },
    onError: err => callback(err)
  });
};

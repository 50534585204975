/**
 * @flow
 * @relayHash b92a5bda1bf7b65450e26afdb7eb7f33
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateContractorGroupInput = {|
  name: string,
  description?: ?string,
  isPublic?: ?boolean,
  contractors: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type CreateContractorGroupMutationVariables = {|
  input: CreateContractorGroupInput
|};
export type CreateContractorGroupMutationResponse = {|
  +createContractorGroup: ?{|
    +newGroup: ?{|
      +id: string
    |}
  |}
|};
export type CreateContractorGroupMutation = {|
  variables: CreateContractorGroupMutationVariables,
  response: CreateContractorGroupMutationResponse,
|};
*/


/*
mutation CreateContractorGroupMutation(
  $input: CreateContractorGroupInput!
) {
  createContractorGroup(input: $input) {
    newGroup {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateContractorGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createContractorGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateContractorGroupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "newGroup",
        "storageKey": null,
        "args": null,
        "concreteType": "ContractorGroupNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateContractorGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateContractorGroupMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateContractorGroupMutation",
    "id": null,
    "text": "mutation CreateContractorGroupMutation(\n  $input: CreateContractorGroupInput!\n) {\n  createContractorGroup(input: $input) {\n    newGroup {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc9b22a8b180951397a1b67fbd82d216';
module.exports = node;

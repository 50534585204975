import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select, InputBase } from '@material-ui/core';
import GlobalButton from '../UI/GlobalButton';
import usePaginationStyles from '../../styles/common/usePaginationStyles';
import { PAGINATION_TYPES } from '../../constants';

const DIRECTIONS = {
  next: 'next',
  prev: 'prev'
};

const Pagination = props => {
  const {
    cursorStack,
    hasNextPage,
    endCursor,
    edgeCount,
    totalCount,
    onChange,
    paginationType = PAGINATION_TYPES.default.name,
    rowsPerPage
  } = props;

  const classes = usePaginationStyles();
  const [paginationInfo, setPaginationInfo] = useState({ from: 0, to: 0 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    setPaginationInfo({
      from: cursorStack.length * rowsPerPage + 1,
      to: cursorStack.length * rowsPerPage + edgeCount
    });
  }, [endCursor]);

  const pageChangeHandler = direction => {
    setLoading(true);
    switch (direction) {
      case DIRECTIONS.next:
        onChange({ cursorStack: cursorStack.concat(endCursor), first: rowsPerPage });
        break;
      case DIRECTIONS.prev:
        onChange({ cursorStack: cursorStack.slice(0, -1), first: rowsPerPage });
        break;
      default:
        break;
    }
  };

  const rowsPerPageChangeHandler = event => {
    onChange({ cursorStack: [], first: event.target.value });
  };

  const paginationValues = PAGINATION_TYPES[paginationType].values;

  return (
    totalCount > paginationValues[0] && (
      <div className={classes.paginationContainer}>
        <GlobalButton
          disabled={loading || cursorStack.length === 0}
          handleClick={() => pageChangeHandler(DIRECTIONS.prev)}
          variant="transparent"
        >
          Prev
        </GlobalButton>
        <span className={classes.bodySmall}>
          {paginationInfo.from}-{paginationInfo.to > totalCount ? totalCount : paginationInfo.to} of{' '}
          {totalCount}
        </span>
        <GlobalButton
          disabled={loading || !hasNextPage}
          handleClick={() => pageChangeHandler(DIRECTIONS.next)}
          variant="transparent"
        >
          Next
        </GlobalButton>
        <div className={classes.rowSelectContainer}>
          <span className={classes.bodySmall}>Items per page:</span>
          <FormControl>
            <Select
              value={rowsPerPage}
              onChange={rowsPerPageChangeHandler}
              name="rowsPerPage"
              input={<InputBase />}
            >
              {paginationValues.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    )
  );
};

Pagination.propTypes = {
  hasNextPage: PropTypes.bool,
  endCursor: PropTypes.string,
  edgeCount: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
  cursorStack: PropTypes.array,
  paginationType: PropTypes.oneOf([...Object.keys(PAGINATION_TYPES)])
};

export default Pagination;

import { TOOLS } from './contants';
import { resetTool } from './commons';

const toolCursor = (canvas, _config, { onSelectAnnotationHandler }) => {
  resetTool(canvas, TOOLS.defaultTool.name);
  canvas.getObjects().forEach(object => {
    object.hoverCursor = 'pointer';
  });
  canvas.on('mouse:down', onSelectAnnotationHandler);
};
export { toolCursor };

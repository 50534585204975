import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, has } from 'lodash';
import { CELL_WIDTHS } from '../../constants';

const TableColGroup = ({ rowTemplate }) => {
  const columns = useMemo(
    () =>
      rowTemplate.reduce((prev, column, index) => {
        const width = has(column, 'width') ? get(column, 'width') : null;
        const type = get(column, 'type');
        return prev.concat(
          <col
            key={`${get(column, 'name')}/${index}`}
            style={{
              width: width || CELL_WIDTHS[type]
            }}
          />
        );
      }, []),
    [rowTemplate]
  );

  return <colgroup>{columns}</colgroup>;
};

PropTypes.TableColGroup = {
  rowTemplate: PropTypes.array.isRequired
};

export default TableColGroup;

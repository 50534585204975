/**
 * @flow
 * @relayHash ac8471971210275918ded218fe2e2dca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteBatchDeliverableInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type DeleteBatchDeliverableMutationVariables = {|
  input: DeleteBatchDeliverableInput
|};
export type DeleteBatchDeliverableMutationResponse = {|
  +deleteBatchDeliverable: ?{|
    +isDeleted: ?boolean
  |}
|};
export type DeleteBatchDeliverableMutation = {|
  variables: DeleteBatchDeliverableMutationVariables,
  response: DeleteBatchDeliverableMutationResponse,
|};
*/


/*
mutation DeleteBatchDeliverableMutation(
  $input: DeleteBatchDeliverableInput!
) {
  deleteBatchDeliverable(input: $input) {
    isDeleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteBatchDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteBatchDeliverable",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteBatchDeliverablePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isDeleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteBatchDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteBatchDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteBatchDeliverableMutation",
    "id": null,
    "text": "mutation DeleteBatchDeliverableMutation(\n  $input: DeleteBatchDeliverableInput!\n) {\n  deleteBatchDeliverable(input: $input) {\n    isDeleted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cee1a54e35b8bfed4210f2a2714f99f9';
module.exports = node;

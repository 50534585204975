import React, { useState, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormikTextField from '../form/FormikTextField';
import GlobalButton from '../UI/GlobalButton';
import InvalidAccountCreation from './InvalidLinkFromEmail';
import { ReactComponent as Logo } from '../../../shared/images/AWEbase-Default-Light.svg';
import loginStyles from '../../styles/account/login';
import withAppType from '../../hoc/withAppType';
import { FormikCheckbox } from '../form/FormikCheckbox';

const ConfirmPassword = props => {
  const { submitHandler, validLink, error, variant } = props;
  const [hasLetter, setHasLetter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasLength, setHasLength] = useState(false);
  const { classes } = props;

  const checkPassword = value => {
    let hasLetter = /[a-zA-Z]/;
    let hasNumber = /\d/;
    let hasSymbol = /[$-/:-?{-~!"^_`[\]@#\\£§]/;

    setHasLetter(hasLetter.test(value));
    setHasNumber(hasNumber.test(value));
    setHasSymbol(hasSymbol.test(value));
    setHasLength(value.length > 7);
  };

  const handleValidate = values => {
    const { password, repeatPassword } = values;
    const errors = {};
    if (password !== repeatPassword) {
      errors.repeatPassword = 'Your passwords do not appear to match.';
    } else if (password === '123456' || password === 'password' || password === 'abc123') {
      errors.password = 'Please come up with a stronger password :)';
    } else if (!hasLetter || !hasNumber || !hasSymbol || !hasLength) {
      errors.password = 'Make sure all password requirements are met.';
    } else if (variant === 'createAccount' && !values.acceptTerms) {
      errors.acceptTerms = 'Please read and accept AWEbase Terms of Use to continue.';
    }
    return errors;
  };

  const _confirm = (values, actions) => {
    actions.setSubmitting(true);
    submitHandler(values, actions);
  };

  const initialValues = {
    password: '',
    repeatPassword: '',
    acceptTerms: false
  };
  return (
    <div className={classes.mainLogin}>
      <div className={classes.logo}>
        <Logo />
      </div>
      <div className={classes.loginContent}>
        {validLink === undefined ? (
          <Typography variant="body1">Loading...</Typography>
        ) : !validLink ? (
          <InvalidAccountCreation />
        ) : (
          <Fragment>
            <Typography variant="h1">
              {variant === 'resetPassword' ? 'Set new password' : 'Welcome to AWEbase'}
            </Typography>
            {variant !== 'resetPassword' && (
              <Typography className={classes.welcomeText}>
                Please set up a password to be used when accessing your account.
              </Typography>
            )}
            <Formik initialValues={initialValues} validate={handleValidate} onSubmit={_confirm}>
              {({ isSubmitting, values }) => (
                <Form className={`${classes.loginForm} ${classes.noMarginTop}`}>
                  <Field
                    required
                    fullWidth
                    name="password"
                    component={FormikTextField}
                    label="Password"
                    placeholder="Password"
                    type="password"
                    validate={checkPassword}
                    className={classes.loginFormInputField}
                  />
                  <Field
                    required
                    fullWidth
                    name="repeatPassword"
                    component={FormikTextField}
                    label="Repeat Password"
                    placeholder="Repeat Password"
                    type="password"
                    className={classes.loginFormInputField}
                  />
                  <div className={classes.passwordChecklist}>
                    <span>Please make sure your password has </span>
                    <span className={hasLetter ? classes.reqMet : classes.reqUnmet}>letters</span>,
                    <span className={hasNumber ? classes.reqMet : classes.reqUnmet}> numbers</span>,
                    <span className={hasSymbol ? classes.reqMet : classes.reqUnmet}> symbols</span>,
                    <span> and is at least </span>
                    <span className={hasLength ? classes.reqMet : classes.reqUnmet}>
                      at least 8 characters long.
                    </span>
                  </div>

                  {variant === 'createAccount' && (
                    <div className={classes.acceptTerms}>
                      <Typography>
                        By clicking the checkbox below you acknowledge that you have read and accept
                        AWEbase Terms of Use available{' '}
                        <a
                          href="https://www.awebase.com/awebase-software-terms-of-use/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                        .
                      </Typography>

                      <Field
                        component={FormikCheckbox}
                        name="acceptTerms"
                        label="I accept AWEbase Terms of Use"
                      />
                    </div>
                  )}

                  <GlobalButton
                    id="signInCompleteStaffAccount"
                    type="submit"
                    className={classes.submitNewPasswordButton}
                    fullWidth={true}
                    disabled={isSubmitting || (variant === 'createAccount' && !values.acceptTerms)}
                  >
                    {variant === 'resetPassword' ? 'Confirm new password' : 'Sign in'}
                  </GlobalButton>
                </Form>
              )}
            </Formik>

            <Typography
              variant="body2"
              className={error ? classes.badAlert : classes.badAlertPlaceholder}
            >
              {error}
            </Typography>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default withAppType(withStyles(loginStyles)(ConfirmPassword));

ConfirmPassword.propTypes = {
  variant: PropTypes.oneOf(['createAccount', 'resetPassword']),
  submitHandler: PropTypes.func.isRequired,
  validLink: PropTypes.bool,
  error: PropTypes.string
};

ConfirmPassword.defaultProps = {
  variant: 'createAccount'
};

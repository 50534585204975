import React, { Fragment, useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { makeStyles } from '@material-ui/core/styles';

import AutoTable from '../../../../../shared/components/table/AutoTable';
import CreateGroupForm from '../../CreateGroupForm';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import Dialog from '../../../../../shared/components/common/Dialog';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import GroupsQuickView from './GroupsQuickView';
import { CELL_TYPES, ALLOWED_ACTIONS } from '../../../../../shared/constants';
import useDialog from '../../../../../shared/hooks/useDialog';
import DeleteContractorGroupMutation from '../../mutations/DeleteContractorGroupMutation';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { errorToast } from '../../../../../shared/toasts';

const useStyles = makeStyles({
  createGroupForm: {
    '& .MuiDialog-paper': {
      width: '685px'
    }
  }
});

const GroupsList = props => {
  const classes = useStyles();

  const [selectedId, setSelectedId] = useState(null);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [selectedGroup, setGroup] = useState({});
  const [actionType, setActionType] = useState('');
  const [isDialogOpen, toggleDialogOpen] = useDialog();

  if (!props.general || !props.general.general) {
    return null;
  }

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const {
    relay: { refetch },
    general: {
      general: {
        contractorGroups: {
          edges,
          totalCount,
          edgeCount,
          pageInfo: { hasNextPage, endCursor }
        }
      }
    },
    userContext: {
      id: currentUserId,
      orgStaff: { allowedActions }
    }
  } = props;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const canEditOrDeleteGroup = id => {
    const selectedGroup = edges.find(edge => edge.node.id === id);
    return selectedGroup.node.createdBy.user.id === currentUserId;
  };

  const canUserCreateGroup = computePermissionRole(
    ALLOWED_ACTIONS.CONTRACTOR_GROUP_CREATE,
    allowedActions
  );

  const findAndSetGroup = (id = null) => {
    setSelectedId(null);
    if (id) {
      const selectedGroup = adjustedEdges.find(edge => edge.node.id === id);
      setGroup(selectedGroup.node);
    } else {
      setGroup({});
    }
    toggleDialogOpen();
  };

  const onLinkClickHandler = id => {
    if (!canEditOrDeleteGroup(id)) {
      errorToast('You cannot update this group.');
      return;
    }
    findAndSetGroup(id);
    setActionType('EDIT');
  };

  const onDuplicateClickHandler = id => {
    findAndSetGroup(id);
    setActionType('DUPLICATE');
  };

  const handleCreateGroup = () => {
    findAndSetGroup();
    setActionType('CREATE');
  };

  const onDeleteClickHandler = id => {
    setSelectedId(null);
    DeleteContractorGroupMutation(id, response => {
      if (response) {
        setRefetchCounter(counter => counter + 1);
      }
    });
  };

  const matchDialogTitle = () => {
    switch (actionType) {
      case 'EDIT':
        return 'Edit Group';
      case 'DUPLICATE':
        return 'Duplicate Group';
      case 'CREATE':
        return 'Add New Contractor Group';
      default:
        return 'Add New Contractor Group';
    }
  };

  const adjustedEdges = edges.map(edge => {
    const { id, name, numberOfMembers, created, isPublic, contractors } = edge.node;
    return {
      node: {
        id,
        name,
        numberOfMembers,
        created,
        visibility: isPublic,
        isPublic,
        // need for duplicate action
        contractors,
        hideTrash: !canEditOrDeleteGroup(id)
      }
    };
  });

  return (
    <Fragment>
      {canUserCreateGroup && (
        <GlobalButton handleClick={handleCreateGroup} noMargin variant="secondary">
          Create Group
        </GlobalButton>
      )}

      <AutoTable
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
          {
            name: 'name',
            label: 'Group Name',
            sortable: true,
            type: CELL_TYPES.link
          },
          { name: 'visibility', label: '', type: CELL_TYPES.visibility, width: 30 },
          {
            name: 'created',
            label: 'Creation date',
            sortable: true,
            type: CELL_TYPES.date
          },
          { name: 'numberOfMembers', label: 'Members', align: 'right' },
          {
            name: '',
            label: '',
            type: CELL_TYPES.duplicate,
            hidden: !canUserCreateGroup,
            width: 30
          },
          {
            name: '',
            label: '',
            type: CELL_TYPES.trash,
            onClick: onDeleteClickHandler,
            width: 30
          }
        ]}
        edges={adjustedEdges}
        onChangeHandler={onChangeHandler}
        refetchCounter={refetchCounter}
        rowProps={{
          handleLinkClick: onLinkClickHandler,
          handleDuplicateClick: onDuplicateClickHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />

      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <GroupsQuickView groupId={selectedId} />
      </QuickViewDrawer>
      <Dialog
        className={classes.createGroupForm}
        title={matchDialogTitle()}
        isDialogOpen={isDialogOpen}
        closeDialog={toggleDialogOpen}
      >
        <CreateGroupForm
          setRefetchCounter={setRefetchCounter}
          handleClose={toggleDialogOpen}
          group={selectedGroup}
          actionType={actionType}
        />
      </Dialog>
    </Fragment>
  );
};
export default withUserContext(
  createRefetchContainer(
    GroupsList,
    {
      general: graphql`
        fragment GroupsList_general on Query {
          general {
            contractorGroups(first: $first, orderBy: $orderBy, after: $after) {
              pageInfo {
                hasNextPage
                endCursor
              }
              totalCount
              edgeCount
              edges {
                node {
                  id
                  isPublic
                  name
                  numberOfMembers
                  created
                  createdBy {
                    user {
                      id
                    }
                  }
                  contractors {
                    edges {
                      node {
                        id
                        fullName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query GroupsListRefetchQuery($first: Int, $orderBy: String, $after: String) {
        ...GroupsList_general
      }
    `
  )
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const updateJobMutation = graphql`
  mutation UpdateJobMutation($input: UpdateJobInput!) {
    updateJob(input: $input) {
      updatedJob {
        name
        description
      }
    }
  }
`;

export default (variables, callback) => {
  const updateJobVariables = {
    input: variables
  };

  const config = {
    mutation: updateJobMutation,
    variables: updateJobVariables,
    onCompleted: (response, errors) => {
      callback(response.updateJob, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

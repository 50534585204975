import React from 'react';

import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import ContractorsListPage from './ContractorsListPage';
import GroupsListPage from './GroupsListPage';

const ContractorsPage = props => {
  return (
    <AutoTabList>
      <ContractorsListPage label="Contractors" {...props} />
      <GroupsListPage label="Groups" {...props} />
    </AutoTabList>
  );
};

export default ContractorsPage;

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid, Box } from '@material-ui/core';

import * as Yup from 'yup';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import FormikDropdownField from '../../../shared/components/form/FormikDropdownField';
import FormikDateField from '../../../shared/components/form/FormikDateField';
import CreateBatchDeliverableMutation from './mutations/CreateBatchDeliverableMutation';
import FormikSearchableDropdown from '../../../shared/components/form/FormikSearchableDropdown';
import GlobalButton from '../../../shared/components/UI/GlobalButton';

import { formatDateForQueries } from '../../../shared/utils/formatters';
import { successToast } from '../../../shared/toasts';
import { getCurrencyCodeFromUserContext } from '../../../shared/utils/helpers';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import { FormikAmountWithCurrencyField } from '../form/AmountWithCurrencyField';
import { FormikCategorySelectWithTypes } from '../shared/dropdowns/CategorySelectWithTypes';

const CreateDeliverableBatchForm = props => {
  const { handleClose, toRefetchData, isContextual, contextAction, userContext } = props;
  const navigation = JSON.parse(userContext.orgStaff.organization.configuration).navigation;

  const initialValues = {
    title: '',
    category: null,
    release:
      isContextual && contextAction
        ? {
            value: contextAction.data.releaseId,
            label: `${contextAction.data.releaseName} (${contextAction.data.productTitle})`
          }
        : null,
    categoryType: '',
    amount: { amount: '', currencyCode: getCurrencyCodeFromUserContext(userContext) },
    quantity: '',
    dueDate: null,
    assignedStaff: '',
    tags: { options: [] },
    description: ''
  };

  const CreateDeliverableBatchFormSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.')
      .required('Required'),
    amount: Yup.object().shape({
      amount: Yup.number()
        .transform(cv => (isNaN(cv) ? undefined : cv))
        .min(0, 'Amount must be zero or more.')
    }),
    quantity: Yup.number()
      .required('Required')
      .min(0, 'Amount must be higher than 0')
      .max(30, 'Please enter a number lower than 30.'),
    dueDate: Yup.date().nullable(true),
    description: Yup.string().max(5000, 'Please enter no more than 5000 characters.')
  });

  const validate = values => {
    let errors = {};
    if (!values.release || !values.release.value) {
      errors.release = 'Required';
    }
    if (!values.category || !values.category.value) {
      errors.category = 'Required';
    }
    return errors;
  };

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    CreateBatchDeliverableMutation(
      {
        title: values.title,
        category: values.category.value,
        release: values.release.value,
        categoryType: values.categoryType,
        amount: values.amount.amount || values.amount.amount === 0 ? values.amount.amount : null,
        currencyCode: values.amount.currencyCode,
        quantity: values.quantity,
        ...(values.assignedStaff && { assignedStaff: values.assignedStaff.value }),
        ...(values.dueDate && { dueDate: formatDateForQueries(values.dueDate) }),
        tags: values.tags.options.map(option => option.label),
        description: values.description
      },
      (createDeliverable, errors) => {
        setSubmitting(false);

        if (errors && errors[0].fields) {
          setErrors(errors[0].fields);
        } else if (createDeliverable && createDeliverable.isCreated) {
          handleClose();
          setTimeout(() => {
            successToast('New deliverables have been created.', {
              text: 'Go to Deliverables List',
              link: `/deliverables`
            });
          }, 250);
          toRefetchData();
        }
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateDeliverableBatchFormSchema}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ values, setFieldTouched, isSubmitting, submitForm, setFieldValue }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                required
                name="title"
                component={FormikTextField}
                label="Deliverable Name"
                placeholder="Enter a Deliverable name..."
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                required
                name="release"
                placeholder={`Enter ${navigation.productTitle.singular} or ${navigation.releaseTitle.singular} name...`}
                disabled={isContextual}
                component={FormikSearchableDropdown}
                fullWidth
                {...(isContextual ? { setInitialValue: true } : null)}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                required
                name="category"
                component={FormikCategorySelectWithTypes}
                label="Category"
                placeholder="Select a Category..."
                onChange={(name, value) => {
                  setFieldValue('categoryType', '');
                  setFieldValue(name, value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="categoryType"
                itemType="categories"
                items={
                  values.category
                    ? values.category.types.edges.map(edge => ({ ...edge.node }))
                    : null
                }
                component={FormikDropdownField}
                label="Type"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="amount"
                component={FormikAmountWithCurrencyField}
                label="Amount"
                placeholder="Enter an amount..."
                onKeyPress={() => setFieldTouched('amount', true)} // manually run setFieldTouched on keypress to immediately run validation
              />
            </Grid>
            <Grid item xs={6}>
              <Field name="dueDate" component={FormikDateField} label="Due Date" />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="quantity"
                required
                component={FormikTextField}
                label="Quantity"
                placeholder="Enter a quantity up to 30..."
                type="number"
                step="1"
                onKeyPress={() => setFieldTouched('quantity', true)} // manually run setFieldTouched on keypress to immediately run validation
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="assignedStaff"
                component={FormikSearchableDropdown}
                placeholder="Select Staff..."
                label="Staff Coordinator"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="tags"
                label="Tags"
                placeholder="Select tags..."
                component={FormikSearchableDropdown}
                creatable
                onChange={(field, options) => setFieldValue(field, options)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                multiline
                rows="5"
                name="description"
                component={FormikTextField}
                label="Description"
                placeholder="Enter a Deliverable description..."
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" width="100%" mt={3}>
            <GlobalButton
              variant="transparent"
              handleClick={handleClose}
              id="cancelDeliverableBatch"
            >
              Cancel
            </GlobalButton>
            <GlobalButton id="submitDeliverableBatch" type="submit" disabled={isSubmitting}>
              Create Batch Deliverables
            </GlobalButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(CreateDeliverableBatchForm);

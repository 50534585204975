import { fetchQuery } from 'react-relay';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

const getResetPasswordTokenStatus = (resetToken, uidb64, callback) => {
  const query = graphql`
    query getResetPasswordTokenStatusQuery($resetToken: String!, $uidb64: String!) {
      resetPasswordTokenStatus(resetToken: $resetToken, uidb64: $uidb64)
    }
  `;

  fetchQuery(getEnvironment(), query, {
    resetToken,
    uidb64
  }).then(data => {
    callback(data);
  });
};

getResetPasswordTokenStatus.PropTypes = {
  resetToken: PropTypes.string.isRequired,
  uidb64: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
};

export default getResetPasswordTokenStatus;

import { makeStyles } from '@material-ui/styles';

export const tabStyles = theme => {
  return {
    root: {
      flexGrow: 1
    },
    tabPanel: {
      marginLeft: '-30px',
      paddingLeft: '30px',
      borderRadius: '4px',
      backgroundColor: props => (props.noBackground ? '' : 'white'),
      boxShadow: props => (props.noBackground ? '' : '0 2px 2px 0 rgba(210,218,228,0.35)')
    },
    tabBox: {
      padding: '30px 30px 30px 0'
    },
    tabs: {
      backgroundColor: theme.palette.colors.lightBlueGrey,
      color: theme.palette.colors.darkGrey,
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main
      }
    },
    tab: {
      fontSize: '0.8125rem',
      fontWeight: 'bold',
      textTransform: 'none !important',
      '&:hover': {
        backgroundColor: theme.palette.primary.tabBackground,
        color: theme.palette.primary.main
      }
    },
    selectedTab: {
      backgroundColor: theme.palette.primary.tabBackground,
      fontSize: '0.8125rem',
      fontWeight: 'bold',
      textTransform: 'none',
      padding: '9px 10px',
      color: theme.palette.primary.main
    },
    appBar: {
      boxShadow: 'none'
    }
  };
};

export const useTabStyles = makeStyles(tabStyles);

/**
 * @flow
 * @relayHash c76271ba510c63f39875cd4007eebf0d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SolicitationSearchableContractorGroupDropdownQueryVariables = {|
  name?: ?string,
  orderBy?: ?string,
  first?: ?number,
|};
export type SolicitationSearchableContractorGroupDropdownQueryResponse = {|
  +general: ?{|
    +contractorGroups: ?{|
      +edgeCount: ?number,
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +contractors: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +fullName: ?string,
                +lastName: ?string,
                +representativeImageUrl: ?string,
                +conflictingContractor: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |}
      |}>,
    |}
  |}
|};
export type SolicitationSearchableContractorGroupDropdownQuery = {|
  variables: SolicitationSearchableContractorGroupDropdownQueryVariables,
  response: SolicitationSearchableContractorGroupDropdownQueryResponse,
|};
*/


/*
query SolicitationSearchableContractorGroupDropdownQuery(
  $name: String
  $orderBy: String
  $first: Int
) {
  general {
    contractorGroups(name: $name, orderBy: $orderBy, first: $first) {
      edgeCount
      totalCount
      edges {
        node {
          id
          name
          contractors {
            edges {
              node {
                id
                fullName
                lastName
                representativeImageUrl
                conflictingContractor {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "general",
    "storageKey": null,
    "args": null,
    "concreteType": "General",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractorGroups",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          }
        ],
        "concreteType": "ContractorGroupNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorGroupNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorGroupNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contractors",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractorNode",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fullName",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "lastName",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "representativeImageUrl",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "conflictingContractor",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ContractorNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationSearchableContractorGroupDropdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationSearchableContractorGroupDropdownQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationSearchableContractorGroupDropdownQuery",
    "id": null,
    "text": "query SolicitationSearchableContractorGroupDropdownQuery(\n  $name: String\n  $orderBy: String\n  $first: Int\n) {\n  general {\n    contractorGroups(name: $name, orderBy: $orderBy, first: $first) {\n      edgeCount\n      totalCount\n      edges {\n        node {\n          id\n          name\n          contractors {\n            edges {\n              node {\n                id\n                fullName\n                lastName\n                representativeImageUrl\n                conflictingContractor {\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '881c8bc925bda8b494e9df0c153a7972';
module.exports = node;

/**
 * @flow
 * @relayHash 83b493518cb9766a380948d0279f9a25
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type UnlockDeliverableBatchForJobInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type BatchUnlockDeliverablesMutationVariables = {|
  input: UnlockDeliverableBatchForJobInput
|};
export type BatchUnlockDeliverablesMutationResponse = {|
  +unlockBatchDeliverableForJob: ?{|
    +isUnlocked: ?boolean,
    +deliverables: ?$ReadOnlyArray<?{|
      +id: string,
      +state: DeliverableState,
    |}>,
  |}
|};
export type BatchUnlockDeliverablesMutation = {|
  variables: BatchUnlockDeliverablesMutationVariables,
  response: BatchUnlockDeliverablesMutationResponse,
|};
*/


/*
mutation BatchUnlockDeliverablesMutation(
  $input: UnlockDeliverableBatchForJobInput!
) {
  unlockBatchDeliverableForJob(input: $input) {
    isUnlocked
    deliverables {
      id
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnlockDeliverableBatchForJobInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "unlockBatchDeliverableForJob",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnlockDeliverableBatchForJobPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isUnlocked",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BatchUnlockDeliverablesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BatchUnlockDeliverablesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BatchUnlockDeliverablesMutation",
    "id": null,
    "text": "mutation BatchUnlockDeliverablesMutation(\n  $input: UnlockDeliverableBatchForJobInput!\n) {\n  unlockBatchDeliverableForJob(input: $input) {\n    isUnlocked\n    deliverables {\n      id\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b607f9e367aa7645d64d34de3eb1feb';
module.exports = node;

import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import DetailsSection from '../../../shared/components/common/DetailsSection';
import CommentThread from '../../../shared/components/comment/CommentThread';
import { mapSolicitationComments } from '../../../shared/utils/helpers';
import AddSolicitationCommentMutation from '../../../shared/mutations/solicitations/AddSolicitationCommentMutation';
import UpdateSolicitationCommentMutation from '../../../shared/mutations/solicitations/UpdateSolicitationCommentMutation';
import RemoveSolicitationCommentMutation from '../../../shared/mutations/solicitations/RemoveSolicitationCommentMutation';
import { SOLICITATION_STATES } from '../../../shared/constants';
import useInterval from '../../../shared/hooks/useInterval';

const SolicitationCommentThread = props => {
  const {
    solicitationId,
    solicitation,
    relay: { refetch },
    refetchNotifications = () => {}
  } = props;

  useInterval(
    () => {
      if (solicitation && solicitation.state === SOLICITATION_STATES.active) {
        refetch({ id: solicitationId }, null, refetchNotifications, { force: true });
      }
    },
    60000,
    solicitation.state
  );

  const handleSubmitComment = ({ comment, repliedTo }, cb) => {
    AddSolicitationCommentMutation(
      {
        solicitationId,
        content: comment,
        ...(repliedTo && { repliedTo: repliedTo.commentId })
      },
      response => {
        if (response && response.isUpdated) {
          cb();
        }
      }
    );
  };

  const handleUpdateComment = (commentId, content, cb) => {
    const variables = {
      commentId,
      content
    };
    UpdateSolicitationCommentMutation(variables, response => {
      if (response && response.isUpdated) {
        cb();
      }
    });
  };

  const handleDeleteComment = commentId => {
    RemoveSolicitationCommentMutation(commentId, () => {});
  };

  if (solicitation) {
    const { state, comments } = solicitation;
    return (
      <DetailsSection>
        <CommentThread
          formTitle=""
          comments={mapSolicitationComments(comments)}
          handleAddComment={state === SOLICITATION_STATES.active ? handleSubmitComment : null}
          handleReplyToComment={state === SOLICITATION_STATES.active ? handleSubmitComment : null}
          handleUpdateComment={handleUpdateComment}
          handleDeleteComment={state === SOLICITATION_STATES.active ? handleDeleteComment : null}
          noEditButton={state !== SOLICITATION_STATES.active}
        />
      </DetailsSection>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default createRefetchContainer(
  SolicitationCommentThread,
  {
    solicitation: graphql`
      fragment SolicitationCommentThread_solicitation on SolicitationNode
        @argumentDefinitions(id: { type: "ID!", defaultValue: "" }) {
        solicitationId
        state
        comments {
          edges {
            node {
              id
              content
              created
              contentEditedAt
              repliedTo {
                content
                created
                user {
                  fullName
                }
              }
              contractor {
                fullName
                representativeImageUrl
                freelancer {
                  user {
                    id
                  }
                }
              }
              createdByStaff {
                fullName
                representativeImageUrl
                user {
                  id
                }
              }
            }
          }
        }
      }
    `
  },
  graphql`
    query SolicitationCommentThreadRefetchQuery($id: ID!) {
      solicitation: solicitation(id: $id) {
        ...SolicitationCommentThread_solicitation @arguments(id: $id)
      }
    }
  `
);

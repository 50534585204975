import React from 'react';
import { withRouter } from 'react-router';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import { CELL_TYPES } from '../../../../../shared/constants';

const DetailsSolicitations = props => {
  const { solicitations } = props.deliverable;

  const mappedSolicitationsEdges = solicitations.edges.map(edge => {
    const {
      id,
      dateSent,
      subject,
      staffCoordinator: { representativeImageUrl, fullName },
      state
    } = edge.node;

    return {
      node: {
        id,
        dateSent,
        subject,
        staffCoordinator: { name: fullName, imageUrl: representativeImageUrl },
        state: {
          code: state
        }
      }
    };
  });

  const onLinkClickHandler = id => {
    const { history } = props;
    history.push(`/solicitations/${id}`);
  };

  return (
    <DetailsSection noBorder title="JobOpps">
      {solicitations.edges.length ? (
        <AutoTable
          edges={mappedSolicitationsEdges}
          rowTemplate={[
            {
              name: 'subject',
              label: 'Subject',
              type: CELL_TYPES.link,
              sortable: true
            },
            {
              name: 'dateSent',
              type: CELL_TYPES.date,
              label: 'Date Sent',
              width: 100
            },
            {
              name: 'state',
              type: CELL_TYPES.status,
              label: 'Status',
              statusTypes: props.solicitationStateTypes.enumValues,
              variant: 'solicitation'
            },
            {
              name: 'staffCoordinator',
              label: 'Coordinator',
              type: CELL_TYPES.avatar,
              onlyTooltip: true,
              align: 'center'
            },
          ]}
          rowProps={{
            handleLinkClick: onLinkClickHandler
          }}
        />
      ) : (
        'Not added to any JobOpps'
      )}
    </DetailsSection>
  );
};

export default withRouter(
  createFragmentContainer(DetailsSolicitations, {
    deliverable: graphql`
      fragment DetailsSolicitations_deliverable on DeliverableNode {
        solicitations {
          edges {
            node {
              id
              staffCoordinator {
                representativeImageUrl
                fullName
              }
              dateSent
              state
              subject
            }
          }
        }
      }
    `,
    solicitationStateTypes: graphql`
      fragment DetailsSolicitations_solicitationStateTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  })
);

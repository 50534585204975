const colors = {
  grey: '#3F3F3F',
  orange: '#e77200',
  brightOrange: '#E7A71B',
  darkOrange: '#B7540A',
  lightOrange: '#ffd8b3',
  lightestOrange: '#F9EDE3',
  darkGrey: '#2A174B',
  activeDarkGrey: '#2A2A2A',
  lightGrey: '#B6B3FC',
  lightBlueGrey: '#EDF1F6',
  mediumBlueGrey: '#989AAB',
  darkBlueGrey: '#4A4554',
  blueGrey: '#9FA2B5',
  lightestGrey: '#E3E5EE',
  filterButtonGrey: '#D5D9E2',
  dividerGrey: '#B5B8C7',
  toastLightGreen: '#C2E0D4',
  red: '#EA2727',
  green: '#27C569',
  lightGreen: '#D4F3E1',
  yellow: '#FCA740',
  lighterYellow: '#F8C868',
  lightBlue: '#ECECFB',
  blue: '#289DE8',
  tableGrey: '#9A9CAD',
  tableHeaderGrey: '#9B9DAD',
  tableBackground: '#FAFBFC',
  sidebarIconGrey: '#8A8A8A',
  formFieldBorderColor: '#B7BBC9',
  brightViolet: '#5612ce',
  violet: '#401C82',
  darkViolet: '#2a174b',
  lightViolet: '#b6b3fc',
  activeDarkViolet: '#220E45',
  annotationStroke: 'rgb(255, 0, 74)',
  annotationFill: 'rgba(255, 0, 74, 0.15)',
  freelancerTabBackground: '#E0E4F5',
  organizationTabBackground: '#EBE3DE',
  progressBarLightGrey: '#E8E7ED'
};

export default colors;

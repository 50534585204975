import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation MarkClosedSolicitationsBatchMutation($input: MarkClosedSolicitationBatchInput!) {
    markClosedSolicitationBatch(input: $input) {
      isUpdated
    }
  }
`;

const MarkClosedSolicitationBatchMutation = (ids, callback) => {
  const markClosedSolicitationBatchVariables = {
    input: { ids }
  };

  const config = {
    mutation,
    variables: markClosedSolicitationBatchVariables,
    onCompleted: response => {
      callback(response.markClosedSolicitationBatch);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default MarkClosedSolicitationBatchMutation;

MarkClosedSolicitationBatchMutation.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired
};

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { round } from 'lodash';

const dateFns = new DateFnsUtils();

export const round2 = value => round(value, 2);

export const formatFullName = (firstName, lastName) => `${firstName} ${lastName}`;

export const formatAmount = (amount = '', currency = 'USD') => {
  if (amount !== null) {
    // passing undefined as first argument, makes a use of browser locale (for correxct number formatting)
    return currency
      ? new Intl.NumberFormat(undefined, { style: 'currency', currency }).format(amount)
      : amount;
  } else {
    return '-';
  }
};

export const getSymbol = currency => {
  const symbol = new Intl.NumberFormat('en', { style: 'currency', currency })
    .formatToParts('')
    .find(x => x.type === 'currency');
  return symbol && symbol.value;
};

const _parseStringToDate = date => {
  if (typeof date === 'string' && date.indexOf('T') !== -1) {
    date = date.substring(0, date.indexOf('T'));
  }
  if (typeof date === 'string') {
    date = dateFns.parse(date, 'yyyy-MM-dd', new Date());
  }
  return date;
};

export const formatDateDisplay = (date, dateFormat = 'MMM d, yyyy') => {
  if (!date) {
    return '-';
  }
  date = _parseStringToDate(date);
  return dateFns.format(
    date,
    dateFormat.replace(/D|Y/g, x => x.toLowerCase())
  );
};

export const getDateFormatForDateField = (dateFormat = 'MMM d, yyyy') => {
  return dateFormat
    .replace(/M{1,}/i, 'MM')
    .replace(/D{1,}/i, 'dd')
    .replace(/Y{1,}/i, 'yyyy')
    .replace(/\.|\/|,\s/g, '-')
    .split(' ')
    .join('-');
};

export const formatDateForQueries = date => {
  const momentDate = moment(date);
  if (!momentDate.isValid()) return;

  if (momentDate.toString().indexOf('T') !== -1) {
    return dateFns.format(new Date(momentDate), 'yyyy-MM-dd');
  }
  return dateFns.format(new Date(`${momentDate} 00:00`), 'yyyy-MM-dd');
};

export const formatSecondsToHms = seconds => {
  const d = Number(seconds);

  const values = [
    Math.floor(d / 3600),
    Math.floor((d % 3600) / 60),
    Math.floor((d % 3600) % 60)
  ].filter((value, index) => value > 0 || index === 2);

  if (values.length === 1) {
    values.unshift(0);
  }

  if (values[values.length - 1] < 10) {
    values[values.length - 1] = '0' + values[values.length - 1];
  }
  return values.join(':');
};

export const formatBytes = (bytes = 0, decimals = 2) => {
  if (bytes === 0) return '0 B';
  const isNegative = bytes < 0 ? '-' : '';
  bytes = Math.abs(bytes);
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return isNegative + parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatBytesToGb = (bytes = 0, decimals = 2) => {
  return parseFloat((bytes / Math.pow(1000, 3)).toFixed(decimals)) + ' GB';
};

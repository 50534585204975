import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from '../../../shared/components/table/StyledTableCell';
import DownloadFileSquareButton from '../UI/DownloadFileSquareButton';

const TableCellFileDownload = ({ index, fileProps, ...otherProps }) => (
  <StyledTableCell>
    {fileProps ? (
      <DownloadFileSquareButton
        id={`downloadFile-${index}`}
        fileName={fileProps.fileName}
        fileUrl={fileProps.fileUrl}
        variant="transparent"
        {...otherProps}
      />
    ) : (
      <p>No file provided</p>
    )}
  </StyledTableCell>
);

TableCellFileDownload.propTypes = {
  fileProps: PropTypes.shape({
    fileUrl: PropTypes.string,
    fileName: PropTypes.string
  }),
  tooltipTitle: PropTypes.string
};

export default TableCellFileDownload;

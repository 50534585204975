import React from 'react';
import PropTypes from 'prop-types';
import StyledTableCell from './StyledTableCell';
import SquareButton from '../UI/SquareButton';
import ConfirmationDialog from '../common/ConfirmationDialogV2';

const TableCellTrash = ({
  id,
  rowIndex,
  handleClick,
  hidden,
  disabled,
  withConfirm,
  renderConfirmContent,
  fileName,
  tooltip
}) => {
  const onClickHandler = () => {
    handleClick(id);
  };

  const squareButtonVariables = {
    id: `deleteRow-${rowIndex}`,
    icon: 'trash',
    variant: 'transparent',
    disabled,
    tooltipTitle: tooltip
  };

  return (
    <StyledTableCell noWrap>
      {!hidden &&
        (withConfirm ? (
          <ConfirmationDialog
            content={renderConfirmContent(fileName)}
            onConfirm={onClickHandler}
            copies={{ confirm: 'Delete' }}
          >
            {showConfirm => <SquareButton {...squareButtonVariables} onClick={showConfirm} />}
          </ConfirmationDialog>
        ) : (
          <SquareButton {...squareButtonVariables} onClick={onClickHandler} />
        ))}
    </StyledTableCell>
  );
};

TableCellTrash.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  withConfirm: PropTypes.bool,
  renderConfirmContent: PropTypes.func,
  tooltipTitle: PropTypes.string
};

TableCellTrash.defaultProps = {
  hidden: false,
  disabled: false,
  renderConfirmContent: fileName => (
    <>
      You are about to delete <b>{fileName}</b>
    </>
  )
};

export default TableCellTrash;

/**
 * @flow
 * @relayHash 6747055e9c2c0f43b00352645c9d0df9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExportDeliverablesInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type ExportDeliverablesMutationVariables = {|
  input: ExportDeliverablesInput
|};
export type ExportDeliverablesMutationResponse = {|
  +exportDeliverables: ?{|
    +fileUrl: ?string
  |}
|};
export type ExportDeliverablesMutation = {|
  variables: ExportDeliverablesMutationVariables,
  response: ExportDeliverablesMutationResponse,
|};
*/


/*
mutation ExportDeliverablesMutation(
  $input: ExportDeliverablesInput!
) {
  exportDeliverables(input: $input) {
    fileUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ExportDeliverablesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "exportDeliverables",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ExportDeliverablesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "fileUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ExportDeliverablesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ExportDeliverablesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ExportDeliverablesMutation",
    "id": null,
    "text": "mutation ExportDeliverablesMutation(\n  $input: ExportDeliverablesInput!\n) {\n  exportDeliverables(input: $input) {\n    fileUrl\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb1bd1933965cedd85baa54613d932e0';
module.exports = node;

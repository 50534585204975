import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import colors from '../../../shared/styles/common/colors';
import Avatar from '../common/Avatar';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  containerColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },

  title: {
    color: colors.darkBlueGrey,
    fontSize: '26px'
  },

  categoryIcon: {
    position: 'relative',
    top: '3px',
    '& svg': {
      width: '70px',
      height: '25px',

      '& path': {
        fill: colors.darkBlueGrey
      },
      '& g': {
        fill: colors.darkBlueGrey
      }
    }
  },
  avatarIcon: {
    position: 'relative',
    top: '3px'
  }
}));

const CategoryTitleHeader = props => {
  const { title, icon, link, renderTop, renderBottom } = props;
  const classes = useStyles();

  const renderIcon = () => {
    switch (typeof icon) {
      case 'string': {
        return (
          <ListItemIcon className={classes.avatarIcon} aria-label="category icon">
            <Avatar src={icon} size={34} />{' '}
          </ListItemIcon>
        );
      }
      case 'object': {
        if (!icon) return null;
        const Icon = icon;
        return (
          <ListItemIcon className={classes.categoryIcon} aria-label="category icon">
            <Icon className={classes.categoryIcon} />
          </ListItemIcon>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderRow = row => {
    if (!row) {
      return null;
    }
    switch (typeof row) {
      case 'string': {
        return <span className={classes.subtitle}>{row}</span>;
      }
      case 'function': {
        return row();
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={classes.container}>
      <Link className={classes.link} to={link}>
        {renderIcon()}
      </Link>
      <div className={classes.containerColumn}>
        {renderRow(renderTop)}
        <div className={classes.container}>
          <span className={classes.title}>{title}</span>
        </div>
        {renderRow(renderBottom)}
      </div>
    </div>
  );
};

CategoryTitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  renderTop: PropTypes.func,
  renderBottom: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  link: PropTypes.string.isRequired
};

export default CategoryTitleHeader;

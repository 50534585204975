import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import FormikTextField from '../FormikTextField';
import DetailsRow from '../../UI/DetailsRow';

const EditableField = props => {
  const {
    editing,
    component = FormikTextField,
    name,
    label,
    formikValues,
    getContent = value => value,
    gridItemProps,
    detailsRowProps,
    fieldProps,
    renderDetail
  } = props;

  const detail = renderDetail ? (
    renderDetail(formikValues[name])
  ) : (
    <DetailsRow title={label} content={getContent(formikValues[name])} {...detailsRowProps} />
  );

  return (
    <Grid item {...gridItemProps}>
      {editing ? <Field name={name} label={label} component={component} {...fieldProps} /> : detail}
    </Grid>
  );
};

EditableField.propTypes = {
  editing: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formikValues: PropTypes.object.isRequired,
  gridItemProps: PropTypes.object,
  component: PropTypes.any,
  getContent: PropTypes.func
};

EditableField.defaultProps = {
  detailsRowProps: { noMarginTop: true },
  gridItemProps: { xs: 6 }
};

export default EditableField;

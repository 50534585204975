import { TOOLS } from './contants';

const setCursors = (canvas, defaultCursor, hoverCursor) => {
  canvas.defaultCursor = defaultCursor;
  canvas.hoverCursor = hoverCursor;
};

const resetTool = (canvas, toolName) => {
  canvas.off();
  canvas.isDrawingMode = false;
  canvas.getObjects().forEach(obj => {
    obj.set({selectable: false, active: false, hoverCursor: null});
  });
  setCursors(canvas, TOOLS[toolName].cursor, TOOLS[toolName].cursor);
};

export { resetTool };

import React, { Fragment, useState, useMemo } from 'react';
import { fetchQuery } from 'react-relay';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { Typography, Grid, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import Detail from '../../../../../shared/components/UI/Detail';
import StyledTableCell from '../../../../../shared/components/table/StyledTableCell';
import AmountTrend from '../../../../../shared/components/UI/AmountTrend';
import Amount from '../../../../../shared/components/common/Amount';
import { TableCellWithBadge } from './styled';

import { CELL_TYPES, DELIVERABLE_STATES, PAGINATION_TYPES } from '../../../../../shared/constants';
import { getEnvironment } from '../../../../../shared/utils/helpers';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import { round2 } from '../../../../../shared/utils/formatters';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import Date from '../../../../../shared/components/common/Date';
import { resolveDeliverableLink } from '../../../../../shared/utils/linkResolvers';

const BudgetQuickView = ({ releaseId, selectedId, exchangeRateDate, userContext }) => {
  const [state, setState] = useState(null);

  const theme = useTheme();
  const { orange, red } = theme.palette.colors;

  useEffectWithStatus(
    status => {
      getDataPromise({ orderBy: 'exchangeEffect' }).then(response => {
        status.mounted && setState(response.releaseCategoryInfo);
      });
    },
    [selectedId]
  );

  const getDataPromise = variables => {
    return fetchQuery(
      getEnvironment(),
      graphql`
        query BudgetQuickViewQuery(
          $releaseId: ID!
          $categoryId: ID
          $first: Int
          $after: String
          $orderBy: String
        ) {
          releaseCategoryInfo(releaseId: $releaseId, categoryId: $categoryId) {
            category {
              id
              name
            }
            contractedAmount
            pendingAmount
            committedAmount
            originalCommittedAmount
            deliverables(first: $first, after: $after, orderBy: $orderBy) {
              edges {
                node {
                  state
                  id
                  title
                  amount
                  currencyCode
                  amountInHomeCurrency
                  originalAmountInHomeCurrency
                }
              }
              totalCount
              edgeCount
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
      `,
      {
        releaseId,
        categoryId: selectedId === 'otherCategory' ? '' : selectedId,
        first: PAGINATION_TYPES.default.defaultValue,
        ...variables
      }
    );
  };

  const onChangeHandler = variables => {
    getDataPromise(variables).then(response => setState(response.releaseCategoryInfo));
  };

  const categoryName = useMemo(() => {
    if (state) {
      return state.category ? state.category.name : 'Other Categories';
    }
  }, [state]);

  const edges = useMemo(() => {
    if (state) {
      return state.deliverables.edges.map(({ node }) => {
        const isPending = Object.values(DELIVERABLE_STATES).splice(0, 4).includes(node.state);
        const { currencyCode: organizationCurrencyCode } = userContext.orgStaff.organization;
        const isForeignCurrency =
          node.currencyCode !== organizationCurrencyCode && node.amount !== null;
        return {
          node: {
            id: node.id,
            title: { name: node.title, color: isPending ? orange : red, id: node.id },
            fxValue: {
              amount: isForeignCurrency ? node.amount : null,
              currencyCode: node.currencyCode
            },
            initialValue: { amount: isForeignCurrency ? node.originalAmountInHomeCurrency : null },
            currentValue: { amount: node.amountInHomeCurrency },
            exchangeEffect: isForeignCurrency
              ? node.originalAmountInHomeCurrency - node.amountInHomeCurrency
              : null
          }
        };
      });
    }
  }, [state, userContext.orgStaff.organization, orange, red]);

  const renderTitleCell = ({ index, value }) => (
    <TableCellWithBadge
      key={index}
      color={value.color}
      name={value.name}
      link={resolveDeliverableLink(value.id)}
    />
  );

  const renderExchangeEffectCell = ({ index, value }) => (
    <StyledTableCell key={index} align="right">
      <AmountTrend value={value} />
    </StyledTableCell>
  );

  return state ? (
    <Fragment>
      <Box mt={-4} mb={4}>
        <Typography variant="h1">Budget Category: {categoryName}</Typography>
      </Box>
      <DetailsSection title="Summary">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Detail
              name="Contracted Amount"
              value={<Amount amount={state.contractedAmount} />}
              badgeColor={red}
              flexDirection="column"
              alignItems="flex-start"
            />
          </Grid>
          <Grid item xs={4}>
            <Detail
              name="Pending Amount"
              value={<Amount amount={state.pendingAmount} />}
              badgeColor={orange}
              flexDirection="column"
              alignItems="flex-start"
            />
          </Grid>
          <Grid item xs={4}>
            <Detail
              name="Category Exchange Rate Effect"
              value={
                <AmountTrend
                  value={round2(state.originalCommittedAmount) - round2(state.committedAmount)}
                />
              }
              flexDirection="column"
              alignItems="flex-start"
            />
          </Grid>
        </Grid>
      </DetailsSection>
      <DetailsSection title="Deliverables" noBorder>
        <AutoTable
          initialOrderBy="exchangeEffect"
          rowTemplate={[
            {
              name: 'title',
              label: 'Deliverable Title',
              sortable: true,
              renderCell: renderTitleCell
            },
            {
              name: 'fxValue',
              label: 'FX Value',
              type: CELL_TYPES.amount
            },
            { name: 'initialValue', label: 'Initial Value', type: CELL_TYPES.amount },
            {
              name: 'currentValue',
              label: 'Current Value',
              type: CELL_TYPES.amount,
              labelSubscript: <Date date={exchangeRateDate} />
            },
            {
              name: 'exchangeEffect',
              label: 'Exchange Rate Effect',
              sortable: true,
              align: 'right',
              renderCell: renderExchangeEffectCell,
              width: '170px'
            }
          ]}
          edges={edges}
          onChangeHandler={onChangeHandler}
          paginationProps={{
            hasNextPage: state.deliverables.pageInfo.hasNextPage,
            endCursor: state.deliverables.pageInfo.endCursor,
            totalCount: state.deliverables.totalCount,
            edgeCount: state.deliverables.edgeCount
          }}
        />
      </DetailsSection>
    </Fragment>
  ) : (
    <div>Loading...</div>
  );
};

BudgetQuickView.propTypes = {
  releaseId: PropTypes.string.isRequired,
  selectedId: PropTypes.any
};

export default withUserContext(BudgetQuickView);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const SignUpOrganizationUpdateEmailMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation SignUpOrganizationUpdateEmailMutation($input: SignUpOrganizationUpdateEmailInput!) {
        signUpOrganizationUpdateEmail(input: $input) {
          userEmail
          emailSent
          orgToken
          uidb64
          clientMutationId
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.signUpOrganizationUpdateEmail, errors);
    },
    onError: err => console.error(err)
  });
};

export default SignUpOrganizationUpdateEmailMutation;

SignUpOrganizationUpdateEmailMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      orgToken: PropTypes.string.isRequired,
      uidb64: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  )
};

import React from 'react';
import { Grid } from '@material-ui/core';
import FilterDebounceInput from '../../../../shared/components/filters/FilterDebounceInput';
const TagsListFilters = props => {
  const { filterValues, onFilterChange } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FilterDebounceInput
          id="filterByNameTagsList"
          name="name"
          value={filterValues['name']}
          label="Tag name"
          onChange={onFilterChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default TagsListFilters;

import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as WritingIcon } from '../../images/writing-asset-type-icon.svg';
import { ReactComponent as DocIcon } from '../../images/doc-asset-type-icon.svg';
import { ReactComponent as Model3DIcon } from '../../images/3d-asset-type-icon.svg';
import { ReactComponent as ArchivedIcon } from '../../images/archive-asset-type-icon.svg';
import AspectRatioContainer from '../assets/AspectRatioContainer';
import AspectRatioImageContainer from '../assets/AspectRatioImageContainer';
import { REVIEW_INTERFACES } from '../../constants';
import { ReactComponent as AudioIcon } from '../../images/audio-asset-type-icon.svg';

const ContentContainer = ({ children }) => (
  <AspectRatioContainer>
    <AspectRatioImageContainer>{children}</AspectRatioImageContainer>
  </AspectRatioContainer>
);

const ImageContent = ({ fileUrl }) => (
  <ContentContainer>
    <img src={fileUrl} alt="asset preview" />
  </ContentContainer>
);

const GenericContent = () => (
  <ContentContainer>
    <WritingIcon />
  </ContentContainer>
);

const PdfContent = () => (
  <ContentContainer>
    <DocIcon />
  </ContentContainer>
);

const Model3DContent = () => (
  <ContentContainer>
    <Model3DIcon />
  </ContentContainer>
);

const ArchivedFileContent = () => (
  <ContentContainer>
    <ArchivedIcon />
  </ContentContainer>
);

const ReviewAssetCardContent = ({ fileUrl, reviewInterface, isStandardStorage }) => {
  const renderContent = () => {
    if (!isStandardStorage) {
      return <ArchivedFileContent />;
    }
    switch (reviewInterface) {
      case REVIEW_INTERFACES.image:
      case REVIEW_INTERFACES.video:
        return <ImageContent fileUrl={fileUrl} />;
      case REVIEW_INTERFACES.generic:
        return <GenericContent />;
      case REVIEW_INTERFACES.a3dModel:
        return <Model3DContent />;
      case REVIEW_INTERFACES.document:
        return <PdfContent />;
      case REVIEW_INTERFACES.audio:
        return <AudioIcon />;
      default:
        break;
    }
  };

  return renderContent();
};

ReviewAssetCardContent.propTypes = {
  // required for image, video thumbnails
  fileUrl: PropTypes.string,
  reviewInterface: PropTypes.string
};

export default ReviewAssetCardContent;

import React, { useState } from 'react';

import graphql from 'babel-plugin-relay/macro';
import clsx from 'clsx';
import { createFragmentContainer } from 'react-relay';
import { Formik, Form } from 'formik';

import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import StatusIndicator from '../../../../shared/components/UI/StatusIndicator';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import ButtonWithTooltip from '../../../../shared/components/UI/ButtonWithTooltip';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import { ReactComponent as SolicitationIcon } from '../../../../shared/images/solicitations.svg';
import { SOLICITATION_STATES, ALLOWED_ACTIONS } from '../../../../shared/constants';
import SolicitationDetailsTab from './SolicitationDetailsTab';
import SolicitationContractorsTab from './SolicitationContractorsTab';
import SolicitationDeliverablesTab from './SolicitationDeliverablesTab';
import SolicitationReferenceTab from './SolicitationReferenceTab';
import MarkClosedSolicitationMutation from '../mutations/MarkClosedSolicitationMutation';
import SendSolicitationMutation from '../mutations/SendSolicitationMutation';
import RevertClosedSolicitationMutation from '../mutations/RevertClosedSolicitationMutation';
import UpdateSolicitationMutation from '../mutations/UpdateSolicitationMutation';
import { formatDateForQueries } from '../../../../shared/utils/formatters';
import ReassignSolicitationCoordinatorMutation from '../mutations/ReassignSolicitationCoordinatorMutation';
import { computePermissionRole } from '../../../../shared/utils/helpers';
import ConfirmationDialog from '../../../../shared/components/common/ConfirmationDialog';

const SolicitationDetails = props => {
  const {
    stateTypes,
    refetchCounter,
    handleContextActionOptions,
    setContextActions,
    toRefetchData,
    solicitation: {
      id,
      subject,
      state,
      numberOfDeliverables,
      numberOfContractors,
      message,
      replyByDate,
      staffCoordinator,
      numberOfAllocatedDeliverables,
      numberOfAllocatedContractedDeliverables
    },
    history,
    userContext: {
      id: currentUserId,
      orgStaff: { allowedActions }
    }
  } = props;

  const classes = useDetailsStyles();
  const [disableButtons, setDisableButtons] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [refetchCounterForContractors, setRefetchCounterForContractors] = useState(0);
  const [refetchCounterForDeliverables, setRefetchCounterForDeliverables] = useState(0);

  const onMarkAsClosed = () => {
    setDisableButtons(true);
    MarkClosedSolicitationMutation(id, response => {
      setDisableButtons(false);
      if (response && response.isUpdated) {
        setRefetchCounterForDeliverables(counter => counter + 1);
      }
    });
  };

  const onSend = () => {
    setDisableButtons(true);
    SendSolicitationMutation(id, () => {
      setDisableButtons(false);
    });
  };

  const onRevertClosed = () => {
    setDisableButtons(true);
    RevertClosedSolicitationMutation(id, () => {
      setDisableButtons(false);
    });
  };

  const onUpdate = values => {
    setDisableButtons(true);
    const variables = {
      id,
      subject: values.subject,
      ...(values.message && { message: values.message }),
      ...(values.replyByDate && { replyByDate: formatDateForQueries(values.replyByDate) })
    };
    if (canEditDeliverablesAndContractors() && canReassignSolicitation()) {
      UpdateSolicitationMutation(variables, () => {
        ReassignSolicitationCoordinatorMutation(id, values.assignedStaff.value, () => {
          doAfterMutation();
        });
      });
    } else if (canReassignSolicitation()) {
      ReassignSolicitationCoordinatorMutation(id, values.assignedStaff.value, () => {
        doAfterMutation();
      });
    } else if (canEditDeliverablesAndContractors()) {
      UpdateSolicitationMutation(variables, () => {
        doAfterMutation();
      });
    }
  };

  const doAfterMutation = () => {
    setDisableButtons(false);
    setEditing(false);
  };

  const validate = values => {
    let errors = {};
    if (!values.assignedStaff || !values.assignedStaff.value) {
      return (errors.assignedStaff = 'Required');
    }
    return errors;
  };

  const renderSendButton = () => {
    if (numberOfContractors && numberOfDeliverables && replyByDate) {
      return (
        <GlobalButton id="sendButton" handleClick={onSend} disabled={disableButtons}>
          Send
        </GlobalButton>
      );
    } else {
      return (
        <ButtonWithTooltip
          id="sendButton"
          disabled={true}
          tooltipText={
            'You must have at least one contractor, one deliverable and reply by date set before you send it.'
          }
          buttonText="Send"
          variant="primary"
        />
      );
    }
  };

  const canEditDetails = () => {
    return (
      state !== SOLICITATION_STATES.closed &&
      ((computePermissionRole(
        [
          ALLOWED_ACTIONS.SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER,
          ALLOWED_ACTIONS.SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER
        ],
        allowedActions
      ) &&
        currentUserId === staffCoordinator.user.id) ||
        computePermissionRole(
          ALLOWED_ACTIONS.SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        ))
    );
  };

  const canEditDeliverablesAndContractors = () => {
    return (
      state === SOLICITATION_STATES.draft &&
      ((computePermissionRole(
        [
          ALLOWED_ACTIONS.SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER,
          ALLOWED_ACTIONS.SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER
        ],
        allowedActions
      ) &&
        currentUserId === staffCoordinator.user.id) ||
        computePermissionRole(
          ALLOWED_ACTIONS.SOLICITATION_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        ))
    );
  };

  const canSendSolicitation = () => {
    return (
      state === SOLICITATION_STATES.draft &&
      ((computePermissionRole(
        [
          ALLOWED_ACTIONS.SOLICITATION_SEND_ASSIGNED_TO_ANOTHER_USER,
          ALLOWED_ACTIONS.SOLICITATION_SEND_ASSIGNED_TO_AUTHENTICATED_USER
        ],
        allowedActions
      ) &&
        currentUserId === staffCoordinator.user.id) ||
        computePermissionRole(
          ALLOWED_ACTIONS.SOLICITATION_SEND_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        ))
    );
  };

  const canMarkClosedSolicitation = () => {
    return (
      state === SOLICITATION_STATES.active &&
      ((computePermissionRole(
        [
          ALLOWED_ACTIONS.SOLICITATION_MARK_CLOSED_ASSIGNED_TO_ANOTHER_USER,
          ALLOWED_ACTIONS.SOLICITATION_MARK_CLOSED_ASSIGNED_TO_AUTHENTICATED_USER
        ],
        allowedActions
      ) &&
        currentUserId === staffCoordinator.user.id) ||
        computePermissionRole(
          ALLOWED_ACTIONS.SOLICITATION_MARK_CLOSED_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        ))
    );
  };

  const canReassignSolicitation = () => {
    return computePermissionRole(
      ALLOWED_ACTIONS.SOLICITATION_REASSIGN_TO_ANOTHER_STAFF_MEMBER,
      allowedActions
    );
  };

  const canRevertClosedSolicitation = () => {
    return (
      state === SOLICITATION_STATES.closed &&
      ((computePermissionRole(
        [
          ALLOWED_ACTIONS.SOLICITATION_REVERT_SOLICITATION_TO_ACTIVE_ASSIGNED_TO_ANOTHER_USER,
          ALLOWED_ACTIONS.SOLICITATION_REVERT_SOLICITATION_TO_ACTIVE_ASSIGNED_TO_AUTHENTICATED_USER
        ],
        allowedActions
      ) &&
        currentUserId === staffCoordinator.user.id) ||
        computePermissionRole(
          ALLOWED_ACTIONS.SOLICITATION_REVERT_SOLICITATION_TO_ACTIVE_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        ))
    );
  };

  const canAddComment = () => {
    return (
      state === SOLICITATION_STATES.active &&
      ((computePermissionRole(
        [
          ALLOWED_ACTIONS.SOLICITATION_REPLY_TO_SOLICITATION_MESSAGE_ASSIGNED_TO_ANOTHER_USER,
          ALLOWED_ACTIONS.SOLICITATION_REPLY_TO_SOLICITATION_MESSAGE_ASSIGNED_TO_AUTHENTICATED_USER
        ],
        allowedActions
      ) &&
        currentUserId === staffCoordinator.user.id) ||
        computePermissionRole(
          ALLOWED_ACTIONS.SOLICITATION_REPLY_TO_SOLICITATION_MESSAGE_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        ))
    );
  };

  const initialValues = {
    message: message || '',
    subject: subject || '',
    replyByDate: replyByDate || null,
    assignedStaff: { value: staffCoordinator.id, label: staffCoordinator.fullName }
  };
  const initialContractor = history.location.state && history.location.state.contractorId;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onUpdate}
      enableReinitialize
      validate={validate}
    >
      {() => (
        <Form>
          <div className={classes.containerSpaceBetween}>
            <div className={clsx(classes.containerColumn, classes.alignFlexStart)}>
              <CategoryTitleHeader title={subject} icon={SolicitationIcon} link="/solicitations" />
              <StatusIndicator
                statusTypes={stateTypes.enumValues}
                statusCode={state}
                variant="solicitation"
              />
            </div>
            <div className={clsx(classes.container, classes.alignFlexStart)}>
              {canSendSolicitation() ? renderSendButton() : null}
              {canMarkClosedSolicitation() &&
                (numberOfAllocatedDeliverables !== numberOfAllocatedContractedDeliverables ? (
                  <ConfirmationDialog
                    content={`This JobOpp has at least one allocated Deliverable that is not contracted yet. Closing the JobOpp will deallocate any non-contracted Deliverables. Do you want to proceed?`}
                  >
                    {confirm => (
                      <GlobalButton
                        id="markAsClosedButton"
                        handleClick={confirm(onMarkAsClosed)}
                        disabled={disableButtons}
                      >
                        Mark as Closed
                      </GlobalButton>
                    )}
                  </ConfirmationDialog>
                ) : (
                  <GlobalButton
                    id="markAsClosedButton"
                    handleClick={onMarkAsClosed}
                    disabled={disableButtons}
                  >
                    Mark as Closed
                  </GlobalButton>
                ))}
              {canRevertClosedSolicitation() && (
                <GlobalButton
                  id="revertClosedButton"
                  handleClick={onRevertClosed}
                  disabled={disableButtons}
                >
                  Revert to Sent
                </GlobalButton>
              )}
            </div>
          </div>
          <AutoTabList
            initialTabIndex={initialContractor || state === SOLICITATION_STATES.active ? 2 : 0}
          >
            <SolicitationDetailsTab
              label="Details"
              isEditing={isEditing}
              setEditing={setEditing}
              canEditSolicitation={canEditDetails}
              canReassignSolicitation={canReassignSolicitation}
            />
            <SolicitationDeliverablesTab
              label="Deliverables"
              handleContextActionOptions={handleContextActionOptions}
              setContextActions={setContextActions}
              refetchCounter={refetchCounter}
              refetchCounterForDeliverables={refetchCounterForDeliverables}
              canEditSolicitation={canEditDeliverablesAndContractors}
              disabled={isEditing}
            />
            <SolicitationContractorsTab
              label="Contractors"
              disabled={isEditing}
              initialContractor={initialContractor}
              canEditSolicitation={canEditDeliverablesAndContractors}
              canAddComment={canAddComment}
              toRefetchData={toRefetchData}
              refetchCounterForContractors={refetchCounterForContractors}
              setRefetchCounterForContractors={setRefetchCounterForContractors}
            />
            <SolicitationReferenceTab label="Reference" disabled={isEditing} />
          </AutoTabList>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(
  createFragmentContainer(SolicitationDetails, {
    solicitation: graphql`
      fragment SolicitationDetails_solicitation on SolicitationNode {
        id
        subject
        state
        numberOfDeliverables
        numberOfContractors
        replyByDate
        message
        numberOfAllocatedDeliverables
        numberOfAllocatedContractedDeliverables
        createdBy {
          user {
            id
          }
        }
        staffCoordinator {
          id
          fullName
          user {
            id
          }
        }
      }
    `,
    stateTypes: graphql`
      fragment SolicitationDetails_stateTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  })
);

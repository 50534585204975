import React from 'react';
import PropTypes from 'prop-types';
import withUserContext from '../../../../contexts/userContext/withUserContext';
import { RichTextFormattedValue } from '../../../richText';
import CommentRepliedTo from '../../../comment/CommentRepliedTo';
import { CommentEditedLabel } from '../CommentCard';
import CommentForm from './CommentForm';
import Comment from './Comment';
import * as STATE from './states';

const DetailedComment = props => {
  const {
    deliverableId,
    comment,
    state,
    onStateChange,
    onAddNew,
    onReply,
    onEdit,
    onViewMarkup,
    onDelete,
    commentFormProps,
    userContext: { id: currentUserId }
  } = props;

  if (state === STATE.ADD_NEW) {
    return <CommentForm {...commentFormProps} deliverableId={deliverableId} onSubmit={onAddNew} />;
  } else if (state === STATE.EDIT) {
    return (
      <CommentForm
        deliverableId={deliverableId}
        comment={comment}
        onCancel={() => onStateChange(null)}
        onSubmit={onEdit}
        actionLabels={{
          submit: 'Save'
        }}
      />
    );
  } else if (state === STATE.REPLY) {
    return (
      <>
        <CommentForm
          deliverableId={deliverableId}
          onCancel={() => onStateChange(null)}
          onSubmit={(values, actions) => {
            onReply(
              {
                ...values,
                repliedTo: comment
              },
              actions
            );
          }}
        />
        <CommentRepliedTo
          user={{
            fullName: comment.fullName,
            avatarSrc: comment.avatarSrc
          }}
          content={<RichTextFormattedValue value={comment.content} />}
          created={comment.dateCreated}
        />
      </>
    );
  } else if (state === STATE.DEFAULT && comment) {
    return (
      <>
        <Comment
          authorName={comment.fullName}
          authorAvatar={comment.avatarSrc}
          date={comment.dateCreated}
          onViewMarkup={onViewMarkup ? () => onViewMarkup(comment) : null}
          onReply={() => onStateChange(STATE.REPLY)}
          onEdit={comment.userId === currentUserId ? () => onStateChange(STATE.EDIT) : null}
          onDelete={comment.userId === currentUserId ? () => onDelete(comment.commentId) : null}
          withAttachments={comment.referenceFiles.length > 0}
        >
          <RichTextFormattedValue value={comment.content} />
          {comment.wasCommentEdited && <CommentEditedLabel />}
        </Comment>
        {comment.repliedTo && (
          <CommentRepliedTo
            {...comment.repliedTo}
            content={<RichTextFormattedValue value={comment.repliedTo.content} />}
          />
        )}
      </>
    );
  } else {
    return false;
  }
};

DetailedComment.propTypes = {
  deliverableId: PropTypes.string.isRequired,
  comment: PropTypes.object,
  state: PropTypes.string.isRequired,
  onStateChange: PropTypes.func.isRequired,
  onAddNew: PropTypes.func,
  onReply: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onViewMarkup: PropTypes.func,
  onDelete: PropTypes.func
};

export default withUserContext(DetailedComment);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

export default (id, budgetInfo, onCompleted, onError) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateReleaseBudgetMutation($input: UpdateReleaseBudgetInput!) {
        updateReleaseBudget(input: $input) {
          updatedRelease {
            id
            budgetInfo {
              category {
                id
                name
              }
              budgetAmount
            }
            categoryInfo {
              category {
                id
                name
              }
              pendingAmount
              contractedAmount
              originalPendingAmount
              originalContractedAmount
              hasFxDeliverables
            }
            exchangeRateDate
            deliverablesTotalAmount
            deliverablesTotalOriginalAmount
            totalBudget
            deliverablesTotalContractedAmount
            deliverablesTotalPendingAmount
          }
        }
      }
    `,
    variables: { input: { id, budgetInfo } },
    onCompleted,
    onError
  });
};

import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import RolesDetails from './RolesDetails';
import QueryRenderer from '../../../../../../shared/relay/QueryRenderer';

const RolesDetailsPage = props => (
  <QueryRenderer
    query={graphql`
      query RolesDetailsPageQuery($id: ID!) {
        organizationRole(id: $id) {
          ...RolesDetails_role
        }
        __type(name: "OrganizationPermission") {
          ...RolesDetails_allPermissions
        }
      }
    `}
    variables={{
      id: props.match.params.id
    }}
    render={relayProps => (
      <RolesDetails
        role={relayProps.organizationRole}
        allPermissions={relayProps.__type}
        {...props}
      />
    )}
  />
);

export default RolesDetailsPage;

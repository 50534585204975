import { styled } from '@material-ui/styles';
import { Box } from '@material-ui/core';

export const FullScreenContainer = styled(Box)(props => ({
  height: '100vh',
  width: '100vw',
  paddingTop: '60px',
  backgroundColor: props.background === 'grey' ? '#3F3F3F' : '#F7F8F9',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Field } from 'formik';

import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import FormikDropdownField from '../../../../../shared/components/form/FormikDropdownField';
import FormikDateField from '../../../../../shared/components/form/FormikDateField';
import AvatarWithName from '../../../../../shared/components/UI/AvatarWithName';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import DetailsRowAmount from '../../../../../shared/components/UI/DetailsRowAmount';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import FormikSearchableDropdown from '../../../../../shared/components/form/FormikSearchableDropdown';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';
import { DELIVERABLE_STATES } from '../../../../../shared/constants';
import { FormikAmountWithCurrencyField } from '../../../form/AmountWithCurrencyField';
import { FormikCategorySelectWithTypes } from '../../../shared/dropdowns/CategorySelectWithTypes';
import { resolveContractorLink } from '../../../../../shared/utils/linkResolvers';

const DetailsMainSection = props => {
  const classes = useDetailsStyles();

  const {
    deliverable: {
      id,
      state,
      category,
      categoryType,
      amount,
      currencyCode,
      assignedStaff,
      assignedContractor,
      assignedInternally,
      dueDate,
      internalId,
      amountInHomeCurrency
    },
    isEditing,
    values,
    setFieldValue
  } = props;

  const assignedTo = assignedContractor || assignedInternally;

  return isEditing ? (
    <DetailsSection noBorder title="Details">
      <div key={id}>
        <div className={classes.formFieldNoMargin}>
          <Field
            name="assignedStaff"
            label="Coordinator"
            placeholder="Select Staff..."
            component={FormikSearchableDropdown}
            visibleToFreelancer
          />
        </div>
        <div className={classes.formField}>
          <Field
            name="assignedContractor"
            label="Assignee"
            placeholder="Unassigned"
            disabled
            component={FormikTextField}
          />
        </div>
        <div className={classes.formField}>
          <Field
            required
            name="category"
            visibleToFreelancer
            component={FormikCategorySelectWithTypes}
            label="Category"
            noEmptyValue
            disabled={state !== DELIVERABLE_STATES.draft && state !== DELIVERABLE_STATES.ready}
            onChange={(name, value) => {
              setFieldValue('categoryType', '');
              setFieldValue(name, value);
            }}
          />
        </div>
        <div className={classes.formField}>
          <Field
            name="categoryType"
            label="Category Type"
            items={
              values.category ? values.category.types.edges.map(edge => ({ ...edge.node })) : null
            }
            itemType="categories"
            visibleToFreelancer
            component={FormikDropdownField}
          />
        </div>
        <div className={classes.formField}>
          <Field
            name="amount"
            label="Amount"
            component={FormikAmountWithCurrencyField}
            placeholder="Amount"
            disabled={state !== DELIVERABLE_STATES.draft && state !== DELIVERABLE_STATES.ready}
            fullWidth
          />
        </div>
        <div className={classes.formField}>
          <Field name="dueDate" component={FormikDateField} label="Due date" fullWidth />
        </div>
        <div className={classes.formField}>
          <Field name="internalId" component={FormikTextField} label="Internal ID" fullWidth />
        </div>
      </div>
    </DetailsSection>
  ) : (
    <DetailsSection noBorder title="Details">
      <div key={id}>
        <DetailsRow
          noMarginTop
          title="Coordinator"
          content={
            assignedStaff ? (
              <AvatarWithName
                defaultIcon="person"
                name={assignedStaff.fullName}
                avatarProps={{
                  src: assignedStaff.representativeImageUrl
                }}
              />
            ) : (
              '-'
            )
          }
        />
        <DetailsRow
          link={assignedContractor ? resolveContractorLink(assignedContractor.id) : ''}
          title="Assignee"
          content={
            assignedTo ? (
              <AvatarWithName
                defaultIcon="person"
                name={assignedTo.fullName}
                avatarProps={{
                  src: assignedTo.representativeImageUrl,
                  border: assignedContractor ? 'freelancer' : 'organization'
                }}
              />
            ) : (
              '-'
            )
          }
        />
        <DetailsRow title="Category" content={category.name} />
        <DetailsRow
          title="Type"
          content={categoryType && categoryType.name ? categoryType.name : '-'}
        />
        <DetailsRowAmount
          amount={amount}
          currencyCode={currencyCode}
          subscriptAmount={amountInHomeCurrency}
        />
        <DetailsRowDate title="Due date" date={dueDate} />
        <DetailsRow title="Internal ID" content={internalId} />
      </div>
    </DetailsSection>
  );
};

export default withUserContext(
  createFragmentContainer(DetailsMainSection, {
    deliverable: graphql`
      fragment DetailsMainSection_deliverable on DeliverableNode {
        id
        dueDate
        internalId
        state
        category {
          id
          name
        }
        categoryType {
          id
          name
        }
        amount
        currencyCode
        amountInHomeCurrency
        assignedStaff {
          fullName
          representativeImageUrl
        }
        assignedInternally {
          fullName
          representativeImageUrl
        }
        assignedContractor {
          id
          fullName
          representativeImageUrl
        }
      }
    `
  })
);

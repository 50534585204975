import React, { useState, useEffect, useRef } from 'react';
import { withTheme } from '@material-ui/styles';
import { PdfLoader, PdfHighlighter, Highlight } from '@awebase/react-pdf-highlighter';

import UpdateDeliverableReviewAnnotationMutation from '../../../mutations/review/UpdateDeliverableReviewAnnotationMutation';
import SquareButton from '../../UI/SquareButton';
import {
  AnnotationActionsContainer,
  AnnotationToolbarContainer
} from '../review_interfaces/AnnotationInterfaceContainers';
import {
  PdfContainer,
  PdfSelectionTip,
  Spinner,
  StyledAreaHighlight,
  ReviewToolbar
} from './styled';
import AddActionsGroup from '../review_interfaces/AddActionsGroup';

const generateId = () => 'document-highlight-' + new Date().getTime().toString();
let scrollToAnnotation = null;

const PdfAnnotation = props => {
  const {
    assetId,
    src,
    highlightedAnnotationRef,
    annotationData,
    onCloseClick,
    handleHighlightAnnotation,
    theme,
    canAddMarkup,
    sidebarAction,
    setSidebarAction
  } = props;
  const [isReviewActive, _setIsReviewActive] = useState(false);
  const highligherRef = useRef();
  const [isInAreaSelectMode, setIsInAreaSelecteMode] = useState(false);

  useEffect(() => {
    const { annotationData, highlightedAnnotationRef } = props;

    if (highlightedAnnotationRef && scrollToAnnotation) {
      const annotation = annotationData.find(
        ({ annotationRef }) => annotationRef === highlightedAnnotationRef
      );
      scrollToAnnotation(annotation);
    } else if (!highlightedAnnotationRef && highligherRef.current) {
      highligherRef.current.onScroll();
    }
  }, [highlightedAnnotationRef]);

  useEffect(() => {
    if (!sidebarAction && isReviewActive) {
      setIsReviewActive(false);
    }
  }, [sidebarAction]);

  const setIsReviewActive = val => {
    _setIsReviewActive(val);
    setSidebarAction(null);
    setIsInAreaSelecteMode(false);
    if (!val) {
      highligherRef.current.hideTipAndSelection();
    }
  };

  const showCommentForm = submitHandler => {
    setSidebarAction(val => ({ ...val, type: 'ADD_NEW', submitHandler }));
  };

  return (
    <>
      <AnnotationActionsContainer>
        <SquareButton onClick={onCloseClick} icon="close" />
      </AnnotationActionsContainer>

      <PdfLoader url={src} beforeLoad={<Spinner />}>
        {pdfDocument => (
          <PdfContainer isInAreaSelectMode={isInAreaSelectMode}>
            <PdfHighlighter
              enableAreaSelection={() => isInAreaSelectMode}
              pdfDocument={pdfDocument}
              onScrollChange={() => {
                handleHighlightAnnotation(null);
              }}
              ref={highligherRef}
              scrollRef={scrollTo => {
                scrollToAnnotation = scrollTo;
              }}
              onSelectionFinished={(
                position,
                pdfContent,
                hideTipAndSelection,
                transformSelection
              ) => {
                if (!isReviewActive) {
                  setIsReviewActive(true);
                }

                return (
                  <PdfSelectionTip
                    theme={theme}
                    label="Add Comment"
                    className={!canAddMarkup ? 'disabled' : ''}
                    onClick={() => {
                      if (!canAddMarkup) return;
                      transformSelection();
                      showCommentForm(({ content, rawContent, referenceIds }) => {
                        const annotationRef = generateId();

                        UpdateDeliverableReviewAnnotationMutation(
                          {
                            reviewAsset: assetId,
                            annotationRef,
                            annotationData: JSON.stringify([
                              ...annotationData,
                              {
                                // NOTE:
                                // - annotationRef is needed for comments thread to work correctly
                                // - id is needed for isScrolledTo to work correcly (react-pdf-highligher library)
                                // - content.image is base64 screenshot of area highlight
                                //   we don't want to store that in the db, but this key is required for propper render
                                annotationRef,
                                id: annotationRef,
                                position,
                                content: {
                                  pdfContent,
                                  image: pdfContent.image ? '-' : null
                                }
                              }
                            ]),
                            content,
                            rawContent,
                            referenceIds
                          },
                          response => {
                            setSidebarAction(null);
                            hideTipAndSelection();
                          }
                        );
                      });
                    }}
                  />
                );
              }}
              highlightTransform={(
                highlight,
                index,
                _setTip,
                _hideTip,
                _viewportToScaled,
                _screenshot,
                isScrolledTo
              ) => {
                const isTextHighlight = !Boolean(highlight.content && highlight.content.image);
                const highlightComponent = isTextHighlight ? (
                  <Highlight isScrolledTo={isScrolledTo} position={highlight.position} />
                ) : (
                  <StyledAreaHighlight
                    theme={theme}
                    highlight={highlight}
                    isScrolledTo={isScrolledTo}
                    disableDragging
                    enableResizing={{
                      bottom: false,
                      bottomLeft: false,
                      bottomRight: false,
                      left: false,
                      right: false,
                      top: false,
                      topLeft: false,
                      topRight: false
                    }}
                  />
                );

                return (
                  <div
                    key={index}
                    onClick={() => handleHighlightAnnotation(highlight.annotationRef)}
                    children={highlightComponent}
                  />
                );
              }}
              highlights={annotationData}
            />
          </PdfContainer>
        )}
      </PdfLoader>

      <AnnotationToolbarContainer>
        {isReviewActive ? (
          <ReviewToolbar
            disabled={!canAddMarkup}
            onCancel={() => setIsReviewActive(false)}
            isAreaSelect={isInAreaSelectMode}
            onAreaSelectToggle={value => setIsInAreaSelecteMode(value)}
          />
        ) : (
          <AddActionsGroup
            disabled={!canAddMarkup}
            onAddAnnotation={() => setIsReviewActive(true)}
            onAddComment={() => showCommentForm()}
          />
        )}
      </AnnotationToolbarContainer>
    </>
  );
};

export default withTheme(PdfAnnotation);

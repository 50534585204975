import { useState } from 'react';

const useDialog = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const toggleDialogOpen = () => {
    setDialogOpen(!isDialogOpen);
  };

  return [isDialogOpen, toggleDialogOpen];
};

export default useDialog;

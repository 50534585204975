import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const ToggleInvoicePaymentReceivedMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation ToggleInvoicePaymentReceivedMutation($input: ToggleInvoicePaymentReceivedInput!) {
          toggleInvoicePaymentReceived(input: $input) {
            invoice {
              id
              status
              job {
                id
                totalValue
                totalInvoicesAmount
                totalAmountOfPaymentReceived
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default ToggleInvoicePaymentReceivedMutation;

/**
 * @flow
 * @relayHash 4354019d22e396e274f5541767cc1f0d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateContractorGroupInput = {|
  groupId: string,
  name: string,
  contractors: $ReadOnlyArray<?string>,
  description?: ?string,
  isPublic?: ?boolean,
  clientMutationId?: ?string,
|};
export type UpdateContractorGroupMutationVariables = {|
  input: UpdateContractorGroupInput
|};
export type UpdateContractorGroupMutationResponse = {|
  +updateContractorGroup: ?{|
    +group: ?{|
      +id: string,
      +isPublic: boolean,
      +name: string,
      +numberOfMembers: ?number,
      +contractors: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +fullName: ?string,
          |}
        |}>
      |},
    |}
  |}
|};
export type UpdateContractorGroupMutation = {|
  variables: UpdateContractorGroupMutationVariables,
  response: UpdateContractorGroupMutationResponse,
|};
*/


/*
mutation UpdateContractorGroupMutation(
  $input: UpdateContractorGroupInput!
) {
  updateContractorGroup(input: $input) {
    group {
      id
      isPublic
      name
      numberOfMembers
      contractors {
        edges {
          node {
            id
            fullName
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateContractorGroupInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateContractorGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateContractorGroupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "group",
        "storageKey": null,
        "args": null,
        "concreteType": "ContractorGroupNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isPublic",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfMembers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractors",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateContractorGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateContractorGroupMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateContractorGroupMutation",
    "id": null,
    "text": "mutation UpdateContractorGroupMutation(\n  $input: UpdateContractorGroupInput!\n) {\n  updateContractorGroup(input: $input) {\n    group {\n      id\n      isPublic\n      name\n      numberOfMembers\n      contractors {\n        edges {\n          node {\n            id\n            fullName\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '55f895cec6a7cc389910f5835c402571';
module.exports = node;

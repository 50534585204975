import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Typography } from '@material-ui/core';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Formik, Form, Field } from 'formik';

import { useDetailsStyles } from '../../../../../../shared/styles/common/useDetailsStyles';
import FormikTextField from '../../../../../../shared/components/form/FormikTextField';
import SaveButton from '../../../../../../shared/components/UI/SaveButton';
import DetailsSection from '../../../../../../shared/components/common/DetailsSection';
import EditButton from '../../../../../../shared/components/UI/EditButton';
import CancelButton from '../../../../../../shared/components/UI/CancelButton';
import CategoryTitleHeader from '../../../../../../shared/components/UI/CategoryTitleHeader';
import withUserContext from '../../../../../../shared/contexts/userContext/withUserContext';
import { ReactComponent as StaffManagementIcon } from '../../../../../../shared/images/staff.svg';
import { FormikCheckbox } from '../../../../../../shared/components/form/FormikCheckbox';
import UpdateRoleMutation from '../../mutations/UpdateRoleMutation';
import getUserData from '../../../../../mutations/getUserData';

const RolesDetails = props => {
  const { allPermissions, role, userContext } = props;
  const [isEditing, setEditing] = useState(false);
  const classes = useDetailsStyles({ isEditing });

  const toggleEditHandler = resetForm => {
    // when user clicks Cancel, we should reset form to intial values, regardless of any changes user has made
    if (typeof resetForm === 'function') {
      resetForm();
    }
    setEditing(!isEditing);
  };

  const submitHandler = (values, { setFieldError }) => {
    const { name, ...rolePermissions } = values;
    // we need to send only permissions that are not part of default ones
    const selectedExtraPermissions = Object.keys(rolePermissions).filter(
      permission => !role.defaultPermissions.includes(permission) && rolePermissions[permission]
    );

    const variables = { id: role.id, name, extraPermissions: selectedExtraPermissions };
    UpdateRoleMutation(variables, (response, errors) => {
      if (errors && errors[0].fields && errors[0].fields.name) {
        setFieldError('name', errors[0].fields.name);
      }
      if (response) {
        toggleEditHandler();
        if (userContext.orgStaff.role.id === role.id) {
          // if user updates permissions to his own role, we want to refetch userContext because some actions might
          // become available or forbidden
          getUserData(userContext.setUserInfo);
        }
      }
    });
  };

  const mapInitialValues = () => {
    return allPermissions.enumValues.reduce((obj, item) => {
      if (
        role.defaultPermissions.includes(item.name) ||
        role.extraPermissions.includes(item.name)
      ) {
        obj[item.name] = true;
      } else {
        obj[item.name] = false;
      }
      return obj;
    }, {});
  };
  const initialValues = {
    name: role.name,
    ...mapInitialValues()
  };

  const roleValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required').min(3).max(30)
  });

  const renderPermissions = () => {
    // pretty convoluted helper function to create nicer UI from permissions list - separate permissions in sections with labels
    const permissionSections = [
      { section: 'CREATE', label: 'Global Create' },
      { section: 'PRODUCT', label: 'Products' },
      { section: 'RELEASE', label: 'Releases' },
      { section: 'DELIVERABLE', label: 'Deliverables' },
      { section: 'SOLICITATION', label: 'JobOpps' },
      { section: 'JOB', label: 'Jobs' },
      { section: 'CONTRACTOR', label: 'Contractors' },
      { section: 'ADMIN', label: 'Settings/Organization Admin' },
      { section: 'NOT_FOUND', label: 'Other' }
    ];
    const initialPermissionSectionMap = permissionSections.map(section => {
      return {
        ...section,
        permissions: []
      };
    });
    const mappedPermissionSections = allPermissions.enumValues.reduce((acc, permission) => {
      if (permission.name.includes('CREATE')) {
        acc[0].permissions.push(permission);
      } else if (permission.name === 'JOB_ASSIGN_RATING_TO_CLOSED') {
        // skipped for now, we are going to add ratings back in the future
      } else {
        const correctSection = acc.findIndex(item => permission.name.includes(item.section));
        if (correctSection !== -1) {
          acc[correctSection].permissions.push(permission);
        } else {
          // goes to Not found section
          acc[acc.length - 1].permissions.push(permission);
        }
      }
      return acc;
    }, initialPermissionSectionMap);

    return mappedPermissionSections.map(section => {
      if (!section.permissions.length) {
        return null;
      }
      return (
        <div key={section.section}>
          <div className={classes.marginTop}>
            <Typography variant="overline">{section.label}</Typography>
          </div>
          {section.permissions.map(permission => (
            <div className={classes.permissionLabel} key={permission.name}>
              <Field
                component={FormikCheckbox}
                name={permission.name}
                label={permission.description}
                disabled={!isEditing || role.defaultPermissions.includes(permission.name)}
              />
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitHandler}
      validationSchema={roleValidationSchema}
    >
      {({ submitForm, resetForm }) => (
        <Form>
          <div className={classes.topSectionHeightSmall}>
            <div className={classes.containerSpaceBetween}>
              <div className={clsx(classes.containerColumn, classes.alignFlexStart)}>
                <CategoryTitleHeader
                  title={role.name}
                  icon={StaffManagementIcon}
                  link="/admin/staff-management/roles"
                />
              </div>
              {isEditing && (
                <div className={classes.containerAlignFlexStart}>
                  <CancelButton id="cancelRole" onClick={() => toggleEditHandler(resetForm)} />
                  <SaveButton id="saveRole" />
                </div>
              )}
              {!isEditing && !role.isDefault && (
                <div className={classes.containerAlignFlexStart}>
                  <EditButton id="editRole" onClick={toggleEditHandler} />
                </div>
              )}
            </div>
          </div>

          <div className={classes.detailsContentNoTabs}>
            <Fragment>
              <DetailsSection noBorder title="">
                <div className={classes.container}>
                  <div className={classes.containerColumn}>
                    <div className={classes.marginBottom}>
                      {isEditing ? (
                        <Field
                          name="name"
                          label="Role name"
                          placeholder="Role name"
                          component={FormikTextField}
                          fullWidth
                        />
                      ) : (
                        <Typography variant="h3">{role.name}</Typography>
                      )}
                    </div>
                    {isEditing ? (
                      <Typography variant="overline">
                        {`Customize a role by adding/removing permissions. You cannot remove
                        permissions that were set from the original - ${role.basedOnRole.name} role.`}
                      </Typography>
                    ) : null}
                    <div>{renderPermissions()}</div>
                  </div>
                </div>
              </DetailsSection>
              <Typography>
                *Note that while "Title" and "Release" are the default terms in AWEbase, your
                organization may have customized one or both of these terms to better reflect your
                business.
              </Typography>
            </Fragment>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(
  createFragmentContainer(RolesDetails, {
    role: graphql`
      fragment RolesDetails_role on OrganizationRoleNode {
        id
        name
        isDefault
        defaultPermissions
        extraPermissions
        basedOnRole {
          name
        }
      }
    `,
    allPermissions: graphql`
      fragment RolesDetails_allPermissions on __Type {
        enumValues {
          name
          description
        }
      }
    `
  })
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation MarkClosedSolicitationMutation($input: MarkClosedSolicitationInput!) {
    markClosedSolicitation(input: $input) {
      isUpdated
      solicitation {
        id
        state
      }
    }
  }
`;

const MarkClosedSolicitationMutation = (id, callback) => {
  const markClosedSolicitationVariables = {
    input: { id }
  };

  const config = {
    mutation,
    variables: markClosedSolicitationVariables,
    onCompleted: response => {
      callback(response.markClosedSolicitation);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default MarkClosedSolicitationMutation;

MarkClosedSolicitationMutation.propTypes = {
  id: PropTypes.string.isRequired
};

/**
 * @flow
 * @relayHash 131bfb9a9b9af5b4b8aaa550f080ca1b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentRequestStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
export type MarkPaymentSentInput = {|
  id: string,
  message?: ?string,
  clientMutationId?: ?string,
|};
export type MarkPaymentSentMutationVariables = {|
  input: MarkPaymentSentInput
|};
export type MarkPaymentSentMutationResponse = {|
  +markPaymentSent: ?{|
    +paymentRequest: ?{|
      +id: string,
      +status: PaymentRequestStatus,
    |}
  |}
|};
export type MarkPaymentSentMutation = {|
  variables: MarkPaymentSentMutationVariables,
  response: MarkPaymentSentMutationResponse,
|};
*/


/*
mutation MarkPaymentSentMutation(
  $input: MarkPaymentSentInput!
) {
  markPaymentSent(input: $input) {
    paymentRequest {
      id
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkPaymentSentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "markPaymentSent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkPaymentSentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "paymentRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "PaymentRequestNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MarkPaymentSentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MarkPaymentSentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "MarkPaymentSentMutation",
    "id": null,
    "text": "mutation MarkPaymentSentMutation(\n  $input: MarkPaymentSentInput!\n) {\n  markPaymentSent(input: $input) {\n    paymentRequest {\n      id\n      status\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98670c83b714c907c6f9e9a9ca9528cd';
module.exports = node;

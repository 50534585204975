import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';
import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeleteJobAssociatedFileMutation($input: DeleteJobAssociatedFileInput!) {
    deleteJobAssociatedFile(input: $input) {
      isDeleted
    }
  }
`;

export default (fileId, jobId, callback = () => {}) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { id: fileId } },
    onCompleted: (response, errors) => {
      callback(response.deleteJobAssociatedFile, errors);
    },
    onError: err => console.error(err),
    updater: store => {
      const jobProxy = store.get(jobId);
      const conn = ConnectionHandler.getConnection(jobProxy, 'JobContractTab_associatedFiles');
      // deleting file node from the connection in Job
      ConnectionHandler.deleteNode(conn, fileId);
      // deleting file node from the store itself
      store.delete(fileId);
    }
  });
};

/**
 * @flow
 * @relayHash 194030db746f8153a18de993d8c50a2a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignUpOrganizationInput = {|
  organizationName: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  recaptcha: string,
  clientMutationId?: ?string,
|};
export type SignUpOrganizationMutationVariables = {|
  input: SignUpOrganizationInput
|};
export type SignUpOrganizationMutationResponse = {|
  +signUpOrganization: ?{|
    +emailSent: ?boolean,
    +orgToken: ?string,
    +uidb64: ?string,
  |}
|};
export type SignUpOrganizationMutation = {|
  variables: SignUpOrganizationMutationVariables,
  response: SignUpOrganizationMutationResponse,
|};
*/


/*
mutation SignUpOrganizationMutation(
  $input: SignUpOrganizationInput!
) {
  signUpOrganization(input: $input) {
    emailSent
    orgToken
    uidb64
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SignUpOrganizationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "signUpOrganization",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignUpOrganizationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailSent",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orgToken",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uidb64",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SignUpOrganizationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SignUpOrganizationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SignUpOrganizationMutation",
    "id": null,
    "text": "mutation SignUpOrganizationMutation(\n  $input: SignUpOrganizationInput!\n) {\n  signUpOrganization(input: $input) {\n    emailSent\n    orgToken\n    uidb64\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83dbc1e01bc04ce54499e9371be168ce';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountInformationContent_accountInformation$ref: FragmentReference;
declare export opaque type AccountInformationContent_accountInformation$fragmentType: AccountInformationContent_accountInformation$ref;
export type AccountInformationContent_accountInformation = {|
  +currentUser: ?{|
    +firstName: string,
    +lastName: string,
    +fullName: ?string,
    +email: string,
    +representativeImageUrl: ?string,
    +freelancer: ?{|
      +id: string,
      +coverImageUrl: ?string,
      +companyName: ?string,
      +nickname: string,
      +title: ?string,
      +phone: ?string,
      +timezone: string,
      +address1: ?string,
      +address2: ?string,
      +country: ?{|
        +name: ?string,
        +code: ?string,
      |},
      +state: ?string,
      +city: ?string,
      +zipcode: ?string,
      +additionalInfo: ?string,
      +portfolioUrl: ?string,
    |},
  |},
  +$refType: AccountInformationContent_accountInformation$ref,
|};
export type AccountInformationContent_accountInformation$data = AccountInformationContent_accountInformation;
export type AccountInformationContent_accountInformation$key = {
  +$data?: AccountInformationContent_accountInformation$data,
  +$fragmentRefs: AccountInformationContent_accountInformation$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AccountInformationContent_accountInformation",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentUser",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "representativeImageUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "freelancer",
          "storageKey": null,
          "args": null,
          "concreteType": "FreelancerNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "coverImageUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "companyName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "nickname",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "phone",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "timezone",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "address1",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "address2",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "country",
              "storageKey": null,
              "args": null,
              "concreteType": "Country",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "code",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "state",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "city",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "zipcode",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "additionalInfo",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "portfolioUrl",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a9d6085ffd5631dc5fd157498ed19906';
module.exports = node;

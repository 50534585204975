import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { ReactComponent as ArrowIcon } from '../../../shared/images/breadcrumbs_arrow.svg';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    color: theme.palette.colors.darkBlueGrey
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.colors.darkBlueGrey,
    fontSize: '16px'
  },

  arrowIcon: {
    display: 'flex',
    justifyContent: 'center',
    '&.MuiListItemIcon-root': {
      minWidth: '30px'
    }
  }
}));

const ProductReleaseBreadcrumb = ({ config }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, classes.subtitle)}>
      {config.productLink ? (
        <Link className={classes.link} to={config.productLink}>
          {config.product}
        </Link>
      ) : (
        config.product
      )}
      {config.product && config.release && (
        <ListItemIcon className={classes.arrowIcon} aria-label="arrow icon">
          <ArrowIcon />
        </ListItemIcon>
      )}
      {config.releaseLink ? (
        <Link className={classes.link} to={config.releaseLink}>
          {config.release}
        </Link>
      ) : (
        config.release
      )}
    </div>
  );
};

ProductReleaseBreadcrumb.propTypes = {
  config: PropTypes.shape({
    product: PropTypes.string.isRequired,
    productLink: PropTypes.string,
    release: PropTypes.string.isRequired,
    releaseLink: PropTypes.string
  }).isRequired
};

export default ProductReleaseBreadcrumb;

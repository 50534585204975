import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import SolicitationDetailsMainContent from './SolicitationDetailsMainContent';

const SolicitationDetailsTab = props => {
  const { id } = useParams();
  return (
    <QueryRenderer
      query={graphql`
        query SolicitationDetailsTabQuery($id: ID!) {
          solicitation(id: $id) {
            ...SolicitationDetailsMainContent_solicitation
          }
        }
      `}
      variables={{ id }}
      render={relayProps => (
        <SolicitationDetailsMainContent solicitation={relayProps.solicitation} {...props} />
      )}
    />
  );
};

export default SolicitationDetailsTab;

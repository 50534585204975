import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { fetchQuery } from 'react-relay';

import { Box, Grid, Link, TableBody, TableRow, Typography } from '@material-ui/core';

import Dialog from '../../../../shared/components/common/Dialog';
import AssetCardImage from '../../../../shared/components/assets/AssetCardImage';
import { ReactComponent as Logo } from '../../../../shared/images/awebase-logo-white-small.svg';
import { StyledTable } from '../../../../shared/components/table/StyledTable';
import TableCellAmount from '../../../../shared/components/table/TableCellAmount';
import StyledTableCell from '../../../../shared/components/table/StyledTableCell';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import {
  ActionButtonSection,
  LeftModuleSection,
  ModuleIndicator,
  RightModuleSection,
  StyledModuleFootnote
} from './styled';
import { getEnvironment } from '../../../../shared/utils/helpers';
import { BillingModalContent } from './SubscriptionModals';
import AddSubscriptionModuleMutation from './mutations/AddSubscriptionModuleMutation';
import RemoveSubscriptionModuleMutation from './mutations/RemoveSubscriptionModuleMutation';
import Date from '../../../../shared/components/common/Date';

const DIALOG_VERSIONS = {
  info: { name: 'info', title: '' },
  purchase: { name: 'purchase', title: 'Billing' },
  error: { name: 'error', title: 'Error' }
};

const SubscriptionModuleDialog = ({
  currentModule,
  scheduledModule,
  toggleDialogOpen,
  interval,
  tier,
  nextBillingDate
}) => {
  const [dialogVersion, setDialogVersion] = useState(DIALOG_VERSIONS.info);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    name,
    quantity,
    moduleId,
    imageUrl,
    headline,
    description,
    externalUrl,
    cost,
    incremental
  } = currentModule;

  const checkUpdateModule = toIncrease => {
    setSubmitting(true);
    fetchQuery(
      getEnvironment(),
      graphql`
        query SubscriptionModuleDialogQuery($module: String!, $increase: Boolean!) {
          updateModuleCheck(module: $module, increase: $increase) {
            invoicePreview {
              total
              timestamp
              invoiceLines {
                itemName
                quantity
                cost
              }
            }
            cancelModuleRemoval
            removeModule
            error
            subscriptionChanges {
              tier
              interval
              modules
              activeUsers
              totalUsers
            }
          }
        }
      `,
      {
        module: moduleId,
        increase: toIncrease
      },
      { force: true }
    ).then(response => {
      setSubmitting(false);
      if (response && response.updateModuleCheck) {
        const {
          error,
          invoicePreview,
          subscriptionChanges,
          cancelModuleRemoval,
          removeModule
        } = response.updateModuleCheck;
        if (error) {
          setDialogVersion({ ...DIALOG_VERSIONS.error, message: error });
        } else {
          setDialogVersion({
            ...DIALOG_VERSIONS.purchase,
            invoicePreview,
            subscriptionChanges,
            ...(cancelModuleRemoval && { cancelModuleRemoval: name }),
            ...(removeModule && { removeModule: name })
          });
        }
      }
    });
  };

  const scheduledChangeFootnote =
    scheduledModule &&
    scheduledModule.quantity !== quantity &&
    `${scheduledModule.quantity ? scheduledModule.quantity : ''} ${name} will be ${
      !scheduledModule.quantity ? 'no longer active' : 'active'
    } ${nextBillingDate ? 'on ' : 'if you choose to renew your subscription.'}`;

  const handleConfirmUpdateModule = () => {
    setSubmitting(true);
    if (dialogVersion.removeModule) {
      RemoveSubscriptionModuleMutation(moduleId, response => {
        setSubmitting(false);
        toggleDialogOpen();
      });
    } else {
      AddSubscriptionModuleMutation(
        {
          module: moduleId,
          ...(dialogVersion.invoicePreview && { timestamp: dialogVersion.invoicePreview.timestamp })
        },
        response => {
          setSubmitting(false);
          toggleDialogOpen();
        }
      );
    }
  };

  return (
    <Dialog
      isDialogOpen={true}
      closeDialog={toggleDialogOpen}
      title={dialogVersion.title}
      fullWidth
      maxWidth={dialogVersion.name === DIALOG_VERSIONS.info.name ? (imageUrl ? 1200 : 700) : 750}
      noHeaderMargin={dialogVersion.name === DIALOG_VERSIONS.info.name}
    >
      {dialogVersion.name === DIALOG_VERSIONS.purchase.name && (
        <BillingModalContent
          handleClose={toggleDialogOpen}
          handleSubmit={handleConfirmUpdateModule}
          isSubmitting={isSubmitting}
          {...dialogVersion}
          subscriptionChanges={{
            ...dialogVersion.subscriptionChanges,
            nextBillingDate
          }}
          currentSubscription={{
            ...dialogVersion.subscriptionChanges,
            nextBillingDate,
            modules: dialogVersion.removeModule
              ? dialogVersion.subscriptionChanges.modules + 1
              : dialogVersion.subscriptionChanges.modules - 1
          }}
        />
      )}

      {dialogVersion.name === DIALOG_VERSIONS.info.name && (
        <Grid container>
          {imageUrl && (
            <Grid item xs={7}>
              <LeftModuleSection>
                <AssetCardImage src={imageUrl} />
                <Typography variant="h3">{headline}</Typography>
                <Typography>
                  {description}{' '}
                  {externalUrl && (
                    <Link href={externalUrl} variant="caption" target="_blank" rel="noopener">
                      here.
                    </Link>
                  )}
                </Typography>
              </LeftModuleSection>
            </Grid>
          )}
          <Grid item xs={imageUrl ? 5 : 12}>
            <RightModuleSection>
              <Logo />
              <Typography variant="h1">{name}</Typography>

              {!imageUrl && (
                <>
                  <Typography variant="h3">{headline}</Typography>
                  <Typography style={{ marginTop: '10px', marginBottom: '40px' }}>
                    {description}{' '}
                    {externalUrl && (
                      <Link href={externalUrl} variant="caption" target="_blank" rel="noopener">
                        here.
                      </Link>
                    )}
                  </Typography>
                </>
              )}
              {cost && (
                <>
                  <Typography variant="overline">Subscription Cycle</Typography>
                  <Typography variant="body2">{interval}</Typography>

                  <StyledTable>
                    <TableBody>
                      {cost.map((item, index) => (
                        <TableRow
                          key={item.tier}
                          className={
                            tier === item.tier || cost.length === 1
                              ? 'active-row'
                              : index < cost.length - 1 && tier === cost[index + 1].tier
                              ? 'before-active-row'
                              : ''
                          }
                        >
                          <StyledTableCell component="th" scope="row">
                            {item.tier}
                          </StyledTableCell>
                          <TableCellAmount
                            currencyCode="USD"
                            amount={item.price}
                            className="module-price-tier-cell"
                          />
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                  <Typography variant="subtitle1">
                    Prices will be prorated according to your subscription cycle.
                  </Typography>
                </>
              )}
              {!cost && (
                <Typography>
                  If you are interested in API access, please contact us at{' '}
                  <span className="span-email">info@awebase.com</span>
                </Typography>
              )}
              {scheduledChangeFootnote && (
                <StyledModuleFootnote>
                  {scheduledChangeFootnote} {nextBillingDate && <Date date={nextBillingDate} />}
                </StyledModuleFootnote>
              )}
              {cost && (
                <ActionButtonSection>
                  {(!quantity ||
                    incremental ||
                    (scheduledModule && scheduledModule.quantity < quantity)) && (
                    <Box position="relative" width="100%">
                      <GlobalButton
                        variant="acceptation"
                        fullWidth
                        handleClick={() => checkUpdateModule(true)}
                        disabled={isSubmitting}
                        noMargin
                      >
                        Add Module
                      </GlobalButton>
                      <ModuleIndicator
                        quantity={quantity}
                        incremental={incremental}
                        forButton
                        scheduledChange={scheduledModule && scheduledModule.quantity < quantity}
                      />
                    </Box>
                  )}
                  {((quantity && !scheduledModule) ||
                    (scheduledModule && scheduledModule.quantity !== 0)) && (
                    <Box position="relative" width="100%">
                      <GlobalButton
                        variant="cancelation"
                        fullWidth
                        handleClick={() => checkUpdateModule(false)}
                        disabled={isSubmitting}
                        noMargin
                      >
                        Remove Module
                      </GlobalButton>
                      {!incremental && (
                        <ModuleIndicator quantity={quantity} incremental={incremental} forButton />
                      )}
                    </Box>
                  )}
                </ActionButtonSection>
              )}
            </RightModuleSection>
          </Grid>
        </Grid>
      )}
      {dialogVersion.name === DIALOG_VERSIONS.error.name && (
        <Typography>{dialogVersion.message}</Typography>
      )}
    </Dialog>
  );
};

export default SubscriptionModuleDialog;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const SendInvitationToContractorMutation = (id, message, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation SendInvitationToContractorMutation($input: SendInvitationToContractorInput!) {
        sendInvitationToContractor(input: $input) {
          emailSent
          updatedContractor {
            invitationEmailSent
          }
        }
      }
    `,
    variables: {
      input: {
        id,
        message
      }
    },
    onCompleted: response => {
      callback(response.sendInvitationToContractor);
    }
  });
};

SendInvitationToContractorMutation.propTypes = {
  id: PropTypes.string.isRequired,
  callback: PropTypes.func
};

export default SendInvitationToContractorMutation;

import React, { Fragment, useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { CELL_TYPES } from '../../../../shared/constants';
import withDialogProps from '../../../../shared/hoc/withDialogProps';
import AutoTable from '../../../../shared/components/table/AutoTable';
import Dialog from '../../../../shared/components/common/Dialog';
import HandleTagActionForm from './HandleTagActionForm';
import TagsListFilters from './TagsListFilters';

const ACTION_BUTTON_OPTIONS = {
  delete: { name: 'delete', label: 'Delete', modalTitle: 'Deleting Tags' },
  merge: { name: 'merge', label: 'Merge Tags', modalTitle: 'Merging Tags' }
};

const TAGS_ROW_TEMPLATE = [
  { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
  { name: 'name', label: 'Tag Name', sortable: true, type: CELL_TYPES.link },
  { name: 'relatedInstancesNumber', label: 'Number of Instances', align: 'right' }
];

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: '30px'
  }
});

const TagsList = props => {
  const classes = useStyles();
  const {
    relay: { refetch },
    tags: { tagByOrg }
  } = props;

  const initialActionsData = { action: '', selectedTags: [] };
  const [resetCursorAndRefetchCounter, setResetCursorAndRefetchCounter] = useState(0);
  const [actionsData, setActionsData] = useState(initialActionsData);

  if (!tagByOrg) return <div>Something went wrong!</div>;

  const {
    pageInfo: { hasNextPage, endCursor },
    edges,
    totalCount,
    edgeCount
  } = tagByOrg;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const getCurrentSelectedTags = ids => {
    return edges.filter(edge => ids.includes(edge.node.id));
  };

  const closeEditingHandler = () => {
    setActionsData(initialActionsData);
  };

  const actionClickHandler = (action, checked) => {
    setActionsData({ action, selectedTags: getCurrentSelectedTags(checked) });
  };

  const disabledActionsCheckHandler = checked => {
    if (!checked.length) {
      return [ACTION_BUTTON_OPTIONS.delete.name, ACTION_BUTTON_OPTIONS.merge.name];
    }
    if (checked.length === 1) {
      return [ACTION_BUTTON_OPTIONS.merge.name];
    }
  };

  const handleCloseWithRefetch = () => {
    closeEditingHandler();
    setResetCursorAndRefetchCounter(resetCursorAndRefetchCounter + 1);
  };

  const onTagNameClickHandler = id => {
    setActionsData({ action: 'edit', selectedTags: edges.filter(({ node }) => node.id === id) });
  };

  return (
    <Fragment>
      <Typography variant="h3" className={classes.marginBottom}>
        Tag Management
      </Typography>
      <AutoTable
        initialOrderBy="name"
        rowTemplate={TAGS_ROW_TEMPLATE}
        edges={edges}
        onChangeHandler={onChangeHandler}
        resetCursorAndRefetchCounter={resetCursorAndRefetchCounter}
        labelledByHeader="select all tags"
        rowProps={{
          handleLinkClick: onTagNameClickHandler
        }}
        actionButtonProps={{
          options: Object.values(ACTION_BUTTON_OPTIONS),
          handleMenuItemClick: actionClickHandler,
          getDisabledActions: disabledActionsCheckHandler
        }}
        filterProps={{
          filterComponent: <TagsListFilters />,
          initialFilters: {
            name: ''
          },
          withApply: true
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
      {actionsData.action && (
        <Dialog
          title={
            actionsData.action === 'edit'
              ? 'Rename Tag'
              : ACTION_BUTTON_OPTIONS[actionsData.action].modalTitle
          }
          isDialogOpen={actionsData.action ? true : false}
          closeDialog={closeEditingHandler}
        >
          <HandleTagActionForm
            handleClose={closeEditingHandler}
            handleCloseWithRefetch={handleCloseWithRefetch}
            action={actionsData.action}
            selectedTags={actionsData.selectedTags}
          />
        </Dialog>
      )}
    </Fragment>
  );
};

export default withRouter(
  withDialogProps(
    createRefetchContainer(
      TagsList,
      {
        tags: graphql`
          fragment TagsList_tags on Query
          @argumentDefinitions(
            orderBy: { type: "String", defaultValue: "name" }
            name: { type: "String", defaultValue: "" }
          ) {
            tagByOrg(first: $first, orderBy: $orderBy, after: $after, name: $name) {
              pageInfo {
                hasNextPage
                endCursor
              }
              totalCount
              edgeCount
              edges {
                node {
                  id
                  name
                  relatedInstancesNumber
                }
              }
            }
          }
        `
      },
      graphql`
        query TagsListRefetchQuery($first: Int, $orderBy: String, $after: String, $name: String) {
          ...TagsList_tags @arguments(orderBy: $orderBy, name: $name)
        }
      `
    )
  )
);

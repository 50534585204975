import React from 'react';
import styled from 'styled-components';
import GlobalButton from '../../UI/GlobalButton';

const BaseButton = props => (
  <GlobalButton iconProps={{ style: { height: '12px' } }} variant="primary" {...props} />
);

const AddActionButton = styled(BaseButton)`
  svg path {
    fill: #fff;
  }
`;

export default AddActionButton;

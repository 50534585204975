import React from 'react';
import { Field, Form, Formik } from 'formik';
import { DialogContentText, Typography } from '@material-ui/core';
import FormikTextField from '../../../../../../../shared/components/form/FormikTextField';
import Dialog, { DialogActions } from '../../../../../../../shared/components/common/Dialog';
import { yupSchemas } from '../../../../../../../shared/validations';
import GlobalButton from '../../../../../../../shared/components/UI/GlobalButton';
import MarkPaymentSentMutation from '../../../../mutations/MarkPaymentSentMutation';
import { successToast } from '../../../../../../../shared/toasts';

const MarkPaymentSentDialog = ({
  paymentRequestId,
  isDialogOpen,
  closeDialog,
  setPaymentRequest
}) => {
  const handleSubmit = ({ message }, { setSubmitting }) => {
    setSubmitting(true);
    MarkPaymentSentMutation({
      id: paymentRequestId,
      message: message
    })
      .then(() => successToast('Payment sent!'))
      .finally(() => {
        setSubmitting(false);
        closeDialog();
      });
  };

  const initialValues = {
    message: ''
  };

  const validationSchema = yupSchemas.shape({
    message: yupSchemas.string(false, { max: 5000 })
  });

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title="Mark Payment Sent"
      minWidth={400}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContentText>
              <Typography variant="body1" component="span">
                Message
              </Typography>
            </DialogContentText>
            <Field
              component={FormikTextField}
              rows="2"
              name="message"
              fullWidth
              multiline
              required
            />
            <DialogActions>
              <GlobalButton handleClick={closeDialog} variant="transparent" disabled={isSubmitting}>
                Cancel
              </GlobalButton>
              <GlobalButton type="submit" disabled={isSubmitting} loading={isSubmitting}>
                Submit
              </GlobalButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default MarkPaymentSentDialog;

import React from 'react';
import PropTypes from 'prop-types';

import { TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';

import { useTableStyles } from '../../styles/common/tableStyles';

const StyledTableHeader = ({ children, className, ...otherProps }) => {
  const classes = useTableStyles();
  return (
    <TableHead>
      <TableRow className={clsx(classes.tableHeadRow, className)} {...otherProps}>
        {children}
      </TableRow>
    </TableHead>
  );
};

StyledTableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default StyledTableHeader;

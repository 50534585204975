import React from 'react';
import PropTypes from 'prop-types';
import { Card as MUICard, CardActionArea, Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import styledComponent, { css } from 'styled-components';

const StyledCardActionArea = styledComponent(CardActionArea)`
  ${props =>
    !props.onClick &&
    css`
      cursor: default;
    `};
`;

const StyledCard = styled(MUICard)({
  position: 'relative',

  '&:hover': {
    '& .onHover': {
      opacity: 1
    }
  }
});

const StyledBox = styled(({ noHover, ...otherProps }) => <Box {...otherProps} />)(
  ({ theme, noHover }) => ({
    opacity: noHover ? 1 : 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    }),

    '& button:not(:last-child), & span > button': {
      marginRight: theme.spacing(1)
    }
  })
);

const Card = props => {
  const {
    onCardClick,
    renderTopLeft,
    renderTopRight,
    renderContent,
    renderActionBottom,
    renderBottom,
    classes,
    noHover,
    disabled
  } = props;

  return (
    <StyledCard elevation={0} className={classes.card}>
      {renderTopLeft && (
        <StyledBox
          className="onHover"
          m={2}
          position="absolute"
          top="0"
          left="0"
          zIndex="1"
          noHover={noHover}
        >
          {renderTopLeft()}
        </StyledBox>
      )}
      {renderTopRight && (
        <StyledBox
          className="onHover"
          m={2}
          position="absolute"
          top="0"
          right="0"
          zIndex="1"
          noHover={noHover}
        >
          {renderTopRight()}
        </StyledBox>
      )}
      <StyledCardActionArea
        onClick={onCardClick}
        className={classes.cardActionArea}
        disabled={disabled}
      >
        {renderContent()}
        {renderActionBottom && <Box mt={0.5}>{renderActionBottom()}</Box>}
      </StyledCardActionArea>
      {renderBottom()}
    </StyledCard>
  );
};

Card.propTypes = {
  onCardClick: PropTypes.func,
  renderTopLeft: PropTypes.func,
  renderTopRight: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
  renderActionBottom: PropTypes.func,
  renderBottom: PropTypes.func,
  // True makes content rendered with renderTopLeft & renderTopRight visible without hover
  noHover: PropTypes.bool,
  classes: PropTypes.shape({
    card: PropTypes.string,
    cardActionArea: PropTypes.string
  }),
  disabled: PropTypes.bool
};

Card.defaultProps = {
  renderTopLeft: null,
  renderTopRight: null,
  renderContent: () => null,
  renderActionBottom: null,
  renderBottom: () => null,
  noHover: false,
  classes: {},
  disabled: false
};

export default Card;

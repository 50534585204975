import React from 'react';
import clsx from 'clsx';
import useAssetStyles from '../../styles/assets/useAssetStyles';

const AspectRatioContainer = ({ children, aspectRatio }) => {
  const classes = useAssetStyles({ aspectRatio });
  return (
    <div className={clsx('aspect-ratio-container', classes.aspectRatioContainer)}>{children}</div>
  );
};

export default AspectRatioContainer;

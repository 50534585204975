import React, { useState, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Box, Typography } from '@material-ui/core';

import TextField from '../../../shared/components/form/fields/TextField';
import { CurrencySelect } from './CurrencySelect';

import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import withFieldProps from '../../../shared/hoc/withFieldProps';
import { useEffectWithStatus } from '../../../shared/hooks/useEffectWithStatus';
import {
  getEnvironment,
  formatCurrencyOption,
  getCurrencyCodeFromUserContext,
  getCommissionRateFromUserContext
} from '../../../shared/utils/helpers';
import { getSymbol, formatAmount, round2 } from '../../../shared/utils/formatters';

export const AmountWithCurrencyField = props => {
  const {
    userContext,
    name,
    onChange,
    value,
    helperText,
    noSelect,
    maxMenuHeight,
    ...otherProps
  } = props;

  const initialCurrencyCode = useMemo(
    () => ({
      value: value.currencyCode,
      label: value.currencyCode
    }),
    []
  );

  const [currencyCode, setCurrencyCode] = useState(initialCurrencyCode);

  useEffectWithStatus(status => {
    if (value.currencyCode !== getCurrencyCodeFromUserContext(userContext)) {
      getCurrencyData(status);
    }
  }, []);

  useEffectWithStatus(
    status => {
      // create job modal amount field case
      if (noSelect && value.amount !== 0) {
        getCurrencyData(status);
      }
    },
    [value.currencyCode]
  );

  const getCurrencyData = status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query AmountWithCurrencyFieldQuery($currencyCode: String!) {
          currencyRate(currencyCode: $currencyCode) {
            rateDate
            baseCurrency
            currencyCode
            rate
          }
        }
      `,
      { currencyCode: value.currencyCode }
    ).then(response => {
      status.mounted && setCurrencyCode(formatCurrencyOption(response.currencyRate));
    });
  };

  const onChangeHandler = (innerName, innerValue) => {
    let updatedValue = { ...value };

    if (innerName === 'currency') {
      setCurrencyCode(innerValue);
      updatedValue.currencyCode = innerValue.value;
    } else {
      updatedValue.amount = innerName.target.value;
    }

    onChange(name, updatedValue);
  };

  const renderCurrencyLabel = () => {
    const {
      rest: { baseCurrency, rate },
      value: currencyCodeValue
    } = currencyCode;

    if (baseCurrency === currencyCodeValue) {
      return false;
    }

    const commissionRate = getCommissionRateFromUserContext(userContext);

    const copies = {
      rate: `RATE - ${round2(rate)} ${currencyCodeValue} / ${baseCurrency}`,
      value: `${formatAmount((value.amount / rate) * (1 + commissionRate), baseCurrency)}`
    };

    return (
      <Box display="flex" justifyContent="space-between">
        <Typography variant="overline">{copies.rate}</Typography>
        <Typography variant="overline">{copies.value}</Typography>
      </Box>
    );
  };

  const currencySymbol = useMemo(() => getSymbol(currencyCode.value), [currencyCode]);

  return (
    <Fragment>
      <TextField
        {...otherProps}
        name="amount"
        type="number"
        value={value.amount}
        onChange={onChangeHandler}
        decoratorStart={currencySymbol}
        fullWidth
        {...(!noSelect && {
          InputProps: {
            endAdornment: (
              <Box mr="-14px" width={175}>
                <CurrencySelect
                  name="currency"
                  onChange={onChangeHandler}
                  value={currencyCode}
                  noClear
                  noBorder
                  isSearchable={false}
                  maxMenuHeight={maxMenuHeight}
                  disabled={otherProps.disabled}
                />
              </Box>
            )
          }
        })}
        {...(helperText && {
          error: true,
          helperText: typeof helperText === 'string' ? helperText : helperText.amount
        })}
      />
      {currencyCode.rest && renderCurrencyLabel()}
    </Fragment>
  );
};

AmountWithCurrencyField.propTypes = {
  value: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currencyCode: PropTypes.string
  }).isRequired,
  noSelect: PropTypes.bool.isRequired
};

AmountWithCurrencyField.defaultProps = {
  noSelect: false,
  maxMenuHeight: 150
};

export const FormikAmountWithCurrencyField = withFieldProps(
  withUserContext(AmountWithCurrencyField)
);

import React, { Fragment, useState } from 'react';
import { Typography, DialogActions, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DeleteTagsMutation from './mutations/DeleteTagsMutation';
import { successToast } from '../../../../shared/toasts';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import { Formik, Form, Field } from 'formik';
import FormikTextField from '../../../../shared/components/form/FormikTextField';
import MergeTagsMutation from './mutations/MergeTagsMutation';
import RenameTagMutation from './mutations/RenameTagMutation';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingBottom: theme.spacing(3)
  },
  dialogActions: {
    paddingTop: theme.spacing(3)
  }
}));

const HandleTagActionForm = props => {
  const { handleClose, handleCloseWithRefetch, selectedTags, action } = props;
  const classes = useStyles();

  const [mergeTagsChoice, setMergeTagsChoice] = useState(false);

  const handleResponse = response => {
    if (!response) {
      return;
    }
    const toastMessages = {
      delete: `${
        selectedTags.length > 1
          ? `${selectedTags.length} tags were deleted`
          : `${selectedTags[0].node.name} was deleted`
      }.`,
      merge: `${selectedTags.length} tags were merged to ${
        response && response.newTag && response.newTag.name
      }.`,
      edit: `${selectedTags[0].node.name} tag was renamed to ${
        response && response.tag && response.tag.name
      }.`
    };

    if (response.nameExists) {
      setMergeTagsChoice(true);
      return;
    } else if (response.isDeleted || response.newTag || response.tag) {
      setTimeout(() => {
        successToast(toastMessages[action]);
      }, 250);
      handleCloseWithRefetch();
    }
  };

  const submitHandler = values => {
    if (action === 'delete') {
      DeleteTagsMutation(
        selectedTags.map(tag => tag.node.id),
        handleResponse
      );
    } else if (action === 'merge') {
      MergeTagsMutation(
        selectedTags.map(tag => tag.node.id),
        values.name,
        mergeTagsChoice,
        handleResponse
      );
    } else if (action === 'edit') {
      RenameTagMutation(selectedTags[0].node.id, values.name, mergeTagsChoice, handleResponse);
    }
  };

  const validate = values => {
    if ((action === 'merge' || action === 'edit') && !values.name) {
      return { name: 'Required' };
    }
    return {};
  };

  const renderDeleteContent = () => (
    <Typography variant="body1">
      {`You are about to ${
        selectedTags.length === 1
          ? `delete ${selectedTags[0].node.name} tag with ${selectedTags[0].node.relatedInstancesNumber} instances`
          : `delete ${selectedTags.length} tags`
      }.`}
    </Typography>
  );

  const renderMergeContent = () => (
    <Fragment>
      <Typography variant="body1">You are about to merge tags:</Typography>
      <Typography variant="body2" className={classes.dialogContent}>
        {selectedTags.map(
          (tagNode, i) => `${tagNode.node.name}${i < selectedTags.length - 1 ? ', ' : ''}`
        )}
      </Typography>
    </Fragment>
  );

  const renderEditContent = () => (
    <Typography variant="body1" className={classes.dialogContent}>
      You are about to rename{' '}
      <Typography component="span" variant="body2">
        {selectedTags[0].node.name}
      </Typography>{' '}
      tag.
    </Typography>
  );

  const renderContent = () => {
    if (action === 'delete') return renderDeleteContent();
    if (action === 'merge') return renderMergeContent();
    if (action === 'edit') return renderEditContent();
    return null;
  };

  return (
    <Formik initialValues={{ name: '' }} validate={validate} onSubmit={submitHandler}>
      {({ values }) => (
        <Form>
          <Box display="flex" width="400px" flexDirection="column" spacing={2}>
            {renderContent()}

            {(action === 'merge' || action === 'edit') && (
              <Fragment>
                {mergeTagsChoice ? (
                  <Typography variant="body1">
                    Tag with name{' '}
                    <Typography component="span" variant="body2">
                      {values.name}
                    </Typography>{' '}
                    already exists. You can either choose a different name or merge into an existing
                    tag.
                  </Typography>
                ) : (
                  <Field
                    name="name"
                    component={FormikTextField}
                    required
                    fullWidth
                    label="New Tag Name"
                    placeholder="New tag name"
                  />
                )}
              </Fragment>
            )}

            <DialogActions className={classes.dialogActions}>
              <GlobalButton variant="transparent" handleClick={handleClose}>
                Cancel
              </GlobalButton>
              <GlobalButton type="submit">{mergeTagsChoice ? 'Merge' : 'Confirm'}</GlobalButton>
              {mergeTagsChoice && (
                <GlobalButton handleClick={() => setMergeTagsChoice(false)}>Rename</GlobalButton>
              )}
            </DialogActions>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default HandleTagActionForm;

import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import Detail from '../../../../../shared/components/UI/Detail';
import { formatBytes } from '../../../../../shared/utils/formatters';

const DetailsGroup = styled(Box)`
  position: absolute;
  left: 340px;
  display: flex;
  top: 6px;
  > *:first-child {
    margin-right: 42px;
  }
`;

const DetailItem = ({ value, ...props }) => (
  <Detail alignItems="flexStart" value={formatBytes(value)} {...props} />
);

const StyledHeader = props => {
  const { activeStorage, archiveStorage } = props;

  return (
    <Box position="relative">
      <DetailsGroup>
        <DetailItem name="Active Storage" value={activeStorage} />
        <DetailItem name="Archive Storage" value={archiveStorage} />
      </DetailsGroup>
    </Box>
  );
};

export default StyledHeader;

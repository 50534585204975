import React from 'react';
import Typography from '@material-ui/core/Typography';
import { getURLPrefix } from '../../services/utilities';

const Forbidden = () => {
  // TODO: needs actual design. Just a placeholder now.
  return (
    <div>
      <Typography>You do not have access.</Typography>
      <Typography>
        <a href={`${getURLPrefix()}/`}>Click here to go Home</a>
      </Typography>
    </div>
  );
};

export default Forbidden;

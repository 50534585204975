/**
 * @flow
 * @relayHash 3698b1137a0daa0cba543fc97e664306
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateReviewAssetCommentInput = {|
  assetId: string,
  content?: ?string,
  rawContent?: ?string,
  extraData?: ?any,
  repliedTo?: ?string,
  referenceIds?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type CreateReviewAssetCommentMutationVariables = {|
  input: CreateReviewAssetCommentInput
|};
export type CreateReviewAssetCommentMutationResponse = {|
  +createReviewAssetComment: ?{|
    +newComment: ?{|
      +id: string
    |},
    +reviewAsset: ?{|
      +reviewAssetAnnotation: ?{|
        +id: string,
        +annotationData: ?any,
      |},
      +comments: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +content: string,
            +contentEditedAt: ?any,
            +created: any,
            +objectId: number,
            +extraData: ?any,
            +repliedTo: ?{|
              +content: ?string,
              +created: ?any,
              +user: ?{|
                +fullName: ?string
              |},
              +referenceFiles: ?$ReadOnlyArray<?{|
                +id: string,
                +title: ?string,
                +description: ?string,
                +fileUrl: ?string,
                +reviewInterface: ?string,
              |}>,
            |},
            +user: ?{|
              +id: string,
              +fullName: ?string,
              +representativeImageUrl: ?string,
            |},
            +referenceFiles: ?$ReadOnlyArray<?{|
              +id: string,
              +title: ?string,
              +description: ?string,
              +fileUrl: ?string,
              +reviewInterface: ?string,
            |}>,
            +reactions: ?$ReadOnlyArray<?{|
              +id: ?string,
              +definitionId: ?string,
              +name: ?string,
              +color: ?string,
              +number: ?number,
              +users: ?$ReadOnlyArray<?{|
                +id: string,
                +fullName: ?string,
                +representativeImageUrl: ?string,
              |}>,
            |}>,
          |}
        |}>
      |},
    |},
  |}
|};
export type CreateReviewAssetCommentMutation = {|
  variables: CreateReviewAssetCommentMutationVariables,
  response: CreateReviewAssetCommentMutationResponse,
|};
*/


/*
mutation CreateReviewAssetCommentMutation(
  $input: CreateReviewAssetCommentInput!
) {
  createReviewAssetComment(input: $input) {
    newComment {
      id
    }
    reviewAsset {
      reviewAssetAnnotation {
        id
        annotationData
      }
      comments {
        edges {
          node {
            id
            content
            contentEditedAt
            created
            objectId
            extraData
            repliedTo {
              content
              created
              user {
                fullName
                id
              }
              referenceFiles {
                id
                title
                description
                fileUrl
                reviewInterface
              }
            }
            user {
              id
              fullName
              representativeImageUrl
            }
            referenceFiles {
              id
              title
              description
              fileUrl
              reviewInterface
            }
            reactions {
              id
              definitionId
              name
              color
              number
              users {
                id
                fullName
                representativeImageUrl
              }
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateReviewAssetCommentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "newComment",
  "storageKey": null,
  "args": null,
  "concreteType": "CommentNode",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reviewAssetAnnotation",
  "storageKey": null,
  "args": null,
  "concreteType": "DeliverableReviewAnnotationNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "annotationData",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contentEditedAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "objectId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "extraData",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referenceFiles",
  "storageKey": null,
  "args": null,
  "concreteType": "ReferenceNode",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reviewInterface",
      "args": null,
      "storageKey": null
    }
  ]
},
v12 = [
  (v2/*: any*/),
  (v10/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
],
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": (v12/*: any*/)
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reactions",
  "storageKey": null,
  "args": null,
  "concreteType": "ReactionNode",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "definitionId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "users",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": true,
      "selections": (v12/*: any*/)
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateReviewAssetCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createReviewAssetComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateReviewAssetCommentPayload",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "ReviewAssetNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "comments",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "repliedTo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRepliedToNode",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/)
                                ]
                              },
                              (v11/*: any*/)
                            ]
                          },
                          (v13/*: any*/),
                          (v11/*: any*/),
                          (v14/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateReviewAssetCommentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createReviewAssetComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateReviewAssetCommentPayload",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "ReviewAssetNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "comments",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "repliedTo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRepliedToNode",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v2/*: any*/)
                                ]
                              },
                              (v11/*: any*/)
                            ]
                          },
                          (v13/*: any*/),
                          (v11/*: any*/),
                          (v14/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateReviewAssetCommentMutation",
    "id": null,
    "text": "mutation CreateReviewAssetCommentMutation(\n  $input: CreateReviewAssetCommentInput!\n) {\n  createReviewAssetComment(input: $input) {\n    newComment {\n      id\n    }\n    reviewAsset {\n      reviewAssetAnnotation {\n        id\n        annotationData\n      }\n      comments {\n        edges {\n          node {\n            id\n            content\n            contentEditedAt\n            created\n            objectId\n            extraData\n            repliedTo {\n              content\n              created\n              user {\n                fullName\n                id\n              }\n              referenceFiles {\n                id\n                title\n                description\n                fileUrl\n                reviewInterface\n              }\n            }\n            user {\n              id\n              fullName\n              representativeImageUrl\n            }\n            referenceFiles {\n              id\n              title\n              description\n              fileUrl\n              reviewInterface\n            }\n            reactions {\n              id\n              definitionId\n              name\n              color\n              number\n              users {\n                id\n                fullName\n                representativeImageUrl\n              }\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '37a535e264bcb7be2b60a41a23a04734';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../../shared/utils/helpers';
import PropTypes from 'prop-types';

const CreateStaffUserMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateStaffUserMutation($input: CreateStaffUserInput!) {
        createStaffUser(input: $input) {
          staffUser {
            id
            role {
              numberOfStaff
            }
          }
          emailFailed
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.createStaffUser, errors);
    },
    onError: err => console.error(err)
  });
};

CreateStaffUserMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  )
};

export default CreateStaffUserMutation;

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { DialogActions, DialogContent, DialogContentText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormikTextField from '../../../../../../../shared/components/form/FormikTextField';
import GlobalButton from '../../../../../../../shared/components/UI/GlobalButton';
import { errorToast, successToast } from '../../../../../../../shared/toasts';
import { yupSchemas } from '../../../../../../../shared/validations';
import DeclinePaymentRequestMutation from '../../../../mutations/DeclinePaymentRequestMutation';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '0',
    paddingBottom: theme.spacing(4)
  }
}));

const DeclinePaymentRequestDialog = props => {
  const { handleClose, selectedId } = props;

  const classes = useStyles();

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    DeclinePaymentRequestMutation(selectedId, values.reason, response => {
      setSubmitting(false);
      if (response) {
        if (response.emailSent) {
          successToast('The payment request was revoked.');
        } else {
          errorToast(
            'The payment request was revoked, but there was an error sending the accounting email.'
          );
        }
        handleClose();
      }
    });
  };

  return (
    <Formik
      initialValues={{ reason: '' }}
      validationSchema={yupSchemas.shape({ reason: yupSchemas.string(true, { max: 5000 }) })}
      onSubmit={onSubmitHandler}
    >
      {({ isSubmitting }) => (
        <Form>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              <Typography variant="body1" component="span">
                Please provide a reason for revoking this payment request
              </Typography>
            </DialogContentText>
            <Field
              component={FormikTextField}
              rows="2"
              name="reason"
              fullWidth
              multiline
              required
            />
          </DialogContent>
          <DialogActions>
            <GlobalButton
              id="cancelDeclinePaymentRequest"
              handleClick={handleClose}
              variant="transparent"
              disabled={isSubmitting}
            >
              Cancel
            </GlobalButton>
            <GlobalButton
              id="submitDeclinePaymentRequest"
              type="submit"
              variant="cancelation"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Revoke Payment Request
            </GlobalButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default DeclinePaymentRequestDialog;

import React, { useState, useMemo } from 'react';
import get from 'lodash/get';
import { CELL_TYPES, FILE_STORAGE_CLASSES } from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import DownloadFileSquareButton from '../../../../../shared/components/UI/DownloadFileSquareButton';
import { successToast } from '../../../../../shared/toasts';
import Filters from './Filters';
import ConfirmationDialog from './ConfirmationDialog';
import { ProgressDialog, DownloadContainer } from './styled';
import { formatBytes } from '../../../../../shared/utils/formatters';
import TableCellTrash from '../../../../../shared/components/table/TableCellTrash';

const ACTION_BUTTON_OPTIONS = {
  download: { name: 'download', label: 'Download' },
  archive: { name: 'archive', label: 'Archive' },
  restore: { name: 'restore', label: 'Restore' },
  delete: { name: 'delete', label: 'Delete' }
};
const CONFIRMATION_DIALOGS = {
  archive: {
    action: 'archive',
    title: 'Archive Files',
    copies: {
      confirm: 'Archive'
    }
  },
  delete: {
    action: 'delete',
    title: 'Delete Files',
    copies: {
      confirm: 'Delete'
    }
  }
};

const StyledFilesList = props => {
  const {
    files,
    storageClasses,
    deliverableStateTypes,
    relay,
    canDelete,
    canDownload,
    onActionConfirm,
    handleDownloadClick
  } = props;

  const [refetchCounter, setRefetchCounter] = useState(0);
  const [performingActionOn, setPerformingActionOn] = useState(null);

  const confirmationDialogProps =
    performingActionOn && CONFIRMATION_DIALOGS[performingActionOn.type]
      ? {
          ...CONFIRMATION_DIALOGS[performingActionOn.type],
          ...onActionConfirm[performingActionOn.type]
        }
      : null;

  const flattenedEdges = useMemo(
    () =>
      files.edges.map(({ node }) => ({
        node: {
          id: node.id,
          fileName: node.fileName,
          fileSize: formatBytes(node.fileSize),
          fileUrl: node.fileUrl,
          storageClass: {
            code: node.storageClass
          },
          deliverableName: node.deliverable.title,
          deliverableState: {
            code: node.deliverable.state
          },
          releaseName: node.deliverable.release.name,
          //   needed for actions validation
          hasComments: node.hasComments
        }
      })),
    [files]
  );

  const getSuccessToastText = () => {
    const { type, files } = performingActionOn;
    let actionStr = '';
    switch (type) {
      case ACTION_BUTTON_OPTIONS.download.name:
        actionStr = `${
          files.length === 1 ? 'is' : 'are'
        } being prepared for download. The download link will be emailed shortly`;
        break;
      case ACTION_BUTTON_OPTIONS.archive.name:
        actionStr = `${files.length === 1 ? 'has' : 'have'} been archived`;
        break;
      case ACTION_BUTTON_OPTIONS.delete.name:
        actionStr = `${files.length === 1 ? 'has' : 'have'} been deleted`;
        break;
      default:
        actionStr = 'will be restored';
    }

    if (files.length === 1) {
      const { fileName } = files[0].node;
      return `${fileName} ${actionStr}.`;
    } else {
      return `${files.length} files ${actionStr}.`;
    }
  };

  const getDisabledActions = checked => {
    const actions = [];
    let standardCount = 0;
    let archivedCount = 0;

    for (const item of checked) {
      if (item.node.storageClass.code === FILE_STORAGE_CLASSES.RESTORING) {
        break;
      } else if (item.node.storageClass.code === FILE_STORAGE_CLASSES.STANDARD) {
        standardCount++;
      } else if (item.node.storageClass.code === FILE_STORAGE_CLASSES.GLACIER) {
        archivedCount++;
      }
    }

    if (checked.length !== standardCount || !canDownload)
      actions.push(ACTION_BUTTON_OPTIONS.download.name);
    if (checked.length !== standardCount) actions.push(ACTION_BUTTON_OPTIONS.archive.name);
    if (checked.length !== archivedCount) actions.push(ACTION_BUTTON_OPTIONS.restore.name);
    if (!canDelete(checked)) actions.push(ACTION_BUTTON_OPTIONS.delete.name);

    return actions;
  };

  const handleTableChange = vars => {
    relay.refetch(vars, null, () => {
      if (performingActionOn) {
        successToast(getSuccessToastText());
        setPerformingActionOn(null);
      }
    });
  };

  const handleActionClick = (action, checkedFileIds) => {
    setPerformingActionOn({
      type: action,
      files: files.edges.filter(edge => checkedFileIds.includes(edge.node.id)),
      fileIds: checkedFileIds
    });
    if (
      [ACTION_BUTTON_OPTIONS.download.name, ACTION_BUTTON_OPTIONS.restore.name].includes(action)
    ) {
      handleSubmitAction(onActionConfirm[action].onConfirm, checkedFileIds);
    }
  };

  const handleSubmitAction = (mutationFn, fileIds) => {
    mutationFn({ ids: fileIds }).then(() => setRefetchCounter(prev => prev + 1));
  };

  return (
    <>
      <ProgressDialog
        open={get(performingActionOn, 'type') === ACTION_BUTTON_OPTIONS.restore.name}
      />
      <ConfirmationDialog
        isDialogOpen={confirmationDialogProps !== null}
        {...confirmationDialogProps}
        files={get(performingActionOn, 'files', [])}
        closeDialog={() => setPerformingActionOn(null)}
        onConfirm={() => {
          handleSubmitAction(confirmationDialogProps.onConfirm, performingActionOn.fileIds);
        }}
      />

      <AutoTable
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
          {
            name: 'fileName',
            label: 'File Name',
            sortable: true
          },
          {
            name: 'deliverableName',
            label: 'Deliverable',
            width: '20%'
          },
          {
            name: 'releaseName',
            label: 'Release',
            width: '20%'
          },
          {
            name: 'deliverableState',
            label: 'Deliverable State',
            type: CELL_TYPES.status,
            statusTypes: deliverableStateTypes.enumValues
          },
          {
            name: 'storageClass',
            label: 'Storage Status',
            type: CELL_TYPES.status,
            statusTypes: storageClasses.enumValues,
            variant: 'storageClass'
          },
          {
            name: 'fileSize',
            label: 'File Size',
            sortable: true
          },
          {
            name: '',
            label: '',
            renderCell: ({ index, rowIndex }) => {
              const { node } = flattenedEdges[rowIndex];
              const canDownload = node.storageClass.code === FILE_STORAGE_CLASSES.STANDARD;
              return (
                <td key={index}>
                  <DownloadContainer isVisible={true}>
                    <DownloadFileSquareButton
                      fileName={node.fileName}
                      fileUrl={node.fileUrl}
                      // only for review assets
                      handleDownloadClick={handleDownloadClick && handleDownloadClick(node.id)}
                      disabled={!canDownload}
                      variant="transparent"
                      tooltip={
                        canDownload
                          ? 'Download'
                          : 'This file has been archived and cannot be downloaded.'
                      }
                    />
                  </DownloadContainer>
                </td>
              );
            },
            width: 30
          },
          {
            name: 'fileName',
            label: '',
            onClick: id => handleSubmitAction(onActionConfirm.delete.onConfirm, [id]),
            withConfirm: true,
            disabled: !canDelete(),
            renderCell: ({ index, rowIndex }) => {
              const { node } = flattenedEdges[rowIndex];
              const canDeleteThisNode = canDelete([{ node: { ...node } }]);
              return (
                <TableCellTrash
                  key={index}
                  id={node.id}
                  rowIndex={rowIndex}
                  handleClick={id => handleSubmitAction(onActionConfirm.delete.onConfirm, [id])}
                  disabled={!canDeleteThisNode}
                  tooltip={
                    canDeleteThisNode
                      ? 'Delete'
                      : 'This review asset has comments and cannot be deleted.'
                  }
                  withConfirm
                  fileName={node.fileName}
                />
              );
            },
            width: 30
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={handleTableChange}
        refetchCounter={refetchCounter}
        labelledByHeader="select all files"
        actionButtonProps={{
          options: Object.values(ACTION_BUTTON_OPTIONS),
          handleMenuItemClick: handleActionClick,
          getDisabledActions
        }}
        filterProps={{
          withApply: true,
          filterComponent: (
            <Filters
              storageClasses={storageClasses.enumValues.filter(
                ({ name }) => name !== 'DEEP_ARCHIVE'
              )}
              deliverableStates={deliverableStateTypes.enumValues}
            />
          )
        }}
        paginationProps={{
          hasNextPage: files.pageInfo.hasNextPage,
          endCursor: files.pageInfo.endCursor,
          totalCount: files.totalCount,
          edgeCount: files.edgeCount
        }}
      />
    </>
  );
};

export default StyledFilesList;

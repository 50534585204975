import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const contractorGroupQuery = graphql`
  query SolicitationSearchableContractorGroupDropdownQuery(
    $name: String
    $orderBy: String
    $first: Int
  ) {
    general {
      contractorGroups(name: $name, orderBy: $orderBy, first: $first) {
        edgeCount
        totalCount
        edges {
          node {
            id
            name
            contractors {
              edges {
                node {
                  id
                  fullName
                  lastName
                  representativeImageUrl
                  conflictingContractor {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SolicitationSearchableContractorGroupDropdown = props => {
  const { onChange } = props;

  const getFetchVariables = inputValue => {
    return {
      name: inputValue,
      first: 10,
      orderBy: 'name'
    };
  };

  const formatOptions = data => {
    if (!data || !data.general || !data.general.contractorGroups) {
      return [];
    }
    return data.general.contractorGroups.edges.reduce((result, group) => {
      result.push({
        value: group.node.id,
        label: group.node.name,
        ...group.node
      });

      return result;
    }, []);
  };

  return (
    <SearchableDropdown
      resultKey="general.contractorGroups"
      query={contractorGroupQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      value={[]}
      selectChangeHandler={onChange}
      name="solicitationContractorGroup"
      placeholder="Add contractor group"
      setInitialValue
    />
  );
};
export default SolicitationSearchableContractorGroupDropdown;

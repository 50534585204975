import { makeStyles } from '@material-ui/styles';

const useAssetStyles = makeStyles(theme => ({
  headerToolbar: {
    marginBottom: theme.spacing(3),
    '& button:first-child': {
      marginLeft: 0
    }
  },

  aspectRatioContainer: {
    paddingTop: props => (props.aspectRatio ? `${props.aspectRatio}%` : '53%'),
    width: '100%',
    position: 'relative',

    '&:hover': {
      '& .asset-hover-container': {
        opacity: '1'
      }
    }
  },

  aspectRatioImageContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    borderRadius: '4px',
    overflow: 'hidden',
    backgroundColor: theme.palette.colors.grey,

    '& img': {
      width: '100%',
      height: '100%',
      // backgroundColor: '#3f3f3f',
      objectFit: props => (props.cover ? 'cover' : 'contain')
    },

    '& svg': {
      width: '100%',
      height: '100%'
    }
  },

  descriptionButton: {
    margin: '0',
    padding: '0',
    height: 'auto',

    '&:hover': {
      backgroundColor: 'transparent'
    },

    '& span': {
      fontSize: '14px',
      color: theme.palette.primary.main,
      marginTop: '1rem',
      padding: '0',
      justifyContent: 'flex-start'
    }
  },

  descriptionPreview: {
    marginTop: '0.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '5',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box'
  },

  assetGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridGap: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },

  assetDescriptionDialogContent: {
    minWidth: '400px',
    '& h3': {
      marginBottom: theme.spacing(2)
    },
    '& p': {
      fontWeight: 'normal',
      fontSize: '14px'
    }
  }
}));

export default useAssetStyles;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeactivateProductMutation($input: DeactivateProductInput!) {
    deactivateProduct(input: $input) {
      updatedProduct {
        id
        isActive
      }
    }
  }
`;

const DeactivateProductMutation = (id, callback) => {
  const config = {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.deactivateProduct);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default DeactivateProductMutation;

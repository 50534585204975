import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const RemindAboutInvoiceMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation RemindAboutInvoiceMutation($input: RemindAboutInvoiceInput!) {
          remindAboutInvoice(input: $input) {
            success
          }
        }
      `,
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default RemindAboutInvoiceMutation;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation RemoveSolicitationDeliverableMutation($input: RemoveSolicitationDeliverableInput!) {
    removeSolicitationDeliverable(input: $input) {
      isUpdated
      solicitation {
        id
        numberOfDeliverables
        totalDeliverableAmount
        numberOfContractedDeliverables
        numberOfAllocatedDeliverables
      }
    }
  }
`;

const RemoveSolicitationDeliverableMutation = (solicitationId, deliverableId, callback) => {
  const removeSolicitationDeliverableVariables = {
    input: { solicitationId, deliverableId }
  };

  const config = {
    mutation,
    variables: removeSolicitationDeliverableVariables,
    onCompleted: response => {
      callback(response.removeSolicitationDeliverable);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default RemoveSolicitationDeliverableMutation;

RemoveSolicitationDeliverableMutation.propTypes = {
  solicitationId: PropTypes.string.isRequired,
  deliverableId: PropTypes.string.isRequired
};

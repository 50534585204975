import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography, Box, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import useSideBar from '../../hooks/useSideBar';
import { drawerWidth, drawerClosedWidth } from '../../styles/common/base';
import { regularMenuWidth, menuWithContextActionWidth } from '../../styles/common/globalCreate';

const useStyles = makeStyles(theme => ({
  popper: {
    transform: props => {
      const menuPopperWidth = props.willShowAnyContextActions
        ? menuWithContextActionWidth
        : regularMenuWidth;
      const sidebarWidth = props.isSidebarOpen ? drawerWidth : drawerClosedWidth;
      return `translate3d(${
        menuPopperWidth + sidebarWidth + theme.spacing(2)
      }px, 62px, 0px)!important`;
    },
    pointerEvents: 'auto'
  },
  tooltip: {
    backgroundColor: '#fff',
    color: theme.palette.colors.darkBlueGrey,
    boxShadow: theme.shadows[2],
    fontSize: 14,
    lineHeight: 1.25,
    width: 300
  },
  arrow: {
    color: '#fff',
    '&::before': {
      boxShadow: theme.shadows[2]
    }
  }
}));

const TooltipTitle = props => {
  const { variant, listOfItems = [] } = props;
  const upToFiveItemList = listOfItems.slice(0, 5);
  return (
    <Box p={2}>
      {variant === 'permission' && (
        <Fragment>
          <Typography variant="body1">
            You do not have the appropriate permissions to take this action.
          </Typography>
          <Typography variant="body1">Contact your AWEbase administrator.</Typography>
        </Fragment>
      )}
      {variant === 'job' && (
        <Fragment>
          <Typography variant="body1">
            The selected Deliverable{listOfItems.length ? 's' : ''} cannot be added to a job. Please
            review and make the following changes:
          </Typography>
          <Box mt={2} component="p">
            Deselect deliverables that are not in{' '}
            <Box component="span" color="colors.grey" fontWeight="bold">
              Draft
            </Box>{' '}
            or{' '}
            <Typography variant="subtitle1" component="span">
              Assignable
            </Typography>{' '}
            state:
          </Box>
          {listOfItems.length > 0 && (
            <Box pl={1} mt={1}>
              {upToFiveItemList.map(item => (
                <Typography variant="body2" key={item}>
                  {item}
                </Typography>
              ))}
              {listOfItems.length > 5 && <Typography variant="overline">and more...</Typography>}
            </Box>
          )}
          <Box component="p" mt={2}>
            For more info, visit{' '}
            <Link
              variant="caption"
              target="_blank"
              rel="noopener"
              href="https://intercom.help/awebase/en/articles/4003165-deliverables-faq-and-troubleshooting"
            >
              Deliverables FAQ
            </Link>
          </Box>
        </Fragment>
      )}
      {variant === 'solicitationFromDeliverables' && (
        <Typography variant="body1">
          To create a JobOpp from Deliverables, all Deliverables must be assigned to the
          current user, marked assignable, and in the same category.
        </Typography>
      )}
      {variant === 'solicitationFromContractors' && (
        <Typography variant="body1">
          To create a JobOpp from Contractors, all Contractors must be active and able to be
          hired.
        </Typography>
      )}
    </Box>
  );
};

const GlobalCreateTooltip = props => {
  const { variant, errorItems, willShowAnyContextActions, ...otherProps } = props;
  const isSidebarOpen = useSideBar()[0];

  const classes = useStyles({ willShowAnyContextActions, isSidebarOpen });

  return (
    <Tooltip
      arrow
      interactive
      placement="right-start"
      classes={classes}
      title={variant ? <TooltipTitle variant={variant} listOfItems={errorItems} /> : ''}
      {...otherProps}
    />
  );
};

export default GlobalCreateTooltip;

GlobalCreateTooltip.propTypes = {
  // if variant is empty string, no tooltip will be shown
  variant: PropTypes.oneOf([
    '',
    'job',
    'solicitationFromDeliverables',
    'solicitationFromContractors',
    'permission'
  ]),
  errorItems: PropTypes.array
};

GlobalCreateTooltip.defaultProps = {
  errorItems: []
};

import React, { useState } from 'react';
import styled from 'styled-components';
import graphql from 'babel-plugin-relay/macro';
import { Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import SquareButton from '../../../../shared/components/UI/SquareButton';
import DropdownTooltip from '../../../../shared/components/UI/DropdownTooltip';
import { ReactComponent as AddReactionIcon } from '../../../../shared/images/add-reaction-icon.svg';

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 150px;
  padding: 4px 10px 8px;
`;

const DropdownItem = styled(({ colorCode, ...props }) => <Typography {...props} />).attrs({
  component: 'button',
  variant: 'body2'
})`
  width: 100%;
  text-align: left;
  background: transparent;
  cursor: pointer;
  border: none;
  margin-top: 2px;
  padding: 0 0 2px 0;
  color: #${props => props.colorCode};

  &:hover {
    box-shadow: inset 0px -2px 0px 0px ${props => fade(props.theme.palette.primary.main, 0.2)};
  }
`;

const ReactionsList = ({ onSelect }) => (
  <QueryRenderer
    query={graphql`
      query ReactionButtonQuery {
        reactionByOrg {
          edges {
            node {
              id
              name
              color
            }
          }
        }
      }
    `}
    render={relayProps => {
      const reactions = relayProps.reactionByOrg.edges.map(edge => edge.node);
      return (
        <DropdownContainer>
          {reactions.map(reaction => (
            <DropdownItem
              key={reaction.id}
              colorCode={reaction.color}
              onClick={() => onSelect(reaction)}
            >
              {reaction.name}
            </DropdownItem>
          ))}
        </DropdownContainer>
      );
    }}
  />
);

const ReactionButton = ({ onSelect, renderButton }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(val => !val);
  };

  const handleSelect = reaction => {
    setIsOpen(false);
    onSelect(reaction);
  };

  return (
    <DropdownTooltip
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={<ReactionsList onSelect={handleSelect} />}
    >
      {renderButton ? (
        renderButton({ handleToggle })
      ) : (
        <SquareButton variant="secondary" size={24} icon={AddReactionIcon} onClick={handleToggle} />
      )}
    </DropdownTooltip>
  );
};

export default ReactionButton;

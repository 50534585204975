import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Box, Divider } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { yupSchemas } from '../../../../../../shared/validations';
import useUpdateEffect from '../../../../../../shared/hooks/useUpdateEffect';
import Dialog from '../../../../../../shared/components/common/Dialog';
import Detail from '../../../../../../shared/components/UI/Detail';
import Amount from '../../../../../../shared/components/common/Amount';
import AvatarWithName from '../../../../../../shared/components/UI/AvatarWithName';
import FullscreenModal from '../../../../../../shared/components/fullscreenModal/FullscreenModal';
import ContractPreview from '../../../../../../shared/components/job/ContractPreview';
import FormikAmountFieldWithCurrencyRate from '../../../../../../shared/components/form/formikFields/FormikAmountFieldWithCurrencyRate';
import FormikTextField from '../../../../../../shared/components/form/FormikTextField';
import { FormikCheckbox } from '../../../../../../shared/components/form/FormikCheckbox';
import FormikDropzone from '../../../../../../shared/components/form/formikFields/FormikDropzone';
import GlobalButton from '../../../../../../shared/components/UI/GlobalButton';
import { errorToast } from '../../../../../../shared/toasts';
import PreviewPaymentRequestMutation from '../../../mutations/PreviewPaymentRequestMutation';

const CreatePaymentRequestDialog = props => {
  const {
    job: {
      id,
      jobId,
      name: jobName,
      totalValue,
      balance,
      currencyCode,
      conversionRateInPreferredCurrency,
      contractor: {
        fullName,
        representativeImageUrl,
        currency: contractorPreferredCurrencyCode,
        organization: { financeEmail, email: orgDefaultEmail }
      },
      contract
    },
    onSubmit,
    isDialogOpen,
    closeDialog,
    initialValues
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [pdfBase64, setPdfBase64] = useState(null);

  const togglePreview = () => {
    setPreviewOpen(!previewOpen);
  };

  useUpdateEffect(() => {
    togglePreview();
  }, [pdfBase64]);

  const formikProps = {
    initialValues: {
      file: null,
      attachContract: false,
      paymentAmount: '',
      message: '',
      ...initialValues
    },
    validationSchema: yupSchemas.shape({
      file: yupSchemas.pdfFileSize,
      paymentAmount: Yup.number()
        .required('Required')
        .min(0.01, 'Must be more than zero.')
        .max(balance, 'Amount cannot exceed current balance.'),
      message: yupSchemas.string(false, 1, 5000)
    }),
    onSubmit: (values, actions) => {
      const { paymentAmount, message, attachContract, file } = values;
      const variables = { jobId: id, paymentAmount, message, attachContract };

      if (!pdfBase64) {
        PreviewPaymentRequestMutation(variables, file, response => {
          actions.setSubmitting(false);
          if (response && response.previewPaymentRequest) {
            if (response.previewPaymentRequest.paymentRequestPreview) {
              // sometimes the response will be there but file content will be null, so setting modal open only if there is content,
              // show error otherwise
              setPdfBase64(
                `data:application/pdf;base64,${response.previewPaymentRequest.paymentRequestPreview}`
              );
            }
          } else {
            errorToast('Generating payment request file failed, try again!');
          }
        });
      } else {
        onSubmit(values, actions);
      }
    }
  };

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title="Create Payment Request"
      minWidth={500}
    >
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail name="Job Name" value={jobName} flexDirection="column" alignItems="flex-start" />
        <Detail
          name="Accounts Payable Email"
          value={financeEmail || orgDefaultEmail}
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail name="Job ID" value={jobId} flexDirection="column" alignItems="flex-start" />
        <Detail
          name="Total Job Amount"
          value={<Amount amount={totalValue} currencyCode={currencyCode} />}
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail
          name="Contractor"
          renderValue={() => (
            <AvatarWithName
              name={fullName}
              size={34}
              avatarProps={{
                src: representativeImageUrl
              }}
            />
          )}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Detail
          name="Balance"
          value={<Amount amount={balance} currencyCode={currencyCode} />}
          valueContainerProps={{
            variant: 'h2'
          }}
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>
      <Divider />
      <Formik {...formikProps}>
        {({ submitForm, isSubmitting }) => (
          <>
            <Form>
              <Box mt={2} mb={2}>
                <Field name="file" label="Attach File (Invoice, etc.)" component={FormikDropzone} />
              </Box>
              <Box mb={2}>
                <Field
                  name="paymentAmount"
                  label="Requested Amount (Exclusive of VAT)"
                  component={FormikAmountFieldWithCurrencyRate}
                  currencyCode={currencyCode}
                  targetCurrencyRate={conversionRateInPreferredCurrency}
                  targetCurrencyCode={contractorPreferredCurrencyCode}
                  fullWidth
                  required
                />
              </Box>
              <Box mb={4}>
                <Field
                  name="message"
                  label="Message"
                  component={FormikTextField}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Field
                  name="attachContract"
                  label="Attach Contract"
                  component={FormikCheckbox}
                  disabled={!contract}
                />
                <Box display="flex">
                  <GlobalButton
                    handleClick={closeDialog}
                    variant="transparent"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </GlobalButton>
                  <GlobalButton
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type="submit"
                    variant="primary"
                    style={{ marginRight: 0 }}
                  >
                    Review and Submit
                  </GlobalButton>
                </Box>
              </Box>
            </Form>
            <FullscreenModal
              open={previewOpen}
              handleBack={() => setPdfBase64(null)}
              actions={[
                {
                  label: 'Submit',
                  handler: submitForm,
                  isSubmitting
                }
              ]}
            >
              {pdfBase64 && <ContractPreview contractPreviewPdf={pdfBase64} />}
            </FullscreenModal>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default createFragmentContainer(CreatePaymentRequestDialog, {
  job: graphql`
    fragment CreatePaymentRequestDialog_job on JobNode {
      id
      jobId
      name
      balance
      totalValue
      currencyCode
      conversionRateInPreferredCurrency
      contractor {
        id
        fullName
        representativeImageUrl
        currency
        organization {
          financeEmail
          email
        }
      }
      contract {
        id
      }
    }
  `
});

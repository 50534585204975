import React from 'react';
import clsx from 'clsx';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import DetailsDescription from './DetailsDescription';
import DetailsMainContent from './DetailsMainContent';
import DetailsContact from './DetailsContact';

const DeliverableDetailsTab = () => {
  const { id } = useParams();
  const classes = useDetailsStyles();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableDetailsTabQuery($id: ID!) {
          deliverable(id: $id) {
            ...DetailsDescription_deliverable
            ...DetailsMainContent_deliverable
            ...DetailsContact_deliverable
          }
        }
      `}
      variables={{ id }}
      render={relayProps => (
        <div className={classes.container}>
          <div className={classes.width100}>
            <DetailsDescription deliverable={relayProps.deliverable} />
            <div className={clsx(classes.containerColumn, classes.width100)}>
              <div className={classes.container}>
                <DetailsMainContent deliverable={relayProps.deliverable} />
              </div>
              <DetailsContact deliverable={relayProps.deliverable} />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default DeliverableDetailsTab;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const SignUpOrganizationMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation SignUpOrganizationMutation($input: SignUpOrganizationInput!) {
        signUpOrganization(input: $input) {
          emailSent
          orgToken
          uidb64
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.signUpOrganization, errors);
    },
    onError: err => console.error(err)
  });
};

export default SignUpOrganizationMutation;

SignUpOrganizationMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      organizationName: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      recaptcha: PropTypes.string.isRequired
    })
  )
};

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import ContractorsList from './ContractorsList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';

const ContractorsListPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ContractorsListPageQuery(
          $first: Int
          $orderBy: String
          $after: String
          $includeDoNotHire: Boolean
          $includeInactive: Boolean
          $contractorName: String
          $inviteStatus: String
          $country: String
          $release: String
          $product: String
          $tags: String
          $minCompletedDeliverables: Float
          $group: String
        ) {
          ...ContractorsList_contractors
        }
      `}
      variables={variables}
      render={relayProps => <ContractorsList contractors={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(ContractorsListPage, 'orgContrList', {
  orderBy: 'name',
  initialFilters: {
    contractorName: '',
    includeDoNotHire: false,
    includeInactive: false,
    inviteStatus: '',
    tags: '',
    release: null,
    product: null,
    country: null,
    minCompletedDeliverables: null,
    group: null
  }
});

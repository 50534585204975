/**
 * @flow
 * @relayHash 687938d4e20be39a6a16d8ff85e81559
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AnnotationView_reviewAsset$ref = any;
export type AnnotationViewQueryRendererQueryVariables = {|
  reviewAssetId: string
|};
export type AnnotationViewQueryRendererQueryResponse = {|
  +reviewAsset: ?{|
    +$fragmentRefs: AnnotationView_reviewAsset$ref
  |}
|};
export type AnnotationViewQueryRendererQuery = {|
  variables: AnnotationViewQueryRendererQueryVariables,
  response: AnnotationViewQueryRendererQueryResponse,
|};
*/


/*
query AnnotationViewQueryRendererQuery(
  $reviewAssetId: ID!
) {
  reviewAsset(id: $reviewAssetId) {
    ...AnnotationView_reviewAsset
    id
  }
}

fragment AnnotationView_reviewAsset on ReviewAssetNode {
  id
  fileReviewUrl
  settings
  reviewAssetAnnotation {
    annotationData
    id
  }
  reactions {
    id
    definitionId
    name
    color
    number
    users {
      id
      fullName
      representativeImageUrl
    }
  }
  comments {
    edges {
      node {
        id
        content
        contentEditedAt
        created
        objectId
        extraData
        referenceFiles {
          id
          title
          description
          fileUrl
          reviewInterface
        }
        repliedTo {
          content
          created
          user {
            fullName
            id
          }
          referenceFiles {
            id
            title
            description
            fileUrl
            reviewInterface
          }
        }
        user {
          id
          fullName
          representativeImageUrl
        }
        reactions {
          id
          definitionId
          name
          color
          number
          users {
            id
            fullName
            representativeImageUrl
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "reviewAssetId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "reviewAssetId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reactions",
  "storageKey": null,
  "args": null,
  "concreteType": "ReactionNode",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "definitionId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "users",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": true,
      "selections": (v4/*: any*/)
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referenceFiles",
  "storageKey": null,
  "args": null,
  "concreteType": "ReferenceNode",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reviewInterface",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AnnotationViewQueryRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReviewAssetNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AnnotationView_reviewAsset",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AnnotationViewQueryRendererQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReviewAssetNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileReviewUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "settings",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAssetAnnotation",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableReviewAnnotationNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "annotationData",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/)
            ]
          },
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comments",
            "storageKey": null,
            "args": null,
            "concreteType": "CommentNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "contentEditedAt",
                        "args": null,
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "objectId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "extraData",
                        "args": null,
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "repliedTo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentRepliedToNode",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ]
                          },
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": (v4/*: any*/)
                      },
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AnnotationViewQueryRendererQuery",
    "id": null,
    "text": "query AnnotationViewQueryRendererQuery(\n  $reviewAssetId: ID!\n) {\n  reviewAsset(id: $reviewAssetId) {\n    ...AnnotationView_reviewAsset\n    id\n  }\n}\n\nfragment AnnotationView_reviewAsset on ReviewAssetNode {\n  id\n  fileReviewUrl\n  settings\n  reviewAssetAnnotation {\n    annotationData\n    id\n  }\n  reactions {\n    id\n    definitionId\n    name\n    color\n    number\n    users {\n      id\n      fullName\n      representativeImageUrl\n    }\n  }\n  comments {\n    edges {\n      node {\n        id\n        content\n        contentEditedAt\n        created\n        objectId\n        extraData\n        referenceFiles {\n          id\n          title\n          description\n          fileUrl\n          reviewInterface\n        }\n        repliedTo {\n          content\n          created\n          user {\n            fullName\n            id\n          }\n          referenceFiles {\n            id\n            title\n            description\n            fileUrl\n            reviewInterface\n          }\n        }\n        user {\n          id\n          fullName\n          representativeImageUrl\n        }\n        reactions {\n          id\n          definitionId\n          name\n          color\n          number\n          users {\n            id\n            fullName\n            representativeImageUrl\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '84fc955e6c4c6fbe1aa979e48ef43cbc';
module.exports = node;

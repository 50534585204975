import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import StyledButtonGroup from './buttonGroup/StyledButtonGroup';
import GlobalButton from './GlobalButton';
import GroupButtonSquare from './buttonGroup/GroupButtonSquare';

const Container = styled.div`
  display: inline-block;
  position: relative;
  z-index: 2;

  button {
    background: #d5d9e2;
    text-transform: none;
    border: none;

    span {
      font-size: 12px;
      font-weight: bold;
    }
  }

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;

      button:first-child {
        width: calc(100% - 35px);
      }
    `};
`;

function setPopperFullWidth({ instance: { reference, popper } }) {
  popper.style.width = `${reference.offsetWidth}px`;
}

const ActionButton = props => {
  const { label, fullWidth, options } = props;
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => setIsOpen(val => !val);

  const handleClose = e => {
    if (anchorRef.current && !anchorRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  return (
    <Container fullWidth={fullWidth}>
      <StyledButtonGroup ref={anchorRef} fullWidth={fullWidth}>
        <GlobalButton variant="secondary" noMargin handleClick={handleToggle} fullWidth={fullWidth}>
          {label}
        </GlobalButton>
        <GroupButtonSquare
          id="actionButtonArrow"
          onClick={handleToggle}
          icon="arrowDropdown"
          variant="secondary"
          size={34}
          aria-haspopup="true"
          aria-owns={isOpen ? 'menu-list-grow' : undefined}
        />
      </StyledButtonGroup>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        {...(fullWidth && {
          placement: 'bottom-start',
          popperOptions: {
            onCreate: setPopperFullWidth,
            onUpdate: setPopperFullWidth
          }
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center bottom'
            }}
          >
            <Paper id="menu-list-grow" style={{ width: '100%' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((optionProps, i) => (
                    <MenuItem
                      key={i}
                      {...optionProps}
                      onClick={e => {
                        if (optionProps.onClick) optionProps.onClick(e);
                        setIsOpen(false);
                      }}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
};

ActionButton.propsTypes = {
  label: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object)
};

export default ActionButton;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PaymentRequestStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentRequestQuickViewContent_paymentRequest$ref: FragmentReference;
declare export opaque type PaymentRequestQuickViewContent_paymentRequest$fragmentType: PaymentRequestQuickViewContent_paymentRequest$ref;
export type PaymentRequestQuickViewContent_paymentRequest = {|
  +id: string,
  +requestId: string,
  +status: PaymentRequestStatus,
  +created: any,
  +paymentAmount: number,
  +paymentRequestedBy: ?{|
    +id: string,
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +paymentRequestFileUrl: ?string,
  +message: ?string,
  +declinedBy: ?{|
    +id: string,
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +declinedDate: ?any,
  +declinedReason: ?string,
  +invoice: ?{|
    +invoiceFileUrl: ?string
  |},
  +job: {|
    +currencyCode: ?string,
    +contractor: {|
      +id: string,
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |},
  |},
  +$refType: PaymentRequestQuickViewContent_paymentRequest$ref,
|};
export type PaymentRequestQuickViewContent_paymentRequest$data = PaymentRequestQuickViewContent_paymentRequest;
export type PaymentRequestQuickViewContent_paymentRequest$key = {
  +$data?: PaymentRequestQuickViewContent_paymentRequest$data,
  +$fragmentRefs: PaymentRequestQuickViewContent_paymentRequest$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "PaymentRequestQuickViewContent_paymentRequest",
  "type": "PaymentRequestNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requestId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "created",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "paymentRequestedBy",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentRequestFileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "declinedBy",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "declinedDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "declinedReason",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "invoice",
      "storageKey": null,
      "args": null,
      "concreteType": "InvoiceNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "invoiceFileUrl",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "job",
      "storageKey": null,
      "args": null,
      "concreteType": "JobNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currencyCode",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contractor",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractorNode",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0ad9d7717c3fc0e92e15aa438a63553';
module.exports = node;

/**
 * @flow
 * @relayHash 187de458b94c8281571b727d1bdc7ec9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PreviewPaymentRequestInput = {|
  jobId: string,
  paymentAmount: any,
  message?: ?string,
  attachContract?: ?boolean,
  clientMutationId?: ?string,
|};
export type PreviewPaymentRequestMutationVariables = {|
  input: PreviewPaymentRequestInput
|};
export type PreviewPaymentRequestMutationResponse = {|
  +previewPaymentRequest: ?{|
    +paymentRequestPreview: ?string
  |}
|};
export type PreviewPaymentRequestMutation = {|
  variables: PreviewPaymentRequestMutationVariables,
  response: PreviewPaymentRequestMutationResponse,
|};
*/


/*
mutation PreviewPaymentRequestMutation(
  $input: PreviewPaymentRequestInput!
) {
  previewPaymentRequest(input: $input) {
    paymentRequestPreview
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PreviewPaymentRequestInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "previewPaymentRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PreviewPaymentRequestPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "paymentRequestPreview",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PreviewPaymentRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PreviewPaymentRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PreviewPaymentRequestMutation",
    "id": null,
    "text": "mutation PreviewPaymentRequestMutation(\n  $input: PreviewPaymentRequestInput!\n) {\n  previewPaymentRequest(input: $input) {\n    paymentRequestPreview\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5aeb0248a3204553e2dbc07f9185654b';
module.exports = node;

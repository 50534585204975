/**
 * @flow
 * @relayHash b21d3485e79efcbfc13208de38f72a0c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InvoicesList_job$ref = any;
type InvoicesList_statusTypes$ref = any;
export type InvoicesListQueryVariables = {|
  jobId: string
|};
export type InvoicesListQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: InvoicesList_job$ref
  |},
  +invoiceStatusTypes: ?{|
    +$fragmentRefs: InvoicesList_statusTypes$ref
  |},
|};
export type InvoicesListQuery = {|
  variables: InvoicesListQueryVariables,
  response: InvoicesListQueryResponse,
|};
*/

/*
query InvoicesListQuery(
  $jobId: ID!
) {
  job(id: $jobId) {
    ...InvoicesList_job
    id
  }
  invoiceStatusTypes: __type(name: "JobInvoiceStatus") {
    ...InvoicesList_statusTypes
  }
}

fragment InvoicesList_job on JobNode {
  id
  currencyCode
  invoices(orderBy: "-created") {
    edges {
      node {
        id
        identifier
        status
        created
        amount
        verificationDate
      }
    }
  }
}

fragment InvoicesList_statusTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'jobId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'jobId'
      }
    ],
    v2 = [
      {
        kind: 'Literal',
        name: 'name',
        value: 'JobInvoiceStatus'
      }
    ],
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'InvoicesListQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'InvoicesList_job',
              args: null
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: 'invoiceStatusTypes',
          name: '__type',
          storageKey: '__type(name:"JobInvoiceStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'InvoicesList_statusTypes',
              args: null
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'InvoicesListQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            (v3 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'currencyCode',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'invoices',
              storageKey: 'invoices(orderBy:"-created")',
              args: [
                {
                  kind: 'Literal',
                  name: 'orderBy',
                  value: '-created'
                }
              ],
              concreteType: 'InvoiceNodeConnection',
              plural: false,
              selections: [
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'edges',
                  storageKey: null,
                  args: null,
                  concreteType: 'InvoiceNodeEdge',
                  plural: true,
                  selections: [
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'node',
                      storageKey: null,
                      args: null,
                      concreteType: 'InvoiceNode',
                      plural: false,
                      selections: [
                        (v3 /*: any*/),
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'identifier',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'status',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'created',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'amount',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'verificationDate',
                          args: null,
                          storageKey: null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          kind: 'LinkedField',
          alias: 'invoiceStatusTypes',
          name: '__type',
          storageKey: '__type(name:"JobInvoiceStatus")',
          args: (v2 /*: any*/),
          concreteType: '__Type',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'enumValues',
              storageKey: null,
              args: null,
              concreteType: '__EnumValue',
              plural: true,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'name',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'description',
                  args: null,
                  storageKey: null
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'InvoicesListQuery',
      id: null,
      text:
        'query InvoicesListQuery(\n  $jobId: ID!\n) {\n  job(id: $jobId) {\n    ...InvoicesList_job\n    id\n  }\n  invoiceStatusTypes: __type(name: "JobInvoiceStatus") {\n    ...InvoicesList_statusTypes\n  }\n}\n\nfragment InvoicesList_job on JobNode {\n  id\n  currencyCode\n  invoices(orderBy: "-created") {\n    edges {\n      node {\n        id\n        identifier\n        status\n        created\n        amount\n        verificationDate\n      }\n    }\n  }\n}\n\nfragment InvoicesList_statusTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '6b9a7f11852d5dc4287331a445ef7006';
module.exports = node;

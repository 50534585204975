/**
 * @flow
 * @relayHash ad0009c828518c2d226e672cc1cbf195
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getResetPasswordTokenStatusQueryVariables = {|
  resetToken: string,
  uidb64: string,
|};
export type getResetPasswordTokenStatusQueryResponse = {|
  +resetPasswordTokenStatus: ?boolean
|};
export type getResetPasswordTokenStatusQuery = {|
  variables: getResetPasswordTokenStatusQueryVariables,
  response: getResetPasswordTokenStatusQueryResponse,
|};
*/


/*
query getResetPasswordTokenStatusQuery(
  $resetToken: String!
  $uidb64: String!
) {
  resetPasswordTokenStatus(resetToken: $resetToken, uidb64: $uidb64)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "resetToken",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "uidb64",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "resetPasswordTokenStatus",
    "args": [
      {
        "kind": "Variable",
        "name": "resetToken",
        "variableName": "resetToken"
      },
      {
        "kind": "Variable",
        "name": "uidb64",
        "variableName": "uidb64"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getResetPasswordTokenStatusQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getResetPasswordTokenStatusQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getResetPasswordTokenStatusQuery",
    "id": null,
    "text": "query getResetPasswordTokenStatusQuery(\n  $resetToken: String!\n  $uidb64: String!\n) {\n  resetPasswordTokenStatus(resetToken: $resetToken, uidb64: $uidb64)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa3de00554b9668f02db891946627f39';
module.exports = node;

/**
 * @flow
 * @relayHash 3b1d09121929a69f1a94a252a78254bb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignUpEmailVerifiedQueryVariables = {|
  uidb64: string,
  orgToken: string,
|};
export type SignUpEmailVerifiedQueryResponse = {|
  +completeOrganizationSignUpTokenStatus: ?{|
    +tokenStatus: ?boolean,
    +errorMessage: ?string,
  |}
|};
export type SignUpEmailVerifiedQuery = {|
  variables: SignUpEmailVerifiedQueryVariables,
  response: SignUpEmailVerifiedQueryResponse,
|};
*/


/*
query SignUpEmailVerifiedQuery(
  $uidb64: String!
  $orgToken: String!
) {
  completeOrganizationSignUpTokenStatus(uidb64: $uidb64, orgToken: $orgToken) {
    tokenStatus
    errorMessage
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uidb64",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgToken",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "completeOrganizationSignUpTokenStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orgToken",
        "variableName": "orgToken"
      },
      {
        "kind": "Variable",
        "name": "uidb64",
        "variableName": "uidb64"
      }
    ],
    "concreteType": "CompleteOrganizationSignUpNode",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tokenStatus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "errorMessage",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SignUpEmailVerifiedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SignUpEmailVerifiedQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SignUpEmailVerifiedQuery",
    "id": null,
    "text": "query SignUpEmailVerifiedQuery(\n  $uidb64: String!\n  $orgToken: String!\n) {\n  completeOrganizationSignUpTokenStatus(uidb64: $uidb64, orgToken: $orgToken) {\n    tokenStatus\n    errorMessage\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '390122f52aaf5c5b4e2de5d708605ae8';
module.exports = node;

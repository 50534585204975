import React from 'react';
import clsx from 'clsx';
import { Icon } from '@material-ui/core';

import GlobalButton from '../UI/GlobalButton';
import StyledButtonGroup from '../UI/buttonGroup/StyledButtonGroup';
import { useFilterStyles } from '../../styles/common/filterStyles';
import GroupButtonSquare from '../UI/buttonGroup/GroupButtonSquare';

const FilterButton = props => {
  const { onClick, onClear, activeFilters = [] } = props;
  const numberOfActiveFilters = activeFilters.length;
  const areFiltersActive = numberOfActiveFilters > 0;
  const classes = useFilterStyles({ active: areFiltersActive });

  return (
    <StyledButtonGroup>
      <GlobalButton variant="secondary" noMargin handleClick={onClick}>
        <Icon className={clsx(classes.filterIcon, 'fas fa-filter')} />
        Filters {areFiltersActive && `(${numberOfActiveFilters})`}
      </GlobalButton>
      {areFiltersActive && (
        <GroupButtonSquare onClick={onClear} variant="secondary" icon="close" size={34} />
      )}
    </StyledButtonGroup>
  );
};
export default FilterButton;

/**
 * @flow
 * @relayHash d6c2643d608259f8595b1c434e14b4a8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DeliverableList_deliverables$ref = any;
type DeliverableList_stateTypes$ref = any;
export type DeliverableListPageQueryVariables = {|
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
  title?: ?string,
  state?: ?string,
  assignedStaff?: ?string,
  jobDeadlineFrom?: ?any,
  jobDeadlineTo?: ?any,
  organization?: ?string,
  includeClosed?: ?boolean,
  includeCompleted?: ?boolean,
  includeCanceled?: ?boolean,
|};
export type DeliverableListPageQueryResponse = {|
  +__type: ?{|
    +$fragmentRefs: DeliverableList_stateTypes$ref
  |},
  +$fragmentRefs: DeliverableList_deliverables$ref,
|};
export type DeliverableListPageQuery = {|
  variables: DeliverableListPageQueryVariables,
  response: DeliverableListPageQueryResponse,
|};
*/


/*
query DeliverableListPageQuery(
  $first: Int
  $after: String
  $orderBy: String
  $title: String
  $state: String
  $assignedStaff: ID
  $jobDeadlineFrom: Date
  $jobDeadlineTo: Date
  $organization: String
  $includeClosed: Boolean
  $includeCompleted: Boolean
  $includeCanceled: Boolean
) {
  ...DeliverableList_deliverables
  __type(name: "DeliverableState") {
    ...DeliverableList_stateTypes
  }
}

fragment DeliverableList_deliverables on Query {
  allDeliverables(first: $first, after: $after, orderBy: $orderBy, title: $title, state: $state, assignedStaff: $assignedStaff, jobDeadlineFrom: $jobDeadlineFrom, jobDeadlineTo: $jobDeadlineTo, organization: $organization, includeCompleted: $includeCompleted, includeClosed: $includeClosed, includeCanceled: $includeCanceled) {
    edges {
      cursor
      node {
        id
        deliverableId
        title
        state
        job {
          id
          contract {
            id
            contractFileUrl
            job {
              status
              id
            }
          }
          jobDeadline
          name
        }
        reviewStep
        assignedStaff {
          fullName
          representativeImageUrl
          organization {
            name
            representativeImageUrl
            id
          }
          id
        }
      }
    }
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}

fragment DeliverableList_stateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "state",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "assignedStaff",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadlineFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadlineTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "organization",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeClosed",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeCompleted",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeCanceled",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "DeliverableState"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeliverableListPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"DeliverableState\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeliverableList_stateTypes",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "DeliverableList_deliverables",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeliverableListPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allDeliverables",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "assignedStaff",
            "variableName": "assignedStaff"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "includeCanceled",
            "variableName": "includeCanceled"
          },
          {
            "kind": "Variable",
            "name": "includeClosed",
            "variableName": "includeClosed"
          },
          {
            "kind": "Variable",
            "name": "includeCompleted",
            "variableName": "includeCompleted"
          },
          {
            "kind": "Variable",
            "name": "jobDeadlineFrom",
            "variableName": "jobDeadlineFrom"
          },
          {
            "kind": "Variable",
            "name": "jobDeadlineTo",
            "variableName": "jobDeadlineTo"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          },
          {
            "kind": "Variable",
            "name": "organization",
            "variableName": "organization"
          },
          {
            "kind": "Variable",
            "name": "state",
            "variableName": "state"
          },
          {
            "kind": "Variable",
            "name": "title",
            "variableName": "title"
          }
        ],
        "concreteType": "DeliverableNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "deliverableId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "state",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "job",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "contractFileUrl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "job",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "JobNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "status",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "jobDeadline",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reviewStep",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "assignedStaff",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "organization",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrgNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPreviousPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"DeliverableState\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DeliverableListPageQuery",
    "id": null,
    "text": "query DeliverableListPageQuery(\n  $first: Int\n  $after: String\n  $orderBy: String\n  $title: String\n  $state: String\n  $assignedStaff: ID\n  $jobDeadlineFrom: Date\n  $jobDeadlineTo: Date\n  $organization: String\n  $includeClosed: Boolean\n  $includeCompleted: Boolean\n  $includeCanceled: Boolean\n) {\n  ...DeliverableList_deliverables\n  __type(name: \"DeliverableState\") {\n    ...DeliverableList_stateTypes\n  }\n}\n\nfragment DeliverableList_deliverables on Query {\n  allDeliverables(first: $first, after: $after, orderBy: $orderBy, title: $title, state: $state, assignedStaff: $assignedStaff, jobDeadlineFrom: $jobDeadlineFrom, jobDeadlineTo: $jobDeadlineTo, organization: $organization, includeCompleted: $includeCompleted, includeClosed: $includeClosed, includeCanceled: $includeCanceled) {\n    edges {\n      cursor\n      node {\n        id\n        deliverableId\n        title\n        state\n        job {\n          id\n          contract {\n            id\n            contractFileUrl\n            job {\n              status\n              id\n            }\n          }\n          jobDeadline\n          name\n        }\n        reviewStep\n        assignedStaff {\n          fullName\n          representativeImageUrl\n          organization {\n            name\n            representativeImageUrl\n            id\n          }\n          id\n        }\n      }\n    }\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n  }\n}\n\nfragment DeliverableList_stateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '06ef57c33255d35779033329fe687638';
module.exports = node;

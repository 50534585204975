/**
 * @flow
 * @relayHash 828cd19dfc8df8c274b7df1ee22c52a7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCommentInput = {|
  id: string,
  content?: ?string,
  rawContent?: ?string,
  extraData?: ?any,
  referenceIds?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type UpdateCommentMutationVariables = {|
  input: UpdateCommentInput
|};
export type UpdateCommentMutationResponse = {|
  +updateComment: ?{|
    +updatedComment: ?{|
      +id: string,
      +created: any,
      +content: string,
      +extraData: ?any,
      +objectId: number,
      +contentEditedAt: ?any,
      +user: ?{|
        +id: string,
        +fullName: ?string,
        +representativeImageUrl: ?string,
      |},
      +referenceFiles: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
        +description: ?string,
        +fileUrl: ?string,
        +reviewInterface: ?string,
      |}>,
      +reactions: ?$ReadOnlyArray<?{|
        +id: ?string,
        +definitionId: ?string,
        +name: ?string,
        +color: ?string,
        +number: ?number,
        +users: ?$ReadOnlyArray<?{|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |}>,
      |}>,
    |},
    +clientMutationId: ?string,
  |}
|};
export type UpdateCommentMutation = {|
  variables: UpdateCommentMutationVariables,
  response: UpdateCommentMutationResponse,
|};
*/


/*
mutation UpdateCommentMutation(
  $input: UpdateCommentInput!
) {
  updateComment(input: $input) {
    updatedComment {
      id
      created
      content
      extraData
      objectId
      contentEditedAt
      user {
        id
        fullName
        representativeImageUrl
      }
      referenceFiles {
        id
        title
        description
        fileUrl
        reviewInterface
      }
      reactions {
        id
        definitionId
        name
        color
        number
        users {
          id
          fullName
          representativeImageUrl
        }
      }
    }
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCommentInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateComment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCommentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedComment",
        "storageKey": null,
        "args": null,
        "concreteType": "CommentNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "content",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "extraData",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "objectId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "contentEditedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "referenceFiles",
            "storageKey": null,
            "args": null,
            "concreteType": "ReferenceNode",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fileUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "reviewInterface",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reactions",
            "storageKey": null,
            "args": null,
            "concreteType": "ReactionNode",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "definitionId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "color",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "users",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": true,
                "selections": (v2/*: any*/)
              }
            ]
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateCommentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateCommentMutation",
    "id": null,
    "text": "mutation UpdateCommentMutation(\n  $input: UpdateCommentInput!\n) {\n  updateComment(input: $input) {\n    updatedComment {\n      id\n      created\n      content\n      extraData\n      objectId\n      contentEditedAt\n      user {\n        id\n        fullName\n        representativeImageUrl\n      }\n      referenceFiles {\n        id\n        title\n        description\n        fileUrl\n        reviewInterface\n      }\n      reactions {\n        id\n        definitionId\n        name\n        color\n        number\n        users {\n          id\n          fullName\n          representativeImageUrl\n        }\n      }\n    }\n    clientMutationId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32c6fe7abebba01f8a88c5aae3b01c3d';
module.exports = node;

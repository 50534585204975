import { truncate, trimStart } from 'lodash';
import { differenceInCalendarDays, parse } from 'date-fns';
import { freelancerEnvironment, organizationEnvironment } from '../Environments';
import { getURLPrefix } from '../services/utilities';
import { FREELANCER_APP, ORGANIZATION_APP } from '../services/constants';
import { AUDIT_LOG_ACTIONS, DELIVERABLE_STATES } from '../constants';
import { formatDateForQueries } from './formatters';
import { mapReferenceFilesToRichTextJson } from '../components/richText';

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const formatCommentsNodeConnection = comments => {
  return comments.edges.map(comment => comment.node);
};

export const mapReviewComments = comments => {
  let newComments = [...comments];
  newComments.sort((a, b) => new Date(b.node.created) - new Date(a.node.created));
  return newComments.map(comment => {
    const {
      id: commentId,
      content,
      referenceFiles,
      created: dateCreated,
      revisionNumber,
      user: { id: userId, fullName, representativeImageUrl: avatarSrc },
      contentEditedAt,
      repliedTo,
      withMarkup,
      reactions
    } = comment.node;

    const extraData = comment.node.extraData ? JSON.parse(comment.node.extraData) : {};

    return {
      commentId,
      userId,
      fullName,
      avatarSrc,
      revisionNumber,
      content: mapReferenceFilesToRichTextJson(content, referenceFiles),
      referenceFiles,
      dateCreated,
      annotationRef: extraData.annotation_ref ? extraData.annotation_ref : null,
      wasCommentEdited: contentEditedAt ? true : false,
      repliedTo: repliedTo
        ? {
            ...repliedTo,
            content: mapReferenceFilesToRichTextJson(repliedTo.content, repliedTo.referenceFiles)
          }
        : null,
      withMarkup,
      reactions
    };
  });
};

export const mapContractorNotes = notes => {
  return notes.map(note => {
    const {
      id: commentId,
      content,
      created: dateCreated,
      staff: {
        representativeImageUrl: avatarSrc,
        user: { fullName, id: userId }
      },
      contentEditedAt,
      repliedTo
    } = note.node;

    return {
      commentId,
      userId,
      fullName,
      avatarSrc,
      dateCreated,
      content,
      wasCommentEdited: contentEditedAt ? true : false,
      repliedTo
    };
  });
};

export const mapJobComments = comments => {
  return comments.map(comment => {
    const {
      id: commentId,
      content,
      created: dateCreated,
      user: { id: userId, fullName, representativeImageUrl: avatarSrc },
      contentEditedAt,
      repliedTo,
      extraData,
      commentType
    } = comment.node;

    return {
      commentId,
      userId,
      fullName,
      avatarSrc,
      content,
      dateCreated,
      wasCommentEdited: contentEditedAt ? true : false,
      repliedTo,
      extraData,
      commentType
    };
  });
};

export const mapSolicitationComments = comments => {
  return comments.edges.map(comment => {
    const {
      id: commentId,
      content,
      created: dateCreated,
      contractor,
      createdByStaff,
      contentEditedAt,
      repliedTo
    } = comment.node;
    let avatarSrc, fullName, userId;
    if (createdByStaff) {
      avatarSrc = createdByStaff.representativeImageUrl;
      fullName = createdByStaff.fullName;
      userId = createdByStaff.user.id;
    } else {
      avatarSrc = contractor.representativeImageUrl;
      fullName = contractor.fullName;
      userId = contractor.freelancer && contractor.freelancer.user.id;
    }

    return {
      commentId,
      userId,
      fullName,
      avatarSrc,
      dateCreated,
      content,
      wasCommentEdited: contentEditedAt ? true : false,
      repliedTo
    };
  });
};

export const isPermissionDenied = errors => {
  return errors.find(error => error.code === 'PERMISSION_DENIED');
};

export const isUnauthorized = errors => {
  return errors.find(error => error.code === 'UNAUTHORIZED');
};

export const isNotFound = errors => {
  return errors.find(error => error.code === 'NOT_FOUND');
};

export const isNonCritical = errors => {
  const errorTypes = errors.reduce((prevValue, error) => {
    return prevValue.add(error.code);
  }, new Set());
  return errorTypes.size <= 1 && errorTypes.has('NON_CRITICAL_ERROR');
};

export const isLoginError = errors => {
  const errorTypes = errors.reduce((prevValue, error) => {
    return prevValue.add(error.code);
  }, new Set());
  return errorTypes.size <= 1 && errorTypes.has('LOGIN_ERROR');
};

export const isUIError = errors => {
  const errorTypes = errors.reduce((prevValue, error) => {
    return prevValue.add(error.code);
  }, new Set());
  return errorTypes.size <= 1 && errorTypes.has('UI_ERROR');
};

export const formatErrorToString = errors => {
  return errors
    .reduce((result, error) => {
      result.push(`${error.code ? error.code + ': ' : ''}${error.message}`);
      return result;
    }, [])
    .join(' ');
};

export const checkFileSizeTooBig = (fileSize, maxFileSize) => {
  return fileSize > maxFileSize;
};

export const getEnvironment = () => {
  if (window.__AWEBASE_RELAY_MOCK_ENV && process.env.NODE_ENV !== 'production') {
    return window.__AWEBASE_RELAY_MOCK_ENV;
  }

  const urlPrefix = getURLPrefix();

  switch (urlPrefix) {
    case ORGANIZATION_APP:
      return organizationEnvironment;
    case FREELANCER_APP:
      return freelancerEnvironment;
    default:
      break;
  }
};

export const formatAuditLogDescription = (action, changes, additionalData) => {
  // use to format description column in Tools Tab
  // if there is additional data, it takes priority over 'changes' field
  // if there is no additional data, we format JSON string 'changes' based on action type
  const parsedAdditionalData = JSON.parse(additionalData);
  if (parsedAdditionalData && parsedAdditionalData.action) {
    return parsedAdditionalData.description || '';
  }
  const parsedChanges = JSON.parse(changes);
  if (action === AUDIT_LOG_ACTIONS.create) {
    return Object.keys(parsedChanges)
      .map(key => {
        return `* ${key}: '${truncateString(parsedChanges[key][1], 140)}'`;
      })
      .join('\r\n');
  } else if (action === AUDIT_LOG_ACTIONS.update) {
    return Object.keys(parsedChanges)
      .map(key => {
        return `* ${key}: '${truncateString(parsedChanges[key][0], 140)}' => '${truncateString(
          parsedChanges[key][1],
          140
        )}'`;
      })
      .join('\r\n');
  } else {
    return '';
  }
};

export const formatAuditLogAction = (action, additionalData) => {
  // use to format Action column in Tools tabs
  // action in additionalData takes priority if one exists
  const parsedAdditionalData = JSON.parse(additionalData);
  if (parsedAdditionalData && parsedAdditionalData.action) {
    return parsedAdditionalData.action;
  }
  switch (action) {
    case AUDIT_LOG_ACTIONS.create:
      return 'Created';
    case AUDIT_LOG_ACTIONS.update:
      return 'Updated';
    case AUDIT_LOG_ACTIONS.delete:
      return 'Deleted';
    default:
      return '';
  }
};

export const truncateString = (value, length) => {
  return value ? truncate(value, { length }) : '-';
};

/**
 * Compute permission role with permission action list
 * @param action {string or array} action constant as JS string
 * @param allowed_actions {[String]}
 * @returns {boolean}
 */
export const computePermissionRole = (action, allowed_actions = []) => {
  if (typeof action === 'string') {
    return allowed_actions.includes(action);
  }
  if (Array.isArray(action)) {
    return allowed_actions.some(a => action.indexOf(a) !== -1);
  }
  return false;
};

export const trimEnumCharacters = value => {
  return value.split('A_')[1];
};

export const getTodayMidnight = () => {
  var d = new Date();
  d.setHours(0, 0, 0, 0);
  return d.toDateString();
};

export const isIsoDate = dateString => {
  return /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/.test(
    dateString
  );
};

const _parseStringToDate = date => {
  if (typeof date === 'string' && date.indexOf('T') !== -1) {
    date = date.substring(0, date.indexOf('T'));
  }
  if (typeof date === 'string') {
    date = parse(date, 'yyyy-MM-dd', new Date());
  }
  return date;
};

export const formatFilterValuesForQuery = filterValues => {
  const filters = { ...filterValues };
  for (let key in filters) {
    if (filters[key]) {
      if (typeof filters[key] === 'object' && filters[key].value) {
        // a workaround of SearchableFilter components storing both ID and label in state
        // don't want to refactor searchable filters, so added this check
        filters[key] = filters[key].value;
      } else if (typeof filters[key] === 'string' && filters[key].includes('A_')) {
        filters[key] = trimEnumCharacters(filters[key]);
      } else if (filters[key] instanceof Date) {
        let date = new Date(filters[key]);
        filters[key] = !isNaN(date) ? formatDateForQueries(date) : null;
      } else if (isIsoDate(filters[key])) {
        // dates in json object ('save as default' filters) are converted to ISO dateString, this catches
        // them and formats the date
        filters[key] = formatDateForQueries(filters[key]);
      } else if (typeof filters[key] === 'object' && filters[key].options) {
        filters[key] = filters[key].options.map(option => option.value).join(' ');
      }
    }
  }
  return filters;
};

export const getAssignedUserId = assignedStaff => (assignedStaff ? assignedStaff.user.id : null);

export const getSizeLabel = size =>
  `File size must be less than ${size} MB. If needed, your organization can change file size limitations in the Settings Menu.`;

export const getSizeInBytes = mb => mb * 1000 * 1000;

export const getFileExtension = fileName => fileName.split(/[#?]/)[0].split('.').pop().trim();

const getNumberOfDaysLeft = (date1, date2) => {
  return differenceInCalendarDays(_parseStringToDate(date2), _parseStringToDate(date1));
};

export const getActiveDeliverableDueDateWarningColor = (configuration, date, state) => {
  if (
    [
      DELIVERABLE_STATES.completed,
      DELIVERABLE_STATES.closed,
      DELIVERABLE_STATES.canceled,
      DELIVERABLE_STATES.inactive
    ].includes(state) ||
    !date
  ) {
    return null;
  }
  const dueDateWarnings = JSON.parse(configuration)['due_date_warnings']['deliverable'];
  const daysLeft = getNumberOfDaysLeft(new Date(), date);
  if (dueDateWarnings['past_date']['is_active'] && daysLeft <= 0) {
    return dueDateWarnings['past_date']['color'];
  }
  if (
    dueDateWarnings['before_date']['is_active'] &&
    dueDateWarnings['before_date']['number_of_days'] >= daysLeft
  ) {
    return dueDateWarnings['before_date']['color'];
  }
  return null;
};

export const getActiveReleaseDeadlineWarningColor = (
  configuration,
  date,
  hasIncompleteDeliverables
) => {
  if (!hasIncompleteDeliverables || !date) {
    return null;
  }
  const deadlineWarnings = JSON.parse(configuration)['due_date_warnings']['release'];
  const daysLeft = getNumberOfDaysLeft(new Date(), date);
  if (deadlineWarnings['past_date']['is_active'] && daysLeft <= 0) {
    return deadlineWarnings['past_date']['color'];
  }
  if (
    deadlineWarnings['before_date']['is_active'] &&
    deadlineWarnings['before_date']['number_of_days'] >= daysLeft
  ) {
    return deadlineWarnings['before_date']['color'];
  }
};

export const hexToRgb = hex =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));

export const getCurrencyCodeFromUserContext = userContext => {
  if (userContext.orgStaff) {
    return userContext.orgStaff.organization.currencyCode;
  } else {
    // for freelancers we have to use currency code for the organization of each deliverable or job individually
    // so this function cannot help with that
    return '';
  }
};

export const getDateDisplayFormatFromUserContext = userContext => {
  if (userContext && userContext.orgStaff) {
    return JSON.parse(userContext.orgStaff.organization.configuration)['date_display'];
  } else {
    // for freelancers we are going to use default formatter until we enable custom config for freelancers
    return 'MMM d, yyyy';
  }
};

export const getCommissionRateFromUserContext = userContext => {
  if (userContext && userContext.orgStaff) {
    return (
      parseFloat(
        JSON.parse(userContext.orgStaff.organization.configuration)['currency_exchange_commission']
      ) / 100
    );
  } else {
    return 0.0;
  }
};

export const getMiscFileMaxSizeFromUserContext = userContext => {
  if (userContext && userContext.orgStaff) {
    return JSON.parse(userContext.orgStaff.organization.configuration)['misc_file_size'];
  }
  return null;
};

export const getTimeFromAnnotationRef = annotationRef => {
  if (typeof annotationRef !== 'string') {
    return null;
  } else {
    if (annotationRef.includes('annotation-group-at/')) {
      return annotationRef.match(/\/(\d+\.?\d*)$/)[1];
    }
    const time = annotationRef.match(/\/video-/)
      ? annotationRef.match(/\/video-(\d+\.?\d*)$/)
      : annotationRef.match(/annotation-\d+-(\d+)/);
    return time ? time[1] : null;
  }
};

/**
 * Check if the app is running on the dev env
 */
export const isDevEnv = () => {
  return window.location.hostname !== process.env.REACT_APP_PROD_HOSTNAME;
};

/**
 * Check if the app is running on the prod env
 */
export const isProdEnv = () => {
  return window.location.hostname === process.env.REACT_APP_PROD_HOSTNAME;
};

export const getEnv = () => {
  return isProdEnv() ? 'prod' : 'dev';
};

/**
 * Check if canvas is blank
 */
// returns true if every pixel's uint32 representation is 0 (or "blank")
export const isCanvasBlank = canvas => {
  const context = canvas.getContext('2d');

  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
  );

  return !pixelBuffer.some(color => color !== 0);
};

/**
 * Calculates total amount from the list of deliverables
 * @param {array} deliverables
 */

export const calculateTotalAmount = (deliverables, userContext) => {
  const usersOrganizationCurrencyCode = getCurrencyCodeFromUserContext(userContext);

  if (!deliverables || deliverables.length === 0) {
    return {
      amount: '',
      currencyCode: usersOrganizationCurrencyCode
    };
  }

  return deliverables.reduce(
    (prev, { amount, currencyCode }) =>
      amount
        ? {
            amount: prev.amount + Number(amount),
            currencyCode
          }
        : { ...prev, currencyCode },
    {
      amount: 0,
      currencyCode: usersOrganizationCurrencyCode
    }
  );
};

export const formatCurrencyOption = ({ currencyCode, ...other }) => ({
  value: currencyCode,
  label: currencyCode,
  rest: {
    ...other
  }
});

export const formatFileUploadHelperString = (formats, maxSize) => {
  const formatsString = formats.reduce((string, format, index, array) => {
    let formatString = format;

    if (format === 'any') {
      return 'a';
    }

    if (formatString.includes('/')) {
      formatString = formatString.split('/')[1];
    }

    if (index === array.length - 2) {
      formatString = `${formatString} or`;
    }

    if (index < array.length - 2) {
      formatString = `${formatString},`;
    }

    return `${string} .${trimStart(formatString, '.')} `;
  }, 'a ');

  return `Select ${formatsString} file that is less than ${maxSize} MB.`;
};

export const formatReviewAssetAcceptedFilesArray = fileFormats =>
  fileFormats[0] === 'application/octet-stream' ? ['.glb'] : fileFormats;

export const getCountryName = country => (country ? country.name : null);

export const isPromise = result => result && typeof result.finally === 'function';

export const triggerFileDownload = elAnchorId => {
  const downloadAnchor = document.getElementById(elAnchorId);
  downloadAnchor.addEventListener('click', event => {
    event.stopPropagation();
  });
  downloadAnchor.click();
};

/**
 * @flow
 * @relayHash 20a97e98234b9ad2ec39182af8ae62c4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type ValidateDeliverablesForJobInput = {|
  deliverables: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type ValidateDeliverablesForJobMutationVariables = {|
  input: ValidateDeliverablesForJobInput
|};
export type ValidateDeliverablesForJobMutationResponse = {|
  +validateDeliverablesForJob: ?{|
    +isValidCategory: ?boolean,
    +isValidState: ?boolean,
    +isValidCurrency: ?boolean,
    +isValidStaff: ?boolean,
    +isValidBudget: ?boolean,
    +deliverables: ?$ReadOnlyArray<?{|
      +id: string,
      +title: string,
      +category: {|
        +id: string,
        +name: string,
      |},
      +amount: ?any,
      +dueDate: ?any,
      +currencyCode: ?DeliverableCurrencyCode,
      +amountInHomeCurrency: ?any,
      +assignedStaff: ?{|
        +id: string,
        +fullName: ?string,
        +representativeImageUrl: ?string,
      |},
      +state: DeliverableState,
    |}>,
  |}
|};
export type ValidateDeliverablesForJobMutation = {|
  variables: ValidateDeliverablesForJobMutationVariables,
  response: ValidateDeliverablesForJobMutationResponse,
|};
*/


/*
mutation ValidateDeliverablesForJobMutation(
  $input: ValidateDeliverablesForJobInput!
) {
  validateDeliverablesForJob(input: $input) {
    isValidCategory
    isValidState
    isValidCurrency
    isValidStaff
    isValidBudget
    deliverables {
      id
      title
      category {
        id
        name
      }
      amount
      dueDate
      currencyCode
      amountInHomeCurrency
      assignedStaff {
        id
        fullName
        representativeImageUrl
      }
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ValidateDeliverablesForJobInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "validateDeliverablesForJob",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ValidateDeliverablesForJobPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isValidCategory",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isValidState",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isValidCurrency",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isValidStaff",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isValidBudget",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dueDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currencyCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amountInHomeCurrency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignedStaff",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "representativeImageUrl",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ValidateDeliverablesForJobMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ValidateDeliverablesForJobMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ValidateDeliverablesForJobMutation",
    "id": null,
    "text": "mutation ValidateDeliverablesForJobMutation(\n  $input: ValidateDeliverablesForJobInput!\n) {\n  validateDeliverablesForJob(input: $input) {\n    isValidCategory\n    isValidState\n    isValidCurrency\n    isValidStaff\n    isValidBudget\n    deliverables {\n      id\n      title\n      category {\n        id\n        name\n      }\n      amount\n      dueDate\n      currencyCode\n      amountInHomeCurrency\n      assignedStaff {\n        id\n        fullName\n        representativeImageUrl\n      }\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e47384b6e13f54b70cdd4a024e9bce5f';
module.exports = node;

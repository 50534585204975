import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import BillingInvoices from '../BillingInvoices';

const BillingInvoicesRenderer = props => {
  return (
    <QueryRenderer
      query={graphql`
        query BillingInvoicesRendererQuery($first: Int, $orderBy: String, $after: String) {
          ...BillingInvoices_billing
          __type(name: "StripeInvoiceStatus") {
            ...BillingInvoices_stateTypes
          }
        }
      `}
      variables={{ orderBy: '-created' }}
      render={relayProps => (
        <BillingInvoices billing={relayProps} stateTypes={relayProps.__type} {...props} />
      )}
    />
  );
};

export default BillingInvoicesRenderer;

/**
 * @flow
 * @relayHash a5451f3127399d1472be60cb4339e0b6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePortfolioAssetInput = {|
  id: string,
  fileName?: ?string,
  description?: ?string,
  clientMutationId?: ?string,
|};
export type UpdatePortfolioAssetMutationVariables = {|
  input: UpdatePortfolioAssetInput
|};
export type UpdatePortfolioAssetMutationResponse = {|
  +updatePortfolioAsset: ?{|
    +clientMutationId: ?string,
    +updatedAsset: ?{|
      +description: ?string,
      +fileName: ?string,
    |},
  |}
|};
export type UpdatePortfolioAssetMutation = {|
  variables: UpdatePortfolioAssetMutationVariables,
  response: UpdatePortfolioAssetMutationResponse,
|};
*/


/*
mutation UpdatePortfolioAssetMutation(
  $input: UpdatePortfolioAssetInput!
) {
  updatePortfolioAsset(input: $input) {
    clientMutationId
    updatedAsset {
      description
      fileName
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdatePortfolioAssetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdatePortfolioAssetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatePortfolioAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePortfolioAssetPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "AssetNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdatePortfolioAssetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatePortfolioAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePortfolioAssetPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedAsset",
            "storageKey": null,
            "args": null,
            "concreteType": "AssetNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdatePortfolioAssetMutation",
    "id": null,
    "text": "mutation UpdatePortfolioAssetMutation(\n  $input: UpdatePortfolioAssetInput!\n) {\n  updatePortfolioAsset(input: $input) {\n    clientMutationId\n    updatedAsset {\n      description\n      fileName\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43e3ab367887d5a04b891ae21dc92229';
module.exports = node;

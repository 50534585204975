import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { ColorBadge } from '../../../organization/components/releases/ReleaseDetailsPage/ReleaseBudgetTab/styled';

const StyledName = styled(({ nameColor, noMargin, ...otherProps }) => (
  <Typography {...otherProps} />
))`
  color: ${props => props.nameColor}
`;

const StyledValue = styled(Typography).attrs(props => ({
  variant: props.variant || 'subtitle2',
}))`
  a {
    color: ${props => props.theme.palette.colors.darkBlueGrey}
  }
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  color: ${props => props.theme.palette.colors.darkBlueGrey};
  font-size: 14px;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.palette.primary.main};
    cursor: pointer
  }
`;

const Detail = ({
  name,
  nameIcon,
  value,
  badgeColor,
  renderValue,
  nameContainerProps: extendNameContainerProps,
  valueContainerProps,
  nameColor,
  link,
  ...otherProps
}) => {
  const isRow = otherProps.flexDirection === 'row';
  const nameContainerProps = {
    variant: 'overline',
    nameColor,
    ...extendNameContainerProps
  };

  return (
    <Box display="flex" {...otherProps}>
      <Box
        display="flex"
        alignItems="center"
        {...(isRow
          ? { mr: 1 }
          : {
              mb: nameContainerProps.noMargin
                ? 0
                : nameContainerProps.variant === 'overline'
                ? 0.5
                : 1.5
            })}
      >
        {badgeColor && <ColorBadge color={badgeColor} />}
        <StyledName {...nameContainerProps}>
          {name}
          {isRow && ':'}
        </StyledName>
        {nameIcon}
      </Box>
      <Box ml={badgeColor && '20px'}>
        {renderValue ? renderValue() : link ? <StyledLink to={link}>{value}</StyledLink> : <StyledValue {...valueContainerProps}>{value}</StyledValue>}
      </Box>
    </Box>
  );
};

Detail.propTypes = {
  renderValue: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func])
};

Detail.defaultProps = {
  value: '-',
  alignItems: 'center',
  flexDirection: 'row'
};

export default Detail;

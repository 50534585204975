import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReplyIcon from '@material-ui/icons/Reply';
import { ReactComponent as AnnotationIcon } from '../../../../images/annotation-icon.svg';
import SquareButton from '../../../UI/SquareButton';
import GlobalButton from '../../../UI/GlobalButton';
import CommentCard from '../CommentCard';

const ViewMarkupButton = styled(GlobalButton)`
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 0;
  white-space: nowrap;

  svg {
    width: 14px;
    margin-right: 8px;

    path {
      fill: #fff;
    }
  }
`;

const Comment = props => {
  const { onReply, onEdit, onDelete, onViewMarkup, ...cardProps } = props;
  const actions = [];

  if (onReply) {
    actions.push(<SquareButton variant="secondary" size={24} icon={ReplyIcon} onClick={onReply} />);
  }
  if (onEdit) {
    actions.push(<SquareButton onClick={onEdit} variant="secondary" size={24} icon="edit" />);
  }
  if (onViewMarkup) {
    actions.push(
      <ViewMarkupButton onClick={onViewMarkup} variant="primary">
        <AnnotationIcon />
        View Annotation
      </ViewMarkupButton>
    );
  }
  if (onDelete) {
    actions.push(<SquareButton onClick={onDelete} variant="secondary" size={24} icon="trash" />);
  }

  return <CommentCard {...cardProps} actions={actions} />;
};

Comment.propTypes = {
  onReply: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onViewMarkup: PropTypes.func
};

export default Comment;

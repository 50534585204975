/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CreateBudget_release$ref: FragmentReference;
declare export opaque type CreateBudget_release$fragmentType: CreateBudget_release$ref;
export type CreateBudget_release = {|
  +id: string,
  +name: string,
  +$refType: CreateBudget_release$ref,
|};
export type CreateBudget_release$data = CreateBudget_release;
export type CreateBudget_release$key = {
  +$data?: CreateBudget_release$data,
  +$fragmentRefs: CreateBudget_release$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CreateBudget_release",
  "type": "ReleaseNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ffc53b115cc8a17d73e08c98063a2b0f';
module.exports = node;

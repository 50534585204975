import React from 'react';

import FormikDropdownField from '../../../../shared/components/form/FormikDropdownField';

const ZipReviewInterfaceDropdown = props => {
  const items = [
    {
      id: 'generic',
      title: 'Generic'
    },
    {
      id: '3d_model',
      title: '3D Model'
    }
  ];

  return <FormikDropdownField {...props} items={items} />;
};

export default ZipReviewInterfaceDropdown;

import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export const Container = styled(props => <Grid container {...props} />)(({ theme }) => ({
  '& > *:first-child': {
    width: '350px'
  },
  '& > *:last-child': {
    marginLeft: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    borderLeft: `1px solid ${theme.palette.colors.lightestGrey}`
  }
}));

export const TableContainer = styled(props => <Grid container {...props} />)(({ theme }) => ({
  '& >:first-child': {
    width: '400px',
    paddingRight: theme.spacing(4)
  },
  '& >:last-child': {
    paddingTop: theme.spacing(2)
  }
}));

export const InputContainer = styled(Box)(() => ({
  width: '200px',
  '& .MuiInputBase-root': {
    width: '120px'
  }
}));

export const TableLoadingMessage = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingLeft: 0,
  fontWeight: 'bold'
}));

import React from 'react';
import { withTheme } from '@material-ui/styles';

import ColorButton from './ColorButton';
import ColorPicker from './ColorPicker';

const CircleColorPicker = withTheme(({ theme, disabled, ...otherProps }) => {
  const colorList = otherProps.colorList ? otherProps.colorList : [
    theme.palette.colors.annotationStroke,
    'blue',
    'green',
    'red',
    'yellow',
    'black',
    'grey',
    'white'
  ];
  return <ColorPicker
    {...otherProps}
    colors={colorList}
    renderButton={({ handleClick, color }) => (
      <ColorButton disabled={disabled} color={color} onClick={handleClick} />
    )}
  />
});

export default CircleColorPicker;

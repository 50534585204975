import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { CELL_TYPES } from '../../../../shared/constants';
import AutoTable from '../../../../shared/components/table/AutoTable';
import SearchableDeliverableDropdown from '../../jobs/SearchableDeliverableDropdown';

const DeliverableTab = props => {
  const {
    className,
    form: {
      values: { deliverables },
      setFieldValue
    }
  } = props;

  const [orderBy, setOrderBy] = useState('dueDate');

  const addToListHandler = selectedDeliverable => {
    if (selectedDeliverable) {
      const newDeliverableList = [...deliverables, selectedDeliverable];
      setFieldValue('deliverables', newDeliverableList.sort(dateSortCompareFunction));
    }
  };

  const removeFromListHandler = id => {
    setFieldValue(
      'deliverables',
      deliverables.filter(value => value.id !== id)
    );
  };

  const onChangeHandler = variables => {
    setOrderBy(variables.orderBy);
    setFieldValue('deliverables', deliverables.sort(dateSortCompareFunction));
  };

  const dateSortCompareFunction = (a, b) =>
    orderBy === 'dueDate'
      ? new Date(a.dueDate) - new Date(b.dueDate)
      : new Date(b.dueDate) - new Date(a.dueDate);

  const flattenedEdges = deliverables.map(deliverable => {
    const { id, title, category, amount, dueDate, currencyCode } = deliverable;

    return {
      node: {
        id,
        title,
        categoryName: category ? category.name : deliverable.categoryName,
        categoryId: category ? category.id : deliverable.categoryId,
        amount,
        currencyCode,
        dueDate: dueDate && typeof dueDate === 'object' ? dueDate.date : dueDate
      }
    };
  });

  const categoryIdOfDeliverablesInList = deliverables.length ? deliverables[0].category.id : '';

  return (
    <div className={className}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SearchableDeliverableDropdown
            name="solicitationDeliverables"
            selectChangeHandler={addToListHandler}
            placeholder="Add deliverable"
            fetchVariables={{
              category: categoryIdOfDeliverablesInList,
              state: '2',
              assignedToMeOnly: true
            }}
            itemsInList={deliverables}
            keyToReload={categoryIdOfDeliverablesInList + deliverables.length}
            value={null}
          />
        </Grid>
      </Grid>
      <AutoTable
        rowTemplate={[
          { name: 'title', label: 'Name' },
          { name: 'categoryName', label: 'Category', align: 'right' },
          { name: 'amount', label: 'Amount', type: CELL_TYPES.amount },
          { name: 'dueDate', label: 'Due Date', type: CELL_TYPES.date, sortable: true },
          {
            name: 'trash',
            label: '',
            type: CELL_TYPES.trash,
            onClick: removeFromListHandler,
            tooltip: 'Remove'
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        initialOrderBy="dueDate"
      />
    </div>
  );
};

export default DeliverableTab;

/**
 * @flow
 * @relayHash 2028c4cea355a2ab8ec0482f83f51138
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ToggleDeliverablesTagsInput = {|
  tags: $ReadOnlyArray<?string>,
  deliverables: $ReadOnlyArray<?string>,
  activate: boolean,
  clientMutationId?: ?string,
|};
export type BulkToggleTagsOnDeliverablesMutationVariables = {|
  input: ToggleDeliverablesTagsInput
|};
export type BulkToggleTagsOnDeliverablesMutationResponse = {|
  +toggleDeliverablesTags: ?{|
    +deliverables: ?$ReadOnlyArray<?{|
      +id: string
    |}>
  |}
|};
export type BulkToggleTagsOnDeliverablesMutation = {|
  variables: BulkToggleTagsOnDeliverablesMutationVariables,
  response: BulkToggleTagsOnDeliverablesMutationResponse,
|};
*/


/*
mutation BulkToggleTagsOnDeliverablesMutation(
  $input: ToggleDeliverablesTagsInput!
) {
  toggleDeliverablesTags(input: $input) {
    deliverables {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ToggleDeliverablesTagsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "toggleDeliverablesTags",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleDeliverablesTagsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BulkToggleTagsOnDeliverablesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BulkToggleTagsOnDeliverablesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BulkToggleTagsOnDeliverablesMutation",
    "id": null,
    "text": "mutation BulkToggleTagsOnDeliverablesMutation(\n  $input: ToggleDeliverablesTagsInput!\n) {\n  toggleDeliverablesTags(input: $input) {\n    deliverables {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '584575091d822ad3df9b1f15e83e5dd2';
module.exports = node;

/**
 * @flow
 * @relayHash c60273977ddf44db1e833919def8209a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TopBarOrganizationNotification_notifications$ref = any;
export type TopBarOrganizationNotificationRefetchQueryVariables = {|
  unread?: ?boolean,
  first: number,
  after?: ?string,
|};
export type TopBarOrganizationNotificationRefetchQueryResponse = {|
  +$fragmentRefs: TopBarOrganizationNotification_notifications$ref
|};
export type TopBarOrganizationNotificationRefetchQuery = {|
  variables: TopBarOrganizationNotificationRefetchQueryVariables,
  response: TopBarOrganizationNotificationRefetchQueryResponse,
|};
*/


/*
query TopBarOrganizationNotificationRefetchQuery(
  $unread: Boolean
  $first: Int!
  $after: String
) {
  ...TopBarOrganizationNotification_notifications_Fa6ey
}

fragment TopBarOrganizationNotification_notifications_Fa6ey on Query {
  notifications(unread: $unread, first: $first, after: $after) {
    totalCount
    edges {
      node {
        id
        unread
        timesince
        description
        actor {
          name
          representativeImageUrl
          actorType
        }
        deliverable {
          title
          release {
            name
            product {
              title
              id
            }
            id
          }
          id
        }
        extraData {
          targetRelayId
          actionRelayId
          content
          notificationType
          instanceType
          instanceTitle
          action
        }
        reviewAssetComment {
          id
        }
        reviewAsset {
          id
          revisionNumber
          deliverable {
            id
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "unread",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "unread",
    "variableName": "unread"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TopBarOrganizationNotificationRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "TopBarOrganizationNotification_notifications",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TopBarOrganizationNotificationRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notifications",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "NotificationNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NotificationNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "unread",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "timesince",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NotificationActorNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "representativeImageUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actorType",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "deliverable",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v2/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "extraData",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NotificationExtraData",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "targetRelayId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actionRelayId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "content",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "notificationType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "instanceType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "instanceTitle",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "action",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "reviewAssetComment",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNode",
                    "plural": false,
                    "selections": (v5/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "reviewAsset",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReviewAssetNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "revisionNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "deliverable",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": (v5/*: any*/)
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "notifications",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "TopBarOrganizationNotification_notifications",
        "filters": [
          "unread"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TopBarOrganizationNotificationRefetchQuery",
    "id": null,
    "text": "query TopBarOrganizationNotificationRefetchQuery(\n  $unread: Boolean\n  $first: Int!\n  $after: String\n) {\n  ...TopBarOrganizationNotification_notifications_Fa6ey\n}\n\nfragment TopBarOrganizationNotification_notifications_Fa6ey on Query {\n  notifications(unread: $unread, first: $first, after: $after) {\n    totalCount\n    edges {\n      node {\n        id\n        unread\n        timesince\n        description\n        actor {\n          name\n          representativeImageUrl\n          actorType\n        }\n        deliverable {\n          title\n          release {\n            name\n            product {\n              title\n              id\n            }\n            id\n          }\n          id\n        }\n        extraData {\n          targetRelayId\n          actionRelayId\n          content\n          notificationType\n          instanceType\n          instanceTitle\n          action\n        }\n        reviewAssetComment {\n          id\n        }\n        reviewAsset {\n          id\n          revisionNumber\n          deliverable {\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3943e0df0415ea4b3c2d995dbbbeedac';
module.exports = node;

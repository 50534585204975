/**
 * @flow
 * @relayHash f72ca2559d9ed900eff054d4f2077047
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveSolicitationDeliverableInput = {|
  solicitationId: string,
  deliverableId: string,
  clientMutationId?: ?string,
|};
export type RemoveSolicitationDeliverableMutationVariables = {|
  input: RemoveSolicitationDeliverableInput
|};
export type RemoveSolicitationDeliverableMutationResponse = {|
  +removeSolicitationDeliverable: ?{|
    +isUpdated: ?boolean,
    +solicitation: ?{|
      +id: string,
      +numberOfDeliverables: ?number,
      +totalDeliverableAmount: ?number,
      +numberOfContractedDeliverables: ?number,
      +numberOfAllocatedDeliverables: ?number,
    |},
  |}
|};
export type RemoveSolicitationDeliverableMutation = {|
  variables: RemoveSolicitationDeliverableMutationVariables,
  response: RemoveSolicitationDeliverableMutationResponse,
|};
*/


/*
mutation RemoveSolicitationDeliverableMutation(
  $input: RemoveSolicitationDeliverableInput!
) {
  removeSolicitationDeliverable(input: $input) {
    isUpdated
    solicitation {
      id
      numberOfDeliverables
      totalDeliverableAmount
      numberOfContractedDeliverables
      numberOfAllocatedDeliverables
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveSolicitationDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeSolicitationDeliverable",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveSolicitationDeliverablePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isUpdated",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": null,
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalDeliverableAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfContractedDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfAllocatedDeliverables",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RemoveSolicitationDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RemoveSolicitationDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RemoveSolicitationDeliverableMutation",
    "id": null,
    "text": "mutation RemoveSolicitationDeliverableMutation(\n  $input: RemoveSolicitationDeliverableInput!\n) {\n  removeSolicitationDeliverable(input: $input) {\n    isUpdated\n    solicitation {\n      id\n      numberOfDeliverables\n      totalDeliverableAmount\n      numberOfContractedDeliverables\n      numberOfAllocatedDeliverables\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dca6fc403106f2849be9381987cba772';
module.exports = node;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/common/colors';
import Avatar from '../common/Avatar';

const useStyles = makeStyles({
  greyBar: {
    backgroundColor: colors.lightBlueGrey,
    padding: '22px 25px',
    borderRadius: '5px',
    display: 'flex'
  },
  container: {
    display: 'flex',
    '& .avatar': {
      margin: '10px',
      marginLeft: '0'
    }
  },
  width50: {
    width: '50%'
  },
  containerColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  alignItemsCenterColumn: {
    justifyContent: 'center'
  },
  name: {
    color: colors.darkBlueGrey,
    fontSize: '14px',
    fontWeight: 'bold'
  },
  subtitle: {
    color: colors.blueGrey,
    fontSize: '10px',
    fontWeight: 'bold'
  }
});

const GreyBarWithContactInfo = props => {
  const classes = useStyles();
  const {
    fullName,
    representativeImageUrl,
    organizationName,
    organizationRepresentativeImageUrl,
    organizationCountry
  } = props;
  return (
    <div className={classes.greyBar}>
      <div className={clsx(classes.containerColumn, classes.width50)}>
        <span className={classes.name}>Contact</span>
        <div className={classes.container}>
          <Avatar size={60} alt={`picture of ${fullName}`} src={representativeImageUrl} />
          <div className={clsx(classes.containerColumn, classes.alignItemsCenterColumn)}>
            <span className={classes.name}>{`${fullName}`}</span>
            {/* TODO Change with the Staff title. */}
            <span className={classes.subtitle}>Staff</span>
          </div>
        </div>
      </div>
      <div className={clsx(classes.containerColumn, classes.width50)}>
        <span className={classes.name}>Organization</span>
        <div className={classes.container}>
          <Avatar
            size={60}
            alt={`picture of ${organizationName} `}
            src={organizationRepresentativeImageUrl}
            defaultIcon="organization"
          />
          <div className={clsx(classes.containerColumn, classes.alignItemsCenterColumn)}>
            <span className={classes.name}>{organizationName}</span>
            <span className={classes.subtitle}>{organizationCountry}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GreyBarWithContactInfo;

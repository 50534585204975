import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation RemoveSubscriptionModuleMutation($input: RemoveSubscriptionModuleInput!) {
    removeSubscriptionModule(input: $input) {
      isRemoved
      organization {
        subscription {
          numberOfModules
          nextBillingAmount
        }
        scheduledSubscription {
          activeModulesCount
          interval
          availableModules {
            moduleId
            quantity
          }
        }
        activeSubscription {
          availableModules {
            moduleId
            name
            incremental
            quantity
            cost {
              tier
              price
            }
            blurbText
            headline
            externalUrl
            description
            imageUrl
          }
        }
      }
    }
  }
`;

const RemoveSubscriptionModuleMutation = (module, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { module } },
    onCompleted: response => {
      callback(response.removeSubscriptionModule);
    },
    onError: err => console.error(err)
  });
};

export default RemoveSubscriptionModuleMutation;

RemoveSubscriptionModuleMutation.propTypes = {
  module: PropTypes.oneOf(['activity', 'data', 'saml', 'storage']).isRequired
};

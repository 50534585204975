import React, { Fragment, useState } from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Formik, Form, Field } from 'formik';

import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { yupSchemas } from '../../../../../shared/validations';
import StyledSwitch from '../../../../../shared/components/UI/StyledSwitch';
import EditableField from '../../../../../shared/components/form/fields/EditableField';
import Detail from '../../../../../shared/components/UI/Detail';
import { get } from 'lodash';
import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import UpdateOrganizationSamlConfigurationMutation from '../mutations/UpdateOrganizationSamlConfigurationMutation';

const FIELDS = [
  {
    name: 'idpEntityId',
    label: 'IDP Server Entity ID',
    fieldProps: { placeholder: 'Enter the server entity ID...' }
  },
  {
    name: 'idpUrl',
    label: 'IDP Server URL',
    fieldProps: { placeholder: 'Enter the server URL...' }
  },
  {
    name: 'idpCert',
    label: 'IDP Certificate',
    fieldProps: { placeholder: 'Enter the IDP certificate...', multiline: true, rows: '10' }
  },
  {
    name: 'idpIdentifier',
    label: 'Permanent Unique Identifier',
    fieldProps: { placeholder: 'Optional...' }
  }
];

const SamlForm = props => {
  const { organization } = props;
  const [isEditing, setEditing] = useState(false);

  const classes = useDetailsStyles({ isEditing });

  if (!organization) {
    return <div>Something went wrong!</div>;
  }
  const { samlConfiguration, hasSamlModule } = organization;

  const toggleEditHandler = resetForm => {
    if (typeof resetForm === 'function') {
      resetForm();
    }
    setEditing(!isEditing);
  };

  const submitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    const {
      samlEnabled,
      idpEntityId,
      idpUrl,
      idpCert: idpX509cert,
      idpIdentifier: idpAttrUserPermanentId
    } = values;
    UpdateOrganizationSamlConfigurationMutation(
      { samlEnabled, idpEntityId, idpUrl, idpX509cert, idpAttrUserPermanentId },
      response => {
        setSubmitting(false);
        toggleEditHandler();
      }
    );
  };

  const initialValues = {
    samlEnabled: get(samlConfiguration, 'samlEnabled', false),
    idpEntityId: get(samlConfiguration, 'idpEntityId', ''),
    idpUrl: get(samlConfiguration, 'idpUrl', ''),
    idpCert: get(samlConfiguration, 'idpX509cert', ''),
    idpIdentifier: get(samlConfiguration, 'idpAttrUserPermanentId', '')
  };
  const validationSchema = {
    idpEntityId: yupSchemas.string(true),
    idpUrl: yupSchemas.string(true),
    idpCert: yupSchemas.string(true),
    idpIdentifier: yupSchemas.string(false, { max: 50 })
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {({ resetForm, isSubmitting, values, setFieldValue }) => (
        <Form>
          <Fragment>
            {hasSamlModule && (
              <div className={classes.containerJustifyFlexEnd}>
                <EditToggle
                  isEditing={isEditing}
                  saveProps={{ id: 'saveSaml' }}
                  editProps={{ id: 'editSaml', onClick: toggleEditHandler }}
                  cancelProps={{
                    id: 'cancelSamlEdit',
                    onClick: () => toggleEditHandler(resetForm)
                  }}
                  isSubmitting={isSubmitting}
                />
              </div>
            )}
            <Typography variant="h3" style={{ marginBottom: '30px' }}>
              SAML Settings
            </Typography>
            {!hasSamlModule && (
              <Typography style={{ marginBottom: '30px' }}>
                Purchase the SAML module to begin using single sign on!
              </Typography>
            )}
            <Grid container spacing={3}>
              <Grid container item>
                <Grid item xs={4}>
                  {isEditing ? (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography>Enable SAML SSO </Typography>
                      <StyledSwitch
                        checked={values.samlEnabled}
                        onChange={e => setFieldValue('samlEnabled', e.target.checked)}
                      />
                    </Box>
                  ) : (
                    <Detail
                      flexDirection="column"
                      value={values.samlEnabled ? 'Enabled' : 'Not enabled'}
                      name="SAML Enabled"
                      alignItems="left"
                      valueContainerProps={{ variant: 'body1' }}
                    />
                  )}
                </Grid>
              </Grid>
              {FIELDS.map(field => (
                <Grid container item key={field.name}>
                  <EditableField
                    gridItemProps={{ xs: 4 }}
                    {...field}
                    editing={isEditing}
                    formikValues={values}
                    fieldProps={{ ...field.fieldProps, fullWidth: true }}
                    renderDetail={value =>
                      field.name === 'idpCert' ? (
                        <Field
                          component={FormikTextField}
                          disabled
                          rows="10"
                          className={classes.disabledInputField}
                          label={field.label}
                          name={field.name}
                          fullWidth
                          {...field.fieldProps}
                        />
                      ) : (
                        <Detail
                          name={field.label}
                          flexDirection="column"
                          alignItems="left"
                          valueContainerProps={{ variant: 'body1' }}
                          value={value || '-'}
                        />
                      )
                    }
                  />
                </Grid>
              ))}
              {!isEditing && samlConfiguration && (
                <Grid container item xs={4}>
                  <Link
                    id="downloadMetadata"
                    href={samlConfiguration.metadataUrl}
                    target="_blank"
                    download="Awebase metadata"
                    rel="noopener noreferrer"
                    variant="caption"
                  >
                    Download Awebase metadata
                  </Link>
                </Grid>
              )}
            </Grid>
          </Fragment>
        </Form>
      )}
    </Formik>
  );
};

export default createFragmentContainer(SamlForm, {
  organization: graphql`
    fragment SamlForm_organization on OrganizationNode {
      hasSamlModule
      samlConfiguration {
        samlEnabled
        idpEntityId
        idpUrl
        idpX509cert
        idpAttrUserPermanentId
        metadataUrl
      }
    }
  `
});

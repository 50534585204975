import React, {useState} from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import SelectField from '../../../form/fields/SelectField';
import { Checkbox } from '@material-ui/core';
import { Slider as MuiSlider } from '@material-ui/core';
import GlobalButton from '../../../UI/GlobalButton';
import UpdateReviewAssetSettingsMutation from '../../../../mutations/review/UpdateReviewAssetSettingsMutation';
import { successToast } from '../../../../toasts';

const environmentsOptions = [
    { value: 0, name: 'Studio' },
    { value: 1, name: 'Cloudy' },
    { value: 2, name: 'Sunny' },
    { value: 3, name: 'Dawn' }
  ];

const SceneSettings = ({src, settings, assetId, settingsChanged, disabled}) =>  {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lightIntensity, setLightIntensity] = useState(settings.lightIntensity);
  const [flatShading, setFlatShading] = useState(settings.flatShading);
  const [environment, setEnvironment] = useState(settings.environment);
  
  
  const handleChangeFlatShading = (event) => {
    setFlatShading(event.target.checked);
    const newSettings = {
        ...settings,
        flatShading: event.target.checked
    }
    settingsChanged(newSettings);
  };

  const handleLightIntensityChanged = (ev, value) => {
      ev.preventDefault();
      setLightIntensity(value);
      const newSettings = {
        ...settings,
        lightIntensity: value
    }
    settingsChanged(newSettings);
  }

  const handleEnvironmentChange = (ev) => {
      setEnvironment(ev.target.value);

      const newSettings = {
        ...settings,
        environment: ev.target.value
      }
      settingsChanged(newSettings);
  };

  const handleSave = () => {
    // send the mutation to the server.

    const newSettings = {
      ...settings,
      environment: environment
    }

    const settingsJson = JSON.stringify(newSettings)
    UpdateReviewAssetSettingsMutation(
      {
        assetId, 
        settings: settingsJson
      }, 
      response => {
        if (response) {
          setTimeout(() => {
            successToast(`Scene Settings Saved`);
          }, 250);
        }
      });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const controlStyle = {
      padding: 10
  }

  return (
    <div>
      <GlobalButton
        aria-describedby={id}
        color="primary"
        handleClick={handleClick}
        disabled={disabled}
      >
        Scene Settings
      </GlobalButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
          <div style={{width: 300}}>
            <div style={controlStyle}>
            <Typography variant="body2" >
              Flat Shading:
            </Typography>
            
              <Checkbox
                checked={flatShading}
                onChange={handleChangeFlatShading}
                name="checkFlatShading"
                color="primary"
                label="Flat Shading"
              />
            </div>
            <div style={controlStyle}>
            <Typography variant="body2" style={{ marginRight: '16px' }}>
              Environment:
            </Typography>
            
              <SelectField
                name="environment"
                value={environment}
                onChange={handleEnvironmentChange}
                options={environmentsOptions}
              />
            </div>
            <div style={controlStyle}>
            <Typography variant="body2" >
              Light:
            </Typography>
            
            
            
            <MuiSlider
                min={0}
                max={1}
                step={0.05}
                value={lightIntensity}
                onChange={handleLightIntensityChanged}
            />
            </div>
            <div style={controlStyle}>
              <GlobalButton handleClick={handleSave} className="primary">
                  Save
              </GlobalButton>
            </div>
            </div>
      </Popover>
    </div>
  );
}

export default SceneSettings;
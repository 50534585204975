import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

export default callback => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation ObtainJSONWebTokenMutation($input: ObtainJSONWebTokenInput!) {
        obtainJsonWebToken(input: $input) {
          userType
          token
          refreshToken
        }
      }
    `,
    variables: {
      input: {}
    },
    onCompleted: callback,
    onError: err => console.error(err)
  });
};

/**
 * @flow
 * @relayHash 12f12d263bada71373ee7e00b3c953b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SendContractReminderToContractorInput = {|
  jobId: string,
  clientMutationId?: ?string,
|};
export type SendReminderToContractorMutationVariables = {|
  input: SendContractReminderToContractorInput
|};
export type SendReminderToContractorMutationResponse = {|
  +sendContractReminderToContractor: ?{|
    +emailSent: ?boolean
  |}
|};
export type SendReminderToContractorMutation = {|
  variables: SendReminderToContractorMutationVariables,
  response: SendReminderToContractorMutationResponse,
|};
*/


/*
mutation SendReminderToContractorMutation(
  $input: SendContractReminderToContractorInput!
) {
  sendContractReminderToContractor(input: $input) {
    emailSent
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendContractReminderToContractorInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sendContractReminderToContractor",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendContractReminderToContractorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailSent",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SendReminderToContractorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SendReminderToContractorMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SendReminderToContractorMutation",
    "id": null,
    "text": "mutation SendReminderToContractorMutation(\n  $input: SendContractReminderToContractorInput!\n) {\n  sendContractReminderToContractor(input: $input) {\n    emailSent\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9dd76a3157c381b851f1367cdc312871';
module.exports = node;

import React, { useState, useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import ContractorDetailsTab from './ContractorDetailsTab';
import ContractorJobsTab from './ContractorJobsTab';
import ContractorDeliverablesTab from './ContractorDeliverablesTab';
import ContractorToolsTab from './ContractorToolsTab';
import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import { ALLOWED_ACTIONS } from '../../../../shared/constants';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import { successToast } from '../../../../shared/toasts';
import { formatDateDisplay } from '../../../../shared/utils/formatters';
import { yupSchemas } from '../../../../shared/validations';
import { ReactComponent as ContractorsIcon } from '../../../../shared/images/contractors.svg';
import {
  computePermissionRole,
  getDateDisplayFormatFromUserContext
} from '../../../../shared/utils/helpers';
import UpdateContractorMutation from '../mutations/UpdateContractorMutation';
import SendInvitationToContractorMutation from '../mutations/SendInvitationToContractorMutation';
import useDialog from '../../../../shared/hooks/useDialog';
import Dialog from '../../../../shared/components/common/Dialog';
import InvitationResendDialog from './InvitationResendDialog';
import InvitationForm from './InvitationForm';
import ContractorPortfolioTab from './ContractorPortfolioTab';
import ContractorShowcaseTab from './ContractorShowcaseTab';

const ContractorDetails = props => {
  const { history, userContext, contractor, refetchCounter } = props;
  const {
    orgStaff: { allowedActions }
  } = userContext;
  const classes = useDetailsStyles();

  const [isEditing, setEditing] = useState(false);
  const [isEditAllowed, setEditAllowed] = useState(true);
  const [refetchCounterForTools, setRefetchCounterForTools] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const [isInviteDialogOpen, setInviteDialogOpen] = useDialog(false);
  const [messageText, setMessageText] = useState('');

  useEffect(() => {
    setEditAllowed(history.location.pathname.includes('details'));
  }, [history.location.pathname]);

  const toggleEdit = () => {
    setEditing(!isEditing);
  };

  const sendInviteToConnect = () => {
    setSubmitting(true);
    SendInvitationToContractorMutation(contractor.id, messageText, response => {
      setSubmitting(false);
      if (response && response.emailSent) {
        setRefetchCounterForTools(prev => prev + 1);
        successToast('Invitation email was sent!');
        setMessageText('');
        setInviteDialogOpen(false);
      }
    });
  };

  const closeInviteDialog = () => {
    setMessageText('');
    setInviteDialogOpen(false);
  };

  const handleFinalSubmit = (values, setFieldError) => {
    setSubmitting(true);
    const {
      freelancer,
      firstName,
      lastName,
      nickname,
      title,
      email,
      portfolioUrl,
      phone,
      timezone,
      internalId,
      companyName,
      tags,
      address1,
      address2,
      city,
      country,
      state,
      zipcode,
      resendInvitation
    } = values;

    UpdateContractorMutation(
      {
        id: contractor.id,
        ...(!freelancer && {
          firstName,
          lastName,
          nickname,
          title,
          email,
          phone,
          timezone,
          companyName,
          address1,
          address2,
          city,
          state,
          zipcode,
          portfolioUrl,
          country: country ? country.value : '',
          resendInvitation: resendInvitation === 'resend'
        }),
        internalId,
        tags: tags.options.map(option => option.label)
      },
      (response, errors) => {
        setSubmitting(false);
        if (isDialogOpen) {
          toggleDialogOpen();
        }
        if (errors && errors[0].fields && errors[0].fields.email) {
          setFieldError('email', errors[0].fields.email);
        } else {
          toggleEdit();
        }
      }
    );
  };

  const canUserEdit =
    isEditAllowed && computePermissionRole(ALLOWED_ACTIONS.CONTRACTOR_EDIT_DETAILS, allowedActions);

  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      firstName: contractor.firstName ? contractor.firstName : '',
      lastName: contractor.lastName ? contractor.lastName : '',
      nickname: contractor.nickname ? contractor.nickname : '',
      title: contractor.title ? contractor.title : '',
      email: contractor.email ? contractor.email : '',
      phone: contractor.phone ? contractor.phone : '',
      timezone: contractor.timezone ? contractor.timezone : '',
      timezoneList: contractor.timezoneList ? contractor.timezoneList : [],
      companyName: contractor.companyName ? contractor.companyName : '',
      portfolioUrl: contractor.portfolioUrl ? contractor.portfolioUrl : '',
      internalId: contractor.internalId ? contractor.internalId : '',
      tags: {
        options: contractor.tags
          ? contractor.tags.edges.map(edge => ({ value: edge.node.id, label: edge.node.name }))
          : []
      },
      address1: contractor.address1 ? contractor.address1 : '',
      address2: contractor.address2 ? contractor.address2 : '',
      city: contractor.city ? contractor.city : '',
      state: contractor.state ? contractor.state : '',
      country: contractor.country
        ? { value: contractor.country.code, label: contractor.country.name }
        : null,
      zipcode: contractor.zipcode ? contractor.zipcode : '',
      // only to be used in conditional validation so we can skip some fields that are not editable when freelancer is not null
      freelancer: contractor.freelancer && contractor.freelancer.id ? true : false,
      resendInvitation: 'resend'
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().when('freelancer', {
        is: false,
        then: yupSchemas.string(true, { max: 50 })
      }),
      lastName: Yup.string().when('freelancer', {
        is: false,
        then: yupSchemas.string(true, { max: 50 })
      }),
      title: Yup.string().when('freelancer', {
        is: false,
        then: yupSchemas.string(false, { max: 100 })
      }),
      companyName: Yup.string().when('freelancer', {
        is: false,
        then: yupSchemas.string()
      }),
      phone: Yup.string().when('freelancer', {
        is: false,
        then: yupSchemas.phone()
      }),
      internalId: yupSchemas.string(false, { max: 100 }).nullable(),
      address1: yupSchemas.string().nullable(),
      address2: yupSchemas.string().nullable(),
      city: yupSchemas.string().nullable(),
      country: yupSchemas.country(),
      zipcode: yupSchemas.string(false, { max: 10 }).nullable(),
      email: Yup.string().when('freelancer', {
        is: false,
        then: yupSchemas.email(true)
      }),
      tags: Yup.array().ensure(),
      portfolioUrl: Yup.string().when('freelancer', {
        is: false,
        then: yupSchemas.url()
      })
    }),
    onSubmit: (values, { setFieldError }) => {
      if (values.email !== contractor.email && contractor.invitationEmailSent) {
        // email was updated but there is invitation send to old email
        toggleDialogOpen();
      } else {
        handleFinalSubmit(values, setFieldError);
      }
    }
  };

  return (
    <Formik {...formikProps}>
      {({ values, setFieldValue, setValues, setFieldError, resetForm }) => (
        <Form>
          <div className={classes.contractorsTopSectionHeight}>
            <div className={classes.containerSpaceBetween}>
              <CategoryTitleHeader
                title={contractor.fullName}
                icon={ContractorsIcon}
                link="/contractors"
              />
              <div className={classes.containerJustifyFlexEnd}>
                {!contractor.freelancer && !contractor.conflictingContractor && (
                  <div className={classes.containerColumn}>
                    <GlobalButton
                      id="sendInvite"
                      variant="primary"
                      handleClick={() => setInviteDialogOpen(true)}
                      disabled={isSubmitting}
                    >
                      Invite to AWEbase
                    </GlobalButton>
                    {contractor.invitationEmailSent && (
                      <Typography variant="overline" style={{ margin: '8px 8px 0' }}>
                        Last Invited:{' '}
                        {formatDateDisplay(
                          contractor.invitationEmailSent,
                          getDateDisplayFormatFromUserContext(userContext)
                        )}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <AutoTabList onTabChange={value => setEditAllowed(value === 0)}>
            <ContractorDetailsTab
              setValues={setValues}
              setFieldValue={setFieldValue}
              label="Details"
              isEditing={isEditing}
              toggleEdit={toggleEdit}
              canUserEdit={canUserEdit}
              values={values}
              isSubmitting={isSubmitting}
              resetForm={resetForm}
            />
            <ContractorJobsTab refetchCounter={refetchCounter} label="Jobs" disabled={isEditing} />
            <ContractorDeliverablesTab
              refetchCounter={refetchCounter}
              label="Deliverables"
              disabled={isEditing}
            />
            <ContractorShowcaseTab label="Showcase" disabled={isEditing} />
            <ContractorPortfolioTab label="Portfolio" disabled={isEditing} />
            <ContractorToolsTab
              label="Tools"
              disabled={isEditing}
              allowedActions={allowedActions}
              refetchCounter={props.refetchCounter}
              refetchCounterForTools={refetchCounterForTools}
              setRefetchCounterForTools={setRefetchCounterForTools}
            />
          </AutoTabList>
          <Dialog
            isDialogOpen={isDialogOpen}
            closeDialog={toggleDialogOpen}
            title="This contractor has a pending invitation"
            maxWidth={660}
          >
            <InvitationResendDialog
              handleClose={toggleDialogOpen}
              setFieldValue={setFieldValue}
              handleSubmit={() => handleFinalSubmit(values, setFieldError)}
              isSubmitting={isSubmitting}
            />
          </Dialog>
          {isInviteDialogOpen && (
            <Dialog
              isDialogOpen={true}
              title="Invite to AWEbase"
              closeDialog={closeInviteDialog}
              minWidth={500}
            >
              <InvitationForm
                formTitleText="Add custom message (optional)"
                isSubmitting={isSubmitting}
                handleAddMessage={sendInviteToConnect}
                messageText={messageText}
                handleSetMessage={e => setMessageText(e.target.value)}
              />
            </Dialog>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(
  withUserContext(
    createFragmentContainer(ContractorDetails, {
      contractor: graphql`
        fragment ContractorDetails_contractor on ContractorNode {
          id
          doNotHire
          isActive
          firstName
          lastName
          fullName
          nickname
          title
          email
          phone
          timezone
          internalId
          companyName
          portfolioUrl
          address1
          address2
          city
          state
          zipcode
          country {
            name
            code
          }
          invitationEmailSent
          tags {
            edges {
              node {
                id
                name
              }
            }
          }
          freelancer {
            id
          }
          conflictingContractor {
            id
          }
          invitationEmailSent
          coverImageUrl
        }
      `
    })
  )
);

import React from 'react';

import { Grid } from '@material-ui/core';

import FilterDebounceInput from '../../../../../shared/components/filters/FilterDebounceInput';
import FilterCheckbox from '../../../../../shared/components/filters/FilterCheckbox';
import FilterSelect from '../../../../../shared/components/filters/FilterSelect';
import { useFilterStyles } from '../../../../../shared/styles/common/filterStyles';

const SolicitationContractorListFilters = props => {
  const { filterValues, onFilterChange } = props;
  const classes = useFilterStyles();

  const statusOptions = [
    { value: 'not_invited', name: 'Not invited' },
    { value: 'pending', name: 'Pending' },
    { value: 'joined', name: 'Joined' }
  ];

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          id="contractorNameFilter"
          value={filterValues['contractorName']}
          onChange={onFilterChange}
          name="contractorName"
          label="Contractor Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          id="filterByInviteStatus"
          name="inviteStatus"
          onChange={onFilterChange}
          value={filterValues['inviteStatus']}
          label="Invite Status"
          options={statusOptions}
        />
      </Grid>
      <Grid container item xs={2} alignContent="flex-start" style={{ marginTop: '1rem' }}>
        <Grid item xs={12}>
          <FilterCheckbox
            id="includeDoNotHireFilter"
            onChange={onFilterChange}
            name="includeDoNotHire"
            value={filterValues['includeDoNotHire']}
            label="Include 'Do not hire'"
          />
        </Grid>
        <Grid item xs={12}>
          <FilterCheckbox
            id="includeInactiveFilter"
            onChange={onFilterChange}
            name="includeInactive"
            value={filterValues['includeInactive']}
            label="Include Inactive"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SolicitationContractorListFilters;

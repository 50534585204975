import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import JobDetailsTab from '../tabs/JobDetailsTab';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';

const JobDetailsTabRenderer = props => {
  const { id } = useParams();
  return (
    <QueryRenderer
      query={graphql`
        query JobDetailsTabRendererQuery($id: ID!) {
          job(id: $id) {
            ...JobDetailsTab_job
            ...JobCommentThread_job
          }
          jobState: __type(name: "JobStatus") {
            ...JobDetailsTab_stateTypes
          }
        }
      `}
      variables={{ id }}
      render={relayProps => (
        <JobDetailsTab
          job={relayProps.job}
          stateTypes={relayProps.jobState}
          relayProps={relayProps}
          {...props}
        />
      )}
    />
  );
};

export default JobDetailsTabRenderer;

import React from 'react';
import PropTypes from 'prop-types';

import GlobalButton from '../../components/UI/GlobalButton';
import { Icon } from '@material-ui/core';

import useReviewStepProgressStyles from '../../styles/deliverable/useReviewStepProgressStyles';
import withAppType from '../../hoc/withAppType';

const ReviewStepProgress = props => {
  const {
    stepList,
    currentStep,
    handleStepClicked,
    canChangeReviewStepInReviewTab,
    isOrganization
  } = props;

  const classes = useReviewStepProgressStyles();

  return (
    <div className={classes.root}>
      {stepList.map((step, idx) => (
        <div key={step.id} className={classes.step}>
          <GlobalButton
            id={`reviewStep-${step.id}`}
            handleClick={event => handleStepClicked(step.id, event)}
            variant={step.id !== currentStep.id ? 'secondary' : 'highlighted'}
            disabled={!canChangeReviewStepInReviewTab}
            disabledWithoutStyling={!isOrganization}
          >
            {step.name}
          </GlobalButton>
          {idx !== stepList.length - 1 ? <Icon className={'fas fa-chevron-right'} /> : null}
        </div>
      ))}
    </div>
  );
};

ReviewStepProgress.propTypes = {
  /** Array[string] to indicate all deliverable steps from beginning to end */
  stepList: PropTypes.array.isRequired,
  /** Current step string from stepList PropType */
  currentStep: PropTypes.object.isRequired,
  /** Callback function when step button is clicked. */
  handleStepClicked: PropTypes.func.isRequired
};

ReviewStepProgress.defaultProps = {
  stepList: [],
  currentStepId: '',
  handleStepClicked: () => {}
};

export default withAppType(ReviewStepProgress);

import React from 'react';
import styled from 'styled-components';
import { Typography, Box } from '@material-ui/core';
import CommentCard from './CommentCard';

export const EmptyCommentList = () => (
  <Box textAlign="center" pt={2}>
    <Typography variant="subtitle1">There are no comments.</Typography>
  </Box>
);

const CommentListContainer = styled.div`
  ${CommentCard} {
    padding: 12px 12px 24px 12px;
    margin-bottom: 12px;
  }
`;

export default CommentListContainer;

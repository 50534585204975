import React from 'react';
import UserContext from './userContext';

const UserProvider = ({ children, userInfo, setUserInfo }) => {
  return (
    <UserContext.Provider value={{ ...userInfo, setUserInfo }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation RemoveSolicitationContractorMutation($input: RemoveSolicitationContractorInput!) {
    removeSolicitationContractor(input: $input) {
      isUpdated
      solicitation {
        id
        numberOfContractors
      }
    }
  }
`;

const RemoveSolicitationContractorMutation = (solicitationId, contractorId, callback) => {
  const removeSolicitationContractorVariables = {
    input: { solicitationId, contractorId }
  };

  const config = {
    mutation,
    variables: removeSolicitationContractorVariables,
    onCompleted: response => {
      callback(response.removeSolicitationContractor);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default RemoveSolicitationContractorMutation;

RemoveSolicitationContractorMutation.propTypes = {
  solicitationId: PropTypes.string.isRequired,
  contractorId: PropTypes.string.isRequired
};

/**
 * @flow
 * @relayHash cf862e3cc71e652a4e3f2054649f8a87
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
export type AddSolicitationCommentInput = {|
  solicitationId: string,
  contractorId?: ?string,
  content: string,
  repliedTo?: ?string,
  clientMutationId?: ?string,
|};
export type AddSolicitationCommentMutationVariables = {|
  input: AddSolicitationCommentInput
|};
export type AddSolicitationCommentMutationResponse = {|
  +addSolicitationComment: ?{|
    +isUpdated: ?boolean,
    +solicitation: ?{|
      +state: SolicitationState,
      +comments: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +content: ?string,
            +created: any,
            +contentEditedAt: ?any,
            +repliedTo: ?{|
              +content: ?string,
              +created: ?any,
              +user: ?{|
                +fullName: ?string
              |},
            |},
            +contractor: {|
              +fullName: ?string,
              +representativeImageUrl: ?string,
              +freelancer: ?{|
                +user: {|
                  +id: string
                |}
              |},
            |},
            +createdByStaff: ?{|
              +fullName: ?string,
              +representativeImageUrl: ?string,
              +user: {|
                +id: string
              |},
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type AddSolicitationCommentMutation = {|
  variables: AddSolicitationCommentMutationVariables,
  response: AddSolicitationCommentMutationResponse,
|};
*/

/*
mutation AddSolicitationCommentMutation(
  $input: AddSolicitationCommentInput!
) {
  addSolicitationComment(input: $input) {
    isUpdated
    solicitation {
      state
      comments {
        edges {
          node {
            id
            content
            created
            contentEditedAt
            repliedTo {
              content
              created
              user {
                fullName
                id
              }
            }
            contractor {
              fullName
              representativeImageUrl
              freelancer {
                user {
                  id
                }
                id
              }
              id
            }
            createdByStaff {
              fullName
              representativeImageUrl
              user {
                id
              }
              id
            }
          }
        }
      }
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'AddSolicitationCommentInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'input',
        variableName: 'input'
      }
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'isUpdated',
      args: null,
      storageKey: null
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'state',
      args: null,
      storageKey: null
    },
    v4 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'content',
      args: null,
      storageKey: null
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'created',
      args: null,
      storageKey: null
    },
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'contentEditedAt',
      args: null,
      storageKey: null
    },
    v8 = {
      kind: 'ScalarField',
      alias: null,
      name: 'fullName',
      args: null,
      storageKey: null
    },
    v9 = {
      kind: 'ScalarField',
      alias: null,
      name: 'representativeImageUrl',
      args: null,
      storageKey: null
    },
    v10 = {
      kind: 'LinkedField',
      alias: null,
      name: 'user',
      storageKey: null,
      args: null,
      concreteType: 'UserNode',
      plural: false,
      selections: [(v4 /*: any*/)]
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'AddSolicitationCommentMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'addSolicitationComment',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'AddSolicitationCommentPayload',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'solicitation',
              storageKey: null,
              args: null,
              concreteType: 'SolicitationNode',
              plural: false,
              selections: [
                (v3 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'comments',
                  storageKey: null,
                  args: null,
                  concreteType: 'SolicitationContractorLogNodeConnection',
                  plural: false,
                  selections: [
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'edges',
                      storageKey: null,
                      args: null,
                      concreteType: 'SolicitationContractorLogNodeEdge',
                      plural: true,
                      selections: [
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'node',
                          storageKey: null,
                          args: null,
                          concreteType: 'SolicitationContractorLogNode',
                          plural: false,
                          selections: [
                            (v4 /*: any*/),
                            (v5 /*: any*/),
                            (v6 /*: any*/),
                            (v7 /*: any*/),
                            {
                              kind: 'LinkedField',
                              alias: null,
                              name: 'repliedTo',
                              storageKey: null,
                              args: null,
                              concreteType: 'CommentRepliedToNode',
                              plural: false,
                              selections: [
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                  kind: 'LinkedField',
                                  alias: null,
                                  name: 'user',
                                  storageKey: null,
                                  args: null,
                                  concreteType: 'UserNode',
                                  plural: false,
                                  selections: [(v8 /*: any*/)]
                                }
                              ]
                            },
                            {
                              kind: 'LinkedField',
                              alias: null,
                              name: 'contractor',
                              storageKey: null,
                              args: null,
                              concreteType: 'ContractorNode',
                              plural: false,
                              selections: [
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                {
                                  kind: 'LinkedField',
                                  alias: null,
                                  name: 'freelancer',
                                  storageKey: null,
                                  args: null,
                                  concreteType: 'FreelancerNode',
                                  plural: false,
                                  selections: [(v10 /*: any*/)]
                                }
                              ]
                            },
                            {
                              kind: 'LinkedField',
                              alias: null,
                              name: 'createdByStaff',
                              storageKey: null,
                              args: null,
                              concreteType: 'StaffNode',
                              plural: false,
                              selections: [(v8 /*: any*/), (v9 /*: any*/), (v10 /*: any*/)]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'AddSolicitationCommentMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'addSolicitationComment',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'AddSolicitationCommentPayload',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'solicitation',
              storageKey: null,
              args: null,
              concreteType: 'SolicitationNode',
              plural: false,
              selections: [
                (v3 /*: any*/),
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'comments',
                  storageKey: null,
                  args: null,
                  concreteType: 'SolicitationContractorLogNodeConnection',
                  plural: false,
                  selections: [
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'edges',
                      storageKey: null,
                      args: null,
                      concreteType: 'SolicitationContractorLogNodeEdge',
                      plural: true,
                      selections: [
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'node',
                          storageKey: null,
                          args: null,
                          concreteType: 'SolicitationContractorLogNode',
                          plural: false,
                          selections: [
                            (v4 /*: any*/),
                            (v5 /*: any*/),
                            (v6 /*: any*/),
                            (v7 /*: any*/),
                            {
                              kind: 'LinkedField',
                              alias: null,
                              name: 'repliedTo',
                              storageKey: null,
                              args: null,
                              concreteType: 'CommentRepliedToNode',
                              plural: false,
                              selections: [
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                  kind: 'LinkedField',
                                  alias: null,
                                  name: 'user',
                                  storageKey: null,
                                  args: null,
                                  concreteType: 'UserNode',
                                  plural: false,
                                  selections: [(v8 /*: any*/), (v4 /*: any*/)]
                                }
                              ]
                            },
                            {
                              kind: 'LinkedField',
                              alias: null,
                              name: 'contractor',
                              storageKey: null,
                              args: null,
                              concreteType: 'ContractorNode',
                              plural: false,
                              selections: [
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                {
                                  kind: 'LinkedField',
                                  alias: null,
                                  name: 'freelancer',
                                  storageKey: null,
                                  args: null,
                                  concreteType: 'FreelancerNode',
                                  plural: false,
                                  selections: [(v10 /*: any*/), (v4 /*: any*/)]
                                },
                                (v4 /*: any*/)
                              ]
                            },
                            {
                              kind: 'LinkedField',
                              alias: null,
                              name: 'createdByStaff',
                              storageKey: null,
                              args: null,
                              concreteType: 'StaffNode',
                              plural: false,
                              selections: [
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v4 /*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                (v4 /*: any*/)
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'mutation',
      name: 'AddSolicitationCommentMutation',
      id: null,
      text:
        'mutation AddSolicitationCommentMutation(\n  $input: AddSolicitationCommentInput!\n) {\n  addSolicitationComment(input: $input) {\n    isUpdated\n    solicitation {\n      state\n      comments {\n        edges {\n          node {\n            id\n            content\n            created\n            contentEditedAt\n            repliedTo {\n              content\n              created\n              user {\n                fullName\n                id\n              }\n            }\n            contractor {\n              fullName\n              representativeImageUrl\n              freelancer {\n                user {\n                  id\n                }\n                id\n              }\n              id\n            }\n            createdByStaff {\n              fullName\n              representativeImageUrl\n              user {\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '38d4f4f1f6c9c748939a2d0881599ee6';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import JobDetails from './JobDetails';
import { useParams } from 'react-router';

const JobDetailsPage = props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query JobDetailsPageQuery($id: ID!) {
          job(id: $id) {
            ...JobDetails_job
          }
          jobState: __type(name: "JobStatus") {
            ...JobDetails_stateTypes
          }
        }
      `}
      variables={{ id }}
      render={relayProps => (
        <JobDetails stateTypes={relayProps.jobState} job={relayProps.job} {...props} />
      )}
    />
  );
};

export default JobDetailsPage;

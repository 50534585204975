import React from 'react';
import { Grid } from '@material-ui/core';

import SearchableOrganizationDropdown from '../shared/SearchableOrganizationDropdown';
import SearchableContactDropdown from '../shared/SearchableContactDropdown';
import FilterDebounceInput from '../../../shared/components/filters/FilterDebounceInput';
import DateField from '../../../shared/components/form/fields/DateField';
import FilterSelect from '../../../shared/components/filters/FilterSelect';
import { useFilterStyles } from '../../../shared/styles/common/filterStyles';
import { JOB_STATUSES } from '../../../shared/constants';

const JobsPageFilters = ({ filterValues, onFilterChange, statusOptions }) => {
  // const [mappedStatusOptions, setMappedStatusOptions] = useState([]);
  // const { includeInactive } = filterValues;
  const classes = useFilterStyles();

  const mapStatusOptions = () =>
    statusOptions
      .filter(option => option.name !== JOB_STATUSES.preparing)
      .map(option => ({
        value: option.name,
        name: option.description
      }));

  // TODO there is no includeInactive filter for freelancers app
  // check if bellow code was just unnecessary copy/paste from org app

  // useEffect(() => {
  //   if (
  //     !includeInactive &&
  //     [
  //       JOB_STATUSES.canceled,
  //       JOB_STATUSES.revoked,
  //       JOB_STATUSES.closed,
  //       JOB_STATUSES.declined
  //     ].includes(filterValues['status'])
  //   ) {
  //     onFilterChange('status', '');
  //   }

  //   const newStatusOptions = statusOptions.filter(option => {
  //     if (
  //       [
  //         JOB_STATUSES.canceled,
  //         JOB_STATUSES.closed,
  //         JOB_STATUSES.revoked,
  //         JOB_STATUSES.declined
  //       ].includes(option.name)
  //     ) {
  //       return includeInactive;
  //     }
  //     return true;
  //   });
  //   setMappedStatusOptions(mapStatusOptions(newStatusOptions));
  // }, [includeInactive]);

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }} alignItems="center">
      {[
        {
          name: 'name',
          label: 'Job Name',
          component: FilterDebounceInput,
          onChange: onFilterChange,
          props: { fullWidth: true }
        },
        {
          name: 'organization',
          label: 'Organization:',
          component: SearchableOrganizationDropdown,
          onChange: value => onFilterChange('organization', value)
        },
        {
          name: 'orgSequenceId',
          label: 'Job ID:',
          component: FilterDebounceInput,
          onChange: onFilterChange,
          props: { fullWidth: true }
        },
        {
          name: 'staffId',
          label: 'Contact:',
          component: SearchableContactDropdown,
          onChange: value => onFilterChange('staffId', value)
        },
        {
          name: 'status',
          label: 'Status',
          component: FilterSelect,
          onChange: onFilterChange,
          props: { options: mapStatusOptions() }
        },
        {
          name: 'contractDateFrom',
          label: 'Contract Date From:',
          component: DateField,
          onChange: onFilterChange,
          props: { placeholder: 'From...' }
        },
        {
          name: 'contractDateTo',
          label: 'To:',
          component: DateField,
          onChange: onFilterChange,
          props: { placeholder: 'To...' }
        },
        {
          name: 'jobDeadlineFrom',
          label: 'Job Deadline From:',
          component: DateField,
          onChange: onFilterChange,
          props: { placeholder: 'From...' }
        },
        {
          name: 'jobDeadlineTo',
          label: 'To:',
          component: DateField,
          onChange: onFilterChange,
          props: { placeholder: 'To...' }
        }
      ].map(({ name, label, component: Component, props, onChange }) => (
        <Grid
          key={name}
          item
          xs={2}
          classes={{
            item:
              name === 'jobDeadlineFrom' || name === 'contractDateFrom' ? classes.dueDateFrom : ''
          }}
        >
          <Component
            name={name}
            label={label}
            value={filterValues[name]}
            onChange={onChange}
            {...props}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default JobsPageFilters;

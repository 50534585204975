/**
 * @flow
 * @relayHash 0aeb4b305b5aa8b26f4b4c4aaf827ad5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type StorageClassEnum = "DEEP_ARCHIVE" | "GLACIER" | "RESTORING" | "STANDARD" | "%future added value";
export type DeliverablesQuickViewQueryVariables = {|
  id: string
|};
export type DeliverablesQuickViewQueryResponse = {|
  +deliverable: ?{|
    +release: ?{|
      +id: string,
      +name: string,
      +product: {|
        +id: string,
        +title: string,
      |},
    |},
    +title: string,
    +description: ?string,
    +dueDate: ?any,
    +category: {|
      +name: string
    |},
    +categoryType: ?{|
      +name: string
    |},
    +amount: ?any,
    +currencyCode: ?DeliverableCurrencyCode,
    +tags: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
        |}
      |}>
    |},
    +assignedStaff: ?{|
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |},
    +assignedContractor: ?{|
      +id: string,
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |},
    +assignedInternally: ?{|
      +id: string,
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |},
    +latestReviewAsset: ?{|
      +id: string,
      +fileName: string,
      +fileThumbnailUrl: ?string,
      +reviewInterface: ?string,
      +revisionNumber: ?number,
      +storageClass: ?StorageClassEnum,
    |},
    +state: DeliverableState,
    +job: ?{|
      +id: string,
      +name: string,
    |},
  |},
  +deliverableStateTypes: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +name: string,
      +description: ?string,
    |}>
  |},
|};
export type DeliverablesQuickViewQuery = {|
  variables: DeliverablesQuickViewQueryVariables,
  response: DeliverablesQuickViewQueryResponse,
|};
*/


/*
query DeliverablesQuickViewQuery(
  $id: ID!
) {
  deliverable(id: $id) {
    release {
      id
      name
      product {
        id
        title
      }
    }
    title
    description
    dueDate
    category {
      name
      id
    }
    categoryType {
      name
      id
    }
    amount
    currencyCode
    tags {
      edges {
        node {
          id
          name
        }
      }
    }
    assignedStaff {
      fullName
      representativeImageUrl
      id
    }
    assignedContractor {
      id
      fullName
      representativeImageUrl
    }
    assignedInternally {
      id
      fullName
      representativeImageUrl
    }
    latestReviewAsset {
      id
      fileName
      fileThumbnailUrl
      reviewInterface
      revisionNumber
      storageClass
    }
    state
    job {
      id
      name
    }
    id
  }
  deliverableStateTypes: __type(name: "DeliverableState") {
    enumValues {
      name
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "release",
  "storageKey": null,
  "args": null,
  "concreteType": "ReleaseNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "product",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductNode",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v4/*: any*/)
      ]
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dueDate",
  "args": null,
  "storageKey": null
},
v8 = [
  (v3/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyCode",
  "args": null,
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": null,
  "args": null,
  "concreteType": "TagNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TagNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "TagNode",
          "plural": false,
          "selections": (v11/*: any*/)
        }
      ]
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v15 = [
  (v2/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
],
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "assignedContractor",
  "storageKey": null,
  "args": null,
  "concreteType": "ContractorNode",
  "plural": false,
  "selections": (v15/*: any*/)
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "assignedInternally",
  "storageKey": null,
  "args": null,
  "concreteType": "StaffNode",
  "plural": false,
  "selections": (v15/*: any*/)
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "latestReviewAsset",
  "storageKey": null,
  "args": null,
  "concreteType": "ReviewAssetNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileThumbnailUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reviewInterface",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "revisionNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "storageClass",
      "args": null,
      "storageKey": null
    }
  ]
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "job",
  "storageKey": null,
  "args": null,
  "concreteType": "JobNode",
  "plural": false,
  "selections": (v11/*: any*/)
},
v21 = {
  "kind": "LinkedField",
  "alias": "deliverableStateTypes",
  "name": "__type",
  "storageKey": "__type(name:\"DeliverableState\")",
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "DeliverableState"
    }
  ],
  "concreteType": "__Type",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "enumValues",
      "storageKey": null,
      "args": null,
      "concreteType": "__EnumValue",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v6/*: any*/)
      ]
    }
  ]
},
v22 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeliverablesQuickViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryNode",
            "plural": false,
            "selections": (v8/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categoryType",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryTypeNode",
            "plural": false,
            "selections": (v8/*: any*/)
          },
          (v9/*: any*/),
          (v10/*: any*/),
          (v12/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignedStaff",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/)
            ]
          },
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ]
      },
      (v21/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeliverablesQuickViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryNode",
            "plural": false,
            "selections": (v22/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categoryType",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryTypeNode",
            "plural": false,
            "selections": (v22/*: any*/)
          },
          (v9/*: any*/),
          (v10/*: any*/),
          (v12/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignedStaff",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v2/*: any*/)
        ]
      },
      (v21/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DeliverablesQuickViewQuery",
    "id": null,
    "text": "query DeliverablesQuickViewQuery(\n  $id: ID!\n) {\n  deliverable(id: $id) {\n    release {\n      id\n      name\n      product {\n        id\n        title\n      }\n    }\n    title\n    description\n    dueDate\n    category {\n      name\n      id\n    }\n    categoryType {\n      name\n      id\n    }\n    amount\n    currencyCode\n    tags {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    assignedStaff {\n      fullName\n      representativeImageUrl\n      id\n    }\n    assignedContractor {\n      id\n      fullName\n      representativeImageUrl\n    }\n    assignedInternally {\n      id\n      fullName\n      representativeImageUrl\n    }\n    latestReviewAsset {\n      id\n      fileName\n      fileThumbnailUrl\n      reviewInterface\n      revisionNumber\n      storageClass\n    }\n    state\n    job {\n      id\n      name\n    }\n    id\n  }\n  deliverableStateTypes: __type(name: \"DeliverableState\") {\n    enumValues {\n      name\n      description\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1202bb5ed70d06fab3babc48df1030ac';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffDetails_staff$ref: FragmentReference;
declare export opaque type StaffDetails_staff$fragmentType: StaffDetails_staff$ref;
export type StaffDetails_staff = {|
  +id: string,
  +fullName: ?string,
  +title: ?string,
  +roleLabel: ?string,
  +isOwner: boolean,
  +role: ?{|
    +id: string,
    +name: string,
  |},
  +representativeImageUrl: ?string,
  +user: {|
    +id: string,
    +email: string,
    +firstName: string,
    +lastName: string,
    +isActive: ?boolean,
    +lastLogin: ?any,
  |},
  +$refType: StaffDetails_staff$ref,
|};
export type StaffDetails_staff$data = StaffDetails_staff;
export type StaffDetails_staff$key = {
  +$data?: StaffDetails_staff$data,
  +$fragmentRefs: StaffDetails_staff$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StaffDetails_staff",
  "type": "StaffNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "roleLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isOwner",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "role",
      "storageKey": null,
      "args": null,
      "concreteType": "OrganizationRoleNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isActive",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastLogin",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1aaaeca2cc5d2ecb528ba98011e2747';
module.exports = node;

import React from 'react';
import colors from '../../styles/common/colors';
import CustomTableTooltip from '../table/CustomTableTooltip';

function Briefcase({ text, onClick, width, height, tooltip }) {
  const getXCoord = () => {
    if (text.length === 1) {
      return width - 17;
    } else if (text.length === 2) {
      return width - 20;
    } else {
      return width - 22;
    }
  };

  return (
    <CustomTableTooltip title={tooltip}>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.62 24"
      fill={colors.darkBlueGrey}
      width={`${width}px`}
      height={`${height}px`}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        style={{ fillRule: 'evenodd' }}
        d="M23.62,6V4h-4V6Zm-10,2V19h16V8Zm16-2a2,2,0,0,1,2,2V19a2,2,0,0,1-2,2h-16a2,2,0,0,1-2-2V8a2,2,0,0,1,2-2h4V4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6Z"
      />
      <rect x="1.69" y="12.5" width="7.97" height="2" />
      <rect x="4.68" y="9.51" width="2" height="7.97" />
      <text
        x={getXCoord()}
        y={`${height - 10}`}
        style={{ fontSize: '9px', fill: `${colors.darkBlueGrey}`, fontFamily: 'Nunito' }}
      >
        {text.length < 3 ? text : '99+'}
      </text>
    </svg>
    </CustomTableTooltip>
  );
}

Briefcase.defaultProps = {
  text: '',
  width: 36,
  height: 27,
  onClick: () => {},
  tooltip: 'Allocate Deliverables'
};

export default Briefcase;

import { fabric } from 'fabric';
import { ATTRS, TOOLS, OPTIONS } from './contants';
import { resetTool } from './commons';
import { hideControls } from '../CanvasData';

const toolLine = (canvas, { annotationColor, userId, annotationRef, frameRef }) => {
  resetTool(canvas, TOOLS.dot.name);
  let line;
  let isMouseMoving = false;

  const mouseDownHandler = event => {
    const point = event.pointer;

    line = new fabric.Line([point.x, point.y, point.x, point.y], {
      originX: 'center',
      originY: 'center',
      selectable: false,
      stroke: annotationColor,
      strokeWidth: OPTIONS.strokeWidth,
      [ATTRS.originalColor]: annotationColor,
      [ATTRS.type]: TOOLS.line.name,
      [ATTRS.authorId]: userId,
      [ATTRS.ref]: annotationRef,
      [ATTRS.frameRef]: frameRef
    });

    canvas.add(line);
    isMouseMoving = true;
  };

  const mouseMoveHandler = event => {
    if (isMouseMoving) {
      line.set({
        x2: event.pointer.x,
        y2: event.pointer.y
      });
      canvas.renderAll();
    }
  };

  const mouseUpHandler = () => {
    hideControls(line, ['mt', 'ml', 'mr', 'mb']);
    isMouseMoving = false;
    line.setCoords();
  };

  canvas.on({
    'mouse:down': mouseDownHandler,
    'mouse:move': mouseMoveHandler,
    'mouse:up': mouseUpHandler
  });
};

export { toolLine };

import React from 'react';
import CreateReferenceMutation from '../../../../../../mutations/deliverables/CreateReferenceMutation';
import { performFileUpload } from '../../../../../../services/utilities';
import AssetForm from '../../../../../assets/AssetForm';

const UploadTab = ({ deliverableId, onSelect, onCancel }) => {
  const handleSubmitOk = ({ createReference: { newReference } }) => {
    onSelect(newReference);
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    performFileUpload(
      'reference',
      'file',
      null,
      values.file,
      file => {
        CreateReferenceMutation(
          {
            deliverable: deliverableId,
            title: values.fileName || file.name,
            description: values.description
          },
          file
        ).then(handleSubmitOk);
      },
      (stagedPath, filename) => {
        CreateReferenceMutation({
          deliverable: deliverableId,
          title: values.fileName || filename,
          description: values.description,
          stagedPath
        }).then(handleSubmitOk);
      }
    );
  };

  return <AssetForm onSubmit={handleSubmit} onCancel={onCancel} />;
};

export default UploadTab;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import AccountInformationContent from './AccountInformationContent';

const AccountInformationTab = props => {
  return (
    <QueryRenderer
      query={graphql`
        query AccountInformationTabQuery {
          ...AccountInformationContent_accountInformation
        }
      `}
      variables={{}}
      render={relayProps => {
        return <AccountInformationContent accountInformation={relayProps} {...props} />;
      }}
    />
  );
};

export default AccountInformationTab;

/**
 * @flow
 * @relayHash 80846e6372b4f68a002985c8b5bfafe5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateOrganizationSamlConfigurationInput = {|
  samlEnabled?: ?boolean,
  idpEntityId?: ?string,
  idpUrl?: ?string,
  idpX509cert?: ?string,
  idpAttrUserPermanentId?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateOrganizationSamlConfigurationMutationVariables = {|
  input: UpdateOrganizationSamlConfigurationInput
|};
export type UpdateOrganizationSamlConfigurationMutationResponse = {|
  +updateOrganizationSamlConfiguration: ?{|
    +organization: ?{|
      +samlConfiguration: ?{|
        +samlEnabled: boolean,
        +idpEntityId: ?string,
        +idpUrl: ?string,
        +idpX509cert: ?string,
        +idpAttrUserPermanentId: string,
        +metadataUrl: ?string,
      |}
    |}
  |}
|};
export type UpdateOrganizationSamlConfigurationMutation = {|
  variables: UpdateOrganizationSamlConfigurationMutationVariables,
  response: UpdateOrganizationSamlConfigurationMutationResponse,
|};
*/


/*
mutation UpdateOrganizationSamlConfigurationMutation(
  $input: UpdateOrganizationSamlConfigurationInput!
) {
  updateOrganizationSamlConfiguration(input: $input) {
    organization {
      samlConfiguration {
        samlEnabled
        idpEntityId
        idpUrl
        idpX509cert
        idpAttrUserPermanentId
        metadataUrl
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrganizationSamlConfigurationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "samlEnabled",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idpEntityId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idpUrl",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idpX509cert",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "idpAttrUserPermanentId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "metadataUrl",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateOrganizationSamlConfigurationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrganizationSamlConfiguration",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSamlConfigurationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "samlConfiguration",
                "storageKey": null,
                "args": null,
                "concreteType": "SamlConfigurationNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateOrganizationSamlConfigurationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrganizationSamlConfiguration",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSamlConfigurationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "samlConfiguration",
                "storageKey": null,
                "args": null,
                "concreteType": "SamlConfigurationNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateOrganizationSamlConfigurationMutation",
    "id": null,
    "text": "mutation UpdateOrganizationSamlConfigurationMutation(\n  $input: UpdateOrganizationSamlConfigurationInput!\n) {\n  updateOrganizationSamlConfiguration(input: $input) {\n    organization {\n      samlConfiguration {\n        samlEnabled\n        idpEntityId\n        idpUrl\n        idpX509cert\n        idpAttrUserPermanentId\n        metadataUrl\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '771bded6e50d4d6a9a52f94e8322c30a';
module.exports = node;

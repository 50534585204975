import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router';

import CreateReleaseForm from '../../../organization/components/releases/CreateReleaseForm';
import CreateProductForm from '../../../organization/components/products/CreateProductForm';
import CreateDeliverableForm from '../../../organization/components/deliverables/CreateDeliverableForm';
import CreateDeliverableBatchForm from '../../../organization/components/deliverables/CreateDeliverableBatchForm';
import CreateJobForm from '../../../organization/components/jobs/CreateJobForm';
import CreateContractorForm from '../../../organization/components/contractors/CreateContractorForm';

import Dialog from './Dialog';
import useSideBar from '../../hooks/useSideBar';
import useGlobalCreateModalStyles from '../../styles/common/useGlobalCreateModalStyles';

import { adminOptions } from '../../../organization/components/shared/TopBarOrganization';
import CreateSolicitationForm from '../../../organization/components/solicitations/CreateSolicitationForm';
import CreateContractTemplateForm from '../../../organization/components/admin/contractTemplates/CreateContractTemplateForm';
import AddStaffUserForm from '../../../organization/components/admin/staffManagement/AddStaffUserForm';
import CreateStaffRoleForm from '../../../organization/components/admin/staffManagement/CreateRoleForm';

import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import CreateGroupForm from '../../../organization/components/contractors/CreateGroupForm';
import ImportDeliverablesForm from '../../../organization/components/deliverables/ImportDeliverablesForm';

const GlobalCreateModal = props => {
  const {
    selectedModal,
    handleClose,
    toRefetchData,
    contextActions,
    isDialogOpen,
    userContext,
    options
  } = props;

  const isOpen = useSideBar()[0];
  const classes = useGlobalCreateModalStyles({ isOpen });

  const renderModalContent = () => {
    const modalProps = {
      toRefetchData,
      handleClose,
      userContext
    };

    const findAction = passedActionName =>
      contextActions.find(action => action.name === passedActionName);

    if (selectedModal) {
      switch (selectedModal.buttonName) {
        case options.releases && options.releases.buttonName:
          return <CreateReleaseForm {...modalProps} />;
        case options.batchReleaseDetails && options.batchReleaseDetails.buttonName:
          return (
            <CreateDeliverableBatchForm
              contextAction={findAction('batchReleaseDetails')}
              isContextual={true}
              {...modalProps}
            />
          );
        case options.releaseDetailsDeliverable && options.releaseDetailsDeliverable.buttonName:
          return (
            <CreateDeliverableForm
              contextAction={findAction('releaseDetailsDeliverable')}
              isContextual={true}
              {...modalProps}
            />
          );
        case options.duplicateRelease && options.duplicateRelease.buttonName:
          return (
            <CreateReleaseForm
              contextAction={findAction('duplicateRelease')}
              isContextual={true}
              {...modalProps}
            />
          );
        case options.deliverables && options.deliverables.buttonName:
          return <CreateDeliverableForm {...modalProps} />;

        case options.products && options.products.buttonName:
          return <CreateProductForm {...modalProps} />;
        case options.jobs && options.jobs.buttonName:
          return <CreateJobForm {...modalProps} />;
        case options.jobWithDeliverablesSolicitations &&
          options.jobWithDeliverablesSolicitations.buttonName:
          return (
            <CreateJobForm
              selectedDeliverables={findAction('jobWithDeliverablesSolicitations').data.checked}
              refreshCheckedItems={
                findAction('jobWithDeliverablesSolicitations').data.refreshChecked
              }
              {...modalProps}
            />
          );
        case options.jobWithDeliverables && options.jobWithDeliverables.buttonName:
          return (
            <CreateJobForm
              selectedDeliverables={findAction('jobWithDeliverables').data.checked}
              refreshCheckedItems={findAction('jobWithDeliverables').data.refreshChecked}
              {...modalProps}
            />
          );
        case options.contractors && options.contractors.buttonName:
          return <CreateContractorForm {...modalProps} />;
        case options.solicitations && options.solicitations.buttonName:
          return <CreateSolicitationForm {...modalProps} />;
        case options.solicitationWithDeliverables &&
          options.solicitationWithDeliverables.buttonName:
          return (
            <CreateSolicitationForm
              {...modalProps}
              contextAction={findAction('solicitationWithDeliverables')}
              isContextual={true}
            />
          );
        case options.batchDeliverables && options.batchDeliverables.buttonName:
          return <CreateDeliverableBatchForm {...modalProps} />;
        case options.importDeliverables && options.importDeliverables.buttonName:
          return <ImportDeliverablesForm {...modalProps} />;
        case adminOptions.contractTemplate.buttonName:
          return <CreateContractTemplateForm {...modalProps} />;
        case adminOptions.staffManagement.buttonName:
          return <AddStaffUserForm {...modalProps} />;
        case adminOptions.staffRolesManagement.buttonName:
          return <CreateStaffRoleForm {...modalProps} />;
        case options.productDetails && options.productDetails.buttonName:
          return (
            <CreateReleaseForm
              contextAction={findAction('productDetails')}
              isContextual={true}
              {...modalProps}
            />
          );
        case options.solicitationWithContractors && options.solicitationWithContractors.buttonName:
          return (
            <CreateSolicitationForm
              contextAction={findAction('solicitationWithContractors')}
              isContextual={true}
              {...modalProps}
            />
          );
        case options.contractorGroupWithContractors &&
          options.contractorGroupWithContractors.buttonName:
          return (
            <CreateGroupForm
              contextAction={findAction('contractorGroupWithContractors')}
              isContextual={true}
              {...modalProps}
            />
          );
        default:
          return <div></div>;
      }
    }
  };

  const matchDialogWidth = () => {
    if (!selectedModal) {
      return classes.defaultWidth;
    }
    switch (selectedModal.buttonName) {
      case options.contractors && options.contractors.buttonName:
        return classes.contractor;
      case options.releases && options.releases.buttonName:
        return classes.release;
      case options.products && options.products.buttonName:
        return classes.product;
      case options.deliverables && options.deliverables.buttonName:
        return classes.deliverable;
      case options.jobs && options.jobs.buttonName:
      case options.jobWithDeliverables && options.jobWithDeliverables.buttonName:
      case options.jobWithDeliverablesSolicitations &&
        options.jobWithDeliverablesSolicitations.buttonName:
        return classes.job;
      case options.solicitations && options.solicitations.buttonName:
        return classes.solicitation;
      default:
        return classes.defaultWidth;
    }
  };

  const checkIfCloseButtonShouldBeHidden = () => {
    return (
      selectedModal.name === (options.jobWithDeliverables && options.jobWithDeliverables.name) ||
      selectedModal.name === (options.jobs && options.jobs.name) ||
      selectedModal.name ===
        (options.jobWithDeliverablesSolicitations && options.jobWithDeliverablesSolicitations.name)
    );
  };

  if (selectedModal) {
    return (
      <Dialog
        className={clsx(classes.globalCreateDialog, matchDialogWidth())}
        title={selectedModal.modalTitle}
        isDialogOpen={isDialogOpen}
        closeDialog={handleClose}
        noButton={checkIfCloseButtonShouldBeHidden()}
        disableBackdropClick
        disableEscapeKeyDown
      >
        {renderModalContent()}
      </Dialog>
    );
  } else return null;
};

export default withUserContext(withRouter(GlobalCreateModal));

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import useDialogStyles from '../../../shared/styles/common/useDialogStyles';
import FormikTextField from '../../../shared/components/form/FormikTextField';

const AnnotationCommentForm = props => {
  const { handleOnSubmit, handleSubmitWithoutComment, closeDialog, initialValue } = props;
  const classes = useDialogStyles();

  const formikProps = {
    initialValues: {
      comment: initialValue ? initialValue : ''
    },
    validationSchema: {},
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleOnSubmit(values.comment, () => setSubmitting(false));
    }
  };

  return (
    <Formik {...formikProps}>
      {({ submitForm, isSubmitting, setSubmitting }) => (
        <Form className={classes.annotationCommentForm}>
          <Field
            autoFocus
            fullWidth
            name="comment"
            label="Comment"
            multiline
            rows="8"
            component={FormikTextField}
          />
          <div className={classes.dialogActions}>
            <GlobalButton
              id="dismissAnnotation"
              variant="transparent"
              handleClick={closeDialog}
              disabled={isSubmitting}
            >
              Cancel
            </GlobalButton>
            {handleSubmitWithoutComment && !initialValue && (
              <GlobalButton
                id="addWithoutACommentForm"
                handleClick={() => {
                  setSubmitting(true);
                  handleSubmitWithoutComment(() => setSubmitting(false));
                }}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Add without comment
              </GlobalButton>
            )}
            <GlobalButton
              id="saveAddCommentCommentForm"
              handleClick={submitForm}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {initialValue ? 'Save' : 'Add'} Comment
            </GlobalButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AnnotationCommentForm.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleSubmitWithoutComment: PropTypes.func,
  initialValue: PropTypes.string
};

export default AnnotationCommentForm;

/**
 * @flow
 * @relayHash 83d713cfec7ad0f92a6c8b1a0e7e5d56
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateStaffProfileInput = {|
  firstName?: ?string,
  lastName?: ?string,
  title?: ?string,
  phone?: ?string,
  skype?: ?string,
  reprImageNull?: ?boolean,
  showFreelancerFieldsPopup?: ?boolean,
  clientMutationId?: ?string,
|};
export type UpdateStaffProfileMutationVariables = {|
  input: UpdateStaffProfileInput
|};
export type UpdateStaffProfileMutationResponse = {|
  +updateStaffProfile: ?{|
    +updatedStaffProfile: ?{|
      +firstName: string,
      +lastName: string,
      +fullName: ?string,
      +email: string,
      +representativeImageUrl: ?string,
      +orgStaff: ?{|
        +id: string,
        +roleLabel: ?string,
        +phone: ?string,
        +title: ?string,
        +notificationConfig: ?any,
        +showFreelancerFieldsPopup: ?boolean,
        +organization: {|
          +name: string
        |},
      |},
    |}
  |}
|};
export type UpdateStaffProfileMutation = {|
  variables: UpdateStaffProfileMutationVariables,
  response: UpdateStaffProfileMutationResponse,
|};
*/


/*
mutation UpdateStaffProfileMutation(
  $input: UpdateStaffProfileInput!
) {
  updateStaffProfile(input: $input) {
    updatedStaffProfile {
      firstName
      lastName
      fullName
      email
      representativeImageUrl
      orgStaff {
        id
        roleLabel
        phone
        title
        notificationConfig
        showFreelancerFieldsPopup
        organization {
          name
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateStaffProfileInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "roleLabel",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "notificationConfig",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showFreelancerFieldsPopup",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateStaffProfileMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateStaffProfile",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStaffProfilePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffProfile",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "orgStaff",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "organization",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrganizationNode",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateStaffProfileMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateStaffProfile",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStaffProfilePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffProfile",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "orgStaff",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "organization",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrganizationNode",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateStaffProfileMutation",
    "id": null,
    "text": "mutation UpdateStaffProfileMutation(\n  $input: UpdateStaffProfileInput!\n) {\n  updateStaffProfile(input: $input) {\n    updatedStaffProfile {\n      firstName\n      lastName\n      fullName\n      email\n      representativeImageUrl\n      orgStaff {\n        id\n        roleLabel\n        phone\n        title\n        notificationConfig\n        showFreelancerFieldsPopup\n        organization {\n          name\n          id\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46daa7cb17c5a3d78c868484715e3586';
module.exports = node;

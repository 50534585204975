/**
 * @flow
 * @relayHash 324717a5978cd872eb06776d06c1fedc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateReleaseBudgetInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type CreateReleaseBudgetMutationVariables = {|
  input: CreateReleaseBudgetInput
|};
export type CreateReleaseBudgetMutationResponse = {|
  +createReleaseBudget: ?{|
    +release: ?{|
      +budgetInfo: ?$ReadOnlyArray<?{|
        +category: ?{|
          +id: string,
          +name: string,
        |},
        +budgetAmount: ?any,
      |}>,
      +categoryInfo: ?$ReadOnlyArray<?{|
        +category: ?{|
          +id: string,
          +name: string,
        |},
        +pendingAmount: ?any,
        +contractedAmount: ?any,
        +originalPendingAmount: ?any,
        +originalContractedAmount: ?any,
        +hasFxDeliverables: ?boolean,
      |}>,
      +exchangeRateDate: ?any,
      +deliverablesTotalAmount: ?any,
      +deliverablesTotalOriginalAmount: ?any,
      +totalBudget: ?any,
      +deliverablesTotalContractedAmount: ?any,
      +deliverablesTotalPendingAmount: ?any,
    |}
  |}
|};
export type CreateReleaseBudgetMutation = {|
  variables: CreateReleaseBudgetMutationVariables,
  response: CreateReleaseBudgetMutationResponse,
|};
*/


/*
mutation CreateReleaseBudgetMutation(
  $input: CreateReleaseBudgetInput!
) {
  createReleaseBudget(input: $input) {
    release {
      budgetInfo {
        category {
          id
          name
        }
        budgetAmount
      }
      categoryInfo {
        category {
          id
          name
        }
        pendingAmount
        contractedAmount
        originalPendingAmount
        originalContractedAmount
        hasFxDeliverables
      }
      exchangeRateDate
      deliverablesTotalAmount
      deliverablesTotalOriginalAmount
      totalBudget
      deliverablesTotalContractedAmount
      deliverablesTotalPendingAmount
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateReleaseBudgetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "budgetInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "BudgetInfo",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "budgetAmount",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "categoryInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "ReleaseCategoryInfo",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pendingAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "contractedAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "originalPendingAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "originalContractedAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasFxDeliverables",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "exchangeRateDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalAmount",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalOriginalAmount",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalBudget",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalContractedAmount",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalPendingAmount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateReleaseBudgetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createReleaseBudget",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateReleaseBudgetPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "release",
            "storageKey": null,
            "args": null,
            "concreteType": "ReleaseNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateReleaseBudgetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createReleaseBudget",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateReleaseBudgetPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "release",
            "storageKey": null,
            "args": null,
            "concreteType": "ReleaseNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateReleaseBudgetMutation",
    "id": null,
    "text": "mutation CreateReleaseBudgetMutation(\n  $input: CreateReleaseBudgetInput!\n) {\n  createReleaseBudget(input: $input) {\n    release {\n      budgetInfo {\n        category {\n          id\n          name\n        }\n        budgetAmount\n      }\n      categoryInfo {\n        category {\n          id\n          name\n        }\n        pendingAmount\n        contractedAmount\n        originalPendingAmount\n        originalContractedAmount\n        hasFxDeliverables\n      }\n      exchangeRateDate\n      deliverablesTotalAmount\n      deliverablesTotalOriginalAmount\n      totalBudget\n      deliverablesTotalContractedAmount\n      deliverablesTotalPendingAmount\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4b06fbd4cf1867b2be860c0d50d4497';
module.exports = node;

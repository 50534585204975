import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.colors.tableBackground,
    color: theme.palette.colors.darkBlueGrey,
    boxShadow: theme.shadows[2],
    fontSize: 11
  },

  tooltipPlacementTop: {
    margin: '8px 0'
  },
  arrow: {
    color: theme.palette.colors.tableBackground,
    '&::before': {
      boxShadow: theme.shadows[2]
    }
  }
}));

function CustomTableTooltip(props) {
  const classes = useStyles();

  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

export default CustomTableTooltip;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation AllocateDeliverableMutation($input: AddSolicitationAllocatedDeliverableInput!) {
    addSolicitationAllocatedDeliverable(input: $input) {
      isUpdated
      solicitation {
        id
        numberOfAllocatedDeliverables
        numberOfAllocatedContractedDeliverables
      }
    }
  }
`;

const AllocateDeliverableMutation = (solicitationId, deliverableId, contractorId, callback) => {
  const allocateDeliverableVariables = {
    input: { solicitationId, deliverableId, contractorId }
  };

  const config = {
    mutation,
    variables: allocateDeliverableVariables,
    onCompleted: response => {
      callback(response.addSolicitationAllocatedDeliverable);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default AllocateDeliverableMutation;

AllocateDeliverableMutation.propTypes = {
  solicitationId: PropTypes.string.isRequired,
  deliverableId: PropTypes.string.isRequired,
  contractorId: PropTypes.string.isRequired
};

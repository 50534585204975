import React, { useMemo } from 'react';
import StyledTableHeader from './StyledTableHeader';
import TableHeaderSortCell from './TableHeaderSortCell';
import StyledTableCell from './StyledTableCell';
import TableHeaderCheckbox from './TableHeaderCheckbox';
import { CELL_TYPES } from '../../constants';
import { TableCellWithBadge } from '../../../organization/components/releases/ReleaseDetailsPage/ReleaseBudgetTab/styled';
import TableCellWithSubscript from './TableCellWithSubscript';

const AutoTableHeader = ({ rowTemplate, sortableProps, checkboxProps }) => {
  const tableHeaderCells = useMemo(
    () =>
      rowTemplate.map((column, index) => {
        const align = column.type === CELL_TYPES.amount ? 'right' : column.align;
        if (column.sortable) {
          return (
            <TableHeaderSortCell
              key={index}
              name={column.name}
              label={column.label}
              align={align}
              noWrap={column.noWrap}
              {...sortableProps}
            />
          );
        } else if (column.checkbox) {
          return <TableHeaderCheckbox key={index} {...checkboxProps} />;
        } else if (column.badgeColor) {
          return (
            <TableCellWithBadge
              key={index}
              color={column.badgeColor}
              name={column.label}
              align={align}
            />
          );
        } else if (column.labelSubscript) {
          return (
            <TableCellWithSubscript
              key={index}
              cellText={column.label}
              subscriptText={column.labelSubscript}
              align={align}
            />
          );
        } else {
          return (
            <StyledTableCell key={index} empty={!column.label} align={align}>
              {column.label}
            </StyledTableCell>
          );
        }
      }),
    [rowTemplate, sortableProps, checkboxProps]
  );
  return <StyledTableHeader>{tableHeaderCells}</StyledTableHeader>;
};

export default AutoTableHeader;

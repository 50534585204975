import React from 'react';

import StyledTableCell from './StyledTableCell';
import { Box, styled } from '@material-ui/core';

const StyledBox = styled(Box)({
  display: 'inline-flex',
  flexDirection: 'column',
  lineHeight: 1,
  '& span': {
    fontSize: '10px',
    fontWeight: 'normal'
  }
});

const TableCellWithSubscript = ({ cellText, subscriptText, ...otherProps }) => (
  <StyledTableCell {...otherProps}>
    <StyledBox>
      {cellText}
      <span>{subscriptText}</span>
    </StyledBox>
  </StyledTableCell>
);

export default TableCellWithSubscript;

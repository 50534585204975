import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

/**
 * @param {string} id ID of a release
 * @param {function} onCompleted onCompleted callback
 * @param {function} onError onError callback
 */

export default (id, onCompleted, onError) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateReleaseBudgetMutation($input: CreateReleaseBudgetInput!) {
        createReleaseBudget(input: $input) {
          release {
            budgetInfo {
              category {
                id
                name
              }
              budgetAmount
            }
            categoryInfo {
              category {
                id
                name
              }
              pendingAmount
              contractedAmount
              originalPendingAmount
              originalContractedAmount
              hasFxDeliverables
            }
            exchangeRateDate
            deliverablesTotalAmount
            deliverablesTotalOriginalAmount
            totalBudget
            deliverablesTotalContractedAmount
            deliverablesTotalPendingAmount
          }
        }
      }
    `,
    variables: { input: { id } },
    onCompleted,
    onError
  });
};

/**
 * @flow
 * @relayHash bf77605c7ed4d0ba3f23a185c4e9d1db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SelectExistingTab_deliverable$ref = any;
export type SelectExistingTabRendererQueryVariables = {|
  id: string,
  orderBy?: ?string,
|};
export type SelectExistingTabRendererQueryResponse = {|
  +deliverable: ?{|
    +$fragmentRefs: SelectExistingTab_deliverable$ref
  |}
|};
export type SelectExistingTabRendererQuery = {|
  variables: SelectExistingTabRendererQueryVariables,
  response: SelectExistingTabRendererQueryResponse,
|};
*/


/*
query SelectExistingTabRendererQuery(
  $id: ID!
  $orderBy: String
) {
  deliverable(id: $id) {
    ...SelectExistingTab_deliverable
    id
  }
}

fragment SelectExistingTab_deliverable on DeliverableNode {
  id
  references(orderBy: $orderBy) {
    edges {
      node {
        id
        title
        fileUrl
        reviewInterface
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectExistingTabRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SelectExistingTab_deliverable",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectExistingTabRendererQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "references",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              }
            ],
            "concreteType": "ReferenceNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ReferenceNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReferenceNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reviewInterface",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SelectExistingTabRendererQuery",
    "id": null,
    "text": "query SelectExistingTabRendererQuery(\n  $id: ID!\n  $orderBy: String\n) {\n  deliverable(id: $id) {\n    ...SelectExistingTab_deliverable\n    id\n  }\n}\n\nfragment SelectExistingTab_deliverable on DeliverableNode {\n  id\n  references(orderBy: $orderBy) {\n    edges {\n      node {\n        id\n        title\n        fileUrl\n        reviewInterface\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aeb03aa2ee7b4196bb619bb66dbd96f4';
module.exports = node;

import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import StyledCheckbox from '../../../../../shared/components/UI/StyledCheckbox';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import { ALLOWED_ACTIONS, TOOLS_DESCRIPTIONS } from '../../../../../shared/constants';
import DoNotHireContractorMutation from '../../mutations/DoNotHireContractorMutation';
import MarkInactiveContractorMutation from '../../mutations/MarkInactiveContractorMutation';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import ToolActions from '../../../shared/ToolActions';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';

const ContractorToolsActions = props => {
  const {
    contractor: { id, doNotHire, isActive },
    userContext: {
      orgStaff: { allowedActions }
    },
    setRefetchCounterForTools
  } = props;

  const handleActivateDeactivate = isActive => {
    MarkInactiveContractorMutation(id, isActive, () => {
      setRefetchCounterForTools(prev => prev + 1);
    });
  };

  const handleDoNotHire = e => {
    DoNotHireContractorMutation(id, e.target.checked, () => {
      setRefetchCounterForTools(prev => prev + 1);
    });
  };

  const canUserMarkDoNotHire = computePermissionRole(
    ALLOWED_ACTIONS.CONTRACTOR_MARK_DO_NOT_HIRE,
    allowedActions
  );

  const canUserMarkInactive = computePermissionRole(
    ALLOWED_ACTIONS.CONTRACTOR_MARK_PROFILE_INACTIVE,
    allowedActions
  );

  const actionList = [
    {
      condition: canUserMarkDoNotHire,
      actionRender: () => (
        <Typography variant="body1">
          <StyledCheckbox
            id="doNotHireContractorCheckbox"
            checked={Boolean(doNotHire)}
            onChange={e => handleDoNotHire(e)}
          />
          Do not hire this Contractor.
        </Typography>
      ),
      description: TOOLS_DESCRIPTIONS.doNotHire
    },
    {
      condition: isActive && canUserMarkInactive,
      actionRender: () => (
        <GlobalButton
          fullWidth
          noMargin
          id="deactivateContractorAccount"
          handleClick={() => {
            handleActivateDeactivate(false);
          }}
        >
          Mark Contractor Inactive
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.markContractorInactive
    },
    {
      condition: !isActive && canUserMarkInactive,
      actionRender: () => (
        <GlobalButton
          fullWidth
          noMargin
          id="activateContractorAccount"
          handleClick={() => {
            handleActivateDeactivate(true);
          }}
        >
          Mark Contractor Active
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.activateContractor
    }
  ];

  return <ToolActions actions={actionList} />;
};

export default withUserContext(
  createFragmentContainer(ContractorToolsActions, {
    contractor: graphql`
      fragment ContractorToolsActions_contractor on ContractorNode {
        id
        isActive
        doNotHire
      }
    `
  })
);

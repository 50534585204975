/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductToolsActions_product$ref: FragmentReference;
declare export opaque type ProductToolsActions_product$fragmentType: ProductToolsActions_product$ref;
export type ProductToolsActions_product = {|
  +id: string,
  +hasReleases: ?boolean,
  +isActive: boolean,
  +$refType: ProductToolsActions_product$ref,
|};
export type ProductToolsActions_product$data = ProductToolsActions_product;
export type ProductToolsActions_product$key = {
  +$data?: ProductToolsActions_product$data,
  +$fragmentRefs: ProductToolsActions_product$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProductToolsActions_product",
  "type": "ProductNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasReleases",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8adc89a5f4751b0c2fdfd6fc0a765f2f';
module.exports = node;

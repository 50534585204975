/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OverviewContent_organization$ref: FragmentReference;
declare export opaque type OverviewContent_organization$fragmentType: OverviewContent_organization$ref;
export type OverviewContent_organization = {|
  +id: string,
  +activeStorageLimit: ?number,
  +archiveStorageLimit: ?number,
  +latestActiveMetricsData: ?{|
    +total: ?number,
    +data: ?$ReadOnlyArray<?{|
      +groupName: ?string,
      +metricValues: ?$ReadOnlyArray<?{|
        +name: ?string,
        +value: ?number,
      |}>,
    |}>,
  |},
  +latestArchiveMetricsData: ?{|
    +total: ?number,
    +data: ?$ReadOnlyArray<?{|
      +groupName: ?string,
      +metricValues: ?$ReadOnlyArray<?{|
        +name: ?string,
        +value: ?number,
      |}>,
    |}>,
  |},
  +$refType: OverviewContent_organization$ref,
|};
export type OverviewContent_organization$data = OverviewContent_organization;
export type OverviewContent_organization$key = {
  +$data?: OverviewContent_organization$data,
  +$fragmentRefs: OverviewContent_organization$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "total",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "data",
    "storageKey": null,
    "args": null,
    "concreteType": "MetricsDataValueGroup",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "groupName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "metricValues",
        "storageKey": null,
        "args": null,
        "concreteType": "MetricsDataValue",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "OverviewContent_organization",
  "type": "OrganizationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "activeStorageLimit",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "archiveStorageLimit",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "latestActiveMetricsData",
      "storageKey": null,
      "args": null,
      "concreteType": "MetricsData",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "latestArchiveMetricsData",
      "storageKey": null,
      "args": null,
      "concreteType": "MetricsData",
      "plural": false,
      "selections": (v0/*: any*/)
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5472ace74dadd6d054d9574750a30d21';
module.exports = node;

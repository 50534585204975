import React from 'react';
import { LinearProgress } from '@material-ui/core';
import Timer from './Timer';
import ProgressBarAnnotation from './ProgressBarAnnotation';
import SquareButton from '../UI/SquareButton';
import { useCommonAnnotationStyles } from '../../styles/deliverable/commonAnnotationStyles';
import AddActionsGroup from '../deliverable/review_interfaces/AddActionsGroup';
import Slider from '../common/Slider';

const ProgressToolbar = props => {
  const {
    currentTime,
    duration,
    annotationData,
    onProgressBarClick,
    onAnnotationClick,
    onAddAnnotationClick,
    onAddCommentClick,
    onTogglePlayClick,
    highlightedAnnotationRef,
    isPlaying,
    sliderProps,
    canAddMarkup
  } = props;

  const classes = useCommonAnnotationStyles();

  const onLinearProgressClick = event => {
    const currentTargetRect = event.currentTarget.getBoundingClientRect();
    const trackPercent = (event.pageX - currentTargetRect.left) / currentTargetRect.width;
    onProgressBarClick(trackPercent);
  };

  return (
    <div className={classes.progressToolbarContainer}>
      <div className={classes.toolbarButtonsContainer}>
        <AddActionsGroup
          disabled={!canAddMarkup}
          onAddAnnotation={onAddAnnotationClick}
          onAddComment={onAddCommentClick}
        />
        <SquareButton
          active={isPlaying}
          icon={isPlaying ? 'pause' : 'play'}
          variant="transparent"
          onClick={onTogglePlayClick}
        />
      </div>
      <Timer time={currentTime} primary />
      <div className={classes.progressBarContainer}>
        <LinearProgress
          onClick={onLinearProgressClick}
          className={classes.progressBar}
          variant="determinate"
          value={(currentTime / duration) * 100}
        />
        {annotationData.map(annotation => {
          return (
            <ProgressBarAnnotation
              key={annotation.annotationRef}
              value={annotation.annotationRef}
              left={annotation.time / duration}
              onAnnotationClick={onAnnotationClick}
              isActive={highlightedAnnotationRef === annotation.annotationRef}
            />
          );
        })}
      </div>
      <Timer time={duration} />
      <Slider {...sliderProps} />
    </div>
  );
};

export default ProgressToolbar;

import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import AutoTable from '../../../../../../../shared/components/table/AutoTable';
import { CELL_TYPES } from '../../../../../../../shared/constants';
import QuickViewDrawer from '../../../../../../../shared/components/common/QuickViewDrawer';
import PaymentRequestQuickView from './PaymentRequestQuickView';

const PaymentRequestsList = props => {
  const {
    job,
    statusTypes: { enumValues }
  } = props;
  const {
    totalValue,
    currencyCode,
    paymentRequests: { edges }
  } = job;
  const [selectedId, setSelectedId] = useState(null);

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const getEdgesWithBalance = () => {
    const mappedEdges = edges
      .map(({ node: { id, created, requestId, paymentAmount, status } }) => ({
        node: {
          id,
          created,
          requestId,
          paymentAmount,
          currencyCode,
          status: { code: status }
        }
      }))
      .reverse();

    let balance = totalValue;

    for (const edge of mappedEdges) {
      balance -= edge.node.paymentAmount;
      edge.node.balance = balance;
    }

    return mappedEdges.reverse();
  };

  return (
    <>
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <PaymentRequestQuickView selectedId={selectedId} />
      </QuickViewDrawer>
      <AutoTable
        rowTemplate={[
          { name: 'quickView', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
          { name: 'requestId', label: 'Payment Request ID' },
          { name: 'created', label: 'Date', type: CELL_TYPES.date },
          {
            name: 'status',
            label: 'Status',
            type: CELL_TYPES.status,
            statusTypes: enumValues,
            variant: 'paymentRequests'
          },
          { name: 'paymentAmount', label: 'Payment Amount', type: CELL_TYPES.amount, width: 150 },
          { name: 'balance', label: 'Balance', type: CELL_TYPES.amount }
        ]}
        edges={getEdgesWithBalance()}
      />
    </>
  );
};

export default createFragmentContainer(PaymentRequestsList, {
  job: graphql`
    fragment PaymentRequestsList_job on JobNode {
      id
      totalValue
      currencyCode
      paymentRequests(orderBy: "-created") {
        edges {
          node {
            id
            created
            requestId
            paymentAmount
            status
          }
        }
      }
    }
  `,
  statusTypes: graphql`
    fragment PaymentRequestsList_statusTypes on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

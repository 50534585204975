import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import JobDetails from './JobDetails';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import { PAGINATION_TYPES } from '../../../shared/constants';

const JobDetailsPage = props => {
  return (
    <QueryRenderer
      query={graphql`
        query JobDetailsPageQuery($id: ID!, $first: Int, $orderBy: String) {
          job(id: $id) {
            ...JobDetailsToggleFollowButton_job
            ...JobDetails_job
            ...JobDetailsTab_job
            ...JobCommentThread_job
            ...JobDeliverablesTab_job
            ...JobContractTab_job
            ...JobToolsTab_job
          }
          jobState: __type(name: "JobStatus") {
            ...JobDetails_stateTypes
          }
          deliverableState: __type(name: "DeliverableState") {
            ...JobDeliverablesTab_stateTypes
          }
        }
      `}
      variables={{ id: props.match.params.id, first: PAGINATION_TYPES.default.defaultValue }}
      render={relayProps => (
        <JobDetails
          job={relayProps.job}
          stateTypes={relayProps.jobState}
          relayProps={relayProps}
          {...props}
        />
      )}
    />
  );
};

export default JobDetailsPage;

import React, { Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import DeliverableActionLogList from './DeliverableActionLogList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import DeliverableToolsActions from './DeliverableToolsActions';

const DeliverableToolsTab = props => {
  const variables = useTableHistoryQueryVariables();
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableToolsTabQuery($id: ID!, $first: Int, $after: String) {
          deliverable(id: $id) {
            ...DeliverableToolsActions_deliverable
            ...DeliverableActionLogList_deliverable
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <Fragment>
          <DeliverableToolsActions deliverable={relayProps.deliverable} {...props} />
          <DeliverableActionLogList deliverable={relayProps.deliverable} {...props} />
        </Fragment>
      )}
    />
  );
};

export default withTableHistoryProvider(DeliverableToolsTab, 'orgDelTools', {
  rowsPerPage: 10
});

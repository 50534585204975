import React, { Fragment, useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import AutoTable from '../../../shared/components/table/AutoTable';
import SolicitationQuickView from './SolicitationQuickView';
import SolicitationsListFilters from './SolicitationsListFilters';
import QuickViewDrawer from '../../../shared/components/common/QuickViewDrawer';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import useInterval from '../../../shared/hooks/useInterval';
import useUpdateEffect from '../../../shared/hooks/useUpdateEffect';
import { useEffectWithStatus } from '../../../shared/hooks/useEffectWithStatus';
import DeleteSolicitationsMutation from './mutations/DeleteSolicitationsMutation';
import MarkClosedSolicitationBatchMutation from './mutations/MarkClosedSolicitationsBatchMutation';
import getFetchNotifications from '../../../shared/mutations/notifications/fetchNotifications';
import { CELL_TYPES, SOLICITATION_STATES, ALLOWED_ACTIONS } from '../../../shared/constants';
import { successToast } from '../../../shared/toasts';
import { computePermissionRole } from '../../../shared/utils/helpers';
import { resolveReleaseLink, resolveProductLink } from '../../../shared/utils/linkResolvers';

const ACTION_BUTTON_OPTIONS = {
  markAsClosed: { name: 'markAsClosed', label: 'Mark as Closed' },
  delete: { name: 'delete', label: 'Delete' }
};

const SolicitationsList = props => {
  const {
    relay: { refetch },
    solicitations: {
      allSolicitations: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    },
    solicitation,
    stateTypes,
    userContext: {
      id: currentUserId,
      orgStaff: {
        allowedActions,
        organization: { configuration }
      }
    }
  } = props;

  const [selectedId, setSelectedId] = useState(null);
  const [refetchCounter, setRefetchCounter] = useState(props.refetchCounter);
  const [notifications, setNotifications] = useState(null);
  const [actionButtonValues, setActionButtonValues] = useState(
    Object.values(ACTION_BUTTON_OPTIONS)
  );

  const navigation = JSON.parse(configuration).navigation;

  useUpdateEffect(() => {
    setRefetchCounter(prev => prev + 1);
  }, [props.refetchCounter]);

  useInterval(() => refetchNotifications(), 60000);

  useEffectWithStatus(status => {
    refetchNotifications(status);
  }, []);

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const refetchNotifications = getFetchNotifications('solicitation', setNotifications);

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    const { history, match } = props;
    history.push(`${match.url}/${id}`);
  };

  const onActionClickHandler = (action, checked) => {
    if (ACTION_BUTTON_OPTIONS.markAsClosed.name === action) {
      handleMarkClosed(checked);
    } else if (ACTION_BUTTON_OPTIONS.delete.name === action) {
      handleDelete(checked);
    }
  };

  const handleMarkClosed = checked => {
    MarkClosedSolicitationBatchMutation(checked, success => {
      if (success) {
        successToast(
          `${checked.length} Job Opportunit${
            checked.length === 1 ? 'y was' : 'ies were'
          } marked as closed.`
        );
        setRefetchCounter(prev => prev + 1);
      }
    });
  };

  const handleDelete = checked => {
    DeleteSolicitationsMutation(checked, success => {
      if (success) {
        successToast(
          `${checked.length} Job Opportunit${checked.length === 1 ? 'y was' : 'ies were'} deleted.`
        );
        setRefetchCounter(prev => prev + 1);
      }
    });
  };

  const disabledActionsCheckHandler = checked => {
    const disabledActions = new Set();

    const canUserDelete = solicitation => {
      return (
        solicitation.status.code === SOLICITATION_STATES.draft &&
        solicitation.staff.id === currentUserId &&
        computePermissionRole(
          ALLOWED_ACTIONS.SOLICITATION_DELETE_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        )
      );
    };
    const canUserCloseOwn = computePermissionRole(
      [
        ALLOWED_ACTIONS.SOLICITATION_MARK_CLOSED_ASSIGNED_TO_AUTHENTICATED_USER,
        ALLOWED_ACTIONS.SOLICITATION_DELETE_ASSIGNED_TO_ANOTHER_USER
      ],
      allowedActions
    );
    const canUserCloseOther = computePermissionRole(
      ALLOWED_ACTIONS.SOLICITATION_DELETE_ASSIGNED_TO_ANOTHER_USER,
      allowedActions
    );
    const canUserClose = solicitation => {
      return (
        solicitation.status.code === SOLICITATION_STATES.active &&
        ((solicitation.staff.id === currentUserId && canUserCloseOwn) || canUserCloseOther)
      );
    };
    let checked_index = 0;
    while (disabledActions.size !== 2 && checked_index < checked.length) {
      const solicitation = checked[checked_index].node;
      !canUserDelete(solicitation) && disabledActions.add(ACTION_BUTTON_OPTIONS.delete.name);
      !canUserClose(solicitation) && disabledActions.add(ACTION_BUTTON_OPTIONS.markAsClosed.name);
      checked_index++;
    }

    // Based on checked items we set if confirmation dialog is needed for mark Closed action
    let foundWithAllocatedNotContracted = false;
    for (let i = 0; i < checked.length; i++) {
      if (
        checked[i].node.numberOfAllocatedDeliverables !==
        checked[i].node.numberOfAllocatedContractedDeliverables
      ) {
        foundWithAllocatedNotContracted = true;
      }
      if (foundWithAllocatedNotContracted) {
        break;
      }
    }
    if (foundWithAllocatedNotContracted) {
      setActionButtonValues(
        Object.values({
          ...ACTION_BUTTON_OPTIONS,
          markAsClosed: {
            ...ACTION_BUTTON_OPTIONS.markAsClosed,
            confirmText:
              'Some of selected JobOpps have allocated Deliverables that are not contracted yet. Closing the JobOpps will deallocate the Deliverables. Do you want to proceed?'
          }
        })
      );
    }

    return Array.from(disabledActions);
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      created,
      solicitationId,
      staffCoordinator: {
        fullName: name,
        representativeImageUrl: imageUrl,
        user: { id: userId }
      },
      releases,
      subject,
      replyByDate,
      numberOfContractors,
      numberOfDeliverables,
      state: stateCode,
      totalDeliverableAmount,
      numberOfAllocatedContractedDeliverables,
      numberOfAllocatedDeliverables
    } = edge.node;
    let messageType = 'none';
    if (stateCode === SOLICITATION_STATES.active && notifications) {
      if (notifications.edges.find(element => element.node.targetObjectId === solicitationId)) {
        messageType = 'new';
      }
    }
    return {
      node: {
        id,
        created,
        subject,
        replyByDate,
        numberOfContractors,
        numberOfDeliverables,
        releases: releases.map(el => {
          return {
            cellText: el.name,
            cellLink: resolveReleaseLink(el.id)
          };
        }),
        products: releases.map(el => {
          return {
            cellText: el.product.title,
            cellLink: resolveProductLink(el.product.id)
          };
        }),
        status: { code: stateCode },
        staff: { name, imageUrl, id: userId },
        totalDeliverableAmount,
        message: { type: messageType, size: 18, cursor: false },
        // last two used to check if confirmation dialog is needed when trying to mark as closed
        numberOfAllocatedDeliverables,
        numberOfAllocatedContractedDeliverables
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
          { name: '', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
          {
            name: 'subject',
            label: 'Subject',
            type: CELL_TYPES.link,
            sortable: true
          },
          { name: 'message', label: '', type: CELL_TYPES.message },
          {
            name: 'releases',
            label: navigation.releaseTitle.plural,
            type: CELL_TYPES.listWithMultiple,
            multipleValue: 'Multiple ' + navigation.releaseTitle.plural,
            width: 100
          },
          {
            name: 'products',
            label: navigation.productTitle.plural,
            type: CELL_TYPES.listWithMultiple,
            multipleValue: 'Multiple ' + navigation.productTitle.plural
          },
          {
            name: 'created',
            label: 'Created on',
            sortable: true,
            noWrap: true,
            type: CELL_TYPES.date
          },
          {
            name: 'replyByDate',
            label: 'Reply By',
            sortable: true,
            type: CELL_TYPES.date,
            width: 95
          },
          {
            name: 'status',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: stateTypes.enumValues,
            variant: 'solicitation'
          },
          {
            name: 'staff',
            label: 'Staff',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            width: 76,
            align: 'center'
          },
          { name: 'numberOfContractors', label: 'Contractors', align: 'right' },
          { name: 'numberOfDeliverables', label: 'Deliverables', align: 'right' },
          {
            name: 'totalDeliverableAmount',
            label: 'Total',
            type: CELL_TYPES.amount
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        labelledByHeader="select all solicitations"
        refetchCounter={refetchCounter}
        selectedId={selectedId}
        filterProps={{
          filterComponent: <SolicitationsListFilters stateOptions={stateTypes.enumValues} />,
          withApply: true
        }}
        rowProps={{
          handleLinkClick: onLinkClickHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
        actionButtonProps={{
          options: actionButtonValues,
          handleMenuItemClick: onActionClickHandler,
          getDisabledActions: disabledActionsCheckHandler
        }}
        withBackground
      />
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <SolicitationQuickView solicitationId={selectedId} solicitation={solicitation} />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withRouter(
  withUserContext(
    createRefetchContainer(
      SolicitationsList,
      {
        solicitations: graphql`
          fragment SolicitationsList_solicitations on Query {
            allSolicitations(
              first: $first
              orderBy: $orderBy
              after: $after
              replyByFrom: $replyByFrom
              replyByTo: $replyByTo
              state: $state
              subject: $subject
              staffCoordinator: $staffCoordinator
              includeClosed: $includeClosed
            ) {
              pageInfo {
                hasNextPage
                endCursor
              }
              totalCount
              edgeCount
              edges {
                node {
                  id
                  created
                  subject
                  replyByDate
                  staffCoordinator {
                    fullName
                    representativeImageUrl
                    user {
                      id
                    }
                  }
                  releases {
                    name
                    id
                    product {
                      title
                      id
                    }
                  }
                  state
                  numberOfContractors
                  numberOfDeliverables
                  totalDeliverableAmount
                  solicitationId
                  numberOfAllocatedDeliverables
                  numberOfAllocatedContractedDeliverables
                }
              }
            }
          }
        `,
        stateTypes: graphql`
          fragment SolicitationsList_stateTypes on __Type {
            enumValues {
              name
              description
            }
          }
        `
      },
      graphql`
        query SolicitationsListRefetchQuery(
          $first: Int
          $orderBy: String
          $after: String
          $replyByFrom: Date
          $replyByTo: Date
          $state: String
          $subject: String
          $staffCoordinator: ID
          $includeClosed: Boolean
        ) {
          ...SolicitationsList_solicitations
        }
      `
    )
  )
);

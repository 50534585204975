import { makeStyles } from '@material-ui/core/styles';

export const regularMenuWidth = 200;
export const menuWithContextActionWidth = 290;

const appStyles = makeStyles(theme => ({
  buttonGroup: {
    boxShadow: 'none'
  },
  menuPopper: {
    transform: 'translate3d(20px, 61px, 0px) !important',
    width: props =>
      `${props.willShowAnyContextActions ? menuWithContextActionWidth : regularMenuWidth}px`
  },

  primaryButton: {
    backgroundColor: theme.palette.colors.orange,
    fontWeight: 'bold',
    textTransform: 'none',
    color: 'white',
    boxShadow: 'none',
    borderColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.colors.darkOrange
    }
  },
  divider: {
    fontWeight: 'bold',
    color: theme.palette.colors.blueGrey,
    opacity: '75%',
    fontSize: '12px'
  }
}));

export default appStyles;

import React, { Fragment, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import DeliverablesListFilters from './DeliverablesListFilters';
import { CELL_TYPES } from '../../../shared/constants';
import useInterval from '../../../shared/hooks/useInterval';
import getFetchNotifications from '../../../shared/mutations/notifications/fetchNotifications';
import AutoTable from '../../../shared/components/table/AutoTable';
import { tableStyles } from '../../../shared/styles/common/tableStyles';
import ContractPreviewWithSignaturePad from '../jobs/ContractPreviewWithSignaturePad';
import { useEffectWithStatus } from '../../../shared/hooks/useEffectWithStatus';

const DeliverableList = props => {
  const {
    relay: { refetch },
    deliverables: { allDeliverables },
    stateTypes,
    history,
    match
  } = props;

  const [showContract, setShowContract] = useState(null);
  const [notifications, setNotifications] = useState(null);

  useEffectWithStatus(status => {
    refetchNotifications(status);
  }, []);

  useInterval(() => refetchNotifications(), 60000);

  if (!allDeliverables) return <div>Something went wrong!</div>;

  const {
    edges,
    totalCount,
    edgeCount,
    pageInfo: { hasNextPage, endCursor }
  } = allDeliverables;

  const refetchNotifications = getFetchNotifications('deliverable', setNotifications);

  const onLinkClickWithIconHandler = jobIdForLink => {
    history.push(`/jobs/contracts/${jobIdForLink}`);
  };

  const onChangeHandler = variables => refetch({ ...variables, status: '5' });

  const onLinkClickHandler = id => {
    history.push(`${match.url}/${id}`);
  };

  const flattenedEdges = edges.map(edge => {
    const { id, deliverableId, title, state, assignedStaff, job, reviewStep } = edge.node;

    let newComment = false;
    if (notifications) {
      if (
        notifications.edges.find(element => Number(element.node.targetObjectId) === deliverableId)
      ) {
        newComment = true;
      }
    }
    const staff = assignedStaff
      ? {
          name: assignedStaff.fullName,
          imageUrl: assignedStaff.representativeImageUrl,
          withBadge: newComment
        }
      : null;

    const organization = assignedStaff
      ? {
          name: assignedStaff.organization.name,
          imageUrl: assignedStaff.organization.representativeImageUrl
        }
      : null;

    const contract = job ? { name: job.id, tooltip: job.name, iconName: 'job' } : null;

    return {
      node: {
        id,
        title,
        state: { code: state, reviewStep },
        staff,
        organization,
        jobCompletionDate: job ? job.jobDeadline : null,
        contract
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: 'title', label: 'Name', type: CELL_TYPES.link, sortable: true, width: '40%' },
          {
            name: 'contract',
            label: 'Job',
            type: CELL_TYPES.linkWithIcon,
            width: 30,
            align: 'center'
          },
          { name: 'jobCompletionDate', label: 'Job Deadline', type: CELL_TYPES.date },
          {
            name: 'state',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: stateTypes.enumValues
          },
          {
            name: 'staff',
            label: 'Contact',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center'
          },
          {
            name: 'organization',
            label: 'Organization',
            type: CELL_TYPES.avatar,
            icon: 'organization'
          }
        ]}
        onChangeHandler={onChangeHandler}
        edges={flattenedEdges}
        paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
        rowProps={{
          handleLinkClick: onLinkClickHandler,
          handleLinkClickWithIcon: onLinkClickWithIconHandler
        }}
        filterProps={{
          filterComponent: <DeliverablesListFilters stateOptions={stateTypes.enumValues} />,
          withApply: true
        }}
        withBackground
      />
      {showContract ? (
        <ContractPreviewWithSignaturePad
          contract={showContract}
          showContract={showContract ? true : false}
          setShowContract={setShowContract}
        />
      ) : null}
    </Fragment>
  );
};

export default withRouter(
  withStyles(tableStyles)(
    createRefetchContainer(
      DeliverableList,
      {
        deliverables: graphql`
          fragment DeliverableList_deliverables on Query {
            allDeliverables(
              first: $first
              after: $after
              orderBy: $orderBy
              title: $title
              state: $state
              assignedStaff: $assignedStaff
              jobDeadlineFrom: $jobDeadlineFrom
              jobDeadlineTo: $jobDeadlineTo
              organization: $organization
              includeCompleted: $includeCompleted
              includeClosed: $includeClosed
              includeCanceled: $includeCanceled
            ) {
              edges {
                cursor
                node {
                  id
                  deliverableId
                  title
                  state
                  job {
                    id
                    contract {
                      id
                      contractFileUrl
                      job {
                        status
                      }
                    }
                    jobDeadline
                    name
                  }
                  reviewStep
                  assignedStaff {
                    fullName
                    representativeImageUrl
                    organization {
                      name
                      representativeImageUrl
                    }
                  }
                }
              }
              totalCount
              edgeCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
            }
          }
        `,
        stateTypes: graphql`
          fragment DeliverableList_stateTypes on __Type {
            enumValues {
              name
              description
            }
          }
        `
      },

      graphql`
        query DeliverableListRefetchQuery(
          $first: Int
          $after: String
          $orderBy: String
          $title: String
          $state: String
          $assignedStaff: ID
          $jobDeadlineFrom: Date
          $jobDeadlineTo: Date
          $organization: String
          $includeClosed: Boolean
          $includeCompleted: Boolean
          $includeCanceled: Boolean
        ) {
          ...DeliverableList_deliverables
        }
      `
    )
  )
);

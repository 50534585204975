import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DropdownTooltip from '../../../UI/DropdownTooltip';
import QuillContext from '../../QuillContext';
import { Button } from './styled';

const HeadingSelectDropdownContainer = styled.div`
  padding: 5px 0;
`;

const HeadingSelectDropdownItem = styled(Typography)
  .withConfig({
    shouldForwardProp: prop => !['active'].includes(prop)
  })
  .attrs({
    component: 'button'
  })`
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 5px;
  outline: none;

  && {
    margin-bottom: 5px;
    ${props =>
      props.active
        ? css`
            color: ${props => props.theme.palette.primary.main};
          `
        : css`
            &:hover {
              color: ${props => props.theme.palette.colors.activeDarkGrey};
            }
          `};
  }
`;

const BLOCK_BUTTONS = [
  { type: 'h1', val: 1, label: 'Heading 1' },
  { type: 'h2', val: 2, label: 'Heading 2' },
  { type: 'h3', val: 3, label: 'Heading 3' }
];

const HeadingSelectButton = ({ disabled }) => {
  const { quillRef, updateRenderKey } = useContext(QuillContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const activeHeader = disabled ? null : quillRef.current.getFormat().header;

  const handleSelect = val => e => {
    e.preventDefault();
    quillRef.current.format('header', activeHeader === val ? false : val);
    updateRenderKey();
    setIsDropdownOpen(false);
  };

  return (
    <DropdownTooltip
      open={isDropdownOpen}
      onClose={() => setIsDropdownOpen(false)}
      title={
        <HeadingSelectDropdownContainer>
          {BLOCK_BUTTONS.map(({ type, label, val }) => (
            <HeadingSelectDropdownItem
              key={type}
              variant={type}
              active={val === activeHeader}
              onMouseDown={handleSelect(val)}
            >
              {label}
            </HeadingSelectDropdownItem>
          ))}
        </HeadingSelectDropdownContainer>
      }
    >
      <Button
        disabled={disabled}
        active={!!activeHeader}
        onMouseDown={e => {
          e.preventDefault();
          setIsDropdownOpen(val => !val);
        }}
        icon={TextFieldsIcon}
      />
    </DropdownTooltip>
  );
};

export default HeadingSelectButton;

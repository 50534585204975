import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Star } from '../../images/star.svg';
import { ReactComponent as StarOrange } from '../../images/star-orange.svg';

const RatingStars = props => {
  const { rating } = props;

  const renderStars = () => {
    if (rating || rating === 0) {
      const stars = Array(5).fill(false);
      stars.fill(true, 0, rating);
      return stars.map((star, index) => (star ? <StarOrange key={index} /> : <Star key={index} />));
    } else {
      return 'N/A';
    }
  };

  return <Fragment>{renderStars()}</Fragment>;
};

RatingStars.propTypes = {
  rating: PropTypes.number.isRequired
};

export default RatingStars;

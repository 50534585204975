/**
 * @flow
 * @relayHash 2e6ab751bec5a9f8ceeda50874d53ff2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type SearchableDeliverableDropdownQueryVariables = {|
  title?: ?string,
  first?: ?number,
  state?: ?string,
  excludeAllocated?: ?boolean,
  onlyInSolicitation?: ?string,
  category?: ?string,
  excludeInSolicitation?: ?string,
  currencyCode?: ?string,
  onlyDraftOrReady?: ?boolean,
  assignedToMeOnly?: ?boolean,
|};
export type SearchableDeliverableDropdownQueryResponse = {|
  +allDeliverables: ?{|
    +edgeCount: ?number,
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
        +release: ?{|
          +product: {|
            +title: string
          |}
        |},
        +category: {|
          +id: string,
          +name: string,
        |},
        +state: DeliverableState,
        +amount: ?any,
        +dueDate: ?any,
        +currencyCode: ?DeliverableCurrencyCode,
        +amountInHomeCurrency: ?any,
        +assignedStaff: ?{|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |},
      |}
    |}>,
  |}
|};
export type SearchableDeliverableDropdownQuery = {|
  variables: SearchableDeliverableDropdownQueryVariables,
  response: SearchableDeliverableDropdownQueryResponse,
|};
*/


/*
query SearchableDeliverableDropdownQuery(
  $title: String
  $first: Int
  $state: String
  $excludeAllocated: Boolean
  $onlyInSolicitation: String
  $category: ID
  $excludeInSolicitation: String
  $currencyCode: String
  $onlyDraftOrReady: Boolean
  $assignedToMeOnly: Boolean
) {
  allDeliverables(title: $title, orderBy: "title", state: $state, first: $first, excludeAllocated: $excludeAllocated, onlyInSolicitation: $onlyInSolicitation, assignedToMeOnly: $assignedToMeOnly, category: $category, excludeInSolicitation: $excludeInSolicitation, currencyCode: $currencyCode, onlyDraftOrReady: $onlyDraftOrReady) {
    edgeCount
    totalCount
    edges {
      node {
        id
        title
        release {
          product {
            title
            id
          }
          id
        }
        category {
          id
          name
        }
        state
        amount
        dueDate
        currencyCode
        amountInHomeCurrency
        assignedStaff {
          id
          fullName
          representativeImageUrl
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "state",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "excludeAllocated",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "onlyInSolicitation",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "excludeInSolicitation",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currencyCode",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "onlyDraftOrReady",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "assignedToMeOnly",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "assignedToMeOnly",
    "variableName": "assignedToMeOnly"
  },
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category"
  },
  {
    "kind": "Variable",
    "name": "currencyCode",
    "variableName": "currencyCode"
  },
  {
    "kind": "Variable",
    "name": "excludeAllocated",
    "variableName": "excludeAllocated"
  },
  {
    "kind": "Variable",
    "name": "excludeInSolicitation",
    "variableName": "excludeInSolicitation"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "onlyDraftOrReady",
    "variableName": "onlyDraftOrReady"
  },
  {
    "kind": "Variable",
    "name": "onlyInSolicitation",
    "variableName": "onlyInSolicitation"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "title"
  },
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "state"
  },
  {
    "kind": "Variable",
    "name": "title",
    "variableName": "title"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "edgeCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryNode",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dueDate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyCode",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amountInHomeCurrency",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "assignedStaff",
  "storageKey": null,
  "args": null,
  "concreteType": "StaffNode",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "representativeImageUrl",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SearchableDeliverableDropdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allDeliverables",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNodeConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "release",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReleaseNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "product",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchableDeliverableDropdownQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allDeliverables",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNodeConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "release",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReleaseNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "product",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SearchableDeliverableDropdownQuery",
    "id": null,
    "text": "query SearchableDeliverableDropdownQuery(\n  $title: String\n  $first: Int\n  $state: String\n  $excludeAllocated: Boolean\n  $onlyInSolicitation: String\n  $category: ID\n  $excludeInSolicitation: String\n  $currencyCode: String\n  $onlyDraftOrReady: Boolean\n  $assignedToMeOnly: Boolean\n) {\n  allDeliverables(title: $title, orderBy: \"title\", state: $state, first: $first, excludeAllocated: $excludeAllocated, onlyInSolicitation: $onlyInSolicitation, assignedToMeOnly: $assignedToMeOnly, category: $category, excludeInSolicitation: $excludeInSolicitation, currencyCode: $currencyCode, onlyDraftOrReady: $onlyDraftOrReady) {\n    edgeCount\n    totalCount\n    edges {\n      node {\n        id\n        title\n        release {\n          product {\n            title\n            id\n          }\n          id\n        }\n        category {\n          id\n          name\n        }\n        state\n        amount\n        dueDate\n        currencyCode\n        amountInHomeCurrency\n        assignedStaff {\n          id\n          fullName\n          representativeImageUrl\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f843edcc08f677a1658e4518fb2b2b22';
module.exports = node;

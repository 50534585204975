import React from 'react';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import graphql from 'babel-plugin-relay/macro';

import OrganizationProfile from './OrganizationProfile';

const OrganizationProfilePage = props => (
  <QueryRenderer
    query={graphql`
      query OrganizationProfilePageQuery {
        organization {
          ...OrganizationProfile_organization
        }
      }
    `}
    render={relayProps => <OrganizationProfile organization={relayProps.organization} {...props} />}
  />
);

export default OrganizationProfilePage;

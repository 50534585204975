import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';

const productQuery = graphql`
  query SearchableProductDropdownQuery(
    $title: String
    $orderBy: String
    $first: Int
    $isActive: Boolean
  ) {
    allProducts(title: $title, orderBy: $orderBy, first: $first, isActive: $isActive) {
      edgeCount
      totalCount
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const SearchableProductDropdown = props => {
  const {
    onChange,
    userContext: {
      orgStaff: { organization }
    },
    ...otherProps
  } = props;
  const navigation = JSON.parse(organization.configuration).navigation;

  const getFetchVariables = inputValue => {
    return {
      title: inputValue,
      first: 10,
      orderBy: '-created',
      isActive: true
    };
  };

  const formatOptions = products => {
    if (!products || !products.allProducts) {
      return [];
    }
    return products.allProducts.edges.map(product => {
      return { value: product.node.id, label: product.node.title };
    });
  };

  return (
    <SearchableDropdown
      query={productQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      resultKey="allProducts"
      value={props.value}
      selectChangeHandler={onChange}
      label={navigation.productTitle.singular}
      placeholder={`Select ${navigation.productTitle.singular}...`}
      {...otherProps}
    />
  );
};

export default withUserContext(SearchableProductDropdown);

import { makeStyles } from '@material-ui/core/styles';

export const detailsStyles = theme => ({
  topSectionHeight: {
    minHeight: '140px'
  },
  topSectionHeightSmall: {
    minHeight: '70px'
  },
  contractorsTopSectionHeight: {
    alignItems: 'flex-start',
    minHeight: '70px'
  },
  container: {
    display: 'flex'
  },
  halfWidth: {
    width: '50%'
  },
  containerSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  containerAlignFlexStart: {
    display: 'flex',
    alignSelf: 'flex-start'
  },
  containerJustifyFlexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  alignFlexStart: {
    alignSelf: 'flex-start'
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  containerColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  containerSmall: {
    width: '50%',
    fontWeight: 'bold',
    display: 'flex',
    marginTop: '20px',
    paddingBottom: '10px',
    alignItems: 'center'
  },
  titleMarginRight: {
    marginRight: '30px'
  },
  titleMarginNoTabs: {
    margin: '30px 0 10px'
  },
  marginBottom: {
    marginBottom: '30px'
  },
  marginTop: {
    marginTop: '15px'
  },
  greyBorderBottom: {
    borderBottom: `1px solid ${theme.palette.colors.lightestGrey}`
  },
  stateIndicatorContainer: {
    height: '30px'
  },
  stateIndicator: {
    padding: props => (props.noPadding ? '' : '18px 8px'),
    fontWeight: 'bold',
    '& svg': {
      height: '0.4em',
      width: '0.4em',
      marginRight: '4px'
    }
  },
  colorRed: {
    color: theme.palette.colors.red
  },
  colorOrange: {
    color: theme.palette.colors.orange
  },
  colorDefaultGrey: {
    color: theme.palette.colors.grey
  },
  colorYellow: {
    color: theme.palette.colors.yellow
  },
  colorGreen: {
    color: theme.palette.colors.green
  },
  colorMediumBlueGrey: {
    color: theme.palette.colors.mediumBlueGrey
  },
  colorDarkBlueGrey: {
    color: theme.palette.colors.darkBlueGrey
  },

  contractGridContainer: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `"contractFirst contractSecond"`
  },
  contractFirst: { gridArea: 'contractFirst' },

  contractSecond: { gridArea: 'contractSecond' },

  borderMarginTop: {
    borderBottom: '1px solid #D3D7E7',
    margin: '40px 0 40px 0'
  },

  formField: {
    width: '300px',
    marginTop: '30px'
  },

  formFieldNoMargin: {
    width: '300px'
  },
  productThumbnailImage: {
    marginLeft: '30px',
    objectFit: 'cover',
    borderRadius: '3px'
  },
  productImage: {
    objectFit: 'cover',
    borderRadius: '3px'
  },
  width100: {
    width: '100%'
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.colors.lightestGrey}`
  },

  deliverableContainerPadding: {
    paddingLeft: '30px'
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px'
  },
  buttonRowRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  detailsContentNoTabs: {
    backgroundColor: 'white',
    marginLeft: '-30px',
    padding: '30px',
    borderRadius: '4px'
  },
  solicitationLeft: {
    width: '50%',
    paddingRight: '24px'
  },
  solicitationRight: {
    width: '50%',
    paddingLeft: '24px'
  },

  detailsContentNoTitle: {
    marginTop: '-40px'
  },
  profileLeft: {
    width: '20%',
    padding: '30px 50px 30px 30px'
  },
  profileRight: {
    width: '80%',
    padding: '30px 50px 30px 30px'
  },

  permissionLabel: {
    '& label .MuiFormControlLabel-label': {
      color: theme.palette.colors.darkBlueGrey,
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '19px'
    },
    '& label .MuiFormControlLabel-label.Mui-disabled': {
      color: props =>
        props.isEditing ? theme.palette.colors.mediumBlueGrey : theme.palette.colors.darkBlueGrey
    },
    '& label .Mui-checked.Mui-disabled': {
      color: props =>
        props.isEditing ? theme.palette.colors.lightOrange : theme.palette.colors.orange
    }
  },
  addToListButton: {
    margin: '20px 0'
  },
  fileSizeContainer: {
    marginLeft: '12px',
    marginBottom: '45px',
    '& .MuiGrid-container': {
      backgroundColor: theme.palette.colors.lightBlueGrey,
      opacity: '0.8',
      borderRadius: '4px'
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'white'
    }
  },
  storageOverviewRow: {
    paddingTop: '10px',
    paddingBottom: '10px',
    borderBottom: `1px solid ${theme.palette.colors.lightestGrey}`
  },
  storageTotalRow: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  storageOverviewColumn: {
    paddingRight: '60px',
    marginRight: '60px'
  },
  dueDatesWarningRow: {
    display: 'flex',
    padding: '22px 16px',
    alignItems: 'center',

    '& p': {
      color: theme.palette.colors.mediumBlueGrey
    },

    '& div.MuiTextField-root': {
      width: '45px',
      marginLeft: '10px',
      marginRight: '10px',
      height: '34px',
      '& input': {
        padding: '10px 15px',
        textAlign: 'center'
      }
    }
  },
  dueDatesWarningRowGrey: {
    backgroundColor: theme.palette.colors.lightBlueGrey
  },
  disabledInputField: {
    '& label': {
      fontWeight: 'bold',
      color: `${theme.palette.colors.mediumBlueGrey} !important`
    }
  }
});

export const useDetailsStyles = makeStyles(detailsStyles);

import React, { useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Box } from '@material-ui/core';
import { CELL_TYPES } from '../../../../shared/constants';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import Detail from '../../../../shared/components/UI/Detail';
import AutoTable from '../../../../shared/components/table/AutoTable';
import { formatAmount } from '../../../../shared/utils/formatters';
import QuickViewDrawer from '../../../../shared/components/common/QuickViewDrawer';
import InvoiceQuickView from '../../invoices/InvoiceQuickView';
import CreateInvoiceDialog from '../CreateInvoiceDialog';

const JobInvoicesTab = props => {
  const {
    relay: { refetch },
    statusTypes: { enumValues: statusEnumValues },
    job: {
      id,
      currencyCode,
      totalValue,
      totalInvoicesAmount,
      totalAmountOfPaymentReceived,
      invoices: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    }
  } = props;
  const [tableRefetchCounter, setTableRefetchCounter] = useState(0);
  const [showCreateInvoiceDialog, setShowCreateInvoiceDialog] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const handleQuickViewToggle = id => setSelectedInvoiceId(val => (val === id ? null : id));

  const flattenedEdges = edges.map(({ node }) => {
    const { id, identifier, status, created, amount } = node;

    return {
      node: {
        id,
        currencyCode,
        identifier,
        status: { code: status },
        created,
        amount
      }
    };
  });

  return (
    <>
      <QuickViewDrawer selectedId={selectedInvoiceId} setSelectedId={setSelectedInvoiceId}>
        <InvoiceQuickView invoiceId={selectedInvoiceId} />
      </QuickViewDrawer>

      <CreateInvoiceDialog
        isDialogOpen={showCreateInvoiceDialog}
        onClose={() => setShowCreateInvoiceDialog(false)}
        jobId={id}
        onCreated={() => setTableRefetchCounter(val => val + 1)}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" maxWidth={1270} mb={1}>
        <GlobalButton handleClick={() => setShowCreateInvoiceDialog(true)} variant="secondary">
          Send Invoice
        </GlobalButton>
        <Detail name="Invoices" value={totalCount} />
        <Detail name="Total Job Amount" value={formatAmount(totalValue, currencyCode)} />
        <Detail name="Invoiced Amount" value={formatAmount(totalInvoicesAmount, currencyCode)} />
        <Detail
          name="Payment Recieved"
          value={formatAmount(totalAmountOfPaymentReceived, currencyCode)}
        />
      </Box>
      <AutoTable
        resetCursorAndRefetchCounter={tableRefetchCounter}
        rowTemplate={[
          { name: 'info', label: '', type: CELL_TYPES.info, onClick: handleQuickViewToggle },
          {
            name: 'identifier',
            label: 'Invoice ID',
            type: CELL_TYPES.link
          },
          {
            name: 'created',
            label: 'Date Submitted',
            type: CELL_TYPES.date
          },
          {
            name: 'status',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: statusEnumValues,
            variant: 'jobInvoice'
          },
          {
            name: 'amount',
            label: 'Invoice Amount',
            type: CELL_TYPES.amount,
            width: '20%'
          }
        ]}
        rowProps={{
          handleLinkClick: handleQuickViewToggle
        }}
        edges={flattenedEdges}
        onChangeHandler={refetch}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
    </>
  );
};

export default createRefetchContainer(
  JobInvoicesTab,
  {
    job: graphql`
      fragment JobInvoicesTab_job on JobNode
      @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
        id
        totalValue
        currencyCode
        totalInvoicesAmount
        totalAmountOfPaymentReceived
        invoices(orderBy: "-created", first: $first, after: $after) {
          totalCount
          edgeCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              identifier
              created
              status
              amount
            }
          }
        }
      }
    `,
    statusTypes: graphql`
      fragment JobInvoicesTab_statusTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  },
  graphql`
    query JobInvoicesTabQuery($id: ID!, $first: Int, $after: String) {
      job(id: $id) {
        ...JobInvoicesTab_job @arguments(after: $after, first: $first)
      }
    }
  `
);

import React, { useState, Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import clsx from 'clsx';
import get from 'lodash/get';
import { createFragmentContainer } from 'react-relay';
import { Formik, Form, Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import StatusIndicator from '../../../../shared/components/UI/StatusIndicator';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import ButtonWithTooltip from '../../../../shared/components/UI/ButtonWithTooltip';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import DetailsRow from '../../../../shared/components/UI/DetailsRow';
import AvatarWithName from '../../../../shared/components/UI/AvatarWithName';
import { ReactComponent as ContractsIcon } from '../../../../shared/images/contracts.svg';
import { CONTRACT_TEMPLATE_STATES } from '../../../../shared/constants';
import FormikTextField from '../../../../shared/components/form/FormikTextField';
import FormikFileInput from '../../../../shared/components/form/FormikFileInput';
import UpdateContractTemplateMutation from './mutations/UpdateContractTemplateMutation';
import MarkContractTemplateActiveMutation from './mutations/MarkContractTemplateActiveMutation';
import MarkContractTemplateInactiveMutation from './mutations/MarkContractTemplateInactiveMutation';
import FullScreenModal from '../../../../shared/components/fullscreenModal/FullscreenModal';
import ContractPreview from '../../../../shared/components/job/ContractPreview';
import DetailsRowDate from '../../../../shared/components/UI/DetailsRowDate';
import WhiteBox from '../../../../shared/components/common/WhiteBox';
import EditToggle from '../../../../shared/components/UI/EditToggle';
import { FormikCategorySelect } from '../../shared/dropdowns/CategorySelect';
import ContractTemplateVariableWarningDialog from '../../shared/CotractTemplateVariableWarningDialog';

const ContractTemplateDetails = props => {
  const {
    stateTypes,
    template: {
      id,
      name,
      state,
      categories,
      createdBy,
      created,
      activationDate,
      docxFileName,
      docxFileUrl,
      samplePdfFileUrl
    }
  } = props;
  const classes = useDetailsStyles();
  const [isEditing, setEditing] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState(null);

  const mappedCategories = () => {
    if (!categories || !categories.edges.length) {
      return '';
    }
    return categories.edges.map(category => category.node.name).join(', ');
  };

  const onMarkActive = () => {
    MarkContractTemplateActiveMutation(id, () => {});
  };

  const onMarkInactive = () => {
    MarkContractTemplateInactiveMutation(id, () => {});
  };

  const onUpdate = (values, { setSubmitting }) =>
    new Promise(resolve => {
      const { name, categories } = values;
      const variables = {
        id,
        name,
        categories: categories ? categories.map(option => option.value) : [],
        ignoreVariablesErrors: pendingUpdate ? ['empty', 'not_supported'] : ['empty']
      };
      // ignore file field if no new file was uploaded
      const file = values.file instanceof File ? values.file : null;

      UpdateContractTemplateMutation(variables, file, response => {
        setSubmitting(false);
        resolve(response);
        if (response) {
          if (response.errors) {
            setPendingUpdate({
              errors: JSON.parse(response.errors),
              values
            });
          } else {
            setPendingUpdate(null);
            setEditing(false);
          }
        }
      });
    });

  const handleDownloadFile = () => {
    window.open(samplePdfFileUrl);
  };

  const renderMarkActiveButton = () => {
    if (categories && categories.edges.length) {
      return (
        <GlobalButton id="markActiveButton" handleClick={onMarkActive}>
          Mark Active
        </GlobalButton>
      );
    } else {
      return (
        <ButtonWithTooltip
          id="markActiveButton"
          disabled={true}
          tooltipText={
            'You must select at least one category before you can activate this template.'
          }
          buttonText="Mark Active"
          variant="primary"
        />
      );
    }
  };

  const validate = values => {
    let errors = {};
    if (state === CONTRACT_TEMPLATE_STATES.active && values.categories.length === 0) {
      errors.categories = 'You must select at least one category.';
    }
    if (!values.name) {
      errors.name = 'Required.';
    }
    return errors;
  };

  const initialValues = {
    name: name || '',
    categories: categories
      ? categories.edges.map(edge => ({ value: edge.node.id, label: edge.node.name }))
      : [],
    file: { name: docxFileName }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onUpdate}
      enableReinitialize
      validate={validate}
    >
      {({ isSubmitting, resetForm }) => (
        <Form>
          <ContractTemplateVariableWarningDialog
            isDialogOpen={pendingUpdate !== null}
            onConfirm={({ actions }) => onUpdate(pendingUpdate.values, actions)}
            closeDialog={() => {
              setPendingUpdate(null);
            }}
            errors={get(pendingUpdate, 'errors.contract_variables')}
          />

          <div className={classes.topSectionHeight}>
            <div className={classes.containerSpaceBetween}>
              <div className={clsx(classes.containerColumn, classes.alignFlexStart)}>
                <div className={classes.titleMarginNoTabs}>
                  <CategoryTitleHeader title={name} icon={ContractsIcon} link="/admin/contracts" />
                  <StatusIndicator
                    statusTypes={stateTypes.enumValues}
                    statusCode={state}
                    variant="contractTemplate"
                  />
                </div>
              </div>
              {!isEditing && (
                <div className={clsx(classes.container, classes.alignFlexStart)}>
                  {state === CONTRACT_TEMPLATE_STATES.draft ||
                  state === CONTRACT_TEMPLATE_STATES.inactive
                    ? renderMarkActiveButton()
                    : null}
                  {state === CONTRACT_TEMPLATE_STATES.active && (
                    <GlobalButton id="markInactiveButton" handleClick={onMarkInactive}>
                      Mark Inactive
                    </GlobalButton>
                  )}
                </div>
              )}
            </div>
          </div>
          <WhiteBox>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: '2rem' }}
            >
              <Typography variant="h3">Template Details</Typography>
              {state !== CONTRACT_TEMPLATE_STATES.inactive && (
                <EditToggle
                  isEditing={isEditing}
                  isSubmitting={isSubmitting}
                  editProps={{
                    id: 'editContractTemplate',
                    onClick: () => setEditing(true)
                  }}
                  saveProps={{
                    id: 'saveContractTemplateEdit',
                    loading: isSubmitting
                  }}
                  cancelProps={{
                    id: 'cancelContractTemplateEdit',
                    onClick: () => {
                      resetForm();
                      setEditing(false);
                    }
                  }}
                />
              )}
            </Grid>

            {isEditing ? (
              <Grid container style={{ marginBottom: '2rem' }}>
                <Grid item xs={4}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    name="name"
                    label="Template name"
                    required
                  />
                  {state === CONTRACT_TEMPLATE_STATES.draft && (
                    <div className={classes.marginTop}>
                      <Field
                        fullWidth
                        required
                        name="file"
                        label="Upload a .docx file"
                        type="file"
                        component={FormikFileInput}
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      />
                    </div>
                  )}

                  <div className={classes.marginTop}>
                    <Field
                      name="categories"
                      component={FormikCategorySelect}
                      placeholder="Select categories"
                      label="Deliverable Categories"
                      withChipsBelow
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <Grid container style={{ marginBottom: '2rem' }} spacing={3}>
                  <Grid item xs={5}>
                    <DetailsRow title="Template name" content={name} />
                    <DetailsRow
                      title="File"
                      content={
                        <a
                          href={docxFileUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          download={docxFileName}
                        >
                          {docxFileName}
                        </a>
                      }
                    />
                    <DetailsRow title="Categories" content={mappedCategories()} />
                  </Grid>
                  <Grid item xs={5}>
                    <DetailsRow
                      title="Creator Name"
                      content={
                        createdBy ? (
                          <AvatarWithName
                            name={createdBy.fullName}
                            avatarProps={{
                              src: createdBy.representativeImageUrl
                            }}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                    <DetailsRowDate title="Created" date={created} />
                    <DetailsRowDate title="Activated" date={activationDate} />
                  </Grid>
                </Grid>
                <GlobalButton
                  handleClick={() => setPreviewOpen(true)}
                  style={{ marginLeft: '0px' }}
                >
                  Preview Sample Contract
                </GlobalButton>
              </Fragment>
            )}
          </WhiteBox>

          <FullScreenModal
            open={previewOpen}
            handleBack={() => setPreviewOpen(false)}
            actions={[
              {
                label: 'Download PDF',
                handler: handleDownloadFile
              }
            ]}
          >
            <ContractPreview contractPreviewPdf={samplePdfFileUrl}></ContractPreview>
          </FullScreenModal>
        </Form>
      )}
    </Formik>
  );
};

export default createFragmentContainer(ContractTemplateDetails, {
  template: graphql`
    fragment ContractTemplateDetails_template on ContractTemplateNode {
      id
      name
      state
      activationDate
      created
      docxFileName
      docxFileUrl
      samplePdfFileUrl
      createdBy {
        fullName
        representativeImageUrl
      }
      categories(orderBy: "name") {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  stateTypes: graphql`
    fragment ContractTemplateDetails_stateTypes on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

import React from 'react';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import graphql from 'babel-plugin-relay/macro';
import { PAGINATION_TYPES } from '../../../../shared/constants';
import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import CategoriesList from './CategoriesList';
import DueDates from './DueDates';
import TagsList from './TagsList';
import RegionalUI from './RegionalUI';
import Navigation from './Navigation';
import Reactions from './Reactions';
import PaymentRequests from './PaymentRequests';
import FileSizeLimitsRenderer from './tabRenderers/FileSizeLimitsRenderer';

const CustomizationPage = props => {
  return (
    <QueryRenderer
      query={graphql`
        query CustomizationPageQuery($first: Int, $orderBy: String, $after: String, $name: String) {
          ...CategoriesList_categories
          ...TagsList_tags
        }
      `}
      variables={{
        first: PAGINATION_TYPES.default.defaultValue
      }}
      render={relayProps => (
        <AutoTabList>
          <CategoriesList label="Deliverables" categories={relayProps} {...props} />
          <FileSizeLimitsRenderer label="File Size Limits" {...props} />
          <DueDates label="Due Dates" {...props} />
          <RegionalUI label="Regional UI" {...props} />
          <TagsList tags={relayProps} label="Tags" {...props} />
          <Navigation label="Navigation" {...props} />
          <Reactions label="Reactions" {...props} />
          <PaymentRequests label="Payment Requests" {...props} />
        </AutoTabList>
      )}
    />
  );
};

export default CustomizationPage;

import React, { useContext } from 'react';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import QuillContext from '../../QuillContext';
import { Container, Button } from './styled';
import HeadingSelectButton from './HeadingSelectButton';
import AttachReferenceFileButton from './AttachReferenceFileButton';
import ToggleLinkButton from './ToggleLinkButton';
import EmojiSelectButton from './EmojiSelectButton';

const MARK_BUTTONS = [
  { type: 'bold', icon: FormatBoldIcon },
  { type: 'italic', icon: FormatItalicIcon },
  { type: 'underline', icon: FormatUnderlinedIcon }
];

const Toolbar = () => {
  const { quillRef, updateRenderKey } = useContext(QuillContext);
  const isFocused = quillRef.current ? quillRef.current.hasFocus() : false;

  const isMarkActive = type => {
    if (isFocused) {
      const format = quillRef.current.getFormat();
      return format[type] || false;
    } else {
      return false;
    }
  };

  const handleMarkToggle = type => e => {
    e.preventDefault();
    const newValue = isMarkActive(type) ? false : true;
    quillRef.current.format(type, newValue);
    updateRenderKey();
  };

  return (
    <Container>
      {MARK_BUTTONS.map(({ type, icon }) => (
        <Button
          key={type}
          icon={icon}
          active={isMarkActive(type)}
          onMouseDown={handleMarkToggle(type)}
          disabled={!isFocused}
        />
      ))}
      <HeadingSelectButton disabled={!isFocused} />
      <ToggleLinkButton disabled={!isFocused} />
      <AttachReferenceFileButton disabled={!isFocused} />
      <EmojiSelectButton disabled={!isFocused} />
    </Container>
  );
};

export default Toolbar;

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Placeholder from './Placeholder';
import { useEffectWithStatus } from '../../../../hooks/useEffectWithStatus';
import loadZip, {findModelFile, clearBlobs} from '../../../../utils/zipUtils.js';
import ZipFbx3DAsset from './ZipFbx3DAsset';
import ZipObj3DAsset from './ZipObj3DAsset';
import { errorToast } from '../../../../toasts';

const ERROR_NO_FILE = "Could not find fbx or obj in zip archive.";

/**
 * This components is responsible to load into memory the content
 * of the zip archive containing the 3D files using the browser
 * blob API and pass the data to the components responsible for
 * loading the 3D model.
 * 
 * The component is also responsible to revoke the Blob Url when
 * is unmounted.
 * 
 */
const Zip3DAsset = props => {
  const { src, theme } = props;

  const [ext, setExt] = useState(null);
  const fileMap = useRef(null);
  const [url, setUrl] = useState(null);
  const [basePath, setBasePath] = useState(null);
  const [error, setError] = useState(null);
  
  useEffectWithStatus(status => {
    fileMap.current = loadZip(src);
    if (status.mounted) {
      fileMap.current.then(function(map) {
        if (status.mounted) {
          fileMap.current = map;
          // check if the file is not found!
          const res = findModelFile(map);
          
          if (res === null) {
            errorToast(ERROR_NO_FILE);
            setError(ERROR_NO_FILE);
            return;
          }
          setUrl(map[res.fileName]);
          var path = res.fileName.split('/')
          path.pop();
          
          const basePath = path.join('/');
          setBasePath(basePath);
          setExt(res.fileType);
        }
      });
    }
  
    // Return the cleanup function to clear the blobs from memory
    return () => {clearBlobs(fileMap.current)}; 
  }, []);

  if (ext && ext === "fbx") {
    return <ZipFbx3DAsset basePath={basePath} fileMap={fileMap.current} url={url} {...props} />
  } else 
  if (ext && ext === "obj") {
    return <ZipObj3DAsset basePath={basePath} fileMap={fileMap.current} url={url} {...props} />
  } else if (error) {
    return null;
  } else {
    return <Placeholder theme={theme} />;
  }
};

Zip3DAsset.propTypes = {
  src: PropTypes.string.isRequired,
  centerCameraOn: PropTypes.func.isRequired,
  flatShading: PropTypes.bool,
  handlers: PropTypes.shape({
    onPointerMove: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onClick: PropTypes.func
  })
};

export default Zip3DAsset;

import React from 'react';

import StyledTableCell from './StyledTableCell';
import { ReactComponent as PersonIcon } from '../../images/person.svg';
import { ReactComponent as GroupIcon } from '../../images/users-group.svg';
import { useTableStyles } from '../../styles/common/tableStyles';
import CustomTableTooltip from './CustomTableTooltip';

const TableCellVisibility = props => {
  const { isPublic } = props;
  const classes = useTableStyles();

  return (
    <StyledTableCell icon noWrap>
      {isPublic ? (
        <CustomTableTooltip title="Public">
          <GroupIcon className={classes.lightGreyIcon} />
        </CustomTableTooltip>
      ) : (
        <CustomTableTooltip title="Private">
          <PersonIcon className={classes.lightGreyIcon} />
        </CustomTableTooltip>
      )}
    </StyledTableCell>
  );
};

export default TableCellVisibility;

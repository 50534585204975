import React, { Fragment, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Box } from '@material-ui/core';

import AutoTabList from '../../../shared/components/UI/AutoTabList';
import JobDetailsTab from './details/tabs/JobDetailsTab';
import JobContractTab from './details/tabs/JobContractTab';
import JobToolsTab from './details/tabs/JobToolsTab';
import { ReactComponent as JobsIcon } from '../../../shared/images/jobs.svg';
import CategoryTitleHeader from '../../../shared/components/UI/CategoryTitleHeader';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import { successToast } from '../../../shared/toasts';
import { JOB_STATUSES, ALLOWED_ACTIONS } from '../../../shared/constants';
import { computePermissionRole } from '../../../shared/utils/helpers';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import SendReminderToContractorMutation from './mutations/SendReminderToContractorMutation';
import { formatAmount } from '../../../shared/utils/formatters';
import { useDetailsStyles } from '../../../shared/styles/common/useDetailsStyles';
import ProductReleaseBreadcrumb from '../../../shared/components/UI/ProductReleaseBreadcrumb';
import HeaderSubtitle from '../../../shared/components/UI/HeaderSubtitle';
import PaymentRequests from './details/tabs/PaymentRequests';
import CloseJobMutation from './mutations/CloseJobMutation';
import ConfirmationDialog from '../../../shared/components/common/ConfirmationDialog';

import JobDetailsTabDeliverablesList from './details/tabs/JobDeliverablesTab';
import JobDetailsToggleFollowButton from './details/JobDetailsToggleFollowButton';

const JobDetails = props => {
  const {
    job: { name, status, jobDeadline, id, releases, balance, currencyCode },
    relayProps,
    stateTypes,
    userContext: {
      orgStaff: { allowedActions }
    }
  } = props;

  const [isSubmitting, setSubmitting] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [refetchCounterForTools, setRefetchCounterForTools] = useState(0);

  const classes = useDetailsStyles();

  const handleSendReminder = () => {
    setSubmitting(true);
    SendReminderToContractorMutation(id, response => {
      setSubmitting(false);
      if (response && response.emailSent) {
        setRefetchCounterForTools(prev => prev + 1);
        successToast('Reminder was sent!');
      }
    });
  };

  const handleCloseJob = () => {
    setSubmitting(true);
    CloseJobMutation(id, response => {
      setSubmitting(false);
      if (response) {
        setRefetchCounterForTools(prev => prev + 1);
      }
    });
  };

  const getTopSubtitleStrings = () => {
    if (releases.length === 1) {
      return {
        product: releases[0].product.title,
        release: releases[0].name,
        productLink: `/products/${releases[0].product.id}`,
        releaseLink: `/releases/${releases[0].id}`
      };
    } else if (releases.length > 1) {
      return { product: 'Multiple Titles', release: 'Multiple Releases' };
    }
  };

  const renderSendReminderButton = () => {
    if (
      status === JOB_STATUSES.awaiting_signature &&
      computePermissionRole(ALLOWED_ACTIONS.JOB_RESEND_CONTRACT, allowedActions)
    ) {
      return (
        <GlobalButton
          id="sendReminderJobContractTop"
          handleClick={handleSendReminder}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Send reminder to contractor
        </GlobalButton>
      );
    }
    return null;
  };

  const renderCloseJobButton = () => {
    if (
      status === JOB_STATUSES.delivered &&
      computePermissionRole(ALLOWED_ACTIONS.JOB_CLOSE_JOB, allowedActions)
    ) {
      return (
        <ConfirmationDialog
          content={`This action is irreversible. All deliverables in this job will be closed. ${
            Number(balance) > 0
              ? `The remaining balance for this job is ${formatAmount(balance, currencyCode)}`
              : 'There is no balance due for this job'
          }. Are you sure you want to proceed?`}
          copies={{
            cancelCopy: 'Back',
            confirmCopy: 'Proceed to Close Job'
          }}
        >
          {confirm => (
            <GlobalButton
              disabled={isSubmitting}
              noMargin
              handleClick={confirm(handleCloseJob)}
              id="closeJob"
            >
              Close Job
            </GlobalButton>
          )}
        </ConfirmationDialog>
      );
    }
    return null;
  };

  const toggleEditHandler = () => {
    setEditing(!isEditing);
  };

  return (
    <Fragment>
      <div className={classes.topSectionHeightSmall}>
        <div className={classes.containerSpaceBetween}>
          <CategoryTitleHeader
            topSubtitleWithArrow={getTopSubtitleStrings()}
            renderTop={() => <ProductReleaseBreadcrumb config={getTopSubtitleStrings()} />}
            title={name}
            renderBottom={() => (
              <HeaderSubtitle
                date={jobDeadline}
                state={status}
                stateTypes={stateTypes}
                variant="job"
              />
            )}
            icon={JobsIcon}
            link="/jobs"
          />
          <div className={classes.containerJustifyFlexEnd}>
            <Box mr={2} height={34} display="flex" alignItems="center">
              <JobDetailsToggleFollowButton job={relayProps.job} />
            </Box>

            {!isEditing && (
              <>
                {renderSendReminderButton()}
                {renderCloseJobButton()}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.marginTop}>
        <AutoTabList>
          <JobDetailsTab
            isEditing={isEditing}
            toggleEditHandler={toggleEditHandler}
            label="Details"
            job={relayProps.job}
            relayProps={relayProps}
            stateTypes={stateTypes}
          />
          <JobDetailsTabDeliverablesList
            label="Deliverables"
            job={relayProps.job}
            stateTypes={relayProps.deliverableState}
            disabled={isEditing}
          />
          <JobContractTab
            label="Contract"
            job={relayProps.job}
            handleSendReminder={handleSendReminder}
            isSubmitting={isSubmitting}
            disabled={isEditing}
          />
          <PaymentRequests label="Payment Requests & Invoices" jobId={id} disabled={isEditing} />
          <JobToolsTab
            label="Tools"
            job={relayProps.job}
            refetchCounter={props.refetchCounter}
            refetchCounterForTools={refetchCounterForTools}
            setRefetchCounterForTools={setRefetchCounterForTools}
            disabled={isEditing}
          />
        </AutoTabList>
      </div>
    </Fragment>
  );
};

export default withUserContext(
  createFragmentContainer(JobDetails, {
    job: graphql`
      fragment JobDetails_job on JobNode {
        jobId
        name
        status
        jobDeadline
        id
        balance
        currencyCode
        releases {
          id
          name
          product {
            id
            title
          }
        }
      }
    `,
    stateTypes: graphql`
      fragment JobDetails_stateTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  })
);

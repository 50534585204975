import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation RemoveReleaseBudgetMutation($input: RemoveReleaseBudgetInput!) {
    removeReleaseBudget(input: $input) {
      updatedRelease {
        id
        totalBudget
        budgetInfo {
          category {
            id
            name
          }
          budgetAmount
        }
      }
    }
  }
`;

const RemoveReleaseBudgetMutation = (id, callback) => {
  const config = {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.removeReleaseBudget);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default RemoveReleaseBudgetMutation;

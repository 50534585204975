import React, { useState, Fragment } from 'react';

import AutoTabList from '../../../shared/components/UI/AutoTabList';
import CategoryTitleHeader from '../../../shared/components/UI/CategoryTitleHeader';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import { useDetailsStyles } from '../../../shared/styles/common/useDetailsStyles';
import AccountInformationTab from './AccountInformationTab';
import NotificationSettingsTab from './NotificationSettingsTab';

const StaffUserPage = props => {
  const {
    userContext: { representativeImageUrl }
  } = props;
  const [isEditing, setEditing] = useState(false);

  const toggleEditHandler = () => {
    setEditing(!isEditing);
  };

  const classes = useDetailsStyles();

  return (
    <Fragment>
      <div className={classes.topSectionHeightSmall}>
        <CategoryTitleHeader
          title="User Settings"
          icon={representativeImageUrl || ''}
          link="/profile"
        />
      </div>
      <AutoTabList>
        <AccountInformationTab
          label="Account Information"
          disabled={isEditing}
          toggleEditHandler={toggleEditHandler}
          isEditing={isEditing}
        />
        <NotificationSettingsTab label="Notification Settings" disabled={isEditing} />
      </AutoTabList>
    </Fragment>
  );
};

export default withUserContext(StaffUserPage);

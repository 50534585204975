/**
 * @flow
 * @relayHash 64d04ec243779be522c7a8609d90fa34
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExposeReferenceToSolicitationInput = {|
  id: string,
  expose: boolean,
  clientMutationId?: ?string,
|};
export type ExposeReferenceToSolicitationMutationVariables = {|
  input: ExposeReferenceToSolicitationInput
|};
export type ExposeReferenceToSolicitationMutationResponse = {|
  +exposeReferenceToSolicitation: ?{|
    +updatedReference: ?{|
      +id: string,
      +exposeToSolicitation: boolean,
    |}
  |}
|};
export type ExposeReferenceToSolicitationMutation = {|
  variables: ExposeReferenceToSolicitationMutationVariables,
  response: ExposeReferenceToSolicitationMutationResponse,
|};
*/

/*
mutation ExposeReferenceToSolicitationMutation(
  $input: ExposeReferenceToSolicitationInput!
) {
  exposeReferenceToSolicitation(input: $input) {
    updatedReference {
      id
      exposeToSolicitation
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'ExposeReferenceToSolicitationInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'exposeReferenceToSolicitation',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'ExposeReferenceToSolicitationPayload',
        plural: false,
        selections: [
          {
            kind: 'LinkedField',
            alias: null,
            name: 'updatedReference',
            storageKey: null,
            args: null,
            concreteType: 'ReferenceNode',
            plural: false,
            selections: [
              {
                kind: 'ScalarField',
                alias: null,
                name: 'id',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'exposeToSolicitation',
                args: null,
                storageKey: null
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ExposeReferenceToSolicitationMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'ExposeReferenceToSolicitationMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'ExposeReferenceToSolicitationMutation',
      id: null,
      text:
        'mutation ExposeReferenceToSolicitationMutation(\n  $input: ExposeReferenceToSolicitationInput!\n) {\n  exposeReferenceToSolicitation(input: $input) {\n    updatedReference {\n      id\n      exposeToSolicitation\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'a79442474619fdca7c6ce0d39b70a048';
module.exports = node;

/**
 * @flow
 * @relayHash 24b7046d2e1950ec40aa08b2c4dff443
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffList_staff$ref = any;
export type StaffListRefetchQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
  staffName?: ?string,
  role?: ?string,
  includeInactive?: ?boolean,
|};
export type StaffListRefetchQueryResponse = {|
  +$fragmentRefs: StaffList_staff$ref
|};
export type StaffListRefetchQuery = {|
  variables: StaffListRefetchQueryVariables,
  response: StaffListRefetchQueryResponse,
|};
*/


/*
query StaffListRefetchQuery(
  $first: Int
  $orderBy: String
  $after: String
  $staffName: String
  $role: ID
  $includeInactive: Boolean
) {
  ...StaffList_staff
}

fragment StaffList_staff on Query {
  staffByOrg(first: $first, orderBy: $orderBy, after: $after, staffName: $staffName, role: $role, includeInactive: $includeInactive) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        fullName
        roleLabel
        title
        user {
          email
          lastLogin
          isActive
          id
        }
        representativeImageUrl
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "staffName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "role",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StaffListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "StaffList_staff",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StaffListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "staffByOrg",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "includeInactive",
            "variableName": "includeInactive"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          },
          {
            "kind": "Variable",
            "name": "role",
            "variableName": "role"
          },
          {
            "kind": "Variable",
            "name": "staffName",
            "variableName": "staffName"
          }
        ],
        "concreteType": "StaffNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fullName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "roleLabel",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastLogin",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isActive",
                        "args": null,
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "representativeImageUrl",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StaffListRefetchQuery",
    "id": null,
    "text": "query StaffListRefetchQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n  $staffName: String\n  $role: ID\n  $includeInactive: Boolean\n) {\n  ...StaffList_staff\n}\n\nfragment StaffList_staff on Query {\n  staffByOrg(first: $first, orderBy: $orderBy, after: $after, staffName: $staffName, role: $role, includeInactive: $includeInactive) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        fullName\n        roleLabel\n        title\n        user {\n          email\n          lastLogin\n          isActive\n          id\n        }\n        representativeImageUrl\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'becf351cc285f543c0ed033472147736';
module.exports = node;

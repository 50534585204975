/**
 * @flow
 * @relayHash e2ec7e972c69585abc665d4207b6d7bc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateReleaseBudgetInput = {|
  id: string,
  budgetInfo: $ReadOnlyArray<?BudgetInfoInput>,
  clientMutationId?: ?string,
|};
export type BudgetInfoInput = {|
  categoryId?: ?string,
  budgetAmount: any,
|};
export type UpdateReleaseBudgetMutationVariables = {|
  input: UpdateReleaseBudgetInput
|};
export type UpdateReleaseBudgetMutationResponse = {|
  +updateReleaseBudget: ?{|
    +updatedRelease: ?{|
      +id: string,
      +budgetInfo: ?$ReadOnlyArray<?{|
        +category: ?{|
          +id: string,
          +name: string,
        |},
        +budgetAmount: ?any,
      |}>,
      +categoryInfo: ?$ReadOnlyArray<?{|
        +category: ?{|
          +id: string,
          +name: string,
        |},
        +pendingAmount: ?any,
        +contractedAmount: ?any,
        +originalPendingAmount: ?any,
        +originalContractedAmount: ?any,
        +hasFxDeliverables: ?boolean,
      |}>,
      +exchangeRateDate: ?any,
      +deliverablesTotalAmount: ?any,
      +deliverablesTotalOriginalAmount: ?any,
      +totalBudget: ?any,
      +deliverablesTotalContractedAmount: ?any,
      +deliverablesTotalPendingAmount: ?any,
    |}
  |}
|};
export type UpdateReleaseBudgetMutation = {|
  variables: UpdateReleaseBudgetMutationVariables,
  response: UpdateReleaseBudgetMutationResponse,
|};
*/


/*
mutation UpdateReleaseBudgetMutation(
  $input: UpdateReleaseBudgetInput!
) {
  updateReleaseBudget(input: $input) {
    updatedRelease {
      id
      budgetInfo {
        category {
          id
          name
        }
        budgetAmount
      }
      categoryInfo {
        category {
          id
          name
        }
        pendingAmount
        contractedAmount
        originalPendingAmount
        originalContractedAmount
        hasFxDeliverables
      }
      exchangeRateDate
      deliverablesTotalAmount
      deliverablesTotalOriginalAmount
      totalBudget
      deliverablesTotalContractedAmount
      deliverablesTotalPendingAmount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateReleaseBudgetInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryNode",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateReleaseBudget",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateReleaseBudgetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedRelease",
        "storageKey": null,
        "args": null,
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetInfo",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "budgetAmount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categoryInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "ReleaseCategoryInfo",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "pendingAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contractedAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "originalPendingAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "originalContractedAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasFxDeliverables",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "exchangeRateDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deliverablesTotalAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deliverablesTotalOriginalAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalBudget",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deliverablesTotalContractedAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deliverablesTotalPendingAmount",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateReleaseBudgetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateReleaseBudgetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateReleaseBudgetMutation",
    "id": null,
    "text": "mutation UpdateReleaseBudgetMutation(\n  $input: UpdateReleaseBudgetInput!\n) {\n  updateReleaseBudget(input: $input) {\n    updatedRelease {\n      id\n      budgetInfo {\n        category {\n          id\n          name\n        }\n        budgetAmount\n      }\n      categoryInfo {\n        category {\n          id\n          name\n        }\n        pendingAmount\n        contractedAmount\n        originalPendingAmount\n        originalContractedAmount\n        hasFxDeliverables\n      }\n      exchangeRateDate\n      deliverablesTotalAmount\n      deliverablesTotalOriginalAmount\n      totalBudget\n      deliverablesTotalContractedAmount\n      deliverablesTotalPendingAmount\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4217032c460224f61a5400e3141d18d7';
module.exports = node;

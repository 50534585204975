import React, { useState, Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import ReleaseToolsActions from './ReleaseToolsActions';
import ReleaseActionLogList from './ReleaseActionLogList';

const ReleaseToolsTab = ({ tableName, ...otherProps }) => {
  const variables = useTableHistoryQueryVariables();
  const [refetchCounterForTools, setRefetchCounterForTools] = useState(0);

  const { id } = useParams();
  return (
    <QueryRenderer
      query={graphql`
        query ReleaseToolsTabQuery($id: ID!, $first: Int, $after: String) {
          release(id: $id) {
            ...ReleaseToolsActions_release
            ...ReleaseActionLogList_release
          }
        }
      `}
      variables={{
        id,
        ...variables
      }}
      render={relayProps => {
        return (
          <Fragment>
            <ReleaseToolsActions
              release={relayProps.release}
              {...otherProps}
              setRefetchCounterForTools={setRefetchCounterForTools}
            />
            <ReleaseActionLogList
              release={relayProps.release}
              {...otherProps}
              refetchCounterForTools={refetchCounterForTools}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default withTableHistoryProvider(ReleaseToolsTab, 'orgReleaseTools', {
  rowsPerPage: 10
});

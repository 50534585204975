/**
 * @flow
 * @relayHash 665027824425ca5ddfe45b74dc02ae09
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationQuickViewContractorList_solicitation$ref = any;
export type SolicitationQuickViewContractorListRefetchQueryVariables = {|
  first?: ?number,
  after?: ?string,
  id: string,
  orderBy?: ?string,
|};
export type SolicitationQuickViewContractorListRefetchQueryResponse = {|
  +solicitation: ?{|
    +$fragmentRefs: SolicitationQuickViewContractorList_solicitation$ref
  |}
|};
export type SolicitationQuickViewContractorListRefetchQuery = {|
  variables: SolicitationQuickViewContractorListRefetchQueryVariables,
  response: SolicitationQuickViewContractorListRefetchQueryResponse,
|};
*/


/*
query SolicitationQuickViewContractorListRefetchQuery(
  $first: Int
  $after: String
  $id: ID!
  $orderBy: String
) {
  solicitation: solicitation(id: $id) {
    ...SolicitationQuickViewContractorList_solicitation_2IJuIk
    id
  }
}

fragment SolicitationQuickViewContractorList_solicitation_2IJuIk on SolicitationNode {
  id
  state
  contractors(first: $first, after: $after, orderBy: $orderBy) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        fullName
        representativeImageUrl
        numberOfCommentsInSolicitation
        freelancer {
          user {
            userId
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationQuickViewContractorListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "solicitation",
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SolicitationQuickViewContractorList_solicitation",
            "args": (v2/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationQuickViewContractorListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "solicitation",
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractors",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "ContractorNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "representativeImageUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numberOfCommentsInSolicitation",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "freelancer",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FreelancerNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "userId",
                                "args": null,
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ]
                          },
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationQuickViewContractorListRefetchQuery",
    "id": null,
    "text": "query SolicitationQuickViewContractorListRefetchQuery(\n  $first: Int\n  $after: String\n  $id: ID!\n  $orderBy: String\n) {\n  solicitation: solicitation(id: $id) {\n    ...SolicitationQuickViewContractorList_solicitation_2IJuIk\n    id\n  }\n}\n\nfragment SolicitationQuickViewContractorList_solicitation_2IJuIk on SolicitationNode {\n  id\n  state\n  contractors(first: $first, after: $after, orderBy: $orderBy) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        fullName\n        representativeImageUrl\n        numberOfCommentsInSolicitation\n        freelancer {\n          user {\n            userId\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '310306e7f16ff0d1da11d53ea70cb4c2';
module.exports = node;

/**
 * @flow
 * @relayHash bcaa4dd74fc73fcaaca7af07faa57409
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchableReleaseDropdownQueryVariables = {|
  releaseOrProductName?: ?string,
  orderBy?: ?string,
  first?: ?number,
  includeInactive?: ?boolean,
|};
export type SearchableReleaseDropdownQueryResponse = {|
  +releasesByOrg: ?{|
    +edgeCount: ?number,
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +product: {|
          +title: string
        |},
      |}
    |}>,
  |}
|};
export type SearchableReleaseDropdownQuery = {|
  variables: SearchableReleaseDropdownQueryVariables,
  response: SearchableReleaseDropdownQueryResponse,
|};
*/


/*
query SearchableReleaseDropdownQuery(
  $releaseOrProductName: String
  $orderBy: String
  $first: Int
  $includeInactive: Boolean
) {
  releasesByOrg(releaseOrProductName: $releaseOrProductName, orderBy: $orderBy, first: $first, includeInactive: $includeInactive) {
    edgeCount
    totalCount
    edges {
      node {
        id
        name
        product {
          title
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "releaseOrProductName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "includeInactive",
    "variableName": "includeInactive"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "releaseOrProductName",
    "variableName": "releaseOrProductName"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "edgeCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SearchableReleaseDropdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "releasesByOrg",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseNodeConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ReleaseNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ReleaseNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductNode",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchableReleaseDropdownQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "releasesByOrg",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseNodeConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ReleaseNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ReleaseNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductNode",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SearchableReleaseDropdownQuery",
    "id": null,
    "text": "query SearchableReleaseDropdownQuery(\n  $releaseOrProductName: String\n  $orderBy: String\n  $first: Int\n  $includeInactive: Boolean\n) {\n  releasesByOrg(releaseOrProductName: $releaseOrProductName, orderBy: $orderBy, first: $first, includeInactive: $includeInactive) {\n    edgeCount\n    totalCount\n    edges {\n      node {\n        id\n        name\n        product {\n          title\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '573380443e23e5cae9acd22daa71b291';
module.exports = node;

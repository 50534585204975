import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import CreateProductMutation from '../../mutations/CreateProductMutation';
import { successToast } from '../../../shared/toasts';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';

import { Grid } from '@material-ui/core';

const CreateProductForm = ({ toRefetchData, handleClose, userContext }) => {
  const navigation = JSON.parse(userContext.orgStaff.organization.configuration).navigation;

  const onSubmit = (values, { setSubmitting }) => {
    CreateProductMutation(values.title, values.code, values.description, createProduct => {
      setSubmitting(false);
      if (createProduct && createProduct.newProduct) {
        handleClose();
        setTimeout(() => {
          successToast(`New ${navigation.productTitle.singular} has been created.`, {
            text: `Go to ${navigation.productTitle.singular}`,
            link: `/products/${createProduct.newProduct.id}`
          });
        }, 250);
        toRefetchData();
      }
    });
  };

  const CreateProductSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(100, 'Please enter no more than 100 characters.')
      .required('Required'),
    code: Yup.string().max(30, 'Please enter no more than 30 characters.'),
    description: Yup.string().max(5000, 'Please enter no more than 5000 characters.')
  });

  const initialValues = {
    title: '',
    code: '',
    description: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateProductSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Field
                required
                name="title"
                component={FormikTextField}
                label={`${navigation.productTitle.singular} Name`}
                placeholder={`Enter ${navigation.productTitle.singular} name...`}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="code"
                component={FormikTextField}
                label={`${navigation.productTitle.singular} Code`}
                placeholder={`Enter ${navigation.productTitle.singular} code...`}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                multiline
                rows="14"
                name="description"
                component={FormikTextField}
                label="Description"
                placeholder={`Enter ${navigation.productTitle.singular} description...`}
              />
            </Grid>

            <Grid container item xs={12} justify="flex-end">
              <GlobalButton variant="transparent" handleClick={handleClose} id="cancelProduct">
                Cancel
              </GlobalButton>
              <GlobalButton type="submit" id="submitProduct" disabled={isSubmitting}>
                Create {navigation.productTitle.singular}
              </GlobalButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(CreateProductForm);

import React, { useState, useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import withUserContext from '../../../../../../../shared/contexts/userContext/withUserContext';
import { Typography, Box, Divider } from '@material-ui/core';
import GlobalButton from '../../../../../../../shared/components/UI/GlobalButton';
import Detail from '../../../../../../../shared/components/UI/Detail';
import Date from '../../../../../../../shared/components/common/Date';
import AvatarWithName from '../../../../../../../shared/components/UI/AvatarWithName';
import { computePermissionRole } from '../../../../../../../shared/utils/helpers';
import StatusIndicator from '../../../../../../../shared/components/UI/StatusIndicator';
import { formatAmount } from '../../../../../../../shared/utils/formatters';
import { ActionsContainer } from '../../../../../../../shared/components/common/QuickViewDrawer';
import { JOB_INVOICE_STATES, ALLOWED_ACTIONS } from '../../../../../../../shared/constants';
import colors from '../../../../../../../shared/styles/common/colors';
import FullscreenModal from '../../../../../../../shared/components/fullscreenModal/FullscreenModal';
import ContractPreview from '../../../../../../../shared/components/job/ContractPreview';
import { successToast } from '../../../../../../../shared/toasts';
import InvoiceApprovalMutation from '../../../../mutations/InvoiceApprovalMutation';
import DeclineInvoiceDialog from './DeclineInvoiceDialog';
import CreatePaymentRequestDialog from '../CreatePaymentRequestDialog';

const LOADING_ERROR = 'LOADING_ERROR';

const fileFromUrl = async (url, callback) => {
  try {
    // eslint-disable-next-line no-useless-escape
    const fileName = url.match(/\/([^\/?#]+)[^\/]*$/)[1];
    const res = await fetch(url);
    const data = await res.blob();
    const file = new File([data], fileName);
    callback(file);
  } catch (e) {
    callback(LOADING_ERROR);
  }
};

const InvoiceQuickViewContent = props => {
  const { invoice, statusTypes, userContext } = props;
  const [isApprovingInvoice, setIsApprovingInvoice] = useState(false);
  const [isDecliningInvoice, setIsDecliningInvoice] = useState(false);
  const [isInvoicePdfVisible, setInvoicePdfVisible] = useState(false);
  const [invoicePdfFile, setInvoicePdfFile] = useState(null);

  useEffect(() => {
    if (!invoicePdfFile && isInvoicePdfVisible) {
      fileFromUrl(invoice.invoiceFileUrl, setInvoicePdfFile);
    }
  }, [isInvoicePdfVisible]);

  const canApproveOrDecline =
    invoice.status === JOB_INVOICE_STATES.new &&
    computePermissionRole(
      ALLOWED_ACTIONS.JOB_MANAGE_PAYMENT_REQUESTS,
      userContext.orgStaff.allowedActions
    );

  const handleApproveInvoice = (paymentRequestValues, actions) => {
    const { paymentAmount, message, attachContract, file } = paymentRequestValues;

    actions.setSubmitting(true);
    InvoiceApprovalMutation(
      {
        approved: true,
        invoiceId: invoice.id,
        message,
        attachContract,
        paymentAmount
      },
      file
    ).finally(() => {
      actions.setSubmitting(false);
      setIsApprovingInvoice(false);
      setInvoicePdfVisible(false);
      successToast('Invoice approved!');
    });
  };

  return (
    <>
      {invoicePdfFile && (
        <CreatePaymentRequestDialog
          job={invoice.job}
          isDialogOpen={isApprovingInvoice}
          onSubmit={handleApproveInvoice}
          closeDialog={() => setIsApprovingInvoice(false)}
          initialValues={{
            paymentAmount: invoice.amount,
            file: invoicePdfFile
          }}
        />
      )}

      <DeclineInvoiceDialog
        isDialogOpen={isDecliningInvoice}
        closeDialog={() => setIsDecliningInvoice(false)}
        invoiceId={invoice.id}
        onDeclined={() => setInvoicePdfVisible(false)}
      />

      <FullscreenModal
        open={isInvoicePdfVisible}
        handleBack={() => setInvoicePdfVisible(false)}
        actions={[
          ...(canApproveOrDecline
            ? [
                {
                  label: 'Decline',
                  handler: () => setIsDecliningInvoice(true),
                  btnProps: {
                    variant: 'cancelation'
                  }
                },
                {
                  label: 'Process Invoice',
                  handler: () => setIsApprovingInvoice(true),
                  btnProps: {
                    variant: 'acceptation',
                    disabled: !invoicePdfFile || invoicePdfFile === LOADING_ERROR
                  }
                }
              ]
            : [])
        ]}
      >
        <ContractPreview
          contractPreviewPdf={invoicePdfFile === LOADING_ERROR ? null : invoicePdfFile}
          noData={
            invoicePdfFile === LOADING_ERROR ? 'Failed to load the invoice' : 'Loading invoice...'
          }
        />
      </FullscreenModal>

      <Typography variant="h3" style={{ marginBottom: '4px' }}>
        {invoice.identifier}
      </Typography>
      <StatusIndicator
        statusCode={invoice.status}
        variant="jobInvoice"
        statusTypes={statusTypes}
        noPadding
      />

      <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
        <Detail
          name="Contractor"
          renderValue={() => (
            <AvatarWithName
              name={invoice.job.contractor.fullName}
              size={34}
              avatarProps={{
                src: invoice.job.contractor.representativeImageUrl
              }}
            />
          )}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Detail
          name="Date Submitted"
          value={<Date date={invoice.created} />}
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail
          name="Job Coordinator"
          renderValue={() => (
            <AvatarWithName
              name={invoice.job.staffCoordinator.fullName}
              size={34}
              avatarProps={{
                src: invoice.job.staffCoordinator.representativeImageUrl
              }}
            />
          )}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Detail
          name="Date Approved"
          value={
            invoice.status !== JOB_INVOICE_STATES.declined ? (
              <Date date={invoice.verificationDate} />
            ) : null
          }
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Detail
          name="Invoice Amount"
          value={formatAmount(invoice.amount, invoice.job.currencyCode)}
          flexDirection="column"
          alignItems="flex-end"
          valueContainerProps={{
            variant: 'h2'
          }}
        />
      </Box>

      {invoice.status === JOB_INVOICE_STATES.declined && (
        <>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Declined by"
              renderValue={() => (
                <AvatarWithName
                  name={invoice.verificationBy.fullName}
                  size={34}
                  avatarProps={{
                    src: invoice.verificationBy.representativeImageUrl
                  }}
                />
              )}
              flexDirection="column"
              alignItems="flex-start"
              nameColor={colors.red}
            />
            <Detail
              name="Date Declined"
              value={<Date date={invoice.verificationDate} />}
              flexDirection="column"
              alignItems="flex-end"
              nameColor={colors.red}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Reason"
              value={invoice.verificationMessage}
              flexDirection="column"
              alignItems="flex-start"
              valueContainerProps={{ variant: 'body1' }}
              nameColor={colors.red}
            />
          </Box>
        </>
      )}

      {invoice.markedPaymentSentBy && (
        <>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Marked Payment Sent by"
              renderValue={() => (
                <AvatarWithName
                  name={invoice.markedPaymentSentBy.fullName}
                  size={34}
                  avatarProps={{
                    src: invoice.markedPaymentSentBy.representativeImageUrl
                  }}
                />
              )}
              flexDirection="column"
              alignItems="flex-start"
            />
            <Detail
              name="Date Payment Sent"
              value={<Date date={invoice.markedPaymentSentDate} />}
              flexDirection="column"
              alignItems="flex-end"
            />
          </Box>
          {invoice.markedPaymentSentMessage && (
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Detail
                name="Comment"
                value={invoice.markedPaymentSentMessage}
                flexDirection="column"
                alignItems="flex-start"
                valueContainerProps={{ variant: 'body1' }}
              />
            </Box>
          )}
        </>
      )}

      {invoice.message && (
        <Detail
          name="Invoice Message"
          renderValue={() => (
            <>
              <AvatarWithName
                name={invoice.job.contractor.fullName}
                size={34}
                avatarProps={{
                  src: invoice.job.contractor.representativeImageUrl
                }}
              />
              <Box mt={1}>
                <Typography variant="body1">{invoice.message}</Typography>
              </Box>
            </>
          )}
          flexDirection="column"
          alignItems="flex-start"
        />
      )}

      <Box mt={3} mb={2}>
        <Divider />
      </Box>

      <ActionsContainer>
        <GlobalButton variant="secondary" handleClick={() => setInvoicePdfVisible(true)}>
          View Invoice
        </GlobalButton>
      </ActionsContainer>
    </>
  );
};

export default createFragmentContainer(withUserContext(InvoiceQuickViewContent), {
  invoice: graphql`
    fragment InvoiceQuickViewContent_invoice on InvoiceNode {
      id
      identifier
      status
      created
      amount
      message
      invoiceFileUrl
      verificationDate
      verificationMessage
      verificationBy {
        fullName
        representativeImageUrl
      }
      markedPaymentSentDate
      markedPaymentSentMessage
      markedPaymentSentBy {
        fullName
        representativeImageUrl
      }
      job {
        id
        name
        currencyCode
        staffCoordinator {
          fullName
          representativeImageUrl
        }
        contractor {
          fullName
          representativeImageUrl
        }
        ...CreatePaymentRequestDialog_job
      }
    }
  `
});

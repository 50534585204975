import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation CreateContractTemplateMutation($input: CreateContractTemplateInput!) {
    createContractTemplate(input: $input) {
      contractTemplate {
        id
      }
      errors
    }
  }
`;

const CreateContractTemplateMutation = (input, file, callback) => {
  const config = {
    mutation,
    uploadables: { template_file: file },
    variables: { input },
    onCompleted: response => {
      callback(response.createContractTemplate);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default CreateContractTemplateMutation;

CreateContractTemplateMutation.propTypes = {
  input: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  file: PropTypes.any.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import StyledTableCell from './StyledTableCell';
import RatingStars from '../UI/RatingStars';

const useStyles = makeStyles({
  tableCellRating: {
    '& svg': {
      width: '12px',
      height: '12px'
    }
  }
});

const TableCellRating = props => {
  const { rating } = props;
  const classes = useStyles();

  return (
    <StyledTableCell className={classes.tableCellRating}>
      <RatingStars rating={rating} />
    </StyledTableCell>
  );
};

TableCellRating.propTypes = {
  rating: PropTypes.number.isRequired
};

export default TableCellRating;

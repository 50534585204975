/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductDetails_product$ref: FragmentReference;
declare export opaque type ProductDetails_product$fragmentType: ProductDetails_product$ref;
export type ProductDetails_product = {|
  +title: string,
  +isActive: boolean,
  +id: string,
  +$refType: ProductDetails_product$ref,
|};
export type ProductDetails_product$data = ProductDetails_product;
export type ProductDetails_product$key = {
  +$data?: ProductDetails_product$data,
  +$fragmentRefs: ProductDetails_product$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProductDetails_product",
  "type": "ProductNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3cfc987646f05b0f3f5e8cef6ff9a7ad';
module.exports = node;

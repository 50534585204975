import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

export default (password, staffToken, uidb64, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CompleteStaffAccountMutation(
        $completeStaffAccountInput: CompleteStaffAccountInput!
      ) {
        completeStaffAccount(input: $completeStaffAccountInput) {
          user {
            email
          }
        }
      }
    `,
    variables: {
      completeStaffAccountInput: {
        password,
        staffToken,
        uidb64
      }
    },
    onCompleted: response => {
      callback(response);
    },
    onError: err => console.error(err)
  });
};

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import BillingActivity from '../BillingActivity';

const BillingActivityRenderer = props => {
  return (
    <QueryRenderer
      query={graphql`
        query BillingActivityRendererQuery($first: Int, $after: String) {
          organization {
            ...BillingActivity_organization
          }
        }
      `}
      render={relayProps => <BillingActivity organization={relayProps.organization} {...props} />}
    />
  );
};

export default BillingActivityRenderer;

/**
 * @flow
 * @relayHash 0158949082971f64883df916764fd851
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImageCanvasInterfaceQueryVariables = {|
  reviewAssetId: string
|};
export type ImageCanvasInterfaceQueryResponse = {|
  +reviewAsset: ?{|
    +id: string,
    +reviewAssetAnnotation: ?{|
      +annotationData: ?any
    |},
  |}
|};
export type ImageCanvasInterfaceQuery = {|
  variables: ImageCanvasInterfaceQueryVariables,
  response: ImageCanvasInterfaceQueryResponse,
|};
*/


/*
query ImageCanvasInterfaceQuery(
  $reviewAssetId: ID!
) {
  reviewAsset(id: $reviewAssetId) {
    id
    reviewAssetAnnotation {
      annotationData
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "reviewAssetId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "reviewAssetId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "annotationData",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ImageCanvasInterfaceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReviewAssetNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAssetAnnotation",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableReviewAnnotationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ImageCanvasInterfaceQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReviewAssetNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewAssetAnnotation",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableReviewAnnotationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ImageCanvasInterfaceQuery",
    "id": null,
    "text": "query ImageCanvasInterfaceQuery(\n  $reviewAssetId: ID!\n) {\n  reviewAsset(id: $reviewAssetId) {\n    id\n    reviewAssetAnnotation {\n      annotationData\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7524484fbdf0ec8c44eadece6d0044c';
module.exports = node;

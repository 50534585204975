import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import AutoTable from '../../../shared/components/table/AutoTable';

import { CELL_TYPES } from '../../../shared/constants';

const JobHistory = props => {
  const {
    relay: { refetch },
    organization: {
      organization: { jobs }
    },
    statusTypes
  } = props;

  if (!jobs) return <div>Something went wrong.</div>;

  const {
    edges,
    pageInfo: { hasNextPage, endCursor },
    totalCount,
    edgeCount
  } = jobs;

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      jobId,
      status,
      name,
      totalValue,
      contractDate,
      jobDeadline,
      staffCoordinator: { representativeImageUrl, fullName },
      contract,
      dateCompleted,
      currencyCode
    } = edge.node;

    return {
      node: {
        id,
        jobId,
        status: { code: status },
        name,
        contractDate,
        jobDeadline,
        jobStartDate: contract ? contract.dateSignedByContractor : '',
        amount: totalValue,
        fullName: { name: fullName, imageUrl: representativeImageUrl },
        dateCompleted,
        currencyCode
      }
    };
  });

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    props.history.push(`/jobs/contracts/${id}`);
  };

  return (
    <AutoTable
      initialOrderBy="-contractDate"
      rowTemplate={[
        { name: 'jobId', label: 'Job ID', sortable: true, type: CELL_TYPES.link },
        { name: 'name', label: 'Job Name', sortable: true, type: CELL_TYPES.link, width: '20%' },
        { name: 'contractDate', label: 'Contract Date', type: CELL_TYPES.date, sortable: true },
        {
          name: 'jobDeadline',
          label: 'Job Completion Deadline',
          type: CELL_TYPES.date,
          sortable: true
        },
        { name: 'dateCompleted', label: 'Date Completed', type: CELL_TYPES.date, sortable: true },
        {
          name: 'status',
          label: 'Status',
          type: CELL_TYPES.status,
          statusTypes: statusTypes.enumValues,
          variant: 'job'
        },
        {
          name: 'fullName',
          type: CELL_TYPES.avatar,
          label: 'Contact',
          onlyTooltip: true,
          align: 'center',
          width: 64
        },
        { name: 'amount', label: 'Amount', type: CELL_TYPES.amount, sortable: true }
      ]}
      edges={flattenedEdges}
      onChangeHandler={onChangeHandler}
      rowProps={{
        handleLinkClick: onLinkClickHandler
      }}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
    />
  );
};

export default createRefetchContainer(
  JobHistory,
  {
    organization: graphql`
      fragment JobHistory_organization on Query
      @argumentDefinitions(
        id: { type: "ID!" }
        first: { type: "Int" }
        orderBy: { type: "String", defaultValue: "-contractDate" }
        after: { type: "String" }
      ) {
        organization(id: $id) {
          id
          jobs(first: $first, orderBy: $orderBy, after: $after) {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                contract {
                  dateSignedByContractor
                }
                totalValue
                staffCoordinator {
                  representativeImageUrl
                  fullName
                }
                status
                name
                contractDate
                jobDeadline
                jobId
                dateCompleted
                currencyCode
              }
            }
          }
        }
      }
    `,
    statusTypes: graphql`
      fragment JobHistory_statusTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  },
  graphql`
    query JobHistoryRefetchQuery($id: ID!, $first: Int, $orderBy: String, $after: String) {
      ...JobHistory_organization
        @arguments(id: $id, first: $first, orderBy: $orderBy, after: $after)
    }
  `
);

import React, { useState, createRef } from 'react';
import styled, { css } from 'styled-components';
import SlickSlider from 'react-slick';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

// NOTE:
// https://github.com/kenwheeler/slick/blob/master/slick/slick.scss
const Container = styled.div`
  width: 100%;
  position: relative;

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-track,
  .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
  }

  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir='rtl'] & {
      float: right;
    }

    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }

    .slick-initialized & {
      display: block;
    }

    .slick-loading & {
      visibility: hidden;
    }

    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;

const NavButton = styled.button.attrs({
  type: 'button'
})`
  padding: 0;
  user-select: none;
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: ${props => props.theme.palette.colors.progressBarLightGrey};
  color: #4a4554;

  position: absolute;
  cursor: pointer;
  z-index: 10;

  top: 82px;

  &:first-child {
    left: 0;
  }

  &:not(:first-child) {
    right: 0;
  }

  svg {
    font-size: 30px;
    margin: 9px;
  }

  ${props =>
    props.disabled &&
    css`
      display: none;
    `};
`;

const Slider = slickProps => {
  const sliderRef = createRef();
  const [disabledDirection, setDisabledDirection] = useState(-1);

  const handleSlideChange = direction => () => {
    const slider = sliderRef.current;
    if (direction > 0) {
      slider.slickNext();
    } else {
      slider.slickPrev();
    }
  };

  const handleBeforeChange = (oldIndex, newIndex) => {
    if (newIndex === 0) {
      setDisabledDirection(-1);
    } else if (newIndex === sliderRef.current.props.children.length - 1) {
      setDisabledDirection(1);
    } else {
      setDisabledDirection(0);
    }
  };

  return (
    <Container>
      <NavButton onClick={handleSlideChange(-1)} disabled={disabledDirection === -1}>
        <ChevronLeftRoundedIcon />
      </NavButton>
      <NavButton onClick={handleSlideChange(1)} disabled={disabledDirection === 1}>
        <ChevronRightRoundedIcon />
      </NavButton>

      <SlickSlider
        {...slickProps}
        arrows={false}
        ref={sliderRef}
        infinite={false}
        beforeChange={handleBeforeChange}
      />
    </Container>
  );
};

export default Slider;

import React from 'react';
import { Typography, Box, TableBody, TableCell } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Slider from './Slider';
import { StyledTable } from '../../../../../shared/components/table/StyledTable';
import {
  ToggleButtonsGroup,
  ToggleButton,
  PlanHeader,
  StyledPlanInfoRow,
  StyledPlanInfoCell
} from './styled';
import { SUBSCRIPTION_TIERS_INFO, formatCellContent } from './helpers';

const BillingCycleLabel = styled(Typography)({
  fontWeight: 'normal'
});

// NOTE:
// Used on awebase marketing site, doesn't include every feature of AvailablePlans
const AvailablePlansAsSlider = ({ displayMonthly, onBillingCycleChange, subscriptionTiers }) => {
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h1">Available Plans</Typography>
        <Box mt={3} mb={3}>
          <ToggleButtonsGroup>
            <ToggleButton active={!displayMonthly} onClick={() => onBillingCycleChange(false)}>
              Annual
            </ToggleButton>
            <ToggleButton active={displayMonthly} onClick={() => onBillingCycleChange(true)}>
              Monthly
            </ToggleButton>
          </ToggleButtonsGroup>
        </Box>
      </Box>

      <Slider>
        {subscriptionTiers.map((tier, i) => (
          <div key={i}>
            <Box display="flex" flexDirection="column" alignItems="center" mb={1.5}>
              <PlanHeader
                name={tier.name}
                price={displayMonthly ? tier.monthlyPrice || '0' : tier.annualPrice || '0'}
                align="center"
              />
              <BillingCycleLabel>
                Per Month, Billed {displayMonthly ? 'Monthly' : 'Annually'}
              </BillingCycleLabel>
            </Box>
            <StyledTable size="small">
              <TableBody>
                {SUBSCRIPTION_TIERS_INFO.map((row, i) => (
                  <StyledPlanInfoRow key={row.name}>
                    <TableCell component="th" scope="row" align="left">
                      {row.label}
                    </TableCell>
                    <StyledPlanInfoCell>
                      {formatCellContent(row, tier[row.name])}
                    </StyledPlanInfoCell>
                  </StyledPlanInfoRow>
                ))}
              </TableBody>
            </StyledTable>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default AvailablePlansAsSlider;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  commentFormContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& textarea': {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'bold',
      color: theme.palette.colors.darkBlueGrey
    },
    '& h3': {
      marginBottom: '15px'
    }
  },

  commentFormBottom: {
    display: 'flex',
    marginTop: '20px',
    width: '100%',
    justifyContent: 'flex-end'
  }
}));

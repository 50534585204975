/**
 * @flow
 * @relayHash bf50c6841083f48af4df840d59857c10
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobCommentThread_job$ref = any;
export type JobCommentThreadRefetchQueryVariables = {|
  id: string
|};
export type JobCommentThreadRefetchQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: JobCommentThread_job$ref
  |}
|};
export type JobCommentThreadRefetchQuery = {|
  variables: JobCommentThreadRefetchQueryVariables,
  response: JobCommentThreadRefetchQueryResponse,
|};
*/

/*
query JobCommentThreadRefetchQuery(
  $id: ID!
) {
  job: job(id: $id) {
    ...JobCommentThread_job_1Bmzm5
    id
  }
}

fragment JobCommentThread_job_1Bmzm5 on JobNode {
  id
  comments {
    edges {
      node {
        id
        content
        created
        contentEditedAt
        extraData
        commentType
        repliedTo {
          content
          created
          user {
            fullName
            id
          }
        }
        user {
          id
          fullName
          representativeImageUrl
        }
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id'
      }
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'content',
      args: null,
      storageKey: null
    },
    v4 = {
      kind: 'ScalarField',
      alias: null,
      name: 'created',
      args: null,
      storageKey: null
    },
    v5 = {
      kind: 'ScalarField',
      alias: null,
      name: 'fullName',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'JobCommentThreadRefetchQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: 'job',
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'JobCommentThread_job',
              args: (v1 /*: any*/)
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'JobCommentThreadRefetchQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: 'job',
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'LinkedField',
              alias: null,
              name: 'comments',
              storageKey: null,
              args: null,
              concreteType: 'CommentNodeConnection',
              plural: false,
              selections: [
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'edges',
                  storageKey: null,
                  args: null,
                  concreteType: 'CommentNodeEdge',
                  plural: true,
                  selections: [
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'node',
                      storageKey: null,
                      args: null,
                      concreteType: 'CommentNode',
                      plural: false,
                      selections: [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'contentEditedAt',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'extraData',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'commentType',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'repliedTo',
                          storageKey: null,
                          args: null,
                          concreteType: 'CommentRepliedToNode',
                          plural: false,
                          selections: [
                            (v3 /*: any*/),
                            (v4 /*: any*/),
                            {
                              kind: 'LinkedField',
                              alias: null,
                              name: 'user',
                              storageKey: null,
                              args: null,
                              concreteType: 'UserNode',
                              plural: false,
                              selections: [(v5 /*: any*/), (v2 /*: any*/)]
                            }
                          ]
                        },
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'user',
                          storageKey: null,
                          args: null,
                          concreteType: 'UserNode',
                          plural: false,
                          selections: [
                            (v2 /*: any*/),
                            (v5 /*: any*/),
                            {
                              kind: 'ScalarField',
                              alias: null,
                              name: 'representativeImageUrl',
                              args: null,
                              storageKey: null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'JobCommentThreadRefetchQuery',
      id: null,
      text:
        'query JobCommentThreadRefetchQuery(\n  $id: ID!\n) {\n  job: job(id: $id) {\n    ...JobCommentThread_job_1Bmzm5\n    id\n  }\n}\n\nfragment JobCommentThread_job_1Bmzm5 on JobNode {\n  id\n  comments {\n    edges {\n      node {\n        id\n        content\n        created\n        contentEditedAt\n        extraData\n        commentType\n        repliedTo {\n          content\n          created\n          user {\n            fullName\n            id\n          }\n        }\n        user {\n          id\n          fullName\n          representativeImageUrl\n        }\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '52a3babd49c1b0e62c52539d764bfa6c';
module.exports = node;

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { fetchQuery } from 'react-relay';

import SearchableFormSelect from './fields/SearchableFormSelect';
import TagsGrid from '../UI/TagsGrid';

import { getEnvironment } from '../../utils/helpers';

const SearchableDropdown = props => {
  const {
    withChipsBelow,
    value,
    selectChangeHandler,
    name,
    label,
    query,
    getFetchVariables,
    formatOptions,
    setInitialValue,
    handleOnDelete,
    resultKey,
    force,
    ...otherProps
  } = props;

  const [resultsCounts, setResultsCounts] = useState(null);

  const getResultsCounts = data => {
    let result = null;
    if (resultKey.includes('general')) {
      result = data['general'][resultKey.split('.')[1]];
    } else if (resultKey.includes('+')) {
      const resultKeys = resultKey.split('+');
      result = {};
      result.totalCount = data[resultKeys[0]].totalCount + data[resultKeys[1]].totalCount;
      result.edgeCount = data[resultKeys[0]].edgeCount + data[resultKeys[1]].edgeCount;
      // we will slice in the formatOptions to max 10
      if (result.edgeCount > 10) result.edgeCount = 10;
    } else {
      result = data[resultKey];
    }
    return result ? { totalCount: result.totalCount, edgeCount: result.edgeCount } : null;
  };

  const loadOptions = debounce((isMounted, inputValue, cb) => {
    fetchQuery(getEnvironment(), query, getFetchVariables(inputValue), { force }).then(data => {
      if (isMounted.current) {
        setResultsCounts(getResultsCounts(data));
        cb(formatOptions(data));
      }
    });
  }, 250);

  const isOptionDisabled = option => {
    return value.options.find(selectedOption => selectedOption.value === option.value)
      ? true
      : false;
  };

  const onChangeHandler = selected => {
    if (selected) {
      selectChangeHandler(name, {
        options: selected
      });
    }
  };

  const onDeleteHandler = deletedValue => {
    selectChangeHandler(name, {
      options: [...value.options].filter(option => option.value !== deletedValue)
    });
  };

  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    // prevents from creating duplicates only differing by case
    if (
      !selectOptions.length &&
      !value.options.find(option => option.label.toLowerCase() === inputValue.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <SearchableFormSelect
        resultsCounts={resultsCounts}
        {...(withChipsBelow
          ? {
              value: value.options,
              onChange: onChangeHandler,
              isOptionDisabled,
              controlShouldRenderValue: false
            }
          : { value, onChange: selectChangeHandler })}
        {...(setInitialValue ? { value } : null)}
        loadOptions={loadOptions}
        noCache={true}
        label={label || ''}
        id={`id-for-${name}`}
        name={name}
        isValidNewOption={isValidNewOption}
        {...otherProps}
      />
      {withChipsBelow && (
        <TagsGrid
          tags={value.options.map(option => ({ node: { id: option.value, name: option.label } }))}
          onDelete={onDeleteHandler}
          style={{ paddingTop: '0.5rem' }}
        />
      )}
    </Fragment>
  );
};

export default SearchableDropdown;

SearchableDropdown.propTypes = {
  selectChangeHandler: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  // graphql query
  query: PropTypes.func.isRequired,
  getFetchVariables: PropTypes.func.isRequired,
  formatOptions: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  // set true if initial value is not empty
  setInitialValue: PropTypes.bool,
  // whether to render chips below or inside select, when choosing this type of field it's required
  // for the initial field data to be `{options: []}`
  withChipsBelow: PropTypes.bool,
  creatable: PropTypes.bool,
  // This should be the key of the object containing 'totalCount' and 'edgeCount' in the query response.
  // Required for the label in dropdown. If quering for general please do "general.actualKeyNameHere".
  resultKey: PropTypes.string.isRequired,
  // set to true if we want to avoid cached options result
  // (for ex. when options get added/removed to the list that need to be added/removed to options like in Create Job Form for deliverables)
  force: PropTypes.bool
};

/**
 * @flow
 * @relayHash 3b2801d23b30a6b85273020f6603081b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DeliverableDetailsToggleFollowButton_deliverable$ref = any;
export type DeliverableDetailsToggleFollowButtonQueryVariables = {|
  id: string,
  withFollowers: boolean,
|};
export type DeliverableDetailsToggleFollowButtonQueryResponse = {|
  +deliverable: ?{|
    +$fragmentRefs: DeliverableDetailsToggleFollowButton_deliverable$ref
  |}
|};
export type DeliverableDetailsToggleFollowButtonQuery = {|
  variables: DeliverableDetailsToggleFollowButtonQueryVariables,
  response: DeliverableDetailsToggleFollowButtonQueryResponse,
|};
*/

/*
query DeliverableDetailsToggleFollowButtonQuery(
  $id: ID!
  $withFollowers: Boolean!
) {
  deliverable(id: $id) {
    ...DeliverableDetailsToggleFollowButton_deliverable_4hJhOB
    id
  }
}

fragment DeliverableDetailsToggleFollowButton_deliverable_4hJhOB on DeliverableNode {
  id
  isCurrentUserFollowing
  followers {
    edges @include(if: $withFollowers) {
      node {
        id
        fullName
      }
    }
    totalCount
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'withFollowers',
        type: 'Boolean!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id'
      }
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'DeliverableDetailsToggleFollowButtonQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'deliverable',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'DeliverableNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'DeliverableDetailsToggleFollowButton_deliverable',
              args: [
                {
                  kind: 'Variable',
                  name: 'withFollowers',
                  variableName: 'withFollowers'
                }
              ]
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'DeliverableDetailsToggleFollowButtonQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'deliverable',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'DeliverableNode',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'isCurrentUserFollowing',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'followers',
              storageKey: null,
              args: null,
              concreteType: 'StaffNodeConnection',
              plural: false,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'totalCount',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'Condition',
                  passingValue: true,
                  condition: 'withFollowers',
                  selections: [
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'edges',
                      storageKey: null,
                      args: null,
                      concreteType: 'StaffNodeEdge',
                      plural: true,
                      selections: [
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'node',
                          storageKey: null,
                          args: null,
                          concreteType: 'StaffNode',
                          plural: false,
                          selections: [
                            (v2 /*: any*/),
                            {
                              kind: 'ScalarField',
                              alias: null,
                              name: 'fullName',
                              args: null,
                              storageKey: null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'DeliverableDetailsToggleFollowButtonQuery',
      id: null,
      text:
        'query DeliverableDetailsToggleFollowButtonQuery(\n  $id: ID!\n  $withFollowers: Boolean!\n) {\n  deliverable(id: $id) {\n    ...DeliverableDetailsToggleFollowButton_deliverable_4hJhOB\n    id\n  }\n}\n\nfragment DeliverableDetailsToggleFollowButton_deliverable_4hJhOB on DeliverableNode {\n  id\n  isCurrentUserFollowing\n  followers {\n    edges @include(if: $withFollowers) {\n      node {\n        id\n        fullName\n      }\n    }\n    totalCount\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '8482905201860e94fcd0cdf36063ca52';
module.exports = node;

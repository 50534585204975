import React from 'react';

import Briefcase from '../UI/Briefcase';
import StyledTableCell from './StyledTableCell';
import { useTableStyles } from '../../styles/common/tableStyles';

const TableCellBriefcase = props => {
  const {
    handleClick,
    valueProps: { value }
  } = props;
  const classes = useTableStyles();
  return (
    <StyledTableCell noWrap>
      <div className={classes.verticalCenter}>
        <Briefcase onClick={handleClick} text={value} />
      </div>
    </StyledTableCell>
  );
};

export default TableCellBriefcase;

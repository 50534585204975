/**
 * @flow
 * @relayHash dd1a56fa77756a8f0afddc6894f3faf3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConfirmPresignedUploadInput = {|
  filename: string,
  modelName: string,
  modelId?: ?string,
  fieldName: string,
  stagedPath?: ?string,
  clientMutationId?: ?string,
|};
export type ConfirmPresignedUploadMutationVariables = {|
  input: ConfirmPresignedUploadInput
|};
export type ConfirmPresignedUploadMutationResponse = {|
  +confirmPresignedUpload: ?{|
    +isConfirmed: ?boolean
  |}
|};
export type ConfirmPresignedUploadMutation = {|
  variables: ConfirmPresignedUploadMutationVariables,
  response: ConfirmPresignedUploadMutationResponse,
|};
*/


/*
mutation ConfirmPresignedUploadMutation(
  $input: ConfirmPresignedUploadInput!
) {
  confirmPresignedUpload(input: $input) {
    isConfirmed
  }
}
*/

const node/*: ConcreteRequest*/ = (function () {
  var v0 = [
        {
          "kind": "LocalArgument",
          "name": "input",
          "type": "ConfirmPresignedUploadInput!",
          "defaultValue": null
        }
      ],
      v1 = [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "confirmPresignedUpload",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "input",
              "variableName": "input"
            }
          ],
          "concreteType": "ConfirmPresignedUploadPayload",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isConfirmed",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "ConfirmPresignedUploadMutation",
      "type": "Mutation",
      "metadata": null,
      "argumentDefinitions": (v0/*: any*/),
      "selections": (v1/*: any*/)
    },
    "operation": {
      "kind": "Operation",
      "name": "ConfirmPresignedUploadMutation",
      "argumentDefinitions": (v0/*: any*/),
      "selections": (v1/*: any*/)
    },
    "params": {
      "operationKind": "mutation",
      "name": "ConfirmPresignedUploadMutation",
      "id": null,
      "text": "mutation ConfirmPresignedUploadMutation(\n  $input: ConfirmPresignedUploadInput!\n) {\n  confirmPresignedUpload(input: $input) {\n    isConfirmed\n  }\n}\n",
      "metadata": {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '32770d48839d84418a6d99de964e1d38';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const cancelDeliverableMutation = graphql`
  mutation CancelDeliverableMutation($input: CancelDeliverableInput!) {
    cancelDeliverable(input: $input) {
      updatedDeliverable {
        state
      }
    }
  }
`;

export default (id, callback) => {
  const config = {
    mutation: cancelDeliverableMutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.cancelDeliverable);
    }
  };

  commitMutation(getEnvironment(), config);
};

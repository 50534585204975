import React from 'react';
import GlobalButton from './GlobalButton';
import { ReactComponent as EditIcon } from '../../images/edit.svg';

const EditButton = ({ id, onClick, buttonCopy = 'Edit', edit = true, disabled }) => {
  return (
    <GlobalButton
      id={id}
      variant="secondary"
      edit={edit}
      icon={EditIcon}
      handleClick={onClick}
      disabled={disabled}
    >
      {buttonCopy}
    </GlobalButton>
  );
};

export default EditButton;

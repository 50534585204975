import { FILE_STORAGE_CLASSES, REVIEW_INTERFACES } from '../../../constants';

export const isAssetWithMarkup = asset => {
  if (asset) {
    const { node } = asset;
    return (
      node.revisionNumber !== 0 &&
      node.storageClass === FILE_STORAGE_CLASSES.STANDARD &&
      node.reviewInterface !== REVIEW_INTERFACES.generic
    );
  } else {
    return false;
  }
};

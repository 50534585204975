import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Box, Typography } from '@material-ui/core';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import CreateReleaseBudgetMutation from '../../mutations/CreateReleaseBudgetMutation';

const CreateBudget = props => {
  const {
    release: { id, name }
  } = props;

  const onClickHandler = () => {
    CreateReleaseBudgetMutation(id);
  };

  return (
    <DetailsSection title="Budget" noBorder>
      <GlobalButton variant="secondary" handleClick={onClickHandler}>
        Create Budget
      </GlobalButton>
      <Box display="flex" justifyContent="center">
        <Typography variant="overline">{`${name} currently has no budget`}</Typography>
      </Box>
    </DetailsSection>
  );
};

export default createFragmentContainer(CreateBudget, {
  release: graphql`
    fragment CreateBudget_release on ReleaseNode {
      id
      name
    }
  `
});

import React from 'react';
import GlobalButton from './GlobalButton';

const CancelButton = ({ id, onClick, ...otherProps }) => {
  return (
    <GlobalButton id={id} variant="transparent" handleClick={onClick} {...otherProps}>
      Cancel
    </GlobalButton>
  );
};

export default CancelButton;

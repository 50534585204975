import React from 'react';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import ReactionButton from './ReactionButton';
import ToggleReviewAssetReactionMutation from './ToggleReviewAssetReactionMutation';

const AddReviewAssetReactionButton = ({ reviewAsset, userContext, ...buttonProps }) => {
  const handleSelect = reaction => {
    const existingReaction = reviewAsset.reactions.find(r => r.definitionId === reaction.id);
    const userHasReaction =
      existingReaction && existingReaction.users.find(user => user.id === userContext.id);

    if (!userHasReaction) {
      ToggleReviewAssetReactionMutation({
        reactionId: reaction.id,
        reviewAssetId: reviewAsset.id
      });
    }
  };

  return <ReactionButton onSelect={handleSelect} {...buttonProps} />;
};

export default withUserContext(AddReviewAssetReactionButton);

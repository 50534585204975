import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import graphql from 'babel-plugin-relay/macro';
import clsx from 'clsx';
import useQueryParams from '../../../shared/hooks/useQueryParams';
import { fetchQuery } from 'react-relay';

import { getEnvironment } from '../../../shared/utils/helpers';

import useSignUpStyles from '../../../shared/styles/account/useSignUpStyles';
import { ReactComponent as Logo } from '../../../shared/images/AWEbase-Default-Light.svg';
import { FullScreenContainer } from './FullScreenContainer';

const StripeComplete = props => {
  const { history } = props;
  const classes = useSignUpStyles();
  const queryParams = useQueryParams();

  useEffect(() => {
    getStripeCompleteData();
  }, []);

  const getQueryParam = param => {
    if (queryParams.get(param)) {
      return queryParams.get(param);
    } else return '';
  };

  const stripeCompleteQuery = graphql`
    query StripeCompleteQuery($uidb64: String!, $status: String!) {
      stripeCompleteUpdate(uidb64: $uidb64, status: $status) {
        status
        message
      }
    }
  `;

  const getStripeCompleteData = () => {
    if (getQueryParam('status') === 'False') {
      // if status is False, means user clicked Back (Cancel) from stripe checkout window,
      // no need to process anything on the backend
      redirectToAwebase();
    } else {
      fetchQuery(getEnvironment(), stripeCompleteQuery, {
        uidb64: getQueryParam('uidb64'),
        status: getQueryParam('status')
      }).then(response => {
        if (response && response.stripeCompleteUpdate) {
          redirectToAwebase();
        }
      });
    }
  };

  const redirectToAwebase = () => {
    history.push('/admin/billing/payment');
  };

  return (
    <FullScreenContainer>
      <div className={clsx(classes.logo, classes.logoContainer, classes.darkLogo)}>
        <Logo />
      </div>
      <div
        className={clsx(
          classes.planContainer,
          classes.textAlignCenter,
          classes.container,
          classes.containerColumn
        )}
      >
        <span className={classes.planName}>Payment Data Processing...</span>
      </div>
    </FullScreenContainer>
  );
};

export default withRouter(StripeComplete);

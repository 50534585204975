/**
 * @flow
 * @relayHash d942308a6d0d2ed843a31a725b558212
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BillingInvoices_billing$ref = any;
type BillingInvoices_stateTypes$ref = any;
export type BillingInvoicesRendererQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
|};
export type BillingInvoicesRendererQueryResponse = {|
  +__type: ?{|
    +$fragmentRefs: BillingInvoices_stateTypes$ref
  |},
  +$fragmentRefs: BillingInvoices_billing$ref,
|};
export type BillingInvoicesRendererQuery = {|
  variables: BillingInvoicesRendererQueryVariables,
  response: BillingInvoicesRendererQueryResponse,
|};
*/


/*
query BillingInvoicesRendererQuery(
  $first: Int
  $orderBy: String
  $after: String
) {
  ...BillingInvoices_billing
  __type(name: "StripeInvoiceStatus") {
    ...BillingInvoices_stateTypes
  }
}

fragment BillingInvoices_billing on Query {
  billingActivity(first: $first, orderBy: $orderBy, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        created
        status
        amount
        paidDate
        pdfLink
        typeLabel
      }
    }
  }
}

fragment BillingInvoices_stateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "StripeInvoiceStatus"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BillingInvoicesRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"StripeInvoiceStatus\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BillingInvoices_stateTypes",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "BillingInvoices_billing",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BillingInvoicesRendererQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "billingActivity",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          }
        ],
        "concreteType": "StripeInvoiceNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "StripeInvoiceNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "StripeInvoiceNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "created",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "amount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "paidDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pdfLink",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "typeLabel",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"StripeInvoiceStatus\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BillingInvoicesRendererQuery",
    "id": null,
    "text": "query BillingInvoicesRendererQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n) {\n  ...BillingInvoices_billing\n  __type(name: \"StripeInvoiceStatus\") {\n    ...BillingInvoices_stateTypes\n  }\n}\n\nfragment BillingInvoices_billing on Query {\n  billingActivity(first: $first, orderBy: $orderBy, after: $after) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        created\n        status\n        amount\n        paidDate\n        pdfLink\n        typeLabel\n      }\n    }\n  }\n}\n\nfragment BillingInvoices_stateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a337e91108887bb78e5f7d203699b633';
module.exports = node;

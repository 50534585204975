import * as JSZip from 'jszip';

const loadZip = (url) => {
    return new Promise(function(resolve, reject){
        fetch(url).then(function(response){
            if (response.status === 200 || response.status === 0) {
                return response.blob();
            }
        })
        .then(JSZip.loadAsync)
        .then(function(zip){
            let fileMap = {};
            let processing = [];

            for ( let file in zip.files ) {
                if (file.startsWith("__MACOSX")) continue;
				let entry = zip.file( file );

				if ( entry === null ) continue;

				processing.push( entry.async( 'blob' ).then( function ( file, blob ) {

					fileMap[ file ] = URL.createObjectURL( blob );

                }.bind( this, file ) ) );

            }
            return Promise.all(processing).then(function() {
                resolve(fileMap);
            }, function(reason) {
                reject("Something went wrong :" + reason);
            })
        })
    });
};

const filterFBX = (value) => {
    return value.toLowerCase().endsWith(".fbx");
}

const filterObj = (value) => {
    return value.toLowerCase().endsWith(".obj");
}

const filterMtl = (value) => {
    return value.toLowerCase().endsWith(".mtl");
}

const findObjFile = (fileMap) => {
    const keys = Object.keys(fileMap);
    let models = keys.filter(filterObj);
    return models ? models[0] : null;
}

const findFbxFile = (fileMap) => {
    const keys = Object.keys(fileMap);
    let models = keys.filter(filterFBX);
    return models ? models[0] : null;
}

const findMtlFile = (fileMap) => {
    const keys = Object.keys(fileMap);
    let models = keys.filter((filterMtl));
    return models ? models[0] : null;
}

const findModelFile = (fileMap) => {
    const keys = Object.keys(fileMap);
    let fileType = "fbx";
    // search for an fbx file
    let models = keys.filter(filterFBX);

    if (models.length === 0) {
        fileType = "obj";
        models = keys.filter(filterObj);
        
    }

    if(models.length >= 1) {
        // this is the model and we return the model key
        return {fileType, fileName: models[0]};
    } else {
        return null;
    }
}

const clearBlobs = (fileMap) => {
    Object.values(fileMap).forEach(blobUrl => {
        URL.revokeObjectURL(blobUrl);
    });
}

const resolveFileKey = (map, fileName) => {
    const keys = Object.keys(map);  

    return keys.find((el) => {
        return el.includes(fileName);
    });
}

export default loadZip;
export {findModelFile , findFbxFile, findObjFile, findMtlFile, clearBlobs, resolveFileKey};

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { DialogContentText } from '@material-ui/core';
import Dialog, { DialogActions } from './Dialog';
import FormikSearchableDropdown from '../form/FormikSearchableDropdown';
import GlobalButton from '../UI/GlobalButton';

const ToggleTagsDialog = ({ ids, onConfirm, activate, type, ...dialogProps }) => {
  const initialValues = {
    tags: { options: [] }
  };

  return (
    <Dialog
      title={`${activate ? 'Add' : 'Remove'} tags ${activate ? 'to' : 'from'} ${type}`}
      minWidth={350}
      {...dialogProps}
    >
      <Formik initialValues={initialValues} onSubmit={onConfirm}>
        {({ values, submitForm, isSubmitting, setFieldValue }) => (
          <>
            <DialogContentText variant="body1">
              {`${activate ? 'Add' : 'Remove'} tags ${
                activate ? 'to' : 'from'
              } selected ${type}.  If the ${type} ${
                activate ? 'already has' : 'does not have'
              } the tag, the record will not be modified.`}
            </DialogContentText>
            <Field
              name="tags"
              label="Tags"
              placeholder="Select tags..."
              component={FormikSearchableDropdown}
              value={values.tags}
              onChange={setFieldValue}
              creatable={activate ? true : false}
            />

            <DialogActions>
              <GlobalButton
                variant="transparent"
                handleClick={dialogProps.closeDialog}
                disabled={isSubmitting}
              >
                Cancel
              </GlobalButton>
              <GlobalButton handleClick={submitForm} loading={isSubmitting} disabled={isSubmitting}>
                {activate ? 'Add' : 'Remove'}
              </GlobalButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

ToggleTagsDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    })
  )
};

export default ToggleTagsDialog;

/**
 * @flow
 * @relayHash 7853e7eafeb1e1d4efd39c66fd4d710b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DeliverableFilesHeader_storageInfo$ref = any;
type DeliverableFilesList_deliverableStateTypes$ref = any;
type DeliverableFilesList_files$ref = any;
type DeliverableFilesList_storageClasses$ref = any;
export type StorageDeliverableFilesTabQueryVariables = {|
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
  storageClass?: ?string,
  deliverableState?: ?string,
  releaseId?: ?string,
  deliverableTitle?: ?string,
  fileName?: ?string,
|};
export type StorageDeliverableFilesTabQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: DeliverableFilesHeader_storageInfo$ref
  |},
  +storageClasses: ?{|
    +$fragmentRefs: DeliverableFilesList_storageClasses$ref
  |},
  +deliverableStateTypes: ?{|
    +$fragmentRefs: DeliverableFilesList_deliverableStateTypes$ref
  |},
  +$fragmentRefs: DeliverableFilesList_files$ref,
|};
export type StorageDeliverableFilesTabQuery = {|
  variables: StorageDeliverableFilesTabQueryVariables,
  response: StorageDeliverableFilesTabQueryResponse,
|};
*/


/*
query StorageDeliverableFilesTabQuery(
  $first: Int
  $after: String
  $orderBy: String
  $storageClass: String
  $deliverableState: String
  $releaseId: String
  $deliverableTitle: String
  $fileName: String
) {
  organization {
    ...DeliverableFilesHeader_storageInfo
    id
  }
  ...DeliverableFilesList_files
  storageClasses: __type(name: "StorageClassEnum") {
    ...DeliverableFilesList_storageClasses
  }
  deliverableStateTypes: __type(name: "DeliverableState") {
    ...DeliverableFilesList_deliverableStateTypes
  }
}

fragment DeliverableFilesHeader_storageInfo on OrganizationNode {
  storage {
    activeStorageFinalFiles
    archivedStorageFinalFiles
  }
}

fragment DeliverableFilesList_files on Query {
  finalFilesByOrg(first: $first, after: $after, orderBy: $orderBy, storageClass: $storageClass, deliverableState: $deliverableState, releaseId: $releaseId, deliverableTitle: $deliverableTitle, fileName: $fileName) {
    edges {
      node {
        id
        fileName
        fileSize
        fileUrl
        storageClass
        deliverable {
          title
          state
          release {
            name
            id
          }
          id
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
  }
}

fragment DeliverableFilesList_storageClasses on __Type {
  enumValues {
    name
    description
  }
}

fragment DeliverableFilesList_deliverableStateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "storageClass",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "deliverableState",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "releaseId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "deliverableTitle",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "fileName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "StorageClassEnum"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "DeliverableState"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "enumValues",
    "storageKey": null,
    "args": null,
    "concreteType": "__EnumValue",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StorageDeliverableFilesTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeliverableFilesHeader_storageInfo",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "storageClasses",
        "name": "__type",
        "storageKey": "__type(name:\"StorageClassEnum\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeliverableFilesList_storageClasses",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "deliverableStateTypes",
        "name": "__type",
        "storageKey": "__type(name:\"DeliverableState\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeliverableFilesList_deliverableStateTypes",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "DeliverableFilesList_files",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StorageDeliverableFilesTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "storage",
            "storageKey": null,
            "args": null,
            "concreteType": "StorageNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "activeStorageFinalFiles",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "archivedStorageFinalFiles",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "finalFilesByOrg",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "deliverableState",
            "variableName": "deliverableState"
          },
          {
            "kind": "Variable",
            "name": "deliverableTitle",
            "variableName": "deliverableTitle"
          },
          {
            "kind": "Variable",
            "name": "fileName",
            "variableName": "fileName"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          },
          {
            "kind": "Variable",
            "name": "releaseId",
            "variableName": "releaseId"
          },
          {
            "kind": "Variable",
            "name": "storageClass",
            "variableName": "storageClass"
          }
        ],
        "concreteType": "DeliverableFinalFileNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableFinalFileNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableFinalFileNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileSize",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileUrl",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "storageClass",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "deliverable",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ]
                      },
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "storageClasses",
        "name": "__type",
        "storageKey": "__type(name:\"StorageClassEnum\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": (v5/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": "deliverableStateTypes",
        "name": "__type",
        "storageKey": "__type(name:\"DeliverableState\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": (v5/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StorageDeliverableFilesTabQuery",
    "id": null,
    "text": "query StorageDeliverableFilesTabQuery(\n  $first: Int\n  $after: String\n  $orderBy: String\n  $storageClass: String\n  $deliverableState: String\n  $releaseId: String\n  $deliverableTitle: String\n  $fileName: String\n) {\n  organization {\n    ...DeliverableFilesHeader_storageInfo\n    id\n  }\n  ...DeliverableFilesList_files\n  storageClasses: __type(name: \"StorageClassEnum\") {\n    ...DeliverableFilesList_storageClasses\n  }\n  deliverableStateTypes: __type(name: \"DeliverableState\") {\n    ...DeliverableFilesList_deliverableStateTypes\n  }\n}\n\nfragment DeliverableFilesHeader_storageInfo on OrganizationNode {\n  storage {\n    activeStorageFinalFiles\n    archivedStorageFinalFiles\n  }\n}\n\nfragment DeliverableFilesList_files on Query {\n  finalFilesByOrg(first: $first, after: $after, orderBy: $orderBy, storageClass: $storageClass, deliverableState: $deliverableState, releaseId: $releaseId, deliverableTitle: $deliverableTitle, fileName: $fileName) {\n    edges {\n      node {\n        id\n        fileName\n        fileSize\n        fileUrl\n        storageClass\n        deliverable {\n          title\n          state\n          release {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n  }\n}\n\nfragment DeliverableFilesList_storageClasses on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n\nfragment DeliverableFilesList_deliverableStateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b37330250b71ebb427668a3b4e99f1fa';
module.exports = node;

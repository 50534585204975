/**
 * @flow
 * @relayHash e55bbe12c8c475b485cce529b787caf1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationContractorsList_solicitation$ref = any;
export type SolicitationContractorsListRefetchQueryVariables = {|
  first?: ?number,
  after?: ?string,
  id: string,
  orderBy?: ?string,
  contractorName?: ?string,
  includeInactive?: ?boolean,
  includeDoNotHire?: ?boolean,
  inviteStatus?: ?string,
|};
export type SolicitationContractorsListRefetchQueryResponse = {|
  +solicitation: ?{|
    +$fragmentRefs: SolicitationContractorsList_solicitation$ref
  |}
|};
export type SolicitationContractorsListRefetchQuery = {|
  variables: SolicitationContractorsListRefetchQueryVariables,
  response: SolicitationContractorsListRefetchQueryResponse,
|};
*/


/*
query SolicitationContractorsListRefetchQuery(
  $first: Int
  $after: String
  $id: ID!
  $orderBy: String
  $contractorName: String
  $includeInactive: Boolean
  $includeDoNotHire: Boolean
  $inviteStatus: String
) {
  solicitation: solicitation(id: $id) {
    ...SolicitationContractorsList_solicitation
    id
  }
}

fragment SolicitationContractorsList_solicitation on SolicitationNode {
  id
  state
  solicitationId
  numberOfDeliverables
  numberOfAllocatedDeliverables
  numberOfContractorsThatContacted
  numberOfContractedDeliverables
  contractors(first: $first, after: $after, orderBy: $orderBy, contractorName: $contractorName, includeDoNotHire: $includeDoNotHire, includeInactive: $includeInactive, inviteStatus: $inviteStatus) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        fullName
        representativeImageUrl
        numberOfAllocatedDeliverablesInSolicitation
        numberOfCommentsInSolicitation
        numberOfContractedDeliverablesInSolicitation
        freelancer {
          user {
            userId
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractorName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeDoNotHire",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "inviteStatus",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationContractorsListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "solicitation",
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SolicitationContractorsList_solicitation",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationContractorsListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "solicitation",
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "solicitationId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfAllocatedDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfContractorsThatContacted",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfContractedDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractors",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "contractorName",
                "variableName": "contractorName"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "includeDoNotHire",
                "variableName": "includeDoNotHire"
              },
              {
                "kind": "Variable",
                "name": "includeInactive",
                "variableName": "includeInactive"
              },
              {
                "kind": "Variable",
                "name": "inviteStatus",
                "variableName": "inviteStatus"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              }
            ],
            "concreteType": "ContractorNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "representativeImageUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numberOfAllocatedDeliverablesInSolicitation",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numberOfCommentsInSolicitation",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numberOfContractedDeliverablesInSolicitation",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "freelancer",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FreelancerNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "userId",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationContractorsListRefetchQuery",
    "id": null,
    "text": "query SolicitationContractorsListRefetchQuery(\n  $first: Int\n  $after: String\n  $id: ID!\n  $orderBy: String\n  $contractorName: String\n  $includeInactive: Boolean\n  $includeDoNotHire: Boolean\n  $inviteStatus: String\n) {\n  solicitation: solicitation(id: $id) {\n    ...SolicitationContractorsList_solicitation\n    id\n  }\n}\n\nfragment SolicitationContractorsList_solicitation on SolicitationNode {\n  id\n  state\n  solicitationId\n  numberOfDeliverables\n  numberOfAllocatedDeliverables\n  numberOfContractorsThatContacted\n  numberOfContractedDeliverables\n  contractors(first: $first, after: $after, orderBy: $orderBy, contractorName: $contractorName, includeDoNotHire: $includeDoNotHire, includeInactive: $includeInactive, inviteStatus: $inviteStatus) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        fullName\n        representativeImageUrl\n        numberOfAllocatedDeliverablesInSolicitation\n        numberOfCommentsInSolicitation\n        numberOfContractedDeliverablesInSolicitation\n        freelancer {\n          user {\n            userId\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4250fd6b4dd2e0d62175e52ae890addc';
module.exports = node;

import React, { Fragment, useEffect, useState } from 'react';
import { scroller, Element } from 'react-scroll';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled from 'styled-components';
import { Divider, Typography } from '@material-ui/core';

import CommentCard from './CommentCard';
import CommentForm from './CommentForm';
import CommentRepliedTo from './CommentRepliedTo';

import useCommentThreadStyles from '../../styles/comment/useCommentThreadStyles';
import withUserContext from '../../contexts/userContext/withUserContext';
import Dialog from '../common/Dialog';

const DialogCommentForm = styled(CommentForm)`
  margin-top: 15px;
  padding: 0;
`;

const SCROLL_CONTAINER_ID = 'scroll-container';

const CommentThread = props => {
  const {
    comments,
    formTitle,
    handleAddComment,
    handleReplyToComment,
    handleSelectAsset,
    handleDeleteComment,
    handleUpdateComment,
    disabled,
    highlightedAnnotationRef,
    handleHighlightAnnotation,
    userContext,
    withScroll,
    refetch,
    withTime,
    noEditButton,
    noDeleteButton,
    headerButtonProps,
    noMaxHeight,
    scrollOnAnnotationHighlightClick
  } = props;

  const classes = useCommentThreadStyles({ noMaxHeight });
  const [replyPopupData, setReplyPopupData] = useState(false);
  const [lastHighlightAnnotationClickOn, setLastHighlightAnnotationClickOn] = useState(null);

  useEffect(() => {
    // Scrolls to the highlighted comment on update of highlightedAnnotationRef
    if (highlightedAnnotationRef && lastHighlightAnnotationClickOn !== highlightedAnnotationRef) {
      scroller.scrollTo(highlightedAnnotationRef, {
        duration: 200,
        delay: 0,
        containerId: SCROLL_CONTAINER_ID,
        smooth: 'easeInOutQuart'
      });
    }
    setLastHighlightAnnotationClickOn(null);
  }, [highlightedAnnotationRef]);

  const handleCommentReplyClick = comment => {
    setReplyPopupData(comment);
  };

  const handleCommentReplySubmit = ({ comment }, callback) => {
    handleReplyToComment(
      {
        comment,
        repliedTo: replyPopupData
      },
      () => {
        callback();
        setReplyPopupData(false);
      }
    );
  };

  const mappedComments = comments.map((comment, index) => {
    const isCurrentUser = comment.userId === userContext.id;

    return (
      <Element key={comment.commentId} name={withScroll ? comment.annotationRef : null}>
        <CommentCard
          isCurrentUser={isCurrentUser}
          {...comment}
          commentText={comment.content}
          date={comment.dateCreated}
          highlighted={
            highlightedAnnotationRef && comment.annotationRef === highlightedAnnotationRef
          }
          direction={isCurrentUser ? 'end' : 'start'}
          handleReply={
            handleReplyToComment && !disabled ? () => handleCommentReplyClick(comment) : undefined
          }
          handleDelete={isCurrentUser ? handleDeleteComment : null}
          handleUpdate={handleUpdateComment}
          handleSelectAsset={handleSelectAsset}
          handleHighlightAnnotation={
            comment.annotationRef
              ? annotationId => {
                  if (scrollOnAnnotationHighlightClick)
                    setLastHighlightAnnotationClickOn(annotationId);
                  handleHighlightAnnotation(annotationId);
                }
              : undefined
          }
          refetch={refetch}
          withTime={withTime}
          noEditButton={noEditButton}
          noDeleteButton={noDeleteButton}
        />
        {index < comments.length - 1 && <Divider />}
      </Element>
    );
  });

  return (
    <div className={classes.root}>
      {handleReplyToComment && (
        <Dialog
          isDialogOpen={replyPopupData !== false}
          title="Reply to comment"
          closeDialog={() => setReplyPopupData(false)}
          minWidth={320}
        >
          {replyPopupData && (
            <CommentRepliedTo
              user={{
                fullName: replyPopupData.fullName,
                avatarSrc: replyPopupData.avatarSrc
              }}
              created={replyPopupData.dateCreated}
              content={replyPopupData.content}
            />
          )}
          <DialogCommentForm
            formTitleText={false}
            handleAddComment={handleCommentReplySubmit}
            commentInputProps={{
              autoFocus: true
            }}
          />
        </Dialog>
      )}

      {handleAddComment && (
        <Fragment>
          <CommentForm
            disabled={disabled}
            handleAddComment={handleAddComment}
            formTitleText={formTitle}
            headerButtonProps={headerButtonProps}
          />
          <Divider />
        </Fragment>
      )}
      {comments && comments.length ? (
        <Element
          id={withScroll ? SCROLL_CONTAINER_ID : null}
          className={clsx('element', classes.commentsSection)}
        >
          {mappedComments}
        </Element>
      ) : (
        <Typography variant="subtitle1" className={classes.noComments}>
          There are no comments.
        </Typography>
      )}
    </div>
  );
};

CommentThread.propTypes = {
  formTitle: PropTypes.string,
  disabled: PropTypes.bool,
  handleAddComment: PropTypes.func,
  handleReplyToComment: PropTypes.func,
  handleDeleteComment: PropTypes.func,
  // only needed when component requires custom update mutation
  handleUpdateComment: PropTypes.func,
  handleSelectAsset: PropTypes.func,
  // Passed to CommentCard this function handles selecting
  // the comment via the annotation button/icon.
  handleHighlightAnnotation: PropTypes.func,
  // AnnotatioRef of highlighted comment. Used to highlight
  // the comment & as an id for scroll elements.
  highlightedAnnotationRef: PropTypes.string,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      commentId: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      avatarSrc: PropTypes.string,
      assetIndex: PropTypes.number,
      content: PropTypes.string.isRequired,
      dateCreated: PropTypes.string.isRequired,
      wasCommentEdited: PropTypes.bool
    })
  ),
  userContext: PropTypes.object,
  // Setting withScroll to true allows the commentThread to scroll
  // to the highlighted comment by adding names and id's to react-scroll elements.
  withScroll: PropTypes.bool,
  refetch: PropTypes.func,
  maxHeight: PropTypes.string
};

CommentThread.defaultProps = {
  scrollOnAnnotationHighlightClick: true
};

export default withUserContext(CommentThread);

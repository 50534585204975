import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { CELL_TYPES } from '../../../../shared/constants';
import AutoTable from '../../../../shared/components/table/AutoTable';
import { startCase } from 'lodash';

const BillingInvoices = props => {
  if (!props.billing.billingActivity) return <div>Loading...</div>;

  const {
    relay: { refetch },
    billing: {
      billingActivity: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    },
    stateTypes
  } = props;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const flattenedEdges = edges.map(edge => {
    const { id, created, typeLabel, paidDate, status, amount, pdfLink } = edge.node;
    const invoiceLink = pdfLink ? { fileUrl: pdfLink, fileName: pdfLink } : null;
    return {
      node: {
        id,
        created,
        typeLabel: startCase(typeLabel),
        paidDate,
        status: { code: status },
        amount,
        // we only use USD currency for any stripe billing, regardless of what is currency of organization
        currencyCode: 'USD',
        invoiceLink
      }
    };
  });

  return (
    <AutoTable
      rowTemplate={[
        { name: 'typeLabel', label: 'Subscription' },
        { name: 'created', label: 'Invoice Date', type: CELL_TYPES.date, sortable: true },
        { name: 'paidDate', label: 'Date Paid', type: CELL_TYPES.date },
        {
          name: 'status',
          label: 'Status',
          type: CELL_TYPES.status,
          statusTypes: stateTypes.enumValues,
          variant: 'billingActivity'
        },
        { name: 'amount', label: 'Amount', type: CELL_TYPES.amount },
        {
          name: 'invoiceLink',
          label: '',
          type: CELL_TYPES.fileDownload,
          icon: 'invoice',
          tooltipTitle: 'Download Invoice',
          width: 30
        }
      ]}
      onChangeHandler={onChangeHandler}
      initialOrderBy="-created"
      edges={flattenedEdges}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
    />
  );
};

export default createRefetchContainer(
  BillingInvoices,
  {
    billing: graphql`
      fragment BillingInvoices_billing on Query {
        billingActivity(first: $first, orderBy: $orderBy, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
          edges {
            node {
              id
              created
              status
              amount
              paidDate
              pdfLink
              typeLabel
            }
          }
        }
      }
    `,
    stateTypes: graphql`
      fragment BillingInvoices_stateTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  },
  graphql`
    query BillingInvoicesRefetchQuery($first: Int, $orderBy: String, $after: String) {
      ...BillingInvoices_billing
    }
  `
);

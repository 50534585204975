import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import DeliverableList from './DeliverableList';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';

const DeliverableListPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableListPageQuery(
          $first: Int
          $after: String
          $orderBy: String
          $includeInactive: Boolean
          $state: String
          $title: String
          $releaseId: String
          $productId: String
          $tags: String
          $dueDateFrom: Date
          $dueDateTo: Date
          $staffCoordinator: String
          $assignedToStaffOrContractor: String
          $includeClosed: Boolean
          $includeCanceled: Boolean
          $category: ID
          $categoryType: ID
          $onlyFollowed: Boolean
        ) {
          ...DeliverableList_deliverables
          __type(name: "DeliverableState") {
            ...DeliverableList_stateTypes
          }
        }
      `}
      variables={variables}
      render={relayProps => (
        <DeliverableList
          deliverables={relayProps}
          deliverable={relayProps}
          stateTypes={relayProps.__type}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(DeliverableListPage, 'orgDeliverablesList', {
  orderBy: 'dueDate',
  initialFilters: {
    title: '',
    tags: '',
    releaseId: null,
    productId: null,
    category: null,
    categoryType: null,
    state: '',
    staffCoordinator: null,
    assignedToStaffOrContractor: null,
    dueDateFrom: null,
    dueDateTo: null,
    includeInactive: false,
    includeClosed: false,
    includeCanceled: false,
    onlyFollowed: false
  }
});

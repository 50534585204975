import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import CssBaseline from '@material-ui/core/CssBaseline';
import GlobalButton from '../../components/UI/GlobalButton';

import { MainContainer, Toolbar, ContentContainer } from './styled';

function ElevationScroll(props) {
  const { children, trigger } = props;

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullscreenModal = props => {
  const { open, handleBack, backButtonText, children, noTopBar, actions } = props;

  const [scrollContainer, setScrollContainer] = useState(undefined);

  const scrollTrigger = useScrollTrigger({
    target: scrollContainer,
    disableHysteresis: true,
    threshold: 0
  });

  const isTopBarSubmitting = actions
    ? actions.find(action => action.isSubmitting === true) !== undefined
    : false;

  return (
    <Dialog fullScreen open={open} onClose={handleBack} TransitionComponent={Transition}>
      <MainContainer>
        {!noTopBar && (
          <Fragment>
            <CssBaseline />
            <ElevationScroll trigger={scrollTrigger} {...props}>
              <AppBar color="default">
                <Toolbar>
                  <GlobalButton
                    id={`fullscreenModal-${backButtonText}`}
                    handleClick={handleBack}
                    variant="secondary"
                    disabled={isTopBarSubmitting}
                  >
                    {backButtonText}
                  </GlobalButton>
                  {actions &&
                    actions.map((action, i) => (
                      <GlobalButton
                        key={i}
                        handleClick={action.handler}
                        loading={action.isSubmitting}
                        disabled={isTopBarSubmitting}
                        id={`fullscreenModal-${action.label}`}
                        {...action.btnProps}
                      >
                        {action.label}
                      </GlobalButton>
                    ))}
                </Toolbar>
              </AppBar>
            </ElevationScroll>
            <Toolbar />
          </Fragment>
        )}

        <ContentContainer
          ref={node => {
            if (node) {
              setScrollContainer(node);
            }
          }}
        >
          {children}
        </ContentContainer>
      </MainContainer>
    </Dialog>
  );
};

FullscreenModal.propTypes = {
  /** Flag to open Dialog component */
  open: PropTypes.bool.isRequired,
  /** Callback on back button
   * Accepts event object from component
   * Needs to have a passed-in setter function for open prop to close
   */
  handleBack: PropTypes.func.isRequired,
  /** Text to render back button */
  backButtonText: PropTypes.string,

  // Hides the tob bar / header on the modal
  noTopBar: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Callback on action button
       * Accepts event object from component
       * Needs to have a passed-in setter function for open prop to close
       */
      handler: PropTypes.func.isRequired,
      /** Text to render action button */
      label: PropTypes.string.isRequired,
      isSubmitting: PropTypes.bool
    })
  )
};

FullscreenModal.defaultProps = {
  open: false,
  handleBack: () => {},
  backButtonText: 'Back'
};

export default FullscreenModal;

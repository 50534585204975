import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import usePrevious from '../../../hooks/usePrevious';
import useInterval from '../../../hooks/useInterval';
import { mapReviewComments } from '../../../utils/helpers';
import { resolveDeliverableReviewMarkupLink } from '../../../utils/linkResolvers';
import imageForZeroAsset from '../../../images/asset-zero.png';
import { RichTextFormattedValue } from '../../richText';
import SquareButton from '../../UI/SquareButton';
import AssetReviewCommentChip from '../../UI/AssetReviewCommentChip';
import CommentListContainer, { EmptyCommentList } from '../review/CommentListContainer';
import CommentCard, {
  CommentEditedLabel,
  CommentPreview,
  ReactionsGroup
} from '../review/CommentCard';
import ReactionCard from '../review/ReactionCard';
import {
  MarkupRoute,
  Header,
  ContentContainer,
  CommentsListOuterContainer,
  CommentListHeader,
  CommentContainer,
  ReviewAssetThreadContainer,
  ActiveCommentView,
  AssetCard
} from './components';
import { isAssetWithMarkup } from './helpers';
import AddCommentReactionButton from '../../../../organization/components/deliverables/reactions/AddCommentReactionButton';
import ToggleCommentReactionMutation from '../../../../organization/components/deliverables/reactions/ToggleCommentReactionMutation';

const DeliverableReviewContent = props => {
  const {
    deliverable,
    canChangeReviewStep,
    canAddComment,
    canUpload,
    canAddReaction,
    onReviewStepChange,
    history,
    location,
    refetch
  } = props;

  const assets = deliverable.reviewActive
    ? deliverable.reviewActive.deliverableReviewAssets.edges.map((asset, index) => {
        const comments = asset.node.comments.edges.map(comment => ({
          node: {
            ...comment.node,
            revisionNumber: asset.node.revisionNumber
          }
        }));
        return {
          node: {
            ...asset.node,
            ...(asset.node.revisionNumber === 0
              ? { fileThumbnailUrl: imageForZeroAsset, reviewInterface: 'image' }
              : {}),
            comments: {
              edges: comments
            }
          }
        };
      })
    : [];

  const comments = mapReviewComments(
    assets.reduce((acc, asset) => {
      return acc.concat(
        asset.node.comments.edges.map(edge => ({
          node: {
            ...edge.node,
            withMarkup: isAssetWithMarkup(asset)
          }
        }))
      );
    }, [])
  );

  const previousProps = usePrevious({ review: deliverable.reviewActive });
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [activeCommentId, setActiveCommentId] = useState(
    comments[0] ? comments[0].commentId : null
  );

  useInterval(() => {
    if (!history.location.pathname.includes('markup')) {
      refetch(true);
    }
  }, 60000);

  useEffect(() => {
    if (
      location.state &&
      location.state.commentId &&
      location.state.commentId !== activeCommentId
    ) {
      setActiveCommentId(location.state.commentId);
    }
  }, [location.state]);

  useEffect(() => {
    const assetFromUrl = getAssetByRevisionNumber(getRevisionNumberFromUrl());
    if (assetFromUrl) {
      if (didAssetChange(selectedAsset, assetFromUrl)) {
        setSelectedAsset(assetFromUrl);
      }
    } else {
      history.replace(
        `${props.match.url}/${deliverable.reviewActive.deliverableReviewAssets.edges[0].node.revisionNumber}`
      );
    }
  }, [location]);

  useEffect(() => {
    const { reviewActive: review } = deliverable;
    // this effect is mostly useful to trigget url change after each refetch interval (1min) if some changes were done by
    // another user
    if (previousProps) {
      if (previousProps.review === null) {
        if (review) {
          // responsible for selecting the asset when starting review process
          props.history.replace(
            `${props.match.url}/${review.deliverableReviewAssets.edges[0].node.revisionNumber}`
          );
        }
      } else {
        if (review === null) {
          props.history.replace(`${props.match.url}`);
        } else {
          // this will trigger [location] use Effect and update selectedAsset with most recent data if any of it changed
          const assetFromUrl = getAssetByRevisionNumber(getRevisionNumberFromUrl());
          if (assetFromUrl) {
            // So we don't update the state unnecessarily, we check if any components that could have changed, changed:
            // like first asset selected, different asset selected or comments added/deleted
            if (didAssetChange(selectedAsset, assetFromUrl)) {
              setSelectedAsset(assetFromUrl);
            }
          } else {
            props.history.replace(
              `${props.match.url}/${review.deliverableReviewAssets.edges[0].node.revisionNumber}`
            );
          }
        }
      }
    }
  }, [deliverable.reviewActive]);

  const didAssetChange = (prevAsset, newAsset) => {
    if (!prevAsset && !newAsset) {
      return false;
    }
    if ((!prevAsset && newAsset) || (prevAsset && !newAsset)) {
      return true;
    }
    return (
      prevAsset.node.id !== newAsset.node.id ||
      prevAsset.node.comments.edges.length !== newAsset.node.comments.edges.length ||
      prevAsset.node.revisionNumber !== newAsset.node.revisionNumber
    );
  };

  const getAssetByRevisionNumber = revisionNumber => {
    const assets = deliverable.reviewActive.deliverableReviewAssets.edges;
    return revisionNumber || revisionNumber === 0
      ? assets.find(({ node }) => node.revisionNumber === Number(revisionNumber))
      : null;
  };

  const getRevisionNumberFromUrl = () => {
    let match = props.location.pathname.match(/review\/(\d+)/);
    return match ? match[1] : null;
  };

  const handleViewMarkup = ({ revisionNumber, annotationRef }) => {
    const link = resolveDeliverableReviewMarkupLink(deliverable.id, revisionNumber);
    history.push(link, { annotationRef: annotationRef });
  };

  return (
    <>
      <MarkupRoute
        deliverable={deliverable}
        selectedAsset={selectedAsset}
        canAddComment={canAddComment}
        canAddReaction={canAddReaction}
      />

      <Header
        deliverable={deliverable}
        canUpload={canUpload}
        onReviewStepChange={onReviewStepChange}
        canChangeReviewStep={canChangeReviewStep}
      />

      <ContentContainer>
        <CommentsListOuterContainer>
          <CommentListHeader
            onAddComment={() => {
              setActiveCommentId(null);
            }}
          />
          <CommentListContainer>
            {comments.length > 0 ? (
              comments.map(comment => {
                const actions = [
                  <AssetReviewCommentChip
                    onClick={e => {
                      e.stopPropagation();
                      const asset = getAssetByRevisionNumber(comment.revisionNumber);
                      setSelectedAsset(asset);
                      setActiveCommentId(comment.commentId);
                    }}
                    revisionNumber={comment.revisionNumber}
                  />
                ];
                if (comment.annotationRef && comment.withMarkup) {
                  actions.push(
                    <SquareButton
                      onClick={e => {
                        e.stopPropagation();
                        handleViewMarkup(comment);
                      }}
                      variant="secondary"
                      icon="annotation"
                      size={24}
                    />
                  );
                }

                if (canAddReaction) {
                  actions.push(<AddCommentReactionButton comment={comment} />);
                }

                return (
                  <CommentCard
                    key={comment.commentId}
                    authorName={comment.fullName}
                    authorAvatar={comment.avatarSrc}
                    date={comment.dateCreated}
                    actions={actions}
                    withHover
                    withAttachments={comment.referenceFiles.length > 0}
                    active={activeCommentId === comment.commentId}
                    onClick={() => {
                      setActiveCommentId(comment.commentId);
                    }}
                  >
                    <CommentPreview>
                      <RichTextFormattedValue value={comment.content} plain />
                    </CommentPreview>
                    {comment.wasCommentEdited && <CommentEditedLabel />}
                    {comment.reactions && comment.reactions.length > 0 && (
                      <ReactionsGroup>
                        {comment.reactions.map(reaction => (
                          <ReactionCard
                            key={reaction.id}
                            reaction={reaction}
                            onDelete={() => {
                              ToggleCommentReactionMutation({
                                reactionId: reaction.definitionId,
                                commentId: comment.commentId
                              });
                            }}
                          />
                        ))}
                      </ReactionsGroup>
                    )}
                  </CommentCard>
                );
              })
            ) : (
              <EmptyCommentList />
            )}
          </CommentListContainer>
        </CommentsListOuterContainer>
        <CommentContainer>
          <ActiveCommentView
            deliverable={deliverable}
            selectedAsset={selectedAsset}
            canAddComment={canAddComment}
            comment={
              activeCommentId && comments.find(comment => comment.commentId === activeCommentId)
            }
            onViewMarkup={handleViewMarkup}
            onDelete={() => setActiveCommentId(null)}
            onCreate={comment => setActiveCommentId(comment.id)}
          />
        </CommentContainer>
        <ReviewAssetThreadContainer>
          {assets.map(asset => (
            <AssetCard
              asset={asset.node}
              key={asset.node.id}
              active={selectedAsset && selectedAsset.node.id === asset.node.id}
              onRevisionNumberClick={() => {
                setActiveCommentId(null);
                history.push(`${props.match.url}/${asset.node.revisionNumber}`);
              }}
              canAddReaction={asset.node.revisionNumber === 0 ? false : canAddReaction}
              canDelete={canUpload && asset.node.comments.edges.length === 0}
              onViewMarkup={
                isAssetWithMarkup(asset)
                  ? () => {
                      handleViewMarkup({ revisionNumber: asset.node.revisionNumber });
                    }
                  : null
              }
            />
          ))}
        </ReviewAssetThreadContainer>
      </ContentContainer>
    </>
  );
};

DeliverableReviewContent.propTypes = {
  canAddComment: PropTypes.bool,
  canUploadAsset: PropTypes.bool,
  canChangeReviewStep: PropTypes.bool,
  refetch: PropTypes.func.isRequired,
  deliverable: PropTypes.shape({
    reviewActive: PropTypes.object
  }).isRequired
};

export default withRouter(DeliverableReviewContent);

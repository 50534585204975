import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import ContractTemplatesList from './ContractTemplatesList';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../shared/contexts/tableHistoryContext';

const ContractTemplatesPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ContractTemplatesPageQuery(
          $first: Int
          $orderBy: String
          $after: String
          $excludeInactive: Boolean
        ) {
          ...ContractTemplatesList_contractTemplates
          __type(name: "ContractTemplateState") {
            ...ContractTemplatesList_stateTypes
          }
        }
      `}
      variables={variables}
      render={relayProps => (
        <ContractTemplatesList
          label="ContractTemplates"
          contractTemplates={relayProps}
          stateTypes={relayProps.__type}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(ContractTemplatesPage, 'orgContactTemplates', {
  orderBy: 'name',
  initialFilters: {
    excludeInactive: true
  }
});

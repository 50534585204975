import React from 'react';

import { Typography } from '@material-ui/core';
import { useCommonAnnotationStyles } from '../../styles/deliverable/commonAnnotationStyles';

import { formatSecondsToHms } from '../../utils/formatters';

const Timer = props => {
  const { time, primary, ...otherProps } = props;
  const classes = useCommonAnnotationStyles({ primary });

  return (
    <div className={classes.timerContainer}>
      <Typography variant="body2" {...otherProps}>
        {formatSecondsToHms(time)}
      </Typography>
    </div>
  );
};

export default Timer;

import React, { Fragment, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import { withRouter } from 'react-router';

import { CELL_TYPES } from '../../../shared/constants';
import AutoTable from '../../../shared/components/table/AutoTable';
import ContractPreviewWithSignaturePad from './ContractPreviewWithSignaturePad';
import JobsPageFilters from './JobsPageFilters';

const ContractsList = props => {
  const {
    relay: { refetch },
    jobs: {
      allJobs: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    },
    statusTypes: { enumValues: statusEnumValues }
  } = props;

  const [showContract, setShowContract] = useState(null);

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    const { history, match } = props;
    history.push(`${match.url}/${id}`);
  };

  const onContractClickHandler = id => {
    const job = edges.find(element => element.node.id === id);
    if (job) {
      setShowContract(job.node.contract);
    }
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      jobId,
      totalValue: amount,
      currencyCode,
      staffCoordinator: {
        fullName: staffName,
        representativeImageUrl: imageUrl,
        organization: { name: organization, representativeImageUrl: organizationImage }
      },
      contractDate,
      jobDeadline,
      status,
      deliverables,
      contract,
      name
    } = edge.node;
    return {
      node: {
        id,
        orgSequenceId: organization + ' #' + jobId,
        amount,
        currencyCode,
        staff: { name: staffName, imageUrl },
        organization: { name: organization, imageUrl: organizationImage },
        jobDeadline,
        contractDate,
        status: { code: status },
        quantity: deliverables.edges.length,
        contract,
        name
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: 'orgSequenceId', label: 'Org. Job ID', sortable: true, type: CELL_TYPES.link },
          { name: 'name', label: 'Job Name', sortable: true, type: CELL_TYPES.link, width: '20%' },
          { name: 'contract', label: '', type: CELL_TYPES.contract },
          {
            name: 'contractDate',
            label: 'Contract Date',
            type: CELL_TYPES.date,
            sortable: true,
            width: 120
          },
          {
            name: 'jobDeadline',
            label: 'Job Deadline',
            type: CELL_TYPES.date,
            sortable: true,
            width: 114
          },
          {
            name: 'status',
            label: 'Status',
            type: CELL_TYPES.status,
            statusTypes: statusEnumValues,
            variant: 'job'
          },
          {
            name: 'staff',
            label: 'Contact',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center'
          },
          { name: 'quantity', label: 'Deliverables', align: 'right' },
          { name: 'amount', label: 'Amount', type: CELL_TYPES.amount, sortable: true }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        filterProps={{
          filterComponent: <JobsPageFilters statusOptions={statusEnumValues} />,
          withApply: true
        }}
        rowProps={{
          handleLinkClick: onLinkClickHandler,
          handleContractClick: onContractClickHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
        withBackground
      />
      {showContract ? (
        <ContractPreviewWithSignaturePad
          contract={showContract}
          showContract={showContract ? true : false}
          setShowContract={setShowContract}
        />
      ) : null}
    </Fragment>
  );
};

export default withRouter(
  createRefetchContainer(
    ContractsList,
    {
      jobs: graphql`
        fragment ContractsList_jobs on Query
        @argumentDefinitions(
          after: { type: "String" }
          first: { type: "Int" }
          orderBy: { type: "String", defaultValue: "-contractDate" }
          status: { type: "String", defaultValue: "" }
          organization: { type: "String" }
          orgSequenceId: { type: "String" }
          staffId: { type: "String" }
          contractDateFrom: { type: "Date" }
          contractDateTo: { type: "Date" }
          jobDeadlineFrom: { type: "Date" }
          jobDeadlineTo: { type: "Date" }
          name: { type: "String" }
        ) {
          allJobs(
            after: $after
            first: $first
            orderBy: $orderBy
            status: $status
            organization: $organization
            orgSequenceId: $orgSequenceId
            staffId: $staffId
            contractDateFrom: $contractDateFrom
            contractDateTo: $contractDateTo
            jobDeadlineFrom: $jobDeadlineFrom
            jobDeadlineTo: $jobDeadlineTo
            jobName: $name
          ) {
            totalCount
            edgeCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                jobId
                name
                staffCoordinator {
                  fullName
                  representativeImageUrl
                  organization {
                    name
                    representativeImageUrl
                    currencyCode
                  }
                }
                jobDeadline
                totalValue
                contractDate
                status
                currencyCode
                deliverables {
                  edges {
                    node {
                      id
                    }
                  }
                }
                contract {
                  id
                  contractFileUrl
                  job {
                    status
                  }
                }
              }
            }
          }
        }
      `,
      statusTypes: graphql`
        fragment ContractsList_statusTypes on __Type {
          enumValues {
            name
            description
          }
        }
      `
    },
    graphql`
      query ContractsListRefetchQuery(
        $first: Int
        $orderBy: String
        $after: String
        $status: String
        $organization: String
        $orgSequenceId: String
        $staffId: String
        $contractDateFrom: Date
        $contractDateTo: Date
        $jobDeadlineFrom: Date
        $jobDeadlineTo: Date
        $name: String
      ) {
        ...ContractsList_jobs
          @arguments(
            first: $first
            orderBy: $orderBy
            after: $after
            status: $status
            organization: $organization
            orgSequenceId: $orgSequenceId
            staffId: $staffId
            contractDateFrom: $contractDateFrom
            contractDateTo: $contractDateTo
            jobDeadlineFrom: $jobDeadlineFrom
            jobDeadlineTo: $jobDeadlineTo
            name: $name
          )
      }
    `
  )
);

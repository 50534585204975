/**
 * @flow
 * @relayHash ab262b8ee09037e1b3c29de2838bf038
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReactionsQueryRendererQueryVariables = {||};
export type ReactionsQueryRendererQueryResponse = {|
  +reactionByOrg: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +color: string,
      |}
    |}>
  |}
|};
export type ReactionsQueryRendererQuery = {|
  variables: ReactionsQueryRendererQueryVariables,
  response: ReactionsQueryRendererQueryResponse,
|};
*/


/*
query ReactionsQueryRendererQuery {
  reactionByOrg {
    edges {
      node {
        id
        name
        color
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reactionByOrg",
    "storageKey": null,
    "args": null,
    "concreteType": "ReactionDefinitionNodeConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ReactionDefinitionNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ReactionDefinitionNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "color",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReactionsQueryRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReactionsQueryRendererQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ReactionsQueryRendererQuery",
    "id": null,
    "text": "query ReactionsQueryRendererQuery {\n  reactionByOrg {\n    edges {\n      node {\n        id\n        name\n        color\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c54d746147b3b4da9cd206e2c9abe21';
module.exports = node;

import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import RelayQueryResponseCache from 'relay-runtime/lib/RelayQueryResponseCache';
import { isEmpty } from 'lodash';

import {
  AWE_AUTH_TOKEN_ORGANIZATION,
  AWE_AUTH_TOKEN_FREELANCER,
  APP_TYPES,
  API_URLS
} from './services/constants';

import {
  isPermissionDenied,
  isUnauthorized,
  isNotFound,
  isNonCritical,
  isLoginError,
  isUIError,
  formatErrorToString
} from './utils/helpers';

import authService from './services/authService';
import { errorToast } from './toasts';

const oneMinute = 60 * 1000;
const cache = new RelayQueryResponseCache({ size: 250, ttl: oneMinute });

function getFetchQuery(applicationType) {
  let authToken, apiUrl;

  switch (applicationType) {
    case APP_TYPES.organization:
      authToken = AWE_AUTH_TOKEN_ORGANIZATION;
      apiUrl = API_URLS.organization;
      break;
    case APP_TYPES.freelancer:
      authToken = AWE_AUTH_TOKEN_FREELANCER;
      apiUrl = API_URLS.freelancer;
      break;
    default:
      break;
  }

  return function fetchQuery(operation, variables, cacheConfig, uploadables) {
    let requestVariables = {
      method: 'POST',
      headers: {
        ...(authService.jwt[authToken] && {
          AppAuthorization: `JWT ${authService.jwt[authToken]}`
        })
      }
    };
    let body;

    if (!isEmpty(uploadables)) {
      if (!window.FormData) {
        throw new Error('Uploading files without `FormData` not supported.');
      }

      // Clean up empty file entries
      Object.keys(uploadables).forEach(key => {
        if (uploadables[key] === '' || uploadables[key] === null) {
          delete uploadables[key];
        }
      });

      const formData = new FormData();

      formData.append('query', operation.text);
      formData.append('variables', JSON.stringify(variables));

      // Adding each file to formData
      Object.keys(uploadables).forEach(key => {
        formData.append(key, uploadables[key]);
      });

      body = formData;
    } else {
      requestVariables.headers['Content-Type'] = 'application/json';
      body = JSON.stringify({
        query: operation.text,
        variables
      });
    }

    const queryID = operation.text;
    const isMutation = operation.operationKind === 'mutation';
    const isQuery = operation.operationKind === 'query';
    const forceFetch = cacheConfig && cacheConfig.force;

    // Try to get data from cache on queries
    const fromCache = cache.get(queryID, variables);
    if (isQuery && fromCache !== null && !forceFetch) {
      return fromCache;
    }

    // Otherwise, fetch data from server

    return fetch(apiUrl, {
      ...requestVariables,
      body
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        // Update cache on queries
        if (isQuery && json) {
          cache.set(queryID, variables, json);
        }
        // Clear cache on mutations
        if (isMutation) {
          cache.clear();
        }
        if (json.errors && isPermissionDenied(json.errors)) {
          authService.signout();
          return;
        }

        if (json.errors && isUnauthorized(json.errors)) {
          authService.redirectForbidden();
          return;
        }

        if (json.errors && isNotFound(json.errors)) {
          authService.redirectNotFound();
          return;
        }

        if (json.errors && isUIError(json.errors)) {
          // specially formatted errors in the backend
          if (json.errors[0].fields.general) {
            const buttonProps = json.errors[0].fields.general_link
              ? {
                  link: json.errors[0].fields.general_link.url,
                  text: json.errors[0].fields.general_link.text,
                  externalLink: json.errors[0].fields.general_link.external === 'true'
                }
              : null;
            errorToast(
              json.errors[0].fields.general,
              { autoClose: buttonProps ? 20000 : 5000 },
              buttonProps
            );
          }
          return json;
        }

        if (json.errors && !isNonCritical(json.errors) && !isLoginError(json.errors)) {
          // for login error we show it in UI, no need for toast message
          console.log('enviroments.js errors', json);
          errorToast(formatErrorToString(json.errors), { autoClose: 5000 });
        }

        return json;
      });
  };
}

export const organizationEnvironment = new Environment({
  network: Network.create(getFetchQuery(APP_TYPES.organization)),
  store: new Store(new RecordSource())
});

export const freelancerEnvironment = new Environment({
  network: Network.create(getFetchQuery(APP_TYPES.freelancer)),
  store: new Store(new RecordSource())
});

import { makeStyles } from '@material-ui/styles';

export const filterStyles = theme => ({
  filterIcon: {
    '&.MuiIcon-root': {
      margin: '2px',
      fontSize: '13px',
      marginRight: '8px',
      color: props => (props.active ? theme.palette.primary.main : '')
    }
  },
  checkboxGridItem: {
    marginTop: theme.spacing(2),
    '& > .MuiGrid-item': {
      padding: `0 ${theme.spacing(3)}px`
    }
  },
  filterSection: {
    '& .filterInputFullWidth': {
      width: '100%'
    },
    '& .filterInputMarginZero': {
      margin: 0
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    borderRadius: '5px',
    position: 'relative',

    '&::after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      left: '5px',
      bottom: 0,
      height: '1px',
      backgroundColor: theme.palette.colors.filterButtonGrey,
      width: 'calc(100% - 10px)'
    }
  },
  filterRoot: {
    '& .MuiGrid-grid-xs-2': {
      flexBasis: '20%',
      maxWidth: '20%'
    },
    '& .MuiGrid-grid-xs-4': {
      flexBasis: '40%',
      maxWidth: '40%'
    }
  },
  dueDateFrom: {
    '&::after': {
      content: '"..."',
      position: 'absolute',
      margin: '2rem 0 0 7px'
    }
  },
  filtersWidth: {
    maxWidth: props => (props.width ? `${props.width}px` : 'initial')
  },
  alignSelfCenter: {
    alignSelf: 'center'
  }
});

export const useFilterStyles = makeStyles(filterStyles);

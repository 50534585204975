import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RestoreIcon from '@material-ui/icons/Restore';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import { FILE_STORAGE_CLASSES } from '../../../constants';
import StyledTableCell from '../../../components/table/StyledTableCell';
import SquareButton from '../../UI/SquareButton';

const FileStatusCellComponent = ({ node, ...rest }) => {
  let icon;
  let tooltipText;
  if (node.storageClass === FILE_STORAGE_CLASSES.RESTORING) {
    icon = RestoreIcon;
    tooltipText = 'Restoring';
  } else if (node.storageClass === FILE_STORAGE_CLASSES.GLACIER) {
    icon = BusinessCenterIcon;
    tooltipText = 'Archived';
  }

  // NOTE:
  // Button is wrapped into a span because tooltip doesn't work for disabled buttons
  return (
    <StyledTableCell {...rest}>
      <SquareButton disabled variant="transparent" icon={icon} tooltipTitle={tooltipText} />
    </StyledTableCell>
  );
};

FileStatusCellComponent.propTypes = {
  node: PropTypes.shape({
    storageClass: PropTypes.oneOf(Object.values(FILE_STORAGE_CLASSES))
  })
};

export const FileStatusCell = styled(FileStatusCellComponent)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

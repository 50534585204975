import React from 'react';
import UserContext from './userContext';

const withUserContext = Component => {
  return props => {
    return (
      <UserContext.Consumer>
        {context => <Component {...props} userContext={context} />}
      </UserContext.Consumer>
    );
  };
};

export default withUserContext;

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { makeStyles } from '@material-ui/styles';

const useClearFieldButtonStyles = makeStyles(theme => ({
  clearInputButton: {
    marginRight: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer'
    },
    '& svg': {
      width: '12px',
      height: '12px'
    }
  }
}));

const ClearFieldButton = props => {
  const { onClick } = props;
  const classes = useClearFieldButtonStyles();

  return (
    <IconButton onClick={onClick} size="small" className={classes.clearInputButton}>
      <ClearIcon />
    </IconButton>
  );
};

ClearFieldButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ClearFieldButton;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type LogEntryAction = "A_0" | "A_1" | "A_2" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeliverableActionLogList_deliverable$ref: FragmentReference;
declare export opaque type DeliverableActionLogList_deliverable$fragmentType: DeliverableActionLogList_deliverable$ref;
export type DeliverableActionLogList_deliverable = {|
  +id: string,
  +history: ?{|
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
    +totalCount: ?number,
    +edgeCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +timestamp: any,
        +actor: ?{|
          +orgStaff: ?{|
            +fullName: ?string,
            +representativeImageUrl: ?string,
          |},
          +freelancer: ?{|
            +fullName: ?string,
            +representativeImageUrl: ?string,
          |},
        |},
        +action: LogEntryAction,
        +changes: string,
        +additionalData: ?any,
      |}
    |}>,
  |},
  +$refType: DeliverableActionLogList_deliverable$ref,
|};
export type DeliverableActionLogList_deliverable$data = DeliverableActionLogList_deliverable;
export type DeliverableActionLogList_deliverable$key = {
  +$data?: DeliverableActionLogList_deliverable$data,
  +$fragmentRefs: DeliverableActionLogList_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "DeliverableActionLogList_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "history",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "LogEntryNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "LogEntryNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "LogEntryNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "timestamp",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "actor",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "orgStaff",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "StaffNode",
                      "plural": false,
                      "selections": (v1/*: any*/)
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "freelancer",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "FreelancerNode",
                      "plural": false,
                      "selections": (v1/*: any*/)
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "action",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "changes",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "additionalData",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '39fb3f1d41dc542931f79dc9e27b9269';
module.exports = node;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';

import TextField from '../form/fields/TextField';

const FilterDebounceInput = props => {
  const {
    value,
    name,
    label,
    onChange,
    errorMessage,
    validateNegativeNumbers,
    validateNegativeNumbersAndZero,
    ...otherProps
  } = props;

  const [fieldError, setFieldError] = useState('');

  const onChangeHandler = event => {
    if (
      (validateNegativeNumbers && event.target.value < 0) ||
      (validateNegativeNumbersAndZero && event.target.value <= 0)
    ) {
      setFieldError(errorMessage);
      return;
    } else {
      setFieldError('');
    }

    onChange(name, event.target.value);
  };

  const handleKeyPress = evt => {
    if (otherProps.type === 'number' && ['e', 'E', '+', '-'].includes(evt.key)) {
      evt.preventDefault();
    }
  };

  return (
    <DebounceInput
      element={TextField}
      error={!!fieldError}
      helperText={fieldError}
      min="0"
      value={value}
      onChange={onChangeHandler}
      name={name}
      id={`debounceInput-${name}`}
      label={label}
      debounceTimeout={500}
      minLength={1}
      onKeyPress={handleKeyPress}
      {...otherProps}
    />
  );
};

FilterDebounceInput.propTypes = {
  validateNegativeNumbers: PropTypes.bool,
  validateNegativeNumbersAndZero: PropTypes.bool,
  errorMessage: PropTypes.string
};

FilterDebounceInput.defaultProps = {
  validateNegativeNumbers: false,
  validateNegativeNumbersAndZero: false,
  errorMessage: 'Cannot be a negative number'
};

export default FilterDebounceInput;

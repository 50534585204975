import React, { Fragment, useState } from 'react';
import { Typography } from '@material-ui/core';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import useQuickViewStyles from '../../../../../shared/styles/common/useQuickViewStyles';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import { CELL_TYPES } from '../../../../../shared/constants';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import { getEnvironment } from '../../../../../shared/utils/helpers';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';

const groupQuickViewQuery = graphql`
  query GroupsQuickViewQuery($id: ID!, $first: Int, $orderBy: String, $after: String) {
    contractorGroup(id: $id) {
      id
      name
      created
      createdBy {
        fullName
      }
      contractors(first: $first, orderBy: $orderBy, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
        edgeCount
        edges {
          node {
            id
            fullName
            representativeImageUrl
          }
        }
      }
    }
  }
`;

const GroupsQuickView = props => {
  const { groupId } = props;
  const [contractorGroup, setGroup] = useState(null);
  const { first } = useTableHistoryQueryVariables();

  const classes = useQuickViewStyles();

  useEffectWithStatus(
    status => {
      setGroup(null);
      fetchQuery(getEnvironment(), groupQuickViewQuery, {
        id: groupId,
        first,
        orderBy: 'name'
      }).then(response => {
        status.mounted && setGroup(response.contractorGroup);
      });
    },
    [groupId]
  );

  const onChangeHandler = variables => {
    fetchQuery(getEnvironment(), groupQuickViewQuery, { ...variables, id: groupId }).then(
      response => {
        setGroup(response.contractorGroup);
      }
    );
  };

  if (!contractorGroup) return 'Loading...';

  const adjustedEdges = contractorGroup.contractors.edges.map(edge => {
    const { id, fullName, representativeImageUrl } = edge.node;

    return {
      node: {
        id,
        fullName: { name: fullName, imageUrl: representativeImageUrl }
      }
    };
  });

  return (
    <Fragment>
      <div className={classes.quickViewHeader}>
        <Typography variant="h1">{contractorGroup.name}</Typography>
      </div>
      <DetailsSection title="Group Details">
        <DetailsRow title="Founder" content={contractorGroup.createdBy.fullName} />
        <DetailsRowDate title="Creation date" date={contractorGroup.created} />
      </DetailsSection>

      <DetailsSection title="Contractors in group">
        <div className={classes.tablePadding}>
          <AutoTable
            edges={adjustedEdges}
            onChangeHandler={onChangeHandler}
            rowTemplate={[
              { name: 'fullName', type: CELL_TYPES.avatar, label: 'Name' }
              //Disabling Ratings from UI until feature fully fleshed out
              // { name: 'rating', type: CELL_TYPES.rating, label: 'Rating' }
            ]}
            paginationProps={{
              hasNextPage: contractorGroup.contractors.pageInfo.hasNextPage,
              endCursor: contractorGroup.contractors.pageInfo.endCursor,
              totalCount: contractorGroup.contractors.totalCount,
              edgeCount: contractorGroup.contractors.edgeCount
            }}
          />
        </div>
      </DetailsSection>
    </Fragment>
  );
};

export default withTableHistoryProvider(GroupsQuickView, 'orgGroupContractors');

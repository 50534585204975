import React, { Component, Fragment } from 'react';
import videojs from 'video.js';

import ProgressToolbar from '../../review/ProgressToolbar';
import Dialog from '../../common/Dialog';
import AnnotationCommentForm from '../AnnotationCommentForm';
import SquareButton from '../../UI/SquareButton';
import {
  AnnotationActionsContainer,
  AnnotationAssetContainer,
  AnnotationToolbarContainer
} from './AnnotationInterfaceContainers';

import withDialogProps from '../../../hoc/withDialogProps';
import { errorToast } from '../../../toasts';
import { VIDEO_MIME_TYPE_MAPPER } from '../../../constants';
import { getTimeFromAnnotationRef } from '../../../utils/helpers';
import UpdateDeliverableReviewAnnotationMutation from '../../../mutations/review/UpdateDeliverableReviewAnnotationMutation';

class LegacyVideoAnnotation extends Component {
  state = {
    isMuted: false,
    isPlaying: false,
    volume: 100,
    duration: null,
    currentTime: 0
  };

  componentDidMount() {
    const { src } = this.props;
    let mimeType = this.getType(src);

    let sourceData = {
      src
    };

    if (mimeType !== undefined) {
      sourceData['type'] = mimeType;
    }

    this.player = videojs(this.videoNode, {
      preload: 'metadata',
      controls: false,
      fluid: true,
      sources: [sourceData]
    });

    this.player.ready(this.onPlayerReady);
    this.player.on('loadedmetadata', this.onLoadedMetaData);
    this.player.on('timeupdate', this.onTimeUpdate);
    this.player.on('play', this.onPlayPause);
    this.player.on('pause', this.onPlayPause);
  }

  componentDidUpdate(prevProps) {
    const { highlightedAnnotationRef: previousAnnotationRef } = prevProps;
    this.highlightChangeHandler(previousAnnotationRef);
  }

  getType = src => {
    if (src.startsWith('http')) {
      const urlSplit = src.split('/');
      const filenameTokens = urlSplit[urlSplit.length - 1];
      const cleanedFilename = filenameTokens.split('?')[0];
      const cleanedTokens = cleanedFilename.split('.');
      const extension = cleanedTokens[cleanedTokens.length - 1];

      return VIDEO_MIME_TYPE_MAPPER[extension];
    } else {
      const cleanedTokens = src.split('.');
      const extension = cleanedTokens[cleanedTokens.length - 1];

      return VIDEO_MIME_TYPE_MAPPER[extension];
    }
  };

  highlightChangeHandler = previousAnnotationRef => {
    const { highlightedAnnotationRef } = this.props;

    if (highlightedAnnotationRef && highlightedAnnotationRef !== previousAnnotationRef) {
      const time = getTimeFromAnnotationRef(highlightedAnnotationRef);
      this.player.currentTime(time);
    }
  };

  componentWillUnmount() {
    this.player && this.player.dispose();
  }

  onLoadedMetaData = () => {
    this.setState({ duration: this.player.duration().toFixed(0) });
  };

  onPlayPause = () => {
    this.setState({ isPlaying: !this.state.isPlaying });
  };

  onTimeUpdate = () => {
    const newTime = this.player.currentTime().toFixed(0);
    if (newTime !== this.state.currentTime) {
      this.setState({ currentTime: newTime });
    }
  };

  onPlayerReady = () => {
    this.setState({ volume: this.player.volume() });
  };

  volumeChangeHandler = volume => {
    this.setState({ volume: volume }, () => {
      this.player.volume(this.state.volume);
    });
  };

  toggleMuteHandler = () => {
    const { isMuted } = this.state;
    this.setState(
      {
        isMuted: !isMuted
      },
      () => {
        this.player.muted(this.state.isMuted);
      }
    );
  };

  progressBarClickHandler = trackPercent => {
    this.isHighlightSet() && this.props.handleHighlightAnnotation();
    this.player.currentTime(this.state.duration * trackPercent);
  };

  togglePlayHandler = () => {
    this.player.paused() ? this.player.play() : this.player.pause();
  };

  isHighlightSet = () => {
    return this.props.highlightedAnnotationRef !== null;
  };

  onSubmitHandler = (content, cb = () => {}) => {
    const { annotationData, assetId, toggleDialogOpen } = this.props;
    const { currentTime } = this.state;
    if (annotationData.find(annotation => annotation.time === currentTime)) {
      errorToast('Cannot add two annotations at the same point in time.');
    } else {
      const newAnnotation = {
        time: currentTime,
        annotationRef: `annotation-${annotationData.length}-${currentTime}`
      };
      UpdateDeliverableReviewAnnotationMutation(
        {
          reviewAsset: assetId,
          annotationData: JSON.stringify(annotationData.concat(newAnnotation)),
          annotationRef: newAnnotation.annotationRef,
          content
        },
        () => {
          cb();
          toggleDialogOpen();
        }
      );
    }
  };

  onSubmitWithoutHandler = cb => {
    this.onSubmitHandler('Added annotation without a comment', cb);
  };

  addCommentHandler = () => {
    this.props.toggleDialogOpen();
  };

  render() {
    const { volume, isMuted, isPlaying, duration, currentTime } = this.state;
    const {
      annotationData,
      highlightedAnnotationRef,
      handleHighlightAnnotation,
      isDialogOpen,
      toggleDialogOpen,
      onCloseClick,
      canAddMarkup
    } = this.props;

    return (
      <Fragment>
        <AnnotationActionsContainer>
          <SquareButton onClick={onCloseClick} variant="black" icon="close" />
        </AnnotationActionsContainer>
        <AnnotationAssetContainer>
          <div data-vjs-player>
            <video ref={node => (this.videoNode = node)} className="video-js"></video>
          </div>
        </AnnotationAssetContainer>
        <AnnotationToolbarContainer>
          <ProgressToolbar
            sliderProps={{
              min: 0,
              max: 1,
              step: 0.01,
              value: isMuted ? 0 : volume,
              onChange: this.volumeChangeHandler,
              onClick: this.toggleMuteHandler
            }}
            isPlaying={isPlaying}
            duration={duration}
            currentTime={currentTime}
            annotationData={annotationData}
            highlightedAnnotationRef={highlightedAnnotationRef}
            onProgressBarClick={this.progressBarClickHandler}
            onAddCommentClick={this.addCommentHandler}
            onTogglePlayClick={this.togglePlayHandler}
            onAnnotationClick={handleHighlightAnnotation}
            canAddMarkup={canAddMarkup}
          />
        </AnnotationToolbarContainer>
        <Dialog
          isDialogOpen={isDialogOpen}
          title="Leave a comment"
          closeDialog={toggleDialogOpen}
          noButton
        >
          <AnnotationCommentForm
            handleOnSubmit={this.onSubmitHandler}
            handleSubmitWithoutComment={this.onSubmitWithoutHandler}
            closeDialog={toggleDialogOpen}
          />
        </Dialog>
      </Fragment>
    );
  }
}

export default withDialogProps(LegacyVideoAnnotation);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

export default (password1, password2, uidb64, resetToken, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation ResetPasswordMutation($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
          message
          email
          clientMutationId
        }
      }
    `,
    variables: {
      input: {
        password1,
        password2,
        uidb64,
        resetToken,
        clientMutationId: ''
      }
    },
    onCompleted: response => {
      callback(response.resetPassword);
    },
    onError: err => console.error(err)
  });
};

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

/**
 * @param {Object} variables Mutation variables
 * @param {string} variables.jobId ID of the job
 * @param {number} variables.paymentAmount
 * @param {string} variables.message
 * @param {boolean} variables.attachContract
 * @param {File} invoice_file Pdf file
 * @param {function} onCompleted onCompleted callback
 * @param {function} onError onError callback
 */

export default (variables, invoice_file, onCompleted, onError) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation PreviewPaymentRequestMutation($input: PreviewPaymentRequestInput!) {
        previewPaymentRequest(input: $input) {
          paymentRequestPreview
        }
      }
    `,
    variables: { input: variables },
    uploadables: { invoice_file },
    onCompleted,
    onError
  });
};

import React from 'react';
import get from 'lodash/get';
import PhoneField from './fields/PhoneField';

const FormikPhoneField = props => {
  const { field, form, ...otherProps } = props;

  const isInvalid = get(form.errors, field.name) && get(form.touched, field.name);

  return (
    <PhoneField
      name={field.name}
      value={field.value}
      error={isInvalid}
      helperText={get(form.errors, field.name)}
      onChange={val => form.setFieldValue(field.name, val)}
      onBlur={form.handleBlur}
      {...otherProps}
    />
  );
};

export default FormikPhoneField;

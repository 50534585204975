import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../../../shared/relay/QueryRenderer';
import InvoicesList from './InvoicesList';

const InvoicesListQueryRenderer = ({ jobId }) => (
  <QueryRenderer
    query={graphql`
      query InvoicesListQuery($jobId: ID!) {
        job(id: $jobId) {
          ...InvoicesList_job
        }
        invoiceStatusTypes: __type(name: "JobInvoiceStatus") {
          ...InvoicesList_statusTypes
        }
      }
    `}
    variables={{ jobId }}
    render={relayProps => (
      <InvoicesList job={relayProps.job} statusTypes={relayProps.invoiceStatusTypes} />
    )}
  />
);

export default InvoicesListQueryRenderer;

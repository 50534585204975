import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const BulkToggleTagsOnContractorsMutation = (input, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation BulkToggleTagsOnContractorsMutation($input: ToggleContractorsTagsInput!) {
        toggleContractorsTags(input: $input) {
          contractors {
            id
          }
        }
      }
    `,
    variables: {
      input
    },
    onCompleted: (response, errors) => {
      callback(response.contractors, errors);
    },
    onError: err => console.error(err)
  });
};

export default BulkToggleTagsOnContractorsMutation;

import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@material-ui/core';
import clsx from 'clsx';

import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import TextField from '../../../../shared/components/form/fields/TextField';
import useInviteFormStyles from '../../../../shared/styles/contractors/useInviteFormStyles';

const InvitationForm = props => {
  const {
    formTitleText,
    handleAddMessage,
    messageText,
    handleSetMessage,
    isSubmitting,
    disabled
  } = props;
  const classes = useInviteFormStyles();

  return (
    <ListItem className={clsx(classes.inviteFormContainer, props.className)}>
      {formTitleText && <ListItemText primary={formTitleText} />}
      <TextField
        name="addMessage"
        value={messageText}
        disabled={disabled}
        onChange={handleSetMessage}
        placeholder="Add a custom message to the invite email here."
        multiline
        rows={5}
        fullWidth
      />
      <GlobalButton
        id="addMessageInviteForm"
        disabled={isSubmitting}
        loading={isSubmitting}
        handleClick={handleAddMessage}
      >
        Send Invite
      </GlobalButton>
    </ListItem>
  );
};

InvitationForm.propTypes = {
  className: PropTypes.string,
  /** Determines the title text for form UI */
  formTitleText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  messageText: PropTypes.string,
  handleSetMessage: PropTypes.func,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  handleAddMessage: PropTypes.func.isRequired
};

InvitationForm.defaultProps = {
  handleAddMessage: () => {
    console.info('Send Invite CLICKED');
  }
};

export default InvitationForm;

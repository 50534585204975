import { makeStyles } from '@material-ui/styles';

export const tableStyles = theme => ({
  hidden: {
    '&.MuiTableCell-root': {
      display: 'none'
    }
  },

  root: {
    marginTop: '30px',
    overflowX: 'auto',
    boxShadow: 'none'
  },
  table: {
    borderCollapse: 'collapse',

    '& td, th': {
      borderBottom: 'none'
    }
  },

  tableFooter: {
    position: 'relative',
    transform: 'scale(1)',

    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      zIndex: '-1',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.colors.tableBackground,
      borderRadius: '0 0 4px 4px',
      marginLeft: '-30px',
      marginRight: '-30px'
    },

    '& tr': {
      position: 'relative'
    }
  },

  tableHeadRow: {
    backgroundColor: 'transparent',
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    '& th': {
      color: theme.palette.colors.mediumBlueGrey,
      fontWeight: 'bold',
      fontSize: '12px',
      border: 'none',
      '&:first-child': {
        paddingLeft: '9px'
      },
      '&:last-child': {
        paddingRight: '9px'
      }
    }
  },

  tableCellTruncate: {
    maxWidth: '1px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  navigationIcons: {
    cursor: 'pointer',
    color: theme.palette.colors.darkBlueGrey
  },

  disabledNavigationIcon: {
    cursor: 'not-allowed',
    opacity: '0.3'
  },

  activeNavigationIcon: {
    color: 'black',
    cursor: 'pointer'
  },

  footerLabel: {
    float: 'right'
  },

  stateTableCell: {
    fontWeight: 'bold',
    '& svg': {
      height: '0.4em',
      width: '0.4em',
      marginRight: '4px'
    }
  },

  tableCellGrey: {
    '&.MuiTableCell-body': {
      color: theme.palette.colors.darkBlueGrey,
      fontWeight: 'normal',
      hyphens: 'auto',
      '& a': {
        textDecoration: 'none',
        fontWeight: 'bold',
        color: theme.palette.colors.darkBlueGrey,
        '&:hover': {
          color: theme.palette.primary.main
        }
      }
    }
  },

  tableCellClickable: {
    '&.MuiTableCell-body': {
      fontWeight: 'bold',
      color: theme.palette.colors.darkBlueGrey,
      fontSize: '14px',
      '&:hover': {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      }
    }
  },

  avatarCellWithText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => (props.onlyTooltip ? 'center' : ''),
    '& .MuiAvatar-root': {
      marginRight: props => (props.onlyTooltip ? '' : '9px')
    }
  },

  thumbnailImage: {
    margin: '10px 10px 5px 0px',
    borderRadius: '5px',
    width: '150px',
    height: '94px',
    background: '#d3d3d3',
    objectFit: 'cover'
  },

  noWrapCell: {
    whiteSpace: 'noWrap'
  },

  avatarCell: {
    paddingRight: '1px',
    '& svg': {
      color: theme.palette.colors.dividerGrey
    }
  },

  emptyCell: {
    paddingLeft: '0px'
  },

  infoIcon: {
    height: '0.8em',
    color: theme.palette.colors.darkBlueGrey,
    '&:hover': {
      cursor: 'pointer'
    },
    '& path': {
      fill: theme.palette.colors.darkBlueGrey
    }
  },

  tableCellFlags: {
    '& svg': {
      width: '18px',
      height: '18px',
      marginRight: '6px',
      verticalAlign: 'middle',
      alignItems: 'center',
      '& g': {
        fill: theme.palette.colors.red
      }
    }
  },

  tableCellWarning: {
    padding: '0!important',
    '& svg': {
      height: '16px',
      width: '16px',
      '& g': {
        fill: theme.palette.colors.red
      }
    }
  },

  verticalCenter: {
    display: 'flex',
    justifyContent: 'center'
  },

  verticalTop: {
    '&.MuiTableCell-root': {
      verticalAlign: 'top'
    }
  },

  iconCell: {
    '& svg': {
      height: '16px',
      width: '16px'
    }
  },

  actionsCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& svg': {
      height: '16px',
      marginRight: '16px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    color: theme.palette.colors.darkBlueGrey
  },

  upDownCell: {
    '& svg': {
      height: '20px',
      marginRight: '16px'
    }
  },

  warningCell: {
    '&.MuiTableCell-body': {
      fontWeight: 'bold',
      color: props => props.colorProp
    }
  },

  lightGreyIcon: {
    fill: theme.palette.colors.mediumBlueGrey
  },

  greenIcon: {
    fill: theme.palette.colors.green
  },

  redIcon: {
    fill: theme.palette.colors.red
  },

  cursor: {
    cursor: 'pointer'
  },

  '& .MuiFormControlLabel-labelPlacementStart': {
    marginLeft: 0,
    marginRight: 0
  },

  minWidthCell: {
    minWidth: '80px'
  }
});

export const useTableStyles = makeStyles(tableStyles);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const UpdateReactionsMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation UpdateReactionsMutation($input: UpdateReactionDefinitionsInput!) {
          updateReactions(input: $input) {
            reactions {
              id
              name
              color
            }
          }
        }
      `,
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default UpdateReactionsMutation;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractorJobsTopSection_contractor$ref: FragmentReference;
declare export opaque type ContractorJobsTopSection_contractor$fragmentType: ContractorJobsTopSection_contractor$ref;
export type ContractorJobsTopSection_contractor = {|
  +activeJobsCount: ?number,
  +completedJobsCount: ?number,
  +jobsLifetimeValue: ?any,
  +jobsThisYearValue: ?any,
  +$refType: ContractorJobsTopSection_contractor$ref,
|};
export type ContractorJobsTopSection_contractor$data = ContractorJobsTopSection_contractor;
export type ContractorJobsTopSection_contractor$key = {
  +$data?: ContractorJobsTopSection_contractor$data,
  +$fragmentRefs: ContractorJobsTopSection_contractor$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ContractorJobsTopSection_contractor",
  "type": "ContractorNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "activeJobsCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "completedJobsCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobsLifetimeValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobsThisYearValue",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '463aa946446a0b90bf8d86b503518009';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';
import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation CreateJobAssociatedFileMutation($input: CreateJobAssociatedFileInput!) {
    createJobAssociatedFile(input: $input) {
      jobAssociatedFile {
        id
        fileUrl
        fileName
        fileSize
      }
    }
  }
`;

export default (jobId, file, callback = () => {}) => {
  commitMutation(getEnvironment(), {
    mutation,
    uploadables: {
      associated_file: file
    },
    variables: {
      input: {
        jobId
      }
    },
    onCompleted: (response, errors) => {
      callback(response.createJobAssociatedFile, errors);
    },
    onError: err => console.error(err),
    updater: store => {
      const payload = store.getRootField('createJobAssociatedFile');
      const newNode = payload && payload.getLinkedRecord('jobAssociatedFile');
      const jobProxy = store.get(jobId);
      const conn = ConnectionHandler.getConnection(jobProxy, 'JobContractTab_associatedFiles');
      const newEdge = ConnectionHandler.createEdge(store, conn, newNode, 'JobAssociatedFileEdge');
      ConnectionHandler.insertEdgeAfter(conn, newEdge);
    }
  });
};

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';
import PropTypes from 'prop-types';

const UpdateContractorGroupMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateContractorGroupMutation($input: UpdateContractorGroupInput!) {
        updateContractorGroup(input: $input) {
          group {
            id
            isPublic
            name
            numberOfMembers
            contractors {
              edges {
                node {
                  id
                  fullName
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.updateContractorGroup, errors);
    },
    onError: err => console.error(err)
  });
};

UpdateContractorGroupMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      groupId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      contractors: PropTypes.array.isRequired,
      isPublic: PropTypes.bool.isRequired
    })
  )
};

export default UpdateContractorGroupMutation;

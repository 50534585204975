/**
 * @flow
 * @relayHash 884a1693a81d0d71ebdc0ce9e546d789
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StorageClassEnum = "DEEP_ARCHIVE" | "GLACIER" | "RESTORING" | "STANDARD" | "%future added value";
export type CreateDeliverableFinalFileInput = {|
  deliverableId: string,
  description?: ?string,
  stagedPath?: ?string,
  fileName?: ?string,
  clientMutationId?: ?string,
|};
export type CreateDeliverableFinalFileMutationVariables = {|
  input: CreateDeliverableFinalFileInput
|};
export type CreateDeliverableFinalFileMutationResponse = {|
  +createDeliverableFinalFile: ?{|
    +deliverable: ?{|
      +id: string,
      +finalFiles: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +created: any,
            +fileName: string,
            +fileSize: ?number,
            +description: ?string,
            +fileUrl: ?string,
            +storageClass: ?StorageClassEnum,
          |}
        |}>
      |},
    |}
  |}
|};
export type CreateDeliverableFinalFileMutation = {|
  variables: CreateDeliverableFinalFileMutationVariables,
  response: CreateDeliverableFinalFileMutationResponse,
|};
*/


/*
mutation CreateDeliverableFinalFileMutation(
  $input: CreateDeliverableFinalFileInput!
) {
  createDeliverableFinalFile(input: $input) {
    deliverable {
      id
      finalFiles(orderBy: "-created") {
        edges {
          node {
            id
            created
            fileName
            fileSize
            description
            fileUrl
            storageClass
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDeliverableFinalFileInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createDeliverableFinalFile",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeliverableFinalFilePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "finalFiles",
            "storageKey": "finalFiles(orderBy:\"-created\")",
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-created"
              }
            ],
            "concreteType": "DeliverableFinalFileNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableFinalFileNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableFinalFileNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "created",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileSize",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "storageClass",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateDeliverableFinalFileMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateDeliverableFinalFileMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateDeliverableFinalFileMutation",
    "id": null,
    "text": "mutation CreateDeliverableFinalFileMutation(\n  $input: CreateDeliverableFinalFileInput!\n) {\n  createDeliverableFinalFile(input: $input) {\n    deliverable {\n      id\n      finalFiles(orderBy: \"-created\") {\n        edges {\n          node {\n            id\n            created\n            fileName\n            fileSize\n            description\n            fileUrl\n            storageClass\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e56b330f2c1981b36165e23417c84fa2';
module.exports = node;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import SquareButton from '../UI/SquareButton';
import useUpdateEffect from '../../hooks/useUpdateEffect';

const useStyles = makeStyles(() => ({
  root: {
    pointerEvents: 'none',
    zIndex: '1000 !important'
  },
  paper: {
    pointerEvents: 'all',
    width: props => props.width,
    top: '85px',
    border: 'none',
    boxShadow: '0 25px 100px 0 rgba(0,0,0,0.35)',
    padding: '30px',
    paddingBottom: '115px'
  }
}));

export const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  margin-top: ${props => props.theme.spacing(6)}px;

  > button {
    margin-bottom: ${props => props.theme.spacing(1.5)}px;
  }
`;

const QuickViewDrawer = ({ children, selectedId, setSelectedId, width = 560 }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ width });

  useEffect(() => {
    selectedId ? setOpen(true) : setOpen(false);
  }, [selectedId]);

  useUpdateEffect(() => {
    !open && setSelectedId(null);
  }, [open]);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <Drawer anchor="right" open={open} classes={classes} ModalProps={{ hideBackdrop: true }}>
      <Box display="flex">
        <SquareButton
          onClick={() => {
            setSelectedId(null);
            setOpen(false);
          }}
          variant="secondary"
          icon="close"
        />
      </Box>
      <Box>{selectedId && children}</Box>
    </Drawer>
  );
};

export default QuickViewDrawer;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import EditButton from './EditButton';
import SaveButton from './SaveButton';
import CancelButton from './CancelButton';

const EditToggle = ({ isEditing, editProps, saveProps, cancelProps, isSubmitting }) => {
  return (
    <Box ml="auto" mr={-1} display="inline-block">
      {isEditing ? (
        <Fragment>
          <CancelButton {...cancelProps} disabled={isSubmitting} />
          <SaveButton {...saveProps} disabled={isSubmitting} />
        </Fragment>
      ) : (
        <EditButton {...editProps} />
      )}
    </Box>
  );
};

EditToggle.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  editProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  saveProps: PropTypes.shape({
    id: PropTypes.string,
    // onClick is not required here since SaveButton has type="submit"
    onClick: PropTypes.func
  }).isRequired,
  cancelProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  isSubmitting: PropTypes.bool
};

export default EditToggle;

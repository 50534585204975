/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SolicitationDetails_stateTypes$ref: FragmentReference;
declare export opaque type SolicitationDetails_stateTypes$fragmentType: SolicitationDetails_stateTypes$ref;
export type SolicitationDetails_stateTypes = {|
  +enumValues: ?$ReadOnlyArray<{|
    +name: string,
    +description: ?string,
  |}>,
  +$refType: SolicitationDetails_stateTypes$ref,
|};
export type SolicitationDetails_stateTypes$data = SolicitationDetails_stateTypes;
export type SolicitationDetails_stateTypes$key = {
  +$data?: SolicitationDetails_stateTypes$data,
  +$fragmentRefs: SolicitationDetails_stateTypes$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SolicitationDetails_stateTypes",
  "type": "__Type",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "enumValues",
      "storageKey": null,
      "args": null,
      "concreteType": "__EnumValue",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '41d4a7a23c503733016838bb89321cee';
module.exports = node;

import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { ReactComponent as BackIcon } from '../../../images/back.svg';
import BaseCommentListContainer from '../review/CommentListContainer';

export { EmptyCommentList } from '../review/CommentListContainer';

export const SidebarContainer = styled.div`
  height: 100%;
  width: 474px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

export const SidebarHeaderContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.palette.colors.lightestGrey};
  margin: 24px 24px 0 24px;
  padding: 0 12px 24px 12px;

  > * {
    width: 100%;
    height: 45px;
  }
`;

const BackButtonComponent = ({ children, ...rest }) => (
  <button type="button" {...rest}>
    <BackIcon />
    <Typography variant="body2" component="span">
      {children}
    </Typography>
  </button>
);

export const SidebarHeaderBackButton = styled(BackButtonComponent)`
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;

    path {
      fill: #4a4554;
    }
  }
`;

export const CommentListContainer = styled(BaseCommentListContainer)`
  overflow-y: auto;
  padding: 24px 24px 0 24px;
`;

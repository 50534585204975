/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type StorageClassEnum = "DEEP_ARCHIVE" | "GLACIER" | "RESTORING" | "STANDARD" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReviewAssetsList_files$ref: FragmentReference;
declare export opaque type ReviewAssetsList_files$fragmentType: ReviewAssetsList_files$ref;
export type ReviewAssetsList_files = {|
  +reviewAssetsByOrg: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fileName: string,
        +fileSize: ?number,
        +storageClass: ?StorageClassEnum,
        +hasComments: ?boolean,
        +deliverable: ?{|
          +title: string,
          +state: DeliverableState,
          +release: ?{|
            +name: string
          |},
        |},
      |}
    |}>,
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
    +totalCount: ?number,
    +edgeCount: ?number,
  |},
  +$refType: ReviewAssetsList_files$ref,
|};
export type ReviewAssetsList_files$data = ReviewAssetsList_files;
export type ReviewAssetsList_files$key = {
  +$data?: ReviewAssetsList_files$data,
  +$fragmentRefs: ReviewAssetsList_files$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ReviewAssetsList_files",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "storageClass",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "deliverableState",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "releaseId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "deliverableTitle",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "fileName",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "reviewAssetsByOrg",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "deliverableState",
          "variableName": "deliverableState"
        },
        {
          "kind": "Variable",
          "name": "deliverableTitle",
          "variableName": "deliverableTitle"
        },
        {
          "kind": "Variable",
          "name": "fileName",
          "variableName": "fileName"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "releaseId",
          "variableName": "releaseId"
        },
        {
          "kind": "Variable",
          "name": "storageClass",
          "variableName": "storageClass"
        }
      ],
      "concreteType": "ReviewAssetNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ReviewAssetNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ReviewAssetNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fileName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fileSize",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "storageClass",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hasComments",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "deliverable",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "DeliverableNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "title",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "state",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "release",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ReleaseNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "name",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '47faba39d881b4e0156c5eabe64bd766';
module.exports = node;

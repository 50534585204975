/**
 * @flow
 * @relayHash fd1b605bd966b9cb37145dd273a70d00
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateStaffUserInput = {|
  firstName: string,
  lastName: string,
  title: string,
  role: string,
  email: string,
  prorationTimestamp?: ?string,
  clientMutationId?: ?string,
|};
export type CreateStaffUserMutationVariables = {|
  input: CreateStaffUserInput
|};
export type CreateStaffUserMutationResponse = {|
  +createStaffUser: ?{|
    +staffUser: ?{|
      +id: string,
      +role: ?{|
        +numberOfStaff: ?number
      |},
    |},
    +emailFailed: ?boolean,
  |}
|};
export type CreateStaffUserMutation = {|
  variables: CreateStaffUserMutationVariables,
  response: CreateStaffUserMutationResponse,
|};
*/


/*
mutation CreateStaffUserMutation(
  $input: CreateStaffUserInput!
) {
  createStaffUser(input: $input) {
    staffUser {
      id
      role {
        numberOfStaff
        id
      }
    }
    emailFailed
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateStaffUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numberOfStaff",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailFailed",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateStaffUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createStaffUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateStaffUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "role",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationRoleNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateStaffUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createStaffUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateStaffUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "role",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationRoleNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateStaffUserMutation",
    "id": null,
    "text": "mutation CreateStaffUserMutation(\n  $input: CreateStaffUserInput!\n) {\n  createStaffUser(input: $input) {\n    staffUser {\n      id\n      role {\n        numberOfStaff\n        id\n      }\n    }\n    emailFailed\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16a6d82ed6d4cf775c472cb844cfacd8';
module.exports = node;

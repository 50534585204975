import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import BillingPayment from '../BillingPayment';

// NOTE: we build single docker image for all envs
const stripePublicKey =
  window.location.hostname === process.env.REACT_APP_PROD_HOSTNAME
    ? process.env.REACT_APP_STRIPE_PROD_PUBLIC_KEY
    : process.env.REACT_APP_STRIPE_DEV_PUBLIC_KEY;

const stripePromise = loadStripe(stripePublicKey);

const BillingPaymentRenderer = props => {
  return (
    <Elements stripe={stripePromise}>
      <QueryRenderer
        query={graphql`
          query BillingPaymentRendererQuery {
            ...BillingPayment_billing
            organization {
              ...BillingPayment_organization
            }
          }
        `}
        variables={{}}
        render={relayProps => (
          <BillingPayment billing={relayProps} organization={relayProps.organization} {...props} />
        )}
      />
    </Elements>
  );
};

export default BillingPaymentRenderer;

import React from 'react';
import PropTypes from 'prop-types';
import StyledTableCell from './StyledTableCell';
import { useTableStyles } from '../../styles/common/tableStyles';
import Avatar from '../common/Avatar';
import NewMessageBadge from '../UI/NewMessageBadge';
import CustomTableTooltip from './CustomTableTooltip';

const TableCellAvatarWithText = props => {
  const {
    avatarSrc,
    onClick,
    id,
    cellText,
    handleClick,
    onlyTooltip,
    withBadge,
    border,
    icon,
    customTooltipText,
    ...otherProps
  } = props;

  const classes = useTableStyles({ onlyTooltip });
  const avatar = (
    <Avatar src={avatarSrc} alt={cellText} size={32} defaultIcon={icon} border={border} />
  );
  const clickable = typeof onClick === 'function' && (!border || border === 'freelancer');

  const onClickHandler = clickable
    ? () => {
        onClick(id);
      }
    : null;

  return (
    <StyledTableCell {...otherProps} clickable={clickable} onClick={onClickHandler}>
      {customTooltipText ? (
        <div className={classes.avatarCellWithText}>
          {/* wrapping in span, otherwise Tooltip does not work (something about wrapping Tooltip around a custom component) */}
          {avatar}
          <CustomTableTooltip title={customTooltipText}>
            <span>{!onlyTooltip && cellText}</span>
          </CustomTableTooltip>
        </div>
      ) : (
        <div className={classes.avatarCellWithText}>
          <CustomTableTooltip title={cellText}>
            {/* wrapping in span, otherwise Tooltip does not work (something about wrapping Tooltip around a custom component) */}
            <span>
              {withBadge ? (
                <NewMessageBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  variant="dot"
                  border={border}
                >
                  {avatar}
                </NewMessageBadge>
              ) : (
                avatar
              )}
            </span>
          </CustomTableTooltip>
          {!onlyTooltip && cellText}
        </div>
      )}
    </StyledTableCell>
  );
};

TableCellAvatarWithText.propTypes = {
  avatarSrc: PropTypes.string,
  cellText: PropTypes.string,
  onClick: PropTypes.func,
  onlyTooltip: PropTypes.bool,
  icon: PropTypes.oneOf(['person', 'organization']),
  border: PropTypes.oneOf(['organization', 'freelancer'])
};

TableCellAvatarWithText.defaultProps = {
  icon: 'person'
};

export default TableCellAvatarWithText;

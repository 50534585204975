import { fetchQuery } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const query = graphql`
  query getContractPreviewPdfQuery(
    $contractor: ID!
    $category: ID!
    $name: String!
    $description: String!
    $contractTemplate: ID
    $jobDeadline: Date
    $contractSignDeadline: Date
    $totalValue: Decimal
    $deliverables: [ID]
    $contractDate: Date
  ) {
    previewContractPdf(
      contractor: $contractor
      category: $category
      name: $name
      description: $description
      contractTemplate: $contractTemplate
      jobDeadline: $jobDeadline
      contractSignDeadline: $contractSignDeadline
      totalValue: $totalValue
      deliverables: $deliverables
      contractDate: $contractDate
    ) {
      pdfFile
      errors
    }
  }
`;

export default variables =>
  fetchQuery(getEnvironment(), query, variables).then(data => data.previewContractPdf);

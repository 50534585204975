import { fetchQuery } from 'react-relay';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

const generateUploadUrl = (filename, modelName, modelId, fieldName, contentType, callback) => {
  const query = graphql`
    query generateUploadUrlQuery(
      $filename: String!
      $modelName: String!
      $modelId: ID
      $fieldName: String!
      $contentType: String
    ) {
      fileUploadUrl(
        filename: $filename
        modelName: $modelName
        modelId: $modelId
        fieldName: $fieldName
        contentType: $contentType
      )
    }
  `;

  fetchQuery(getEnvironment(), query, {
    filename,
    modelName,
    modelId,
    fieldName,
    contentType
  }).then(data => {
    callback(data.fileUploadUrl);
  });
};

generateUploadUrl.PropTypes = {
  filename: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  modelId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
};

export default generateUploadUrl;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';

import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import FormikSearchableDropdown from '../../../../../shared/components/form/FormikSearchableDropdown';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { DELIVERABLE_STATES } from '../../../../../shared/constants';
import StatusIndicator from '../../../../../shared/components/UI/StatusIndicator';
import {
  resolveProductLink,
  resolveReleaseLink,
  resolveJobLink
} from '../../../../../shared/utils/linkResolvers';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';

const DetailsInfo = props => {
  const {
    isEditing,
    deliverableStateTypes,
    deliverable: {
      state,
      title,
      release: { id: releaseId, name: releaseName, code: releaseCode, product },
      jobId,
      job,
      contractSignDeadline
    },
    userContext
  } = props;
  const navigation = JSON.parse(userContext.orgStaff.organization.configuration).navigation;

  let infoSection;
  if (isEditing && state === DELIVERABLE_STATES.ready) {
    infoSection = (
      <Field
        name="title"
        label="Deliverable Name"
        placeholder="Enter a Deliverable name..."
        component={FormikTextField}
        visibleToFreelancer
        fullWidth
      />
    );
  } else if (isEditing && state === DELIVERABLE_STATES.draft) {
    infoSection = (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            name="title"
            label="Deliverable Name"
            placeholder="Enter a Deliverable name..."
            component={FormikTextField}
            visibleToFreelancer
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="release"
            label={`${navigation.releaseTitle.singular} Name`}
            placeholder={`Select ${navigation.releaseTitle.singular}...`}
            component={FormikSearchableDropdown}
            releaseOnly
          />
        </Grid>
      </Grid>
    );
  } else {
    infoSection = (
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <DetailsRow title="Deliverable Name" content={title} />
          <DetailsRow
            link={resolveProductLink(product.id)}
            title={`${navigation.productTitle.singular} Name`}
            content={product.title}
          />
          <DetailsRow
            link={resolveReleaseLink(releaseId)}
            title={`${navigation.releaseTitle.singular} Name`}
            content={releaseName}
          />
          <DetailsRow
            link={resolveReleaseLink(releaseId)}
            title={`${navigation.releaseTitle.singular} Code`}
            content={releaseCode}
          />
        </Grid>
        <Grid item xs={6}>
          <DetailsRow
            link={job ? resolveJobLink(job.id) : ''}
            title="Job ID"
            content={jobId}
            titleWidth={172}
          />
          <DetailsRow
            link={job ? resolveJobLink(job.id) : ''}
            title="Job Name"
            content={job ? job.name : ''}
            titleWidth={172}
          />
          <DetailsRowDate
            title="Contract Signature Deadline"
            date={contractSignDeadline}
            titleWidth={172}
          />
          <DetailsRow
            title="Deliverable State"
            content={
              <StatusIndicator
                table
                statusTypes={deliverableStateTypes.enumValues}
                statusCode={state}
                variant="deliverable"
              />
            }
            titleWidth={172}
          />
        </Grid>
      </Grid>
    );
  }

  return <DetailsSection title="Info">{infoSection}</DetailsSection>;
};

export default withUserContext(
  createFragmentContainer(DetailsInfo, {
    deliverable: graphql`
      fragment DetailsInfo_deliverable on DeliverableNode {
        state
        title
        jobId
        contractSignDeadline
        release {
          id
          name
          code
          product {
            id
            title
          }
        }
        job {
          id
          name
        }
      }
    `,
    deliverableStateTypes: graphql`
      fragment DetailsInfo_deliverableStateTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  })
);

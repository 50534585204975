import { fetchQuery } from 'react-relay';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

const getAccountCreationData = (contractorToken, uidb64, callback) => {
  const query = graphql`
    query getAccountCreationDataQuery($contractorToken: String!, $uidb64: String!) {
      accountCreationTokenStatus(contractorToken: $contractorToken, uidb64: $uidb64)
    }
  `;

  fetchQuery(getEnvironment(), query, {
    contractorToken,
    uidb64,
  }).then((data) => {
    callback(data);
  });
};

getAccountCreationData.PropTypes = {
  contractorToken: PropTypes.string.isRequired,
  uidb64: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export default getAccountCreationData;

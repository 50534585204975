import React, { useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import FormikDropdownField from '../../../../../shared/components/form/FormikDropdownField';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const FormikRolesSelect = props => {
  const { onlyDefaultRoles, ...fieldProps } = props;
  const [roles, setRoles] = useState([]);

  useEffectWithStatus(status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query FormikRolesSelectQuery($isDefault: Boolean) {
          organizationRoles(isDefault: $isDefault) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      {
        isDefault: onlyDefaultRoles
      }
    ).then(response => {
      if (status.mounted && response) {
        const mappedRoles = response.organizationRoles.edges.map(({ node }) => node);
        setRoles(mappedRoles);
      }
    });
  }, []);

  return (
    <FormikDropdownField
      {...fieldProps}
      itemType="roles"
      items={roles}
      placeholder={roles.length === 0 ? 'Loading...' : undefined}
    />
  )
}

export default FormikRolesSelect;
/**
 * @flow
 * @relayHash 9414a707478ff619ac349564f0a2eb8f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BillingPlan_billing$ref = any;
export type BillingPlanRendererQueryVariables = {||};
export type BillingPlanRendererQueryResponse = {|
  +$fragmentRefs: BillingPlan_billing$ref
|};
export type BillingPlanRendererQuery = {|
  variables: BillingPlanRendererQueryVariables,
  response: BillingPlanRendererQueryResponse,
|};
*/


/*
query BillingPlanRendererQuery {
  ...BillingPlan_billing
}

fragment BillingPlan_billing on Query {
  subscriptionTiers {
    name
    numberOfUsers
    activeStorage
    archiveStorage
    activeDeliverables
    activeContractors
    activeContractTemplates
    moduleAccess
    monthlyPrice
    annualPrice
  }
  organization {
    subscription {
      tierName
      interval
      nextBillingDate
      nextBillingAmount
      numberOfModules
      numberOfActiveUsers
      numberOfTotalUsers
      numberOfDeliverables
      numberOfContractors
      numberOfContractTemplates
    }
    scheduledSubscription {
      interval
      activeModulesCount
      availableModules {
        moduleId
        quantity
      }
      id
    }
    activeSubscription {
      tier
      interval
      availableModules {
        moduleId
        name
        incremental
        quantity
        cost {
          tier
          price
        }
        blurbText
        description
        imageUrl
        headline
        externalUrl
      }
      id
    }
    latestActiveMetricsData {
      total
    }
    latestArchiveMetricsData {
      total
    }
    activeStorageLimit
    archiveStorageLimit
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "interval",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "moduleId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tier",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "total",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BillingPlanRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "BillingPlan_billing",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BillingPlanRendererQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subscriptionTiers",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationTierInfoNode",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfUsers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeStorage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "archiveStorage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeDeliverables",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeContractors",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeContractTemplates",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "moduleAccess",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "monthlyPrice",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "annualPrice",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationSubscription",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tierName",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "nextBillingDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "nextBillingAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfModules",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfActiveUsers",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfTotalUsers",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfDeliverables",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfContractors",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfContractTemplates",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduledSubscription",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationSubscriptionNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "activeModulesCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "availableModules",
                "storageKey": null,
                "args": null,
                "concreteType": "SubscriptionModuleNode",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ]
              },
              (v4/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "activeSubscription",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationSubscriptionNode",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "availableModules",
                "storageKey": null,
                "args": null,
                "concreteType": "SubscriptionModuleNode",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "incremental",
                    "args": null,
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "cost",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SubscriptionModuleCostNode",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "price",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "blurbText",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "imageUrl",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "headline",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "externalUrl",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              (v4/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "latestActiveMetricsData",
            "storageKey": null,
            "args": null,
            "concreteType": "MetricsData",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "latestArchiveMetricsData",
            "storageKey": null,
            "args": null,
            "concreteType": "MetricsData",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeStorageLimit",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "archiveStorageLimit",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BillingPlanRendererQuery",
    "id": null,
    "text": "query BillingPlanRendererQuery {\n  ...BillingPlan_billing\n}\n\nfragment BillingPlan_billing on Query {\n  subscriptionTiers {\n    name\n    numberOfUsers\n    activeStorage\n    archiveStorage\n    activeDeliverables\n    activeContractors\n    activeContractTemplates\n    moduleAccess\n    monthlyPrice\n    annualPrice\n  }\n  organization {\n    subscription {\n      tierName\n      interval\n      nextBillingDate\n      nextBillingAmount\n      numberOfModules\n      numberOfActiveUsers\n      numberOfTotalUsers\n      numberOfDeliverables\n      numberOfContractors\n      numberOfContractTemplates\n    }\n    scheduledSubscription {\n      interval\n      activeModulesCount\n      availableModules {\n        moduleId\n        quantity\n      }\n      id\n    }\n    activeSubscription {\n      tier\n      interval\n      availableModules {\n        moduleId\n        name\n        incremental\n        quantity\n        cost {\n          tier\n          price\n        }\n        blurbText\n        description\n        imageUrl\n        headline\n        externalUrl\n      }\n      id\n    }\n    latestActiveMetricsData {\n      total\n    }\n    latestArchiveMetricsData {\n      total\n    }\n    activeStorageLimit\n    archiveStorageLimit\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '870efa23ba26c56b04f9a1a9251cb704';
module.exports = node;

import React from 'react';

import FormikDropdownField from '../FormikDropdownField';

const DateDisplayDropdown = props => {
  // TODO: update these when we know what exactly formats we should support
  const items = [
    // MDY
    { id: 'MMM D, YYYY', title: 'Nov 9, 2019' },
    { id: 'M/D/YYYY', title: '11/9/2019' },
    { id: 'M-D-YYYY', title: '11-9-2019' },
    { id: 'M.D.YYYY', title: '11.9.2019' },
    //   DMY
    { id: 'D/M/YYYY', title: '9/11/2019' },
    { id: 'DD.M.YYYY', title: '09.11.2019' },
    { id: 'D-M-YYYY', title: '9-11-2019' },
    { id: 'DD-MMM-YYYY', title: '09-Nov-2019' },
    // YMD
    { id: 'YYYY-M-DD', title: '2019-11-09' },
    { id: 'YYYY-MMM-D', title: '2019-Nov-9' },
    { id: 'YYYY.M.D', title: '2019.11.9' },
    { id: 'YYYY/M/DD', title: '2019/11/09' }
  ];

  return <FormikDropdownField {...props} items={items} />;
};

export default DateDisplayDropdown;

/**
 * @flow
 * @relayHash 817180ef61f2db0a85c3b7f1b7403ff6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
export type MarkBatchDeliverableCompleteInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type BatchMarkDeliverableCompleteMutationVariables = {|
  input: MarkBatchDeliverableCompleteInput
|};
export type BatchMarkDeliverableCompleteMutationResponse = {|
  +markBatchDeliverableComplete: ?{|
    +updatedDeliverables: ?$ReadOnlyArray<?{|
      +id: string,
      +state: DeliverableState,
      +job: ?{|
        +id: string,
        +status: JobStatus,
        +hasCompletedDeliverables: ?boolean,
      |},
    |}>
  |}
|};
export type BatchMarkDeliverableCompleteMutation = {|
  variables: BatchMarkDeliverableCompleteMutationVariables,
  response: BatchMarkDeliverableCompleteMutationResponse,
|};
*/


/*
mutation BatchMarkDeliverableCompleteMutation(
  $input: MarkBatchDeliverableCompleteInput!
) {
  markBatchDeliverableComplete(input: $input) {
    updatedDeliverables {
      id
      state
      job {
        id
        status
        hasCompletedDeliverables
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkBatchDeliverableCompleteInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "markBatchDeliverableComplete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkBatchDeliverableCompletePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedDeliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasCompletedDeliverables",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BatchMarkDeliverableCompleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BatchMarkDeliverableCompleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BatchMarkDeliverableCompleteMutation",
    "id": null,
    "text": "mutation BatchMarkDeliverableCompleteMutation(\n  $input: MarkBatchDeliverableCompleteInput!\n) {\n  markBatchDeliverableComplete(input: $input) {\n    updatedDeliverables {\n      id\n      state\n      job {\n        id\n        status\n        hasCompletedDeliverables\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f53e01099ad03d7a0d3259548adf5360';
module.exports = node;

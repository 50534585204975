import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const BatchDeleteReviewAssetMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation BatchDeleteReviewAssetMutation($input: DeleteBatchReviewAssetsInput!) {
          deleteBatchReviewAssets(input: $input) {
            isDeleted
          }
        }
      `,
      variables: {
        input
      },
      onCompleted: resolve,
      onError: reject
    });
  });

export default BatchDeleteReviewAssetMutation;

import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Typography, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CreateCategoryMutation from './mutations/CreateCategoryMutation';
import DeleteCategoriesMutation from './mutations/DeleteCategoriesMutation';
import { successToast } from '../../../../shared/toasts';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import FormikTextField from '../../../../shared/components/form/FormikTextField';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '0',
    paddingBottom: theme.spacing(4),
    width: '400px'
  }
}));

const HandleCategoryForm = props => {
  const { handleClose, handleCloseWithRefetch, selectedCategories, action } = props;
  const classes = useStyles();

  const onSubmitHandler = (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    if (action === 'duplicate') {
      CreateCategoryMutation(selectedCategories[0].node.id, values.name, (response, errors) => {
        setSubmitting(false);
        if (errors && errors[0].fields && errors[0].fields.name) {
          setFieldError('name', errors[0].fields.name);
        }
        if (response) {
          setTimeout(() => {
            successToast('New category was created.', {
              text: 'Go to details page.',
              link: `/admin/customization/deliverables/${response.newCategory.id}`
            });
          }, 250);
          handleCloseWithRefetch();
        }
      });
    } else {
      DeleteCategoriesMutation(
        selectedCategories.map(cat => cat.node.id),
        response => {
          setSubmitting(false);
          if (response) {
            setTimeout(() => {
              successToast(
                `${
                  selectedCategories.length > 1
                    ? `${selectedCategories.length} categories were deleted`
                    : `${selectedCategories[0].node.name} was deleted`
                } .`
              );
            }, 250);
          }
          handleCloseWithRefetch();
        }
      );
    }
  };
  const validate = values => {
    let errors = {};
    if (action === 'duplicate' && !values.name) {
      errors.name = 'Required';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{ name: `${selectedCategories[0].node.name} Copy` }}
      validate={validate}
      onSubmit={onSubmitHandler}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              <Typography variant="body1" component="span">
                {`You are about to ${
                  action === 'duplicate'
                    ? `create a new category`
                    : selectedCategories.length === 1
                    ? `delete ${selectedCategories[0].node.name}`
                    : `delete ${selectedCategories.length} categories`
                }. ${action === 'duplicate' ? 'Choose a unique name.' : ''}`}
              </Typography>
            </DialogContentText>
            {action === 'duplicate' && (
              <Field
                component={FormikTextField}
                name="name"
                label="Category name"
                required
                fullWidth
              />
            )}
          </DialogContent>
          <DialogActions>
            <GlobalButton variant="transparent" handleClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GlobalButton>
            <GlobalButton handleClick={submitForm} disabled={isSubmitting}>
              Confirm
            </GlobalButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default HandleCategoryForm;

/**
 * @flow
 * @relayHash f1379b9809b3371e5657e4619b498367
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type BudgetQuickViewQueryVariables = {|
  releaseId: string,
  categoryId?: ?string,
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
|};
export type BudgetQuickViewQueryResponse = {|
  +releaseCategoryInfo: ?{|
    +category: ?{|
      +id: string,
      +name: string,
    |},
    +contractedAmount: ?any,
    +pendingAmount: ?any,
    +committedAmount: ?any,
    +originalCommittedAmount: ?any,
    +deliverables: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +state: DeliverableState,
          +id: string,
          +title: string,
          +amount: ?any,
          +currencyCode: ?DeliverableCurrencyCode,
          +amountInHomeCurrency: ?any,
          +originalAmountInHomeCurrency: ?any,
        |}
      |}>,
      +totalCount: ?number,
      +edgeCount: ?number,
      +pageInfo: {|
        +hasNextPage: boolean,
        +endCursor: ?string,
      |},
    |},
  |}
|};
export type BudgetQuickViewQuery = {|
  variables: BudgetQuickViewQueryVariables,
  response: BudgetQuickViewQueryResponse,
|};
*/


/*
query BudgetQuickViewQuery(
  $releaseId: ID!
  $categoryId: ID
  $first: Int
  $after: String
  $orderBy: String
) {
  releaseCategoryInfo(releaseId: $releaseId, categoryId: $categoryId) {
    category {
      id
      name
    }
    contractedAmount
    pendingAmount
    committedAmount
    originalCommittedAmount
    deliverables(first: $first, after: $after, orderBy: $orderBy) {
      edges {
        node {
          state
          id
          title
          amount
          currencyCode
          amountInHomeCurrency
          originalAmountInHomeCurrency
        }
      }
      totalCount
      edgeCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "releaseId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categoryId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "releaseCategoryInfo",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "categoryId",
        "variableName": "categoryId"
      },
      {
        "kind": "Variable",
        "name": "releaseId",
        "variableName": "releaseId"
      }
    ],
    "concreteType": "ReleaseCategoryInfo",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": null,
        "concreteType": "CategoryNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "contractedAmount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "pendingAmount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "committedAmount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "originalCommittedAmount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverables",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          }
        ],
        "concreteType": "DeliverableNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "state",
                    "args": null,
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "amount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "currencyCode",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "amountInHomeCurrency",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "originalAmountInHomeCurrency",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BudgetQuickViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BudgetQuickViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "BudgetQuickViewQuery",
    "id": null,
    "text": "query BudgetQuickViewQuery(\n  $releaseId: ID!\n  $categoryId: ID\n  $first: Int\n  $after: String\n  $orderBy: String\n) {\n  releaseCategoryInfo(releaseId: $releaseId, categoryId: $categoryId) {\n    category {\n      id\n      name\n    }\n    contractedAmount\n    pendingAmount\n    committedAmount\n    originalCommittedAmount\n    deliverables(first: $first, after: $after, orderBy: $orderBy) {\n      edges {\n        node {\n          state\n          id\n          title\n          amount\n          currencyCode\n          amountInHomeCurrency\n          originalAmountInHomeCurrency\n        }\n      }\n      totalCount\n      edgeCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '128b2a6655c4a6d47637e8bad8964948';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ReactComponent as DropperIcon } from '../../../shared/images/eye-dropper.svg';

const useSwatchColorPickerStyles = makeStyles(() => ({
  color: {
    width: '97px',
    height: '34px',
    borderRadius: '2px 0 0 2px',
    background: props => props.color
  },
  icon: {
    width: '34px',
    height: '34px',
    padding: '9px',
    backgroundColor: '#DEDEE4',
    borderRadius: '0 2px 2px 0',
    '& svg': {
      height: '16px',
      width: '16px'
    },
    cursor: 'pointer'
  },
  swatch: {
    display: 'flex',
    marginRight: '24px',
    marginLeft: '15px'
  }
}));

const SwatchColorPickerButton = props => {
  const { handleClick, color } = props;
  const classes = useSwatchColorPickerStyles({ color });
  return (
    <div className={classes.swatch}>
      <div className={classes.color} />
      <ButtonBase className={classes.icon} onClick={handleClick}>
        <DropperIcon />
      </ButtonBase>
    </div>
  );
};

SwatchColorPickerButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired
};

export default SwatchColorPickerButton;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const MarkInactiveContractorMutation = (id, isActive, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation MarkInactiveContractorMutation($input: MarkInactiveContractorInput!) {
        markInactiveContractor(input: $input) {
          updatedContractor {
            isActive
          }
        }
      }
    `,
    variables: {
      input: {
        id,
        isActive
      }
    },
    onCompleted: response => {
      callback(response.markInactiveContractor);
    }
  });
};

MarkInactiveContractorMutation.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  callback: PropTypes.func
};

export default MarkInactiveContractorMutation;

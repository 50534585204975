import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import { DialogContentText } from '@material-ui/core';
import Dialog, { DialogActions } from '../../../../../shared/components/common/Dialog';
import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';

const ContactorsList = styled(DialogContentText)`
  margin-bottom: ${props => props.theme.spacing(3)}px;

  ul {
    margin: 5px 0 0 0;
    padding-left: 30px;
    font-weight: bold;
  }
`;

const ContactorBulkInviteDialog = ({ contractors, onConfirm, ...dialogProps }) => (
  <Dialog title="Invite contractors" minWidth={350} {...dialogProps}>
    <Formik onSubmit={onConfirm}>
      {({ submitForm, isSubmitting }) => (
        <>
          <ContactorsList component="div" variant="body1">
            By clicking Send, you will invite the following {contractors.length} contractor
            {contractors.length > 1 ? 's' : ''} to AWEBase: <br />
            <ul>
              {contractors.map(({ node }) => (
                <li key={node.id}>{node.name.name}</li>
              ))}
            </ul>
          </ContactorsList>

          <DialogContentText variant="body1">
            If you wish to enter a custom message, please enter it below.
          </DialogContentText>
          <Field
            name="message"
            label="Message"
            component={FormikTextField}
            fullWidth
            multiline
            rows={3}
          />

          <DialogActions>
            <GlobalButton
              variant="transparent"
              handleClick={dialogProps.closeDialog}
              disabled={isSubmitting}
            >
              Cancel
            </GlobalButton>
            <GlobalButton handleClick={submitForm} loading={isSubmitting} disabled={isSubmitting}>
              Send
            </GlobalButton>
          </DialogActions>
        </>
      )}
    </Formik>
  </Dialog>
);

ContactorBulkInviteDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  contractors: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    })
  )
};

export default ContactorBulkInviteDialog;

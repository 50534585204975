import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import { withRouter } from 'react-router';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HelpIcon from '@material-ui/icons/Help';

import Avatar from '../../../shared/components/common/Avatar';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import appStyles from '../../../shared/styles/common/base';
import authService from '../../../shared/services/authService';
import TopBarFreelancerNotification from './TopBarFreelancerNotification';
import { showIntercom, IC_TARGETS } from '../../../shared/services/intercomService';

const TopBar = props => {
  const classes = appStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(anchorEl);
  const { userContext } = props;

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography className={classes.dividerText} align="center" display="block">
        User Profile
      </Typography>
      <Divider component="div" />
      <MenuItem className={classes.topBarMenuItemFree} onClick={handleOpenUserProfile}>
        Your Profile
      </MenuItem>
      <Divider component="div" />
      <MenuItem className={classes.topBarMenuItemFree} onClick={handleLogout}>
        Log out
      </MenuItem>
    </Menu>
  );

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleOpenUserProfile() {
    const { history } = props;
    history.push(`/profile`);
    setAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleContactSupport() {
    setAnchorEl(null);
    showIntercom();
  }

  function handleLogout() {
    authService.signout();
  }

  return (
    <Fragment>
      <AppBar position="relative" className={clsx(classes.appBar)} color="inherit">
        <Toolbar className={classes.appBarToolbar}>
          <div></div>
          <div className={classes.appBarRight}>
            <IconButton
              aria-label={`Contact Support`}
              color="inherit"
              onClick={handleContactSupport}
              data-intercom-target={IC_TARGETS.helpButton}
            >
              <HelpIcon />
            </IconButton>
            <QueryRenderer
              query={graphql`
                query TopBarFreelancerQuery {
                  ...TopBarFreelancerNotification_notifications
                }
              `}
              variables={{ unread: true }}
              render={relayProps => <TopBarFreelancerNotification notifications={relayProps} />}
              renderLoading={() => {}}
            />
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              className={classes.currentUserIcon}
              data-intercom-target={IC_TARGETS.profileButton}
            >
              <Avatar alt={`Your avatar`} src={userContext.representativeImageUrl} />
              <Typography variant="body2">{`${userContext.firstName} ${userContext.lastName}`}</Typography>
              <KeyboardArrowDownIcon />
            </IconButton>
          </div>
        </Toolbar>
        {renderMenu}
      </AppBar>
    </Fragment>
  );
};

export default withRouter(withUserContext(TopBar));

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SolicitationDetails_solicitation$ref: FragmentReference;
declare export opaque type SolicitationDetails_solicitation$fragmentType: SolicitationDetails_solicitation$ref;
export type SolicitationDetails_solicitation = {|
  +id: string,
  +subject: string,
  +state: SolicitationState,
  +numberOfDeliverables: ?number,
  +numberOfContractors: ?number,
  +replyByDate: ?any,
  +message: ?string,
  +numberOfAllocatedDeliverables: ?number,
  +numberOfAllocatedContractedDeliverables: ?number,
  +createdBy: ?{|
    +user: {|
      +id: string
    |}
  |},
  +staffCoordinator: {|
    +id: string,
    +fullName: ?string,
    +user: {|
      +id: string
    |},
  |},
  +$refType: SolicitationDetails_solicitation$ref,
|};
export type SolicitationDetails_solicitation$data = SolicitationDetails_solicitation;
export type SolicitationDetails_solicitation$key = {
  +$data?: SolicitationDetails_solicitation$data,
  +$fragmentRefs: SolicitationDetails_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ]
};
return {
  "kind": "Fragment",
  "name": "SolicitationDetails_solicitation",
  "type": "SolicitationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subject",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfContractors",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "replyByDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfAllocatedDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfAllocatedContractedDeliverables",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "createdBy",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffCoordinator",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '14446b5aacc5a73121903aad9f289701';
module.exports = node;

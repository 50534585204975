import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';
import AutoTable from '../../../../../../../shared/components/table/AutoTable';
import { CELL_TYPES } from '../../../../../../../shared/constants';
import QuickViewDrawer from '../../../../../../../shared/components/common/QuickViewDrawer';
import { JOB_INVOICE_STATES } from '../../../../../../../shared/constants';
import { resolveJobInvoiceLink } from '../../../../../../../shared/utils/linkResolvers';
import InvoiceQuickView from './InvoiceQuickView';

const InvoicesList = props => {
  const {
    job: {
      id: jobId,
      currencyCode,
      invoices: { edges }
    },
    statusTypes: { enumValues: statusEnumValues },
    history
  } = props;
  const { id: selectedInvoiceId } = useParams();

  const handleQuickViewToggle = id => {
    const newLink = resolveJobInvoiceLink(jobId, selectedInvoiceId === id ? null : id);
    history.push(newLink);
  };

  const flattenedEdges = edges.map(({ node }) => {
    const { id, identifier, status, created, verificationDate, amount } = node;

    return {
      node: {
        id,
        identifier,
        status: { code: status },
        created,
        approvedAt: status !== JOB_INVOICE_STATES.declined ? verificationDate : null,
        amount,
        currencyCode
      }
    };
  });

  return (
    <>
      <QuickViewDrawer selectedId={selectedInvoiceId} setSelectedId={handleQuickViewToggle}>
        <InvoiceQuickView invoiceId={selectedInvoiceId} statusTypes={statusEnumValues} />
      </QuickViewDrawer>
      <AutoTable
        rowTemplate={[
          { name: 'quickView', label: '', type: CELL_TYPES.info, onClick: handleQuickViewToggle },
          {
            name: 'identifier',
            label: 'Invoice ID',
            type: CELL_TYPES.link
          },
          { name: 'created', label: 'Date Submitted', type: CELL_TYPES.date },
          { name: 'approvedAt', label: 'Date Approved', type: CELL_TYPES.date },
          {
            name: 'status',
            label: 'Status',
            type: CELL_TYPES.status,
            statusTypes: statusEnumValues,
            variant: 'jobInvoice'
          },
          {
            name: 'amount',
            label: 'Invoice Amount',
            type: CELL_TYPES.amount,
            width: 150
          }
        ]}
        rowProps={{
          handleLinkClick: handleQuickViewToggle
        }}
        edges={flattenedEdges}
      />
    </>
  );
};

export default createFragmentContainer(withRouter(InvoicesList), {
  job: graphql`
    fragment InvoicesList_job on JobNode {
      id
      currencyCode
      invoices(orderBy: "-created") {
        edges {
          node {
            id
            identifier
            status
            created
            amount
            verificationDate
          }
        }
      }
    }
  `,
  statusTypes: graphql`
    fragment InvoicesList_statusTypes on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

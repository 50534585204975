/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobDetails_job$ref: FragmentReference;
declare export opaque type JobDetails_job$fragmentType: JobDetails_job$ref;
export type JobDetails_job = {|
  +jobId: ?number,
  +name: string,
  +status: JobStatus,
  +jobDeadline: ?any,
  +contractor: {|
    +organization: {|
      +name: string,
      +representativeImageUrl: ?string,
    |}
  |},
  +$refType: JobDetails_job$ref,
|};
export type JobDetails_job$data = JobDetails_job;
export type JobDetails_job$key = {
  +$data?: JobDetails_job$data,
  +$fragmentRefs: JobDetails_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "JobDetails_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobId",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobDeadline",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contractor",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractorNode",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "OrgNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "representativeImageUrl",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ea283b975ffc1559e5e5a007ce1a9cde';
module.exports = node;

import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Box } from '@material-ui/core';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import FormikDropdownField from '../../../shared/components/form/FormikDropdownField';
import FormikDateField from '../../../shared/components/form/FormikDateField';
import CreateDeliverableMutation from '../../mutations/CreateDeliverableMutation';
import FormikSearchableDropdown from '../../../shared/components/form/FormikSearchableDropdown';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import { FormikAmountWithCurrencyField } from '../form/AmountWithCurrencyField';

import { formatDateForQueries } from '../../../shared/utils/formatters';
import { successToast } from '../../../shared/toasts';
import { getCurrencyCodeFromUserContext } from '../../../shared/utils/helpers';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import { FormikCategorySelectWithTypes } from '../shared/dropdowns/CategorySelectWithTypes';
import SearchableStaffDropdown from '../shared/dropdowns/SearchableStaffDropdown';

const CreateDeliverableForm = props => {
  const { handleClose, toRefetchData, isContextual, contextAction, userContext } = props;
  const navigation = JSON.parse(userContext.orgStaff.organization.configuration).navigation;

  const initialValues = {
    title: '',
    release:
      isContextual && contextAction
        ? {
            value: contextAction.data.releaseId,
            label: `${contextAction.data.releaseName} (${contextAction.data.productTitle})`
          }
        : null,
    category: null,
    assignedStaff: null,
    dueDate: null,
    amount: { amount: '', currencyCode: getCurrencyCodeFromUserContext(userContext) },
    tags: { options: [] },
    followers: { options: [] },
    categoryType: '',
    description: '',
    managerNote: ''
  };

  const CreateDeliverableSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(100, 'Please enter no more than 100 characters.')
      .required('Required'),
    description: Yup.string().max(5000, 'Please enter no more than 5000 characters.'),
    amount: Yup.object().shape({
      amount: Yup.number()
        .transform(cv => (isNaN(cv) ? undefined : cv))
        .min(0, 'Amount must be zero or more.')
    }),
    dueDate: Yup.date().nullable(true),
    managerNote: Yup.string().max(5000, 'Please enter no more than 5000 characters.')
  });

  const validate = values => {
    let errors = {};

    if (!values.release || !values.release.value) {
      errors.release = 'Required';
    }
    if (!values.category || !values.category.value) {
      errors.category = 'Required';
    }

    return errors;
  };

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    CreateDeliverableMutation(
      values.title,
      values.release.value,
      values.category.value,

      (createDeliverable, errors) => {
        setSubmitting(false);
        if (errors) {
          if (errors[0].fields) setErrors(errors[0].fields);
        } else {
          if (createDeliverable && createDeliverable.newDeliverable) {
            handleClose();
            setTimeout(() => {
              successToast('New deliverable has been created.', {
                text: 'Go to deliverable',
                link: `/deliverables/${createDeliverable.newDeliverable.id}`
              });
            }, 250);
            toRefetchData();
          }
        }
      },
      {
        assignedStaff: values.assignedStaff ? values.assignedStaff.value : null,
        dueDate: values.dueDate ? formatDateForQueries(values.dueDate) : null,
        amount: values.amount.amount,
        currencyCode: values.amount.currencyCode,
        tags: values.tags.options.map(option => option.label),
        followerIds: values.followers.options.map(option => option.value),
        categoryType: values.categoryType,
        description: values.description,
        managerNote: values.managerNote
      }
    );
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={validate}
        validationSchema={CreateDeliverableSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldTouched, isSubmitting, setFieldValue, submitForm }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Field
                  required
                  name="title"
                  component={FormikTextField}
                  label="Deliverable Name"
                  placeholder="Enter a Deliverable name..."
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="release"
                  placeholder={`Enter ${navigation.productTitle.singular} or ${navigation.releaseTitle.singular} name...`}
                  disabled={isContextual}
                  component={FormikSearchableDropdown}
                  {...(isContextual ? { setInitialValue: true } : null)}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="category"
                  component={FormikCategorySelectWithTypes}
                  label="Category"
                  placeholder="Select a Category..."
                  onChange={(name, value) => {
                    setFieldValue('categoryType', '');
                    setFieldValue(name, value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="categoryType"
                  itemType="categories"
                  items={
                    values.category
                      ? values.category.types.edges.map(edge => ({ ...edge.node }))
                      : null
                  }
                  component={FormikDropdownField}
                  label="Type"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="amount"
                  component={FormikAmountWithCurrencyField}
                  label="Amount"
                  placeholder="Enter an amount..."
                  onKeyPress={() => setFieldTouched('amount', true)} // manually run setFieldTouched on keypress to immediately run validation
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field name="dueDate" component={FormikDateField} label="Due Date" />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="tags"
                  label="Tags"
                  placeholder="Select tags..."
                  component={FormikSearchableDropdown}
                  creatable
                  onChange={(field, options) => setFieldValue(field, options)}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="assignedStaff"
                  component={FormikSearchableDropdown}
                  placeholder="Select Staff..."
                  label="Staff Coordinator"
                  onChange={value => {
                    const prevOption = values.assignedStaff;
                    let newFollowers = [];

                    if (!value) {
                      newFollowers = values.followers.options.filter(
                        option => option.value !== prevOption.value
                      );
                    } else if (prevOption) {
                      newFollowers = [
                        ...values.followers.options.filter(
                          option => option.value !== prevOption.value
                        ),
                        value
                      ];
                    } else {
                      newFollowers = [...values.followers.options, value];
                    }

                    setFieldValue('assignedStaff', value);
                    setFieldValue('followers', { options: newFollowers });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SearchableStaffDropdown
                  name="followers"
                  label="Followers"
                  value={values.followers}
                  onChange={setFieldValue}
                  withChipsBelow
                  isMulti
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  multiline
                  rows="5"
                  name="description"
                  component={FormikTextField}
                  label="Description"
                  placeholder="Enter a Deliverable description..."
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  multiline
                  rows="3"
                  name="managerNote"
                  component={FormikTextField}
                  label="Notes"
                  placeholder="Enter notes..."
                />
              </Grid>
              <Box display="flex" justifyContent="flex-end" width="100%" mt={3}>
                <GlobalButton
                  id="cancelCreateDeliverableForm"
                  variant="transparent"
                  handleClick={handleClose}
                >
                  Cancel
                </GlobalButton>
                <GlobalButton id="submitDeliverable" type="submit" disabled={isSubmitting}>
                  Create Deliverable
                </GlobalButton>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withUserContext(CreateDeliverableForm);

/**
 * @flow
 * @relayHash c453d486fbd3c8352094b779d5d53a08
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportDeliverablesInput = {|
  file: string,
  clientMutationId?: ?string,
|};
export type ImportDeliverablesMutationVariables = {|
  input: ImportDeliverablesInput
|};
export type ImportDeliverablesMutationResponse = {|
  +importDeliverables: ?{|
    +result: ?number,
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type ImportDeliverablesMutation = {|
  variables: ImportDeliverablesMutationVariables,
  response: ImportDeliverablesMutationResponse,
|};
*/


/*
mutation ImportDeliverablesMutation(
  $input: ImportDeliverablesInput!
) {
  importDeliverables(input: $input) {
    result
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportDeliverablesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "importDeliverables",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ImportDeliverablesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "errors",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ImportDeliverablesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ImportDeliverablesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ImportDeliverablesMutation",
    "id": null,
    "text": "mutation ImportDeliverablesMutation(\n  $input: ImportDeliverablesInput!\n) {\n  importDeliverables(input: $input) {\n    result\n    errors\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fca265eecfc98b599e5eed80525c3250';
module.exports = node;

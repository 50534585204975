/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobDetailsTab_stateTypes$ref: FragmentReference;
declare export opaque type JobDetailsTab_stateTypes$fragmentType: JobDetailsTab_stateTypes$ref;
export type JobDetailsTab_stateTypes = {|
  +enumValues: ?$ReadOnlyArray<{|
    +name: string,
    +description: ?string,
  |}>,
  +$refType: JobDetailsTab_stateTypes$ref,
|};
export type JobDetailsTab_stateTypes$data = JobDetailsTab_stateTypes;
export type JobDetailsTab_stateTypes$key = {
  +$data?: JobDetailsTab_stateTypes$data,
  +$fragmentRefs: JobDetailsTab_stateTypes$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "JobDetailsTab_stateTypes",
  "type": "__Type",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "enumValues",
      "storageKey": null,
      "args": null,
      "concreteType": "__EnumValue",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8ec4ea22769115ae089a1c48abe61f5f';
module.exports = node;

/**
 * @flow
 * @relayHash cd206b653466824fa59ea600ccbf8427
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SamlForm_organization$ref = any;
export type SamlTabQueryVariables = {||};
export type SamlTabQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: SamlForm_organization$ref
  |}
|};
export type SamlTabQuery = {|
  variables: SamlTabQueryVariables,
  response: SamlTabQueryResponse,
|};
*/


/*
query SamlTabQuery {
  organization {
    ...SamlForm_organization
    id
  }
}

fragment SamlForm_organization on OrganizationNode {
  hasSamlModule
  samlConfiguration {
    samlEnabled
    idpEntityId
    idpUrl
    idpX509cert
    idpAttrUserPermanentId
    metadataUrl
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SamlTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SamlForm_organization",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SamlTabQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasSamlModule",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "samlConfiguration",
            "storageKey": null,
            "args": null,
            "concreteType": "SamlConfigurationNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "samlEnabled",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "idpEntityId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "idpUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "idpX509cert",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "idpAttrUserPermanentId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "metadataUrl",
                "args": null,
                "storageKey": null
              },
              (v0/*: any*/)
            ]
          },
          (v0/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SamlTabQuery",
    "id": null,
    "text": "query SamlTabQuery {\n  organization {\n    ...SamlForm_organization\n    id\n  }\n}\n\nfragment SamlForm_organization on OrganizationNode {\n  hasSamlModule\n  samlConfiguration {\n    samlEnabled\n    idpEntityId\n    idpUrl\n    idpX509cert\n    idpAttrUserPermanentId\n    metadataUrl\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1523233e6d5a604e2531bb2b58dc49ae';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Toast from './components/common/Toast';

/**
 * Green toast
 * @param {string} message - Message of the toast
 * @param {Object} buttonProps - Props passed to the button
 * @param {string} buttonProps.text - Copy of the button
 * @param {string} buttonProps.link - Path to redirect to
 * @param {Object} toastConfig - Toastify props
 */

export const successToast = (message, buttonProps, toastConfig) => {
  toast(<Toast message={message} buttonProps={buttonProps} />, toastConfig);
};

successToast.propTypes = {
  message: PropTypes.string.isRequired,
  buttonProps: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }),
  toastConfig: PropTypes.object
};

/**
 * Red toast
 * @param {string} message - Message of the toast
 * @param {Object} toastConfig - Toastify props
 */

export const errorToast = (message, toastConfig, buttonProps = null) => {
  toast(<Toast isError message={message} buttonProps={buttonProps} />, toastConfig);
};

errorToast.propTypes = {
  message: PropTypes.string.isRequired,
  toastConfig: PropTypes.object
};

import React from 'react';
import clsx from 'clsx';
import SquareButton from '../SquareButton';
import { useButtonStyles } from '../../../styles/common/useButtonStyles';

const GroupButtonSquare = ({ className, ...props }) => {
  const classes = useButtonStyles();
  return <SquareButton className={clsx(classes.squareGroupButton, className)} {...props} />;
};

export default GroupButtonSquare;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation UpdateContractTemplateMutation($input: UpdateContractTemplateInput!) {
    updateContractTemplate(input: $input) {
      contractTemplate {
        id
        name
        docxFileName
        docxFileUrl
        samplePdfFileUrl
        categories(orderBy: "name") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      errors
    }
  }
`;

const UpdateContractTemplateMutation = (input, file, callback) => {
  const config = {
    mutation,
    ...(file && { uploadables: { template_file: file } }),
    variables: { input },
    onCompleted: response => {
      callback(response.updateContractTemplate);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default UpdateContractTemplateMutation;

UpdateContractTemplateMutation.propTypes = {
  input: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';
import PropTypes from 'prop-types';

const UpdateFreelancerEmailMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateFreelancerEmailMutation($input: UpdateFreelancerEmailInput!) {
        updateFreelancerEmail(input: $input) {
          tokenValid
          emailChanged
          message
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.updateFreelancerEmail, errors);
    },
    onError: err => console.error(err)
  });
};

UpdateFreelancerEmailMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      emailChangeToken: PropTypes.string.isRequired,
      uidb64: PropTypes.string.isRequired
    })
  )
};

export default UpdateFreelancerEmailMutation;

import { makeStyles } from '@material-ui/core/styles';

const formStyles = makeStyles(theme => ({
  dateFieldRoot: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },

    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0
    }
  },

  clearInputButton: {
    marginRight: theme.spacing(1),

    '& svg': {
      width: '12px',
      height: '12px'
    }
  },

  textFieldRoot: {
    '& .MuiPhoneNumber-flagButton .MuiButton-label:after': {
      content: '""',
      borderLeft: '3px solid transparent',
      borderRight: '3px solid transparent',
      borderTop: '3px solid rgba(0, 0, 0, 0.54)',
      marginLeft: '3px'
    },

    '&:not(.MuiFormControl-fullWidth)': {
      width: '300px'
    },

    '& label': {
      fontSize: '12px',
      transform: 'none !important',
      marginBottom: '6px',
      lineHeight: '16px',
      position: 'relative',

      '&.Mui-focused:not(.Mui-error)': {
        color: theme.palette.primary.main
      }
    },

    '& input': {
      fontSize: '12px',
      padding: '13px 16px'
    },

    '& input:not([disabled])[name="phone"]': {
      padding: '13px 0px'
    },
    '& input[disabled]::placeholder': {
      color: 'rgba(0, 0, 0, 0.68)'
    },

    '& input[type="file"]': {
      display: 'none'
    },

    '& input[type="number"]': {
      '&::-webkit-inner-spin-button,&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '-moz-appearance': 'textfield'
    },

    '& fieldset': {
      borderRadius: '2px'
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: props =>
          props.visibleToFreelancer
            ? theme.palette.colors.brightViolet
            : theme.palette.colors.formFieldBorderColor,
        borderWidth: props => (props.visibleToFreelancer ? '2px' : '1px')
      },
      '&:hover fieldset': {
        borderWidth: '1px'
      },
      '&.Mui-error fieldset': {
        borderColor: theme.palette.colors.red,
        borderWidth: '1px'
      },
      '&:not(.Mui-error).Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '1px'
      }
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
      fontSize: '12px'
    }
  },

  bigTextFieldRoot: {
    '& label': {
      color: '#87848E'
    },
    '& input': {
      backgroundColor: '#FFF',
      fontWeight: 'normal',
      fontSize: '14px',
      padding: '22px 16px'
    }
  },

  dropdownWrapper: {
    width: props => (props.notFullWidth ? '300px' : '100%'),
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
      fontSize: '12px'
    },
    '& label': {
      fontSize: '12px',
      transform: 'none !important',
      marginBottom: '6px',
      lineHeight: '16px',
      position: 'relative',

      '&.Mui-focused:not(.Mui-error)': {
        color: theme.palette.primary.main,
        borderWidth: '1px'
      }
    }
  },

  dropdownRoot: {
    '&.MuiSelect-select:focus': {
      border: '1px solid yellow'
    },
    '& div': {
      fontSize: '12px',
      padding: '13px 16px',
      height: '0'
    },

    '& fieldset': {
      borderRadius: '2px'
    },

    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: props =>
          props.visibleToFreelancer
            ? theme.palette.colors.brightViolet
            : theme.palette.colors.formFieldBorderColor,
        borderWidth: props => (props.visibleToFreelancer ? '2px' : '1px')
      },
      '&:hover fieldset': {
        borderWidth: '1px'
      },
      '&.Mui-error fieldset': {
        borderColor: theme.palette.colors.red,
        borderWidth: '1px'
      },

      '&:not(.Mui-error).Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '1px'
      }
    }
  },

  inputFieldFile: {
    height: '40.25px',
    padding: '0',

    '&:hover': {
      cursor: 'pointer'
    }
  },

  inputFileNamePlaceholder: {
    color: theme.palette.colors.mediumBlueGrey
  },

  inputFileName: {
    padding: '0 14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: '1 1 auto',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  endAdornmentButton: {
    '&.MuiButtonBase-root': {
      borderLeft: `1px solid ${theme.palette.colors.formFieldBorderColor}`,
      borderRadius: '0',
      '& svg': {
        width: '16px',
        height: '16px'
      }
    }
  },

  radioButtonGroup: {
    color: theme.palette.colors.mediumBlueGrey,
    fontWeight: 'bold',
    fontSize: '14px'
  },

  circularProgress: {
    color: '#FFF'
  },

  radioButtonGroupWrapper: {
    '& legend': {
      fontSize: '12px'
    },
    '& span': {
      fontWeight: 'bold'
    },
    '& label': {
      marginRight: props => (props.noMarginRight ? '0px' : '48px')
    }
  }
}));

export default formStyles;

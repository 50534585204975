import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { CELL_TYPES } from '../../../../shared/constants';
import AutoTable from '../../../../shared/components/table/AutoTable';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { formatAuditLogDescription, formatAuditLogAction } from '../../../../shared/utils/helpers';

const BillingActivity = props => {
  const {
    relay: { refetch },
    organization: { subscriptionHistory: historyLog }
  } = props;

  if (!historyLog) return <div>Something went wrong!</div>;

  const {
    edges,
    edgeCount,
    totalCount,
    pageInfo: { hasNextPage, endCursor }
  } = historyLog;

  const onChangeHandler = variables => {
    refetch({ ...variables });
  };

  const formattedEdges = edges.map(edge => {
    const {
      id,
      timestamp: date,
      action,
      changes,
      additionalData,
      actor: { orgStaff, freelancer }
    } = edge.node;
    const name = orgStaff ? orgStaff.fullName : freelancer ? freelancer.fullName : '';
    const imageUrl = orgStaff
      ? orgStaff.representativeImageUrl
      : freelancer
      ? freelancer.representativeImageUrl
      : '';
    return {
      node: {
        id,
        date,
        who: { name, imageUrl },
        action: formatAuditLogAction(action, additionalData),
        changes: formatAuditLogDescription(action, changes, additionalData)
      }
    };
  });

  return (
    <Fragment>
      <Typography variant="h3">Audit Log</Typography>
      <AutoTable
        rowTemplate={[
          { name: 'who', label: 'User', type: CELL_TYPES.avatar },
          { name: 'action', label: 'Action' },
          { name: 'changes', label: 'Changes' },
          { name: 'date', label: 'Date', type: CELL_TYPES.date }
        ]}
        edges={formattedEdges}
        onChangeHandler={onChangeHandler}
        paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
      />
    </Fragment>
  );
};

export default createRefetchContainer(
  BillingActivity,
  {
    organization: graphql`
      fragment BillingActivity_organization on OrganizationNode
        @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        id

        subscriptionHistory(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
          edges {
            node {
              id
              timestamp
              actor {
                orgStaff {
                  fullName
                  representativeImageUrl
                }
                freelancer {
                  fullName
                  representativeImageUrl
                }
              }
              action
              changes
              additionalData
            }
          }
        }
      }
    `
  },
  graphql`
    query BillingActivityRefetchQuery($first: Int, $after: String) {
      organization {
        ...BillingActivity_organization @arguments(first: $first, after: $after)
      }
    }
  `
);

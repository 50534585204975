import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

/**
 * @param {string} targetType - 'solicitation' or 'deliverable' or 'job'
 * @param {function} setter - Function setting the state. Is called with noitifications as an argument
 * @param {Object} variables - Additional variables or overwrite of existing ones
 */

const getFetchNotifications = (targetType, setter, variables) => {
  return (status = { mounted: true }) => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query fetchNotificationsQuery($unread: Boolean, $targetType: String, $targetId: String) {
          notifications(unread: $unread, targetType: $targetType, targetId: $targetId) {
            edges {
              node {
                targetObjectId
                actorObjectId
              }
            }
          }
        }
      `,
      { unread: true, targetType, ...variables },
      { force: true }
    ).then(response => {
      if (response && response.notifications && status.mounted) {
        setter(response.notifications);
      }
    });
  };
};

export default getFetchNotifications;

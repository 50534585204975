import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import { PAGINATION_TYPES } from '../../../../../shared/constants';
import ShowcaseList from './ShowcaseList';

const ContractorShowcaseTab = props => {
  const variables = useTableHistoryQueryVariables();
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query ContractorShowcaseTabQuery($id: ID!, $first: Int, $after: String) {
          contractor(id: $id) {
            ...ShowcaseList_contractor
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => <ShowcaseList contractor={relayProps.contractor} {...props} />}
    />
  );
};

export default withTableHistoryProvider(ContractorShowcaseTab, 'orgContrShowcase', {
  rowsPerPage: PAGINATION_TYPES.short.defaultValue
});

import * as Yup from 'yup';
import merge from 'lodash/merge';
import { phoneRegExp, ERROR_LABELS, ACCEPTED_FILE_SIZES, getSizeLabel } from './constants';
import { getSizeInBytes } from './utils/helpers';

const getFileSizeValidation = (label, acceptedSize) =>
  Yup.mixed().test('fileSize', label, value =>
    value === null ? true : value.size <= acceptedSize
  );

export const yupSchemas = {
  pdfFileSize: Yup.mixed().test('size', getSizeLabel(10), value =>
    value === null ? true : value.size <= getSizeInBytes(10)
  ),
  avatarFileSize: getFileSizeValidation(
    ERROR_LABELS.fileTooLarge.avatar,
    ACCEPTED_FILE_SIZES.avatar
  ),
  backgroundFileSize: getFileSizeValidation(
    ERROR_LABELS.fileTooLarge.contractorBackground,
    ACCEPTED_FILE_SIZES.contractorBackground
  ),
  thumbnailFileSize: getFileSizeValidation(
    ERROR_LABELS.fileTooLarge.thumbnail,
    ACCEPTED_FILE_SIZES.thumbnail
  ),
  shape: schape => Yup.object().shape(schape),
  url: (required = false) => {
    let validation = Yup.string().matches(
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
      'Please enter correct url.'
    );
    if (required) {
      validation = validation.clone(validation).required('Required');
    }
    return validation;
  },
  string: (required = false, optionsOverwrite) => {
    const { min, max } = merge({ max: 255 }, optionsOverwrite);
    let validation = Yup.string();

    if (min > 0) {
      validation = validation
        .clone(validation)
        .min(min, `Please enter at least ${min} characters.`);
    }

    if (max) {
      validation = validation
        .clone(validation)
        .max(max, `Please enter no more than ${max} characters.`);
    }

    if (required) {
      validation = validation.clone(validation).required('Required');
    }

    return validation;
  },
  phone: (required = false) => {
    let validation = Yup.string().matches(phoneRegExp, 'Phone number is not valid');

    if (required) {
      validation = validation.clone(validation).required('Required');
    }

    return validation;
  },
  email: (required = false) => {
    let validation = Yup.string().email('Invalid email');

    if (required) {
      validation = validation.clone(validation).required('Required');
    }

    return validation;
  },
  object: (required = false) => {
    let validation = Yup.mixed();

    if (required) {
      validation = validation.clone(validation).required('Required');
    }

    return validation;
  },
  country: (required = false) => {
    let validation = Yup.object().shape({ value: Yup.string(), label: Yup.string() }).nullable();
    if (required) {
      validation = validation.clone(validation).required('Required');
    }

    return validation;
  }
};

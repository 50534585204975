import { styled } from '@material-ui/styles';
import Switch from '@material-ui/core/Switch';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-colorSecondary.Mui-checked': {
    color: theme.palette.primary.main
  },
  '& .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main
  }
}));

export default StyledSwitch;

import React from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog as MUIDialog } from '@material-ui/core';

// NOTE:
// Prevents row from chaning it's height when archiving a file
export const DownloadContainer = styled.div`
  ${props =>
    !props.isVisible &&
    css`
      visibility: hidden;
    `}
`;

const ProgressDialogSpinnerContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressDialog = props => {
  return props.open
    ? createPortal(
        <MUIDialog {...props}>
          <ProgressDialogSpinnerContainer>
            <CircularProgress size={24} />
          </ProgressDialogSpinnerContainer>
        </MUIDialog>,
        document.body
      )
    : null;
};

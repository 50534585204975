/**
 * @flow
 * @relayHash cf9a0ac2a5d06ac2c1aa66b5257d6e0a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DetailsContact_deliverable$ref = any;
type DetailsDescription_deliverable$ref = any;
type DetailsMainContent_deliverable$ref = any;
export type DeliverableDetailsTabQueryVariables = {|
  id: string
|};
export type DeliverableDetailsTabQueryResponse = {|
  +deliverable: ?{|
    +$fragmentRefs: DetailsDescription_deliverable$ref & DetailsMainContent_deliverable$ref & DetailsContact_deliverable$ref
  |}
|};
export type DeliverableDetailsTabQuery = {|
  variables: DeliverableDetailsTabQueryVariables,
  response: DeliverableDetailsTabQueryResponse,
|};
*/


/*
query DeliverableDetailsTabQuery(
  $id: ID!
) {
  deliverable(id: $id) {
    ...DetailsDescription_deliverable
    ...DetailsMainContent_deliverable
    ...DetailsContact_deliverable
    id
  }
}

fragment DetailsDescription_deliverable on DeliverableNode {
  description
}

fragment DetailsMainContent_deliverable on DeliverableNode {
  id
  category {
    name
    id
  }
  categoryType {
    name
    id
  }
  job {
    jobDeadline
    contract {
      dateSignedByContractor
      id
    }
    id
  }
}

fragment DetailsContact_deliverable on DeliverableNode {
  assignedStaff {
    fullName
    representativeImageUrl
    organization {
      representativeImageUrl
      name
      country {
        name
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeliverableDetailsTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DetailsDescription_deliverable",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "DetailsMainContent_deliverable",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "DetailsContact_deliverable",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeliverableDetailsTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryNode",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categoryType",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryTypeNode",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "jobDeadline",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contract",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dateSignedByContractor",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              },
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignedStaff",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgNode",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "country",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Country",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DeliverableDetailsTabQuery",
    "id": null,
    "text": "query DeliverableDetailsTabQuery(\n  $id: ID!\n) {\n  deliverable(id: $id) {\n    ...DetailsDescription_deliverable\n    ...DetailsMainContent_deliverable\n    ...DetailsContact_deliverable\n    id\n  }\n}\n\nfragment DetailsDescription_deliverable on DeliverableNode {\n  description\n}\n\nfragment DetailsMainContent_deliverable on DeliverableNode {\n  id\n  category {\n    name\n    id\n  }\n  categoryType {\n    name\n    id\n  }\n  job {\n    jobDeadline\n    contract {\n      dateSignedByContractor\n      id\n    }\n    id\n  }\n}\n\nfragment DetailsContact_deliverable on DeliverableNode {\n  assignedStaff {\n    fullName\n    representativeImageUrl\n    organization {\n      representativeImageUrl\n      name\n      country {\n        name\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62e821054ea5a79c0fbd8749e5a68b47';
module.exports = node;

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import clsx from 'clsx';

import { NoSsr } from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Control from './fieldElements/reactSelectComponents/Control';
import Option from './fieldElements/reactSelectComponents/Option';
import MultiValue from './fieldElements/reactSelectComponents/MultiValue';
import ValueContainer from './fieldElements/reactSelectComponents/ValueContainer';
import Menu from './fieldElements/reactSelectComponents/Menu';
import ClearIndicator from './fieldElements/reactSelectComponents/ClearIndicator';

export const useSearchableSelectStyles = makeStyles(theme => ({
  root: {
    '& p:not(.Mui-error)': {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },

  searchBar: {
    '& fieldset': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    }
  },
  innerTextField: {
    width: props => (props.notFullWidth ? '' : '100% !important')
  },
  input: {
    display: 'flex',
    fontSize: '12px',
    padding: '13px 0 13px 16px',
    lineHeight: 'normal',
    overflow: 'hidden'
  },
  valueContainer: {
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > div > div': {
      display: 'flex !important',
      alignItems: 'center',
      fontWeight: 'bold',
      marginTop: '-8px'
    },
    '& input': {
      padding: '3px 0!important'
    }
  },
  singleValue: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    left: 0,
    right: 0
  },
  disabled: {
    '& label': {
      color: 'rgba(0, 0, 0, 0.38)'
    },
    '& > div': {
      color: 'rgba(0, 0, 0, 0.38)'
    }
  }
}));

export const getSelectStyles = (theme, props = {}) => ({
  input: base => ({
    ...base,
    '& input': {
      font: 'inherit'
    }
  }),
  dropdownIndicator: base => ({
    ...base,
    height: '40px',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: props.noBorder ? '' : `1px solid ${theme.palette.colors.formFieldBorderColor}`,
    transition: `background-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    color: theme.palette.colors.darkBlueGrey,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.colors.darkBlueGrey,
      backgroundColor: theme.palette.action.hover
    }
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
});

// TODO:
// Add common code for props setup which will be used in both Select and SearchableFormSelect
const SearchableFormSelect = props => {
  const {
    isValidNewOption,
    isMulti,
    noCache,
    isOptionDisabled,
    value,
    onChange,
    name,
    label,
    required,
    onBlur,
    helperText,
    disabled,
    loadOptions,
    creatable,
    className,
    resultsCounts,
    hideSelection,
    itemsInList,
    keyToReload,
    textFieldProps,
    noClear,
    maxMenuHeight,
    visibleToFreelancer,
    ...rest
  } = props;

  const classes = useSearchableSelectStyles();
  const theme = useTheme();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const sharedProps = {
    menuPortalTarget: document.body,
    maxMenuHeight,
    isDisabled: disabled,
    isMulti,
    name,
    cacheOptions: !noCache,
    isOptionDisabled,
    onChange,
    loadOptions: (inputValue, cb) => {
      loadOptions(isMounted, inputValue, cb);
    },
    defaultOptions: true,
    classes,
    styles: getSelectStyles(theme),
    inputId: name,
    id: `select-${name}`,
    TextFieldProps: {
      label,
      InputLabelProps: {
        shrink: true,
        htmlFor: name
      },
      required,
      onBlur,
      error: typeof helperText === 'string',
      helperText,
      ...textFieldProps
    },
    components: {
      Control,
      Menu: props => (
        <Menu resultsCounts={resultsCounts} {...(itemsInList && { itemsInList })} {...props} />
      ),
      Option,
      MultiValue,
      ValueContainer,
      ClearIndicator: noClear ? () => null : ClearIndicator
    },
    isClearable: !hideSelection,
    value,
    defaultValue: value,
    noOptionsMessage: () => 'No results',
    controlShouldRenderValue: !hideSelection,
    ...(itemsInList && {
      filterOption: data => !itemsInList.find(item => item.id === data.value)
    }),
    key: keyToReload,
    visibleToFreelancer,
    ...rest
  };

  return (
    <div className={clsx(classes.root, className)}>
      <NoSsr>
        {creatable ? (
          <AsyncCreatableSelect
            {...sharedProps}
            ignoreCase={true}
            isValidNewOption={isValidNewOption}
            placeholder={
              props.disabled && props.disabledPlaceholder
                ? props.disabledPlaceholder
                : props.placeholder
            }
          />
        ) : (
          <AsyncSelect
            {...sharedProps}
            placeholder={
              props.disabled && props.disabledPlaceholder
                ? props.disabledPlaceholder
                : props.placeholder
            }
          />
        )}
      </NoSsr>
    </div>
  );
};

SearchableFormSelect.propTypes = {
  /*
    For some views the select adds item to the list that is stored in the state.
    Passing this array will filter out items existing in the list.
  */
  itemsInList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ),
  // if provided, it will trigger option reload if some external condition changes
  keyToReload: PropTypes.string
};

SearchableFormSelect.defaultProps = {
  itemsInList: null,
  noClear: false,
  maxMenuHeight: 225
};

export default SearchableFormSelect;

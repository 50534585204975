import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from '../UI/StyledCheckbox';

export const FormikCheckbox = ({ field, form, label, labelPlacement, ...otherProps }) => {
  const handleChange = event => {
    form.setFieldValue(field.name, !field.value);
  };

  return (
    <FormControlLabel
      control={<StyledCheckbox {...otherProps} checked={field.value} onChange={handleChange} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

FormikCheckbox.defaultProps = {
  labelPlacement: 'end'
};

/**
 * @flow
 * @relayHash f1d8b6cc6f7053dce8e34b5031f1ae63
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDeliverableReviewAnnotationInput = {|
  reviewAsset: string,
  annotationData: any,
  annotationRef?: ?string,
  content?: ?string,
  rawContent?: ?string,
  referenceIds?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type UpdateDeliverableReviewAnnotationMutationVariables = {|
  input: UpdateDeliverableReviewAnnotationInput
|};
export type UpdateDeliverableReviewAnnotationMutationResponse = {|
  +updateDeliverableReviewAnnotation: ?{|
    +updatedDeliverableReviewAnnotation: ?{|
      +annotationData: ?any,
      +reviewAsset: {|
        +comments: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +content: string,
              +contentEditedAt: ?any,
              +created: any,
              +objectId: number,
              +extraData: ?any,
              +repliedTo: ?{|
                +content: ?string,
                +created: ?any,
                +user: ?{|
                  +fullName: ?string
                |},
                +referenceFiles: ?$ReadOnlyArray<?{|
                  +id: string,
                  +title: ?string,
                  +description: ?string,
                  +fileUrl: ?string,
                  +reviewInterface: ?string,
                |}>,
              |},
              +user: ?{|
                +id: string,
                +fullName: ?string,
                +representativeImageUrl: ?string,
              |},
              +referenceFiles: ?$ReadOnlyArray<?{|
                +id: string,
                +title: ?string,
                +description: ?string,
                +fileUrl: ?string,
                +reviewInterface: ?string,
              |}>,
              +reactions: ?$ReadOnlyArray<?{|
                +id: ?string,
                +definitionId: ?string,
                +name: ?string,
                +color: ?string,
                +number: ?number,
                +users: ?$ReadOnlyArray<?{|
                  +id: string,
                  +fullName: ?string,
                  +representativeImageUrl: ?string,
                |}>,
              |}>,
            |}
          |}>
        |}
      |},
    |},
    +clientMutationId: ?string,
  |}
|};
export type UpdateDeliverableReviewAnnotationMutation = {|
  variables: UpdateDeliverableReviewAnnotationMutationVariables,
  response: UpdateDeliverableReviewAnnotationMutationResponse,
|};
*/


/*
mutation UpdateDeliverableReviewAnnotationMutation(
  $input: UpdateDeliverableReviewAnnotationInput!
) {
  updateDeliverableReviewAnnotation(input: $input) {
    updatedDeliverableReviewAnnotation {
      annotationData
      reviewAsset {
        comments {
          edges {
            node {
              id
              content
              contentEditedAt
              created
              objectId
              extraData
              repliedTo {
                content
                created
                user {
                  fullName
                  id
                }
                referenceFiles {
                  id
                  title
                  description
                  fileUrl
                  reviewInterface
                }
              }
              user {
                id
                fullName
                representativeImageUrl
              }
              referenceFiles {
                id
                title
                description
                fileUrl
                reviewInterface
              }
              reactions {
                id
                definitionId
                name
                color
                number
                users {
                  id
                  fullName
                  representativeImageUrl
                }
              }
            }
          }
        }
        id
      }
      id
    }
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDeliverableReviewAnnotationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "annotationData",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contentEditedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "objectId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "extraData",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referenceFiles",
  "storageKey": null,
  "args": null,
  "concreteType": "ReferenceNode",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reviewInterface",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = [
  (v3/*: any*/),
  (v9/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
],
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": (v11/*: any*/)
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reactions",
  "storageKey": null,
  "args": null,
  "concreteType": "ReactionNode",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "definitionId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "users",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": true,
      "selections": (v11/*: any*/)
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateDeliverableReviewAnnotationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDeliverableReviewAnnotation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeliverableReviewAnnotationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverableReviewAnnotation",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableReviewAnnotationNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "reviewAsset",
                "storageKey": null,
                "args": null,
                "concreteType": "ReviewAssetNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "comments",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "repliedTo",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CommentRepliedToNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "user",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/)
                                    ]
                                  },
                                  (v10/*: any*/)
                                ]
                              },
                              (v12/*: any*/),
                              (v10/*: any*/),
                              (v13/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v14/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateDeliverableReviewAnnotationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDeliverableReviewAnnotation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeliverableReviewAnnotationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverableReviewAnnotation",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableReviewAnnotationNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "reviewAsset",
                "storageKey": null,
                "args": null,
                "concreteType": "ReviewAssetNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "comments",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "repliedTo",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CommentRepliedToNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "user",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      (v3/*: any*/)
                                    ]
                                  },
                                  (v10/*: any*/)
                                ]
                              },
                              (v12/*: any*/),
                              (v10/*: any*/),
                              (v13/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v3/*: any*/)
                ]
              },
              (v3/*: any*/)
            ]
          },
          (v14/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateDeliverableReviewAnnotationMutation",
    "id": null,
    "text": "mutation UpdateDeliverableReviewAnnotationMutation(\n  $input: UpdateDeliverableReviewAnnotationInput!\n) {\n  updateDeliverableReviewAnnotation(input: $input) {\n    updatedDeliverableReviewAnnotation {\n      annotationData\n      reviewAsset {\n        comments {\n          edges {\n            node {\n              id\n              content\n              contentEditedAt\n              created\n              objectId\n              extraData\n              repliedTo {\n                content\n                created\n                user {\n                  fullName\n                  id\n                }\n                referenceFiles {\n                  id\n                  title\n                  description\n                  fileUrl\n                  reviewInterface\n                }\n              }\n              user {\n                id\n                fullName\n                representativeImageUrl\n              }\n              referenceFiles {\n                id\n                title\n                description\n                fileUrl\n                reviewInterface\n              }\n              reactions {\n                id\n                definitionId\n                name\n                color\n                number\n                users {\n                  id\n                  fullName\n                  representativeImageUrl\n                }\n              }\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    clientMutationId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4173b7898a9a3108098a0e7c22d54eaa';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeleteCategoriesMutation($input: DeleteCategoriesInput!) {
    deleteCategories(input: $input) {
      isDeleted
    }
  }
`;

const DeleteCategoriesMutation = (ids, callback) => {
  const config = {
    mutation,
    variables: { input: { ids } },
    onCompleted: (response, errors) => {
      callback(response.deleteCategories, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default DeleteCategoriesMutation;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Avatar from '../../common/Avatar';
import BaseDateBadge from '../../comment/DateBadge';
import ReactionCard, {
  CounterContainer as ReactionCounterContainer,
  LabelContainer as ReactionLabelContainer
} from './ReactionCard';

const DateBadge = styled(BaseDateBadge)``;

export const CommentPreview = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ReactionsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-top: 6px;
    margin-right: 4px;
  }
`;

export const CommentEditedLabel = styled.span.attrs({
  children: '(edited)'
})`
  font-size: 10px;
  color: ${props => props.theme.palette.colors.mediumBlueGrey};
`;

const ActionsContainer = styled.div`
  margin-left: auto;
  display: flex;

  > * {
    margin-left: 8px;
  }
`;

export const AttachmentIcon = styled(AttachFileIcon)`
  width: 15px;

  & + * {
    margin-left: 5px;
  }
`;

const Container = styled.div`
  position: relative;
  transition: background-color 0.2s;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `};

  ${ActionsContainer} {
    * {
      transition: none;
    }
  }

  ${props =>
    props.active &&
    css`
      box-shadow: inset 0px -4px 0px 0px ${props.theme.palette.primary.main};
      background: ${fade(props.theme.palette.primary.main, 0.2)};

      ${DateBadge} {
        color: ${props.theme.palette.primary.main};
      }

      ${ActionsContainer} {
        .MuiButtonBase-root {
          background: ${props.theme.palette.primary.main};
        }

        .MuiChip-label {
          color: #fff;
        }

        svg path {
          fill: #fff;
        }

        ${AttachmentIcon} path {
          fill: ${props.theme.palette.primary.main};
        }
      }

      ${ReactionCard} {
        ${ReactionLabelContainer} {
          border-color: ${props.theme.palette.primary.main};
        }

        ${ReactionCounterContainer} {
          background: ${props.theme.palette.primary.main};
          color: #fff;
        }
      }
    `};

  ${props =>
    props.withHover &&
    !props.active &&
    css`
      &:not(:last-child) {
        box-shadow: inset 0px -1px 0px 0px ${props => props.theme.palette.colors.lightestGrey};
      }

      &:hover {
        box-shadow: inset 0px -4px 0px 0px ${props.theme.palette.colors.darkBlueGrey};
        background: ${props.theme.palette.colors.progressBarLightGrey};

        ${ActionsContainer} {
          .MuiButtonBase-root {
            background: ${props.theme.palette.colors.darkBlueGrey};

            &,
            .MuiChip-label {
              color: #fff;
            }

            svg path {
              fill: #fff;
            }
          }
        }

        ${ReactionCard} {
          ${ReactionLabelContainer} {
            border-color: ${props.theme.palette.colors.darkBlueGrey};
          }

          ${ReactionCounterContainer} {
            background: ${props.theme.palette.colors.darkBlueGrey};
            color: #fff;
          }
        }
      }
    `};
`;

const CommentCard = props => {
  const {
    date,
    authorName,
    authorAvatar,
    actions,
    author,
    children,
    withAttachments,
    ...containerProps
  } = props;

  return (
    <Container {...containerProps}>
      <Box display="flex" mb={2}>
        <Avatar defaultIcon="person" size={34} src={authorAvatar} />
        <Box display="flex" flexDirection="column" ml={1.5}>
          <Typography variant="body2">{authorName}</Typography>
          <DateBadge date={date} />
        </Box>

        {actions && (
          <ActionsContainer
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {withAttachments && <AttachmentIcon />}
            {actions.map((node, i) => (
              <Fragment key={i} children={node} />
            ))}
          </ActionsContainer>
        )}
      </Box>
      {children}
    </Container>
  );
};

CommentCard.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  authorName: PropTypes.string.isRequired,
  authorAvatar: PropTypes.string.isRequired,
  active: PropTypes.bool,
  withAttachments: PropTypes.bool
};

export default styled(CommentCard)``;

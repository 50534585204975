import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import BillingPlan from '../BillingPlan';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';

const BillingPlanRenderer = props => {
  return (
    <QueryRenderer
      query={graphql`
        query BillingPlanRendererQuery {
          ...BillingPlan_billing
        }
      `}
      variables={{}}
      render={relayProps => <BillingPlan billing={relayProps} {...props} />}
    />
  );
};

export default BillingPlanRenderer;

/**
 * @flow
 * @relayHash ece1c92b94c187394384a7a06f948978
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrganizationProfile_organization$ref = any;
export type OrganizationProfilePageQueryVariables = {||};
export type OrganizationProfilePageQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: OrganizationProfile_organization$ref
  |}
|};
export type OrganizationProfilePageQuery = {|
  variables: OrganizationProfilePageQueryVariables,
  response: OrganizationProfilePageQueryResponse,
|};
*/


/*
query OrganizationProfilePageQuery {
  organization {
    ...OrganizationProfile_organization
    id
  }
}

fragment OrganizationProfile_organization on OrganizationNode {
  id
  name
  address1
  address2
  city
  state
  zipcode
  country {
    name
    code
  }
  website
  representativeImageUrl
  financeEmail
  contractEmail
  email
  phone
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrganizationProfilePageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OrganizationProfile_organization",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrganizationProfilePageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "city",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "zipcode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "country",
            "storageKey": null,
            "args": null,
            "concreteType": "Country",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "code",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "website",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "representativeImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "financeEmail",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "contractEmail",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrganizationProfilePageQuery",
    "id": null,
    "text": "query OrganizationProfilePageQuery {\n  organization {\n    ...OrganizationProfile_organization\n    id\n  }\n}\n\nfragment OrganizationProfile_organization on OrganizationNode {\n  id\n  name\n  address1\n  address2\n  city\n  state\n  zipcode\n  country {\n    name\n    code\n  }\n  website\n  representativeImageUrl\n  financeEmail\n  contractEmail\n  email\n  phone\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d5c000d07e9964ff08878ba48e77af7';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import OverviewContent from './OverviewContent';

const OverviewTab = props => {
  return (
    <QueryRenderer
      query={graphql`
        query OverviewTabQuery {
          organization {
            ...OverviewContent_organization
          }
        }
      `}
      variables={{}}
      render={relayProps => <OverviewContent organization={relayProps.organization} {...props} />}
    />
  );
};

export default OverviewTab;

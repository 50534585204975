/**
 * @flow
 * @relayHash 3255a7ec258576257983b0c950058c72
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractsList_jobs$ref = any;
export type ContractsListRefetchQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
  status?: ?string,
  organization?: ?string,
  orgSequenceId?: ?string,
  staffId?: ?string,
  contractDateFrom?: ?any,
  contractDateTo?: ?any,
  jobDeadlineFrom?: ?any,
  jobDeadlineTo?: ?any,
  name?: ?string,
|};
export type ContractsListRefetchQueryResponse = {|
  +$fragmentRefs: ContractsList_jobs$ref
|};
export type ContractsListRefetchQuery = {|
  variables: ContractsListRefetchQueryVariables,
  response: ContractsListRefetchQueryResponse,
|};
*/


/*
query ContractsListRefetchQuery(
  $first: Int
  $orderBy: String
  $after: String
  $status: String
  $organization: String
  $orgSequenceId: String
  $staffId: String
  $contractDateFrom: Date
  $contractDateTo: Date
  $jobDeadlineFrom: Date
  $jobDeadlineTo: Date
  $name: String
) {
  ...ContractsList_jobs_3u1rKX
}

fragment ContractsList_jobs_3u1rKX on Query {
  allJobs(after: $after, first: $first, orderBy: $orderBy, status: $status, organization: $organization, orgSequenceId: $orgSequenceId, staffId: $staffId, contractDateFrom: $contractDateFrom, contractDateTo: $contractDateTo, jobDeadlineFrom: $jobDeadlineFrom, jobDeadlineTo: $jobDeadlineTo, jobName: $name) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        jobId
        name
        staffCoordinator {
          fullName
          representativeImageUrl
          organization {
            name
            representativeImageUrl
            currencyCode
            id
          }
          id
        }
        jobDeadline
        totalValue
        contractDate
        status
        currencyCode
        deliverables {
          edges {
            node {
              id
            }
          }
        }
        contract {
          id
          contractFileUrl
          job {
            status
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "organization",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgSequenceId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "staffId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractDateFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractDateTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadlineFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadlineTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "contractDateFrom",
  "variableName": "contractDateFrom"
},
v3 = {
  "kind": "Variable",
  "name": "contractDateTo",
  "variableName": "contractDateTo"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = {
  "kind": "Variable",
  "name": "jobDeadlineFrom",
  "variableName": "jobDeadlineFrom"
},
v6 = {
  "kind": "Variable",
  "name": "jobDeadlineTo",
  "variableName": "jobDeadlineTo"
},
v7 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v8 = {
  "kind": "Variable",
  "name": "orgSequenceId",
  "variableName": "orgSequenceId"
},
v9 = {
  "kind": "Variable",
  "name": "organization",
  "variableName": "organization"
},
v10 = {
  "kind": "Variable",
  "name": "staffId",
  "variableName": "staffId"
},
v11 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyCode",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractsListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ContractsList_jobs",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractsListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allJobs",
        "storageKey": null,
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "Variable",
            "name": "jobName",
            "variableName": "name"
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "concreteType": "JobNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPreviousPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobNode",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "jobId",
                    "args": null,
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "staffCoordinator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "organization",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrgNode",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v12/*: any*/)
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "jobDeadline",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalValue",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "contractDate",
                    "args": null,
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v15/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "deliverables",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DeliverableNode",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contract",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractNode",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "contractFileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "job",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobNode",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractsListRefetchQuery",
    "id": null,
    "text": "query ContractsListRefetchQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n  $status: String\n  $organization: String\n  $orgSequenceId: String\n  $staffId: String\n  $contractDateFrom: Date\n  $contractDateTo: Date\n  $jobDeadlineFrom: Date\n  $jobDeadlineTo: Date\n  $name: String\n) {\n  ...ContractsList_jobs_3u1rKX\n}\n\nfragment ContractsList_jobs_3u1rKX on Query {\n  allJobs(after: $after, first: $first, orderBy: $orderBy, status: $status, organization: $organization, orgSequenceId: $orgSequenceId, staffId: $staffId, contractDateFrom: $contractDateFrom, contractDateTo: $contractDateTo, jobDeadlineFrom: $jobDeadlineFrom, jobDeadlineTo: $jobDeadlineTo, jobName: $name) {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        jobId\n        name\n        staffCoordinator {\n          fullName\n          representativeImageUrl\n          organization {\n            name\n            representativeImageUrl\n            currencyCode\n            id\n          }\n          id\n        }\n        jobDeadline\n        totalValue\n        contractDate\n        status\n        currencyCode\n        deliverables {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        contract {\n          id\n          contractFileUrl\n          job {\n            status\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4467b68c2814e774c1f0a8ab800ec9c9';
module.exports = node;

/**
 * @flow
 * @relayHash 12c57e4834e147744f033e8257aa18a4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationDetailsMainContent_solicitation$ref = any;
export type SolicitationDetailsTabQueryVariables = {|
  id: string
|};
export type SolicitationDetailsTabQueryResponse = {|
  +solicitation: ?{|
    +$fragmentRefs: SolicitationDetailsMainContent_solicitation$ref
  |}
|};
export type SolicitationDetailsTabQuery = {|
  variables: SolicitationDetailsTabQueryVariables,
  response: SolicitationDetailsTabQueryResponse,
|};
*/

/*
query SolicitationDetailsTabQuery(
  $id: ID!
) {
  solicitation(id: $id) {
    ...SolicitationDetailsMainContent_solicitation
    id
  }
}

fragment SolicitationDetailsMainContent_solicitation on SolicitationNode {
  createdBy {
    fullName
    representativeImageUrl
    id
  }
  staffCoordinator {
    fullName
    representativeImageUrl
    id
  }
  subject
  dateSent
  replyByDate
  numberOfDeliverables
  totalDeliverableAmount
  message
  state
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id'
      }
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v3 = [
      {
        kind: 'ScalarField',
        alias: null,
        name: 'fullName',
        args: null,
        storageKey: null
      },
      {
        kind: 'ScalarField',
        alias: null,
        name: 'representativeImageUrl',
        args: null,
        storageKey: null
      },
      (v2 /*: any*/)
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'SolicitationDetailsTabQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'solicitation',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'SolicitationNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'SolicitationDetailsMainContent_solicitation',
              args: null
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'SolicitationDetailsTabQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'solicitation',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'SolicitationNode',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'createdBy',
              storageKey: null,
              args: null,
              concreteType: 'StaffNode',
              plural: false,
              selections: (v3 /*: any*/)
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'staffCoordinator',
              storageKey: null,
              args: null,
              concreteType: 'StaffNode',
              plural: false,
              selections: (v3 /*: any*/)
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'subject',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'dateSent',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'replyByDate',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'numberOfDeliverables',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'totalDeliverableAmount',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'message',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'state',
              args: null,
              storageKey: null
            },
            (v2 /*: any*/)
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'SolicitationDetailsTabQuery',
      id: null,
      text:
        'query SolicitationDetailsTabQuery(\n  $id: ID!\n) {\n  solicitation(id: $id) {\n    ...SolicitationDetailsMainContent_solicitation\n    id\n  }\n}\n\nfragment SolicitationDetailsMainContent_solicitation on SolicitationNode {\n  createdBy {\n    fullName\n    representativeImageUrl\n    id\n  }\n  staffCoordinator {\n    fullName\n    representativeImageUrl\n    id\n  }\n  subject\n  dateSent\n  replyByDate\n  numberOfDeliverables\n  totalDeliverableAmount\n  message\n  state\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '242b5b24f2885ea2db636243529d7d43';
module.exports = node;

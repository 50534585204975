import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import Placeholder from './Placeholder';
import { useEffectWithStatus } from '../../../../hooks/useEffectWithStatus';
import { findFbxFile, resolveFileKey } from '../../../../utils/zipUtils.js';

/**
 * This components loads and renders fbx 3D Models inside a zip archive
 * that contains the .fbx file and the related texture or material
 * files. The loader uses relative urls contained in the .fbx file to
 * resolve the auxiliary files.
 *
 */
const ZipFbx3DAsset = props => {
  const { fileMap, centerCameraOn, theme, flatShading, handlers } = props;
  const [obj, setScene] = useState(null);
  const fileName = findFbxFile(fileMap);
  // TODO gracefully fall back
  if (fileName === null) {
    throw new Error('Could not find fbx or obj in zip archive.');
  }

  useEffectWithStatus(status => {
    const objUrl = fileMap[fileName];
    // create a custom manager to map the urls
    const manager = new THREE.LoadingManager();
    // sets the function to map the urls
    // the url parameter is are provided by
    // THREE.js as a relative path and
    // we use the map that contains the mapping
    // between the name of the file inside the zip
    // to the blob: url provided by the Zip3DAsset component.
    manager.setURLModifier(url => {
      // Split the url path
      const arrayPath = url.split('/');
      // get the fileanem and query string
      const lastPath = arrayPath[arrayPath.length - 1];
      // get the file name by removing the query string part
      const filename = lastPath.split('?')[0];
      // get the url using the basePath of the 3D model
      // plus the file name and use it to map to the
      // correct blob URL
      const key = resolveFileKey(fileMap, filename);
      const newUrl = fileMap[key];
      // return a new url is a mapping was found
      // otherwise return the original url
      return newUrl ? newUrl : url;
    });

    let fbxLoader = new FBXLoader(manager);
    // Callback invoked after the fbx file is loaded.
    let callbackOnLoad = model => {
      model.traverse(function (child) {
        if (child.isMesh) {
          child.material.flatShading = flatShading;
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      status.mounted && setScene(model);
    };

    // starts the loading process using the FBX Loader
    fbxLoader.load(objUrl, callbackOnLoad, null, null, null);
  }, []);

  useUpdateEffect(() => {
    centerCameraOn(obj);
  }, [obj]);

  return obj ? <primitive object={obj} {...handlers} /> : <Placeholder theme={theme} />;
};

ZipFbx3DAsset.propTypes = {
  src: PropTypes.string.isRequired,
  centerCameraOn: PropTypes.func.isRequired,
  flatShading: PropTypes.bool,
  handlers: PropTypes.shape({
    onPointerMove: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onClick: PropTypes.func
  })
};

export default ZipFbx3DAsset;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';
import OrganizationList from './organizations/list/OrganizationsList';

const OrganizationListTabRenderer = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query OrganizationListTabRendererQuery(
          $first: Int
          $orderBy: String
          $after: String
          $name: String
          $status: String
        ) {
          ...OrganizationsList_organizations
            @arguments(
              first: $first
              orderBy: $orderBy
              after: $after
              name: $name
              status: $status
            )
        }
      `}
      variables={variables}
      render={relayProps => (
        <OrganizationList label="Organization" organizations={relayProps} {...props} />
      )}
    />
  );
};

export default withTableHistoryProvider(OrganizationListTabRenderer, 'freOrganization', {
  initialFilters: {
    name: '',
    status: ''
  }
});

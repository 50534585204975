/**
 * @flow
 * @relayHash 0b0afba8586b2e3928b34fe928dd3716
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ToggleContractorsTagsInput = {|
  tags: $ReadOnlyArray<?string>,
  contractors: $ReadOnlyArray<?string>,
  activate: boolean,
  clientMutationId?: ?string,
|};
export type BulkToggleTagsOnContractorsMutationVariables = {|
  input: ToggleContractorsTagsInput
|};
export type BulkToggleTagsOnContractorsMutationResponse = {|
  +toggleContractorsTags: ?{|
    +contractors: ?$ReadOnlyArray<?{|
      +id: string
    |}>
  |}
|};
export type BulkToggleTagsOnContractorsMutation = {|
  variables: BulkToggleTagsOnContractorsMutationVariables,
  response: BulkToggleTagsOnContractorsMutationResponse,
|};
*/


/*
mutation BulkToggleTagsOnContractorsMutation(
  $input: ToggleContractorsTagsInput!
) {
  toggleContractorsTags(input: $input) {
    contractors {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ToggleContractorsTagsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "toggleContractorsTags",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleContractorsTagsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractors",
        "storageKey": null,
        "args": null,
        "concreteType": "ContractorNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BulkToggleTagsOnContractorsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BulkToggleTagsOnContractorsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BulkToggleTagsOnContractorsMutation",
    "id": null,
    "text": "mutation BulkToggleTagsOnContractorsMutation(\n  $input: ToggleContractorsTagsInput!\n) {\n  toggleContractorsTags(input: $input) {\n    contractors {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2e0d7ae37a2a7d364927bccfa086186d';
module.exports = node;

import React from 'react';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as WarningIcon } from '../../images/exclamation-triangle-icon.svg';
import StyledTableCell from './StyledTableCell';
import { useTableStyles } from '../../styles/common/tableStyles';

const TableCellWarning = props => {
  const { hidden, tooltip = '' } = props;
  const classes = useTableStyles();
  return (
    <StyledTableCell noWrap className={classes.tableCellWarning}>
      {!hidden && (
        <div className={classes.verticalCenter}>
          <Tooltip title={tooltip}>
            <WarningIcon />
          </Tooltip>
        </div>
      )}
    </StyledTableCell>
  );
};

export default TableCellWarning;

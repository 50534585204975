import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled(Box)`
  position: relative;
`;

const Tooltip = styled(Box)`
  position: absolute;
  opacity: 0;
  background: #fafafa;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  padding: 8px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all .1s ease;
  pointer-events: none;
  transform: translate(-40%, 0);
  border-radius: 3px;
  white-space: nowrap;

  .chartjs-tooltip-key {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 8px;
  }

  .chartjs-tooltip-key + span {
    margin-left: 10px;
  }
`;

const DoughnutChart = ({ options, ...otherProps }) => {
  const tooltipRef = useRef(null);

  return (
    <Container>
      <Tooltip ref={tooltipRef} />

      <Doughnut
        {...otherProps}
        options={{
          maintainAspectRatio: false,
          utoutPercentage: 65,
          ...options,
          tooltips: {
            ...(options && options.tooltips),
            enabled: false,
            custom: function(tooltip) {
              // NOTE:
              // Based on https://www.chartjs.org/samples/latest/tooltips/custom-pie.html
              const tooltipEl = tooltipRef.current;

              if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltip.yAlign) {
                tooltipEl.classList.add(tooltip.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              if (tooltip.body) {
                const bodyLines = tooltip.body.map(bodyItem => bodyItem.lines[0]);
                let innerHTML = '';

                bodyLines.forEach((body, i) => {
                  const colors = tooltip.labelColors[i];
                  const tolltipKeyHTML = `<span class="chartjs-tooltip-key" style="background: ${colors.backgroundColor}"></span>`;
                  const bodyStr = typeof body === 'string' ? body : `${body.label} <span>${body.value}</span>`
                  innerHTML += `${tolltipKeyHTML} ${bodyStr}`;
                });

                tooltipEl.innerHTML = innerHTML;
              }

              var positionY = this._chart.canvas.offsetTop;
              var positionX = this._chart.canvas.offsetLeft;

              tooltipEl.style.opacity = 1;
              tooltipEl.style.left = positionX + tooltip.caretX + 'px';
              tooltipEl.style.top = positionY + tooltip.caretY + 'px';
            }
          }
        }}
      />
    </Container>
  );
};

DoughnutChart.propTypes = {
  data: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.number)
      })
    ),
    labels: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

DoughnutChart.defaultProps = {
  width: 200,
  height: 200
};

export default DoughnutChart;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';
import PropTypes from 'prop-types';

const UpdateReviewAssetSettingsMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateReviewAssetSettingsMutation(
        $input: UpdateReviewAssetSettingsInput!
        ){
        updateReviewAssetSettings(input: $input) {
          reviewAsset {
            settings
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.updateReviewAssetSettings, errors);
    },
    onError: err => console.error(err)
  });
};

UpdateReviewAssetSettingsMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      emailChangeToken: PropTypes.string.isRequired,
      uidb64: PropTypes.string.isRequired
    })
  )
};

export default UpdateReviewAssetSettingsMutation;
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import FullScreenModal from '../../../shared/components/fullscreenModal/FullscreenModal';
import ContractPreview from '../../../shared/components/job/ContractPreview';
import SignaturePadModal from '../../../shared/components/signaturePad/SignaturePadModal';
import SignContractByContractor from './mutations/SignContractByContractor';

import { dataURLtoFile } from '../../../shared/utils/helpers';
import { successToast } from '../../../shared/toasts';
import { JOB_STATUSES } from '../../../shared/constants';

const ContractPreviewWithSignaturePad = props => {
  const [openSignaturePad, setOpenSignaturePad] = useState(false);
  const [isSubmittingContract, setSubmittingContract] = useState(false);
  const { showContract, setShowContract, contract } = props;

  const pendingContract = contract
    ? contract.job.status === JOB_STATUSES.awaiting_signature
    : false;
  const contractPreviewPdf = contract ? contract.contractFileUrl : false;

  const handleCloseContract = () => {
    setShowContract(false);
  };

  const handleSignAndSend = signature => {
    if (signature) {
      setSubmittingContract(true);
      const signatureFile = dataURLtoFile(signature, `contractorSignature.png`);
      SignContractByContractor(contract.id, signatureFile, response => {
        setSubmittingContract(false);
        if (response && response.job) {
          handleCloseContract();
          successToast('Contract was signed!');
        }
      });
    }
  };

  const handleOpenSignaturePad = () => {
    setOpenSignaturePad(true);
  };

  const handleCloseSignaturePad = () => {
    setOpenSignaturePad(false);
  };

  return (
    <Fragment>
      <SignaturePadModal
        open={openSignaturePad}
        handleClose={handleCloseSignaturePad}
        handleInsertSignature={handleSignAndSend}
      />
      <FullScreenModal
        open={showContract}
        handleBack={handleCloseContract}
        actions={[
          {
            label: 'Download',
            handler: () => window.open(contractPreviewPdf)
          },
          ...(pendingContract
            ? [
                {
                  label: 'Sign',
                  handler: handleOpenSignaturePad,
                  isSubmitting: isSubmittingContract
                }
              ]
            : [])
        ]}
      >
        <ContractPreview contractPreviewPdf={contractPreviewPdf}></ContractPreview>
      </FullScreenModal>
    </Fragment>
  );
};

ContractPreviewWithSignaturePad.propTypes = {
  showContract: PropTypes.bool.isRequired,
  setShowContract: PropTypes.func.isRequired,
  contract: PropTypes.object.isRequired
};

export default ContractPreviewWithSignaturePad;

import React from 'react';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';

import { SnackbarContent, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

import { ReactComponent as MagicIcon } from '../../images/magic-icon.svg';
import GlobalButton from '../UI/GlobalButton';

import useToastStyles from '../../styles/common/useToastStyles';

const Toast = props => {
  const { closeToast, isError, message, buttonProps } = props;
  const classes = useToastStyles({ isError });

  const snackbarContentProps = {
    className: classes.root,
    classes: {
      message: classes.toastMessage,
      action: classes.closeButton
    },
    message: (
      <div className={classes.toastContainer}>
        <div className={classes.toastIcon}>{isError ? <ErrorIcon /> : <MagicIcon />}</div>
        <div className={classes.toastContent}>
          <p id="client-snackbar" className={classes.message}>
            {message}
          </p>
          {buttonProps &&
            (buttonProps.externalLink ? (
              <GlobalButton
                id={`toast-button-${kebabCase(buttonProps.text)}`}
                handleClick={() => {
                  window.open(buttonProps.link);
                }}
                variant="secondary"
              >
                {buttonProps.text}
              </GlobalButton>
            ) : (
              <Link to={buttonProps.link} className={classes.link}>
                <GlobalButton
                  id={`toast-button-${kebabCase(buttonProps.text)}`}
                  handleClick={closeToast}
                  variant="secondary"
                >
                  {buttonProps.text}
                </GlobalButton>
              </Link>
            ))}
        </div>
      </div>
    ),
    action: (
      <IconButton
        disableRipple
        key="close"
        aria-label="close"
        color="inherit"
        size="small"
        onClick={closeToast}
      >
        <CloseIcon className={classes.icon} />
      </IconButton>
    )
  };

  return <SnackbarContent {...snackbarContentProps} />;
};

export default Toast;

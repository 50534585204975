/**
 * @flow
 * @relayHash 343e3576c3137b0969482ce2502023eb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Header_job$ref = any;
type Tabs_job$ref = any;
export type PaymentRequestsQueryVariables = {|
  id: string
|};
export type PaymentRequestsQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: Header_job$ref & Tabs_job$ref
  |}
|};
export type PaymentRequestsQuery = {|
  variables: PaymentRequestsQueryVariables,
  response: PaymentRequestsQueryResponse,
|};
*/


/*
query PaymentRequestsQuery(
  $id: ID!
) {
  job(id: $id) {
    ...Header_job
    ...Tabs_job
    id
  }
}

fragment Header_job on JobNode {
  id
  status
  balance
  totalValue
  currencyCode
  totalAmountOfPaymentRequests
  ...CreatePaymentRequestDialog_job
}

fragment Tabs_job on JobNode {
  id
  paymentRequests(orderBy: "-created") {
    totalCount
  }
  invoices(orderBy: "-created") {
    totalCount
  }
}

fragment CreatePaymentRequestDialog_job on JobNode {
  id
  jobId
  name
  balance
  totalValue
  currencyCode
  conversionRateInPreferredCurrency
  contractor {
    id
    fullName
    representativeImageUrl
    currency
    organization {
      financeEmail
      email
      id
    }
  }
  contract {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "-created"
  }
],
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PaymentRequestsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Header_job",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "Tabs_job",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PaymentRequestsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "balance",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalValue",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currencyCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalAmountOfPaymentRequests",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "jobId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "conversionRateInPreferredCurrency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "representativeImageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currency",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "financeEmail",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contract",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "paymentRequests",
            "storageKey": "paymentRequests(orderBy:\"-created\")",
            "args": (v3/*: any*/),
            "concreteType": "PaymentRequestNodeConnection",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "invoices",
            "storageKey": "invoices(orderBy:\"-created\")",
            "args": (v3/*: any*/),
            "concreteType": "InvoiceNodeConnection",
            "plural": false,
            "selections": (v4/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PaymentRequestsQuery",
    "id": null,
    "text": "query PaymentRequestsQuery(\n  $id: ID!\n) {\n  job(id: $id) {\n    ...Header_job\n    ...Tabs_job\n    id\n  }\n}\n\nfragment Header_job on JobNode {\n  id\n  status\n  balance\n  totalValue\n  currencyCode\n  totalAmountOfPaymentRequests\n  ...CreatePaymentRequestDialog_job\n}\n\nfragment Tabs_job on JobNode {\n  id\n  paymentRequests(orderBy: \"-created\") {\n    totalCount\n  }\n  invoices(orderBy: \"-created\") {\n    totalCount\n  }\n}\n\nfragment CreatePaymentRequestDialog_job on JobNode {\n  id\n  jobId\n  name\n  balance\n  totalValue\n  currencyCode\n  conversionRateInPreferredCurrency\n  contractor {\n    id\n    fullName\n    representativeImageUrl\n    currency\n    organization {\n      financeEmail\n      email\n      id\n    }\n  }\n  contract {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '093e3e340fb5e710bacf9dafcb371d61';
module.exports = node;

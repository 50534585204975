import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import CategoryDetails from './CategoryDetails';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';

const CategoryDetailsPage = props => (
  <QueryRenderer
    query={graphql`
      query CategoryDetailsPageQuery($id: ID!) {
        category(id: $id) {
          ...CategoryDetails_category
        }
      }
    `}
    variables={{
      id: props.match.params.id
    }}
    render={relayProps => <CategoryDetails category={relayProps.category} {...props} />}
  />
);

export default CategoryDetailsPage;

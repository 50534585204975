import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';

const JobContractTabNoContract = () => {
  const classes = useDetailsStyles();
  return (
    <Fragment>
      <Typography variant="body1" className={classes.marginBottom}>
        This job uses an external contract. Please refer to the Associated Files section for details
        about the contract or to upload the contract for this job.
      </Typography>
    </Fragment>
  );
};

export default JobContractTabNoContract;

import React from 'react';
import PropTypes from 'prop-types';
import { Chip as MuiChip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import colors from '../../styles/common/colors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.lightBlueGrey,
    color: colors.mediumBlueGrey,
    fontWeight: 'bold'
  },
  deleteIcon: {
    color: theme.palette.colors.darkBlueGrey,
    width: '12px',
    height: '12px',
    marginRight: '10px'
  }
}));

const Chip = props => {
  const { id, text, onDelete, ...otherProps } = props;
  const classes = useStyles();

  const onDeleteHandler = () => {
    onDelete(id);
  };

  return (
    <MuiChip
      onDelete={onDelete ? onDeleteHandler : null}
      label={text}
      classes={{ ...classes }}
      clickable={false}
      deleteIcon={<CloseIcon />}
      {...otherProps}
    />
  );
};

PropTypes.Chip = {
  text: PropTypes.string.isRequired
};

export default Chip;

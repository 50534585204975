import React from 'react';
import styled from 'styled-components';
import { linearGradient, transparentize } from 'polished';
import merge from 'lodash/merge';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { Link } from 'react-router-dom';
import { JOB_PAYMENT_STATUS } from '../../../../shared/constants';
import CustomTableTooltip from '../../../../shared/components/table/CustomTableTooltip';
import newInvoiceIconUrl from '../../../../shared/images/new-invoice.svg';
import paymentStatusIconUrl from '../../../../shared/images/payment-status-icon.svg';

export const PaymentStatusHeader = styled(props => (
  <CustomTableTooltip
    title="Payment Status"
    PopperProps={{
      modifiers: {
        offset: {
          offset: '-20,0'
        }
      }
    }}
  >
    <img src={paymentStatusIconUrl} alt="" {...props} />
  </CustomTableTooltip>
))`
  display: block;
  height: 19px;
  margin-top: 1px;
`;

const NewInvoiceComponent = linkProps => (
  <CustomTableTooltip title="New Invoice">
    <Link {...linkProps}>
      <img src={newInvoiceIconUrl} alt="New Invoices" />
    </Link>
  </CustomTableTooltip>
);

export const NewInvocesLink = styled(NewInvoiceComponent)`
  &,
  img {
    display: block;
  }

  img {
    width: 16px;
  }
`;

export const FollowingIcon = styled(NotificationsActiveIcon)`
  display: block;
  margin-bottom: 2px;
  color: ${props => props.theme.palette.colors.darkBlueGrey};
  font-size: 20px;
`;

export const PaymentStatusIcon = styled(({ borderColor, topFill, bottomFill, ...tooltipProps }) => (
  <CustomTableTooltip {...tooltipProps} />
)).attrs(props => {
  const { status, title, theme } = props;
  let topFill;
  let bottomFill;
  if (status === JOB_PAYMENT_STATUS.partiallyRequested) {
    bottomFill = theme.palette.colors.grey;
  } else if (status === JOB_PAYMENT_STATUS.partiallySent) {
    bottomFill = theme.palette.colors.green;
  } else if (status === JOB_PAYMENT_STATUS.fullyRequested) {
    topFill = theme.palette.colors.grey;
    bottomFill = theme.palette.colors.grey;
  } else if (status === JOB_PAYMENT_STATUS.fullySent) {
    topFill = theme.palette.colors.green;
    bottomFill = theme.palette.colors.green;
  }

  return merge(
    {
      topFill: '#fff',
      bottomFill: '#fff',
      children: <div />,
      title
    },
    props,
    {
      borderColor: topFill || bottomFill || transparentize(0.5, theme.palette.colors.darkBlueGrey),
      topFill,
      bottomFill
    }
  );
})`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid ${props => props.borderColor};
  background: ${props =>
    linearGradient({
      colorStops: [`${props.topFill} 50%`, `${props.bottomFill} 50% 100%`],
      toDirection: 'to bottom'
    })};
`;

import { makeStyles } from '@material-ui/core/styles';

export const billingStyles = theme => ({
  helpIcon: {
    width: '12px',
    height: '12px',
    marginLeft: '5px'
  }
});

export const useBillingStyles = makeStyles(billingStyles);

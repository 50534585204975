import React, { useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../utils/helpers';

import Select from '../fields/Select';
import withFieldProps from '../../../hoc/withFieldProps';
import { useEffectWithStatus } from '../../../hooks/useEffectWithStatus';

export const CountrySelect = props => {
  const [options, setOptions] = useState([]);

  useEffectWithStatus(status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query CountrySelectQuery {
          general {
            countryList {
              code
              name
            }
          }
        }
      `
    ).then(response => {
      status.mounted &&
        setOptions(
          response.general.countryList.map(country => ({
            value: country.code,
            label: country.name
          }))
        );
    });
  }, []);

  return <Select options={options} {...props} helperText={props.helperText} />;
};

export const FormikCountrySelect = withFieldProps(CountrySelect);

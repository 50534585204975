import React from 'react';

import { Grid } from '@material-ui/core';

import FilterCheckbox from '../../../shared/components/filters/FilterCheckbox';
import FilterDebounceInput from '../../../shared/components/filters/FilterDebounceInput';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';

const ProductListFilters = props => {
  const {
    filterValues,
    onFilterChange,
    userContext: {
      orgStaff: { organization }
    }
  } = props;
  const navigation = JSON.parse(organization.configuration).navigation;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FilterDebounceInput
          id="filterByTitleProductList"
          name="title"
          value={filterValues['title']}
          label={`${navigation.productTitle.singular} Name`}
          onChange={onFilterChange}
          fullWidth
          placeholder={`Enter ${navigation.productTitle.singular} Name...`}
        />
      </Grid>
      <Grid item xs={6}>
        <FilterDebounceInput
          name="code"
          validateNegativeNumbers
          id="filterByCodeProductList"
          value={filterValues['code']}
          label={`${navigation.productTitle.singular} Code`}
          onChange={onFilterChange}
          fullWidth
          placeholder={`Enter ${navigation.productTitle.singular} Code...`}
        />
      </Grid>
      <Grid item xs={6}>
        <FilterCheckbox
          name="includeInactive"
          id="filterIncludeInactiveProductList"
          value={filterValues['includeInactive']}
          label={`Include Inactive ${navigation.productTitle.plural}`}
          onChange={onFilterChange}
        />
      </Grid>
    </Grid>
  );
};

export default withUserContext(ProductListFilters);

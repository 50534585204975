import React, { Fragment, useRef } from 'react';
import {
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  FormHelperText,
  MenuItem
} from '@material-ui/core';

import formStyles from '../../styles/common/formStyles';

const FormikDropdownField = ({
  children,
  field,
  form,
  disabled,
  onChange,
  noEmptyValue,
  items,
  itemType,
  notFullWidth,
  placeholder,
  ignoreTouchedValue,
  visibleToFreelancer,
  ...otherProps
}) => {
  const classes = formStyles({ notFullWidth, visibleToFreelancer });
  const isInvalid =
    form.errors[field.name] && (form.touched[field.name] || ignoreTouchedValue) ? true : false;

  const inputLabel = useRef(null);
  const { label } = otherProps;

  const dropdownOptions = () => {
    switch (itemType) {
      case 'graphQL':
        return items.edges.map((item, i) => (
          <MenuItem key={i} value={item.node}>
            {item.node.name || item.node.title || item.node.user.title}
          </MenuItem>
        ));
      case 'specifics':
        return items.map((item, i) => (
          <MenuItem key={i} value={item}>
            {item}
          </MenuItem>
        ));
      case 'timezones':
        return items.map((item, i) => (
          <MenuItem key={i} value={item}>
            {item}
          </MenuItem>
        ));
      case 'categories':
      case 'roles':
        return items.map((item, i) => (
          <MenuItem key={i} value={item.id}>
            {item.name}
          </MenuItem>
        ));
      case 'assignedStaff':
        return items.map((item, i) => (
          <MenuItem key={i} value={item.id}>
            {item.user.firstName} {item.user.lastName}
          </MenuItem>
        ));
      case 'assignedContractor':
        return items.map((item, i) => (
          <MenuItem key={i} value={item.id}>
            {item.firstName} {item.lastName}
          </MenuItem>
        ));
      case 'enumValue':
        return items.map((item, i) => (
          <MenuItem key={i} value={item.name}>
            {item.description}
          </MenuItem>
        ));
      case 'adminCategories':
        return items.map((item, i) => (
          <MenuItem key={i} value={item.name}>
            {item.name}
          </MenuItem>
        ));
      default:
        return items.map((item, i) => (
          <MenuItem key={i} value={item.id}>
            {item.title}
          </MenuItem>
        ));
    }
  };

  return (
    <Fragment>
      <FormControl variant="outlined" className={classes.dropdownWrapper}>
        <InputLabel
          {...otherProps}
          ref={inputLabel}
          htmlFor={`outlined-${field.name}-simple`}
          error={isInvalid}
        >
          {label}
        </InputLabel>
        <Select
          {...otherProps}
          disabled={disabled}
          name={field.name}
          value={field.value}
          onChange={onChange || form.handleChange}
          onBlur={form.handleBlur}
          error={isInvalid}
          displayEmpty={
            (items === null && (field.name === 'categoryType' || field.name === 'template')) ||
            field.name === 'budgetCategoryToAdd'
          } // show empty option if categoryType since empty option has instructions
          id={`dropdownField-${field.name}`}
          input={
            <OutlinedInput
              name="item"
              id={`outlined-${field.name}-simple`}
              classes={{
                root: classes.dropdownRoot
              }}
            />
          }
        >
          {items === null && (field.name === 'categoryType' || field.name === 'template') ? (
            <MenuItem
              key="0"
              value=""
              disabled
            >
              <em>Select a Category first...</em>
            </MenuItem>
          ) : !noEmptyValue ? (
            <MenuItem key="0" value="">
              <em>{placeholder || 'None'}</em>
            </MenuItem>
          ) : null}
          {items !== null && dropdownOptions(itemType)}
        </Select>
        <FormHelperText error={isInvalid}>{isInvalid && form.errors[field.name]}</FormHelperText>
      </FormControl>
    </Fragment>
  );
};

export default FormikDropdownField;

/**
 * @flow
 * @relayHash e714fe9d8474eee7ddfa344ac4457ec1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StorageClassEnum = "DEEP_ARCHIVE" | "GLACIER" | "RESTORING" | "STANDARD" | "%future added value";
export type DeleteReviewAssetInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeleteReviewAssetMutationVariables = {|
  input: DeleteReviewAssetInput
|};
export type DeleteReviewAssetMutationResponse = {|
  +deleteReviewAsset: ?{|
    +deliverable: ?{|
      +id: string,
      +reviewActive: ?{|
        +id: string,
        +deliverableReviewAssets: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileThumbnailUrl: ?string,
              +fileName: string,
              +created: any,
              +revisionNumber: ?number,
              +storageClass: ?StorageClassEnum,
              +comments: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +created: any,
                    +content: string,
                    +extraData: ?any,
                    +contentEditedAt: ?any,
                    +user: ?{|
                      +id: string,
                      +fullName: ?string,
                      +representativeImageUrl: ?string,
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type DeleteReviewAssetMutation = {|
  variables: DeleteReviewAssetMutationVariables,
  response: DeleteReviewAssetMutationResponse,
|};
*/


/*
mutation DeleteReviewAssetMutation(
  $input: DeleteReviewAssetInput!
) {
  deleteReviewAsset(input: $input) {
    deliverable {
      id
      reviewActive {
        id
        deliverableReviewAssets {
          edges {
            node {
              id
              fileThumbnailUrl
              fileName
              created
              revisionNumber
              storageClass
              comments {
                edges {
                  node {
                    id
                    created
                    content
                    extraData
                    contentEditedAt
                    user {
                      id
                      fullName
                      representativeImageUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteReviewAssetInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteReviewAsset",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteReviewAssetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "reviewActive",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableReviewNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverableReviewAssets",
                "storageKey": null,
                "args": null,
                "concreteType": "ReviewAssetNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReviewAssetNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReviewAssetNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fileThumbnailUrl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fileName",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "revisionNumber",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "storageClass",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "comments",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CommentNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommentNode",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v2/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "content",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "extraData",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "contentEditedAt",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "user",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "UserNode",
                                        "plural": false,
                                        "selections": [
                                          (v1/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "fullName",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "representativeImageUrl",
                                            "args": null,
                                            "storageKey": null
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteReviewAssetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteReviewAssetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteReviewAssetMutation",
    "id": null,
    "text": "mutation DeleteReviewAssetMutation(\n  $input: DeleteReviewAssetInput!\n) {\n  deleteReviewAsset(input: $input) {\n    deliverable {\n      id\n      reviewActive {\n        id\n        deliverableReviewAssets {\n          edges {\n            node {\n              id\n              fileThumbnailUrl\n              fileName\n              created\n              revisionNumber\n              storageClass\n              comments {\n                edges {\n                  node {\n                    id\n                    created\n                    content\n                    extraData\n                    contentEditedAt\n                    user {\n                      id\n                      fullName\n                      representativeImageUrl\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4e889d9eef66da6b22d212eb9d48a5fe';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import SearchableDropdown from '../../../../shared/components/form/SearchableDropdown';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';

const releaseQuery = graphql`
  query SearchableReleaseDropdownQuery(
    $releaseOrProductName: String
    $orderBy: String
    $first: Int
    $includeInactive: Boolean
  ) {
    releasesByOrg(
      releaseOrProductName: $releaseOrProductName
      orderBy: $orderBy
      first: $first
      includeInactive: $includeInactive
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          name
          product {
            title
          }
        }
      }
    }
  }
`;

const SearchableReleaseDropdown = props => {
  const {
    onChange,
    releaseOnly,
    userContext: {
      orgStaff: { organization }
    },
    ...otherProps
  } = props;
  const navigation = JSON.parse(organization.configuration).navigation;

  const getFetchVariables = inputValue => {
    return {
      releaseOrProductName: inputValue,
      first: 10,
      orderBy: '-created',
      includeInactive: false
    };
  };

  const formatOptions = releases => {
    if (!releases || !releases.releasesByOrg) {
      return [];
    }

    return releases.releasesByOrg.edges.map(release => {
      return {
        value: release.node.id,
        label: releaseOnly
          ? release.node.name
          : `${release.node.product.title} (${release.node.name})`
      };
    });
  };

  return (
    <SearchableDropdown
      resultKey="releasesByOrg"
      query={releaseQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      label={
        releaseOnly
          ? navigation.releaseTitle.singular
          : `${navigation.productTitle.singular} (${navigation.releaseTitle.singular})`
      }
      placeholder={
        releaseOnly
          ? `Select ${navigation.releaseTitle.singular}...`
          : `Enter ${navigation.productTitle.singular} or ${navigation.releaseTitle.singular} Name`
      }
      value={props.value}
      {...otherProps}
    />
  );
};

export default withUserContext(SearchableReleaseDropdown);

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../../../shared/relay/QueryRenderer';
import PaymentRequestQuickViewContent from './PaymentRequestQuickViewContent';

const PaymentRequestQuickView = ({ selectedId, ...props }) => (
  <QueryRenderer
    query={graphql`
      query PaymentRequestQuickViewQuery($id: ID!) {
        paymentRequest(id: $id) {
          ...PaymentRequestQuickViewContent_paymentRequest
        }
        __type(name: "PaymentRequestStatus") {
          ...PaymentRequestQuickViewContent_statusTypes
        }
      }
    `}
    variables={{ id: selectedId }}
    render={relayProps => (
      <PaymentRequestQuickViewContent
        paymentRequest={relayProps.paymentRequest}
        statusTypes={relayProps.__type}
        {...relayProps}
        {...props}
      />
    )}
  />
);

export default PaymentRequestQuickView;

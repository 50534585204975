import React from 'react';
import { RichTextFormattedValue } from '../../richText';
import SquareButton from '../../UI/SquareButton';
import BaseCommentCard, {
  CommentPreview,
  CommentEditedLabel,
  ReactionsGroup
} from '../review/CommentCard';
import ReactionCard from '../review/ReactionCard';
import ToggleCommentReactionMutation from '../../../../organization/components/deliverables/reactions/ToggleCommentReactionMutation';
import AddCommentReactionButton from '../../../../organization/components/deliverables/reactions/AddCommentReactionButton';

const CommentCard = ({ comment, onSelect, canAddReaction }) => {
  const actions = [];

  if (comment.annotationRef) {
    actions.push(
      <SquareButton
        onClick={e => {
          e.stopPropagation();
          onSelect();
        }}
        variant="secondary"
        icon="annotation"
        size={24}
      />
    );
  }

  if (canAddReaction) {
    actions.push(<AddCommentReactionButton comment={comment} />);
  }

  return (
    <BaseCommentCard
      authorName={comment.fullName}
      authorAvatar={comment.avatarSrc}
      date={comment.dateCreated}
      actions={actions}
      withHover
      withAttachments={comment.referenceFiles.length > 0}
      onClick={onSelect}
    >
      <CommentPreview>
        <RichTextFormattedValue value={comment.content} plain />
      </CommentPreview>
      {comment.wasCommentEdited && <CommentEditedLabel />}
      {comment.reactions && comment.reactions.length > 0 && (
        <ReactionsGroup>
          {comment.reactions.map(reaction => (
            <ReactionCard
              key={reaction.id}
              reaction={reaction}
              onDelete={() => {
                ToggleCommentReactionMutation({
                  reactionId: reaction.definitionId,
                  commentId: comment.commentId
                });
              }}
            />
          ))}
        </ReactionsGroup>
      )}
    </BaseCommentCard>
  );
};

export default CommentCard;

import React, { Fragment } from 'react';
import { Grid, styled, Box, Typography, Link } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import * as Yup from 'yup';

import Dialog from '../../../../shared/components/common/Dialog';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import { yupSchemas } from '../../../../shared/validations';
import FormikEditableImage from '../../../../shared/components/form/formikFields/FormikEditableImage';
import EditableField from '../../../../shared/components/form/fields/EditableField';
import EditToggle from '../../../../shared/components/UI/EditToggle';
import FormikPhoneField from '../../../../shared/components/form/FormikPhoneField';
import Detail from '../../../../shared/components/UI/Detail';
import useDialog from '../../../../shared/hooks/useDialog';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import { FormikCountrySelect } from '../../../../shared/components/form/dropdowns/CountrySelect';
import UpdateEmailForm from '../UpdateEmailForm';
import UpdateFreelancerProfileMutation from '../mutations/UpdateFreelancerProfileMutation';
import FormikDropdownField from '../../../../shared/components/form/FormikDropdownField';

const StyledAvatarBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(3),
  top: 0,
  transform: 'translateY(-50%)'
}));

const AccountInformationContent = props => {
  const {
    accountInformation: {
      currentUser: {
        firstName,
        lastName,
        fullName,
        email,
        representativeImageUrl,
        freelancer: {
          nickname,
          coverImageUrl,
          companyName,
          title,
          phone,
          timezone,
          address1,
          address2,
          country,
          state,
          city,
          zipcode,
          additionalInfo,
          portfolioUrl
        }
      }
    },
    timezones: { timezoneList },
    userContext: { setUserInfo },

    isEditing,
    toggleEditHandler
  } = props;

  const avatarAlt = `${fullName} picture`;
  const [isDialogOpen, toggleDialogOpen] = useDialog();

  const toggleEditEmailHandler = () => {
    toggleDialogOpen();
  };

  const submitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);

    const {
      firstName,
      lastName,
      nickname,
      title,
      phone,
      companyName,
      timezone,
      address1,
      address2,
      city,
      state,
      country,
      zipcode,
      reprImage,
      reprImageNull,
      coverImage,
      coverImageNull,
      additionalInfo,
      portfolioUrl
    } = values;

    UpdateFreelancerProfileMutation(
      {
        firstName,
        lastName,
        nickname,
        title,
        phone,
        companyName,
        timezone,
        address1,
        address2,
        city,
        state,
        country: country ? country.value : '',
        zipcode,
        reprImageNull,
        coverImageNull,
        additionalInfo,
        portfolioUrl
      },
      reprImage.file,
      coverImage.file,
      response => {
        setSubmitting(false);
        if (response && response.updatedFreelancerProfile) {
          const { representativeImageUrl, firstName, lastName } = response.updatedFreelancerProfile;
          setUserInfo({
            ...props.userContext,
            representativeImageUrl,
            firstName,
            lastName
          });
          toggleEditHandler();
        }
      }
    );
  };

  const initialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    nickname: nickname ? nickname : '',
    title: title || '',
    timezone: timezone ? timezone : '',
    email: email ? email : '',
    phone: phone ? phone : '',
    companyName: companyName ? companyName : '',
    address1: address1 ? address1 : '',
    address2: address2 ? address2 : '',
    state: state ? state : '',
    city: city ? city : '',
    country: country
      ? {
          value: country.code,
          label: country.name
        }
      : null,
    zipcode: zipcode ? zipcode : '',
    additionalInfo: additionalInfo ? additionalInfo : '',
    reprImageNull: false,
    reprImage: {
      src: representativeImageUrl,
      file: null
    },
    coverImageNull: false,
    coverImage: {
      src: coverImageUrl,
      file: null
    },
    portfolioUrl: portfolioUrl || ''
  };

  const validationSchema = Yup.object().shape({
    firstName: yupSchemas.string(true, { max: 50 }),
    lastName: yupSchemas.string(true, { max: 50 }),
    nickname: yupSchemas.string(false, { max: 50 }),
    title: yupSchemas.string(),
    email: yupSchemas.email(true),
    phone: yupSchemas.phone(),
    companyName: yupSchemas.string(false, { max: 100 }),
    address1: yupSchemas.string(),
    address2: yupSchemas.string(),
    city: yupSchemas.string(),
    country: yupSchemas.country(),
    zipcode: yupSchemas.string(false, { max: 10 }),
    reprImage: yupSchemas.shape({
      file: yupSchemas.avatarFileSize
    }),
    coverImage: yupSchemas.shape({
      file: yupSchemas.backgroundFileSize
    }),
    portfolioUrl: yupSchemas.url()
  });

  return (
    <Fragment>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitHandler}
      >
        {({ values, isSubmitting, resetForm }) => (
          <Form>
            <Field
              name="coverImage"
              nameNull="coverImageNull"
              editing={isEditing}
              component={FormikEditableImage}
              variant="background"
              imageProps={{ alt: 'Background image' }}
              uploadButtonId="uploadBackgroundFreelancerProfile"
              deleteButtonId="deleteBackgroundFreelancerProfile"
            />
            <Box py={3} pl="200px" mb={5} position="relative" display="flex">
              <StyledAvatarBox>
                <Field
                  name="reprImage"
                  nameNull="reprImageNull"
                  editing={isEditing}
                  component={FormikEditableImage}
                  imageProps={{ alt: avatarAlt }}
                  uploadButtonId="uploadImageFreelancerProfile"
                  deleteButtonId="deleteImageFreelancerProfile"
                />
              </StyledAvatarBox>
              <EditToggle
                isEditing={isEditing}
                isSubmitting={isSubmitting}
                editProps={{
                  id: 'editFreelancerProfile',
                  onClick: toggleEditHandler,
                  buttonCopy: 'Edit Account Information',
                  edit: false
                }}
                saveProps={{
                  id: 'saveFreelancerProfile',
                  loading: isSubmitting
                }}
                cancelProps={{
                  id: 'cancelFreelancerProfile',
                  onClick: () => {
                    resetForm();
                    toggleEditHandler();
                  }
                }}
              />
              {!isEditing && (
                <GlobalButton id="editFreelancerEmail" handleClick={toggleEditEmailHandler}>
                  Change Email
                </GlobalButton>
              )}
            </Box>
            <Grid container spacing={3}>
              <Grid item md={9}>
                <DetailsSection title="Account Info">
                  <Grid container spacing={1}>
                    {[
                      {
                        name: 'firstName',
                        label: 'First Name'
                      },
                      {
                        name: 'lastName',
                        label: 'Last Name'
                      },
                      {
                        name: 'nickname',
                        label: 'Nickname'
                      },
                      {
                        name: 'phone',
                        label: 'Phone Number',
                        component: FormikPhoneField
                      },
                      {
                        name: 'email',
                        label: 'Email',
                        fieldProps: { disabled: true }
                      },
                      { name: 'portfolioUrl', label: 'Portfolio' },
                      { name: 'companyName', label: 'Company Name' },

                      {
                        name: 'title',
                        label: 'Title'
                      },
                      {
                        name: 'timezone',
                        label: 'Timezone',
                        component: FormikDropdownField,
                        fieldProps: {
                          itemType: 'timezones',
                          items: timezoneList
                        }
                      }
                    ].map(field => (
                      <EditableField
                        key={field.name}
                        editing={isEditing}
                        formikValues={values}
                        gridItemProps={{ xs: 3 }}
                        renderDetail={value => (
                          <Detail
                            name={field.label}
                            value={
                              field.name === 'portfolioUrl' && value ? (
                                <Link
                                  href={value.includes('://') ? value : 'http://' + value}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  variant="subtitle2"
                                >
                                  {value}
                                </Link>
                              ) : (
                                value || '-'
                              )
                            }
                            flexDirection="column"
                            alignItems="left"
                          />
                        )}
                        {...field}
                        fieldProps={{
                          ...(field.fieldProps || {}),
                          ...(field.name !== 'timezone' && { fullWidth: true })
                        }}
                      />
                    ))}
                  </Grid>
                </DetailsSection>
              </Grid>
            </Grid>
            <Grid item md={9}>
              <DetailsSection title="Address Info">
                <Grid container spacing={1}>
                  {[
                    { name: 'address1', label: 'Address' },
                    { name: 'address2', label: 'Address 2' },
                    { name: 'city', label: 'City' },
                    { name: 'zipcode', label: 'Zip/Postal Code' },
                    { name: 'state', label: 'State/Province' },
                    {
                      name: 'country',
                      label: 'Country',
                      component: FormikCountrySelect,
                      getContent: value => (value ? value.label : '')
                    }
                  ].map(field => (
                    <EditableField
                      key={field.name}
                      editing={isEditing}
                      formikValues={values}
                      gridItemProps={{ xs: 4 }}
                      renderDetail={value => (
                        <Detail
                          name={field.label}
                          value={(field.name === 'country' && value ? value.label : value) || '-'}
                          flexDirection="column"
                          alignItems="left"
                        />
                      )}
                      {...field}
                      fieldProps={{
                        fullWidth: true
                      }}
                    />
                  ))}
                </Grid>
              </DetailsSection>
            </Grid>
            <Grid item md={9}>
              <DetailsSection title="Additional Information">
                <EditableField
                  name="additionalInfo"
                  editing={isEditing}
                  label="Additional Info"
                  formikValues={values}
                  renderDetail={value => <Typography>{value || '-'}</Typography>}
                  fieldProps={{
                    fullWidth: true,
                    multiline: true,
                    rows: '10'
                  }}
                />
              </DetailsSection>
            </Grid>
          </Form>
        )}
      </Formik>
      <Dialog
        isDialogOpen={isDialogOpen}
        closeDialog={toggleDialogOpen}
        title="Enter New Email Address"
        minWidth={400}
      >
        <UpdateEmailForm handleClose={toggleDialogOpen} />
      </Dialog>
    </Fragment>
  );
};

export default withUserContext(
  createFragmentContainer(AccountInformationContent, {
    accountInformation: graphql`
      fragment AccountInformationContent_accountInformation on Query {
        currentUser {
          firstName
          lastName
          fullName
          email
          representativeImageUrl
          freelancer {
            id
            coverImageUrl
            companyName
            nickname
            title
            phone
            timezone
            address1
            address2
            country {
              name
              code
            }
            state
            city
            zipcode
            additionalInfo
            portfolioUrl
          }
        }
      }
    `,
    timezones: graphql`
      fragment AccountInformationContent_timezones on General {
        timezoneList
      }
    `
  })
);

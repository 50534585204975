import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFilterStyles } from '../../../../../shared/styles/common/filterStyles';
import FilterSelect from '../../../../../shared/components/filters/FilterSelect';
import FilterDebounceInput from '../../../../../shared/components/filters/FilterDebounceInput';
import SearchableReleaseDropdown from '../../../shared/dropdowns/SearchableReleaseDropdown';

const enumToSelectOptions = options =>
  options.map(option => ({
    value: option.name,
    name: option.description
  }));

const Filters = props => {
  const { onFilterChange, filterValues, storageClasses, deliverableStates } = props;
  const classes = useFilterStyles();

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          name="fileName"
          value={filterValues.fileName}
          label="File Name"
          onChange={onFilterChange}
          fullWidth
          placeholder="Enter File Name..."
        />
      </Grid>
      <Grid item xs={2}>
        <FilterDebounceInput
          name="deliverableTitle"
          value={filterValues.deliverableTitle}
          label="Deliverable Name"
          onChange={onFilterChange}
          fullWidth
          placeholder="Enter Deliverable Name..."
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableReleaseDropdown
          name="releaseId"
          onChange={value => onFilterChange('releaseId', value)}
          value={filterValues.releaseId}
          releaseOnly
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          name="deliverableState"
          onChange={onFilterChange}
          value={filterValues.deliverableState}
          label="Deliverable State"
          options={enumToSelectOptions(deliverableStates)}
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          name="storageClass"
          onChange={onFilterChange}
          value={filterValues.storageClass}
          label="Storage Status"
          options={enumToSelectOptions(storageClasses)}
        />
      </Grid>
    </Grid>
  );
};

Filters.propTypes = {
  onFilterChange: PropTypes.func,
  filterValues: PropTypes.object,
  deliverableStates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    })
  ),
  storageClasses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    })
  )
};

export default Filters;

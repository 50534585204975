/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrganizationSubscriptionInterval = "ANNUAL" | "MONTHLY" | "%future added value";
export type OrganizationSubscriptionTier = "BUSINESS" | "ENTERPRISE" | "ENTREPRENEUR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BillingPlan_billing$ref: FragmentReference;
declare export opaque type BillingPlan_billing$fragmentType: BillingPlan_billing$ref;
export type BillingPlan_billing = {|
  +subscriptionTiers: ?$ReadOnlyArray<?{|
    +name: ?string,
    +numberOfUsers: ?string,
    +activeStorage: ?number,
    +archiveStorage: ?number,
    +activeDeliverables: ?string,
    +activeContractors: ?string,
    +activeContractTemplates: ?string,
    +moduleAccess: ?boolean,
    +monthlyPrice: ?string,
    +annualPrice: ?string,
  |}>,
  +organization: ?{|
    +subscription: ?{|
      +tierName: ?string,
      +interval: ?string,
      +nextBillingDate: ?any,
      +nextBillingAmount: ?string,
      +numberOfModules: ?number,
      +numberOfActiveUsers: ?number,
      +numberOfTotalUsers: ?number,
      +numberOfDeliverables: ?number,
      +numberOfContractors: ?number,
      +numberOfContractTemplates: ?number,
    |},
    +scheduledSubscription: ?{|
      +interval: OrganizationSubscriptionInterval,
      +activeModulesCount: ?number,
      +availableModules: ?$ReadOnlyArray<?{|
        +moduleId: ?string,
        +quantity: ?number,
      |}>,
    |},
    +activeSubscription: ?{|
      +tier: OrganizationSubscriptionTier,
      +interval: OrganizationSubscriptionInterval,
      +availableModules: ?$ReadOnlyArray<?{|
        +moduleId: ?string,
        +name: ?string,
        +incremental: ?boolean,
        +quantity: ?number,
        +cost: ?$ReadOnlyArray<?{|
          +tier: ?string,
          +price: ?number,
        |}>,
        +blurbText: ?string,
        +description: ?string,
        +imageUrl: ?string,
        +headline: ?string,
        +externalUrl: ?string,
      |}>,
    |},
    +latestActiveMetricsData: ?{|
      +total: ?number
    |},
    +latestArchiveMetricsData: ?{|
      +total: ?number
    |},
    +activeStorageLimit: ?number,
    +archiveStorageLimit: ?number,
  |},
  +$refType: BillingPlan_billing$ref,
|};
export type BillingPlan_billing$data = BillingPlan_billing;
export type BillingPlan_billing$key = {
  +$data?: BillingPlan_billing$data,
  +$fragmentRefs: BillingPlan_billing$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "interval",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "moduleId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tier",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "total",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "BillingPlan_billing",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscriptionTiers",
      "storageKey": null,
      "args": null,
      "concreteType": "OrganizationTierInfoNode",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "numberOfUsers",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "activeStorage",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "archiveStorage",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "activeDeliverables",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "activeContractors",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "activeContractTemplates",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "moduleAccess",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "monthlyPrice",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "annualPrice",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "organization",
      "storageKey": null,
      "args": null,
      "concreteType": "OrganizationNode",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "subscription",
          "storageKey": null,
          "args": null,
          "concreteType": "OrganizationSubscription",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "tierName",
              "args": null,
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "nextBillingDate",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "nextBillingAmount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numberOfModules",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numberOfActiveUsers",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numberOfTotalUsers",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numberOfDeliverables",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numberOfContractors",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numberOfContractTemplates",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "scheduledSubscription",
          "storageKey": null,
          "args": null,
          "concreteType": "OrganizationSubscriptionNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "activeModulesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "availableModules",
              "storageKey": null,
              "args": null,
              "concreteType": "SubscriptionModuleNode",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "activeSubscription",
          "storageKey": null,
          "args": null,
          "concreteType": "OrganizationSubscriptionNode",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "availableModules",
              "storageKey": null,
              "args": null,
              "concreteType": "SubscriptionModuleNode",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "incremental",
                  "args": null,
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "cost",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SubscriptionModuleCostNode",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "price",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "blurbText",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "imageUrl",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "headline",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "externalUrl",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "latestActiveMetricsData",
          "storageKey": null,
          "args": null,
          "concreteType": "MetricsData",
          "plural": false,
          "selections": (v5/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "latestArchiveMetricsData",
          "storageKey": null,
          "args": null,
          "concreteType": "MetricsData",
          "plural": false,
          "selections": (v5/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "activeStorageLimit",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "archiveStorageLimit",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae8e66ef993938cbc4e0e06e6a8ec591';
module.exports = node;

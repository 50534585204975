import { TOOLS } from './contants';
import { resetTool } from './commons';
import { getObjectsByRef } from './SessionManager';
import { setEditMode } from '../CanvasData';
import {
  updateStrokeWhileScaling,
  annotationResizeHandler,
  updateRelativeAttributes,
  setAnnotationColor,
  getAnnotationColor
} from '../CanvasData';

const getActiveObjectColor = canvas => {
  const activeObject = canvas.getActiveObject();
  return getAnnotationColor(activeObject);
};

const toolSelect = (
  canvas,
  { sessionId, dimensions, annotationColor, onActiveAnnotationColor }
) => {
  resetTool(canvas, TOOLS.defaultTool.name);

  const objects = getObjectsByRef(canvas, sessionId);

  objects.forEach(object => {
    object.hoverCursor = 'pointer';
    setEditMode(object);

    const { width, height } = dimensions;
    updateRelativeAttributes(object, width, height);
  });

  const activeObject = canvas.getActiveObject();
  setAnnotationColor(activeObject, annotationColor);

  canvas.renderAll();

  canvas.on('object:scaling', updateStrokeWhileScaling);
  canvas.on('object:scaled', annotationResizeHandler);

  canvas.on('selection:created', () => onActiveAnnotationColor(getActiveObjectColor(canvas)));
  canvas.on('selection:updated', () => onActiveAnnotationColor(getActiveObjectColor(canvas)));
};
export { toolSelect };

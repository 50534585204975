import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

/**
 *
 * @param {string} id - ID of the review asset
 * @param {*} callback - Callback that receives 'response' and 'errors' as arguments
 */

const DeleteReviewAssetMutation = (id, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation DeleteReviewAssetMutation($input: DeleteReviewAssetInput!) {
        deleteReviewAsset(input: $input) {
          deliverable {
            id
            reviewActive {
              id
              deliverableReviewAssets {
                edges {
                  node {
                    id
                    fileThumbnailUrl
                    fileName
                    created
                    revisionNumber
                    storageClass
                    comments {
                      edges {
                        node {
                          id
                          created
                          content
                          extraData
                          contentEditedAt
                          user {
                            id
                            fullName
                            representativeImageUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: { id }
    },
    onCompleted: callback
  });
};

DeleteReviewAssetMutation.propTypes = {
  id: PropTypes.string.isRequired
};

export default DeleteReviewAssetMutation;

import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    margin: '0 -12px'
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      opacity: 0.7,
      height: '24px',
      margin: '0 12px',
      '& .MuiButton-label': {
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        fontWeight: 'bold'
      },
      '& .MuiTouchRipple-root': {
        width: '100%',
        height: 'inherit'
      }
    },
    '& .MuiIcon-root': {
      fontSize: '12px',
      color: '#B3B7C6'
    }
  }
}));

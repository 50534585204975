import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const categoryQuery = graphql`
  query SearchableDeliverableCategoryDropdownQuery($name: String, $orderBy: String, $first: Int) {
    categoryByOrg(name: $name, orderBy: $orderBy, first: $first) {
      edgeCount
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const SearchableDeliverableCategoryDropdown = props => {
  const getFetchVariables = inputValue => {
    return {
      name: inputValue,
      first: 10,
      orderBy: 'name'
    };
  };

  const formatOptions = categories => {
    if (!categories || !categories.categoryByOrg) {
      return [];
    }

    return categories.categoryByOrg.edges.map(category => {
      return {
        value: category.node.id,
        label: category.node.name
      };
    });
  };

  const { onChange, ...otherProps } = props;
  return (
    <SearchableDropdown
      query={categoryQuery}
      resultKey="categoryByOrg"
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      {...otherProps}
    />
  );
};

export default SearchableDeliverableCategoryDropdown;

/**
 * @flow
 * @relayHash 6d416d3063bd0a31387c7fe1fef2e695
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ArchiveBatchDeliverableFinalFilesInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type BatchArchiveDeliverableFinalFilesMutationVariables = {|
  input: ArchiveBatchDeliverableFinalFilesInput
|};
export type BatchArchiveDeliverableFinalFilesMutationResponse = {|
  +archiveBatchDeliverableFinalFiles: ?{|
    +isArchived: ?boolean,
    +organization: ?{|
      +storage: ?{|
        +activeStorageFinalFiles: ?number,
        +archivedStorageFinalFiles: ?number,
      |}
    |},
  |}
|};
export type BatchArchiveDeliverableFinalFilesMutation = {|
  variables: BatchArchiveDeliverableFinalFilesMutationVariables,
  response: BatchArchiveDeliverableFinalFilesMutationResponse,
|};
*/


/*
mutation BatchArchiveDeliverableFinalFilesMutation(
  $input: ArchiveBatchDeliverableFinalFilesInput!
) {
  archiveBatchDeliverableFinalFiles(input: $input) {
    isArchived
    organization {
      storage {
        activeStorageFinalFiles
        archivedStorageFinalFiles
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ArchiveBatchDeliverableFinalFilesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isArchived",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "storage",
  "storageKey": null,
  "args": null,
  "concreteType": "StorageNode",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "activeStorageFinalFiles",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "archivedStorageFinalFiles",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BatchArchiveDeliverableFinalFilesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "archiveBatchDeliverableFinalFiles",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveBatchDeliverableFinalFilesPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BatchArchiveDeliverableFinalFilesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "archiveBatchDeliverableFinalFiles",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveBatchDeliverableFinalFilesPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "BatchArchiveDeliverableFinalFilesMutation",
    "id": null,
    "text": "mutation BatchArchiveDeliverableFinalFilesMutation(\n  $input: ArchiveBatchDeliverableFinalFilesInput!\n) {\n  archiveBatchDeliverableFinalFiles(input: $input) {\n    isArchived\n    organization {\n      storage {\n        activeStorageFinalFiles\n        archivedStorageFinalFiles\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a744cbafe5b4c4f8d01cf96e22ff301c';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';
import SquareButton from '../UI/SquareButton';

const TableCellLinkWithIcon = ({
  iconName,
  handleClick,
  truncate,
  classes,
  tooltip,
  ...otherProps
}) => {
  const squareButtonVariables = {
    icon: iconName,
    variant: 'transparent',
    tooltipTitle: tooltip
  };

  return (
    <StyledTableCell noWrap>
      <SquareButton {...squareButtonVariables} onClick={handleClick} />
    </StyledTableCell>
  );
};

TableCellLinkWithIcon.propTypes = {
  handleClick: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
  truncate: PropTypes.bool,
  tooltip: PropTypes.string
};

export default TableCellLinkWithIcon;

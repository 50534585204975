import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import get from 'lodash/get';
import withCurrentUser from '../../../../../shared/contexts/userContext/withUserContext';
import DeliverableReferencesContent from '../../../../../shared/components/deliverable/DeliverableReferencesContent';

const DeliverableReferencesList = props => {
  const {
    deliverable,
    relay: { refetch },
    userContext: { id: currentUserId }
  } = props;

  // NOTE:
  // before 1.24, back-end wasn't tracking user_id for reference files
  const canEditOrDeleteNode = node => get(node.user, 'id') === currentUserId;

  return (
    <DeliverableReferencesContent
      deliverable={deliverable}
      refetch={refetch}
      canEditItem={canEditOrDeleteNode}
      canDeleteItem={canEditOrDeleteNode}
    />
  );
};

export default createRefetchContainer(
  withCurrentUser(DeliverableReferencesList),
  {
    deliverable: graphql`
      fragment DeliverableReferencesList_deliverable on DeliverableNode
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        orderBy: { type: "String", defaultValue: "-created" }
      ) {
        id
        references(first: $first, after: $after, orderBy: $orderBy) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
          edges {
            node {
              id
              fileUrl
              title
              description
              reviewInterface
              user {
                id
              }
            }
          }
        }
      }
    `
  },
  graphql`
    query DeliverableReferencesListRefetchQuery(
      $id: ID!
      $first: Int
      $after: String
      $orderBy: String
    ) {
      deliverable(id: $id) {
        ...DeliverableReferencesList_deliverable
          @arguments(first: $first, after: $after, orderBy: $orderBy)
      }
    }
  `
);

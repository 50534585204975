import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../relay/QueryRenderer';
import AnnotationView from './AnnotationView';

const AnnotationViewQueryRenderer = props => {
  return (
    <QueryRenderer
      query={graphql`
        query AnnotationViewQueryRendererQuery($reviewAssetId: ID!) {
          reviewAsset(id: $reviewAssetId) {
            ...AnnotationView_reviewAsset
          }
        }
      `}
      variables={{
        reviewAssetId: props.assetId
      }}
      render={relayProps => <AnnotationView {...relayProps} {...props} />}
    />
  );
};

export default AnnotationViewQueryRenderer;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const contractorQuery = graphql`
  query SolicitationSearchableContractorDropdownQuery(
    $first: Int
    $contractorName: String
    $excludeInSolicitation: String
  ) {
    contractorsByOrg(
      first: $first
      orderBy: "fullName"
      contractorName: $contractorName
      excludeInSolicitation: $excludeInSolicitation
      excludeWithConflicts: true
      includeInactive: false
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          fullName
          lastName
          representativeImageUrl
        }
      }
    }
  }
`;
const SolicitationSearchableContractorDropdown = props => {
  const { solicitationId, contractorsInList, onChange } = props;

  const getFetchVariables = inputValue => {
    return {
      contractorName: inputValue,
      first: 10 + contractorsInList.length,
      excludeInSolicitation: solicitationId
    };
  };

  const formatOptions = contractors =>
    contractors && contractors.contractorsByOrg
      ? contractors.contractorsByOrg.edges.map(contractor => {
          return { value: contractor.node.id, label: contractor.node.fullName, ...contractor.node };
        })
      : [];

  return (
    <SearchableDropdown
      itemsInList={contractorsInList}
      keyToReload={`${contractorsInList.length}`}
      resultKey="contractorsByOrg"
      query={contractorQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      name="solicitationContractors"
      value={[]}
      placeholder="Add contractors"
    />
  );
};

export default SolicitationSearchableContractorDropdown;

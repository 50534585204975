import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

/**
 *
 * @param {Object} variables - Variables passed to the mutation
 * @param {string} variables.deliverable - ID of the deliverable review
 * @param {*} file - ???
 * @param {*} callback - Callback that receives 'response' and 'errors' as arguments
 */

const CreateReviewAssetMutation = (variables, file, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateReviewAssetMutation($input: CreateReviewAssetInput!) {
        createReviewAsset(input: $input) {
          deliverableReview {
            id
            deliverable {
              id
              reviewStep
              reviewActive {
                id
                step {
                  id
                  name
                }
                reviewStepList {
                  id
                  name
                }
              }
            }
            deliverableReviewAssets {
              edges {
                node {
                  id
                  fileThumbnailUrl
                  fileName
                  created
                  revisionNumber
                  reviewInterface
                  storageClass
                  comments {
                    edges {
                      node {
                        id
                        created
                        content
                        extraData
                        objectId
                        contentEditedAt
                        user {
                          id
                          fullName
                          representativeImageUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    uploadables: {
      review_asset_file: file
    },
    onCompleted: callback
  });
};

CreateReviewAssetMutation.propTypes = {
  file: PropTypes.object.isRequired,
  variables: PropTypes.shape({
    deliverable: PropTypes.string.isRequired,
    stagedPath: PropTypes.string,
    fileName: PropTypes.string
  })
};

export default CreateReviewAssetMutation;

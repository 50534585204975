import React, { Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import Typography from '@material-ui/core/Typography';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';
import { IC_TARGETS } from '../../../../../shared/services/intercomService';
import GreyBar from './GreyBar';

const InfoSection = props => {
  const {
    solicitation: { message, dateSent, replyByDate },
    classes
  } = props;

  return (
    <Fragment>
      <DetailsSection title="JobOpp Inquiry Message" data-intercom-target={IC_TARGETS.solicitationsMessage} >
        <Typography variant="body1" className={classes.marginBottom}>
          {message}
        </Typography>
      </DetailsSection>
      <div className={classes.detailsContentNoTitle}>
        <DetailsSection>
          <DetailsRowDate title="Date Received" date={dateSent} />
          <DetailsRowDate title="Reply by Date" date={replyByDate} />
        </DetailsSection>
      </div>
      <GreyBar solicitation={props.solicitation} data-intercom-target={IC_TARGETS.solicitationContact} />
    </Fragment>
  );
};

export default createFragmentContainer(InfoSection, {
  solicitation: graphql`
    fragment InfoSection_solicitation on SolicitationNode {
      message
      dateSent
      replyByDate
      ...GreyBar_solicitation
    }
  `
});

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import ContractorDeliverablesContent from './ContractorDeliverablesContent';

const ContractorDeliverablesTab = props => {
  const { id } = useParams();
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ContractorDeliverablesTabQuery(
          $id: ID!
          $first: Int
          $after: String
          $orderBy: String
          $dueDateFrom: Date
          $dueDateTo: Date
          $category: ID
          $state: String
          $title: String
          $tags: String
          $staffCoordinator: String
          $assignedToStaffOrContractor: String
          $includeClosed: Boolean
          $includeCanceled: Boolean
          $includeInactive: Boolean
          $releaseId: String
          $productId: String
          $categoryType: ID
        ) {
          contractor(id: $id) {
            ...ContractorDeliverablesTopSection_contractor
            ...ContractorDeliverablesList_contractor
          }
          deliverableState: __type(name: "DeliverableState") {
            ...ContractorDeliverablesList_stateTypes
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => <ContractorDeliverablesContent relayProps={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(ContractorDeliverablesTab, 'orgContrDeliverables', {
  orderBy: 'dueDate',
  initialFilters: {
    title: '',
    tags: '',
    releaseId: null,
    productId: null,
    category: null,
    categoryType: null,
    state: '',
    staffCoordinator: null,
    assignedToStaffOrCoordinator: null,
    dueDateFrom: null,
    dueDateTo: null,
    includeInactive: false,
    includeClosed: false,
    includeCanceled: false,
    onlyFollowed: false
  }
});

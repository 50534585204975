import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';

import FlagIndicator from '../UI/FlagIndicator';

import { useTableStyles } from '../../styles/common/tableStyles';

const TableCellFlag = props => {
  const { doNotHire, deactivated, hasConflictingContractor } = props;
  const classes = useTableStyles();
  return (
    <StyledTableCell className={classes.tableCellFlags}>
      <FlagIndicator doNotHire={doNotHire} deactivated={deactivated} hasConflictingContractor={hasConflictingContractor} />
    </StyledTableCell>
  );
};

TableCellFlag.propTypes = {
  doNotHire: PropTypes.bool,
  deactivated: PropTypes.bool,
  hasConflictingContractor: PropTypes.bool
};

export default TableCellFlag;

/**
 * @flow
 * @relayHash 68e45c8d3e8068198ca46058ea5923a1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteReleaseInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type DeleteReleaseMutationVariables = {|
  input: DeleteReleaseInput
|};
export type DeleteReleaseMutationResponse = {|
  +deleteRelease: ?{|
    +isDeleted: ?boolean
  |}
|};
export type DeleteReleaseMutation = {|
  variables: DeleteReleaseMutationVariables,
  response: DeleteReleaseMutationResponse,
|};
*/


/*
mutation DeleteReleaseMutation(
  $input: DeleteReleaseInput!
) {
  deleteRelease(input: $input) {
    isDeleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteReleaseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteRelease",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteReleasePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isDeleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteReleaseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteReleaseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteReleaseMutation",
    "id": null,
    "text": "mutation DeleteReleaseMutation(\n  $input: DeleteReleaseInput!\n) {\n  deleteRelease(input: $input) {\n    isDeleted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ee230c487a1f301bf05ba92bf3e2685';
module.exports = node;

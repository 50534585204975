import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Typography, Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { computePermissionRole } from '../../../../../../../shared/utils/helpers';
import Detail from '../../../../../../../shared/components/UI/Detail';
import Date from '../../../../../../../shared/components/common/Date';
import Amount from '../../../../../../../shared/components/common/Amount';
import StatusIndicator from '../../../../../../../shared/components/UI/StatusIndicator';
import AvatarWithName from '../../../../../../../shared/components/UI/AvatarWithName';
import ActionButton from '../../../../../../../shared/components/UI/ActionButton';
import { successToast, errorToast } from '../../../../../../../shared/toasts';
import Dialog from '../../../../../../../shared/components/common/Dialog';
import useDialog from '../../../../../../../shared/hooks/useDialog';
import { PAYMENT_REQUESTS_STATES, ALLOWED_ACTIONS } from '../../../../../../../shared/constants';
import withUserContext from '../../../../../../../shared/contexts/userContext/withUserContext';
import colors from '../../../../../../../shared/styles/common/colors';
import ResendPaymentRequestMutation from '../../../../mutations/ResendPaymentRequestMutation';
import DeclinePaymentRequestDialog from './DeclinePaymentRequestDialog';
import MarkPaymentSentDialog from './MarkPaymentSentDialog';

const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  margin: '0 auto',
  marginTop: theme.spacing(6),
  '& > button': {
    marginBottom: theme.spacing(2)
  }
}));

const PaymentRequestQuickViewContent = props => {
  const {
    paymentRequest,
    statusTypes: { enumValues: statusTypes },
    userContext
  } = props;
  const [isRevokeDialogOpen, toggleRevokeDialog] = useDialog();
  const [isMarkPaymentSentDialogOpen, toggleMarkPaymentSentDialog] = useDialog();

  const canManagePaymentRequestPayment =
    paymentRequest.status === PAYMENT_REQUESTS_STATES.requested &&
    computePermissionRole(
      ALLOWED_ACTIONS.JOB_MANAGE_PAYMENT_REQUESTS,
      userContext.orgStaff.allowedActions
    );

  const resendClickHandler = () => {
    ResendPaymentRequestMutation(paymentRequest.id, response => {
      if (response && response.emailSent) {
        successToast('Request was sent!');
      } else {
        errorToast('Request failed to send.');
      }
    });
  };

  return (
    <>
      <Dialog
        isDialogOpen={isRevokeDialogOpen}
        closeDialog={toggleRevokeDialog}
        title="Revoke Payment Request"
      >
        <DeclinePaymentRequestDialog
          handleClose={toggleRevokeDialog}
          selectedId={paymentRequest.id}
        />
      </Dialog>

      <MarkPaymentSentDialog
        isDialogOpen={isMarkPaymentSentDialogOpen}
        paymentRequestId={paymentRequest.id}
        closeDialog={toggleMarkPaymentSentDialog}
      />

      <Typography variant="h1" gutterBottom>
        Payment Request #{paymentRequest.requestId}
      </Typography>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail
          name="Status"
          renderValue={() => (
            <StatusIndicator
              statusCode={paymentRequest.status}
              variant="paymentRequests"
              statusTypes={statusTypes}
              noPadding
            />
          )}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Detail
          name="Payment Request Date"
          value={<Date date={paymentRequest.created} />}
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail
          name="Contractor"
          renderValue={() => (
            <AvatarWithName
              name={paymentRequest.job.contractor.fullName}
              size={34}
              avatarProps={{
                src: paymentRequest.job.contractor.representativeImageUrl
              }}
            />
          )}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Detail
          name="Payment Amount"
          value={
            <Amount
              amount={paymentRequest.paymentAmount}
              currencyCode={paymentRequest.job.currencyCode}
            />
          }
          valueContainerProps={{
            variant: 'h2'
          }}
          flexDirection="column"
          alignItems="flex-end"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail
          name="Requested by"
          renderValue={() => (
            <AvatarWithName
              name={paymentRequest.paymentRequestedBy.fullName}
              size={34}
              avatarProps={{
                src: paymentRequest.paymentRequestedBy.representativeImageUrl
              }}
            />
          )}
          flexDirection="column"
          alignItems="flex-start"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Detail
          name="Message"
          value={paymentRequest.message}
          flexDirection="column"
          alignItems="flex-start"
          valueContainerProps={{ variant: 'body1' }}
        />
      </Box>
      {paymentRequest.status === PAYMENT_REQUESTS_STATES.declined && (
        <>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Revoked by"
              renderValue={() => (
                <AvatarWithName
                  name={paymentRequest.declinedBy.fullName}
                  size={34}
                  avatarProps={{
                    src: paymentRequest.declinedBy.representativeImageUrl
                  }}
                />
              )}
              flexDirection="column"
              alignItems="flex-start"
              nameColor={colors.red}
            />
            <Detail
              name="Revoked Date"
              value={<Date date={paymentRequest.declinedDate} />}
              flexDirection="column"
              alignItems="flex-end"
              nameColor={colors.red}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Detail
              name="Reason"
              value={paymentRequest.declinedReason}
              flexDirection="column"
              alignItems="flex-start"
              valueContainerProps={{ variant: 'body1' }}
              nameColor={colors.red}
            />
          </Box>
        </>
      )}

      <ButtonBox>
        <ActionButton
          label="Manage Request"
          fullWidth
          options={[
            {
              children: 'Download Payment Request',
              onClick: () => window.open(paymentRequest.paymentRequestFileUrl)
            },
            ...(paymentRequest.invoice
              ? [
                  {
                    children: 'Download Invoice',
                    onClick: () => window.open(paymentRequest.invoice.invoiceFileUrl)
                  }
                ]
              : []),
            ...(canManagePaymentRequestPayment
              ? [
                  {
                    children: 'Resend Payment Request',
                    onClick: resendClickHandler
                  },
                  {
                    children: 'Mark Payment Sent',
                    onClick: toggleMarkPaymentSentDialog
                  },
                  {
                    children: (
                      <Typography component="span" color="error">
                        Revoke Payment Request
                      </Typography>
                    ),
                    onClick: toggleRevokeDialog
                  }
                ]
              : [])
          ]}
        />
      </ButtonBox>
    </>
  );
};

export default createFragmentContainer(withUserContext(PaymentRequestQuickViewContent), {
  paymentRequest: graphql`
    fragment PaymentRequestQuickViewContent_paymentRequest on PaymentRequestNode {
      id
      requestId
      status
      created
      paymentAmount
      paymentRequestedBy {
        id
        fullName
        representativeImageUrl
      }
      paymentRequestFileUrl
      message
      declinedBy {
        id
        fullName
        representativeImageUrl
      }
      declinedDate
      declinedReason
      invoice {
        invoiceFileUrl
      }
      job {
        currencyCode
        contractor {
          id
          fullName
          representativeImageUrl
        }
      }
    }
  `,
  statusTypes: graphql`
    fragment PaymentRequestQuickViewContent_statusTypes on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

/**
 *
 * @param {string} commentId - ID of the comment to be deleted
 * @param {*} callback - Callback that receives 'response' and 'errors' as arguments
 */

const DeleteJobCommentMutation = (commentId, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation DeleteJobCommentMutation($input: DeleteJobCommentInput!) {
        deleteJobComment(input: $input) {
          isDeleted
          job {
            comments {
              edges {
                node {
                  id
                  created
                  content
                  contentEditedAt
                  user {
                    id
                    fullName
                    representativeImageUrl
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: {
        commentId
      }
    },
    onCompleted: (response, errors) => {
      callback(response.deleteJobComment, errors);
    }
  });
};

DeleteJobCommentMutation.propTypes = {
  commentId: PropTypes.string.isRequired,
  callback: PropTypes.func
};

export default DeleteJobCommentMutation;

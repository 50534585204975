/**
 * @flow
 * @relayHash c940042c83afda9ec3d580b2e29c40c8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFreelancerEmailInput = {|
  emailChangeToken: string,
  uidb64: string,
  clientMutationId?: ?string,
|};
export type UpdateFreelancerEmailMutationVariables = {|
  input: UpdateFreelancerEmailInput
|};
export type UpdateFreelancerEmailMutationResponse = {|
  +updateFreelancerEmail: ?{|
    +tokenValid: ?boolean,
    +emailChanged: ?boolean,
    +message: ?string,
  |}
|};
export type UpdateFreelancerEmailMutation = {|
  variables: UpdateFreelancerEmailMutationVariables,
  response: UpdateFreelancerEmailMutationResponse,
|};
*/


/*
mutation UpdateFreelancerEmailMutation(
  $input: UpdateFreelancerEmailInput!
) {
  updateFreelancerEmail(input: $input) {
    tokenValid
    emailChanged
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFreelancerEmailInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateFreelancerEmail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateFreelancerEmailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tokenValid",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailChanged",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateFreelancerEmailMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateFreelancerEmailMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateFreelancerEmailMutation",
    "id": null,
    "text": "mutation UpdateFreelancerEmailMutation(\n  $input: UpdateFreelancerEmailInput!\n) {\n  updateFreelancerEmail(input: $input) {\n    tokenValid\n    emailChanged\n    message\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ad86df085f8faa8f73ed7b4abe3092e';
module.exports = node;

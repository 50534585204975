import React from 'react';
import PropTypes from 'prop-types';

import StyledCheckbox from '../UI/StyledCheckbox';
import StyledTableCell from './StyledTableCell';

const TableCellCheckbox = props => {
  const { isChecked, onCheck, labelledBy, indeterminate, ...otherProps } = props;

  return (
    <StyledTableCell {...otherProps} style={{ padding: '0' }}>
      <StyledCheckbox
        indeterminate={indeterminate}
        id={`checkbox-${labelledBy}`}
        onClick={onCheck}
        checked={isChecked}
        inputProps={{ 'aria-labelledby': labelledBy }}
      />
    </StyledTableCell>
  );
};

TableCellCheckbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  labelledBy: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool
};

export default TableCellCheckbox;

import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { clamp } from 'lodash';
import { getEnvironment } from '../../utils/helpers';
import AWEbaseCanvas from './CanvasAnnotation/AWEbaseCanvas';
import { getCanvasDataAsJson, mergeAnnotationData } from './CanvasAnnotation/CanvasData';
import SquareButton from '../UI/SquareButton';
import CanvasAnnotationToolbar from './CanvasAnnotation/CanvasAnnotationToolbar';
import {
  AnnotationAssetContainer,
  AnnotationActionsContainer,
  AnnotationInterfaceContainer,
  AnnotationToolbarContainer
} from './review_interfaces/AnnotationInterfaceContainers';
import { TOOLS, ATTRS, getAnnotationAddTools } from './CanvasAnnotation/CanvasTools/contants';
import withUserContext from '../../contexts/userContext/withUserContext';
import UpdateDeliverableReviewAnnotationMutation from '../../mutations/review/UpdateDeliverableReviewAnnotationMutation';
import { errorToast } from '../../toasts';

const generateId = () => 'image-highlight-' + new Date().getTime().toString();

const ImageCanvasInterface = ({
  src,
  assetId,
  annotationData,
  userContext,
  canAddMarkup,
  highlightedAnnotationRef,
  handleHighlightAnnotation,
  onCloseClick,
  setSidebarAction
}) => {
  const containerRef = useRef();
  const childRef = useRef();
  const [sessionId, setSessionId] = useState(null);
  const [selectedTool, setSelectedTool] = useState(null);
  const [hideMarkers, setHideMarkers] = useState(false);
  const [color, setColor] = useState('red');
  const [editing, setEditing] = useState(false);
  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    if (!highlightedAnnotationRef && sessionId) {
      onCancelSession();
    }
  }, [highlightedAnnotationRef]);

  const onLoadedImage = ({ target: img }) => {
    const clientWidth = containerRef.current.clientWidth;
    // TO REVIEW is there a way to do thi without using a global ?
    const clientHeight = window.innerHeight - 54;
    const imageWidth = img.naturalWidth;
    const imageHeight = img.naturalHeight;

    const scale = {
      x: clientWidth / imageWidth,
      y: clientHeight / imageHeight
    };

    const scaleFactor = clamp(Math.min(scale.x, scale.y), 1);

    const dims = {
      width: img.naturalWidth * scaleFactor,
      height: img.naturalHeight * scaleFactor
    };
    setDimensions(dims);
  };

  /**
    Receives the notification from the Toolbar component that
    the user wants to start an annotation session.
    Annotation sessions are related 1-to-1 with comments.
     */
  const onStartSession = () => {
    // generate an id for the session
    // FRAMTODO this id needs to be unique
    const annotationRef = generateId();
    // change the toolbar controls
    // pass the new session id to the toolbar component
    handleHighlightAnnotation(annotationRef);
    setSelectedTool(TOOLS.dot);
    setSessionId(annotationRef);
    setSidebarAction(val => ({
      ...val,
      type: 'ADD_NEW',
      submitHandler: (values, actions) => {
        if (!childRef.current.containsAnnotations(annotationRef)) {
          errorToast('Please add some annotations before posting a comment.');
          actions.setSubmitting(false);
          return;
        }

        childRef.current.cleanupSession();
        setSelectedTool(TOOLS.defaultTool);

        fetchAnnotationData(serverData => {
          const mergedData = mergeAnnotationData(
            getCanvasDataAsJson(childRef.current.getCanvas(), dimensions),
            JSON.parse(serverData),
            userContext.id
          );
          const variables = {
            reviewAsset: assetId,
            annotationData: JSON.stringify(mergedData),
            [ATTRS.ref]: annotationRef,
            ...values
          };

          UpdateDeliverableReviewAnnotationMutation(variables, response => {
            setSessionId(null);
            setSidebarAction(null);
            handleHighlightAnnotation(null);
          });
        });
      }
    }));
  };

  const onCancelSession = () => {
    childRef.current.cancel(sessionId);
    setSessionId(null);
    handleHighlightAnnotation(null);
    setSelectedTool(TOOLS.defaultTool);
  };

  const onChangeTool = name => {
    setSelectedTool(TOOLS[name]);
  };

  const onAnnotationVisibilityChanged = value => {
    setHideMarkers(!value);
  };

  const onChangeColor = color => {
    setColor(color);
  };

  const onSaveEditChanges = (data, onSuccess) => {
    // we retrieve the annotation data from the server in case other users modified the data
    fetchAnnotationData(serverData => {
      const mergedData = mergeAnnotationData(
        getCanvasDataAsJson(childRef.current.getCanvas(), dimensions),
        JSON.parse(serverData),
        userContext.id
      );
      const variables = {
        reviewAsset: assetId,
        annotationData: JSON.stringify(mergedData)
      };

      UpdateDeliverableReviewAnnotationMutation(variables, response => {
        onSuccess();
      });
    });
  };

  const onStartEditMarkers = () => {
    setEditing(true);
    childRef.current.startEdit();
  };

  const onSaveEditMarkers = value => {
    setEditing(false);
    childRef.current.saveEdit();
  };

  /** SUBMIT AND COMMENT HANDLERS */
  const annotationQuery = graphql`
    query ImageCanvasInterfaceQuery($reviewAssetId: ID!) {
      reviewAsset(id: $reviewAssetId) {
        id
        reviewAssetAnnotation {
          annotationData
        }
      }
    }
  `;
  const fetchAnnotationData = (callback = () => {}) => {
    fetchQuery(getEnvironment(), annotationQuery, { reviewAssetId: assetId }, { force: true }).then(
      data => {
        callback(data.reviewAsset.reviewAssetAnnotation.annotationData);
      }
    );
  };

  const onActiveAnnotationColor = color => {
    setColor(color);
  };

  return (
    <Fragment>
      <AnnotationActionsContainer>
        <SquareButton onClick={onCloseClick} icon="close" />
      </AnnotationActionsContainer>
      <AnnotationInterfaceContainer>
        <AnnotationAssetContainer ref={containerRef}>
          <div style={dimensions}>
            <img src={src} onLoad={onLoadedImage} alt="Review asset" />
          </div>
          {dimensions && (
            <AWEbaseCanvas
              userId={userContext.id}
              dimensions={dimensions}
              backgroundColor={'transparent'}
              color={color}
              annotationData={annotationData}
              tool={selectedTool}
              editing={editing}
              hideMarkers={hideMarkers}
              onChangeAnnotation={handleHighlightAnnotation}
              // we should change the props for session and selection
              // this is only relevant when someone is viewing outsite of a
              // session. the id for the session can be used to select
              // part of the canvas annotations.
              sessionId={sessionId}
              annotationRef={highlightedAnnotationRef}
              onCloseEditMode={onSaveEditChanges}
              ref={childRef}
              onActiveAnnotationColor={onActiveAnnotationColor}
            />
          )}
        </AnnotationAssetContainer>

        <AnnotationToolbarContainer>
          <CanvasAnnotationToolbar
            sessionId={sessionId}
            canAddMarkup={canAddMarkup}
            onStartSession={onStartSession}
            onAddComment={() => {
              setSidebarAction(val => ({
                ...val,
                type: 'ADD_NEW'
              }));
            }}
            setToolHandler={onChangeTool}
            tools={getAnnotationAddTools()}
            selectedToolId={selectedTool ? selectedTool.name : null}
            toggleAnnotationVisibility={onAnnotationVisibilityChanged}
            annotationsVisible={!hideMarkers}
            onCloseClick={onCancelSession}
            editing={editing}
            onStartEdit={onStartEditMarkers}
            onSaveEdit={onSaveEditMarkers}
            colorProps={{
              color: color,
              disabled: editing && !sessionId,
              onChange: onChangeColor
            }}
          />
        </AnnotationToolbarContainer>
      </AnnotationInterfaceContainer>
    </Fragment>
  );
};

PropTypes.ImageCanvasInterface = {
  /**
    The if of the asset that is the subject of the review interface.
     */
  assetId: PropTypes.string,
  /**
    Call back invokded when the close button is clicked by the user.
     */
  onCloseClick: PropTypes.func,
  /**
    Call back invoked when the annotation for the asset has been modified
    and needs to be saved to the server.
     */
  updateReviewAssetAnnotation: PropTypes.func,
  /**
    The reference of the annotation that is currently selected in the comment thread.
     */
  highlightedAnnotationRef: PropTypes.string,
  /**
    The data of the annotations to be displayed by this interface
    */
  annotationData: PropTypes.array,
  /**
    Callback to inform the client that a new annotation has been selected.
     */
  handleHighlightAnnotation: PropTypes.func,
  /**
    URL of the file asset to be displayed by this interface
     */
  src: PropTypes.string,
  /**
    Flag to indicate if the user has the permissions to add markups to this asset.
    */
  canAddMarkup: PropTypes.bool,
  /**
    Not sure what this is for.
     */
  sceneSettings: PropTypes.object
};

export default withUserContext(ImageCanvasInterface);

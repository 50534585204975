import React, { useEffect, Fragment } from 'react';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';

import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import useInterval from '../../hooks/useInterval';
import { withRouter } from 'react-router';
import NotificationMenuItem from '../UI/NotificationMenuItem';
import {
  resolveDeliverableLink,
  resolveSolicitationLink,
  resolveJobLink,
  resolveJobInvoiceLink,
  resolveContractorLink,
  resolveStaffLink,
  resolveOrganizationLink
} from '../../utils/linkResolvers';
import { computePermissionRole } from '../../utils/helpers';
import GlobalButton from '../UI/GlobalButton';
import colors from '../../styles/common/colors';
import { NOTIFICATION_TYPES, ALLOWED_ACTIONS } from '../../constants';
import withUserContext from '../../contexts/userContext/withUserContext';
import MarkNotificationsAsReadMutation from '../../mutations/notifications/MarkNotificationsAsReadMutation';
import { Typography, Box } from '@material-ui/core';
import MarkAllNotificationsAsReadMutation from '../../mutations/notifications/MarkAllNotificationsAsReadMutation';
import { FREELANCER_APP } from '../../services/constants';

const StyledMenuItem = styled(MenuItem)(({ theme, index }) => ({
  display: 'flex',
  width: '500px',
  flexDirection: 'column',
  borderLeft: `3px solid ${colors.lightBlueGrey}`,
  paddingLeft: '8px',
  margin: '10px',
  borderRadius: '5px',
  backgroundColor: index % 2 === 0 ? 'white' : '#F7F7F9',

  '& .MuiAvatar-root': {
    marginRight: '15px'
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.notificationHover,
    borderLeft: `3px solid ${theme.palette.primary.main}`
  },

  '& .truncateContent': {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden'
  }
}));

const StyledBadge = styled(Badge)(({ theme, badgeContent }) => ({
  '& span': {
    backgroundColor: theme.palette.colors.red,
    color: theme.palette.colors.lightBlueGrey,
    height: '17px',
    minWidth: '17px',
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
    fontSize: '0.6rem',
    padding: badgeContent > 99 ? '0 3px' : 0
  }
}));

const StyledNotificationMenuHeader = styled(Box)(({ theme }) => ({
  paddingLeft: '10px',
  paddingBottom: '8px',
  margin: '10px',
  marginBottom: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.colors.lightBlueGrey}`
}));

export const NotificationsButton = ({ count, onClick, ...otherProps }) => (
  <IconButton
    aria-label={`show ${count} new notifications`}
    aria-controls="notification-list"
    aria-haspopup="true"
    color="inherit"
    onClick={onClick}
    {...otherProps}
  >
    <StyledBadge badgeContent={count} color="secondary" max={99}>
      <NotificationsIcon />
    </StyledBadge>
  </IconButton>
);

NotificationsButton.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

NotificationsButton.defaultProps = {
  count: 0,
  onClick: () => {}
};

const TopBarNotification = props => {
  const {
    type,
    notifications: { notifications },
    loadMore,
    hasMore,
    isLoading,
    refetchConnection,
    userContext
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (!notifications.edges.length && anchorEl) {
      handleClose();
    }
  }, [notifications.edges.length, anchorEl]);

  const handleClick = event => {
    if (notifications.edges.length) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const refetchNotifications = (count = 10) => {
    refetchConnection(count, null, { unread: true });
  };

  const handleNotificationClick = notification => {
    const {
      extraData: { targetRelayId, actionRelayId, notificationType, instanceType },
      reviewAssetComment,
      reviewAsset
    } = notification;

    let redirectLink,
      stateProps = null;
    switch (notificationType) {
      case NOTIFICATION_TYPES.staffAssigned:
      case NOTIFICATION_TYPES.assignedInternally:
      case NOTIFICATION_TYPES.deliverableClose:
      case NOTIFICATION_TYPES.deliverableReset:
      case NOTIFICATION_TYPES.deliverableCancel:
        redirectLink = resolveDeliverableLink(targetRelayId, 'details');
        break;
      case NOTIFICATION_TYPES.reviewAssetReaction:
        redirectLink = resolveDeliverableLink(targetRelayId, 'review', reviewAsset.revisionNumber);
        break;
      case NOTIFICATION_TYPES.reviewAssetUpload:
      case NOTIFICATION_TYPES.reviewAssetComment:
      case NOTIFICATION_TYPES.reviewAssetCommentReaction:
        redirectLink = resolveDeliverableLink(targetRelayId, 'review');
        stateProps = reviewAssetComment ? { commentId: reviewAssetComment.id } : null;
        break;
      case NOTIFICATION_TYPES.referenceUpload:
        redirectLink = resolveDeliverableLink(targetRelayId, 'reference');
        break;
      case NOTIFICATION_TYPES.deliverableFileUpload:
        redirectLink = resolveDeliverableLink(targetRelayId, 'files');
        break;
      case NOTIFICATION_TYPES.solicitationComment:
      case NOTIFICATION_TYPES.solicitationReceived:
        redirectLink = resolveSolicitationLink(targetRelayId, actionRelayId ? 'contractors' : null);
        stateProps = actionRelayId ? { contractorId: actionRelayId } : null;
        break;
      case NOTIFICATION_TYPES.jobComment:
      case NOTIFICATION_TYPES.contractSigned:
      case NOTIFICATION_TYPES.jobDeclined:
      case NOTIFICATION_TYPES.jobCanceled:
      case NOTIFICATION_TYPES.jobRevoked:
      case NOTIFICATION_TYPES.jobReceived:
        redirectLink = resolveJobLink(targetRelayId);
        break;
      case NOTIFICATION_TYPES.invoiceCreated:
      case NOTIFICATION_TYPES.invoiceDeclined:
      case NOTIFICATION_TYPES.invoiceApproved:
        if (type === FREELANCER_APP) {
          redirectLink = resolveJobLink(targetRelayId, 'invoices');
        } else {
          redirectLink = resolveJobInvoiceLink(targetRelayId, actionRelayId);
        }
        break;
      case NOTIFICATION_TYPES.contractorInviteAccepted:
        redirectLink = resolveContractorLink(targetRelayId);
        break;
      case NOTIFICATION_TYPES.staffInviteAccepted:
        redirectLink =
          userContext.orgStaff &&
          computePermissionRole(
            ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STAFF_ROLE_AND_PERMISSIONS,
            userContext.orgStaff.allowedActions
          )
            ? resolveStaffLink(targetRelayId)
            : null;
        break;
      case NOTIFICATION_TYPES.contractorInviteReceived:
        redirectLink = resolveOrganizationLink(targetRelayId);
        break;
      case NOTIFICATION_TYPES.followObject:
        if (instanceType === 'Job') {
          redirectLink = resolveJobLink(targetRelayId);
        } else if (instanceType === 'Deliverable') {
          redirectLink = resolveDeliverableLink(targetRelayId, 'details');
        } else {
          throw new Error(`Unknown instanceType for ${NOTIFICATION_TYPES.followObject}`);
        }
        break;
      default:
        break;
    }

    if (redirectLink) {
      props.history.push(redirectLink, stateProps);
    }
    handleClose();
  };

  useInterval(
    () =>
      refetchNotifications(
        notifications.edges.length > 10 && anchorEl ? notifications.edges.length : 10
      ),
    60000
  );

  const clearNotification = id => {
    MarkNotificationsAsReadMutation({ notificationId: id }, () =>
      refetchNotifications(notifications.edges.length)
    );
  };
  const clearAllNotifications = () => {
    MarkAllNotificationsAsReadMutation(() => refetchNotifications(10));
  };

  return (
    <Fragment>
      <NotificationsButton count={notifications.totalCount} onClick={handleClick} />
      {notifications && notifications.edges.length > 0 && (
        <Menu
          id="notification-list"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledNotificationMenuHeader>
            <Typography variant="h3">Notifications</Typography>
            <GlobalButton variant="transparent" handleClick={clearAllNotifications}>
              Clear All
            </GlobalButton>
          </StyledNotificationMenuHeader>
          {notifications.edges.map((notification, index) => (
            <StyledMenuItem
              onClick={() => handleNotificationClick(notification.node)}
              key={notification.node.id}
              index={index}
            >
              <NotificationMenuItem
                notification={notification.node}
                handleClearClick={clearNotification}
              />
            </StyledMenuItem>
          ))}

          {hasMore() && (
            <GlobalButton
              id="loadMoreNotifications"
              variant="transparent"
              handleClick={() => {
                if (!isLoading()) {
                  loadMore(10, null, { force: true });
                }
              }}
              style={{ margin: 'auto', display: 'flex' }}
            >
              <span>Load more</span>
              <KeyboardArrowDownIcon />
            </GlobalButton>
          )}
        </Menu>
      )}
    </Fragment>
  );
};

export default withRouter(withUserContext(TopBarNotification));

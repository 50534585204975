/**
 * @flow
 * @relayHash 341401a1ea2941a2c0029061cead492e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GroupsQuickViewQueryVariables = {|
  id: string,
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
|};
export type GroupsQuickViewQueryResponse = {|
  +contractorGroup: ?{|
    +id: string,
    +name: string,
    +created: any,
    +createdBy: ?{|
      +fullName: ?string
    |},
    +contractors: ?{|
      +pageInfo: {|
        +hasNextPage: boolean,
        +endCursor: ?string,
      |},
      +totalCount: ?number,
      +edgeCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |}
      |}>,
    |},
  |}
|};
export type GroupsQuickViewQuery = {|
  variables: GroupsQuickViewQueryVariables,
  response: GroupsQuickViewQueryResponse,
|};
*/


/*
query GroupsQuickViewQuery(
  $id: ID!
  $first: Int
  $orderBy: String
  $after: String
) {
  contractorGroup(id: $id) {
    id
    name
    created
    createdBy {
      fullName
      id
    }
    contractors(first: $first, orderBy: $orderBy, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          id
          fullName
          representativeImageUrl
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contractors",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "after",
      "variableName": "after"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "orderBy",
      "variableName": "orderBy"
    }
  ],
  "concreteType": "ContractorNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pageInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "PageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hasNextPage",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "endCursor",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "edgeCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractorNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractorNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v5/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "representativeImageUrl",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GroupsQuickViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractorGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorGroupNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "createdBy",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GroupsQuickViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractorGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorGroupNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "createdBy",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GroupsQuickViewQuery",
    "id": null,
    "text": "query GroupsQuickViewQuery(\n  $id: ID!\n  $first: Int\n  $orderBy: String\n  $after: String\n) {\n  contractorGroup(id: $id) {\n    id\n    name\n    created\n    createdBy {\n      fullName\n      id\n    }\n    contractors(first: $first, orderBy: $orderBy, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edgeCount\n      edges {\n        node {\n          id\n          fullName\n          representativeImageUrl\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a5c8f071b4aa95cbb9c19f5b75b40a1c';
module.exports = node;

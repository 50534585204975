/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobDetailsTab_job$ref: FragmentReference;
declare export opaque type JobDetailsTab_job$fragmentType: JobDetailsTab_job$ref;
export type JobDetailsTab_job = {|
  +id: string,
  +contractSignDeadline: ?any,
  +contractDate: any,
  +created: any,
  +status: JobStatus,
  +staffCoordinator: {|
    +fullName: ?string,
    +representativeImageUrl: ?string,
    +organization: {|
      +name: string,
      +representativeImageUrl: ?string,
    |},
  |},
  +contract: ?{|
    +id: string,
    +contractFileUrl: ?string,
    +job: {|
      +status: JobStatus
    |},
  |},
  +jobId: ?number,
  +totalValue: number,
  +currencyCode: ?string,
  +hasDeliverableReview: ?boolean,
  +deliverableCategory: ?{|
    +name: string
  |},
  +$refType: JobDetailsTab_job$ref,
|};
export type JobDetailsTab_job$data = JobDetailsTab_job;
export type JobDetailsTab_job$key = {
  +$data?: JobDetailsTab_job$data,
  +$fragmentRefs: JobDetailsTab_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "JobDetailsTab_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "contractSignDeadline",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "contractDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "created",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffCoordinator",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "OrgNode",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v2/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contract",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contractFileUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "job",
          "storageKey": null,
          "args": null,
          "concreteType": "JobNode",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currencyCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasDeliverableReview",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "deliverableCategory",
      "storageKey": null,
      "args": null,
      "concreteType": "CategoryNode",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f0af892fdce03f6647101068ffa0d29';
module.exports = node;

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Popover } from '@material-ui/core';

import ColorsGrid from './ColorsGrid';

const useColorsPopoverStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    maxWidth: props => props.maxWidth
  }
}));

export const ColorPicker = props => {
  const { color, renderButton, onChange, colors, popoverProps } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useColorsPopoverStyles({ maxWidth: popoverProps.maxWidth });

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (_event, color) => {
    onChange(color);
    handleClose();
  };

  return (
    <Fragment>
      {renderButton({ handleClick, color })}
      <Popover
        classes={classes}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={popoverProps.anchorOrigin}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'center'
        }}
      >
        <ColorsGrid selectedColor={color} colors={colors} onChange={handleChange} />
      </Popover>
    </Fragment>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired,
  popoverProps: PropTypes.shape({
    maxWidth: PropTypes.number.isRequired,
    anchorOrigin: PropTypes.object.isRequired
  }).isRequired
};

ColorPicker.defaultProps = {
  popoverProps: { maxWidth: 152, anchorOrigin: { horizontal: 'right', vertical: 'top' } }
};

export default ColorPicker;

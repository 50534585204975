import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route, Link, Switch } from 'react-router-dom';

import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';

import { useTabStyles } from '../../styles/common/tabStyles';
import { kebabCase } from 'lodash';

const TabPanel = props => {
  const { label, index, component, onLocationChange, routeProps } = props;

  const classes = useTabStyles();
  const id = `${kebabCase(label)}-tab-panel`;

  useEffect(() => {
    onLocationChange(index);
  }, [routeProps.location]);

  return (
    <Typography
      id={id}
      className={classes.tabPanel}
      role="tabpanel"
      component="div"
      aria-labelledby={id}
    >
      <Box className={classes.tabBox}>
        {React.cloneElement(component, { ...component.props, ...routeProps })}
      </Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node
};

const AutoTabList = props => {
  const { onTabChange, label, children, initialTabIndex = 0, match, history, location } = props;

  const [value, setValue] = useState(initialTabIndex);
  const classes = useTabStyles();

  useEffect(() => {
    if (location.pathname === match.url) {
      history.replace(
        `${match.url}/${kebabCase(children[initialTabIndex].props.label)}`,
        location.state
      );
    }
  }, [location.pathname]);

  const onChangeHandler = (_event, newValue) => {
    if (typeof onTabChange === 'function') {
      // TODO: check why this was there: newValue is always an index not a label
      // onTabChange(children.findIndex(child => kebabCase(child.props.label) === newValue));
      onTabChange(newValue);
    }
  };

  const a11yProps = label => {
    const id = `${kebabCase(label)}-tab`;
    return { 'aria-controls': id, id };
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={onChangeHandler}
          aria-label={label ? kebabCase(label) : 'tabs'}
        >
          {React.Children.map(children, child => {
            const { label, disabled } = child.props;
            const tabValue = `${kebabCase(label)}`;
            return (
              <Tab
                key={tabValue}
                classes={{ selected: classes.selectedTab }}
                label={label}
                disabled={disabled}
                component={Link}
                to={`${match.url}/${tabValue}`}
                {...a11yProps(label)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      <Switch>
        {React.Children.map(children, (child, index) => {
          const { label } = child.props;
          const tabValue = kebabCase(label);
          return (
            <Route
              path={`${match.path}/${tabValue}`}
              render={routeProps => (
                <TabPanel
                  key={tabValue}
                  label={label}
                  index={index}
                  routeProps={routeProps}
                  onLocationChange={setValue}
                  component={child}
                />
              )}
            />
          );
        })}
      </Switch>
    </div>
  );
};

AutoTabList.propTypes = {
  // Used only for aria-label
  label: PropTypes.string,
  // Any children are viable as long as they have a label prop
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        label: PropTypes.string.isRequired
      })
    })
  ),
  initialTabIndex: PropTypes.number,
  onTabChange: PropTypes.func
};

export default withRouter(AutoTabList);

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import { useParams } from 'react-router';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import BottomSubtitle from '../../../../shared/components/UI/BottomSubtitle';
import { ReactComponent as CalendarIcon } from '../../../../shared/images/calendar.svg';
import { formatDateDisplay } from '../../../../shared/utils/formatters';
import { ReactComponent as DeliverablesIcon } from '../../../../shared/images/deliverables.svg';
import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import { DELIVERABLE_STATES } from '../../../../shared/constants';
import DeliverableDetailsTab from './DeliverableDetailsTab';
import DeliverableReviewTab from './DeliverableReviewTab';
import DeliverableReferenceTab from './DeliverableReferenceTab';
import DeliverableFilesTab from './DeliverableFilesTab';

const DeliverableDetailsPage = props => {
  const { id } = useParams();
  const classes = useDetailsStyles();

  return (
    <QueryRenderer
      query={graphql`
        query DeliverableDetailsPageQuery($id: ID!) {
          deliverable(id: $id) {
            title
            state
            job {
              jobDeadline
            }
          }
        }
      `}
      variables={{
        id
      }}
      render={relayProps => {
        const { deliverable } = relayProps;
        return (
          <>
            <div className={classes.topSectionHeightSmall}>
              <div className={classes.marginBottom}>
                <div className={classes.marginTop}>
                  <CategoryTitleHeader
                    title={deliverable.title}
                    renderBottom={() => (
                      <BottomSubtitle icon={<CalendarIcon />}>
                        Completion Deadline: {formatDateDisplay(deliverable.job.jobDeadline)}
                      </BottomSubtitle>
                    )}
                    icon={DeliverablesIcon}
                    link="/deliverables"
                  />
                </div>
              </div>
            </div>
            <AutoTabList
              initialTabIndex={deliverable.state === DELIVERABLE_STATES.inProgress ? 1 : 0}
            >
              <DeliverableDetailsTab label="Details" />
              <DeliverableReviewTab label="Review" />
              <DeliverableReferenceTab label="Reference" />
              <DeliverableFilesTab label="Files" />
            </AutoTabList>
          </>
        );
      }}
    />
  );
};

export default DeliverableDetailsPage;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreatePaymentRequestDialog_job$ref = any;
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Header_job$ref: FragmentReference;
declare export opaque type Header_job$fragmentType: Header_job$ref;
export type Header_job = {|
  +id: string,
  +status: JobStatus,
  +balance: ?any,
  +totalValue: number,
  +currencyCode: ?string,
  +totalAmountOfPaymentRequests: ?any,
  +$fragmentRefs: CreatePaymentRequestDialog_job$ref,
  +$refType: Header_job$ref,
|};
export type Header_job$data = Header_job;
export type Header_job$key = {
  +$data?: Header_job$data,
  +$fragmentRefs: Header_job$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Header_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "balance",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currencyCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalAmountOfPaymentRequests",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CreatePaymentRequestDialog_job",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6c4973e9e420023218739aeeba86df26';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

const mutation = graphql`
  mutation CreateContractorMutation($input: CreateContractorInput!) {
    createContractor(input: $input) {
      newContractor {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export default (firstName, lastName, email, callback) => {
  const variables = {
    input: {
      firstName,
      lastName,
      email
    }
  };

  commitMutation(getEnvironment(), {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response.createContractor, errors);
    },
    onError: err => console.error(err)
  });
};

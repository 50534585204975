import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import GlobalButton from '../UI/GlobalButton';
import GlobalCreateModal from './GlobalCreateModal';
import useDialog from '../../hooks/useDialog';

const GlobalCreateAdmin = ({
  props,
  getAdminOptionSelectedForPage,
  pathname,
  roleTypes,
  options
}) => {
  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const [selectedModal, setSelectedModal] = useState(null);

  // listen to page change and re-set selected modal (and re-render button text) accordingly
  useEffect(() => {
    setSelectedModal(getAdminOptionSelectedForPage());
  }, [pathname]);

  function handleClick() {
    toggleDialogOpen();
  }

  return selectedModal ? (
    <Grid container>
      <GlobalButton handleClick={handleClick}>{selectedModal.buttonName}</GlobalButton>
      <GlobalCreateModal
        isDialogOpen={isDialogOpen}
        handleClose={handleClick}
        selectedModal={selectedModal}
        toRefetchData={props.toRefetchData}
        roleTypes={roleTypes}
        options={options}
      />
    </Grid>
  ) : null;
};

export default GlobalCreateAdmin;

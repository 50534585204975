import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import JobsList from './JobsList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';

const JobsListPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query JobsListPageQuery(
          $first: Int
          $after: String
          $orderBy: String
          $jobDeadlineFrom: Date
          $jobDeadlineTo: Date
          $jobId: String
          $releaseId: String
          $productId: String
          $category: ID
          $status: String
          $paymentStatus: String
          $includeInactive: Boolean
          $contractorId: String
          $staffId: String
          $name: String
          $onlyFollowed: Boolean
        ) {
          ...JobsList_jobs
          jobStatuses: __type(name: "JobStatus") {
            ...JobsList_statusTypes
          }
          jobPaymentStatuses: __type(name: "JobPaymentStatus") {
            ...JobsList_paymentStatusTypes
          }
        }
      `}
      variables={variables}
      render={relayProps => (
        <JobsList
          jobs={relayProps}
          statusTypes={relayProps.jobStatuses}
          paymentStatusTypes={relayProps.jobPaymentStatuses}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(JobsListPage, 'orgJobs', {
  orderBy: 'jobDeadline',
  initialFilters: {
    releaseId: null,
    productId: null,
    category: null,
    jobDeadlineFrom: null,
    jobDeadlineTo: null,
    status: '',
    paymentStatus: '',
    staffId: null,
    contractorId: null,
    includeInactive: false,
    onlyFollowed: false,
    name: ''
  }
});

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const DownloadBatchReviewAssetsMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation DownloadBatchReviewAssetsMutation($input: DownloadBatchReviewAssetsFilesInput!) {
          downloadBatchReviewAssetsFiles(input: $input) {
            email
          }
        }
      `,
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default DownloadBatchReviewAssetsMutation;

import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { Box } from '@material-ui/core';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import FormikDropzone from '../form/formikFields/FormikDropzone';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import useDialogStyles from '../../../shared/styles/common/useDialogStyles';
import { getSizeLabel, getSizeInBytes } from '../../utils/helpers';
import { yupSchemas } from '../../validations';
import { performFileUpload } from '../../services/utilities';
import CreateDeliverableFinalFileMutation from '../../mutations/deliverables/CreateDeliverableFinalFileMutation';

const FinalFilesUploadForm = ({ deliverableId, closeDialog, finalFileMaxSize }) => {
  const classes = useDialogStyles();

  const finalFilesInitialValues = {
    description: '',
    file: null
  };

  const finalFilesValidationSchema = Yup.object().shape({
    description: yupSchemas.string(true, { max: 512 }),
    file: Yup.mixed()
      .required('A file is required')
      .test(
        'fileSize',
        getSizeLabel(finalFileMaxSize),
        value => value && value.size <= getSizeInBytes(finalFileMaxSize)
      )
  });

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const mutationVariables = {
      deliverableId,
      description: values.description
    };

    const onCompleted = response => {
      setSubmitting(false);
      if (response && response.createDeliverableFinalFile) {
        closeDialog();
      }
    };

    const onError = () => {
      setSubmitting(false);
    };

    performFileUpload(
      'deliverablefinalfile',
      'file',
      null,
      values.file,
      file => {
        CreateDeliverableFinalFileMutation({ ...mutationVariables }, file, onCompleted, onError);
      },
      (stagedPath, fileName) => {
        CreateDeliverableFinalFileMutation(
          { ...mutationVariables, stagedPath, fileName },
          null,
          onCompleted,
          onError
        );
      }
    );
  };

  return (
    <Formik
      initialValues={finalFilesInitialValues}
      validationSchema={finalFilesValidationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box mt={2} mb={2}>
            <Field
              fullWidth
              required
              name="file"
              label="File"
              type="file"
              component={FormikDropzone}
              accept=""
              // helperTextProps={{
              //   maxFileSize: finalFileMaxSize,
              //   acceptedFormats: ['any']
              // }}
            />
          </Box>
          <Box mt={2} mb={2}>
            <Field
              fullWidth
              name="description"
              label="Description"
              multiline
              rows="8"
              component={FormikTextField}
              required
            />
          </Box>
          <div className={classes.dialogActions}>
            <GlobalButton
              id="cancelFinalFilesUploadForm"
              variant="transparent"
              handleClick={closeDialog}
              disabled={isSubmitting}
            >
              Cancel
            </GlobalButton>
            <GlobalButton
              loading={isSubmitting}
              id="addFileFinalFilesUploadForm"
              handleClick={submitForm}
              disabled={isSubmitting}
            >
              Add file
            </GlobalButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FinalFilesUploadForm;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation SendSolicitationMutation($input: SendSolicitationInput!) {
    sendSolicitation(input: $input) {
      isSent
      solicitation {
        id
        state
        dateSent
      }
    }
  }
`;

const SendSolicitationMutation = (id, callback) => {
  const sendSolicitationVariables = {
    input: { id }
  };

  const config = {
    mutation,
    variables: sendSolicitationVariables,
    onCompleted: response => {
      callback(response.sendSolicitation);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default SendSolicitationMutation;

SendSolicitationMutation.propTypes = {
  id: PropTypes.string.isRequired
};

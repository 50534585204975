import { makeStyles } from '@material-ui/core/styles';

export const drawerWidth = 236;
export const drawerClosedWidth = 92;
export const appBarHeight = 85;
const mainContainerShift = 25;

const appStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minWidth: '1280px'
  },
  dividerText: {
    width: '250px',
    fontWeight: 'bold',
    color: theme.palette.colors.dividerGrey
  },
  topBarMenuItemOrg: {
    '&:hover': {
      backgroundColor: theme.palette.colors.lightOrange,
      color: theme.palette.colors.orange,
      fontWeight: 'bold'
    }
  },
  topBarMenuItemFree: {
    '&:hover': {
      backgroundColor: theme.palette.colors.lightBlue,
      color: theme.palette.colors.blue,
      fontWeight: 'bold'
    }
  },

  loginContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  loginContainerOrganization: {
    backgroundColor: theme.palette.colors.grey
  },

  loginContainerFreelancer: {
    backgroundColor: theme.palette.colors.violet
  },

  appBar: {
    zIndex: 10,
    height: `${appBarHeight}px`,
    width: '100%',
    color: theme.palette.colors.mediumBlueGrey,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: `1px solid #DFE6EE`
  },

  appBarToolbar: {
    '&.MuiToolbar-regular': {
      minHeight: appBarHeight,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },

  appBarRight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  currentUserIcon: {
    borderRadius: '10px',
    '& p': {
      paddingLeft: '10px'
    }
  },

  mainContainer: {
    display: 'flex',
    backgroundColor: 'transparent',
    overflow: 'auto',
    height: `calc(100vh - ${appBarHeight}px)`,
    paddingLeft: '24px'
  },

  appContainer: {
    position: 'relative',
    width: '100%',
    marginLeft: `-${mainContainerShift}px`,
    paddingLeft: `${mainContainerShift}px`,
    backgroundColor: theme.palette.colors.lightBlueGrey,
    boxShadow: 'inset 65px 0px 20px -40px rgba(0,0,0,0.25)'
  }
}));

export default appStyles;

/**
 * @flow
 * @relayHash 6d0885b1ac3f77595e7d4cd6a867e1c8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCategoryInput = {|
  id: string,
  name?: ?string,
  categoryTypes?: ?$ReadOnlyArray<?CategoryTypeInput>,
  steps?: ?$ReadOnlyArray<?StepInput>,
  clientMutationId?: ?string,
|};
export type CategoryTypeInput = {|
  id?: ?string,
  name: string,
  replacementFor?: ?$ReadOnlyArray<?string>,
|};
export type StepInput = {|
  id?: ?string,
  name: string,
  order: number,
  replacementFor?: ?$ReadOnlyArray<?string>,
|};
export type UpdateCategoryMutationVariables = {|
  input: UpdateCategoryInput
|};
export type UpdateCategoryMutationResponse = {|
  +updateCategory: ?{|
    +updatedCategory: ?{|
      +id: string,
      +name: string,
      +categoryTypes: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
            +numberOfDeliverables: ?number,
          |}
        |}>
      |},
      +steps: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
            +order: number,
            +numberOfDeliverables: ?number,
          |}
        |}>
      |},
    |}
  |}
|};
export type UpdateCategoryMutation = {|
  variables: UpdateCategoryMutationVariables,
  response: UpdateCategoryMutationResponse,
|};
*/


/*
mutation UpdateCategoryMutation(
  $input: UpdateCategoryInput!
) {
  updateCategory(input: $input) {
    updatedCategory {
      id
      name
      categoryTypes {
        edges {
          node {
            id
            name
            numberOfDeliverables
          }
        }
      }
      steps {
        edges {
          node {
            id
            name
            order
            numberOfDeliverables
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCategoryInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numberOfDeliverables",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateCategory",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCategoryPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedCategory",
        "storageKey": null,
        "args": null,
        "concreteType": "CategoryNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categoryTypes",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryTypeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryTypeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryTypeNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "steps",
            "storageKey": null,
            "args": null,
            "concreteType": "StepNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "StepNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StepNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateCategoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateCategoryMutation",
    "id": null,
    "text": "mutation UpdateCategoryMutation(\n  $input: UpdateCategoryInput!\n) {\n  updateCategory(input: $input) {\n    updatedCategory {\n      id\n      name\n      categoryTypes {\n        edges {\n          node {\n            id\n            name\n            numberOfDeliverables\n          }\n        }\n      }\n      steps {\n        edges {\n          node {\n            id\n            name\n            order\n            numberOfDeliverables\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8291e0ee199f7f4f78635ae0beced3f5';
module.exports = node;

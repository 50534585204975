import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../../shared/utils/helpers';
import PropTypes from 'prop-types';

const UpdateStaffUserMutation = (variables, file, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateStaffUserMutation($input: UpdateStaffUserInput!) {
        updateStaffUser(input: $input) {
          updatedStaffUser {
            fullName
            user {
              firstName
              lastName
              email
            }
            representativeImageUrl
            title
            roleLabel
            role {
              id
              numberOfStaff
            }
          }
          oldRole {
            id
            numberOfStaff
          }
          emailFailed
        }
      }
    `,
    variables: {
      input: variables
    },
    uploadables: {
      representative_image: file
    },
    onCompleted: (response, errors) => {
      callback(response.updateStaffUser, errors);
    },
    onError: err => console.error(err)
  });
};

UpdateStaffUserMutation.propTypes = {
  variables: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      title: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      reprImageNull: PropTypes.bool
    })
  ),
  file: PropTypes.any.isRequired
};

export default UpdateStaffUserMutation;

/**
 * @flow
 * @relayHash 10e5fe36a12b5327407d344eb0d6783f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getContractPreviewPdfQueryVariables = {|
  contractor: string,
  category: string,
  name: string,
  description: string,
  contractTemplate?: ?string,
  jobDeadline?: ?any,
  contractSignDeadline?: ?any,
  totalValue?: ?any,
  deliverables?: ?$ReadOnlyArray<?string>,
  contractDate?: ?any,
|};
export type getContractPreviewPdfQueryResponse = {|
  +previewContractPdf: ?{|
    +pdfFile: ?string,
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type getContractPreviewPdfQuery = {|
  variables: getContractPreviewPdfQueryVariables,
  response: getContractPreviewPdfQueryResponse,
|};
*/


/*
query getContractPreviewPdfQuery(
  $contractor: ID!
  $category: ID!
  $name: String!
  $description: String!
  $contractTemplate: ID
  $jobDeadline: Date
  $contractSignDeadline: Date
  $totalValue: Decimal
  $deliverables: [ID]
  $contractDate: Date
) {
  previewContractPdf(contractor: $contractor, category: $category, name: $name, description: $description, contractTemplate: $contractTemplate, jobDeadline: $jobDeadline, contractSignDeadline: $contractSignDeadline, totalValue: $totalValue, deliverables: $deliverables, contractDate: $contractDate) {
    pdfFile
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contractor",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "description",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractTemplate",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadline",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractSignDeadline",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "totalValue",
    "type": "Decimal",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "deliverables",
    "type": "[ID]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractDate",
    "type": "Date",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "previewContractPdf",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "category",
        "variableName": "category"
      },
      {
        "kind": "Variable",
        "name": "contractDate",
        "variableName": "contractDate"
      },
      {
        "kind": "Variable",
        "name": "contractSignDeadline",
        "variableName": "contractSignDeadline"
      },
      {
        "kind": "Variable",
        "name": "contractTemplate",
        "variableName": "contractTemplate"
      },
      {
        "kind": "Variable",
        "name": "contractor",
        "variableName": "contractor"
      },
      {
        "kind": "Variable",
        "name": "deliverables",
        "variableName": "deliverables"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "jobDeadline",
        "variableName": "jobDeadline"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "totalValue",
        "variableName": "totalValue"
      }
    ],
    "concreteType": "PreviewContractPDF",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "pdfFile",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "errors",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getContractPreviewPdfQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getContractPreviewPdfQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getContractPreviewPdfQuery",
    "id": null,
    "text": "query getContractPreviewPdfQuery(\n  $contractor: ID!\n  $category: ID!\n  $name: String!\n  $description: String!\n  $contractTemplate: ID\n  $jobDeadline: Date\n  $contractSignDeadline: Date\n  $totalValue: Decimal\n  $deliverables: [ID]\n  $contractDate: Date\n) {\n  previewContractPdf(contractor: $contractor, category: $category, name: $name, description: $description, contractTemplate: $contractTemplate, jobDeadline: $jobDeadline, contractSignDeadline: $contractSignDeadline, totalValue: $totalValue, deliverables: $deliverables, contractDate: $contractDate) {\n    pdfFile\n    errors\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b44553c62e313eb868530828483d47a6';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DeliverableCurrencyCode = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SolicitationDeliverablesList_solicitation$ref: FragmentReference;
declare export opaque type SolicitationDeliverablesList_solicitation$fragmentType: SolicitationDeliverablesList_solicitation$ref;
export type SolicitationDeliverablesList_solicitation = {|
  +id: string,
  +solicitationId: ?string,
  +state: SolicitationState,
  +staffCoordinator: {|
    +user: {|
      +id: string
    |}
  |},
  +deliverables: ?{|
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
    +totalCount: ?number,
    +edgeCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
        +amount: ?any,
        +currencyCode: ?DeliverableCurrencyCode,
        +amountInHomeCurrency: ?any,
        +state: DeliverableState,
        +category: {|
          +id: string,
          +name: string,
        |},
        +release: ?{|
          +id: string,
          +name: string,
          +product: {|
            +id: string,
            +title: string,
          |},
        |},
        +allocation: ?{|
          +solicitation: {|
            +id: string
          |},
          +contractor: {|
            +fullName: ?string,
            +representativeImageUrl: ?string,
          |},
        |},
        +dueDate: ?any,
        +assignedContractor: ?{|
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |},
        +assignedInternally: ?{|
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |},
        +assignedStaff: ?{|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
          +user: {|
            +id: string
          |},
        |},
      |}
    |}>,
  |},
  +$refType: SolicitationDeliverablesList_solicitation$ref,
|};
export type SolicitationDeliverablesList_solicitation$data = SolicitationDeliverablesList_solicitation;
export type SolicitationDeliverablesList_solicitation$key = {
  +$data?: SolicitationDeliverablesList_solicitation$data,
  +$fragmentRefs: SolicitationDeliverablesList_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": (v2/*: any*/)
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "SolicitationDeliverablesList_solicitation",
  "type": "SolicitationNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "String"
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "solicitationId",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffCoordinator",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "deliverables",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "DeliverableNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "DeliverableNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currencyCode",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amountInHomeCurrency",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "category",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CategoryNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v5/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "release",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ReleaseNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v5/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "product",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ProductNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "allocation",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SolicitationAllocatedDeliverableNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "solicitation",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "SolicitationNode",
                      "plural": false,
                      "selections": (v2/*: any*/)
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "contractor",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ContractorNode",
                      "plural": false,
                      "selections": (v8/*: any*/)
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dueDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "assignedContractor",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractorNode",
                  "plural": false,
                  "selections": (v8/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "assignedInternally",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": (v8/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "assignedStaff",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v3/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '94404a6705e83fcfb67ad9f3558dde14';
module.exports = node;

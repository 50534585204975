import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import SolicitationDeliverablesList from './SolicitationDeliverablesList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';

const SolicitationDeliverablesTab = props => {
  const variables = useTableHistoryQueryVariables();
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query SolicitationDeliverablesTabQuery(
          $id: ID!
          $first: Int
          $after: String
          $orderBy: String
        ) {
          solicitation(id: $id) {
            ...SolicitationDeliverablesList_solicitation
          }
        }
      `}
      variables={{
        id,
        ...variables
      }}
      render={relayProps => (
        <SolicitationDeliverablesList solicitation={relayProps.solicitation} {...props} />
      )}
    />
  );
};

export default withTableHistoryProvider(
  SolicitationDeliverablesTab,
  'orgSolicitationDeliverables',
  {
    orderBy: 'dueDate'
  }
);

const loginStyles = theme => ({
  mainLogin: {
    width: '480px',
    '@media (max-width: 600px)': {
      maxWidth: '90%'
    }
  },

  logo: {
    paddingBottom: '100px',
    '@media (max-width: 600px)': {
      paddingBottom: '80px'
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      width: 198,
      height: 44
    }
  },

  loginContent: {
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '35px 72px',
    '@media (max-width: 600px)': {
      padding: '24px 36px'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h1': {
      '@media (max-width: 600px)': {
        fontSize: '26px'
      },
      textAlign: 'center'
    }
  },

  loginForm: {
    width: '100%',
    marginTop: '30px',
    '& label': {
      marginTop: '12px'
    }
  },

  noMarginTop: {
    marginTop: '0px'
  },

  loginExtra: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '0 0 20px',
    '& p, & a': {
      fontSize: '12px',
      color: theme.palette.colors.darkBlueGrey,
      cursor: 'pointer',
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      '&:hover': {
        color: theme.palette.primary.main
      },
      '& span': {
        fontSize: '13px'
      }
    },

    '& label': {
      margin: 0
    }
  },

  rememberCheck: {
    margin: 0,
    '& span': {
      fontSize: '12px',
      color: 'gray',
      padding: 0
    }
  },

  rememberUnchecked: {
    color: 'lightgray',
    fontSize: '18px',
    marginRight: '5px'
  },

  rememberChecked: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    marginRight: '5px'
  },

  submitButton: {
    margin: '15px 0 10px 0'
  },

  submitNewPasswordButton: {
    margin: '24px 0 10px 0'
  },

  back: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  divider: {
    display: 'flex',
    flex: 1,
    width: '100%',
    '&::before, &::after': {
      content: '""',
      flexGrow: '1',
      background: 'linear-gradient(to right, lightgray, lightgray)',
      backgroundSize: '100% 1px',
      backgroundPosition: '0% 50%',
      backgroundRepeat: 'repeat-x',
      margin: '0px 5px'
    },
    marginBottom: '20px',
    marginTop: '20px'
  },

  googleAuth: {
    width: '100%',
    boxShadow: 'none !important', // TODO: find way to achieve look wtihout using important
    border: 'solid 1px lightgray !important', // TODO: find way to achieve look wtihout using important
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& div': {
      marginRight: '-18px !important',
      borderRight: '1px solid lightgray'
    },
    '& span': {
      margin: 'auto'
    }
  },

  badAlert: {
    color: theme.palette.colors.red,
    marginTop: '16px',
    marginBottom: '0',
    fontSize: '12px',
    textAlign: 'center'
  },
  badAlertPlaceholder: {
    marginTop: '36px',
    marginBottom: '0px'
  },

  goodAlert: {
    color: theme.palette.colors.green,
    marginBottom: '36px',
    marginTop: '30px'
  },

  passwordChecklist: {
    fontSize: '12px',
    paddingTop: '18px',
    textAlign: 'center'
  },

  reqMet: {
    color: theme.palette.colors.green,
    fontWeight: 'bold'
  },

  reqUnmet: {
    color: theme.palette.colors.red,
    fontWeight: 'bold'
  },

  welcomeText: {
    textAlign: 'center',
    paddingTop: '18px'
  },

  loginFormInputField: {
    minHeight: '95px',
    '& label': {
      fontWeight: 'bold',
      color: theme.palette.colors.mediumBlueGrey
    }
  },
  marginTop: {
    marginTop: '30px'
  },
  acceptTerms: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginTop: '40px',
    '& a': {
      color: theme.palette.primary.main
    },
    '& span.MuiFormControlLabel-label': {
      fontSize: '12px',
      fontWeight: 'bold'
    },
    '& label': {
      marginTop: '0px'
    }
  },
  switchAppContainer: {
    margin: '36px 0 0 0',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
});

export default loginStyles;

import { FREELANCER_APP, ORGANIZATION_APP } from './constants';
import axios from 'axios';
import generateUploadUrl from '../mutations/file/generateUploadUrl';
import ConfirmPresignedUploadMutation from '../mutations/file/ConfirmPresignedUploadMutation';

/**
 * Redirect the browser to load Organization React App
 *
 * @return  {undefined}
 */
export function redirectToOrganizationApp() {
  console.info('Redirecting to Organization app');
  window.location.href = ORGANIZATION_APP;
}

/**
 * Get current app url prefix
 *
 * @return  {string|null}  App URL prefix route
 */
export function getURLPrefix() {
  const locationPathTokens = window.location.pathname.split('/app/');
  if (locationPathTokens.length === 2) {
    // "/app/" routing without app name
    if (locationPathTokens[1] === '') {
      return null;
    } else {
      const isOrg = locationPathTokens[1].indexOf('organization') === 0;
      const isFreelancer = locationPathTokens[1].indexOf('freelancer') === 0;

      // wrong prefix routing handling
      if (isOrg !== true && isFreelancer !== true) {
        return null;
      } else if (isOrg === true) {
        return ORGANIZATION_APP;
      } else if (isFreelancer === true) {
        return FREELANCER_APP;
      }
    }
  } else {
    return null;
  }
}

/**
 * Get Promise instance which retrieves presigned URL from GraphQL and invoke resolve or reject callback
 * @param filename {string} A name of File to upload
 * @param modelName {string} target model instance class
 * @param modelId   {string|null} Nullable target model instance GraphQL ID (If nullable, file upload will be staged)
 * @param fieldName {string} target field attribute on model class
 * @param contentType {string} MIME-type foe File instance for presigned URL
 * @returns {Promise<generateUploadUrl>}
 */
const getUploadTaskPromise = (filename, modelName, modelId, fieldName, contentType) =>
  new Promise((resolve, reject) => {
    generateUploadUrl(filename, modelName, modelId, fieldName, contentType, uploadEndpoint => {
      if (uploadEndpoint) {
        resolve(uploadEndpoint);
      } else {
        reject('NO_UPLOAD_ENDPOINT');
      }
    });
  });

/**
 * Get Promise instance which confirms the result of presigned file upload to GraphQL and invoke resolve or reject callback
 * @param fileResponse {object} axios response instance from file upload operation
 * @param filename {string} A name of File to upload
 * @param modelName {string} target model instance class
 * @param modelId   {string|null} Nullable target model instance GraphQL ID (If nullable, file upload will be staged)
 * @param fieldName {string} target field attribute on model class
 * @param stagedPath {string|null} A full S3 path to target file to bind in staged prefix
 * @returns {Promise<ConfirmPresignedUploadMutation>}
 */
const getConfirmUploadTaskPromise = (
  fileResponse,
  filename,
  modelName,
  modelId,
  fieldName,
  stagedPath
) =>
  new Promise((resolve, reject) => {
    if (fileResponse.status === 200) {
      ConfirmPresignedUploadMutation(
        filename,
        modelName,
        modelId,
        fieldName,
        stagedPath,
        confirmResponse => {
          if (confirmResponse === true) {
            resolve(stagedPath);
          } else {
            reject('UNCONFIRMED_PUT_UPLOAD_REQUEST');
          }
        }
      );
    } else {
      console.error('Presigned upload unsuccessful: ', fileResponse);
      reject('FAILED_PUT_UPLOAD_REQUEST');
    }
  });

/**
 * Initiate file upload
 * @param modelName {string} target model instance class
 * @param fieldName {string} target field attribute on model class
 * @param modelId   {string|null} Nullable target model instance GraphQL ID (If nullable, file upload will be staged)
 * @param file      {File} File instance to  upload
 * @param noPresignedUploadHandler  {function} A callback to run if presigned URL upload is not available
 * @param confirmPresignedUploadSuccessfulCallback  {function} A callback to run after presigned file upload is successful
 */
export function performFileUpload(
  modelName,
  fieldName,
  modelId = null,
  file,
  noPresignedUploadHandler,
  confirmPresignedUploadSuccessfulCallback
) {
  let filename = file.name;
  let contentType = file.type || 'application/octet-stream';
  let presignedUrl;

  const uploadTaskPromise = getUploadTaskPromise(
    filename,
    modelName,
    modelId,
    fieldName,
    contentType
  );

  uploadTaskPromise
    .then(
      uploadEndpoint => {
        // If presigned upload URL is available, prepare direct upload
        presignedUrl = uploadEndpoint;
        return axios.put(uploadEndpoint, file, {
          headers: {
            'Content-Type': file.type || 'application/octet-stream',
            'Content-Disposition': `attachment; filename=${filename}`
          }
        });
      },
      reason => {
        // If presigned upload URL is not available, do backup upload
        noPresignedUploadHandler(file);
        throw reason;
      }
    )
    .then(
      fileResponse => {
        return fileResponse;
      },
      reason => {
        // If direct upload fails, do backup upload
        if (reason !== 'NO_UPLOAD_ENDPOINT') {
          noPresignedUploadHandler(file);
          throw 'SIGNED_URL_FILE_REQUEST_FAIL';
        } else {
          throw reason;
        }
      }
    )
    .then(fileResponse => {
      // If direct upload has succeded, confirm the upload
      let url = new URL(presignedUrl);
      let relativeKeyPath = url.pathname.replace('/media/', '');

      return getConfirmUploadTaskPromise(
        fileResponse,
        filename,
        modelName,
        modelId,
        fieldName,
        relativeKeyPath
      );
    })
    .then(stagedPath => {
      // If direct upload has been confirmed
      confirmPresignedUploadSuccessfulCallback(stagedPath, filename);
    })
    .catch(error => {
      if (error === 'UNCONFIRMED_PUT_UPLOAD_REQUEST') {
        console.error('Uploaded file did not exist from presigned URL: ', file.name);
      }
    });
}

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

const mutation = graphql`
  mutation CreateDeliverableMutation($input: CreateDeliverableInput!) {
    createDeliverable(input: $input) {
      newDeliverable {
        id
        release {
          hasDeliverables
          categoryInfo {
            category {
              id
              name
            }
            contractedAmount
            pendingAmount
          }
          deliverablesTotalAmount
          deliverablesTotalOriginalAmount
          deliverablesTotalPendingAmount
        }
      }
    }
  }
`;

export default (title, release, category, callback, optionalFields) => {
  const data = {
    title,
    release,
    category,
    ...optionalFields
  };

  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] === undefined || data[key] === null) {
      delete data[key];
    }
  });

  const variables = { input: data };

  commitMutation(getEnvironment(), {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response.createDeliverable, errors);
    },
    onError: err => console.error(err)
  });
};

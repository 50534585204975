import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';

import { useTabStyles } from '../../styles/common/tabStyles';
import { kebabCase } from 'lodash';

const TabPanel = props => {
  const { children, value, index, label, ...other } = props;

  const classes = useTabStyles({ noBackground: true });
  const id = `${kebabCase(label)}-tab-panel`;

  return (
    <Typography
      id={id}
      className={classes.tabPanel}
      role="tabpanel"
      component="div"
      hidden={value !== index}
      aria-labelledby={id}
      {...other}
    >
      <Box className={classes.tabBox}>{children}</Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const AutoTabListWithoutRouting = props => {
  const { onTabChange, label, initialTabIndex = 0 } = props;

  const [value, setValue] = useState(initialTabIndex);
  const classes = useTabStyles();

  const handleChange = (_event, newValue) => {
    setValue(newValue);

    if (typeof onTabChange === 'function') {
      onTabChange(newValue);
    }
  };

  const a11yProps = label => {
    const id = `${kebabCase(label)}-tab`;
    return { 'aria-controls': id, id };
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label={label ? kebabCase(label) : 'tabs'}
        >
          {React.Children.map(props.children, (child, index) => (
            <Tab
              classes={{ selected: classes.selectedTab }}
              key={index}
              label={child.props.label}
              disabled={child.props.disabled}
              {...a11yProps(child.props.label)}
            />
          ))}
        </Tabs>
      </AppBar>
      {React.Children.map(props.children, (child, index) => {
        return (
          value === index && (
            <TabPanel key={index} value={value} index={index} label={child.props.label}>
              {React.cloneElement(child, child.props)}
            </TabPanel>
          )
        );
      })}
    </div>
  );
};

AutoTabListWithoutRouting.propTypes = {
  // Used only for aria-label
  label: PropTypes.string,
  // Any children are viable as long as they have a label prop
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        label: PropTypes.string.isRequired
      })
    })
  ),
  initialTabIndex: PropTypes.number,
  onTabChange: PropTypes.func
};

export default AutoTabListWithoutRouting;

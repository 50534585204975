/**
 * @flow
 * @relayHash 67e344dd61c07f982239c3a74dbe8221
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceTypeEnum = "AWEBASE" | "CUSTOM" | "%future added value";
export type CreateInvoiceInput = {|
  jobId: string,
  invoiceType: InvoiceTypeEnum,
  amount: any,
  identifier?: ?string,
  paymentTerm?: ?string,
  vatPercentage?: ?number,
  witholdingPercentage?: ?number,
  message?: ?string,
  clientMutationId?: ?string,
|};
export type CreateInvoiceMutationVariables = {|
  input: CreateInvoiceInput
|};
export type CreateInvoiceMutationResponse = {|
  +createInvoice: ?{|
    +clientMutationId: ?string
  |}
|};
export type CreateInvoiceMutation = {|
  variables: CreateInvoiceMutationVariables,
  response: CreateInvoiceMutationResponse,
|};
*/

/*
mutation CreateInvoiceMutation(
  $input: CreateInvoiceInput!
) {
  createInvoice(input: $input) {
    clientMutationId
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'CreateInvoiceInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'createInvoice',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'CreateInvoicePayload',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'clientMutationId',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'CreateInvoiceMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'CreateInvoiceMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'CreateInvoiceMutation',
      id: null,
      text:
        'mutation CreateInvoiceMutation(\n  $input: CreateInvoiceInput!\n) {\n  createInvoice(input: $input) {\n    clientMutationId\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'ef61e1154f2def057477b694f8cd9bb1';
module.exports = node;

import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import JobsList from '../../../jobs/JobsList/JobsList';

const ContractorJobsList = props => {
  const {
    contractor: {
      assignedJobs: {
        totalCount,
        edgeCount,
        pageInfo: { hasNextPage, endCursor },
        edges
      }
    },
    ...otherProps
  } = props;

  const [refetchCounter, setRefetchCounter] = useState(0);

  return (
    <JobsList
      isContractorTab
      noBackground
      edges={edges}
      paginationProps={{ totalCount, edgeCount, hasNextPage, endCursor }}
      refetchCounter={refetchCounter}
      toRefetchData={() => setRefetchCounter(val => val + 1)}
      {...otherProps}
    />
  );
};

export default createRefetchContainer(
  ContractorJobsList,
  {
    contractor: graphql`
      fragment ContractorJobsList_contractor on ContractorNode {
        assignedJobs(
          first: $first
          after: $after
          jobDeadlineFrom: $jobDeadlineFrom
          jobDeadlineTo: $jobDeadlineTo
          jobId: $jobId
          orderBy: $orderBy
          staffId: $staffId
          status: $status
          paymentStatus: $paymentStatus
          releaseId: $releaseId
          productId: $productId
          category: $category
          includeInactive: $includeInactive
          jobName: $name
        ) {
          totalCount
          edgeCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              jobId
              jobDeadline
              name
              status
              created
              releases {
                id
                name
                product {
                  id
                  title
                }
              }
              isCurrentUserFollowing
              followedDeliverables: deliverables(onlyFollowed: true) {
                edgeCount
              }
              deliverableCategory {
                name
              }
              staffCoordinator {
                representativeImageUrl
                fullName
              }
              totalValue
              currencyCode
              paymentStatus
              hasInvoicesToReview
            }
          }
        }
      }
    `,
    statusTypes: graphql`
      fragment ContractorJobsList_statusTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `,
    paymentStatusTypes: graphql`
      fragment ContractorJobsList_paymentStatusTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  },
  graphql`
    query ContractorJobsListRefetchQuery(
      $id: ID!
      $first: Int
      $after: String
      $orderBy: String
      $jobDeadlineFrom: Date
      $jobDeadlineTo: Date
      $jobId: String
      $staffId: String
      $status: String
      $paymentStatus: String
      $releaseId: String
      $productId: String
      $category: ID
      $includeInactive: Boolean
      $name: String
    ) {
      contractor(id: $id) {
        ...ContractorJobsList_contractor
      }
    }
  `
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';
import PropTypes from 'prop-types';

const mutation = graphql`
  mutation CreateReleaseMutation($input: CreateReleaseInput!) {
    createRelease(input: $input) {
      newRelease {
        id
        product {
          id
          hasReleases
        }
      }
    }
  }
`;

const CreateReleaseMutation = (input, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input },
    onCompleted: response => {
      callback(response.createRelease);
    },
    onError: err => console.error(err)
  });
};

CreateReleaseMutation.propTypes = {
  input: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string,
      product: PropTypes.string.isRequired,
      deadline: PropTypes.string,
      description: PropTypes.string,
      copyOfRelease: PropTypes.string
    })
  )
};

export default CreateReleaseMutation;

import React from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer as RelayQueryRenderer } from 'react-relay';

import { getEnvironment } from '../utils/helpers';

const QueryRenderer = ({ query, variables, render, renderLoading, renderError, ...otherProps }) => (
  <RelayQueryRenderer
    environment={getEnvironment()}
    query={query}
    variables={variables}
    render={({ error, props: relayProps }) => {
      if (error) {
        console.error(error.message);
        return renderError(error);
      }

      return relayProps ? render(relayProps) : renderLoading();
    }}
    {...otherProps}
  />
);

QueryRenderer.propTypes = {
  query: PropTypes.func.isRequired,
  variables: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  renderLoading: PropTypes.func.isRequired,
  renderError: PropTypes.func.isRequired
};

QueryRenderer.defaultProps = {
  variables: {},
  renderLoading: () => <div>Loading...</div>,
  renderError: error => (
    <div>
      <h1>Error!</h1>
      <p>Something went wrong...</p>
    </div>
  )
};

export default QueryRenderer;

/**
 * @flow
 * @relayHash 01f0ce5d896e3594caa8b463d9b4f4e9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
export type CloseJobInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type CloseJobMutationVariables = {|
  input: CloseJobInput
|};
export type CloseJobMutationResponse = {|
  +closeJob: ?{|
    +job: ?{|
      +status: JobStatus,
      +deliverables: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +state: DeliverableState
          |}
        |}>
      |},
    |}
  |}
|};
export type CloseJobMutation = {|
  variables: CloseJobMutationVariables,
  response: CloseJobMutationResponse,
|};
*/


/*
mutation CloseJobMutation(
  $input: CloseJobInput!
) {
  closeJob(input: $input) {
    job {
      status
      deliverables {
        edges {
          node {
            state
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CloseJobInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CloseJobMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "closeJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CloseJobPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverables",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CloseJobMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "closeJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CloseJobPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverables",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CloseJobMutation",
    "id": null,
    "text": "mutation CloseJobMutation(\n  $input: CloseJobInput!\n) {\n  closeJob(input: $input) {\n    job {\n      status\n      deliverables {\n        edges {\n          node {\n            state\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b82cb49b9a532dd83712c6916b4bbd6';
module.exports = node;

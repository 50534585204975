import React from 'react';
import AutoTabList from '../../../shared/components/UI/AutoTabList';
import OrganizationsListTabRenderer from './OrganizationListTabRenderer';
import ContactsListTabRenderer from './ContactsListTabRenderer';

const OrganizationsPage = () => (
  <AutoTabList>
    <OrganizationsListTabRenderer label="Organizations" />
    <ContactsListTabRenderer label="Contacts" />
  </AutoTabList>
);

export default OrganizationsPage;

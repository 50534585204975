import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { Drawer, List } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { ReactComponent as Logo } from '../../images/AWEbase-Default-Light.svg';
import { ReactComponent as Cutout } from '../../images/side-nav-cutout.svg';
import { ReactComponent as BackIcon } from '../../images/back.svg';
import { ReactComponent as SettingsIcon } from '../../images/settings.svg';
import SideBarLink from './SideBarLink';
import SidebarMenuArrow from '../UI/SidebarMenuArrow';
import withAppType from '../../hoc/withAppType';
import useSideBar from '../../hooks/useSideBar';
import withUserContext from '../../contexts/userContext/withUserContext';
import useSideBarStyles from '../../styles/common/sideBarStyles';
import { ALLOWED_ACTIONS } from '../../constants';
import { computePermissionRole } from '../../utils/helpers';
import { ADMIN_LINKS, ORGANIZATION_LINKS } from '../../../organization/routes';
import { FREELANCER_LINKS } from '../../../freelancer/routes';

const SideBar = props => {
  const [showAdminList, setShowAdminList] = useState(false);
  const [open, setOpen] = useSideBar();

  const {
    isOrganization,
    userContext: { orgStaff },
    pathname
  } = props;
  const classes = useSideBarStyles();
  const navigation = orgStaff && JSON.parse(orgStaff.organization.configuration).navigation;

  useEffect(() => {
    if (isOrganization && ADMIN_LINKS.find(route => pathname.includes(route.path))) {
      setShowAdminList(true);
    }
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
    localStorage.setItem('sidebar_expanded', !open);
  };

  const toggleAdminList = () => {
    setShowAdminList(!showAdminList);
  };

  const withAdminInterface = () => {
    // we need an option to toggle sidebar if user has permission to do at least one of admin/settings actions
    // then in the component we filter further which links should be accessible
    return (
      orgStaff &&
      computePermissionRole(
        [
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_BILLING_INFO,
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_CONTRACTS_TEMPLATE,
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_CUSTOMIZATION,
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STORAGE,
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STAFF_ROLE_AND_PERMISSIONS,
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_ORGANIZATION_PROFILE
        ],
        orgStaff.allowedActions
      )
    );
  };

  const getInitialLinkForAdminList = () => {
    // depending on user;s permissions we link them to first available option
    if (orgStaff) {
      if (
        computePermissionRole(
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_CUSTOMIZATION,
          orgStaff.allowedActions
        )
      ) {
        return '/admin/customization';
      }
      if (
        computePermissionRole(
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STAFF_ROLE_AND_PERMISSIONS,
          orgStaff.allowedActions
        )
      ) {
        return '/admin/staff-management';
      }
      if (
        computePermissionRole(
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_BILLING_INFO,
          orgStaff.allowedActions
        )
      ) {
        return '/admin/billing';
      }
      if (
        computePermissionRole(
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_CONTRACTS_TEMPLATE,
          orgStaff.allowedActions
        )
      ) {
        return '/admin/contracts';
      }
      if (
        computePermissionRole(
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_STORAGE,
          orgStaff.allowedActions
        )
      ) {
        return '/admin/storage-management';
      }
      if (
        computePermissionRole(
          ALLOWED_ACTIONS.ORGANIZATION_ADMIN_MANAGE_ORGANIZATION_PROFILE,
          orgStaff.allowedActions
        )
      ) {
        return '/admin/organization-profile';
      }
    }
    return '';
  };

  const organizationLinks = orgStaff
    ? ORGANIZATION_LINKS.map(route => {
        const {
          path,
          linkProps: { path: linkPropsPath, label, icon, intercomDataTarget }
        } = route;

        const sideBarLinkProps = {
          key: path,
          to: linkPropsPath || path,
          label,
          iconComponent: icon,
          intercomDataTarget
        };

        switch (path) {
          case '/products':
            sideBarLinkProps.label = navigation.productTitle.plural;
            break;
          case '/releases':
            sideBarLinkProps.label = navigation.releaseTitle.plural;
            break;
          default:
            break;
        }

        return <SideBarLink {...sideBarLinkProps} />;
      })
    : [];

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open={open}
      anchor="left"
    >
      <NavLink
        to="/deliverables"
        className={clsx(classes.sideBarLogo, {
          [classes.sideBarClosed]: !open
        })}
      >
        <Logo />
      </NavLink>
      <div className={classes.toolbar}>
        <div className="filler"></div>
        <Cutout></Cutout>
        <SidebarMenuArrow onClick={toggleDrawer} isOrganization={isOrganization}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </SidebarMenuArrow>
      </div>
      <List className={classes.sideBarList}>
        <div className={classes.sideBarLinks}>
          {isOrganization ? (
            showAdminList ? (
              <Fragment>
                {ADMIN_LINKS.map(route => {
                  return (
                    computePermissionRole(route.action, orgStaff.allowedActions) && (
                      <SideBarLink
                        key={route.path}
                        to={route.linkProps.path || route.path}
                        label={route.linkProps.label}
                        iconComponent={route.linkProps.icon}
                        intercomDataTarget={route.linkProps.intercomDataTarget}
                      />
                    )
                  );
                })}
              </Fragment>
            ) : (
              <Fragment>{organizationLinks}</Fragment>
            )
          ) : (
            <Fragment>
              {FREELANCER_LINKS.map(route => (
                <SideBarLink
                  key={route.path}
                  to={route.path}
                  label={route.linkProps.label}
                  iconComponent={route.linkProps.icon}
                  intercomDataTarget={route.linkProps.intercomDataTarget}
                />
              ))}
            </Fragment>
          )}
        </div>
        {withAdminInterface() && (
          <div className={classes.sideBarLink}>
            {showAdminList ? (
              <SideBarLink
                to={'/deliverables'}
                label="Go Back"
                iconComponent={BackIcon}
                isActive={() => false}
                onClick={toggleAdminList}
              />
            ) : (
              <SideBarLink
                to={getInitialLinkForAdminList()}
                label="Settings"
                iconComponent={SettingsIcon}
                isActive={() => false}
                onClick={toggleAdminList}
              />
            )}
          </div>
        )}
      </List>
    </Drawer>
  );
};

export default withAppType(withUserContext(SideBar));

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import AspectRatioContainer from './AspectRatioContainer';
import AspectRatioImageContainer from './AspectRatioImageContainer';
import useAssetStyles from '../../styles/assets/useAssetStyles';
import { ReactComponent as SmallLogo } from '../../images/awebase-logo-white-small.svg';
import { REVIEW_INTERFACES } from '../../constants';
import { ReactComponent as WritingIcon } from '../../images/writing-asset-type-icon.svg';
import { ReactComponent as PdfIcon } from '../../images/pdf-asset-type-icon.svg';
import { ReactComponent as AudioIcon } from '../../images/audio-asset-type-icon.svg';
import videoIcon from '../../images/default_video_thumbnail.png';

const StyledDefaultBackgroundContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.colors.lightestGrey,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > svg': {
    width: '50%',
    height: '50%'
  }
}));

const renderContent = (reviewInterface, src, index) => {
  switch (reviewInterface) {
    case REVIEW_INTERFACES.image:
      return <img src={src} alt="asset" />;

    case REVIEW_INTERFACES.video:
      return <img src={videoIcon} alt="asset" />;

    case REVIEW_INTERFACES.generic:
    case REVIEW_INTERFACES.a3dModel:
      return <WritingIcon />;
    case REVIEW_INTERFACES.document:
      return <PdfIcon />;
    case REVIEW_INTERFACES.audio:
      return <AudioIcon />;
    default:
      break;
  }
};

const AssetCardImage = ({
  src,
  aspectRatio,
  children,
  toolbarVisible,
  cover,
  reviewInterface,
  index,
  imageContainerProps,
  placeholder
}) => {
  const classes = useAssetStyles({ toolbarVisible });
  return (
    <AspectRatioContainer aspectRatio={aspectRatio}>
      <AspectRatioImageContainer cover={cover} {...imageContainerProps}>
        {reviewInterface ? (
          renderContent(reviewInterface, src, index)
        ) : src ? (
          <img src={src} alt="asset" />
        ) : (
          <StyledDefaultBackgroundContainer>
            {placeholder || <SmallLogo />}
          </StyledDefaultBackgroundContainer>
        )}
      </AspectRatioImageContainer>
      {children && (
        <div className={clsx('asset-hover-container', classes.assetkToolbarContainer)}>
          {children}
        </div>
      )}
    </AspectRatioContainer>
  );
};

AssetCardImage.propTypes = {
  src: PropTypes.string,
  // aspect ratio is the result of dividing the width value by the length,
  // 16:9 ratio would be 9/16 = 0.56
  aspectRatio: PropTypes.number,
  // children are displayed on hover over the image, intended for buttons
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  // Children always will be fully opaque.
  toolbarVisible: PropTypes.bool.isRequired,
  // True sets image's 'object-fit' to cover. Else - contain.
  cover: PropTypes.bool,
  reviewInterface: PropTypes.oneOf(Object.values(REVIEW_INTERFACES))
};

AssetCardImage.defaultProps = {
  toolbarVisible: false,
  cover: false
};

export default AssetCardImage;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import clsx from 'clsx';
import { ListItemAvatar } from '@material-ui/core';
import useCommentCardStyles from '../../styles/comment/useCommentCardStyles';
import Avatar from '../common/Avatar';
import DateBadge from './DateBadge';

const Container = styled.div`
  margin-top: 16px;
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.primary.dark};
`;

const Content = styled.div`
  background-color: ${props => props.theme.palette.primary.notificationHover};
  width: 100%;
  margin: 12px 0;
  border-left: 4px solid ${props => props.theme.palette.primary.main};
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: ${props => props.theme.palette.primary.dark};
`;

const UserInfoContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const UserName = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

const CommentRepliedTo = ({ user, created, content }) => {
  const commentCardClasses = useCommentCardStyles({ direction: 'start' });

  return (
    <Container>
      <Title>Replying to:</Title>
      <Content>
        <UserInfoContainer>
          {user.avatarSrc && (
            <ListItemAvatar
              className={clsx(
                commentCardClasses.commentHeaderAvatar,
                commentCardClasses.avatarAlign
              )}
            >
              <Avatar alt={user.fullName} src={user.avatarSrc} />
            </ListItemAvatar>
          )}
          <div>
            <UserName>{user.fullName}</UserName>
            <DateBadge date={created} dark={true} />
          </div>
        </UserInfoContainer>
        {content}
      </Content>
    </Container>
  );
};

CommentRepliedTo.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired
  }).isRequired,
  created: PropTypes.string,
  content: PropTypes.node.isRequired
};

export default CommentRepliedTo;

/**
 * @flow
 * @relayHash 9a41938ee94204ae0bc0445b308fd3b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractorJobsList_contractor$ref = any;
export type ContractorJobsListRefetchQueryVariables = {|
  id: string,
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
  jobDeadlineFrom?: ?any,
  jobDeadlineTo?: ?any,
  jobId?: ?string,
  staffId?: ?string,
  status?: ?string,
  paymentStatus?: ?string,
  releaseId?: ?string,
  productId?: ?string,
  category?: ?string,
  includeInactive?: ?boolean,
  name?: ?string,
|};
export type ContractorJobsListRefetchQueryResponse = {|
  +contractor: ?{|
    +$fragmentRefs: ContractorJobsList_contractor$ref
  |}
|};
export type ContractorJobsListRefetchQuery = {|
  variables: ContractorJobsListRefetchQueryVariables,
  response: ContractorJobsListRefetchQueryResponse,
|};
*/


/*
query ContractorJobsListRefetchQuery(
  $id: ID!
  $first: Int
  $after: String
  $orderBy: String
  $jobDeadlineFrom: Date
  $jobDeadlineTo: Date
  $jobId: String
  $staffId: String
  $status: String
  $paymentStatus: String
  $releaseId: String
  $productId: String
  $category: ID
  $includeInactive: Boolean
  $name: String
) {
  contractor(id: $id) {
    ...ContractorJobsList_contractor
    id
  }
}

fragment ContractorJobsList_contractor on ContractorNode {
  assignedJobs(first: $first, after: $after, jobDeadlineFrom: $jobDeadlineFrom, jobDeadlineTo: $jobDeadlineTo, jobId: $jobId, orderBy: $orderBy, staffId: $staffId, status: $status, paymentStatus: $paymentStatus, releaseId: $releaseId, productId: $productId, category: $category, includeInactive: $includeInactive, jobName: $name) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        jobId
        jobDeadline
        name
        status
        created
        releases {
          id
          name
          product {
            id
            title
          }
        }
        isCurrentUserFollowing
        followedDeliverables: deliverables(onlyFollowed: true) {
          edgeCount
        }
        deliverableCategory {
          name
          id
        }
        staffCoordinator {
          representativeImageUrl
          fullName
          id
        }
        totalValue
        currencyCode
        paymentStatus
        hasInvoicesToReview
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadlineFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadlineTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "staffId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "paymentStatus",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "releaseId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "productId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "edgeCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractorJobsListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractorJobsList_contractor",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractorJobsListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignedJobs",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "category",
                "variableName": "category"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "includeInactive",
                "variableName": "includeInactive"
              },
              {
                "kind": "Variable",
                "name": "jobDeadlineFrom",
                "variableName": "jobDeadlineFrom"
              },
              {
                "kind": "Variable",
                "name": "jobDeadlineTo",
                "variableName": "jobDeadlineTo"
              },
              {
                "kind": "Variable",
                "name": "jobId",
                "variableName": "jobId"
              },
              {
                "kind": "Variable",
                "name": "jobName",
                "variableName": "name"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              },
              {
                "kind": "Variable",
                "name": "paymentStatus",
                "variableName": "paymentStatus"
              },
              {
                "kind": "Variable",
                "name": "productId",
                "variableName": "productId"
              },
              {
                "kind": "Variable",
                "name": "releaseId",
                "variableName": "releaseId"
              },
              {
                "kind": "Variable",
                "name": "staffId",
                "variableName": "staffId"
              },
              {
                "kind": "Variable",
                "name": "status",
                "variableName": "status"
              }
            ],
            "concreteType": "JobNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "JobNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "jobId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "jobDeadline",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "created",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "releases",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "title",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isCurrentUserFollowing",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": "followedDeliverables",
                        "name": "deliverables",
                        "storageKey": "deliverables(onlyFollowed:true)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "onlyFollowed",
                            "value": true
                          }
                        ],
                        "concreteType": "DeliverableNodeConnection",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "deliverableCategory",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "staffCoordinator",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "representativeImageUrl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "totalValue",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currencyCode",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "paymentStatus",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasInvoicesToReview",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractorJobsListRefetchQuery",
    "id": null,
    "text": "query ContractorJobsListRefetchQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n  $orderBy: String\n  $jobDeadlineFrom: Date\n  $jobDeadlineTo: Date\n  $jobId: String\n  $staffId: String\n  $status: String\n  $paymentStatus: String\n  $releaseId: String\n  $productId: String\n  $category: ID\n  $includeInactive: Boolean\n  $name: String\n) {\n  contractor(id: $id) {\n    ...ContractorJobsList_contractor\n    id\n  }\n}\n\nfragment ContractorJobsList_contractor on ContractorNode {\n  assignedJobs(first: $first, after: $after, jobDeadlineFrom: $jobDeadlineFrom, jobDeadlineTo: $jobDeadlineTo, jobId: $jobId, orderBy: $orderBy, staffId: $staffId, status: $status, paymentStatus: $paymentStatus, releaseId: $releaseId, productId: $productId, category: $category, includeInactive: $includeInactive, jobName: $name) {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        jobId\n        jobDeadline\n        name\n        status\n        created\n        releases {\n          id\n          name\n          product {\n            id\n            title\n          }\n        }\n        isCurrentUserFollowing\n        followedDeliverables: deliverables(onlyFollowed: true) {\n          edgeCount\n        }\n        deliverableCategory {\n          name\n          id\n        }\n        staffCoordinator {\n          representativeImageUrl\n          fullName\n          id\n        }\n        totalValue\n        currencyCode\n        paymentStatus\n        hasInvoicesToReview\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '749e2219991e2a44d279a5fcd5b0d58b';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import GroupsList from './GroupsList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';

const GroupsListPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query GroupsListPageQuery($first: Int, $orderBy: String, $after: String) {
          ...GroupsList_general
        }
      `}
      variables={variables}
      render={relayProps => <GroupsList general={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(GroupsListPage, 'orgGroupsList', {
  orderBy: '-created'
});

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: props => (props.noMarginTop ? '' : '20px')
  },
  containerSmall: {
    fontWeight: 'bold',
    display: 'flex',
    paddingBottom: '10px',
    alignItems: props => (props.subscript ? 'start' : 'center')
  },
  containerColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  detailsContainerWidth: {
    width: props => (props.titleWidth ? `${props.titleWidth}px` : '120px')
  },
  detailsTitle: {
    color: theme.palette.colors.blueGrey,
    '& a': {
      color: theme.palette.colors.blueGrey,
      textDecoration: 'none'
    }
  },
  clickableRow: {
    cursor: 'pointer'
  },
  link: {
    fontWeight: 'bold',
    color: theme.palette.colors.blueGrey,
    fontSize: '14px',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  }
}));

const DetailsRow = props => {
  const { title, content, noMarginTop, titleWidth, onClick, link, subscript } = props;
  const classes = useStyles({ titleWidth, noMarginTop, subscript });
  return (
    <div className={classes.container}>
      <div className={clsx(classes.containerSmall, classes.detailsContainerWidth)}>
        <Typography variant="overline">{title}:</Typography>
      </div>
      <div
        onClick={onClick}
        className={clsx(classes.containerSmall, {
          [classes.clickableRow]: onClick !== undefined,
          [classes.containerColumn]: subscript !== undefined
        })}
      >
        {link ? (
          <Link className={classes.link} to={link}>
            {content}
          </Link>
        ) : (
          <span className={classes.detailsTitle}>{content || content === 0 ? content : '-'}</span>
        )}
        {subscript && (
          <Typography variant="overline" className={classes.detailsTitle}>
            {subscript}
          </Typography>
        )}
      </div>
    </div>
  );
};

DetailsRow.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  content: PropTypes.any,
  noMarginTop: PropTypes.bool,
  titleWidth: PropTypes.number,
  subscript: PropTypes.string
};

export default DetailsRow;

import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  Dialog as MUIDialog,
  DialogActions as MUIDialogActions
} from '@material-ui/core';
import useDialogStyles from '../../styles/common/useDialogStyles';
import SquareButton from '../UI/SquareButton';

// NOTE:
// Should be used for every dialog actions in the app to have consistent paddings
export const DialogActions = styled(MUIDialogActions)`
  &.MuiDialogActions-root {
    margin-top: ${props => props.theme.spacing(4)}px;
    padding: 0;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

const Dialog = props => {
  const {
    isDialogOpen,
    closeDialog,
    title,
    children,
    className,
    noButton,
    minWidth,
    maxWidth,
    noHeaderMargin,
    ...otherProps
  } = props;

  const classes = useDialogStyles({ minWidth, maxWidth, noHeaderMargin });

  return isDialogOpen
    ? createPortal(
        <MUIDialog
          className={clsx(classes.dialog, className)}
          open={isDialogOpen}
          onClose={closeDialog}
          aria-labelledby="dialog-title"
          {...otherProps}
        >
          <div className={classes.dialogContent}>
            <div className={classes.dialogHeader}>
              {title && (
                <Typography variant="h2" id="dialog-title">
                  {title}
                </Typography>
              )}
              {noButton ? null : (
                <SquareButton onClick={closeDialog} variant="secondary" icon="close" />
              )}
            </div>
            {children}
          </div>
        </MUIDialog>,
        document.body
      )
    : null;
};

Dialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  noButton: PropTypes.bool,
  title: PropTypes.string
};

export default Dialog;

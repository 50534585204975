import React from 'react';

import { Grid } from '@material-ui/core';

import FilterDebounceInput from '../../../../../shared/components/filters/FilterDebounceInput';
import SearchableOrganizationDropdown from '../../../shared/SearchableOrganizationDropdown';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { useFilterStyles } from '../../../../../shared/styles/common/filterStyles';

const ContactsListFilters = props => {
  const { filterValues, onFilterChange } = props;
  const classes = useFilterStyles();

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          name="staffName"
          label="Contact"
          value={filterValues['staffName']}
          onChange={onFilterChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableOrganizationDropdown
          id="filterByOrganizationInDeliverables"
          name="organization"
          value={filterValues['organization']}
          onChange={value => onFilterChange('organization', value)}
        />
      </Grid>
    </Grid>
  );
};

export default withUserContext(ContactsListFilters);

/**
 * @flow
 * @relayHash c6c0c0ffd874ce9152ecf28ae884bc7d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateContractorInput = {|
  id: string,
  firstName?: ?string,
  lastName?: ?string,
  title?: ?string,
  email?: ?string,
  resendInvitation?: ?boolean,
  phone?: ?string,
  skype?: ?string,
  nickname?: ?string,
  companyName?: ?string,
  portfolioUrl?: ?string,
  timezone?: ?string,
  address1?: ?string,
  address2?: ?string,
  city?: ?string,
  state?: ?string,
  zipcode?: ?string,
  country?: ?string,
  description?: ?string,
  skills?: ?$ReadOnlyArray<?string>,
  internalId?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  customFields?: ?any,
  clientMutationId?: ?string,
|};
export type UpdateContractorMutationVariables = {|
  input: UpdateContractorInput
|};
export type UpdateContractorMutationResponse = {|
  +updateContractor: ?{|
    +updatedContractor: ?{|
      +id: string,
      +firstName: ?string,
      +lastName: ?string,
      +fullName: ?string,
      +nickname: ?string,
      +title: ?string,
      +email: ?string,
      +phone: ?string,
      +timezone: ?string,
      +skype: ?string,
      +companyName: ?string,
      +portfolioUrl: ?string,
      +invitationEmailSent: ?any,
      +internalId: ?string,
      +address1: ?string,
      +address2: ?string,
      +city: ?string,
      +state: ?string,
      +zipcode: ?string,
      +country: ?{|
        +name: ?string,
        +code: ?string,
      |},
      +representativeImageUrl: ?string,
      +tags: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
          |}
        |}>
      |},
      +freelancer: ?{|
        +id: string
      |},
      +coverImageUrl: ?string,
      +conflictingContractor: ?{|
        +id: string
      |},
    |}
  |}
|};
export type UpdateContractorMutation = {|
  variables: UpdateContractorMutationVariables,
  response: UpdateContractorMutationResponse,
|};
*/


/*
mutation UpdateContractorMutation(
  $input: UpdateContractorInput!
) {
  updateContractor(input: $input) {
    updatedContractor {
      id
      firstName
      lastName
      fullName
      nickname
      title
      email
      phone
      timezone
      skype
      companyName
      portfolioUrl
      invitationEmailSent
      internalId
      address1
      address2
      city
      state
      zipcode
      country {
        name
        code
      }
      representativeImageUrl
      tags {
        edges {
          node {
            id
            name
          }
        }
      }
      freelancer {
        id
      }
      coverImageUrl
      conflictingContractor {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateContractorInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateContractor",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateContractorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedContractor",
        "storageKey": null,
        "args": null,
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fullName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nickname",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timezone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "skype",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "portfolioUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "invitationEmailSent",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "internalId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "city",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "zipcode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "country",
            "storageKey": null,
            "args": null,
            "concreteType": "Country",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "code",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "representativeImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tags",
            "storageKey": null,
            "args": null,
            "concreteType": "TagNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "freelancer",
            "storageKey": null,
            "args": null,
            "concreteType": "FreelancerNode",
            "plural": false,
            "selections": (v3/*: any*/)
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "coverImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "conflictingContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": (v3/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateContractorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateContractorMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateContractorMutation",
    "id": null,
    "text": "mutation UpdateContractorMutation(\n  $input: UpdateContractorInput!\n) {\n  updateContractor(input: $input) {\n    updatedContractor {\n      id\n      firstName\n      lastName\n      fullName\n      nickname\n      title\n      email\n      phone\n      timezone\n      skype\n      companyName\n      portfolioUrl\n      invitationEmailSent\n      internalId\n      address1\n      address2\n      city\n      state\n      zipcode\n      country {\n        name\n        code\n      }\n      representativeImageUrl\n      tags {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      freelancer {\n        id\n      }\n      coverImageUrl\n      conflictingContractor {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '37d112a852dc5bd6ee957f1d0809a3b2';
module.exports = node;

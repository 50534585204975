import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

export default (password, contractorToken, uidb64, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateAccountMutation($createAccountInput: CreateAccountInput!) {
        createAccount(input: $createAccountInput) {
          newUser {
            email
          }
        }
      }
    `,
    variables: {
      createAccountInput: {
        password,
        contractorToken,
        uidb64
      }
    },
    onCompleted: response => {
      callback(response);
    },
    onError: err => console.error(err)
  });
};

import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, List, ListItem, Typography, Link } from '@material-ui/core';

import ZipReviewInterfaceDropdown from '../form/dropdowns/ZipReviewInterfaceDropdown';
import FormikDropzone from '../form/formikFields/FormikDropzone';
import GlobalButton from '../UI/GlobalButton';
import useDialogStyles from '../../styles/common/useDialogStyles';
import { getSizeInBytes, getFileExtension } from '../../utils/helpers';
import { REVIEW_INTERFACES, ASSET_UPLOAD_USER_MESSAGES } from '../../constants';
import { files } from 'jszip';

const getConfigForReviewInterface = (extension, reviewInterface, config) => {
  let correctConfig = config.find(config => config.fileTypes.includes('.' + extension));

  if (!correctConfig && extension === 'zip' && reviewInterface) {
    correctConfig = config.find(config => config.reviewInterface === reviewInterface);
  }

  // if file extension not found, we apply 'generic' file limits
  if (!correctConfig) {
    correctConfig = config.find(config => config.reviewInterface === REVIEW_INTERFACES.generic);
  }
  return correctConfig;
};

const ConfigList = ({ config }) => {
  const fileTypes = config.filter(el => el.fileTypes.length !== 0);
  const generic = config.filter(el => el.fileTypes.length === 0);
  return (
    <List dense={true}>
      {fileTypes.map(el => (
        <ListItem key={el.reviewInterface}>
          <Typography variant="body1">
            {el.fileTypes.join(', ')} - Max File Size: {el.fileSize} MB
          </Typography>
        </ListItem>
      ))}
      {generic.map(el => (
        <ListItem key={el.reviewInterface}>
          <Typography variant="body1">
            Any other file type - Max File Size: {el.fileSize} MB
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

const FileTypeInfo = ({ ext, reviewInterface, config }) => {
  let userMsg = ASSET_UPLOAD_USER_MESSAGES[ext];
  const link = userMsg
    ? userMsg.link
    : 'https://intercom.help/awebase/en/articles/4201278-accepted-file-types';

  const renderMessage = userMsg => {
    if (userMsg) {
      return (
        <div>
          <Typography variant="body1">{userMsg.msg}</Typography>
          <Typography variant="body1">
            Max File Size: {getConfigForReviewInterface(ext, reviewInterface, config).fileSize} MB
          </Typography>
        </div>
      );
    } else {
      return (
        <div>
          {ext && (
            <Typography variant="body1">
              You can upload this file but it will be treated as a generic file.
            </Typography>
          )}
          <Typography variant="body1">
            AWEbase Review Interface supports the following file types:
          </Typography>
          <ConfigList config={config} />
        </div>
      );
    }
  };

  return (
    <div>
      {renderMessage(userMsg)}
      <Typography variant="body1">
        {' '}
        Click{' '}
        <Link variant="caption" target="_blank" rel="noopener" href={link}>
          here
        </Link>{' '}
        to know more.
      </Typography>
    </div>
  );
};

const DeliverableReviewAssetFileUploadForm = props => {
  const { closeDialog, onSubmit, isLoading, reviewAssetFileValidationConfig } = props;

  const [reviewInterface, setReviewInterface] = useState('');
  const classes = useDialogStyles();

  const validateSizePerType = (file, reviewInterface) => {
    const extension = getFileExtension(file.name);
    const correctConfig = getConfigForReviewInterface(
      extension,
      reviewInterface,
      reviewAssetFileValidationConfig
    );
    setReviewInterface(correctConfig.reviewInterface);
    return {
      isValid: file.size <= getSizeInBytes(correctConfig.fileSize),
      maxFileSize: correctConfig.fileSize
    };
  };

  const validate = values => {
    const { file, reviewInterface } = values;
    let errors = {};

    if (!file) {
      errors.file = 'File is required';
    } else {
      if (isZipFile(files) && reviewInterface === '') {
        errors.reviewInterface = `Zip file Types is required.`;
      }
      const { isValid, maxFileSize } = validateSizePerType(file, reviewInterface);
      if (!isValid) {
        errors.file = `Max file size for this file type is ${maxFileSize}MB.`;
      }
    }

    if (isZipFile(file) && !reviewInterface) {
      errors.reviewInterface = `Review Interface required for zip files`;
    }
    return errors;
  };

  const formikProps = {
    initialValues: {
      file: null,
      reviewInterface: reviewInterface
    },
    validate,
    onSubmit
  };

  const isZipFile = file => {
    return file ? getExt(file) === 'zip' : false;
  };

  const getExt = file => {
    return file ? getFileExtension(file.name) : null;
  };

  return (
    <Formik {...formikProps}>
      {({ submitForm, setFieldValue, values }) => (
        <Form>
          <Box mb={2}>
            <Field
              fullWidth
              required
              name="file"
              label="File"
              type="file"
              component={FormikDropzone}
              accept=""
              // TODO: this will be updated to a different way to display file type and size information
              // helperTextProps={{
              //   maxFileSize,
              //   acceptedFormats: formatReviewAssetAcceptedFilesArray(acceptedFileTypes)
              // }}
            />
          </Box>
          {isZipFile(values.file) && (
            <Field
              required
              component={ZipReviewInterfaceDropdown}
              name="reviewInterface"
              label="Zip File Type"
              onChange={e => {
                setFieldValue('reviewInterface', e.target.value);
                setReviewInterface(e.target.value);
              }}
              className={classes.formFieldNoMargin}
            />
          )}

          <FileTypeInfo
            ext={getExt(values.file)}
            config={reviewAssetFileValidationConfig}
            reviewInterface={reviewInterface}
          />
          <div className={classes.dialogActions}>
            <GlobalButton
              id="cancelReviewAsset"
              variant="transparent"
              handleClick={closeDialog}
              disabled={isLoading}
            >
              Cancel
            </GlobalButton>
            <GlobalButton
              loading={isLoading}
              disabled={isLoading}
              id="addFileReviewAsset"
              handleClick={submitForm}
            >
              Add file
            </GlobalButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DeliverableReviewAssetFileUploadForm;

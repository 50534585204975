import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

export default (email, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation SignInEmailMutation($input: SignInEmailInput!) {
        signInEmail(input: $input) {
          passwordRequired
          signInIdpLink
        }
      }
    `,
    variables: {
      input: {
        email
      }
    },
    onCompleted: callback,
    onError: err => console.error(err)
  });
};

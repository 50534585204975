/**
 * @flow
 * @relayHash 08a79c8f1c6aaa969420fd9c7318fd77
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProductDetailsContent_product$ref = any;
export type ProductDetailsTabQueryVariables = {|
  id: string
|};
export type ProductDetailsTabQueryResponse = {|
  +product: ?{|
    +$fragmentRefs: ProductDetailsContent_product$ref
  |}
|};
export type ProductDetailsTabQuery = {|
  variables: ProductDetailsTabQueryVariables,
  response: ProductDetailsTabQueryResponse,
|};
*/


/*
query ProductDetailsTabQuery(
  $id: ID!
) {
  product(id: $id) {
    ...ProductDetailsContent_product
    id
  }
}

fragment ProductDetailsContent_product on ProductNode {
  id
  code
  internalId
  representativeImageUrl
  title
  description
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProductDetailsTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "product",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ProductDetailsContent_product",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductDetailsTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "product",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "internalId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "representativeImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProductDetailsTabQuery",
    "id": null,
    "text": "query ProductDetailsTabQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    ...ProductDetailsContent_product\n    id\n  }\n}\n\nfragment ProductDetailsContent_product on ProductNode {\n  id\n  code\n  internalId\n  representativeImageUrl\n  title\n  description\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '36158ea2ece75c69034ec755a13500ef';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash/round';
import { Box, Typography, FormHelperText } from '@material-ui/core';
import { formatAmount } from '../../../utils/formatters';
import FormikAmountField from './FormikAmountField';
import { isInvalid } from '../FormikTextField';

const FormikAmountFieldWithCurrencyRate = props => {
  const { currencyCode, targetCurrencyRate, targetCurrencyCode, form, field, ...rest } = props;

  const convertedAmount =
    targetCurrencyRate &&
    targetCurrencyCode &&
    currencyCode !== targetCurrencyCode &&
    !isNaN(field.value)
      ? round(field.value * targetCurrencyRate, 2)
      : null;

  return (
    <>
      <FormikAmountField {...rest} currencyCode={currencyCode} form={form} field={field} />
      {!isInvalid(props) && (
        <FormHelperText component="div">
          {convertedAmount ? (
            <Box display="flex" justifyContent="space-between">
              <Typography variant="overline">
                RATE - {round(targetCurrencyRate, 2)} {targetCurrencyCode} / {currencyCode}
              </Typography>
              <Typography variant="overline">
                {formatAmount(convertedAmount, targetCurrencyCode)}
              </Typography>
            </Box>
          ) : (
            false
          )}
        </FormHelperText>
      )}
    </>
  );
};

FormikAmountFieldWithCurrencyRate.propTypes = {
  currencyCode: PropTypes.string.isRequired,
  targetCurrencyRate: PropTypes.string.isRequired,
  targetCurrencyCode: PropTypes.string.isRequired
};

export default FormikAmountFieldWithCurrencyRate;

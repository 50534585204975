import React from 'react';
import ReactDOM from 'react-dom';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import Quill from 'quill/core';
import Emitter from 'quill/core/emitter';
import { Range } from 'quill/core/selection';
import InlineBlot from 'quill/blots/inline';
import EmbedBlot from 'quill/blots/embed';
import ItalicBlot from 'quill/formats/italic';
import BoldBlot from 'quill/formats/bold';
import UnderlineBlot from 'quill/formats/underline';
import HeaderBlot from 'quill/formats/header';
import { ReferenceFilePreview } from './components';

const valueFromAttribute = (node, attr) => {
  const rawVal = node.getAttribute(attr);
  return rawVal && !['undefined', 'null'].includes(rawVal) ? rawVal : null;
};

// NOTE:
// Using span because of incosistant behaviour in browsers which breakes active
// state of toolbar link button
// https://github.com/quilljs/quill/issues/3392
export class LinkBlot extends InlineBlot {
  static blotName = 'link';
  static tagName = 'quill-link';

  static create(value) {
    const node = super.create(value);
    const hasProtocol = value.split(':').length > 1;
    const url = hasProtocol ? value : `https://${value}`;
    node.setAttribute('data-href', sanitizeUrl(url));
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('data-href');
  }
}

export class ReferenceFileBlot extends EmbedBlot {
  static blotName = 'referenceFile';
  static tagName = 'quill-reference-file';

  static create(value) {
    const node = super.create();
    node.setAttribute('data-ref-id', value.id);
    node.setAttribute('data-ref-src', value.src);
    node.setAttribute('data-ref-title', value.title);
    node.setAttribute('data-ref-review-interface', value.reviewInterface);
    return node;
  }

  static value(domNode) {
    return {
      id: valueFromAttribute(domNode, 'data-ref-id'),
      src: valueFromAttribute(domNode, 'data-ref-src'),
      title: valueFromAttribute(domNode, 'data-ref-title'),
      reviewInterface: valueFromAttribute(domNode, 'data-ref-review-interface')
    };
  }

  constructor(scroll, node) {
    super(scroll, node);
    const awebaseFrontendTheme = window.__awebaseReactQuillDataBridge.theme;
    ReactDOM.render(
      <ThemeProvider theme={awebaseFrontendTheme}>
        <StyledComponentsThemeProvider theme={awebaseFrontendTheme}>
          <ReferenceFilePreview
            onRemove={this.handleNodeRemove}
            src={node.src}
            reviewInterface={node.reviewInterface}
          />
        </StyledComponentsThemeProvider>
      </ThemeProvider>,
      this.contentNode
    );
  }

  handleNodeRemove = () => {
    const range = new Range(this.offset(this.scroll), this.length());
    this.scroll.deleteAt(range.index, range.length);
    this.scroll.emitter.emit(Emitter.events.SCROLL_UPDATE);
  };
}

Quill.register(LinkBlot, true);
Quill.register(BoldBlot, true);
Quill.register(ItalicBlot, true);
Quill.register(UnderlineBlot, true);
Quill.register(HeaderBlot, true);
Quill.register(ReferenceFileBlot, true);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { MtlObjBridge } from 'three/examples/jsm/loaders/obj2/bridge/MtlObjBridge.js';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import Placeholder from './Placeholder';
import { useEffectWithStatus } from '../../../../hooks/useEffectWithStatus';
import { findObjFile, findMtlFile } from '../../../../utils/zipUtils.js';

/**
 * This components loads and renders OBJ 3D Models inside a zip archive
 * that contains the .obj file and the related .mat file
 * The material name as a convention must be named with the same name
 * of the .obj file and located in the same directory.
 *
 */
const ZipObj3DAsset = props => {
  const { fileMap, basePath, centerCameraOn, theme, flatShading, handlers } = props;
  const [obj, setScene] = useState(null);

  // find the obj file
  const objFileName = findObjFile(fileMap);
  const mtlFileName = findMtlFile(fileMap);

  const manager = new THREE.LoadingManager();
  // sets the function to map the urls
  // the url parameter is are provided by
  // THREE.js as a relative path and
  // we use the map that contains the mapping
  // between the name of the file inside the zip
  // to the blob: url provided by the Zip3DAsset component.
  // TODO this function is the same of the ./ZipFbx3DAsset.js
  // remove duplicate code.
  manager.setURLModifier(url => {
    // Split the url path
    const arrayPath = url.split('/');
    // get the fileanem and query string
    const lastPath = arrayPath[arrayPath.length - 1];
    // get the file name by removing the query string part
    const filename = lastPath.split('?')[0];
    // get the url using the basePath of the 3D model
    // plus the file name and use it to map to the
    // correct blob URL
    const newUrl = fileMap[basePath + '/' + filename];
    // return a new url is a mapping was found
    // otherwise return the original url
    return newUrl ? newUrl : url;
  });

  useEffectWithStatus(status => {
    let objLoader2 = new OBJLoader2(manager);

    // check the file extension to select the appropiate loader.
    let callbackOnLoad = obj => {
      obj.traverse(function (child) {
        if (child.isMesh) {
          child.material.flatShading = flatShading;
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      status.mounted && setScene(obj);
    };

    let onLoadMtl = function (mtlParseResult) {
      const objUrl = fileMap[objFileName];
      objLoader2.addMaterials(MtlObjBridge.addMaterialsFromMtlLoader(mtlParseResult), true);
      objLoader2.load(objUrl, callbackOnLoad, null, null, null);
    };
    // If the archive contains a .mtl matching the name of .obj file
    if (mtlFileName) {
      const mtlUrl = fileMap[mtlFileName];
      // we load the mtl file and we set the callback to proceed
      // with loading the .obj file after the .mtl is loaded.
      let mtlLoader = new MTLLoader(manager);
      mtlLoader.load(mtlUrl, onLoadMtl);
    } else {
      // Otherwise we proceed to load the .obj without any
      // material file.
      const objUrl = fileMap[objFileName];
      objLoader2.load(objUrl, callbackOnLoad, null, null, null);
    }
  }, []);

  useUpdateEffect(() => {
    centerCameraOn(obj);
  }, [obj]);

  return obj ? <primitive object={obj} {...handlers} /> : <Placeholder theme={theme} />;
};

ZipObj3DAsset.propTypes = {
  src: PropTypes.string.isRequired,
  centerCameraOn: PropTypes.func.isRequired,
  flatShading: PropTypes.bool,
  handlers: PropTypes.shape({
    onPointerMove: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onClick: PropTypes.func
  })
};

export default ZipObj3DAsset;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const UpdateContractorNoteMutation = (id, content, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateContractorNoteMutation($input: UpdateContractorNoteInput!) {
        updateContractorNote(input: $input) {
          updatedContractorNote {
            id
            content
            contentEditedAt
          }
        }
      }
    `,
    variables: {
      input: { id, content }
    },
    onCompleted: (response, errors) => {
      callback(response.updateContractorNote, errors);
    }
  });
};

UpdateContractorNoteMutation.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default UpdateContractorNoteMutation;

import React from 'react';
import styled from 'styled-components';
import Dialog from '../../../../common/Dialog';
import AutoTabListWithoutRouting from '../../../../UI/AutoTabListWithoutRouting';
import UploadTab from './components/UploadTab';
import SelectExistingTab from './components/SelectExistingTabRenderer';

const Container = styled.div`
  .MuiAppBar-root,
  .MuiTabs-root {
    background: transparent;
  }

  .MuiTabs-root {
    box-shadow: inset 0px -1px 0px 0px ${props => props.theme.palette.colors.lightestGrey};
  }

  .MuiTab-root.Mui-selected {
    background: #e872181a;
  }

  [role='tabpanel'] > .MuiBox-root {
    padding: 24px 0 0 0;
  }
`;

const SelectReferenceFileDialog = props => {
  const { deliverableId, isDialogOpen, closeDialog, onSelect } = props;

  const tabProps = {
    onSelect,
    deliverableId,
    onCancel: closeDialog
  };

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title="Attach Reference"
      minWidth={600}
    >
      <Container>
        <AutoTabListWithoutRouting>
          <UploadTab label="Upload" {...tabProps} />
          <SelectExistingTab label="Select Existing" {...tabProps} />
        </AutoTabListWithoutRouting>
      </Container>
    </Dialog>
  );
};

export default SelectReferenceFileDialog;

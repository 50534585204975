/**
 * @flow
 * @relayHash 33c6c7880fd93cd4e69d9ee3a70cc6da
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type ActivateDeliverableInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type ActivateDeliverableMutationVariables = {|
  input: ActivateDeliverableInput
|};
export type ActivateDeliverableMutationResponse = {|
  +activateDeliverable: ?{|
    +updatedDeliverable: ?{|
      +state: DeliverableState
    |}
  |}
|};
export type ActivateDeliverableMutation = {|
  variables: ActivateDeliverableMutationVariables,
  response: ActivateDeliverableMutationResponse,
|};
*/


/*
mutation ActivateDeliverableMutation(
  $input: ActivateDeliverableInput!
) {
  activateDeliverable(input: $input) {
    updatedDeliverable {
      state
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ActivateDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ActivateDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "activateDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivateDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ActivateDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "activateDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivateDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ActivateDeliverableMutation",
    "id": null,
    "text": "mutation ActivateDeliverableMutation(\n  $input: ActivateDeliverableInput!\n) {\n  activateDeliverable(input: $input) {\n    updatedDeliverable {\n      state\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ccf705577e4521a9ac0cd4a2971655f8';
module.exports = node;

import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  containerColumn: {
    flexDirection: 'column'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  textMarginBottom: {
    marginBottom: '30px'
  },
  textMarginTop: {
    marginTop: '30px'
  },
  logoContainer: {
    width: '260px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '115px',
    '& svg': {
      width: '236px',
      height: '45px'
    }
  },
  darkLogo: {
    '& svg': {
      fill: theme.palette.colors.darkBlueGrey,
      '& g, & path, & polygon': {
        fill: theme.palette.colors.darkBlueGrey
      }
    }
  },
  messageBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '80px 50px',
    width: '450px',
    backgroundColor: theme.palette.colors.tableBackground,
    borderRadius: '5px'
  },
  alignCenter: {
    alignItems: 'center'
  },
  signUpFormInputField: {
    marginBottom: '20px'
  },
  formContainer: {
    width: '470px'
  },
  planContainer: {
    width: '400px'
  },
  textMarginLeft: {
    marginLeft: '5px'
  },
  orangeLink: {
    cursor: 'pointer',
    marginLeft: '5px',
    fontWeight: 'bold',
    color: theme.palette.colors.orange,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.colors.darkGrey,
      textDecoration: 'none'
    }
  },
  planName: {
    fontWeight: 'bold',
    fontSize: '16px'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  badAlert: {
    color: theme.palette.colors.red,
    marginTop: '16px',
    marginBottom: '0',
    fontSize: '12px',
    textAlign: 'center'
  },
  badAlertPlaceholder: {
    marginTop: '36px',
    marginBottom: '0px'
  }
}));

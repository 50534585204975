import { useState, useEffect } from 'react';

// initialize local storage with an expanded sidebar
!localStorage.hasOwnProperty('sidebar_expanded') &&
  localStorage.setItem('sidebar_expanded', 'true');

let listeners = [];
let isOpen = localStorage.getItem('sidebar_expanded') === 'true';

const setState = value => {
  isOpen = value;
  listeners.forEach(listener => {
    listener(isOpen);
  });
};

const useSideBar = () => {
  const newListener = useState()[1];
  useEffect(() => {
    listeners.push(newListener);
    return () => {
      listeners = listeners.filter(listener => listener !== newListener);
    };
  }, []);
  return [isOpen, setState];
};

export default useSideBar;

/**
 * @flow
 * @relayHash 7334690bdc20a63d682b15cbb9d0872b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BillingPayment_billing$ref = any;
type BillingPayment_organization$ref = any;
export type BillingPaymentRendererQueryVariables = {||};
export type BillingPaymentRendererQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: BillingPayment_organization$ref
  |},
  +$fragmentRefs: BillingPayment_billing$ref,
|};
export type BillingPaymentRendererQuery = {|
  variables: BillingPaymentRendererQueryVariables,
  response: BillingPaymentRendererQueryResponse,
|};
*/


/*
query BillingPaymentRendererQuery {
  ...BillingPayment_billing
  organization {
    ...BillingPayment_organization
    id
  }
}

fragment BillingPayment_billing on Query {
  stripePaymentDetail
}

fragment BillingPayment_organization on OrganizationNode {
  billingAddress1
  billingAddress2
  billingCity
  billingCountry {
    name
    code
  }
  billingZipcode
  billingState
  phone
  activeSubscription {
    tier
    autoRenew
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BillingPaymentRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BillingPayment_organization",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "BillingPayment_billing",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BillingPaymentRendererQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "stripePaymentDetail",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "billingAddress1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "billingAddress2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "billingCity",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "billingCountry",
            "storageKey": null,
            "args": null,
            "concreteType": "Country",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "code",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "billingZipcode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "billingState",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "activeSubscription",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationSubscriptionNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tier",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "autoRenew",
                "args": null,
                "storageKey": null
              },
              (v0/*: any*/)
            ]
          },
          (v0/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BillingPaymentRendererQuery",
    "id": null,
    "text": "query BillingPaymentRendererQuery {\n  ...BillingPayment_billing\n  organization {\n    ...BillingPayment_organization\n    id\n  }\n}\n\nfragment BillingPayment_billing on Query {\n  stripePaymentDetail\n}\n\nfragment BillingPayment_organization on OrganizationNode {\n  billingAddress1\n  billingAddress2\n  billingCity\n  billingCountry {\n    name\n    code\n  }\n  billingZipcode\n  billingState\n  phone\n  activeSubscription {\n    tier\n    autoRenew\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '74b2d94d3a9d598eb31dbf208bcea0fb';
module.exports = node;

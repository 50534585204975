import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import { Box, styled, Link as ExternalLink } from '@material-ui/core';

import SignUpOrganizationMutation from './mutations/SignUpOrganizationMutation';
import { ReactComponent as Logo } from '../../../shared/images/AWEbase-Default-Light.svg';
import FormikTextField from '../../../shared/components/form/FormikTextField';
import { FormikCheckbox } from '../../../shared/components/form/FormikCheckbox';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import greeter from '../../../shared/images/SOME_Greeter.png';
import blob from '../../../shared/images/sign-up-blobs.svg';
import { ReactComponent as CheckCircleIcon } from '../../../shared/images/check-circle.svg';
import { ReactComponent as CancelCircleIcon } from '../../../shared/images/cancel-circle.svg';
import colors from '../../../shared/styles/common/colors';
import { yupSchemas } from '../../../shared/validations';
import ReCAPTCHA from 'react-google-recaptcha';

const SignUpContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  width: '100vw',
  paddingTop: '30px',
  paddingBottom: '30px',
  backgroundColor: theme.palette.colors.grey,
  display: 'flex',
  justifyContent: 'center',
  '&>div': {
    display: 'flex',
    flexDirection: 'column',
    '& h1, h3, h4, p:not(.Mui-error), label, .MuiInputBase-root': {
      color: 'white'
    }
  },
  '&>div:first-child': {
    width: '268px',
    '&>div svg': {
      height: '70px',
      marginBottom: '-6px',
      '& g, & g path, & g polygon': {
        fill: theme.palette.colors.orange
      }
    },
    '& h3': {
      paddingBottom: '10px',
      fontSize: '18px'
    },
    '& h4': {
      fontWeight: 'normal'
    },
    '& p:not(.Mui-error), p:not(.Mui-error) a': {
      lineHeight: 1,
      fontSize: '12px'
    },
    '& form': {
      '& label': {
        marginBottom: '2px',
        '&.Mui-error': {
          color: theme.palette.colors.red
        }
      },
      '&>div:not(.MuiBox-root)': {
        paddingTop: '16px'
      },
      '&>div:last-of-type': {
        paddingBottom: '16px'
      },
      '& p.Mui-error': {
        marginTop: 0,
        marginBottom: '-15px'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset, fieldset:hover': {
          borderWidth: 0
        },
        '&.Mui-error fieldset, .Mui-focused fieldset': {
          borderWidth: '1px'
        },
        '&.Mui-error fieldset': {
          borderColor: theme.palette.colors.red
        },
        '&:not(.Mui-error).Mui-focused fieldset': {
          borderColor: theme.palette.primary.main
        }
      },
      '& input': {
        backgroundColor: '#5A5B5E'
      },
      '&>label:last-of-type': {
        display: 'flex',
        justifyContent: 'center',
        '& span': {
          fontSize: '12px'
        },
        '& span:not(.Mui-checked) span svg': {
          fill: '#5A5B5E'
        }
      },
      '& button': {
        borderRadius: 0
      },
      '& svg': {
        width: '15px',
        height: '15px'
      }
    }
  },
  '&>div:last-child': {
    width: '315px',
    paddingTop: '64px',
    alignItems: 'flex-end',
    '& h1': {
      textAlign: 'right',
      fontSize: '34px',
      width: '280px',
      '& span': {
        color: theme.palette.colors.orange
      }
    },
    '&>div': {
      display: 'flex',
      flexDirection: 'column',
      width: '310px',
      marginTop: '6px',
      alignItems: 'center',
      height: '500px',
      background: `url(${blob})`,
      backgroundSize: '274px 452px',
      backgroundPosition: '100% 100%',
      backgroundRepeat: 'no-repeat',
      '& img': {
        width: '175px',
        marginLeft: '40px'
      },
      '& div': {
        width: '200px',
        marginTop: '58px',
        marginLeft: '50px',
        '& p': {
          marginBottom: '13px',
          '&:last-child': {
            fontSize: '10px',
            width: '200px',
            marginTop: '27px'
          },
          '&:nth-of-type(2)': {
            lineHeight: 1.25,
            fontSize: '13px'
          }
        }
      }
    }
  }
}));

const SignUp = props => {
  const { history } = props;

  const [hasLetter, setHasLetter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasLength, setHasLength] = useState(false);

  const initialValues = {
    organizationName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    recaptcha: null,
    acceptTerms: false
  };

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const SignUpValidationSchema = yupSchemas.shape({
    organizationName: yupSchemas.string(true, { max: 100 }),
    firstName: yupSchemas.string(true, { max: 50 }),
    lastName: yupSchemas.string(true, { max: 50 }),
    email: yupSchemas.email(true),
    recaptcha: yupSchemas.string(true, { max: 2000 }),
    password: yupSchemas.string(true, { max: 50 })
  });

  const checkPassword = value => {
    let hasLetter = /[a-zA-Z]/;
    let hasNumber = /\d/;
    let hasSymbol = /[$-/:-?{-~!"^_`[\]@#\\£§]/;
    setHasLetter(hasLetter.test(value));
    setHasNumber(hasNumber.test(value));
    setHasSymbol(hasSymbol.test(value));
    setHasLength(value.length > 7);
  };

  const handleValidate = values => {
    const { password, repeatPassword } = values;
    const errors = {};

    if (!hasLetter || !hasNumber || !hasSymbol || !hasLength) {
      errors.password = password ? '' : 'Required';
    } else if (password !== repeatPassword) {
      errors.repeatPassword = 'Your passwords do not appear to match';
    }
    return errors;
  };

  const handleSignUp = (values, { setSubmitting, setErrors }) => {
    if (!values.acceptTerms) return;
    setSubmitting(true);
    const { organizationName, firstName, lastName, email, password, recaptcha } = values;
    SignUpOrganizationMutation(
      {
        organizationName,
        firstName,
        lastName,
        email,
        password,
        recaptcha
      },
      (response, errors) => {
        if (errors) {
          setSubmitting(false);
          setErrors(errors[0].fields);
        } else {
          history.push({
            pathname: '/verify-email',
            state: {
              referrer: '/signup/',
              orgToken: response.orgToken,
              uidb64: response.uidb64,
              email
            }
          });
        }
      }
    );
  };

  const renderFormFields = values => {
    const fieldsArray = [
      {
        name: 'organizationName',
        label: 'Organization Name',
        placeholder: 'Enter organization name...'
      },
      { name: 'firstName', label: 'First Name', placeholder: 'Enter first name...' },
      { name: 'lastName', label: 'Last Name', placeholder: 'Enter last name...' },
      { name: 'email', label: 'Email', placeholder: 'Enter email address...', type: 'email' },
      {
        name: 'password',
        label: 'Password',
        validate: checkPassword,
        placeholder: 'Enter password...',
        type: 'password'
      },
      {
        name: 'repeatPassword',
        label: 'Repeat Password',
        placeholder: 'Repeat password...',
        type: 'password'
      }
    ];
    return fieldsArray.map(field => (
      <Fragment key={field.name}>
        <Field {...field} required component={FormikTextField} fullWidth />
        {field.name === 'password' && values.password ? passwordChecklist() : null}
      </Fragment>
    ));
  };

  const passwordChecklist = () => {
    const checklistOptions = [
      { label: 'Letter', value: hasLetter },
      { label: 'Number', value: hasNumber },
      { label: 'Symbol', value: hasSymbol },
      { label: 'Length', value: hasLength }
    ];
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" pt="3px">
        {checklistOptions.map(option => (
          <Box display="flex" flexDirection="row" alignItems="center" key={option.label}>
            <Typography style={{ fontSize: '11px', paddingRight: '2px' }}>
              {option.label}
            </Typography>
            {option.value ? (
              <CheckCircleIcon fill={colors.green} />
            ) : (
              <CancelCircleIcon fill={colors.red} />
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const onReCaptchaChange = (value, setFieldValue) => {
    setFieldValue('recaptcha', value);
    console.log('ReCAPTCHA' + value);
  };

  return (
    <SignUpContainer>
      <Box>
        <Box>
          <Logo />
        </Box>
        <Typography variant="h3">Sign up for AWEbase®.</Typography>
        <Typography variant="h4">
          Enter the name of your organization and other details of your AWEbase® account below.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={SignUpValidationSchema}
          validate={handleValidate}
          onSubmit={handleSignUp}
        >
          {({ isSubmitting, values, errors, setFieldValue }) => (
            <Form>
              {renderFormFields(values)}
              <Typography variant="body1">
                By clicking the checkbox below you acknowledge that you have read and accept AWEbase
                Terms of Use available{' '}
                <ExternalLink
                  href=" https://www.awebase.com/awebase-software-terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="caption"
                >
                  here
                </ExternalLink>
              </Typography>
              <Field
                component={FormikCheckbox}
                name="acceptTerms"
                label="I accept AWEbase Terms of Use"
              />

              <ReCAPTCHA
                sitekey={`${siteKey}`}
                onChange={value => onReCaptchaChange(value, setFieldValue)}
              />

              <GlobalButton
                id="signUpSubmit"
                type="submit"
                disabled={isSubmitting || !values.acceptTerms || values.recaptcha == null}
                loading={isSubmitting}
                fullWidth
                noMargin
              >
                Sign Up
              </GlobalButton>
            </Form>
          )}
        </Formik>
      </Box>
      <Box>
        <Typography variant="h1">
          WE'RE <span>VERY</span> EXCITED TO HAVE YOU ONBOARD!
        </Typography>
        <Box>
          <img src={greeter} alt="awebase greeter" />
          <Box>
            <Typography variant="body2">
              By signing up for your free AWEbase® account, you'll be on your way to explore a
              powerful new way of managing your freelance contractor pipeline!
            </Typography>
            <Typography>
              Your FREE account<sup>*</sup> has access to all core features in AWEbase®. Paid
              upgrades and modules are available in the software settings.
            </Typography>
            <Typography>
              <sup>*</sup>Data and usage limits apply to free accounts.
            </Typography>
          </Box>
        </Box>
      </Box>
    </SignUpContainer>
  );
};

export default withRouter(SignUp);

import colors from './common/colors';

const border = `1px solid ${colors.lightestGrey}`;

export const borderRight = {
  borderRight: border
};

export const borderLeft = {
  borderLeft: border
};

export const borderTop = {
  borderTop: border
};

export const borderBottom = {
  borderBottom: border
};

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation SendReminderToContractorMutation($input: SendContractReminderToContractorInput!) {
    sendContractReminderToContractor(input: $input) {
      emailSent
    }
  }
`;

export default (jobId, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { jobId } },
    onCompleted: response => {
      callback(response.sendContractReminderToContractor);
    },
    onError: err => console.error(err)
  });
};

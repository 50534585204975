import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation MergeTagsMutation($input: MergeTagsInput!) {
    mergeTags(input: $input) {
      newTag {
        id
        name
      }
      nameExists
    }
  }
`;

const MergeTagsMutation = (ids, name, mergeIfExists, callback) => {
  const config = {
    mutation,
    variables: { input: { ids, name, mergeIfExists } },
    onCompleted: (response, errors) => {
      callback(response.mergeTags, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default MergeTagsMutation;

import React from 'react';
import Editor from './Editor';
import { RichTextPlainOps, RichTextFormattedOps, opsToRawString } from './deltaRenderer';

export const richTextValueToRawString = opsToRawString;

export const richTextValueToJsonString = value => {
  const isEmpty =
    value.ops
      .map(op => op.insert)
      .join()
      .trim().length === 0;
  return isEmpty
    ? ''
    : JSON.stringify({
        version: 1.0,
        content: value
      });
};

export const mapReferenceFilesToRichTextJson = (content, files) => {
  if (!files) return content;

  const contentJson = richTextValueFromJsonString(content);
  if (contentJson) {
    const newOps = contentJson.ops.map(op => {
      if (typeof op.insert === 'object' && op.insert.referenceFile) {
        const fileId = op.insert.referenceFile.id;
        const upToDateFile = files.find(file => file.id === fileId);
        return {
          insert: {
            referenceFile: upToDateFile
              ? {
                  id: fileId,
                  title: upToDateFile.title,
                  description: upToDateFile.description,
                  src: upToDateFile.fileUrl,
                  reviewInterface: upToDateFile.reviewInterface
                }
              : { id: fileId }
          }
        };
      } else {
        return op;
      }
    });
    return richTextValueToJsonString({ ops: newOps });
  } else {
    return content;
  }
};

export const richTextValueFromJsonString = value => {
  try {
    const json = JSON.parse(value);
    return json.content;
  } catch (e) {
    return null;
  }
};

export const RichTextFormattedValue = ({ value, plain = false }) => {
  const richTextValue = richTextValueFromJsonString(value);

  if (richTextValue) {
    const Component = plain ? RichTextPlainOps : RichTextFormattedOps;
    return <Component ops={richTextValue.ops} />;
  } else {
    return value;
  }
};

export default Editor;

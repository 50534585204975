/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CreatePaymentRequestDialog_job$ref: FragmentReference;
declare export opaque type CreatePaymentRequestDialog_job$fragmentType: CreatePaymentRequestDialog_job$ref;
export type CreatePaymentRequestDialog_job = {|
  +id: string,
  +jobId: ?number,
  +name: string,
  +balance: ?any,
  +totalValue: number,
  +currencyCode: ?string,
  +conversionRateInPreferredCurrency: ?any,
  +contractor: {|
    +id: string,
    +fullName: ?string,
    +representativeImageUrl: ?string,
    +currency: ?string,
    +organization: {|
      +financeEmail: ?string,
      +email: string,
    |},
  |},
  +contract: ?{|
    +id: string
  |},
  +$refType: CreatePaymentRequestDialog_job$ref,
|};
export type CreatePaymentRequestDialog_job$data = CreatePaymentRequestDialog_job;
export type CreatePaymentRequestDialog_job$key = {
  +$data?: CreatePaymentRequestDialog_job$data,
  +$fragmentRefs: CreatePaymentRequestDialog_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CreatePaymentRequestDialog_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "balance",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currencyCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "conversionRateInPreferredCurrency",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contractor",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractorNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "representativeImageUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currency",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "OrganizationNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "financeEmail",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contract",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractNode",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '84233f98ce2c02c09234c9813d7c942c';
module.exports = node;

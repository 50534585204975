import React, { useState, useEffect } from 'react';
import { withRouter, Route } from 'react-router-dom';
import FullscreenModal from '../../../fullscreenModal/FullscreenModal';
import AnnotationView from '../../AnnotationView';
import { isAssetWithMarkup } from '../helpers';

const MarkupRoute = props => {
  const {
    deliverable,
    selectedAsset,
    canAddComment,
    canAddReaction,
    history,
    location,
    match
  } = props;
  const [isMarkupOpen, setMarkupOpen] = useState(false);

  useEffect(() => {
    const isMarkupOpen = location.pathname.includes('/markup');
    setMarkupOpen(isMarkupOpen);
  }, [location.pathname]);

  const closeMarkup = () => {
    history.push(match.url);
  };

  return (
    <Route
      path={`${match.url}/:revisionNumber/markup`}
      render={routerProps =>
        selectedAsset ? (
          <FullscreenModal open={isMarkupOpen} noTopBar>
            <AnnotationView
              deliverable={deliverable}
              assetId={selectedAsset.node.id}
              closeModal={closeMarkup}
              reviewInterface={selectedAsset.node.reviewInterface}
              deliverableId={deliverable.id}
              canAddCommentInReviewTab={canAddComment}
              canViewMarkup={isAssetWithMarkup(selectedAsset)}
              canAddReaction={canAddReaction}
              {...routerProps}
            />
          </FullscreenModal>
        ) : null
      }
    />
  );
};

export default withRouter(MarkupRoute);

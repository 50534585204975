import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid } from '@material-ui/core';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import graphql from 'babel-plugin-relay/macro';
import { fetchQuery } from 'react-relay';

import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import UpdateOrganizationConfigurationMutation from '../mutations/UpdateOrganizationConfigurationMutation';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import CurrencyCodeDropdown from '../../../../../shared/components/form/dropdowns/CurrencyCodeDropdown';
import DateDisplayDropdown from '../../../../../shared/components/form/dropdowns/DateDisplayDropdown';
import FormikRadioGroupField from '../../../../../shared/components/form/FormikRadioGroupField';
import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import { getEnvironment } from '../../../../../shared/utils/helpers';
import { useEffectWithStatus } from '../../../../../shared/hooks/useEffectWithStatus';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import { Container, InputContainer, TableContainer, TableLoadingMessage } from './styled';
import Date from '../../../../../shared/components/common/Date';
import Detail from '../../../../../shared/components/UI/Detail';

const currencyRatesQuery = graphql`
  query RegionalUICurrencyRatesQuery {
    currencyRates {
      currencyCode
      rateDate
      rate
    }
  }
`;

const RegionalUI = props => {
  const {
    userContext: {
      setUserInfo,
      orgStaff: { organization }
    }
  } = props;
  const classes = useDetailsStyles();
  const [currencyRates, setCurrencyRates] = useState(null);

  useEffectWithStatus(
    status => {
      if (!organization) return false;

      setCurrencyRates(null);

      fetchQuery(getEnvironment(), currencyRatesQuery).then(response => {
        status.mounted && setCurrencyRates(response.currencyRates);
      });
    },
    [get(organization, 'currencyCode')]
  );

  if (!organization) {
    return <div>Something went wrong!</div>;
  }

  const handleCurrencySubmit = value => {
    handleSubmit({ currencyCode: value });
  };

  const handleDateSubmit = value => {
    handleSubmit({ dateDisplay: value });
  };

  const handleTimeSubmit = (e, form) => {
    form.setFieldValue('timeDisplay', e.target.value);
    handleSubmit({ timeDisplay: e.target.value });
  };

  const handleCommisionSubmit = debounce(value => {
    if (value <= 100) {
      handleSubmit({
        currencyExchangeCommission: value || '0'
      });
    }
  }, 200);

  const handleSubmit = variables => {
    UpdateOrganizationConfigurationMutation(variables, response => {
      if (response && response.isUpdated && response.organization) {
        setUserInfo({
          ...props.userContext,
          orgStaff: { ...props.userContext.orgStaff, organization: response.organization }
        });
      }
    });
  };

  const validate = values => {
    const { currencyExchangeCommission } = values;
    if (currencyExchangeCommission > 100) {
      return { currencyExchangeCommission: 'Commission value cannot be more than 100%.' };
    }
    if (currencyExchangeCommission < 0) {
      return { currencyExchangeCommission: 'Commission value cannot be less than 0%.' };
    }
    return {};
  };

  return (
    <Formik
      initialValues={{
        currencyCode: organization.currencyCode,
        dateDisplay: JSON.parse(organization.configuration).date_display,
        timeDisplay: JSON.parse(organization.configuration).time_display,
        currencyExchangeCommission: JSON.parse(organization.configuration)
          .currency_exchange_commission
      }}
      validate={validate}
    >
      {({ setFieldValue, setFieldTouched }) => (
        <Form>
          <Container className={classes.detailsContentNoTabs}>
            <Grid item>
              <DetailsSection title="Regional UI Customizations" noBorder>
                <Field
                  component={CurrencyCodeDropdown}
                  name="currencyCode"
                  label="Home Currency"
                  onChange={e => {
                    setFieldValue('currencyCode', e.target.value);
                    handleCurrencySubmit(e.target.value);
                  }}
                  noEmptyValue
                  className={classes.formFieldNoMargin}
                />
                <Field
                  component={DateDisplayDropdown}
                  name="dateDisplay"
                  label="Date Display"
                  noEmptyValue
                  className={classes.formFieldNoMargin}
                  onChange={e => {
                    setFieldValue('dateDisplay', e.target.value);
                    handleDateSubmit(e.target.value);
                  }}
                />
                <Field
                  component={FormikRadioGroupField}
                  name="timeDisplay"
                  options={[
                    { name: '12-hour', value: '12' },
                    { name: '24-hour', value: '24' }
                  ]}
                  onChangeHandler={handleTimeSubmit}
                  label="Time Display"
                />
              </DetailsSection>
            </Grid>

            <DetailsSection title="Exchange Rates" noBorder>
              <InputContainer>
                <Field
                  component={FormikTextField}
                  fullWidth
                  name="currencyExchangeCommission"
                  type="number"
                  label="Exchange Rate Commision"
                  decoratorEnd="%"
                  step="0.0001"
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('currencyExchangeCommission', value);
                    setFieldTouched('currencyExchangeCommission');
                    handleCommisionSubmit(value);
                  }}
                />
              </InputContainer>

              <TableContainer>
                {currencyRates ? (
                  <>
                    <Grid item>
                      <AutoTable
                        rowTemplate={[
                          { name: 'currencyCode', label: 'Currency' },
                          { name: 'rate', label: 'Rate' }
                        ]}
                        edges={currencyRates.map((item, i) => ({ node: { ...item, id: i } }))}
                      />
                    </Grid>
                    <Grid item>
                      <Detail
                        name="Updated"
                        renderValue={() => <Date date={currencyRates[0].rateDate} />}
                      />
                      <Detail
                        name="Source"
                        value="European Central Bank"
                        valueContainerProps={{ variant: 'body1' }}
                      />
                    </Grid>
                  </>
                ) : (
                  <TableLoadingMessage>Loding exchange rates...</TableLoadingMessage>
                )}
              </TableContainer>
            </DetailsSection>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(RegionalUI);

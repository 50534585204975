/**
 * @flow
 * @relayHash 7ffdbbb2404bad700778382797ff82e2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobsList_jobs$ref = any;
export type JobsListContainerRefetchQueryVariables = {|
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
  jobDeadlineFrom?: ?any,
  jobDeadlineTo?: ?any,
  jobId?: ?string,
  releaseId?: ?string,
  productId?: ?string,
  category?: ?string,
  status?: ?string,
  paymentStatus?: ?string,
  includeInactive?: ?boolean,
  contractorId?: ?string,
  staffId?: ?string,
  name?: ?string,
  onlyFollowed?: ?boolean,
|};
export type JobsListContainerRefetchQueryResponse = {|
  +$fragmentRefs: JobsList_jobs$ref
|};
export type JobsListContainerRefetchQuery = {|
  variables: JobsListContainerRefetchQueryVariables,
  response: JobsListContainerRefetchQueryResponse,
|};
*/


/*
query JobsListContainerRefetchQuery(
  $first: Int
  $after: String
  $orderBy: String
  $jobDeadlineFrom: Date
  $jobDeadlineTo: Date
  $jobId: String
  $releaseId: String
  $productId: String
  $category: ID
  $status: String
  $paymentStatus: String
  $includeInactive: Boolean
  $contractorId: String
  $staffId: String
  $name: String
  $onlyFollowed: Boolean
) {
  ...JobsList_jobs
}

fragment JobsList_jobs on Query {
  allJobs(first: $first, after: $after, orderBy: $orderBy, jobDeadlineFrom: $jobDeadlineFrom, jobDeadlineTo: $jobDeadlineTo, onlyFollowed: $onlyFollowed, jobId: $jobId, releaseId: $releaseId, productId: $productId, category: $category, status: $status, paymentStatus: $paymentStatus, includeInactive: $includeInactive, contractorId: $contractorId, staffId: $staffId, jobName: $name) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        created
        jobId
        jobDeadline
        status
        name
        releases {
          id
          name
          product {
            id
            title
          }
        }
        isCurrentUserFollowing
        deliverableCategory {
          name
          id
        }
        contractor {
          id
          fullName
          representativeImageUrl
        }
        staffCoordinator {
          id
          fullName
          representativeImageUrl
        }
        totalValue
        currencyCode
        paymentStatus
        hasInvoicesToReview
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadlineFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobDeadlineTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "releaseId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "productId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "paymentStatus",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractorId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "staffId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "onlyFollowed",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JobsListContainerRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "JobsList_jobs",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "JobsListContainerRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allJobs",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "category",
            "variableName": "category"
          },
          {
            "kind": "Variable",
            "name": "contractorId",
            "variableName": "contractorId"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "includeInactive",
            "variableName": "includeInactive"
          },
          {
            "kind": "Variable",
            "name": "jobDeadlineFrom",
            "variableName": "jobDeadlineFrom"
          },
          {
            "kind": "Variable",
            "name": "jobDeadlineTo",
            "variableName": "jobDeadlineTo"
          },
          {
            "kind": "Variable",
            "name": "jobId",
            "variableName": "jobId"
          },
          {
            "kind": "Variable",
            "name": "jobName",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "onlyFollowed",
            "variableName": "onlyFollowed"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          },
          {
            "kind": "Variable",
            "name": "paymentStatus",
            "variableName": "paymentStatus"
          },
          {
            "kind": "Variable",
            "name": "productId",
            "variableName": "productId"
          },
          {
            "kind": "Variable",
            "name": "releaseId",
            "variableName": "releaseId"
          },
          {
            "kind": "Variable",
            "name": "staffId",
            "variableName": "staffId"
          },
          {
            "kind": "Variable",
            "name": "status",
            "variableName": "status"
          }
        ],
        "concreteType": "JobNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "created",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "jobId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "jobDeadline",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "releases",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ReleaseNode",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "product",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isCurrentUserFollowing",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "deliverableCategory",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contractor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNode",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "staffCoordinator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffNode",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalValue",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "currencyCode",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "paymentStatus",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasInvoicesToReview",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "JobsListContainerRefetchQuery",
    "id": null,
    "text": "query JobsListContainerRefetchQuery(\n  $first: Int\n  $after: String\n  $orderBy: String\n  $jobDeadlineFrom: Date\n  $jobDeadlineTo: Date\n  $jobId: String\n  $releaseId: String\n  $productId: String\n  $category: ID\n  $status: String\n  $paymentStatus: String\n  $includeInactive: Boolean\n  $contractorId: String\n  $staffId: String\n  $name: String\n  $onlyFollowed: Boolean\n) {\n  ...JobsList_jobs\n}\n\nfragment JobsList_jobs on Query {\n  allJobs(first: $first, after: $after, orderBy: $orderBy, jobDeadlineFrom: $jobDeadlineFrom, jobDeadlineTo: $jobDeadlineTo, onlyFollowed: $onlyFollowed, jobId: $jobId, releaseId: $releaseId, productId: $productId, category: $category, status: $status, paymentStatus: $paymentStatus, includeInactive: $includeInactive, contractorId: $contractorId, staffId: $staffId, jobName: $name) {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        created\n        jobId\n        jobDeadline\n        status\n        name\n        releases {\n          id\n          name\n          product {\n            id\n            title\n          }\n        }\n        isCurrentUserFollowing\n        deliverableCategory {\n          name\n          id\n        }\n        contractor {\n          id\n          fullName\n          representativeImageUrl\n        }\n        staffCoordinator {\n          id\n          fullName\n          representativeImageUrl\n        }\n        totalValue\n        currencyCode\n        paymentStatus\n        hasInvoicesToReview\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5460ecd9641732b06941c084bad76c9';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

/**
 *
 * @param {string} commentId - ID of the comment to be deleted
 * @param {*} callback - Callback that receives 'response' and 'errors' as arguments
 */

const DeleteReviewAssetCommentMutation = (commentId, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation DeleteReviewAssetCommentMutation($input: DeleteReviewAssetCommentInput!) {
        deleteReviewAssetComment(input: $input) {
          isDeleted
          reviewAsset {
            reviewAssetAnnotation {
              id
              annotationData
            }
            comments {
              edges {
                node {
                  id
                  created
                  content
                  extraData
                  objectId
                  contentEditedAt
                  user {
                    id
                    fullName
                    representativeImageUrl
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: {
        id: commentId
      }
    },
    onCompleted: (response, errors) => {
      callback(response.deleteReviewAssetComment, errors);
    }
  });
};

DeleteReviewAssetCommentMutation.propTypes = {
  commentId: PropTypes.string.isRequired,
  callback: PropTypes.func
};

export default DeleteReviewAssetCommentMutation;

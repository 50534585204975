import React, { Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import clsx from 'clsx';
import { withRouter } from 'react-router';

import QueryRenderer from '../../../shared/relay/QueryRenderer';
import CategoryTitleHeader from '../../../shared/components/UI/CategoryTitleHeader';
import AutoTabList from '../../../shared/components/UI/AutoTabList';
import JobHistory from './JobHistory';
import OrganizationStaff from './OrganizationStaff';
import OrganizationDetails from './OrganizationDetails';
import OrganizationDetailsPending from './OrganizationDetailsPending';

import { ReactComponent as OrganizationsIcon } from '../../../shared/images/organizations.svg';
import { useDetailsStyles } from '../../../shared/styles/common/useDetailsStyles';
import { ORGANIZATION_STATUS_FOR_FREELANCER, PAGINATION_TYPES } from '../../../shared/constants';

const OrganizationDetailsPage = props => {
  const classes = useDetailsStyles();

  return (
    <QueryRenderer
      query={graphql`
        query OrganizationDetailsPageQuery(
          $id: ID!
          $first: Int
          $orderBy: String
          $after: String
        ) {
          ...JobHistory_organization
            @arguments(id: $id, first: $first, orderBy: $orderBy, after: $after)
          ...OrganizationStaff_organization
            @arguments(id: $id, first: $first, orderBy: $orderBy, after: $after)
          ...OrganizationDetails_organization @arguments(id: $id)
          ...OrganizationDetailsPending_organization @arguments(id: $id)
          organization(id: $id) {
            name
            status
          }
          jobStatus: __type(name: "JobStatus") {
            ...JobHistory_statusTypes
          }
        }
      `}
      variables={{ id: props.match.params.id, first: PAGINATION_TYPES.default.defaultValue }}
      render={relayProps => (
        <Fragment>
          <div className={clsx(classes.topSectionHeightSmall, classes.marginTop)}>
            <CategoryTitleHeader
              title={relayProps.organization.name}
              icon={OrganizationsIcon}
              link="/organizations/organizations"
            />
          </div>
          {relayProps.organization.status ===
          ORGANIZATION_STATUS_FOR_FREELANCER.pendingInvitation ? (
            <OrganizationDetailsPending organization={relayProps} />
          ) : (
            <AutoTabList>
              <OrganizationDetails label="Details" organization={relayProps} />
              <JobHistory
                organization={relayProps}
                statusTypes={relayProps.jobStatus}
                label="Job History"
              />
              <OrganizationStaff organization={relayProps} label="Organization Staff" />
            </AutoTabList>
          )}
        </Fragment>
      )}
    />
  );
};

export default withRouter(OrganizationDetailsPage);

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Grid, Box } from '@material-ui/core';
import { withRouter, Switch, Route } from 'react-router-dom';
import QueryRenderer from '../../../../../../shared/relay/QueryRenderer';
import { borderLeft } from '../../../../../../shared/styles/borders';
import Header from './Header';
import Tabs from './Tabs';
import PaymentRequestsList from './PaymentRequestsList';
import InvoicesList from './InvoicesList';

const PaymentRequests = ({ jobId, match }) => (
  <QueryRenderer
    query={graphql`
      query PaymentRequestsQuery($id: ID!) {
        job(id: $id) {
          ...Header_job
          ...Tabs_job
        }
      }
    `}
    variables={{
      id: jobId
    }}
    render={relayProps => (
      <>
        <Header job={relayProps.job} />
        <Grid container>
          <Grid item xs={3}>
            <Box mr={4} ml={1}>
              <Tabs job={relayProps.job} />
            </Box>
          </Grid>
          <Grid item xs={9} style={borderLeft}>
            <Box ml={4}>
              <Switch>
                <Route
                  path={`${match.path}`}
                  exact
                  render={() => <PaymentRequestsList jobId={jobId} />}
                />
                <Route
                  path={`${match.path}/invoices/:id?`}
                  render={() => <InvoicesList jobId={jobId} />}
                />
              </Switch>
            </Box>
          </Grid>
        </Grid>
      </>
    )}
  />
);

export default withRouter(PaymentRequests);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation UpdateCategoryMutation($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      updatedCategory {
        id
        name
        categoryTypes {
          edges {
            node {
              id
              name
              numberOfDeliverables
            }
          }
        }
        steps {
          edges {
            node {
              id
              name
              order
              numberOfDeliverables
            }
          }
        }
      }
    }
  }
`;

const UpdateCategoryMutation = (input, callback) => {
  const config = {
    mutation,
    variables: { input },
    onCompleted: (response, errors) => {
      callback(response.updateCategory, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default UpdateCategoryMutation;

UpdateCategoryMutation.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  categoryTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      replacementFor: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      replacementFor: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

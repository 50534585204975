import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';
import Amount from '../common/Amount';
import AmountTrend from '../UI/AmountTrend';
import { Box, Typography } from '@material-ui/core';
import CustomTableTooltip from './CustomTableTooltip';

const TableCellAmount = ({
  amount,
  trend,
  hideTrend,
  currencyCode,
  customTooltipText,
  subscriptAmount,
  ...otherProps
}) => (
  <StyledTableCell align="right" noWrap {...otherProps}>
    <CustomTableTooltip title={customTooltipText || ''}>
      <Box display="inline-flex" flexDirection="column">
        {amount || amount === 0 ? <Amount amount={amount} currencyCode={currencyCode} /> : '-'}
        {(trend || trend === 0) && !hideTrend && <AmountTrend value={trend} type="cell" />}
        {(subscriptAmount || subscriptAmount === 0) && (
          <Typography variant="overline">
            <Amount amount={subscriptAmount} />
          </Typography>
        )}
      </Box>
    </CustomTableTooltip>
  </StyledTableCell>
);

TableCellAmount.propTypes = {
  // pass, when want to show different currency than organization's
  currencyCode: PropTypes.string,
  hideTrend: PropTypes.bool
};

TableCellAmount.defaultProps = {
  hideTrend: false
};

export default TableCellAmount;

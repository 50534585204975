import React from 'react';
import useDialog from '../hooks/useDialog';

const withDialogProps = WrappedComponent => {
  const ComponentWithDialogProps = props => {
    const [isDialogOpen, toggleDialogOpen] = useDialog();
    return (
      <WrappedComponent
        {...props}
        isDialogOpen={isDialogOpen}
        toggleDialogOpen={toggleDialogOpen}
      />
    );
  };

  return ComponentWithDialogProps;
};

export default withDialogProps;

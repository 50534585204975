/**
 * @flow
 * @relayHash dbc1805ae14f85c0236add8ab7b34e35
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractorDeliverablesList_contractor$ref = any;
export type ContractorDeliverablesListRefetchQueryVariables = {|
  id: string,
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
  dueDateFrom?: ?any,
  dueDateTo?: ?any,
  category?: ?string,
  state?: ?string,
  title?: ?string,
  tags?: ?string,
  includeClosed?: ?boolean,
  includeCanceled?: ?boolean,
  includeInactive?: ?boolean,
  releaseId?: ?string,
  productId?: ?string,
  categoryType?: ?string,
  assignedToStaffOrContractor?: ?string,
  staffCoordinator?: ?string,
|};
export type ContractorDeliverablesListRefetchQueryResponse = {|
  +contractor: ?{|
    +$fragmentRefs: ContractorDeliverablesList_contractor$ref
  |}
|};
export type ContractorDeliverablesListRefetchQuery = {|
  variables: ContractorDeliverablesListRefetchQueryVariables,
  response: ContractorDeliverablesListRefetchQueryResponse,
|};
*/


/*
query ContractorDeliverablesListRefetchQuery(
  $id: ID!
  $first: Int
  $after: String
  $orderBy: String
  $dueDateFrom: Date
  $dueDateTo: Date
  $category: ID
  $state: String
  $title: String
  $tags: String
  $includeClosed: Boolean
  $includeCanceled: Boolean
  $includeInactive: Boolean
  $releaseId: String
  $productId: String
  $categoryType: ID
  $assignedToStaffOrContractor: String
  $staffCoordinator: String
) {
  contractor(id: $id) {
    ...ContractorDeliverablesList_contractor
    id
  }
}

fragment ContractorDeliverablesList_contractor on ContractorNode {
  id
  assignedDeliverables(first: $first, after: $after, orderBy: $orderBy, dueDateFrom: $dueDateFrom, dueDateTo: $dueDateTo, category: $category, state: $state, title: $title, releaseId: $releaseId, productId: $productId, assignedToStaffOrContractor: $assignedToStaffOrContractor, staffCoordinator: $staffCoordinator, tags: $tags, includeClosed: $includeClosed, includeCanceled: $includeCanceled, includeInactive: $includeInactive, categoryType: $categoryType) {
    edgeCount
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        title
        category {
          name
          id
        }
        categoryType {
          name
          id
        }
        state
        amount
        currencyCode
        dueDate
        release {
          id
          name
          product {
            id
            title
          }
        }
        assignedStaff {
          user {
            id
          }
          fullName
          representativeImageUrl
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dueDateFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dueDateTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "state",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tags",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeClosed",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeCanceled",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "releaseId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "productId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categoryType",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "assignedToStaffOrContractor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "staffCoordinator",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractorDeliverablesListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractorDeliverablesList_contractor",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractorDeliverablesListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignedDeliverables",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "assignedToStaffOrContractor",
                "variableName": "assignedToStaffOrContractor"
              },
              {
                "kind": "Variable",
                "name": "category",
                "variableName": "category"
              },
              {
                "kind": "Variable",
                "name": "categoryType",
                "variableName": "categoryType"
              },
              {
                "kind": "Variable",
                "name": "dueDateFrom",
                "variableName": "dueDateFrom"
              },
              {
                "kind": "Variable",
                "name": "dueDateTo",
                "variableName": "dueDateTo"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "includeCanceled",
                "variableName": "includeCanceled"
              },
              {
                "kind": "Variable",
                "name": "includeClosed",
                "variableName": "includeClosed"
              },
              {
                "kind": "Variable",
                "name": "includeInactive",
                "variableName": "includeInactive"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              },
              {
                "kind": "Variable",
                "name": "productId",
                "variableName": "productId"
              },
              {
                "kind": "Variable",
                "name": "releaseId",
                "variableName": "releaseId"
              },
              {
                "kind": "Variable",
                "name": "staffCoordinator",
                "variableName": "staffCoordinator"
              },
              {
                "kind": "Variable",
                "name": "state",
                "variableName": "state"
              },
              {
                "kind": "Variable",
                "name": "tags",
                "variableName": "tags"
              },
              {
                "kind": "Variable",
                "name": "title",
                "variableName": "title"
              }
            ],
            "concreteType": "DeliverableNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryNode",
                        "plural": false,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "categoryType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryTypeNode",
                        "plural": false,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currencyCode",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dueDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignedStaff",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "representativeImageUrl",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractorDeliverablesListRefetchQuery",
    "id": null,
    "text": "query ContractorDeliverablesListRefetchQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n  $orderBy: String\n  $dueDateFrom: Date\n  $dueDateTo: Date\n  $category: ID\n  $state: String\n  $title: String\n  $tags: String\n  $includeClosed: Boolean\n  $includeCanceled: Boolean\n  $includeInactive: Boolean\n  $releaseId: String\n  $productId: String\n  $categoryType: ID\n  $assignedToStaffOrContractor: String\n  $staffCoordinator: String\n) {\n  contractor(id: $id) {\n    ...ContractorDeliverablesList_contractor\n    id\n  }\n}\n\nfragment ContractorDeliverablesList_contractor on ContractorNode {\n  id\n  assignedDeliverables(first: $first, after: $after, orderBy: $orderBy, dueDateFrom: $dueDateFrom, dueDateTo: $dueDateTo, category: $category, state: $state, title: $title, releaseId: $releaseId, productId: $productId, assignedToStaffOrContractor: $assignedToStaffOrContractor, staffCoordinator: $staffCoordinator, tags: $tags, includeClosed: $includeClosed, includeCanceled: $includeCanceled, includeInactive: $includeInactive, categoryType: $categoryType) {\n    edgeCount\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n    edges {\n      node {\n        id\n        title\n        category {\n          name\n          id\n        }\n        categoryType {\n          name\n          id\n        }\n        state\n        amount\n        currencyCode\n        dueDate\n        release {\n          id\n          name\n          product {\n            id\n            title\n          }\n        }\n        assignedStaff {\n          user {\n            id\n          }\n          fullName\n          representativeImageUrl\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dd69e6ffca944596b697487a1ac0629';
module.exports = node;

/**
 * @flow
 * @relayHash d255c3c8300cf951e810e2e7b8134d16
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractTemplatesList_contractTemplates$ref = any;
type ContractTemplatesList_stateTypes$ref = any;
export type ContractTemplatesPageQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
  excludeInactive?: ?boolean,
|};
export type ContractTemplatesPageQueryResponse = {|
  +__type: ?{|
    +$fragmentRefs: ContractTemplatesList_stateTypes$ref
  |},
  +$fragmentRefs: ContractTemplatesList_contractTemplates$ref,
|};
export type ContractTemplatesPageQuery = {|
  variables: ContractTemplatesPageQueryVariables,
  response: ContractTemplatesPageQueryResponse,
|};
*/


/*
query ContractTemplatesPageQuery(
  $first: Int
  $orderBy: String
  $after: String
  $excludeInactive: Boolean
) {
  ...ContractTemplatesList_contractTemplates
  __type(name: "ContractTemplateState") {
    ...ContractTemplatesList_stateTypes
  }
}

fragment ContractTemplatesList_contractTemplates on Query {
  contractTemplatesByOrg(first: $first, orderBy: $orderBy, after: $after, excludeInactive: $excludeInactive) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        name
        created
        createdBy {
          fullName
          representativeImageUrl
          id
        }
        state
        categories(orderBy: "name") {
          edges {
            node {
              name
              id
            }
          }
        }
      }
    }
  }
}

fragment ContractTemplatesList_stateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "excludeInactive",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "ContractTemplateState"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractTemplatesPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"ContractTemplateState\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractTemplatesList_stateTypes",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "ContractTemplatesList_contractTemplates",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractTemplatesPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractTemplatesByOrg",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "excludeInactive",
            "variableName": "excludeInactive"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
          }
        ],
        "concreteType": "ContractTemplateNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractTemplateNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractTemplateNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "created",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createdBy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "representativeImageUrl",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "state",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "categories",
                    "storageKey": "categories(orderBy:\"name\")",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": "name"
                      }
                    ],
                    "concreteType": "CategoryNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CategoryNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"ContractTemplateState\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractTemplatesPageQuery",
    "id": null,
    "text": "query ContractTemplatesPageQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n  $excludeInactive: Boolean\n) {\n  ...ContractTemplatesList_contractTemplates\n  __type(name: \"ContractTemplateState\") {\n    ...ContractTemplatesList_stateTypes\n  }\n}\n\nfragment ContractTemplatesList_contractTemplates on Query {\n  contractTemplatesByOrg(first: $first, orderBy: $orderBy, after: $after, excludeInactive: $excludeInactive) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        name\n        created\n        createdBy {\n          fullName\n          representativeImageUrl\n          id\n        }\n        state\n        categories(orderBy: \"name\") {\n          edges {\n            node {\n              name\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ContractTemplatesList_stateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24e32761732dcdd771b283d9f8a58a6e';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeliverableDetailsToggleFollowButton_deliverable$ref: FragmentReference;
declare export opaque type DeliverableDetailsToggleFollowButton_deliverable$fragmentType: DeliverableDetailsToggleFollowButton_deliverable$ref;
export type DeliverableDetailsToggleFollowButton_deliverable = {|
  +id: string,
  +isCurrentUserFollowing: ?boolean,
  +followers: ?{|
    +edges?: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
      |}
    |}>,
    +totalCount: ?number,
  |},
  +$refType: DeliverableDetailsToggleFollowButton_deliverable$ref,
|};
export type DeliverableDetailsToggleFollowButton_deliverable$data = DeliverableDetailsToggleFollowButton_deliverable;
export type DeliverableDetailsToggleFollowButton_deliverable$key = {
  +$data?: DeliverableDetailsToggleFollowButton_deliverable$data,
  +$fragmentRefs: DeliverableDetailsToggleFollowButton_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DeliverableDetailsToggleFollowButton_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "withFollowers",
      "type": "Boolean",
      "defaultValue": null
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isCurrentUserFollowing",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "followers",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "Condition",
          "passingValue": true,
          "condition": "withFollowers",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "StaffNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8ed60df09b6794f87c53c71654a7ae63';
module.exports = node;

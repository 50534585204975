import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import RolesList from './RolesList';

const RolesTab = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query RolesTabQuery($first: Int, $after: String) {
          ...RolesList_roles @arguments(first: $first)
        }
      `}
      variables={variables}
      render={relayProps => <RolesList roles={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(RolesTab, 'adminRolesList');

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Budget_release$ref: FragmentReference;
declare export opaque type Budget_release$fragmentType: Budget_release$ref;
export type Budget_release = {|
  +id: string,
  +budgetInfo: ?$ReadOnlyArray<?{|
    +category: ?{|
      +id: string,
      +name: string,
    |},
    +budgetAmount: ?any,
  |}>,
  +categoryInfo: ?$ReadOnlyArray<?{|
    +category: ?{|
      +id: string,
      +name: string,
    |},
    +pendingAmount: ?any,
    +contractedAmount: ?any,
    +originalPendingAmount: ?any,
    +originalContractedAmount: ?any,
    +hasFxDeliverables: ?boolean,
  |}>,
  +exchangeRateDate: ?any,
  +deliverablesTotalAmount: ?any,
  +deliverablesTotalOriginalAmount: ?any,
  +$refType: Budget_release$ref,
|};
export type Budget_release$data = Budget_release;
export type Budget_release$key = {
  +$data?: Budget_release$data,
  +$fragmentRefs: Budget_release$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryNode",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "Budget_release",
  "type": "ReleaseNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetInfo",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "budgetAmount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "categoryInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "ReleaseCategoryInfo",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pendingAmount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contractedAmount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "originalPendingAmount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "originalContractedAmount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hasFxDeliverables",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "exchangeRateDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deliverablesTotalAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deliverablesTotalOriginalAmount",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fd805ce685f9da736979abdb16f9b5fa';
module.exports = node;

/**
 * @flow
 * @relayHash 7fba3984ddbbf9a1d78c0777eb2f6d0e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddressInfo_contractor$ref = any;
type ContractorDetailsContent_contractor$ref = any;
type ContractorInfo_contractor$ref = any;
type Notes_contractor$ref = any;
export type ContractorDetailsTabQueryVariables = {|
  id: string
|};
export type ContractorDetailsTabQueryResponse = {|
  +contractor: ?{|
    +$fragmentRefs: ContractorDetailsContent_contractor$ref & ContractorInfo_contractor$ref & AddressInfo_contractor$ref & Notes_contractor$ref
  |}
|};
export type ContractorDetailsTabQuery = {|
  variables: ContractorDetailsTabQueryVariables,
  response: ContractorDetailsTabQueryResponse,
|};
*/


/*
query ContractorDetailsTabQuery(
  $id: ID!
) {
  contractor(id: $id) {
    ...ContractorDetailsContent_contractor
    ...ContractorInfo_contractor
    ...AddressInfo_contractor
    ...Notes_contractor
    id
  }
}

fragment ContractorDetailsContent_contractor on ContractorNode {
  representativeImageUrl
  fullName
  internalId
  doNotHire
  isActive
  coverImageUrl
  additionalInfo
  conflictingContractor {
    id
  }
}

fragment ContractorInfo_contractor on ContractorNode {
  id
  currency
  firstName
  lastName
  fullName
  nickname
  title
  email
  portfolioUrl
  phone
  timezone
  timezoneList
  companyName
  internalId
  conflictingContractor {
    id
    fullName
    representativeImageUrl
  }
  freelancer {
    id
  }
}

fragment AddressInfo_contractor on ContractorNode {
  address1
  address2
  city
  state
  country {
    name
  }
  zipcode
  freelancer {
    id
  }
}

fragment Notes_contractor on ContractorNode {
  id
  contractorNotes {
    edges {
      node {
        id
        created
        staff {
          user {
            id
            fullName
          }
          representativeImageUrl
          id
        }
        content
        contentEditedAt
        repliedTo {
          content
          created
          user {
            fullName
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractorDetailsTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractorDetailsContent_contractor",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "ContractorInfo_contractor",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "AddressInfo_contractor",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "Notes_contractor",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractorDetailsTabQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractorNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "internalId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "doNotHire",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isActive",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "coverImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "additionalInfo",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "conflictingContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nickname",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "portfolioUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timezone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timezoneList",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "freelancer",
            "storageKey": null,
            "args": null,
            "concreteType": "FreelancerNode",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "city",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "country",
            "storageKey": null,
            "args": null,
            "concreteType": "Country",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "zipcode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractorNotes",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNoteNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorNoteNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorNoteNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "staff",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v3/*: any*/)
                            ]
                          },
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "contentEditedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "repliedTo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentRepliedToNode",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractorDetailsTabQuery",
    "id": null,
    "text": "query ContractorDetailsTabQuery(\n  $id: ID!\n) {\n  contractor(id: $id) {\n    ...ContractorDetailsContent_contractor\n    ...ContractorInfo_contractor\n    ...AddressInfo_contractor\n    ...Notes_contractor\n    id\n  }\n}\n\nfragment ContractorDetailsContent_contractor on ContractorNode {\n  representativeImageUrl\n  fullName\n  internalId\n  doNotHire\n  isActive\n  coverImageUrl\n  additionalInfo\n  conflictingContractor {\n    id\n  }\n}\n\nfragment ContractorInfo_contractor on ContractorNode {\n  id\n  currency\n  firstName\n  lastName\n  fullName\n  nickname\n  title\n  email\n  portfolioUrl\n  phone\n  timezone\n  timezoneList\n  companyName\n  internalId\n  conflictingContractor {\n    id\n    fullName\n    representativeImageUrl\n  }\n  freelancer {\n    id\n  }\n}\n\nfragment AddressInfo_contractor on ContractorNode {\n  address1\n  address2\n  city\n  state\n  country {\n    name\n  }\n  zipcode\n  freelancer {\n    id\n  }\n}\n\nfragment Notes_contractor on ContractorNode {\n  id\n  contractorNotes {\n    edges {\n      node {\n        id\n        created\n        staff {\n          user {\n            id\n            fullName\n          }\n          representativeImageUrl\n          id\n        }\n        content\n        contentEditedAt\n        repliedTo {\n          content\n          created\n          user {\n            fullName\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89826e458cfd097624c16fe06d677868';
module.exports = node;

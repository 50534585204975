import { styled } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: theme.palette.primary.checkboxHover
  },
  '& svg': {
    width: 22,
    height: 22
  },
  '&.Mui-checked': {
    color: props => props.checkedcolorprop || theme.palette.primary.main
  },
  '&.Mui-checked:hover': {
    backgroundColor: theme.palette.primary.checkboxHover
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.primary.main
  }
}));

export default StyledCheckbox;

import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Formik, Form, Field } from 'formik';
import { Grid, Box } from '@material-ui/core';

import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import FormikTextField from '../../../../shared/components/form/FormikTextField';
import SaveButton from '../../../../shared/components/UI/SaveButton';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import EditButton from '../../../../shared/components/UI/EditButton';
import CancelButton from '../../../../shared/components/UI/CancelButton';
import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import { ReactComponent as CustomizationIcon } from '../../../../shared/images/customization.svg';
import DetailsRow from '../../../../shared/components/UI/DetailsRow';
import AutoTable from '../../../../shared/components/table/AutoTable';
import ButtonWithTooltip from '../../../../shared/components/UI/ButtonWithTooltip';
import UpdateCategoryMutation from './mutations/UpdateCategoryMutation';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import useDialog from '../../../../shared/hooks/useDialog';
import withDialogProps from '../../../../shared/hoc/withDialogProps';
import Dialog from '../../../../shared/components/common/Dialog';
import HandleCategoryEditForm from './HandleCategoryEditForm';
import { CELL_TYPES } from '../../../../shared/constants';
import WhiteBox from '../../../../shared/components/common/WhiteBox';

const EDITING_ACTIONS = {
  addType: { name: 'addType', modalTitle: 'Add New Type', variant: 'Type' },
  editType: { name: 'editType', modalTitle: 'Edit Type', variant: 'Type' },
  removeType: { name: 'removeType', modalTitle: 'Remove Type', variant: 'Type' },
  addStep: { name: 'addStep', modalTitle: 'Add New Step', variant: 'Step' },
  editStep: { name: 'editStep', modalTitle: 'Edit Step', variant: 'Step' },
  removeStep: { name: 'removeStep', modalTitle: 'Remove Step', variant: 'Step' }
};

const TableBox = props => <Box width="40%" {...props} />;

const CategoryDetails = props => {
  const { category } = props;
  const initialEditingData = { action: {}, selected: null };

  const [isEditing, setEditing] = useState(false);
  const [editingData, setEditingData] = useState(initialEditingData);
  const [isDialogOpen, setDialogOpen] = useDialog();

  const classes = useDetailsStyles({ isEditing });

  const toggleDialogOpen = () => {
    setDialogOpen(!isDialogOpen);
  };

  const handleCloseDialog = () => {
    toggleDialogOpen();
    setEditingData(initialEditingData);
  };

  const toggleEditHandler = resetForm => {
    // when user clicks Cancel, we should reset form to intial values, regardless of any changes user has made
    if (typeof resetForm === 'function') {
      resetForm();
    }
    setEditing(!isEditing);
  };

  const submitHandler = (values, { setErrors, setSubmitting }) => {
    setSubmitting(true);
    const { name, categoryTypes, steps } = values;

    const variables = {
      id: category.id,
      name,
      categoryTypes: categoryTypes.map(type => ({
        name: type.name,
        id: type.relayId,
        replacementFor: type.replacementFor
      })),
      steps: steps.map((step, index) => ({
        name: step.name,
        order: index + 1,
        id: step.relayId,
        replacementFor: step.replacementFor
      }))
    };
    UpdateCategoryMutation(variables, (response, errors) => {
      setSubmitting(false);
      if (errors && errors[0].fields) {
        setErrors(errors[0].fields);
      } else if (response) {
        toggleEditHandler();
      }
    });
  };

  const addNewType = () => {
    updateEditingData(EDITING_ACTIONS.addType);
  };

  const editType = typeName => {
    updateEditingData(EDITING_ACTIONS.editType, typeName);
  };

  const removeType = typeName => {
    updateEditingData(EDITING_ACTIONS.removeType, typeName);
  };

  const addNewStep = () => {
    updateEditingData(EDITING_ACTIONS.addStep);
  };

  const editStep = stepName => {
    updateEditingData(EDITING_ACTIONS.editStep, stepName);
  };

  const removeStep = stepName => {
    updateEditingData(EDITING_ACTIONS.removeStep, stepName);
  };

  const updateEditingData = (action, value = null) => {
    setEditingData({ action, selected: value });
    toggleDialogOpen();
  };

  const getCurrentStepIndex = (steps, name) => {
    return steps.map(step => step.name).indexOf(name);
  };

  const getCurrentStepValue = (steps, name) => {
    return steps.find(step => step.name === name);
  };

  const moveStepUp = (value, steps, setFieldValue) => {
    const currentIndex = getCurrentStepIndex(steps, value);
    if (currentIndex <= 0) {
      return;
    }
    setFieldValue('steps', [
      ...steps.slice(0, currentIndex - 1),
      getCurrentStepValue(steps, value),
      steps[currentIndex - 1],
      ...steps.slice(currentIndex + 1)
    ]);
  };

  const moveStepDown = (value, steps, setFieldValue) => {
    const currentIndex = getCurrentStepIndex(steps, value);
    if (currentIndex >= steps.length - 1) {
      return;
    }
    setFieldValue('steps', [
      ...steps.slice(0, currentIndex),
      steps[currentIndex + 1],
      getCurrentStepValue(steps, value),
      ...steps.slice(currentIndex + 2)
    ]);
  };

  const getDialogTitle = () => {
    return editingData.action.modalTitle;
  };

  const initialValues = {
    name: category.name,
    categoryTypes: category.categoryTypes.edges.map(edge => ({
      ...edge.node,
      relayId: edge.node.id,
      replacementFor: []
    })),
    steps: category.steps.edges.map(edge => ({
      ...edge.node,
      relayId: edge.node.id,
      replacementFor: []
    })),
    typeOrStepName: '',
    replacementName: ''
  };

  const categoryValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .min(3, 'Min 3 characters.')
      .max(30, 'Max 30 characters.')
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitHandler}
      validationSchema={categoryValidationSchema}
    >
      {({ submitForm, resetForm, values, setFieldValue, setFieldError, isSubmitting }) => (
        <Form>
          <div className={classes.topSectionHeightSmall}>
            <div className={classes.containerSpaceBetween}>
              <div className={clsx(classes.containerColumn, classes.alignFlexStart)}>
                <CategoryTitleHeader
                  title={category.name}
                  icon={CustomizationIcon}
                  link="/admin/customization/deliverables"
                />
              </div>
            </div>
          </div>

          <WhiteBox>
            <Grid container alignItems="flex-end">
              <Box ml="auto" mr={-1} display="inline-block">
                {isEditing ? (
                  <Fragment>
                    <CancelButton
                      id="cancelCategory"
                      onClick={() => toggleEditHandler(resetForm)}
                      disabled={isSubmitting}
                    />
                    {values.steps.length && values.categoryTypes.length ? (
                      <SaveButton id="saveCategory" disabled={isSubmitting} />
                    ) : (
                      <ButtonWithTooltip
                        buttonText="Save"
                        tooltipText="You must have at least one type and at least one step."
                        disabled
                        variant="primary"
                      />
                    )}
                  </Fragment>
                ) : (
                  <EditButton id="editCategory" onClick={toggleEditHandler} />
                )}
              </Box>
            </Grid>

            {isEditing ? (
              <Fragment>
                <Grid item xs={3} style={{ marginBottom: '32px' }}>
                  <Field
                    component={FormikTextField}
                    name="name"
                    placeholder="Category name"
                    label="Category name"
                    className={classes.marginBottom}
                    fullWidth
                  />
                </Grid>
                <TableBox>
                  <DetailsSection noBorder title="Types">
                    <AutoTable
                      rowTemplate={[
                        { name: 'name', label: 'Type Name' },
                        {
                          name: 'edit',
                          type: CELL_TYPES.edit,
                          label: '',
                          width: '3%',
                          onClick: editType
                        },
                        {
                          name: 'trash',
                          type: CELL_TYPES.trash,
                          label: '',
                          onClick: removeType,
                          hidden:
                            values.categoryTypes.length === 1 &&
                            values.categoryTypes[0].numberOfDeliverables !== 0,
                          width: '3%'
                        }
                      ]}
                      edges={values.categoryTypes.map(node => ({
                        // using name for id because newly added types won't have an id yet
                        node: { ...node, id: node.name }
                      }))}
                    />
                    <GlobalButton
                      variant="secondary"
                      handleClick={addNewType}
                      noMargin
                      className={classes.addToListButton}
                    >
                      Add New Type
                    </GlobalButton>
                  </DetailsSection>
                </TableBox>
                <TableBox>
                  <DetailsSection noBorder title="Steps">
                    <AutoTable
                      rowTemplate={[
                        { name: 'upDown', label: '', type: CELL_TYPES.upDown, width: '40px' },
                        { name: 'name', label: 'Step Name' },
                        {
                          name: 'edit',
                          type: CELL_TYPES.edit,
                          label: '',
                          width: '3%',
                          onClick: editStep
                        },
                        {
                          name: 'trash',
                          type: CELL_TYPES.trash,
                          onClick: removeStep,
                          label: '',
                          hidden:
                            values.steps.length === 1 && values.steps[0].numberOfDeliverables !== 0,
                          width: '3%'
                        }
                      ]}
                      edges={values.steps.map((step, index, array) => ({
                        node: {
                          name: step.name,
                          order: step.order,
                          // using name for id because newly added steps won't have an id
                          id: step.name,
                          upDown: {
                            allowMoveUp: index !== 0,
                            allowMoveDown: index !== array.length - 1,
                            values: values.steps,
                            setFieldValue
                          }
                        }
                      }))}
                      rowProps={{
                        handleUpClick: moveStepUp,
                        handleDownClick: moveStepDown
                      }}
                    />
                    <GlobalButton
                      variant="secondary"
                      handleClick={addNewStep}
                      noMargin
                      className={classes.addToListButton}
                    >
                      Add New Step
                    </GlobalButton>
                  </DetailsSection>
                </TableBox>
                {editingData.action.name && (
                  <Dialog
                    title={getDialogTitle()}
                    isDialogOpen={isDialogOpen}
                    closeDialog={handleCloseDialog}
                    noButton
                  >
                    <HandleCategoryEditForm
                      handleClose={handleCloseDialog}
                      editingData={editingData}
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      editingActions={EDITING_ACTIONS}
                    />
                  </Dialog>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Grid item xs={4} style={{ marginBottom: '32px' }}>
                  <DetailsRow title="Category name" content={category.name} />
                </Grid>
                <TableBox>
                  <DetailsSection noBorder title="Types">
                    <AutoTable
                      rowTemplate={[{ name: 'name', label: 'Type Name' }]}
                      edges={category.categoryTypes.edges}
                    />
                  </DetailsSection>
                </TableBox>
                <TableBox>
                  <DetailsSection noBorder title="Steps">
                    <AutoTable
                      rowTemplate={[{ name: 'name', label: 'Step Name' }]}
                      edges={category.steps.edges}
                    />
                  </DetailsSection>
                </TableBox>
              </Fragment>
            )}
          </WhiteBox>
        </Form>
      )}
    </Formik>
  );
};

export default withDialogProps(
  createFragmentContainer(CategoryDetails, {
    category: graphql`
      fragment CategoryDetails_category on CategoryNode {
        id
        name
        numberOfDeliverables
        categoryTypes {
          edges {
            node {
              id
              name
              numberOfDeliverables
            }
          }
        }
        steps {
          edges {
            node {
              id
              name
              order
              numberOfDeliverables
            }
          }
        }
      }
    `
  })
);

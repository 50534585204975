import { ATTRS, TOOLS, OPTIONS } from './contants';
import { resetTool } from './commons';
import { hideControls } from '../CanvasData';

const toolFreeDraw = (canvas, { annotationColor, userId, annotationRef, frameRef }) => {
  resetTool(canvas, TOOLS.free.name);
  canvas.freeDrawingCursor = TOOLS.free.cursor;
  canvas.freeDrawingBrush.color = annotationColor;
  canvas.freeDrawingBrush.width = OPTIONS.strokeWidth;
  canvas.isDrawingMode = true;
  canvas.renderAll();

  const pathCreatedHandler = event => {
    const path = event.path;
    path.set({
      selectable: false,
      [ATTRS.originalColor]: annotationColor,
      [ATTRS.type]: TOOLS.free.name,
      [ATTRS.authorId]: userId,
      [ATTRS.ref]: annotationRef,
      [ATTRS.frameRef]: frameRef
    });
    hideControls(path, ['mt', 'ml', 'mr', 'mb']);
    canvas.renderAll();
  };

  canvas.on({
    'path:created': pathCreatedHandler
  });
};
export { toolFreeDraw };

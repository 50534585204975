import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';

import ProductDetails from './ProductDetails';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';

const ProductDetailsPage = props => {
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query ProductDetailsPageQuery($id: ID!) {
          product(id: $id) {
            ...ProductDetails_product
          }
        }
      `}
      variables={{ id }}
      render={relayProps => <ProductDetails product={relayProps.product} {...props} />}
    />
  );
};

export default ProductDetailsPage;

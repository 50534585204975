import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const DoNotHireContractorMutation = (id, doNotHire, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation DoNotHireContractorMutation($input: DoNotHireContractorInput!) {
        doNotHireContractor(input: $input) {
          updatedContractor {
            doNotHire
          }
        }
      }
    `,
    variables: {
      input: {
        id,
        doNotHire,
      },
    },
    onCompleted: (response) => {
      callback(response.doNotHireContractor);
    },
  });
};

DoNotHireContractorMutation.propTypes = {
  id: PropTypes.string.isRequired,
  doNotHire: PropTypes.bool.isRequired,
  callback: PropTypes.func,
};

export default DoNotHireContractorMutation;

import React from 'react';
import { Box, Typography, LinearProgress } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CheckIcon from '@material-ui/icons/Check';
import { styled } from '@material-ui/styles';
import Detail from '../../../../shared/components/UI/Detail';
import { formatBytes } from '../../../../shared/utils/formatters';

export const StyledInfoRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '15px',
  marginBottom: '20px'
});

const StyledStorageBox = styled(Box)(({ theme }) => ({
  padding: '12px',
  backgroundColor: theme.palette.colors.progressBarLightGrey,
  borderRadius: '3px',
  '& >p': {
    paddingBottom: '5px'
  },
  // width: '90%',
  '& >div:last-of-type': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px'
  }
}));

const StorageDetail = ({ label, value }) => (
  <Detail
    name={label}
    value={formatBytes(value)}
    flexDirection="column"
    alignItems="left"
    nameContainerProps={{ variant: 'body2', noMargin: true }}
    valueContainerProps={{ variant: 'body1' }}
  />
);

const ProgressBar = styled(({ value, ...other }) => (
  <LinearProgress variant="determinate" value={value} {...other} />
))(({ theme, value }) => ({
  height: '24px',
  backgroundColor: 'white',
  borderRadius: '3px',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor:
      value <= 50
        ? theme.palette.colors.green
        : value <= 90
        ? theme.palette.colors.lighterYellow
        : theme.palette.colors.red
  }
}));

export const StorageBox = ({ label, inUse, total }) => (
  <StyledStorageBox>
    <Typography variant="body2">{label}</Typography>
    <ProgressBar value={inUse > total ? 100 : (inUse / total) * 100} />
    <Box>
      <StorageDetail label="In Use" value={inUse} />
      <StorageDetail label="Storage Limit" value={total} />
      <StorageDetail label="Remaining" value={total - inUse} />
    </Box>
  </StyledStorageBox>
);

export const ScheduledSubscriptionInterval = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& p:last-of-type': {
    color: theme.palette.colors.orange,
    fontSize: '11px'
  }
}));

export const StyledModuleBox = styled(({ quantity, ...other }) => <Box {...other} />)(
  ({ quantity, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '250px',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '5px',
    paddingBottom: '10px',
    backgroundColor: quantity
      ? theme.palette.colors.lightestOrange
      : theme.palette.colors.progressBarLightGrey,
    alignItems: 'center',
    '& .pencil-logo svg': {
      marginTop: '15px',
      marginBottom: '10px',
      width: '85px',
      height: '85px',
      backgroundColor: 'white',
      padding: '7px',
      borderRadius: '50%',
      '& g': {
        fill: theme.palette.colors.orange
      }
    },
    '& p.MuiTypography-body1': {
      height: '5em',
      paddingLeft: '12px',
      paddingRight: '15px'
    },
    '& p': {
      fontSize: '14px',
      // marginBottom: '5px',
      color: quantity ? theme.palette.colors.orange : theme.palette.colors.darkBlueGrey,
      textAlign: 'center'
    },
    '& p:last-of-type': {
      fontSize: '12px'
    }
  })
);

export const StyledModuleFootnote = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.orange,
  fontSize: '11px',
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: '10px'
}));

const StyledModuleIndicatorBox = styled(({ quantity, forButton, scheduledChange, ...other }) => (
  <Box {...other} />
))(({ quantity, forButton, scheduledChange, theme }) => ({
  borderRadius: '50%',
  height: forButton ? '60px' : '50px',
  width: forButton ? '60px' : '50px',
  backgroundColor: 'white',
  position: 'absolute',
  top: forButton ? '-12px' : '-15px',
  right: '-15px',
  padding: '7px',
  '& div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    backgroundColor: quantity
      ? scheduledChange
        ? theme.palette.colors.lightestOrange
        : theme.palette.colors.lightGreen
      : theme.palette.colors.progressBarLightGrey,
    '& svg': {
      width: forButton ? '30px' : '20px',
      height: forButton ? '30px' : '20px',
      fill: quantity
        ? scheduledChange
          ? theme.palette.colors.orange
          : theme.palette.colors.green
        : theme.palette.colors.mediumBlueGrey
    },
    color: scheduledChange ? theme.palette.colors.orange : theme.palette.colors.green,
    fontWeight: '900',
    fontSize: '16px'
  }
}));

export const ModuleIndicator = ({ quantity, incremental, scheduledChange, forButton }) => (
  <StyledModuleIndicatorBox
    quantity={quantity}
    forButton={forButton}
    scheduledChange={scheduledChange}
  >
    <Box>
      {!quantity && <ShoppingCartIcon />}
      {quantity ? incremental ? quantity : <CheckIcon /> : null}
    </Box>
  </StyledModuleIndicatorBox>
);

export const LeftModuleSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  borderRight: `2px solid ${theme.palette.colors.lightestGrey}`,
  '& p': {
    marginTop: '10px'
  },
  overflow: 'auto',
  height: '500px'
}));

export const RightModuleSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  alignItems: 'center',
  overflow: 'hidden',
  '& >svg': {
    width: '95px',
    height: '95px',
    marginBottom: '20px',
    '& g': {
      fill: theme.palette.colors.orange
    }
  },
  '& h1': {
    marginBottom: '15px'
  },
  '& table': {
    marginTop: '20px',
    marginBottom: '10px',
    borderTop: `2px solid ${theme.palette.colors.brightOrange}`,
    '& tr:not(.before-active-row)': {
      borderBottom: `2px solid ${theme.palette.colors.lightestGrey}`
    },
    '& tr': {
      '&.active-row': {
        borderBottom: `2px solid ${theme.palette.colors.brightOrange}`,
        borderTop: `2px solid ${theme.palette.colors.brightOrange}`,
        backgroundColor: theme.palette.colors.lightestOrange,
        '& th': {
          color: theme.palette.colors.orange
        },
        '& td': {
          color: theme.palette.colors.orange
        }
      },

      '& td': {
        color: theme.palette.colors.darkBlueGrey
      }
    },
    '& .MuiTableCell-root': {
      padding: '12px 8px'
    }
  },
  '& .span-email': {
    color: theme.palette.colors.orange
  }
}));

export const ActionButtonSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '70px',
  width: '100%',
  '& button': {
    marginBottom: '20px'
  }
}));

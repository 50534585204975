import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import ToolActions from '../../../shared/ToolActions';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import ActivateDeliverableMutation from '../../mutations/ActivateDeliverableMutation';
import DeactivateDeliverableMutation from '../../mutations/DeactivateDeliverableMutation';
import UnlockDeliverableForJobMutation from '../../mutations/UnlockDeliverableForJobMutation';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { computePermissionRole, getAssignedUserId } from '../../../../../shared/utils/helpers';
import {
  DELIVERABLE_STATES,
  ALLOWED_ACTIONS,
  TOOLS_DESCRIPTIONS
} from '../../../../../shared/constants';
import DeleteBatchDeliverableMutation from '../../../../mutations/DeleteBatchDeliverableMutation';
import { withRouter } from 'react-router';
import ResetDeliverableMutation from '../../mutations/ResetDeliverableMutation';
import CancelDeliverableMutation from '../../mutations/CancelDeliverableMutation';
import ConfirmationDialog from '../../../../../shared/components/common/ConfirmationDialog';

const DeliverableToolsActions = props => {
  const {
    history,
    deliverable: { id, state, assignedStaff, assignedInternally, job },
    userContext: {
      id: currentUserId,
      orgStaff: { allowedActions }
    },
    setRefetchCounterForTools
  } = props;

  const checkAfterMutation = response => {
    if (response && response.updatedDeliverable) {
      setRefetchCounterForTools(prev => prev + 1);
    }
  };

  const handleUnlock = () => UnlockDeliverableForJobMutation(id, checkAfterMutation);

  const handleActivate = () => ActivateDeliverableMutation(id, checkAfterMutation);

  const handleDeactivate = () => DeactivateDeliverableMutation(id, checkAfterMutation);

  const handleReset = () => ResetDeliverableMutation(id, checkAfterMutation);

  const handleCancel = () => CancelDeliverableMutation(id, checkAfterMutation);

  const handleDelete = () => {
    DeleteBatchDeliverableMutation([id], response => {
      if (response && response.isDeleted) {
        history.push('/deliverables');
      }
    });
  };

  const canUnlock =
    state === DELIVERABLE_STATES.locked
      ? (computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_UNLOCK_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ) &&
          currentUserId === getAssignedUserId(assignedStaff)) ||
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_UNLOCK_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )
      : false;

  const hasActivatingPermission = computePermissionRole(
    ALLOWED_ACTIONS.DELIVERABLE_DEACTIVATE,
    allowedActions
  );
  const canDeactivate =
    hasActivatingPermission &&
    (state === DELIVERABLE_STATES.draft || state === DELIVERABLE_STATES.ready);

  const canActivate = hasActivatingPermission && state === DELIVERABLE_STATES.inactive;

  const canDelete =
    (state === DELIVERABLE_STATES.inactive || state === DELIVERABLE_STATES.draft) &&
    !job &&
    computePermissionRole(ALLOWED_ACTIONS.DELIVERABLE_DELETE, allowedActions);

  const canResetOrCancel =
    state === DELIVERABLE_STATES.inProgress &&
    assignedInternally !== null &&
    computePermissionRole(ALLOWED_ACTIONS.DELIVERABLE_INTERNAL_RESET_OR_CANCEL, allowedActions);

  const actionList = [
    {
      condition: canUnlock,
      actionRender: () => (
        <GlobalButton noMargin id="unlockButton" handleClick={handleUnlock} fullWidth>
          Unlock
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.unlockDeliverable
    },
    {
      condition: canDeactivate,
      actionRender: () => (
        <GlobalButton
          noMargin
          id="deactivateDeliverableButton"
          handleClick={handleDeactivate}
          fullWidth
        >
          Mark Deliverable Inactive
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.markDeliverableInactive
    },
    {
      condition: canActivate,
      actionRender: () => (
        <GlobalButton
          noMargin
          id="activateDeliverableButton"
          handleClick={handleActivate}
          fullWidth
        >
          Mark Deliverable Active
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.activateDeliverable
    },
    {
      condition: canDelete,
      actionRender: () => (
        <GlobalButton noMargin id="deleteDeliverableButton" handleClick={handleDelete} fullWidth>
          Delete Deliverable
        </GlobalButton>
      ),
      description: TOOLS_DESCRIPTIONS.deleteDeliverable
    },
    {
      condition: canResetOrCancel,
      actionRender: () => (
        <ConfirmationDialog
          content="This action is irreversible. Are you sure you want to reset Deliverable?"
          copies={{ confirmCopy: 'Reset Deliverable', cancelCopy: 'Cancel' }}
        >
          {confirm => (
            <GlobalButton
              noMargin
              id="resetDeliverableButton"
              handleClick={confirm(handleReset)}
              fullWidth
            >
              Reset Deliverable
            </GlobalButton>
          )}
        </ConfirmationDialog>
      ),
      description: TOOLS_DESCRIPTIONS.resetDeliverable
    },
    {
      condition: canResetOrCancel,
      actionRender: () => (
        <ConfirmationDialog
          content="This action is irreversible. Are you sure you want to cancel Deliverable?"
          copies={{ confirmCopy: 'Cancel Deliverable', cancelCopy: 'Cancel' }}
        >
          {confirm => (
            <GlobalButton
              noMargin
              id="cancelDeliverableButton"
              handleClick={confirm(handleCancel)}
              fullWidth
            >
              Cancel Deliverable
            </GlobalButton>
          )}
        </ConfirmationDialog>
      ),
      description: TOOLS_DESCRIPTIONS.cancelDeliverable
    }
  ];

  return <ToolActions actions={actionList} />;
};

export default withRouter(
  withUserContext(
    createFragmentContainer(DeliverableToolsActions, {
      deliverable: graphql`
        fragment DeliverableToolsActions_deliverable on DeliverableNode {
          id
          state
          assignedInternally {
            id
          }
          assignedStaff {
            user {
              id
            }
          }
          job {
            id
          }
        }
      `
    })
  )
);

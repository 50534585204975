/**
 * @flow
 * @relayHash 1eb20c03531807d79c3ec8e96122c25a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationDeliverablesList_solicitation$ref = any;
export type SolicitationDeliverablesListRefetchQueryVariables = {|
  first?: ?number,
  after?: ?string,
  id: string,
  orderBy?: ?string,
|};
export type SolicitationDeliverablesListRefetchQueryResponse = {|
  +solicitation: ?{|
    +$fragmentRefs: SolicitationDeliverablesList_solicitation$ref
  |}
|};
export type SolicitationDeliverablesListRefetchQuery = {|
  variables: SolicitationDeliverablesListRefetchQueryVariables,
  response: SolicitationDeliverablesListRefetchQueryResponse,
|};
*/


/*
query SolicitationDeliverablesListRefetchQuery(
  $first: Int
  $after: String
  $id: ID!
  $orderBy: String
) {
  solicitation: solicitation(id: $id) {
    ...SolicitationDeliverablesList_solicitation
    id
  }
}

fragment SolicitationDeliverablesList_solicitation on SolicitationNode {
  id
  solicitationId
  state
  staffCoordinator {
    user {
      id
    }
    id
  }
  deliverables(first: $first, after: $after, orderBy: $orderBy) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        title
        amount
        currencyCode
        amountInHomeCurrency
        state
        category {
          id
          name
        }
        release {
          id
          name
          product {
            id
            title
          }
        }
        allocation {
          solicitation {
            id
          }
          contractor {
            fullName
            representativeImageUrl
            id
          }
          id
        }
        dueDate
        assignedContractor {
          fullName
          representativeImageUrl
          id
        }
        assignedInternally {
          fullName
          representativeImageUrl
          id
        }
        assignedStaff {
          id
          fullName
          representativeImageUrl
          user {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": (v4/*: any*/)
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v9/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SolicitationDeliverablesListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "solicitation",
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SolicitationDeliverablesList_solicitation",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SolicitationDeliverablesListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "solicitation",
        "name": "solicitation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "solicitationId",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffCoordinator",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              }
            ],
            "concreteType": "DeliverableNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currencyCode",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amountInHomeCurrency",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "release",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ReleaseNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "product",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ProductNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "allocation",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SolicitationAllocatedDeliverableNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "solicitation",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "SolicitationNode",
                            "plural": false,
                            "selections": (v4/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contractor",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractorNode",
                            "plural": false,
                            "selections": (v10/*: any*/)
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dueDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignedContractor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNode",
                        "plural": false,
                        "selections": (v10/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignedInternally",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": (v10/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignedStaff",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SolicitationDeliverablesListRefetchQuery",
    "id": null,
    "text": "query SolicitationDeliverablesListRefetchQuery(\n  $first: Int\n  $after: String\n  $id: ID!\n  $orderBy: String\n) {\n  solicitation: solicitation(id: $id) {\n    ...SolicitationDeliverablesList_solicitation\n    id\n  }\n}\n\nfragment SolicitationDeliverablesList_solicitation on SolicitationNode {\n  id\n  solicitationId\n  state\n  staffCoordinator {\n    user {\n      id\n    }\n    id\n  }\n  deliverables(first: $first, after: $after, orderBy: $orderBy) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        title\n        amount\n        currencyCode\n        amountInHomeCurrency\n        state\n        category {\n          id\n          name\n        }\n        release {\n          id\n          name\n          product {\n            id\n            title\n          }\n        }\n        allocation {\n          solicitation {\n            id\n          }\n          contractor {\n            fullName\n            representativeImageUrl\n            id\n          }\n          id\n        }\n        dueDate\n        assignedContractor {\n          fullName\n          representativeImageUrl\n          id\n        }\n        assignedInternally {\n          fullName\n          representativeImageUrl\n          id\n        }\n        assignedStaff {\n          id\n          fullName\n          representativeImageUrl\n          user {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c9d183a8dcc82a8efd8ff22b3ad15ab3';
module.exports = node;

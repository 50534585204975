import React, { Fragment, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Grid } from '@material-ui/core';
import { createFragmentContainer } from 'react-relay';
import clsx from 'clsx';

import DetailsRow from '../../../../shared/components/UI/DetailsRow';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import ButtonWithTooltip from '../../../../shared/components/UI/ButtonWithTooltip';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import ContractPreviewWithSignaturePad from '../ContractPreviewWithSignaturePad';
import DetailsRowAmount from '../../../../shared/components/UI/DetailsRowAmount';
import DetailsRowDate from '../../../../shared/components/UI/DetailsRowDate';
import { JOB_STATUSES } from '../../../../shared/constants';
import { IC_TARGETS } from '../../../../shared/services/intercomService';
import StatusIndicator from '../../../../shared/components/UI/StatusIndicator';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import AvatarWithName from '../../../../shared/components/UI/AvatarWithName';
import { borderLeft } from '../../../../shared/styles/borders';
import JobCommentThread from '../JobCommentThread';
import DeclineJobByContractor from '../mutations/DeclineJobByContractor';
import { successToast } from '../../../../shared/toasts';
import ConfirmationDialog from '../../../../shared/components/common/ConfirmationDialog';
import CreateInvoiceDialog from '../CreateInvoiceDialog';

const JobDetailsTab = props => {
  const classes = useDetailsStyles();
  const [showContract, setShowContract] = useState(false);
  const [showCreateInvoiceDialog, setShowCreateInvoiceDialog] = useState(false);

  const {
    stateTypes,
    job: {
      id,
      status,
      contractSignDeadline,
      staffCoordinator: { organization, fullName, representativeImageUrl },
      totalValue,
      currencyCode,
      contract,
      deliverableCategory,
      contractDate,
      created,
      hasDeliverableReview
    }
  } = props;

  const handleViewClick = () => {
    setShowContract(true);
  };

  const declineJob = () => {
    DeclineJobByContractor(id, response => {
      if (response) {
        successToast('Job was declined.');
      }
    });
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <DetailsSection title="Info" noBorder>
            <Grid container>
              <Grid item xs={5}>
                <DetailsRow
                  noMarginTop
                  title="Organization"
                  content={
                    <AvatarWithName
                      name={organization.name}
                      defaultIcon="organization"
                      avatarProps={{
                        src: organization.representativeImageUrl
                      }}
                    />
                  }
                  titleWidth={136}
                />
                <DetailsRow
                  title="Contact"
                  content={
                    <AvatarWithName
                      name={fullName}
                      avatarProps={{
                        src: representativeImageUrl
                      }}
                    />
                  }
                  titleWidth={136}
                />
                <DetailsRow
                  title="Deliverable Category"
                  content={deliverableCategory ? deliverableCategory.name : '-'}
                  titleWidth={136}
                />
                <DetailsRow
                  title="Job Status"
                  content={
                    <StatusIndicator
                      table
                      statusTypes={stateTypes.enumValues}
                      statusCode={status}
                      variant="job"
                    />
                  }
                  titleWidth={136}
                />
              </Grid>
              <Grid item xs={4}>
                <DetailsRowDate
                  noMarginTop
                  title="Contract Signature Deadline"
                  date={contractSignDeadline}
                  titleWidth={172}
                />
                <DetailsRowDate title="Contract date" date={contractDate} titleWidth={172} />
                <DetailsRowDate title="Job Creation" date={created} titleWidth={172} />
                <DetailsRowAmount
                  title="Total Amount of Job"
                  amount={totalValue}
                  currencyCode={currencyCode}
                  titleWidth={172}
                />
              </Grid>
              <Grid item xs={3}>
                <div className={classes.containerRow}>
                  {contract ? (
                    <Fragment>
                      <GlobalButton
                        id="viewContractJobDetailsTabTab"
                        handleClick={handleViewClick}
                        data-intercom-target={IC_TARGETS.jobsJobViewContract}
                        fullWidth
                      >
                        {`Review ${
                          status === JOB_STATUSES.awaiting_signature ? 'and Sign ' : ''
                        }Contract`}
                      </GlobalButton>
                    </Fragment>
                  ) : (
                    <ButtonWithTooltip
                      disabled={true}
                      tooltipText={`This Job was accomplished without an AWEbase specific contract. Please contact ${fullName} for more details.`}
                      buttonText="Review Contract"
                      fullWidth
                    />
                  )}
                </div>

                {(status === JOB_STATUSES.awaiting_signature ||
                  (status === JOB_STATUSES.in_progress && !contract && !hasDeliverableReview)) && (
                  <div className={clsx(classes.marginTop, classes.containerRow)}>
                    <ConfirmationDialog
                      copies={{ confirmCopy: 'Decline', cancelCopy: 'Cancel' }}
                      content="This action is irreversible. Job will be declined and you will not have access to its deliverables. Are you sure you want to continue?"
                    >
                      {confirm => (
                        <GlobalButton
                          id="declineJob"
                          variant="secondary"
                          handleClick={confirm(declineJob)}
                          fullWidth
                        >
                          {`Decline ${!contract ? 'Job' : 'Contract'}`}
                        </GlobalButton>
                      )}
                    </ConfirmationDialog>
                  </div>
                )}

                {[JOB_STATUSES.in_progress, JOB_STATUSES.delivered].includes(status) && (
                  <div className={clsx(classes.marginTop, classes.containerRow)}>
                    <GlobalButton
                      variant="primary"
                      fullWidth
                      handleClick={() => setShowCreateInvoiceDialog(true)}
                    >
                      Send Invoice
                    </GlobalButton>
                  </div>
                )}
              </Grid>
            </Grid>
          </DetailsSection>
        </Grid>
        <Grid item xs={3} style={borderLeft}>
          <JobCommentThread job={props.relayProps.job} jobId={id} />
        </Grid>
      </Grid>
      {contract ? (
        <ContractPreviewWithSignaturePad
          contract={contract}
          showContract={showContract}
          setShowContract={setShowContract}
        />
      ) : null}

      <CreateInvoiceDialog
        isDialogOpen={showCreateInvoiceDialog}
        onClose={() => setShowCreateInvoiceDialog(false)}
        jobId={id}
      />
    </Fragment>
  );
};

export default createFragmentContainer(JobDetailsTab, {
  job: graphql`
    fragment JobDetailsTab_job on JobNode {
      id
      contractSignDeadline
      contractDate
      created
      status
      staffCoordinator {
        fullName
        representativeImageUrl
        organization {
          name
          representativeImageUrl
        }
      }
      contract {
        id
        contractFileUrl
        job {
          status
        }
      }
      jobId
      totalValue
      currencyCode
      hasDeliverableReview
      deliverableCategory {
        name
      }
    }
  `,
  stateTypes: graphql`
    fragment JobDetailsTab_stateTypes on __Type {
      enumValues {
        name
        description
      }
    }
  `
});

import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import styled, { css } from 'styled-components';
import { Container, Box } from '@material-ui/core';

const OuterContainerComponent = ({ isLoading, ...rest }) => <Box {...rest} />;
const OuterContainer = styled(OuterContainerComponent)`
  min-height: 100%;
  overflow: hidden;

  ${props =>
    !props.isLoading &&
    css`
      background: ${props => props.theme.palette.colors.grey};
    `};
`;

const StyledPage = styled(Page)`
  margin-bottom: 40px;
`;

// It is crucial for performance to use PDF.js worker whenever possible.
// This ensures that PDF files will be rendered in a separate thread without affecting page performance.
// TODO: currently using external CDN, but we could have pdf.worker.js in our output folder
// https://github.com/wojtekmaj/react-pdf#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContractPreview = ({ contractPreviewPdf, ...rest }) => {
  const [numPages, setNumPages] = React.useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <OuterContainer isLoading={numPages === null}>
      <Container>
        <Document
          file={contractPreviewPdf}
          error="Failed to load the contract"
          loading="Loading contract..."
          onLoadSuccess={onDocumentLoadSuccess}
          {...rest}
        >
          {/* loads all pages in the document */}
          {Array.from(new Array(numPages), (el, index) => (
            <StyledPage
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              loading=""
              scale={2}
              error={`Failed to load page ${index + 1}`}
            />
          ))}
        </Document>
      </Container>
    </OuterContainer>
  );
};

export default ContractPreview;

ContractPreview.propTypes = {
  // file to render. Could be base64 string, url, or file object
  contractPreviewPdf: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)])
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import EditableBigAvatar from '../fields/EditableBigAvatar';
import AssetCardImage from '../../assets/AssetCardImage';
import SquareButton from '../../UI/SquareButton';
import UploadImageButton from '../UploadImageButton';
import EditableThumbnail from '../fields/EditableThumbnail';

const ErrorLabel = styled('p')(
  ({ theme }) => ({ fontSize: '12px', color: theme.palette.error.main }),
  { withTheme: true }
);

const FormikEditableImage = props => {
  const {
    variant = 'avatar',
    field,
    form,
    nameNull,
    imageProps,
    uploadButtonId,
    deleteButtonId,
    editing,
    ...otherProps
  } = props;

  const onChange = (file, dataUrl) => {
    form.setFieldValue(field.name, {
      file,
      src: dataUrl
    });
    form.setFieldValue(nameNull, false);
  };

  const onDelete = () => {
    form.setFieldValue(field.name, { src: '', file: null });
    form.setFieldValue(nameNull, true);
  };

  return (
    <Fragment>
      {variant === 'avatar' && (
        <Fragment>
          <EditableBigAvatar
            avatarProps={{
              ...imageProps,
              src: field.value.src
            }}
            uploadButtonProps={{
              id: uploadButtonId,
              handleChange: onChange
            }}
            deleteButtonProps={{
              id: deleteButtonId,
              onClick: onDelete
            }}
            editing={editing}
            {...otherProps}
          />
          <ErrorLabel>{form.errors[field.name] && form.errors[field.name].file}</ErrorLabel>
        </Fragment>
      )}

      {variant === 'background' && (
        <AssetCardImage aspectRatio={25} src={field.value.src} toolbarVisible cover {...otherProps}>
          {editing && (
            <Fragment>
              <ErrorLabel>{form.errors[field.name] && form.errors[field.name].file}</ErrorLabel>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <UploadImageButton id={uploadButtonId} handleChange={onChange} />
                <SquareButton
                  icon="trash"
                  id={deleteButtonId}
                  onClick={onDelete}
                  style={{ marginLeft: 8 }}
                />
              </Box>
            </Fragment>
          )}
        </AssetCardImage>
      )}

      {variant === 'thumbnail' && (
        <Fragment>
          <EditableThumbnail
            thumbnailProps={{
              ...imageProps,
              src: field.value.src
            }}
            uploadButtonProps={{
              id: uploadButtonId,
              handleChange: onChange
            }}
            deleteButtonProps={{
              id: deleteButtonId,
              onClick: onDelete
            }}
            editing={editing}
            {...otherProps}
          />
          <ErrorLabel>{form.errors[field.name] && form.errors[field.name].file}</ErrorLabel>
        </Fragment>
      )}
    </Fragment>
  );
};

FormikEditableImage.propTypes = {
  variant: PropTypes.oneOf(['avatar', 'thumbnail', 'background']),
  nameNull: PropTypes.string.isRequired,
  imageProps: PropTypes.shape({
    alt: PropTypes.string.isRequired
  }),
  uploadButtonId: PropTypes.string.isRequired,
  deleteButtonId: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired
};

export default FormikEditableImage;

import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch, Typography, Grid, Box } from '@material-ui/core';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { styled } from '@material-ui/styles';
import GlobalButton from '../../UI/GlobalButton';
import SquareButton from '../../UI/SquareButton';
import CircleColorPicker from '../../colorPicker/CircleColorPicker';
import AddActionsGroup from '../review_interfaces/AddActionsGroup';

const ToolbarControlsContainer = styled('div')(
  ({ theme }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& > p': {
      marginRight: theme.spacing(2)
    },
    '& > button:not(:last-child)': {
      marginRight: theme.spacing(0.5)
    },
    '& > button:first-child': {
      marginLeft: 0
    }
  }),
  { withTheme: true }
);

const CanvasAnnotationToolbar = props => {
  const {
    sessionId,
    onStartSession,
    onAddComment,
    toggleAnnotationVisibility,
    annotationsVisible,
    selectedToolId,
    setToolHandler,
    tools,
    editing,
    colorProps,
    onCloseClick,
    canAddMarkup,
    onStartEdit,
    onSaveEdit
  } = props;

  const onChangeHandler = event => {
    toggleAnnotationVisibility(event.target.checked);
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      {sessionId && (
        <Grid item xs={6} container spacing={1}>
          <Grid item>
            <ToolbarControlsContainer>
              <Box>
                <SquareButton
                  handleClick={onCloseClick}
                  tooltipTitle="Discard Annotation"
                  icon="close"
                  variant="secondary"
                />
              </Box>
            </ToolbarControlsContainer>
          </Grid>
          <Grid item>
            <ToolbarControlsContainer>
              <Typography variant="body2">Tools</Typography>
              {tools.map(tool => (
                <SquareButton
                  key={tool.name}
                  disabled={editing || !canAddMarkup}
                  variant="transparent"
                  icon={tool.icon}
                  tooltipTitle={tool.description}
                  active={tool.name === selectedToolId}
                  onClick={() => setToolHandler(tool.name)}
                />
              ))}
            </ToolbarControlsContainer>
          </Grid>
          <Grid item>
            <ToolbarControlsContainer>
              <Typography variant="body2">Color</Typography>
              <CircleColorPicker {...colorProps} />
            </ToolbarControlsContainer>
          </Grid>
        </Grid>
      )}
      {!sessionId && !editing && (
        <Grid item xs={6} container spacing={1}>
          <Grid item>
            <AddActionsGroup
              disabled={editing || !canAddMarkup}
              onAddAnnotation={onStartSession}
              onAddComment={onAddComment}
            />
          </Grid>
        </Grid>
      )}

      <Grid item xs={6} container justify="flex-end">
        {!sessionId &&
          (!sessionId && editing ? (
            <GlobalButton
              handleClick={onSaveEdit}
              disabled={!canAddMarkup}
              icon={OpenWithIcon}
              variant="black"
              iconProps={{ style: { fontSize: '16px' } }}
            >
              Save Changes
            </GlobalButton>
          ) : (
            <GlobalButton
              handleClick={onStartEdit}
              disabled={!canAddMarkup}
              icon={OpenWithIcon}
              variant="black"
              iconProps={{ style: { fontSize: '16px' } }}
            >
              Edit Markers
            </GlobalButton>
          ))}
        {!sessionId && !editing ? (
          <FormControlLabel
            control={
              <Switch
                disabled={editing}
                onChange={onChangeHandler}
                checked={annotationsVisible}
                value={annotationsVisible}
                color="primary"
              />
            }
            label={<Typography variant="body2">View markers</Typography>}
            labelPlacement="start"
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

CanvasAnnotationToolbar.propTypes = {
  /** The session id for the annotation session, if null the annotation
   *  session has not started and the tools are not display
   */
  sessionId: PropTypes.string,
  /**
    Determines if the annotation are displayed.
     */
  toggleAnnotationVisibility: PropTypes.func,
  annotationsVisible: PropTypes.bool,
  selectedToolId: PropTypes.string,
  setToolHandler: PropTypes.func,
  tools: PropTypes.array,
  editing: PropTypes.bool,
  colorProps: PropTypes.shape({
    color: PropTypes.string,
    disable: PropTypes.bool,
    onChange: PropTypes.func
  }),
  isVideoOverlay: PropTypes.bool,
  onCloseClick: PropTypes.func,
  canAddMarkup: PropTypes.bool,
  onStartEdit: PropTypes.func,
  onSaveEdit: PropTypes.func,
  onStartSession: PropTypes.func.isRequired,
  onAddComment: PropTypes.func.isRequired
};

export default CanvasAnnotationToolbar;

import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Typography, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CreateRoleMutation from '../mutations/CreateRoleMutation';
import DeleteRoleMutation from '../mutations/DeleteRoleMutation';
import { DialogActions } from '../../../../../shared/components/common/Dialog';
import { successToast } from '../../../../../shared/toasts';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import FormikTextField from '../../../../../shared/components/form/FormikTextField';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '0'
  }
}));

const HandleRoleForm = props => {
  const { handleClose, handleCloseWithRefetch, selectedRole, action } = props;

  const classes = useStyles();

  const onSubmitHandler = (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    if (action === 'duplicate') {
      CreateRoleMutation(selectedRole.id, values.name, (response, errors) => {
        setSubmitting(false);
        if (errors && errors[0].fields && errors[0].fields.name) {
          setFieldError('name', errors[0].fields.name);
        }
        if (response) {
          setTimeout(() => {
            successToast(
              'New role was created. Review and customize permissions in the details page.',
              {
                text: 'Go to details page.',
                link: `/admin/staff-management/roles/${response.newOrganizationRole.id}`
              }
            );
          }, 250);
          handleCloseWithRefetch();
        }
      });
    } else {
      DeleteRoleMutation(selectedRole.id, response => {
        setSubmitting(false);
        if (response) {
          handleClose();
          setTimeout(() => {
            successToast(`${selectedRole.name} was deleted.`);
          }, 250);
        }
        handleCloseWithRefetch();
      });
    }
  };
  const validate = values => {
    let errors = {};
    if (action === 'duplicate' && !values.name) {
      errors.name = 'Required';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{ name: `${selectedRole.name}_copy` }}
      validate={validate}
      onSubmit={onSubmitHandler}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              <Typography
                variant="body1"
                component="span"
                dangerouslySetInnerHTML={{
                  __html: `You are about to ${
                    action === 'duplicate' ? 'create a new' : `delete <b>${selectedRole.name}</b>`
                  } role. ${action === 'duplicate' ? 'Choose a unique name.' : ''}`
                }}
              />
            </DialogContentText>
            {action === 'duplicate' && (
              <Field component={FormikTextField} name="name" label="Role name" required fullWidth />
            )}
          </DialogContent>
          <DialogActions>
            <GlobalButton variant="transparent" handleClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GlobalButton>
            <GlobalButton handleClick={submitForm} disabled={isSubmitting}>
              Confirm
            </GlobalButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default HandleRoleForm;

import React from 'react';

import StyledTableCell from './StyledTableCell';
import { ReactComponent as CheckCircleIcon } from '../../images/check-circle.svg';
import { ReactComponent as CancelCircleIcon } from '../../images/cancel-circle.svg';
import { useTableStyles } from '../../styles/common/tableStyles';
import CustomTableTooltip from './CustomTableTooltip';

const TableCellAssignable = props => {
  const { isAssignable, customTooltipText } = props;
  const classes = useTableStyles();

  return (
    <StyledTableCell icon noWrap align="center">
      <div className={classes.verticalCenter}>
        {isAssignable ? (
          <CheckCircleIcon className={classes.greenIcon} />
        ) : customTooltipText ? (
          <CustomTableTooltip title={customTooltipText}>
            <CancelCircleIcon className={classes.redIcon} />
          </CustomTableTooltip>
        ) : (
          <CancelCircleIcon className={classes.redIcon} />
        )}
      </div>
    </StyledTableCell>
  );
};

export default TableCellAssignable;

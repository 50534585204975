import React from 'react';
import FormikTextField from '../FormikTextField';
import { getSymbol } from '../../../utils/formatters';
import { getCurrencyCodeFromUserContext } from '../../../utils/helpers';
import withUserContext from '../../../contexts/userContext/withUserContext';

const FormikAmountField = ({ userContext, currencyCode, ...otherProps }) => {
  return (
    <FormikTextField
      {...otherProps}
      type="number"
      decoratorStart={
        currencyCode
          ? getSymbol(currencyCode)
          : getSymbol(getCurrencyCodeFromUserContext(userContext))
      }
    />
  );
};

export default withUserContext(FormikAmountField);

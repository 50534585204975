/**
 * @flow
 * @relayHash 77143fd6eef3f52e8fb5b54e1c24c5c5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateJobInput = {|
  id: string,
  name: string,
  description?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateJobMutationVariables = {|
  input: UpdateJobInput
|};
export type UpdateJobMutationResponse = {|
  +updateJob: ?{|
    +updatedJob: ?{|
      +name: string,
      +description: ?string,
    |}
  |}
|};
export type UpdateJobMutation = {|
  variables: UpdateJobMutationVariables,
  response: UpdateJobMutationResponse,
|};
*/


/*
mutation UpdateJobMutation(
  $input: UpdateJobInput!
) {
  updateJob(input: $input) {
    updatedJob {
      name
      description
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateJobInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateJobMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJobPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedJob",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateJobMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJobPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedJob",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateJobMutation",
    "id": null,
    "text": "mutation UpdateJobMutation(\n  $input: UpdateJobInput!\n) {\n  updateJob(input: $input) {\n    updatedJob {\n      name\n      description\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '612fee90b0ba5eb3aeeed892b22a0735';
module.exports = node;

import React from 'react';
import colors from '../../styles/common/colors';
import CustomTableTooltip from '../table/CustomTableTooltip';

function Message({ text, size, cursor, onClick, noMargin, tooltip }) {
  const getXCoord = () => {
    if (text.length === 1) {
      return size - 17;
    } else if (text.length === 2) {
      return size - 20;
    } else {
      return size - 23;
    }
  };
  return (
    <CustomTableTooltip title={tooltip}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={colors.darkBlueGrey}
        width={`${size}px`}
        height={`${size}px`}
        style={{ marginTop: !noMargin && '4px', cursor: cursor && 'pointer' }}
        onClick={onClick}
      >
        <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z" />
        <path d="M0 0h24v24H0z" fill="none" />
        <text
          x={getXCoord()}
          y={`${size - 14}`}
          style={{ fontSize: '9px', fill: 'white', fontFamily: 'Nunito' }}
        >
          {text.length < 3 ? text : '99+'}
        </text>
      </svg>
    </CustomTableTooltip>
  );
}

Message.defaultProps = {
  text: '',
  size: 27,
  cursor: true,
  onClick: () => {},
  noMargin: false,
  tooltip: 'Messages'
};

export default Message;

import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import FormikTextField from '../../../shared/components/form/FormikTextField';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import useDialogStyles from '../../../shared/styles/common/useDialogStyles';

import { successToast } from '../../../shared/toasts';
import UpdateFreelancerEmailRequestMutation from './mutations/UpdateFreelancerEmailRequestMutation';

const UpdateEmailForm = ({ handleClose }) => {
  const classes = useDialogStyles();

  const onSubmit = (values, { setSubmitting, setFieldError }) => {
    UpdateFreelancerEmailRequestMutation(values.email, response => {
      if (response && response.emailAlreadyExists) {
        setSubmitting(false);
        setFieldError('email', 'User with this email already exists.');
      } else if (response && response.emailSent) {
        handleClose();
        successToast('Please check your email for further instructions.');
      }
    });
  };

  const UpdateEmailFormSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .max(200, 'Please enter no more than 200 characters.').required
  });

  const initialValues = {
    email: ''
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={UpdateEmailFormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              name="email"
              component={FormikTextField}
              label="New Email Address"
              placeholder="Email@address.com"
              required
              fullWidth
            />
            <div className={classes.dialogActions}>
              <GlobalButton
                variant="transparent"
                handleClick={handleClose}
                id="cancelEmailChangeRequest"
              >
                Cancel
              </GlobalButton>
              <GlobalButton
                id="submitEmailChangeRequest"
                disabled={isSubmitting}
                handleClick={submitForm}
              >
                Update Email
              </GlobalButton>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default UpdateEmailForm;

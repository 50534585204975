// @ts-check
//import {isProdEnv} from '../utils/helpers';
import { isDevEnv } from '../utils/helpers';
const INTERCOM_APP_ID = 'vx5e1f9w';

const FREELANCER_USER_TYPE = 'Freelancer';
const ORG_STAFF_USER_TYPE = 'Org. Staff';

const intercomDisabled = () => {
  //return !isProdEnv()
  return isDevEnv();
};

const IC_TARGETS = {
  // Common Targets
  sbProducts: 'sidebar_products',
  sbReleases: 'sidebar_releases',
  sbSolicitations: 'sidebar_solicitations',
  sbDeliverables: 'sidebar_deliverables',
  sbJobs: 'sidebar_jobs',
  helpButton: 'help_button',
  profileButton: 'profile_button',

  // Organization App
  sbContractors: 'sidebar_contractors',

  // Organization Admin
  sbStaffManagement: 'sidebar_staff_management',
  sbCustomization: 'sidebar_customizations',
  sbBilling: 'sidebar_billing',
  sbContracts: 'sidebar_contracts',
  sbStorageManagement: 'sidebar_strorage_management',
  sbOrganizationProfile: 'sidebar_organization_profile',

  // Freelancer App
  sbOrganizations: 'sidebar_organizations',
  sbPortfolio: 'sidebar_portfolio',

  orgAcceptButton: 'organizations_org_accept',
  solicitationsMessage: 'solicitations_message',
  solicitationComment: 'solicitation_comment',
  solicitationContact: 'solicitation_contact',

  jobsJobInfo: 'job_info', // TODO
  jobsJobViewContract: 'job_view_contract', // TODO
  jobsJobDeliverableName: 'job_deliverables_name' // TODO
};

/**
 * Boot Intercom with Org. Staff User information.
 * User information are not present when the page loads, we need
 * to invoke this method when the user authenticates.
 *
 * TODO add custom attribute to inform Intercom if the user is
 * using the Freelancer App or the Organization App
 * https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
 * TODO add Intercom company attribute
 * https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
 *
 * @param {{
 *  email: string;
 *  firstName: string;
 *  lastName: string;
 *  orgStaff: {
 *      organization: {
 *          name: string;
 *          id: string;
 *          type: string;
 *      }
 *  };
 *  id: string;
 *  created_at?: any;
 * }} userInfo
 *
 */
const bootIntercomStaff = userInfo => {
  if (intercomDisabled()) return;
  //@ts-ignore
  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    email: userInfo.email,
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    user_id: userInfo.id,
    company: {
      company_id: userInfo.orgStaff.organization.id,
      name: userInfo.orgStaff.organization.name,
      plan: userInfo.orgStaff.organization.tierLabel
    },
    awebase_user_type: ORG_STAFF_USER_TYPE
  });
};

const updateIntercom = () => {
  if (intercomDisabled()) return;
  //@ts-ignore
  window.Intercom('update');
};

/**
 * Boot Intercom with Freelancer user information.
 * User information are not present when the page loads, we need
 * to invoke this method when the user authenticates.
 *
 * TODO add custom attribute to inform Intercom if the user is
 * using the Freelancer App or the Organization App
 * https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
 * TODO add Intercom company attribute
 * https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
 *
 * @param {{
 *  email: string;
 *  firstName: string;
 *  lastName: string;
 *  id: string;
 *  created_at?: any;
 * }} userInfo
 *
 */
const bootIntercomFreelancer = userInfo => {
  if (intercomDisabled()) return;
  //@ts-ignore
  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    email: userInfo.email,
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    user_id: userInfo.id,
    awebase_user_type: FREELANCER_USER_TYPE
  });
};

const logoutIntercom = () => {
  if (intercomDisabled()) return;
  //@ts-ignore
  window.Intercom('shutdown');
};

const showIntercom = () => {
  if (intercomDisabled()) return;
  //@ts-ignore
  window.Intercom('show');
};

export {
  bootIntercomStaff,
  bootIntercomFreelancer,
  showIntercom,
  logoutIntercom,
  updateIntercom,
  IC_TARGETS
};

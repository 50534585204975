import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

import Chip from './Chip';

const TagsGrid = props => {
  const { tags, onDelete, withMessage, ...otherProps } = props;

  return withMessage && tags.length === 0 ? (
    <Typography variant="body1" {...otherProps}>
      No tags added
    </Typography>
  ) : (
    <Grid container spacing={2} {...otherProps}>
      {tags.map(tag => (
        <Grid item key={tag.node.id}>
          <Chip id={tag.node.id} text={tag.node.name} onDelete={onDelete} />
        </Grid>
      ))}
    </Grid>
  );
};

TagsGrid.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({ id: PropTypes.string, name: PropTypes.name })
    })
  ).isRequired,
  onDelete: PropTypes.func,
  withMessage: PropTypes.bool
};

export default TagsGrid;

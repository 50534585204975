import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import Placeholder from './Placeholder';
import { useEffectWithStatus } from '../../../../hooks/useEffectWithStatus';
import {Mesh, MeshStandardMaterial} from 'three';

/**
 * This components loads and renders STL files.
 * 
 */
const STLAsset = props => {
  const { src, centerCameraOn, theme, flatShading, handlers } = props;

  const [scene, setScene] = useState(null);

  useEffectWithStatus(status => {
    let loader = new STLLoader();
    loader.load(src, geometry => {
        var material = new MeshStandardMaterial( { color: "#555555", flatShading: flatShading } );
          
        var mesh = new Mesh( geometry, material );
        mesh.position.set( 0, 0, 0);
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        mesh.flatShading = flatShading;

        status.mounted && setScene(mesh);
    });
  }, []);

  useUpdateEffect(() => {
    centerCameraOn(scene);
  }, [scene]);

  return scene ? <primitive object={scene} {...handlers} /> : <Placeholder theme={theme} />;
};

STLAsset.propTypes = {
  src: PropTypes.string.isRequired,
  centerCameraOn: PropTypes.func.isRequired,
  flatShading: PropTypes.bool,
  handlers: PropTypes.shape({
    onPointerMove: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onClick: PropTypes.func
  })
};

export default STLAsset;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const updateDeliverableMutation = graphql`
  mutation UpdateDeliverableMutation($input: UpdateDeliverableInput!) {
    updateDeliverable(input: $input) {
      updatedDeliverable {
        id
        title
        description
        internalId
        state
        release {
          id
          name
        }
        category {
          id
          name
        }
        categoryType {
          name
        }
        amount
        amountInHomeCurrency
        currencyCode
        assignedStaff {
          id
          fullName
          representativeImageUrl
          user {
            id
          }
        }
        tags {
          edges {
            node {
              id
              name
            }
          }
        }
        dueDate
        managerNote
      }
    }
  }
`;

export default (variables, callback) => {
  const updateDeliverableVariables = {
    input: variables
  };

  const config = {
    mutation: updateDeliverableMutation,
    variables: updateDeliverableVariables,
    onCompleted: (response, errors) => {
      callback(response.updateDeliverable, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import SolicitationSearchableContractorDropdown from './SolicitationSearchableContractorDropdown';
import SolicitationSearchableContractorGroupDropdown from './SolicitationSearchableContractorGroupDropdown';

const ContractorDropdowns = props => {
  const { onChange, contractorsInList } = props;

  const addContractorHandler = contractor => {
    if (contractor) {
      onChange([contractor]);
    }
  };

  const addGroupHandler = group => {
    if (group) {
      const contractorsFromGroup = group.contractors.edges
        .filter(
          contractorInGroup =>
            !contractorsInList.find(contractor => contractorInGroup.node.id === contractor.id) &&
            !contractorInGroup.node.conflictingContractor
        )
        .map(contractor => contractor.node);
      onChange(contractorsFromGroup);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SolicitationSearchableContractorDropdown
          contractorsInList={contractorsInList}
          onChange={addContractorHandler}
        />
      </Grid>
      <Grid item xs={12}>
        <SolicitationSearchableContractorGroupDropdown onChange={addGroupHandler} />
      </Grid>
    </Grid>
  );
};

ContractorDropdowns.propTypes = {
  onChange: PropTypes.func.isRequired,
  contractorsInList: PropTypes.array.isRequired
};

ContractorDropdowns.defaultProps = {
  contractorsInList: []
};

export default ContractorDropdowns;

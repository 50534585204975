/**
 * @flow
 * @relayHash b6b3db083ee4f10d2f20037de81b2c4a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InvoicesList_invoices$ref = any;
type InvoicesList_statusTypes$ref = any;
export type InvoicesPageQueryVariables = {|
  after?: ?string,
  first?: ?number,
  invoiceId?: ?string,
  jobName?: ?string,
  status?: ?string,
  contact?: ?string,
|};
export type InvoicesPageQueryResponse = {|
  +__type: ?{|
    +$fragmentRefs: InvoicesList_statusTypes$ref
  |},
  +$fragmentRefs: InvoicesList_invoices$ref,
|};
export type InvoicesPageQuery = {|
  variables: InvoicesPageQueryVariables,
  response: InvoicesPageQueryResponse,
|};
*/


/*
query InvoicesPageQuery(
  $after: String
  $first: Int
  $invoiceId: String
  $jobName: String
  $status: String
  $contact: String
) {
  ...InvoicesList_invoices_Ncswc
  __type(name: "JobInvoiceStatus") {
    ...InvoicesList_statusTypes
  }
}

fragment InvoicesList_invoices_Ncswc on Query {
  allInvoices(orderBy: "-created", after: $after, first: $first, invoiceId: $invoiceId, jobName: $jobName, status: $status, contact: $contact) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        identifier
        created
        verificationDate
        job {
          id
          name
          staffCoordinator {
            fullName
            representativeImageUrl
            id
          }
          currencyCode
          totalValue
        }
        status
        amount
      }
    }
  }
}

fragment InvoicesList_statusTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "invoiceId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "jobName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contact",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "JobInvoiceStatus"
  }
],
v2 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v3 = {
  "kind": "Variable",
  "name": "contact",
  "variableName": "contact"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = {
  "kind": "Variable",
  "name": "invoiceId",
  "variableName": "invoiceId"
},
v6 = {
  "kind": "Variable",
  "name": "jobName",
  "variableName": "jobName"
},
v7 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "InvoicesPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"JobInvoiceStatus\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InvoicesList_statusTypes",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "InvoicesList_invoices",
        "args": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "InvoicesPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allInvoices",
        "storageKey": null,
        "args": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "-created"
          },
          (v7/*: any*/)
        ],
        "concreteType": "InvoiceNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edgeCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPreviousPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "InvoiceNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "InvoiceNode",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "identifier",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "created",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "verificationDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "job",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobNode",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "staffCoordinator",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "representativeImageUrl",
                            "args": null,
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currencyCode",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "totalValue",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "amount",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"JobInvoiceStatus\")",
        "args": (v1/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "InvoicesPageQuery",
    "id": null,
    "text": "query InvoicesPageQuery(\n  $after: String\n  $first: Int\n  $invoiceId: String\n  $jobName: String\n  $status: String\n  $contact: String\n) {\n  ...InvoicesList_invoices_Ncswc\n  __type(name: \"JobInvoiceStatus\") {\n    ...InvoicesList_statusTypes\n  }\n}\n\nfragment InvoicesList_invoices_Ncswc on Query {\n  allInvoices(orderBy: \"-created\", after: $after, first: $first, invoiceId: $invoiceId, jobName: $jobName, status: $status, contact: $contact) {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        identifier\n        created\n        verificationDate\n        job {\n          id\n          name\n          staffCoordinator {\n            fullName\n            representativeImageUrl\n            id\n          }\n          currencyCode\n          totalValue\n        }\n        status\n        amount\n      }\n    }\n  }\n}\n\nfragment InvoicesList_statusTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '863235ace2b044f086ec4fe59581ef3c';
module.exports = node;

import React, { useState, Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import ProductActionLogList from './ProductActionLogList';
import ProductToolsActions from './ProductToolsActions';

const ProductToolsTab = props => {
  const { id } = useParams();
  const variables = useTableHistoryQueryVariables();
  const [refetchCounterForTools, setRefetchCounterForTools] = useState(0);

  return (
    <QueryRenderer
      query={graphql`
        query ProductToolsTabQuery($id: ID!, $first: Int, $after: String) {
          product(id: $id) {
            ...ProductToolsActions_product
            ...ProductActionLogList_product
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <Fragment>
          <ProductToolsActions
            product={relayProps.product}
            {...props}
            setRefetchCounterForTools={setRefetchCounterForTools}
          />
          <ProductActionLogList
            product={relayProps.product}
            {...props}
            refetchCounterForTools={refetchCounterForTools}
          />
        </Fragment>
      )}
    />
  );
};

export default withTableHistoryProvider(ProductToolsTab, 'orgProductTools', {
  rowsPerPage: 10
});

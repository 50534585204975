import React, { Fragment } from 'react';
import AnimateHeight from 'react-animate-height';
import { Grid } from '@material-ui/core';

import GlobalButton from '../UI/GlobalButton';
import FilterCheckbox from '../filters/FilterCheckbox';

import { useFilterStyles } from '../../styles/common/filterStyles';

const FiltersContainer = props => {
  const {
    showFilters,
    width,
    withApply,
    onApplyFilters,
    onToggleFilters,
    withSaveAsDefault,
    saveAsDefaultValue,
    handleSaveAsDefault
  } = props;

  const filtersHeight = showFilters ? 'auto' : 0;
  const classes = useFilterStyles({ width });

  return (
    <AnimateHeight duration={300} height={filtersHeight}>
      {(withApply || withSaveAsDefault) && (
        <Grid
          className={classes.filterActionsContainer}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          {withSaveAsDefault && (
            <FilterCheckbox
              id="filterSaveAsDefault"
              name="saveAsDefault"
              onChange={handleSaveAsDefault}
              value={saveAsDefaultValue}
              label="Save current selection as default"
            />
          )}
          {withApply && (
            <Fragment>
              <GlobalButton variant="alternative" handleClick={onToggleFilters}>
                Cancel
              </GlobalButton>
              <GlobalButton handleClick={onApplyFilters}>Apply</GlobalButton>
            </Fragment>
          )}
        </Grid>
      )}
    </AnimateHeight>
  );
};

export default FiltersContainer;

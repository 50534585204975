import { fabric } from 'fabric';
import { ATTRS, TOOLS } from './contants';
import { resetTool } from './commons';
import { hideControls } from '../CanvasData';

const toolDot = (canvas, { annotationColor, userId, annotationRef, frameRef }) => {
  resetTool(canvas, TOOLS.dot.name);
  const mouseDownHandler = event => {
    let dot = new fabric.Circle({
      left: event.absolutePointer.x,
      top: event.absolutePointer.y,
      originX: 'center',
      originY: 'center',
      radius: 10,
      selectable: false,
      fill: annotationColor,
      [ATTRS.originalColor]: annotationColor,
      [ATTRS.type]: TOOLS.dot.name,
      [ATTRS.authorId]: userId,
      [ATTRS.ref]: annotationRef,
      [ATTRS.frameRef]: frameRef
    });
    hideControls(dot, ['tl', 'tr', 'br', 'bl', 'mtr', 'mt', 'ml', 'mr', 'mb']);
    canvas.add(dot);
  };

  canvas.on({
    'mouse:down': mouseDownHandler
  });
};

export { toolDot };

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type JobPaymentStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobsList_jobs$ref: FragmentReference;
declare export opaque type JobsList_jobs$fragmentType: JobsList_jobs$ref;
export type JobsList_jobs = {|
  +allJobs: ?{|
    +totalCount: ?number,
    +edgeCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +created: any,
        +jobId: ?number,
        +jobDeadline: ?any,
        +status: JobStatus,
        +name: string,
        +releases: ?$ReadOnlyArray<?{|
          +id: string,
          +name: string,
          +product: {|
            +id: string,
            +title: string,
          |},
        |}>,
        +isCurrentUserFollowing: ?boolean,
        +deliverableCategory: ?{|
          +name: string
        |},
        +contractor: {|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |},
        +staffCoordinator: {|
          +id: string,
          +fullName: ?string,
          +representativeImageUrl: ?string,
        |},
        +totalValue: number,
        +currencyCode: ?string,
        +paymentStatus: JobPaymentStatus,
        +hasInvoicesToReview: ?boolean,
      |}
    |}>,
  |},
  +$refType: JobsList_jobs$ref,
|};
export type JobsList_jobs$data = JobsList_jobs;
export type JobsList_jobs$key = {
  +$data?: JobsList_jobs$data,
  +$fragmentRefs: JobsList_jobs$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "JobsList_jobs",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "jobDeadlineFrom",
      "type": "Date"
    },
    {
      "kind": "RootArgument",
      "name": "jobDeadlineTo",
      "type": "Date"
    },
    {
      "kind": "RootArgument",
      "name": "onlyFollowed",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "jobId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "releaseId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "productId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "category",
      "type": "ID"
    },
    {
      "kind": "RootArgument",
      "name": "status",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "paymentStatus",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "includeInactive",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "contractorId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "staffId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "name",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "allJobs",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "category",
          "variableName": "category"
        },
        {
          "kind": "Variable",
          "name": "contractorId",
          "variableName": "contractorId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "includeInactive",
          "variableName": "includeInactive"
        },
        {
          "kind": "Variable",
          "name": "jobDeadlineFrom",
          "variableName": "jobDeadlineFrom"
        },
        {
          "kind": "Variable",
          "name": "jobDeadlineTo",
          "variableName": "jobDeadlineTo"
        },
        {
          "kind": "Variable",
          "name": "jobId",
          "variableName": "jobId"
        },
        {
          "kind": "Variable",
          "name": "jobName",
          "variableName": "name"
        },
        {
          "kind": "Variable",
          "name": "onlyFollowed",
          "variableName": "onlyFollowed"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "paymentStatus",
          "variableName": "paymentStatus"
        },
        {
          "kind": "Variable",
          "name": "productId",
          "variableName": "productId"
        },
        {
          "kind": "Variable",
          "name": "releaseId",
          "variableName": "releaseId"
        },
        {
          "kind": "Variable",
          "name": "staffId",
          "variableName": "staffId"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "status"
        }
      ],
      "concreteType": "JobNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "JobNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "created",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jobId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jobDeadline",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "releases",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ReleaseNode",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "product",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ProductNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "title",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isCurrentUserFollowing",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "deliverableCategory",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CategoryNode",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contractor",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractorNode",
                  "plural": false,
                  "selections": (v2/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "staffCoordinator",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": (v2/*: any*/)
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "totalValue",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currencyCode",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "paymentStatus",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hasInvoicesToReview",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8b482a6a004dd4dd7ab6075d25b7abc';
module.exports = node;

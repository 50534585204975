import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Typography, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TransferAccountOwnershipMutation from '../../mutations/TransferAccountOwnershipMutation';
import { successToast } from '../../../../../../shared/toasts';
import GlobalButton from '../../../../../../shared/components/UI/GlobalButton';
import FormikSearchableDropdown from '../../../../../../shared/components/form/FormikSearchableDropdown';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '0',
    paddingBottom: theme.spacing(4)
  }
}));

const TransferOwnershipForm = props => {
  const { handleClose, userContext } = props;

  const classes = useStyles();

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    TransferAccountOwnershipMutation(values.newOwner.value, response => {
      setSubmitting(false);
      if (response && response.currentStaffUser) {
        userContext.setUserInfo({ ...userContext, orgStaff: response.currentStaffUser });
        handleClose();
        successToast('Account ownership was transferred.');
      }
    });
  };
  const validate = values => {
    let errors = {};
    if (!values.newOwner) {
      errors.newOwner = 'Required';
    }
    return errors;
  };

  return (
    <Formik initialValues={{ staff: null }} validate={validate} onSubmit={onSubmitHandler}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              <Typography variant="body1" component="span">
                Please select another Staff Account with an Admin Role as the new Owner.
              </Typography>
            </DialogContentText>
            <Field component={FormikSearchableDropdown} name="newOwner" required fullWidth />
          </DialogContent>
          <DialogActions>
            <GlobalButton variant="transparent" handleClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GlobalButton>
            <GlobalButton handleClick={submitForm} disabled={isSubmitting}>
              Confirm
            </GlobalButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default TransferOwnershipForm;

/**
 * @flow
 * @relayHash e529d98ffb257cf4f21fdf09895443f3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateReferenceInput = {|
  title: string,
  description?: ?string,
  deliverable: string,
  stagedPath?: ?string,
  clientMutationId?: ?string,
|};
export type CreateReferenceMutationVariables = {|
  input: CreateReferenceInput
|};
export type CreateReferenceMutationResponse = {|
  +createReference: ?{|
    +newReference: ?{|
      +id: string,
      +title: ?string,
      +fileUrl: ?string,
      +reviewInterface: ?string,
    |}
  |}
|};
export type CreateReferenceMutation = {|
  variables: CreateReferenceMutationVariables,
  response: CreateReferenceMutationResponse,
|};
*/


/*
mutation CreateReferenceMutation(
  $input: CreateReferenceInput!
) {
  createReference(input: $input) {
    newReference {
      id
      title
      fileUrl
      reviewInterface
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateReferenceInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createReference",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateReferencePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "newReference",
        "storageKey": null,
        "args": null,
        "concreteType": "ReferenceNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reviewInterface",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateReferenceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateReferenceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateReferenceMutation",
    "id": null,
    "text": "mutation CreateReferenceMutation(\n  $input: CreateReferenceInput!\n) {\n  createReference(input: $input) {\n    newReference {\n      id\n      title\n      fileUrl\n      reviewInterface\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8899edf0fe80489db4ac8eeb9ddb4bbf';
module.exports = node;

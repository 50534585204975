import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation DeactivateBatchStaffUsersMutation($input: DeactivateBatchStaffUsersInput!) {
    deactivateBatchStaffUsers(input: $input) {
      updatedStaffUsers {
        id
        user {
          isActive
        }
      }
    }
  }
`;

const DeactivateBatchStaffUsersMutation = (ids, callback) => {
  const config = {
    mutation,
    variables: { input: { ids } },
    onCompleted: response => {
      callback(response.deactivateBatchStaffUsers);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default DeactivateBatchStaffUsersMutation;

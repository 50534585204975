import React, { useState, useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';

import { Redirect } from 'react-router-dom';
import authService from '../../../shared/services/authService';
import useQueryParams from '../../../shared/hooks/useQueryParams';
import { fetchQuery } from 'react-relay';
import { getEnvironment } from '../../../shared/utils/helpers';
import CompleteStaffAccountMutation from './mutations/CompleteStaffAccountMutation';
import ConfirmPassword from '../../../shared/components/account/ConfirmPassword';

const CompleteStaffAccount = props => {
  const [error, setError] = useState('');

  const [validLink, setValidLink] = useState(undefined);
  const [redirectToLogin, setRedirectToLogin] = useState(undefined);
  const [redirectHome, setRedirectHome] = useState(undefined);
  const queryParams = useQueryParams();

  const checkTokenQuery = graphql`
    query CompleteStaffAccountQuery($staffToken: String!, $uidb64: String!) {
      completeAccountTokenStatus(staffToken: $staffToken, uidb64: $uidb64)
    }
  `;

  useEffect(() => {
    // Force-logout before creating new account if user is logged in as someone else
    authService._removeTokens();
    if (queryParams.get('staff-token') && queryParams.get('uidb64')) {
      fetchQuery(getEnvironment(), checkTokenQuery, {
        staffToken: queryParams.get('staff-token'),
        uidb64: queryParams.get('uidb64')
      }).then(response => {
        if (response.completeAccountTokenStatus) {
          setValidLink(true);
        } else {
          setValidLink(false);
        }
      });
    } else {
      // if any of query params is missing, redirect to login page
      setRedirectToLogin(true);
    }
  }, []);

  if (redirectHome)
    return (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    );
  if (redirectToLogin)
    return (
      <Redirect
        to={{
          pathname: '/login'
        }}
      />
    );

  const submitHandler = (values, actions) => {
    CompleteStaffAccountMutation(
      values.password,
      queryParams.get('staff-token'),
      queryParams.get('uidb64'),
      response => {
        actions.setSubmitting(false);
        if (response && response.completeStaffAccount && response.completeStaffAccount.user) {
          // it logs in user automatically if account creation was successful
          authService.signIn(
            response.completeStaffAccount.user.email,
            values.password,
            (success, error) => {
              if (success) {
                setRedirectHome(true);
              } else {
                setError(error);
              }
            }
          );
        } else {
          setError('Something went wrong.');
          console.error('Account completion failed.');
        }
      }
    );
  };

  return <ConfirmPassword submitHandler={submitHandler} validLink={validLink} error={error} />;
};

export default CompleteStaffAccount;

/**
 * @flow
 * @relayHash cc85afb6d3b5697367497d1fcadb072a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountInformationContent_accountInformation$ref = any;
export type AccountInformationTabQueryVariables = {||};
export type AccountInformationTabQueryResponse = {|
  +$fragmentRefs: AccountInformationContent_accountInformation$ref
|};
export type AccountInformationTabQuery = {|
  variables: AccountInformationTabQueryVariables,
  response: AccountInformationTabQueryResponse,
|};
*/


/*
query AccountInformationTabQuery {
  ...AccountInformationContent_accountInformation
}

fragment AccountInformationContent_accountInformation on Query {
  currentUser {
    firstName
    lastName
    fullName
    email
    representativeImageUrl
    orgStaff {
      id
      roleLabel
      phone
      title
      organization {
        name
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountInformationTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "AccountInformationContent_accountInformation",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountInformationTabQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "UserNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fullName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "representativeImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "orgStaff",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "roleLabel",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "phone",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ]
              }
            ]
          },
          (v0/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountInformationTabQuery",
    "id": null,
    "text": "query AccountInformationTabQuery {\n  ...AccountInformationContent_accountInformation\n}\n\nfragment AccountInformationContent_accountInformation on Query {\n  currentUser {\n    firstName\n    lastName\n    fullName\n    email\n    representativeImageUrl\n    orgStaff {\n      id\n      roleLabel\n      phone\n      title\n      organization {\n        name\n        id\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b8a5364e8c3b18aed1e67f321fb4197';
module.exports = node;

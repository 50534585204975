import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import StyledCheckbox from '../UI/StyledCheckbox';

const useStyles = makeStyles(theme => ({
  label: {
    marginLeft: theme.spacing(1),
    opacity: props => (props.disabled ? '0.5' : '1')
  }
}));

const FilterCheckbox = props => {
  const { name, value, label, onChange, disabled } = props;
  const classes = useStyles({ disabled });

  const onChangeHandler = () => {
    onChange(name, !value);
  };

  return (
    <FormControlLabel
      control={<StyledCheckbox checked={value} onChange={onChangeHandler} />}
      label={<Typography variant="body2">{label}</Typography>}
      classes={{ ...classes }}
      disabled={disabled}
    />
  );
};

FilterCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default FilterCheckbox;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

/**
 *
 * @param {Object} variables - Variables passed to the mutation
 * @param {string} variables.jobId - ID of the job
 * @param {string} variables.content - Content of the comment
 * @param {*} callback - Callback that receives 'response' and 'errors' as arguments
 */

const CreateJobCommentMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateJobCommentMutation($input: CreateJobCommentInput!) {
        createJobComment(input: $input) {
          job {
            comments {
              edges {
                node {
                  id
                  content
                  created
                  contentEditedAt
                  repliedTo {
                    user {
                      fullName
                    }
                    content
                    created
                  }
                  user {
                    id
                    fullName
                    representativeImageUrl
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.createJobComment, errors);
    }
  });
};

CreateJobCommentMutation.propTypes = {
  variables: PropTypes.shape({
    jobId: PropTypes.string.isRequired,
    content: PropTypes.string
  })
};

export default CreateJobCommentMutation;

/**
 * @flow
 * @relayHash 92e1878298e9cba15c67911652631e6a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BillingPaymentQueryVariables = {||};
export type BillingPaymentQueryResponse = {|
  +stripeSessionIdUpdatePayment: ?string
|};
export type BillingPaymentQuery = {|
  variables: BillingPaymentQueryVariables,
  response: BillingPaymentQueryResponse,
|};
*/


/*
query BillingPaymentQuery {
  stripeSessionIdUpdatePayment
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "stripeSessionIdUpdatePayment",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BillingPaymentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BillingPaymentQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "BillingPaymentQuery",
    "id": null,
    "text": "query BillingPaymentQuery {\n  stripeSessionIdUpdatePayment\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76b67ce9f9312c74cc03bf139adaf685';
module.exports = node;

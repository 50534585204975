import { makeStyles } from '@material-ui/styles';

const useDialogStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      overflow: 'auto',
      maxWidth: props => `${props.maxWidth}px` || '600px'
    }
  },

  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: props => (props.noHeaderMargin ? '0px' : theme.spacing(3)),

    '& h2': {
      lineHeight: `${theme.spacing(4)}px`
    }
  },

  dialogContent: {
    padding: theme.spacing(3),
    minWidth: props => (props.minWidth ? `${props.minWidth}px` : '')
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '64px'
  },
  annotationCommentForm: {
    width: '450px'
  },

  marginTop: {
    marginTop: theme.spacing(3)
  },
  fullWidth: {
    width: '100%'
  },
  radioButtonOptions: {
    '& label': {
      marginBottom: '10px'
    }
  }
}));

export default useDialogStyles;

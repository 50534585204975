import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation RevokeJobMutation($input: RevokeJobInput!) {
    revokeJob(input: $input) {
      isRevoked
      job {
        status
        deliverables {
          edges {
            node {
              state
              assignedContractor {
                representativeImageUrl
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

const RevokeJobMutation = (id, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { id } },
    onCompleted: response => {
      callback(response.revokeJob);
    },
    onError: err => console.error(err)
  });
};

export default RevokeJobMutation;

import { makeStyles } from '@material-ui/core/styles';

export const commonAnnotationStyles = theme => ({
  toolbarButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&& button:not(:last-child)': {
      marginRight: theme.spacing(1)
    },
    '& > button:first-child': {
      marginLeft: 0
    }
  },

  sliderControlContainer: {
    position: 'relative'
  },

  sliderContainer: {
    position: 'absolute',
    height: '125px',
    width: '32px',
    backgroundColor: 'white',
    zIndex: '1000',
    border: `1px solid ${theme.palette.colors.lightBlueGrey}`,
    top: '-125px',
    left: '0',
    padding: `${theme.spacing(2)}px 0`
  },

  timerContainer: {
    padding: `0 ${theme.spacing(2)}px`,

    '& p': {
      color: props => (props.primary ? theme.palette.primary.main : ''),
      fontSize: '12px'
    }
  },

  progressToolbarContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },

  progressBarContainer: {
    flex: '1 1 auto',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}px`
  },

  progressBar: {
    flex: '1 1 auto',
    borderRadius: '3px',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  progressBarAnnotation: {
    position: 'absolute',
    backgroundColor: props =>
      props.isActive ? theme.palette.primary.main : theme.palette.colors.annotationStroke,
    width: '7px',
    height: '13px',
    left: props => `${props.left * 100}%`,
    border: '1px solid white',

    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export const useCommonAnnotationStyles = makeStyles(commonAnnotationStyles);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const ToggleAssignFollowersForJobsMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation ToggleAssignFollowersForJobsMutation($input: ToggleAssignFollowersForJobsInput!) {
          toggleAssignFollowersForJobs(input: $input) {
            success
            clientMutationId
          }
        }
      `,
      variables: {
        input
      },
      onCompleted: resolve,
      onError: reject
    });
  });

export default ToggleAssignFollowersForJobsMutation;

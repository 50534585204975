import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import StyledHeader from '../shared/StyledHeader';

const Header = props => {
  const { storage } = props.storageInfo;

  return (
    <StyledHeader
      activeStorage={storage.activeStorageFinalFiles}
      archiveStorage={storage.archivedStorageFinalFiles}
    />
  );
};

export default createFragmentContainer(Header, {
  storageInfo: graphql`
    fragment DeliverableFilesHeader_storageInfo on OrganizationNode {
      storage {
        activeStorageFinalFiles
        archivedStorageFinalFiles
      }
    }
  `
});

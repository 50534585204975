import React from 'react';
import styled from 'styled-components';
import AddReviewAssetReaction from '../../../../organization/components/deliverables/reactions/AddReviewAssetReactionButton';
import ToggleReviewAssetReactionMutation from '../../../../organization/components/deliverables/reactions/ToggleReviewAssetReactionMutation';
import { ReactComponent as AddReactionIcon } from '../../../images/add-reaction-icon.svg';
import SquareButton from '../../UI/SquareButton';
import ReactionCard, {
  CounterContainer as ReactionCounterContainer,
  LabelContainer as ReactionLabelContainer
} from '../review/ReactionCard';

const Container = styled.div`
  position: absolute;
  left: 24px;
  top: 24px;
  right: 64px;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;

  ${ReactionCard} {
    margin-right: 8px;
    margin-top: 3px;
    margin-bottom: 3px;

    ${ReactionLabelContainer} {
      border-color: ${props => props.theme.palette.colors.darkBlueGrey};
    }

    ${ReactionCounterContainer} {
      background: ${props => props.theme.palette.colors.darkBlueGrey};
      color: #fff;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-right: 24px;
`;

const ReactionsOverlay = ({ reviewAsset, canAddReaction }) => {
  return (
    <Container>
      {canAddReaction && (
        <AddReviewAssetReaction
          reviewAsset={reviewAsset}
          renderButton={({ handleToggle }) => (
            <ButtonContainer>
              <SquareButton
                variant="black"
                size={34}
                icon={AddReactionIcon}
                onClick={handleToggle}
              />
            </ButtonContainer>
          )}
        />
      )}
      {reviewAsset.reactions &&
        reviewAsset.reactions.length > 0 &&
        reviewAsset.reactions.map(reaction => (
          <ReactionCard
            key={reaction.id}
            reaction={reaction}
            onDelete={() => {
              ToggleReviewAssetReactionMutation({
                reactionId: reaction.definitionId,
                reviewAssetId: reviewAsset.id
              });
            }}
          />
        ))}
    </Container>
  );
};

export default ReactionsOverlay;

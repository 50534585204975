/**
 * @flow
 * @relayHash fd95bfb2fa0f8ff7ebfee483c764eeab
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SendBulkInvitationsToContractorsInput = {|
  ids: $ReadOnlyArray<?string>,
  message?: ?string,
  clientMutationId?: ?string,
|};
export type BulkSendInvitationsToContractorsMutationVariables = {|
  input: SendBulkInvitationsToContractorsInput
|};
export type BulkSendInvitationsToContractorsMutationResponse = {|
  +sendBulkInvitationsToContractors: ?{|
    +updatedContractors: ?$ReadOnlyArray<?{|
      +id: string
    |}>
  |}
|};
export type BulkSendInvitationsToContractorsMutation = {|
  variables: BulkSendInvitationsToContractorsMutationVariables,
  response: BulkSendInvitationsToContractorsMutationResponse,
|};
*/

/*
mutation BulkSendInvitationsToContractorsMutation(
  $input: SendBulkInvitationsToContractorsInput!
) {
  sendBulkInvitationsToContractors(input: $input) {
    updatedContractors {
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'SendBulkInvitationsToContractorsInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'sendBulkInvitationsToContractors',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'SendBulkInvitationsToContractorsPayload',
        plural: false,
        selections: [
          {
            kind: 'LinkedField',
            alias: null,
            name: 'updatedContractors',
            storageKey: null,
            args: null,
            concreteType: 'ContractorNode',
            plural: true,
            selections: [
              {
                kind: 'ScalarField',
                alias: null,
                name: 'id',
                args: null,
                storageKey: null
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'BulkSendInvitationsToContractorsMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'BulkSendInvitationsToContractorsMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'BulkSendInvitationsToContractorsMutation',
      id: null,
      text:
        'mutation BulkSendInvitationsToContractorsMutation(\n  $input: SendBulkInvitationsToContractorsInput!\n) {\n  sendBulkInvitationsToContractors(input: $input) {\n    updatedContractors {\n      id\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '4fb469f510a30a89bc46f225f11bd432';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import GreyBarWithContactInfo from '../../../../../shared/components/UI/GreyBarWithContactInfo';
import { getCountryName } from '../../../../../shared/utils/helpers';

const DetailsContact = props => {
  if (!props.deliverable.assignedStaff) return null;
  const {
    assignedStaff: {
      fullName,
      representativeImageUrl,
      organization: {
        name: organizationName,
        representativeImageUrl: organizationRepresentativeImageUrl,
        country
      }
    }
  } = props.deliverable;
  return (
    <GreyBarWithContactInfo
      {...{
        fullName,
        representativeImageUrl,
        organizationCountry: getCountryName(country),
        organizationName,
        organizationRepresentativeImageUrl
      }}
    />
  );
};

export default createFragmentContainer(DetailsContact, {
  deliverable: graphql`
    fragment DetailsContact_deliverable on DeliverableNode {
      assignedStaff {
        fullName
        representativeImageUrl
        organization {
          representativeImageUrl
          name
          country {
            name
          }
        }
      }
    }
  `
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type StorageClassEnum = "DEEP_ARCHIVE" | "GLACIER" | "RESTORING" | "STANDARD" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeliverableReview_deliverable$ref: FragmentReference;
declare export opaque type DeliverableReview_deliverable$fragmentType: DeliverableReview_deliverable$ref;
export type DeliverableReview_deliverable = {|
  +id: string,
  +job: ?{|
    +id: string,
    +name: string,
    +contractor: {|
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |},
  |},
  +state: DeliverableState,
  +description: ?string,
  +assignedStaff: ?{|
    +id: string,
    +fullName: ?string,
    +representativeImageUrl: ?string,
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
    |},
  |},
  +assignedInternally: ?{|
    +user: {|
      +id: string,
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |}
  |},
  +reviewAssetFileValidationConfig: ?$ReadOnlyArray<?{|
    +reviewInterface: ?string,
    +fileTypes: ?$ReadOnlyArray<?string>,
    +fileSize: ?number,
  |}>,
  +reviewActive: ?{|
    +id: string,
    +step: {|
      +id: string,
      +name: string,
    |},
    +reviewStepList: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
    |}>,
    +deliverableReviewAssets: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileThumbnailUrl: ?string,
          +fileName: string,
          +created: any,
          +revisionNumber: ?number,
          +reviewInterface: ?string,
          +storageClass: ?StorageClassEnum,
          +reactions: ?$ReadOnlyArray<?{|
            +id: ?string,
            +definitionId: ?string,
            +name: ?string,
            +color: ?string,
            +number: ?number,
            +users: ?$ReadOnlyArray<?{|
              +id: string,
              +fullName: ?string,
              +representativeImageUrl: ?string,
            |}>,
          |}>,
          +comments: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +created: any,
                +content: string,
                +extraData: ?any,
                +contentEditedAt: ?any,
                +repliedTo: ?{|
                  +content: ?string,
                  +created: ?any,
                  +user: ?{|
                    +fullName: ?string
                  |},
                  +referenceFiles: ?$ReadOnlyArray<?{|
                    +id: string,
                    +title: ?string,
                    +description: ?string,
                    +fileUrl: ?string,
                    +reviewInterface: ?string,
                  |}>,
                |},
                +user: ?{|
                  +id: string,
                  +fullName: ?string,
                  +representativeImageUrl: ?string,
                |},
                +referenceFiles: ?$ReadOnlyArray<?{|
                  +id: string,
                  +title: ?string,
                  +description: ?string,
                  +fileUrl: ?string,
                  +reviewInterface: ?string,
                |}>,
                +reactions: ?$ReadOnlyArray<?{|
                  +id: ?string,
                  +definitionId: ?string,
                  +name: ?string,
                  +color: ?string,
                  +number: ?number,
                  +users: ?$ReadOnlyArray<?{|
                    +id: string,
                    +fullName: ?string,
                    +representativeImageUrl: ?string,
                  |}>,
                |}>,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +$refType: DeliverableReview_deliverable$ref,
|};
export type DeliverableReview_deliverable$data = DeliverableReview_deliverable;
export type DeliverableReview_deliverable$key = {
  +$data?: DeliverableReview_deliverable$data,
  +$fragmentRefs: DeliverableReview_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": (v5/*: any*/)
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reviewInterface",
  "args": null,
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reactions",
  "storageKey": null,
  "args": null,
  "concreteType": "ReactionNode",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "definitionId",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "users",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": true,
      "selections": (v5/*: any*/)
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referenceFiles",
  "storageKey": null,
  "args": null,
  "concreteType": "ReferenceNode",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/)
  ]
};
return {
  "kind": "Fragment",
  "name": "DeliverableReview_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "job",
      "storageKey": null,
      "args": null,
      "concreteType": "JobNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contractor",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractorNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignedStaff",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "UserNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignedInternally",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        (v6/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "reviewAssetFileValidationConfig",
      "storageKey": null,
      "args": null,
      "concreteType": "ReviewAssetFileValidationConfig",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fileTypes",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fileSize",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "reviewActive",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableReviewNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "step",
          "storageKey": null,
          "args": null,
          "concreteType": "StepNode",
          "plural": false,
          "selections": (v8/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "reviewStepList",
          "storageKey": null,
          "args": null,
          "concreteType": "StepNode",
          "plural": true,
          "selections": (v8/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "deliverableReviewAssets",
          "storageKey": null,
          "args": null,
          "concreteType": "ReviewAssetNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ReviewAssetNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ReviewAssetNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fileThumbnailUrl",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fileName",
                      "args": null,
                      "storageKey": null
                    },
                    (v9/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "revisionNumber",
                      "args": null,
                      "storageKey": null
                    },
                    (v7/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "storageClass",
                      "args": null,
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "comments",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CommentNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CommentNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CommentNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v9/*: any*/),
                                (v11/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "extraData",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "contentEditedAt",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "repliedTo",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CommentRepliedToNode",
                                  "plural": false,
                                  "selections": [
                                    (v11/*: any*/),
                                    (v9/*: any*/),
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "user",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "UserNode",
                                      "plural": false,
                                      "selections": [
                                        (v2/*: any*/)
                                      ]
                                    },
                                    (v12/*: any*/)
                                  ]
                                },
                                (v6/*: any*/),
                                (v12/*: any*/),
                                (v10/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a4d9bd476d6dcb437e0d125c982b96e1';
module.exports = node;

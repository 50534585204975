import React, { useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import DeliverableReferencesContent from '../../../../../shared/components/deliverable/DeliverableReferencesContent';
import ExposeReferenceToSolicitationMutation from '../../mutations/ExposeReferenceToSolicitationMutation';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';

const DeliverableReferencesList = props => {
  const {
    deliverable,
    userContext: {
      orgStaff: { allowedActions }
    },
    relay: { refetch }
  } = props;

  const [optimisticExposeToSolicitationIndex, setOptimisticExposeToSolicitationIndex] = useState(
    {}
  );

  const handleExposeToSolicitationChange = (id, value) => {
    setOptimisticExposeToSolicitationIndex(state => ({ ...state, [id]: value }));
    ExposeReferenceToSolicitationMutation({ id, expose: value }, (res, errors) => {
      if (errors) {
        setOptimisticExposeToSolicitationIndex(state => ({ ...state, [id]: undefined }));
      }
    });
  };

  const canModifyReferences = () =>
    computePermissionRole(ALLOWED_ACTIONS.DELIVERABLE_UPLOAD_REFERENCE_FILE, allowedActions);
  const canExposeToSolicitation = computePermissionRole(
    ALLOWED_ACTIONS.DELIVERABLE_EXPOSE_REFERENCE_TO_SOLICITATION,
    allowedActions
  );

  return (
    <DeliverableReferencesContent
      deliverable={deliverable}
      refetch={refetch}
      toggleAction={{
        label: 'Display on JobOpps',
        getValue: id =>
          optimisticExposeToSolicitationIndex[id] !== undefined
            ? optimisticExposeToSolicitationIndex[id]
            : deliverable.references.edges.find(edge => edge.node.id === id).node
                .exposeToSolicitation,
        onChange: handleExposeToSolicitationChange,
        disabled: !canExposeToSolicitation
      }}
      canEditItem={canModifyReferences}
      canDeleteItem={canModifyReferences}
    />
  );
};

export default withUserContext(
  createRefetchContainer(
    DeliverableReferencesList,
    {
      deliverable: graphql`
        fragment DeliverableReferencesList_deliverable on DeliverableNode {
          id
          references(first: $first, after: $after, orderBy: $orderBy) {
            totalCount
            edgeCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                title
                fileUrl
                description
                reviewInterface
                exposeToSolicitation
                user {
                  id
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query DeliverableReferencesListRefetchQuery(
        $id: ID!
        $first: Int
        $after: String
        $orderBy: String
      ) {
        deliverable(id: $id) {
          ...DeliverableReferencesList_deliverable
        }
      }
    `
  )
);

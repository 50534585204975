import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';
import TableCellRelationLink from './TableCellRelationLink';


const TableCellListWithMultiple = props => {
  const { items, multipleValue, ...otherProps } = props;

  if (items.length === 0) {
    return <StyledTableCell {...otherProps} >-</StyledTableCell>;
  }

  if (items.length > 1 ) {
    return (
      <StyledTableCell {...otherProps}>
        { multipleValue }
      </StyledTableCell>
    );
  } else {
    return (
      <TableCellRelationLink {...otherProps}
        cellText={items[0].cellText}
        cellLink={items[0].cellLink} />
    );
  }
};

TableCellListWithMultiple.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      cellText: PropTypes.string.isRequired,
      cellLink: PropTypes.string.isRequired,
    })
  ).isRequired,
  truncate: PropTypes.bool,
  multipleValue: PropTypes.string.isRequired
};

export default TableCellListWithMultiple;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  circularProgress: {
    display: 'flex',
    color: '#FFF',
    padding: '0 20px'
  }
}));
const Spinner = () => {
  const classes = useStyles();
  return (
    <span className={classes.circularProgress}>
      <CircularProgress color="inherit" size={16} />
    </span>
  );
};

export default Spinner;

import React from 'react';
import withUserContext from '../../contexts/userContext/withUserContext';
import AssetList from '../../components/assets/AssetList';
import { performFileUpload } from '../../services/utilities';
import CreateReferenceMutation from '../../mutations/deliverables/CreateReferenceMutation';
import UpdateReferenceMutation from '../../mutations/deliverables/UpdateReferenceMutation';
import DeleteReferenceMutation from '../../mutations/deliverables/DeleteReferenceMutation';
import { getFileExtension } from '../../utils/helpers';

const DeliverableReferencesContent = props => {
  const {
    deliverable: {
      id: deliverableId,
      references: {
        pageInfo: { hasNextPage, endCursor },
        totalCount,
        edgeCount,
        edges
      }
    },
    refetch,
    ...assetListProps
  } = props;

  const mappedEdges = edges.map(({ node }) => ({
    node: {
      ...node,
      fileName: node.title,
      reviewInterface:
        node.reviewInterface === 'document' && getFileExtension(node.fileUrl) !== 'pdf'
          ? 'generic'
          : node.reviewInterface
    }
  }));

  const handleCreateItem = (values, actions, cb) => {
    actions.setSubmitting(true);

    const onCompleted = () => {
      actions.setSubmitting(false);
      cb();
    };

    performFileUpload(
      'reference',
      'file',
      null,
      values.file,
      file => {
        CreateReferenceMutation(
          {
            deliverable: deliverableId,
            title: values.fileName || file.name,
            description: values.description
          },
          file
        ).then(onCompleted);
      },
      (stagedPath, filename) => {
        CreateReferenceMutation({
          deliverable: deliverableId,
          title: values.fileName || filename,
          description: values.description,
          stagedPath
        }).then(onCompleted);
      }
    );
  };

  const handleEditItem = ({ id, fileName: title, description }, cb) => {
    UpdateReferenceMutation({ id, title, description }).then(cb);
  };

  const handleDeleteItem = (id, cb) => {
    DeleteReferenceMutation({ id }).then(() => {
      cb();
      refetch(prevVars => ({ ...prevVars, after: '' }));
    });
  };

  return (
    <AssetList
      edges={mappedEdges}
      refetch={refetch}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
      {...assetListProps}
      shouldShowUploadButton
      createItemHandler={handleCreateItem}
      editItemHandler={handleEditItem}
      deleteItemHandler={handleDeleteItem}
    />
  );
};

export default withUserContext(DeliverableReferencesContent);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

/**
 * @param {Object} variables - Variables passed to the mutation
 * @param {string} variables.contractorId - ID of the contractor
 * @param {string} variables.fileName - Name of the asset
 * @param {string} variables.description - Portfolio asset description
 * @param {Object} file - Showcase image file
 */

export default (variables, file, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateShowcaseAssetMutation($input: CreateShowcaseAssetInput!) {
        createShowcaseAsset(input: $input) {
          newAsset {
            id
            fileName
            fileUrl
            description
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    uploadables: {
      upload_image: file
    },
    onCompleted: (response, errors) => {
      callback(response.updateContractor, errors);
    }
  });
};

import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';

import { DialogActions, DialogContent, DialogContentText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormikDateField from '../../../shared/components/form/FormikDateField';
import FormikSearchableDropdown from '../../../shared/components/form/FormikSearchableDropdown';
import DeleteBatchDeliverableMutation from '../../mutations/DeleteBatchDeliverableMutation';
import UpdateBatchDeliverableMutation from '../../mutations/UpdateBatchDeliverableMutation';
import BatchMarkDeliverableReadyMutation from './mutations/BatchMarkDeliverableReadyMutation';
import BatchRevertToDraftDeliverableMutation from './mutations/BatchRevertToDraftDeliverableMutation';
import BatchMarkDeliverableCompleteMutation from './mutations/BatchMarkDeliverableCompleteMutation';
import { formatDateForQueries } from '../../../shared/utils/formatters';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import { FormikAmountWithCurrencyField } from '../form/AmountWithCurrencyField';
import { getCurrencyCodeFromUserContext } from '../../../shared/utils/helpers';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '0',
    paddingBottom: theme.spacing(4),
    minWidth: '423px',
    minHeight: props => (props.isAmountField ? '225px' : '150px')
  }
}));

const EditDeliverableForm = props => {
  const {
    handleClose,
    handleCloseWithRefetch,
    editingData: { action, checked },
    options,
    userContext
  } = props;

  const classes = useStyles({ isAmountField: action === options.modifyAmount.name });

  const onSubmitHandler = (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    if (action === options.delete.name) {
      DeleteBatchDeliverableMutation(checked, response => {
        setSubmitting(false);
        if (response && response.isDeleted) {
          handleCloseWithRefetch();
        }
      });
    } else if (action === options.markReady.name) {
      BatchMarkDeliverableReadyMutation(checked)
        .then(response => {
          if (response && response.updatedDeliverables) {
            handleCloseWithRefetch();
          }
        })
        .finally(setSubmitting(false));
    } else if (action === options.markCompleted.name) {
      BatchMarkDeliverableCompleteMutation(checked)
        .then(response => {
          if (response && response.updatedDeliverables) {
            handleCloseWithRefetch();
          }
        })
        .finally(setSubmitting(false));
    } else if (action === options.markDraft.name) {
      BatchRevertToDraftDeliverableMutation(checked)
        .then(response => {
          if (response && response.updatedDeliverables) {
            handleCloseWithRefetch();
          }
        })
        .finally(setSubmitting(false));
    } else {
      let variables = {};
      switch (action) {
        case options.modifyAmount.name:
          variables = {
            ...values.amount
          };
          break;
        case options.assignStaff.name:
          variables.assignedStaff = values.assignedStaff.value;
          break;
        case options.modifyDate.name:
          variables.dueDate = formatDateForQueries(values.dueDate);
          break;
        default:
          break;
      }

      UpdateBatchDeliverableMutation(checked, variables, (response, errors) => {
        setSubmitting(false);
        if (response && response.isUpdated) {
          handleCloseWithRefetch();
        } else if (errors && errors[0].fields && errors[0].fields.amount) {
          setFieldError('amount', errors[0].fields.amount);
        }
      });
    }
  };

  const validate = values => {
    let errors = {};

    if (action === options.modifyAmount.name) {
      if (!values.amount.amount) {
        errors.amount = { amount: 'Required' };
      } else if (Number(values.amount.amount) < 0) {
        errors.amount = { amount: 'The amount must be zero or more.' };
      }
    }
    if (action === options.modifyDate.name && !values.dueDate) {
      errors.dueDate = 'Required';
    }
    if (action === options.assignStaff.name && !values.assignedStaff) {
      errors.assignedStaff = 'Required';
    }
    return errors;
  };

  const renderFormField = () => {
    switch (action) {
      case options.modifyAmount.name:
        return (
          <Field
            name="amount"
            label="Amount"
            component={FormikAmountWithCurrencyField}
            placeholder="Amount"
            fullWidth
            required
            maxMenuHeight={100}
          />
        );
      case options.modifyDate.name:
        return (
          <Field fullWidth required name="dueDate" component={FormikDateField} label="Due date" />
        );
      case options.assignStaff.name:
        return (
          <Field
            required
            name="assignedStaff"
            component={FormikSearchableDropdown}
            placeholder="Select Staff..."
            label="Staff Coordinator"
          />
        );
      default:
        return null;
    }
  };

  const renderInfoText = () => {
    const qDeliverables = checked.length;
    const formatName = qDeliverables === 1 ? 'deliverable' : 'deliverables';
    let actionVerb = '';
    switch (action) {
      case options.delete.name:
        actionVerb = 'delete';
        break;
      case options.markReady.name:
        actionVerb = 'set the assignable state for';
        break;
      case options.markDraft.name:
        actionVerb = 'set the draft state for';
        break;
      case options.markCompleted.name:
        actionVerb = 'set the completed state for';
        break;
      default:
        actionVerb = 'edit';
    }
    return `You are about to ${actionVerb} ${qDeliverables} ${formatName}.`;
  };

  const initialValues = useMemo(
    () => ({
      amount: {
        amount: '',
        currencyCode: getCurrencyCodeFromUserContext(userContext)
      },
      dueDate: null,
      assignedStaff: null
    }),
    []
  );

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmitHandler}>
      {({ isSubmitting, submitForm }) => (
        <Form>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              <Typography variant="body1" component="span">
                {renderInfoText()}
              </Typography>
            </DialogContentText>
            {renderFormField()}
          </DialogContent>
          <DialogActions>
            <GlobalButton
              id="cancelEditDeliverable"
              handleClick={handleClose}
              variant="transparent"
              disabled={isSubmitting}
            >
              Cancel
            </GlobalButton>
            <GlobalButton
              id="submitEditDeliverable"
              // instead of using type="submit" we specify to submit only this form since this could be reused in pages that have
              // parent Formik Form component and would end up submitting all
              handleClick={submitForm}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {action === options.delete.name ? 'Delete' : 'Submit'}
            </GlobalButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(EditDeliverableForm);

import React from 'react';
import styled from 'styled-components';
import Detail from '../../UI/Detail';
import AvatarWithName from '../../UI/AvatarWithName';
import JobCommentsBadge from './JobCommentsBadge';

const Container = styled.div`
  display: flex;

  > * {
    margin-right: 48px;
  }
`;

const JobTitle = styled.div`
  display: flex;
  align-items: center;

  > span:first-child {
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }

  a {
    line-height: 1;
  }
`;

const DeliverableDetailsHeader = ({ deliverable, ...rest }) => {
  const assignee = deliverable.job
    ? deliverable.job.contractor
    : deliverable.assignedInternally.user;

  return (
    <Container {...rest}>
      <Detail
        flexDirection="column"
        alignItems="flex-start"
        name="Assignee"
        value={
          <AvatarWithName
            name={assignee.fullName}
            avatarProps={{
              src: assignee.representativeImageUrl
            }}
            typographyProps={{
              variant: 'body2'
            }}
            size={20}
          />
        }
      />
      {deliverable.job && (
        <Detail
          flexDirection="column"
          alignItems="flex-start"
          name="Job Name"
          value={
            <JobTitle>
              <span>{deliverable.job.name}</span>
              <JobCommentsBadge deliverable={deliverable} />
            </JobTitle>
          }
        />
      )}
    </Container>
  );
};

export default DeliverableDetailsHeader;

/**
 * @flow
 * @relayHash 0e9534352ed3b88656ea6ab4af6ca6a1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountInformationContent_accountInformation$ref = any;
type AccountInformationContent_timezones$ref = any;
export type AccountInformationTabQueryVariables = {||};
export type AccountInformationTabQueryResponse = {|
  +general: ?{|
    +$fragmentRefs: AccountInformationContent_timezones$ref
  |},
  +$fragmentRefs: AccountInformationContent_accountInformation$ref,
|};
export type AccountInformationTabQuery = {|
  variables: AccountInformationTabQueryVariables,
  response: AccountInformationTabQueryResponse,
|};
*/


/*
query AccountInformationTabQuery {
  ...AccountInformationContent_accountInformation
  general {
    ...AccountInformationContent_timezones
  }
}

fragment AccountInformationContent_accountInformation on Query {
  currentUser {
    firstName
    lastName
    fullName
    email
    representativeImageUrl
    freelancer {
      id
      coverImageUrl
      companyName
      nickname
      title
      phone
      timezone
      address1
      address2
      country {
        name
        code
      }
      state
      city
      zipcode
      additionalInfo
      portfolioUrl
    }
    id
  }
}

fragment AccountInformationContent_timezones on General {
  timezoneList
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountInformationTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "general",
        "storageKey": null,
        "args": null,
        "concreteType": "General",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AccountInformationContent_timezones",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "AccountInformationContent_accountInformation",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountInformationTabQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentUser",
        "storageKey": null,
        "args": null,
        "concreteType": "UserNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fullName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "representativeImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "freelancer",
            "storageKey": null,
            "args": null,
            "concreteType": "FreelancerNode",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "coverImageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "companyName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "nickname",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "phone",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "timezone",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "address1",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "address2",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "country",
                "storageKey": null,
                "args": null,
                "concreteType": "Country",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "code",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "state",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "city",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zipcode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "additionalInfo",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "portfolioUrl",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v0/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "general",
        "storageKey": null,
        "args": null,
        "concreteType": "General",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timezoneList",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountInformationTabQuery",
    "id": null,
    "text": "query AccountInformationTabQuery {\n  ...AccountInformationContent_accountInformation\n  general {\n    ...AccountInformationContent_timezones\n  }\n}\n\nfragment AccountInformationContent_accountInformation on Query {\n  currentUser {\n    firstName\n    lastName\n    fullName\n    email\n    representativeImageUrl\n    freelancer {\n      id\n      coverImageUrl\n      companyName\n      nickname\n      title\n      phone\n      timezone\n      address1\n      address2\n      country {\n        name\n        code\n      }\n      state\n      city\n      zipcode\n      additionalInfo\n      portfolioUrl\n    }\n    id\n  }\n}\n\nfragment AccountInformationContent_timezones on General {\n  timezoneList\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'afa142b96e4be4fe1420ef0be9019e8f';
module.exports = node;

/**
 * @flow
 * @relayHash f5a024695a6a2270598e15f278c55fb7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type fetchReviewAssetDownloadUrlQueryVariables = {|
  id: string
|};
export type fetchReviewAssetDownloadUrlQueryResponse = {|
  +reviewAsset: ?{|
    +fileDownloadUrl: ?string
  |}
|};
export type fetchReviewAssetDownloadUrlQuery = {|
  variables: fetchReviewAssetDownloadUrlQueryVariables,
  response: fetchReviewAssetDownloadUrlQueryResponse,
|};
*/


/*
query fetchReviewAssetDownloadUrlQuery(
  $id: ID!
) {
  reviewAsset(id: $id) {
    fileDownloadUrl
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileDownloadUrl",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "fetchReviewAssetDownloadUrlQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReviewAssetNode",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "fetchReviewAssetDownloadUrlQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewAsset",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReviewAssetNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "fetchReviewAssetDownloadUrlQuery",
    "id": null,
    "text": "query fetchReviewAssetDownloadUrlQuery(\n  $id: ID!\n) {\n  reviewAsset(id: $id) {\n    fileDownloadUrl\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'effdcfec9848454d31f0cbe39259f8bc';
module.exports = node;

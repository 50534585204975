import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';
import ContactsList from './contacts/list/ContactsList';

const ContactsListTabRenderer = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ContactsListTabRendererQuery(
          $first: Int
          $orderBy: String
          $after: String
          $staffName: String
          $organization: ID
        ) {
          ...ContactsList_contacts
            @arguments(
              first: $first
              orderBy: $orderBy
              after: $after
              staffName: $staffName
              organization: $organization
            )
        }
      `}
      variables={variables}
      render={relayProps => <ContactsList label="Contacts" contacts={relayProps} {...props} />}
    />
  );
};

export default withTableHistoryProvider(ContactsListTabRenderer, 'freOrganizationsContacts', {
  orderBy: 'first_name',
  initialFilters: {
    staffName: '',
    organization: null
  }
});

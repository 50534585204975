/**
 * @flow
 * @relayHash 54a11a215398cb20d05bfedee214cb55
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CurrencyCodes = "AUD" | "BGN" | "BRL" | "CAD" | "CHF" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "ISK" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "THB" | "TRY" | "USD" | "ZAR" | "%future added value";
export type CreateDeliverableInput = {|
  title: string,
  release: string,
  category: string,
  categoryType?: ?string,
  assignedStaff?: ?string,
  dueDate?: ?any,
  amount?: ?any,
  currencyCode?: ?CurrencyCodes,
  description?: ?string,
  managerNote?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  followerIds?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type CreateDeliverableMutationVariables = {|
  input: CreateDeliverableInput
|};
export type CreateDeliverableMutationResponse = {|
  +createDeliverable: ?{|
    +newDeliverable: ?{|
      +id: string,
      +release: ?{|
        +hasDeliverables: ?boolean,
        +categoryInfo: ?$ReadOnlyArray<?{|
          +category: ?{|
            +id: string,
            +name: string,
          |},
          +contractedAmount: ?any,
          +pendingAmount: ?any,
        |}>,
        +deliverablesTotalAmount: ?any,
        +deliverablesTotalOriginalAmount: ?any,
        +deliverablesTotalPendingAmount: ?any,
      |},
    |}
  |}
|};
export type CreateDeliverableMutation = {|
  variables: CreateDeliverableMutationVariables,
  response: CreateDeliverableMutationResponse,
|};
*/


/*
mutation CreateDeliverableMutation(
  $input: CreateDeliverableInput!
) {
  createDeliverable(input: $input) {
    newDeliverable {
      id
      release {
        hasDeliverables
        categoryInfo {
          category {
            id
            name
          }
          contractedAmount
          pendingAmount
        }
        deliverablesTotalAmount
        deliverablesTotalOriginalAmount
        deliverablesTotalPendingAmount
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasDeliverables",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "categoryInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "ReleaseCategoryInfo",
  "plural": true,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "category",
      "storageKey": null,
      "args": null,
      "concreteType": "CategoryNode",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "contractedAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pendingAmount",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalAmount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalOriginalAmount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliverablesTotalPendingAmount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateDeliverableMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "newDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "release",
                "storageKey": null,
                "args": null,
                "concreteType": "ReleaseNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateDeliverableMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createDeliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeliverablePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "newDeliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "release",
                "storageKey": null,
                "args": null,
                "concreteType": "ReleaseNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateDeliverableMutation",
    "id": null,
    "text": "mutation CreateDeliverableMutation(\n  $input: CreateDeliverableInput!\n) {\n  createDeliverable(input: $input) {\n    newDeliverable {\n      id\n      release {\n        hasDeliverables\n        categoryInfo {\n          category {\n            id\n            name\n          }\n          contractedAmount\n          pendingAmount\n        }\n        deliverablesTotalAmount\n        deliverablesTotalOriginalAmount\n        deliverablesTotalPendingAmount\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a802e9511b9b2ec34985b1e9e247d94f';
module.exports = node;

import { makeStyles } from '@material-ui/styles';
import colors from '../../../shared/styles/common/colors';

export default makeStyles(theme => ({
  container: {
    display: 'flex'
  },

  totalValuesPosition: {
    top: '10px',
    left: '160px',
    position: 'absolute'
  },
  relative: {
    position: 'relative'
  },
  lightGray: {
    color: '#9FA2B5',
    fontWeight: 'bold'
  },
  darkGray: {
    color: '#989AAB',
    fontWeight: 'bold'
  },
  red: {
    color: colors.red,
    fontWeight: 'bold'
  },
  green: {
    color: colors.green,
    fontWeight: 'bold'
  },
  marginRight: {
    marginRight: '30px'
  }
}));

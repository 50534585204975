import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import Budget from './Budget';
import CreateBudget from './CreateBudget';

const ReleaseBudgetTab = props => {
  const { id } = useParams();
  return (
    <QueryRenderer
      query={graphql`
        query ReleaseBudgetTabQuery($id: ID!) {
          release(id: $id) {
            budgetInfo {
              budgetAmount
            }
            ...Budget_release
            ...CreateBudget_release
          }
        }
      `}
      variables={{ id }}
      render={relayProps => {
        const { release } = relayProps;
        return release.budgetInfo ? (
          <Budget release={release} {...props} />
        ) : (
          <CreateBudget release={release} />
        );
      }}
    />
  );
};

export default ReleaseBudgetTab;

import React, { useState } from 'react';
import { fetchQuery } from 'react-relay';
import { Formik, Form, Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import graphql from 'babel-plugin-relay/macro';

import FormikTextField from '../../../../shared/components/form/FormikTextField';
import useDialogStyles from '../../../../shared/styles/common/useDialogStyles';
import { successToast, errorToast } from '../../../../shared/toasts';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import CreateStaffUserMutation from './mutations/CreateStaffUserMutation';
import FormikRolesSelect from './roleTypes/FormikRolesSelect';
import { useEffectWithStatus } from '../../../../shared/hooks/useEffectWithStatus';
import { getEnvironment } from '../../../../shared/utils/helpers';
import ActivateStaffUserMutation from './mutations/ActivateStaffUserMutation';
import { BillingModalContent, UpgradeTierModalContent } from '../billing/SubscriptionModals';

const FORM_VERSIONS = {
  needUpgrade: { name: 'needUpgrade' },
  purchase: { name: 'purchase' },
  addUser: { name: 'addUser' },
  error: { name: 'error' }
};
const AddStaffUserForm = ({ handleClose, toRefetchData, activateUserId }) => {
  // to be used when creating new user or activating inactive one

  const classes = useDialogStyles();
  const [formVersion, setFormVersion] = useState(undefined);

  useEffectWithStatus(status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query AddStaffUserFormQuery {
          organization {
            subscription {
              nextBillingDate
            }
          }
          addStaffUserCheck {
            invoicePreview {
              total
              timestamp
              invoiceLines {
                itemName
                quantity
                cost
              }
            }
            subscriptionInfo {
              tier
              interval
              modules
              activeUsers
              totalUsers
            }

            needUpgrade
            isSeatAvailable
            error
          }
        }
      `,
      {},
      { force: true }
    ).then(response => {
      if (status.mounted && response && response.addStaffUserCheck) {
        const {
          error,
          isSeatAvailable,
          needUpgrade,
          invoicePreview,
          subscriptionInfo
        } = response.addStaffUserCheck;
        if (error) {
          setFormVersion({ ...FORM_VERSIONS.error, message: error });
        } else if (isSeatAvailable) {
          if (activateUserId) {
            activateUserHandler();
          } else {
            setFormVersion(FORM_VERSIONS.addUser);
          }
        } else if (needUpgrade) {
          setFormVersion(FORM_VERSIONS.needUpgrade);
        } else {
          setFormVersion({
            ...FORM_VERSIONS.purchase,
            invoicePreview,
            subscriptionChanges: {
              ...subscriptionInfo,
              nextBillingDate: response.organization.subscription.nextBillingDate
            },
            currentSubscription: {
              ...subscriptionInfo,
              nextBillingDate: response.organization.subscription.nextBillingDate,
              totalUsers: subscriptionInfo.totalUsers - 1
            }
          });
        }
      }
    });
  }, []);

  const onSubmit = (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    const { firstName, lastName, role, title, email } = values;
    CreateStaffUserMutation(
      {
        firstName,
        lastName,
        title,
        email,
        role,
        ...(formVersion.timestamp ? { prorationTimestamp: formVersion.timestamp } : {})
      },
      (response, errors) => {
        setSubmitting(false);
        if (errors && errors[0].fields && errors[0].fields.email) {
          setFieldError('email', errors[0].fields.email);
        }
        if (response && response.emailFailed) {
          errorToast('New staff user was created but their verification email failed.');
        }
        if (response) {
          handleClose();
          setTimeout(() => {
            successToast('New staff user has been created.', {
              text: 'Go to their profile.',
              link: `/admin/staff-management/users/${response.staffUser.id}`
            });
          }, 250);
          toRefetchData();
        }
      }
    );
  };

  const activateUserHandler = (setSubmitting = () => {}) => {
    setSubmitting(true);
    ActivateStaffUserMutation(
      activateUserId,
      formVersion && formVersion.invoicePreview ? formVersion.invoicePreview.timestamp : null,
      () => {
        setSubmitting(false);
        handleClose();
      }
    );
  };

  const staffValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.')
      .required('Required'),
    lastName: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.')
      .required('Required'),
    title: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.'),
    email: Yup.string().email('Invalid email').required('Required'),
    role: Yup.string().required('Required')
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    role: '',
    title: '',
    email: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={staffValidationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, setSubmitting }) => (
        <Form>
          {!formVersion && <Typography>Loading...</Typography>}
          {formVersion && formVersion.name === FORM_VERSIONS.purchase.name && (
            <BillingModalContent
              handleClose={handleClose}
              handleSubmit={() =>
                activateUserId
                  ? activateUserHandler(setSubmitting)
                  : setFormVersion({
                      ...FORM_VERSIONS.addUser,
                      timestamp: formVersion.invoicePreview.timestamp
                    })
              }
              isSubmitting={isSubmitting}
              {...formVersion}
            />
          )}
          {formVersion && formVersion.name === FORM_VERSIONS.needUpgrade.name && (
            <UpgradeTierModalContent handleClose={handleClose} />
          )}
          {formVersion && formVersion.name === FORM_VERSIONS.addUser.name && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    required
                    component={FormikTextField}
                    name="firstName"
                    label="First Name"
                    placeholder="First name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    required
                    name="lastName"
                    component={FormikTextField}
                    label="Last Name"
                    placeholder="Last name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    required
                    name="title"
                    component={FormikTextField}
                    label="Title"
                    placeholder="Title"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    required
                    name="email"
                    component={FormikTextField}
                    label="Email"
                    placeholder="Email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field required name="role" component={FormikRolesSelect} label="Role" />
                </Grid>
              </Grid>
              <div className={classes.dialogActions}>
                <GlobalButton
                  id="cancelCreateStaffUserForm"
                  variant="transparent"
                  handleClick={handleClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </GlobalButton>
                <GlobalButton
                  id="createStaffUser"
                  handleClick={submitForm}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Send Invitation
                </GlobalButton>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddStaffUserForm;

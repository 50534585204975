import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../utils/helpers';

/**
 *
 * @param {Object} variables - Variables passed to the mutation
 * @param {string} variables.assetId - ID of the review asset
 * @param {string} variables.extraData - Extra data attached to the comment
 * @param {string} variables.content - Content of the comment
 * @param {string} variables.clientMutationId - clientMutationId
 * @param {*} callback - Callback that receives 'response' and 'errors' as arguments
 */

const CreateReviewAssetCommentMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation CreateReviewAssetCommentMutation($input: CreateReviewAssetCommentInput!) {
        createReviewAssetComment(input: $input) {
          newComment {
            id
          }
          reviewAsset {
            reviewAssetAnnotation {
              id
              annotationData
            }
            comments {
              edges {
                node {
                  id
                  content
                  contentEditedAt
                  created
                  objectId
                  extraData
                  repliedTo {
                    content
                    created
                    user {
                      fullName
                    }
                    referenceFiles {
                      id
                      title
                      description
                      fileUrl
                      reviewInterface
                    }
                  }
                  user {
                    id
                    fullName
                    representativeImageUrl
                  }
                  referenceFiles {
                    id
                    title
                    description
                    fileUrl
                    reviewInterface
                  }
                  reactions {
                    id
                    definitionId
                    name
                    color
                    number
                    users {
                      id
                      fullName
                      representativeImageUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.createReviewAssetComment, errors);
    }
  });
};

CreateReviewAssetCommentMutation.propTypes = {
  variables: PropTypes.shape({
    assetId: PropTypes.string.isRequired,
    content: PropTypes.string,
    extraData: PropTypes.string,
    clientMutationId: PropTypes.string
  })
};

export default CreateReviewAssetCommentMutation;

import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { formatDateDisplay } from '../../utils/formatters';
import withUserContext from '../../contexts/userContext/withUserContext';
import { getDateDisplayFormatFromUserContext } from '../../utils/helpers';

const StyledTypography = styled(({ dark, ...rest }) => <Typography {...rest} />)`
  font-size: 10px;
  color: ${props =>
    props.dark ? props.theme.palette.primary.dark : props.theme.palette.colors.blueGrey};
`;

const DateBadge = props => {
  const { date, userContext, dark, ...rest } = props;
  return (
    <StyledTypography variant="body2" component="span" dark={dark} {...rest}>
      {formatDateDisplay(date, getDateDisplayFormatFromUserContext(userContext))}
    </StyledTypography>
  );
};

export default withUserContext(DateBadge);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../utils/helpers';

const mutation = graphql`
  mutation UpdateSolicitationCommentMutation($input: UpdateSolicitationCommentInput!) {
    updateSolicitationComment(input: $input) {
      isUpdated
      solicitation {
        state
        comments {
          edges {
            node {
              id
              content
              created
              contentEditedAt
              repliedTo {
                user {
                  fullName
                }
                content
                created
              }
              contractor {
                fullName
                representativeImageUrl
                freelancer {
                  user {
                    id
                  }
                }
              }
              createdByStaff {
                fullName
                representativeImageUrl
                user {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

const UpdateSolicitationCommentMutation = (input, callback) => {
  const config = {
    mutation,
    variables: { input },
    onCompleted: response => {
      callback(response.updateSolicitationComment);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default UpdateSolicitationCommentMutation;

UpdateSolicitationCommentMutation.propTypes = {
  input: PropTypes.objectOf(
    PropTypes.shape({
      commentId: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  )
};

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import BatchArchiveDeliverableFinalFilesMutation from '../../../deliverables/mutations/BatchArchiveDeliverableFinalFilesMutation';
import BatchRestoreDeliverableFinalFilesMutation from '../../../deliverables/mutations/BatchRestoreDeliverableFinalFilesMutation';
import BatchDeleteDeliverableFinalFileMutation from '../../../../../shared/mutations/deliverables/BatchDeleteDeliverableFinalFileMutation';
import DownloadBatchDeliverableFinalFilesMutation from '../../../deliverables/mutations/DownloadBatchDeliverableFinalFilesMutation';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import StyledFilesList from '../shared/StyledFilesList';

const ACTIONS_ON_CONFIRM = {
  archive: {
    onConfirm: BatchArchiveDeliverableFinalFilesMutation
  },
  delete: {
    onConfirm: BatchDeleteDeliverableFinalFileMutation
  },
  restore: {
    onConfirm: BatchRestoreDeliverableFinalFilesMutation
  },
  download: {
    onConfirm: DownloadBatchDeliverableFinalFilesMutation
  }
};

const DeliverableFilesList = props => {
  const {
    files: { finalFilesByOrg },
    storageClasses,
    deliverableStateTypes,
    relay,
    userContext: {
      orgStaff: { allowedActions }
    }
  } = props;

  const canDelete = () =>
    computePermissionRole(ALLOWED_ACTIONS.DELIVERABLE_DELETE_FINAL_FILE, allowedActions);

  const canDownload = computePermissionRole(
    ALLOWED_ACTIONS.DELIVERABLE_DOWNLOAD_FINAL_FILE,
    allowedActions
  );

  return (
    <StyledFilesList
      files={finalFilesByOrg}
      storageClasses={storageClasses}
      deliverableStateTypes={deliverableStateTypes}
      relay={relay}
      canDelete={canDelete}
      canDownload={canDownload}
      onActionConfirm={ACTIONS_ON_CONFIRM}
    />
  );
};

export default createRefetchContainer(
  withUserContext(DeliverableFilesList),
  {
    files: graphql`
      fragment DeliverableFilesList_files on Query {
        finalFilesByOrg(
          first: $first
          after: $after
          orderBy: $orderBy
          storageClass: $storageClass
          deliverableState: $deliverableState
          releaseId: $releaseId
          deliverableTitle: $deliverableTitle
          fileName: $fileName
        ) {
          edges {
            node {
              id
              fileName
              fileSize
              fileUrl
              storageClass
              deliverable {
                title
                state
                release {
                  name
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
        }
      }
    `,
    storageClasses: graphql`
      fragment DeliverableFilesList_storageClasses on __Type {
        enumValues {
          name
          description
        }
      }
    `,
    deliverableStateTypes: graphql`
      fragment DeliverableFilesList_deliverableStateTypes on __Type {
        enumValues {
          name
          description
        }
      }
    `
  },
  graphql`
    query DeliverableFilesListRefetchQuery(
      $first: Int
      $after: String
      $orderBy: String
      $storageClass: String
      $deliverableState: String
      $releaseId: String
      $deliverableTitle: String
      $fileName: String
    ) {
      ...DeliverableFilesList_files
    }
  `
);

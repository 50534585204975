/**
 * @flow
 * @relayHash 847b3ba193951f4a3bc1bff2b5a5a077
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getUserDataQueryVariables = {||};
export type getUserDataQueryResponse = {|
  +currentUser: ?{|
    +id: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +message: ?string,
    +representativeImageUrl: ?string,
    +orgStaff: ?{|
      +role: ?{|
        +id: string,
        +name: string,
      |},
      +isOwner: boolean,
      +allowedActions: ?$ReadOnlyArray<?string>,
      +id: string,
      +showFreelancerFieldsPopup: ?boolean,
      +organization: {|
        +id: string,
        +configuration: ?any,
        +currencyCode: string,
        +name: string,
        +tierLabel: ?string,
      |},
    |},
  |}
|};
export type getUserDataQuery = {|
  variables: getUserDataQueryVariables,
  response: getUserDataQueryResponse,
|};
*/


/*
query getUserDataQuery {
  currentUser {
    id
    firstName
    lastName
    email
    message
    representativeImageUrl
    orgStaff {
      role {
        id
        name
      }
      isOwner
      allowedActions
      id
      showFreelancerFieldsPopup
      organization {
        id
        configuration
        currencyCode
        name
        tierLabel
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "currentUser",
    "storageKey": null,
    "args": null,
    "concreteType": "UserNode",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "representativeImageUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "orgStaff",
        "storageKey": null,
        "args": null,
        "concreteType": "StaffNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "role",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationRoleNode",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isOwner",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "allowedActions",
            "args": null,
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "showFreelancerFieldsPopup",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "configuration",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currencyCode",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tierLabel",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getUserDataQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getUserDataQuery",
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getUserDataQuery",
    "id": null,
    "text": "query getUserDataQuery {\n  currentUser {\n    id\n    firstName\n    lastName\n    email\n    message\n    representativeImageUrl\n    orgStaff {\n      role {\n        id\n        name\n      }\n      isOwner\n      allowedActions\n      id\n      showFreelancerFieldsPopup\n      organization {\n        id\n        configuration\n        currencyCode\n        name\n        tierLabel\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6352f94c616d4542bdd64f5d266cfa9d';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SelectExistingTab_deliverable$ref: FragmentReference;
declare export opaque type SelectExistingTab_deliverable$fragmentType: SelectExistingTab_deliverable$ref;
export type SelectExistingTab_deliverable = {|
  +id: string,
  +references: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +fileUrl: ?string,
        +reviewInterface: ?string,
      |}
    |}>
  |},
  +$refType: SelectExistingTab_deliverable$ref,
|};
export type SelectExistingTab_deliverable$data = SelectExistingTab_deliverable;
export type SelectExistingTab_deliverable$key = {
  +$data?: SelectExistingTab_deliverable$data,
  +$fragmentRefs: SelectExistingTab_deliverable$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SelectExistingTab_deliverable",
  "type": "DeliverableNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "String"
    }
  ],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "references",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "ReferenceNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ReferenceNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ReferenceNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fileUrl",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reviewInterface",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd3ca30cb1702d4a9c15dd47f093799d3';
module.exports = node;

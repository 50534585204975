/**
 * @flow
 * @relayHash 6daf74444408fc9150331c317d81dee1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ReleaseDeliverablesList_release$ref = any;
export type ReleaseDeliverablesListRefetchQueryVariables = {|
  id: string,
  first?: ?number,
  after?: ?string,
  orderBy?: ?string,
  dueDateFrom?: ?any,
  dueDateTo?: ?any,
  title?: ?string,
  includeInactive?: ?boolean,
  state?: ?string,
  category?: ?string,
  tags?: ?string,
  staffCoordinator?: ?string,
  assignedToStaffOrContractor?: ?string,
  includeClosed?: ?boolean,
  includeCanceled?: ?boolean,
|};
export type ReleaseDeliverablesListRefetchQueryResponse = {|
  +release: ?{|
    +$fragmentRefs: ReleaseDeliverablesList_release$ref
  |}
|};
export type ReleaseDeliverablesListRefetchQuery = {|
  variables: ReleaseDeliverablesListRefetchQueryVariables,
  response: ReleaseDeliverablesListRefetchQueryResponse,
|};
*/


/*
query ReleaseDeliverablesListRefetchQuery(
  $id: ID!
  $first: Int
  $after: String
  $orderBy: String
  $dueDateFrom: Date
  $dueDateTo: Date
  $title: String
  $includeInactive: Boolean
  $state: String
  $category: ID
  $tags: String
  $staffCoordinator: String
  $assignedToStaffOrContractor: String
  $includeClosed: Boolean
  $includeCanceled: Boolean
) {
  release(id: $id) {
    ...ReleaseDeliverablesList_release
    id
  }
}

fragment ReleaseDeliverablesList_release on ReleaseNode {
  id
  deliverables(first: $first, orderBy: $orderBy, after: $after, dueDateFrom: $dueDateFrom, dueDateTo: $dueDateTo, title: $title, state: $state, includeInactive: $includeInactive, category: $category, tags: $tags, includeClosed: $includeClosed, includeCanceled: $includeCanceled, staffCoordinator: $staffCoordinator, assignedToStaffOrContractor: $assignedToStaffOrContractor) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edgeCount
    edges {
      cursor
      node {
        id
        jobId
        job {
          id
          name
        }
        title
        categoryType {
          name
          id
        }
        category {
          name
          id
        }
        state
        reviewStep
        amount
        currencyCode
        dueDate
        assignedContractor {
          id
          representativeImageUrl
          fullName
        }
        assignedInternally {
          id
          representativeImageUrl
          fullName
        }
        assignedStaff {
          user {
            id
          }
          fullName
          representativeImageUrl
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dueDateFrom",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dueDateTo",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeInactive",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "state",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tags",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "staffCoordinator",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "assignedToStaffOrContractor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeClosed",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeCanceled",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReleaseDeliverablesListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "release",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ReleaseDeliverablesList_release",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReleaseDeliverablesListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "release",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "assignedToStaffOrContractor",
                "variableName": "assignedToStaffOrContractor"
              },
              {
                "kind": "Variable",
                "name": "category",
                "variableName": "category"
              },
              {
                "kind": "Variable",
                "name": "dueDateFrom",
                "variableName": "dueDateFrom"
              },
              {
                "kind": "Variable",
                "name": "dueDateTo",
                "variableName": "dueDateTo"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "includeCanceled",
                "variableName": "includeCanceled"
              },
              {
                "kind": "Variable",
                "name": "includeClosed",
                "variableName": "includeClosed"
              },
              {
                "kind": "Variable",
                "name": "includeInactive",
                "variableName": "includeInactive"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              },
              {
                "kind": "Variable",
                "name": "staffCoordinator",
                "variableName": "staffCoordinator"
              },
              {
                "kind": "Variable",
                "name": "state",
                "variableName": "state"
              },
              {
                "kind": "Variable",
                "name": "tags",
                "variableName": "tags"
              },
              {
                "kind": "Variable",
                "name": "title",
                "variableName": "title"
              }
            ],
            "concreteType": "DeliverableNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "jobId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "job",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "categoryType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryTypeNode",
                        "plural": false,
                        "selections": (v4/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryNode",
                        "plural": false,
                        "selections": (v4/*: any*/)
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reviewStep",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currencyCode",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dueDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignedContractor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNode",
                        "plural": false,
                        "selections": (v7/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignedInternally",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": (v7/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignedStaff",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ]
                          },
                          (v6/*: any*/),
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ReleaseDeliverablesListRefetchQuery",
    "id": null,
    "text": "query ReleaseDeliverablesListRefetchQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n  $orderBy: String\n  $dueDateFrom: Date\n  $dueDateTo: Date\n  $title: String\n  $includeInactive: Boolean\n  $state: String\n  $category: ID\n  $tags: String\n  $staffCoordinator: String\n  $assignedToStaffOrContractor: String\n  $includeClosed: Boolean\n  $includeCanceled: Boolean\n) {\n  release(id: $id) {\n    ...ReleaseDeliverablesList_release\n    id\n  }\n}\n\nfragment ReleaseDeliverablesList_release on ReleaseNode {\n  id\n  deliverables(first: $first, orderBy: $orderBy, after: $after, dueDateFrom: $dueDateFrom, dueDateTo: $dueDateTo, title: $title, state: $state, includeInactive: $includeInactive, category: $category, tags: $tags, includeClosed: $includeClosed, includeCanceled: $includeCanceled, staffCoordinator: $staffCoordinator, assignedToStaffOrContractor: $assignedToStaffOrContractor) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      cursor\n      node {\n        id\n        jobId\n        job {\n          id\n          name\n        }\n        title\n        categoryType {\n          name\n          id\n        }\n        category {\n          name\n          id\n        }\n        state\n        reviewStep\n        amount\n        currencyCode\n        dueDate\n        assignedContractor {\n          id\n          representativeImageUrl\n          fullName\n        }\n        assignedInternally {\n          id\n          representativeImageUrl\n          fullName\n        }\n        assignedStaff {\n          user {\n            id\n          }\n          fullName\n          representativeImageUrl\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d9a1f86b02cd061756158e80c2b56bc';
module.exports = node;

import React, { useContext } from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import QuillContext from '../../QuillContext';
import { Button } from './styled';

const AttachReferenceFileButton = ({ disabled }) => {
  const { quillRef, onReferenceFileSelect } = useContext(QuillContext);

  const handleInsert = e => {
    e.preventDefault();
    const range = quillRef.current.getSelection();

    onReferenceFileSelect({
      confirmSelect: referenceFile => {
        quillRef.current.focus();
        quillRef.current.insertEmbed(range.index, 'referenceFile', referenceFile);
        quillRef.current.setSelection(range.index + 1);
      }
    });
  };

  return <Button icon={AttachFileIcon} onMouseDown={handleInsert} disabled={disabled} />;
};

export default AttachReferenceFileButton;

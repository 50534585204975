import { fabric } from 'fabric';
import { ATTRS, TOOLS, OPTIONS } from './contants';
import { resetTool } from './commons';
import {hideControls} from '../CanvasData';

const toolCircle = (canvas, { annotationColor, userId, annotationRef, frameRef }) => {
  resetTool(canvas, TOOLS.circle.name);
  let circle;
  let startingPoint;
  let isMouseMoving = false;

  const mouseDownHandler = event => {
    startingPoint = event.absolutePointer;

    circle = new fabric.Circle({
      left: startingPoint.x,
      top: startingPoint.y,
      originX: 'center',
      originY: 'center',
      radius: 0,
      fill: 'transparent',
      selectable: false,
      stroke: annotationColor,
      strokeWidth: OPTIONS.strokeWidth,
      [ATTRS.originalColor]: annotationColor,
      [ATTRS.type]: TOOLS.circle.name,
      [ATTRS.authorId]: userId,
      [ATTRS.ref]: annotationRef,
      [ATTRS.frameRef]: frameRef
    });
    new fabric.Shadow({
      color: 'black',
      blur: 5
    });
    canvas.add(circle);
    isMouseMoving = true;
  };

  const mouseMoveHandler = event => {
    if (isMouseMoving) {
      const deltaX = Math.abs(event.absolutePointer.x - startingPoint.x);
      const deltaY = Math.abs(event.absolutePointer.y - startingPoint.y);
      const radius = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
      circle.set({ radius });
      canvas.renderAll();
    }
  };

  const mouseUpHandler = () => {
    circle.setCoords();
    isMouseMoving = false;
    hideControls(circle, ['mt', 'ml', 'mr', 'mb', 'mtr']);
  };

  canvas.on({
    'mouse:down': mouseDownHandler,
    'mouse:move': mouseMoveHandler,
    'mouse:up': mouseUpHandler
  });
};

export { toolCircle };

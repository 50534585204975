/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeliverableList_deliverables$ref: FragmentReference;
declare export opaque type DeliverableList_deliverables$fragmentType: DeliverableList_deliverables$ref;
export type DeliverableList_deliverables = {|
  +allDeliverables: ?{|
    +edges: $ReadOnlyArray<?{|
      +cursor: string,
      +node: ?{|
        +id: string,
        +deliverableId: ?number,
        +title: string,
        +state: DeliverableState,
        +job: ?{|
          +id: string,
          +contract: ?{|
            +id: string,
            +contractFileUrl: ?string,
            +job: {|
              +status: JobStatus
            |},
          |},
          +jobDeadline: ?any,
          +name: string,
        |},
        +reviewStep: ?string,
        +assignedStaff: ?{|
          +fullName: ?string,
          +representativeImageUrl: ?string,
          +organization: {|
            +name: string,
            +representativeImageUrl: ?string,
          |},
        |},
      |},
    |}>,
    +totalCount: ?number,
    +edgeCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
      +startCursor: ?string,
      +endCursor: ?string,
    |},
  |},
  +$refType: DeliverableList_deliverables$ref,
|};
export type DeliverableList_deliverables$data = DeliverableList_deliverables;
export type DeliverableList_deliverables$key = {
  +$data?: DeliverableList_deliverables$data,
  +$fragmentRefs: DeliverableList_deliverables$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DeliverableList_deliverables",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "after",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "title",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "state",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "assignedStaff",
      "type": "ID"
    },
    {
      "kind": "RootArgument",
      "name": "jobDeadlineFrom",
      "type": "Date"
    },
    {
      "kind": "RootArgument",
      "name": "jobDeadlineTo",
      "type": "Date"
    },
    {
      "kind": "RootArgument",
      "name": "organization",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "includeCompleted",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeClosed",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCanceled",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "allDeliverables",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "assignedStaff",
          "variableName": "assignedStaff"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "includeCanceled",
          "variableName": "includeCanceled"
        },
        {
          "kind": "Variable",
          "name": "includeClosed",
          "variableName": "includeClosed"
        },
        {
          "kind": "Variable",
          "name": "includeCompleted",
          "variableName": "includeCompleted"
        },
        {
          "kind": "Variable",
          "name": "jobDeadlineFrom",
          "variableName": "jobDeadlineFrom"
        },
        {
          "kind": "Variable",
          "name": "jobDeadlineTo",
          "variableName": "jobDeadlineTo"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Variable",
          "name": "state",
          "variableName": "state"
        },
        {
          "kind": "Variable",
          "name": "title",
          "variableName": "title"
        }
      ],
      "concreteType": "DeliverableNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "DeliverableNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "deliverableId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "state",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "job",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "JobNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "contract",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ContractNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "contractFileUrl",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "job",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "JobNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "status",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "jobDeadline",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reviewStep",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "assignedStaff",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StaffNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "organization",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "OrgNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "edgeCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasPreviousPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '55501b52c67db660763ee577918db4e6';
module.exports = node;

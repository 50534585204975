import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import FilterDebounceInput from '../../../shared/components/filters/FilterDebounceInput';
import FilterSelect from '../../../shared/components/filters/FilterSelect';
import SearchableContactDropdown from '../shared/SearchableContactDropdown';
import SearchableOrganizationDropdown from '../shared/SearchableOrganizationDropdown';
import DateField from '../../../shared/components/form/fields/DateField';
import { useFilterStyles } from '../../../shared/styles/common/filterStyles';
import { DELIVERABLE_STATES } from '../../../shared/constants';
import FilterCheckbox from '../../../shared/components/filters/FilterCheckbox';

const DeliverablesListFilters = props => {
  const { filterValues, onFilterChange, stateOptions, staffLabel } = props;
  const { includeClosed, includeCanceled, includeCompleted, state } = filterValues;
  const classes = useFilterStyles();

  const [mappedStateOptions, setMappedStateOptions] = useState([]);

  const mapStateOptions = options =>
    options.map(option => ({
      value: option.name,
      name: option.description
    }));

  useEffect(() => {
    if (
      (state === DELIVERABLE_STATES.completed && !includeCompleted) ||
      (state === DELIVERABLE_STATES.closed && !includeClosed) ||
      (state === DELIVERABLE_STATES.canceled && !includeCanceled)
    ) {
      onFilterChange('state', '');
    }

    const newStateOptions = stateOptions.filter(option => {
      if (option.name === DELIVERABLE_STATES.closed) {
        return includeClosed;
      }
      if (option.name === DELIVERABLE_STATES.canceled) {
        return includeCanceled;
      }
      if (option.name === DELIVERABLE_STATES.completed) {
        return includeCompleted;
      }

      return (
        option.name !== DELIVERABLE_STATES.draft &&
        option.name !== DELIVERABLE_STATES.ready &&
        option.name !== DELIVERABLE_STATES.locked &&
        option.name !== DELIVERABLE_STATES.inactive
      );
    });
    setMappedStateOptions(mapStateOptions(newStateOptions));
  }, [includeCanceled, includeClosed, includeCompleted, state, stateOptions, onFilterChange]);

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }} alignItems="center">
      <Grid item xs={2}>
        <FilterDebounceInput
          name="title"
          value={filterValues['title']}
          label="Deliverable Name"
          onChange={onFilterChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          id="filterByDeliverableState"
          name="state"
          onChange={onFilterChange}
          value={filterValues['state']}
          label="State"
          options={mappedStateOptions}
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableContactDropdown
          id="filterByStaffInDeliverables"
          name="assignedStaff"
          value={filterValues['assignedStaff']}
          onChange={value => onFilterChange('assignedStaff', value)}
          label={staffLabel}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={3}>
          <Grid item xs={6} classes={{ item: classes.dueDateFrom }}>
            <DateField
              id="due_date_from"
              name="jobDeadlineFrom"
              label="Job Deadline From:"
              value={filterValues['jobDeadlineFrom']}
              placeholder="From..."
              onChange={onFilterChange}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              id="due-date-to"
              name="jobDeadlineTo"
              label="To:"
              value={filterValues['jobDeadlineTo']}
              placeholder="To..."
              onChange={onFilterChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <SearchableOrganizationDropdown
          id="filterByOrganizationInDeliverables"
          name="organization"
          value={filterValues['organization']}
          onChange={value => onFilterChange('organization', value)}
        />
      </Grid>
      <Grid item xs={3}>
        <FilterCheckbox
          id="filterIncludeCompleted"
          name="includeCompleted"
          onChange={onFilterChange}
          value={filterValues['includeCompleted']}
          label="Include Completed Deliverables"
        />
      </Grid>
      <Grid item xs={2}>
        <FilterCheckbox
          id="filterincludeClosed"
          name="includeClosed"
          onChange={onFilterChange}
          value={filterValues['includeClosed']}
          label="Include Closed Deliverables"
        />
      </Grid>
      <Grid item xs={2}>
        <FilterCheckbox
          id="filterIncludeCanceled"
          name="includeCanceled"
          onChange={onFilterChange}
          value={filterValues['includeCanceled']}
          label="Include Canceled Deliverables"
        />
      </Grid>
    </Grid>
  );
};

export default DeliverablesListFilters;

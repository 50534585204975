import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import FullScreenModal from '../../../../../shared/components/fullscreenModal/FullscreenModal';
import ContractPreview from '../../../../../shared/components/job/ContractPreview';
import NoContract from './JobContractTabNoContract';
import { JOB_STATUSES } from '../../../../../shared/constants';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';

const JobContractTabTopSection = props => {
  const classes = useDetailsStyles();
  const {
    job: { jobId, status, contract },
    handleSendReminder,
    isSubmitting
  } = props;
  const [showContract, setShowContract] = React.useState(false);

  const pendingContract = contract
    ? !contract.signedPdfByContractorUrl && contract.signedPdfByOrganizationUrl
    : false;
  const signedContract = contract ? contract.signedPdfByContractorUrl : false;
  const finalizedContract = contract ? contract.finalizedPdfUrl : false;
  const contractPreviewPdf = finalizedContract || signedContract || pendingContract;

  const toggleShowContract = () => {
    setShowContract(!showContract);
  };

  const handleDownloadContract = () => {
    window.open(contractPreviewPdf);
  };

  return (
    <Fragment>
      <Typography variant="h3">Contract Details</Typography>
      {contract ? (
        <div className={classes.contractGridContainer}>
          <div className={classes.contractFirst}>
            <div className={classes.containerColumn}>
              <Fragment>
                <DetailsRow title="Contract ID" content={contract.contractId} titleWidth={160} />
                <DetailsRow title="Job ID" content={jobId} titleWidth={160} />
                <DetailsRow
                  title="Contract Template Used"
                  content={contract.template.name}
                  titleWidth={160}
                />
              </Fragment>
            </div>
          </div>
          <div className={classes.contractSecond}>
            <div className={classes.containerColumn}>
              <Fragment>
                <DetailsRowDate
                  title="Date Signed & Sent by Org"
                  date={contract.dateSignedByOrganization}
                  titleWidth={160}
                />
                <DetailsRowDate
                  title="Date Signed by Contractor"
                  date={contract.dateSignedByContractor}
                  titleWidth={160}
                />
                <div className={classes.buttonRow}>
                  {pendingContract || signedContract ? (
                    <Fragment>
                      <GlobalButton
                        id="viewPendingContractJobContract"
                        variant="secondary"
                        handleClick={toggleShowContract}
                      >
                        {`View ${pendingContract ? 'pending' : 'signed'} contract`}
                      </GlobalButton>
                    </Fragment>
                  ) : null}

                  {status === JOB_STATUSES.awaiting_signature ? (
                    <GlobalButton
                      id="sendReminderJobContract"
                      handleClick={handleSendReminder}
                      disabled={isSubmitting}
                    >
                      Send reminder to contractor
                    </GlobalButton>
                  ) : null}
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.marginTop}>
          <NoContract />
        </div>
      )}

      <div className={classes.borderMarginTop}></div>
      <Typography variant="h3">Associated Files</Typography>
      <div className={classes.marginTop}></div>
      {contractPreviewPdf && (
        <FullScreenModal
          open={showContract}
          handleBack={toggleShowContract}
          actions={[
            {
              label: 'Download PDF',
              handler: handleDownloadContract
            }
          ]}
        >
          <ContractPreview contractPreviewPdf={contractPreviewPdf} />
        </FullScreenModal>
      )}
    </Fragment>
  );
};

export default JobContractTabTopSection;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CreatePaymentRequestDialog_job$ref = any;
export type JobInvoiceStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type InvoiceQuickViewContent_invoice$ref: FragmentReference;
declare export opaque type InvoiceQuickViewContent_invoice$fragmentType: InvoiceQuickViewContent_invoice$ref;
export type InvoiceQuickViewContent_invoice = {|
  +id: string,
  +identifier: string,
  +status: JobInvoiceStatus,
  +created: any,
  +amount: number,
  +message: ?string,
  +invoiceFileUrl: ?string,
  +verificationDate: ?any,
  +verificationMessage: ?string,
  +verificationBy: ?{|
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +markedPaymentSentDate: ?any,
  +markedPaymentSentMessage: ?string,
  +markedPaymentSentBy: ?{|
    +fullName: ?string,
    +representativeImageUrl: ?string,
  |},
  +job: {|
    +id: string,
    +name: string,
    +currencyCode: ?string,
    +staffCoordinator: {|
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |},
    +contractor: {|
      +fullName: ?string,
      +representativeImageUrl: ?string,
    |},
    +$fragmentRefs: CreatePaymentRequestDialog_job$ref,
  |},
  +$refType: InvoiceQuickViewContent_invoice$ref,
|};
export type InvoiceQuickViewContent_invoice$data = InvoiceQuickViewContent_invoice;
export type InvoiceQuickViewContent_invoice$key = {
  +$data?: InvoiceQuickViewContent_invoice$data,
  +$fragmentRefs: InvoiceQuickViewContent_invoice$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fullName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "representativeImageUrl",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "InvoiceQuickViewContent_invoice",
  "type": "InvoiceNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "identifier",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "created",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "invoiceFileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "verificationDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "verificationMessage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "verificationBy",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "markedPaymentSentDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "markedPaymentSentMessage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "markedPaymentSentBy",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "job",
      "storageKey": null,
      "args": null,
      "concreteType": "JobNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currencyCode",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "staffCoordinator",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffNode",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contractor",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractorNode",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "FragmentSpread",
          "name": "CreatePaymentRequestDialog_job",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '1fde28ee16acad7d0168dcc18fffc0a8';
module.exports = node;

import React from 'react';
import { Box, Button, TableCell, Typography, TableRow } from '@material-ui/core';
import styledComponent from 'styled-components';
import { styled } from '@material-ui/styles';

export const ToggleButtonsGroup = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  padding: '5px',
  borderRadius: '20px',
  backgroundColor: theme.palette.colors.lightestOrange
}));

export const ToggleButton = styled(({ active, ...other }) => <Button {...other} />)(
  ({ theme, active }) => ({
    height: '34px',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 19px',
    borderRadius: '20px',
    textTransform: 'none',
    backgroundColor: active && theme.palette.colors.orange,
    color: active ? 'white' : theme.palette.colors.orange,
    fontSize: '12px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: active ? theme.palette.colors.orange : 'rgba(231, 114, 0, 0.1)'
    }
  })
);

export const PlanSelectionCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: '7px',
  '& h1': {
    marginTop: '30px',
    marginBottom: '10px'
  }
}));

const StyledPlanHeaderCell = styled(({ isCurrent, ...other }) => <TableCell {...other} />)(() => ({
  '&': {
    borderBottom: 'none'
  }
}));

const PriceBubble = styled(({ isCurrent, ...other }) => <Box {...other} />)(
  ({ theme, isCurrent }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginBottom: '15px',
    backgroundColor: isCurrent
      ? theme.palette.colors.lightestOrange
      : theme.palette.colors.progressBarLightGrey,
    width: '90px',
    height: '90px',
    '& sup': {
      fontSize: '12px'
    },
    '& h1': {
      fontSize: '40px'
    },
    '& h1, & span': {
      color: isCurrent ? theme.palette.colors.orange : theme.palette.colors.darkBlueGrey,
      lineHeight: 1
    }
  })
);

const PlanHeaderComponent = ({ isCurrent, name, price, ...boxProps }) => (
  <Box {...boxProps}>
    <Typography variant="body2">{isCurrent ? 'Current Tier' : ''}</Typography>
    <Typography variant="h1">{name}</Typography>
    <PriceBubble isCurrent={isCurrent}>
      <Typography variant="h1">
        <sup>$</sup>
        {price}
      </Typography>
      <Typography variant="overline">Per User</Typography>
    </PriceBubble>
  </Box>
);

export const PlanHeader = styledComponent(PlanHeaderComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h1 {
    margin-bottom: 10px;
    color: ${props =>
      props.isCurrent
        ? props.theme.palette.colors.orange
        : props.theme.palette.colors.mediumBlueGrey}
  }

  > p {
    height: 1.5rem;
    color: ${props => props.theme.palette.colors.orange};
  }
`;

export const PlanHeaderCell = props => {
  return (
    <StyledPlanHeaderCell isCurrent={props.isCurrent}>
      <PlanHeader {...props} />
    </StyledPlanHeaderCell>
  );
};

export const StyledPlanInfoCell = styled(({ isCurrent, index, isFooter, ...other }) => (
  <TableCell {...other} />
))(({ theme, index, isCurrent, isFooter }) => ({
  textAlign: 'center',
  fontWeight: isCurrent ? 'bold' : 'normal',
  color: isCurrent ? theme.palette.colors.orange : theme.palette.colors.darkBlueGrey,
  backgroundColor: isCurrent && index % 2 === 0 ? theme.palette.colors.lightestOrange : 'inherit',
  paddingTop: isFooter ? '15px' : '6px'
}));

export const StyledPlanInfoRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.colors.progressBarLightGrey
  },
  '& th': {
    paddingLeft: '7px',
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px'
  },
  '& td:last-child': {
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px'
  }
}));

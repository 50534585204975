/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountInformationContent_timezones$ref: FragmentReference;
declare export opaque type AccountInformationContent_timezones$fragmentType: AccountInformationContent_timezones$ref;
export type AccountInformationContent_timezones = {|
  +timezoneList: ?$ReadOnlyArray<?string>,
  +$refType: AccountInformationContent_timezones$ref,
|};
export type AccountInformationContent_timezones$data = AccountInformationContent_timezones;
export type AccountInformationContent_timezones$key = {
  +$data?: AccountInformationContent_timezones$data,
  +$fragmentRefs: AccountInformationContent_timezones$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AccountInformationContent_timezones",
  "type": "General",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "timezoneList",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1d4ed5b15829dc394b58f2e658dade3f';
module.exports = node;

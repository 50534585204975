/**
 * @flow
 * @relayHash b568f8193858051f28b49b27d28950b9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateJobAssociatedFileInput = {|
  jobId: string,
  clientMutationId?: ?string,
|};
export type CreateJobAssociatedFileMutationVariables = {|
  input: CreateJobAssociatedFileInput
|};
export type CreateJobAssociatedFileMutationResponse = {|
  +createJobAssociatedFile: ?{|
    +jobAssociatedFile: ?{|
      +id: string,
      +fileUrl: ?string,
      +fileName: string,
      +fileSize: ?string,
    |}
  |}
|};
export type CreateJobAssociatedFileMutation = {|
  variables: CreateJobAssociatedFileMutationVariables,
  response: CreateJobAssociatedFileMutationResponse,
|};
*/


/*
mutation CreateJobAssociatedFileMutation(
  $input: CreateJobAssociatedFileInput!
) {
  createJobAssociatedFile(input: $input) {
    jobAssociatedFile {
      id
      fileUrl
      fileName
      fileSize
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateJobAssociatedFileInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createJobAssociatedFile",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateJobAssociatedFilePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobAssociatedFile",
        "storageKey": null,
        "args": null,
        "concreteType": "JobAssociatedFileNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileSize",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateJobAssociatedFileMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateJobAssociatedFileMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateJobAssociatedFileMutation",
    "id": null,
    "text": "mutation CreateJobAssociatedFileMutation(\n  $input: CreateJobAssociatedFileInput!\n) {\n  createJobAssociatedFile(input: $input) {\n    jobAssociatedFile {\n      id\n      fileUrl\n      fileName\n      fileSize\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '954e9d55d566346d944bf6822c412270';
module.exports = node;

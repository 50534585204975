/**
 * @flow
 * @relayHash 2d14999cb2840e99bf3c844819e3defe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeactivateBatchStaffUsersInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type DeactivateBatchStaffUsersMutationVariables = {|
  input: DeactivateBatchStaffUsersInput
|};
export type DeactivateBatchStaffUsersMutationResponse = {|
  +deactivateBatchStaffUsers: ?{|
    +updatedStaffUsers: ?$ReadOnlyArray<?{|
      +id: string,
      +user: {|
        +isActive: ?boolean
      |},
    |}>
  |}
|};
export type DeactivateBatchStaffUsersMutation = {|
  variables: DeactivateBatchStaffUsersMutationVariables,
  response: DeactivateBatchStaffUsersMutationResponse,
|};
*/


/*
mutation DeactivateBatchStaffUsersMutation(
  $input: DeactivateBatchStaffUsersInput!
) {
  deactivateBatchStaffUsers(input: $input) {
    updatedStaffUsers {
      id
      user {
        isActive
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeactivateBatchStaffUsersInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isActive",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeactivateBatchStaffUsersMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deactivateBatchStaffUsers",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateBatchStaffUsersPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeactivateBatchStaffUsersMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deactivateBatchStaffUsers",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateBatchStaffUsersPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeactivateBatchStaffUsersMutation",
    "id": null,
    "text": "mutation DeactivateBatchStaffUsersMutation(\n  $input: DeactivateBatchStaffUsersInput!\n) {\n  deactivateBatchStaffUsers(input: $input) {\n    updatedStaffUsers {\n      id\n      user {\n        isActive\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2596e17c82352daa556fbd290617d0ac';
module.exports = node;

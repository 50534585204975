import { makeStyles } from '@material-ui/styles';
import { drawerWidth, appBarHeight } from '../../../shared/styles/common/base';

const useToastStyles = makeStyles(theme => ({
  toastContainer2: {
    top: `calc(${appBarHeight}px + 3px)`,
    left: `calc(${drawerWidth}px + 24px)`,
    padding: 0,
    width: 'inherit'
  },
  toast2: {
    padding: 0,
    minHeight: 'inherit',
    overflow: 'visible'
  },
  root: {
    backgroundColor: 'white',
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minWidth: 'unset'
  },
  icon: {
    fontSize: 20
  },

  toastMessage: {
    padding: 0
  },

  toastContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    backgroundColor: props => {
      return props.isError ? '#ffa69c' : theme.palette.colors.toastLightGreen;
    }
  },

  toastIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '12px',
    '& svg': {
      margin: 0,
      color: props => (props.isError ? 'red' : 'green')
    }
  },

  toastContent: {
    backgroundColor: 'white',
    minWidth: '200px',
    maxWidth: '300px',
    padding: '16px 0px 20px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  message: {
    margin: '0 0 12px 0',
    fontSize: '12px',
    color: theme.palette.colors.darkBlueGrey
  },

  closeButton: {
    margin: 0,
    padding: '12px 8px',
    '& button': {
      color: 'black'
    }
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  }
}));

export default useToastStyles;

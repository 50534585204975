import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

const mutation = graphql`
  mutation ConfirmPresignedUploadMutation($input: ConfirmPresignedUploadInput!) {
    confirmPresignedUpload(input: $input) {
      isConfirmed
    }
  }
`;

export default (filename, modelName, modelId, fieldName, stagedPath, callback) => {
  const variables = {
    input: {
      filename,
      modelName,
      modelId,
      fieldName,
      stagedPath
    }
  };

  commitMutation(getEnvironment(), {
    mutation,
    variables,
    onCompleted: response => {
      callback(response.confirmPresignedUpload.isConfirmed);
    },
    onError: err => console.error(err)
  });
};

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddressInfo_contractor$ref: FragmentReference;
declare export opaque type AddressInfo_contractor$fragmentType: AddressInfo_contractor$ref;
export type AddressInfo_contractor = {|
  +address1: ?string,
  +address2: ?string,
  +city: ?string,
  +state: ?string,
  +country: ?{|
    +name: ?string
  |},
  +zipcode: ?string,
  +freelancer: ?{|
    +id: string
  |},
  +$refType: AddressInfo_contractor$ref,
|};
export type AddressInfo_contractor$data = AddressInfo_contractor;
export type AddressInfo_contractor$key = {
  +$data?: AddressInfo_contractor$data,
  +$fragmentRefs: AddressInfo_contractor$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AddressInfo_contractor",
  "type": "ContractorNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "address1",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "address2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "city",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "country",
      "storageKey": null,
      "args": null,
      "concreteType": "Country",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "zipcode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "freelancer",
      "storageKey": null,
      "args": null,
      "concreteType": "FreelancerNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'cb701ffef73537725ea2c50ce7a5a701';
module.exports = node;

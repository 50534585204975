/**
 * @flow
 * @relayHash 41cad17db09dfe43cebc930b80cd0956
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CategorySelectWithTypesQueryVariables = {||};
export type CategorySelectWithTypesQueryResponse = {|
  +categoryByOrg: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +categoryTypes: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: string,
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type CategorySelectWithTypesQuery = {|
  variables: CategorySelectWithTypesQueryVariables,
  response: CategorySelectWithTypesQueryResponse,
|};
*/


/*
query CategorySelectWithTypesQuery {
  categoryByOrg(orderBy: "name") {
    edges {
      node {
        id
        name
        categoryTypes {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "categoryByOrg",
    "storageKey": "categoryByOrg(orderBy:\"name\")",
    "args": [
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "name"
      }
    ],
    "concreteType": "CategoryNodeConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "CategoryNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryNode",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "categoryTypes",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryTypeNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryTypeNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryTypeNode",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CategorySelectWithTypesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CategorySelectWithTypesQuery",
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "CategorySelectWithTypesQuery",
    "id": null,
    "text": "query CategorySelectWithTypesQuery {\n  categoryByOrg(orderBy: \"name\") {\n    edges {\n      node {\n        id\n        name\n        categoryTypes {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '15202b5dc8c694468c1c100ebee99287';
module.exports = node;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import AutoTable from '../../../../shared/components/table/AutoTable';
import { CELL_TYPES, ALLOWED_ACTIONS } from '../../../../shared/constants';
import { resolveJobLink } from '../../../../shared/utils/linkResolvers';
import { computePermissionRole } from '../../../../shared/utils/helpers';
import { successToast } from '../../../../shared/toasts';
import { resolveReleaseLink, resolveProductLink } from '../../../../shared/utils/linkResolvers';
import BulkAddFollowersDialog from '../../shared/BulkAddFollowersDialog';
import ToggleAssignFollowersForJobsMutation from '../mutations/ToggleAssignFollowersForJobsMutation';
import ConfirmJobFollow from '../ConfirmJobFollow';
import StyledTableCell from '../../../../shared/components/table/StyledTableCell';
import CustomTableTooltip from '../../../../shared/components/table/CustomTableTooltip';
import JobsListFilters from './JobsListFilters';
import { PaymentStatusHeader, NewInvocesLink, PaymentStatusIcon, FollowingIcon } from './styled';

const ACTION_BUTTON_OPTIONS = {
  addFollowers: { name: 'addFollowers', label: 'Add followers' },
  follow: { name: 'follow', label: 'Follow' },
  unfollow: { name: 'unfollow', label: 'Unfollow' }
};

const JobsList = props => {
  const {
    isContractorTab,
    edges,
    paginationProps,
    refetchCounter,
    toRefetchData,
    statusTypes: { enumValues: statusEnumValues },
    paymentStatusTypes: { enumValues: paymentStatusEnumValues },
    relay: { refetch },
    match,
    history,
    userContext: {
      orgStaff: {
        id: orgStaffUserId,
        organization: { configuration },
        allowedActions
      }
    }
  } = props;

  const [pendingAction, setPendingAction] = useState({});
  const navigation = JSON.parse(configuration).navigation;

  const flattenedEdges = edges.map(({ node }) => ({
    node: {
      id: node.id,
      created: node.created,
      jobId: node.jobId,
      jobDeadline: node.jobDeadline,
      status: { code: node.status },
      name: node.name,
      isCurrentUserFollowing: node.isCurrentUserFollowing,
      releases: node.releases.map(el => {
        return {
          cellText: el.name,
          cellLink: resolveReleaseLink(el.id),
          truncate: true
        };
      }),
      products: node.releases.map(el => {
        return {
          cellText: el.product.title,
          cellLink: resolveProductLink(el.product.id),
          truncate: true
        };
      }),
      category: node.deliverableCategory ? node.deliverableCategory.name : null,
      staff: {
        id: node.staffCoordinator.id,
        name: node.staffCoordinator.fullName,
        imageUrl: node.staffCoordinator.representativeImageUrl
      },
      ...(isContractorTab
        ? {}
        : {
            contractor: {
              id: node.contractor.id,
              name: node.contractor.fullName,
              imageUrl: node.contractor.representativeImageUrl
            }
          }),
      amount: node.totalValue,
      currencyCode: node.currencyCode,
      paymentStatus: node.paymentStatus,
      hasInvoicesToReview: node.hasInvoicesToReview
    }
  }));

  const jobIdClickHandler = id => {
    if (isContractorTab) {
      history.push(`/jobs/${id}`);
    } else {
      history.push(`${match.url}/${id}`);
    }
  };

  const onContractorClick = id => {
    const { history } = props;
    history.push(`/contractors/contractors/${id}`);
  };

  const showFollowingSuccess = (action, jobIds) =>
    successToast(`${action} ${jobIds.length} ${pluralize('job', jobIds.length)}`);

  const handlePendingActionReset = () => setPendingAction({});

  const handleActionClick = (action, jobIds) => setPendingAction({ name: action, jobIds });

  const getDisabledActions = () => {
    const disabled = [];

    if (!computePermissionRole(ALLOWED_ACTIONS.ADD_FOLLOWERS, allowedActions)) {
      disabled.push(ACTION_BUTTON_OPTIONS.addFollowers.name);
    }

    return disabled;
  };

  return (
    <>
      {[ACTION_BUTTON_OPTIONS.follow.name, ACTION_BUTTON_OPTIONS.unfollow.name].includes(
        pendingAction.name
      ) && (
        <ConfirmJobFollow
          isDialogOpen
          jobIds={pendingAction.jobIds}
          follow={pendingAction.name === ACTION_BUTTON_OPTIONS.follow.name}
          closeDialog={handlePendingActionReset}
          onSubmit={withDeliverables =>
            ToggleAssignFollowersForJobsMutation({
              userIds: [orgStaffUserId],
              follow: pendingAction.name === ACTION_BUTTON_OPTIONS.follow.name,
              jobIds: pendingAction.jobIds,
              withDeliverables
            }).then(() => {
              const actionStr =
                pendingAction.name === ACTION_BUTTON_OPTIONS.follow.name
                  ? 'Followed'
                  : 'Unfollowed';
              showFollowingSuccess(actionStr, pendingAction.jobIds);
              handlePendingActionReset();
              toRefetchData();
            })
          }
        />
      )}
      {pendingAction.name === ACTION_BUTTON_OPTIONS.addFollowers.name && (
        <BulkAddFollowersDialog
          onSubmit={({ userIds, withDeliverables }) =>
            ToggleAssignFollowersForJobsMutation({
              follow: true,
              userIds,
              jobIds: pendingAction.jobIds,
              withDeliverables
            }).then(() => {
              handlePendingActionReset();
              successToast(
                `Added ${userIds.length} ${pluralize('followers', userIds.length)} to ${
                  pendingAction.jobIds.length
                } ${pluralize('job', pendingAction.jobIds.length)}`
              );
              toRefetchData();
            })
          }
          withDeliverables
          infoText={`You are about to edit ${pendingAction.jobIds.length} ${pluralize(
            'Job',
            pendingAction.jobIds.length
          )}.`}
          isDialogOpen
          closeDialog={handlePendingActionReset}
        />
      )}

      <AutoTable
        title={isContractorTab ? 'Job History' : null}
        labelledByHeader="select all jobs"
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
          { name: 'jobId', label: 'ID', sortable: true, type: CELL_TYPES.link },
          { name: 'name', label: 'Name', sortable: true, type: CELL_TYPES.link },
          {
            name: 'isCurrentUserFollowing',
            label: '',
            renderContent: (_key, isFollowing) =>
              isFollowing ? (
                <CustomTableTooltip title="Following">
                  <FollowingIcon />
                </CustomTableTooltip>
              ) : (
                false
              )
          },
          {
            name: 'releases',
            label: navigation.releaseTitle.plural,
            type: CELL_TYPES.listWithMultiple,
            multipleValue: 'Multiple ' + navigation.releaseTitle.plural
          },
          {
            name: 'products',
            label: navigation.productTitle.plural,
            type: CELL_TYPES.listWithMultiple,
            multipleValue: 'Multiple ' + navigation.productTitle.plural
          },
          { name: 'category', label: 'Category', sortable: true },
          {
            name: 'created',
            label: 'Created on',
            noWrap: true,
            type: CELL_TYPES.date,
            sortable: true
          },
          {
            name: 'jobDeadline',
            label: 'Deadline',
            sortable: true,
            type: CELL_TYPES.date
          },
          {
            name: 'status',
            label: 'Status',
            type: CELL_TYPES.status,
            statusTypes: statusEnumValues,
            variant: 'job'
          },
          {
            name: 'staff',
            label: 'Staff',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            width: 76,
            align: 'center'
          },
          ...(isContractorTab
            ? []
            : [
                {
                  name: 'contractor',
                  label: 'Contractor',
                  type: CELL_TYPES.avatar,
                  onClick: onContractorClick,
                  onlyTooltip: true,
                  align: 'center'
                }
              ]),
          {
            name: 'amount',
            label: 'Amount',
            type: CELL_TYPES.amount,
            sortable: true,
            align: 'right'
          },
          {
            name: 'hasInvoicesToReview',
            label: '',
            width: 20,
            renderCell: ({ index, rowIndex }) => {
              const {
                node: { id, hasInvoicesToReview }
              } = flattenedEdges[rowIndex];
              return (
                <StyledTableCell key={index} style={{ paddingRight: 0 }}>
                  {hasInvoicesToReview && (
                    <NewInvocesLink to={resolveJobLink(id, 'payment-requests/invoices')} />
                  )}
                </StyledTableCell>
              );
            }
          },
          {
            name: 'paymentStatus',
            width: 16,
            label: <PaymentStatusHeader />,
            renderCell: ({ index, value }) => {
              const { description: statusTitle } = paymentStatusEnumValues.find(
                ({ name }) => name === value
              );
              return (
                <StyledTableCell key={index}>
                  <PaymentStatusIcon status={value} title={statusTitle} />
                </StyledTableCell>
              );
            }
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={refetch}
        filterProps={{
          filterComponent: (
            <JobsListFilters
              isContractor={isContractorTab}
              statusOptions={statusEnumValues}
              paymentStatusOptions={paymentStatusEnumValues}
            />
          ),
          withApply: true
        }}
        actionButtonProps={{
          options: Object.values(ACTION_BUTTON_OPTIONS),
          handleMenuItemClick: handleActionClick,
          getDisabledActions
        }}
        rowProps={{
          handleLinkClick: jobIdClickHandler
        }}
        withBackground={!isContractorTab}
        refetchCounter={refetchCounter}
        paginationProps={paginationProps}
      />
    </>
  );
};

JobsList.propTypes = {
  /** This prop is used to disable the background for this list on contractor details
   * jobs tab jobs list. TODO remove once all tables are moved to the white background.
   */
  noBackground: PropTypes.bool,
  /** True if used in contractor details, replaces contractor with staff  */
  isContractorTab: PropTypes.bool.isRequired
};

JobsList.defaultProps = {
  noBackground: false,
  isContractorTab: false
};

export default withUserContext(JobsList);

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { loadCSS } from 'fg-loadcss';
import * as Sentry from '@sentry/browser';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { isProdEnv, getEnv } from './shared/utils/helpers';

import { getURLPrefix, redirectToOrganizationApp } from './shared/services/utilities';

import OrganizationApp from './organization/OrganizationApp';
import FreelancerApp from './freelancer/FreelancerApp';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import organizationTheme from './shared/styles/theme/organizationTheme';
import freelancerTheme from './shared/styles/theme/freelancerTheme';

import { FREELANCER_APP, ORGANIZATION_APP } from './shared/services/constants';
import 'react-toastify/dist/ReactToastify.css';
import authService from './shared/services/authService';
import { TableHistoryContextManager } from './shared/contexts/tableHistoryContext';

const ORGANIZATION_APP_CONFIG = {
  baseName: ORGANIZATION_APP,
  theme: organizationTheme,
  appComponent: <OrganizationApp />
};

const FREELANCER_APP_CONFIG = {
  baseName: FREELANCER_APP,
  theme: freelancerTheme,
  appComponent: <FreelancerApp />
};

const getAppConfig = () => {
  switch (getURLPrefix()) {
    case ORGANIZATION_APP:
      return ORGANIZATION_APP_CONFIG;
    case FREELANCER_APP:
      return FREELANCER_APP_CONFIG;
    default:
      return null;
  }
};

const App = () => {
  let appConfig = getAppConfig();
  if (!appConfig) {
    redirectToOrganizationApp();
  } else {
    authService.setAuthVariables();
    authService.checkIfAuthenticated();
  }

  if (isProdEnv()) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_FRONTEND_DSN,
      environment: getEnv()
    });
    console.info('Sentry logger initialized');
  }

  useEffect(() => {
    // useEffect hook for grabbing font-awesome icons
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
  }, []);

  useEffect(() => {
    if (!authService.urlPrefix) {
      authService.setAuthVariables();
      authService.checkIfAuthenticated();
    }
  }, []);

  return (
    appConfig && (
      <BrowserRouter basename={appConfig.baseName}>
        <TableHistoryContextManager baseName={appConfig.baseName}>
          <StyledComponentsThemeProvider theme={appConfig.theme}>
            <ThemeProvider theme={appConfig.theme}>
              <CssBaseline />
              {appConfig.appComponent}
            </ThemeProvider>
          </StyledComponentsThemeProvider>
        </TableHistoryContextManager>
      </BrowserRouter>
    )
  );
};

export default App;

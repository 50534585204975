export const AWE_USER_EMAIL_ORGANIZATION = 'awe-user-email-organization';
export const AWE_AUTH_TOKEN_ORGANIZATION = 'awe-auth-token-organization';
export const AWE_AUTH_REFRESH_TOKEN_ORGANIZATION = 'awe-auth-refresh-token-organization';

export const AWE_USER_EMAIL_FREELANCER = 'awe-user-email-freelancer';
export const AWE_AUTH_TOKEN_FREELANCER = 'awe-auth-token-freelancer';
export const AWE_AUTH_REFRESH_TOKEN_FREELANCER = 'awe-auth-refresh-token-freelancer';

export const ORGANIZATION_APP = '/app/organization';
export const FREELANCER_APP = '/app/freelancer';

export const APP_TYPES = {
  organization: 'organization',
  freelancer: 'freelancer'
};

export const API_URLS = {
  organization: '/api/graphql/organization/',
  freelancer: '/api/graphql/freelancer/'
};

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import ReleaseDetailsMainContent from './ReleaseDetailsMainContent';

const ReleaseDetailsTab = props => {
  const { id } = useParams();
  return (
    <QueryRenderer
      query={graphql`
        query ReleaseDetailsTabQuery($id: ID!) {
          release(id: $id) {
            ...ReleaseDetailsMainContent_release
          }
        }
      `}
      variables={{ id }}
      render={relayProps => <ReleaseDetailsMainContent release={relayProps.release} {...props} />}
    />
  );
};

export default ReleaseDetailsTab;

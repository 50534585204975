import React, { Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import clsx from 'clsx';

import useReleaseDeliverablesStyles from '../../../../../shared/styles/release/useReleaseDeliverablesStyles';
import { formatAmount } from '../../../../../shared/utils/formatters';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';

const ReleaseDeliverablesListHeader = props => {
  const {
    release: {
      numberOfUnassignedDeliverables,
      deliverablesTotalAmount,
      deliverablesTotalContractedAmount,
      numberOfDeliverablesWithoutCoordinator
    },

    userContext: {
      orgStaff: {
        organization: { currencyCode }
      }
    }
  } = props;

  const classes = useReleaseDeliverablesStyles();

  const classColorCoord =
    numberOfDeliverablesWithoutCoordinator === 0 ? classes.green : classes.red;
  const classColorUnassigned = numberOfUnassignedDeliverables === 0 ? classes.green : classes.red;

  return (
    <Fragment>
      <div className={clsx(classes.container, classes.totalValuesPosition)}>
        <div className={classes.marginRight}>
          <span className={classes.lightGray}>Total Amount: </span>
          <span className={classes.darkGray}>
            {formatAmount(deliverablesTotalAmount, currencyCode)}
          </span>
        </div>
        <div className={classes.marginRight}>
          <span className={classes.lightGray}>Total Assigned Amount: </span>
          <span className={classes.darkGray}>
            {formatAmount(deliverablesTotalContractedAmount, currencyCode)}
          </span>
        </div>
        <div className={classes.marginRight}>
          <span className={classes.lightGray}>No Coordinator: </span>
          <span className={classColorCoord}>{numberOfDeliverablesWithoutCoordinator}</span>
        </div>
        <div>
          <span className={classes.lightGray}>Unassigned: </span>
          <span className={classColorUnassigned}>{numberOfUnassignedDeliverables}</span>
        </div>
      </div>
    </Fragment>
  );
};
export default withUserContext(
  createFragmentContainer(ReleaseDeliverablesListHeader, {
    release: graphql`
      fragment ReleaseDeliverablesListHeader_release on ReleaseNode {
        id
        numberOfUnassignedDeliverables
        numberOfDeliverablesWithoutCoordinator
        deliverablesTotalAmount
        deliverablesTotalContractedAmount
      }
    `
  })
);

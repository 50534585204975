import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../utils/helpers';

const mutation = graphql`
  mutation MarkAllNotificationsAsReadMutation($input: MarkAllNotificationsAsReadInput!) {
    markAllNotificationsAsRead(input: $input) {
      isUpdated
    }
  }
`;

const MarkAllNotificationsAsReadMutation = callback => {
  const config = {
    mutation,
    variables: { input: {} },
    onCompleted: response => {
      callback(response.markAllNotificationsAsRead);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default MarkAllNotificationsAsReadMutation;

import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { DialogContentText, Box, FormControlLabel } from '@material-ui/core';
import Dialog, { DialogActions } from '../../../shared/components/common/Dialog';
import StyledCheckbox from '../../../shared/components/UI/StyledCheckbox';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import { isPromise } from '../../../shared/utils/helpers';
import SearchableStaffDropdown from '../shared/dropdowns/SearchableStaffDropdown';

const BulkAddFollowersDialog = ({ onSubmit, infoText, withDeliverables, ...dialogProps }) => {
  const handleSubmut = (values, actions) => {
    // TODO:
    // This won't be needed when formik will be updated to 2.x as it handles promises automatically
    const result = onSubmit(
      {
        userIds: values.selectedStaff.options.map(({ value }) => value),
        withDeliverables: values.withDeliverables
      },
      actions
    );
    if (isPromise(result)) result.finally(() => actions.setSubmitting(false));
  };

  return (
    <Dialog title="Add Followers" {...dialogProps}>
      <Formik
        onSubmit={handleSubmut}
        initialValues={{
          selectedStaff: {
            options: []
          }
        }}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <>
            <DialogContentText>{infoText}</DialogContentText>

            <Box mt={1.5} width={500}>
              <SearchableStaffDropdown
                showCurrentUser
                name="selectedStaff"
                label="Staff Name"
                value={values.selectedStaff}
                onChange={setFieldValue}
                withChipsBelow
                isMulti
              />
            </Box>
            {withDeliverables && (
              <Box mt={1.5}>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={values.withDeliverables}
                      onChange={e => setFieldValue('withDeliverables', e.target.checked)}
                    />
                  }
                  label="Add followers to associated Deliverables"
                />
              </Box>
            )}

            <DialogActions>
              <GlobalButton
                variant="transparent"
                handleClick={dialogProps.closeDialog}
                disabled={isSubmitting}
              >
                Cancel
              </GlobalButton>
              <GlobalButton
                handleClick={submitForm}
                loading={isSubmitting}
                disabled={isSubmitting || values.selectedStaff.options.length === 0}
              >
                Submit
              </GlobalButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

BulkAddFollowersDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  infoText: PropTypes.string
};

export default BulkAddFollowersDialog;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const deactivateDeliverableMutation = graphql`
  mutation DeactivateDeliverableMutation($input: DeactivateDeliverableInput!) {
    deactivateDeliverable(input: $input) {
      updatedDeliverable {
        state
      }
    }
  }
`;

export default (id, callback) => {
  const deactivateDeliverableVariables = {
    input: { id }
  };

  const config = {
    mutation: deactivateDeliverableMutation,
    variables: deactivateDeliverableVariables,
    onCompleted: response => {
      callback(response.deactivateDeliverable);
    }
  };

  commitMutation(getEnvironment(), config);
};

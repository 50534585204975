import React, { Fragment, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import FolderIcon from '@material-ui/icons/Folder';
import SqaureButton from '../../../../../shared/components/UI/SquareButton';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import StyledTableCell from '../../../../../shared/components/table/StyledTableCell';
import { CELL_TYPES } from '../../../../../shared/constants';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { getActiveDeliverableDueDateWarningColor } from '../../../../../shared/utils/helpers';
import {
  resolveDeliverableLink,
  resolveProductLink
} from '../../../../../shared/utils/linkResolvers';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import DeliverablesQuickView from '../../../deliverables/DeliverablesQuickView';

const JobDeliverablesTab = props => {
  const {
    relay: { refetch },
    history,
    job: {
      deliverables: {
        edges,
        totalCount,
        edgeCount,
        pageInfo: { hasNextPage, endCursor }
      }
    },
    stateTypes,
    userContext: {
      orgStaff: {
        organization: { configuration }
      }
    }
  } = props;
  const navigation = JSON.parse(configuration).navigation;

  const [selectedId, setSelectedId] = useState(null);

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const onLinkClickHandler = id => {
    history.push(`/deliverables/${id}`);
  };
  const flattenedEdges = edges.map(edge => {
    const {
      id,
      title,
      release: {
        product: { title: productName, id: productId }
      },
      categoryType,
      state,
      reviewStep,
      amount,
      currencyCode,
      dueDate,
      assignedStaff
    } = edge.node;

    const type = categoryType ? categoryType.name : null;

    const staff = assignedStaff
      ? {
          id: assignedStaff.user.id,
          name: assignedStaff.fullName,
          imageUrl: assignedStaff.representativeImageUrl
        }
      : null;

    return {
      node: {
        id,
        title,
        productName: { cellText: productName, cellLink: resolveProductLink(productId) },
        type,
        state: { code: state },
        reviewStep,
        amount,
        currencyCode,
        dueDate: {
          date: dueDate,
          color: getActiveDeliverableDueDateWarningColor(configuration, dueDate, state)
        },
        staff
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        initialOrderBy="title"
        rowTemplate={[
          {
            name: '',
            label: '',
            type: CELL_TYPES.info,
            onClick: onQuickViewClickHandler
          },
          {
            name: 'title',
            label: 'Name',
            type: CELL_TYPES.link
          },
          {
            name: 'productName',
            label: `${navigation.productTitle.singular}`,
            type: CELL_TYPES.relationLink
          },
          { name: 'type', label: 'Type' },
          {
            name: 'dueDate',
            label: 'Due Date',
            type: CELL_TYPES.dateWithWarning,
            sortable: true,
            width: 100
          },
          {
            name: 'state',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: stateTypes.enumValues
          },
          {
            name: 'staff',
            label: 'Coordinator',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center'
          },
          {
            name: 'amount',
            label: 'Amount',
            type: CELL_TYPES.amount
          },
          {
            name: '',
            label: '',
            renderCell: ({ index, rowIndex }) => {
              const { node } = flattenedEdges[rowIndex];

              return (
                <StyledTableCell key={index} align="center">
                  <SqaureButton
                    variant="transparent"
                    noMargin
                    icon={FolderIcon}
                    onClick={() => history.push(resolveDeliverableLink(node.id, 'files'))}
                    tooltipTitle="Deliverable Files"
                  />
                </StyledTableCell>
              );
            }
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        rowProps={{
          handleLinkClick: onLinkClickHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <DeliverablesQuickView deliverableId={selectedId} configuration={configuration} />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withUserContext(
  createRefetchContainer(
    JobDeliverablesTab,
    {
      job: graphql`
        fragment JobDeliverablesTab_job on JobNode
        @argumentDefinitions(
          first: { type: "Int" }
          orderBy: { type: "String", defaultValue: "title" }
          after: { type: "String" }
        ) {
          id
          deliverables(first: $first, after: $after, orderBy: $orderBy) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                title
                state
                dueDate
                amount
                currencyCode
                reviewStep
                categoryType {
                  name
                }
                assignedStaff {
                  representativeImageUrl
                  fullName
                  user {
                    id
                  }
                }
                release {
                  product {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      `,
      stateTypes: graphql`
        fragment JobDeliverablesTab_stateTypes on __Type {
          enumValues {
            name
            description
          }
        }
      `
    },
    graphql`
      query JobDeliverablesTabRefetchQuery(
        $id: ID!
        $first: Int
        $after: String
        $orderBy: String
      ) {
        job(id: $id) {
          ...JobDeliverablesTab_job @arguments(first: $first, after: $after, orderBy: $orderBy)
        }
      }
    `
  )
);

import React from 'react';
import debounce from 'lodash/debounce';
import Editor, {
  richTextValueFromJsonString,
  richTextValueToJsonString,
  richTextValueToRawString
} from '../../richText';

const findReferenceFileIds = value =>
  value.ops
    .filter(op => typeof op.insert === 'object' && op.insert.referenceFile)
    .map(op => op.insert.referenceFile.id);

const RichTextField = ({ value, onChange, ...rest }) => {
  const handleChange = debounce(value => {
    onChange({
      content: richTextValueToJsonString(value),
      rawContent: richTextValueToRawString(value),
      referenceIds: findReferenceFileIds(value)
    });
  }, 100);

  return (
    <Editor {...rest} value={richTextValueFromJsonString(value) || value} onChange={handleChange} />
  );
};

export default RichTextField;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation ToggleAutoRenewalMutation($input: ToggleAutoRenewalSettingsInput!) {
    toggleAutoRenewalSettings(input: $input) {
      activeSubscription {
        autoRenew
      }
    }
  }
`;

const ToggleAutoRenewalMutation = (shouldAutoRenew, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input: { shouldAutoRenew } },
    onCompleted: response => {
      callback(response.toggleAutoRenewalSettings);
    },
    onError: err => console.error(err)
  });
};

export default ToggleAutoRenewalMutation;

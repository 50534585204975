import React, { Fragment, useState, useEffect } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';
import { Box } from '@material-ui/core';

import { CELL_TYPES, DELIVERABLE_STATES, ALLOWED_ACTIONS } from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import withDialogProps from '../../../../../shared/hoc/withDialogProps';
import useDialog from '../../../../../shared/hooks/useDialog';
import Dialog from '../../../../../shared/components/common/Dialog';

import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import EditDeliverableForm from '../../../deliverables/EditDeliverableForm';
import { resolveReleaseLink, resolveProductLink } from '../../../../../shared/utils/linkResolvers';

import AddSolicitationDeliverableMutation from '../../mutations/AddSolicitationDeliverableMutation';
import RemoveSolicitationDeliverableMutation from '../../mutations/RemoveSolicitationDeliverableMutation';
import {
  computePermissionRole,
  getActiveDeliverableDueDateWarningColor
} from '../../../../../shared/utils/helpers';
import SearchableDeliverableDropdown from '../../../jobs/SearchableDeliverableDropdown';
import useUpdateEffect from '../../../../../shared/hooks/useUpdateEffect';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import DeliverablesQuickView from '../../../deliverables/DeliverablesQuickView';

const ACTION_BUTTON_OPTIONS = {
  assignStaff: { name: 'assignStaff', label: 'Set Coordinator', modalTitle: 'Set Coordinator' }
};

const SolicitationDeliverablesList = props => {
  const {
    relay: { refetch },
    userContext: {
      id: currentUserId,
      orgStaff: {
        allowedActions,
        organization: { configuration }
      }
    },
    solicitation,
    refetchCounter: contextActionRefetchCounter,
    refetchCounterForDeliverables,
    handleContextActionOptions,
    canEditSolicitation
  } = props;

  const initialEditingData = { action: '', checked: [], refreshChecked: () => {} };

  const [refetchCounter, setRefetchCounter] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [isDialogOpen, setDialogOpen] = useDialog();
  const [editingData, setEditingData] = useState(initialEditingData);

  useEffect(() => {
    return () => {
      handleContextActionOptions();
    };
  }, []);

  useUpdateEffect(() => {
    setRefetchCounter(prev => prev + 1);
  }, [contextActionRefetchCounter, refetchCounterForDeliverables]);

  if (!solicitation) {
    return <div>Something went wrong.</div>;
  }
  const {
    id,
    solicitationId,
    deliverables: {
      pageInfo: { hasNextPage, endCursor },
      edges,
      totalCount,
      edgeCount
    }
  } = solicitation;

  const navigation = JSON.parse(configuration).navigation;

  const toggleDialogOpen = () => {
    setDialogOpen(!isDialogOpen);
  };

  const checkForContextActionsHandler = (checked, refreshChecked) => {
    if (checked.length) {
      const areAllInReadyOrDraftState = checked.every(
        ({ node }, _i) =>
          node.state === DELIVERABLE_STATES.ready || node.state === DELIVERABLE_STATES.draft
      );
      const isInReadyOrDraftState = item =>
        !(
          item.node.state === DELIVERABLE_STATES.ready ||
          item.node.state === DELIVERABLE_STATES.draft
        );

      handleContextActionOptions({
        name: 'jobWithDeliverablesSolicitations',
        data: {
          checked: checked.map(item => ({
            ...item.node,
            assignedStaff: item.node.assignedStaff
              ? {
                  ...item.node.assignedStaff,
                  fullName: item.node.assignedStaff.name,
                  representativeImageUrl: item.node.assignedStaff.imageUrl
                }
              : null
          })),
          disabledProps: {
            disabled: !areAllInReadyOrDraftState,
            errorItems: checked.filter(isInReadyOrDraftState).map(({ node }) => node.title),
            variant: 'job'
          },
          refreshChecked
        }
      });
    } else {
      handleContextActionOptions();
    }
  };

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const onLinkClickHandler = id => {
    const { history } = props;
    history.push(`/deliverables/${id}`);
  };

  const removeClickHandler = deliverableId => {
    RemoveSolicitationDeliverableMutation(id, deliverableId, response => {
      if (response && response.isUpdated) {
        setRefetchCounter(prev => prev + 1);
      }
    });
  };

  const addToListHandler = deliverable => {
    if (!deliverable) return;
    AddSolicitationDeliverableMutation(id, deliverable.value, response => {
      if (response && response.isUpdated) {
        setRefetchCounter(prev => prev + 1);
      }
    });
  };

  const actionClickHandler = (action, checked, refreshChecked) => {
    setEditingData({ action, checked, refreshChecked });
    toggleDialogOpen();
  };

  const closeEditingHandler = () => {
    setEditingData(initialEditingData);
    toggleDialogOpen();
  };

  const closeEditingAndUpdateHandler = () => {
    editingData.refreshChecked();
    closeEditingHandler();
  };

  const disabledActionsCheckHandler = checked => {
    const disabledItems = [];
    const byCurrentUser = checked.every(({ node }) => node.assignedStaff.userId === currentUserId);

    if (
      (!byCurrentUser &&
        !computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER,
          allowedActions
        )) ||
      !computePermissionRole(
        [
          ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER,
          ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER
        ],
        allowedActions
      )
    ) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.assignStaff.name);
    }

    return disabledItems;
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      title,
      category: { name: categoryName },
      amount,
      currencyCode,
      amountInHomeCurrency,
      dueDate,
      release: {
        name: releaseName,
        id: releaseId,
        product: { title: productName, id: productId }
      },
      assignedContractor,
      assignedInternally,
      assignedStaff,
      state,
      allocation,
      release
    } = edge.node;

    const isAllocatedInOtherSolicitation = allocation
      ? allocation.solicitation.id !== solicitation.id
      : false;
    const assignedTo = assignedContractor || assignedInternally || null;

    return {
      node: {
        id,
        // used for context action create job with deliverables
        release,
        warning: isAllocatedInOtherSolicitation,
        title,
        categoryName,
        amount,
        currencyCode,
        // used for context action
        amountInHomeCurrency,
        dueDate: {
          date: dueDate,
          color: getActiveDeliverableDueDateWarningColor(configuration, dueDate, state)
        },
        releaseName: {
          cellText: releaseName,
          cellLink: resolveReleaseLink(releaseId)
        },
        productName: {
          cellText: productName,
          cellLink: resolveProductLink(productId)
        },
        category: edge.node.category,
        state,

        assignedTo: assignedTo
          ? {
              name: assignedTo.fullName,
              imageUrl: assignedTo.representativeImageUrl,
              border: assignedContractor ? 'freelancer' : 'organization'
            }
          : null,
        assignedStaff: assignedStaff
          ? {
              name: assignedStaff.fullName,
              imageUrl: assignedStaff.representativeImageUrl,
              userId: assignedStaff.user.id,
              id: assignedStaff.id
            }
          : null,
        allocation: allocation
          ? {
              name: allocation.contractor.fullName,
              imageUrl: allocation.contractor.representativeImageUrl
            }
          : null
      }
    };
  });

  const categoryId = edges.length ? edges[0].node.category.id : '';

  return (
    <Fragment>
      {canEditSolicitation() && (
        <Box width="300px" mb={2}>
          <SearchableDeliverableDropdown
            fetchVariables={{
              category: categoryId,
              excludeInSolicitation: solicitationId,
              state: '2',
              assignedToMeOnly: true
            }}
            name="solicitationDeliverables"
            placeholder="Add deliverable"
            keyToReload={`${categoryId}-${edges.length}`}
            selectChangeHandler={addToListHandler}
          />
        </Box>
      )}
      <AutoTable
        title="Deliverable List"
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
          { name: '', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
          {
            name: 'title',
            label: 'Name',
            sortable: true,
            type: CELL_TYPES.link
          },
          {
            name: 'warning',
            label: '',
            type: CELL_TYPES.warning,
            tooltip: 'This Deliverable is allocated outside this JobOpp.'
          },
          {
            name: 'productName',
            label: navigation.productTitle.singular,
            type: CELL_TYPES.relationLink,
            sortable: true
          },
          {
            name: 'releaseName',
            label: navigation.releaseTitle.singular,
            type: CELL_TYPES.relationLink,
            sortable: true
          },
          { name: 'categoryName', label: 'Category', width: '8%' },
          {
            name: 'dueDate',
            label: 'Due Date',
            type: CELL_TYPES.dateWithWarning,
            sortable: true,
            width: 100
          },
          {
            name: 'assignedStaff',
            label: 'Coordinator',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center'
          },
          {
            name: 'assignedTo',
            label: 'Assignee',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            width: 83,
            align: 'center'
          },
          {
            name: 'allocation',
            label: 'Allocated',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center'
          },
          { name: 'amount', label: 'Amount', type: CELL_TYPES.amount },
          {
            name: 'trash',
            label: '',
            type: CELL_TYPES.trash,
            onClick: removeClickHandler,
            hidden: !canEditSolicitation(),
            tooltip: 'Remove'
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        refetchCounter={refetchCounter}
        checkForContextActions={checkForContextActionsHandler}
        rowProps={{
          handleLinkClick: onLinkClickHandler
        }}
        labelledByHeader="select all deliverables"
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
        actionButtonProps={{
          options: Object.values(ACTION_BUTTON_OPTIONS),
          handleMenuItemClick: actionClickHandler,
          getDisabledActions: disabledActionsCheckHandler
        }}
      />
      <Dialog title="Set Coordinator" isDialogOpen={isDialogOpen} closeDialog={toggleDialogOpen}>
        <EditDeliverableForm
          handleClose={closeEditingHandler}
          handleCloseWithRefetch={closeEditingAndUpdateHandler}
          // this is not the prettiest way but it allows me to reuse same EditDeliverableForm like in DeliverableList component
          // and in the future we might need other actions as well
          // TODO this needs to be refactored, everytime we add an actions to the EditDeliverableForm and we do not
          // add it here this can potentially break.
          options={{
            ...ACTION_BUTTON_OPTIONS,
            modifyAmount: { name: 'modifyAmount' },
            delete: { name: 'delete' },
            markReady: { name: 'markReady' },
            modifyDate: { name: 'modifyDate' }
          }}
          editingData={editingData}
        />
      </Dialog>
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <DeliverablesQuickView deliverableId={selectedId} configuration={configuration} />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withRouter(
  withUserContext(
    withDialogProps(
      createRefetchContainer(
        SolicitationDeliverablesList,
        {
          solicitation: graphql`
            fragment SolicitationDeliverablesList_solicitation on SolicitationNode {
              id
              solicitationId
              state
              staffCoordinator {
                user {
                  id
                }
              }
              deliverables(first: $first, after: $after, orderBy: $orderBy) {
                pageInfo {
                  hasNextPage
                  endCursor
                }
                totalCount
                edgeCount
                edges {
                  node {
                    id
                    title
                    amount
                    currencyCode
                    amountInHomeCurrency
                    state
                    category {
                      id
                      name
                    }
                    release {
                      id
                      name
                      product {
                        id
                        title
                      }
                    }
                    allocation {
                      solicitation {
                        id
                      }
                      contractor {
                        fullName
                        representativeImageUrl
                      }
                    }
                    dueDate
                    assignedContractor {
                      fullName
                      representativeImageUrl
                    }
                    assignedInternally {
                      fullName
                      representativeImageUrl
                    }
                    assignedStaff {
                      id
                      fullName
                      representativeImageUrl
                      user {
                        id
                      }
                    }
                  }
                }
              }
            }
          `
        },
        graphql`
          query SolicitationDeliverablesListRefetchQuery(
            $first: Int
            $after: String
            $id: ID!
            $orderBy: String
          ) {
            solicitation: solicitation(id: $id) {
              ...SolicitationDeliverablesList_solicitation
            }
          }
        `
      )
    )
  )
);

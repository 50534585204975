import React from 'react';
import PropTypes from 'prop-types';
import ObjAsset from './ObjAsset';
import GltfAsset from './GltfAsset';
import STLAsset from './STLAsset';
import Zip3DAsset from './Zip3DAsset';
import FbxAsset from './FbxAsset';

/**
 * This component is responsible to select the correct loader
 * based on the extension of the 3D file being loaded.
 *
 */

const ModelAsset = props => {
  const { src } = props;
  const url = src.split('?')[0].toLowerCase();
  if (url.endsWith('.obj')) {
    return <ObjAsset {...props} />;
  } else if (url.endsWith('.glb')) {
    return <GltfAsset {...props} />;
  } else if (url.endsWith('.fbx')) {
    return <FbxAsset {...props} />;
  } else if (url.endsWith('.stl')) {
    return <STLAsset {...props} />;
  } else if (url.endsWith('.zip')) {
    return <Zip3DAsset {...props} />;
  }
};

ModelAsset.propTypes = {
  src: PropTypes.string.isRequired,
  centerCameraOn: PropTypes.func.isRequired,
  flatShading: PropTypes.bool,
  handlers: PropTypes.shape({
    onPointerMove: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onClick: PropTypes.func
  })
};

export default ModelAsset;

/**
 * @flow
 * @relayHash 1f9ec3ba4426eab5f210a44abc7142de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteJobCommentInput = {|
  commentId: string,
  clientMutationId?: ?string,
|};
export type DeleteJobCommentMutationVariables = {|
  input: DeleteJobCommentInput
|};
export type DeleteJobCommentMutationResponse = {|
  +deleteJobComment: ?{|
    +isDeleted: ?boolean,
    +job: ?{|
      +comments: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +created: any,
            +content: string,
            +contentEditedAt: ?any,
            +user: ?{|
              +id: string,
              +fullName: ?string,
              +representativeImageUrl: ?string,
            |},
          |}
        |}>
      |}
    |},
  |}
|};
export type DeleteJobCommentMutation = {|
  variables: DeleteJobCommentMutationVariables,
  response: DeleteJobCommentMutationResponse,
|};
*/


/*
mutation DeleteJobCommentMutation(
  $input: DeleteJobCommentInput!
) {
  deleteJobComment(input: $input) {
    isDeleted
    job {
      comments {
        edges {
          node {
            id
            created
            content
            contentEditedAt
            user {
              id
              fullName
              representativeImageUrl
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteJobCommentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDeleted",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "comments",
  "storageKey": null,
  "args": null,
  "concreteType": "CommentNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CommentNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "CommentNode",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "created",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "content",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "contentEditedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "user",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fullName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "representativeImageUrl",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteJobCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteJobComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteJobCommentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteJobCommentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteJobComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteJobCommentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteJobCommentMutation",
    "id": null,
    "text": "mutation DeleteJobCommentMutation(\n  $input: DeleteJobCommentInput!\n) {\n  deleteJobComment(input: $input) {\n    isDeleted\n    job {\n      comments {\n        edges {\n          node {\n            id\n            created\n            content\n            contentEditedAt\n            user {\n              id\n              fullName\n              representativeImageUrl\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f0383dd1b1ae373f9a474ed8c254132';
module.exports = node;

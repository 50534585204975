import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Typography, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DeactivateBatchStaffUsersMutation from '../mutations/DeactivateBatchStaffUsersMutation';
import UpdateRoleBatchStaffUsersMutation from '../mutations/UpdateRoleBatchStaffUsersMutation';
import { successToast } from '../../../../../shared/toasts';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import FormikRolesSelect from '../roleTypes/FormikRolesSelect';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '0',
    paddingBottom: theme.spacing(4)
  }
}));

const EditStaffForm = props => {
  const {
    handleClose,
    handleCloseWithRefetch,
    editingData: { action, checked },
    options
  } = props;

  const classes = useStyles();

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    if (action === options.deactivate.name) {
      DeactivateBatchStaffUsersMutation(checked, response => {
        setSubmitting(false);
        if (response && response.updatedStaffUsers) {
          handleCloseWithRefetch();
          successToast(`${checked.length} user(s) were deactivated.`);
        }
      });
    } else {
      UpdateRoleBatchStaffUsersMutation({ ids: checked, role: values.role }, response => {
        setSubmitting(false);
        if (response && response.updatedStaffUsers) {
          handleCloseWithRefetch();
          successToast(`${checked.length} user(s) roles were updated.`);
        }
      });
    }
  };
  const validate = values => {
    let errors = {};
    if (action === options.changeRole.name && !values.role) {
      errors.role = 'Required';
    }
    return errors;
  };
  const renderFormField = () => {
    switch (action) {
      case options.changeRole.name:
        return <Field required name="role" component={FormikRolesSelect} label="Role" />;
      default:
        return null;
    }
  };

  return (
    <Formik initialValues={{ role: '' }} validate={validate} onSubmit={onSubmitHandler}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              <Typography variant="body1" component="span">
                {`You are about to ${
                  action === options.deactivate.name ? 'deactivate' : 'change role of'
                } ${checked.length} user(s).`}
              </Typography>
            </DialogContentText>
            {renderFormField()}
          </DialogContent>
          <DialogActions>
            <GlobalButton variant="transparent" handleClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GlobalButton>
            <GlobalButton handleClick={submitForm} disabled={isSubmitting}>
              {action === options.deactivate.name ? 'Deactivate' : 'Submit'}
            </GlobalButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default EditStaffForm;

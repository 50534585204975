import React, { Fragment, useState, useEffect } from 'react';
import { createRefetchContainer } from 'react-relay';
import styled from 'styled-components';
import graphql from 'babel-plugin-relay/macro';
import { withStyles } from '@material-ui/styles';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import pluralize from 'pluralize';

import Dialog from '../../../shared/components/common/Dialog';
import AutoTable from '../../../shared/components/table/AutoTable';
import DeliverablesListFilters from './DeliverablesListFilters';
import EditDeliverableForm from './EditDeliverableForm';
import QuickViewDrawer from '../../../shared/components/common/QuickViewDrawer';
import DeliverablesQuickView from './DeliverablesQuickView';

import { successToast } from '../../../shared/toasts';
import withDialogProps from '../../../shared/hoc/withDialogProps';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import useDialog from '../../../shared/hooks/useDialog';
import { errorToast } from '../../../shared/toasts';
import { tableStyles } from '../../../shared/styles/common/tableStyles';
import { CELL_TYPES, DELIVERABLE_STATES, ALLOWED_ACTIONS } from '../../../shared/constants';
import {
  getActiveDeliverableDueDateWarningColor,
  computePermissionRole,
  triggerFileDownload
} from '../../../shared/utils/helpers';
import getFetchNotifications from '../../../shared/mutations/notifications/fetchNotifications';
import useInterval from '../../../shared/hooks/useInterval';
import { resolveReleaseLink, resolveProductLink } from '../../../shared/utils/linkResolvers';
import { useEffectWithStatus } from '../../../shared/hooks/useEffectWithStatus';
import BulkAddFollowersDialog from '../shared/BulkAddFollowersDialog';
import ToggleAssignFollowersForDeliverablesMutation from './mutations/ToggleAssignFollowersForDeliverablesMutation';
import AssignDeliverableInternallyForm from './AssignDeliverableInternallyForm';
import CustomTableTooltip from '../../../shared/components/table/CustomTableTooltip';
import ToggleTagsDialog from '../../../shared/components/common/ToggleTagsDialog';
import BulkToggleTagsOnDeliverablesMutation from './mutations/BulkToggleTagsOnDeliverablesMutation';
import ExportDeliverablesMutation from './mutations/ExportDeliverablesMutation';

const FollowingIcon = styled(NotificationsActiveIcon)`
  display: block;
  margin-bottom: 2px;
  color: ${props => props.theme.palette.colors.darkBlueGrey};
  font-size: 20px;
`;

const ACTION_BUTTON_OPTIONS = {
  markReady: { name: 'markReady', label: 'Mark Assignable', modalTitle: 'Mark Assignable' },
  markDraft: { name: 'markDraft', label: 'Mark as Draft', modalTitle: 'Mark as Draft' },
  markCompleted: {
    name: 'markCompleted',
    label: 'Mark Completed',
    modalTitle: 'Mark Completed'
  },
  assignStaff: {
    name: 'assignStaff',
    label: 'Set Coordinator',
    modalTitle: 'Set Coordinator'
  },
  assignInternally: {
    name: 'assignInternally',
    label: 'Assign Internally',
    modalTitle: 'Assign Internal Deliverables'
  },
  modifyAmount: { name: 'modifyAmount', label: 'Modify Amount', modalTitle: 'Change Amount' },
  modifyDate: { name: 'modifyDate', label: 'Modify Date', modalTitle: 'Change Due Date' },
  addTags: { name: 'addTags', label: 'Add Tags' },
  removeTags: { name: 'removeTags', label: 'Remove Tags' },
  addFollowers: { name: 'addFollowers', label: 'Add Followers' },
  follow: { name: 'follow', label: 'Follow' },
  unfollow: { name: 'unfollow', label: 'Unfollow' },
  delete: { name: 'delete', label: 'Delete', modalTitle: 'Delete Deliverables' },
  export: { name: 'export', label: 'Export' }
};

const DeliverableList = props => {
  const {
    refetchCounter,
    relay: { refetch },
    toRefetchData,
    deliverables: { allDeliverables },
    stateTypes,
    history,
    match,
    userContext: {
      id: userId,
      orgStaff: {
        id: orgStaffUserId,
        organization: { configuration },
        allowedActions
      },
      message
    },
    handleContextActionOptions,
    filterProps
  } = props;

  const initialEditingData = { action: '', checked: [] };
  const navigation = JSON.parse(configuration).navigation;

  const [selectedId, setSelectedId] = useState(null);
  const [resetCursorAndRefetchCounter, setResetCursorAndRefetchCounter] = useState(0);
  const [editingData, setEditingData] = useState(initialEditingData);
  const [isDialogOpen, setDialogOpen] = useDialog();
  const [notifications, setNotifications] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    if (message) {
      errorToast(message);
    }
  }, [message]);

  useEffect(() => {
    return () => {
      handleContextActionOptions();
    };
  }, []);

  useInterval(() => refetchNotifications(), 60000);

  useEffectWithStatus(status => {
    refetchNotifications(status);
  }, []);

  if (!allDeliverables) return <div>Something went wrong!</div>;

  const {
    edges,
    totalCount,
    edgeCount,
    pageInfo: { hasNextPage, endCursor }
  } = allDeliverables;

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const refetchNotifications = getFetchNotifications('deliverable', setNotifications);

  const toggleDialogOpen = () => {
    setDialogOpen(!isDialogOpen);
  };

  const onChangeHandler = variables => refetch(variables);

  const onLinkClickHandler = id => {
    history.push(`${match.url}/${id}`);
  };

  const actionClickHandler = (action, checked) => {
    if ([ACTION_BUTTON_OPTIONS.follow.name, ACTION_BUTTON_OPTIONS.unfollow.name].includes(action)) {
      const follow = action === ACTION_BUTTON_OPTIONS.follow.name;

      ToggleAssignFollowersForDeliverablesMutation({
        userIds: [orgStaffUserId],
        deliverableIds: checked,
        follow
      }).then(() => {
        const action = follow ? 'Followed' : 'Unfollowed';
        successToast(`${action} ${checked.length} ${pluralize('deliverable', checked.length)}`);
        toRefetchData();
      });
    } else if (ACTION_BUTTON_OPTIONS.export.name === action) {
      setEditingData({ action, checked });
      ExportDeliverablesMutation({
        ids: checked
      }).then((response, errors) => {
        setDownloadUrl(response.exportDeliverables.fileUrl);
        triggerFileDownload('export-link');
        successToast(`${checked.length} ${pluralize('deliverable', checked.length)} exported.`);
        toRefetchData();
      });
    } else {
      setEditingData({ action, checked });
      toggleDialogOpen();
    }
  };

  const onContractorClick = id => {
    const { history } = props;
    history.push(`/contractors/contractors/${id}`);
  };

  const closeEditingHandler = () => {
    setEditingData(initialEditingData);
    toggleDialogOpen();
  };

  const closeEditingWithRefetch = () => {
    console.log('close');
    closeEditingHandler();
    setResetCursorAndRefetchCounter(resetCursorAndRefetchCounter + 1);
  };

  const getDialogTitle = () => {
    return editingData.action ? ACTION_BUTTON_OPTIONS[editingData.action].modalTitle : '';
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      deliverableId,
      title,
      state,
      isCurrentUserFollowing,
      release: {
        name: releaseName,
        id: releaseId,
        product: { title: productName, id: productId }
      },
      category: { name: categoryName, id: categoryId },
      category,
      reviewStep,
      amount,
      currencyCode,
      amountInHomeCurrency,
      dueDate,
      assignedStaff,
      assignedContractor,
      assignedInternally,
      job
    } = edge.node;

    let newComment = false;
    if (state === DELIVERABLE_STATES.inProgress && notifications) {
      if (
        notifications.edges.find(element => Number(element.node.targetObjectId) === deliverableId)
      ) {
        newComment = true;
      }
    }

    const staff = assignedStaff
      ? {
          userId: assignedStaff.user.id,
          name: assignedStaff.fullName,
          imageUrl: assignedStaff.representativeImageUrl,
          id: assignedStaff.id
        }
      : null;

    const assignedTo = assignedContractor || assignedInternally || null;

    const assignedToObj = assignedTo
      ? {
          id: assignedTo.id,
          name: assignedTo.fullName,
          imageUrl: assignedTo.representativeImageUrl,
          withBadge: newComment,
          border: assignedContractor ? 'freelancer' : 'organization'
        }
      : null;

    return {
      node: {
        id,
        title,
        state: { code: state, reviewStep },
        releaseName: { cellText: releaseName, cellLink: resolveReleaseLink(releaseId) },
        productName: { cellText: productName, cellLink: resolveProductLink(productId) },
        categoryName,
        category,
        amount,
        isCurrentUserFollowing,
        currencyCode,
        // used for context action
        amountInHomeCurrency,
        dueDate: {
          date: dueDate,
          color: getActiveDeliverableDueDateWarningColor(configuration, dueDate, state)
        },
        staff,
        assignedTo: assignedToObj,
        categoryId,
        release: edge.node.release,
        // used for action validation
        hasJob: job ? true : false
      }
    };
  });

  const disabledActionsCheckHandler = checked => {
    const disabledItems = [];
    const areAllInDraft = checked.every(edge => edge.node.state.code === DELIVERABLE_STATES.draft);
    const areAllInReady = checked.every(edge => edge.node.state.code === DELIVERABLE_STATES.ready);
    const areAllInDraftOrReady = checked.every(edge => {
      const code = edge.node.state.code;
      return code === DELIVERABLE_STATES.draft || code === DELIVERABLE_STATES.ready;
    });
    const areAllInDraftOrInactive = checked.every(edge => {
      const code = edge.node.state.code;
      return code === DELIVERABLE_STATES.draft || code === DELIVERABLE_STATES.inactive;
    });
    const areAllAssignedToCurrentUser = checked.every(
      edge => edge.node.staff && edge.node.staff.id === userId
    );
    const areAllActive = checked.every(
      edge => edge.node.state.code !== DELIVERABLE_STATES.inactive
    );
    const areAllWithoutJobs = checked.every(edge => !edge.node.hasJob);
    const areAllInProgress = checked.every(
      edge => edge.node.state.code === DELIVERABLE_STATES.inProgress
    );
    const canDelete = computePermissionRole(ALLOWED_ACTIONS.DELIVERABLE_DELETE, allowedActions);
    const canEdit =
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_ANOTHER_USER,
        allowedActions
      ) ||
      (computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_EDIT_DETAILS_ASSIGNED_TO_AUTHENTICATED_USER,
        allowedActions
      ) &&
        areAllAssignedToCurrentUser);
    const canMarkReady =
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_MARK_READY_ASSIGNED_TO_ANOTHER_USER,
        allowedActions
      ) ||
      (computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_MARK_READY_ASSIGNED_TO_AUTHENTICATED_USER,
        allowedActions
      ) &&
        areAllAssignedToCurrentUser);
    const canMarkCompleted =
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_ANOTHER_USER,
        allowedActions
      ) ||
      (areAllAssignedToCurrentUser &&
        computePermissionRole(
          ALLOWED_ACTIONS.DELIVERABLE_MARK_COMPLETE_ASSIGNED_TO_AUTHENTICATED_USER,
          allowedActions
        ));
    const canAssignInternally =
      computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_ASSIGN_INTERNALLY_ASSIGNED_TO_ANOTHER_USER,
        allowedActions
      ) ||
      (computePermissionRole(
        ALLOWED_ACTIONS.DELIVERABLE_ASSIGN_INTERNALLY_ASSIGNED_TO_AUTHENTICATED_USER,
        allowedActions
      ) &&
        areAllAssignedToCurrentUser);

    if (!areAllInDraft || !canMarkReady) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.markReady.name);
    }

    if (!areAllInReady || !canMarkReady) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.markDraft.name);
    }

    if (!areAllInProgress || !canMarkCompleted) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.markCompleted.name);
    }

    if (!areAllInDraftOrInactive || !canDelete || !areAllWithoutJobs) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.delete.name);
    }

    if (!areAllInDraftOrReady || !canEdit) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.modifyAmount.name);
    }

    if (!canEdit || !areAllActive) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.assignStaff.name);
      disabledItems.push(ACTION_BUTTON_OPTIONS.modifyDate.name);
    }
    if (!canAssignInternally || !areAllInReady) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.assignInternally.name);
    }

    if (!computePermissionRole(ALLOWED_ACTIONS.ADD_FOLLOWERS, allowedActions)) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.addFollowers.name);
    }

    if (checked.length < 1) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.addTags.name);
      disabledItems.push(ACTION_BUTTON_OPTIONS.removeTags.name);
      disabledItems.push(ACTION_BUTTON_OPTIONS.export.name);
    }

    return disabledItems;
  };

  const checkForContextActionsHandler = (checked, refreshCheckedItems) => {
    if (checked.length) {
      const isInReadyOrDraftState = item =>
        !(
          item.node.state.code === DELIVERABLE_STATES.ready ||
          item.node.state.code === DELIVERABLE_STATES.draft
        );
      const areAllInDraftOrReadyState = checked.every(
        ({ node }, _i, arr) =>
          node.state.code === DELIVERABLE_STATES.ready ||
          node.state.code === DELIVERABLE_STATES.draft
      );
      const areInReadyState = checked.every(
        ({ node }, _i, arr) => node.state.code === DELIVERABLE_STATES.ready
      );
      const sameCategory = checked.every(
        ({ node }, _i, arr) => node.categoryId === arr[0].node.categoryId
      );
      const byCurrentUser = checked.every(({ node }) => node.staff && node.staff.userId === userId);
      let contextActionsAvailable = [];

      contextActionsAvailable.push({
        name: 'solicitationWithDeliverables',
        data: {
          checked: checked.map(item => item.node),
          disabledProps: {
            disabled: !(areInReadyState && sameCategory && byCurrentUser),
            variant: 'solicitationFromDeliverables'
          }
        }
      });
      contextActionsAvailable.push({
        name: 'jobWithDeliverables',
        data: {
          checked: checked.map(item => {
            return {
              ...item.node,
              assignedStaff: item.node.staff
                ? {
                    ...item.node.staff,
                    fullName: item.node.staff.name,
                    representativeImageUrl: item.node.staff.imageUrl
                  }
                : null,
              state: item.node.state.code
            };
          }),
          disabledProps: {
            disabled: !areAllInDraftOrReadyState,
            variant: 'job',
            errorItems: checked.filter(isInReadyOrDraftState).map(({ node }) => node.title)
          },
          refreshChecked: refreshCheckedItems
        }
      });
      handleContextActionOptions(contextActionsAvailable);
    } else {
      handleContextActionOptions();
    }
  };

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
          { name: '', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
          {
            name: 'title',
            label: 'Name',
            type: CELL_TYPES.link,
            sortable: true
          },
          {
            name: 'isCurrentUserFollowing',
            label: '',
            width: 36,
            renderContent: (_key, isFollowing) =>
              isFollowing ? (
                <CustomTableTooltip title="Following">
                  <FollowingIcon />
                </CustomTableTooltip>
              ) : (
                false
              )
          },
          {
            name: 'productName',
            label: navigation.productTitle.singular,
            type: CELL_TYPES.relationLink,
            sortable: true
          },
          {
            name: 'releaseName',
            label: navigation.releaseTitle.singular,
            type: CELL_TYPES.relationLink,
            sortable: true
          },
          {
            name: 'categoryName',
            label: 'Category',
            sortable: true
          },
          {
            name: 'dueDate',
            label: 'Due Date',
            type: CELL_TYPES.dateWithWarning,
            sortable: true,
            width: 100
          },
          {
            name: 'state',
            label: 'State',
            type: CELL_TYPES.status,
            statusTypes: stateTypes.enumValues
          },
          {
            name: 'staff',
            label: 'Coordinator',
            type: CELL_TYPES.avatar,
            onlyTooltip: true,
            align: 'center'
          },
          {
            name: 'assignedTo',
            label: 'Assignee',
            type: CELL_TYPES.avatar,
            onClick: onContractorClick,
            onlyTooltip: true,
            width: 83,
            align: 'center'
          },
          {
            name: 'amount',
            label: 'Amount',
            type: CELL_TYPES.amount,
            sortable: true
          }
        ]}
        resetCursorAndRefetchCounter={resetCursorAndRefetchCounter}
        onChangeHandler={onChangeHandler}
        edges={flattenedEdges}
        refetchCounter={refetchCounter}
        labelledByHeader="select all deliverables"
        paginationProps={{ edgeCount, totalCount, hasNextPage, endCursor }}
        selectedId={selectedId}
        checkForContextActions={checkForContextActionsHandler}
        rowProps={{
          handleLinkClick: onLinkClickHandler
        }}
        filterProps={{
          filterComponent: <DeliverablesListFilters stateOptions={stateTypes.enumValues} />,
          withApply: true,
          withSaveAsDefault: true,
          ...filterProps
        }}
        actionButtonProps={{
          options: Object.values(ACTION_BUTTON_OPTIONS),
          handleMenuItemClick: actionClickHandler,
          getDisabledActions: disabledActionsCheckHandler
        }}
        withBackground
      />
      {editingData.action === ACTION_BUTTON_OPTIONS.assignInternally.name ? (
        <AssignDeliverableInternallyForm
          deliverableIds={editingData.checked}
          isDialogOpen={isDialogOpen}
          handleClose={closeEditingHandler}
          handleCloseWithRefetch={closeEditingWithRefetch}
          modalTitle={getDialogTitle()}
        />
      ) : editingData.action === ACTION_BUTTON_OPTIONS.addFollowers.name ? (
        <BulkAddFollowersDialog
          onSubmit={({ userIds }) =>
            ToggleAssignFollowersForDeliverablesMutation({
              follow: true,
              userIds,
              deliverableIds: editingData.checked
            }).then(() => {
              closeEditingHandler();
              successToast(
                `Added ${userIds.length} ${pluralize('follower', userIds.length)} to ${
                  editingData.checked.length
                } ${pluralize('deliverable', editingData.checked.length)}`
              );
              toRefetchData();
            })
          }
          infoText={`You are about to edit ${editingData.checked.length} ${pluralize(
            'Deliverable',
            editingData.checked.length
          )}.`}
          isDialogOpen={isDialogOpen}
          closeDialog={closeEditingHandler}
        />
      ) : editingData.action === ACTION_BUTTON_OPTIONS.addTags.name ||
        editingData.action === ACTION_BUTTON_OPTIONS.removeTags.name ? (
        <ToggleTagsDialog
          isDialogOpen={isDialogOpen}
          ids={editingData.checked}
          onConfirm={values => {
            BulkToggleTagsOnDeliverablesMutation(
              {
                tags: values.tags.options.map(option => option.value),
                deliverables: editingData.checked,
                activate: editingData.action === ACTION_BUTTON_OPTIONS.addTags.name ? true : false
              },
              () => {
                closeEditingHandler();
                successToast(
                  `Deliverable ${values.tags.options.length > 1 ? 'tags' : 'tag'} succesfully ${
                    editingData.action === ACTION_BUTTON_OPTIONS.addTags.name ? 'added' : 'removed'
                  }.`
                );
                toRefetchData();
              }
            );
          }}
          closeDialog={closeEditingHandler}
          activate={editingData.action === ACTION_BUTTON_OPTIONS.addTags.name ? true : false}
          type={'Deliverable'}
        />
      ) : editingData.action === ACTION_BUTTON_OPTIONS.export.name ? (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          id={'export-link'}
          href={downloadUrl}
          target="_blank"
          download={'deliverable-export'}
          rel="noopener noreferrer"
          style={{ display: 'none' }}
        />
      ) : (
        <Dialog title={getDialogTitle()} isDialogOpen={isDialogOpen} closeDialog={toggleDialogOpen}>
          <EditDeliverableForm
            handleClose={closeEditingHandler}
            handleCloseWithRefetch={closeEditingWithRefetch}
            options={ACTION_BUTTON_OPTIONS}
            editingData={editingData}
          />
        </Dialog>
      )}
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <DeliverablesQuickView deliverableId={selectedId} configuration={configuration} />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withUserContext(
  withDialogProps(
    withStyles(tableStyles)(
      createRefetchContainer(
        DeliverableList,
        {
          deliverables: graphql`
            fragment DeliverableList_deliverables on Query {
              allDeliverables(
                first: $first
                after: $after
                orderBy: $orderBy
                includeInactive: $includeInactive
                state: $state
                title: $title
                releaseId: $releaseId
                productId: $productId
                tags: $tags
                dueDateFrom: $dueDateFrom
                dueDateTo: $dueDateTo
                staffCoordinator: $staffCoordinator
                assignedToStaffOrContractor: $assignedToStaffOrContractor
                includeClosed: $includeClosed
                includeCanceled: $includeCanceled
                category: $category
                categoryType: $categoryType
                onlyFollowed: $onlyFollowed
              ) {
                edges {
                  cursor
                  node {
                    id
                    deliverableId
                    title
                    reviewStep
                    state
                    dueDate
                    release {
                      name
                      id
                      product {
                        id
                        title
                      }
                    }
                    category {
                      id
                      name
                    }
                    amount
                    currencyCode
                    amountInHomeCurrency
                    isCurrentUserFollowing
                    assignedContractor {
                      id
                      representativeImageUrl
                      fullName
                    }
                    assignedInternally {
                      id
                      representativeImageUrl
                      fullName
                    }
                    assignedStaff {
                      id
                      fullName
                      representativeImageUrl
                      user {
                        id
                      }
                    }
                    job {
                      id
                    }
                  }
                }
                totalCount
                edgeCount
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
              }
            }
          `,
          stateTypes: graphql`
            fragment DeliverableList_stateTypes on __Type {
              enumValues {
                name
                description
              }
            }
          `
        },
        graphql`
          query DeliverableListRefetchQuery(
            $first: Int
            $after: String
            $orderBy: String
            $includeInactive: Boolean
            $state: String
            $title: String
            $releaseId: String
            $productId: String
            $tags: String
            $dueDateFrom: Date
            $dueDateTo: Date
            $staffCoordinator: String
            $assignedToStaffOrContractor: String
            $includeClosed: Boolean
            $includeCanceled: Boolean
            $category: ID
            $categoryType: ID
            $onlyFollowed: Boolean
          ) {
            ...DeliverableList_deliverables
          }
        `
      )
    )
  )
);

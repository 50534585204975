/**
 * @flow
 * @relayHash 6f145c5f3a55ca67d15541e05d01ff6d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompleteStaffAccountQueryVariables = {|
  staffToken: string,
  uidb64: string,
|};
export type CompleteStaffAccountQueryResponse = {|
  +completeAccountTokenStatus: ?boolean
|};
export type CompleteStaffAccountQuery = {|
  variables: CompleteStaffAccountQueryVariables,
  response: CompleteStaffAccountQueryResponse,
|};
*/


/*
query CompleteStaffAccountQuery(
  $staffToken: String!
  $uidb64: String!
) {
  completeAccountTokenStatus(staffToken: $staffToken, uidb64: $uidb64)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "staffToken",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "uidb64",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "completeAccountTokenStatus",
    "args": [
      {
        "kind": "Variable",
        "name": "staffToken",
        "variableName": "staffToken"
      },
      {
        "kind": "Variable",
        "name": "uidb64",
        "variableName": "uidb64"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CompleteStaffAccountQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CompleteStaffAccountQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "CompleteStaffAccountQuery",
    "id": null,
    "text": "query CompleteStaffAccountQuery(\n  $staffToken: String!\n  $uidb64: String!\n) {\n  completeAccountTokenStatus(staffToken: $staffToken, uidb64: $uidb64)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '405b259126e049f15f36057ff6f49498';
module.exports = node;

/**
 * @flow
 * @relayHash 2a48f7075048913b4e27b8f4ff8a812e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobInvoicesTab_job$ref = any;
export type JobInvoicesTabQueryVariables = {|
  id: string,
  first?: ?number,
  after?: ?string,
|};
export type JobInvoicesTabQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: JobInvoicesTab_job$ref
  |}
|};
export type JobInvoicesTabQuery = {|
  variables: JobInvoicesTabQueryVariables,
  response: JobInvoicesTabQueryResponse,
|};
*/

/*
query JobInvoicesTabQuery(
  $id: ID!
  $first: Int
  $after: String
) {
  job(id: $id) {
    ...JobInvoicesTab_job_2HEEH6
    id
  }
}

fragment JobInvoicesTab_job_2HEEH6 on JobNode {
  id
  totalValue
  currencyCode
  totalInvoicesAmount
  totalAmountOfPaymentReceived
  invoices(orderBy: "-created", first: $first, after: $after) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        identifier
        created
        status
        amount
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'after',
        type: 'String',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id'
      }
    ],
    v2 = {
      kind: 'Variable',
      name: 'after',
      variableName: 'after'
    },
    v3 = {
      kind: 'Variable',
      name: 'first',
      variableName: 'first'
    },
    v4 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'JobInvoicesTabQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            {
              kind: 'FragmentSpread',
              name: 'JobInvoicesTab_job',
              args: [(v2 /*: any*/), (v3 /*: any*/)]
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'JobInvoicesTabQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'job',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'JobNode',
          plural: false,
          selections: [
            (v4 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'totalValue',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'currencyCode',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'totalInvoicesAmount',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'totalAmountOfPaymentReceived',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'invoices',
              storageKey: null,
              args: [
                (v2 /*: any*/),
                (v3 /*: any*/),
                {
                  kind: 'Literal',
                  name: 'orderBy',
                  value: '-created'
                }
              ],
              concreteType: 'InvoiceNodeConnection',
              plural: false,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'totalCount',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'edgeCount',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'pageInfo',
                  storageKey: null,
                  args: null,
                  concreteType: 'PageInfo',
                  plural: false,
                  selections: [
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'hasNextPage',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'hasPreviousPage',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'startCursor',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'endCursor',
                      args: null,
                      storageKey: null
                    }
                  ]
                },
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'edges',
                  storageKey: null,
                  args: null,
                  concreteType: 'InvoiceNodeEdge',
                  plural: true,
                  selections: [
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'node',
                      storageKey: null,
                      args: null,
                      concreteType: 'InvoiceNode',
                      plural: false,
                      selections: [
                        (v4 /*: any*/),
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'identifier',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'created',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'status',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'amount',
                          args: null,
                          storageKey: null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'JobInvoicesTabQuery',
      id: null,
      text:
        'query JobInvoicesTabQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n) {\n  job(id: $id) {\n    ...JobInvoicesTab_job_2HEEH6\n    id\n  }\n}\n\nfragment JobInvoicesTab_job_2HEEH6 on JobNode {\n  id\n  totalValue\n  currencyCode\n  totalInvoicesAmount\n  totalAmountOfPaymentReceived\n  invoices(orderBy: "-created", first: $first, after: $after) {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        identifier\n        created\n        status\n        amount\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'c11f02f51b87fea7f7935bf738ef8cb3';
module.exports = node;

/**
 * @flow
 * @relayHash f6ff09e09507335d8074b02086180d0c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteSolicitationBatchInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type DeleteSolicitationsMutationVariables = {|
  input: DeleteSolicitationBatchInput
|};
export type DeleteSolicitationsMutationResponse = {|
  +deleteSolicitationBatch: ?{|
    +isDeleted: ?boolean
  |}
|};
export type DeleteSolicitationsMutation = {|
  variables: DeleteSolicitationsMutationVariables,
  response: DeleteSolicitationsMutationResponse,
|};
*/


/*
mutation DeleteSolicitationsMutation(
  $input: DeleteSolicitationBatchInput!
) {
  deleteSolicitationBatch(input: $input) {
    isDeleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteSolicitationBatchInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteSolicitationBatch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteSolicitationBatchPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isDeleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteSolicitationsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteSolicitationsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteSolicitationsMutation",
    "id": null,
    "text": "mutation DeleteSolicitationsMutation(\n  $input: DeleteSolicitationBatchInput!\n) {\n  deleteSolicitationBatch(input: $input) {\n    isDeleted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3abf9fd88833d636afdd6fb75c072d3a';
module.exports = node;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import Placeholder from './Placeholder';
import { useEffectWithStatus } from '../../../../hooks/useEffectWithStatus';

/**
 * This components loads and renders GLB files.
 *
 */
const GltfAsset = props => {
  const { src, centerCameraOn, flatShading, theme, handlers } = props;

  const [scene, setScene] = useState(null);

  useEffectWithStatus(status => {
    let loader = new GLTFLoader();
    loader.load(src, gltf => {
      gltf.scene.traverse(function (child) {
        if (child.isMesh) {
          child.material.flatShading = flatShading;
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      status.mounted && setScene(gltf.scene);
    });
  }, []);

  useUpdateEffect(() => {
    centerCameraOn(scene);
  }, [scene]);

  return scene ? <primitive object={scene} {...handlers} /> : <Placeholder theme={theme} />;
};

GltfAsset.propTypes = {
  src: PropTypes.string.isRequired,
  centerCameraOn: PropTypes.func.isRequired,
  flatShading: PropTypes.bool,
  handlers: PropTypes.shape({
    onPointerMove: PropTypes.func,
    onPointerLeave: PropTypes.func,
    onClick: PropTypes.func
  })
};

export default GltfAsset;

/**
 * @flow
 * @relayHash bac1823d6418bdd280b4bf4babd0c52f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobInvoiceStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "%future added value";
export type ToggleInvoicePaymentReceivedInput = {|
  id: string,
  received: boolean,
  clientMutationId?: ?string,
|};
export type ToggleInvoicePaymentReceivedMutationVariables = {|
  input: ToggleInvoicePaymentReceivedInput
|};
export type ToggleInvoicePaymentReceivedMutationResponse = {|
  +toggleInvoicePaymentReceived: ?{|
    +invoice: ?{|
      +id: string,
      +status: JobInvoiceStatus,
      +job: {|
        +id: string,
        +totalValue: number,
        +totalInvoicesAmount: ?any,
        +totalAmountOfPaymentReceived: ?any,
      |},
    |}
  |}
|};
export type ToggleInvoicePaymentReceivedMutation = {|
  variables: ToggleInvoicePaymentReceivedMutationVariables,
  response: ToggleInvoicePaymentReceivedMutationResponse,
|};
*/

/*
mutation ToggleInvoicePaymentReceivedMutation(
  $input: ToggleInvoicePaymentReceivedInput!
) {
  toggleInvoicePaymentReceived(input: $input) {
    invoice {
      id
      status
      job {
        id
        totalValue
        totalInvoicesAmount
        totalAmountOfPaymentReceived
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'ToggleInvoicePaymentReceivedInput!',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v2 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'toggleInvoicePaymentReceived',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'ToggleInvoicePaymentReceivedPayload',
        plural: false,
        selections: [
          {
            kind: 'LinkedField',
            alias: null,
            name: 'invoice',
            storageKey: null,
            args: null,
            concreteType: 'InvoiceNode',
            plural: false,
            selections: [
              (v1 /*: any*/),
              {
                kind: 'ScalarField',
                alias: null,
                name: 'status',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: null,
                name: 'job',
                storageKey: null,
                args: null,
                concreteType: 'JobNode',
                plural: false,
                selections: [
                  (v1 /*: any*/),
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'totalValue',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'totalInvoicesAmount',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'totalAmountOfPaymentReceived',
                    args: null,
                    storageKey: null
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ToggleInvoicePaymentReceivedMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v2 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'ToggleInvoicePaymentReceivedMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v2 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'ToggleInvoicePaymentReceivedMutation',
      id: null,
      text:
        'mutation ToggleInvoicePaymentReceivedMutation(\n  $input: ToggleInvoicePaymentReceivedInput!\n) {\n  toggleInvoicePaymentReceived(input: $input) {\n    invoice {\n      id\n      status\n      job {\n        id\n        totalValue\n        totalInvoicesAmount\n        totalAmountOfPaymentReceived\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'e65f1f5162eb4540704ddc01037031f3';
module.exports = node;

import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import AutoTable from '../../../../../shared/components/table/AutoTable';
import ContactsListFilters from './ContactsListFilters';
import { CELL_TYPES } from '../../../../../shared/constants';

const ContactsList = props => {
  const {
    relay: { refetch },
    contacts: { allStaff }
  } = props;

  if (!allStaff) {
    return <div>Something went wrong!</div>;
  }

  const {
    edges,
    totalCount,
    edgeCount,
    pageInfo: { hasNextPage, endCursor }
  } = allStaff;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      fullName,
      user: { email, representativeImageUrl },
      organization: { name: organizationName, representativeImageUrl: organizationImage },
      title
    } = edge.node;

    return {
      node: {
        id,
        first_name: { name: fullName, imageUrl: representativeImageUrl },
        title,
        email,
        organization: { name: organizationName, imageUrl: organizationImage }
      }
    };
  });

  return (
    <AutoTable
      rowTemplate={[
        { name: 'first_name', type: CELL_TYPES.avatar, label: 'Contact', sortable: true },
        { name: 'title', label: 'Title' },
        { name: 'email', label: 'Email' },
        {
          name: 'organization',
          label: 'Organization Name',
          type: CELL_TYPES.avatar,
          icon: 'organization'
        }
      ]}
      edges={flattenedEdges}
      onChangeHandler={onChangeHandler}
      filterProps={{
        filterComponent: <ContactsListFilters />,
        withApply: true
      }}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
    />
  );
};

export default createRefetchContainer(
  ContactsList,
  {
    contacts: graphql`
      fragment ContactsList_contacts on Query
      @argumentDefinitions(
        first: { type: "Int" }
        orderBy: { type: "String", defaultValue: "first_name" }
        after: { type: "String" }
        staffName: { type: "String" }
        organization: { type: "ID" }
      ) {
        allStaff(
          first: $first
          orderBy: $orderBy
          after: $after
          staffName: $staffName
          organization: $organization
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
          edges {
            node {
              id
              fullName
              user {
                email
                representativeImageUrl
              }
              title
              organization {
                name
                representativeImageUrl
              }
            }
          }
        }
      }
    `
  },
  graphql`
    query ContactsListRefetchQuery(
      $first: Int
      $orderBy: String
      $after: String
      $staffName: String
      $organization: ID
    ) {
      ...ContactsList_contacts
        @arguments(
          first: $first
          orderBy: $orderBy
          after: $after
          staffName: $staffName
          organization: $organization
        )
    }
  `
);

/**
 * @flow
 * @relayHash f92c67e847dabec60864c32d44f9c22b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffDetails_staff$ref = any;
export type StaffDetailsPageQueryVariables = {|
  id: string
|};
export type StaffDetailsPageQueryResponse = {|
  +staff: ?{|
    +$fragmentRefs: StaffDetails_staff$ref
  |}
|};
export type StaffDetailsPageQuery = {|
  variables: StaffDetailsPageQueryVariables,
  response: StaffDetailsPageQueryResponse,
|};
*/


/*
query StaffDetailsPageQuery(
  $id: ID!
) {
  staff(id: $id) {
    ...StaffDetails_staff
    id
  }
}

fragment StaffDetails_staff on StaffNode {
  id
  fullName
  title
  roleLabel
  isOwner
  role {
    id
    name
  }
  representativeImageUrl
  user {
    id
    email
    firstName
    lastName
    isActive
    lastLogin
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StaffDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "staff",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StaffNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "StaffDetails_staff",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StaffDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "staff",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "StaffNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fullName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "roleLabel",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isOwner",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "role",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationRoleNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "representativeImageUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isActive",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastLogin",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StaffDetailsPageQuery",
    "id": null,
    "text": "query StaffDetailsPageQuery(\n  $id: ID!\n) {\n  staff(id: $id) {\n    ...StaffDetails_staff\n    id\n  }\n}\n\nfragment StaffDetails_staff on StaffNode {\n  id\n  fullName\n  title\n  roleLabel\n  isOwner\n  role {\n    id\n    name\n  }\n  representativeImageUrl\n  user {\n    id\n    email\n    firstName\n    lastName\n    isActive\n    lastLogin\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88fe687762ea136e5648b0740cb3fa83';
module.exports = node;

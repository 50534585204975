/**
 * @flow
 * @relayHash ba2a68d49ee2ecd59ae5dad2ce354774
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type fetchNotificationsQueryVariables = {|
  unread?: ?boolean,
  targetType?: ?string,
  targetId?: ?string,
|};
export type fetchNotificationsQueryResponse = {|
  +notifications: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +targetObjectId: ?string,
        +actorObjectId: string,
      |}
    |}>
  |}
|};
export type fetchNotificationsQuery = {|
  variables: fetchNotificationsQueryVariables,
  response: fetchNotificationsQueryResponse,
|};
*/


/*
query fetchNotificationsQuery(
  $unread: Boolean
  $targetType: String
  $targetId: String
) {
  notifications(unread: $unread, targetType: $targetType, targetId: $targetId) {
    edges {
      node {
        targetObjectId
        actorObjectId
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "unread",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "targetType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "targetId",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "targetId",
    "variableName": "targetId"
  },
  {
    "kind": "Variable",
    "name": "targetType",
    "variableName": "targetType"
  },
  {
    "kind": "Variable",
    "name": "unread",
    "variableName": "unread"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "targetObjectId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actorObjectId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "fetchNotificationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notifications",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "NotificationNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NotificationNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "fetchNotificationsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notifications",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "NotificationNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NotificationNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "fetchNotificationsQuery",
    "id": null,
    "text": "query fetchNotificationsQuery(\n  $unread: Boolean\n  $targetType: String\n  $targetId: String\n) {\n  notifications(unread: $unread, targetType: $targetType, targetId: $targetId) {\n    edges {\n      node {\n        targetObjectId\n        actorObjectId\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6179eb108373117275dea38b06214f94';
module.exports = node;

import React, { useState, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from '../../../shared/images/AWEbase-Default-Light.svg';
import FormikTextField from '../../../shared/components/form/FormikTextField';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import useSignUpStyles from '../../../shared/styles/account/useSignUpStyles';
import { FullScreenContainer } from './FullScreenContainer';
import SignUpOrganizationUpdateEmailMutation from './mutations/SignUpOrganizationUpdateEmailMutation';

const VerifyEmail = props => {
  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [error, setError] = useState('');

  const { history } = props;
  const classes = useSignUpStyles();

  const initialValues = {
    email: '',
    orgToken: history.location.state ? history.location.state.orgToken : '',
    uidb64: history.location.state ? history.location.state.uidb64 : ''
  };

  const VerifyEmailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .max(200, 'Please enter no more than 200 characters.')
      .required('Required')
  });

  const handleUpdateEmail = (values, { setSubmitting, setErrors }) => {
    setError('');
    setSubmitting(true);
    const { orgToken, uidb64, email } = values;
    SignUpOrganizationUpdateEmailMutation(
      {
        orgToken,
        uidb64,
        email
      },
      (response, errors) => {
        setSubmitting(false);
        if (response && response.emailSent) {
          setUpdatedEmail(response.userEmail);
          setIsUpdatingEmail(false);
        }
        if (response && !response.emailSent) {
          setError('Email failed to send.');
        }
        if (errors) {
          setErrors(errors[0].fields);
        }
      }
    );
  };

  const renderEmail = () => {
    if (updatedEmail) {
      return updatedEmail;
    } else if (history.location.state && history.location.state.email) {
      return history.location.state.email;
    } else return 'Unknown email';
  };

  return history.location.state && history.location.state.referrer === '/signup/' ? (
    <Fragment>
      <FullScreenContainer background="grey">
        <div className={clsx(classes.logo, classes.logoContainer)}>
          <Logo />
        </div>
        <div className={classes.messageBox}>
          <Typography className={classes.textMarginBottom} variant="h1">
            {isUpdatingEmail ? 'Updating email' : 'Check your email!'}
          </Typography>
          {isUpdatingEmail ? (
            <Formik
              initialValues={initialValues}
              validationSchema={VerifyEmailValidationSchema}
              onSubmit={handleUpdateEmail}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    required
                    big
                    name="email"
                    component={FormikTextField}
                    label="Email"
                    placeholder="you@company.com"
                    type="email"
                    fullWidth
                    className={classes.signUpFormInputField}
                  />

                  <GlobalButton
                    id="updateEmail"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth={true}
                    noMargin
                    big
                  >
                    Update email
                  </GlobalButton>
                  <Typography
                    variant="body2"
                    className={error ? classes.badAlert : classes.badAlertPlaceholder}
                  >
                    {error}
                  </Typography>
                </Form>
              )}
            </Formik>
          ) : (
            <Fragment>
              <Typography className={classes.textMarginBottom}>
                We've emailed a special link to
                <span className={clsx(classes.planName, classes.textMarginLeft)}>
                  {renderEmail()}
                </span>
                . Click the link to confirm your address and get started.
              </Typography>
              <Typography>
                Wrong email? Please
                <span onClick={() => setIsUpdatingEmail(true)} className={classes.orangeLink}>
                  re-enter your address.
                </span>
              </Typography>
            </Fragment>
          )}
        </div>
      </FullScreenContainer>
    </Fragment>
  ) : (
    <Redirect to="/signup" />
  );
};

export default withRouter(VerifyEmail);

/**
 * @flow
 * @relayHash a11b4b10b1f8103e24a4fe92ab12c325
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSubscriptionDialogQueryVariables = {|
  tierName: string,
  interval: string,
|};
export type UpdateSubscriptionDialogQueryResponse = {|
  +updateSubscriptionCheck: ?{|
    +isMissingPaymentInfo: ?boolean,
    +invoicePreview: ?{|
      +total: ?number,
      +timestamp: ?string,
      +invoiceLines: ?$ReadOnlyArray<?{|
        +itemName: ?string,
        +quantity: ?number,
        +cost: ?number,
      |}>,
    |},
    +error: ?string,
    +notAllowed: ?boolean,
    +isDowngradingTier: ?boolean,
    +subscriptionChanges: ?{|
      +tier: ?string,
      +interval: ?string,
      +nextBillingDate: ?any,
      +modules: ?number,
      +activeUsers: ?number,
      +totalUsers: ?number,
    |},
  |}
|};
export type UpdateSubscriptionDialogQuery = {|
  variables: UpdateSubscriptionDialogQueryVariables,
  response: UpdateSubscriptionDialogQueryResponse,
|};
*/


/*
query UpdateSubscriptionDialogQuery(
  $tierName: String!
  $interval: String!
) {
  updateSubscriptionCheck(tierName: $tierName, interval: $interval) {
    isMissingPaymentInfo
    invoicePreview {
      total
      timestamp
      invoiceLines {
        itemName
        quantity
        cost
      }
    }
    error
    notAllowed
    isDowngradingTier
    subscriptionChanges {
      tier
      interval
      nextBillingDate
      modules
      activeUsers
      totalUsers
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tierName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "interval",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateSubscriptionCheck",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "interval",
        "variableName": "interval"
      },
      {
        "kind": "Variable",
        "name": "tierName",
        "variableName": "tierName"
      }
    ],
    "concreteType": "UpdateSubscriptionCheck",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isMissingPaymentInfo",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invoicePreview",
        "storageKey": null,
        "args": null,
        "concreteType": "InvoicePreview",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "total",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timestamp",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "invoiceLines",
            "storageKey": null,
            "args": null,
            "concreteType": "InvoiceLine",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "itemName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "quantity",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cost",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "error",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "notAllowed",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isDowngradingTier",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subscriptionChanges",
        "storageKey": null,
        "args": null,
        "concreteType": "SubscriptionInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tier",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "interval",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nextBillingDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "modules",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeUsers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalUsers",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSubscriptionDialogQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSubscriptionDialogQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "UpdateSubscriptionDialogQuery",
    "id": null,
    "text": "query UpdateSubscriptionDialogQuery(\n  $tierName: String!\n  $interval: String!\n) {\n  updateSubscriptionCheck(tierName: $tierName, interval: $interval) {\n    isMissingPaymentInfo\n    invoicePreview {\n      total\n      timestamp\n      invoiceLines {\n        itemName\n        quantity\n        cost\n      }\n    }\n    error\n    notAllowed\n    isDowngradingTier\n    subscriptionChanges {\n      tier\n      interval\n      nextBillingDate\n      modules\n      activeUsers\n      totalUsers\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd72d14d30aaaa8efe7fedcd41aa6e7e4';
module.exports = node;

/**
 * @flow
 * @relayHash e0e6e89610da10e6c1c076720ab65754
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateStaffUserInput = {|
  id: string,
  firstName?: ?string,
  lastName?: ?string,
  title?: ?string,
  reprImageNull?: ?boolean,
  role?: ?string,
  email?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateStaffUserMutationVariables = {|
  input: UpdateStaffUserInput
|};
export type UpdateStaffUserMutationResponse = {|
  +updateStaffUser: ?{|
    +updatedStaffUser: ?{|
      +fullName: ?string,
      +user: {|
        +firstName: string,
        +lastName: string,
        +email: string,
      |},
      +representativeImageUrl: ?string,
      +title: ?string,
      +roleLabel: ?string,
      +role: ?{|
        +id: string,
        +numberOfStaff: ?number,
      |},
    |},
    +oldRole: ?{|
      +id: string,
      +numberOfStaff: ?number,
    |},
    +emailFailed: ?boolean,
  |}
|};
export type UpdateStaffUserMutation = {|
  variables: UpdateStaffUserMutationVariables,
  response: UpdateStaffUserMutationResponse,
|};
*/


/*
mutation UpdateStaffUserMutation(
  $input: UpdateStaffUserInput!
) {
  updateStaffUser(input: $input) {
    updatedStaffUser {
      fullName
      user {
        firstName
        lastName
        email
        id
      }
      representativeImageUrl
      title
      roleLabel
      role {
        id
        numberOfStaff
      }
      id
    }
    oldRole {
      id
      numberOfStaff
    }
    emailFailed
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateStaffUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "roleLabel",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v10 = [
  (v9/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "numberOfStaff",
    "args": null,
    "storageKey": null
  }
],
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "role",
  "storageKey": null,
  "args": null,
  "concreteType": "OrganizationRoleNode",
  "plural": false,
  "selections": (v10/*: any*/)
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "oldRole",
  "storageKey": null,
  "args": null,
  "concreteType": "OrganizationRoleNode",
  "plural": false,
  "selections": (v10/*: any*/)
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailFailed",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateStaffUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateStaffUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStaffUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v11/*: any*/)
            ]
          },
          (v12/*: any*/),
          (v13/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateStaffUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateStaffUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStaffUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedStaffUser",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v11/*: any*/),
              (v9/*: any*/)
            ]
          },
          (v12/*: any*/),
          (v13/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateStaffUserMutation",
    "id": null,
    "text": "mutation UpdateStaffUserMutation(\n  $input: UpdateStaffUserInput!\n) {\n  updateStaffUser(input: $input) {\n    updatedStaffUser {\n      fullName\n      user {\n        firstName\n        lastName\n        email\n        id\n      }\n      representativeImageUrl\n      title\n      roleLabel\n      role {\n        id\n        numberOfStaff\n      }\n      id\n    }\n    oldRole {\n      id\n      numberOfStaff\n    }\n    emailFailed\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '354d7e5433a76c9467078e7dc09fcc25';
module.exports = node;

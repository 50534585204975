/**
 * @flow
 * @relayHash 5f4cdc3dd75d91d784cafc2d2ffc57b9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SolicitationState = "A_1" | "A_2" | "A_3" | "%future added value";
export type UpdateSolicitationCommentInput = {|
  commentId: string,
  content: string,
  clientMutationId?: ?string,
|};
export type UpdateSolicitationCommentMutationVariables = {|
  input: UpdateSolicitationCommentInput
|};
export type UpdateSolicitationCommentMutationResponse = {|
  +updateSolicitationComment: ?{|
    +isUpdated: ?boolean,
    +solicitation: ?{|
      +state: SolicitationState,
      +comments: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +content: ?string,
            +created: any,
            +contentEditedAt: ?any,
            +repliedTo: ?{|
              +user: ?{|
                +fullName: ?string
              |},
              +content: ?string,
              +created: ?any,
            |},
            +contractor: {|
              +fullName: ?string,
              +representativeImageUrl: ?string,
              +freelancer: ?{|
                +user: {|
                  +id: string
                |}
              |},
            |},
            +createdByStaff: ?{|
              +fullName: ?string,
              +representativeImageUrl: ?string,
              +user: {|
                +id: string
              |},
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type UpdateSolicitationCommentMutation = {|
  variables: UpdateSolicitationCommentMutationVariables,
  response: UpdateSolicitationCommentMutationResponse,
|};
*/


/*
mutation UpdateSolicitationCommentMutation(
  $input: UpdateSolicitationCommentInput!
) {
  updateSolicitationComment(input: $input) {
    isUpdated
    solicitation {
      state
      comments {
        edges {
          node {
            id
            content
            created
            contentEditedAt
            repliedTo {
              user {
                fullName
                id
              }
              content
              created
            }
            contractor {
              fullName
              representativeImageUrl
              freelancer {
                user {
                  id
                }
                id
              }
              id
            }
            createdByStaff {
              fullName
              representativeImageUrl
              user {
                id
              }
              id
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSolicitationCommentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isUpdated",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contentEditedAt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSolicitationCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSolicitationComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSolicitationCommentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "solicitation",
            "storageKey": null,
            "args": null,
            "concreteType": "SolicitationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "comments",
                "storageKey": null,
                "args": null,
                "concreteType": "SolicitationContractorLogNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SolicitationContractorLogNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SolicitationContractorLogNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "repliedTo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRepliedToNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/)
                                ]
                              },
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contractor",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractorNode",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "freelancer",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "FreelancerNode",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "createdByStaff",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffNode",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSolicitationCommentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSolicitationComment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSolicitationCommentPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "solicitation",
            "storageKey": null,
            "args": null,
            "concreteType": "SolicitationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "comments",
                "storageKey": null,
                "args": null,
                "concreteType": "SolicitationContractorLogNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SolicitationContractorLogNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SolicitationContractorLogNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "repliedTo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRepliedToNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v4/*: any*/)
                                ]
                              },
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contractor",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractorNode",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "freelancer",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "FreelancerNode",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v4/*: any*/)
                                ]
                              },
                              (v4/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "createdByStaff",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffNode",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateSolicitationCommentMutation",
    "id": null,
    "text": "mutation UpdateSolicitationCommentMutation(\n  $input: UpdateSolicitationCommentInput!\n) {\n  updateSolicitationComment(input: $input) {\n    isUpdated\n    solicitation {\n      state\n      comments {\n        edges {\n          node {\n            id\n            content\n            created\n            contentEditedAt\n            repliedTo {\n              user {\n                fullName\n                id\n              }\n              content\n              created\n            }\n            contractor {\n              fullName\n              representativeImageUrl\n              freelancer {\n                user {\n                  id\n                }\n                id\n              }\n              id\n            }\n            createdByStaff {\n              fullName\n              representativeImageUrl\n              user {\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '385cb8d6ebeb8e26046ad54f18c09f7e';
module.exports = node;

import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { computePermissionRole } from '../../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import JobComments from '../../../../../shared/components/job/JobComments';

const JobCommentThread = props => {
  const {
    job,
    relay: { refetch },
    userContext: { orgStaff }
  } = props;

  const canAddComment =
    job.staffCoordinator.id === orgStaff.id ||
    job.deliverableCoordinators.some(coordinator => coordinator.id === orgStaff.id) ||
    computePermissionRole(ALLOWED_ACTIONS.JOB_ADD_COMMENT, orgStaff.allowedActions);

  if (job) {
    const { id, comments } = job;
    return (
      <JobComments jobId={id} comments={comments} canAddComment={canAddComment} refetch={refetch} />
    );
  } else {
    return <div>Loading</div>;
  }
};

export default withUserContext(
  createRefetchContainer(
    JobCommentThread,
    {
      job: graphql`
        fragment JobCommentThread_job on JobNode
        @argumentDefinitions(id: { type: "ID!", defaultValue: "" }) {
          id
          staffCoordinator {
            id
          }
          deliverableCoordinators {
            id
          }
          comments {
            edges {
              node {
                id
                content
                created
                contentEditedAt
                extraData
                commentType
                repliedTo {
                  content
                  created
                  user {
                    fullName
                  }
                }
                user {
                  id
                  fullName
                  representativeImageUrl
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query JobCommentThreadRefetchQuery($id: ID!) {
        job: job(id: $id) {
          ...JobCommentThread_job @arguments(id: $id)
        }
      }
    `
  )
);

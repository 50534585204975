import React, { useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../shared/utils/helpers';

import Select from '../../../shared/components/form/fields/Select';
import withFieldProps from '../../../shared/hoc/withFieldProps';
import { useEffectWithStatus } from '../../../shared/hooks/useEffectWithStatus';

export const ContractTemplateSelect = props => {
  const { category, state, id, ...otherProps } = props;

  const [options, setOptions] = useState([]);

  useEffectWithStatus(
    status => {
      if (category) {
        fetchQuery(
          getEnvironment(),
          graphql`
            query ContractTemplateSelectQuery($state: String, $categories: [ID]) {
              contractTemplatesByOrg(state: $state, categories: $categories) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          `,
          {
            categories: [category],
            state
          }
        ).then(response => {
          status.mounted &&
            setOptions(
              response.contractTemplatesByOrg.edges.map(({ node: { id, name } }) => ({
                value: id,
                label: name
              }))
            );
        });
      }
    },
    [category]
  );

  return <Select id={id} options={options} {...otherProps} />;
};

ContractTemplateSelect.defaultProps = {
  state: '2',
  id: 'contract-template-select'
};

export const FormikContractTemplateSelect = withFieldProps(ContractTemplateSelect);

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useDetailsStyles } from '../../styles/common/useDetailsStyles';
import {
  SOLICITATION_STATES,
  JOB_STATUSES,
  DELIVERABLE_STATES,
  CONTRACT_TEMPLATE_STATES,
  ORGANIZATION_STATUS_FOR_FREELANCER,
  BILLING_ACTIVITY_STATES,
  PAYMENT_REQUESTS_STATES,
  FILE_STORAGE_CLASSES,
  JOB_INVOICE_STATES
} from '../../constants';

const StatusIndicator = ({ statusCode, reviewStep, statusTypes, variant, table, noPadding }) => {
  const classes = useDetailsStyles({ noPadding });

  const getStatusValue = () => {
    switch (variant) {
      case 'active': {
        return statusCode ? 'Active' : 'Inactive';
      }
      case 'organization': {
        return statusCode;
      }
      default:
        return statusCode === DELIVERABLE_STATES.inProgress && reviewStep
          ? reviewStep
          : statusTypes.find(status => status.name === statusCode).description;
    }
  };

  const getClassesCheck = () => {
    switch (variant) {
      case 'job': {
        return {
          [classes.colorMediumBlueGrey]: [
            JOB_STATUSES.preparing,
            JOB_STATUSES.awaiting_signature
          ].includes(statusCode),
          [classes.colorRed]: [
            JOB_STATUSES.canceled,
            JOB_STATUSES.revoked,
            JOB_STATUSES.declined
          ].includes(statusCode),
          [classes.colorOrange]: [JOB_STATUSES.in_progress].includes(statusCode),
          [classes.colorGreen]: [JOB_STATUSES.closed, JOB_STATUSES.delivered].includes(statusCode)
        };
      }
      case 'solicitation': {
        return {
          [classes.colorGreen]: statusCode === SOLICITATION_STATES.active,
          [classes.colorYellow]: statusCode === SOLICITATION_STATES.draft,
          [classes.colorRed]: statusCode === SOLICITATION_STATES.closed
        };
      }
      case 'deliverable': {
        return {
          [classes.colorRed]: [DELIVERABLE_STATES.canceled, DELIVERABLE_STATES.inactive].includes(
            statusCode
          ),
          [classes.colorMediumBlueGrey]: [
            DELIVERABLE_STATES.ready,
            DELIVERABLE_STATES.draft,
            DELIVERABLE_STATES.pendingSignature
          ].includes(statusCode),
          [classes.colorDarkBlueGrey]: [DELIVERABLE_STATES.locked].includes(statusCode),
          [classes.colorOrange]: [DELIVERABLE_STATES.inProgress].includes(statusCode),
          [classes.colorGreen]: [DELIVERABLE_STATES.closed, DELIVERABLE_STATES.completed].includes(
            statusCode
          )
        };
      }
      case 'contractTemplate': {
        return {
          [classes.colorGreen]: [CONTRACT_TEMPLATE_STATES.active].includes(statusCode),
          [classes.colorRed]: [CONTRACT_TEMPLATE_STATES.inactive].includes(statusCode),
          [classes.colorYellow]: [CONTRACT_TEMPLATE_STATES.draft].includes(statusCode)
        };
      }
      // generic type when just have a boolean statusCode true or false
      case 'active': {
        return {
          [classes.colorGreen]: statusCode,
          [classes.colorRed]: !statusCode
        };
      }
      case 'organization': {
        return {
          [classes.colorGreen]: ORGANIZATION_STATUS_FOR_FREELANCER.connected === statusCode,
          [classes.colorYellow]: ORGANIZATION_STATUS_FOR_FREELANCER.pendingInvitation === statusCode
        };
      }
      case 'billingActivity': {
        return {
          [classes.colorDefaultGrey]: BILLING_ACTIVITY_STATES.draft === statusCode,
          [classes.colorOrange]: BILLING_ACTIVITY_STATES.open === statusCode,
          [classes.colorGreen]: BILLING_ACTIVITY_STATES.paid === statusCode,
          [classes.colorRed]: BILLING_ACTIVITY_STATES.uncollectible === statusCode,
          [classes.colorDarkBlueGrey]: BILLING_ACTIVITY_STATES.void === statusCode
        };
      }
      case 'paymentRequests': {
        return {
          [classes.colorOrange]: [
            PAYMENT_REQUESTS_STATES.requested,
            PAYMENT_REQUESTS_STATES.received
          ].includes(statusCode),
          [classes.colorGreen]: [
            PAYMENT_REQUESTS_STATES.paymentSent,
            PAYMENT_REQUESTS_STATES.paid
          ].includes(statusCode),
          [classes.colorRed]: PAYMENT_REQUESTS_STATES.declined === statusCode
        };
      }
      case 'jobInvoice': {
        return {
          [classes.colorMediumBlueGrey]: JOB_INVOICE_STATES.new === statusCode,
          [classes.colorOrange]: [
            JOB_INVOICE_STATES.paymentRequested,
            JOB_INVOICE_STATES.paymentSent
          ].includes(statusCode),
          [classes.colorGreen]: JOB_INVOICE_STATES.paymentReceived === statusCode,
          [classes.colorRed]: JOB_INVOICE_STATES.declined === statusCode
        };
      }
      case 'storageClass': {
        return {
          [classes.colorGreen]: FILE_STORAGE_CLASSES.STANDARD === statusCode,
          [classes.colorOrange]: FILE_STORAGE_CLASSES.GLACIER === statusCode,
          [classes.colorDefaultGrey]: FILE_STORAGE_CLASSES.RESTORING === statusCode
        };
      }
      default:
        return {};
    }
  };

  return (
    <div
      className={clsx(getClassesCheck(), {
        [classes.stateIndicator]: !table
      })}
    >
      {getStatusValue()}
    </div>
  );
};

StatusIndicator.propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  statusTypes: PropTypes.array.isRequired,
  variant: PropTypes.oneOf([
    'job',
    'solicitation',
    'deliverable',
    'contractTemplate',
    'active',
    'organization',
    'billingActivity',
    'paymentRequests',
    'storageClass',
    'jobInvoice'
  ]).isRequired,
  // should be true if status indicator is displayed in the table cell (so it does not add extra padding/styling needed everywhere else)
  table: PropTypes.bool
};

StatusIndicator.defaultProps = {
  statusTypes: [],
  variant: 'deliverable'
};

export default StatusIndicator;

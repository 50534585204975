/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CreateInvoiceForm_job$ref: FragmentReference;
declare export opaque type CreateInvoiceForm_job$fragmentType: CreateInvoiceForm_job$ref;
export type CreateInvoiceForm_job = {|
  +id: string,
  +jobId: ?number,
  +name: string,
  +balance: ?any,
  +pendingInvoiceTotals: ?any,
  +currencyCode: ?string,
  +conversionRateInPreferredCurrency: ?any,
  +staffCoordinator: {|
    +fullName: ?string,
    +representativeImageUrl: ?string,
    +organization: {|
      +name: string
    |},
  |},
  +invoices: ?{|
    +totalCount: ?number
  |},
  +$refType: CreateInvoiceForm_job$ref,
|};
export type CreateInvoiceForm_job$data = CreateInvoiceForm_job;
export type CreateInvoiceForm_job$key = {
  +$data?: CreateInvoiceForm_job$data,
  +$fragmentRefs: CreateInvoiceForm_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CreateInvoiceForm_job",
  "type": "JobNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobId",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "balance",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pendingInvoiceTotals",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currencyCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "conversionRateInPreferredCurrency",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffCoordinator",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "representativeImageUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "OrgNode",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "invoices",
      "storageKey": null,
      "args": null,
      "concreteType": "InvoiceNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d97327fe35b66edd2e4d96970d3c028';
module.exports = node;

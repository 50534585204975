import { commitMutation } from 'react-relay';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const batchUnlockDeliverablesMutation = (deliverablesToUnlock, callback = () => {}) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation BatchUnlockDeliverablesMutation($input: UnlockDeliverableBatchForJobInput!) {
        unlockBatchDeliverableForJob(input: $input) {
          isUnlocked
          deliverables {
            id
            state
          }
        }
      }
    `,
    variables: {
      input: {
        ids: deliverablesToUnlock
      }
    },
    onCompleted: (response, errors) => {
      callback(response.unlockBatchDeliverableForJob, errors);
    }
  });
};

batchUnlockDeliverablesMutation.propTypes = {
  deliverablesToLock: PropTypes.arrayOf(PropTypes.string)
};

export default batchUnlockDeliverablesMutation;

import React from 'react';
import StyledTableCell from './StyledTableCell';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import colors from '../../styles/common/colors';

const TableCellAddUser = props => {
  const { onClick, selected } = props;
  return (
    <StyledTableCell onClick={onClick} align="left" noWrap>
      <PersonAddIcon htmlColor={selected ? colors.orange : colors.darkBlueGrey} />
    </StyledTableCell>
  );
};

export default TableCellAddUser;

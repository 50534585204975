import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';

import GlobalButton from '../UI/GlobalButton';
import useCommentFormStyles from '../../styles/comment/useCommentFormStyles';
import TextField from '../form/fields/TextField';

const CommentForm = props => {
  const { formTitleText, handleAddComment, disabled, commentInputProps } = props;
  const [commentText, setCommentText] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const classes = useCommentFormStyles();

  const onClickHandler = () => {
    setSubmitting(true);
    handleAddComment(
      {
        comment: commentText
      },
      () => {
        setSubmitting(false);
        setCommentText('');
      }
    );
  };

  return (
    <ListItem className={clsx(classes.commentFormContainer, props.className)}>
      {formTitleText && <Typography variant="h3">{formTitleText}</Typography>}
      <TextField
        name="addComment"
        value={commentText}
        disabled={disabled}
        onChange={e => setCommentText(e.target.value)}
        multiline
        rows={5}
        fullWidth
        {...commentInputProps}
      />
      <div className={classes.commentFormBottom}>
        <GlobalButton
          id="addCommentCommentForm"
          disabled={disabled || !commentText || isSubmitting}
          loading={isSubmitting}
          handleClick={onClickHandler}
          noMargin
        >
          Add Comment
        </GlobalButton>
      </div>
    </ListItem>
  );
};

CommentForm.propTypes = {
  className: PropTypes.string,
  /** Determines the title text for form UI */
  formTitleText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  disabled: PropTypes.bool,
  handleAddComment: PropTypes.func.isRequired
};

CommentForm.defaultProps = {
  formTitleText: 'General Comments',
  handleAddComment: () => {
    console.info('Add Comment CLICKED');
  }
};

export default CommentForm;

import React, { useEffect, useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { cloneDeep } from 'lodash';
import { mapReviewComments } from '../../../utils/helpers';
import ImageInterface from '../ImageCanvasInterface';
import PdfAnnotation from '../PdfAnnotation';
import CreateReviewAssetCommentMutation from '../../../mutations/comments/CreateReviewAssetCommentMutation';
import DeleteReviewAssetCommentMutation from '../../../mutations/comments/DeleteReviewAssetCommentMutation';
import AudioAnnotation from '../AudioAnnotation';
import { REVIEW_INTERFACES } from '../../../constants';
import VideoAnnotation from '../VideoCanvasInterface';
import LegacyVideoAnnotation from '../review_interfaces/LegacyVideoAnnotation';
import UpdateCommentMutation from '../../../mutations/comments/UpdateCommentMutation';
import useInterval from '../../../hooks/useInterval';
import ThreeDeeAnnotation from '../review_interfaces/ThreeDeeAnnotation';
import {
  AnnotationModalContentContainer,
  AnnotationInterfaceContainer
} from '../review_interfaces/AnnotationInterfaceContainers'; // -> move to styled
import FileProcessing from '../FileProcessing';
import {
  SidebarContainer,
  SidebarHeaderContainer,
  SidebarHeaderBackButton,
  CommentListContainer,
  EmptyCommentList
} from './styled';
import CommentCard from './CommentCard';
import ReactionsOverlay from './ReactionsOverlay';
import DeliverableDetailsHeader from '../review/DeliverableDetailsHeader';
import DetailedComment, { COMMENT_STATE } from '../review/DetailedComment';

const formatReviewAsset = reviewAssetData => {
  const reviewAsset = cloneDeep(reviewAssetData);
  reviewAsset.reviewAssetAnnotation.annotationData = JSON.parse(
    reviewAsset.reviewAssetAnnotation.annotationData
  );
  return reviewAsset;
};

const AnnotationView = props => {
  const {
    deliverable,
    assetId,
    closeModal,
    reviewInterface,
    canAddCommentInReviewTab,
    canViewMarkup,
    canAddReaction,
    reviewAsset: rawReviewAsset,
    location,
    relay
  } = props;

  const [sidebarAction, setSidebarAction] = useState(null);
  const [highlightedAnnotationRef, setHighlightedAnnotationRef] = useState(null);
  const reviewAsset = formatReviewAsset(rawReviewAsset);
  const comments = mapReviewComments(reviewAsset.comments.edges);

  useEffect(() => {
    if (!canViewMarkup) {
      closeModal();
    } else {
      // here we will have annotationRef param if coming from review tab Eye icon click
      if (location.state && location.state.annotationRef) {
        handleHighlightedAnnotationChange(location.state.annotationRef);
      }
    }
  }, []);

  useInterval(() => refetch(), 60000, assetId);

  const refetch = () => {
    relay.refetch({ reviewAssetId: assetId }, null, null, { force: true });
  };

  const onCloseClick = () => {
    closeModal();
  };

  const handleSubmitComment = ({ content, rawContent, referenceIds, repliedTo }) => {
    CreateReviewAssetCommentMutation(
      {
        assetId,
        content,
        rawContent,
        ...(referenceIds && { referenceIds }),
        ...(repliedTo && { repliedTo: repliedTo.commentId })
      },
      data => {
        if (data && data.reviewAsset) {
          setSidebarAction(null);
        }
      }
    );
  };

  const deleteCommentHandler = commentId => {
    DeleteReviewAssetCommentMutation(commentId, data => {
      if (data && data.reviewAsset) {
        setSidebarAction(null);
      }
    });
  };

  const updateCommentHandler = ({ content, rawContent, referenceIds }) => {
    const commentId = sidebarAction.commentId;
    UpdateCommentMutation(
      {
        id: commentId,
        content,
        rawContent,
        referenceIds
      },
      () => {
        setSidebarAction(val => ({ ...val, type: COMMENT_STATE.DEFAULT }));
      }
    );
  };

  const handleHighlightedAnnotationChange = (annotationRef = null) => {
    if (annotationRef) {
      const targetComment = comments.find(comment => comment.annotationRef === annotationRef);
      if (targetComment) {
        setSidebarAction({
          type: COMMENT_STATE.DEFAULT,
          commentId: targetComment.commentId
        });
      }
    } else {
      setSidebarAction(null);
    }
    setHighlightedAnnotationRef(annotationRef);
  };

  const renderReviewInterface = () => {
    const settings = reviewAsset.settings ? JSON.parse(reviewAsset.settings) : null;
    const reviewInterfaceProps = {
      assetId,
      onCloseClick,
      highlightedAnnotationRef,
      annotationData: reviewAsset.reviewAssetAnnotation.annotationData,
      handleHighlightAnnotation: handleHighlightedAnnotationChange,
      src: reviewAsset.fileReviewUrl,
      canAddMarkup: canAddCommentInReviewTab,
      sceneSettings: settings,
      sidebarAction,
      setSidebarAction
    };

    switch (reviewInterface) {
      case REVIEW_INTERFACES.image:
        return <ImageInterface {...reviewInterfaceProps} />;
      case REVIEW_INTERFACES.audio:
        return <AudioAnnotation {...reviewInterfaceProps} />;
      case REVIEW_INTERFACES.video:
        // If there is no annotationData (image interface instance data)
        // the annotations are legacy and LegacyVideoAnnotation needs to
        // be rendered
        let data = reviewInterfaceProps.annotationData;
        let isNotLegacy =
          data.length === 0 || data.some(value => value.hasOwnProperty('annotationData'));
        return isNotLegacy ? (
          <VideoAnnotation {...reviewInterfaceProps} />
        ) : (
          <LegacyVideoAnnotation {...reviewInterfaceProps} />
        );
      case REVIEW_INTERFACES.a3dModel:
        return <ThreeDeeAnnotation {...reviewInterfaceProps} />;
      case REVIEW_INTERFACES.document:
        return <PdfAnnotation {...reviewInterfaceProps} />;
      default:
        return null;
    }
  };

  const renderSidebarContent = () => {
    if (sidebarAction && sidebarAction.type) {
      const comment = comments.find(comment => comment.commentId === sidebarAction.commentId);
      return (
        <DetailedComment
          deliverableId={deliverable.id}
          comment={comment}
          state={sidebarAction.type}
          onStateChange={newType => {
            setSidebarAction(val => ({ ...val, type: newType || COMMENT_STATE.DEFAULT }));
          }}
          onAddNew={
            canAddCommentInReviewTab
              ? (values, actions) => {
                  if (sidebarAction.submitHandler) {
                    sidebarAction.submitHandler(values, actions);
                  } else {
                    handleSubmitComment(values);
                  }
                }
              : null
          }
          onReply={handleSubmitComment}
          onEdit={updateCommentHandler}
          onDelete={deleteCommentHandler}
          {...(sidebarAction &&
            sidebarAction.type === COMMENT_STATE.ADD_NEW &&
            sidebarAction.submitHandler && {
              commentFormProps: {
                actionLabels: {
                  submit: 'Add Annotation'
                }
              }
            })}
        />
      );
    } else if (comments.length > 0) {
      return comments.map(comment => (
        <CommentCard
          comment={comment}
          key={comment.commentId}
          canAddReaction={canAddReaction}
          onSelect={() => {
            setSidebarAction({
              type: COMMENT_STATE.DEFAULT,
              commentId: comment.commentId
            });
            if (comment.annotationRef) {
              setHighlightedAnnotationRef(comment.annotationRef);
            }
          }}
        />
      ));
    } else {
      return <EmptyCommentList />;
    }
  };

  const renderBackButton = () => {
    const canGoBackToComment = [COMMENT_STATE.REPLY, COMMENT_STATE.EDIT].includes(
      sidebarAction.type
    );
    return (
      <SidebarHeaderBackButton
        onClick={() => {
          if (canGoBackToComment) {
            setSidebarAction(val => ({ ...val, type: COMMENT_STATE.DEFAULT }));
          } else {
            setSidebarAction(null);
            setHighlightedAnnotationRef(null);
          }
        }}
      >
        {canGoBackToComment ? 'Back' : 'Back to comments'}
      </SidebarHeaderBackButton>
    );
  };

  return (
    <AnnotationModalContentContainer>
      <SidebarContainer>
        <SidebarHeaderContainer>
          {sidebarAction && sidebarAction.type ? (
            renderBackButton()
          ) : (
            <DeliverableDetailsHeader deliverable={deliverable} />
          )}
        </SidebarHeaderContainer>
        <CommentListContainer>{renderSidebarContent()}</CommentListContainer>
      </SidebarContainer>
      <AnnotationInterfaceContainer>
        <ReactionsOverlay canAddReaction={canAddReaction} reviewAsset={reviewAsset} />
        {!reviewAsset.fileReviewUrl ? (
          <FileProcessing handleRefetch={refetch} onCloseClick={onCloseClick} />
        ) : (
          renderReviewInterface()
        )}
      </AnnotationInterfaceContainer>
    </AnnotationModalContentContainer>
  );
};

export default createRefetchContainer(
  AnnotationView,
  {
    reviewAsset: graphql`
      fragment AnnotationView_reviewAsset on ReviewAssetNode {
        id
        fileReviewUrl
        settings
        reviewAssetAnnotation {
          annotationData
        }
        reactions {
          id
          definitionId
          name
          color
          number
          users {
            id
            fullName
            representativeImageUrl
          }
        }
        comments {
          edges {
            node {
              id
              content
              contentEditedAt
              created
              objectId
              extraData
              referenceFiles {
                id
                title
                description
                fileUrl
                reviewInterface
              }
              repliedTo {
                content
                created
                user {
                  fullName
                }
                referenceFiles {
                  id
                  title
                  description
                  fileUrl
                  reviewInterface
                }
              }
              user {
                id
                fullName
                representativeImageUrl
              }
              reactions {
                id
                definitionId
                name
                color
                number
                users {
                  id
                  fullName
                  representativeImageUrl
                }
              }
            }
          }
        }
      }
    `
  },
  graphql`
    query AnnotationViewRefetchQuery($reviewAssetId: ID!) {
      reviewAsset(id: $reviewAssetId) {
        ...AnnotationView_reviewAsset
      }
    }
  `
);

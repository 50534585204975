import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import SearchableOrganizationDropdown from '../shared/SearchableOrganizationDropdown';
import SearchableContactDropdown from '../shared/SearchableContactDropdown';
import FilterSelect from '../../../shared/components/filters/FilterSelect';
import FilterDebounceInput from '../../../shared/components/filters/FilterDebounceInput';
import DateField from '../../../shared/components/form/fields/DateField';
import withUserContext from '../../../shared/contexts/userContext/withUserContext';
import { SOLICITATION_STATES } from '../../../shared/constants';
import { useFilterStyles } from '../../../shared/styles/common/filterStyles';

const SolicitationsListFilters = props => {
  const { filterValues, stateOptions, onFilterChange } = props;
  const classes = useFilterStyles();

  const [mappedStateOptions, setMappedStateOptions] = useState([]);

  const mapStateOptions = options =>
    options.map(option => ({
      value: option.name,
      name: option.description
    }));

  useEffect(() => {
    const newStateOptions = stateOptions.filter(
      option => option.name !== SOLICITATION_STATES.draft
    );
    setMappedStateOptions(mapStateOptions(newStateOptions));
  }, []);

  return (
    <Grid container spacing={3} classes={{ root: classes.filterRoot }}>
      <Grid item xs={2}>
        <FilterDebounceInput
          id="filterBySubject"
          value={filterValues['subject']}
          onChange={onFilterChange}
          name="subject"
          label="Subject"
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableOrganizationDropdown
          id="filterByOrganizationInSolicitations"
          name="organization"
          value={filterValues['organization']}
          onChange={value => onFilterChange('organization', value)}
        />
      </Grid>
      <Grid item xs={2}>
        <SearchableContactDropdown
          id="filterByStaffInSolicitations"
          name="staffCoordinator"
          value={filterValues['staffCoordinator']}
          onChange={value => onFilterChange('staffCoordinator', value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={3}>
          <Grid item xs={6} classes={{ item: classes.dueDateFrom }}>
            <DateField
              id="date_received_from"
              name="dateReceivedFrom"
              label="Date Received From:"
              value={filterValues['dateReceivedFrom']}
              placeholder="From..."
              onChange={onFilterChange}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              id="date_received_to"
              name="dateReceivedTo"
              label="To:"
              value={filterValues['dateReceivedTo']}
              placeholder="To..."
              onChange={onFilterChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={3}>
          <Grid item xs={6} classes={{ item: classes.dueDateFrom }}>
            <DateField
              id="reply_by_from"
              name="replyByFrom"
              label="Reply By From:"
              value={filterValues['replyByFrom']}
              placeholder="From..."
              onChange={onFilterChange}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              id="reply_by_to"
              name="replyByTo"
              label="To:"
              value={filterValues['replyByTo']}
              placeholder="To..."
              onChange={onFilterChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <FilterSelect
          id="filterBySolicitationstate"
          name="state"
          onChange={onFilterChange}
          value={filterValues['state']}
          label="State"
          options={mappedStateOptions}
        />
      </Grid>
    </Grid>
  );
};

export default withUserContext(SolicitationsListFilters);

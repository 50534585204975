import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import GlobalButton from '../UI/GlobalButton';

const useStyles = () => ({
  containerFlex: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  }
});

// NOTE:
// Legacy, use ConfirmationDialogV2 instead
class ConfirmationDialog extends React.Component {
  state = {
    open: false,
    loading: false,
    callback: null
  };
  show = callback => (event, ...restProps) => {
    if (event) {
      event.preventDefault();
      event = {
        ...event,
        target: { ...event.target, value: event.target.value }
      };
      this.setState({
        open: true,
        callback: () => callback(event, ...restProps)
      });
    } else {
      this.setState({
        open: true,
        callback
      });
    }
  };
  hide = () => {
    this.setState({
      open: false,
      callback: null
    });
    if (this.props.onClose) this.props.onClose();
  };
  confirm = async () => {
    this.setState({ loading: true });
    let result = null;
    if (!this.props.onSubmit) {
      result = await this.state.callback();
    } else {
      result = await this.props.onSubmit();
    }
    this.setState({ loading: false });
    this.hide();
    return result;
  };

  render() {
    const { classes } = this.props;
    const { title, content } = this.props;
    const copies = {
      cancelCopy: 'Cancel',
      confirmCopy: 'Continue',
      ...this.props.copies
    };

    return (
      <React.Fragment>
        {this.props.children(this.show)}
        <Dialog isDialogOpen={this.state.open} title={title} closeDialog={this.hide} noButton>
          {this.state.loading && 'Loading...'}
          {typeof content === 'string' ? (
            <Typography variant="body1">{content}</Typography>
          ) : (
            content
          )}

          <div className={classes.containerFlex}>
            <GlobalButton
              id="cancelConfirmationDialog"
              handleClick={this.hide}
              variant="transparent"
            >
              {copies.cancelCopy}
            </GlobalButton>
            <GlobalButton
              id="continueConfirmationDialog"
              handleClick={this.confirm}
              variant="primary"
            >
              {copies.confirmCopy}
            </GlobalButton>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  copies: PropTypes.shape({
    cancelCopy: PropTypes.string,
    confirmCopy: PropTypes.string
  })
};

export default withStyles(useStyles)(ConfirmationDialog);

import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import StyledTableCell from '../../../../../shared/components/table/StyledTableCell';

export const ColorBadge = styled(({ color, ...otherProps }) => <Box {...otherProps} />)(
  ({ theme, color }) => ({
    width: 12,
    height: 12,
    backgroundColor: color,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    flexShrink: 0
  })
);

export const TableCellWithBadge = ({ color, name, link, ...otherProps }) => (
  <StyledTableCell {...otherProps}>
    <Box display="inline-flex" alignItems="center">
      <ColorBadge color={color} /> {link ? <Link to={link}>{name}</Link> : name}
    </Box>
  </StyledTableCell>
);

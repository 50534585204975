/**
 * @flow
 * @relayHash 8ff8202f8237e7183d36ed33f0f4d4d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrganizationQuickViewQueryVariables = {|
  id: string
|};
export type OrganizationQuickViewQueryResponse = {|
  +organization: ?{|
    +representativeImageUrl: ?string,
    +name: string,
    +email: string,
    +phone: string,
    +country: ?{|
      +name: ?string
    |},
    +timezoneFormat: string,
    +jobs: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +jobId: ?number,
          +contract: ?{|
            +dateSignedByOrganization: ?any
          |},
        |}
      |}>
    |},
    +orgStaffs: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fullName: ?string,
          +user: {|
            +id: string,
            +representativeImageUrl: ?string,
            +email: string,
          |},
          +title: ?string,
        |}
      |}>
    |},
  |}
|};
export type OrganizationQuickViewQuery = {|
  variables: OrganizationQuickViewQueryVariables,
  response: OrganizationQuickViewQueryResponse,
|};
*/


/*
query OrganizationQuickViewQuery(
  $id: ID!
) {
  organization(id: $id) {
    representativeImageUrl
    name
    email
    phone
    country {
      name
    }
    timezoneFormat
    jobs {
      edges {
        node {
          id
          jobId
          contract {
            dateSignedByOrganization
            id
          }
        }
      }
    }
    orgStaffs {
      edges {
        node {
          id
          fullName
          user {
            id
            representativeImageUrl
            email
          }
          title
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "country",
  "storageKey": null,
  "args": null,
  "concreteType": "Country",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timezoneFormat",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "jobId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateSignedByOrganization",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "orgStaffs",
  "storageKey": null,
  "args": null,
  "concreteType": "StaffNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffNode",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "fullName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "user",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v8/*: any*/),
                (v2/*: any*/),
                (v4/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrganizationQuickViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrgNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "jobs",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "JobNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobNode",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractNode",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v11/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrganizationQuickViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrgNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "jobs",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "JobNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobNode",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractNode",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v8/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v11/*: any*/),
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrganizationQuickViewQuery",
    "id": null,
    "text": "query OrganizationQuickViewQuery(\n  $id: ID!\n) {\n  organization(id: $id) {\n    representativeImageUrl\n    name\n    email\n    phone\n    country {\n      name\n    }\n    timezoneFormat\n    jobs {\n      edges {\n        node {\n          id\n          jobId\n          contract {\n            dateSignedByOrganization\n            id\n          }\n        }\n      }\n    }\n    orgStaffs {\n      edges {\n        node {\n          id\n          fullName\n          user {\n            id\n            representativeImageUrl\n            email\n          }\n          title\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b1eb0a796b62725821403af1f6a797a0';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import SolicitationContractorsList from './SolicitationContractorsList';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';

const SolicitationContractorsTab = props => {
  const { id } = useParams();
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query SolicitationContractorsTabQuery(
          $id: ID!
          $first: Int
          $after: String
          $orderBy: String
          $contractorName: String
          $includeInactive: Boolean
          $includeDoNotHire: Boolean
          $inviteStatus: String
        ) {
          solicitation(id: $id) {
            ...SolicitationContractorsList_solicitation
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <SolicitationContractorsList solicitation={relayProps.solicitation} {...props} />
      )}
    />
  );
};

export default withTableHistoryProvider(SolicitationContractorsTab, 'orgSolicitationContractors', {
  orderBy: 'newest_comment',
  initialFilters: {
    contractorName: '',
    includeDoNotHire: false,
    includeInactive: false,
    inviteStatus: ''
  }
});

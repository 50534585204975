import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';

import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import DetailsContent from './DetailsContent';

const DeliverableDetailsTab = props => {
  const { id } = useParams();
  return (
    <QueryRenderer
      query={graphql`
        query DeliverableDetailsTabQuery($id: ID!) {
          deliverable(id: $id) {
            ...DetailsInfo_deliverable
            ...DetailsContent_deliverable
            ...DetailsDescription_deliverable
            ...DetailsNotes_deliverable
            ...DetailsSolicitations_deliverable
            ...DetailsMainSection_deliverable
          }
          deliverableStateTypes: __type(name: "DeliverableState") {
            ...DetailsInfo_deliverableStateTypes
          }
          solicitationState: __type(name: "SolicitationState") {
            ...DetailsSolicitations_solicitationStateTypes
          }
        }
      `}
      variables={{ id }}
      render={relayProps => (
        <DetailsContent
          deliverable={relayProps.deliverable}
          deliverableStateTypes={relayProps.deliverableStateTypes}
          relayProps={relayProps}
          {...props}
        />
      )}
    />
  );
};

export default DeliverableDetailsTab;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';
import NotificationSettingsContent from './NotificationSettingsContent';

const NotificationSettingsTab = props => {
  return (
    <QueryRenderer
      query={graphql`
        query NotificationSettingsTabQuery {
          ...NotificationSettingsContent_notificationSettings
          __type(name: "StaffAppConfigurationNotificationSummaryInterval") {
            ...NotificationSettingsContent_intervalValues
          }
        }
      `}
      variables={{}}
      render={relayProps => {
        return (
          <NotificationSettingsContent
            notificationSettings={relayProps}
            intervalValues={relayProps.__type}
            {...props}
          />
        );
      }}
    />
  );
};

export default NotificationSettingsTab;

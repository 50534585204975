import { commitMutation } from 'react-relay';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const UpdateDeliverableReviewStepMutation = (deliverableReviewId, stepId, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateDeliverableReviewStepMutation($input: UpdateDeliverableReviewStepInput!) {
        updateDeliverableReviewStep(input: $input) {
          updatedDeliverableReview {
            deliverable {
              reviewStep
              reviewActive {
                id
                step {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: {
        deliverableReview: deliverableReviewId,
        step: stepId
      }
    },
    onCompleted: (response, errors) => {
      callback(response, errors);
    }
  });
};

UpdateDeliverableReviewStepMutation.propTypes = {
  deliverableReviewId: PropTypes.string.isRequired,
  stepId: PropTypes.string.isRequired,
  callback: PropTypes.func
};

export default UpdateDeliverableReviewStepMutation;

import React from 'react';

import StyledTableCell from './StyledTableCell';
import SquareButton from '../UI/SquareButton';

const TableCellDuplicate = props => {
  const { onClick, hidden } = props;

  const squareButtonVariables = {
    icon: 'duplicate',
    variant: 'transparent',
    tooltipTitle: 'Duplicate'
  };

  return (
    <StyledTableCell noWrap>
      {!hidden && <SquareButton {...squareButtonVariables} onClick={onClick} />}
    </StyledTableCell>
  );
};

export default TableCellDuplicate;

/**
 * @flow
 * @relayHash 03774dbb5fe2c500c69dbc2b0da6643b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type MarkReadyBatchDeliverableInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type BatchMarkDeliverableReadyMutationVariables = {|
  input: MarkReadyBatchDeliverableInput
|};
export type BatchMarkDeliverableReadyMutationResponse = {|
  +markReadyBatchDeliverable: ?{|
    +updatedDeliverables: ?$ReadOnlyArray<?{|
      +id: string,
      +state: DeliverableState,
    |}>
  |}
|};
export type BatchMarkDeliverableReadyMutation = {|
  variables: BatchMarkDeliverableReadyMutationVariables,
  response: BatchMarkDeliverableReadyMutationResponse,
|};
*/


/*
mutation BatchMarkDeliverableReadyMutation(
  $input: MarkReadyBatchDeliverableInput!
) {
  markReadyBatchDeliverable(input: $input) {
    updatedDeliverables {
      id
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkReadyBatchDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "markReadyBatchDeliverable",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkReadyBatchDeliverablePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedDeliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BatchMarkDeliverableReadyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BatchMarkDeliverableReadyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BatchMarkDeliverableReadyMutation",
    "id": null,
    "text": "mutation BatchMarkDeliverableReadyMutation(\n  $input: MarkReadyBatchDeliverableInput!\n) {\n  markReadyBatchDeliverable(input: $input) {\n    updatedDeliverables {\n      id\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3002a346c2d3c1862a62a3c09ce8ebac';
module.exports = node;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation AddSolicitationContractorsMutation($input: AddSolicitationContractorsInput!) {
    addSolicitationContractors(input: $input) {
      isUpdated
      solicitation {
        id
        numberOfContractors
      }
    }
  }
`;

const AddSolicitationContractorsMutation = (solicitationId, contractorIds, callback) => {
  const addSolicitationContractorsVariables = {
    input: { solicitationId, contractorIds }
  };

  const config = {
    mutation,
    variables: addSolicitationContractorsVariables,
    onCompleted: response => {
      callback(response.addSolicitationContractors);
    }
  };

  commitMutation(getEnvironment(), config);
};

AddSolicitationContractorsMutation.propTypes = {
  solicitationId: PropTypes.string.isRequired,
  contractorIds: (props, _propName, componentName) => {
    if (!props.contractorIds && !props.contractorGroupId) {
      return new Error(
        `One of props 'contractorIds' or 'contractorGroupId' was not specified in '${componentName}'.`
      );
    }
    if (props.contractorIds) {
      PropTypes.checkPropTypes(
        {
          contractorIds: PropTypes.arrayOf(PropTypes.string)
        },
        { contractorIds: props.contractorIds },
        'prop',
        componentName
      );
    }
    return null;
  }
};

export default AddSolicitationContractorsMutation;

/**
 * @flow
 * @relayHash ab123672acd7d7fac47ea6a5762321da
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GroupsList_general$ref = any;
export type GroupsListRefetchQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
|};
export type GroupsListRefetchQueryResponse = {|
  +$fragmentRefs: GroupsList_general$ref
|};
export type GroupsListRefetchQuery = {|
  variables: GroupsListRefetchQueryVariables,
  response: GroupsListRefetchQueryResponse,
|};
*/


/*
query GroupsListRefetchQuery(
  $first: Int
  $orderBy: String
  $after: String
) {
  ...GroupsList_general
}

fragment GroupsList_general on Query {
  general {
    contractorGroups(first: $first, orderBy: $orderBy, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          id
          isPublic
          name
          numberOfMembers
          created
          createdBy {
            user {
              id
            }
            id
          }
          contractors {
            edges {
              node {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GroupsListRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "GroupsList_general",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GroupsListRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "general",
        "storageKey": null,
        "args": null,
        "concreteType": "General",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractorGroups",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              }
            ],
            "concreteType": "ContractorGroupNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "edgeCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractorGroupNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractorGroupNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isPublic",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numberOfMembers",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "created",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/)
                            ]
                          },
                          (v1/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contractors",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractorNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractorNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ContractorNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "fullName",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GroupsListRefetchQuery",
    "id": null,
    "text": "query GroupsListRefetchQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n) {\n  ...GroupsList_general\n}\n\nfragment GroupsList_general on Query {\n  general {\n    contractorGroups(first: $first, orderBy: $orderBy, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edgeCount\n      edges {\n        node {\n          id\n          isPublic\n          name\n          numberOfMembers\n          created\n          createdBy {\n            user {\n              id\n            }\n            id\n          }\n          contractors {\n            edges {\n              node {\n                id\n                fullName\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd61104e4be8ca19938f9de3e8853581b';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractorDeliverablesTopSection_contractor$ref: FragmentReference;
declare export opaque type ContractorDeliverablesTopSection_contractor$fragmentType: ContractorDeliverablesTopSection_contractor$ref;
export type ContractorDeliverablesTopSection_contractor = {|
  +activeDeliverablesCount: ?number,
  +pastDueDeliverablesCount: ?number,
  +completedDeliverablesCount: ?number,
  +$refType: ContractorDeliverablesTopSection_contractor$ref,
|};
export type ContractorDeliverablesTopSection_contractor$data = ContractorDeliverablesTopSection_contractor;
export type ContractorDeliverablesTopSection_contractor$key = {
  +$data?: ContractorDeliverablesTopSection_contractor$data,
  +$fragmentRefs: ContractorDeliverablesTopSection_contractor$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ContractorDeliverablesTopSection_contractor",
  "type": "ContractorNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "activeDeliverablesCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pastDueDeliverablesCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "completedDeliverablesCount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6affcfbd3860ab4defb984b4637e0c27';
module.exports = node;

import React from 'react';
import * as Yup from 'yup';
import graphql from 'babel-plugin-relay/macro';
import { Grid, Typography } from '@material-ui/core';
import { createFragmentContainer } from 'react-relay';
import { Formik, Form, Field } from 'formik';

import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import { yupSchemas } from '../../../../../shared/validations';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import AvatarWithName from '../../../../../shared/components/UI/AvatarWithName';
import DetailsRowAmount from '../../../../../shared/components/UI/DetailsRowAmount';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import StatusIndicator from '../../../../../shared/components/UI/StatusIndicator';
import UpdateJobMutation from '../../mutations/UpdateJobMutation';
import { ALLOWED_ACTIONS, JOB_STATUSES } from '../../../../../shared/constants';
import { borderLeft } from '../../../../../shared/styles/borders';
import JobCommentThread from './JobCommentThread';
import { resolveContractorLink } from '../../../../../shared/utils/linkResolvers';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';

const JobDetailsTab = props => {
  const {
    isEditing,
    toggleEditHandler,
    job: {
      id,
      contractSignDeadline,
      contractor,
      staffCoordinator,
      jobId,
      name,
      description,
      totalValue,
      currencyCode,
      created,
      contractDate,
      deliverableCategory,
      totalValueInHomeCurrency,
      status,
      contract
    },
    stateTypes,
    userContext: {
      orgStaff: { allowedActions }
    }
  } = props;

  const initialFormikValues = {
    name,
    description
  };

  const validationSchema = Yup.object().shape({
    name: yupSchemas.string(true),
    description: yupSchemas.string(false, { max: 5000 })
  });

  const submitHandler = values => {
    const { name, description } = values;
    UpdateJobMutation(
      {
        id,
        name,
        description
      },
      response => {
        if (response) {
          toggleEditHandler();
        }
      }
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <Formik
          initialValues={initialFormikValues}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
        >
          <Form>
            <DetailsSection
              title="Info"
              renderRight={
                computePermissionRole(ALLOWED_ACTIONS.JOB_EDIT_DETAILS, allowedActions)
                  ? () => (
                      <EditToggle
                        isEditing={isEditing}
                        editProps={{
                          id: 'editJobDetails',
                          onClick: toggleEditHandler,
                          disabled: false
                        }}
                        saveProps={{
                          id: 'saveJobDetails'
                        }}
                        cancelProps={{
                          id: 'cancelJobDetails',
                          onClick: toggleEditHandler
                        }}
                      />
                    )
                  : null
              }
            >
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  {isEditing ? (
                    <Field
                      required
                      name="name"
                      label="Name"
                      placeholder="Enter a Job name..."
                      component={FormikTextField}
                      fullWidth
                    />
                  ) : (
                    <DetailsRow title="Name" content={name} titleWidth={132} noMarginTop />
                  )}
                  <DetailsRow
                    title="Status"
                    content={
                      <StatusIndicator
                        table
                        statusTypes={stateTypes.enumValues}
                        statusCode={status}
                        variant="job"
                      />
                    }
                    titleWidth={132}
                  />

                  <DetailsRow
                    link={resolveContractorLink(contractor.id)}
                    title="Contractor"
                    content={
                      <AvatarWithName
                        name={contractor.fullName}
                        avatarProps={{
                          src: contractor.representativeImageUrl
                        }}
                      />
                    }
                    titleWidth={132}
                  />
                  <DetailsRow
                    title="Job Coordinator"
                    content={
                      <AvatarWithName
                        name={staffCoordinator.fullName}
                        avatarProps={{
                          src: staffCoordinator.representativeImageUrl
                        }}
                      />
                    }
                    titleWidth={132}
                  />
                  <DetailsRow
                    title="Deliverable Category"
                    content={deliverableCategory ? deliverableCategory.name : '-'}
                    titleWidth={132}
                  />
                </Grid>
                <Grid item xs={5}>
                  <DetailsRow noMarginTop title="ID" content={jobId} titleWidth={132} />

                  <DetailsRowDate date={created} title="Job Creation Date" titleWidth={132} />

                  <DetailsRowDate
                    title="Signature Deadline"
                    date={contractSignDeadline}
                    titleWidth={132}
                  />
                  <DetailsRowDate title="Contract Date" date={contractDate} titleWidth={132} />
                  <DetailsRowAmount
                    title="Total Job Amount"
                    amount={totalValue}
                    currencyCode={currencyCode}
                    subscriptAmount={totalValueInHomeCurrency}
                    titleWidth={132}
                  />
                </Grid>
              </Grid>
            </DetailsSection>
            <DetailsSection title="Description" noBorder>
              {isEditing && (contract ? status === JOB_STATUSES.preparing : true) ? (
                <Field
                  name="description"
                  label="Description"
                  placeholder="Job description"
                  component={FormikTextField}
                  multiline
                  fullWidth
                />
              ) : (
                <Typography variant="body1">{description || 'No description added.'}</Typography>
              )}
            </DetailsSection>
          </Form>
        </Formik>
      </Grid>
      <Grid item xs={3} style={borderLeft}>
        <JobCommentThread job={props.relayProps.job} jobId={id} />
      </Grid>
    </Grid>
  );
};

export default withUserContext(
  createFragmentContainer(JobDetailsTab, {
    job: graphql`
      fragment JobDetailsTab_job on JobNode {
        id
        contractSignDeadline
        contractor {
          id
          fullName
          representativeImageUrl
        }
        staffCoordinator {
          fullName
          representativeImageUrl
        }
        contract {
          id
        }
        jobId
        name
        description
        created
        totalValue
        currencyCode
        contractDate
        totalValueInHomeCurrency
        status
        deliverableCategory {
          name
        }
      }
    `
  })
);

import React from 'react';
import PropTypes from 'prop-types';
import styledComponent from 'styled-components';
import { Typography, Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const DetailsSectionContainer = styled(({ noBorder, ...otherProps }) => <Box {...otherProps} />)(
  ({ noBorder, theme }) => ({
    borderBottom: noBorder ? '' : `1px solid ${theme.palette.colors.lightestGrey}`,
    marginBottom: theme.spacing(3),
    flexGrow: 1
  })
);

const DetailsSectionHeader = styled(({ centered, ...otherProps }) => <Box {...otherProps} />)(
  ({ centered }) => ({
    display: 'flex',
    justifyContent: centered ? 'center' : 'space-between',
    alignItems: 'center',
    position: 'relative'
  })
);

const DetailsSectionContent = styled(({ centered, ...otherProps }) => <Box {...otherProps} />)(
  ({ centered, theme }) => ({
    padding: `${theme.spacing(3)}px 0`,
    ...(centered && {
      display: 'flex',
      justifyContent: 'center'
    })
  })
);

const DetailsSection = ({
  title,
  children,
  renderRight,
  noBorder,
  centered,
  contentBoxProps,
  ...rest
}) => (
  <DetailsSectionContainer noBorder={noBorder} {...rest}>
    <DetailsSectionHeader centered={centered}>
      {title && <Typography variant="h3">{title}</Typography>}
      {renderRight && (
        <Box position="absolute" right={0}>
          {renderRight()}
        </Box>
      )}
    </DetailsSectionHeader>
    <DetailsSectionContent centered={centered} {...contentBoxProps}>
      {children}
    </DetailsSectionContent>
  </DetailsSectionContainer>
);

DetailsSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  noBorder: PropTypes.bool,
  centered: PropTypes.bool,
  renderRight: PropTypes.func,
  contentBoxProps: PropTypes.object
};

DetailsSection.defaultProps = {
  noBorder: false,
  centered: false
};

export default styledComponent(DetailsSection)``;

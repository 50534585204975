import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import ReleaseList from './ReleaseList';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';

const ReleaseListPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query ReleaseListPageQuery(
          $after: String
          $first: Int
          $releaseName: String
          $releaseCode: String
          $productName: String
          $productCode: String
          $deadlineFrom: Date
          $deadlineTo: Date
          $orderBy: String
          $includeInactive: Boolean
        ) {
          ...ReleaseList_releases
        }
      `}
      variables={variables}
      render={relayProps => <ReleaseList releases={relayProps} {...props} />}
    />
  );
};
export default withTableHistoryProvider(ReleaseListPage, 'orgReleasesList', {
  orderBy: 'name',
  initialFilters: {
    releaseName: '',
    releaseCode: '',
    productName: '',
    productCode: '',
    deadlineFrom: null,
    deadlineTo: null,
    includeInactive: false
  }
});

import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Icon, Typography } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { GoogleLogin } from 'react-google-login';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as Logo } from '../../images/AWEbase-Default-Light.svg';
import { ORGANIZATION_APP, FREELANCER_APP } from '../../services/constants';
import loginStyles from '../../styles/account/login';
import GlobalButton from '../UI/GlobalButton';
import FormikTextField from '../form/FormikTextField';
import withAppType from '../../hoc/withAppType';
import authService from '../../services/authService';
import Detail from '../UI/Detail';

const Login = props => {
  const [redirectToLink, setRedirectToLink] = useState(null);
  const [showPasswordField, setShowPasswordField] = useState(!props.isOrganization);
  const [idpLink, setIdpLink] = useState(null);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(authService._getRememberMeEmail() !== null);
  const { classes, isOrganization } = props;

  if (redirectToLink) {
    return (
      <Redirect
        to={{
          pathname: redirectToLink,
          search: ''
        }}
      />
    );
  }

  const handleValidate = values => {
    setError('');
    const emailRequirements = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors = {};
    if (emailRequirements.test(values.email) === false) {
      errors.email = 'Please enter a valid email address.';
    }
    if (!values.password && showPasswordField) {
      errors.password = 'Please enter your password.';
    }
    return errors;
  };

  const _confirm = (values, actions) => {
    // if user is trying to log in in a separate tab as a different user this will log him out of other tabs
    authService._removeTokens();
    if (!values.password) {
      authService.signInEmail(
        values.email,
        (passwordRequired, idpLink) => {
          actions.setSubmitting(false);
          if (idpLink && !passwordRequired) {
            //   only link available, redirecting directly
            window.location = idpLink;
          } else if (idpLink && passwordRequired) {
            setShowPasswordField(true);
            setIdpLink(idpLink);
          } else if (!idpLink && passwordRequired) {
            setShowPasswordField(true);
          } else {
            setError('Something went wrong. Try again.');
          }
        },
        rememberMe
      );
    } else {
      // log in regularly if user chooses to enter password
      authService.signIn(
        values.email,
        values.password,
        (success, error) => {
          actions.setSubmitting(false);
          if (success) {
            setRedirectToLink('/');
          } else {
            setError(error);
          }
        },
        rememberMe
      );
    }
  };

  const _responseGoogleSuccess = response => {
    // if user is trying to log in in a separate tab as a different user this will log him out of other tabs
    authService._removeTokens();
    authService.signInGoogle(response.accessToken, success => {
      if (success) {
        setRedirectToLink('/');
      } else {
        setError('Wrong email address. Try again.');
      }
    });
  };

  const _responseGoogleFailure = response => {
    // This is displayed when google api does not return authenticated response
    // or when user closes pop up before successfully authenticated
    console.error(response);
    setError('Authentication failed. Try again.');
  };

  const initialValues = {
    email: '',
    password: ''
  };

  if (rememberMe) {
    initialValues.email = authService._getRememberMeEmail();
  }

  return (
    <div className={classes.mainLogin}>
      <div className={classes.logo}>
        <Logo />
      </div>
      <div className={classes.loginContent}>
        <Formik initialValues={initialValues} validate={handleValidate} onSubmit={_confirm}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={`${classes.loginForm} ${classes.noMarginTop}`}>
              {(!showPasswordField || !isOrganization) && (
                <Field
                  required
                  name="email"
                  component={FormikTextField}
                  label="Email Address"
                  placeholder="Enter your email address..."
                  type="email"
                  fullWidth
                  className={classes.loginFormInputField}
                />
              )}

              {showPasswordField && (
                <>
                  {isOrganization && (
                    <Detail
                      name="Email Address"
                      value={values.email}
                      flexDirection="column"
                      alignItems="left"
                      valueContainerProps={{ variant: 'body1' }}
                      style={{ marginBottom: '10px' }}
                    />
                  )}
                  <Field
                    required
                    name="password"
                    component={FormikTextField}
                    label="Password"
                    placeholder="Enter your password..."
                    type="password"
                    fullWidth
                    className={classes.loginFormInputField}
                  />
                </>
              )}

              <div className={classes.loginExtra}>
                {!showPasswordField || !isOrganization ? (
                  <FormControlLabel
                    className={classes.rememberCheck}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon classes={{ root: classes.rememberUnchecked }} />
                        }
                        checkedIcon={<CheckBoxIcon classes={{ root: classes.rememberChecked }} />}
                        checked={rememberMe}
                        onChange={e => setRememberMe(e.target.checked)}
                        value="remember"
                      />
                    }
                    label="Remember me"
                  />
                ) : (
                  <Typography
                    variant="body2"
                    onClick={() => {
                      setShowPasswordField(false);
                      setFieldValue('password', '');
                    }}
                  >
                    <Icon className={'fas fa-chevron-left'} />
                    Go back
                  </Typography>
                )}
                <Link to="/login/forgot-password">Forgot password?</Link>
              </div>
              <GlobalButton
                id="signInLogin"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                fullWidth
                noMargin
              >
                {!showPasswordField ? 'Continue' : 'Sign in'}
              </GlobalButton>
            </Form>
          )}
        </Formik>
        {idpLink && (
          <>
            <p className={classes.divider}>OR</p>
            <GlobalButton
              variant="alternative"
              handleClick={() => (window.location = idpLink)}
              fullWidth
            >
              Sign in via SAML SSO
            </GlobalButton>
          </>
        )}

        <p className={classes.divider}>OR</p>

        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID}
          // if we want custom button add this:
          // render={renderProps => (
          //   <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
          // )}
          buttonText="Sign in with Google"
          className={classes.googleAuth}
          onSuccess={_responseGoogleSuccess}
          onFailure={_responseGoogleFailure}
          cookiePolicy={'single_host_origin'}
        />

        <Typography variant="body1" className={classes.switchAppContainer}>
          Sign
          {isOrganization ? ' in as a Contractor' : ' into an Organization'}{' '}
          <a href={isOrganization ? FREELANCER_APP : ORGANIZATION_APP}>here</a>.
        </Typography>

        <Typography
          variant="body2"
          className={error ? classes.badAlert : classes.badAlertPlaceholder}
        >
          {error}
        </Typography>
      </div>
    </div>
  );
};

export default withAppType(withStyles(loginStyles)(Login));

import React from 'react';
import PropTypes from 'prop-types';
import { formatDateDisplay } from '../../utils/formatters';
import DetailsRow from './DetailsRow';
import withUserContext from '../../contexts/userContext/withUserContext';
import { getDateDisplayFormatFromUserContext } from '../../utils/helpers';

const DetailsRowDate = props => {
  const { title, date, emptyText, userContext, ...otherProps } = props;

  return (
    <DetailsRow
      title={title || 'Date'}
      content={
        date
          ? formatDateDisplay(date, getDateDisplayFormatFromUserContext(userContext))
          : emptyText || '-'
      }
      {...otherProps}
    />
  );
};

export default withUserContext(DetailsRowDate);

PropTypes.DetailsRowAmount = {
  date: PropTypes.string,
  emptyText: PropTypes.string,
  title: PropTypes.string,
  noMarginTop: PropTypes.bool,
  titleWidth: PropTypes.number
};

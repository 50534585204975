import React, { useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';
import PropTypes from 'prop-types';

import Select from '../../../../shared/components/form/fields/Select';
import withFieldProps from '../../../../shared/hoc/withFieldProps';
import { useEffectWithStatus } from '../../../../shared/hooks/useEffectWithStatus';

// To be used when we need to fetch types together with category
export const CategorySelectWithTypes = props => {
  const { formatOptions, ...otherProps } = props;
  const [options, setOptions] = useState([]);

  useEffectWithStatus(status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query CategorySelectWithTypesQuery {
          categoryByOrg(orderBy: "name") {
            edges {
              node {
                id
                name
                categoryTypes {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `
    ).then(response => {
      if (status.mounted) {
        setOptions(formatOptions(response.categoryByOrg.edges));
      }
    });
  }, []);

  return (
    <Select
      id="dropdownField-category"
      placeholder="Select Category..."
      options={options}
      {...otherProps}
    />
  );
};

CategorySelectWithTypes.propTypes = {
  formatOptions: PropTypes.func
};

CategorySelectWithTypes.defaultProps = {
  formatOptions: options =>
    options.map(category => ({
      value: category.node.id,
      label: category.node.name,
      types: category.node.categoryTypes
    }))
};

export const FormikCategorySelectWithTypes = withFieldProps(CategorySelectWithTypes);

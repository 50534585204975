/**
 * @flow
 * @relayHash 332da842cdb1e9d1f30f81538d99302b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DeliverableDetailsToggleFollowButton_deliverable$ref = any;
type DeliverableDetails_deliverable$ref = any;
type DeliverableDetails_deliverableStateTypes$ref = any;
export type DeliverableDetailsPageQueryVariables = {|
  id: string
|};
export type DeliverableDetailsPageQueryResponse = {|
  +deliverable: ?{|
    +$fragmentRefs: DeliverableDetails_deliverable$ref & DeliverableDetailsToggleFollowButton_deliverable$ref
  |},
  +deliverableStateTypes: ?{|
    +$fragmentRefs: DeliverableDetails_deliverableStateTypes$ref
  |},
|};
export type DeliverableDetailsPageQuery = {|
  variables: DeliverableDetailsPageQueryVariables,
  response: DeliverableDetailsPageQueryResponse,
|};
*/


/*
query DeliverableDetailsPageQuery(
  $id: ID!
) {
  deliverable(id: $id) {
    ...DeliverableDetails_deliverable
    ...DeliverableDetailsToggleFollowButton_deliverable
    id
  }
  deliverableStateTypes: __type(name: "DeliverableState") {
    ...DeliverableDetails_deliverableStateTypes
  }
}

fragment DeliverableDetails_deliverable on DeliverableNode {
  id
  title
  state
  internalId
  assignedStaff {
    id
    fullName
    user {
      id
    }
  }
  assignedInternally {
    id
    fullName
  }
  release {
    id
    name
    code
    product {
      id
      title
      code
    }
  }
  dueDate
}

fragment DeliverableDetailsToggleFollowButton_deliverable on DeliverableNode {
  id
  isCurrentUserFollowing
  followers {
    totalCount
  }
}

fragment DeliverableDetails_deliverableStateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "DeliverableState"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeliverableDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeliverableDetails_deliverable",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "DeliverableDetailsToggleFollowButton_deliverable",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "deliverableStateTypes",
        "name": "__type",
        "storageKey": "__type(name:\"DeliverableState\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeliverableDetails_deliverableStateTypes",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeliverableDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "internalId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignedStaff",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignedInternally",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "release",
            "storageKey": null,
            "args": null,
            "concreteType": "ReleaseNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "product",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dueDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isCurrentUserFollowing",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "followers",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "deliverableStateTypes",
        "name": "__type",
        "storageKey": "__type(name:\"DeliverableState\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DeliverableDetailsPageQuery",
    "id": null,
    "text": "query DeliverableDetailsPageQuery(\n  $id: ID!\n) {\n  deliverable(id: $id) {\n    ...DeliverableDetails_deliverable\n    ...DeliverableDetailsToggleFollowButton_deliverable\n    id\n  }\n  deliverableStateTypes: __type(name: \"DeliverableState\") {\n    ...DeliverableDetails_deliverableStateTypes\n  }\n}\n\nfragment DeliverableDetails_deliverable on DeliverableNode {\n  id\n  title\n  state\n  internalId\n  assignedStaff {\n    id\n    fullName\n    user {\n      id\n    }\n  }\n  assignedInternally {\n    id\n    fullName\n  }\n  release {\n    id\n    name\n    code\n    product {\n      id\n      title\n      code\n    }\n  }\n  dueDate\n}\n\nfragment DeliverableDetailsToggleFollowButton_deliverable on DeliverableNode {\n  id\n  isCurrentUserFollowing\n  followers {\n    totalCount\n  }\n}\n\nfragment DeliverableDetails_deliverableStateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c79f0a81c0565b0e5eebce109ad3304';
module.exports = node;

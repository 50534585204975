import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

const UpdateNotificationSettingsMutation = (variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateNotificationSettingsMutation($input: UpdateNotificationSettingsInput!) {
        updateNotificationSettings(input: $input) {
          currentUser {
            freelancer {
              id
              notificationConfig
              receiveSummaryEmails
              notificationSummaryInterval
            }
            orgStaff {
              id
              notificationConfig
              receiveSummaryEmails
              notificationSummaryInterval
            }
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    onCompleted: (response, errors) => {
      callback(response.updateNotificationSettings, errors);
    },
    onError: err => console.error(err)
  });
};

export default UpdateNotificationSettingsMutation;

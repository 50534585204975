/**
 * @flow
 * @relayHash 941e509d2293d20a63a7585d5c198cf3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractTemplateDetails_stateTypes$ref = any;
type ContractTemplateDetails_template$ref = any;
export type ContractTemplateDetailsPageQueryVariables = {|
  id: string
|};
export type ContractTemplateDetailsPageQueryResponse = {|
  +contractTemplate: ?{|
    +$fragmentRefs: ContractTemplateDetails_template$ref
  |},
  +__type: ?{|
    +$fragmentRefs: ContractTemplateDetails_stateTypes$ref
  |},
|};
export type ContractTemplateDetailsPageQuery = {|
  variables: ContractTemplateDetailsPageQueryVariables,
  response: ContractTemplateDetailsPageQueryResponse,
|};
*/


/*
query ContractTemplateDetailsPageQuery(
  $id: ID!
) {
  contractTemplate(id: $id) {
    ...ContractTemplateDetails_template
    id
  }
  __type(name: "ContractTemplateState") {
    ...ContractTemplateDetails_stateTypes
  }
}

fragment ContractTemplateDetails_template on ContractTemplateNode {
  id
  name
  state
  activationDate
  created
  docxFileName
  docxFileUrl
  samplePdfFileUrl
  createdBy {
    fullName
    representativeImageUrl
    id
  }
  categories(orderBy: "name") {
    edges {
      node {
        id
        name
      }
    }
  }
}

fragment ContractTemplateDetails_stateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "ContractTemplateState"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractTemplateDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractTemplate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractTemplateNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractTemplateDetails_template",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"ContractTemplateState\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractTemplateDetails_stateTypes",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractTemplateDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contractTemplate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractTemplateNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activationDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "docxFileName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "docxFileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "samplePdfFileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "createdBy",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "representativeImageUrl",
                "args": null,
                "storageKey": null
              },
              (v3/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categories",
            "storageKey": "categories(orderBy:\"name\")",
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "name"
              }
            ],
            "concreteType": "CategoryNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "__type",
        "storageKey": "__type(name:\"ContractTemplateState\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractTemplateDetailsPageQuery",
    "id": null,
    "text": "query ContractTemplateDetailsPageQuery(\n  $id: ID!\n) {\n  contractTemplate(id: $id) {\n    ...ContractTemplateDetails_template\n    id\n  }\n  __type(name: \"ContractTemplateState\") {\n    ...ContractTemplateDetails_stateTypes\n  }\n}\n\nfragment ContractTemplateDetails_template on ContractTemplateNode {\n  id\n  name\n  state\n  activationDate\n  created\n  docxFileName\n  docxFileUrl\n  samplePdfFileUrl\n  createdBy {\n    fullName\n    representativeImageUrl\n    id\n  }\n  categories(orderBy: \"name\") {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment ContractTemplateDetails_stateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'afbb972a78bc5b06cc82924d0ca324da';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const contractorGroupsQuery = graphql`
  query SearchableContractorGroupDropdownQuery($contractorGroupName: String) {
    contractorGroupsByUser(name: $contractorGroupName, orderBy: "name", first: 10) {
      edgeCount
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const SearchableContractorGroupDropdown = props => {
  const { onChange, ...otherProps } = props;

  const getFetchVariables = inputValue => {
    return {
      contractorGroupName: inputValue
    };
  };

  const formatOptions = contractorGroups => {
    if (contractorGroups && contractorGroups.contractorGroupsByUser) {
      return [
        ...contractorGroups.contractorGroupsByUser.edges.map(contractorGroup => {
          return { value: contractorGroup.node.id, label: contractorGroup.node.name };
        })
      ];
    } else {
      return [];
    }
  };

  return (
    <SearchableDropdown
      resultKey="contractorGroupsByUser"
      query={contractorGroupsQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      placeholder="Select Contractor Group..."
      label="Contractor Group"
      value={props.value}
      {...otherProps}
    />
  );
};

export default SearchableContractorGroupDropdown;

import { ReactComponent as DeliverablesIcon } from '../shared/images/deliverables.svg';
import { ReactComponent as SolicitationsIcon } from '../shared/images/solicitations.svg';
import { ReactComponent as JobsIcon } from '../shared/images/jobs.svg';
import { ReactComponent as PortfolioIcon } from '../shared/images/portfolio.svg';
import { ReactComponent as OrganizationsIcon } from '../shared/images/organizations.svg';
import { ReactComponent as InvoicesIcon } from '../shared/images/invoices.svg';

import DeliverableListPage from './components/deliverables/DeliverableListPage';
import DeliverableDetailsPage from './components/deliverables/DeliverableDetailsPage';
import SolicitationsPage from './components/solicitations/SolicitationsPage';
import SolicitationDetailsPage from './components/solicitations/SolicitationDetailsPage';
import InvoicesPage from './components/invoices/InvoicesPage';
import JobsPage from './components/jobs/JobsPage';
import JobDetailsPage from './components/jobs/JobDetailsPage';
import OrganizationsPage from './components/organizationsPage/OrganizationsPage';
import OrganizationDetailsPage from './components/organizationsPage/OrganizationDetailsPage';
import FreelancerUserPage from './components/profile/FreelancerUserPage';
import FreelancerPortfolioPage from './components/portfolioPage/FreelancerPortfolioPage';
import { IC_TARGETS } from '../shared/services/intercomService';

export const FREELANCER_ROUTES = [
  {
    component: DeliverableDetailsPage,
    path: '/deliverables/:id'
  },
  {
    component: DeliverableListPage,
    path: '/deliverables',
    linkProps: {
      label: 'Deliverables',
      icon: DeliverablesIcon,
      intercomDataTarget: IC_TARGETS.sbDeliverables
    }
  },
  {
    component: SolicitationDetailsPage,
    path: '/solicitations/:id'
  },
  {
    component: SolicitationsPage,
    path: '/solicitations',
    linkProps: {
      label: 'JobOpp Inquiries',
      icon: SolicitationsIcon,
      intercomDataTarget: IC_TARGETS.sbSolicitations
    }
  },
  {
    component: JobDetailsPage,
    path: '/jobs/:id'
  },
  {
    component: JobsPage,
    path: '/jobs',
    linkProps: { label: 'Jobs', icon: JobsIcon, intercomDataTarget: IC_TARGETS.sbJobs }
  },
  {
    component: InvoicesPage,
    path: '/invoices',
    linkProps: { label: 'Invoices', icon: InvoicesIcon }
  },
  {
    component: OrganizationDetailsPage,
    path: '/organizations/organizations/:id'
  },
  {
    component: OrganizationsPage,
    path: '/organizations',
    linkProps: {
      label: 'Organizations',
      icon: OrganizationsIcon,
      intercomDataTarget: IC_TARGETS.sbOrganizations
    }
  },
  {
    component: FreelancerPortfolioPage,
    path: '/portfolio',
    linkProps: {
      label: 'Portfolio',
      icon: PortfolioIcon,
      intercomDataTarget: IC_TARGETS.sbPortfolio
    }
  },
  {
    component: FreelancerUserPage,
    path: '/profile'
  }
];

export const FREELANCER_LINKS = FREELANCER_ROUTES.filter(route =>
  route.hasOwnProperty('linkProps')
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation AddSubscriptionModuleMutation($input: AddSubscriptionModuleInput!) {
    addSubscriptionModule(input: $input) {
      isAdded
      organization {
        subscription {
          numberOfModules
          nextBillingAmount
        }
        scheduledSubscription {
          activeModulesCount
          interval
          availableModules {
            moduleId
            quantity
          }
        }
        activeSubscription {
          availableModules {
            moduleId
            name
            incremental
            quantity
            cost {
              tier
              price
            }
            blurbText
            description
            imageUrl
            headline
            externalUrl
          }
        }
      }
    }
  }
`;

const AddSubscriptionModuleMutation = (input, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: { input },
    onCompleted: response => {
      callback(response.addSubscriptionModule);
    },
    onError: err => console.error(err)
  });
};

export default AddSubscriptionModuleMutation;

AddSubscriptionModuleMutation.propTypes = {
  module: PropTypes.oneOf(['activity', 'data', 'saml', 'storage']).isRequired,
  timestamp: PropTypes.string.isRequired
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTabStyles } from '../../styles/common/tabStyles';

const useStyles = makeStyles({
  container: { padding: '24px 30px' }
});

const WhiteBox = ({ children, hidden }) => {
  const classes = useTabStyles();
  const ownClasses = useStyles();

  return (
    <div className={clsx(!hidden && classes.tabPanel, !hidden && ownClasses.container)}>
      {children}
    </div>
  );
};

WhiteBox.defaultProps = {
  hidden: false
};

export default WhiteBox;

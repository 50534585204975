import React from 'react';
import DateField from './fields/DateField';

const FormikDateField = props => {
  const { field, form, ...otherProps } = props;

  const isInvalid = form.errors[field.name] && form.touched[field.name] ? true : false;

  return (
    <DateField
      id={`date-picker-inline-${field.name}`}
      value={field.value}
      name={field.name}
      error={isInvalid}
      helperText={isInvalid && form.errors[field.name]}
      onChange={form.setFieldValue}
      {...otherProps}
    />
  );
};

export default FormikDateField;

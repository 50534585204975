import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation TransferAccountOwnershipMutation($input: TransferAccountOwnershipInput!) {
    transferAccountOwnership(input: $input) {
      currentStaffUser {
        id
        isOwner
        roleLabel
        role {
          id
          name
        }
        allowedActions
        organization {
          configuration
          currencyCode
        }
      }
      updatedStaffUser {
        isOwner
        roleLabel
      }
    }
  }
`;

const TransferAccountOwnershipMutation = (staff, callback) => {
  const config = {
    mutation,
    variables: { input: { staff } },
    onCompleted: response => {
      callback(response.transferAccountOwnership);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default TransferAccountOwnershipMutation;

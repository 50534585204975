/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoryDetails_category$ref: FragmentReference;
declare export opaque type CategoryDetails_category$fragmentType: CategoryDetails_category$ref;
export type CategoryDetails_category = {|
  +id: string,
  +name: string,
  +numberOfDeliverables: ?number,
  +categoryTypes: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +numberOfDeliverables: ?number,
      |}
    |}>
  |},
  +steps: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +order: number,
        +numberOfDeliverables: ?number,
      |}
    |}>
  |},
  +$refType: CategoryDetails_category$ref,
|};
export type CategoryDetails_category$data = CategoryDetails_category;
export type CategoryDetails_category$key = {
  +$data?: CategoryDetails_category$data,
  +$fragmentRefs: CategoryDetails_category$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numberOfDeliverables",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CategoryDetails_category",
  "type": "CategoryNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "categoryTypes",
      "storageKey": null,
      "args": null,
      "concreteType": "CategoryTypeNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CategoryTypeNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CategoryTypeNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "steps",
      "storageKey": null,
      "args": null,
      "concreteType": "StepNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StepNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StepNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '777acde759b3d059d169e2edcd3afd5b';
module.exports = node;

/**
 * @flow
 * @relayHash 7cfba0b4ec5db7a1755fe06d631d1d12
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSolicitationInput = {|
  subject: string,
  replyByDate?: ?any,
  message?: ?string,
  deliverables?: ?$ReadOnlyArray<?string>,
  contractors?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type CreateSolicitationMutationVariables = {|
  input: CreateSolicitationInput
|};
export type CreateSolicitationMutationResponse = {|
  +createSolicitation: ?{|
    +newSolicitation: ?{|
      +id: string
    |}
  |}
|};
export type CreateSolicitationMutation = {|
  variables: CreateSolicitationMutationVariables,
  response: CreateSolicitationMutationResponse,
|};
*/


/*
mutation CreateSolicitationMutation(
  $input: CreateSolicitationInput!
) {
  createSolicitation(input: $input) {
    newSolicitation {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSolicitationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createSolicitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSolicitationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "newSolicitation",
        "storageKey": null,
        "args": null,
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateSolicitationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateSolicitationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateSolicitationMutation",
    "id": null,
    "text": "mutation CreateSolicitationMutation(\n  $input: CreateSolicitationInput!\n) {\n  createSolicitation(input: $input) {\n    newSolicitation {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd21a91fd1fbcf3a0fccf4b4ed42d6b16';
module.exports = node;

/**
 * @flow
 * @relayHash 15c7cf3b5391a642fcda735ce956b0bf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TopBarFreelancerNotification_notifications$ref = any;
export type TopBarFreelancerQueryVariables = {||};
export type TopBarFreelancerQueryResponse = {|
  +$fragmentRefs: TopBarFreelancerNotification_notifications$ref
|};
export type TopBarFreelancerQuery = {|
  variables: TopBarFreelancerQueryVariables,
  response: TopBarFreelancerQueryResponse,
|};
*/


/*
query TopBarFreelancerQuery {
  ...TopBarFreelancerNotification_notifications
}

fragment TopBarFreelancerNotification_notifications on Query {
  notifications(unread: true, first: 10) {
    totalCount
    edges {
      node {
        id
        unread
        timesince
        description
        actor {
          name
          representativeImageUrl
          actorType
        }
        extraData {
          targetRelayId
          actionRelayId
          content
          notificationType
          instanceType
          instanceTitle
          action
        }
        reviewAssetComment {
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "unread",
    "value": true
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TopBarFreelancerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "TopBarFreelancerNotification_notifications",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TopBarFreelancerQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notifications",
        "storageKey": "notifications(first:10,unread:true)",
        "args": (v0/*: any*/),
        "concreteType": "NotificationNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "NotificationNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NotificationNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "unread",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "timesince",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NotificationActorNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "representativeImageUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actorType",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "extraData",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NotificationExtraData",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "targetRelayId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actionRelayId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "content",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "notificationType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "instanceType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "instanceTitle",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "action",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "reviewAssetComment",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "notifications",
        "args": (v0/*: any*/),
        "handle": "connection",
        "key": "TopBarFreelancerNotification_notifications",
        "filters": [
          "unread"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TopBarFreelancerQuery",
    "id": null,
    "text": "query TopBarFreelancerQuery {\n  ...TopBarFreelancerNotification_notifications\n}\n\nfragment TopBarFreelancerNotification_notifications on Query {\n  notifications(unread: true, first: 10) {\n    totalCount\n    edges {\n      node {\n        id\n        unread\n        timesince\n        description\n        actor {\n          name\n          representativeImageUrl\n          actorType\n        }\n        extraData {\n          targetRelayId\n          actionRelayId\n          content\n          notificationType\n          instanceType\n          instanceTitle\n          action\n        }\n        reviewAssetComment {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3ae6201ca40850333bd6c0606271dcb';
module.exports = node;

import React from 'react';
import { Typography, TableRow } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  textContainer: {
    width: '100%',
    display: 'flex',
    padding: '20px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const EmptyListTextContainer = props => {
  const classes = useStyles();
  const { children } = props;
  return (
    <TableRow>
      <td colSpan="100">
        <div className={classes.textContainer}>
          <Typography variant="body2">{children}</Typography>
        </div>
      </td>
    </TableRow>
  );
};

export default EmptyListTextContainer;

import { createMuiTheme } from '@material-ui/core/styles';
import sharedTheme from './sharedTheme';
import colors from '../common/colors';

export default createMuiTheme({
  ...sharedTheme,
  palette: {
    ...sharedTheme.palette,
    primary: {
      main: colors.brightViolet,
      dark: colors.darkViolet,
      light: colors.lightViolet,
      bright: colors.brightViolet,
      tabBackground: colors.freelancerTabBackground,
      sideBarBackground: colors.violet,
      sideBarLink: colors.lightViolet,
      sideBarIcon: colors.lightViolet,
      activeLink: colors.activeDarkViolet,
      notificationHover: 'rgba(182, 179, 252, 0.2)',
      checkboxHover: 'rgba(86, 18, 206, 0.1)',
      messageIcon: colors.brightViolet
    }
  }
});

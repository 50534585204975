/**
 * @flow
 * @relayHash 2019dd5716c65cd32975553950f20b20
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DoNotHireContractorInput = {|
  id: string,
  doNotHire: boolean,
  clientMutationId?: ?string,
|};
export type DoNotHireContractorMutationVariables = {|
  input: DoNotHireContractorInput
|};
export type DoNotHireContractorMutationResponse = {|
  +doNotHireContractor: ?{|
    +updatedContractor: ?{|
      +doNotHire: ?boolean
    |}
  |}
|};
export type DoNotHireContractorMutation = {|
  variables: DoNotHireContractorMutationVariables,
  response: DoNotHireContractorMutationResponse,
|};
*/


/*
mutation DoNotHireContractorMutation(
  $input: DoNotHireContractorInput!
) {
  doNotHireContractor(input: $input) {
    updatedContractor {
      doNotHire
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DoNotHireContractorInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "doNotHire",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DoNotHireContractorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "doNotHireContractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DoNotHireContractorPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DoNotHireContractorMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "doNotHireContractor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DoNotHireContractorPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DoNotHireContractorMutation",
    "id": null,
    "text": "mutation DoNotHireContractorMutation(\n  $input: DoNotHireContractorInput!\n) {\n  doNotHireContractor(input: $input) {\n    updatedContractor {\n      doNotHire\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9017a7c579ab084a38efd054a5a4dea2';
module.exports = node;

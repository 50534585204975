import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import GreyBarWithContactInfo from '../../../../../shared/components/UI/GreyBarWithContactInfo';
import { getCountryName } from '../../../../../shared/utils/helpers';

const GreyBar = props => {
  const {
    staffCoordinator: {
      fullName,
      representativeImageUrl,
      organization: {
        name: organizationName,
        representativeImageUrl: organizationRepresentativeImageUrl,
        country
      }
    }
  } = props.solicitation;
  return (
    <GreyBarWithContactInfo
      {...{
        fullName,
        representativeImageUrl,
        organizationName,
        organizationRepresentativeImageUrl,
        organizationCountry: getCountryName(country)
      }}
    />
  );
};

export default createFragmentContainer(GreyBar, {
  solicitation: graphql`
    fragment GreyBar_solicitation on SolicitationNode {
      staffCoordinator {
        fullName
        representativeImageUrl
        organization {
          representativeImageUrl
          name
          country {
            name
          }
        }
      }
    }
  `
});

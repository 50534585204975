import React, { Fragment, useState, useEffect } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import { CELL_TYPES } from '../../../../../shared/constants';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import HandleRoleForm from './HandleRoleForm';
import Dialog from '../../../../../shared/components/common/Dialog';
import withDialogProps from '../../../../../shared/hoc/withDialogProps';
import useDialog from '../../../../../shared/hooks/useDialog';
import { errorToast } from '../../../../../shared/toasts';

const RolesList = props => {
  const {
    relay: { refetch },
    roles: { organizationRoles }
  } = props;

  const [refetchCounterForRoles, setRefetchCounterForRoles] = useState(0);
  const [selectedRole, setSelectedRole] = useState(null);
  const [action, setAction] = useState('');
  const [isDialogOpen, setDialogOpen] = useDialog();

  // NOTE:
  // Refetch data when new role was added from global dialog
  useEffect(() => {
    if (props.refetchCounter !== 0) {
      setRefetchCounterForRoles(prev => prev + 1);
    }
  }, [props.refetchCounter]);

  if (!organizationRoles) return <div>Something went wrong!</div>;

  const {
    pageInfo: { hasNextPage, endCursor },
    edges,
    totalCount,
    edgeCount
  } = organizationRoles;

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    const { history, match } = props;
    history.push(`${match.url}/${id}`);
  };

  const toggleDialogOpen = () => {
    setDialogOpen(!isDialogOpen);
  };

  const onDuplicateClickHandler = id => {
    const currentRole = getCurrentSelectedRole(id);
    if (currentRole) {
      setSelectedRole(currentRole.node);
      setAction('duplicate');
      toggleDialogOpen();
    }
  };

  const handleCloseWithRefetch = () => {
    setRefetchCounterForRoles(prev => prev + 1);
    setSelectedRole(null);
    setAction('');
    toggleDialogOpen();
  };

  const getCurrentSelectedRole = id => {
    return edges.find(edge => edge.node.id === id);
  };

  const onTrashClickHandler = id => {
    const currentRole = getCurrentSelectedRole(id);
    if (currentRole && currentRole.node.numberOfStaff !== 0) {
      errorToast(
        `You cannot delete this role because it is assigned to ${currentRole.node.numberOfStaff} Staff member(s).`
      );
      return;
    } else if (currentRole) {
      setSelectedRole(currentRole.node);
      setAction('delete');
      toggleDialogOpen();
    }
  };

  const getDialogTitle = () => {
    if (action === 'duplicate') {
      return 'Create New Role';
    }
    if (action === 'delete') {
      return 'Delete Role';
    }
    return '';
  };

  const flattenedEdges = edges.map(edge => {
    const { id, name, isDefault, numberOfStaff, basedOnRole } = edge.node;

    return {
      node: {
        id,
        name,
        numberOfStaff,
        basedOnRole: basedOnRole && basedOnRole.name,
        actions: {
          allowDuplicate: isDefault,
          allowDelete: !isDefault
        }
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: 'name', label: 'Name', type: CELL_TYPES.link },
          { name: 'basedOnRole', label: 'Based On' },
          { name: 'numberOfStaff', label: 'Users', align: 'right' },
          {
            name: 'actions',
            label: '',
            type: CELL_TYPES.actions
          }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        refetchCounter={refetchCounterForRoles}
        rowProps={{
          handleLinkClick: onLinkClickHandler,
          handleActionDuplicateClick: onDuplicateClickHandler,
          handleActionTrashClick: onTrashClickHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
      <Dialog
        minWidth={300}
        title={getDialogTitle()}
        isDialogOpen={isDialogOpen}
        closeDialog={toggleDialogOpen}
      >
        {selectedRole && (
          <HandleRoleForm
            handleClose={toggleDialogOpen}
            handleCloseWithRefetch={handleCloseWithRefetch}
            action={action}
            selectedRole={selectedRole}
          />
        )}
      </Dialog>
    </Fragment>
  );
};

export default withDialogProps(
  withRouter(
    createRefetchContainer(
      RolesList,
      {
        roles: graphql`
          fragment RolesList_roles on Query
            @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
            organizationRoles(first: $first, after: $after) {
              pageInfo {
                hasNextPage
                endCursor
              }
              totalCount
              edgeCount
              edges {
                node {
                  id
                  name
                  numberOfStaff
                  isDefault
                  basedOnRole {
                    name
                  }
                }
              }
            }
          }
        `
      },
      graphql`
        query RolesListRefetchQuery($first: Int, $after: String) {
          ...RolesList_roles @arguments(first: $first, after: $after)
        }
      `
    )
  )
);

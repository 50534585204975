import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useContentStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: '24px 30px',
    '& > :last-child': {
      // there is a bug in firefox, where bottom padding does not get applied when overflow is set to scroll,
      // hence the marginBottom on lastChild acting as a bottom padding
      marginBottom: theme.spacing(3)
    }
  }
}));

const StyledContent = ({ children }) => {
  const classes = useContentStyles();
  return <div className={classes.content}>{children}</div>;
};

export default StyledContent;

import React from 'react';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import ToggleCommentReactionMutation from './ToggleCommentReactionMutation';
import ReactionButton from './ReactionButton';

const AddCommentReactionButton = ({ comment, userContext, ...btnProps }) => {
  const handleSelect = reaction => {
    const existingReaction = comment.reactions.find(r => r.definitionId === reaction.id);
    const userHasReaction =
      existingReaction && existingReaction.users.find(user => user.id === userContext.id);

    if (!userHasReaction) {
      ToggleCommentReactionMutation({
        reactionId: reaction.id,
        commentId: comment.commentId
      });
    }
  };

  return <ReactionButton onSelect={handleSelect} {...btnProps} />;
};

export default withUserContext(AddCommentReactionButton);

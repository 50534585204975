import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const ToggleReviewAssetReactionMutation = input =>
  new Promise((resolve, reject) => {
    commitMutation(getEnvironment(), {
      mutation: graphql`
        mutation ToggleReviewAssetReactionMutation($input: ToggleReviewAssetReactionInput!) {
          toggleReviewAssetReaction(input: $input) {
            reviewAsset {
              id
              reactions {
                id
                definitionId
                name
                color
                number
                users {
                  id
                  fullName
                  representativeImageUrl
                }
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: resolve,
      onError: reject
    });
  });

export default ToggleReviewAssetReactionMutation;

import React, { Fragment } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import DetailsRowAmount from '../../../../../shared/components/UI/DetailsRowAmount';

const ContractorJobsTopSection = props => {
  const classes = useDetailsStyles();
  const {
    activeJobsCount,
    completedJobsCount,
    jobsLifetimeValue,
    jobsThisYearValue
  } = props.contractor;

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.halfWidth}>
          <DetailsSection title="Job Summary">
            <DetailsRow noMarginTop title="Active Jobs" content={activeJobsCount} />
            <DetailsRow noMarginTop title="Completed Jobs" content={completedJobsCount} />
          </DetailsSection>
        </div>
        <div className={classes.halfWidth}>
          <DetailsSection title="Total Payout">
            <DetailsRowAmount noMarginTop title="Lifetime" amount={jobsLifetimeValue} />
            <DetailsRowAmount noMarginTop title="Calendar Year" amount={jobsThisYearValue} />
          </DetailsSection>
        </div>
      </div>
    </Fragment>
  );
};

export default createFragmentContainer(ContractorJobsTopSection, {
  contractor: graphql`
    fragment ContractorJobsTopSection_contractor on ContractorNode {
      activeJobsCount
      completedJobsCount
      jobsLifetimeValue
      jobsThisYearValue
    }
  `
});

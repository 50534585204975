import React, { Fragment } from 'react';
import { TextField, FormHelperText, IconButton } from '@material-ui/core';
import formStyles from '../../styles/common/formStyles';
import { ReactComponent as FileUploadIcon } from '../../images/file-upload-icon.svg';
import clsx from 'clsx';
import { formatFileUploadHelperString } from '../../utils/helpers';
import { styled } from '@material-ui/styles';

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  lineHeight: '16px',
  marginBottom: 16,
  color: theme.palette.primary.main
}));

const FormikFileInput = ({
  field,
  form,
  decoratorStart,
  accept,
  placeholder,
  helperTextProps,
  ...otherProps
}) => {
  const classes = formStyles();
  const isInvalid = form.errors[field.name] && form.touched[field.name];

  const inputRef = React.createRef();

  const onChangeHandler = e => {
    const file = e.currentTarget.files[0];
    form.setFieldValue(field.name, file);
  };

  const onClickHandler = () => {
    inputRef.current.click();
  };

  const acceptedFormatsString = helperTextProps
    ? helperTextProps.acceptedFormats.join(', ')
    : accept;

  return (
    <Fragment>
      {helperTextProps && (
        <StyledFormHelperText>
          {formatFileUploadHelperString(
            helperTextProps.acceptedFormats,
            helperTextProps.maxFileSize
          )}
        </StyledFormHelperText>
      )}

      <TextField
        type="file"
        name={field.name}
        id={`textField-${field.name}`}
        variant="outlined"
        onChange={onChangeHandler}
        onBlur={form.handleBlur}
        error={isInvalid ? true : false}
        autoComplete="off"
        helperText={isInvalid && form.errors[field.name]}
        classes={{
          root: classes.textFieldRoot
        }}
        InputLabelProps={{
          shrink: true
        }}
        inputRef={inputRef}
        inputProps={{ accept: acceptedFormatsString }}
        InputProps={{
          className: classes.inputFieldFile,
          notched: false,
          startAdornment: (
            <div
              onClick={onClickHandler}
              className={clsx(
                'file-name',
                classes.inputFileName,
                !field.value && classes.inputFileNamePlaceholder
              )}
            >
              {field.value ? field.value.name : placeholder || 'Click to upload a file'}
            </div>
          ),
          endAdornment: (
            <IconButton onClick={onClickHandler} className={classes.endAdornmentButton}>
              <FileUploadIcon></FileUploadIcon>
            </IconButton>
          ),
          classes: {
            multiline: classes.outLinedInputMultiline
          }
        }}
        {...otherProps}
      />
    </Fragment>
  );
};

export default FormikFileInput;

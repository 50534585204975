import React, { useState, useRef } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';
import differenceBy from 'lodash/differenceBy';
import { computePermissionRole } from '../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS } from '../../../../shared/constants';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import FollowButton from '../../../../shared/components/UI/FollowButton';
import ConfirmJobFollow from '../ConfirmJobFollow';
import ToggleAssignFollowersForJobsMutation from '../mutations/ToggleAssignFollowersForJobsMutation';
import SearchableStaffDropdown from '../../shared/dropdowns/SearchableStaffDropdown';

const JobDetailsToggleFollowButton = ({ job, relay, userContext }) => {
  const [pendingAction, setPendingAction] = useState(null);
  const [isFetchingFollowers, setIsFetchingFollowers] = useState(false);
  const [isFollowersListVisible, setIsFollowersListVisible] = useState(false);
  const selectFocusRef = useRef({ focused: false });
  const canAddFollowers = computePermissionRole(
    ALLOWED_ACTIONS.ADD_FOLLOWERS,
    userContext.orgStaff.allowedActions
  );

  const handleListToggle = isVisible => {
    setIsFollowersListVisible(isVisible);
    if (isVisible) {
      setIsFetchingFollowers(true);
      relay.refetch({ withFollowers: true }, null, () => setIsFetchingFollowers(false));
    }
  };

  const handleUserFollowToggle = (user, follow, isForCurrentUser = false) => {
    setPendingAction({
      follow,
      jobIds: [job.id],
      isForCurrentUser,
      onSubmit: withDeliverables =>
        ToggleAssignFollowersForJobsMutation({
          follow,
          userIds: [user.id],
          jobIds: [job.id],
          withDeliverables
        }).then(() => {
          relay.refetch({ withFollowers: isFollowersListVisible });
          setPendingAction(null);
        })
    });
  };

  // NOTE:
  // This is needed because SearchableStaffDropdown uses portal which can't be tracked
  // by FollowButton handleOutsideClick
  const handleSelectFocus = () => {
    selectFocusRef.current.focused = true;
  };

  const handleSelectBlur = () => {
    selectFocusRef.current.focused = false;
  };

  const followers = job.followers.edges
    ? job.followers.edges.map(({ node }) => ({
        id: node.id,
        name: node.fullName
      }))
    : null;

  return (
    <>
      <ConfirmJobFollow
        isDialogOpen={pendingAction !== null}
        {...pendingAction}
        closeDialog={() => setPendingAction(null)}
      />
      <FollowButton
        isFollowing={job.isCurrentUserFollowing}
        followers={isFetchingFollowers ? null : followers}
        totalFollowers={job.followers.totalCount}
        onFollowToggle={isFollowing => {
          handleUserFollowToggle(
            {
              id: userContext.orgStaff.id
            },
            isFollowing,
            true
          );
        }}
        onShowFollowersToggle={handleListToggle}
        onFollowerRemove={
          canAddFollowers ? follower => handleUserFollowToggle(follower, false) : undefined
        }
        shouldHideOnOusideClick={() => !selectFocusRef.current.focused && !pendingAction}
        renderAddFollowerForm={
          canAddFollowers
            ? () => (
                <SearchableStaffDropdown
                  name="selectedStaff"
                  label="Staff Name"
                  placeholder="Add Follower"
                  value={
                    followers ? followers.map(({ id, name }) => ({ value: id, label: name })) : null
                  }
                  onFocus={handleSelectFocus}
                  onBlur={handleSelectBlur}
                  onChange={value => {
                    if (value.length > 0) {
                      const [newFollower] = differenceBy(
                        value.map(({ value, label }) => ({ id: value, name: label })),
                        followers,
                        follower => follower.id
                      );
                      handleUserFollowToggle(newFollower, true);
                    }
                  }}
                  isMulti
                  controlShouldRenderValue={false}
                  itemsInList={followers ? followers : undefined}
                />
              )
            : undefined
        }
      />
    </>
  );
};

export default createRefetchContainer(
  withUserContext(JobDetailsToggleFollowButton),
  {
    job: graphql`
      fragment JobDetailsToggleFollowButton_job on JobNode
      @argumentDefinitions(withFollowers: { type: "Boolean" }) {
        id
        isCurrentUserFollowing
        followers {
          edges @include(if: $withFollowers) {
            node {
              id
              fullName
            }
          }
          totalCount
        }
      }
    `
  },
  graphql`
    query JobDetailsToggleFollowButtonQuery($id: ID!, $withFollowers: Boolean!) {
      job(id: $id) {
        ...JobDetailsToggleFollowButton_job @arguments(withFollowers: $withFollowers)
      }
    }
  `
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

const mutation = graphql`
  mutation UpdateBatchDeliverableMutation($input: UpdateBatchDeliverableInput!) {
    updateBatchDeliverable(input: $input) {
      isUpdated
      updatedDeliverables {
        id
        assignedStaff {
          fullName
          representativeImageUrl
          user {
            id
          }
        }
        amount
        amountInHomeCurrency
        dueDate
        currencyCode
      }
    }
  }
`;

// export default (checkedIds, amount = null, dueDate = null, assignedStaff = null, callback) => {
export default (ids, variables, callback) => {
  commitMutation(getEnvironment(), {
    mutation,
    variables: {
      input: {
        ids,
        ...variables
      }
    },
    onCompleted: (response, errors) => {
      callback(response.updateBatchDeliverable, errors);
    },
    onError: err => console.error(err)
  });
};

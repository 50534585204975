import { makeStyles } from '@material-ui/core/styles';

const contractorDetailsStyles = makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  marginRight: {
    marginRight: '75px'
  },
  containerColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  formField: {
    width: '300px',
    marginTop: '30px'
  },
  formFieldNoMarginTop: {
    width: '300px',
    marginTop: '0'
  },
  warningDetailsRow: {
    '& span': {
      color: theme.palette.colors.red
    }
  }
}));

export default contractorDetailsStyles;

import { createMuiTheme } from '@material-ui/core/styles';
import sharedTheme from './sharedTheme';
import colors from '../common/colors';

export default createMuiTheme({
  ...sharedTheme,
  palette: {
    ...sharedTheme.palette,
    primary: {
      main: colors.orange,
      dark: colors.darkOrange,
      light: colors.lightOrange,
      bright: colors.brightOrange,
      tabBackground: colors.organizationTabBackground,
      sideBarBackground: colors.grey,
      sideBarLink: colors.lightBlueGrey,
      sideBarIcon: colors.sidebarIconGrey,
      activeLink: colors.orange,
      notificationHover: 'rgba(255,216,179, 0.3)',
      checkboxHover: 'rgba(232,113,25, 0.1)',
      messageIcon: colors.darkBlueGrey
    }
  }
});

import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { yupSchemas } from '../../../../../shared/validations';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import StyledTableCell from '../../../../../shared/components/table/StyledTableCell';
import SwatchColorPicker from '../../../../../shared/components/colorPicker/SwatchColorPicker';
import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import UpdateReactionsMutation from './UpdateReactionsMutation';

const TableContainer = styled.div`
  width: 480px;
`;

const ColorPickerContainer = styled.div`
  > * {
    margin: 0;
  }
`;

const ColorBubble = styled.div`
  margin-left: 15px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: ${props => props.colorCode};
`;

const Reactions = ({ reactions }) => {
  const [isEditing, setEditing] = useState(false);

  const handleSubmit = ({ reactions }, { resetForm, setSubmitting }) => {
    UpdateReactionsMutation({
      definitions: reactions
    })
      .then(() => {
        resetForm();
        setEditing(false);
      })
      .catch(() => setSubmitting(false));
  };

  const validationSchema = yupSchemas.shape({
    reactions: Yup.array().of(
      yupSchemas.shape({
        id: yupSchemas.string(true),
        name: yupSchemas.string(true),
        color: yupSchemas.string(true)
      })
    )
  });

  return (
    <Formik
      initialValues={{
        reactions: reactions.map(({ node }) => node)
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ resetForm, setFieldValue, values }) => (
        <Form>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h3">Review Interface Reactions</Typography>
            <EditToggle
              isEditing={isEditing}
              editProps={{
                id: 'editOrganizationReactions',
                onClick: () => setEditing(true)
              }}
              saveProps={{
                id: 'saveOrganizationReactionsEdit'
              }}
              cancelProps={{
                id: 'cancelOrganizationReactionsEdit',
                onClick: () => {
                  resetForm();
                  setEditing(false);
                }
              }}
            />
          </Grid>

          <TableContainer>
            <AutoTable
              rowTemplate={[
                {
                  name: 'name',
                  label: 'Reaction',
                  renderCell: ({ index, rowIndex, value }) => {
                    return (
                      <StyledTableCell key={index}>
                        {isEditing ? (
                          <Field name={`reactions[${rowIndex}].name`} component={FormikTextField} />
                        ) : (
                          value
                        )}
                      </StyledTableCell>
                    );
                  }
                },
                {
                  name: 'color',
                  label: 'Text Color',
                  renderCell: ({ index, rowIndex, value }) => {
                    const colorCode = `#${values.reactions[rowIndex].color}`;
                    return (
                      <StyledTableCell key={index}>
                        {isEditing ? (
                          <ColorPickerContainer>
                            <SwatchColorPicker
                              initialColor={colorCode}
                              onChange={(name, val) => {
                                const color = val.replace('#', '');
                                setFieldValue(name, color);
                              }}
                              name={`reactions[${rowIndex}].color`}
                            />
                          </ColorPickerContainer>
                        ) : (
                          <ColorBubble colorCode={colorCode} />
                        )}
                      </StyledTableCell>
                    );
                  }
                }
              ]}
              edges={reactions}
            />
          </TableContainer>
        </Form>
      )}
    </Formik>
  );
};

export default Reactions;

import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../utils/helpers';

const getFetchReviewAssetDownloadUrl = (reviewAssetId, callback) => {
  return (status = { mounted: true }) => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query fetchReviewAssetDownloadUrlQuery($id: ID!) {
          reviewAsset(id: $id) {
            fileDownloadUrl
          }
        }
      `,
      { id: reviewAssetId },
      { force: true }
    ).then(response => {
      if (response && response.reviewAsset && status.mounted) {
        callback(response.reviewAsset.fileDownloadUrl);
      }
    });
  };
};

export default getFetchReviewAssetDownloadUrl;

/**
 * @flow
 * @relayHash 7b735c14863cb54c6860c39c63721964
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SolicitationsList_solicitations$ref = any;
export type SolicitationsListRefetchQueryVariables = {|
  first?: ?number,
  orderBy?: ?string,
  after?: ?string,
  replyByFrom?: ?any,
  replyByTo?: ?any,
  state?: ?string,
  subject?: ?string,
  staffCoordinator?: ?string,
  includeClosed?: ?boolean,
|};
export type SolicitationsListRefetchQueryResponse = {|
  +$fragmentRefs: SolicitationsList_solicitations$ref
|};
export type SolicitationsListRefetchQuery = {|
  variables: SolicitationsListRefetchQueryVariables,
  response: SolicitationsListRefetchQueryResponse,
|};
*/

/*
query SolicitationsListRefetchQuery(
  $first: Int
  $orderBy: String
  $after: String
  $replyByFrom: Date
  $replyByTo: Date
  $state: String
  $subject: String
  $staffCoordinator: ID
  $includeClosed: Boolean
) {
  ...SolicitationsList_solicitations
}

fragment SolicitationsList_solicitations on Query {
  allSolicitations(first: $first, orderBy: $orderBy, after: $after, replyByFrom: $replyByFrom, replyByTo: $replyByTo, state: $state, subject: $subject, staffCoordinator: $staffCoordinator, includeClosed: $includeClosed) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edgeCount
    edges {
      node {
        id
        created
        subject
        replyByDate
        staffCoordinator {
          fullName
          representativeImageUrl
          user {
            id
          }
          id
        }
        releases {
          name
          id
          product {
            title
            id
          }
        }
        state
        numberOfContractors
        numberOfDeliverables
        totalDeliverableAmount
        solicitationId
        numberOfAllocatedDeliverables
        numberOfAllocatedContractedDeliverables
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'orderBy',
        type: 'String',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'after',
        type: 'String',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'replyByFrom',
        type: 'Date',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'replyByTo',
        type: 'Date',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'state',
        type: 'String',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'subject',
        type: 'String',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'staffCoordinator',
        type: 'ID',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'includeClosed',
        type: 'Boolean',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'SolicitationsListRefetchQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'FragmentSpread',
          name: 'SolicitationsList_solicitations',
          args: null
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'SolicitationsListRefetchQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'allSolicitations',
          storageKey: null,
          args: [
            {
              kind: 'Variable',
              name: 'after',
              variableName: 'after'
            },
            {
              kind: 'Variable',
              name: 'first',
              variableName: 'first'
            },
            {
              kind: 'Variable',
              name: 'includeClosed',
              variableName: 'includeClosed'
            },
            {
              kind: 'Variable',
              name: 'orderBy',
              variableName: 'orderBy'
            },
            {
              kind: 'Variable',
              name: 'replyByFrom',
              variableName: 'replyByFrom'
            },
            {
              kind: 'Variable',
              name: 'replyByTo',
              variableName: 'replyByTo'
            },
            {
              kind: 'Variable',
              name: 'staffCoordinator',
              variableName: 'staffCoordinator'
            },
            {
              kind: 'Variable',
              name: 'state',
              variableName: 'state'
            },
            {
              kind: 'Variable',
              name: 'subject',
              variableName: 'subject'
            }
          ],
          concreteType: 'SolicitationNodeConnection',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'pageInfo',
              storageKey: null,
              args: null,
              concreteType: 'PageInfo',
              plural: false,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'hasNextPage',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'endCursor',
                  args: null,
                  storageKey: null
                }
              ]
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'totalCount',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'edgeCount',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'edges',
              storageKey: null,
              args: null,
              concreteType: 'SolicitationNodeEdge',
              plural: true,
              selections: [
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'node',
                  storageKey: null,
                  args: null,
                  concreteType: 'SolicitationNode',
                  plural: false,
                  selections: [
                    (v1 /*: any*/),
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'created',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'subject',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'replyByDate',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'staffCoordinator',
                      storageKey: null,
                      args: null,
                      concreteType: 'StaffNode',
                      plural: false,
                      selections: [
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'fullName',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'representativeImageUrl',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'user',
                          storageKey: null,
                          args: null,
                          concreteType: 'UserNode',
                          plural: false,
                          selections: [(v1 /*: any*/)]
                        },
                        (v1 /*: any*/)
                      ]
                    },
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'releases',
                      storageKey: null,
                      args: null,
                      concreteType: 'ReleaseNode',
                      plural: true,
                      selections: [
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'name',
                          args: null,
                          storageKey: null
                        },
                        (v1 /*: any*/),
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'product',
                          storageKey: null,
                          args: null,
                          concreteType: 'ProductNode',
                          plural: false,
                          selections: [
                            {
                              kind: 'ScalarField',
                              alias: null,
                              name: 'title',
                              args: null,
                              storageKey: null
                            },
                            (v1 /*: any*/)
                          ]
                        }
                      ]
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'state',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'numberOfContractors',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'numberOfDeliverables',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'totalDeliverableAmount',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'solicitationId',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'numberOfAllocatedDeliverables',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'numberOfAllocatedContractedDeliverables',
                      args: null,
                      storageKey: null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'SolicitationsListRefetchQuery',
      id: null,
      text:
        'query SolicitationsListRefetchQuery(\n  $first: Int\n  $orderBy: String\n  $after: String\n  $replyByFrom: Date\n  $replyByTo: Date\n  $state: String\n  $subject: String\n  $staffCoordinator: ID\n  $includeClosed: Boolean\n) {\n  ...SolicitationsList_solicitations\n}\n\nfragment SolicitationsList_solicitations on Query {\n  allSolicitations(first: $first, orderBy: $orderBy, after: $after, replyByFrom: $replyByFrom, replyByTo: $replyByTo, state: $state, subject: $subject, staffCoordinator: $staffCoordinator, includeClosed: $includeClosed) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        created\n        subject\n        replyByDate\n        staffCoordinator {\n          fullName\n          representativeImageUrl\n          user {\n            id\n          }\n          id\n        }\n        releases {\n          name\n          id\n          product {\n            title\n            id\n          }\n        }\n        state\n        numberOfContractors\n        numberOfDeliverables\n        totalDeliverableAmount\n        solicitationId\n        numberOfAllocatedDeliverables\n        numberOfAllocatedContractedDeliverables\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '6b8c042d08e08152b60ac510bd76506d';
module.exports = node;

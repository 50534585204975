import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { styled } from '@material-ui/styles';

import Amount from '../common/Amount';

const StyledTrendTypography = styled(({ amount, type, ...otherProps }) => (
  <Typography {...otherProps} />
))(({ theme, amount, type }) => {
  const isNegative = amount < 0;
  return {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: isNegative ? theme.palette.colors.red : theme.palette.colors.green,
    fontSize: type === 'cell' && '12px',

    '& svg': {
      ...(type === 'default' && {
        width: 19,
        height: 19
      }),
      ...(type === 'cell' && {
        width: 16,
        height: 16
      }),
      transform: isNegative ? 'rotate(180deg)' : ''
    }
  };
});

const AmountTrend = ({ value, type }) =>
  value === null ? (
    '-'
  ) : (
    <StyledTrendTypography variant="subtitle2" amount={value} type={type}>
      <ArrowDropDownIcon />
      <Amount amount={value} />
    </StyledTrendTypography>
  );

AmountTrend.propTypes = {
  type: PropTypes.oneOf(['default', 'cell'])
};

AmountTrend.defaultProps = {
  value: 100,
  type: 'default'
};

export default AmountTrend;

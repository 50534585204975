import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createRefetchContainer } from 'react-relay';

import CommentThread from '../../../../../shared/components/comment/CommentThread';
import contractorDetailsStyles from '../../../../../shared/styles/contractors/contractorDetailsStyles';
import CreateContractorNoteMutation from '../../mutations/CreateContractorNoteMutation';
import UpdateContractorNoteMutation from '../../mutations/UpdateContractorNoteMutation';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import { mapContractorNotes, computePermissionRole } from '../../../../../shared/utils/helpers';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';

const Notes = props => {
  const {
    contractor: {
      id: contractorId,
      contractorNotes: { edges: notes }
    },
    userContext: {
      orgStaff: { allowedActions }
    }
  } = props;

  const classes = contractorDetailsStyles();
  const canSubmitComment = computePermissionRole(
    ALLOWED_ACTIONS.CONTRACTOR_ADD_NOTE,
    allowedActions
  );

  const handleSubmitComment = ({ comment, repliedTo }, cb) => {
    CreateContractorNoteMutation(
      {
        id: contractorId,
        content: comment,
        ...(repliedTo && { repliedTo: repliedTo.commentId })
      },
      response => {
        if (response) {
          cb();
        }
      }
    );
  };

  const updateCommentHandler = (commentId, content, toggleDialogOpen) => {
    UpdateContractorNoteMutation(commentId, content, response => {
      if (response) {
        toggleDialogOpen();
      }
    });
  };

  return (
    <div className={classes.notes}>
      <div className={classes.containerColumn}>
        <CommentThread
          noMaxHeight
          handleAddComment={handleSubmitComment}
          handleUpdateComment={updateCommentHandler}
          handleReplyToComment={canSubmitComment ? handleSubmitComment : null}
          disabled={!canSubmitComment}
          comments={mapContractorNotes(notes)}
          formTitle={'Notes about Contractor'}
        />
      </div>
    </div>
  );
};

export default withUserContext(
  createRefetchContainer(
    Notes,
    {
      contractor: graphql`
        fragment Notes_contractor on ContractorNode {
          id
          contractorNotes {
            edges {
              node {
                id
                created
                staff {
                  user {
                    id
                    fullName
                  }
                  representativeImageUrl
                }
                content
                contentEditedAt
                repliedTo {
                  content
                  created
                  user {
                    fullName
                  }
                }
              }
            }
          }
        }
      `
    },
    graphql`
      query NotesRefetchQuery($id: ID!) {
        contractor(id: $id) {
          ...Notes_contractor
        }
      }
    `
  )
);

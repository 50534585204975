import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import AutoTable from '../../../shared/components/table/AutoTable';

import { CELL_TYPES } from '../../../shared/constants';

const OrganizationStaff = props => {
  const {
    relay: { refetch },
    organization: {
      organization: { orgStaffs }
    }
  } = props;
  if (!orgStaffs) return <div>Something went wrong.</div>;

  const {
    edges,
    pageInfo: { hasNextPage, endCursor },
    totalCount,
    edgeCount
  } = orgStaffs;

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      fullName,
      title,
      representativeImageUrl,
      phone,
      city,
      timezone,
      user: { email }
    } = edge.node;

    return {
      node: {
        id,
        lastName: { name: fullName, imageUrl: representativeImageUrl },
        title,
        email,
        phone,
        city,
        timezone
      }
    };
  });

  const onChangeHandler = variables => {
    refetch(variables);
  };

  return (
    <AutoTable
      initialOrderBy="lastName"
      rowTemplate={[
        { name: 'lastName', type: CELL_TYPES.avatar, label: 'Name', sortable: true },
        { name: 'title', label: 'Title' },
        { name: 'email', label: 'Email' },
        { name: 'phone', label: 'Phone Number' },
        { name: 'city', label: 'Location' },
        { name: 'timezone', label: 'Timezone' }
      ]}
      edges={flattenedEdges || []}
      onChangeHandler={onChangeHandler}
      paginationProps={{
        hasNextPage,
        endCursor,
        totalCount,
        edgeCount
      }}
    />
  );
};

export default createRefetchContainer(
  OrganizationStaff,
  {
    organization: graphql`
      fragment OrganizationStaff_organization on Query
      @argumentDefinitions(
        id: { type: "ID!" }
        first: { type: "Int" }
        orderBy: { type: "String", defaultValue: "lastName" }
        after: { type: "String" }
      ) {
        organization(id: $id) {
          name
          orgStaffs(first: $first, orderBy: $orderBy, after: $after) {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                fullName
                title
                representativeImageUrl
                phone
                city
                timezone
                user {
                  email
                }
              }
            }
          }
        }
      }
    `
  },
  graphql`
    query OrganizationStaffRefetchQuery($id: ID!, $first: Int, $orderBy: String, $after: String) {
      ...OrganizationStaff_organization
        @arguments(id: $id, first: $first, orderBy: $orderBy, after: $after)
    }
  `
);

/**
 * @flow
 * @relayHash 4f7ec67444935086561e6ca842b44760
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InvoicesList_invoices$ref = any;
export type InvoicesListQueryVariables = {|
  first?: ?number,
  after?: ?string,
  invoiceId?: ?string,
  jobName?: ?string,
  status?: ?string,
  contact?: ?string,
|};
export type InvoicesListQueryResponse = {|
  +$fragmentRefs: InvoicesList_invoices$ref
|};
export type InvoicesListQuery = {|
  variables: InvoicesListQueryVariables,
  response: InvoicesListQueryResponse,
|};
*/

/*
query InvoicesListQuery(
  $first: Int
  $after: String
  $invoiceId: String
  $jobName: String
  $status: String
  $contact: String
) {
  ...InvoicesList_invoices_Ncswc
}

fragment InvoicesList_invoices_Ncswc on Query {
  allInvoices(orderBy: "-created", after: $after, first: $first, invoiceId: $invoiceId, jobName: $jobName, status: $status, contact: $contact) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        identifier
        created
        verificationDate
        job {
          id
          name
          staffCoordinator {
            fullName
            representativeImageUrl
            id
          }
          currencyCode
          totalValue
        }
        status
        amount
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'after',
        type: 'String',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'invoiceId',
        type: 'String',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'jobName',
        type: 'String',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'status',
        type: 'String',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'contact',
        type: 'String',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'Variable',
      name: 'after',
      variableName: 'after'
    },
    v2 = {
      kind: 'Variable',
      name: 'contact',
      variableName: 'contact'
    },
    v3 = {
      kind: 'Variable',
      name: 'first',
      variableName: 'first'
    },
    v4 = {
      kind: 'Variable',
      name: 'invoiceId',
      variableName: 'invoiceId'
    },
    v5 = {
      kind: 'Variable',
      name: 'jobName',
      variableName: 'jobName'
    },
    v6 = {
      kind: 'Variable',
      name: 'status',
      variableName: 'status'
    },
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'InvoicesListQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'FragmentSpread',
          name: 'InvoicesList_invoices',
          args: [
            (v1 /*: any*/),
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            (v6 /*: any*/)
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'InvoicesListQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'allInvoices',
          storageKey: null,
          args: [
            (v1 /*: any*/),
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/),
            {
              kind: 'Literal',
              name: 'orderBy',
              value: '-created'
            },
            (v6 /*: any*/)
          ],
          concreteType: 'InvoiceNodeConnection',
          plural: false,
          selections: [
            {
              kind: 'ScalarField',
              alias: null,
              name: 'totalCount',
              args: null,
              storageKey: null
            },
            {
              kind: 'ScalarField',
              alias: null,
              name: 'edgeCount',
              args: null,
              storageKey: null
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'pageInfo',
              storageKey: null,
              args: null,
              concreteType: 'PageInfo',
              plural: false,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'hasNextPage',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'hasPreviousPage',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'startCursor',
                  args: null,
                  storageKey: null
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'endCursor',
                  args: null,
                  storageKey: null
                }
              ]
            },
            {
              kind: 'LinkedField',
              alias: null,
              name: 'edges',
              storageKey: null,
              args: null,
              concreteType: 'InvoiceNodeEdge',
              plural: true,
              selections: [
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'node',
                  storageKey: null,
                  args: null,
                  concreteType: 'InvoiceNode',
                  plural: false,
                  selections: [
                    (v7 /*: any*/),
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'identifier',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'created',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'verificationDate',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'job',
                      storageKey: null,
                      args: null,
                      concreteType: 'JobNode',
                      plural: false,
                      selections: [
                        (v7 /*: any*/),
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'name',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'staffCoordinator',
                          storageKey: null,
                          args: null,
                          concreteType: 'StaffNode',
                          plural: false,
                          selections: [
                            {
                              kind: 'ScalarField',
                              alias: null,
                              name: 'fullName',
                              args: null,
                              storageKey: null
                            },
                            {
                              kind: 'ScalarField',
                              alias: null,
                              name: 'representativeImageUrl',
                              args: null,
                              storageKey: null
                            },
                            (v7 /*: any*/)
                          ]
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'currencyCode',
                          args: null,
                          storageKey: null
                        },
                        {
                          kind: 'ScalarField',
                          alias: null,
                          name: 'totalValue',
                          args: null,
                          storageKey: null
                        }
                      ]
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'status',
                      args: null,
                      storageKey: null
                    },
                    {
                      kind: 'ScalarField',
                      alias: null,
                      name: 'amount',
                      args: null,
                      storageKey: null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'InvoicesListQuery',
      id: null,
      text:
        'query InvoicesListQuery(\n  $first: Int\n  $after: String\n  $invoiceId: String\n  $jobName: String\n  $status: String\n  $contact: String\n) {\n  ...InvoicesList_invoices_Ncswc\n}\n\nfragment InvoicesList_invoices_Ncswc on Query {\n  allInvoices(orderBy: "-created", after: $after, first: $first, invoiceId: $invoiceId, jobName: $jobName, status: $status, contact: $contact) {\n    totalCount\n    edgeCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        identifier\n        created\n        verificationDate\n        job {\n          id\n          name\n          staffCoordinator {\n            fullName\n            representativeImageUrl\n            id\n          }\n          currencyCode\n          totalValue\n        }\n        status\n        amount\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'e4749c4ff7cfa63d1a10ed4704ce263b';
module.exports = node;

import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Typography, DialogContentText } from '@material-ui/core';
import { DialogActions } from '../../../../shared/components/common/Dialog';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import FormikTextField from '../../../../shared/components/form/FormikTextField';
import { successToast } from '../../../../shared/toasts';
import CreateRoleMutation from './mutations/CreateRoleMutation';
import FormikRolesSelect from './roleTypes/FormikRolesSelect';

const CreateRoleForm = props => {
  const { handleClose, toRefetchData } = props;

  const handleSubmit = (fields, { setSubmitting, setErrors }) => {
    CreateRoleMutation(fields.id, fields.name, (response, errors) => {
      if (errors && errors[0].fields) {
        setSubmitting(false);
        setErrors(errors[0].fields);
      } else if (response) {
        handleClose();
        toRefetchData();
        successToast(
          'New role was created. Review and customize permissions in the details page.',
          {
            text: 'Go to details page.',
            link: `/admin/staff-management/roles/${response.newOrganizationRole.id}`
          }
        );
      }
    });
  };

  const validateRequired = value => {
    if (!value) return 'Required';
  };

  return (
    <Formik
      initialValues={{
        name: '',
        id: ''
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <DialogContentText component="div">
            <Field
              component={FormikTextField}
              name="name"
              label="Role Name"
              fullWidth
              required
              validate={validateRequired}
            />
            <Box mt={3} mb={1}>
              <Typography variant="body1">
                To create a Role, select an existing Role to base its permissions on.
              </Typography>
            </Box>
            <Field
              component={FormikRolesSelect}
              onlyDefaultRoles={true}
              name="id"
              label="Base Role"
              required
              validate={validateRequired}
            />
          </DialogContentText>
          <DialogActions>
            <GlobalButton variant="transparent" handleClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GlobalButton>
            <GlobalButton handleClick={submitForm} disabled={isSubmitting} loading={isSubmitting}>
              Confirm
            </GlobalButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default CreateRoleForm;

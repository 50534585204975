/**
 * @flow
 * @relayHash cb7f7b27442480309770901c0797a43c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceTypeEnum = "AWEBASE" | "CUSTOM" | "%future added value";
export type PreviewInvoiceInput = {|
  jobId: string,
  invoiceType: InvoiceTypeEnum,
  amount: any,
  identifier?: ?string,
  paymentTerm?: ?string,
  vatPercentage?: ?number,
  witholdingPercentage?: ?number,
  message?: ?string,
  clientMutationId?: ?string,
|};
export type PreviewInvoiceMutationVariables = {|
  input: PreviewInvoiceInput
|};
export type PreviewInvoiceMutationResponse = {|
  +previewInvoice: ?{|
    +clientMutationId: ?string,
    +invoicePreview: ?string,
  |}
|};
export type PreviewInvoiceMutation = {|
  variables: PreviewInvoiceMutationVariables,
  response: PreviewInvoiceMutationResponse,
|};
*/

/*
mutation PreviewInvoiceMutation(
  $input: PreviewInvoiceInput!
) {
  previewInvoice(input: $input) {
    clientMutationId
    invoicePreview
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'PreviewInvoiceInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'previewInvoice',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'PreviewInvoicePayload',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'clientMutationId',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'invoicePreview',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'PreviewInvoiceMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'PreviewInvoiceMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'PreviewInvoiceMutation',
      id: null,
      text:
        'mutation PreviewInvoiceMutation(\n  $input: PreviewInvoiceInput!\n) {\n  previewInvoice(input: $input) {\n    clientMutationId\n    invoicePreview\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'f95f0cb209534d4217912f695e7e5d58';
module.exports = node;

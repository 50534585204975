import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

const mutation = graphql`
  mutation CreateProductMutation($input: CreateProductInput!) {
    createProduct(input: $input) {
      newProduct {
        id
      }
    }
  }
`;

export default (title, code, description, callback) => {
  const variables = {
    input: {
      title,
      code,
      description
    }
  };

  commitMutation(getEnvironment(), {
    mutation,
    variables,
    onCompleted: response => {
      callback(response.createProduct);
    },
    onError: err => console.error(err)
  });
};

/**
 * @flow
 * @relayHash bf24462a1f223e001ca03b3d0c89986d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveSolicitationContractorInput = {|
  solicitationId: string,
  contractorId: string,
  clientMutationId?: ?string,
|};
export type RemoveSolicitationContractorMutationVariables = {|
  input: RemoveSolicitationContractorInput
|};
export type RemoveSolicitationContractorMutationResponse = {|
  +removeSolicitationContractor: ?{|
    +isUpdated: ?boolean,
    +solicitation: ?{|
      +id: string,
      +numberOfContractors: ?number,
    |},
  |}
|};
export type RemoveSolicitationContractorMutation = {|
  variables: RemoveSolicitationContractorMutationVariables,
  response: RemoveSolicitationContractorMutationResponse,
|};
*/


/*
mutation RemoveSolicitationContractorMutation(
  $input: RemoveSolicitationContractorInput!
) {
  removeSolicitationContractor(input: $input) {
    isUpdated
    solicitation {
      id
      numberOfContractors
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveSolicitationContractorInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeSolicitationContractor",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveSolicitationContractorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isUpdated",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "solicitation",
        "storageKey": null,
        "args": null,
        "concreteType": "SolicitationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numberOfContractors",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RemoveSolicitationContractorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RemoveSolicitationContractorMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RemoveSolicitationContractorMutation",
    "id": null,
    "text": "mutation RemoveSolicitationContractorMutation(\n  $input: RemoveSolicitationContractorInput!\n) {\n  removeSolicitationContractor(input: $input) {\n    isUpdated\n    solicitation {\n      id\n      numberOfContractors\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bdd34d198de4bcae168728da1606246b';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import SearchableDropdown from '../../../../shared/components/form/SearchableDropdown';

// shows only staff members eligible to become an Owner
const newOwnerQuery = graphql`
  query SearchableNewOwnerDropdownQuery(
    $staffName: String
    $first: Int
    $orderBy: String
    $includeInactive: Boolean
    $eligibleOwner: Boolean
  ) {
    staffByOrg(
      staffName: $staffName
      orderBy: $orderBy
      first: $first
      includeInactive: $includeInactive
      eligibleOwner: $eligibleOwner
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

const SearchableNewOwnerDropdown = props => {
  const getFetchVariables = inputValue => {
    return {
      staffName: inputValue,
      first: 10,
      orderBy: 'lastName',
      includeInactive: false,
      eligibleOwner: true
    };
  };

  const formatOptions = staffList => {
    if (!staffList || !staffList.staffByOrg) {
      return [];
    }

    return staffList.staffByOrg.edges.map(staff => {
      return {
        value: staff.node.id,
        label: staff.node.fullName
      };
    });
  };

  const { onChange, ...otherProps } = props;

  return (
    <SearchableDropdown
      resultKey="staffByOrg"
      query={newOwnerQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={onChange}
      label="New owner"
      {...otherProps}
    />
  );
};

export default SearchableNewOwnerDropdown;

/**
 * @flow
 * @relayHash 4c7f69e4ff6c0764985d0d7c2ee9ecc5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
export type RevokeJobInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type RevokeJobMutationVariables = {|
  input: RevokeJobInput
|};
export type RevokeJobMutationResponse = {|
  +revokeJob: ?{|
    +isRevoked: ?boolean,
    +job: ?{|
      +status: JobStatus,
      +deliverables: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +state: DeliverableState,
            +assignedContractor: ?{|
              +representativeImageUrl: ?string,
              +fullName: ?string,
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type RevokeJobMutation = {|
  variables: RevokeJobMutationVariables,
  response: RevokeJobMutationResponse,
|};
*/


/*
mutation RevokeJobMutation(
  $input: RevokeJobInput!
) {
  revokeJob(input: $input) {
    isRevoked
    job {
      status
      deliverables {
        edges {
          node {
            state
            assignedContractor {
              representativeImageUrl
              fullName
              id
            }
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RevokeJobInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isRevoked",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RevokeJobMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "revokeJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RevokeJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverables",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "assignedContractor",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractorNode",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RevokeJobMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "revokeJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RevokeJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverables",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "assignedContractor",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractorNode",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ]
                          },
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "RevokeJobMutation",
    "id": null,
    "text": "mutation RevokeJobMutation(\n  $input: RevokeJobInput!\n) {\n  revokeJob(input: $input) {\n    isRevoked\n    job {\n      status\n      deliverables {\n        edges {\n          node {\n            state\n            assignedContractor {\n              representativeImageUrl\n              fullName\n              id\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a3de5610f493224d35fc92819903a983';
module.exports = node;

import { fetchQuery } from 'react-relay';
import PropTypes from 'prop-types';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

const getUserData = callback => {
  const query = graphql`
    query getUserDataQuery {
      currentUser {
        id
        firstName
        lastName
        email
        message
        representativeImageUrl
        orgStaff {
          role {
            id
            name
          }
          isOwner
          allowedActions
          id
          showFreelancerFieldsPopup
          organization {
            id
            configuration
            currencyCode
            name
            tierLabel
          }
        }
      }
    }
  `;

  fetchQuery(getEnvironment(), query, {}).then(data => {
    callback(data.currentUser);
  });
};

getUserData.PropTypes = {
  callback: PropTypes.func.isRequired
};

export default getUserData;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../../shared/utils/helpers';

const UpdateOrganizationMutation = (variables, onCompleted, file) => {
  commitMutation(getEnvironment(), {
    mutation: graphql`
      mutation UpdateOrganizationMutation($input: UpdateOrganizationInput!) {
        updateOrganization(input: $input) {
          organization {
            id
            name
            address1
            address2
            city
            state
            zipcode
            country {
              code
              name
            }
            website
            representativeImageUrl
            email
            phone
            financeEmail
            contractEmail
          }
        }
      }
    `,
    variables: {
      input: variables
    },
    uploadables: {
      repr_image: file
    },
    onCompleted
  });
};

export default UpdateOrganizationMutation;

import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { withRouter } from 'react-router';

import {
  computePermissionRole,
  getActiveReleaseDeadlineWarningColor
} from '../../../../shared/utils/helpers';
import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import AutoTabList from '../../../../shared/components/UI/AutoTabList';
import CategoryTitleHeader from '../../../../shared/components/UI/CategoryTitleHeader';
import { ReactComponent as ReleasesIcon } from '../../../../shared/images/releases.svg';
import { useDetailsStyles } from '../../../../shared/styles/common/useDetailsStyles';
import { formatDateDisplay } from '../../../../shared/utils/formatters';
import { getDateDisplayFormatFromUserContext } from '../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS } from '../../../../shared/constants';
import { ReactComponent as CalendarIcon } from '../../../../shared/images/calendar.svg';
import BottomSubtitle from '../../../../shared/components/UI/BottomSubtitle';
import ThumbnailImage from '../../../../shared/components/UI/ThumbnailImage';
import ReleaseDeliverablesTab from './ReleaseDeliverablesTab';
import ReleaseBudgetTab from './ReleaseBudgetTab';
import ReleaseToolsTab from './ReleaseToolsTab';
import ReleaseDetailsTab from './ReleaseDetailsTab';
import ProductReleaseBreadcrumb from '../../../../shared/components/UI/ProductReleaseBreadcrumb';

const ReleaseDetails = props => {
  const classes = useDetailsStyles();
  const {
    release: {
      id: releaseId,
      product: { id: productId, title: productTitle, representativeImageUrl },
      name: releaseTitle,
      deadline: releaseDeadline,
      isActive,
      hasIncompleteDeliverables
    },
    userContext: {
      orgStaff: {
        allowedActions,
        organization: { configuration }
      }
    },
    handleContextActionOptions,
    refetchCounter
  } = props;

  const formattedDeadline = formatDateDisplay(
    releaseDeadline,
    getDateDisplayFormatFromUserContext(props.userContext)
  );

  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    handleContextActionOptions([
      {
        name: 'batchReleaseDetails',
        data: {
          disabledProps: { disabled: !isActive },
          releaseId,
          releaseName: releaseTitle,
          productTitle
        }
      },
      {
        name: 'releaseDetailsDeliverable',
        data: {
          disabledProps: { disabled: !isActive },
          releaseId,
          releaseName: releaseTitle,
          productTitle
        }
      },
      {
        name: 'duplicateRelease',
        data: {
          disabledProps: { disabled: !isActive },
          copyOfRelease: releaseId,
          productId,
          productTitle
        }
      }
    ]);
    return () => {
      handleContextActionOptions();
    };
  }, [props.history.location.pathname, isActive, releaseId, releaseTitle, productTitle]);

  const toggleEditHandler = () => {
    setEditing(!isEditing);
  };

  const canViewBudget = () => {
    return (
      computePermissionRole(ALLOWED_ACTIONS.RELEASE_VIEW_BUDGET, allowedActions) ||
      computePermissionRole(ALLOWED_ACTIONS.RELEASE_EDIT_BUDGET, allowedActions)
    );
  };

  return (
    <Fragment>
      <div
        className={clsx(
          classes.containerSpaceBetween,
          classes.alignFlexStart,
          classes.marginBottom
        )}
      >
        <div className={clsx(classes.container, classes.alignItemsCenter)}>
          <CategoryTitleHeader
            icon={ReleasesIcon}
            renderTop={() => (
              <ProductReleaseBreadcrumb
                config={{
                  product: productTitle,
                  productLink: `/products/${productId}`
                }}
              />
            )}
            title={releaseTitle}
            renderBottom={() => (
              <BottomSubtitle
                colorProp={getActiveReleaseDeadlineWarningColor(
                  configuration,
                  releaseDeadline,
                  hasIncompleteDeliverables
                )}
                icon={<CalendarIcon />}
              >
                Key Date: {formattedDeadline}
              </BottomSubtitle>
            )}
            link="/releases"
          />
          <ThumbnailImage
            alt="Title Image"
            width="160px"
            height="100px"
            src={representativeImageUrl}
            className={classes.productThumbnailImage}
          />
        </div>
      </div>
      <AutoTabList>
        <ReleaseDetailsTab
          label="Details"
          toggleEditHandler={toggleEditHandler}
          isEditing={isEditing}
          disabled={isEditing}
        />
        <ReleaseDeliverablesTab
          label="Deliverables"
          refetchCounter={refetchCounter}
          disabled={isEditing}
        />
        <ReleaseBudgetTab
          isEditing={isEditing}
          setEditing={setEditing}
          label="Budget"
          disabled={isEditing || !canViewBudget()}
        />
        <ReleaseToolsTab label="Tools" disabled={isEditing} refetchCounter={refetchCounter} />
      </AutoTabList>
    </Fragment>
  );
};

export default withRouter(
  withUserContext(
    createFragmentContainer(ReleaseDetails, {
      release: graphql`
        fragment ReleaseDetails_release on ReleaseNode {
          id
          name
          product {
            id
            code
            title
            representativeImageUrl
          }
          deadline
          isActive
          hasIncompleteDeliverables
        }
      `
    })
  )
);

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation UpdateRoleBatchStaffUsersMutation($input: UpdateRoleBatchStaffUsersInput!) {
    updateRoleBatchStaffUsers(input: $input) {
      updatedStaffUsers {
        id
        roleLabel
        role {
          id
          numberOfStaff
        }
      }
      oldRoles {
        id
        numberOfStaff
      }
    }
  }
`;

const UpdateRoleBatchStaffUsersMutation = (input, callback) => {
  const config = {
    mutation,
    variables: { input },
    onCompleted: response => {
      callback(response.updateRoleBatchStaffUsers);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default UpdateRoleBatchStaffUsersMutation;

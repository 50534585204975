import React, { PureComponent } from 'react';
import { TableRow as MUITableRow } from '@material-ui/core';
import PropTypes from 'prop-types';

import TableCellClickable from './TableCellClickable';
import TableCellLinkWithIcon from './TableCellLinkWithIcon';
import TableCellInfo from './TableCellInfo';
import StyledTableCell from './StyledTableCell';
import TableCellAvatarWithText from './TableCellAvatarWithText';
import TableCellThumbnailWithText from './TableCellThumbnailWithText';
import TableCellListWithBadge from './TableCellListWithBadge';
import TableCellListWithMultiple from './TableCellListWithMultiple';
import TableCellRating from './TableCellRating';
import TableCellAddUser from './TableCellAddUser';
import TableCellMessage from './TableCellMessage';
import TableCellCheckbox from './TableCellCheckbox';
import TableCellTrash from './TableCellTrash';
import TableCellVisibility from './TableCellVisibility';
import TableCellDuplicate from './TableCellDuplicate';
import TableCellFlag from './TableCellFlag';
import TableCellEdit from './TableCellEdit';
import TableCellFileDownload from '../../../shared/components/table/TableCellFileDownload';
import TableCellActions from './TableCellActions';
import { CELL_TYPES } from '../../constants';
import TableCellStatus from './TableCellStatus';
import TableCellContract from './TableCellContract';
import TableCellUpDown from './TableCellUpDown';
import TableCellAmount from './TableCellAmount';
import TableCellDate from './TableCellDate';
import TableCellRelationLink from './TableCellRelationLink';
import TableCellBriefcase from './TableCellBriefcase';
import TableCellWarning from './TableCellWarning';

class AutoTableRow extends PureComponent {
  onClickLink = () => {
    this.props.handleLinkClick(this.props.id);
  };
  onClickLinkWithIcon = id => {
    this.props.handleLinkClickWithIcon(id);
  };

  onClickThumbnail = () => {
    this.props.handleThumbnailClick(this.props.id);
  };

  onClickAddUser = () => {
    this.props.handleAddUserClick(this.props.id, this.props.setFieldValue, this.props.values);
  };

  onCheckRow = event => {
    this.props.handleCheckRow(this.props.id, event.shiftKey);
  };

  onClickMessage = () => {
    this.props.handleMessageClick && this.props.handleMessageClick(this.props.id);
  };

  onClickDuplicate = () => {
    this.props.handleDuplicateClick(this.props.id);
  };

  onClickContract = () => {
    this.props.handleContractClick(this.props.id);
  };

  onClickActionDuplicate = () => {
    this.props.handleActionDuplicateClick(this.props.id);
  };

  onClickActionTrash = () => {
    this.props.handleActionTrashClick(this.props.id);
  };

  onClickUp = (values, setFieldValue) => {
    this.props.handleUpClick(this.props.id, values, setFieldValue);
  };

  onClickDown = (values, setFieldValue) => {
    this.props.handleDownClick(this.props.id, values, setFieldValue);
  };

  onClickBriefcase = () => {
    this.props.handleBriefcaseClick(this.props.id);
  };

  render() {
    const { rowTemplate, selected, rowIndex } = this.props;
    return (
      <MUITableRow selected={selected}>
        {rowTemplate.map((column, index) => {
          // for some simple text cells sometimes we need to pass other props (colorProp for example)
          // this check lets us know to render simple value or to expect an object (check for ex. link, amount etc)
          const isColumnValueString =
            typeof this.props[column.name] === 'string' ||
            typeof this.props[column.name] === 'number' ||
            !this.props[column.name];

          if (column.renderCell) {
            return column.renderCell({
              index,
              rowIndex,
              id: this.props.id,
              value: this.props[column.name],
              columnName: column.name
            });
          }

          switch (column.type) {
            case CELL_TYPES.link:
              return (
                <TableCellClickable
                  key={index}
                  handleClick={
                    column.handleClick ? () => column.handleClick(this.props) : this.onClickLink
                  }
                  truncate={column.truncate}
                  colorProp={!isColumnValueString && this.props[column.name].colorProp}
                  label={column.label}
                  data-intercom-target={column.label + ' ' + rowIndex}
                >
                  {isColumnValueString ? this.props[column.name] : this.props[column.name].cellText}
                </TableCellClickable>
              );
            case CELL_TYPES.relationLink:
              return (
                <TableCellRelationLink
                  key={index}
                  cellText={this.props[column.name].cellText}
                  cellLink={this.props[column.name].cellLink}
                  truncate={column.truncate}
                  colorProp={!isColumnValueString && this.props[column.name].colorProp}
                  data-intercom-target={column.label + ' ' + rowIndex}
                />
              );
            case CELL_TYPES.linkWithIcon:
              return this.props[column.name] && this.props[column.name].name ? (
                <TableCellLinkWithIcon
                  key={index}
                  iconName={this.props[column.name].iconName}
                  handleClick={() => this.onClickLinkWithIcon(this.props[column.name].name)}
                  truncate={column.truncate}
                  data-intercom-target={column.label + ' ' + rowIndex}
                  tooltip={this.props[column.name].tooltip}
                />
              ) : (
                <StyledTableCell key={index}>-</StyledTableCell>
              );
            case CELL_TYPES.avatar:
              return this.props[column.name] ? (
                <TableCellAvatarWithText
                  key={index}
                  onClick={column.onClick}
                  id={this.props[column.name].id}
                  cellText={this.props[column.name].name}
                  avatarSrc={this.props[column.name].imageUrl}
                  withBadge={this.props[column.name].withBadge}
                  colorProp={this.props[column.name].colorProp}
                  border={this.props[column.name].border}
                  data-intercom-target={`${column.label} ${rowIndex}`}
                  onlyTooltip={column.onlyTooltip}
                  icon={column.icon}
                />
              ) : (
                <StyledTableCell key={index}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>-</div>
                </StyledTableCell>
              );
            case CELL_TYPES.thumbnail:
              const handleThumb = typeof this.props.handleThumbnailClick !== 'undefined';
              return this.props[column.name] ? (
                <TableCellThumbnailWithText
                  key={index}
                  handleClick={handleThumb ? this.onClickThumbnail : null}
                  cellText={this.props[column.name].name}
                  avatarSrc={this.props[column.name].imageUrl}
                  withBadge={this.props[column.name].withBadge}
                  clickable={handleThumb}
                  onlyTooltip={column.onlyTooltip}
                  colorProp={this.props[column.name].colorProp}
                  data-intercom-target={column.label + ' ' + rowIndex}
                />
              ) : (
                <StyledTableCell key={index}>-</StyledTableCell>
              );
            case CELL_TYPES.info:
              return (
                <TableCellInfo
                  key={index}
                  id={this.props.id}
                  onClick={column.onClick}
                  // did not want to refactor all components that use this, hence only if this property is
                  // specifically false, the icon is hidden, it it's skipped or true, it will be shown
                  hidden={this.props[column.name] === false || column.hidden}
                />
              );
            case CELL_TYPES.amount:
              return (
                <TableCellAmount
                  key={index}
                  // will have it where we show amount in possibly foreign currency
                  // if value is converted to home currency we should get currency code from context and pass null value here
                  currencyCode={this.props.currencyCode}
                  hideTrend={column.hideTrend}
                  {...(typeof this.props[column.name] === 'object'
                    ? this.props[column.name]
                    : { amount: this.props[column.name] })}
                />
              );
            case CELL_TYPES.listWithBadge:
              return <TableCellListWithBadge key={index} items={this.props[column.name]} />;
            case CELL_TYPES.listWithMultiple:
              return (
                <TableCellListWithMultiple
                  key={index}
                  multipleValue={column.multipleValue}
                  items={this.props[column.name]}
                  truncate={column.truncate}
                />
              );
            case CELL_TYPES.rating:
              return <TableCellRating key={index} rating={this.props[column.name]} />;
            case CELL_TYPES.addUser:
              return (
                <TableCellAddUser
                  key={index}
                  selected={this.props[column.name]}
                  onClick={this.onClickAddUser}
                />
              );
            case CELL_TYPES.date:
              return <TableCellDate key={index} date={this.props[column.name]} />;
            case CELL_TYPES.dateWithWarning:
              return (
                <TableCellDate
                  key={index}
                  colorProp={this.props[column.name].color}
                  date={this.props[column.name].date}
                />
              );
            case CELL_TYPES.status:
              return (
                <TableCellStatus
                  key={index}
                  statusProps={{
                    statusCode: this.props[column.name].code,
                    reviewStep: this.props[column.name].reviewStep,
                    statusTypes: column.statusTypes,
                    variant: column.variant
                  }}
                />
              );
            case CELL_TYPES.message:
              return (
                <TableCellMessage
                  key={index}
                  handleClick={this.onClickMessage}
                  iconProps={this.props[column.name]}
                />
              );
            case CELL_TYPES.contract:
              return (
                <TableCellContract
                  key={index}
                  handleClick={this.onClickContract}
                  contract={this.props[column.name]}
                />
              );
            case CELL_TYPES.checkbox:
              return (
                <TableCellCheckbox
                  key={index}
                  isChecked={this.props.isChecked}
                  onCheck={this.onCheckRow}
                  labelledBy={this.props.labelledBy}
                />
              );
            case CELL_TYPES.visibility:
              return <TableCellVisibility key={index} isPublic={this.props[column.name]} />;
            case CELL_TYPES.duplicate:
              return (
                <TableCellDuplicate
                  key={index}
                  onClick={this.onClickDuplicate}
                  hidden={column.hidden}
                />
              );
            case CELL_TYPES.trash:
              return (
                <TableCellTrash
                  key={index}
                  id={this.props.id}
                  rowIndex={this.props.rowIndex}
                  handleClick={column.onClick}
                  hidden={column.hidden || this.props.hideTrash}
                  disabled={column.disabled}
                  withConfirm={column.withConfirm}
                  renderConfirmContent={column.renderConfirmContent}
                  fileName={this.props[column.name]}
                  tooltip={column.tooltip}
                />
              );
            case CELL_TYPES.flags:
              return (
                <TableCellFlag
                  key={index}
                  doNotHire={this.props[column.name].doNotHire}
                  deactivated={this.props[column.name].deactivated}
                  hasConflictingContractor={this.props[column.name].hasConflictingContractor}
                />
              );
            case CELL_TYPES.actions:
              return (
                <TableCellActions
                  key={index}
                  onClickDuplicate={this.onClickActionDuplicate}
                  onClickTrash={this.onClickActionTrash}
                  hideIconDuplicate={!this.props[column.name].allowDuplicate}
                  hideIconTrash={!this.props[column.name].allowDelete}
                  duplicateTooltip={this.props[column.name].duplicateTooltip}
                  trashTooltip={this.props[column.name].trashTooltip}
                ></TableCellActions>
              );

            case CELL_TYPES.edit:
              return <TableCellEdit key={index} handleClick={column.onClick} id={this.props.id} />;
            case CELL_TYPES.upDown:
              return (
                <TableCellUpDown
                  key={index}
                  onClickUp={() =>
                    this.onClickUp(
                      this.props[column.name].values,
                      this.props[column.name].setFieldValue
                    )
                  }
                  onClickDown={() =>
                    this.onClickDown(
                      this.props[column.name].values,
                      this.props[column.name].setFieldValue
                    )
                  }
                  hideIconUp={!this.props[column.name].allowMoveUp}
                  hideIconDown={!this.props[column.name].allowMoveDown}
                />
              );
            case CELL_TYPES.fileDownload:
              return (
                <TableCellFileDownload
                  key={index}
                  index={rowIndex}
                  fileProps={this.props[column.name]}
                  disabled={column.disabled}
                  icon={column.icon}
                  tooltipTitle={column.tooltip}
                />
              );
            case CELL_TYPES.briefcase:
              return (
                <TableCellBriefcase
                  key={index}
                  handleClick={this.onClickBriefcase}
                  valueProps={this.props[column.name]}
                />
              );

            case CELL_TYPES.warning:
              return (
                <TableCellWarning
                  key={index}
                  hidden={!this.props[column.name]}
                  tooltip={column.tooltip}
                />
              );
            default:
              return (
                <StyledTableCell
                  key={index}
                  truncate={column.truncate}
                  colorProp={!isColumnValueString && this.props[column.name].colorProp}
                  align={column.align}
                >
                  {column.renderContent
                    ? column.renderContent(column.name, this.props[column.name])
                    : isColumnValueString
                    ? this.props[column.name]
                    : this.props[column.name].cellText || '-'}
                </StyledTableCell>
              );
          }
        })}
      </MUITableRow>
    );
  }
}

AutoTableRow.propTypes = {
  handleLinkClick: PropTypes.func,
  handleAddUserClick: PropTypes.func,
  handleMessageClick: PropTypes.func,
  handleAvatarClick: PropTypes.func,
  handleCheckRow: PropTypes.func,
  handleRemoveClick: PropTypes.func,
  handleVisibilityClick: PropTypes.func,
  handleDuplicateClick: PropTypes.func,
  handleContractClick: PropTypes.func,
  handleActionDuplicateClick: PropTypes.func,
  handleActionTrashClick: PropTypes.func,
  handleUpClick: PropTypes.func,
  handleDownClick: PropTypes.func,
  rowTemplate: PropTypes.array
};

export default AutoTableRow;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

const ValueContainer = props => {
  const { isMulti, children, selectProps, isDisabled } = props;

  return isMulti ? (
    <Grid container spacing={1}>
      {children}
    </Grid>
  ) : (
    <div
      className={clsx(selectProps.classes.valueContainer, {
        [selectProps.classes.disabled]: isDisabled
      })}
    >
      {children}
    </div>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

export default ValueContainer;

import React from 'react';

import Header from './DeliverableFilesHeader';
import DeliverableFilesList from './DeliverableFilesList';

const DeliverableFiles = ({ relayProps }) => {
  return (
    <>
      <Header storageInfo={relayProps.organization} />
      <DeliverableFilesList
        files={relayProps}
        storageClasses={relayProps.storageClasses}
        deliverableStateTypes={relayProps.deliverableStateTypes}
      />
    </>
  );
};

export default DeliverableFiles;

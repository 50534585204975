/**
 * @flow
 * @relayHash 8d3d41852182356ed280f7f7685e2362
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OverviewContent_organization$ref = any;
export type OverviewTabQueryVariables = {||};
export type OverviewTabQueryResponse = {|
  +organization: ?{|
    +$fragmentRefs: OverviewContent_organization$ref
  |}
|};
export type OverviewTabQuery = {|
  variables: OverviewTabQueryVariables,
  response: OverviewTabQueryResponse,
|};
*/


/*
query OverviewTabQuery {
  organization {
    ...OverviewContent_organization
    id
  }
}

fragment OverviewContent_organization on OrganizationNode {
  id
  activeStorageLimit
  archiveStorageLimit
  latestActiveMetricsData {
    total
    data {
      groupName
      metricValues {
        name
        value
      }
    }
  }
  latestArchiveMetricsData {
    total
    data {
      groupName
      metricValues {
        name
        value
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "total",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "data",
    "storageKey": null,
    "args": null,
    "concreteType": "MetricsDataValueGroup",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "groupName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "metricValues",
        "storageKey": null,
        "args": null,
        "concreteType": "MetricsDataValue",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OverviewTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OverviewContent_organization",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OverviewTabQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeStorageLimit",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "archiveStorageLimit",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "latestActiveMetricsData",
            "storageKey": null,
            "args": null,
            "concreteType": "MetricsData",
            "plural": false,
            "selections": (v0/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "latestArchiveMetricsData",
            "storageKey": null,
            "args": null,
            "concreteType": "MetricsData",
            "plural": false,
            "selections": (v0/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OverviewTabQuery",
    "id": null,
    "text": "query OverviewTabQuery {\n  organization {\n    ...OverviewContent_organization\n    id\n  }\n}\n\nfragment OverviewContent_organization on OrganizationNode {\n  id\n  activeStorageLimit\n  archiveStorageLimit\n  latestActiveMetricsData {\n    total\n    data {\n      groupName\n      metricValues {\n        name\n        value\n      }\n    }\n  }\n  latestArchiveMetricsData {\n    total\n    data {\n      groupName\n      metricValues {\n        name\n        value\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ef53d0f4babb099c7ea7fa0d8dddeba7';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DetailsSection from '../../../shared/components/common/DetailsSection';

const ToolActions = ({ actions }) => {
  const conditionIsFalse = elem => elem.condition === false;
  const allActionsHidden = actions.every(conditionIsFalse);
  return actions.length ? (
    <DetailsSection noBorder title="Actions">
      {allActionsHidden ? (
        <Typography variant="body1">No actions available.</Typography>
      ) : (
        actions.map(action => {
          return action.condition ? (
            <Grid key={action.description} alignItems="center" container spacing={3}>
              <Grid md={2} item>
                {action.actionRender()}
              </Grid>
              <Grid md={1} item></Grid>
              <Grid md={7} item>
                <Typography variant="body1">{action.description}</Typography>
              </Grid>
            </Grid>
          ) : null;
        })
      )}
    </DetailsSection>
  ) : null;
};

export default ToolActions;

ToolActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      actionRender: PropTypes.func.isRequired,
      condition: PropTypes.bool.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired
};

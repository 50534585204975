import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Typography, Link } from '@material-ui/core';
import get from 'lodash/get';

import * as Yup from 'yup';

import FormikTextField from '../../../../shared/components/form/FormikTextField';
import FormikDropzone from '../../../../shared/components/form/formikFields/FormikDropzone';
import { FormikCategorySelect } from '../../shared/dropdowns/CategorySelect';
import useDialogStyles from '../../../../shared/styles/common/useDialogStyles';
import { successToast } from '../../../../shared/toasts';
import GlobalButton from '../../../../shared/components/UI/GlobalButton';
import CreateContractTemplateMutation from './mutations/CreateContractTemplateMutation';
import { ERROR_LABELS } from '../../../../shared/constants';
import ContractTemplateVariableWarningDialog from '../../shared/CotractTemplateVariableWarningDialog';

const CreateContractTemplateForm = ({ handleClose, toRefetchData }) => {
  const classes = useDialogStyles();
  const [pendingConfirmationForm, setPendingConfirmationForm] = useState(null);

  const onSubmit = (values, { setSubmitting }) =>
    new Promise(resolve => {
      const { name, categories, file } = values;
      setSubmitting(true);
      CreateContractTemplateMutation(
        {
          name,
          categories: categories.map(category => category.value),
          ignoreVariablesErrors: pendingConfirmationForm
            ? ['empty', 'not_supported', 'no_signature']
            : ['empty']
        },
        file,
        response => {
          setSubmitting(false);
          resolve(response);
          if (response) {
            if (response.errors) {
              setPendingConfirmationForm({
                errors: JSON.parse(response.errors),
                values
              });
            } else {
              handleClose();
              setTimeout(() => {
                successToast('New contract template has been created.', {
                  text: 'Go to contract template',
                  link: `/admin/contracts/${response.contractTemplate.id}`
                });
              }, 250);
              toRefetchData();
            }
          }
        }
      );
    });

  const templateValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    file: Yup.mixed()
      .required(ERROR_LABELS.fileRequired)
      .test('fileSize', 'File cannot be empty.', value => value && value.size > 0)
  });

  const initialValues = {
    name: '',
    categories: [],
    file: null
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={templateValidationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <ContractTemplateVariableWarningDialog
            isDialogOpen={pendingConfirmationForm !== null}
            onConfirm={({ actions }) => onSubmit(pendingConfirmationForm.values, actions)}
            closeDialog={() => {
              setPendingConfirmationForm(null);
            }}
            errors={get(pendingConfirmationForm, 'errors.contract_variables')}
          />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                required
                name="name"
                component={FormikTextField}
                label="Name"
                placeholder="Template name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                required
                name="file"
                label="Upload a .docx file"
                type="file"
                component={FormikDropzone}
                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="categories"
                component={FormikCategorySelect}
                placeholder="Select categories"
                label="Deliverable Categories"
                fullWidth
                withChipsBelow
              />
            </Grid>
            {/* TODO: update with an actual link*/}
            <Grid item xs={12}>
              <Typography>
                <Link
                  href="https://intercom.help/awebase/en/articles/3783880-add-a-new-contract-template"
                  variant="caption"
                  target="_blank"
                  rel="noopener"
                >
                  Contract Template Documentation
                </Link>
              </Typography>
            </Grid>
          </Grid>
          {/* TODO: create actual loading indicator for forms that take longer to load */}
          {isSubmitting ? <Typography>Loading...</Typography> : null}
          <div className={classes.dialogActions}>
            <GlobalButton
              id="cancelCreateTemplateForm"
              variant="transparent"
              handleClick={handleClose}
            >
              Cancel
            </GlobalButton>
            <GlobalButton
              id="createTemplateSubmit"
              handleClick={submitForm}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Add Contract Template
            </GlobalButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateContractTemplateForm;

/**
 * @flow
 * @relayHash 77f97a75358de7436326572196676201
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrganizationSubscriptionInterval = "ANNUAL" | "MONTHLY" | "%future added value";
export type AddSubscriptionModuleInput = {|
  module: string,
  timestamp?: ?string,
  clientMutationId?: ?string,
|};
export type AddSubscriptionModuleMutationVariables = {|
  input: AddSubscriptionModuleInput
|};
export type AddSubscriptionModuleMutationResponse = {|
  +addSubscriptionModule: ?{|
    +isAdded: ?boolean,
    +organization: ?{|
      +subscription: ?{|
        +numberOfModules: ?number,
        +nextBillingAmount: ?string,
      |},
      +scheduledSubscription: ?{|
        +activeModulesCount: ?number,
        +interval: OrganizationSubscriptionInterval,
        +availableModules: ?$ReadOnlyArray<?{|
          +moduleId: ?string,
          +quantity: ?number,
        |}>,
      |},
      +activeSubscription: ?{|
        +availableModules: ?$ReadOnlyArray<?{|
          +moduleId: ?string,
          +name: ?string,
          +incremental: ?boolean,
          +quantity: ?number,
          +cost: ?$ReadOnlyArray<?{|
            +tier: ?string,
            +price: ?number,
          |}>,
          +blurbText: ?string,
          +description: ?string,
          +imageUrl: ?string,
          +headline: ?string,
          +externalUrl: ?string,
        |}>
      |},
    |},
  |}
|};
export type AddSubscriptionModuleMutation = {|
  variables: AddSubscriptionModuleMutationVariables,
  response: AddSubscriptionModuleMutationResponse,
|};
*/


/*
mutation AddSubscriptionModuleMutation(
  $input: AddSubscriptionModuleInput!
) {
  addSubscriptionModule(input: $input) {
    isAdded
    organization {
      subscription {
        numberOfModules
        nextBillingAmount
      }
      scheduledSubscription {
        activeModulesCount
        interval
        availableModules {
          moduleId
          quantity
        }
        id
      }
      activeSubscription {
        availableModules {
          moduleId
          name
          incremental
          quantity
          cost {
            tier
            price
          }
          blurbText
          description
          imageUrl
          headline
          externalUrl
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddSubscriptionModuleInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdded",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "subscription",
  "storageKey": null,
  "args": null,
  "concreteType": "OrganizationSubscription",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfModules",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nextBillingAmount",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activeModulesCount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "interval",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "moduleId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "availableModules",
  "storageKey": null,
  "args": null,
  "concreteType": "SubscriptionModuleNode",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/)
  ]
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "availableModules",
  "storageKey": null,
  "args": null,
  "concreteType": "SubscriptionModuleNode",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "incremental",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cost",
      "storageKey": null,
      "args": null,
      "concreteType": "SubscriptionModuleCostNode",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tier",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "price",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "blurbText",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "imageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "headline",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "externalUrl",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddSubscriptionModuleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addSubscriptionModule",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddSubscriptionModulePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scheduledSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationSubscriptionNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "activeSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationSubscriptionNode",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddSubscriptionModuleMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addSubscriptionModule",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddSubscriptionModulePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scheduledSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationSubscriptionNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "activeSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationSubscriptionNode",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddSubscriptionModuleMutation",
    "id": null,
    "text": "mutation AddSubscriptionModuleMutation(\n  $input: AddSubscriptionModuleInput!\n) {\n  addSubscriptionModule(input: $input) {\n    isAdded\n    organization {\n      subscription {\n        numberOfModules\n        nextBillingAmount\n      }\n      scheduledSubscription {\n        activeModulesCount\n        interval\n        availableModules {\n          moduleId\n          quantity\n        }\n        id\n      }\n      activeSubscription {\n        availableModules {\n          moduleId\n          name\n          incremental\n          quantity\n          cost {\n            tier\n            price\n          }\n          blurbText\n          description\n          imageUrl\n          headline\n          externalUrl\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'df4cca2f3114f924d9beab821979fdc8';
module.exports = node;

import React from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import * as Yup from 'yup';

import withUserContext from '../../../../shared/contexts/userContext/withUserContext';
import DetailsSection from '../../../../shared/components/common/DetailsSection';
import UpdateStaffProfileMutation from '../mutations/UpdateStaffProfileMutation';
import { yupSchemas } from '../../../../shared/validations';
import FormikEditableImage from '../../../../shared/components/form/formikFields/FormikEditableImage';
import EditableField from '../../../../shared/components/form/fields/EditableField';
import EditToggle from '../../../../shared/components/UI/EditToggle';
import FormikPhoneField from '../../../../shared/components/form/FormikPhoneField';
import Detail from '../../../../shared/components/UI/Detail';

const AccountInformationContent = props => {
  const {
    accountInformation: { currentUser: profileInfo },
    isEditing,
    toggleEditHandler
  } = props;

  const { setUserInfo } = props.userContext;

  const avatarAlt = `${profileInfo.fullName} picture`;

  const submitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);

    const { title, phone, skype, reprImage, reprImageNull } = values;
    let file = reprImage.file;
    UpdateStaffProfileMutation(
      {
        title,
        phone,
        skype,
        reprImageNull
      },
      file,
      response => {
        setSubmitting(false);
        if (response) {
          setUserInfo({
            ...props.userContext,
            representativeImageUrl: response.updatedStaffProfile.representativeImageUrl
          });
          toggleEditHandler();
        }
      }
    );
  };

  const initialValues = {
    firstName: profileInfo.firstName || '',
    lastName: profileInfo.lastName || '',
    title: profileInfo.orgStaff.title || '',
    role: profileInfo.orgStaff.roleLabel || '',
    email: profileInfo.email || '',
    phone: profileInfo.orgStaff.phone || '',
    organizationName: profileInfo.orgStaff.organization.name || '',
    reprImageNull: false,
    reprImage: {
      src: profileInfo.representativeImageUrl,
      file: null
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: yupSchemas.string(false, { max: 50 }),
    lastName: yupSchemas.string(false, { max: 50 }),
    title: yupSchemas.string(false, { max: 100 }),
    role: yupSchemas.string(),
    email: yupSchemas.email(true),
    phone: yupSchemas.phone(),
    organizationName: yupSchemas.string(false, { max: 100 }),
    reprImage: yupSchemas.shape({
      file: yupSchemas.avatarFileSize
    })
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitHandler}
    >
      {({ values, isSubmitting, resetForm }) => (
        <Form>
          <Grid container>
            <EditToggle
              isEditing={isEditing}
              isSubmitting={isSubmitting}
              editProps={{
                id: 'editOrganizationProfile',
                onClick: toggleEditHandler,
                buttonCopy: 'Edit Account Information',
                edit: false
              }}
              saveProps={{
                id: 'saveOrganizationProfile',
                loading: isSubmitting
              }}
              cancelProps={{
                id: 'cancelOrganizationProfile',
                onClick: () => {
                  resetForm();
                  toggleEditHandler();
                }
              }}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <Field
                name="reprImage"
                nameNull="reprImageNull"
                editing={isEditing}
                component={FormikEditableImage}
                imageProps={{ alt: avatarAlt }}
                uploadButtonId="uploadOrganizationProfile"
                deleteButtonId="deleteOrganizationProfile"
              />
            </Grid>
            <Grid item md={8}>
              <DetailsSection noBorder title="Account Info">
                <Grid container spacing={1}>
                  {[
                    {
                      name: 'firstName',
                      label: 'First Name',
                      fieldProps: { disabled: true }
                    },
                    {
                      name: 'lastName',
                      label: 'Last Name',
                      fieldProps: { disabled: true }
                    },
                    {
                      name: 'phone',
                      label: 'Phone Number',
                      component: FormikPhoneField
                    },
                    {
                      name: 'email',
                      label: 'Email',
                      fieldProps: { disabled: true }
                    },
                    {
                      name: 'organizationName',
                      label: 'Organization',
                      fieldProps: { disabled: true }
                    },
                    {
                      name: 'title',
                      label: 'Title'
                    },
                    {
                      name: 'role',
                      label: 'Role',
                      fieldProps: { disabled: true }
                    }
                  ].map(field => (
                    <EditableField
                      key={field.name}
                      editing={isEditing}
                      formikValues={values}
                      gridItemProps={{ xs: 3 }}
                      renderDetail={value => (
                        <Detail
                          name={field.label}
                          value={value}
                          flexDirection="column"
                          alignItems="left"
                        />
                      )}
                      {...field}
                      fieldProps={{ ...(field.fieldProps || {}), fullWidth: true }}
                    />
                  ))}
                </Grid>
              </DetailsSection>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(
  createFragmentContainer(AccountInformationContent, {
    accountInformation: graphql`
      fragment AccountInformationContent_accountInformation on Query {
        currentUser {
          firstName
          lastName
          fullName
          email
          representativeImageUrl
          orgStaff {
            id
            roleLabel
            phone
            title
            organization {
              name
            }
          }
        }
      }
    `
  })
);

import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Detail from '../../../../shared/components/UI/Detail';
import EditableField from '../../../../shared/components/form/fields/EditableField';

export const GridEditableField = ({ fieldProps, detailProps, gridItemProps, ...props }) => (
  <EditableField
    {...props}
    gridItemProps={{
      xs: 3,
      ...gridItemProps
    }}
    renderDetail={value => (
      <Detail
        name={props.label}
        value={value || '-'}
        flexDirection="column"
        alignItems="left"
        {...detailProps}
      />
    )}
    fieldProps={{
      ...(props.component === undefined && { fullWidth: true }),
      ...fieldProps
    }}
  />
);

export const VatStatusLabel = styled(({ isEnabled, ...props }) => <Typography {...props} />)`
  font-weight: bold;
  color: ${props =>
    props.isEnabled ? props.theme.palette.colors.green : props.theme.palette.colors.red};
`;

import React from 'react';

import { TableSortLabel } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import StyledTableCell from './StyledTableCell';

const TableHeaderSortCell = props => {
  const { name, label, orderBy, handleSort, align, ...otherProps } = props;

  const isDescending = orderBy => orderBy[0] === '-';

  const sortHandler = () => {
    if (orderBy.includes(name)) {
      handleSort(isDescending(orderBy) ? name : `-${name}`);
    } else {
      handleSort(name);
    }
  };

  const sortDirection = isDescending(orderBy) ? 'desc' : 'asc';

  return (
    <StyledTableCell sortDirection={sortDirection} {...otherProps} align={align}>
      <TableSortLabel
        active={orderBy.includes(name)}
        direction={sortDirection}
        onClick={sortHandler}
        IconComponent={ArrowDropDownIcon}
      >
        {label}
      </TableSortLabel>
    </StyledTableCell>
  );
};

export default TableHeaderSortCell;

/**
 * @flow
 * @relayHash ee168fe1e04546c97fed7a46f6b545ca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type JobStatus = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "%future added value";
export type CancelJobInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type CancelJobMutationVariables = {|
  input: CancelJobInput
|};
export type CancelJobMutationResponse = {|
  +cancelJob: ?{|
    +isCanceled: ?boolean,
    +job: ?{|
      +status: JobStatus,
      +deliverables: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +state: DeliverableState
          |}
        |}>
      |},
    |},
  |}
|};
export type CancelJobMutation = {|
  variables: CancelJobMutationVariables,
  response: CancelJobMutationResponse,
|};
*/


/*
mutation CancelJobMutation(
  $input: CancelJobInput!
) {
  cancelJob(input: $input) {
    isCanceled
    job {
      status
      deliverables {
        edges {
          node {
            state
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CancelJobInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isCanceled",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CancelJobMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cancelJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverables",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CancelJobMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cancelJob",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelJobPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "job",
            "storageKey": null,
            "args": null,
            "concreteType": "JobNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "deliverables",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DeliverableNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CancelJobMutation",
    "id": null,
    "text": "mutation CancelJobMutation(\n  $input: CancelJobInput!\n) {\n  cancelJob(input: $input) {\n    isCanceled\n    job {\n      status\n      deliverables {\n        edges {\n          node {\n            state\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb75d02321f36f81c973fd9a6b4c6f46';
module.exports = node;

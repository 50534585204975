import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';

import formStyles from '../../../styles/common/formStyles';

import Event from '@material-ui/icons/Event';
import ClearFieldButton from './fieldElements/ClearFieldButton';
import withUserContext from '../../../contexts/userContext/withUserContext';
import { getDateFormatForDateField } from '../../../utils/formatters';
import { getDateDisplayFormatFromUserContext } from '../../../utils/helpers';

const DateField = props => {
  const { value, id, name, onChange, label, error, helperText, userContext, ...otherProps } = props;

  const [open, setOpen] = useState(false);
  const classes = formStyles();
  // use order of M D Y, based on org's config
  const format = getDateFormatForDateField(getDateDisplayFormatFromUserContext(userContext));

  const onChangeHandler = (date, value) => {
    onChange(name, date, value);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...{
          id,
          onChange: onChangeHandler,
          format,
          placeholder: format.toUpperCase(),
          value,
          label,
          open,
          ...(typeof error === 'undefined'
            ? {}
            : {
                error,
                helperText
              }),
          onClose: () => {
            setOpen(false);
          },
          variant: 'inline',
          inputVariant: 'outlined',
          autoOk: true,
          fullWidth: true,
          classes: {
            root: clsx(classes.textFieldRoot, classes.dateFieldRoot)
          },
          InputAdornmentProps: {
            position: 'start',
            style: {
              display: 'none'
            }
          },
          InputProps: {
            notched: false,
            endAdornment: (
              <Fragment>
                {value && <ClearFieldButton onClick={() => onChange(name, null)} />}
                <IconButton
                  className={classes.endAdornmentButton}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Event />
                </IconButton>
              </Fragment>
            )
          },
          ...otherProps
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

DateField.propTypes = {
  onChange: PropTypes.func
};

export default withUserContext(DateField);

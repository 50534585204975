import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { CELL_TYPES, SOLICITATION_STATES } from '../../../../shared/constants';
import AutoTable from '../../../../shared/components/table/AutoTable';
import useInterval from '../../../../shared/hooks/useInterval';
import getFetchNotifications from '../../../../shared/mutations/notifications/fetchNotifications';
import { useEffectWithStatus } from '../../../../shared/hooks/useEffectWithStatus';

const SolicitationQuickViewContractorList = props => {
  const history = useHistory();
  const {
    relay: { refetch },
    solicitation: {
      id: solicitationId,
      state,
      contractors: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    }
  } = props;

  const [notifications, setNotifications] = useState(null);
  const [refetchCounter, setRefetchCounter] = useState(0);

  useEffectWithStatus(
    status => {
      if (state !== SOLICITATION_STATES.draft) {
        refetchNotifications(status);
      } else {
        setNotifications(null);
      }
    },
    [solicitationId]
  );

  useInterval(
    // any updates are possible only if solicitation is in SENT state
    // so after initial fetch set interval only for SENT
    () => {
      if (state === SOLICITATION_STATES.active) {
        refetchNotifications();
        setRefetchCounter(counter => counter + 1);
      }
    },
    60000,
    solicitationId
  );

  const refetchNotifications = getFetchNotifications('solicitation', setNotifications, {
    targetId: solicitationId
  });

  const onMessageClickHandler = id => {
    history.push(`${history.location.pathname}/${solicitationId}/contractors`, {
      contractorId: id
    });
  };
  const onChangeHandler = variables => {
    refetch(variables);
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      fullName,
      representativeImageUrl,
      freelancer,
      numberOfCommentsInSolicitation
    } = edge.node;
    let messageType = 'none';
    if (notifications && freelancer) {
      if (
        notifications.edges.find(element => element.node.actorObjectId === freelancer.user.userId)
      ) {
        messageType = 'new';
      } else {
        messageType = 'default';
      }
    } else if (state !== SOLICITATION_STATES.draft) {
      messageType = 'default';
    }
    return {
      node: {
        id,
        fullName: { name: fullName, imageUrl: representativeImageUrl },
        message: { type: messageType, value: String(numberOfCommentsInSolicitation) }
      }
    };
  });

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: 'fullName', label: 'Contractor', type: CELL_TYPES.avatar },
          //Disabling Ratings from UI until feature fully fleshed out
          // { name: 'rating', label: 'Rating', type: CELL_TYPES.rating },
          { name: 'message', label: '', type: CELL_TYPES.message, width: 48 }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        initialOrderBy="newest_comment"
        refetchCounter={refetchCounter}
        rowProps={{
          handleMessageClick: onMessageClickHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
    </Fragment>
  );
};

export default createRefetchContainer(
  SolicitationQuickViewContractorList,
  {
    solicitation: graphql`
      fragment SolicitationQuickViewContractorList_solicitation on SolicitationNode
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        orderBy: { type: "String", defaultValue: "newest_comment" }
      ) {
        id
        state
        contractors(first: $first, after: $after, orderBy: $orderBy) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edgeCount
          edges {
            node {
              id
              fullName
              representativeImageUrl
              numberOfCommentsInSolicitation
              freelancer {
                user {
                  userId
                }
              }
            }
          }
        }
      }
    `
  },
  graphql`
    query SolicitationQuickViewContractorListRefetchQuery(
      $first: Int
      $after: String
      $id: ID!
      $orderBy: String
    ) {
      solicitation: solicitation(id: $id) {
        ...SolicitationQuickViewContractorList_solicitation
          @arguments(first: $first, after: $after, orderBy: $orderBy)
      }
    }
  `
);

import { makeStyles } from '@material-ui/core/styles';

export const drawerWidth = 236;
export const drawerClosedWidth = 92;
export const appBarHeight = 85;

const useSideBarStyles = makeStyles(theme => ({
  sideBarLogo: {
    height: `${appBarHeight}px`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.sideBarBackground,
    '& .a': {
      fill: theme.palette.primary.sideBarBackground
    },
    '& svg': {
      margin: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),

      '& path': {
        transition: theme.transitions.create('opacity', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    }
  },
  sideBarClosed: {
    '& svg': {
      margin: '0 0 0 -105px',

      '& g': {
        '& path:not(.logo)': {
          opacity: 0
        }
      }
    }
  },

  sideBarList: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    zIndex: '2',
    overflow: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.primary.sideBarBackground
  },

  sideBarLinks: {
    justifyContent: 'normal',
    marginTop: '32px'
  },

  sideBarLink: {
    textDecoration: 'none',
    color: theme.palette.primary.sideBarLink,
    '& .MuiListItem-root': {
      padding: '0',
      height: '60px'
    },

    '& .MuiListItemIcon-root': {
      width: `${drawerClosedWidth}px`,
      justifyContent: 'center',
      color: theme.palette.primary.sideBarIcon,
      '& svg': {
        '& g': {
          fill: theme.palette.primary.sideBarIcon,

          '& path': {
            fill: theme.palette.primary.sideBarIcon
          }
        }
      }
    },

    '& .MuiListItemText-root span': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: 'white',
      opacity: 0.7
    }
  },

  activeSideBarLink: {
    backgroundColor: theme.palette.primary.activeLink,
    '& .MuiListItem-root': {
      backgroundColor: theme.palette.primary.activeLink
    },
    '& .MuiListItemIcon-root': {
      color: 'white',
      '& svg': {
        '& g': {
          fill: 'white !important'
        },
        '& path': {
          fill: 'white !important'
        }
      }
    },
    '& .MuiListItemText-root span': {
      color: 'white',
      opacity: 1
    }
  },

  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    border: 'none',

    '& .MuiPaper-root': {
      border: 'none',
      overflow: 'visible'
    }
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    borderRight: 'none',
    zIndex: '15'
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: drawerClosedWidth,
    zIndex: '15'
  },

  drawerPaper: {
    overflow: 'visible',
    backgroundColor: 'transparent',
    border: 'none'
  },

  toolbar: {
    display: 'flex',
    backgroundColor: 'transparent',
    zIndex: 11,
    position: 'relative',
    overflow: 'visible',

    '& .filler': {
      flexGrow: 1,
      backgroundColor: theme.palette.primary.sideBarBackground
    },
    '& svg': {
      color: theme.palette.primary.sideBarBackground
    },

    '& button': {
      position: 'absolute',
      right: '-16px',
      top: '20px'
    }
  }
}));

export default useSideBarStyles;

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { getEnvironment } from '../../../../../shared/utils/helpers';

const mutation = graphql`
  mutation UpdateOrganizationSamlConfigurationMutation(
    $input: UpdateOrganizationSamlConfigurationInput!
  ) {
    updateOrganizationSamlConfiguration(input: $input) {
      organization {
        samlConfiguration {
          samlEnabled
          idpEntityId
          idpUrl
          idpX509cert
          idpAttrUserPermanentId
          metadataUrl
        }
      }
    }
  }
`;

const UpdateOrganizationSamlConfigurationMutation = (input, callback) => {
  const config = {
    mutation,
    variables: { input },
    onCompleted: (response, errors) => {
      callback(response.updateOrganizationSamlConfiguration, errors);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default UpdateOrganizationSamlConfigurationMutation;

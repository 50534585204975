import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseTooltip from '../table/CustomTableTooltip';

const TitleContainer = styled.div`
  user-select: none;
`;

const DropdownTooltip = ({
  tooltipComponent: Tooltip,
  shouldCloseOnOutsideClick,
  open,
  onClose,
  title,
  children,
  ...props
}) => {
  const titleContainerRef = useRef(null);
  const childrenRef = useRef(null);

  const handleOutsideClick = useCallback(
    e => {
      const canClose = shouldCloseOnOutsideClick ? shouldCloseOnOutsideClick(e) : true;

      if (
        open &&
        canClose &&
        e.target !== titleContainerRef.current &&
        e.target !== childrenRef.current &&
        !titleContainerRef.current.contains(e.target) &&
        !childrenRef.current.contains(e.target)
      ) {
        onClose();
      }
    },
    [open, shouldCloseOnOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    return () => document.removeEventListener('click', handleOutsideClick, true);
  });

  return (
    <Tooltip
      {...props}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={open}
      PopperProps={{
        modifiers: {
          flip: { enabled: false }
        }
      }}
      interactive
      placement="bottom"
      title={<TitleContainer ref={titleContainerRef}>{title}</TitleContainer>}
    >
      {React.cloneElement(children, {
        ref: node => {
          childrenRef.current = node;

          // NOTE:
          // https://github.com/facebook/react/issues/8873
          if (typeof children.ref === 'function') {
            children.ref(node);
          } else if (children.ref) {
            children.ref.current = node;
          }
        }
      })}
    </Tooltip>
  );
};

DropdownTooltip.propTypes = {
  open: PropTypes.bool.isRequired,
  shouldCloseOnOutsideClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  tooltipComponent: PropTypes.elementType.isRequired
};

DropdownTooltip.defaultProps = {
  tooltipComponent: BaseTooltip
};

export default DropdownTooltip;

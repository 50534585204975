import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import JobDeliverablesTab from '../tabs/JobDeliverablesTab';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../shared/contexts/tableHistoryContext';
import QueryRenderer from '../../../../shared/relay/QueryRenderer';

const JobDeliverablesTabRenderer = props => {
  const variables = useTableHistoryQueryVariables();
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query JobDeliverablesTabRendererQuery(
          $id: ID!
          $first: Int
          $after: String
          $orderBy: String
        ) {
          job(id: $id) {
            ...JobDeliverablesTab_job @arguments(first: $first, after: $after, orderBy: $orderBy)
          }
          deliverableState: __type(name: "DeliverableState") {
            ...JobDeliverablesTab_stateTypes
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => (
        <JobDeliverablesTab
          job={relayProps.job}
          stateTypes={relayProps.deliverableState}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(JobDeliverablesTabRenderer, 'freJobDeliverables', {
  orderBy: 'title'
});

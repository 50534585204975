import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => {
  return {
    bodySmall: {
      fontWeight: 'bold',
      fontSize: '12px',
      color: theme.palette.colors.darkBlueGrey
    },

    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '12px',
      position: 'relative',
      borderTop: `1px solid #D3D7E7`,
      height: '44px',

      '& span': {
        fontWeight: 'bold'
      }
    },

    rowSelectContainer: {
      position: 'absolute',
      display: 'flex',
      right: '0',
      alignItems: 'center',

      '& span': {
        marginRight: theme.spacing(1)
      },

      '& .MuiSelect-root': {
        fontSize: '12px',
        fontWeight: 'bold',
        padding: 0,
        paddingRight: theme.spacing(3)
      }
    }
  };
});

import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid, Box, FormHelperText, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import GlobalButton from '../../../../../shared/components/UI/GlobalButton';
import { FormikCategorySelect } from '../../../shared/dropdowns/CategorySelect';
import { round2 } from '../../../../../shared/utils/formatters';
import { formatAmount } from '../../../../../shared/utils/formatters';

const BudgetAddCategoryForm = ({ onSubmit, budgetState }) => {
  const categoryFormValidationSchema = Yup.object().shape({
    category: Yup.mixed().required('Please select a Category first'),
    amount: Yup.number().required('Required')
  });

  const calculateAmount = value => {
    const categoryInState = budgetState.find(stateValue => stateValue.id === value.value);
    return categoryInState
      ? round2(round2(categoryInState.contractedAmount) + round2(categoryInState.pendingAmount))
      : 0;
  };

  const selectedOptions = useMemo(() => budgetState.filter(value => value.budgetAmount !== null), [
    budgetState
  ]);

  return (
    <Formik
      initialValues={{
        amount: '',
        category: null
      }}
      onSubmit={onSubmit}
      validationSchema={categoryFormValidationSchema}
      enableReinitialize
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item>
              <Field
                name="category"
                label="Budget Category"
                component={FormikCategorySelect}
                onChange={(name, value) => {
                  const calculatedAmount = calculateAmount(value);
                  setFieldValue(name, value);
                  setFieldValue('amount', calculatedAmount);
                }}
                selectedOptions={selectedOptions}
                notFullWidth
                noClear={true}
              />
              {values.amount ? (
                <FormHelperText component="div">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="overline">Existing Deliverable Total</Typography>
                    <Typography variant="overline">{formatAmount(values.amount, 'USD')}</Typography>
                  </Box>
                </FormHelperText>
              ) : (
                false
              )}
            </Grid>
            <Grid item>
              <Box mt="22px">
                <GlobalButton id="addCategory" type="submit">
                  Add
                </GlobalButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default BudgetAddCategoryForm;

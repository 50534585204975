/**
 * @flow
 * @relayHash 39c93f746b971037a613c0bd18830e2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobDetails_job$ref = any;
type JobDetails_stateTypes$ref = any;
export type JobDetailsPageQueryVariables = {|
  id: string
|};
export type JobDetailsPageQueryResponse = {|
  +job: ?{|
    +$fragmentRefs: JobDetails_job$ref
  |},
  +jobState: ?{|
    +$fragmentRefs: JobDetails_stateTypes$ref
  |},
|};
export type JobDetailsPageQuery = {|
  variables: JobDetailsPageQueryVariables,
  response: JobDetailsPageQueryResponse,
|};
*/


/*
query JobDetailsPageQuery(
  $id: ID!
) {
  job(id: $id) {
    ...JobDetails_job
    id
  }
  jobState: __type(name: "JobStatus") {
    ...JobDetails_stateTypes
  }
}

fragment JobDetails_job on JobNode {
  jobId
  name
  status
  jobDeadline
  contractor {
    organization {
      name
      representativeImageUrl
      id
    }
    id
  }
}

fragment JobDetails_stateTypes on __Type {
  enumValues {
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "JobStatus"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JobDetailsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "JobDetails_job",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "jobState",
        "name": "__type",
        "storageKey": "__type(name:\"JobStatus\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "JobDetails_stateTypes",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "JobDetailsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "job",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "jobId",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "jobDeadline",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractor",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractorNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "representativeImageUrl",
                    "args": null,
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ]
              },
              (v4/*: any*/)
            ]
          },
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "jobState",
        "name": "__type",
        "storageKey": "__type(name:\"JobStatus\")",
        "args": (v2/*: any*/),
        "concreteType": "__Type",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "enumValues",
            "storageKey": null,
            "args": null,
            "concreteType": "__EnumValue",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "JobDetailsPageQuery",
    "id": null,
    "text": "query JobDetailsPageQuery(\n  $id: ID!\n) {\n  job(id: $id) {\n    ...JobDetails_job\n    id\n  }\n  jobState: __type(name: \"JobStatus\") {\n    ...JobDetails_stateTypes\n  }\n}\n\nfragment JobDetails_job on JobNode {\n  jobId\n  name\n  status\n  jobDeadline\n  contractor {\n    organization {\n      name\n      representativeImageUrl\n      id\n    }\n    id\n  }\n}\n\nfragment JobDetails_stateTypes on __Type {\n  enumValues {\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8490efa0cac8180a6f204b247fb80a09';
module.exports = node;

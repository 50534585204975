import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const assignBatchDeliverableInternally = graphql`
  mutation AssignBatchDeliverableInternallyMutation(
    $input: AssignBatchDeliverableInternallyInput!
  ) {
    assignBatchDeliverableInternally(input: $input) {
      isUpdated
      updatedDeliverables {
        state
        assignedInternally {
          id
          user {
            id
          }
          fullName
          representativeImageUrl
        }
      }
    }
  }
`;

export default (deliverableIds, staffId, callback) => {
  const config = {
    mutation: assignBatchDeliverableInternally,
    variables: { input: { ids: deliverableIds, staffId } },
    onCompleted: response => {
      callback(response.assignBatchDeliverableInternally);
    }
  };

  commitMutation(getEnvironment(), config);
};

import React from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from './StyledTableCell';
import StatusIndicator from '../UI/StatusIndicator';

const TableCellStatus = ({ cellProps, statusProps }) => (
  <StyledTableCell state noWrap {...cellProps} className='stateTableCell'>
    <StatusIndicator table {...statusProps} />
  </StyledTableCell>
);

TableCellStatus.propTypes = {
  cellProps: PropTypes.object,
  statusProps: PropTypes.object
};

export default TableCellStatus;

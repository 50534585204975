import React from 'react';
import SolicitationsList from './SolicitationsList';
import graphql from 'babel-plugin-relay/macro';
import QueryRenderer from '../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../shared/contexts/tableHistoryContext';

const SolicitationsPage = props => {
  const variables = useTableHistoryQueryVariables();

  return (
    <QueryRenderer
      query={graphql`
        query SolicitationsPageQuery(
          $first: Int
          $orderBy: String
          $after: String
          $replyByFrom: Date
          $replyByTo: Date
          $id: ID!
          $state: String
          $subject: String
          $staffCoordinator: ID
          $includeClosed: Boolean
        ) {
          ...SolicitationsList_solicitations
          __type(name: "SolicitationState") {
            ...SolicitationsList_stateTypes
          }

          ...SolicitationQuickView_solicitation
        }
      `}
      variables={variables}
      render={relayProps => (
        <SolicitationsList
          label="JobOpps"
          solicitations={relayProps}
          notifications={relayProps}
          solicitation={relayProps}
          stateTypes={relayProps.__type}
          {...props}
        />
      )}
    />
  );
};

export default withTableHistoryProvider(SolicitationsPage, 'orgSolicitations', {
  orderBy: 'subject',
  initialFilters: {
    subject: '',
    replyByFrom: null,
    replyByTo: null,
    state: '',
    staffCoordinator: null,
    includeClosed: false
  }
});

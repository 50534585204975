import React, { Fragment, useState, useRef } from 'react';
import GlobalButton from '../UI/GlobalButton';

const UploadImageButton = props => {
  // this component renders the children or a default value "Upload New"
  const { handleChange, id, children, ...otherProps } = props;
  const [value, setValue] = useState('');
  const fileUploader = useRef();

  const onChangeHandler = event => {
    const file = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.onload = event => {
      handleChange(file, event.target.result);
    };
    reader.readAsDataURL(file);
    setValue('');
  };

  return (
    <Fragment>
      <GlobalButton
        variant="black"
        handleClick={() => fileUploader.current.click()}
        {...otherProps}
      >
        {children ? children : 'Upload New'}
      </GlobalButton>
      <input
        id={id}
        ref={fileUploader}
        value={value}
        type={'file'}
        accept="image/x-png,image/gif,image/jpeg"
        style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0 }}
        onChange={onChangeHandler}
      />
    </Fragment>
  );
};

export default UploadImageButton;

import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { fetchQuery } from 'react-relay';
import { Typography } from '@material-ui/core';

import Dialog from '../../../../shared/components/common/Dialog';
import { useEffectWithStatus } from '../../../../shared/hooks/useEffectWithStatus';
import { getEnvironment } from '../../../../shared/utils/helpers';
import {
  MissingPaymentInfoModalContent,
  BillingModalContent,
  NotAllowedModalContent
} from './SubscriptionModals';
import UpdateOrganizationSubscriptionMutation from './mutations/UpdateOrganizationSubscriptionMutation';

const DIALOG_VERSIONS = {
  missingPaymentInfo: { name: 'missingPaymentInfo', title: 'Missing Payment Info' },
  error: { name: 'error', title: 'Error' },
  purchase: { name: 'purchase', title: 'Billing' },
  notAllowed: { name: 'notAllowed', title: 'Tier Warning' }
};

export const INTERVALS = {
  monthly: 'monthly',
  annual: 'annual'
};

const UpdateSubscriptionDialog = ({
  tierName,
  displayMonthly,
  toggleDialogOpen,
  currentSubscription
}) => {
  const [dialogVersion, setDialogVersion] = useState(undefined);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffectWithStatus(status => {
    fetchQuery(
      getEnvironment(),
      graphql`
        query UpdateSubscriptionDialogQuery($tierName: String!, $interval: String!) {
          updateSubscriptionCheck(tierName: $tierName, interval: $interval) {
            isMissingPaymentInfo
            invoicePreview {
              total
              timestamp
              invoiceLines {
                itemName
                quantity
                cost
              }
            }
            error
            notAllowed
            isDowngradingTier
            subscriptionChanges {
              tier
              interval
              nextBillingDate
              modules
              activeUsers
              totalUsers
            }
          }
        }
      `,
      {
        tierName: tierName.toLowerCase(),
        interval: displayMonthly ? INTERVALS.monthly : INTERVALS.annual
      },
      { force: true }
    ).then(response => {
      if (status.mounted) {
        if (response && response.updateSubscriptionCheck) {
          const {
            error,
            invoicePreview,
            isMissingPaymentInfo,
            notAllowed,
            isDowngradingTier,
            subscriptionChanges
          } = response.updateSubscriptionCheck;
          if (error) {
            setDialogVersion({ ...DIALOG_VERSIONS.error, message: error });
          } else if (isMissingPaymentInfo) {
            setDialogVersion(DIALOG_VERSIONS.missingPaymentInfo);
          } else if (notAllowed) {
            setDialogVersion({ ...DIALOG_VERSIONS.notAllowed });
          } else {
            setDialogVersion({
              ...DIALOG_VERSIONS.purchase,
              invoicePreview,
              subscriptionChanges: {
                ...subscriptionChanges,
                nextBillingDate:
                  subscriptionChanges.nextBillingDate ||
                  (subscriptionChanges.tier !== 'Free' ? currentSubscription.nextBillingDate : null)
              },
              isDowngradingTier
            });
          }
        }
      }
    });
  }, []);

  const handleSubmit = () => {
    setSubmitting(true);
    UpdateOrganizationSubscriptionMutation(
      {
        tierName: tierName.toLowerCase(),
        interval: displayMonthly ? INTERVALS.monthly : INTERVALS.annual,
        prorationTimestamp: dialogVersion.timestamp
      },
      response => {
        setSubmitting(false);
        toggleDialogOpen();
      }
    );
  };

  const renderDialogContent = () => {
    switch (dialogVersion.name) {
      case DIALOG_VERSIONS.error.name:
        return <Typography>{dialogVersion.message}</Typography>;
      case DIALOG_VERSIONS.missingPaymentInfo.name:
        return <MissingPaymentInfoModalContent handleClose={toggleDialogOpen} />;
      case DIALOG_VERSIONS.purchase.name:
        return (
          <BillingModalContent
            handleClose={toggleDialogOpen}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            currentSubscription={currentSubscription}
            {...dialogVersion}
          />
        );
      case DIALOG_VERSIONS.notAllowed.name:
        return <NotAllowedModalContent handleClose={toggleDialogOpen} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      isDialogOpen={true}
      closeDialog={toggleDialogOpen}
      title={dialogVersion ? dialogVersion.title : 'Loading...'}
      minWidth={650}
      maxWidth={750}
    >
      {!dialogVersion && <Typography>Loading...</Typography>}
      {dialogVersion && renderDialogContent()}
    </Dialog>
  );
};

export default UpdateSubscriptionDialog;

/**
 * @flow
 * @relayHash 419e08e6e2fa9678523c57cbe249090a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateReleaseInput = {|
  name: string,
  code?: ?string,
  deadline?: ?any,
  description?: ?string,
  product: string,
  copyOfRelease?: ?string,
  clientMutationId?: ?string,
|};
export type CreateReleaseMutationVariables = {|
  input: CreateReleaseInput
|};
export type CreateReleaseMutationResponse = {|
  +createRelease: ?{|
    +newRelease: ?{|
      +id: string,
      +product: {|
        +id: string,
        +hasReleases: ?boolean,
      |},
    |}
  |}
|};
export type CreateReleaseMutation = {|
  variables: CreateReleaseMutationVariables,
  response: CreateReleaseMutationResponse,
|};
*/


/*
mutation CreateReleaseMutation(
  $input: CreateReleaseInput!
) {
  createRelease(input: $input) {
    newRelease {
      id
      product {
        id
        hasReleases
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateReleaseInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createRelease",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateReleasePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "newRelease",
        "storageKey": null,
        "args": null,
        "concreteType": "ReleaseNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "product",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasReleases",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateReleaseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateReleaseMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateReleaseMutation",
    "id": null,
    "text": "mutation CreateReleaseMutation(\n  $input: CreateReleaseInput!\n) {\n  createRelease(input: $input) {\n    newRelease {\n      id\n      product {\n        id\n        hasReleases\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2cdd2537b975f3098d88f32d650471d5';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountInformationContent_accountInformation$ref: FragmentReference;
declare export opaque type AccountInformationContent_accountInformation$fragmentType: AccountInformationContent_accountInformation$ref;
export type AccountInformationContent_accountInformation = {|
  +currentUser: ?{|
    +firstName: string,
    +lastName: string,
    +fullName: ?string,
    +email: string,
    +representativeImageUrl: ?string,
    +orgStaff: ?{|
      +id: string,
      +roleLabel: ?string,
      +phone: ?string,
      +title: ?string,
      +organization: {|
        +name: string
      |},
    |},
  |},
  +$refType: AccountInformationContent_accountInformation$ref,
|};
export type AccountInformationContent_accountInformation$data = AccountInformationContent_accountInformation;
export type AccountInformationContent_accountInformation$key = {
  +$data?: AccountInformationContent_accountInformation$data,
  +$fragmentRefs: AccountInformationContent_accountInformation$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AccountInformationContent_accountInformation",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentUser",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "representativeImageUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orgStaff",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "roleLabel",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "phone",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "organization",
              "storageKey": null,
              "args": null,
              "concreteType": "OrganizationNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '97ea0e1f72f58e9bab35cdaf7c8eb295';
module.exports = node;

import React from 'react';
import graphql from 'babel-plugin-relay/macro';

import SearchableDropdown from '../../../shared/components/form/SearchableDropdown';

const contractorQuery = graphql`
  query GroupSearchableContractorDropdownQuery($contractorName: String, $first: Int) {
    contractorsByOrg(
      contractorName: $contractorName
      orderBy: "fullName"
      first: $first
      excludeWithConflicts: true
      includeInactive: false
    ) {
      edgeCount
      totalCount
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;
const SearchableContractorDropdown = props => {
  const { contractorsInList, handleAddContractor } = props;
  const getFetchVariables = inputValue => {
    return {
      contractorName: inputValue,
      first: 10 + contractorsInList.length
    };
  };

  const formatOptions = contractors => {
    if (!contractors || !contractors.contractorsByOrg) {
      return [];
    }
    return contractors.contractorsByOrg.edges.map(contr => {
      return { value: contr.node.id, label: contr.node.fullName, ...contr.node };
    });
  };
  return (
    <SearchableDropdown
      resultKey="contractorsByOrg"
      keyToReload={`${contractorsInList.length}`}
      itemsInList={contractorsInList}
      query={contractorQuery}
      getFetchVariables={getFetchVariables}
      formatOptions={formatOptions}
      selectChangeHandler={handleAddContractor}
      name="contractors"
      placeholder="Add contractors"
      value={null}
    />
  );
};

export default SearchableContractorDropdown;

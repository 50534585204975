/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type GreyBar_solicitation$ref: FragmentReference;
declare export opaque type GreyBar_solicitation$fragmentType: GreyBar_solicitation$ref;
export type GreyBar_solicitation = {|
  +staffCoordinator: {|
    +fullName: ?string,
    +representativeImageUrl: ?string,
    +organization: {|
      +representativeImageUrl: ?string,
      +name: string,
      +country: ?{|
        +name: ?string
      |},
    |},
  |},
  +$refType: GreyBar_solicitation$ref,
|};
export type GreyBar_solicitation$data = GreyBar_solicitation;
export type GreyBar_solicitation$key = {
  +$data?: GreyBar_solicitation$data,
  +$fragmentRefs: GreyBar_solicitation$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "representativeImageUrl",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "GreyBar_solicitation",
  "type": "SolicitationNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffCoordinator",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "OrgNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "country",
              "storageKey": null,
              "args": null,
              "concreteType": "Country",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4f1598c757991a0601b0da134904347';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';

import RatingStars from '../../../shared/components/UI/RatingStars';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import AvatarWithName from './AvatarWithName';

const QuickViewHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: props => `${props.marginBottom}px`,

  '& .MuiAvatar-root': {
    marginRight: '15px'
  },

  '& h1': {
    marginRight: '24px'
  }
});

const QuickViewAvatarHeader = props => {
  const { fullName, representativeImageUrl, rating, marginBottom } = props;

  return (
    <QuickViewHeader marginBottom={marginBottom || 18}>
      <AvatarWithName
        name={fullName}
        size={50}
        avatarProps={{
          src: representativeImageUrl
        }}
        typographyProps={{
          variant: 'h1'
        }}
      />
      {(rating || rating === 0) && <RatingStars rating={rating}></RatingStars>}
    </QuickViewHeader>
  );
};

QuickViewAvatarHeader.propTypes = {
  fullName: PropTypes.string.isRequired,
  representativeImageUrl: PropTypes.string,
  rating: PropTypes.number,
  marginBottom: PropTypes.number
};

export default QuickViewAvatarHeader;

import React from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Typography } from '@material-ui/core';
import DeliverableReviewContent from '../../../../../shared/components/deliverable/DeliverableReviewContent';

const DeliverableReview = props => {
  const {
    deliverable: { reviewActive },
    relay: { refetch }
  } = props;

  const refetchHandler = (force = false) => {
    refetch(oldVariables => oldVariables, null, null, { force });
  };

  return reviewActive ? (
    <DeliverableReviewContent
      deliverable={props.deliverable}
      refetch={refetchHandler}
      canAddComment
      canUpload
      canChangeReviewStep={false}
      canAddReaction={false}
    />
  ) : (
    <Typography align="center" variant="body2">
      No active review
    </Typography>
  );
};

export default createRefetchContainer(
  DeliverableReview,
  {
    deliverable: graphql`
      fragment DeliverableReview_deliverable on DeliverableNode {
        id
        state
        description
        job {
          id
          name
          contractor {
            fullName
            representativeImageUrl
          }
        }
        assignedStaff {
          fullName
          representativeImageUrl
        }
        reviewAssetFileValidationConfig {
          reviewInterface
          fileTypes
          fileSize
        }
        reviewActive {
          id
          step {
            id
            name
          }
          reviewStepList {
            id
            name
          }
          deliverableReviewAssets {
            edges {
              node {
                id
                fileName
                fileThumbnailUrl
                storageClass
                created
                revisionNumber
                reviewInterface
                reactions {
                  id
                  definitionId
                  name
                  color
                  number
                  users {
                    id
                    fullName
                    representativeImageUrl
                  }
                }
                comments {
                  edges {
                    node {
                      id
                      created
                      content
                      extraData
                      contentEditedAt
                      repliedTo {
                        content
                        created
                        user {
                          fullName
                        }
                        referenceFiles {
                          id
                          title
                          description
                          fileUrl
                          reviewInterface
                        }
                      }
                      user {
                        id
                        fullName
                        representativeImageUrl
                      }
                      referenceFiles {
                        id
                        title
                        description
                        fileUrl
                        reviewInterface
                      }
                      reactions {
                        id
                        definitionId
                        name
                        color
                        number
                        users {
                          id
                          fullName
                          representativeImageUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  },
  graphql`
    query DeliverableReviewRefetchQuery($id: ID!) {
      deliverable(id: $id) {
        ...DeliverableReview_deliverable
      }
    }
  `
);

import React, { useEffect, useState } from 'react';
import CreateReviewAssetCommentMutation from '../../../../mutations/comments/CreateReviewAssetCommentMutation';
import UpdateCommentMutation from '../../../../mutations/comments/UpdateCommentMutation';
import DeleteReviewAssetCommentMutation from '../../../../mutations/comments/DeleteReviewAssetCommentMutation';
import DetailedComment, { COMMENT_STATE } from '../../review/DetailedComment';

const ActiveCommentView = props => {
  const {
    deliverable,
    selectedAsset,
    comment,
    canAddComment,
    onCreate,
    onDelete,
    onViewMarkup
  } = props;
  const DEFAULT_STATE = comment ? COMMENT_STATE.DEFAULT : COMMENT_STATE.ADD_NEW;
  const commentEffectKey = comment ? comment.commentId : null;
  const [activeAction, setActiveAction] = useState(null);

  useEffect(() => {
    setActiveAction(null);
  }, [selectedAsset, commentEffectKey]);

  const handleSubmitComment = ({ content, rawContent, referenceIds, repliedTo }, actions) => {
    const assetId = repliedTo
      ? deliverable.reviewActive.deliverableReviewAssets.edges.find(
          ({ node }) => node.revisionNumber === repliedTo.revisionNumber
        ).node.id
      : selectedAsset.node.id;

    const onCompleted = ({ newComment }) => {
      onCreate(newComment);
      actions.resetForm();
    };

    CreateReviewAssetCommentMutation(
      {
        assetId,
        content,
        rawContent,
        ...(referenceIds && { referenceIds }),
        ...(repliedTo && { repliedTo: repliedTo.commentId })
      },
      onCompleted
    );
  };

  const handleUpdateComment = ({ content, rawContent, referenceIds }) => {
    UpdateCommentMutation(
      {
        id: comment.commentId,
        content,
        rawContent,
        referenceIds
      },
      () => {
        setActiveAction(null);
      }
    );
  };

  const handleDeleteComment = commentId => {
    DeleteReviewAssetCommentMutation(commentId, onDelete);
  };

  return (
    <DetailedComment
      deliverableId={deliverable.id}
      comment={comment}
      state={activeAction || DEFAULT_STATE}
      onStateChange={setActiveAction}
      onAddNew={canAddComment ? handleSubmitComment : null}
      onReply={handleSubmitComment}
      onEdit={handleUpdateComment}
      onDelete={handleDeleteComment}
      onViewMarkup={
        comment ? (comment.withMarkup && comment.annotationRef ? onViewMarkup : null) : null
      }
    />
  );
};

export default ActiveCommentView;

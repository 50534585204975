import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import AutoTable from '../../../../../shared/components/table/AutoTable';
import QuickViewDrawer from '../../../../../shared/components/common/QuickViewDrawer';
import OrganizationQuickView from './OrganizationQuickView';
import OrganizationsListFilters from './OrganizationsListFilters';

import { CELL_TYPES, ORGANIZATION_STATUS_FOR_FREELANCER } from '../../../../../shared/constants';
import { formatDateDisplay } from '../../../../../shared/utils/formatters';
import { resolveSolicitationLink, resolveJobLink } from '../../../../../shared/utils/linkResolvers';

const OrganizationsList = props => {
  const {
    relay: { refetch },
    organizations: {
      organizations: {
        pageInfo: { hasNextPage, endCursor },
        edges,
        totalCount,
        edgeCount
      }
    }
  } = props;

  const [selectedId, setSelectedId] = useState(null);

  const onQuickViewClickHandler = id => {
    id === selectedId ? setSelectedId(null) : setSelectedId(id);
  };

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    const { history, match } = props;
    history.push(`${match.url}/${id}`);
  };

  const flattenedEdges = edges.map(
    ({
      node: {
        id,
        name,
        representativeImageUrl,
        activeJobs,
        completedJobs,
        lastSolicitation,
        lastJob,
        status
      }
    }) => ({
      node: {
        id,
        name: {
          id,
          name: name,
          imageUrl: representativeImageUrl
        },
        activeJobs,
        completedJobs,
        lastSolicitation: lastSolicitation
          ? {
              cellText: formatDateDisplay(lastSolicitation.dateSent),
              cellLink: resolveSolicitationLink(lastSolicitation.id)
            }
          : { cellText: '', cellLink: '' },
        lastJob: lastJob
          ? {
              cellText: formatDateDisplay(lastJob.created),
              cellLink: resolveJobLink(lastJob.id, true)
            }
          : { cellLink: '', cellText: '' },
        status: { code: status },
        info: status !== ORGANIZATION_STATUS_FOR_FREELANCER.pendingInvitation
      }
    })
  );

  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: 'info', label: '', type: CELL_TYPES.info, onClick: onQuickViewClickHandler },
          {
            name: 'name',
            label: 'Organization',
            type: CELL_TYPES.avatar,
            icon: 'organization',
            sortable: true,
            onClick: onLinkClickHandler
          },
          { name: 'lastSolicitation', label: 'Last JobOpp', type: CELL_TYPES.relationLink },
          { name: 'lastJob', label: 'Last Job', type: CELL_TYPES.relationLink },
          {
            name: 'status',
            label: 'Status',
            type: CELL_TYPES.status,
            variant: 'organization'
          },
          { name: 'activeJobs', label: 'Active Jobs', align: 'right' },
          { name: 'completedJobs', label: 'Completed Jobs', align: 'right' }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        filterProps={{
          filterComponent: <OrganizationsListFilters />,
          withApply: true
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
      <QuickViewDrawer selectedId={selectedId} setSelectedId={setSelectedId}>
        <OrganizationQuickView organizationId={selectedId} />
      </QuickViewDrawer>
    </Fragment>
  );
};

export default withRouter(
  createRefetchContainer(
    OrganizationsList,
    {
      organizations: graphql`
        fragment OrganizationsList_organizations on Query
        @argumentDefinitions(
          first: { type: "Int" }
          orderBy: { type: "String", defaultValue: "name" }
          after: { type: "String" }
          name: { type: "String" }
          status: { type: "String" }
        ) {
          organizations(
            first: $first
            orderBy: $orderBy
            after: $after
            name: $name
            status: $status
          ) {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                name
                activeJobs
                completedJobs
                representativeImageUrl
                lastSolicitation {
                  id
                  dateSent
                }
                lastJob {
                  id
                  created
                }
                status
              }
            }
          }
        }
      `
    },
    graphql`
      query OrganizationsListRefetchQuery(
        $first: Int
        $orderBy: String
        $after: String
        $name: String
        $status: String
      ) {
        ...OrganizationsList_organizations
          @arguments(first: $first, orderBy: $orderBy, after: $after, name: $name, status: $status)
      }
    `
  )
);

import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';

import GlobalButton from '../UI/GlobalButton';
import StyledSwitch from '../UI/StyledSwitch';
import AssetCardImage from './AssetCardImage';
import Dialog from '../common/Dialog';
import SquareButton from '../UI/SquareButton';
import Card from './Card';
import DownloadFileSquareButton from '../UI/DownloadFileSquareButton';
import FullScreenImageModal from './FullScreenImageModal';

import useAssetStyles from '../../styles/assets/useAssetStyles';
import useDialog from '../../hooks/useDialog';
import { REVIEW_INTERFACES } from '../../constants';

const AssetCard = props => {
  const {
    id,
    src,
    name,
    description,
    deleteItem,
    editItem,
    index,
    reviewInterface,
    toggleAction
  } = props;

  const classes = useAssetStyles();
  const [isDialogOpen, toggleDialogOpen] = useDialog();
  const [showFullscreen, setShowFullscreen] = React.useState(false);

  const descriptionPreview = useRef(null);
  const lineClamped =
    descriptionPreview.current &&
    descriptionPreview.current.offsetHeight < descriptionPreview.current.scrollHeight;

  const toggleFullscreenHandler = () => {
    setShowFullscreen(!showFullscreen);
  };

  const editItemHandler = () => {
    editItem(id);
  };

  const deleteItemHandler = () => {
    deleteItem(id);
  };

  return (
    <Fragment>
      <Card
        onCardClick={reviewInterface === REVIEW_INTERFACES.image ? toggleFullscreenHandler : null}
        disabled={reviewInterface !== REVIEW_INTERFACES.image}
        renderContent={() => (
          <AssetCardImage src={src} reviewInterface={reviewInterface} index={index} />
        )}
        renderTopRight={() => (
          <Fragment>
            <DownloadFileSquareButton id={`downloadAsset-${index}`} fileName={name} fileUrl={src} />
            {editItem && (
              <SquareButton id={`edit-asset-${index}`} icon="edit" onClick={editItemHandler} />
            )}
            {deleteItem && (
              <SquareButton id={`delete-asset-${index}`} icon="trash" onClick={deleteItemHandler} />
            )}
          </Fragment>
        )}
        renderBottom={() => (
          <Box display="flex" alignItems="flex-start" flexDirection="column" mt={1}>
            <Box display="flex" alignItems="center" width="100%">
              <Typography variant="h4">{name}</Typography>
              {toggleAction && (
                <Box display="flex" alignItems="center" ml="auto" pl={2}>
                  <Typography variant="h4" noWrap>
                    {toggleAction.label}
                  </Typography>
                  <StyledSwitch
                    checked={toggleAction.getValue(id)}
                    onChange={e => toggleAction.onChange(id, e.target.checked)}
                    disabled={toggleAction.disabled}
                  />
                </Box>
              )}
            </Box>

            <Typography
              variant="subtitle1"
              ref={descriptionPreview}
              className={classes.descriptionPreview}
            >
              {description}
            </Typography>
            {description && lineClamped && (
              <GlobalButton
                id={`view-asset-${index}-description`}
                variant="transparent"
                handleClick={toggleDialogOpen}
                className={classes.descriptionButton}
              >
                View full description
              </GlobalButton>
            )}
          </Box>
        )}
      />
      <Dialog title="Description" isDialogOpen={isDialogOpen} closeDialog={toggleDialogOpen}>
        <div className={classes.assetDescriptionDialogContent}>
          <Typography component="p">{description}</Typography>
        </div>
      </Dialog>
      <FullScreenImageModal
        open={showFullscreen}
        onCloseClick={toggleFullscreenHandler}
        description={description}
        name={name}
        src={src}
      />
    </Fragment>
  );
};

AssetCard.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  deleteItem: PropTypes.func,
  reviewInterface: PropTypes.string,
  toggleAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    getValue: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  })
};

export default AssetCard;

import React, { Fragment, useState, useEffect } from 'react';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withRouter } from 'react-router';

import { CELL_TYPES } from '../../../../../shared/constants';
import useDialog from '../../../../../shared/hooks/useDialog';
import withDialogProps from '../../../../../shared/hoc/withDialogProps';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import AutoTable from '../../../../../shared/components/table/AutoTable';
import Dialog from '../../../../../shared/components/common/Dialog';
import getUserData from '../../../../mutations/getUserData';
import EditStaffForm from './EditStaffForm';
import StaffListFilters from './StaffListFilters';
import { formatDateDisplay } from '../../../../../shared/utils/formatters';
import { getDateDisplayFormatFromUserContext } from '../../../../../shared/utils/helpers';

const ACTION_BUTTON_OPTIONS = {
  deactivate: { name: 'deactivate', label: 'Deactivate', modalTitle: 'Deactivating Users' },
  changeRole: { name: 'changeRole', label: 'Change role', modalTitle: 'Changing Role' }
};

const StaffList = props => {
  const {
    relay: { refetch },
    staff: { staffByOrg },
    userContext
  } = props;

  const initialEditingData = { action: '', checked: [] };
  const [resetCursorAndRefetchCounter, setResetCursorAndRefetchCounter] = useState(0);

  const [refetchCounter, setRefetchCounter] = useState(0);

  const [editingData, setEditingData] = useState(initialEditingData);
  const [isDialogOpen, setDialogOpen] = useDialog();

  // prevents us from getting stale data if new staff account is created when user is on Roles tab
  useEffect(() => {
    // props.refetchCounter would be === 0 only if user loaded the page on Roles tab, then switched to Staff tab without creating a new Staff
    // no need to refetch then, data will be still good
    if (props.refetchCounter !== 0) {
      setRefetchCounter(prev => prev + 1);
    }
  }, [props.refetchCounter]);

  if (!staffByOrg) return <div>Something went wrong!</div>;

  const {
    pageInfo: { hasNextPage, endCursor },
    edges,
    totalCount,
    edgeCount
  } = staffByOrg;

  const toggleDialogOpen = () => {
    setDialogOpen(!isDialogOpen);
  };

  const onChangeHandler = variables => {
    refetch(variables);
  };

  const onLinkClickHandler = id => {
    const { history, match } = props;
    history.push(`${match.url}/${id}`);
  };

  const closeEditingHandler = () => {
    setEditingData(initialEditingData);
    toggleDialogOpen();
  };

  const actionClickHandler = (action, checked) => {
    setEditingData({ action, checked });
    toggleDialogOpen();
  };

  const closeEditingWithRefetch = () => {
    if (editingData.checked.some(item => item === userContext.orgStaff.id)) {
      // if current user is one of the ones being editing, we need yo update userContext info
      getUserData(userContext.setUserInfo);
    }
    closeEditingHandler();
    setResetCursorAndRefetchCounter(resetCursorAndRefetchCounter + 1);
  };

  const getDialogTitle = () => {
    return editingData.action ? ACTION_BUTTON_OPTIONS[editingData.action].modalTitle : '';
  };

  const disabledActionsCheckHandler = checked => {
    const disabledItems = [];
    const areAllActive = checked.every(edge => edge.node.isActive);

    if (!areAllActive) {
      disabledItems.push(ACTION_BUTTON_OPTIONS.deactivate.name);
    }

    return disabledItems;
  };

  const flattenedEdges = edges.map(edge => {
    const {
      id,
      fullName: name,
      representativeImageUrl: imageUrl,
      user: { email, lastLogin, isActive },
      title,
      roleLabel: role
    } = edge.node;

    return {
      node: {
        id,
        lastName: { name, imageUrl, id },
        title,
        role,
        email,
        // not using CELL_TYPE.date, because we need to display text if no date
        lastLogin: lastLogin
          ? formatDateDisplay(lastLogin, getDateDisplayFormatFromUserContext(userContext))
          : 'Pending',
        isActive,
        status: { code: isActive }
      }
    };
  });
  return (
    <Fragment>
      <AutoTable
        rowTemplate={[
          { name: '', label: '', type: CELL_TYPES.checkbox, checkbox: true },
          {
            name: 'lastName',
            label: 'Name',
            type: CELL_TYPES.avatar,
            sortable: true,
            onClick: onLinkClickHandler
          },
          { name: 'title', label: 'Title' },
          { name: 'role', label: 'Role' },
          { name: 'email', label: 'Email' },
          { name: 'lastLogin', label: 'Last Access' },
          { name: 'status', label: 'User Status', type: CELL_TYPES.status, variant: 'active' }
        ]}
        edges={flattenedEdges}
        onChangeHandler={onChangeHandler}
        refetchCounter={refetchCounter}
        resetCursorAndRefetchCounter={resetCursorAndRefetchCounter}
        labelledByHeader="select all users"
        filterProps={{
          filterComponent: <StaffListFilters />,
          withApply: true,
          initialFilters: {
            role: '',
            staffName: '',
            includeInactive: false
          },
          width: 1000
        }}
        actionButtonProps={{
          options: Object.values(ACTION_BUTTON_OPTIONS),
          handleMenuItemClick: actionClickHandler,
          getDisabledActions: disabledActionsCheckHandler
        }}
        paginationProps={{
          hasNextPage,
          endCursor,
          totalCount,
          edgeCount
        }}
      />
      <Dialog
        title={getDialogTitle()}
        isDialogOpen={isDialogOpen}
        closeDialog={toggleDialogOpen}
        minWidth={350}
      >
        <EditStaffForm
          handleClose={closeEditingHandler}
          handleCloseWithRefetch={closeEditingWithRefetch}
          options={ACTION_BUTTON_OPTIONS}
          editingData={editingData}
        />
      </Dialog>
    </Fragment>
  );
};

export default withUserContext(
  withRouter(
    withDialogProps(
      createRefetchContainer(
        StaffList,
        {
          staff: graphql`
            fragment StaffList_staff on Query {
              staffByOrg(
                first: $first
                orderBy: $orderBy
                after: $after
                staffName: $staffName
                role: $role
                includeInactive: $includeInactive
              ) {
                pageInfo {
                  hasNextPage
                  endCursor
                }
                totalCount
                edgeCount
                edges {
                  node {
                    id
                    fullName
                    roleLabel
                    title
                    user {
                      email
                      lastLogin
                      isActive
                    }
                    representativeImageUrl
                  }
                }
              }
            }
          `
        },
        graphql`
          query StaffListRefetchQuery(
            $first: Int
            $orderBy: String
            $after: String
            $staffName: String
            $role: ID
            $includeInactive: Boolean
          ) {
            ...StaffList_staff
          }
        `
      )
    )
  )
);

import React from 'react';
import * as Yup from 'yup';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Typography, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';

import FormikTextField from '../../../../../shared/components/form/FormikTextField';
import FormikSearchableDropdown from '../../../../../shared/components/form/FormikSearchableDropdown';
import FormikDateField from '../../../../../shared/components/form/FormikDateField';
import DetailsSection from '../../../../../shared/components/common/DetailsSection';
import DetailsRow from '../../../../../shared/components/UI/DetailsRow';
import DetailsRowDate from '../../../../../shared/components/UI/DetailsRowDate';
import withUserContext from '../../../../../shared/contexts/userContext/withUserContext';
import { computePermissionRole } from '../../../../../shared/utils/helpers';
import { ALLOWED_ACTIONS } from '../../../../../shared/constants';
import { useDetailsStyles } from '../../../../../shared/styles/common/useDetailsStyles';
import { formatDateForQueries } from '../../../../../shared/utils/formatters';
import UpdateReleaseMutation from '../../mutations/UpdateReleaseMutation';
import EditToggle from '../../../../../shared/components/UI/EditToggle';
import { resolveProductLink } from '../../../../../shared/utils/linkResolvers';
import DetailsRowAmount from '../../../../../shared/components/UI/DetailsRowAmount';

const ReleaseDetailsMainContent = props => {
  const {
    isEditing,
    toggleEditHandler,
    release: { id, description, name, internalId, product, deadline, totalBudget, code },
    userContext: {
      orgStaff: {
        allowedActions,
        organization: { configuration }
      }
    }
  } = props;
  const classes = useDetailsStyles();

  const navigation = JSON.parse(configuration).navigation;

  const initialFormikValues = {
    deadline,
    name,
    internalId,
    product: { value: product.id, label: product.title },
    code,
    description
  };

  const submitHandler = values => {
    const { name, product, code, internalId, deadline, description } = values;
    const variables = {
      id,
      name,
      product: product.value,
      code,
      internalId,
      deadline: formatDateForQueries(deadline),
      description
    };
    UpdateReleaseMutation(variables, response => {
      if (response) {
        toggleEditHandler();
      }
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(100, 'Please enter no more than 100 characters.')
      .required('Required'),
    internalId: Yup.string().max(100, 'Please enter no more than 100 characters'),
    product: Yup.string().nullable().required('Required'),
    code: Yup.string()
      .min(3, 'Please enter at least 3 characters.')
      .max(30, 'Please enter no more than 30 characters.'),
    description: Yup.string().max(5000, 'Please enter no more than 5000 characters.')
  });

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <Grid justify="space-between" alignContent="flex-start" container spacing={3}>
            {isEditing ? (
              <Grid md={9} item>
                <DetailsSection noBorder title="Details">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Field
                        required
                        name="name"
                        label={`${navigation.releaseTitle.singular} Name`}
                        placeholder={`Enter a ${navigation.releaseTitle.singular} name...`}
                        component={FormikTextField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="product"
                        label={`${navigation.productTitle.singular} Name`}
                        placeholder={`Select ${navigation.productTitle.singular}...`}
                        component={FormikSearchableDropdown}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="code"
                        label={`${navigation.releaseTitle.singular} Code`}
                        placeholder={`Enter a ${navigation.releaseTitle.singular} code...`}
                        component={FormikTextField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="deadline"
                        label="Key Date"
                        component={FormikDateField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="internalId"
                        label="Internal ID"
                        placeholder={`Enter a ${navigation.releaseTitle.singular} Internal ID...`}
                        component={FormikTextField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DetailsSection>
                <DetailsSection noBorder title="Description">
                  <Grid item xs={12}>
                    <Field
                      name="description"
                      label={`${navigation.releaseTitle.singular} Description`}
                      placeholder={`Enter a ${navigation.releaseTitle.singular} description...`}
                      component={FormikTextField}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </DetailsSection>
              </Grid>
            ) : (
              <Grid md={9} item>
                <DetailsSection noBorder title="Details">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DetailsRow
                        title={`${navigation.releaseTitle.singular} Name`}
                        content={name}
                      />
                      <DetailsRow
                        title={`${navigation.releaseTitle.singular} Code`}
                        content={code}
                      />
                      <DetailsRowDate title="Key Date" date={deadline} />
                      <DetailsRow
                        title={`${navigation.releaseTitle.singular} Internal ID`}
                        content={internalId}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailsRow
                        title={`${navigation.productTitle.singular} Name`}
                        link={resolveProductLink(product.id)}
                        content={product.title}
                      />
                      <DetailsRow
                        title={`${navigation.productTitle.singular} Code`}
                        content={product.code}
                      />
                      <DetailsRowAmount title="Total Budget" amount={totalBudget} />
                    </Grid>
                  </Grid>
                </DetailsSection>
                <DetailsSection noBorder title="Description">
                  <Typography>{description}</Typography>
                </DetailsSection>
              </Grid>
            )}
            <Grid md={3} item>
              <div className={classes.containerJustifyFlexEnd}>
                {computePermissionRole(ALLOWED_ACTIONS.RELEASE_EDIT_DETAILS, allowedActions) && (
                  <EditToggle
                    isEditing={isEditing}
                    editProps={{
                      id: 'editReleaseDetails',
                      onClick: toggleEditHandler,
                      disabled: false
                    }}
                    saveProps={{
                      id: 'saveReleaseDetails'
                    }}
                    cancelProps={{
                      id: 'cancelReleaseDetails',
                      onClick: toggleEditHandler
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default withUserContext(
  createFragmentContainer(ReleaseDetailsMainContent, {
    release: graphql`
      fragment ReleaseDetailsMainContent_release on ReleaseNode {
        id
        description
        name
        code
        internalId
        deadline
        totalBudget
        product {
          id
          code
          title
        }
      }
    `
  })
);

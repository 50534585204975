/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type NotificationTypes = "assigned_internally" | "comment_reaction" | "contract_signed" | "contractor_invite_accepted" | "contractor_invite_received" | "deliverable_cancel" | "deliverable_close" | "deliverable_file_upload" | "deliverable_reset" | "follow_object" | "invoice_approved" | "invoice_created" | "invoice_declined" | "job_canceled" | "job_comment" | "job_declined" | "job_received" | "job_revoked" | "payment_requested" | "reference_upload" | "review_asset_comment" | "review_asset_reaction" | "review_asset_upload" | "solicitation_comment" | "solicitation_received" | "staff_assigned" | "staff_invite_accepted" | "storage_limit_exceeded" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TopBarOrganizationNotification_notifications$ref: FragmentReference;
declare export opaque type TopBarOrganizationNotification_notifications$fragmentType: TopBarOrganizationNotification_notifications$ref;
export type TopBarOrganizationNotification_notifications = {|
  +notifications: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +unread: boolean,
        +timesince: ?string,
        +description: ?string,
        +actor: ?{|
          +name: ?string,
          +representativeImageUrl: ?string,
          +actorType: ?string,
        |},
        +deliverable: ?{|
          +title: string,
          +release: ?{|
            +name: string,
            +product: {|
              +title: string
            |},
          |},
        |},
        +extraData: ?{|
          +targetRelayId: ?string,
          +actionRelayId: ?string,
          +content: ?string,
          +notificationType: ?NotificationTypes,
          +instanceType: ?string,
          +instanceTitle: ?string,
          +action: ?string,
        |},
        +reviewAssetComment: ?{|
          +id: string
        |},
        +reviewAsset: ?{|
          +id: string,
          +revisionNumber: ?number,
          +deliverable: ?{|
            +id: string
          |},
        |},
      |}
    |}>,
  |},
  +$refType: TopBarOrganizationNotification_notifications$ref,
|};
export type TopBarOrganizationNotification_notifications$data = TopBarOrganizationNotification_notifications;
export type TopBarOrganizationNotification_notifications$key = {
  +$data?: TopBarOrganizationNotification_notifications$data,
  +$fragmentRefs: TopBarOrganizationNotification_notifications$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "TopBarOrganizationNotification_notifications",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "notifications"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "unread",
      "type": "Boolean",
      "defaultValue": true
    },
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "after",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "notifications",
      "name": "__TopBarOrganizationNotification_notifications_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "unread",
          "variableName": "unread"
        }
      ],
      "concreteType": "NotificationNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "NotificationNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "NotificationNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "unread",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "timesince",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "actor",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NotificationActorNode",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "representativeImageUrl",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "actorType",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "deliverable",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "DeliverableNode",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "release",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ReleaseNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "product",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ProductNode",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "extraData",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NotificationExtraData",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "targetRelayId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "actionRelayId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "content",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "notificationType",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "instanceType",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "instanceTitle",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "action",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "reviewAssetComment",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CommentNode",
                  "plural": false,
                  "selections": (v3/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "reviewAsset",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ReviewAssetNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "revisionNumber",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "deliverable",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "DeliverableNode",
                      "plural": false,
                      "selections": (v3/*: any*/)
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c95f120d9bf7ce4124e08797a055f04';
module.exports = node;

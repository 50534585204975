import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';

import { getEnvironment } from '../../../../shared/utils/helpers';

const mutation = graphql`
  mutation RemoveAllocatedDeliverableMutation(
    $input: RemoveSolicitationAllocatedDeliverableInput!
  ) {
    removeSolicitationAllocatedDeliverable(input: $input) {
      isUpdated
      solicitation {
        id
        numberOfAllocatedDeliverables
        numberOfAllocatedContractedDeliverables
      }
    }
  }
`;

const RemoveAllocatedDeliverableMutation = (
  solicitationId,
  deliverableId,
  contractorId,
  callback
) => {
  const removeAllocatedDeliverableVariables = {
    input: { solicitationId, deliverableId, contractorId }
  };

  const config = {
    mutation,
    variables: removeAllocatedDeliverableVariables,
    onCompleted: response => {
      callback(response.removeSolicitationAllocatedDeliverable);
    }
  };

  commitMutation(getEnvironment(), config);
};

export default RemoveAllocatedDeliverableMutation;

RemoveAllocatedDeliverableMutation.propTypes = {
  solicitationId: PropTypes.string.isRequired,
  deliverableId: PropTypes.string.isRequired,
  contractorId: PropTypes.string.isRequired
};

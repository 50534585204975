/**
 * @flow
 * @relayHash 56a9b230aea0df3d1141f8217e805b51
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliverableState = "A_1" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "%future added value";
export type UnlockDeliverableForJobInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type UnlockDeliverableForJobMutationVariables = {|
  input: UnlockDeliverableForJobInput
|};
export type UnlockDeliverableForJobMutationResponse = {|
  +unlockDeliverableForJob: ?{|
    +updatedDeliverable: ?{|
      +id: string,
      +state: DeliverableState,
    |}
  |}
|};
export type UnlockDeliverableForJobMutation = {|
  variables: UnlockDeliverableForJobMutationVariables,
  response: UnlockDeliverableForJobMutationResponse,
|};
*/


/*
mutation UnlockDeliverableForJobMutation(
  $input: UnlockDeliverableForJobInput!
) {
  unlockDeliverableForJob(input: $input) {
    updatedDeliverable {
      id
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnlockDeliverableForJobInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "unlockDeliverableForJob",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnlockDeliverableForJobPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedDeliverable",
        "storageKey": null,
        "args": null,
        "concreteType": "DeliverableNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UnlockDeliverableForJobMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UnlockDeliverableForJobMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UnlockDeliverableForJobMutation",
    "id": null,
    "text": "mutation UnlockDeliverableForJobMutation(\n  $input: UnlockDeliverableForJobInput!\n) {\n  unlockDeliverableForJob(input: $input) {\n    updatedDeliverable {\n      id\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '916d591db9b4b4b83076c01bfb647bd7';
module.exports = node;

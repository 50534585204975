import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const unlockDeliverableForJobMutation = graphql`
  mutation UnlockDeliverableForJobMutation($input: UnlockDeliverableForJobInput!) {
    unlockDeliverableForJob(input: $input) {
      updatedDeliverable {
        id
        state
      }
    }
  }
`;

export default (id, callback) => {
  const unlockDeliverableForJobVariables = {
    input: { id }
  };

  const config = {
    mutation: unlockDeliverableForJobMutation,
    variables: unlockDeliverableForJobVariables,
    onCompleted: response => {
      callback(response.unlockDeliverableForJob);
    }
  };

  commitMutation(getEnvironment(), config);
};

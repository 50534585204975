import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../shared/utils/helpers';

const mutation = graphql`
  mutation DeleteBatchDeliverableMutation($input: DeleteBatchDeliverableInput!) {
    deleteBatchDeliverable(input: $input) {
      isDeleted
    }
  }
`;

export default (ids, callback) => {
  const variables = {
    input: {
      ids
    }
  };

  commitMutation(getEnvironment(), {
    mutation,
    variables,
    onCompleted: response => {
      callback(response.deleteBatchDeliverable);
    },
    onError: err => console.error(err)
  });
};

import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { getEnvironment } from '../../../../shared/utils/helpers';

const activateDeliverableMutation = graphql`
  mutation ActivateDeliverableMutation($input: ActivateDeliverableInput!) {
    activateDeliverable(input: $input) {
      updatedDeliverable {
        state
      }
    }
  }
`;

export default (id, callback) => {
  const activateDeliverableVariables = {
    input: { id }
  };

  const config = {
    mutation: activateDeliverableMutation,
    variables: activateDeliverableVariables,
    onCompleted: response => {
      callback(response.activateDeliverable);
    }
  };

  commitMutation(getEnvironment(), config);
};

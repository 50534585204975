/**
 * @flow
 * @relayHash 2ff8cefbb0a8c43ab08f4386375111a3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RenameTagInput = {|
  id: string,
  name: string,
  mergeIfExists?: ?boolean,
  clientMutationId?: ?string,
|};
export type RenameTagMutationVariables = {|
  input: RenameTagInput
|};
export type RenameTagMutationResponse = {|
  +renameTag: ?{|
    +tag: ?{|
      +id: string,
      +name: string,
    |},
    +nameExists: ?boolean,
  |}
|};
export type RenameTagMutation = {|
  variables: RenameTagMutationVariables,
  response: RenameTagMutationResponse,
|};
*/


/*
mutation RenameTagMutation(
  $input: RenameTagInput!
) {
  renameTag(input: $input) {
    tag {
      id
      name
    }
    nameExists
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RenameTagInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "renameTag",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RenameTagPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tag",
        "storageKey": null,
        "args": null,
        "concreteType": "TagNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "nameExists",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RenameTagMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RenameTagMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RenameTagMutation",
    "id": null,
    "text": "mutation RenameTagMutation(\n  $input: RenameTagInput!\n) {\n  renameTag(input: $input) {\n    tag {\n      id\n      name\n    }\n    nameExists\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '08e08b004fe40560de8d48e716428603';
module.exports = node;

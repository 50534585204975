/**
 * @flow
 * @relayHash 65f15c811060d7dad6bbc664bab57a20
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CurrencySelectQueryVariables = {||};
export type CurrencySelectQueryResponse = {|
  +currencyRates: ?$ReadOnlyArray<?{|
    +rateDate: ?any,
    +baseCurrency: ?string,
    +currencyCode: ?string,
    +rate: ?any,
  |}>
|};
export type CurrencySelectQuery = {|
  variables: CurrencySelectQueryVariables,
  response: CurrencySelectQueryResponse,
|};
*/


/*
query CurrencySelectQuery {
  currencyRates {
    rateDate
    baseCurrency
    currencyCode
    rate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "currencyRates",
    "storageKey": null,
    "args": null,
    "concreteType": "ExchangeRateNode",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rateDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "baseCurrency",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currencyCode",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rate",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CurrencySelectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CurrencySelectQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "CurrencySelectQuery",
    "id": null,
    "text": "query CurrencySelectQuery {\n  currencyRates {\n    rateDate\n    baseCurrency\n    currencyCode\n    rate\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e444ed0fc8f010e145d17cbccca79b5c';
module.exports = node;

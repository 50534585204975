/**
 * @flow
 * @relayHash b15960e7b3dd966f08facc31b3bae3f7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ArchiveBatchReviewAssetsInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type BatchArchiveReviewAssetsMutationVariables = {|
  input: ArchiveBatchReviewAssetsInput
|};
export type BatchArchiveReviewAssetsMutationResponse = {|
  +archiveBatchReviewAssets: ?{|
    +isArchived: ?boolean,
    +organization: ?{|
      +storage: ?{|
        +activeStorageReviewAssets: ?number,
        +archivedStorageReviewAssets: ?number,
      |}
    |},
  |}
|};
export type BatchArchiveReviewAssetsMutation = {|
  variables: BatchArchiveReviewAssetsMutationVariables,
  response: BatchArchiveReviewAssetsMutationResponse,
|};
*/


/*
mutation BatchArchiveReviewAssetsMutation(
  $input: ArchiveBatchReviewAssetsInput!
) {
  archiveBatchReviewAssets(input: $input) {
    isArchived
    organization {
      storage {
        activeStorageReviewAssets
        archivedStorageReviewAssets
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ArchiveBatchReviewAssetsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isArchived",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "storage",
  "storageKey": null,
  "args": null,
  "concreteType": "StorageNode",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "activeStorageReviewAssets",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "archivedStorageReviewAssets",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BatchArchiveReviewAssetsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "archiveBatchReviewAssets",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveBatchReviewAssetsPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BatchArchiveReviewAssetsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "archiveBatchReviewAssets",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveBatchReviewAssetsPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "BatchArchiveReviewAssetsMutation",
    "id": null,
    "text": "mutation BatchArchiveReviewAssetsMutation(\n  $input: ArchiveBatchReviewAssetsInput!\n) {\n  archiveBatchReviewAssets(input: $input) {\n    isArchived\n    organization {\n      storage {\n        activeStorageReviewAssets\n        archivedStorageReviewAssets\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f183b34c81f36aae63b2b7d033b62135';
module.exports = node;

/**
 * @flow
 * @relayHash 2f6429763917666c307e745d39e20049
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateContractTemplateInput = {|
  name: string,
  categories?: ?$ReadOnlyArray<?string>,
  ignoreVariablesErrors?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type CreateContractTemplateMutationVariables = {|
  input: CreateContractTemplateInput
|};
export type CreateContractTemplateMutationResponse = {|
  +createContractTemplate: ?{|
    +contractTemplate: ?{|
      +id: string
    |},
    +errors: ?any,
  |}
|};
export type CreateContractTemplateMutation = {|
  variables: CreateContractTemplateMutationVariables,
  response: CreateContractTemplateMutationResponse,
|};
*/

/*
mutation CreateContractTemplateMutation(
  $input: CreateContractTemplateInput!
) {
  createContractTemplate(input: $input) {
    contractTemplate {
      id
    }
    errors
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'input',
        type: 'CreateContractTemplateInput!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'createContractTemplate',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input'
          }
        ],
        concreteType: 'CreateContractTemplatePayload',
        plural: false,
        selections: [
          {
            kind: 'LinkedField',
            alias: null,
            name: 'contractTemplate',
            storageKey: null,
            args: null,
            concreteType: 'ContractTemplateNode',
            plural: false,
            selections: [
              {
                kind: 'ScalarField',
                alias: null,
                name: 'id',
                args: null,
                storageKey: null
              }
            ]
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'errors',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'CreateContractTemplateMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'CreateContractTemplateMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'CreateContractTemplateMutation',
      id: null,
      text:
        'mutation CreateContractTemplateMutation(\n  $input: CreateContractTemplateInput!\n) {\n  createContractTemplate(input: $input) {\n    contractTemplate {\n      id\n    }\n    errors\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'a288c2468ad42ec613c7621b18f90e9d';
module.exports = node;

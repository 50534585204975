import { fabric } from 'fabric';
import { ATTRS, TOOLS, OPTIONS } from './contants';
import { resetTool } from './commons';
import { hideControls } from '../CanvasData';

const toolArrow = (canvas, { annotationColor, userId, annotationRef, frameRef }) => {
  resetTool(canvas, TOOLS.arrow.name);

  let line;
  let triangle;
  let startingPoint;
  let isMouseMoving = false;
  let arrow = new fabric.Group();

  const mouseDownHandler = event => {
    startingPoint = event.pointer;

    triangle = new fabric.Triangle({
      top: startingPoint.y,
      left: startingPoint.x,
      width: OPTIONS.arrowSize.width,
      height: OPTIONS.arrowSize.height,
      fill: annotationColor,
      strokeLineCap: 'round',
      visible: false,
      originX: 'center',
      originY: 'center',
      [ATTRS.type]: TOOLS.arrow.elements.triangle
    });

    line = new fabric.Line([startingPoint.x, startingPoint.y, startingPoint.x, startingPoint.y], {
      originX: 'center',
      originY: 'center',
      stroke: annotationColor,
      strokeWidth: OPTIONS.strokeWidth,
      selectable: false,
      cornerStyle: 'circle',
      [ATTRS.type]: TOOLS.arrow.elements.line
    });

    canvas.add(line);
    canvas.add(triangle);

    isMouseMoving = true;
  };

  const mouseMoveHandler = ({ pointer }) => {
    if (isMouseMoving) {
      line.set({
        x2: pointer.x,
        y2: pointer.y
      });

      triangle.set({
        visible: true,
        top: pointer.y,
        left: pointer.x,
        angle:
          (Math.atan2(pointer.y - startingPoint.y, pointer.x - startingPoint.x) * 180) / Math.PI +
          90
      });
      canvas.renderAll();
    }
  };

  const mouseUpHandler = () => {
    arrow.set({
      [ATTRS.originalColor]: annotationColor,
      [ATTRS.type]: TOOLS.arrow.name,
      [ATTRS.authorId]: userId,
      [ATTRS.ref]: annotationRef,
      [ATTRS.frameRef]: frameRef,
      selectable: false,
      noScaleCache: false,
      shadow: new fabric.Shadow({
        color: annotationColor,
        blur: 0
      })
    });
    canvas.remove(line);
    canvas.remove(triangle);
    arrow.addWithUpdate(triangle);
    arrow.addWithUpdate(line);
    canvas.add(arrow);
    isMouseMoving = false;
    line.setCoords();
    hideControls(arrow, ['mt', 'ml', 'mr', 'mb']);
    arrow = new fabric.Group();
  };

  

  canvas.on({
    'mouse:down': mouseDownHandler,
    'mouse:move': mouseMoveHandler,
    'mouse:up': mouseUpHandler
  });
};

export { toolArrow };

import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router';
import QueryRenderer from '../../../../../shared/relay/QueryRenderer';
import {
  withTableHistoryProvider,
  useTableHistoryQueryVariables
} from '../../../../../shared/contexts/tableHistoryContext';
import { PAGINATION_TYPES } from '../../../../../shared/constants';
import PortfolioList from './PortfolioList';

const ContractorPortfolioTab = props => {
  const variables = useTableHistoryQueryVariables();
  const { id } = useParams();

  return (
    <QueryRenderer
      query={graphql`
        query ContractorPortfolioTabQuery($id: ID!, $first: Int, $after: String) {
          contractor(id: $id) {
            ...PortfolioList_contractor
          }
        }
      `}
      variables={{ id, ...variables }}
      render={relayProps => <PortfolioList contractor={relayProps.contractor} {...props} />}
    />
  );
};

export default withTableHistoryProvider(ContractorPortfolioTab, 'orgContrPortfolio', {
  rowsPerPage: PAGINATION_TYPES.short.defaultValue
});

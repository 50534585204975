import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import useQueryParams from '../../../shared/hooks/useQueryParams';
import { fetchQuery } from 'react-relay';
import { Redirect, withRouter } from 'react-router-dom';

import { getEnvironment } from '../../../shared/utils/helpers';
import useSignUpStyles from '../../../shared/styles/account/useSignUpStyles';
import { ReactComponent as Logo } from '../../../shared/images/AWEbase-Default-Light.svg';
import GlobalButton from '../../../shared/components/UI/GlobalButton';
import { FullScreenContainer } from './FullScreenContainer';
import { useEffectWithStatus } from '../../../shared/hooks/useEffectWithStatus';

const SignUpEmailVerified = props => {
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(true);

  const verifyEmailQuery = graphql`
    query SignUpEmailVerifiedQuery($uidb64: String!, $orgToken: String!) {
      completeOrganizationSignUpTokenStatus(uidb64: $uidb64, orgToken: $orgToken) {
        tokenStatus
        errorMessage
      }
    }
  `;

  const queryParams = useQueryParams();
  const classes = useSignUpStyles();

  const getQueryParam = param => {
    if (queryParams.get(param)) {
      return queryParams.get(param);
    } else return '';
  };

  useEffectWithStatus(status => {
    if (getQueryParam('uidb64') && getQueryParam('org_token')) {
      fetchQuery(getEnvironment(), verifyEmailQuery, {
        uidb64: getQueryParam('uidb64'),
        orgToken: getQueryParam('org_token')
      }).then(response => {
        setLoading(false);
        if (response && response.completeOrganizationSignUpTokenStatus) {
          if (status.mounted) {
            const { errorMessage, tokenStatus } = response.completeOrganizationSignUpTokenStatus;
            if (errorMessage) {
              setError(errorMessage);
            } else if (!tokenStatus) {
              setError('This link is invalid.');
            }
          }
        } else {
          setError('Something went wrong. Please contact AWEbase support.');
        }
      });
    }
  }, []);

  if (!getQueryParam('uidb64') || !getQueryParam('org_token')) {
    return <Redirect to="/" />;
  }

  return (
    <FullScreenContainer>
      <div className={clsx(classes.logo, classes.logoContainer, classes.darkLogo)}>
        <Logo />
      </div>

      <div className={clsx(classes.container, classes.containerColumn)}>
        {isLoading && (
          <Typography
            variant="body2"
            className={clsx(classes.textMarginTop, classes.textMarginBottom)}
          >
            Verifying your email. Please wait...
          </Typography>
        )}
        {!isLoading && (
          <Typography
            variant="body2"
            className={clsx(classes.textMarginTop, classes.textMarginBottom)}
          >
            {error
              ? error
              : 'Your email was verified successfully. You can login to your AWEbase account.'}
          </Typography>
        )}
        <div
          className={clsx(
            classes.container,
            classes.containerColumn,
            classes.formContainer,
            classes.alignCenter
          )}
        >
          {!isLoading && (
            <GlobalButton id="goToLogin" handleClick={() => props.history.push('/login')} noMargin>
              {error ? 'Login here' : 'Proceed to login'}
            </GlobalButton>
          )}
        </div>
      </div>
    </FullScreenContainer>
  );
};

export default withRouter(SignUpEmailVerified);
